import { buildPageableUrl } from "@/utils/urlUtils";
import { axiosService } from "../../../axios";
import { EntityType } from "../../../constants/CommonConstats";

export const alertRuleEntityType = {
    PARTITION: "PARTITION",
    INTEGRATION: "INTEGRATION",
    ACCELERATOR: "ACCELERATOR",
};

export const alertRuleToEntityType = {
    PARTITION: EntityType.PARTITION,
    INTEGRATION: EntityType.INTEGRATION,
};

export const convertAlertRuleTypeToEntityType = alertRuleType => {
    const entityType = alertRuleToEntityType[alertRuleType];

    if (!entityType) {
        console.error(
            `Unknown alert rule entity type: ${JSON.stringify(alertRuleType)}`,
        );
    }

    return entityType;
};

export const alertRuleScope = {
    GLOBAL: "GLOBAL",
    SINGLE: "SINGLE",
};

export const alertRuleSeverities = [
    { value: "CRITICAL", label: "Critical" },
    { value: "HIGH", label: "High" },
    { value: "MEDIUM", label: "Medium" },
    { value: "LOW", label: "Low" },
];

export const alertRulesServices = {
    fetchAlertRuleServices(accountId) {
        return axiosService.get(`/api/alert-rules/services/${accountId}`);
    },

    fetchAlertRules(page, size, sort, filter, accountId) {
        if (accountId) {
            return axiosService.post(
                `${buildPageableUrl(
                    `/api/alert-rules/table`,
                    page,
                    size,
                    sort,
                )}&accountId=${accountId}`,
                filter,
            );
        } else {
            return axiosService.post(
                buildPageableUrl(`/api/alert-rules/table`, page, size, sort),
                filter,
            );
        }
    },

    save(alertRule) {
        const data = JSON.stringify(alertRule);
        return alertRule.id
            ? axiosService.post(`/api/alert-rules/${alertRule.id}`, data)
            : axiosService.post(`/api/alert-rules`, data);
    },

    copy(alertRuleId, body) {
        return axiosService.post(
            `/api/alert-rules/${alertRuleId}/copy`,
            JSON.stringify(body),
        );
    },

    test(alertRule) {
        return axiosService.post(
            `/api/alert-rules/test`,
            JSON.stringify(alertRule),
        );
    },

    get(alertRuleId) {
        return axiosService.get(`/api/alert-rules/${alertRuleId}`);
    },

    delete(alertRuleId) {
        return axiosService.delete(`/api/alert-rules/${alertRuleId}`);
    },

    alertRuleExists(name) {
        return axiosService.post(`/api/alert-rules/exists`, name);
    },

    fetchEventLogs(alertRuleId) {
        return axiosService.get(`/api/alert-rules/${alertRuleId}/history`);
    },
};
