import { ButtonMenu, Gap, Modal, UnityLayout } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { hooks } from "@/components/DesignSystem/Table/Table.component";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import { hasValue, isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import groupBy from "lodash/fp/groupBy";
import mapValues from "lodash/fp/mapValues";
import pipe from "lodash/fp/pipe";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";

const columns = [
    {
        name: "type",
        label: t("rollback.table.header.type"),
        type: fieldTypes.TEXT,
    },
    {
        name: "name",
        label: t("rollback.table.header.name"),
        type: fieldTypes.TEXT,
    },
];

const mapRollbacksToTable = dataSource => {
    return Object.entries(dataSource)
        .map(([type, names]) =>
            names.map(name => ({
                key: `${type}-${name}`,
                type,
                name,
            })),
        )
        .flat();
};

export const RollbackModal = ({
    perex,
    visible,
    rollbackMutation,
    rollbackOptionsLoadable,
    onClose,
}) => {
    const rowSelection = hooks.useRowSelection();

    const dataSource = useMemo(() => {
        return hasValue(rollbackOptionsLoadable)
            ? mapRollbacksToTable(rollbackOptionsLoadable.contents)
            : [];
    }, [rollbackOptionsLoadable]);

    const handleSubmit = useCallback(() => {
        const selectedItems = dataSource.filter(item =>
            rowSelection.selectedRowKeys.includes(item.key),
        );
        const selectedMap = pipe(
            groupBy(item => item.type),
            mapValues(groupedByAll =>
                groupedByAll.map(entry => {
                    return entry.name;
                }),
            ),
        )(selectedItems);

        return rollbackMutation.mutate(selectedMap);
    }, [dataSource, rollbackMutation, rowSelection.selectedRowKeys]);

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("rollback.modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    {perex}
                    <Gap />
                    <TableLocalFiltered
                        padding={false}
                        loading={isLoading(rollbackOptionsLoadable)}
                        rowKey="key"
                        columns={columns}
                        dataSource={dataSource}
                        rowSelection={rowSelection}
                        selectedRowKeys={rowSelection}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("rollback.modal.button.rollback"),
                                    onClick: handleSubmit,
                                    loading: isLoading(rollbackMutation),
                                    disabled:
                                        rowSelection.selectedRowKeys.length ===
                                        0,
                                    type: "primary",
                                },
                                {
                                    label: t("general.close"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

RollbackModal.propTypes = {
    perex: PropTypes.node.isRequired,
    visible: PropTypes.bool,
    accountId: PropTypes.number,
    deploymentId: PropTypes.string,
    partitionId: PropTypes.number,
    onRollback: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    rollbackMutation: PropTypes.object.isRequired,
    rollbackOptionsLoadable: PropTypes.object,
};
