import { useDic } from "@/components/Dic/useDic.hook";
import {
    VersionHistory,
    useVersionHistory,
} from "@/components/Integrations/components/VersionHistory.component";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useCenteredLayout } from "@/components/hooks/useContentLayout.hook";
import { useRouteParams } from "@/modules/router";
import { t } from "@/translations";
import React, { useCallback } from "react";

const VersionHistoryPage = () => {
    useCenteredLayout();
    const {
        locationRouterService,
        accountAppLocations: { instancesLocation },
    } = useDic();
    const routeParams = useRouteParams();

    const navigateToList = useCallback(() => {
        locationRouterService.navigate(instancesLocation);
    }, [locationRouterService, instancesLocation]);

    useSetBackButtonAction(navigateToList);
    useSetPageTitle(t("version-history.title"));

    const versionHistoryResource = useVersionHistory({
        entityId: routeParams.entityId,
        entityType: routeParams.entityType,
        queryParams: { accountId: routeParams.accountId },
    });
    return <VersionHistory versionHistoryResource={versionHistoryResource} />;
};

export default VersionHistoryPage;
