import { Gap } from "@/components/DesignSystem";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import DefaultRouteForm from "./DefaultRoute";

const UserPreferences = () => {
    useSetPageTitle(t("profile.title.user-preferences"));

    return (
        <>
            <Gap size="small" />
            <DefaultRouteForm />
        </>
    );
};

UserPreferences.propTypes = {
    userName: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    jobTitle: PropTypes.string,
};

export default UserPreferences;
