import { Alert, Checkbox, Gap, H5 } from "@/components/DesignSystem";
import { T, t } from "@/translations";
import { produce } from "immer";
import keyBy from "lodash/keyBy";
import sortBy from "lodash/sortBy";
import union from "lodash/union";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import {
    disableBusinessKey,
    getOptionalFields,
    getSelectedType,
    getSimpleValue,
    getTypeLabel,
    resolveBusinessColumns,
} from "../../../packagesPanel.utils";
import { MAX_EXTENSION_COLUMNS, ParsedType } from "../../constants";
import PackageDataMappingLayout from "../PackageDataMappingLayout/PackageDataMappingLayout";
import { PackageTable } from "../PackageTable/PackageTable";
import { TypeSelect } from "../PricePrameters/TypeSelect";
// assets
import { defaultPreviewCellProps } from "@/components/Packages/PackageTableDefinitionPanel/components/PackageDataMappingLayout/DataPreviewTable.component";
import styles from "../styles.less";
import { Skip } from "../TableComponents/Skip";

export const toManyBusinessKeys = fields => {
    const businessKeys = fields.filter(f => f.businessKey).length;
    const selected = fields.filter(f => !f.skip).length;
    return businessKeys >= selected;
};

const getField = (fields, record) =>
    fields.find(f => getSimpleValue(f.label) === getSimpleValue(record.label));

export const getAvailableFields = (
    { optionalFields, mandatoryFields },
    tableExampleData,
    allowChangeBusinessKey = true,
) => {
    const result = getOptionalFields(
        { mandatoryFieldsMap: mandatoryFields, optionalFields },
        tableExampleData,
    ).map(f => {
        f.businessKeyChangeable = allowChangeBusinessKey;
        return f;
    });

    return sortBy(result, "label");
};

export const ExtensionOptionalFields = ({
    globalState,
    changeState,
    step,
    onNext,
    onBack,
    onCancel,
}) => {
    const {
        fileInfo: tableExampleData = {},
        mandatoryFields = [],
        optionalFields = [],
    } = globalState;

    const setOptionalFields = f => changeState({ optionalFields: f });

    const [error, setError] = useState(null);

    useEffect(() => {
        setOptionalFields(
            getAvailableFields(
                { optionalFields, mandatoryFields },
                tableExampleData,
                step.allowChangeBusinessKey,
            ),
        );
    }, []);

    const customColumns = ({ name, type }) => {
        return {
            ...defaultPreviewCellProps,
            label: createTitle(
                name,
                getSelectedType(optionalFields, name) || type,
            ),
            name,
            render: text => <div>{`${text}`}</div>,
        };
    };

    const handleType = (record, value) => {
        setOptionalFields(
            produce(optionalFields, draft => {
                const field = getField(draft, record);
                field.type = value;
            }),
        );
    };

    const disableOption = (record, type) => {
        const byName = keyBy(tableExampleData.columns, "name");
        const originalType = byName[record.label];
        const possibleValues = [
            ParsedType.STRING,
            originalType && originalType.type,
        ];
        return !possibleValues.includes(type);
    };

    const createTitle = (name, type) => {
        const show = resolveBusinessColumns(
            mandatoryFields,
            optionalFields,
        ).includes(name);

        return (
            <div>
                {name}
                {!show && (
                    <div className={styles.type}>{getTypeLabel(type)}</div>
                )}
                {show && (
                    <div className={styles.businessKey}>
                        <i className={"uil uil-key-skeleton-alt"} />
                        {getTypeLabel(type)}
                    </div>
                )}
            </div>
        );
    };

    const onSkip = (record, checked) => {
        setOptionalFields(
            produce(optionalFields, draft => {
                const optionalField = getField(draft, record);
                optionalField.skip = checked;
                optionalField.businessKey = false;
            }),
        );
    };

    const onSkipAll = checked => {
        setOptionalFields(
            produce(optionalFields, draft => {
                draft.forEach(field => {
                    field.skip = checked;
                    field.businessKey = checked ? false : field.businessKey;
                });
            }),
        );
    };

    const handleBusinessKey = (record, checked) => {
        setOptionalFields(
            produce(optionalFields, draft => {
                const optionalField = getField(draft, record);
                optionalField.businessKey = checked;
            }),
        );
    };

    const handleNext = () => {
        const selectedFields = optionalFields.filter(
            field => field.skip === false,
        );

        if (selectedFields.length >= MAX_EXTENSION_COLUMNS) {
            setError(
                t("package.optional-fields.error.max-columns", {
                    maxColumns: MAX_EXTENSION_COLUMNS,
                }),
            );
            return;
        }

        if (toManyBusinessKeys(union(mandatoryFields, optionalFields))) {
            setError(t("package.optional-fields.error.to-many-business-keys"));
            return;
        }

        onNext({ optionalFields });
    };

    const resolveTextColor = labelName => {
        return optionalFields
            .filter(field => field.label === labelName)
            .map(field => {
                return field.skip ? styles.textDisabled : styles.headingColor;
            })
            .shift();
    };

    const columns = [
        {
            key: "pfxFieldName",
            width: "50%",
            title: () => (
                <>
                    {t("package-data-upload.label.extension-optional-fields", {
                        fileName: tableExampleData.fileName,
                    })}
                </>
            ),
            render: (text, record) => (
                <Fragment>
                    <H5 className={resolveTextColor(record.label)}>
                        {record.label}
                    </H5>
                </Fragment>
            ),
        },
        {
            key: "types",
            title: () => (
                <span className={styles.descriptionColor}>
                    <T id="package-label.extension-otp-fields.column-type" />
                </span>
            ),
            render: (text, record) => (
                <TypeSelect
                    record={record}
                    disableOption={disableOption}
                    handleType={handleType}
                    disabled={record.skip || !record.value}
                />
            ),
        },
        {
            key: "businessKey",
            title: () => <>{t("package-data-upload.label.business-key")}</>,
            render: (text, record) => {
                return (
                    <Checkbox
                        onChange={e =>
                            handleBusinessKey(record, e.target.checked)
                        }
                        checked={record.businessKey}
                        disabled={disableBusinessKey(
                            union(mandatoryFields, optionalFields),
                            record,
                        )}
                    />
                );
            },
        },
        Skip(onSkipAll, onSkip, optionalFields),
    ];

    return (
        <PackageDataMappingLayout
            dataExample={tableExampleData}
            disableNextButton={false}
            customColumns={customColumns}
            onNext={handleNext}
            onBack={onBack}
            onCancel={onCancel}
        >
            <T id="package-data-upload.extension-mandatory-files.message" />
            <Gap size="small" />
            {error && (
                <Fragment>
                    <Alert message={error} type="error" />
                    <Gap size="small" />
                </Fragment>
            )}
            <PackageTable fields={optionalFields} columns={columns} />
            <Gap size="medium" />
        </PackageDataMappingLayout>
    );
};

ExtensionOptionalFields.propTypes = {
    globalState: PropTypes.object.isRequired,
    changeState: PropTypes.func.isRequired,
    step: PropTypes.object.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
};
