import { useDic } from "@/components/Dic/useDic.hook";
import { getData } from "@/services/utils";
import React from "react";
import { EntityType } from "../../constants/CommonConstats";
import { DM_LOGS_PAGE } from "../../constants/sessionState.constants";
import ElasticSearchPanel from "../Integrations/Dashboard/Tabs/ElasticSearchPanel";

export const UploadLogsList = ({ projectId, partitionId }) => {
    const { axiosService } = useDic();
    const fetchData = params =>
        axiosService
            .post(
                `/api/projects/${projectId}/partition-assets/${partitionId}/elastic-search-dataload`,
                params,
            )
            .then(getData);

    return (
        <ElasticSearchPanel
            fetchData={fetchData}
            accountId={projectId}
            entityId={partitionId}
            entityType={EntityType.PARTITION}
            sessionStateName={DM_LOGS_PAGE}
            exportUrl={`/api/projects/${projectId}/partition-assets/${partitionId}/elastic-search-dataload/export`}
        />
    );
};
