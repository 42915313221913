import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import useToggle from "@/components/hooks/useToggle.hook";
import {
    LoadablePropType,
    LoadableRenderer,
    responseErrorMessage,
} from "@/modules/loadable";
import { t } from "@/translations";
import { advancedFilterHooks } from "@pricefx/unity-components";
import { createAdvancedFilterFromAPI } from "@pricefx/unity-components/dist/es/components/AdvancedFilter/utils/filters/filters";
import { Spin } from "antd";
import { uniq } from "lodash";
import { flatMap, map, pipe } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Text } from "..";
import { L18NProvider } from "../../../translations/unityTranslations";
import { createTranslations } from "../../../translations/unityTranslations/createTranslations";
import en_gb from "../../../translations/unityTranslations/unity.en.json";
import AdvancedFilter, {
    convertAdvancedFilterToAPIFormat,
    useAdvancedFilter,
} from "../../AdvancedFilter";

const createFieldKey = name => ({
    name,
    label: name,
    type: "TEXT",
});
const extractFieldNamesRecursive = node =>
    !node.criteria
        ? node.fieldName
        : flatMap(extractFieldNamesRecursive, node.criteria);

export const extractFieldKeys = value => {
    try {
        return pipe(
            JSON.parse.bind(JSON),
            extractFieldNamesRecursive,
            uniq,
            map(createFieldKey),
        )(value || "[]");
    } catch (error) {
        console.error("Error in extractFieldKeys", { value, error });
        return [];
    }
};

const FilterEmbedded = ({
    value,
    // withFreeTextFields, // TODO: useAdvancedFilter
    onChange,
    onBlur,
    fieldKeys,
}) => {
    const initialValueRef = useRef(value);
    const rules = useMemo(() => {
        // when value is part of data flow, causes "value" field blur on each keypress
        const value = initialValueRef.current;
        if (!value) return undefined;
        try {
            const rules = createAdvancedFilterFromAPI(JSON.parse(value));
            // console.log("[PfxEntityAdvancedFilter.rules]", { value, parsed: JSON.parse(value), rules, });
            return rules;
        } catch (err) {
            console.log(err);
            return undefined;
        }
    }, []);
    // }, [value]); // when value is part of data flow, causes "value" field blur on each keypress

    const handleChange = useCurrentHandler(rules => {
        const newRules = JSON.stringify(
            convertAdvancedFilterToAPIFormat(rules),
        );
        const newValue = newRules === undefined ? null : newRules; // undefined value omits key in form values, making it impossible to remove filter
        // console.log("[PfxEntityAdvancedFilter.handleChange]", { rules, newRules, value, newValue, });
        onChange(newValue);
        onBlur?.();
    });

    // const { modalProps, freeTextProps } = useAdvancedFilter({ fieldKeys, modalVisible: false, onModalClose: debug, onModalOpen: debug, onSet: debug, rules, withFreeTextFields, });
    // console.log("[PfxEntityAdvancedFilter.rndr]", { value, fieldKeys, rules, });

    const [modalRules, rulesActions] = advancedFilterHooks.useFilterModalState(
        rules,
        // modalProps.rules,
    );
    useEffect(() => {
        const filtered = advancedFilterHooks.clearEmptyNodes(modalRules);
        handleChange(filtered);
    }, [modalRules, handleChange]);

    // useLogChanged("[PfxEntityAdvancedFilter.useLogChanged]", { value, fieldKeys, rules, handleChange, modalRules, rulesActions, });
    // useLogChanged( "[PfxEntityAdvancedFilter.useLogChanged.modalProps]", modalRules, );

    return (
        <AdvancedFilter.Filter
            rulesActions={rulesActions}
            rules={modalRules}
            actions={{
                fetchDataSource: undefined,
            }}
            fieldKeys={fieldKeys}
            // {...freeTextProps}
            // freeTextFieldProps={freeTextFieldProps}
            // enableFreeTextRuleValue={enableFreeTextRuleValue}
        />
    );
};

const FilterInModal = ({
    value,
    withFreeTextFields,
    onChange,
    onBlur,
    fieldKeys,
}) => {
    const [isFilterModalVisible, { setOn, setOff }] = useToggle();
    const rules = useMemo(() => {
        if (!value) return undefined;
        try {
            const rules = createAdvancedFilterFromAPI(JSON.parse(value));
            return rules;
        } catch (err) {
            console.log(err);
            return undefined;
        }
    }, [value]);

    function handleChange(rules) {
        const value = JSON.stringify(convertAdvancedFilterToAPIFormat(rules));
        onChange(value);
        onBlur?.();
    }

    const { modalProps, openButtonProps, statusPanelProps } = useAdvancedFilter(
        {
            fieldKeys,
            modalVisible: isFilterModalVisible,
            onModalClose: setOff,
            onModalOpen: setOn,
            onSet: handleChange,
            rules,
            withFreeTextFields,
        },
    );

    return (
        <>
            <AdvancedFilter.StatusPanel {...statusPanelProps} />
            {value === undefined && (
                <AdvancedFilter.OpenButton
                    {...openButtonProps}
                    label={t("general.advanced-filter")}
                />
            )}
            <AdvancedFilter.Modal {...modalProps} />
        </>
    );
};

export const PfxEntityAdvancedFilter = ({
    fieldKeys: fieldKeysProp,
    value: _value,
    onChange,
    onBlur,
    withFreeTextFields = !fieldKeysProp,
    embedded = false,
}) => {
    const value = _value === null ? undefined : _value;
    const fieldKeys = useMemo(
        // in freetext mode, fieldKeys are used only initially by UC useAdvancedFilter, changes among subsequent renders are not reflected!
        () => (!withFreeTextFields ? fieldKeysProp : extractFieldKeys(value)),
        [withFreeTextFields, fieldKeysProp, value],
    );

    if (embedded)
        return (
            <ErrorBoundary
                fallbackRender={e => {
                    console.error("PfxEntityAdvancedFilter", e);
                    return (
                        <Text type="danger">
                            Error in PfxEntityAdvancedFilter: {e.message}
                        </Text>
                    );
                }}
            >
                <FilterEmbedded
                    {...{
                        value,
                        // withFreeTextFields, // TODO
                        onChange,
                        onBlur,
                        fieldKeys,
                    }}
                />
            </ErrorBoundary>
        );
    else
        return (
            <FilterInModal
                {...{
                    value,
                    withFreeTextFields,
                    onChange,
                    onBlur,
                    fieldKeys,
                }}
            />
        );
};

PfxEntityAdvancedFilter.propTypes = {
    value: PropTypes.string,
    fieldKeys: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    withFreeTextFields: PropTypes.bool,
};

const translations = createTranslations("en_gb", {
    en_gb: {
        ...en_gb,
        unity_pa_component_Select_nodata:
            "Add new option and hit enter or click on label",
    },
});

export const FreetextPfxEntityAdvancedFilter = props => {
    return (
        <L18NProvider translations={translations}>
            <PfxEntityAdvancedFilter withFreeTextFields {...props} />
        </L18NProvider>
    );
};

export const LoadablePfxEntityAdvancedFilter = ({
    fieldKeysLoadable,
    ...props
}) => {
    return (
        <LoadableRenderer
            loadable={fieldKeysLoadable}
            loading={() => <Spin tip="Loading" size="small" />}
            hasValue={fieldKeys =>
                !fieldKeys ? null : (
                    <PfxEntityAdvancedFilter fieldKeys={fieldKeys} {...props} />
                )
            }
            hasError={error => (
                <Text type="error">
                    Can't fetch metadata: {responseErrorMessage(error)}
                </Text>
            )}
        />
    );
};

LoadablePfxEntityAdvancedFilter.propTypes = {
    fieldKeysLoadable: LoadablePropType(PropTypes.array),
};
