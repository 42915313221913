import { Forms } from "@/components/DesignSystem";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { t } from "@/translations";
import * as React from "react";

export const InstanceSelectForm = ({
    instancesOptionsResource,
    formId,
    handleSubmit,
}) => {
    return (
        <Forms.Form formId={formId} onSubmit={handleSubmit}>
            <Forms.FieldGroup width="max" inputWidth="max">
                <Forms.Fields.Select
                    required
                    name="instanceId"
                    label={t("instanceSelectForm.instance.label")}
                    placeholder={t("instanceSelectForm.instance.placeholder")}
                    allowClear={false}
                    showSearch
                    validator={Forms.pmValidators.isRequired}
                    {...getLoadableSelectProps(
                        instancesOptionsResource.loadable,
                    )}
                />
            </Forms.FieldGroup>
        </Forms.Form>
    );
};
