import { useMemo } from "react";

export const useTableExampleDataToOptions = (
    tableExampleData,
    treeInputOptions,
) =>
    useMemo(
        () =>
            treeInputOptions ||
            tableExampleData.columns.map(column => ({
                label: column.name,
                value: column.name,
            })),
        [tableExampleData, treeInputOptions],
    );
