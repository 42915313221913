import { isSkippable } from "@/components/PageLayout/package-steps.utils";
import { wait } from "@/utils/timers.utils";
import { produce } from "immer";
import { packageService } from "../../../../views/Package/package.service";
import {
    deploymentIsRunning,
    finishedWithError,
    packageFinished,
    waitingForUi,
} from "../../../../views/Package/package.utils";
import {
    deployPackageStateEnum,
    inInitState,
    inStepIsRunningState,
} from "./deployPackageState.enum";

const checkDeploymentStateInterval = 1000;

const initialState = {
    deployPackageState: deployPackageStateEnum.init,
    steps: [],
    currentStepIndex: 0,
    deploymentState: {},
    progressInPercent: 5,
    isPackageRunning: false,
};

export const deployPackage = {
    state: initialState,
    reducers: {
        resetState: () => initialState,
        setUiSteps: produce((state, steps) => {
            state.steps = steps;
        }),
        setDeploymentState: produce((state, deploymentState) => {
            const originalDeploymentState = state.deploymentState;

            state.deploymentState = deploymentState;

            if (
                shouldIncrementProgress(
                    originalDeploymentState.log,
                    deploymentState.log,
                )
            ) {
                state.progressInPercent += 5;
            }

            const stepKey = generateStepKey(
                deploymentState.currentStep,
                deploymentState.processState,
            );

            if (state.stepKey === stepKey) {
                return;
            }

            state.stepKey = stepKey;

            // the process is resumed and we need to find and set current step
            if (deploymentState.currentStep) {
                state.deployPackageState =
                    deployPackageStateEnum.stepDefinition;

                const stepAndIndex = findStepByName(
                    state.steps,
                    deploymentState.currentStep,
                );

                if (stepAndIndex.index >= 0) {
                    state.currentStepIndex = stepAndIndex.index;
                }
            }

            if (finishedWithError(deploymentState.processState)) {
                state.deployPackageState = deployPackageStateEnum.error;
                state.progressInPercent = 100;
            } else if (packageFinished(deploymentState.processState)) {
                state.deployPackageState =
                    deployPackageStateEnum.packageDeployed;
            } else if (waitingForUi(deploymentState.processState)) {
                state.progressInPercent = 0;
                if (isSkippable(deploymentState.currentStepData)) {
                    state.deployPackageState =
                        deployPackageStateEnum.stepSkipDecision;
                } else {
                    state.deployPackageState =
                        deployPackageStateEnum.stepDefinition;
                }
            } else if (deploymentIsRunning(deploymentState.processState)) {
                state.deployPackageState = deployPackageStateEnum.stepIsRunning;
            }
        }),
        goToNextStep: produce(state => {
            state.deployPackageState = deployPackageStateEnum.stepDefinition;
            state.progressInPercent = initialState.progressInPercent;
        }),
        stepIsRunning: produce(state => {
            state.deployPackageState = deployPackageStateEnum.stepIsRunning;
            state.progressInPercent = initialState.progressInPercent;
        }),
        setPackageIsRunning: produce((state, isRunning) => {
            state.isPackageRunning = isRunning;
        }),
    },
    effects: {
        async initDeploymentProcess({ packageName, partitionId, instanceId }) {
            this.resetState();
            this.setPackageIsRunning(true);
            // we need to call the deployment state check function AFTER the ui steps
            // are really set
            await this.getUiSteps({
                packageName,
                partitionId,
                instanceId,
            }).then(() =>
                this.checkDeploymentStatePeriodically({
                    packageName,
                    partitionId,
                    instanceId,
                }),
            );
        },
        async restartCurrentStepInDeploymentProcess({
            packageName,
            partitionId,
            instanceId,
        }) {
            this.resetState();
            this.setPackageIsRunning(true);

            await packageService.restartDeploymentStep(
                packageName,
                partitionId,
                instanceId,
            );

            await this.getUiSteps({
                packageName,
                partitionId,
                instanceId,
            }).then(() =>
                this.checkDeploymentStatePeriodically({
                    packageName,
                    partitionId,
                    instanceId,
                }),
            );
        },
        async getUiSteps({ packageName, partitionId, instanceId }) {
            const steps = await packageService.getUiSteps(
                packageName,
                partitionId,
                instanceId,
            );

            this.setUiSteps(steps);
        },
        async checkDeploymentStatePeriodically(
            { packageName, partitionId, instanceId },
            { deployPackage },
        ) {
            await wait(checkDeploymentStateInterval);

            if (
                !shouldCheckForDeploymentState(deployPackage.deployPackageState)
            ) {
                return;
            }

            const deploymentState = await packageService.getDeploymentState(
                packageName,
                partitionId,
                instanceId,
            );

            this.setDeploymentState(deploymentState);

            this.checkDeploymentStatePeriodically({
                packageName,
                partitionId,
                instanceId,
            });
        },
        async saveStepData({
            stepId,
            packageName,
            partitionId,
            instanceId,
            data,
        }) {
            this.stepIsRunning();

            await packageService.addDataToStep(
                packageName,
                partitionId,
                instanceId,
                stepId,
                data,
            );

            this.checkDeploymentStatePeriodically({
                packageName,
                partitionId,
                instanceId,
            });
        },
    },
};

function shouldCheckForDeploymentState(deployPackageState) {
    return (
        inInitState(deployPackageState) ||
        inStepIsRunningState(deployPackageState)
    );
}

function shouldIncrementProgress(log, newLog) {
    if (!newLog) {
        return false;
    }

    return log && log.length < newLog.length;
}

function findStepByName(steps, currentStepName) {
    const index = steps.findIndex(step => step.id === currentStepName);

    const step =
        index !== -1 ? steps[index] : createStepObject(currentStepName);

    return {
        index,
        step,
    };
}

function createStepObject(stepName) {
    return {
        type: stepName,
    };
}

function generateStepKey(currentStepName, processState) {
    return currentStepName + "_" + processState;
}
