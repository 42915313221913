import { useMemoBy } from "@/components/hooks/useMemoBy.hook";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { DislocatedPreferencesDropdown } from "./DislocatedPreferencesDropdown";
import { useTablePreferences } from "./hooks/useTablePreferences.hook";
import { NewPreferenceModal } from "./NewPreferenceModal";
import { RecordDetailPanel } from "./RecordDetailPanel";
import { TableUsingPreference } from "./TableUsingPreference";
import { PREFERENCES_CHANGE_ACTION, useTrackPreferences } from "./tracking";

export const TableWithPreferencesManagementComponent = ({
    preferences,
    newPreferenceModal,
    managePreferencesModal,
    preferencesType,
    selectedPreference,
    onCreated,
    onSavePreference,
    onPreferenceSelect,
    onResetPreference,

    changedPreference,
    onPreferenceChange,
    columns: columnsProp,
    pagination: paginationProp,
    sorter: sorterProp,
    defaultSort, // TODO
    defaultFilter,

    visible,
    renderPanelTitle,
    panelControl,
    renderPanelHeader,
    renderPanelExtra,

    allExpanded: propAllExpanded,

    DropdownMenuComponent = DislocatedPreferencesDropdown,

    ...props
}) => {
    const hasPanel = !!panelControl;
    const memoizedSorter = useMemoBy(sorterProp || defaultSort);
    const {
        columns,
        setColumns,
        pagination,
        onPaginationChange,
        sorter,
        onSortChange,
        filter,
        onFilterChange,
        panelColumns,
        setPanelColumns,
        allExpandedPreference,
        onAllExpandedChange,
    } = useTablePreferences(
        selectedPreference,
        {
            columns: columnsProp,
            pagination: paginationProp,
            sorter: memoizedSorter,
        },
        changedPreference,
        onPreferenceChange,
        hasPanel,
    );
    const trackPreferences = useTrackPreferences();
    const setPanelColumnsTracked = useCallback(
        (...args) => {
            trackPreferences(
                PREFERENCES_CHANGE_ACTION.SET_PANEL_FIELDS,
                preferencesType,
            );
            return setPanelColumns(...args);
        },
        [setPanelColumns, preferencesType, trackPreferences],
    );
    const setColumnsTracked = useCallback(
        (...args) => {
            trackPreferences(
                PREFERENCES_CHANGE_ACTION.SET_TABLE_COLUMNS,
                preferencesType,
            );

            return setColumns(...args);
        },
        [setColumns, preferencesType, trackPreferences],
    );

    return (
        <>
            <NewPreferenceModal
                visible={newPreferenceModal.visible}
                onCreated={onCreated}
                onCancel={newPreferenceModal.hide}
                changedPreference={changedPreference}
                selectedPreference={selectedPreference}
                preferencesType={preferencesType}
            />

            <DropdownMenuComponent
                preferencesType={preferencesType}
                preferences={preferences}
                changedPreference={changedPreference}
                selectedPreference={selectedPreference}
                onPreferenceSelect={onPreferenceSelect}
                onSavePreference={onSavePreference}
                onAddPreference={newPreferenceModal.show}
                openPreferenceManager={managePreferencesModal.show}
                onResetPreference={onResetPreference}
                columns={columns}
                setColumns={setColumnsTracked}
                panelColumns={hasPanel ? panelColumns : undefined}
                setPanelColumns={hasPanel ? setPanelColumnsTracked : undefined}
                visible={visible}
            />
            <TableUsingPreference
                {...props}
                columns={columns}
                setColumns={setColumnsTracked}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                sorter={sorter}
                onSortChange={onSortChange}
                filter={{ ...defaultFilter, ...filter }}
                onFilterChange={onFilterChange}
                allExpanded={
                    propAllExpanded
                        ? {
                              allExpandedPreference,
                              onAllExpandedChange,
                              isAllExpandedDefault:
                                  propAllExpanded?.isAllExpandedDefault,
                          }
                        : undefined
                }
            />
            {panelControl && (
                <RecordDetailPanel
                    renderPanelTitle={renderPanelTitle}
                    renderPanelHeader={renderPanelHeader}
                    renderPanelExtra={renderPanelExtra}
                    panelControl={panelControl}
                    panelColumns={panelColumns}
                />
            )}
        </>
    );
};

TableWithPreferencesManagementComponent.propTypes = {
    preferences: PropTypes.array.isRequired,
    newPreferenceModal: PropTypes.object.isRequired,
    managePreferencesModal: PropTypes.object.isRequired,
    preferencesType: PropTypes.string.isRequired,
    visible: PropTypes.bool,
    selectedPreference: PropTypes.object,
    onCreated: PropTypes.func.isRequired,
    onSavePreference: PropTypes.func.isRequired,
    onPreferenceSelect: PropTypes.func.isRequired,
    onResetPreference: PropTypes.func.isRequired,
    changedPreference: PropTypes.object,
    onPreferenceChange: PropTypes.func.isRequired,
    columns: PropTypes.array,
    pagination: PropTypes.object,
    sorter: PropTypes.object,
    defaultSort: PropTypes.object,
    DropdownMenuComponent: PropTypes.func,
    panelControl: PropTypes.shape({
        record: PropTypes.object,
        show: PropTypes.func.isRequired,
        hide: PropTypes.func.isRequired,
    }),
    renderPanelHeader: PropTypes.func,
    renderPanelExtra: PropTypes.func,
    allExpanded: PropTypes.object,
};
