import {
    ButtonMenu,
    Form,
    Input,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { T, t } from "@/translations";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const INIT_STATE = {
    name: undefined,
    description: undefined,
};

export const NewGroupModalComponent = ({
    visible,
    onOk,
    onCancel,
    group,
    title,
}) => {
    const [userGroup, setUserGroup] = useState(INIT_STATE);

    useEffect(() => {
        setUserGroup(group || INIT_STATE);
    }, [visible]);

    const onUpdateUserGroup = (value, fieldName) => {
        setUserGroup({
            ...userGroup,
            [fieldName]: value,
        });
    };

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={title || t("account-admin-groups-list.modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Form layout="vertical" hideRequiredMark>
                        <Form.Item
                            label={
                                <T id="account-admin-groups-list.modal.group-name" />
                            }
                            required
                            colon={false}
                        >
                            <Input
                                type="name"
                                value={userGroup.name}
                                onChange={event =>
                                    onUpdateUserGroup(
                                        event.target.value,
                                        "name",
                                    )
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                <T id="account-admin-groups-list.modal.group-description" />
                            }
                            colon={false}
                        >
                            <Input
                                type="description"
                                value={userGroup.description}
                                onChange={event =>
                                    onUpdateUserGroup(
                                        event.target.value,
                                        "description",
                                    )
                                }
                            />
                        </Form.Item>
                    </Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.save"),
                                    onClick: () => onOk(userGroup),
                                    type: "primary",
                                    disabled: !userGroup.name,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onCancel,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

NewGroupModalComponent.propTypes = {
    visible: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    group: PropTypes.object,
    okText: PropTypes.string,
    title: PropTypes.string,
};
