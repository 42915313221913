import { Button, Image, P } from "@/components/DesignSystem";
import { HEADER_WITH_PAGE_TITLE } from "@/components/DesignSystem/Table/constants";
import { FEATURE_FLAGS_EDIT } from "@/security/permission.utils";
import { t } from "@/translations";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import { get } from "lodash/fp";
import React from "react";
import featureFlagPlaceholderIcon from "../../assets/img/feature-flags-add-icon.png";
import { FeatureFlags } from "./FeatureFlags.component";

const fetcher = (axiosService, { projectId, partitionId, configurationName }) =>
    axiosService
        .get(
            `/api/partition-assets/${partitionId}/feature-flags/${configurationName}`,
        )
        .then(get("data"));

const getEmptyScreenComponent = ({ onAddPartition }) => (
    <div className="pmFeatureFlags-emptyScreen">
        <Image alt="" src={featureFlagPlaceholderIcon} />
        <P>{t("feature-flags.add-partition.desc")}</P>
        <Button
            type="primary"
            label={t("feature-flags.add-partition.button")}
            onClick={onAddPartition}
            icon={Plus}
        />
    </div>
);

const getBreadcrumbButtons = ({
    submit,
    loading,
    areFeatureFlagsLoaded,
    onAddPartition,
}) =>
    areFeatureFlagsLoaded
        ? [
              {
                  label: "feature-flags.apply-settings",
                  onClick: submit,
                  permissions: [FEATURE_FLAGS_EDIT],
                  config: {
                      disabled: loading,
                  },
              },

              {
                  label: "feature-flags.add-partition.button",
                  onClick: onAddPartition,
                  config: { icon: Plus },
              },
          ]
        : null;

export const AdminFeatureFlagsPage = () => {
    return (
        <FeatureFlags
            fetcher={fetcher}
            getBreadcrumbButtons={getBreadcrumbButtons}
            getEmptyScreenComponent={getEmptyScreenComponent}
            restHeight={HEADER_WITH_PAGE_TITLE}
        />
    );
};

AdminFeatureFlagsPage.propTypes = {};
