import {
    useCopyAlertRuleMutation,
    useServicesOptionsQuery,
} from "@/components/AlertRules/loadables";
import { alertRuleScope } from "@/components/AlertRules/services/alertRules.service";
import { useAlertRuleNameValidator } from "@/components/AlertRules/validators";
import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
const { useForm, Fields, pmValidators } = Forms;

const CopyAlertRuleModal = ({
    accountId,
    alertRuleId,
    scope,
    visible,
    onClose,
    onSuccess,
}) => {
    const servicesResource = useServicesOptionsQuery({
        accountId,
        canFetch: visible && accountId,
    });

    const copyAlertRuleMutation = useCopyAlertRuleMutation({
        afterSuccess: () => {
            onSuccess?.();
            onClose();
        },
    });

    const onSubmit = ({ values }) => {
        return copyAlertRuleMutation.mutate(alertRuleId, {
            name: values.name,
            entityId: values.service.split("|")[1],
            entityType: values.service.split("|")[0],
        });
    };

    const { formId, Form, handleSubmit } = useForm({
        onSubmit,
    });

    const loadableSelectProps = getLoadableSelectProps(
        servicesResource.loadable,
    );

    const nameValidator = useAlertRuleNameValidator({
        accountId,
        scope,
        alertRuleId,
    });

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header size={3} title={t("alerts-rules.copy")} />
                <UnityLayout.Content padding={[false, true]}>
                    <Form onSubmit={handleSubmit}>
                        <Fields.Input
                            autoFocus
                            label={t("alert-rules-list.header.name")}
                            name="name"
                            inputWidth="max"
                            required
                            validator={nameValidator}
                        />
                        <Fields.Select
                            label={t("alert-rules-form.label.service")}
                            name="service"
                            required
                            useRawOptions
                            validator={pmValidators.isRequired}
                            inputWidth="max"
                            {...loadableSelectProps}
                        />
                    </Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    formId,
                                    label: t("general.copy"),
                                    type: "primary",
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

CopyAlertRuleModal.propTypes = {
    accountId: PropTypes.number.isRequired,
    alertRuleId: PropTypes.number.isRequired,
    scope: PropTypes.oneOf(Object.keys(alertRuleScope)),
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
};

export default CopyAlertRuleModal;
