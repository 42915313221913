import { useDic } from "@/components/Dic/useDic.hook";
import { isProcessDone } from "@/components/Integrations/ImUpdate/helpers";
import { useSomeAsyncTasksLiveQuery } from "@/modules/async-tasks";
import { hasValue } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

const emptyTask = { id: -1, status: "", progress: 0 };
export const useDirectUpdate = ({ onError }) => {
    const { messageService, integrationManagerUpdateService } = useDic();
    const onSuccessRef = useRef();
    const [task, setTask] = useState(emptyTask);
    const maybeAsyncJobQuery = useSomeAsyncTasksLiveQuery({
        filterPredicate: useCallback(job => job.id === task.id, [task.id]),
    });

    useEffect(() => {
        if (hasValue(maybeAsyncJobQuery.loadable)) {
            const jobState = maybeAsyncJobQuery.loadable.valueMaybe();
            if (!isEmpty(jobState)) {
                const task = jobState?.[0];
                setTask(task);
                if (isProcessDone(task)) {
                    messageService.success({
                        content: t(
                            "im-instance.message.upgrade-successfully-finished",
                        ),
                    });
                    onSuccessRef.current?.();
                    setTask(emptyTask);
                }
            }
        }
    }, [maybeAsyncJobQuery.loadable, messageService]);

    const directUpdate = useCallback(
        ({ instanceId, versionTo, onSuccess }) => {
            onSuccessRef.current = onSuccess;

            integrationManagerUpdateService
                .directUpdate(instanceId, versionTo)
                .then(getData)
                .then(setTask)
                .catch(onError);
        },
        [integrationManagerUpdateService, onError],
    );

    return {
        update: directUpdate,
        task,
    };
};
