import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { DEFAULT_MINUTE_STEP } from "@/components/UpgradeScheduling/CapacityEntry";
import { TIME_HOURS_MINUTES } from "@/components/UpgradeScheduling/date-formats";
import { t } from "@/translations";
import { fromDuration, toDuration } from "@/utils/duration";
import PropTypes from "prop-types";
import React from "react";

const { useForm, Form, Fields, validators, pmValidators } = Forms;

const UPGRADE_TYPES = ["MINOR", "MAJOR"];

export const getDisabledMinutes = selectedHour =>
    selectedHour === 0 ? [0] : [];

export const getPlaceholder = value =>
    t("general.field.placeholder", {
        fieldName: t(value).toLowerCase(),
    });

export const PartitionUpgradesModalComponent = ({
    visible,
    onOk,
    onCancel,
    initialValues: entity,
    title,
    confirmText,
    data,
}) => {
    const isEditMode = !!entity;
    const getUpgradeTypeOptions = () => {
        const existingUpgradeTypes = data?.map(p => p.upgradeType);

        if (isEditMode) {
            return existingUpgradeTypes?.length === 1
                ? UPGRADE_TYPES
                : [entity.upgradeType];
        } else {
            return UPGRADE_TYPES.filter(
                p => !existingUpgradeTypes?.includes(p),
            );
        }
    };

    const { formId, handleSubmit } = useForm({
        onSubmit: ({ values }) =>
            onOk({
                id: isEditMode ? entity.id : undefined,
                ...values,
                duration: toDuration(values.duration),
            }),
    });

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={title || t("partition-upgrade.create.modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Form
                        formId={formId}
                        layout="vertical"
                        onSubmit={handleSubmit}
                    >
                        <Fields.Select
                            required
                            inputWidth="max"
                            name="upgradeType"
                            initialValue={entity?.upgradeType}
                            label={t("partition-upgrade.upgradeType.label")}
                            options={getUpgradeTypeOptions().map(
                                upgradeType => ({
                                    label: upgradeType,
                                    value: upgradeType,
                                }),
                            )}
                            placeholder={t(
                                "partition-upgrade.upgradeType.label",
                            )}
                            showSearch
                            validator={pmValidators.isRequired}
                        />

                        <Fields.TimePicker
                            required
                            inputWidth="max"
                            label={t("partition-upgrade.duration.label")}
                            name="duration"
                            format={TIME_HOURS_MINUTES}
                            initialValue={fromDuration(entity?.duration)}
                            placeholder={getPlaceholder(
                                "partition-upgrade.duration",
                            )}
                            showNow={false}
                            minuteStep={DEFAULT_MINUTE_STEP}
                            disabledMinutes={getDisabledMinutes}
                            inputReadOnly={true}
                            validator={validators.failOnFirst([
                                pmValidators.isRequired,
                                pmValidators.durationMinimalMinutes(
                                    DEFAULT_MINUTE_STEP,
                                ),
                            ])}
                        />
                    </Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: confirmText || t("general.save"),
                                    type: "primary",
                                    formId,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onCancel,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

PartitionUpgradesModalComponent.propTypes = {
    visible: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    entity: PropTypes.object,
    okText: PropTypes.string,
    title: PropTypes.string,
    confirmText: PropTypes.string,
};
