import { marketplaceItemsApiSchema } from "./MarketplaceApiSchema";

export class MarketplaceItemsApiService {
    baseUrl = "api/marketplace-items";
    /**
     *
     * @type {HttpService}
     */
    httpService = null;
    /**
     *
     * @type {TemplatesApiMapper}
     */
    templatesApiMapper = null;

    constructor({ httpService, templatesApiMapper }) {
        this.httpService = httpService;
        this.templatesApiMapper = templatesApiMapper;
    }

    /**
     * fetches all marketplace items for given type
     *
     * @param {string} type
     * @param {number} accountId
     * @returns {Promise<TemplateEntity[]>}
     */
    getMarketplaceItemsByType(type, accountId) {
        return this.httpService.get(this.baseUrl, {
            schema: marketplaceItemsApiSchema,
            mapper: this.templatesApiMapper,
            httpOptions: {
                params: {
                    type,
                    accountId,
                },
            },
        });
    }
}
