import PropTypes from "prop-types";
import { durationPrecisely } from "./helpers/durationPrecisely";

const FormattedDuration = ({ children: value, format, locale = "en" }) => {
    if (value === null) {
        return value;
    }
    return durationPrecisely({ value, format, locale });
};

FormattedDuration.propTypes = {
    format: PropTypes.string,
    locale: PropTypes.string,
};

export default FormattedDuration;
