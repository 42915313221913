import { Button, Gap, H3, Link, Table, Text } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { updateStep } from "@/components/Integrations/ImUpdate/ImUpdate.component";
import {
    MINUTE,
    STEPS_ESTIMATIONS_SECONDS,
} from "@/components/Integrations/ImUpdate/constants";
import {
    isProcessDone,
    isProcessRunning,
    useStepErrorHandling,
} from "@/components/Integrations/ImUpdate/helpers";
import { useIntegrationUpdateStepState } from "@/components/Integrations/ImUpdate/loadables";
import Progress from "@/components/Progress/Progress";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { downloadFile } from "@/utils/downloadUtils";
import { getErrorMessage } from "@/utils/state/error.utils";
import { ReactComponent as AngleRight } from "@pricefx/unity-components/src/icons/unicons/angle-right-b.svg";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styles from "../styles.less";

export const TestUpdateStep = ({
    instanceId,
    process,
    onNextStep,
    onReTest,
    onBack,
    onCancel,
    wasAlreadyCompleted = false,
}) => {
    const { integrationManagerUpdateService, messageService } = useDic();

    const modificationResource = useIntegrationUpdateStepState({
        instanceId,
        step: "modification",
        canFetch: true,
    });

    const testResource = useIntegrationUpdateStepState({
        instanceId,
        step: "test",
        canFetch: isProcessDone(process),
    });

    const testResult = testResource.loadable.valueMaybe();
    useStepErrorHandling(testResult);

    return (
        <>
            {isProcessRunning(process) ? (
                <>
                    <Text size="large">
                        {t("im-instance.update.test-update.in-progress", {
                            minutes:
                                STEPS_ESTIMATIONS_SECONDS.TEST_UPDATE / MINUTE,
                        })}
                    </Text>
                    <Progress
                        className={styles.progress}
                        showInfo
                        percent={process?.progress}
                        message={t("im-instance.update.test-update.progress")}
                    />
                </>
            ) : isProcessDone(process) ? (
                <>
                    <H3>{t("im-instance.update.test-update.test-result")}</H3>
                    <Text>
                        {t(
                            "im-instance.update.test-update.test-result.success",
                        )}
                    </Text>
                    <Gap size="medium" />
                </>
            ) : null}

            <>
                {!isEmpty(testResult?.results) ? (
                    <>
                        <Table
                            className="pmTable--tallCells"
                            rowKey="id"
                            padding={false}
                            dataSource={testResult.results}
                            columns={[
                                {
                                    type: fieldTypes.TEXT,
                                    label: t(
                                        "im-instance.update.test-update.column.test-type",
                                    ),
                                    name: "scrapingType",
                                },
                                {
                                    type: fieldTypes.TEXT,
                                    label: t(
                                        "im-instance.update.test-update.column.test-result",
                                    ),
                                    name: "result",
                                    render: result => (
                                        <Text
                                            size="small"
                                            type={
                                                result === "OK"
                                                    ? "success"
                                                    : "warning"
                                            }
                                        >
                                            {result}
                                        </Text>
                                    ),
                                },
                                {
                                    type: fieldTypes.TEXT,
                                    label: t("general.download"),
                                    name: "log",
                                    render: (_, record) => (
                                        <>
                                            <Link
                                                onClick={() =>
                                                    downloadFile(
                                                        record.resultRaw,
                                                        `${
                                                            record.scrapingType?.toLowerCase() ??
                                                            "record"
                                                        }-log.json`,
                                                    )
                                                }
                                            >
                                                {t("general.download")}
                                            </Link>
                                        </>
                                    ),
                                },
                            ]}
                        />
                        <H3>{t("im-instance.update.continue-to-next-step")}</H3>
                        <Text size="large">
                            {t(
                                "im-instance.update.test-update.continue-to-next-step-perex",
                            )}
                        </Text>
                        <Gap size="medium" />
                    </>
                ) : null}
                <Gap size="medium" />

                <Button
                    type="primary"
                    label={t("im-instance.update.button.start-update")}
                    data-test="start-update-button"
                    rightIcon={AngleRight}
                    onClick={() => {
                        if (!wasAlreadyCompleted) {
                            integrationManagerUpdateService
                                .finishUpdate(instanceId)
                                .then(getData)
                                .then(onNextStep(updateStep.FINISH_UPDATE));
                        } else {
                            onNextStep(updateStep.FINISH_UPDATE)();
                        }
                    }}
                    disabled={isProcessRunning(process)}
                />
                <Button
                    label={t("im-instance.update.button.re-test-update")}
                    data-test="re-test-update-button"
                    onClick={() => {
                        integrationManagerUpdateService
                            .startTest(instanceId)
                            .then(getData)
                            .then(onReTest)
                            .catch(e =>
                                messageService.error({
                                    content: getErrorMessage(e.response.data),
                                }),
                            );
                    }}
                    disabled={isProcessRunning(process)}
                />
                <Button
                    label={t("general.back")}
                    data-test="back-button"
                    disabled={isProcessRunning(process)}
                    onClick={() =>
                        onBack(
                            isEmpty(modificationResource.loadable.valueMaybe())
                                ? updateStep.CODE_ANALYSIS
                                : updateStep.CODE_MODIFICATION,
                        )
                    }
                />
                <Button
                    type="link"
                    label={t("general.cancel")}
                    data-test="cancel-button"
                    onClick={onCancel}
                />
            </>
        </>
    );
};

TestUpdateStep.propTypes = {
    instanceId: PropTypes.number.isRequired,
    process: PropTypes.object.isRequired,
    onNextStep: PropTypes.func.isRequired,
    onReTest: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    wasAlreadyCompleted: PropTypes.bool,
};
