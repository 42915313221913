import { Button } from "@/components/DesignSystem/Button";
import { ButtonGroup } from "@/components/DesignSystem/ButtonGroup";
import { T } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

export function GoToNextStepButton({ onGoToNextStep }) {
    return (
        <ButtonGroup>
            <Button
                type="primary"
                onClick={onGoToNextStep}
                label={<T id="package.process.continue" />}
            />
        </ButtonGroup>
    );
}

GoToNextStepButton.propTypes = {
    onGoToNextStep: PropTypes.func.isRequired,
};
