import { isProduction } from "../utils/env.utils";

const TOKEN_DEV = "54c2a3bfcead97863d852e5ffd59f7de";
const TOKEN_PROD = "2af25123ffc06b36f20d8273d3b9ebd7";

const PFX_MIXPANEL_ENABLED_IN_DEV = "PFX_MIXPANEL_ENABLED_IN_DEV";
const getMixpanelEnabledInDev = () =>
    !!window.localStorage.getItem(PFX_MIXPANEL_ENABLED_IN_DEV);
const setMixpanelEnabledInDev = enabled =>
    enabled
        ? window.localStorage.setItem(PFX_MIXPANEL_ENABLED_IN_DEV, 1)
        : window.localStorage.removeItem(PFX_MIXPANEL_ENABLED_IN_DEV);

window.setTrackingEnabled = setMixpanelEnabledInDev;

export const mixpanelDisabled = () =>
    !isProduction() && !getMixpanelEnabledInDev();

export const getMixpanelToken = () => {
    if (isProduction()) {
        return TOKEN_PROD;
    }
    return TOKEN_DEV;
};

export const getMixpanelConfiguration = () => ({
    api_host: "https://mp-ux.pricefx.eu",
    app_host: "https://mixpanel.com",
    autotrack: true,
    cdn: "https://cdn.mxpnl.com",
    cross_subdomain_cookie: true,
    persistence: "cookie",
    persistence_name: "",
    cookie_name: "",
    loaded: function loaded() {},
    store_google: true,
    save_referrer: true,
    test: false,
    verbose: false,
    img: false,
    track_pageview: false,

    debug: getMixpanelEnabledInDev(),

    track_links_timeout: 300,
    cookie_expiration: 365,
    upgrade: false,
    disable_persistence: false,
    disable_cookie: false,
    secure_cookie: false,
    ip: true,
    opt_out_tracking_by_default: false,
    opt_out_persistence_by_default: false,
    opt_out_tracking_persistence_type: "localStorage",
    opt_out_tracking_cookie_prefix: null,
    property_blacklist: [],
    xhr_headers: {},
    // { header: value, header2: value }
    inapp_protocol: "//",
    inapp_link_new_window: false,
});
