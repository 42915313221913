import { axiosService } from "../axios";

export const templatesReplacementService = {
    getSteps: templateName =>
        axiosService.get(`/api/template-replacements/${templateName}/steps`),
    getStepContent: (templateName, stepName) =>
        axiosService.get(
            `/api/template-replacements/${templateName}/steps/${stepName}`,
        ),
    setStepContentReplacement: (templateName, stepName, values) =>
        axiosService.post(
            `/api/template-replacements/${templateName}/steps/${stepName}`,
            values,
        ),
};
