import React from "react";
import { AbstractConstantComponent } from "../Constants/AbstractConstant.component";
import { ReleaseVersionsModalComponent } from "../../../../views/Settings/ReleaseVersions/ReleaseVersionsModal.component";
import { toVersionNumber } from "@/utils/versionUtils";

export function ReleaseVersionsPage() {
    const type = "release-version";

    const sortReplacementFunc = sort =>
        sort?.includes("releaseNumber")
            ? toVersionNumber(sort, "releaseNumber", "normalizedReleaseNumber")
            : sort;

    return (
        <AbstractConstantComponent
            type={type}
            deleteProperty="notes"
            modalComponent={ReleaseVersionsModalComponent}
            sortReplacementFunc={sortReplacementFunc}
        />
    );
}
