import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import PartitionsList from "@/components/Partitions/PartitionsList";
import React from "react";

const PartitionsPage = () => {
    useFullWidthTableLayout();
    return <PartitionsList />;
};

export default PartitionsPage;
