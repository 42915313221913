import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { debounceAsync } from "@/utils/promises/promise.utils";
import { get } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";

const { validators, pmValidators, success, error, useFormIsSubmitting } = Forms;

const FF_SET_NAME_VALIDATION_PATTERN = new RegExp(/^[\w\d\-<>_.!~*[\]']*$/);

export const ChangeSetModal = ({ modalProviderProps, onAdd }) => {
    const { axiosService } = useDic();
    const { accountId: projectId, partitionId } = useAccountAppParams();

    const { formId, handleSubmit } = Forms.useForm({
        onSubmit: async ({ values: { set: configurationName } }) => {
            onAdd({
                projectId,
                partitionId,
                configurationName,
            });
            modalProviderProps.onClose();
        },
    });

    const isSubmitting = useFormIsSubmitting(formId);

    const configurationNameExistsValidator = async configurationName => {
        const exists = await axiosService
            .get(
                `/api/projects/${projectId}/partition-assets/${partitionId}/feature-flags/${encodeURIComponent(
                    configurationName,
                )}/exists`,
            )
            .then(get("data"));
        return exists
            ? success()
            : error(
                  t(
                      "feature-flags.partition.change-set.validation.set-doesnt-exist",
                  ),
              );
    };

    return (
        <Modal {...modalProviderProps}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("feature-flags.partition.add-set.title")}
                />
                <UnityLayout.Content padding={[true, true]}>
                    <Forms.Form formId={formId} onSubmit={handleSubmit}>
                        <Forms.FieldGroup width="max" inputWidth="max">
                            <Forms.Fields.Input
                                name="set"
                                label={t(
                                    "feature-flags.add-partition.form.set.label",
                                )}
                                validator={validators.failOnFirst([
                                    pmValidators.isRequired,
                                    pmValidators.notStartsWith("."),
                                    pmValidators.createPatternValidator(
                                        FF_SET_NAME_VALIDATION_PATTERN,
                                        t(
                                            "feature-flags.partition.change-set.validation.invalid-character",
                                        ),
                                    ),
                                    debounceAsync(
                                        500,
                                        configurationNameExistsValidator,
                                    ),
                                ])}
                                tooltip={t(
                                    "feature-flags.partition.add-set.tooltip",
                                )}
                            />
                        </Forms.FieldGroup>
                    </Forms.Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.add"),
                                    formId,
                                    type: "primary",
                                    disabled: isSubmitting,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: modalProviderProps.onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
ChangeSetModal.propTypes = {
    onAdd: PropTypes.func.isRequired,
    modalProviderProps: PropTypes.object,
};
