import { useDic } from "@/components/Dic/useDic.hook";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { pendingPromise, useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import identity from "lodash/identity";
import PropTypes from "prop-types";
import React from "react";

import {
    Button,
    CancelButton,
    Forms,
    H5,
    Input,
    Select,
} from "@/components/DesignSystem";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { VisibleWhenHasPermission } from "@/security/authorization";
import { GROUP_MANAGEMENT_EDIT_PERMISSION } from "@/security/permission.utils";
import { t } from "@/translations";
import { isGroupNameValid } from "@/utils/validation";
import { usePostGroupResource } from "./loadables";

const FORM_TYPE = {
    CREATE: "CREATE",
    EDIT: "EDIT",
};

const groupNameValidator = Forms.validators.failOnFirst([
    Forms.pmValidators.notBlank("group-form.error.missing-name"),
    value =>
        isGroupNameValid(value)
            ? Forms.success()
            : Forms.error(t("group-form.error.wrong-group-name-format")),
]);

const labelValidator = Forms.pmValidators.notBlank(
    "group-form.error.missing-label",
);

export const useGroupForm = ({
    initialValues,
    formType = initialValues ? FORM_TYPE.EDIT : FORM_TYPE.CREATE,
    onCancel,
    afterSave = onCancel,
}) => {
    const postGroupResource = usePostGroupResource({
        afterSave,
        groupName:
            formType === FORM_TYPE.EDIT ? initialValues?.name : undefined,
    });

    const onSubmit = ({ values }) => {
        postGroupResource.mutate(values);
    };

    const form = Forms.useForm({
        onSubmit,
    });

    useSetValidatedInitialValues(
        {
            initialValues,
            setValues: form.setValues,
            setTouched: form.setTouched,
        },
        [initialValues],
    );

    const submit = (
        <VisibleWhenHasPermission permission={GROUP_MANAGEMENT_EDIT_PERMISSION}>
            <Forms.SubmitButton
                formId={form.formId}
                key="submit"
                withoutSpinner
            >
                <Button
                    htmlType="submit"
                    type="primary"
                    onClick={form.handleSubmit}
                    label={t("general.save")}
                />
            </Forms.SubmitButton>
        </VisibleWhenHasPermission>
    );
    const cancel = (
        <CancelButton onClick={onCancel} key="cancel">
            {t("general.cancel")}
        </CancelButton>
    );

    return { form, formType, submit, cancel, initialValues };
};

export const GroupForm = ({ form, formType, inputWidth }) => {
    const isADManaged = useFieldValue({
        formId: form.formId,
        name: "adManaged",
    });

    const { axiosService } = useDic();

    const activeDirectoryGroupsOptionsResource = useQueryLoadable(
        () =>
            isADManaged
                ? axiosService
                      .get("/api/admin/ad-groups")
                      .then(getData)
                      .then(groups =>
                          groups.map(groupName => ({
                              label: groupName,
                              value: groupName,
                          })),
                      )
                : pendingPromise(),
        [axiosService, isADManaged],
    );

    const loadableSelectProps = getLoadableSelectProps(
        activeDirectoryGroupsOptionsResource.loadable,
    );

    return (
        <Forms.Form formId={form.formId} layout="vertical">
            <Forms.FieldGroup inputWidth={inputWidth}>
                <H5>{t("group-form.tutke.ad-groups")}</H5>
                <Forms.Fields.Checkbox
                    name="adManaged"
                    label={t("group-form.label.ad-managed")}
                />

                <Forms.Field
                    name="name"
                    as={isADManaged ? Select : Input}
                    from={isADManaged ? identity : undefined}
                    inputWidth="max"
                    label={t("group-form.label.name")}
                    placeholder={
                        isADManaged
                            ? t("group-form.placeholder.ad-name")
                            : t("group-form.placeholder.name")
                    }
                    required={formType !== FORM_TYPE.EDIT}
                    disabled={formType === FORM_TYPE.EDIT}
                    validator={
                        isADManaged
                            ? Forms.pmValidators.isRequired
                            : formType === FORM_TYPE.CREATE
                            ? groupNameValidator
                            : undefined
                    }
                    description={
                        !isADManaged && formType === FORM_TYPE.CREATE
                            ? t("group-form.description.label")
                            : undefined
                    }
                    {...(isADManaged ? loadableSelectProps : {})}
                />

                <Forms.Fields.Input
                    type="text"
                    name="label"
                    label={t("group-form.label.label")}
                    placeholder={t("group-form.placeholder.label")}
                    required
                    validator={labelValidator}
                />
            </Forms.FieldGroup>
        </Forms.Form>
    );
};

GroupForm.propTypes = {
    form: PropTypes.shape({
        formId: PropTypes.string.isRequired,
    }),
    formType: PropTypes.oneOf(Object.values(FORM_TYPE)),
    inputWidth: PropTypes.string,
};
