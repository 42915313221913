import { Button, Forms } from "@/components/DesignSystem";
import {
    deserialize,
    serialize,
} from "@/components/DesignSystem/Forms/fieldsArray";
import MapperInputType from "@/components/Mappers/form/MapperInputType";
import { DeleteRowButton } from "@/components/Mappers/MapperTableWithCustomization/components/DeleteRowButton";
import { useOtherRowsFieldValues } from "@/components/Mappers/MapperTableWithCustomization/MapperGridForm/MapperRow";
import { InputType } from "@/components/Packages/PackageTableDefinitionPanel/constants";
import { t } from "@/translations";
import { ReactComponent as Wrench } from "@pricefx/unity-components/src/icons/unicons/wrench.svg";
import { isEmpty } from "lodash";
import equals from "lodash/fp/equals";
import pipe from "lodash/fp/flow";
import get from "lodash/fp/get";
import React, { useMemo } from "react";
import "./styles.less";

const converterButtonStyle = isConverterEmpty => {
    return isConverterEmpty ? "" : "pmConverterIconSet";
};

export const MAX_LENGTH_VALIDATION_PATTERN = new RegExp(/^[0-9]*$/);

export const ConvertorButton = ({
    converterExpression,
    isConverterEmpty = isEmpty(converterExpression),
    onConverterOpen,
    rowId,
    initialMaxLength,
}) => (
    <Button
        className={converterButtonStyle(isConverterEmpty)}
        icon={Wrench}
        onClick={() =>
            onConverterOpen({
                rowId,
                converterExpression,
                maxLength: initialMaxLength,
            })
        }
        tooltip={{
            title: t("general.converter-expression"),
        }}
    />
);

const MapperRow = ({
    formId,
    rowId,
    initialInput,
    initialMaxLength,
    initialOutput,
    initialInputType = InputType.BODY,
    converterExpression,
    readOnly = false,
    deleteRow,
    setInputType,
    onConverterOpen,
    canBeRemoved,
    isOptional,
}) => {
    // trigger uniqueness validation
    const otherThanThisRowOutputValues = useOtherRowsFieldValues(
        formId,
        rowId,
        "output",
    );
    const outputValidator = useMemo(() => {
        return Forms.validators.failOnFirst([
            ...(isOptional ? [] : [Forms.pmValidators.isRequired]),
            Forms.pmValidators.createUniqueValueValidation(
                pipe(get("name"), deserialize, get("name"), equals("output")),
                t("data-upload.mapper-table.validation-not-unique-output"),
            ),
        ]);
    }, [otherThanThisRowOutputValues, isOptional]);

    return (
        <Forms.FieldGrid.Row>
            <Forms.Fields.Input
                name={serialize(rowId, "input")}
                label={t("mapper-form.form.row.input")}
                initialValue={initialInput}
                validator={
                    isOptional ? undefined : Forms.pmValidators.isRequired
                }
                disabled={readOnly}
                addonBefore={
                    <MapperInputType
                        name={serialize(rowId, "inputType")}
                        initialValue={initialInputType}
                        disabled={readOnly}
                        onChange={({ value }) => {
                            setInputType(rowId, value);
                        }}
                    />
                }
                fieldSuffix={
                    <ConvertorButton
                        converterExpression={converterExpression}
                        onConverterOpen={onConverterOpen}
                        rowId={rowId}
                        initialMaxLength={initialMaxLength}
                    />
                }
            />

            <Forms.Fields.Input
                name={serialize(rowId, "output")}
                label={t("mapper-form.form.row.output")}
                initialValue={initialOutput}
                validator={outputValidator}
                disabled={readOnly}
            />
            <Forms.UIField name="actions">
                <DeleteRowButton
                    disabled={readOnly || !canBeRemoved}
                    onClick={() => deleteRow(rowId)}
                />
            </Forms.UIField>
        </Forms.FieldGrid.Row>
    );
};
