import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";
import { Property } from "../Property/Property.component";

const getGitHref = ({ gitCommit }) =>
    `https://gitlab.pricefx.eu/engineering/pricefx-core/-/commit/${gitCommit}`;
const getBuildHref = ({ buildId }) =>
    `https://gitlab.pricefx.eu/engineering/pricefx-core/-/jobs/${buildId}`;
const formatServerRoles = serverRoles => serverRoles.join(", ");
function msToTime(ms) {
    const MILLIS_IN_DAY = 86400000;
    if (ms >= MILLIS_IN_DAY) {
        const days = Math.floor(ms / MILLIS_IN_DAY);
        return days + " day" + (days > 1 ? "s" : "");
    } else {
        return new Date(ms).toISOString().slice(11, -5);
    }
}

export const columns = [
    {
        visibleInPanel: true,
        panelOrder: 2,
        type: fieldTypes.TEXT,
        label: t("nodes-list.header.host-name"),
        name: "hostName",
    },
    {
        type: fieldTypes.TEXT,
        label: t("nodes-list.header.node-name"),
        name: "nodeName",
        width: 150,
        className: "pmTable__column--withActionButton",
    },
    {
        visibleInPanel: true,
        panelOrder: 0,
        type: fieldTypes.TEXT,
        label: t("nodes-list.header.cluster-name"),
        name: "clusterName",
        render: (text, record) => (
            <Property.Value href={record.baseUrl}>
                {record.clusterName}
            </Property.Value>
        ),
    },
    {
        visibleInPanel: true,
        panelOrder: 1,
        type: fieldTypes.TEXT,
        label: t("nodes-list.header.location"),
        name: "location",
    },
    {
        type: fieldTypes.TEXT,
        label: t("nodes-list.header.release-name"),
        name: "releaseVersion",
        width: 150,
    },
    {
        visibleInPanel: true,
        panelOrder: 4,
        type: fieldTypes.TEXT,
        label: t("nodes-list.header.major-version"),
        name: "majorVersion",
        width: 150,
        visible: false,
    },
    {
        visibleInPanel: true,
        panelOrder: 6,
        type: fieldTypes.NUMBER,
        label: t("nodes-list.header.build-id"),
        name: "buildId",
        render: (text, record) => (
            <Property.Value href={getBuildHref(record)}>{text}</Property.Value>
        ),
    },
    {
        visible: false,
        visibleInPanel: true,
        panelOrder: 3,
        type: fieldTypes.TEXT,
        label: t("general.ip"),
        name: "ip",
    },
    {
        visible: false,
        visibleInPanel: true,
        panelOrder: 7,
        type: fieldTypes.TEXT,
        label: t("general.server-roles"),
        name: "serverRoles",
        render: (text, record) => <>{formatServerRoles(record.serverRoles)}</>,
        canFilter: false,
    },
    {
        visible: false,
        visibleInPanel: true,
        panelOrder: 8,
        type: fieldTypes.TEXT,
        label: t("general.uptime"),
        name: "uptime",
        render: (text, record) => <>{msToTime(record.uptime)}</>,
        canFilter: false,
    },
    {
        visible: false,
        visibleInPanel: true,
        panelOrder: 12,
        type: fieldTypes.TEXT,
        label: t("general.checkin-date"),
        name: "checkinDate",
        render: (text, record) => (
            <FormattedDateTime fromNow>{record.checkinDate}</FormattedDateTime>
        ),
        canFilter: false,
    },
    {
        visible: false,
        visibleInPanel: true,
        panelOrder: 5,
        type: fieldTypes.TEXT,
        label: t("general.git"),
        name: "gitCommit",
        render: (text, record) => (
            <Property.Value href={getGitHref(record)}>
                {record.gitCommit?.substring(0, 8)}
            </Property.Value>
        ),
    },
    {
        visible: false,
        visibleInPanel: true,
        panelOrder: 9,
        type: fieldTypes.TEXT,
        label: t("general.cloud-provider-account-Id"),
        name: "accountId",
    },
    {
        visible: false,
        visibleInPanel: true,
        panelOrder: 10,
        type: fieldTypes.TEXT,
        label: t("general.cloud-provider-availability-zone"),
        name: "availabilityZone",
    },
    {
        visible: false,
        visibleInPanel: true,
        panelOrder: 11,
        type: fieldTypes.TEXT,
        label: t("general.cloud-provider"),
        name: "cloudProvider",
    },
];
