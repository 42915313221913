import { ButtonMenu, Modal, UnityLayout } from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { getInConflictModalTrackName as getTrackName } from "../../../mixpanel/buttonNames";

const InConflictModal = ({ visible, onStartNewUpdate }) => {
    return (
        <Modal visible={visible}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("im-instance.update.in-conflict.modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    {t("im-instance.update.in-conflict.modal.message")}
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t(
                                        "im-instance.update.in-conflict.modal.action",
                                    ),
                                    type: "primary",
                                    onClick: onStartNewUpdate,
                                    track: {
                                        name: getTrackName("Start New Update"),
                                    },
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

InConflictModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onStartNewUpdate: PropTypes.func.isRequired,
};

export default InConflictModal;
