import { useDic } from "@/components/Dic/useDic.hook";
import {
    crudSuccess,
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { useReloadUserPermissions } from "@/security/hooks/useReloadUserPermissions.hook";

export const useGroupResource = ({ groupName, canFetch }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : axiosService
                      .get(`/api/admin/groups/${groupName}`)
                      .then(({ data }) => data),
        [groupName, canFetch, axiosService],
    );
};

export const usePostGroupResource = ({ afterSave, groupName }) => {
    const { axiosService } = useDic();
    return useMutationLoadableWithNotification(
        async values => {
            const name = values?.name?.trim();
            const label = values?.label?.trim();
            const { data } = await axiosService.post(
                `/api/admin/groups${groupName ? `/${groupName}` : ""}`,
                groupName
                    ? { label, adManaged: values.adManaged }
                    : { name, label, adManaged: values.adManaged },
            );
            afterSave(data);
            return { ...values, ...data };
        },
        [afterSave, axiosService, groupName],
        crudSuccess("Group", "name", !groupName ? "C" : "U"),
    );
};

export const useGroupAllowedUsersResource = ({ groupName, canFetch }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : axiosService
                      .get(`/api/admin/groups/${groupName}/allowed-users`)
                      .then(({ data }) => data),
        [groupName, canFetch, axiosService],
    );
};

export const useGroupUsersResource = ({ groupName, canFetch }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : axiosService
                      .get(`/api/admin/groups/${groupName}/users`)
                      .then(({ data }) => data),
        [groupName, canFetch, axiosService],
    );
};

export const useAddGroupUsersResource = ({ groupName, afterSuccess }) => {
    const { axiosService } = useDic();
    const reloadUserPermissions = useReloadUserPermissions();

    return useMutationLoadableWithNotification(
        async userIds => {
            const result = await Promise.all(
                userIds.map(userId =>
                    axiosService.post(`/api/admin/users/${userId}/groups`, {
                        groupNames: [groupName],
                    }),
                ),
            );
            reloadUserPermissions();
            afterSuccess?.(result);
            return result;
        },
        [groupName, axiosService, reloadUserPermissions, afterSuccess],
        "edit-group.users-added",
    );
};

export const useRemoveGroupUserResource = ({ groupName, afterSuccess }) => {
    const { axiosService } = useDic();
    const reloadUserPermissions = useReloadUserPermissions();

    return useMutationLoadableWithNotification(
        async userId => {
            const { data } = await axiosService.delete(
                `/api/admin/users/${userId}/groups/${groupName}`,
            );
            reloadUserPermissions();
            afterSuccess?.(data);
            return data;
        },
        [groupName, afterSuccess, axiosService, reloadUserPermissions],
        "edit-group.user-removed",
    );
};

export const useDeleteGroupResource = ({ afterSuccess } = {}) => {
    const { axiosService } = useDic();
    return useMutationLoadableWithNotification(
        async ({ name }) => {
            const { data } = await axiosService.delete(
                `/api/admin/groups/${name}`,
            );
            afterSuccess?.(data);
            return { name, ...data };
        },
        [axiosService, afterSuccess],
        crudSuccess("Group", "name", "D"),
    );
};
