import { axiosService } from "../axios";

const userPreferencesService = {
    getUserPreferences() {
        return axiosService.get(`/api/user-preferences`);
    },
    saveUserPreferences(body) {
        return axiosService.post(`/api/user-preferences`, body);
    },

    getListOfDefaultRoutes() {
        return axiosService.get(`/api/user-preferences/default-pages/allowed`);
    },

    getDefaultPage() {
        return axiosService.get(`/api/user-preferences/default-pages`);
    },
};

export default userPreferencesService;
