import { Text } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { AccountSelect } from "@/components/Sidebar/components/AccountSelect.component";
import { useLocation } from "@/components/hooks/useLocation.hook";
import { userAccountId } from "@/security/UserContext";
import { useUserBasicInfo } from "@/security/hooks/useUserBasicInfo.hook";
import { T } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useRecoilState } from "recoil";
import "./SidebarAccountSelect.style.less";

export const SidebarAccountSelect = ({ disabled }) => {
    const { locationRouterService } = useDic();
    const basicInfo = useUserBasicInfo();
    const location = useLocation();
    const [defaultAccountId, setDefaultAccountId] =
        useRecoilState(userAccountId);

    const onAccountChange = useCallback(
        accountId => {
            setDefaultAccountId(accountId);
            locationRouterService.navigate(location, {
                accountId: accountId,
            });
        },
        [location, locationRouterService, setDefaultAccountId],
    );

    return (
        <div className="pmSidebarAccountSelect">
            <Text type="secondary" size="small">
                <T id="account.sidebar.selected-account" />
            </Text>
            <AccountSelect
                onChange={onAccountChange}
                value={defaultAccountId}
                accounts={basicInfo.projects}
                disabled={disabled}
            />
        </div>
    );
};

SidebarAccountSelect.propTypes = {
    disabled: PropTypes.bool,
};
