import { useDic } from "@/components/Dic/useDic.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import PropTypes from "prop-types";
import React from "react";
import { AdminAssetsTable } from "./AdminAssetsTable.component";

export const IntegrationsTableContainer = React.memo(
    function IntegrationsTableContainer({ fetchParams, ...props }) {
        const { adminAssetRoleApiService } = useDic();
        const [tableProps] = useFetchPage(
            (page, size, sort, filter) =>
                adminAssetRoleApiService.fetchIntegrations(
                    page,
                    size,
                    sort,
                    filter,
                    fetchParams,
                ),
            [fetchParams],
        );

        return (
            <AdminAssetsTable
                {...tableProps}
                assetType="integration"
                defaultSort={{
                    fieldName: "instanceName",
                    sortDir: "ascending",
                }}
                {...props}
            />
        );
    },
);

IntegrationsTableContainer.propTypes = {
    projectId: PropTypes.number.isRequired,
    fetchParams: PropTypes.object,
};
