import { useDic } from "@/components/Dic/useDic.hook";
import { TemplateDeploymentEntity } from "@/components/Marketplace/entities/templates/TemplateDeploymentEntity.class";
import { acceleratorTypeEnum } from "@/constants/accelerators.constants";
import { useConfirmModal } from "@/modules/modal";
import { t } from "@/translations";
import { QuestionCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
export const useDeleteTemplateActionButton = ({
    templateDeployment,
    onSuccess,
    disabled,
}) => {
    const [inProgress, setInProgress] = useState(false);
    const { messageService, templatesService } = useDic();
    const confirmModal = useConfirmModal();

    const handleDelete = useCallback(
        templateDeployment => {
            setInProgress(true);

            templatesService
                .deleteDeployment(templateDeployment)
                .then(() => {
                    messageService.success({
                        content: t("delete-accelerator-action.success"),
                    });
                    onSuccess?.();
                })
                .catch(() => {
                    messageService.error({
                        content: t("delete-accelerator-action.error"),
                    });
                })
                .finally(() => setInProgress(false));
        },
        [templatesService, messageService, onSuccess],
    );

    return {
        label: t("general.remove"),
        type: "text",
        size: "medium",
        danger: true,
        visible:
            templateDeployment.template.type === acceleratorTypeEnum.SOLUTION,
        disabled: !templateDeployment.destinationId || inProgress || disabled,
        onClick: () => {
            confirmModal.confirm({
                title: t("delete-accelerator-action.confirm.title"),
                message: t("delete-accelerator-action.confirm.message", {
                    name: <b>{templateDeployment.template.description}</b>,
                }),
                okText: t("general.confirm"),
                cancelText: t("general.cancel"),
                closable: true,
                onConfirm: () => handleDelete(templateDeployment),
                icon: <QuestionCircleOutlined />,
            });
        },
    };
};

useDeleteTemplateActionButton.propTypes = {
    templateDeployment: PropTypes.instanceOf(TemplateDeploymentEntity)
        .isRequired,
};
