import { Icon } from "@/components/DesignSystem";
import { t } from "@/translations";
import { Tooltip } from "antd";
import React from "react";

export const AccountSalesFxLink = ({ accountUrl }) => {
    function openAccountInSalesFx() {
        const win = window.open(
            accountUrl,
            "_blank",
            "noopener=yes,noreferrer=yes",
        );
        win.focus();
    }

    if (accountUrl) {
        return (
            <Tooltip title={t("account-salesfx-link.tooltip")}>
                <span style={{ cursor: "pointer" }}>
                    <Icon
                        type="external-link-alt"
                        onClick={openAccountInSalesFx}
                    />
                </span>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title={t("account-salesfx-link.tooltip-not-defined")}>
                <span style={{ cursor: "pointer" }}>
                    <Icon type="external-link-alt" />
                </span>
            </Tooltip>
        );
    }
};
