import { t } from "@/translations";

export const deployToEnum = {
    INSTANCE: "instance",
    PARTITION: "partition",
};

export const massDeleteOptions = [
    {
        label: t("partition-send-data.label.mass-delete-strategy.never"),
        value: "NEVER",
    },
    {
        label: t("partition-send-data.label.mass-delete-strategy.before"),
        value: "BEFORE",
    },
    {
        label: t("partition-send-data.label.mass-delete-strategy.after"),
        value: "AFTER",
    },
];
