import {
    ContainerWithFloat,
    DatePicker,
    Search,
    searchSizesEnum,
} from "@/components/DesignSystem";
import { useParentState } from "@/components/hooks/useParentState.hook";
import { t } from "@/translations";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

export const Controls = ({
    range: [from, to],
    onRangeChange,
    mustQuery,
    onMustQueryChange,
}) => {
    const [search, setSearch] = useParentState(mustQuery);
    const onKeyDown = useCallback(
        e => onMustQueryChange(e.target.value),
        [onMustQueryChange],
    );
    return (
        <ContainerWithFloat
            flowRight={
                <DatePicker.RangePicker
                    showTime={{ format: "HH:mm" }}
                    format="DD/MM/YYYY HH:mm"
                    placeholder={[
                        t("processed-files.search.start-time"),
                        t("processed-files.search.end-time"),
                    ]}
                    value={[from, to]}
                    onOk={onRangeChange}
                />
            }
        >
            <Search
                isLoading
                loading
                size={searchSizesEnum.STANDARD}
                placeholder={t("processed-files.search.placeholder")}
                value={search}
                onChange={e => {
                    setSearch(e.target.value);
                }}
                onKeyDown={debounce(onKeyDown, 1000)}
                onSearch={() => onMustQueryChange(search)}
            />
        </ContainerWithFloat>
    );
};

Controls.propTypes = {
    range: PropTypes.array.isRequired,
    onRangeChange: PropTypes.func.isRequired,
    mustQuery: PropTypes.string,
    onMustQueryChange: PropTypes.func.isRequired,
};
