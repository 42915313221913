export const propertyType = {
    int: "int",
    filter: "filter",
    mapper: "mapper",
    password: "password",
    placeholders: "placeholders",
    string: "string",
    select: "select",
    text: "text",
};
