// Need to use antd table because of missing row height option (need taller rows because of selects etc.)
import { Button, Gap, H5, Select, Text } from "@/components/DesignSystem";
import { mapUnityColumnsToAntd } from "@/components/DesignSystem/Table/features/mapper";
import { defaultPreviewCellProps } from "@/components/Packages/PackageTableDefinitionPanel/components/PackageDataMappingLayout/DataPreviewTable.component";
import { useConfirmModal } from "@/modules/modal/imperative/useConfirmModal.hook";
import { T, t } from "@/translations";
import { Collapse } from "@pricefx/unity-components";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import { Table } from "antd";
import { produce } from "immer";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import find from "lodash/find";
import flattenDeep from "lodash/flattenDeep";
import fp from "lodash/fp";
import isEmpty from "lodash/isEmpty";
import unionBy from "lodash/unionBy";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { conditionalOptionsModal } from "../../../../../apps/marketplaceApp/components/PackageDefinition/MappingOption.modal";
import { getNotSkippedStepNames } from "../../../../../views/Package/package.utils";
import {
    addConditionalFields,
    disableNextButton,
    getAllAvailableFields,
    getAvailableOptions,
    getSelectedType,
    getTypeLabel,
    resolveTextColor,
} from "../../../packagesPanel.utils";
import CreateCustomFieldModal from "../CustomFields/CreateCustomField.modal";
import PackageDataMappingLayout from "../PackageDataMappingLayout/PackageDataMappingLayout";
import { nameMatches } from "../PackageMandatoryFields/PackageMandatoryFields.utils";
import { PackageTable } from "../PackageTable/PackageTable";
import { handleRowClassName } from "../PricePrameters/priceParemeters.utils";
import { SimpleSkip, Skip, SkipAll } from "../TableComponents/Skip";
import styles from "../styles.less";
import style from "./PackageOptionalFieldsSelectMap.style.less";

const COLUMN_WIDTH = "46%";
export const GROUP = "group";

export const initOptionalFields = (
    optionalFields,
    optionalFieldsDef,
    fileColumns,
) => {
    const optionalFieldsByName = fp.pipe(
        getFlattenFields,
        fp.keyBy("name"),
        fp.pickBy(
            v => !v.value || find(fileColumns, fc => fc.name === v.value),
        ), // value??
    )(optionalFields ?? []);

    const result = cloneDeep(optionalFieldsDef).map(fieldDef =>
        fieldDef.type === GROUP
            ? {
                  ...fieldDef,
                  fields: fieldDef.fields.map(subField =>
                      createField(
                          subField,
                          optionalFieldsByName[subField.name],
                          fileColumns,
                          { groupName: fieldDef.name },
                      ),
                  ),
              }
            : createField(
                  fieldDef,
                  optionalFieldsByName[fieldDef.name],
                  fileColumns,
              ),
    );

    return result;
};

const createField = (stepField, found, fileColumns, additionalProperties) => {
    const maybeColumn = find(fileColumns, c =>
        nameMatches(c.name, stepField.label, stepField.mappingAliases),
    );
    // no type match needed? hasConvertibleType? TODO?
    return {
        ...stepField,
        skip: found?.skip || false,
        label: found?.label || stepField.label,
        type: found?.type || stepField.type || maybeColumn?.type,
        name: stepField.name,
        value: found?.value || maybeColumn?.name || undefined,
        swap: undefined,
        ...additionalProperties,
    };
};

const getNotGroupedFields = optionalFields =>
    (optionalFields || []).filter(f => f.type !== GROUP);

const getGroupedFields = optionalFields =>
    (optionalFields || []).filter(f => f.type === GROUP).map(f => f.fields);

const getGroups = optionalFields =>
    (optionalFields || []).filter(f => f.type === GROUP);

const getFlattenFields = fields => {
    const groupedFields = getGroupedFields(fields);
    const defaultFields = getNotGroupedFields(fields);
    defaultFields.push(groupedFields);
    return flattenDeep(defaultFields);
    // TODO: investigate why following line breaks skip all on groups:
    // return defaultFields.concat(groupedFields);
};

const removeSelected = (columns, fields) => {
    return produce(fields, draft => {
        const selectedValues = getFlattenFields(draft).map(f => f.value);
        return columns.filter(f => !selectedValues.includes(f.name));
    });
};

const disableGroup = (fieldsArray, groupName) => {
    const grouped = getGroupedFields(fieldsArray);
    const filteredField = flattenDeep(grouped).filter(
        field => field.groupName === groupName,
    );

    return filteredField.filter(field => !field.skip).length === 0;
};

const mapCustomFieldToOptional = customField => {
    return {
        label: customField.label,
        name: customField.name,
        skip: customField.skip || false,
        value: customField.name,
        description: t("package-data-upload.custom-field"),
        type: undefined,
        swap: undefined,
        groupName: undefined,
        isUserDefinedCustomField: true,
    };
};

const PackageOptionalFieldsSelectMap = ({
    accountId,
    globalState,
    componentState,
    onNext,
    onBack,
    changeState,
    onCancel,
    apiResult,
}) => {
    const {
        fileInfo: tableExampleData = {},
        mandatoryFieldsMap,
        significantFields,
        optionalFields = [],
    } = globalState;

    const {
        userDefinedCustomFields = [],
        userDefinedCustomFieldValues = [],
        optionalFieldsInternal = [],
    } = componentState;

    const customFieldsAllowed =
        !!apiResult.currentStepData.userDefinedCustomFieldsAllowed;

    const confirmModal = useConfirmModal();

    const optionalFieldsDef = useMemo(
        () =>
            addConditionalFields(
                apiResult.currentStepData.optionalFields,
                filter(
                    apiResult.currentStepData.conditionalFields,
                    cf => !isEmpty(cf.optionalFields),
                ),
                getNotSkippedStepNames(apiResult), // TODO: check
                "optionalFields",
            ),
        [apiResult],
    );

    const [isCustomFieldModalVisible, setCustomFieldModalVisible] =
        useState(false);

    const updateUserDefinedCustomFieldValue = (record, updateFunc) => {
        changeState(null, {
            ...componentState,
            userDefinedCustomFields,
            userDefinedCustomFieldValues: produce(
                userDefinedCustomFieldValues,
                draft => {
                    const field = draft.find(
                        field => record.name === field.name,
                    );
                    updateFunc(field);
                },
            ),
        });
    };

    const setNewUserDefinedCustomFields = field => {
        changeState(null, {
            ...componentState,
            userDefinedCustomFields: unionBy(
                [field],
                userDefinedCustomFields,
                "name",
            ),
            userDefinedCustomFieldValues: unionBy(
                [mapCustomFieldToOptional(field)],
                userDefinedCustomFieldValues,
                "name",
            ),
        });
    };

    useEffect(() => {
        if (isEmpty(optionalFieldsDef)) {
            conditionalOptionsModal({
                condition: apiResult.currentStepData.showMappingOptions,
                data: { optionalFields: [] },
                accountId,
                onNext,
                onCancel,
                confirmModal,
            });
            return;
        }
        const optionalFieldsInternal = initOptionalFields(
            optionalFields,
            optionalFieldsDef,
            tableExampleData.columns,
        );
        changeState(null, {
            ...componentState,
            optionalFieldsInternal,
            userDefinedCustomFields: [],
            userDefinedCustomFieldValues: [],
        });
    }, []);

    const handleSelect = (record, value) => {
        if (record.isUserDefinedCustomField) {
            updateUserDefinedCustomFieldValue(record, f => (f.value = value));
        } else {
            changeState(null, {
                ...componentState,
                optionalFieldsInternal: produce(
                    optionalFieldsInternal,
                    draft => {
                        const field = getFlattenFields(draft).find(
                            field => record.name === field.name,
                        );
                        field.value = value;
                    },
                ),
            });
        }
    };
    const onSkipAll = (checked, fields) => {
        const nextOptionalFieldsInternal = produce(
            optionalFieldsInternal,
            draft => {
                const groupNames = fields.map(f => f.name);
                getFlattenFields(draft).forEach(f => {
                    if (groupNames.includes(f.name)) {
                        f.skip = checked;
                    }
                });
            },
        );
        changeState(null, {
            ...componentState,
            optionalFieldsInternal: nextOptionalFieldsInternal,
            userDefinedCustomFields,
            userDefinedCustomFieldValues: produce(
                userDefinedCustomFieldValues,
                draft => {
                    const groupNames = fields.map(f => f.name);
                    getFlattenFields(draft).forEach(f => {
                        if (groupNames.includes(f.name)) {
                            f.skip = checked;
                        }
                    });
                },
            ),
        });
    };

    const onSkip = (record, checked) => {
        if (record.isUserDefinedCustomField) {
            updateUserDefinedCustomFieldValue(record, f => (f.skip = checked));
        } else {
            const nextOptionalFieldsInternal = produce(
                optionalFieldsInternal,
                draft => {
                    getFlattenFields(draft).forEach(f => {
                        if (record.name === f.name) {
                            f.skip = checked;
                        }
                    });
                },
            );
            changeState(null, {
                ...componentState,
                optionalFieldsInternal: nextOptionalFieldsInternal,
            });
        }
    };

    const handleOnNext = () => {
        const nextOptionalFields = getFlattenFields(optionalFieldsInternal);

        conditionalOptionsModal({
            condition: apiResult.currentStepData.showMappingOptions,
            data: {
                optionalFields: nextOptionalFields,
                userDefinedCustomFields,
                userDefinedCustomFieldValues,
            },
            accountId,
            onNext,
            onCancel,
            confirmModal,
        });
    };

    const allTableFields = unionBy(
        userDefinedCustomFieldValues,
        optionalFieldsInternal,
        "name",
    );

    const availableFields = getAllAvailableFields(
        mandatoryFieldsMap,
        significantFields,
        removeSelected(tableExampleData.columns, allTableFields),
    );

    const selectRenderField = (text, record) => {
        return (
            <Select
                onChange={value => handleSelect(record, value)}
                value={record.value}
                disabled={record.skip}
                placeholder={t("packages.select.placeholder")}
                className={styles.itemSelect}
                showSearch
                allowClear
            >
                {getAvailableOptions(
                    record,
                    removeSelected(tableExampleData.columns, allTableFields),
                    mandatoryFieldsMap,
                    significantFields,
                )}
            </Select>
        );
    };

    const customColumns = ({ name, type }) => {
        return {
            ...defaultPreviewCellProps,
            label: createTitle(
                name,
                getSelectedType(allTableFields, name) || type,
            ),
            name,
            render: text => <div>{`${text}`}</div>,
        };
    };

    const createTitle = (name, type) => {
        return (
            <>
                {name}
                <div className={styles.type}>{getTypeLabel(type)}</div>
            </>
        );
    };

    const groups = getGroups(allTableFields);
    const notGroupedFields = getNotGroupedFields(allTableFields);

    if (!allTableFields.length) return null;

    return (
        <PackageDataMappingLayout
            dataExample={tableExampleData}
            disableNextButton={disableNextButton(
                getFlattenFields(allTableFields),
            )}
            customColumns={customColumns}
            onNext={handleOnNext}
            onBack={onBack}
            onCancel={onCancel}
        >
            <T id="package-data-upload.optional-files.message" />
            <Gap size="small" />
            {groups.map(group => (
                <Collapse key={group.name}>
                    <Collapse.Panel
                        key={group.name}
                        disabled={disableGroup(allTableFields, group.name)}
                        header={
                            <span data-test={`group-${group.name}`}>
                                {group.name}
                            </span>
                        }
                        extra={SkipAll(
                            onSkipAll,
                            group.fields,
                            styles.groupSkipTitle,
                            false,
                            "skip-all-" + group.name,
                        )}
                    >
                        <Table
                            data-test={`group-table-${group.name}`}
                            rowClassName={handleRowClassName}
                            dataSource={group.fields}
                            pagination={false}
                            columns={mapUnityColumnsToAntd([
                                {
                                    key: "fileFields",
                                    width: COLUMN_WIDTH,
                                    label: t(
                                        "package-data-upload.label.pfx-fields",
                                    ),
                                    render: (text, record) => (
                                        <Fragment>
                                            <H5
                                                className={
                                                    resolveTextColor(
                                                        group.fields,
                                                        record.name,
                                                    ).labelColor
                                                }
                                            >
                                                {record.label}
                                            </H5>
                                            <Text
                                                size="small"
                                                className={
                                                    resolveTextColor(
                                                        group.fields,
                                                        record.name,
                                                    ).descriptionColor
                                                }
                                            >
                                                {record.description}
                                            </Text>
                                        </Fragment>
                                    ),
                                },
                                {
                                    key: "mappedField",
                                    width: COLUMN_WIDTH,
                                    label: t(
                                        "package-data-upload.label.file-fields",
                                        {
                                            fileName: tableExampleData.fileName,
                                        },
                                    ),

                                    render: (text, record) =>
                                        selectRenderField(text, record),
                                },

                                SimpleSkip(onSkip),
                            ])}
                        />
                    </Collapse.Panel>
                </Collapse>
            ))}
            {(!isEmpty(notGroupedFields) || customFieldsAllowed) && (
                <>
                    <PackageTable
                        fields={notGroupedFields}
                        rowClassName={handleRowClassName}
                        columns={[
                            {
                                key: "fileFields",
                                width: COLUMN_WIDTH,
                                title: () => (
                                    <div className={style.titleWithLink}>
                                        <T id="package-data-upload.label.pfx-fields" />
                                    </div>
                                ),
                                render: (text, record) => (
                                    <Fragment>
                                        <H5
                                            className={
                                                resolveTextColor(
                                                    notGroupedFields,
                                                    record.name,
                                                )?.labelColor
                                            }
                                        >
                                            {record.label}
                                        </H5>
                                        <Text
                                            size="small"
                                            className={
                                                resolveTextColor(
                                                    notGroupedFields,
                                                    record.name,
                                                )?.descriptionColor
                                            }
                                        >
                                            {record.description}
                                        </Text>
                                    </Fragment>
                                ),
                            },
                            {
                                key: "mappedField",
                                width: COLUMN_WIDTH,
                                title: () =>
                                    t("package-data-upload.label.file-fields", {
                                        fileName: tableExampleData.fileName,
                                    }),
                                render: (text, record) =>
                                    selectRenderField(text, record),
                            },
                            Skip(onSkipAll, onSkip, notGroupedFields),
                        ]}
                    />
                    {customFieldsAllowed && (
                        <>
                            <Gap size="small" />
                            <Button
                                size="small"
                                disabled={availableFields.length === 0}
                                type="default"
                                onClick={() => setCustomFieldModalVisible(true)}
                                label={t(
                                    "package-data-upload.add-custom-field",
                                )}
                                icon={Plus}
                            />
                        </>
                    )}
                </>
            )}
            <CreateCustomFieldModal
                visible={isCustomFieldModalVisible}
                onCancel={() => setCustomFieldModalVisible(false)}
                availableFields={availableFields}
                onSave={customField => {
                    setCustomFieldModalVisible(false);
                    setNewUserDefinedCustomFields(customField);
                }}
            />
        </PackageDataMappingLayout>
    );
};

PackageOptionalFieldsSelectMap.propTypes = {
    accountId: PropTypes.number,
    globalState: PropTypes.object.isRequired,
    componentState: PropTypes.object.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    changeState: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    apiResult: PropTypes.object.isRequired,
};

export default PackageOptionalFieldsSelectMap;
