import { Forms } from "@/components/DesignSystem";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

const { Fields, validators, pmValidators } = Forms;

export const NewManualInstanceFormComponent = ({
    integrationTypesResource,
}) => {
    return (
        <>
            <Fields.Input
                autoFocus
                required
                name="instanceName"
                placeholder={t("instance-form.placeholder.integration-name")}
                label={t("instance-form.label.integration-name")}
                validator={validators.failOnFirst([pmValidators.isRequired])}
            />
            <Fields.Select
                required
                name="type"
                label={t("instance-form.label.integration-type")}
                showSearch
                placeholder={t("instance-form.placeholder.integration-type")}
                validator={pmValidators.isRequired}
                {...getLoadableSelectProps(integrationTypesResource.loadable)}
            />
            <Fields.Input
                required
                name="server"
                label={t("instance-form.label.instance-server")}
                placeholder={t("instance-form.placeholder.instance-server")}
                validator={validators.failOnFirst([pmValidators.isRequired])}
            />
            <Fields.Input
                required
                name="partitionName"
                validator={validators.failOnFirst([pmValidators.isRequired])}
                label={t("instance-form.label.partition-name")}
                placeholder={t("instance-form.placeholder.partition-name")}
            />
        </>
    );
};

NewManualInstanceFormComponent.propTypes = {
    integrationTypesResource: PropTypes.object.isRequired,
};
