import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useCurrentRef } from "./useCurrentRef.hook";

export const useMemoByEquality = (fn, deps) => {
    const fnRef = useCurrentRef(fn);
    const [memoized, setMemoized] = useState(fn());
    const memoizedRef = useCurrentRef(memoized);

    useEffect(() => {
        const newMemoized = fnRef.current();
        if (!isEqual(memoizedRef.current, newMemoized)) {
            setMemoized(newMemoized);
        }
    }, deps);

    return memoized;
};
