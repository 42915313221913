import { DataUploadWizard } from "@/components/DataUploads/Wizard/DataUploadWizard";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";

export function NewDataUploadPage() {
    const { accountId, partitionId } = useAccountAppParams();

    useSetPageTitle(t("partition-send-data.title.data-upload"));
    const { locationRouterService, accountAppLocations } = useDic();
    const showDataUploads = useCallback(
        () =>
            locationRouterService.navigate(
                accountAppLocations.partitionUploadsLocation,
            ),
        [accountAppLocations.partitionUploadsLocation, locationRouterService],
    );
    useSetBackButtonAction(showDataUploads);

    return <DataUploadWizard projectId={accountId} partitionId={partitionId} />;
}
