const fileNameRegex = /.*(\d\d\d\d-\d\d-\d\d).*/i;

export function sortArchivedLogFiles(files) {
    if (!files.length) {
        return files;
    }

    return files.map(toNameAndDateObject).sort(byDateDescending);
}

function toNameAndDateObject(f) {
    return {
        name: f,
        date: getDateFromFileName(f),
    };
}

function byDateDescending(a, b) {
    return b.date - a.date;
}

function getDateFromFileName(fileName) {
    const result = fileNameRegex.exec(fileName);

    return result ? new Date(result[1]) : new Date();
}
