import {
    Alert,
    Col,
    Form,
    Gap,
    H5,
    Input,
    Row,
    Select,
    Text,
} from "@/components/DesignSystem";
import { useConfirmModal } from "@/modules/modal/imperative/useConfirmModal.hook";
import { T, t } from "@/translations";
import cx from "classnames";
import { produce } from "immer";
import get from "lodash/get";
import keyBy from "lodash/keyBy";
import values from "lodash/values";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { conditionalOptionsModal } from "../../../../../apps/marketplaceApp/components/PackageDefinition/MappingOption.modal";
import {
    getAvailableOptions,
    getTypeLabel,
} from "../../../packagesPanel.utils";
import { FE_STEP, ParsedType } from "../../constants";
import PackageDataMappingLayout from "../PackageDataMappingLayout/PackageDataMappingLayout";
import { PackageTable } from "../PackageTable/PackageTable";
// assets
import { defaultPreviewCellProps } from "@/components/Packages/PackageTableDefinitionPanel/components/PackageDataMappingLayout/DataPreviewTable.component";
import styles from "../styles.less";
import { availableOptions, handleRowClassName } from "./priceParemeters.utils";

const hasError = rangeValues =>
    rangeValues?.value?.error ||
    rangeValues?.lowerBound?.error ||
    rangeValues?.upperBound?.error;

const createRangeValues = () => {
    return {
        value: {
            label: "Value",
            name: "value",
            value: undefined,
            type: ParsedType.NUMBER,
        },
        lowerBound: {
            label: "Lower Bound",
            name: "lowerBound",
            value: undefined,
            type: ParsedType.NUMBER,
        },
        upperBound: {
            label: "Upper Bound",
            name: "upperBound",
            value: undefined,
            type: ParsedType.NUMBER,
        },
    };
};

export const PPRange = ({
    accountId,
    globalState,
    onNext,
    onBack,
    changeState,
    onCancel,
    step,
}) => {
    const { fileInfo: tableExampleData = {}, userInputs = {} } = globalState;

    const { label: ppLabel, fields: rangeValues } = userInputs;

    const confirmModal = useConfirmModal();

    useEffect(() => {
        if (!userInputs.fields) {
            setRangeValues(createRangeValues());
        }
    }, []);

    const setRangeValues = i => {
        userInputs.fields = i;
        changeState({ userInputs });
    };

    const setPPLabel = i => {
        userInputs.label = i;
        changeState({ userInputs });
    };

    const headers = tableExampleData.columns
        ? tableExampleData.columns.map(c => c.name)
        : [];

    const fileColumns = keyBy(tableExampleData.columns, "name");

    const handleSelect = (record, value) =>
        setRangeValues(
            produce(rangeValues, draft => {
                const rangeValue = draft[record.name];
                rangeValue.value = value;
                if (record.value) {
                    const type = fileColumns[record.value].type;

                    if (ParsedType.NUMBER !== type) {
                        rangeValue.error = "error";
                    } else {
                        rangeValue.error = undefined;
                    }
                }
            }),
        );

    const handlePPLabel = label => setPPLabel(label);

    const createData = () => ({
        valueType: FE_STEP.PP_RANGE,
        label: ppLabel,
        fields: rangeValues,
    });

    const handleOnNext = () =>
        conditionalOptionsModal({
            condition: step.showMappingOptions,
            data: createData,
            accountId,
            onNext,
            onCancel,
            confirmModal,
        });

    const customColumns = ({ name, type }) => {
        return {
            ...defaultPreviewCellProps,
            label: createTitle(name, type),
            name,
            render: text => {
                return (
                    <div className={cx({ [styles.skipped]: shouldSkip(name) })}>
                        {`${text}`}
                    </div>
                );
            },
        };
    };

    const createTitle = (name, type) => {
        let ppField;

        if (get(rangeValues, "value.value") === name) {
            ppField = get(rangeValues, "value.label");
        }

        if (get(rangeValues, "lowerBound.value") === name) {
            ppField = get(rangeValues, "lowerBound.label");
        }
        if (get(rangeValues, "upperBound.value") === name) {
            ppField = get(rangeValues, "upperBound.label");
        }

        return (
            <div className={cx({ [styles.skipped]: shouldSkip(name) })}>
                {name}
                <div className={styles.type}>{getTypeLabel(type)}</div>
                {ppField && <div className={styles.ppField}>{ppField}</div>}
            </div>
        );
    };
    const shouldSkip = name =>
        rangeValues?.value?.value &&
        rangeValues?.lowerBound?.value &&
        rangeValues?.upperBound?.value &&
        availableOptions(rangeValues, headers).includes(name);

    const columns = [
        {
            key: "pfxFieldName",
            title: () => t("package-data-upload.label.mandatory-fields"),
            render: (text, record) => (
                <Fragment>
                    <H5 className={styles.headingColor}>{record.label}</H5>
                    <Text size="small" className={styles.descriptionColor}>
                        {record.description}
                    </Text>
                </Fragment>
            ),
        },
        {
            key: "valueFromFile",
            width: "30%",
            title: () => (
                <span className={styles.descriptionColor}>
                    <FormattedMessage
                        id="package-data-upload.label.file-fields"
                        values={{
                            fileName: tableExampleData.fileName,
                        }}
                    />
                </span>
            ),
            render: (text, record) => (
                <Form.Item validateStatus={record.error}>
                    <Select
                        placeholder="Choose field..."
                        showSearch
                        allowClear
                        onChange={value => {
                            handleSelect(record, value);
                        }}
                        className={styles.itemSelect}
                        defaultValue={record.value}
                    >
                        {getAvailableOptions(
                            record,
                            tableExampleData.columns,
                            rangeValues,
                        )}
                    </Select>
                </Form.Item>
            ),
        },
    ];

    return (
        <PackageDataMappingLayout
            dataExample={tableExampleData}
            disableNextButton={
                !rangeValues?.value?.value ||
                !rangeValues?.lowerBound?.value ||
                !rangeValues?.upperBound?.value ||
                rangeValues?.value?.error ||
                rangeValues?.lowerBound?.error ||
                rangeValues?.upperBound?.error
            }
            customColumns={customColumns}
            onNext={handleOnNext}
            onBack={onBack}
            onCancel={onCancel}
        >
            <T id="package-data-upload.price-parameters-mapper-message" />
            <Gap size="small" />
            {hasError(rangeValues) && (
                <Fragment>
                    <Alert
                        message={t("package.pp-range.error.not-number")}
                        type="error"
                    />
                    <Gap size="small" />
                </Fragment>
            )}
            <Row className={styles.extensionInputs}>
                <Col span={6}>
                    <Text size="small">
                        <T id="package-data-upload.pp-simple.input.label" />
                    </Text>
                    <Input
                        placeholder={t(
                            "package-data-upload.pp-simple.input.placeholder",
                        )}
                        onChange={e => handlePPLabel(e.target.value)}
                        defaultValue={userInputs.label}
                    />
                </Col>
            </Row>
            <Gap size="medium" />
            <PackageTable
                fields={values(rangeValues)}
                handleRowClassName={handleRowClassName}
                columns={columns}
            />
        </PackageDataMappingLayout>
    );
};

PPRange.propTypes = {
    accountId: PropTypes.number.isRequired,
    changeState: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    step: PropTypes.object.isRequired,
    globalState: PropTypes.object.isRequired,
};
