import { H5 } from "@/components/DesignSystem";
import { T } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

export const Messages = ({ messages }) => {
    return (
        <>
            <H5>
                <T id="package-failed-option.header.messages" />
            </H5>
            <div>
                {messages.map(({ message }) => (
                    <div key={message}>{message}</div>
                ))}
            </div>
        </>
    );
};
Messages.propTypes = {
    messages: PropTypes.array,
};
