import FormattedDate from "@/components/DateTime/FormattedDate";
import { Button } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";
import { DeleteButtonWithConfirm } from "../Delete";

export const columns = ({ isDeletable, onDelete, onDownload }) => [
    {
        label: t("certificate-table.label.certificateId"),
        name: "name",
        type: fieldTypes.TEXT,
        render: (_, record) => record.name || record.certificateId,
    },
    {
        label: t("certificate-table.label.validFrom"),
        name: "validFrom",
        type: fieldTypes.DATETIME,
        render: date => <FormattedDate>{date}</FormattedDate>,
    },
    {
        label: t("certificate-table.label.validTo"),
        name: "validTo",
        type: fieldTypes.DATETIME,
        render: date => <FormattedDate>{date}</FormattedDate>,
    },
    !isDeletable
        ? {
              label: t("certificate-table.label.certificate"),
              name: "certificate",
              width: 150,
              render: (_, record) => {
                  return (
                      <Button
                          size="small"
                          type="link"
                          onClick={() =>
                              onDownload({
                                  id: record.name || record.certificateId,
                                  value: record.value || record.certificate,
                              })
                          }
                          label={t("general.download")}
                      />
                  );
              },
          }
        : {
              label: "",
              name: "delete",
              width: 50,
              render: (_, record) => {
                  return (
                      <DeleteButtonWithConfirm
                          size="small"
                          onlyIcon
                          title={t("certificate-table.delete.modal.title")}
                          message={t("certificate-table.delete.modal.message")}
                          onConfirm={() => onDelete(record.id)}
                      />
                  );
              },
          },
];
