import Changed from "@/components/DateTime/Changed";
import FromNowFormatter from "@/components/DateTime/FromNowFormatter";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";

export const columns = [
    {
        type: fieldTypes.TEXT,
        label: t("data-uploads-list.header.name"),
        name: "name",
        className: "pmTable__column--withActionButton",
    },
    {
        type: fieldTypes.TEXT,
        label: t("data-uploads-list.header.entity"),
        name: "__composedEntity",
    },
    {
        type: fieldTypes.TEXT,
        label: t("data-uploads-list.header.number-of-sftp-users"),
        name: "numberOfSftpUsers",
    },
    {
        type: fieldTypes.DATETIME,
        label: t("data-uploads-list.header.created-at"),
        name: "createdAt",
        render: (text, record) => <FromNowFormatter date={record.createdAt} />,
    },
    {
        type: fieldTypes.TEXT,
        label: t("data-uploads-list.header.created-by"),
        name: "createdBy",
    },
    {
        type: fieldTypes.DATETIME,
        label: t("data-uploads-list.header.updated-at"),
        name: "updatedAt",
        render: (text, record) => <Changed key={record.id} data={record} />,
    },
];
