import { Button, Gap, Table } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { STATUSES } from "@/modules/loadable";
import { t } from "@/translations";
import { downloadFile } from "@/utils/downloadUtils";
import { getErrorMessageFromError } from "@/utils/state/error.utils";
import PropTypes from "prop-types";
import React from "react";
import { AddCertificateModal } from "./AddCertificate.modal";
import { columns } from "./CertificateTable.columns";
import { SSOCertificateAccountService } from "./SSOCertificateAccount.service";
import { SSOCertificateAdminService } from "./SSOCertificateAdmin.service";

export const CertificateTable = ({
    isAdmin,
    projectId,
    certificatesLoadable,
    isDeletable,
    isAddable,
}) => {
    const { show, hide, visible } = useVisibility(false);
    const { messageService } = useDic();

    const certificateService = isAdmin
        ? SSOCertificateAdminService
        : SSOCertificateAccountService;

    const onDownload = ({ id, value }) => {
        downloadFile(value, `${id}.cert`);
    };

    const onDelete = certificateId => {
        certificateService
            .deleteCertficate({
                projectId,
                certificateId,
            })
            .then(() => certificatesLoadable.reload())
            .catch(error =>
                messageService.error({
                    content: getErrorMessageFromError(error),
                }),
            );
    };

    return (
        <>
            <Table
                rowKey="id"
                columns={columns({ isDeletable, onDelete, onDownload })}
                dataSource={certificatesLoadable.loadable.valueMaybe()}
                padding={false}
                pagination={false}
                loading={
                    certificatesLoadable.loadable.state === STATUSES.loading
                }
                page={
                    certificatesLoadable.loadable.state === STATUSES.hasValue
                        ? certificatesLoadable.loadable.contents
                        : {}
                }
            />
            {isAddable && (
                <div onSubmit={e => e.stopPropagation()}>
                    <Gap size="small" />
                    <AddCertificateModal
                        certificateService={certificateService}
                        projectId={projectId}
                        visible={visible}
                        onClose={hide}
                        onSubmit={() => {
                            certificatesLoadable.reload();
                            hide();
                        }}
                    />

                    <Button
                        size="small"
                        onClick={show}
                        label={t("certificate-table.add-certificate")}
                    />
                </div>
            )}
        </>
    );
};

CertificateTable.propTypes = {
    isAdmin: PropTypes.bool,
    projectId: PropTypes.number.isRequired,
    certificatesLoadable: PropTypes.object,
    isDeletable: PropTypes.bool,
    isAddable: PropTypes.bool,
};
