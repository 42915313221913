import { Button, ButtonGroup, Gap, H3, Text } from "@/components/DesignSystem";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { useMarketplaceAppParams } from "@/modules/router/hooks/useMarketplaceAppParams.hook";
import { T } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import {
    SelectDeployDestination,
    useSelectDeployDestinationOptions,
} from "../../../../../apps/marketplaceApp/components/SelectDeployDestination/SelectDeployDestination.component";
import { deployToEnum } from "../../../../../constants/deployment.constants";
import { templatesService } from "../../../../../services/templates.service";
import styles from "./IntegrationManagerInstanceSelect.styles.less";

export function IntegrationManagerInstanceSelect({
    globalState,
    changeState,
    onNext,
    onBack,
    onCancel,
}) {
    const { instanceId: destinationId, runIMTemplate } = globalState;
    const setDestinationId = id => changeState({ instanceId: id });
    const { availableInstancesLoadable, partitionsOptionsLoadable } =
        useSelectDeployDestinationOptions({
            accountId: useMarketplaceAppParams().accountId,
        });

    const handleOnNext = () => {
        if (runIMTemplate) {
            templatesService
                .fetchTemplateInfo(
                    globalState.instanceId,
                    "import-csv-from-ftp-to-pfx",
                )
                .then(data =>
                    onNext({
                        mappingTemplate: data,
                        instanceId: destinationId,
                    }),
                );
        } else {
            onNext({ instanceId: destinationId });
        }
    };

    return (
        <>
            <H3>
                <T id="package.mapping-options.run-im-sftp-template.select-im-instance.header" />
            </H3>
            <Text size="large">
                <T id="package.mapping-options.run-im-sftp-template.select-im-instance.title" />
            </Text>
            <Gap size="large" />

            <SelectDeployDestination
                destination={deployToEnum.INSTANCE}
                onChange={setDestinationId}
                destinationId={destinationId}
                className={styles.destinationSelect}
                availableInstancesLoadable={availableInstancesLoadable}
                partitionsOptionsLoadable={partitionsOptionsLoadable}
            />
            <Gap size="large" />

            <div style={{ clear: "both" }}>
                <Gap size="large" />
                <ButtonGroup spacing={"none"}>
                    <Button
                        type="primary"
                        disabled={!destinationId}
                        onClick={handleOnNext}
                        label={
                            <FormattedMessage id="package.mapping-options.run-im-sftp-template.select-im-instance.continue" />
                        }
                    />
                    <BackButton onBack={onBack} />
                    <Button
                        type="text"
                        onClick={onCancel}
                        label={<T id="general.cancel" />}
                    />
                </ButtonGroup>
            </div>
        </>
    );
}

IntegrationManagerInstanceSelect.propTypes = {
    globalState: PropTypes.object.isRequired,
    changeState: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
