import { Gap } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    LoadableRenderer,
    LoadingLoadable,
    useComposeLoadablesMemoized,
    useMutationLoadable,
    useQueryLoadable,
} from "@/modules/loadable";
import { noop } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import ConditionalErrorAlert from "../../Error/ConditionalErrorAlert";
import { MultilevelMapperTable } from "./MultilevelMapperTable";
import { OptionsResourceSelect } from "./OptionsResourceSelect";
import { CONNECTION_TYPE } from "./constants";
import { useConnectionsMapper } from "./hooks/useConnectionsMapper.hook";

const Loading = () => ({
    loadable: LoadingLoadable(),
    reload: noop,
});

export const ConnectionsMapper = ({
    initMapper,
    sourceConnectionTypes,
    targetConnectionTypes,
    instanceId,
    onSubmit,
    submitButtonText,
    initialSourceConnectionId,
    initialTargetConnectionId,
}) => {
    const { connectionsService } = useDic();
    const connectionsConfigurationsResource = useQueryLoadable(
        async () =>
            connectionsService
                .fetchConfigurations(instanceId)
                .then(({ data }) => data),
        [instanceId, connectionsService],
    );
    // {input/output}OptionsResource can contain either options for:
    // - cascader (multilevel, tree data)
    // - select (flat array)
    // In other words, options is union of two interfaces from two apis
    // as the components / user flow for selecting params to fetch options is different
    // for pricefx/non-pricefx connection type, it is wrapped in <OptionsResourceSelect />
    // which provides resources holding options
    const [inputOptionsResource, setInputOptionsResource] = useState(Loading);
    const resetInputOptionsResource = () => setInputOptionsResource(Loading);
    const [outputOptionsResource, setOutputOptionsResource] = useState(Loading);
    const resetOutputOptionsResource = () => setOutputOptionsResource(Loading);
    const [sourceParams, setSourceParams] = useState({});
    const [targetParams, setTargetParams] = useState({});

    const { loadableInitMapper } = useConnectionsMapper({
        inputOptionsLoadable: inputOptionsResource.loadable,
        outputOptionsLoadable: outputOptionsResource.loadable,
        initMapper,
    });

    const loadables = useComposeLoadablesMemoized([
        inputOptionsResource.loadable,
        outputOptionsResource.loadable,
        loadableInitMapper,
    ]);
    // console.log("%cConnectionsMapper.rndr", "color:coral;", {
    //     sourceParams,
    //     targetParams,
    //     initMapper,
    //     connectionsConfigurationsResource,
    //     inputOptionsResource,
    //     outputOptionsResource,
    //     loadableInitMapper,
    //     loadables,
    // });
    const handleMapperSubmit = useCallback(
        (mapper, convertEmptyStringToNull) =>
            onSubmit({
                mapper,
                sourceParams,
                targetParams,
                convertEmptyStringToNull,
            }),
        [sourceParams, targetParams, onSubmit],
    );
    const { axiosService } = useDic();
    const testMapperResource = useMutationLoadable(
        async mapperDef => {
            const { data } = await axiosService.post(
                `/api/general-mapper/instance/${instanceId}/test-mapper`,
                {
                    sourceConnectionName:
                        sourceParams.connection?.connectionName,
                    sourceConfigurationName: sourceParams.configurationName,
                    sourceSystemName: sourceParams.systemName,
                    sourceUri: sourceParams.uri,
                    mapperDef,
                },
            );
            return data;
        },
        [
            sourceParams.connection?.connectionName,
            sourceParams.configurationName,
            sourceParams.systemName,
            sourceParams.uri,
            axiosService,
            instanceId,
        ],
    );
    const testMapperDisabled = false;

    return (
        <>
            <LoadableRenderer
                loadable={connectionsConfigurationsResource.loadable}
                hasValue={connections => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <div
                            data-test="source-connection"
                            style={{ width: "45%" }}
                        >
                            <OptionsResourceSelect
                                connectionLabelKey="metadata-params-form.connection-from.label"
                                instanceId={instanceId}
                                connectionTypes={sourceConnectionTypes}
                                connections={connections}
                                setOptionsResource={setInputOptionsResource}
                                resetResource={resetInputOptionsResource}
                                setParams={setSourceParams}
                                initialConnectionId={initialSourceConnectionId}
                            />
                        </div>
                        <div
                            data-test="target-connection"
                            style={{ width: "45%" }}
                        >
                            <OptionsResourceSelect
                                connectionLabelKey="metadata-params-form.connection-to.label"
                                instanceId={instanceId}
                                connectionTypes={targetConnectionTypes}
                                connections={connections}
                                setOptionsResource={setOutputOptionsResource}
                                resetResource={resetOutputOptionsResource}
                                setParams={setTargetParams}
                                initialConnectionId={initialTargetConnectionId}
                            />
                        </div>
                    </div>
                )}
            />
            <Gap />
            <LoadableRenderer
                loadable={loadables}
                loading={() => <LoadableRenderer.Spinner />}
                hasError={([inputErrors, outputErrors]) =>
                    null && (
                        <>
                            {[]
                                .concat(inputErrors ?? [])
                                .concat(outputErrors ?? [])
                                .map(error => (
                                    <ConditionalErrorAlert
                                        key={error}
                                        error={error}
                                    />
                                ))}
                            <Gap size="small" />
                        </>
                    )
                }
                hasValue={([inputOptions, outputOptions, mapper]) => (
                    <MultilevelMapperTable
                        mapper={mapper}
                        inputOptions={inputOptions}
                        outputOptions={outputOptions}
                        readOnly={false}
                        submitButtonText={submitButtonText}
                        onSubmit={handleMapperSubmit}
                        testMapperResource={testMapperResource}
                        testMapperDisabled={testMapperDisabled}
                    />
                )}
            />
        </>
    );
};

ConnectionsMapper.propTypes = {
    initMapper: PropTypes.arrayOf(PropTypes.object.isRequired),
    sourceConnectionTypes: PropTypes.arrayOf(
        PropTypes.oneOf(Object.values(CONNECTION_TYPE)).isRequired, // can be also other string type?
    ).isRequired,
    targetConnectionTypes: PropTypes.arrayOf(
        PropTypes.oneOf(Object.values(CONNECTION_TYPE)).isRequired, // can be also other string type?
    ).isRequired,
    instanceId: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitButtonText: PropTypes.string,
    initialSourceConnectionId: PropTypes.number,
    initialTargetConnectionId: PropTypes.number,
};
