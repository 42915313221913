import PropTypes from "prop-types";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button, Icon } from "../../..";
import "./CellWithCopy.style.less";

export const CellWithCopy = ({ text, children }) => {
    return (
        <>
            {children}
            <CopyToClipboard text={text}>
                <Button
                    type="text"
                    size="small"
                    label={<Icon type="copy" />}
                    className="pmCellWithCopy"
                />
            </CopyToClipboard>
        </>
    );
};

CellWithCopy.propTypes = {
    text: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export default CellWithCopy;
