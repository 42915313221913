import { ButtonMenu, Modal, UnityLayout } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { TableLocalFiltered } from "../../TableLocalFiltered";

export const columns = [
    {
        label: t("feature-flags.not-available-partitions.column.partitionName"),
        name: "partitionName",
        type: fieldTypes.TEXT,
    },
    {
        label: t("feature-flags.not-available-partitions.column.projectName"),
        name: "projectName",
        type: fieldTypes.TEXT,
    },
    {
        label: t("feature-flags.not-available-partitions.column.status"),
        name: "status",
        type: fieldTypes.TEXT,
    },
];

const NotAvailablePartitionsModal = ({
    partitionsWithErrors,
    visible,
    onCancel: onPassedCancel,
}) => {
    const onCancel = () => {
        onPassedCancel?.();
    };

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("feature-flags.not-available-partitions.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <TableLocalFiltered
                        columns={columns}
                        dataSource={partitionsWithErrors ?? []}
                        pagination={false}
                        rowKey="partitionId"
                        padding={false}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.cancel"),
                                    type: "text",
                                    onClick: onCancel,
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

NotAvailablePartitionsModal.propTypes = {
    partitionsWithErrors: PropTypes.array,
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func,
};

export default NotAvailablePartitionsModal;
