import * as React from "react";

const DisableSubmitOnEnter = ({ children }) => {
    return (
        <div
            onKeyPress={e => {
                // Blocks form submit on unwanted Enter
                if (e.charCode === 13) {
                    e.preventDefault();
                }
            }}
        >
            {children}
        </div>
    );
};

export default DisableSubmitOnEnter;
