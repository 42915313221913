import {
    ButtonMenu,
    Form,
    Gap,
    Input,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { T, t } from "@/translations";
import { isEmailValid } from "@/utils/validation";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const InviteForm = ({ username, setUsername, visible, form }) => {
    const { getFieldDecorator, resetFields } = form;

    useEffect(() => {
        resetFields();
    }, [resetFields, visible]);

    return (
        <Form hideRequiredMark>
            <Form.Item
                label={<T id="account-admin-users-list.modal.username-label" />}
                required
                colon={false}
            >
                {getFieldDecorator("username", {
                    rules: [
                        {
                            validator: () => isEmailValid(username),
                            message: t(
                                "account-admin-users-list.modal.validation.message",
                            ),
                        },
                    ],
                    validateTrigger: ["onChange", "onMouseLeave", "onBlur"],
                })(
                    <Input
                        type="username"
                        onChange={event => setUsername(event.target.value)}
                    />,
                )}
            </Form.Item>
        </Form>
    );
};

InviteForm.propTypes = {
    username: PropTypes.string.isRequired,
    setUsername: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
};

const InviteFormWrapper = Form.create({
    name: "invite_user",
})(InviteForm);

export const InviteUserModalComponent = ({ visible, onOk, onCancel }) => {
    const [username, setUsername] = useState("");
    useEffect(() => {
        setUsername("");
    }, [visible]);

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("account-admin-users-list.modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Text>
                        <T id="account-admin-users-list.modal.invite-user-desc" />
                    </Text>
                    <Gap />
                    <InviteFormWrapper
                        username={username}
                        setUsername={setUsername}
                        visible={visible}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t(
                                        "account-admin-users-list.modal.invite-user",
                                    ),
                                    onClick: () => onOk(username),
                                    type: "primary",
                                    disabled: !isEmailValid(username),
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onCancel,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

InviteUserModalComponent.propTypes = {
    visible: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
