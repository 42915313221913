import PropTypes from "prop-types";
import React from "react";
import "./PlatformManagerTemplate.style.less";

export const PlatformManagerTemplate = ({ header, children }) => {
    return (
        <>
            {header}
            <div className="pmTemplate">{children}</div>
        </>
    );
};

PlatformManagerTemplate.propTypes = {
    header: PropTypes.node,
    children: PropTypes.node,
};
