import { Forms, Gap, Text } from "@/components/DesignSystem/";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    LoadableRenderer,
    pendingPromise,
    useComposeLoadablesMemoized,
    useQueryLoadable,
} from "@/modules/loadable";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { PartitionSelectForm } from "./PartitionSelectForm.component";
import Warning from "./Warning.component";

const PartitionWarning = ({
    onConfirm,
    onCancel,
    accountId,
    partitionId,
    allowSelect,
    templateResource,
    pending,
    visible,
    alert,
}) => {
    const { templateService } = useDic();
    const { formId, handleSubmit } = Forms.useForm({
        onSubmit: ({ values }) =>
            onConfirm({ destinationId: values.partitionId || partitionId }),
    });

    const partitionsResource = useQueryLoadable(
        async () =>
            accountId && visible
                ? templateService.fetchPartitionForDeploy(accountId)
                : pendingPromise(),
        [accountId, visible, templateService],
    );

    const resources = useComposeLoadablesMemoized([
        templateResource.loadable,
        partitionsResource.loadable,
    ]);

    return (
        <LoadableRenderer
            loading={() => null}
            loadable={resources}
            hasValue={([template, partitions]) => {
                const selectedPartition = partitions.find(
                    partition => partition.id === partitionId,
                );
                return (
                    <Warning
                        onConfirm={() => {
                            onConfirm({ destinationId: partitionId });
                        }}
                        onCancel={onCancel}
                        title={t("warning-step.title.accelerator")}
                        formId={allowSelect ? formId : undefined}
                        isDisabledConfirm={pending}
                        visible={visible}
                        alert={alert}
                    >
                        <Text>
                            {allowSelect
                                ? t("warning-step.message.no-partition", {
                                      templateName: (
                                          <strong
                                              key="templateName"
                                              id={`${partitionId}-templateName`}
                                          >
                                              {template.label}
                                          </strong>
                                      ),
                                  })
                                : t("warning-step.message.partition", {
                                      templateName: (
                                          <strong
                                              key="templateName"
                                              id={`${partitionId}-templateName`}
                                          >
                                              {template.label}
                                          </strong>
                                      ),
                                      serialNumber: (
                                          <strong
                                              key="serialNumber"
                                              id={`${partitionId}-serialNumber`}
                                          >
                                              {selectedPartition?.serialNumber}
                                          </strong>
                                      ),
                                  })}
                        </Text>
                        <Text>{t("warning-step.message.note")}</Text>
                        <Gap />
                        <Text type="error">
                            {t("warning-step.message.importantPart")}
                        </Text>
                        <Gap />
                        {allowSelect && (
                            <PartitionSelectForm
                                formId={formId}
                                partitions={partitions}
                                handleSubmit={handleSubmit}
                            />
                        )}
                    </Warning>
                );
            }}
        />
    );
};

PartitionWarning.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    partitionId: PropTypes.number,
    allowSelect: PropTypes.bool.isRequired,
    pending: PropTypes.bool,
    visible: PropTypes.bool,
    templateResource: PropTypes.object.isRequired,
    alert: PropTypes.object,
};

export default PartitionWarning;
