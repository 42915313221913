import { naturallySortOptions } from "@/utils/form.utils";

export const EVENT_TYPES_OPTIONS = [
    {
        value: "CALCULATION_COMPLETED_PL",
        label: "CALCULATION_COMPLETED_PL",
    },
    {
        value: "CALCULATION_COMPLETED_SIM",
        label: "CALCULATION_COMPLETED_SIM",
    },
    {
        value: "CALCULATION_COMPLETED_CFS",
        label: "CALCULATION_COMPLETED_CFS",
    },
    {
        value: "CALCULATION_COMPLETED_PG",
        label: "CALCULATION_COMPLETED_PG",
    },
    {
        value: "CALCULATION_COMPLETED_RC",
        label: "CALCULATION_COMPLETED_RC",
    },
    {
        value: "ITEM_APPROVED_CT",
        label: "ITEM_APPROVED_CT",
    },
    {
        value: "ITEM_APPROVED_PGI",
        label: "ITEM_APPROVED_PGI",
    },
    {
        value: "ITEM_APPROVED_PL",
        label: "ITEM_APPROVED_PL",
    },
    {
        value: "ITEM_APPROVED_Q",
        label: "ITEM_APPROVED_Q",
    },
    {
        value: "ITEM_APPROVED_RR",
        label: "ITEM_APPROVED_RR",
    },
    {
        value: "ITEM_APPROVED_DCR",
        label: "ITEM_APPROVED_DCR",
    },
    {
        value: "ITEM_UPDATE_RR",
        label: "ITEM_UPDATE_RR",
    },
    {
        value: "ITEM_UPDATE_PR",
        label: "ITEM_UPDATE_PR",
    },
    {
        value: "ITEM_UPDATE_PGI",
        label: "ITEM_UPDATE_PGI",
    },
    {
        value: "ITEM_UPDATE_MPLI",
        label: "ITEM_UPDATE_MPLI",
    },
    {
        value: "CHANGE_NOTIFICATION_CA",
        label: "CHANGE_NOTIFICATION_CA",
    },
    {
        value: "CHANGE_NOTIFICATION_MPL",
        label: "CHANGE_NOTIFICATION_MPL",
    },
    {
        value: "WORKFLOW_MESSAGE",
        label: "WORKFLOW_MESSAGE",
    },
    {
        value: "PADATALOAD_COMPLETED",
        label: "PADATALOAD_COMPLETED",
    },
    {
        value: "CUSTOM",
        label: "CUSTOM",
    },
    {
        value: "ITEM_UPDATE_PPV",
        label: "ITEM_UPDATE_PPV",
    },
    {
        value: "CHANGE_NOTIFICATION_PP",
        label: "CHANGE_NOTIFICATION_PP",
    },
    {
        value: "WORKFLOW_SUBMIT",
        label: "WORKFLOW_SUBMIT",
    },
    {
        value: "ITEM_DENIED_PGI",
        label: "ITEM_DENIED_PGI",
    },
    {
        value: "ITEM_UPDATE_Q",
        label: "ITEM_UPDATE_Q",
    },
    {
        value: "ITEM_UPDATE_CT",
        label: "ITEM_UPDATE_CT",
    },
    {
        value: "ITEM_APPROVED_RBA",
        label: "ITEM_APPROVED_RBA",
    },
    {
        value: "ITEM_APPROVED_CL",
        label: "ITEM_APPROVED_CL",
    },
    {
        value: "ITEM_UPDATE_CL",
        label: "ITEM_UPDATE_CL",
    },
    {
        value: "JOB_FAILED",
        label: "JOB_FAILED",
    },
    {
        value: "ACTION_STATUS",
        label: "ACTION_STATUS",
    },
    {
        value: "ITEM_APPROVED_CR",
        label: "ITEM_APPROVED_CR",
    },
    {
        value: "ITEM_UPDATE_RBA",
        label: "ITEM_UPDATE_RBA",
    },
    {
        value: "ASYNC_LOADDATA_COMPLETED",
        label: "ASYNC_LOADDATA_COMPLETED",
    },
    {
        value: "ITEM_UPDATE_CGI",
        label: "ITEM_UPDATE_CGI",
    },
    {
        value: "CALCULATION_COMPLETED_CG",
        label: "CALCULATION_COMPLETED_CG",
    },
    {
        value: "ITEM_PL_REVOKED",
        label: "ITEM_PL_REVOKED",
    },
    {
        value: "ITEM_UPDATE_CO",
        label: "ITEM_UPDATE_CO",
    },
    {
        value: "ITEM_APPROVED_CGI",
        label: "ITEM_APPROVED_CGI",
    },
    {
        value: "ITEM_DENIED_CGI",
        label: "ITEM_DENIED_CGI",
    },
];

export const EVENT_TYPES_OPTIONS_SORTED =
    naturallySortOptions(EVENT_TYPES_OPTIONS);

const EMPTY_UI_REPREZENTATION = "_null_";
const EMPTY_API_REPREZENTATION = null;
export const mqClusterFrom = value =>
    value === EMPTY_UI_REPREZENTATION ? EMPTY_API_REPREZENTATION : value;
export const mqClusterTo = value =>
    value === EMPTY_API_REPREZENTATION ? EMPTY_UI_REPREZENTATION : value;
export const KAFKA_CLUSTER_OPTIONS = [
    {
        value: EMPTY_UI_REPREZENTATION,
        label: "[empty]",
    },
    {
        value: "QA",
        label: "QA",
    },
    {
        value: "PROD",
        label: "PROD",
    },
];
