import { Modal, UnityLayout } from "@/components/DesignSystem";
import { CopyFilterForm } from "@/components/Filters/CopyFilter/CopyFilterForm";
import { useMemoByDeepEquality } from "@/components/hooks/useMemoByDeepEquality.hook";
import { AlertsContextProvider } from "@/components/PageLayout/Alerts/AlertsContextProvider";
import { useHeaderAlertProp } from "@/components/PageLayout/useSetAlert.hook";
import { ModalProviderPropsPropType } from "@/modules/modal/CustomModal";
import { t } from "@/translations";
import React from "react";

export const CopyFilterModalInner = ({
    modalProviderProps: { onClose, visible },
    accountId,
    filterId,
    initialValues: initialValuesProp,
    afterSuccess,
}) => {
    const initialValues = useMemoByDeepEquality(initialValuesProp);
    const handleAfterSuccess = () => {
        afterSuccess?.();
        onClose();
    };
    const alerts = useHeaderAlertProp();

    return (
        <Modal onClose={onClose} visible={visible}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("filter.copy.modal.title")}
                    alert={alerts}
                />
                <CopyFilterForm
                    accountId={accountId}
                    filterId={filterId}
                    initialValues={initialValues}
                    afterSuccess={handleAfterSuccess}
                    onCancel={onClose}
                />
            </UnityLayout>
        </Modal>
    );
};

CopyFilterModalInner.propTypes = {
    modalProviderProps: ModalProviderPropsPropType(),
};

export const CopyFilterModal = props => (
    <AlertsContextProvider>
        <CopyFilterModalInner {...props} />
    </AlertsContextProvider>
);
