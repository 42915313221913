import isString from "lodash/isString";
import React from "react";

const ContactEmailsColumnRender = ({ contactEmails }) => {
    const contactEmailsArray = isString(contactEmails)
        ? contactEmails.split(",")
        : [];

    return contactEmailsArray.length > 1 ? (
        <React.Fragment>
            {contactEmailsArray.length + " contacts"}
        </React.Fragment>
    ) : (
        <React.Fragment>{contactEmails}</React.Fragment>
    );
};

export default ContactEmailsColumnRender;
