import { axiosService } from "../axios";
import qs from "qs";

const qsOptions = { addQueryPrefix: true };

export const metadataService = {
    fetchEntityTypeNames(objectType, objectId, type, filters) {
        return axiosService.post(
            `/api/metadata/${objectType}/${objectId}/metadata/${type}/entity-names`,
            filters,
        );
    },

    fetchAttributesMetadata(objectType, objectId, type, name, queryParams) {
        const queryStr = queryParams
            ? qs.stringify(queryParams, qsOptions)
            : "";

        return axiosService.get(
            `/api/metadata/${objectType}/${objectId}/metadata/${type}/entity-names/${
                name ? name : type
            }/sub-fields${queryStr}`,
        );
    },

    fetchGeneralMapperInstanceMetadata(
        instanceId,
        connectionName,
        configurationName,
        fetchParams,
    ) {
        return axiosService.post(
            `/api/general-mapper/instance/${instanceId}/metadata?connectionName=${connectionName}&configurationName=${configurationName}`,
            fetchParams,
        );
    },
};
