import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useQueryLoadable, waitForValue } from "@/modules/loadable";
import { SecurityContext } from "@/security/authorization";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useContext } from "react";

const { useForm, Fields, success, error, pmValidators, useFormIsSubmitting } =
    Forms;

const validateUniqName = (userId, isValid) => async (name, getBag) => {
    if (isEmpty(name)) {
        return error(t("general.validation.required"));
    }
    const bag = await getBag();
    const result = await isValid({ ...bag?.values, name, userId });

    return !result.data
        ? error(t("elastic-search.validation.query-name.unique"))
        : success();
};

export const QueryModal = ({
    accountId,
    entityId,
    entityType,
    close,
    visible,
    onSubmit,
    initialValues,
    isValid,
}) => {
    const securityContext = useContext(SecurityContext);

    const { Form, formId } = useForm({
        initialValues: {},
        onSubmit,
    });
    const isSubmitting = useFormIsSubmitting(formId);

    const { elkQueryService } = useDic();

    const availableUsersResource = useQueryLoadable(() => {
        return visible
            ? elkQueryService
                  .fetchUsers(accountId, entityId, entityType)
                  .then(getData)
                  .then(users =>
                      users
                          .filter(user => user.id !== securityContext.user.id)
                          .map(user => ({
                              label: user.username,
                              value: user.username,
                          })),
                  )
            : waitForValue();
    }, [
        accountId,
        elkQueryService,
        entityId,
        entityType,
        securityContext.user.id,
        visible,
    ]);

    const availableUsersSelectProps = getLoadableSelectProps(
        availableUsersResource.loadable,
    );

    return (
        visible && (
            <Modal
                visible={visible}
                onClose={close}
                // transitionName="none" // Disabling modal animation to temporary fix for ucForms calculating width of modal
                // maskTransitionName="none"
            >
                <UnityLayout>
                    <UnityLayout.Header
                        size={3}
                        title={
                            initialValues?.id
                                ? t("elastic-search.modal.edit-query")
                                : t("elastic-search.modal.save-as-new-query")
                        }
                    />
                    <UnityLayout.Content padding={[false, true]}>
                        <Form>
                            <Fields.Input
                                name="id"
                                initialValue={initialValues.id}
                                type="hidden"
                            />
                            <Fields.Input
                                name="name"
                                initialValue={initialValues.name}
                                label={t(
                                    "elastic-search.modal.form.query-name",
                                )}
                                placeholder={t(
                                    "elastic-search.modal.form.placeholder.query-name",
                                )}
                                validator={validateUniqName(
                                    securityContext.user.id,
                                    isValid,
                                )}
                                inputWidth="max"
                            />
                            <Fields.Input
                                name="query"
                                initialValue={initialValues.query}
                                label={t(
                                    "elastic-search.modal.form.query-string",
                                )}
                                placeholder={t(
                                    "elastic-search.modal.form.placeholder.query-string",
                                )}
                                validator={pmValidators.isRequired}
                                inputWidth="max"
                            />

                            <Fields.Select
                                name="usernames"
                                label={t("elastic-search.modal.form.share")}
                                placeholder={t(
                                    "elastic-search.modal.form.placeholder.share",
                                )}
                                mode="tags"
                                {...availableUsersSelectProps}
                                inputWidth="max"
                            />
                        </Form>
                    </UnityLayout.Content>
                    <UnityLayout.Footer
                        actionButtons={
                            <ButtonMenu
                                buttons={[
                                    {
                                        label: t("general.save"),
                                        type: "primary",
                                        formId,
                                        disabled: isSubmitting,
                                    },
                                    {
                                        label: t("general.cancel"),
                                        onClick: close,
                                        type: "text",
                                    },
                                ]}
                            />
                        }
                    />
                </UnityLayout>
            </Modal>
        )
    );
};

QueryModal.propTypes = {
    accountId: PropTypes.number.isRequired,
    entityId: PropTypes.number.isRequired,
    entityType: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    availableUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
    initialValues: PropTypes.object,
    isValid: PropTypes.func.isRequired,
};
