import { useDic } from "@/components/Dic/useDic.hook";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { ClusterUpgradeOverviewAccount } from "@/components/UpgradeScheduling/ClusterUpgradeOverviewAccount.component";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { CLUSTER_UPGRADE_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import React from "react";
import { useSetPageTitle } from "../PageLayout/useSetPageTitle.hook";

export const ClusterUpgradeAccountPage = () => {
    useFullWidthTableLayout();
    const { accountId } = useAccountAppParams();

    useSetPageTitle(t("cluster.upgrade.overview.header"));
    const {
        locationRouterService,
        accountAppLocations: { upgradesNewLocation },
    } = useDic();

    useBreadcrumbButton({
        label: "cluster.upgrade.new-request",
        onClick: () => locationRouterService.navigate(upgradesNewLocation),
        permissions: CLUSTER_UPGRADE_PERMISSIONS,
    });

    return <ClusterUpgradeOverviewAccount accountId={accountId} fixed />;
};
