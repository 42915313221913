import { toVersionNumber } from "@/utils/versionUtils";
import qs from "qs";
import { axiosService } from "../axios";

const qsOptions = { addQueryPrefix: true };

export const registryService = {
    getPartitions({ queryParams: { sort, filter, ...restGetParams } }) {
        const updatedSort = toVersionNumber(
            sort,
            "releaseName",
            "releaseNameNumber",
        );
        const params = qs.stringify(
            { sort: updatedSort, ...restGetParams },
            qsOptions,
        );

        return axiosService.post(
            `/api/registry/partition-info${params}`,
            filter,
        );
    },
    deletePartition(serialNumber) {
        return axiosService.delete(`/api/registry/partition/${serialNumber}`);
    },
    removePartition(partitionId) {
        return axiosService.delete(
            `/api/registry/partition-info/${partitionId}`,
        );
    },
    getUpdates({ queryParams: { filter, ...restGetParams } }) {
        const params = qs.stringify(restGetParams, qsOptions);
        return axiosService.post(`/api/registry/update-info${params}`, filter);
    },
    getRemoteLoginUsers() {
        return axiosService.get("/api/registry/remote-login-users");
    },
    getRemoteLoginInstances() {
        return axiosService.get("/api/registry/remote-login-instances");
    },
    partitionNamesByCluster(clusterName) {
        return axiosService.get(
            `/api/registry/partition-names-by-cluster/${clusterName}`,
        );
    },
};
