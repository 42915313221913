import { useState, useMemo } from "react";

export default function useToggle(initialState = false) {
    const [isOn, setState] = useState(initialState);
    const setters = useMemo(
        () => ({
            setOn: () => setState(true),
            setOff: () => setState(false),
            toggle: () => setState(state => !state),
        }),
        [],
    );
    return [isOn, setters];
}
