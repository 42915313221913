import { createRelatedContactsColumns } from "@/components/CustomerDashboard/RelatedContacts.component";
import { Gap, H3, SwitchAsync, Text } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useSetErrorAlert } from "@/components/PageLayout/useSetAlert.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import React, { useEffect, useMemo, useState } from "react";
import { accountsService } from "../../services/accounts.service";

const preferencesType = preferencesTypes.CONTACTS_MANAGEMENT;

const generateColumns = ({ accountsService, accountId }) => {
    let relatedContactsColumns = createRelatedContactsColumns();
    relatedContactsColumns.push({
        label: t("related-contacts.header.visible"),
        name: "visible",
        type: fieldTypes.BOOLEAN,
        render: (value, record) => (
            <SwitchAsync
                textOn={t("related-contacts.switch.visible")}
                textOff={t("related-contacts.switch.hidden")}
                initialValue={value}
                performCall={({ checked }) =>
                    accountsService.updateRelatedContact(accountId, {
                        visible: checked,
                        id: record.id,
                        owner: record.owner,
                    })
                }
                error="related-contacts.switch.failed"
            />
        ),
    });

    return relatedContactsColumns;
};

export const ContactsManagement = () => {
    const { accountId } = useAccountAppParams();
    const [contacts, setContacts] = useState([]);
    const setErrorAlert = useSetErrorAlert();

    const columns = useMemo(
        () => generateColumns({ accountsService, accountId }),
        [accountId],
    );

    useEffect(() => {
        accountsService
            .fetchRelatedContact(accountId)
            .then(res => setContacts(res.data))
            .catch(e => setErrorAlert(getErrorMessage(e.response.data)));
    }, [accountId, setErrorAlert]);

    return (
        <>
            <H3>{t("related-contacts.drop-down.text")}</H3>
            <Text size="large">{t("contacts-management.description")}</Text>
            <Gap size="large" />
            <TableWithPreferencesManagement
                className="pmTable--condensed pmTable--tallCells related-contacts"
                rowKey="id"
                columns={columns}
                dataSource={contacts}
                datasetSlicing="local"
                preferencesType={preferencesType}
            />
        </>
    );
};

ContactsManagement.propTypes = {};
