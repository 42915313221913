import FormattedDate from "@/components/DateTime/FormattedDate";
import { Alert, Gap, P, Table } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import moment from "moment";
import React from "react";
import PropTypes from "prop-types";

const ConfirmChangesModalContent = ({ comparedEnvs, deletedEnvNames }) => (
    <>
        {comparedEnvs.map(({ envName, changes }) => (
            <>
                <Gap size="small" />
                <b>
                    {t("instance-edit-form.confirmation.column.table-title", {
                        envName,
                    })}
                </b>
                <Table
                    key={envName}
                    rowKey="item"
                    columns={[
                        {
                            label: t(
                                "instance-edit-form.confirmation.column.item",
                            ),
                            name: "item",
                            type: fieldTypes.TEXT,
                        },
                        {
                            label: t(
                                "instance-edit-form.confirmation.column.original",
                            ),
                            name: "original",
                            type: fieldTypes.TEXT,
                        },
                        {
                            label: t(
                                "instance-edit-form.confirmation.column.newValue",
                            ),
                            name: "newValue",
                            type: fieldTypes.TEXT,
                            render: text => {
                                if (moment.isMoment(text)) {
                                    return <FormattedDate date={text} />;
                                }
                                return text;
                            },
                        },
                    ]}
                    dataSource={changes}
                    padding={false}
                />
            </>
        ))}
        {deletedEnvNames.map(envName => (
            <>
                <Gap size="small" />
                <b>
                    {t("instance-edit-form.confirmation.column.table-title", {
                        envName,
                    })}
                </b>
                <Alert
                    showIcon
                    type="warning"
                    message={
                        <>
                            {t(
                                "instance-edit-form.confirmation.delete-warning.text",
                                { envName },
                            )}
                        </>
                    }
                />
            </>
        ))}
    </>
);

export default ConfirmChangesModalContent;

ConfirmChangesModalContent.propTypes = {
    comparedEnvs: PropTypes.array.isRequired,
    deletedEnvNames: PropTypes.array.isRequired,
};
