import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import FormattedDuration from "@/components/DateTime/FormattedDuration";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";

export const clusterColumns = hasRights => [
    {
        type: fieldTypes.TEXT,
        label: t("clusters-list.header.name"),
        name: "name",
    },
    {
        type: fieldTypes.TEXT,
        label: t("clusters-list.header.project-name"),
        name: "projectName",
    },
    {
        visible: false,
        type: fieldTypes.TEXT,
        label: t("clusters-list.header.account-status"),
        name: "accountStatus",
    },
    {
        type: fieldTypes.TEXT,
        label: t("clusters-list.header.type"),
        name: "type",
    },
    {
        type: fieldTypes.TEXT,
        label: t("clusters-list.header.url"),
        name: "url",
    },
    {
        type: fieldTypes.TEXT,
        label: t("clusters-list.header.release-name"),
        name: "releaseName",
    },
    {
        visible: false,
        type: fieldTypes.TEXT,
        label: t("clusters-list.header.version"),
        name: "version",
    },
    {
        type: fieldTypes.DATETIME,
        label: t("clusters-list.header.last-upgrade"),
        name: "lastUpgrade",
        render: (text, record) =>
            record.lastUpgrade && (
                <FormattedDateTime>{record.lastUpgrade}</FormattedDateTime>
            ),
    },
    {
        type: fieldTypes.DATETIME,
        label: t("clusters-list.header.next-upgrade"),
        name: "nextUpgrade",
        render: (text, record) =>
            record.nextUpgrade && (
                <FormattedDateTime>{record.nextUpgrade}</FormattedDateTime>
            ),
    },
    {
        visible: hasRights,
        type: fieldTypes.TEXT,
        label: t("clusters-list.header.custom-upgrade-time.major"),
        name: "majorUpgradeDuration",
        render: (text, record) =>
            record.majorUpgradeDuration && (
                <FormattedDuration>
                    {record.majorUpgradeDuration}
                </FormattedDuration>
            ),
    },
    {
        visible: hasRights,
        type: fieldTypes.TEXT,
        label: t("clusters-list.header.custom-upgrade-time.minor"),
        name: "minorUpgradeDuration",
        render: (text, record) =>
            record.minorUpgradeDuration && (
                <FormattedDuration>
                    {record.minorUpgradeDuration}
                </FormattedDuration>
            ),
    },
    {
        visible: false,
        type: fieldTypes.TEXT,
        label: t("general.cloud-provider"),
        name: "cloudProvider",
    },
    {
        type: fieldTypes.TEXT,
        label: t("clusters-list.header.database-vendor"),
        name: "databaseVendor",
    },
    {
        type: fieldTypes.TEXT,
        label: t("clusters-list.header.database-version"),
        name: "databaseVersion",
    },
    {
        visible: false,
        visibleInPanel: true,
        type: fieldTypes.TEXT,
        label: t("general.checkin-date"),
        name: "checkinDate",
        render: (text, record) => (
            <FormattedDateTime fromNow>{record.checkinDate}</FormattedDateTime>
        ),
        canFilter: false,
    },
    {
        visible: false,
        visibleInPanel: true,
        type: fieldTypes.TEXT,
        label: t("clusters-list.header.custom-upgrade-time.cluster-overrides"),
        name: "clusterOverrides",
        canFilter: false,
    },
    {
        visible: false,
        type: fieldTypes.TEXT,
        label: t("general.location"),
        name: "location",
    },
    {
        visible: false,
        type: fieldTypes.TEXT,
        label: t("clusters-list.header.account-id"),
        name: "accountId",
    },
];
