import mixpanel from "mixpanel-browser";
import {
    getMixpanelConfiguration,
    getMixpanelToken,
    mixpanelDisabled,
} from "./mixpanel.utils";

export function createMixpanel() {
    const token = getMixpanelToken();
    const config = getMixpanelConfiguration();
    const disabled = mixpanelDisabled();

    mixpanel.init(token, config);
    if (disabled) {
        mixpanel.disable();
    }

    // Expose mixpanel to the global scope for Appcues integration
    window.mixpanel = mixpanel;

    return mixpanel;
}
