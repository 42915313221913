import { useDic } from "@/components/Dic/useDic.hook";
import { getFieldByType } from "@/components/FeatureFlags/FeatureFlagValue";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useMutationLoadableWithNotification } from "@/modules/loadable";
import { fromPairs, get, map, noop, pipe } from "lodash/fp";
import { DEFAULT_SET } from "./AddPartitionModal";
import { toApiKey } from "./utils";

const formatDataOutput = data => {
    return data.map(({ value, ...rest }) => ({
        ...rest,
        value: getFieldByType(rest.type).to(value),
    }));
};

export const useApplyAdminFeatureFlagsMutation = ({ onSuccess = noop }) => {
    const { axiosService } = useDic();
    const onSuccessCurrent = useCurrentHandler(onSuccess);

    return useMutationLoadableWithNotification(
        async paramsAndFeatureSets => {
            const payload = pipe(
                map(({ params, data }) => [
                    toApiKey(params),
                    formatDataOutput(data),
                ]),
                fromPairs,
            )(paramsAndFeatureSets);
            const configurationName = DEFAULT_SET;
            const { data } = await axiosService.post(
                `/api/partition-assets/feature-flags/${configurationName}`,
                payload,
            );
            onSuccessCurrent(data);
            return data;
        },
        [axiosService, onSuccessCurrent],
    );
};

export const useApplyPartitionFeatureFlagsMutation = ({ onSuccess = noop }) => {
    const { axiosService } = useDic();
    const onSuccessCurrent = useCurrentHandler(onSuccess);

    return useMutationLoadableWithNotification(
        async paramsAndFeatureSets => {
            const promises = map(({ params, data }) =>
                axiosService
                    .post(
                        `/api/projects/${params.projectId}/partition-assets/${params.partitionId}/feature-flags/${params.configurationName}`,
                        formatDataOutput(data),
                    )
                    .then(get("data")),
            )(paramsAndFeatureSets);

            const data = await Promise.all(promises);
            onSuccessCurrent(data);
            return data;
        },
        [axiosService, onSuccessCurrent],
    );
};
