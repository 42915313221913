import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { ParentWarning } from "@/components/Mappers/ParentWarning";
import { t } from "@/translations";
import { getColumnOptions } from "@/utils/tableUtils";
import React from "react";
import { RouteStatus } from "../IntegrationRoutes/components/RouteStatus";

export const certificatesListColumns = certificates => [
    {
        label: t("certificate-list.header.unique-name"),
        type: fieldTypes.TEXT,
        name: "name",
        className: "pmTable__column--withActionButton",
    },
    {
        type: fieldTypes.TEXT,
        label: t("general.parent"),
        name: "parent",
        render: (text, record) => (
            <ParentWarning parent={record.parent} synced={record.synced} />
        ),
    },
    {
        label: t("certificate-list.header.type"),
        type: fieldTypes.TEXT,
        name: "type",
    },
    {
        type: fieldTypes.OPTION,
        options: certificates && getColumnOptions(certificates, "status"),
        label: t("certificate-list.header.status"),
        name: "status",
        render: (_, record) => (
            <>
                <RouteStatus status={record.status} />
            </>
        ),
    },
    {
        label: t("general.header.created-at"),
        name: "createdAt",
        type: fieldTypes.DATETIME,
        render: (_, d) => <FormattedDateTime>{d.createdAt}</FormattedDateTime>,
    },
    {
        label: t("general.header.created-by"),
        type: fieldTypes.TEXT,
        name: "createdBy",
    },
    {
        label: t("general.header.updated-at"),
        type: fieldTypes.DATETIME,
        name: "updatedAt",
        render: (_, d) => <FormattedDateTime>{d.updatedAt}</FormattedDateTime>,
    },
    {
        label: t("general.header.updated-by"),
        type: fieldTypes.TEXT,
        name: "updatedBy",
    },
];
