import PropTypes from "prop-types";
import React, { useState } from "react";

export const initialState = {
    current: 0,
    steps: [],
};

export const HeaderStepsContext = React.createContext(initialState);

const HeaderStepsContextProvider = ({ children }) => {
    const [headerSteps, setHeaderSteps] = useState(initialState);

    return (
        <HeaderStepsContext.Provider
            value={{
                headerSteps,
                setHeaderSteps,
            }}
        >
            {children}
        </HeaderStepsContext.Provider>
    );
};

HeaderStepsContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default HeaderStepsContextProvider;
