import ClusterNodesList from "@/components/Clusters/ClusterNodesList.component";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import React from "react";

export const ClusterAdminPage = () => {
    useFullWidthTableLayout();
    return <ClusterNodesList />;
};

export default ClusterAdminPage;
