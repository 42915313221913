import { Icon } from "@/components/DesignSystem/Icon";
import PropTypes from "prop-types";
import React from "react";
import styles from "./AlertSeverityFormatter.less";

function getIcon(value) {
    switch (value) {
        case "CRITICAL":
            return (
                <Icon
                    type="exclamation-triangle"
                    style={{ color: "#EA3A6E" }}
                />
            );
        case "HIGH":
            return <Icon type="info-circle" style={{ color: "#F9BB46" }} />;
        case "MEDIUM":
            return <Icon type="info-circle" style={{ color: "#2955A3" }} />;
        case "LOW":
            return <Icon type="info-circle" style={{ color: "#47B719" }} />;
    }
}

const AlertSeverityFormatter = ({ value }) => {
    return (
        <div className={styles.capitalize}>
            {getIcon(value)}
            {value?.toLowerCase()}
        </div>
    );
};

AlertSeverityFormatter.propTypes = {
    value: PropTypes.string.isRequired,
};

export default AlertSeverityFormatter;
