import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useMutationLoadableWithNotification } from "@/modules/loadable";
import { integrationPropertiesService } from "@/services/integration.property.service";
import { getData } from "@/services/utils";
import { noop } from "lodash";

export const useSaveIntegrationPropertyMutation = ({
    entityType,
    entityId,
    afterSuccess = noop,
    onError = noop,
}) => {
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const onErrorCurrent = useCurrentHandler(onError);

    return useMutationLoadableWithNotification(
        async ({ values }) =>
            integrationPropertiesService
                .saveProperty(entityType, entityId, values)
                .then(getData)
                .then(data => {
                    afterSuccessCurrent(data);
                })
                .catch(e => {
                    onErrorCurrent(e);
                    throw e;
                }),
        [entityType, entityId, afterSuccessCurrent, onErrorCurrent],
        "edit-property.modal.success",
        null,
    );
};
