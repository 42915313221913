import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import ServersList from "@/components/Servers/ServersList.component";
import { showWhenAuthorizedByPermissionOr } from "@/security/authorization";
import React from "react";

const ServersListPage = () => {
    useFullWidthTableLayout();
    return <ServersList />;
};

export default showWhenAuthorizedByPermissionOr([
    "servers.read",
    "servers.edit",
])(ServersListPage);
