import ConnectionsList from "@/components/Connections/ConnectionsList";
import { InstanceRepoConnections } from "@/components/Connections/InstanceRepoConnections";
import { useDic } from "@/components/Dic/useDic.hook";
import { ConditionalImConfigErrorAlert } from "@/components/Error/ConditionalImConfigErrorAlert";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    HeaderTabs,
    useHeaderTabs,
} from "@/components/PageLayout/components/HeaderTabs.component";
import { useRefreshAction } from "@/components/PageLayout/useRefreshAction.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import React, { useReducer } from "react";
import { useEditInstanceBreadcrumbButton } from "../../apps/accountApp/components/hooks/useEditInstanceBreadcrumbButton.hook";
import { getConnectionsListTrackName } from "../../mixpanel/buttonNames";

export const TABS = {
    INSTANCE_REPOSITORY: "INSTANCE_REPOSITORY",
    WORKSPACE: "WORKSPACE",
};

export const ConnectionsPage = () => {
    const { instanceId, accountId } = useAccountAppParams();
    const { activeKey, setActiveKey } = useHeaderTabs();
    const [reloadToken, reloadAll] = useReducer(s => ++s, 0);

    useFullWidthTableLayout();
    useRefreshAction(reloadAll);

    const {
        locationRouterService,
        accountAppLocations: { connectionNewLocation, connectionEditLocation },
    } = useDic();

    useEditInstanceBreadcrumbButton({
        instanceId,
        label: "connection-list.button.new",
        onClick: () => locationRouterService.navigate(connectionNewLocation),
        permissions: INTEGRATION_EDIT_PERMISSIONS,
        track: { name: getConnectionsListTrackName("New") },
    });
    const afterLocalCopy = useCurrentHandler(() => {
        setActiveKey(TABS.WORKSPACE);
        reloadAll();
    });
    const afterDeploy = useCurrentHandler(() => {
        setActiveKey(TABS.INSTANCE_REPOSITORY);
        reloadAll();
    });
    const onEdit = useCurrentHandler(record =>
        locationRouterService.navigate(connectionEditLocation, {
            connectionId: record.id,
        }),
    );

    return (
        <>
            <ConditionalImConfigErrorAlert instanceId={instanceId} />
            <HeaderTabs defaultActiveKey={TABS.INSTANCE_REPOSITORY}>
                <HeaderTabs.TabPane
                    tab="Instance Repository"
                    key={TABS.INSTANCE_REPOSITORY}
                    forceRender
                >
                    <InstanceRepoConnections
                        instanceId={instanceId}
                        visible={activeKey === TABS.INSTANCE_REPOSITORY}
                        afterLocalCopy={afterLocalCopy}
                        afterDelete={reloadAll}
                        reloadToken={reloadToken}
                    />
                </HeaderTabs.TabPane>
                <HeaderTabs.TabPane
                    tab="Workspace"
                    key={TABS.WORKSPACE}
                    forceRender
                >
                    <ConnectionsList
                        instanceId={instanceId}
                        accountId={accountId}
                        visible={activeKey === TABS.WORKSPACE}
                        onEdit={onEdit}
                        reloadToken={reloadToken}
                        afterDeploy={afterDeploy}
                    />
                </HeaderTabs.TabPane>
            </HeaderTabs>
        </>
    );
};
