export const formHelpers = {
    pathGet(object, paths) {
        const pathArray = Array.isArray(paths)
            ? paths
            : (paths || "").split(".");
        let value = object;
        pathArray.map(name => {
            if (value === "object") {
                value = value[name];
            } else {
                return null;
            }
        });
        return value;
    },

    /**
     * Looks up the given type name in the schemas definitions
     * @method lookupDefinition
     * @param {String} name
     * @param {any} schema
     */
    lookupDefinition(name, schema) {
        if (!schema || !schema.definitions) {
            return null;
        }
        const defs = schema.definitions;
        const answer = defs[name];

        if (!answer) {
            return answer;
        }

        const fullSchema = answer["fullSchema"];
        if (fullSchema) {
            return fullSchema;
        }
        // we may extend another, if so we need to copy in the base properties
        let extendsTypes = this.pathGet(answer, ["extends", "type"]);
        if (!extendsTypes) {
            return answer;
        }

        const $fullSchema = JSON.parse(JSON.stringify(answer));
        $fullSchema.properties = $fullSchema.properties || {};
        if (!Array.isArray(extendsTypes)) {
            extendsTypes = [extendsTypes];
        }
        extendsTypes.map(extendType => {
            if (typeof extendType === "string") {
                const extendDef = this.lookupDefinition(extendType, schema);
                const properties = this.pathGet(extendDef, ["properties"]);
                if (properties) {
                    properties.map((property, key) => {
                        $fullSchema.properties[key] = property;
                    });
                }
            }
        });
        answer["fullSchema"] = $fullSchema;
        return $fullSchema;
    },
};
