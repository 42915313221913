import { Button } from "@/components/DesignSystem/Button";
import { useConfirmModal } from "@/modules/modal/imperative/useConfirmModal.hook";
import { t } from "@/translations";
import { ReactComponent as Trash } from "@pricefx/unity-components/src/icons/unicons/trash-alt.svg";
import PropTypes from "prop-types";
import React from "react";

export const DeleteButtonWithConfirm = ({
    title = t("general.delete"),
    message,
    onConfirm,
    buttonTitle,
    confirmButtonTitle,
    style,
    onlyIcon,
    onlyLink,
    ...nextProps
}) => {
    const confirmModal = useConfirmModal();
    const onClick = () =>
        confirmModal.confirmDelete({
            title,
            okText: confirmButtonTitle,
            message,
            onConfirm,
        });

    const label = buttonTitle ? buttonTitle : t("general.delete");
    const buttonProps = onlyIcon
        ? {
              danger: true,
              tooltip: label,
              icon: Trash,
              type: "link",
          }
        : onlyLink
        ? {
              label,
              type: "link",
          }
        : {
              danger: true,
              label,
          };

    return (
        <Button
            style={style}
            onClick={onClick}
            {...buttonProps}
            {...nextProps}
        />
    );
};

DeleteButtonWithConfirm.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onlyIcon: PropTypes.bool,
    onlyLink: PropTypes.bool,
    buttonTitle: PropTypes.string,
    confirmButtonTitle: PropTypes.string,
    style: PropTypes.object,
};
