import { array, object, string, number, bool } from "yup";

export const marketplaceItemApiSchema = object().shape({
    isOffer: bool().required(),
    uniqueName: string().nullable(),
    id: number().nullable(),
    label: string().required(),
    description: string().required(),
    components: string().nullable(),
    type: string().required(),
});

export const marketplaceItemsApiSchema = array().of(marketplaceItemApiSchema);
