import {
    ButtonMenu,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { isProcessRunning } from "@/components/Integrations/ImUpdate/helpers";
import Progress from "@/components/Progress/Progress";
import { getIMUpdateLeaveModalTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

const storeDays = 1;
export const LeaveUpdateModal = ({
    deleteTask,
    visible,
    onDiscard,
    onSaveAndLeave,
    onCancel,
}) => {
    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("im-instance.update.leave.modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    {isProcessRunning(deleteTask) ? (
                        <Progress
                            showInfo
                            percent={deleteTask.progress}
                            message="Canceling update..."
                        />
                    ) : (
                        <Text>
                            {t("im-instance.update.leave.modal.message", {
                                days: `${storeDays} day`,
                            })}
                        </Text>
                    )}
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    disabled: isProcessRunning(deleteTask),
                                    label: t(
                                        "im-instance.update.leave.modal.save-and-leave",
                                    ),
                                    type: "primary",
                                    onClick: onSaveAndLeave,
                                    track: {
                                        name: getTrackName("SaveAndLeave"),
                                    },
                                },
                                {
                                    disabled: isProcessRunning(deleteTask),
                                    label: t("general.discard"),
                                    type: "default",
                                    onClick: onDiscard,
                                    track: { name: getTrackName("Discard") },
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

LeaveUpdateModal.propTypes = {
    deleteTask: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    onDiscard: PropTypes.func.isRequired,
    onSaveAndLeave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
