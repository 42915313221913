import { useDic } from "@/components/Dic/useDic.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";
import { ConnectionForm } from "./ConnectionForm";

export const NewConnectionPage = () => {
    const { instanceId } = useAccountAppParams();
    useSetPageTitle(t("connection-form.title.new-connection"));
    const {
        locationRouterService,
        accountAppLocations: { connectionsLocation },
    } = useDic();
    const onCancel = useCallback(
        () => locationRouterService.navigate(connectionsLocation),
        [connectionsLocation, locationRouterService],
    );

    return (
        <ConnectionForm
            instanceId={instanceId}
            connectionId="new"
            onCancel={onCancel}
        />
    );
};
