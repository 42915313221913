import { Gap, H5, Input, Option, Select } from "@/components/DesignSystem";
import { t } from "@/translations";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React from "react";
import { InputType } from "../../Packages/PackageTableDefinitionPanel/constants";
import { TABS_VALUE_TYPES } from "../FieldCustomizationTabs.component";

const inputTypeOptions = [
    InputType.HEADER,
    InputType.PROPERTY,
    InputType.SIMPLE,
    InputType.CONSTANT,
];
const defaultInputType = inputTypeOptions[0];

const withValidation = input => ({
    ...input,
    isValid: !isEmpty(input.input) && !isEmpty(input.inputType),
});

export const ExpertTab = ({
    input,
    inputType = defaultInputType,
    onChange,
}) => {
    const onInputTypeChange = inputType => {
        onChange(
            withValidation({
                input,
                inputType,
                tab: TABS_VALUE_TYPES.EXPERT_OPTION,
            }),
        );
    };

    const onInputChange = e => {
        onChange(
            withValidation({
                input: e.target.value,
                inputType,
                tab: TABS_VALUE_TYPES.EXPERT_OPTION,
            }),
        );
    };

    return (
        <div data-test="tab-expert">
            <H5>{t("formula-editor.tab.expert-options.field-type")}</H5>
            <Select
                showSearch
                value={inputType}
                onChange={onInputTypeChange}
                allowClear={false}
                defaultActiveFirstOption
                dataTest="expert-option-type"
            >
                {inputTypeOptions.map(option => (
                    <Option key={option} value={option}>
                        {option}
                    </Option>
                ))}
            </Select>
            <Gap size="small" />
            <H5>{t("formula-editor.tab.expert-options.expression")}</H5>
            <Input.TextArea
                data-test="expert-option-input"
                rows="10"
                value={input}
                onChange={onInputChange}
            />
        </div>
    );
};

ExpertTab.propTypes = {
    input: PropTypes.string,
    inputType: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};
