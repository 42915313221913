import { Menu } from "@/components/DesignSystem";
import { SidebarAndContentLayout } from "@/components/Layouts/SidebarAndContentLayout";
import TokenPanel from "@/components/Tokens/TokenPanel";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { GeneralProfiles } from "./GeneralProfiles.container";
import SsoUsername from "./SsoUsername";
import UserInfo from "./UserInfo";
import UserPreferences from "./UserPreferences";

const TABS = {
    GENERAL: "GENERAL",
    PASSWORD: "PASSWORD",
    TOKENS: "TOKENS",
    USER_PREFERENCES: "USER_PREFERENCES",
    SSO_USERNAME: "SSO_USERNAME",
};

const tid = name => `menu-item-profile-${name?.toLowerCase?.()}`;

export class ProfilePage extends Component {
    state = {
        activeTab: TABS.GENERAL,
    };

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    render() {
        const { activeTab } = this.state;

        return (
            <SidebarAndContentLayout
                // showHeader={false}
                sidebar={
                    <Menu selectedKeys={[activeTab]} mode="inline" condensed>
                        <Menu.Item
                            data-test={tid(TABS.GENERAL)}
                            key={TABS.GENERAL}
                            onClick={() => this.toggle(TABS.GENERAL)}
                        >
                            <FormattedMessage id="profile.nav-item.general" />
                        </Menu.Item>
                        <Menu.Item
                            data-test={tid(TABS.PASSWORD)}
                            key={TABS.PASSWORD}
                            onClick={() => this.toggle(TABS.PASSWORD)}
                        >
                            <FormattedMessage id="profile.nav-item.password" />
                        </Menu.Item>
                        <Menu.Item
                            data-test={tid(TABS.TOKENS)}
                            key={TABS.TOKENS}
                            onClick={() => this.toggle(TABS.TOKENS)}
                        >
                            <FormattedMessage id="profile.nav-item.tokens" />
                        </Menu.Item>
                        <Menu.Item
                            data-test={tid(TABS.USER_PREFERENCES)}
                            key={TABS.USER_PREFERENCES}
                            onClick={() => this.toggle(TABS.USER_PREFERENCES)}
                        >
                            <FormattedMessage id="profile.nav-item.user-preferences" />
                        </Menu.Item>
                        <Menu.Item
                            data-test={tid(TABS.SSO_USERNAME)}
                            key={TABS.SSO_USERNAME}
                            onClick={() => this.toggle(TABS.SSO_USERNAME)}
                        >
                            <FormattedMessage id="profile.nav-item.sso-username" />
                        </Menu.Item>
                    </Menu>
                }
            >
                {activeTab === TABS.GENERAL && <UserInfo />}
                {activeTab === TABS.PASSWORD && <GeneralProfiles />}
                {activeTab === TABS.TOKENS && <TokenPanel />}
                {activeTab === TABS.USER_PREFERENCES && <UserPreferences />}
                {activeTab === TABS.SSO_USERNAME && <SsoUsername />}
            </SidebarAndContentLayout>
        );
    }
}

ProfilePage.propTypes = {};
