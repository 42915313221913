import { useSetErrorAlert } from "@/components/PageLayout/useSetAlert.hook";
import { useEffect } from "react";
import { IM_UPDATE_RESULT } from "./constants";

export const isProcessRunning = process => {
    return process && process.status === "CREATED";
};
export const isProcessDone = process => {
    return process && process.status === "DONE";
};

export const useStepErrorHandling = result => {
    const setErrorAlert = useSetErrorAlert();

    useEffect(() => {
        if (result?.exception) {
            setErrorAlert(result.exception);
        }
        if (
            [
                IM_UPDATE_RESULT.MERGE_ERROR,
                IM_UPDATE_RESULT.GENERAL_ERROR,
            ].includes(result?.status)
        ) {
            setErrorAlert(result?.errMessage);
        }
    }, [result?.errMessage, result?.exception, result?.status, setErrorAlert]);
};
