import { Select } from "@/components/DesignSystem";
import React from "react";
import styles from "./SidebarMenuSelect.styles.less";

/**
 * This component wraps select for usage in the sidebar menu.
 * It sets position and width for the select overlay to use most of space
 * of the sidebar.
 */
export const SidebarMenuSelect = props => (
    <Select
        showSearch
        withoutBorder
        allowClear={false}
        popupClassName={styles.dropdown}
        {...props}
    />
);
