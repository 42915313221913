import React from "react";
import Moment from "react-moment";

const FormattedDate = ({ children, fromNow }) => {
    const props = fromNow ? { fromNow: true } : { format: "DD/MM/YYYY" };

    if (children === null) {
        return <React.Fragment />;
    } else {
        return <Moment {...props}>{children}</Moment>;
    }
};

export default FormattedDate;
