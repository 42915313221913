import {
    Alert,
    Button,
    DatePicker,
    Forms,
    Gap,
    Link,
    Text,
} from "@/components/DesignSystem";
import { useLocalStorageState } from "@/components/hooks/useLocalStorageState.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { useIMArchiveResultsMutation } from "@/components/Integrations/IMArchiveQuery/loadables";
import { TaskDone } from "@/components/Integrations/IMArchiveQuery/TaskDone";
import { useLeaveCallback } from "@/components/Integrations/ImUpdate/useLeaveCallback.hook";
import {
    TASK_STATUS,
    TaskResult,
    useSomeAsyncTasksLiveQuery,
} from "@/modules/async-tasks";
import {
    isLoading,
    LoadableRenderer,
    responseErrorMessage,
} from "@/modules/loadable";
import { T, t } from "@/translations";
import { identity } from "lodash";
import { clone, get, last, pipe, sortBy } from "lodash/fp";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

const QM_OPTIONS = [
    { value: "GLOB", label: "GLOB" },
    { value: "REGEX", label: "REGEX" },
];
const CQM_OPTIONS = [
    { value: "EXACT", label: "EXACT" },
    { value: "REGEX", label: "REGEX" },
];

const getLastCreated = pipe(
    clone,
    sortBy([pipe(get("createdAt"), d => new Date(d).getTime())]),
    last,
);

const from = values => ({
    queryMode: values.queryMode,
    value: values.value,
    dateFrom: values.dateRange?.[0],
    dateTo: values.dateRange?.[1],
    contentQueryMode: values.contentQueryMode,
    contentValue: values.contentValue,
});

const parseDate = date => {
    if (!date) return undefined;
    return moment(date);
};

const to = ({ dateFrom, dateTo, ...payload } = {}) => ({
    ...payload,
    dateRange: [parseDate(dateFrom), parseDate(dateTo)],
});

const RUNNING_TASK_PROPS = {
    message: "Searching...",
    renderCancelAlert: ({ cancelMutation }) => {
        const cancelDisabled = isLoading(cancelMutation);
        const handleCancel = e => {
            e.stopPropagation();
            e.preventDefault();
            if (cancelDisabled) return;
            cancelMutation.mutate();
        };

        const cancel = (
            <Link href="#" disabled={cancelDisabled} onClick={handleCancel}>
                {t("im-archive-query.result.in-progress.cancel")}
            </Link>
        );

        return (
            <Alert
                showIcon
                type="warning"
                message={
                    <T
                        id="im-archive-query.result.in-progress.slow-search-warning-with-cancel"
                        values={{ cancel }}
                    />
                }
            />
        );
    },
};

export const IMArchiveQuery = ({ instanceId }) => {
    const [lastJobId, setLastJobId, clearLastJobId] = useLocalStorageState(
        "last-im-archive-task-id",
        0,
    );

    const maybeAsyncJobQuery = useSomeAsyncTasksLiveQuery({
        filterPredicate: useCallback(
            job =>
                job.jobId === lastJobId &&
                job.type === "BACKUP_QUERY" &&
                job.context?.instanceId === instanceId,
            [instanceId, lastJobId],
        ),
        map: getLastCreated,
    });
    const searchMutation = useIMArchiveResultsMutation({
        instanceId,
        afterSuccess: setLastJobId,
    });
    const { formId, handleSubmit, setValues, setTouched, reset } =
        Forms.useForm({
            onSubmit: pipe(get("values"), from, searchMutation.mutate),
        });
    const maybeTask = maybeAsyncJobQuery.loadable.valueMaybe();

    useLeaveCallback({
        triggerWhen: [TASK_STATUS.FAILED, TASK_STATUS.DONE].includes(
            maybeTask?.status,
        ),
        onLeave: () => {
            clearLastJobId();
            reset();
        },
    });

    useSetValidatedInitialValues(
        { initialValues: to(maybeTask?.requestPayload), setValues, setTouched },
        [maybeTask?.requestPayload],
    );
    const contentQueryMode = Forms.useFieldValue({
        formId,
        name: "contentQueryMode",
    });
    const contentValue = Forms.useFieldValue({
        formId,
        name: "contentValue",
    });
    const fieldsDisabled =
        isLoading(maybeAsyncJobQuery) || // this query initializes form
        [TASK_STATUS.CREATED, TASK_STATUS.STARTED].includes(maybeTask?.status);
    const submitDisabled = fieldsDisabled;
    // || isLoading(searchMutation); // implicitly handled by UC Forms

    return (
        <div style={{ overflow: "hidden" }}>
            <Forms.Form formId={formId} onSubmit={handleSubmit}>
                <Text size="large">
                    {t("im-archive-query.perex", {
                        documentationLink: (
                            <Link
                                targetBlank
                                href="https://pricefx.atlassian.net/wiki/display/PM/Settings"
                            >
                                {t("im-archive-query.documentation-link-text")}
                            </Link>
                        ),
                    })}
                </Text>
                <Gap size="small" />
                <Forms.FieldGroup grid width="max" inputWidth="max">
                    <Forms.Fields.Select
                        required
                        name="queryMode"
                        disabled={fieldsDisabled}
                        label={t("im-archive-query.fields.queryMode.label")}
                        allowClear={false}
                        showSearch
                        validator={Forms.pmValidators.isRequired}
                        options={QM_OPTIONS}
                        tooltip={t("im-archive-query.fields.queryMode.tooltip")}
                    />
                    <Forms.Fields.Input
                        required
                        name="value"
                        disabled={fieldsDisabled}
                        label={t("im-archive-query.fields.value.label")}
                        placeholder={t(
                            "im-archive-query.fields.value.placeholder",
                        )}
                        allowClear={false}
                        validator={Forms.pmValidators.isRequired}
                        tooltip={t("im-archive-query.fields.value.tooltip")}
                    />
                    <Forms.Fields.Select
                        required={!!contentValue}
                        validator={
                            contentValue
                                ? Forms.pmValidators.isRequired
                                : undefined
                        }
                        name="contentQueryMode"
                        disabled={fieldsDisabled}
                        label={t(
                            "im-archive-query.fields.contentQueryMode.label",
                        )}
                        showSearch
                        allowClear
                        options={CQM_OPTIONS}
                        tooltip={t(
                            "im-archive-query.fields.contentQueryMode.tooltip",
                        )}
                    />
                    <Forms.Fields.Input
                        required={!!contentQueryMode}
                        validator={
                            contentQueryMode
                                ? Forms.pmValidators.isRequired
                                : undefined
                        }
                        name="contentValue"
                        disabled={fieldsDisabled}
                        label={t("im-archive-query.fields.contentValue.label")}
                        placeholder={t(
                            "im-archive-query.fields.contentValue.placeholder",
                        )}
                        allowClear
                        tooltip={t(
                            "im-archive-query.fields.contentValue.tooltip",
                        )}
                    />
                </Forms.FieldGroup>
                <Forms.Field
                    as={DatePicker.RangePicker}
                    from={identity}
                    name="dateRange"
                    disabled={fieldsDisabled}
                    label={t("im-archive-query.fields.dateRange.label")}
                    format="DD/MM/YYYY"
                    placeholder={["from", "to"]}
                />
                <LoadableRenderer
                    loadable={maybeAsyncJobQuery.loadable}
                    hasValue={maybeTask =>
                        !maybeTask ? null : (
                            <TaskResult
                                task={maybeTask}
                                components={{ [TASK_STATUS.DONE]: TaskDone }}
                                props={{
                                    [TASK_STATUS.CREATED]: RUNNING_TASK_PROPS,
                                    [TASK_STATUS.STARTED]: RUNNING_TASK_PROPS,
                                }}
                            />
                        )
                    }
                    hasError={error => (
                        <Alert
                            type="error"
                            message={responseErrorMessage(error)}
                        />
                    )}
                />
                <Gap />
                <Forms.SubmitButton disabled={submitDisabled}>
                    <Button
                        type="primary"
                        label={t("general.search")}
                        onClick={e => {
                            e.stopPropagation();
                            handleSubmit(e);
                        }}
                    />
                </Forms.SubmitButton>
            </Forms.Form>
        </div>
    );
};

IMArchiveQuery.propTypes = { instanceId: PropTypes.number.isRequired };
