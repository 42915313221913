import { useDic } from "@/components/Dic/useDic.hook";
import { useAsyncTasksLiveQuery } from "@/modules/async-tasks/hooks";
import React, { useMemo } from "react";

export const AsyncTasksContext = React.createContext(null);

export const AsyncTasksContextProvider = ({ children }) => {
    const { authenticationService } = useDic();
    const isLoggedIn = authenticationService.isLoggedIn();

    const asyncTasksLiveQuery = useAsyncTasksLiveQuery({
        canFetch: isLoggedIn,
    });
    const context = useMemo(
        () => ({
            asyncTasksLiveQuery,
        }),
        [asyncTasksLiveQuery],
    );

    return (
        <AsyncTasksContext.Provider value={context}>
            {children}
        </AsyncTasksContext.Provider>
    );
};
