import { Alert, Forms, Gap, Select } from "@/components/DesignSystem";
import { TypeText } from "@/components/Mappers/MapperTableWithCustomization/components/TypeText.component";
import { getConverterWarning } from "@/components/Mappers/MapperTableWithCustomization/MapperGridForm/validations";
import { ROW_ENTITIES } from "@/components/Mappers/MapperTableWithCustomization/MapperTableWithCustomization";
import { ParsedType } from "@/components/Packages/PackageTableDefinitionPanel/constants";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import { size } from "lodash";
import React, { useMemo } from "react";

const { Fields } = Forms;

export const getAllowedChangableColumnTypes = columnType => {
    switch (columnType) {
        case ParsedType.NUMBER:
            return [ParsedType.NUMBER, ParsedType.STRING];
        default:
            return [];
    }
};
export const MapperCascaderWithType = ({
    id,
    name,
    optionsWithTypes,
    disabled,
    error,
    children,
    onTypeChange,
    onColumnTypeChange,
    converterProps: { withConverterWarning, parserConfig } = {},
    dataTest,
    ...props
}) => {
    const hasError = !!error;
    const fieldValues = useFieldValue({ name }, [name]);

    const input = useFieldValue({ name: `${id}_${ROW_ENTITIES.input}` }, [id]);
    const inputType = useFieldValue(
        { name: `${id}_${ROW_ENTITIES.inputType}` },
        [id],
    );
    const output = useFieldValue({ name: `${id}_${ROW_ENTITIES.output}` }, [
        id,
    ]);
    const converterExpression = useFieldValue(
        { name: `${id}_${ROW_ENTITIES.converterExpression}` },
        [id],
    );

    const mapperItem = useMemo(
        () => ({
            id,
            input,
            output,
            inputType,
            converterExpression,
        }),
        [id, input, inputType, output, converterExpression],
    );

    const getColumnType = value =>
        optionsWithTypes.find(field => field.value === value)?.type;

    const allowedTypesOptions = (
        optionsWithTypes.find(field => field.value === fieldValues?.[0])
            ?.allowedToChange ?? []
    ).map(type => ({
        label: <TypeText hasError={hasError}>{type}</TypeText>,
        value: type,
    }));

    const warning = getConverterWarning({
        withConverterWarning,
        converterExpression,
        parserConfig,
    });
    const columnType = getColumnType(fieldValues?.[0]);

    return (
        <div className="pmMapperTableWithCustomization-input">
            <Fields.Cascader
                name={name}
                disabled={disabled}
                allowClear={false}
                showSearch
                inputWidth="max"
                addonAfter={
                    size(allowedTypesOptions) > 0 ? (
                        <Select
                            allowClear={false}
                            options={allowedTypesOptions}
                            onChange={type =>
                                onColumnTypeChange?.(input[0], type)
                            }
                            value={columnType}
                        />
                    ) : columnType ? (
                        <TypeText hasError={hasError}>{columnType}</TypeText>
                    ) : null
                }
                options={optionsWithTypes}
                data-test={dataTest}
                {...props}
                onChange={field => {
                    onTypeChange?.({
                        [`${name}Type`]: getColumnType(field.value?.[0]),
                    });
                    return props?.onChange?.();
                }}
            />
            {children && children({ mapperItem, columnType })}
            {(hasError || warning) && (
                <>
                    <Gap size="small" />
                    <Alert message={error || warning} type="error" />
                </>
            )}
        </div>
    );
};
