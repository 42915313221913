import { Skeleton } from "antd";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React from "react";
import "./RowSkeleton.style.less";

export const RowSkeletonVariant = {
    MARKETPLACE_ITEM: "marketplace-item",
};

export const RowSkeleton = ({ variant }) => {
    return (
        <Skeleton
            className={classNames({
                pmRowSkeleton: 1,
                [`pmRowSkeleton-${variant}`]: !isEmpty(variant),
            })}
            active
            title={false}
            round={false}
            paragraph={false}
            avatar={true}
            rows={1}
            size="small"
            shape="square"
        />
    );
};
