import {
    OfferApiSchema,
    OfferImageSchema,
    OffersApiSchema,
} from "./OffersApiSchema";

export class OffersApiService {
    baseUrl = "api/offers";
    /**
     *
     * @type {HttpService}
     */
    httpService = null;

    constructor({ httpService }) {
        this.httpService = httpService;
    }

    /**
     * fetches all offers
     *
     * @returns {Promise<OfferApiSchema[]>}
     */
    fetchOffers() {
        return this.httpService.get(this.baseUrl, {
            schema: OffersApiSchema,
        });
    }

    /**
     * fetches an offer
     *
     * @returns {Promise<OfferApiSchema>}
     */
    fetchOffer(offerId) {
        return this.httpService.get(`${this.baseUrl}/${offerId}`, {
            schema: OfferApiSchema,
        });
    }

    /**
     * edits an offer
     *
     * @returns {Promise<OfferApiSchema>}
     */
    editOffer(offerId, offer) {
        return this.httpService.post(`${this.baseUrl}/${offerId}`, offer, {
            schema: OfferApiSchema,
        });
    }

    /**
     * creates an offer
     *
     * @returns {Promise<OfferApiSchema>}
     */
    createOffer(offer) {
        return this.httpService.post(this.baseUrl, offer, {
            schema: OfferApiSchema,
        });
    }

    /**
     * deletes an offer
     *
     * @returns {Promise<void>}
     */
    deleteOffer(offerId) {
        return this.httpService.delete(`${this.baseUrl}/${offerId}`);
    }
}
