export function createRoutesPath(routeName) {
    const routeParts = routeName.split(".");

    const result = routeParts.reduce(
        (acc, current) => {
            const currentRoute = acc.previousRoute
                ? `${acc.previousRoute}.${current}`
                : current;

            acc.path.push(currentRoute);
            acc.previousRoute = currentRoute;

            return acc;
        },
        {
            path: [],
            previousRoute: "",
        },
    );

    return result.path;
}
