import {
    ButtonMenu,
    Modal,
    Option,
    Select,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { t } from "@/translations";
import { Col, Form, Input, Row } from "antd";
import kebabCase from "lodash/kebabCase";
import React from "react";
import { getCopyRouteDialogTrackName } from "../../../mixpanel/buttonNames";
import ConditionalErrorAlert from "../../Error/ConditionalErrorAlert";
import { CopyRoutePreview } from "./CopyRoutePreview";

export const CopyRouteDialog = ({
    defaultName,
    defaultUniqueName,
    onCancel,
    onCopy,
    onPreview,
    valid,
    errorMessage,
    onInstanceSelect,
    instances,
    routes,
    preview,
    visible = true,
}) => {
    const [form] = Form.useForm();

    function handleNameChange(event) {
        form.setFieldsValue({ uniqueName: kebabCase(event.target.value) });
    }

    function handlePreview() {
        form.validateFields().then(values => {
            onPreview(values);
        });
    }

    const PATTERN_ROUTE_UNIQUE_NAME = /^[a-zA-Z0-9_:.-]*$/;

    return (
        <>
            <Modal width="800px" visible={visible} onClose={onCancel}>
                <UnityLayout>
                    <UnityLayout.Header
                        size={3}
                        title={t("route.copy.modal.title")}
                    />
                    <UnityLayout.Content padding={[true, true]}>
                        {errorMessage && (
                            <ConditionalErrorAlert
                                error={errorMessage}
                                size="medium"
                            />
                        )}
                        <Row gutter={6}>
                            <Col sm={24}>
                                <Form
                                    id="copyForm"
                                    form={form}
                                    onFinish={onCopy}
                                    initialValues={{
                                        name: defaultName,
                                        uniqueName: defaultUniqueName,
                                    }}
                                >
                                    <Text>
                                        {t(
                                            "route.copy.label.integration-instances",
                                        )}
                                    </Text>
                                    <Form.Item
                                        name="targetInstanceId"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    "general.validation.required",
                                                ),
                                            },
                                        ]}
                                    >
                                        <Select onSelect={onInstanceSelect}>
                                            {instances.map(instance => (
                                                <Option
                                                    title={
                                                        instance.instanceName
                                                    }
                                                    key={instance.id}
                                                    value={instance.id}
                                                >
                                                    {instance.instanceName}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Text>
                                        {t("route.copy.label.route-name")}
                                    </Text>
                                    <Form.Item
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    "general.validation.required",
                                                ),
                                            },
                                            props => ({
                                                validator: (rule, value) => {
                                                    let fieldValue =
                                                        props.getFieldValue(
                                                            "name",
                                                        );
                                                    if (
                                                        routes.some(
                                                            route =>
                                                                route.name ===
                                                                fieldValue,
                                                        )
                                                    ) {
                                                        return Promise.reject(
                                                            t(
                                                                "route.copy.validation-message.name-duplication",
                                                            ),
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input onChange={handleNameChange} />
                                    </Form.Item>
                                    <Text>
                                        {t(
                                            "route.copy.label.route-unique-name",
                                        )}
                                    </Text>
                                    <Form.Item
                                        name="uniqueName"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    "general.validation.required",
                                                ),
                                            },
                                            {
                                                pattern:
                                                    PATTERN_ROUTE_UNIQUE_NAME,
                                                message:
                                                    t(
                                                        "general.validation.pattern",
                                                    ) +
                                                    PATTERN_ROUTE_UNIQUE_NAME,
                                            },
                                            props => ({
                                                validator: (rule, value) => {
                                                    let fieldValue =
                                                        props.getFieldValue(
                                                            "name",
                                                        );
                                                    if (
                                                        routes.some(
                                                            route =>
                                                                route.uniqueName ===
                                                                fieldValue,
                                                        )
                                                    ) {
                                                        return Promise.reject(
                                                            t(
                                                                "route.copy.validation-message.name-duplication",
                                                            ),
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col sm={24}>
                                {preview && (
                                    <CopyRoutePreview preview={preview} />
                                )}
                            </Col>
                        </Row>
                    </UnityLayout.Content>
                    <UnityLayout.Footer
                        actionButtons={
                            <ButtonMenu
                                buttons={[
                                    {
                                        label: t("general.copy"),
                                        type: "primary",
                                        htmlType: "submit",
                                        disabled: !valid,
                                        form: "copyForm",
                                        track: {
                                            name: getCopyRouteDialogTrackName(
                                                "Submit",
                                            ),
                                        },
                                    },
                                    {
                                        label: t("route.copy.modal.preview"),
                                        type: "primary",
                                        onClick: handlePreview,
                                        form: "copyForm", // ??
                                        track: {
                                            name: getCopyRouteDialogTrackName(
                                                "Preview",
                                            ),
                                        },
                                    },
                                    {
                                        label: t("general.cancel"),
                                        onClick: onCancel,
                                        type: "text",
                                        track: {
                                            name: getCopyRouteDialogTrackName(
                                                "Cancel",
                                            ),
                                        },
                                    },
                                ]}
                            />
                        }
                    />
                </UnityLayout>
            </Modal>
        </>
    );
};
