import { cancelable } from "cancelable-promise";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import uuid from "uuid/v4";

export const STATUSES = {
    INITIAL: "INITIAL",
    LOADING: "LOADING",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
};

export const isLoading = ([status]) => status === STATUSES.LOADING;
export const getPromise = ([status, promise]) =>
    isLoading([status]) && !promise.isCanceled() ? promise : undefined;
export const getError = ([status, error]) =>
    status === STATUSES.ERROR ? error : false;
export const getResponse = ([status, response]) =>
    status === STATUSES.SUCCESS ? response : undefined;

export function useFetchPage(fn, deps = []) {
    const [state, setState] = useState([STATUSES.INITIAL]);
    const handleError = useCallback(error => {
        setState([STATUSES.ERROR, error]);
        if (process.env.NODE_ENV !== "production") {
            console.error("useFetchPage: an error occurred", error);
        }
    }, []);
    const [fetchFlag, setFetchFlag] = useState();
    const reload = useCallback(() => setFetchFlag(uuid()), []);

    const handleSuccess = useCallback(
        response => setState([STATUSES.SUCCESS, response]),
        [],
    );
    useEffect(
        () =>
            !isLoading(state) ? undefined : () => getPromise(state).cancel(),
        [state],
    );

    const fetchPage = useCallback((...args) => {
        const promise = cancelable(fn(...args));

        setState([STATUSES.LOADING, promise]);
        promise.then(handleSuccess).catch(handleError);
    }, deps);

    const loading = isLoading(state);
    const response = getResponse(state);
    const error = getError(state);

    const tableProps = {
        page: response && response.data,
        fetchPage,
        loading,
        fetchFlag,
    };

    return [
        tableProps,
        {
            status: state[0],
            loading,
            error,
            response,
            reload,
        },
    ];
}

export const useFetchPagePropTypes = {
    page: PropTypes.object,
    fetchPage: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};
