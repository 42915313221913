import qs from "qs";
import { axiosService } from "../axios";

const ssoService = {
    getPfxUsers() {
        return axiosService.get(`/api/sso/pfx-users`);
    },
    getSsoInfo(pfxLogin, relativePath) {
        const queryParams = qs.stringify(
            { relativePath },
            { addQueryPrefix: true },
        );
        return axiosService.get(`/api/sso/sso-info/${pfxLogin}${queryParams}`);
    },
};

export default ssoService;
