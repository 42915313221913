import { axiosService } from "../axios";

export const supportUserService = {
    createSupportUser(values) {
        return axiosService.post(
            `/api/projects/${values.projectId}/partition-assets/${values.partitionId}/create-support-user`,
            values,
        );
    },
};
