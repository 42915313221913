import { Input, Select, Text } from "@/components/DesignSystem";
import { StopOnKeyDownPropagation } from "@/components/StopOnKeyDownPropagation";
import { t } from "@/translations";
import React from "react";
import { DELETE_OP } from "./SetupSimulation.component";
import styles from "./SetupSimulation.style.less";

export const simulationColumns = (
    generateOptions,
    onSourceFieldSelect,
    generateDeleteButton,
    handleLabelChange,
    onFieldSelectClear,
) => [
    {
        label: t("packages.simulation.table.header.source-field"),
        name: "Source",
        render: (text, record) => (
            <div className={styles.error}>
                <div className={styles.inlineContainer}>
                    <Select
                        showSearch
                        allowClear
                        onChange={() => onFieldSelectClear(record, DELETE_OP)}
                        styles={{ borderColor: "red" }}
                        className={styles.error}
                        onSelect={value => onSourceFieldSelect(record, value)}
                        value={record.name}
                    >
                        {generateOptions(record)}
                    </Select>
                    {generateDeleteButton(record)}
                </div>
            </div>
        ),
    },
    {
        label: t("packages.simulation.table.header.expected-field"),
        name: "sourceField",
        render: (text, record) => (
            <div>
                <Text>{record.sourceField} </Text>
            </div>
        ),
    },
    {
        label: t("packages.simulation.table.header.label"),
        name: "alias",
        render: (text, record) => (
            <StopOnKeyDownPropagation>
                <Input
                    type="text"
                    className={!record.alias ? styles.error : undefined}
                    onChange={e =>
                        handleLabelChange(record, e.target.value?.trim())
                    }
                    value={record.alias}
                />
            </StopOnKeyDownPropagation>
        ),
    },
];
