import { NewTableModal } from "@/components/DataUploads/Wizard/NewTable/NewTableModal";
import { Button, Forms, Gap } from "@/components/DesignSystem";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import {
    useEntityNamesQuery,
    useSupportedEntityTypesQuery,
} from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/loadables";
import { hasSubFilter } from "@/constants/SupportedTableImportTypes";
import { t } from "@/translations";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import { get, noop } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

// TODO: mapperMappingSourceType?
export const SOURCE_TYPE = {
    CONNECTIONS: "connections",
    PARTITIONS: "partitions",
};

const emptyArray = [];

export const EntitySelectFields = ({
    formId,
    sourceId,
    sourceType = SOURCE_TYPE.CONNECTIONS,
    allowCreatePartitionTableFor = [],
    projectId,
    setValues,
    setTouched,
    inputWidth,
    onChange = noop,
}) => {
    const newTableModal = useVisibility();
    const entityType = Forms.useFieldValue({
        formId,
        name: "entityType",
    });
    const entityTypesQuery = useSupportedEntityTypesQuery({
        sourceType,
        sourceId,
    });
    const entityNamesQuery = useEntityNamesQuery({
        entityType,
        sourceType,
        sourceId,
    });
    const entityNameRequired = hasSubFilter(entityType);

    const handleEntityTypeChange = field => {
        setValues({ entityName: undefined });
        setTouched({ entityName: false });
        onChange({ entityType: field.value, entityName: undefined });
    };
    const handleEntityNameChange = field => {
        onChange({ entityName: field.value });
    };
    const existingEntityNames = useMemo(
        () =>
            entityNamesQuery.loadable.valueMaybe()?.map(get("value")) ??
            emptyArray,
        [entityNamesQuery.loadable],
    );
    const afterSave = useCurrentHandler(() => {
        entityNamesQuery.reload();
    });
    const afterFinish = useCurrentHandler(() => {
        entityNamesQuery.reload();
        newTableModal.hide();
    });
    // TODO? from FilterForm
    // const entityTypeValidator = useEntityTypeValidator(supportedTableImportTypes());
    // const entityNameValidator = useEntityNameValidator(entityNamesQuery.loadable.valueMaybe() || []);

    return (
        <>
            <Forms.Fields.Select
                required
                name="entityType"
                label={t("entity-type.fields.entity-type.label")}
                placeholder={t("general.select")}
                validator={Forms.pmValidators.isRequired}
                onChange={handleEntityTypeChange}
                inputWidth={inputWidth}
                {...getLoadableSelectProps(entityTypesQuery.loadable)}
            />
            {entityNameRequired && (
                <>
                    <Forms.Fields.Select
                        showSearch
                        required
                        name="entityName"
                        label={t("entity-type.fields.entity-name.label")}
                        placeholder={t("general.select")}
                        validator={Forms.pmValidators.isRequired}
                        inputWidth={inputWidth}
                        onChange={handleEntityNameChange}
                        {...getLoadableSelectProps(entityNamesQuery.loadable)}
                    />
                    {sourceType === SOURCE_TYPE.PARTITIONS &&
                        projectId &&
                        allowCreatePartitionTableFor.includes(entityType) && (
                            <>
                                <Button
                                    size="small"
                                    icon={Plus}
                                    label={t("table-metadata-form.button.new")}
                                    onClick={newTableModal.show}
                                />
                                <Gap />
                                <NewTableModal
                                    visible={newTableModal.visible}
                                    afterSave={afterSave}
                                    afterFinish={afterFinish}
                                    onClose={newTableModal.hide}
                                    projectId={projectId}
                                    partitionId={sourceId}
                                    tableType={entityType}
                                    entityTypeNames={existingEntityNames}
                                />
                            </>
                        )}
                </>
            )}
        </>
    );
};

EntitySelectFields.useEntitySelectFields = ({ formId }) => {
    const entityType = Forms.useFieldValue({ formId, name: "entityType" });
    const entityName = Forms.useFieldValue({ formId, name: "entityName" });
    const entityNameRequired = hasSubFilter(entityType);
    const requiredSelected = !!(
        entityType &&
        (!entityNameRequired || entityName)
    );

    return { entityType, entityName, entityNameRequired, requiredSelected };
};

EntitySelectFields.propTypes = {
    formId: PropTypes.string.isRequired,
    sourceId: PropTypes.number.isRequired,
    projectId: PropTypes.number,
    allowCreatePartitionTableFor: PropTypes.arrayOf(
        PropTypes.string.isRequired,
    ),
    sourceType: PropTypes.string.isRequired,
    inputWidth: PropTypes.string,
    setValues: PropTypes.func.isRequired,
    setTouched: PropTypes.func.isRequired,
};
