import { Button } from "@/components/DesignSystem/Button";
import { t } from "@/translations";
import React from "react";
import Moment from "react-moment";

export const manageQueriesColumns = (onQueryEdit, onQueryDelete) => [
    {
        name: "name",
        label: t("elastic-search.modal.table.title.query-name"),
    },
    {
        name: "createdBy",
        label: t("elastic-search.modal.table.title.created-by"),
    },
    {
        name: "updatedAt",
        label: t("elastic-search.modal.table.title.last-updated"),
        render: (text, record) => (
            <Moment format="DD/MM/YYYY hh:mm">{record.updatedAt}</Moment>
        ),
    },

    {
        name: "action",
        label: t("elastic-search.modal.table.title.actions"),
        render: (_, record) => (
            <>
                <Button
                    size="small"
                    type="link"
                    onClick={() => onQueryEdit(record)}
                    label={t("general.edit")}
                />
                <Button
                    size="small"
                    type="link"
                    danger
                    onClick={() => onQueryDelete(record.id)}
                    label={t("general.delete")}
                />
            </>
        ),
    },
];
