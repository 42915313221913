import { ActivateAccountPage } from "@/components/ActivateAccount/ActivateAccount.page";
import RedirPage from "@/components/Authentication/Redir.page";
import VerifyPage from "@/components/Authentication/Verify.page";
import { MarketplaceAppLayout } from "@/components/Marketplace/MarketplaceApp.layout";
import { NewPasswordPageContainer } from "@/components/NewPassword/NewPassword.container";
import NotificationsPage from "@/components/Notifications/Notificatons.page";
import { AccountApp } from "./apps/accountApp/components/AccountApp/AccountApp.component";
import { AdministrationApp } from "./apps/administrationApp/components/AdministrationApp/AdministrationApp.component";
import { ProfilePage } from "./apps/administrationApp/components/Profile/Profile.component";
import { OverviewApp } from "./apps/overviewApp/components/OverviewApp/OverviewApp.component";
import { ProductEnhancementApp } from "./apps/productEnhancementApp/components/ProductEnhancementApp/ProductEnhancementApp.component";
import { WorkflowsApp } from "./apps/workflowsApp/components/WorkflowsApp/WorkflowsApp.component";
import { taskStatuses } from "./components/Tasks/TasksTable";
import CapacityConfigurationPage from "./components/UpgradeScheduling/CapacityConfigurationPage.component";
import WorkflowsApprovalPage from "./components/WorkFlow/WorkflowsApprovalPage";
import { createLocation } from "./modules/router";
import { UnauthorizedContent } from "./security/components/UnauthorizedContent/UnauthorizedContent.component";
import {
    hasAnyPermissionsFunc,
    MANAGEMENT_PERMISSIONS,
    MARKETPLACE_ACCELERATOR_PERMISSION_FUNC,
    OVERVIEW_PERMISSIONS,
    WORKFLOW_PERMISSION_FUNC,
} from "./security/permission.utils";
import { SsoLogin } from "./security/SsoLogin";
import { DefaultRoutePage } from "./views/DefaultRoutePage";
import { NotFoundPage, ServerErrorPage } from "./views/Pages";
import { ApprovalPage } from "./views/Pages/ApprovalPage";
import { LogoutPage } from "./views/Pages/LogoutPage";
import Tasks from "./views/Tasks/Tasks";
const ROOT_NODE = "";

const defaultRouteLocation = createLocation(ROOT_NODE, "default", "/", {
    component: DefaultRoutePage,
});

const loginLocation = createLocation(ROOT_NODE, "login", "/login", {
    component: () => null,
    authenticatedOnly: false,
    forwardTo: "default",
});

const logoutLocation = createLocation(ROOT_NODE, "logout", "/logout", {
    component: LogoutPage,
    authenticatedOnly: false,
});

const authRedirLocation = createLocation(
    ROOT_NODE,
    "auth-redir",
    "/auth/redir",
    {
        component: RedirPage,
        authenticatedOnly: false,
    },
);

const authVerifyLocation = createLocation(
    ROOT_NODE,
    "auth-verfiy",
    "/auth/verify",
    {
        component: VerifyPage,
        authenticatedOnly: false,
    },
);

const profileLocation = createLocation(ROOT_NODE, "profile", "/profile", {
    component: ProfilePage,
});

const capacityLocation = createLocation(
    profileLocation,
    "capacity",
    "/capacity",
    {
        component: CapacityConfigurationPage,
    },
);

const newPasswordLocation = createLocation(
    ROOT_NODE,
    "new-password",
    "/new-password/:requestId",
    {
        component: NewPasswordPageContainer,
        authenticatedOnly: false,
    },
);

const ssoLoginLocation = createLocation(
    ROOT_NODE,
    "sso-login",
    "/sso-login/:token",
    {
        component: SsoLogin,
        authenticatedOnly: false,
    },
);

const activateAccountLocation = createLocation(
    ROOT_NODE,
    "user-activation",
    "/user-activation/:activationToken",
    {
        component: ActivateAccountPage,
        authenticatedOnly: false,
    },
);

const notificationsLocation = createLocation(
    profileLocation,
    "notifications",
    "/notifications",
    {
        component: NotificationsPage,
    },
);
const tasksLocation = createLocation(ROOT_NODE, "tasks", "/tasks/:status", {
    component: Tasks,
    defaultParams: {
        status: taskStatuses.DONE,
    },
});

const notFoundLocation = createLocation(ROOT_NODE, "404", "/404", {
    component: NotFoundPage,
    authenticatedOnly: false,
});

const noRightsLocation = createLocation(ROOT_NODE, "no-rights", "/no-rights", {
    component: UnauthorizedContent,
});

const serverErrorLocation = createLocation(ROOT_NODE, "500", "/500", {
    component: ServerErrorPage,
});

export const accountAppLocation = createLocation(
    "accountApp",
    "",
    "/customer/:accountId",
    {
        permissionFunc: hasAnyPermissionsFunc,
        component: AccountApp,
        forwardTo: "accountApp.dashboard",
    },
);

export const marketplaceAppLocation = createLocation(
    "marketplaceApp",
    "",
    "/marketplace/:accountId",
    {
        permissionFunc: MARKETPLACE_ACCELERATOR_PERMISSION_FUNC,
        component: MarketplaceAppLayout,
        forwardTo: "marketplaceApp.marketplace",
    },
);

export const administrationAppLocation = createLocation(
    "administrationApp",
    "",
    "/administration",
    {
        component: AdministrationApp,
        permission: MANAGEMENT_PERMISSIONS,
    },
);

export const overviewAppLocation = createLocation(
    "overviewApp",
    "",
    "/overview",
    {
        component: OverviewApp,
        permission: OVERVIEW_PERMISSIONS,
    },
);

export const productEnhancementAppLocation = createLocation(
    "productEnhancementApp",
    "",
    "/product-requests",
    {
        component: ProductEnhancementApp,
        forwardTo: "productEnhancementApp.allRequests",
    },
);

export const workflowsAppLocation = createLocation(
    "workflowsApp",
    "",
    "/workflows",
    {
        component: WorkflowsApp,
        forwardTo: "workflowsApp.list",
        permissionFunc: WORKFLOW_PERMISSION_FUNC,
    },
);

const myApprovalsLocation = createLocation(
    profileLocation,
    "my-approvals",
    "/my-approvals",
    {
        component: WorkflowsApprovalPage,
    },
);

const approvalsLinkLocation = createLocation(
    ROOT_NODE,
    "approvals",
    "/approvals/:hash/:action",
    {
        component: ApprovalPage,
        authenticatedOnly: false,
    },
);

export const locationsMap = {
    authVerifyLocation,
    authRedirLocation,
    logoutLocation,
    loginLocation,
    defaultRouteLocation,
    ssoLoginLocation,
    notFoundLocation,
    serverErrorLocation,
    accountAppLocation,
    administrationAppLocation,
    marketplaceAppLocation,
    newPasswordLocation,
    profileLocation,
    notificationsLocation,
    overviewAppLocation,
    noRightsLocation,
    tasksLocation,
    activateAccountLocation,
    capacityLocation,
    productEnhancementAppLocation,
    workflowsAppLocation,
    myApprovalsLocation,
    approvalsLinkLocation,
};
