import { Forms } from "@/components/DesignSystem";
import { useFieldKeysQuery } from "@/components/Filters/form/loadables";
import { mapFieldToProps } from "@/components/GenericForm/GenericFormInner";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useMapLoadableMemoized } from "@/modules/loadable";
import { mapperMappingSourceType } from "@/services/mapperMappingSourceType.enum";
import filter from "lodash/filter";
import find from "lodash/find";
import forEach from "lodash/forEach";
import get from "lodash/get";
import lowerCase from "lodash/lowerCase";
import PropTypes from "prop-types";
import React from "react";
const { Fields } = Forms;

const filters = {
    in: f => d => find(f.values, v => v === get(d, f.field)),
    equals: f => d => get(d, f.field) === f.value,
};

const applyFilters = (filters, data) => {
    if (!filters) {
        return data;
    }

    let filteredData = data;

    forEach(filters, filter => (filteredData = doFilter(filter, filteredData)));

    return filteredData;
};

const doFilter = (f, data) => {
    const filterFn = filters[lowerCase(f.operator)](f);

    return filter(data, filterFn);
};

const SubFieldElement = ({
    objectId,
    objectType = mapperMappingSourceType.partitions,
    field,
    entityType,
    entityName = entityType,
}) => {
    const fieldKeysResource = useFieldKeysQuery({
        objectType,
        objectId,
        entityType,
        entityName,
        canFetch: objectId && entityType && entityName,
    });

    const subfieldOptionsLoadable = useMapLoadableMemoized(
        fieldKeysResource.loadable,
        (subfields = []) =>
            applyFilters(field.filters, subfields).map(field => ({
                label: field.label,
                value: field.name,
            })),
    );
    return (
        <Fields.Select
            key={field.name}
            {...mapFieldToProps(field)}
            {...getLoadableSelectProps(subfieldOptionsLoadable)}
        />
    );
};

SubFieldElement.propTypes = {
    objectId: PropTypes.number.isRequired,
    objectType: PropTypes.any,
    field: PropTypes.object.isRequired,
    entityType: PropTypes.string,
    entityName: PropTypes.string,
    disabled: PropTypes.bool,
};

export default SubFieldElement;
