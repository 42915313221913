import { createLocationModel } from "@/modules/router";
import { asFunction, asValue } from "awilix";
import { deployPackage } from "../apps/marketplaceApp/components/DeployPackage/deployPackage.model";

export function registerRematchModels(dic) {
    dic.register({
        deployPackageModel: asValue(deployPackage),
        locationModel: asFunction(createLocationModel).singleton(),
        // register all models as an object so they can be easily passed to the
        // store service
        rematchModels: asFunction(({ deployPackageModel, locationModel }) => ({
            deployPackage: deployPackageModel,
            location: locationModel,
        })).singleton(),
    });
}
