import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    crudSuccess,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { t } from "@/translations";

export const useAdminBusinessRolesResource = () => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            axiosService
                .get("/api/admin/business-roles")
                .then(({ data }) => data ?? []),
        [axiosService],
    );
};

export const usePostAdminBusinessRoleResource = afterSave => {
    const { axiosService } = useDic();
    const currentAfterSave = useCurrentHandler(afterSave);
    return useMutationLoadableWithNotification(
        async businessRole => {
            const { data } = await axiosService.post(
                `/api/admin/business-roles${
                    businessRole.id ? `/${businessRole.id}` : ""
                }`,
                businessRole,
            );
            currentAfterSave(data);
            return { ...data, _created: !businessRole.id };
        },
        [axiosService, currentAfterSave],
        crudSuccess(t("general.business-role"), "name", ({ _created }) =>
            _created ? "C" : "U",
        ),
    );
};

export const useDeleteAdminBusinessRoleResource = afterDelete => {
    const { axiosService } = useDic();
    const currentAfterDelete = useCurrentHandler(afterDelete);
    return useMutationLoadableWithNotification(
        async businessRole => {
            const { data } = await axiosService.delete(
                `/api/admin/business-roles/${businessRole.id}`,
            );
            currentAfterDelete(data);
            return businessRole;
        },
        [axiosService, currentAfterDelete],
        crudSuccess(t("general.business-role"), "name", "D"),
    );
};
