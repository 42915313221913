import { Button, Forms, Text } from "@/components/DesignSystem";
import { Gap } from "@/components/DesignSystem/Gap/Gap";
import { useDic } from "@/components/Dic/useDic.hook";
import ConditionalAttributeErrorAlert from "@/components/Error/ConditionalAttributeErrorAlert";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import {
    hasError,
    useMutationLoadableWithNotification,
} from "@/modules/loadable";
import { get, pipe } from "lodash/fp";
import React, { useCallback } from "react";
import { t } from "../../../../translations/";

const passFieldName = "newPassword";

const useChangePasswordMutation = () => {
    const { passwordsService } = useDic();

    return useMutationLoadableWithNotification(
        async values =>
            passwordsService
                .changePassword(values.oldPassword, values[passFieldName])
                .catch(e => {
                    throw e.response.data;
                }),
        [passwordsService],
        "profile.password.success",
        "profile.password.error",
    );
};

export const GeneralProfiles = () => {
    useSetPageTitle(t("profile.password.title"));
    const mutation = useChangePasswordMutation();
    const { formId, handleSubmit } = Forms.useForm({
        onSubmit: pipe(get("values"), mutation.mutate),
    });

    const matchPassword = useCallback(
        async (value, getBag) => {
            const { values } = await getBag();
            return values[passFieldName] === value
                ? Forms.success()
                : Forms.error(t("profile.error.pass-does-not-match"));
        },
        [Forms.useFieldValue({ formId, name: passFieldName })],
    );

    return (
        <Forms.Form formId={formId} onSubmit={handleSubmit}>
            <Text>{t("profile.password.perex")}</Text>
            <Gap size="small" />
            <ConditionalAttributeErrorAlert
                error={hasError(mutation) && mutation.loadable.contents}
            />
            <Forms.Fields.InputPassword
                required
                name="oldPassword"
                label={t("profile.label.old-password")}
                placeholder={t("profile.placeholder.old-password")}
                validator={Forms.pmValidators.isRequired}
                autoComplete="current-password"
            />
            <Forms.Fields.RestrictedPassword
                required
                name={passFieldName}
                label={t("profile.label.new-password")}
                placeholder={t("profile.placeholder.new-password")}
                autoComplete="new-password"
            />
            <Forms.Fields.InputPassword
                required
                name="repeatPassword"
                label={t("profile.label.repeated-password")}
                placeholder={t("profile.placeholder.repeated-password")}
                autoComplete="new-password"
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    matchPassword,
                ])}
            />
            <Button
                label={t("general.save")}
                type="primary"
                htmlType="submit"
                formId={formId}
            />
        </Forms.Form>
    );
};
