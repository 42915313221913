export class DataUploadService {
    baseUrl = "/api/partitions";
    /**
     *
     * @type {HttpService}
     */
    httpService = null;

    constructor({ httpService }) {
        this.httpService = httpService;
    }

    copyDataUpload(dataUploadId, { partitionIds, name: dataUploadName }) {
        return this.httpService.post(`/api/data-uploads/${dataUploadId}/copy`, {
            dataUploadName,
            partitionIds,
        });
    }

    dataLoadDuplicatesForPartitions(name, partitions) {
        return this.httpService.post(`/api/data-uploads/exists`, {
            // TODO: duplicated api? DataUploadEntityStep
            name,
            partitions,
        });
    }

    getBusinessKeySettings(partitionId, type, extensionName) {
        return this.httpService.get(
            `${this.baseUrl}/${partitionId}/types/${type}/${extensionName}`,
        );
    }

    getDataLoadWarnings(partitionId) {
        return this.httpService.get(
            `${this.baseUrl}/${partitionId}/data-uploads/warnings`,
        );
    }

    getSupportedEntityTypes(partitionId) {
        return this.httpService.get(
            `${this.baseUrl}/${partitionId}/data-uploads/supported-types`,
        );
    }

    isDataUploadExists(partitionId, dataUploadName) {
        return this.httpService.get(
            `${this.baseUrl}/${partitionId}/data-uploads/${dataUploadName}/exists`,
        );
    }
}
