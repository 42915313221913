import { Form, Label, Option, Select } from "@/components/DesignSystem";
import { t } from "@/translations";
import { values } from "lodash/fp";
import { PropTypes } from "prop-types";
import React, { useRef } from "react";
import { ENTITY_TYPE_CODES_FE } from "../../../../../constants/SupportedTableImportTypes";

/**
 * @deprecated Should Be migrated to new UC Forms
 */
const EntityTypeSelector = ({
    entityType,
    onChange,
    style = { width: "200px" },
    error,
    ...rest
}) => {
    const ref = useRef(document.body);

    return (
        <div ref={ref} data-test="entity-type">
            <Label
                label={t("table-metadata-form.label.entity-type")}
                required
            />
            <Form.Item validateStatus={error ? "error" : undefined}>
                <Select
                    showSearch
                    name="entityType"
                    style={style}
                    placeholder={t(
                        "table-metadata-form.placeholder.entity-type",
                    )}
                    value={entityType}
                    onChange={onChange}
                    getPopupContainer={() => ref.current}
                    {...rest}
                >
                    {values(ENTITY_TYPE_CODES_FE).map(o => (
                        <Option title={o.label} key={o.value}>
                            {o.label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </div>
    );
};

EntityTypeSelector.propTypes = {
    entityType: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
};

export default EntityTypeSelector;
