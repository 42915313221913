import { tap } from "@/components/Connections/loadables";
import { message } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { TrackingContext } from "@/mixpanel/TrackingContextProvider";
import {
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { map, noop, pick } from "lodash/fp";
import { useCallback, useContext } from "react";

export const useCreateTableMutation = ({
    partitionId,
    tableType,
    afterSave,
}) => {
    const { axiosService } = useDic();
    const afterSaveCurrent = useCurrentHandler(afterSave);

    return useMutationLoadableWithNotification(
        async payload =>
            axiosService
                .post(
                    `/api/partitions/${partitionId}/create-requests/${tableType}`,
                    payload,
                )
                .then(getData)
                .then(tap(data => afterSaveCurrent({ ...payload, ...data }))),
        [afterSaveCurrent, axiosService, partitionId, tableType],
    );
};

export const useSetTableFieldTypesMutation = ({
    partitionId,
    entityType,
    tableParams,
    afterSave,
}) => {
    const { axiosService } = useDic();
    const afterSaveCurrent = useCurrentHandler(afterSave);
    const performRequest = useCallback(
        ({ fieldName, label, fieldType }) =>
            axiosService
                .post(
                    `/api/partitions/${partitionId}/create-requests/${entityType}/attributes`,
                    {
                        ...pick(["name", "lookupTableId"], tableParams),
                        fieldName,
                        label,
                        fieldType,
                    },
                )
                .then(getData),
        [axiosService, tableParams, entityType, partitionId],
    );

    return useMutationLoadableWithNotification(
        async changedFields =>
            Promise.all(changedFields.map(performRequest)).then(
                tap(afterSaveCurrent),
            ),
        [performRequest, afterSaveCurrent],
    );
};

export const useBusinessKeysQuery = ({
    partitionId,
    tableType,
    size,
    canFetch = true,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? Promise.resolve(undefined)
                : axiosService
                      .get(
                          `/api/partitions/${partitionId}/create-requests/${tableType}/key-size/${size}`,
                      )
                      .then(getData),
        [axiosService, canFetch, partitionId, size, tableType],
    );
};

export const usePfxUserGroupsQuery = ({ partitionId, canFetch = true }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : axiosService
                      .get(
                          `/api/partitions/${partitionId}/create-requests/pfx-user-groups`,
                      )
                      .then(getData),
        [axiosService, canFetch, partitionId],
    );
};

export const idleLoadableProps = (loadable, placeholder) =>
    loadable.state === "hasValue" && !loadable.contents
        ? { disabled: true, placeholder }
        : {};

// Company Parameters
// IMPORTANT: Option translations taken from unity, original keys listed below for future reference (update)

const optionsFromStringArray = dict =>
    map(value => ({ value, label: dict[value] || value }));

// Unused
// "unity_options_common_cancelled": "Cancelled",
// "unity_options_common_date_lower": "Date",
// "unity_options_common_draft": "Draft",
// "unity_options_common_error": "Error",
// "unity_options_common_json2": "JSON2",
// "unity_options_common_link": "Link",
// "unity_options_common_matrix2": "MATRIX2",
// "unity_options_common_matrix3": "MATRIX3",
// "unity_options_common_matrix4": "MATRIX4",
// "unity_options_common_matrix5": "MATRIX5",
// "unity_options_common_matrix6": "MATRIX6",
// "unity_options_common_pending": "Pending",
// "unity_options_common_processing": "Processing",
// "unity_options_common_ready": "Ready",
// "unity_options_common_scheduled": "Scheduled",
// "unity_options_common_scheduled_dirty": "Scheduled Dirty",
// "unity_options_common_sparkline": "Sparkline",
// "unity_options_common_text": "Text",
// "unity_options_common_timestamp": "Timestamp"

// List<CpTableType>
/*
"unity_options_common_simple": "SIMPLE",
"unity_options_common_simple_real": "SIMPLE (Key:Real)",
"unity_options_common_simple_integer": "SIMPLE (Key:Integer)",
"unity_options_common_simple_date": "SIMPLE (Key:Date)",
"unity_options_common_range": "RANGE",
"unity_options_common_matrix": "MATRIX",
"unity_options_common_json": "JSON",
*/
const TTen = {
    SIMPLE: "SIMPLE",
    SIMPLE_REAL_KEY: "SIMPLE (Key:Real)",
    SIMPLE_INT_KEY: "SIMPLE (Key:Integer)",
    SIMPLE_DATE_KEY: "SIMPLE (Key:Date)",
    RANGE: "RANGE",
    MATRIX: "MATRIX",
    JSON: "JSON",
};
export const useTableTypesQuery = ({ partitionId, tableType }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `/api/partitions/${partitionId}/create-requests/${tableType}/table-types`,
                )
                .then(getData)
                .then(optionsFromStringArray(TTen)),
        [axiosService, partitionId, tableType],
    );
};

// List<CpValueType>
/*
"unity_options_common_real": "REAL",
"unity_options_common_int": "INT",
"unity_options_common_string": "STRING",
"unity_options_common_date": "DATE",
*/
const VTen = {
    REAL: "REAL",
    INT: "INT",
    STRING: "STRING",
    DATE: "DATE",
};
export const useValueTypesQuery = ({
    partitionId,
    tableType,
    type,
    canFetch,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? Promise.resolve(undefined)
                : axiosService
                      .get(
                          `/api/partitions/${partitionId}/create-requests/${tableType}/value-types?tableType=${type}`,
                      )
                      .then(getData)
                      .then(optionsFromStringArray(VTen)),
        [axiosService, canFetch, partitionId, tableType, type],
    );
};

// List<CpStatus>
// no unity translations in PM repo, defined by Radek Theier:
const STen = {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    SIMULATIONONLY: "Simulation Only",
};
export const useStatusesQuery = ({ partitionId, tableType }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `/api/partitions/${partitionId}/create-requests/${tableType}/statuses`,
                )
                .then(getData)
                .then(optionsFromStringArray(STen)),
        [axiosService, partitionId, tableType],
    );
};

// List<CpFormatType>
/*
"ft_unity_options_common_numeric": "Numeric",
"ft_unity_options_common_numeric_long": "Numeric (long)",
"ft_unity_options_common_money": "Money",
"ft_unity_options_common_percent": "Percent",
"ft_unity_options_common_money_eur": "Money (EUR)",
"ft_unity_options_common_money_usd": "Money (USD)",
"ft_unity_options_common_money_gbp": "Money (GBP)",
"ft_unity_options_common_money_jpy": "Money (JPY)",
"ft_unity_options_common_money_chf": "Money (CHF)",
"ft_unity_options_common_money_pln": "Money (PLN)",
"ft_unity_options_common_integer": "Integer",
*/
const FTen = {
    NUMERIC: "Numeric",
    NUMERIC_LONG: "Numeric (long)",
    MONEY: "Money",
    PERCENT: "Percent",
    MONEY_EUR: "Money (EUR)",
    MONEY_USD: "Money (USD)",
    MONEY_GBP: "Money (GBP)",
    MONEY_JPY: "Money (JPY)",
    MONEY_CHF: "Money (CHF)",
    MONEY_PLN: "Money (PLN)",
    INTEGER: "Integer",
};
export const useFormatTypesQuery = ({ partitionId, tableType }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `/api/partitions/${partitionId}/create-requests/${tableType}/format-types`,
                )
                .then(getData)
                .then(optionsFromStringArray(FTen)),
        [axiosService, partitionId, tableType],
    );
};

const getTrackingProps = (uploadResult, payload) => ({
    dataUploadId: payload.dataUploadId ?? "new",
    uploadType: "normal",
    uploadTargetType: payload.entityType,
    uploadSchedule: payload.schedule ? "scheduled" : "instant",
    uploadResult,
});

export const useStartDataUploadMutation = ({
    projectId,
    partitionId,
    fileId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const { trackDataUpload } = useContext(TrackingContext);
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async payload =>
            axiosService
                .post(
                    `api/projects/${projectId}/partitions/${partitionId}/files/${fileId}/upload`,
                    payload,
                )
                .then(getData)
                .then(data => {
                    trackDataUpload(getTrackingProps("success", payload));
                    return afterSuccessCurrent(data);
                })
                .catch(e => {
                    trackDataUpload(getTrackingProps("failed", payload));
                    throw e;
                }),
        [
            afterSuccessCurrent,
            axiosService,
            fileId,
            partitionId,
            projectId,
            trackDataUpload,
        ],
        "Request for file processing has been successful",
        "Error occurred during file processing!",
    );
};

export const useDataLoadWarningsQuery = ({ partitionId }) => {
    const { dataUploadService } = useDic();

    return useQueryLoadable(
        async () =>
            dataUploadService.getDataLoadWarnings(partitionId).catch(error => {
                message.error(error.response.data.message);
            }),
        [dataUploadService, partitionId],
    );
};
