import { Card } from "@/components/DesignSystem";
import { getSizeWithLabelUnknownWrapper } from "@/utils/formats/bytes";
import PropTypes from "prop-types";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { chartSettings } from "./chartSettings";

const options = {
    maintainAspectRatio: false,
    tooltips: {
        callbacks: {
            label: (tooltipItem, data) => data.labels[tooltipItem.index],
        },
    },
};

const JavaMemory = ({ total, used, free }) => {
    const data = {
        labels: [
            `Used (${getSizeWithLabelUnknownWrapper(used)})`,
            `Free (${getSizeWithLabelUnknownWrapper(free)})`,
        ],
        datasets: [
            {
                ...chartSettings,
                data: [used, free],
            },
        ],
    };
    return (
        <Card
            spacing="medium"
            title={`JVM Memory (Total: ${getSizeWithLabelUnknownWrapper(
                total,
            )})`}
        >
            <Doughnut data={data} options={options} width={200} height={200} />
        </Card>
    );
};

JavaMemory.propTypes = {
    total: PropTypes.number,
    used: PropTypes.number,
    free: PropTypes.number,
};

export default JavaMemory;
