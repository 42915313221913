import { useCallback, useContext } from "react";

import { SecurityContext } from "@/security/authorization";
import { includesPermissionForPartition } from "@/security/permission.utils";

export function useIncludesRightsForPartition(permissions) {
    const securityContext = useContext(SecurityContext);
    const checkerFn = useCallback(
        (accountId, partitionId) =>
            includesPermissionForPartition(
                securityContext,
                accountId,
                partitionId,
                permissions,
            ),
        [securityContext, permissions],
    );

    return checkerFn;
}
