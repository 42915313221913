import { useDic } from "@/components/Dic/useDic.hook";
import { ModalProviderPropsPropType } from "@/modules/modal/CustomModal";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import React, { useEffect, useState } from "react";
import { accountsService } from "../../apps/accountApp/services/accounts.service";
import { mappersService } from "../../services/mapper.service";
import { CopyMapperDialog } from "./CopyMapperDialog";

export const CopyMapperDialogContainer = ({
    modalProviderProps: { onClose, visible },
    accountId,
    mapperId,
    name,
    afterSuccess,
}) => {
    const { messageService } = useDic();
    const [mappers, setMappers] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [instances, setInstances] = useState([]);

    const handleInstanceSelect = id => {
        mappersService
            .fetchMappers(id)
            .then(response => {
                setMappers(response.data.map(d => d.name));
            })
            .catch(e => setErrorMessage(getErrorMessage(e?.response?.data)));
    };

    const handleCopy = values => {
        mappersService
            .copy(mapperId, values)
            .then(() => {
                messageService.info({ content: t("mapper.copy.done") });
                onClose();
                afterSuccess?.();
            })
            .catch(e => {
                console.log("e", e);
                return setErrorMessage(getErrorMessage(e?.response?.data));
            });
    };

    useEffect(() => {
        if (visible) {
            accountsService
                .fetchInstances(accountId)
                .then(response => {
                    setInstances(response.data);
                })
                .catch(e =>
                    setErrorMessage(getErrorMessage(e?.response?.data)),
                );
        }
    }, [visible]);

    return (
        <CopyMapperDialog
            visible={visible}
            onInstanceSelect={handleInstanceSelect}
            defaultName={name}
            onCancel={onClose}
            onCopy={handleCopy}
            errorMessage={errorMessage}
            mappers={mappers}
            instances={instances}
        />
    );
};

CopyMapperDialogContainer.propTypes = {
    modalProviderProps: ModalProviderPropsPropType(),
};
