import { Container, Drawer } from "@/components/DesignSystem";
import get from "lodash/fp/get";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Property } from "../Property/Property.component";

const defaultValueRender = (text, record) => text;

const defaultPanelRender = (record, column) => {
    const {
        name,
        label,
        render,
        panelRenderValue = render || defaultValueRender,
        panelProps,
    } = column;

    return (
        <Property
            key={name}
            label={label}
            value={panelRenderValue(get(name, record), record)}
            {...panelProps}
        />
    );
};

// const Container_ = React.Fragment;
const Container_ = Container;

export const RecordDetailPanel = ({
    panelControl: { record, hide },
    renderPanelHeader,
    renderPanelExtra,
    panelColumns,
    renderPanelTitle,
}) => {
    const visibleColumns = useMemo(
        () => panelColumns.filter(({ visible }) => visible),
        [panelColumns],
    );

    return (
        <Drawer
            title={record ? renderPanelTitle?.(record) : undefined}
            visible={!!record}
            onClose={hide}
            panel
        >
            {!!record && (
                <Container_ type="padding" size="sm" scrollable>
                    {renderPanelHeader?.(record)}
                    {visibleColumns.map(
                        ({ panelRender = defaultPanelRender, ...column }) =>
                            panelRender(record, column),
                    )}
                    {renderPanelExtra?.(record)}
                </Container_>
            )}
        </Drawer>
    );
};

RecordDetailPanel.propTypes = {
    panelControl: PropTypes.shape({
        record: PropTypes.object,
        hide: PropTypes.func.isRequired,
    }),
    renderPanelHeader: PropTypes.func,
    renderPanelExtra: PropTypes.func,
    panelColumns: PropTypes.array,
};
