import { asValue } from "awilix";
import { workflowsAppLocationsMap } from "./workflowsApp.locations";

export function registerWorkflowsApp(dic) {
    const { locationRouterService } = dic.cradle;

    dic.register({
        workflowsAppLocation: asValue(workflowsAppLocationsMap),
    });

    locationRouterService.registerLocations(workflowsAppLocationsMap);
}
