import { Button, Gap, H3 } from "@/components/DesignSystem";
import { DataPreviewFileInfo } from "@/components/Packages/PackageTableDefinitionPanel/components/PackageDataMappingLayout/DataPreviewFileInfo.component";
import { DataPreviewTable } from "@/components/Packages/PackageTableDefinitionPanel/components/PackageDataMappingLayout/DataPreviewTable.component";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

const PackageDataMappingLayout = ({
    dataExample = {},
    changableColumnTypes,
    customColumns,
    disableNextButton,
    children,
    onNext,
    onBack,
    onCancel,
    nextButton = (
        <Button
            type="primary"
            disabled={disableNextButton}
            onClick={onNext}
            label={t("partition-send-data.button.continue")}
            data-test="submit"
        />
    ),
}) => {
    return (
        <>
            <H3>{t("package-data-upload.header")}</H3>
            <DataPreviewFileInfo dataExample={dataExample} />
            <DataPreviewTable
                dataExample={dataExample}
                changableColumnTypes={changableColumnTypes}
                customColumns={customColumns}
            />
            <Gap size="large" />

            {children}

            {nextButton}

            <BackButton onBack={onBack} />

            <Button
                type="text"
                onClick={onCancel}
                label={t("partition-send-data.button.cancel")}
                data-test="cancel"
            />
        </>
    );
};

PackageDataMappingLayout.propTypes = {
    changableColumnTypes: PropTypes.objectOf(
        PropTypes.arrayOf(PropTypes.string),
    ),
    children: PropTypes.any.isRequired,
    customColumns: PropTypes.func,
    dataExample: PropTypes.shape({
        numberOfRows: PropTypes.number,
        fileName: PropTypes.string,
    }),
    disableNextButton: PropTypes.bool,
    header: PropTypes.node,
    nextButton: PropTypes.node,
    onCancel: PropTypes.func.isRequired,
    onNext: PropTypes.func,
    onBack: PropTypes.func,
};

export default PackageDataMappingLayout;
