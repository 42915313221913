import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { checkboxColumn } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/Skip";
import { t } from "@/translations";

export const permissionAccountColumns = (
    onCheck,
    isCheckedFn,
    isIndeterminate = () => false,
    readOnly,
) => [
    {
        type: fieldTypes.TEXT,
        label: t("permissions-table.account"),
        name: "description",
    },
    {
        type: fieldTypes.TEXT,
        label: "",
        width: 35,
        name: "checked",
        render: checkboxColumn(isCheckedFn, onCheck, isIndeterminate, readOnly),
    },
];
