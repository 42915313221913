import { useDic } from "@/components/Dic/useDic.hook";
import { useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZZ";

export const formatDateTime = date => date?.format?.(DATE_TIME_FORMAT) || null;

const createDateRange = (from, to) => ({
    from: formatDateTime(from),
    to: formatDateTime(to),
});

export const useElkMessageStatisticsQuery = ({
    instanceId,
    range: [from, to],
    reloadToken,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () => (
            reloadToken,
            axiosService
                .post(
                    `/api/instances/${instanceId}/elastic-search/messages-statistics`,
                    createDateRange(from, to),
                )
                .then(getData)
        ),
        [axiosService, from, instanceId, to, reloadToken],
    );
};

const INITIAL_PAGE_SIZE = 10;
const INITIAL_PAGES_COUNT = 1000;

export const useProcessingFilesQuery = ({ instanceId, query, reloadToken }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .post(
                    `/api/instances/${instanceId}/elastic-search/processed-files`,
                    query,
                )
                .then(getData)
                .then(({ hits, total }) => {
                    const pageSize = Math.ceil(total.value / INITIAL_PAGE_SIZE);
                    void reloadToken;

                    return {
                        total,
                        files: hits,
                        pages:
                            pageSize < INITIAL_PAGES_COUNT
                                ? pageSize
                                : INITIAL_PAGES_COUNT,
                    };
                }),
        [axiosService, instanceId, query, reloadToken],
    );
};
