import isEqual from "lodash/fp/isEqual";
import { useRef } from "react";

export const usePossiblyStaleValue = (value, equals = isEqual) => {
    const ref = useRef(value);

    if (process.env.NODE_ENV !== "production") {
        if (!equals(ref.current, value)) {
            console.error(
                "Not possible to change initial value during component lifetime, using STALE value!",
                new Error().stack,
            );
        }
    }

    return ref.current;
};
