import React from "react";
import CellWithCopy from "@/components/DesignSystem/Table/components/CellWithCopy/CellWithCopy";
import { t } from "@/translations";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { Spin } from "antd";

export const sftpStatus = {
    STARTED: "STARTED",
    STARTING: "STARTING",
    STOPPED: "STOPPED",
    STOPPING: "STOPPING",
};

const sftpStatuses = {
    [sftpStatus.STARTED]: t("sftp-servers.list.column.status.type.STARTED"),
    [sftpStatus.STARTING]: t("sftp-servers.list.column.status.type.STARTING"),
    [sftpStatus.STOPPED]: t("sftp-servers.list.column.status.type.STOPPED"),
    [sftpStatus.STOPPING]: t("sftp-servers.list.column.status.type.STOPPING"),
};

export const sftpServersListColumns = [
    {
        name: "name",
        label: t("sftp-servers.list.column.name"),
    },
    {
        name: "url",
        label: t("sftp-servers.list.column.address"),
        render: (_, record) => (
            <CellWithCopy text={`${record.address}:${record.port}`}>
                {record.address}
            </CellWithCopy>
        ),
    },
    {
        name: "port",
        label: t("sftp-servers.list.column.port"),
    },
    {
        name: "status",
        label: t("sftp-servers.list.column.status"),
        type: fieldTypes.OPTION,
        options: sftpStatuses,
        render: type => (
            <>
                {[sftpStatus.STARTING, sftpStatus.STOPPING].includes(type) && (
                    <>
                        <Spin size="small" />
                    </>
                )}
                {t(`sftp-servers.list.column.status.type.${type}`)}
            </>
        ),
    },
];
