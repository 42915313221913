import { FIELD_NAME } from "@/components/Connections/InstanceRepoConnections";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useWithRestartingRoutesModal } from "@/components/Integrations/components/RestartingRoutesModal/useWithRestartingRoutesModal.hook";
import {
    UserAbortError,
    useWithConflictModal,
} from "@/components/Mappers/ConflictModal";
import {
    crudSuccess,
    hasValue,
    responseErrorMessage,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { getErrorMessageFromError } from "@/utils/state/error.utils";
import { encodeBase64 } from "@/utils/token";
import { message } from "antd";
import { entries, fromPairs, get, map, noop, pipe } from "lodash/fp";

const MIN_IM_VERSION_FOR_CONNECTION_DEBUG = "1.7.0-SNAPSHOT";

export const tap = fn => arg => {
    fn(arg);
    return arg;
};

export const useInstanceRepoConnectionsQuery = ({
    instanceId,
    reloadToken,
}) => {
    const { connectionsService } = useDic();

    return useQueryLoadable(
        async () =>
            connectionsService
                .fetchInstanceRepoConnections(instanceId)
                .then(getData),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [instanceId, reloadToken, connectionsService],
    );
};
export const useDeleteInstanceRepoConnectionMutation = ({
    instanceId,
    afterSuccess,
}) => {
    const { connectionsService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record =>
            connectionsService
                .deleteInstanceRepoConnection({
                    instanceId,
                    name: record[FIELD_NAME],
                    prn: record.prn,
                })
                .then(getData)
                .then(data => {
                    afterSuccessCurrent();
                    return { ...record, ...data };
                }),

        [afterSuccessCurrent, connectionsService, instanceId],
        ({ name }) =>
            t("general.message.deleted", {
                type: "Instance repo connection",
                name,
            }),
    );
};

export const useLocalCopyMutation = ({ instanceId, afterSuccess = noop }) => {
    const { connectionsService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record => {
            const payload = {
                name: record.name,
                parent: record.parent,
            };
            const res = await connectionsService.createLocalCopy(
                instanceId,
                record.id,
                payload,
            );
            afterSuccessCurrent();
            return res;
        },
        [afterSuccessCurrent, connectionsService, instanceId],
        crudSuccess("Connection Local Copy", "data.name", "C"),
    );
};

export const useConnectionsQuery = ({
    instanceId,
    reloadToken,
    onError = noop,
}) => {
    const { connectionsService } = useDic();
    const onErrorCurrent = useCurrentHandler(onError);

    return useQueryLoadable(
        async () =>
            connectionsService
                .fetchConnections(instanceId)
                .then(getData)
                .catch(e => {
                    onErrorCurrent(e);
                    throw e;
                }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [instanceId, reloadToken, connectionsService],
    );
};

export const useDebugEnabledQuery = ({ instanceId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `/api/instances/${instanceId}/sufficient-version/${MIN_IM_VERSION_FOR_CONNECTION_DEBUG}`,
                )
                .then(getData)
                .catch(e => {
                    message.error(
                        t("connection-list.error.cannot-resolve-im-version"),
                    );
                    throw e;
                }),
        [axiosService, instanceId],
    );
};

export const useDeleteConnectionMutation = ({
    instanceId,
    afterSuccess = noop,
}) => {
    const { connectionsService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record =>
            connectionsService
                .deleteConnection(instanceId, record.id)
                .then(getData)
                .then(data => {
                    afterSuccessCurrent();
                    return { ...record, ...data };
                }),

        [afterSuccessCurrent, connectionsService, instanceId],
        ({ name }) =>
            t("general.message.deleted", {
                type: "connection",
                name,
            }),
    );
};

export const useDeployConnectionMutation = ({
    instanceId,
    afterSuccess = noop,
}) => {
    const { connectionsService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const withConflictModal = useWithConflictModal();
    const withRestartingRoutesModal = useWithRestartingRoutesModal(instanceId);

    return useMutationLoadableWithNotification(
        async record =>
            withRestartingRoutesModal(
                () =>
                    withConflictModal(forceCommit =>
                        connectionsService.deployConnection(
                            instanceId,
                            record.id,
                            {
                                forceCommit,
                            },
                        ),
                    ),
                {
                    changedConnections: [record.name],
                },
            )
                .then(getData)
                .then(tap(afterSuccessCurrent)),

        [
            afterSuccessCurrent,
            connectionsService,
            instanceId,
            withConflictModal,
            withRestartingRoutesModal,
        ],
        "connection-form.deployment.success",
        e => (e instanceof UserAbortError ? null : responseErrorMessage(e)),
    );
};

export const useSetDebugConnectionMutation = ({ afterSuccess = noop } = {}) => {
    const { connectionsService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ instanceId, name, checked }) =>
            connectionsService
                .setConnectionDebug(checked, instanceId, name)
                .then(getData)
                .then(tap(afterSuccessCurrent)),

        [afterSuccessCurrent, connectionsService],
        null,
        "connection-list.error.cannot-set-debug",
    );
};

export const useConnectionTypesOptionsQuery = ({ instanceId }) => {
    const { connectionsService } = useDic();
    return useQueryLoadable(async () => {
        return connectionsService
            .fetchConnectionTypes(instanceId)
            .then(({ data }) => {
                const ct = Object.entries(data).map(([k, v]) => ({
                    value: k,
                    label: v,
                }));
                return ct;
            });
    }, [instanceId, connectionsService]);
};

export const useConnectionTypeQuery = ({ instanceId, type, canFetch }) => {
    const { connectionsService } = useDic();
    return useQueryLoadable(async () => {
        return canFetch
            ? connectionsService
                  .fetchConnectionType(instanceId, type)
                  .then(get("data"))
            : Promise.resolve(useConnectionTypeQuery.IDLE_VALUE);
    }, [instanceId, type, canFetch, connectionsService]);
};
useConnectionTypeQuery.IDLE_VALUE = undefined;
useConnectionTypeQuery.isIdle = ({ loadable }) =>
    hasValue(loadable) &&
    loadable.valueMaybe() === useConnectionTypeQuery.IDLE_VALUE;

export const useConnectionQuery = ({ instanceId, connectionId, canFetch }) => {
    const { connectionsService } = useDic();
    return useQueryLoadable(async () => {
        return canFetch
            ? connectionsService
                  .fetchConnection(instanceId, connectionId)
                  .then(response => {
                      const { name, type, configuration } = response.data;
                      const configurationFields = pipe(
                          entries,
                          map(([k, v]) => [`configuration.${k}`, v]),
                          fromPairs,
                      )(configuration);

                      return { name, type, ...configurationFields };
                  })
            : Promise.resolve(useConnectionTypeQuery.IDLE_VALUE);
    }, [instanceId, connectionId, canFetch, connectionsService]);
};
useConnectionTypeQuery.IDLE_VALUE = undefined;
useConnectionTypeQuery.isIdle = ({ loadable }) =>
    hasValue(loadable) &&
    loadable.valueMaybe() === useConnectionTypeQuery.IDLE_VALUE;

export const useTestConnectionMutation = ({ instanceId }) => {
    const { connectionsService } = useDic();
    return useMutationLoadableWithNotification(
        async ({ values }) =>
            connectionsService.testConnection(instanceId, values),
        [instanceId, connectionsService],
        "connection-form.test.successful",
        e =>
            t("connection-form.test.unsuccessful", {
                error: getErrorMessageFromError(e),
            }),
    );
};

export const useCreateConnectionMutation = ({ instanceId, afterSuccess }) => {
    const { connectionsService } = useDic();
    const withConflictModal = useWithConflictModal();
    const withRestartingRoutesModal = useWithRestartingRoutesModal(instanceId);

    return useMutationLoadableWithNotification(
        async ({ values, withDeploy, confirmDeployed }) =>
            withRestartingRoutesModal(
                () =>
                    withConflictModal(forceCommit =>
                        connectionsService.createConnection(
                            instanceId,
                            values,
                            {
                                withDeploy,
                                forceCommit,
                                confirmDeployed,
                            },
                        ),
                    ),
                {
                    changedConnections: [values.name],
                },
            ).then(response => {
                afterSuccess({
                    connectionId: response.data.id,
                    deployedConnectionId: withDeploy
                        ? encodeBase64(
                              JSON.stringify({
                                  instanceId,
                                  name: response.data.name,
                              }),
                          )
                        : null,
                    withDeploy,
                });
                return withDeploy;
            }),
        [
            withRestartingRoutesModal,
            withConflictModal,
            connectionsService,
            instanceId,
            afterSuccess,
        ],
        withDeploy =>
            withDeploy
                ? t("connection-form.created-and-deployed")
                : t("connection-form.created"),
        e => (e instanceof UserAbortError ? null : responseErrorMessage(e)),
    );
};

export const useSaveConnectionMutation = ({
    instanceId,
    connectionId,
    afterSuccess,
}) => {
    const { connectionsService } = useDic();
    const withConflictModal = useWithConflictModal();
    const withRestartingRoutesModal = useWithRestartingRoutesModal(instanceId);

    return useMutationLoadableWithNotification(
        async ({ values, withDeploy }) =>
            (withDeploy
                ? withRestartingRoutesModal(
                      () =>
                          withConflictModal(forceCommit =>
                              connectionsService.saveConnection(
                                  instanceId,
                                  connectionId,
                                  values,
                                  { withDeploy, forceCommit },
                              ),
                          ),
                      {
                          changedConnections: [values.name],
                      },
                  )
                : withConflictModal(forceCommit =>
                      connectionsService.saveConnection(
                          instanceId,
                          connectionId,
                          values,
                          { withDeploy, forceCommit },
                      ),
                  )
            ).then(() => {
                afterSuccess({ connectionId, withDeploy });
                return withDeploy;
            }),
        [
            withRestartingRoutesModal,
            withConflictModal,
            connectionsService,
            instanceId,
            connectionId,
            afterSuccess,
        ],
        withDeploy =>
            withDeploy
                ? t("connection-form.updated-and-deployed")
                : t("connection-form.updated"),
        e => (e instanceof UserAbortError ? null : responseErrorMessage(e)),
    );
};
