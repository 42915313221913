import { Drawer } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_HEIGHT_WITH_BREADCRUMB } from "@/components/DesignSystem/Table/constants";
import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import { PickUsersModal } from "@/components/PickUsersModal";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { T, t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { preferencesTypes } from "../../../../../constants/preferencesTypes.constants";
import { AccountGroupRightsDrawer } from "../RightsDrawer/AccountGroupRightsDrawer";
import { createColumns } from "./GroupAdminSettings.columns";
import { GroupDrawerInfoComponent } from "./GroupDrawerInfo.component";
import { NewGroupModalComponent } from "./NewGroupModal.component";

const VIEWS = {
    GROUP_PERMISSIONS: "GROUP_PERMISSIONS",
    GROUP_DETAIL: "GROUP_DETAIL",
    GROUP_EDIT_MODAL: "GROUP_EDIT_MODAL",
};

const preferencesType = preferencesTypes.USER_GROUPS_LIST_TABLE;

export const GroupAdminSettingsComponent = ({
    accountId,
    groups,
    projectUsers,
    inviteUsers,
    onModalCancel,
    onGroupAdd,
    onGroupDelete,
    visibleCreateGroupModal,
    fetchGroupDetail,
    onRemoveUserFromGroup,
    onRemoveUsersFromGroup,
    onGroupEdit,
}) => {
    const [detailGroup, setDetailGroup] = useState(null);
    const [addUserVisible, setAddUserVisible] = useState(false);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [view, setView] = useState(null);
    const usersToInvite = useMemo(() => {
        const alreadyInGroupIds = detailGroup?.users?.map(u => u.id) ?? [];
        return projectUsers.filter(u => !alreadyInGroupIds.includes(u.id));
    }, [detailGroup, projectUsers]);

    const onGroupClick = record => {
        fetchGroupDetail(record.id).then(res => {
            setSelectedGroups([record.id]);
            setDetailGroup(res.data);
            setView(VIEWS.GROUP_DETAIL);
        });
    };

    const removeUserFromGroup = record => {
        onRemoveUserFromGroup(detailGroup.id, record.id);
    };

    const removeUsersFromGroup = userIds => {
        onRemoveUsersFromGroup(detailGroup.id, userIds);
    };

    const onInviteUsers = users => {
        inviteUsers(detailGroup.id, users);
        setAddUserVisible(false);
    };

    const onEditModalOpen = group => {
        setView(VIEWS.GROUP_EDIT_MODAL);
        setSelectedGroups([group.id]);
    };

    const onEditModalCancel = () => setView(null);

    const onEditModalSave = role => {
        setView(null);
        onGroupEdit(role);
    };
    const closeGroupDetail = useCallback(() => {
        setView(null);
        setDetailGroup(null);
    }, []);
    const multipleGroupsPermissions = useCallback(groupIds => {
        setSelectedGroups(groupIds);
        setView(VIEWS.GROUP_PERMISSIONS);
    }, []);
    const singleRecordPermissions = useCallback(
        record => multipleGroupsPermissions([record.id]),
        [multipleGroupsPermissions],
    );
    const selectionContextMenu = useMemo(
        () => [
            {
                label: t("general.mass-permissions"),
                onClick: () => multipleGroupsPermissions(selectedGroups),
            },
        ],
        [selectedGroups, multipleGroupsPermissions],
    );
    const rowSelection = useSyncRowSelection(selectedGroups, setSelectedGroups);

    const onPermissionsClose = useCallback(() => {
        setView(null);
        setSelectedGroups([]);
    }, []);

    const columns = createColumns({
        onClick: onGroupClick,
    });
    const selectedGroup = useMemo(
        // only for edit modal, only for one group
        () =>
            selectedGroups.length === 1
                ? groups.find(({ id }) => id === selectedGroups[0])
                : null,
        [selectedGroups, groups],
    );

    const actionMenu = useCallback(
        record => (
            <ActionButton
                wrapperProps={{ onClick: e => e.stopPropagation() }}
                record={record}
                items={[
                    {
                        title: t("general.tooltip.edit"),
                        onClick: onEditModalOpen,
                    },
                    {
                        title: t("general.permissions"),
                        onClick: singleRecordPermissions,
                    },
                    {
                        title: t(
                            "account-admin-groups-list.remove-group.title",
                        ),
                        confirmMessage: t(
                            "account-admin-groups-list.remove-group.message",
                            { name: record.name },
                        ),
                        onConfirm: onGroupDelete,
                        color: "red",
                    },
                ]}
            />
        ),
        [onGroupDelete, singleRecordPermissions],
    );

    return (
        <>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                columns={columns}
                dataSource={groups}
                fixed
                restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
                rowKey="id"
                datasetSlicing="local"
                preferencesType={preferencesType}
                rowSelection={rowSelection}
                selectionContextMenu={selectionContextMenu}
            />
            <Drawer
                onClose={closeGroupDetail}
                visible={view === VIEWS.GROUP_DETAIL}
            >
                <GroupDrawerInfoComponent
                    users={detailGroup?.users ?? []}
                    name={detailGroup?.name ?? ""}
                    description={detailGroup?.description ?? ""}
                    onRemoveUser={removeUserFromGroup}
                    removeUsersFromGroup={removeUsersFromGroup}
                    onAddUser={() => setAddUserVisible(true)}
                />
            </Drawer>

            <AccountGroupRightsDrawer
                visible={Boolean(
                    view === VIEWS.GROUP_PERMISSIONS && !!selectedGroups.length,
                )}
                accountId={accountId}
                groupIds={selectedGroups}
                onClose={onPermissionsClose}
            />

            <NewGroupModalComponent
                onOk={onGroupAdd}
                onCancel={onModalCancel}
                visible={visibleCreateGroupModal}
            />
            <NewGroupModalComponent
                onOk={onEditModalSave}
                onCancel={onEditModalCancel}
                visible={view === VIEWS.GROUP_EDIT_MODAL && selectedGroup}
                title={t("account-admin-groups-list.modal.title.edit")}
                group={selectedGroup}
            />

            {addUserVisible && (
                <PickUsersModal
                    users={usersToInvite}
                    onOk={onInviteUsers}
                    onCancel={() => setAddUserVisible(false)}
                    title={
                        <T
                            id="account-admin-group-add-user.modal.title"
                            values={{
                                groupName: detailGroup?.name ?? null,
                            }}
                        />
                    }
                    description={
                        <T id="account-admin-group-add-user.modal.message" />
                    }
                    visible={addUserVisible}
                />
            )}
        </>
    );
};

GroupAdminSettingsComponent.propTypes = {
    accountId: PropTypes.number.isRequired,
    visible: PropTypes.bool,
    groups: PropTypes.array.isRequired,
    projectUsers: PropTypes.array.isRequired,
    inviteUsers: PropTypes.func.isRequired,
    onModalCancel: PropTypes.func.isRequired,
    onGroupAdd: PropTypes.func.isRequired,
    onGroupDelete: PropTypes.func.isRequired,
    visibleCreateGroupModal: PropTypes.bool.isRequired,
    fetchGroupDetail: PropTypes.func.isRequired,
    onRemoveUserFromGroup: PropTypes.func.isRequired,
    onRemoveUsersFromGroup: PropTypes.func.isRequired,
    onGroupEdit: PropTypes.func.isRequired,
};
