import { Menu } from "@/components/DesignSystem";
import { useCenteredLayout } from "@/components/hooks/useContentLayout.hook";
import { t } from "@/translations";
import React from "react";
import { SidebarAndContentLayout } from "../Layouts/SidebarAndContentLayout";
import { CapacityConfiguration } from "./CapacityConfiguration";

const CapacityConfigurationPage = () => {
    useCenteredLayout();

    return (
        <SidebarAndContentLayout
            showHeader={false}
            sidebar={
                <Menu selectedKeys={["capacity"]} mode="inline" condensed>
                    <Menu.Item key="capacity">
                        {t("capacity.nav-item.capacity")}
                    </Menu.Item>
                </Menu>
            }
        >
            <CapacityConfiguration />
        </SidebarAndContentLayout>
    );
};

export default CapacityConfigurationPage;
