import { Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import SubFieldElement from "@/components/GenericForm/components/SubFieldElement";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import {
    hasError,
    pendingPromise,
    responseErrorMessage,
    useQueryLoadable,
} from "@/modules/loadable";
import { mapperMappingSourceType } from "@/services/mapperMappingSourceType.enum";
import { getData } from "@/services/utils";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import map from "lodash/map";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
const { Fields } = Forms;

export const getLoadableSelectError = loadable =>
    hasError(loadable)
        ? { placeholder: responseErrorMessage(loadable.contents) }
        : {};

const SelectPFXData = ({
    formId,
    selectProps,
    objectId,
    objectType = mapperMappingSourceType.partitions,
    field,
    entityType,
    setValues,
}) => {
    const entityName = useFieldValue({
        formId,
        name: selectProps.name,
    });

    const resetSubfields = useCallback(() => {
        setValues(
            (field.subFields ?? []).reduce(
                (valuesToReset, subField) => ({
                    ...valuesToReset,
                    [subField.name]: undefined,
                }),
                {},
            ),
        );
    }, [field.subFields, setValues]);

    const { metadataService } = useDic();

    const metadataTypeNamesOptions = useQueryLoadable(
        async () =>
            objectId && entityType
                ? await metadataService
                      .fetchEntityTypeNames(
                          objectType,
                          objectId,
                          entityType,
                          field.filters,
                      )
                      .then(getData)
                : pendingPromise(),
        [objectId, entityType, metadataService, objectType, field.filters],
    );

    return (
        <>
            <Fields.Select
                {...selectProps}
                {...getLoadableSelectProps(metadataTypeNamesOptions.loadable)}
                {...getLoadableSelectError(metadataTypeNamesOptions.loadable)}
                onChange={resetSubfields}
            />
            {field.subFields ? (
                <>
                    {map(field.subFields, field => (
                        <SubFieldElement
                            key={field.name}
                            objectId={objectId}
                            objectType={objectType}
                            field={field}
                            entityType={entityType}
                            entityName={entityName}
                        />
                    ))}
                </>
            ) : null}
        </>
    );
};

SelectPFXData.propTypes = {
    formId: PropTypes.string,
    selectProps: PropTypes.object.isRequired,
    objectId: PropTypes.number.isRequired,
    objectType: PropTypes.string,
    field: PropTypes.object.isRequired,
    entityType: PropTypes.string,
    disabled: PropTypes.bool,
    setValues: PropTypes.func.isRequireds,
};

export default SelectPFXData;
