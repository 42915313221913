import { useContext } from "react";
import { SecurityContext } from "../authorization";
import { includesPermissionForIntegration } from "../permission.utils";

export const useHasIntegrationPermission = ({
    accountId,
    instanceId,
    permissions,
}) => {
    const securityContextValue = useContext(SecurityContext);
    return includesPermissionForIntegration(
        securityContextValue,
        instanceId,
        accountId,
        permissions,
    );
};
