import { useDic } from "@/components/Dic/useDic.hook";
import { useMarketplaceAppParams } from "@/modules/router/hooks/useMarketplaceAppParams.hook";
import PropTypes from "prop-types";
import React from "react";
import { DeployPackageContainer } from "../../../apps/marketplaceApp/components/DeployPackage/DeployPackage.container";

export const PackagePage = ({ onPackageConfirmation }) => {
    const {
        locationRouterService,
        marketplaceAppLocations: { marketplaceLocation },
    } = useDic();
    const { partitionId, packageName, instanceId, accountId } =
        useMarketplaceAppParams();

    function navigateToMarketplace() {
        locationRouterService.navigate(marketplaceLocation);
    }

    return (
        <DeployPackageContainer
            accountId={accountId}
            partitionId={partitionId}
            instanceId={instanceId}
            packageName={packageName}
            onPackageConfirmation={
                onPackageConfirmation || navigateToMarketplace
            }
            onPackageCanceled={onPackageConfirmation || navigateToMarketplace}
        />
    );
};

PackagePage.propTypes = {
    onPackageConfirmation: PropTypes.func,
};
