import { AppHeader } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    HeaderProfile,
    useHeaderProfileMenuItems,
} from "@/components/Header/components/Profile/HeaderProfile";
import React, { useCallback } from "react";
import { LOGO_CLICK } from "../../mixpanel/mixpanel.constants";

import { useHeaderItemGoToPfx } from "@/components/Header/components/HeaderGoToPFX";
import { useHeaderItemHelp } from "@/components/Header/components/Help";
import { useHeaderItemNotifications } from "@/components/Header/components/Notifications/HeaderNotifications";
import { useHeaderMenuItems } from "@/components/Header/useHeaderMenuItems.hook";
import { AppVersion } from "@/components/Sidebar/AppVersion/AppVersion";
import { getRootName } from "@/modules/router/location/location.utils";
import { t } from "@/translations";
import { filterVisible } from "@/utils/filterVisible";
import { useRoute } from "react-router5";

const Header = () => {
    const {
        authenticationService,
        locationRouterService,
        locations: { defaultRouteLocation },
        mixpanelService,
    } = useDic();

    const isLoggedIn = authenticationService.isLoggedIn();
    // const userName = basicInfo.userInfo.user.username;

    const onLogoClick = useCallback(() => {
        if (isLoggedIn) {
            mixpanelService.track(LOGO_CLICK, {
                // user_name: userName,
            });
            locationRouterService.navigate(defaultRouteLocation);
        }
    }, [
        defaultRouteLocation,
        isLoggedIn,
        locationRouterService,
        mixpanelService,
        // userName,
    ]);

    const pfxItem = useHeaderItemGoToPfx();
    const menu = useHeaderMenuItems();

    const notificationsItem = useHeaderItemNotifications();
    const profileItems = useHeaderProfileMenuItems();
    const helpItem = useHeaderItemHelp();
    const { route } = useRoute();

    const routeName = getRootName(route.name);
    const headerProps = isLoggedIn
        ? {
              title: routeName ? t(`location.${routeName}`) : undefined,
              menu: filterVisible(menu),
              menuSelectedKeys: [routeName],
              rightMenu: filterVisible([
                  pfxItem,
                  notificationsItem,
                  helpItem,
                  {
                      key: "header-profile",
                      component: HeaderProfile,
                      items: profileItems,
                  },
              ]),
          }
        : {};

    return (
        <AppHeader
            logoVariant="pm"
            onLogoClick={onLogoClick}
            buildVersion={<AppVersion />}
            {...headerProps}
        />
    );
};

Header.propTypes = {};

export default Header;
