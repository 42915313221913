import {
    Alert,
    Button,
    ButtonGroup,
    Forms,
    H3,
} from "@/components/DesignSystem";
import { GenericFormInner } from "@/components/GenericForm/GenericFormInner";
import { useValidateGenericForm } from "@/components/Packages/PackageTableDefinitionPanel/components/GenericForm/GenericForm";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { mapperMappingSourceType } from "@/services/mapperMappingSourceType.enum";
import { t } from "@/translations";
import { size } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { StepUiHeaders } from "../TableComponents/Skip";

const { useForm, Form, SubmitButton } = Forms;

export const FormIntegrationProperties = ({
    step = {},
    onNext,
    onBack,
    onCancel,
    instanceId,
    apiResult,
}) => {
    const { onSubmit, errorMessages } = useValidateGenericForm({
        templateName: apiResult.templateName,
        query: useMemo(() => ({ instanceId }), [instanceId]),
        onNext,
    });

    const { formId, handleSubmit, setValues, setTouched } = useForm({
        onSubmit,
    });

    const initialValues = useMemo(() => {
        return {
            ...step.form.reduce(
                (result, field) => ({ ...result, [field.name]: field.default }),
                {},
            ),
            ...step.oldValues,
        };
    }, [step.form, step.oldValues]);

    useSetValidatedInitialValues({ initialValues, setValues, setTouched }, [
        initialValues,
    ]);

    return (
        <Form formId={formId} onSubmit={handleSubmit}>
            {!step?.ui?.title && <H3>{t("generic-form.title")}</H3>}
            <StepUiHeaders ui={step.ui} />
            {size(errorMessages) > 0 && (
                <Alert message={errorMessages[0]} type="error" showIcon />
            )}
            <GenericFormInner
                formId={formId}
                formDefinitions={step.form}
                objectId={step.connectionId}
                objectType={mapperMappingSourceType.connections}
                setValues={setValues}
            />

            <ButtonGroup>
                <SubmitButton
                    type="primary"
                    label={t("partition-send-data.button.continue")}
                />
                <BackButton onBack={onBack} />
                <Button
                    type="text"
                    onClick={onCancel}
                    size="large"
                    label={t("partition-send-data.button.cancel")}
                />
            </ButtonGroup>
        </Form>
    );
};

FormIntegrationProperties.propTypes = {
    step: PropTypes.object.isRequired,
    apiResult: PropTypes.object,
    instanceId: PropTypes.number.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
};
