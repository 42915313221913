import { FieldCustomizationModal } from "@/components/CalculatedFieldTable/FieldCustomization.modal";
import { Button, Input, Select } from "@/components/DesignSystem";
import { Option } from "@/components/DesignSystem/Select";
import { t } from "@/translations";
import { ReactComponent as Wrench } from "@pricefx/unity-components/src/icons/unicons/wrench.svg";
import cx from "classnames";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import {
    conformToMapping,
    TABS_VALUE_TYPES,
} from "../CalculatedFieldTable/FieldCustomizationTabs.component";
import {
    convertToNumbersForFormula,
    getHeadersOfStringType,
} from "../Packages/PackageTableDefinitionPanel/components/PackageMandatoryFields/PackageMandatoryFields.utils";
import styles from "../Packages/PackageTableDefinitionPanel/components/styles.less";
import {
    InputType,
    ParsedType,
} from "../Packages/PackageTableDefinitionPanel/constants";
import "./CompositeSelect.style.less";

export const Mode = Object.freeze({
    DEFAULT: "default",
    MULTIPLE: "multiple",
});

const getStringFieldsOptions = fields =>
    fields.map(name => (
        <Option title={name} key={name} value={name}>
            {name}
        </Option>
    ));

export const isMultiple = type =>
    !type || type === ParsedType.STRING || type === ParsedType.NUMBER;

const selectComponent = ({
    inputType,
    onChangeSelectClassic,
    onChangeInSelectMultiple,
    onChangeInInputField,
    stringFields,
    children,
    input,
    disabled,
    error, // TODO?
}) => {
    if (isEmpty(input) || !inputType || inputType === InputType.BODY) {
        return (
            <Select
                placeholder={t("packages.select.placeholder")}
                showSearch
                allowClear
                onChange={onChangeSelectClassic}
                className={styles.itemSelect}
                value={input}
                mode={Mode.DEFAULT}
                disabled={disabled}
            >
                {children}
            </Select>
        );
    }

    if (inputType === InputType.COMPOSED) {
        return (
            <Select
                placeholder={t("packages.select.placeholder")}
                showSearch
                allowClear
                onChange={onChangeInSelectMultiple}
                className={styles.itemSelect}
                value={input}
                mode={Mode.MULTIPLE}
                disabled={disabled}
            >
                {getStringFieldsOptions(stringFields)}
            </Select>
        );
    }

    if (inputType === InputType.GROOVY || inputType === InputType.FORMULA) {
        return (
            <Input
                placeholder={t("packages.select.placeholder")}
                onChange={onChangeInInputField}
                allowClear
                className={styles.itemSelect}
                value={input}
                disabled={disabled}
            />
        );
    }
};

export const CompositeSelect = ({
    type,
    inputType,
    input,
    formulaMapping,
    onChange,
    className,
    children,
    disabled,
    tableExampleData,
}) => {
    const classes = cx("pmCompositeSelect", className);
    const [modalVisible, setModalVisible] = useState(false);
    const error = useMemo(
        () =>
            inputType !== InputType.FORMULA
                ? null
                : conformToMapping({
                      input: input,
                      formulaMapping:
                          convertToNumbersForFormula(tableExampleData),
                      previousFormulaMapping: formulaMapping,
                  }).error,
        [input, inputType, tableExampleData, formulaMapping],
    );

    const onChangeSelectClassic = input =>
        onChange({ input, inputType: InputType.BODY });
    const onChangeInSelectMultiple = input =>
        onChange({ input, inputType: InputType.COMPOSED });
    const onChangeInInputField = v =>
        onChange({ input: v?.target?.value, inputType });

    const onChangeInModal = ({ input, inputType, formulaMapping }) => {
        setModalVisible(false);
        onChange({ input, inputType, formulaMapping });
    };
    const stringFields = useMemo(
        () => getHeadersOfStringType(tableExampleData),
        [tableExampleData],
    );

    return (
        <>
            <div className={classes}>
                {isMultiple(type) ? (
                    <Button
                        disabled={disabled}
                        style={{
                            marginLeft: 8,
                        }}
                        icon={Wrench}
                        onClick={() => setModalVisible(true)}
                        tooltip={{ title: t("advanced-field-editor.title") }}
                    />
                ) : (
                    <div />
                )}
                {selectComponent({
                    input,
                    inputType,
                    error,
                    disabled,
                    children,
                    stringFields,
                    onChangeSelectClassic,
                    onChangeInSelectMultiple,
                    onChangeInInputField,
                })}
            </div>
            <FieldCustomizationModal
                visible={modalVisible}
                onSave={onChangeInModal}
                onCancel={() => setModalVisible(false)}
                initialValues={{
                    input,
                    inputType,
                    outputType: type,
                    formulaMapping,
                }}
                tableExampleData={tableExampleData}
                hideTabs={[
                    TABS_VALUE_TYPES.FIELD,
                    TABS_VALUE_TYPES.EXPERT_OPTION,
                ]}
            />
        </>
    );
};

CompositeSelect.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.oneOf(Object.values(ParsedType)).isRequired,
    inputType: PropTypes.oneOf(Object.values(InputType)),
    input: PropTypes.oneOfType([
        PropTypes.array.isRequired,
        PropTypes.string.isRequired,
    ]),
    formulaMapping: PropTypes.object,
    tableExampleData: PropTypes.object,
    disabled: PropTypes.bool,
};
