import { mapValues } from "lodash";
import { permissionsApiSchema } from "./permissionApi.schemas";

export const addTypeAndSort = byType =>
    mapValues(byType, (permissions, type) =>
        permissions
            .map(p => ({ ...p, type }))
            .sort((a, b) => a.name.localeCompare(b.name)),
    );

export class PermissionsApiService {
    baseUrl = "/api/admin/permissions";
    httpService = null;

    constructor({ httpService }) {
        this.httpService = httpService;
    }

    fetchNonGlobalPermission() {
        return this.httpService
            .get(this.getNonGlobalPermissionPath())
            .then(addTypeAndSort);
    }

    /**
     *  @returns {Promise<PermissionEntity>}
     */
    fetchGlobalPermission() {
        return this.httpService.get(this.getUrl("global"), {
            schema: permissionsApiSchema,
        });
    }

    fetchUserGlobalPermissions(userId) {
        return this.httpService.get(this.getUrl(`users/${userId}`));
    }

    fetchGroupUserGlobalPermissions(userId) {
        return this.httpService.get(
            this.getUrl(`users/${userId}/read-only`),
            {},
        );
    }

    fetchGroupGlobalPermissions(groupName) {
        return this.httpService.get(this.getUrl(`groups/${groupName}`));
    }

    saveUserGlobalPermissions(userId, permissions) {
        return this.httpService.post(this.getPostUrl(userId), permissions);
    }

    saveGroupGlobalPermissions(groupName, permissions) {
        return this.httpService.post(
            `/api/admin/groups/${groupName}/permissions`,
            permissions,
        );
    }

    /**
     *  @returns {Promise<PermissionsBagEntity>}
     */
    fetchPermissions() {
        return this.httpService.get(this.baseUrl, {
            schema: permissionsApiSchema,
            // mapper: this.permissionsApiMapper
        });
    }

    getNonGlobalPermissionPath() {
        return this.getUrl("non-global");
    }

    getUrl(path) {
        return `${this.baseUrl}/${path}`;
    }

    getPostUrl(userId) {
        return `/api/admin/users/${userId}/permissions`;
    }
}
