import { Row } from "@/components/DesignSystem";
import { T } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import styles from "../styles.less";

export const MultipleTitle = ({ mandatoryFields, fileName }) => {
    return (
        <span className={styles.descriptionColor}>
            <Row className={styles.switchHeader} type="flex" align="middle">
                {/* {showSwitchLabel(mandatoryFields) && <MultipleFields />} removed as there is Advanced field editor and not multiple fields, TODO: fix when moving editor icon inside inputs */}
                <div style={{ paddingLeft: "114px" }}>
                    <T
                        id="package-data-upload.label.file-fields"
                        values={{
                            fileName,
                        }}
                    />
                </div>
            </Row>
        </span>
    );
};

MultipleTitle.propTypes = {
    fileName: PropTypes.string.isRequired,
    mandatoryFields: PropTypes.any.isRequired,
};
