import { groupMissingRightsByPfxModule } from "@/components/CustomerPartitionCredentials/loadables";
import { Text } from "@/components/DesignSystem";
import { T } from "@/translations";
import { Collapse } from "@pricefx/unity-components";
import React from "react";

export const InsufficientRightsPanel = ({ missingRights }) => {
    return (
        <Collapse>
            <Collapse.Panel
                header={
                    <Text type="error">
                        <T id="partition-password.missing-rights" />
                    </Text>
                }
                key="1"
            >
                {Object.entries(
                    groupMissingRightsByPfxModule(missingRights),
                ).map(([pfxModule, pfxRoleLabels]) => {
                    return (
                        <>
                            <Text>{pfxModule}</Text>
                            <ul>
                                {pfxRoleLabels.map(pfxRoleLabel => (
                                    <li key={pfxRoleLabel}>{pfxRoleLabel}</li>
                                ))}
                            </ul>
                        </>
                    );
                })}
            </Collapse.Panel>
        </Collapse>
    );
};
