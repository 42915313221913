import { useDic } from "@/components/Dic/useDic.hook";
import { FavoriteType } from "@/components/Marketplace/components/FavoriteButton/favorite.types";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useMutationLoadableWithNotification } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";

export const useSaveFavoriteMutation = ({ afterSuccess }) => {
    const { favoritesService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ id, type }) =>
            favoritesService
                .saveFavorite({ id, type })
                .then(getData)
                .then(data => {
                    afterSuccessCurrent();
                    return { id, type, ...data };
                }),

        [afterSuccessCurrent, favoritesService],
        ({ name }) =>
            t("favorites.message.added", {
                type: FavoriteType.TEMPLATE.toLowerCase(),
                name,
            }),
    );
};
export const useDeleteFavoriteMutation = ({ afterSuccess }) => {
    const { favoritesService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ id, type }) =>
            favoritesService
                .deleteFavorite({ id, type })
                .then(getData)
                .then(data => {
                    afterSuccessCurrent();
                    return { id, type, ...data };
                }),

        [afterSuccessCurrent, favoritesService],
        ({ name }) =>
            t("favorites.message.deleted", {
                type: FavoriteType.TEMPLATE.toLowerCase(),
                name,
            }),
    );
};
