import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_HEIGHT_WITH_BREADCRUMB } from "@/components/DesignSystem/Table/constants";
import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { userService } from "../../../services/user.service";
import { AccountUserRightsDrawer } from "../RightsDrawer/AccountUserRightsDrawer";
import { AssignUserToAtlassianGroupModal } from "./atlassian/AssignUserToAtlassianGroupModal";
import { RemoveUserFromAtlassianGroupModal } from "./atlassian/RemoveUserFromAtlassianGroupModal";
import { createColumns } from "./userAdminSettingsTable.columns";
import { message } from "@/components/DesignSystem";
import { ACCOUNT_USER_MANAGEMENT_PERMISSIONS } from "@/security/permission.utils";
import { isPricefxUsername } from "../RightsDrawer/loadables";

const VIEWS = {
    DEFAULT: "default",
    SHOW_ASSETS_PERMISSIONS_PANEL: "showAssetsPermissionsPanel",
};

const preferencesType = preferencesTypes.USERS_LIST_TABLE;

export const UserAdminSettingsTableContainer = React.memo(
    function UserAdminSettingsTableContainer({
        accountId,
        users,
        onUserDelete,
        visible,
        // reload,
    }) {
        const [view, setView] = useState(VIEWS.DEFAULT);
        const [selectedUsers, setSelectedUsers] = useState([]);
        const multipleIdsPermissions = useCallback(userIds => {
            setSelectedUsers(userIds);
            setView(VIEWS.SHOW_ASSETS_PERMISSIONS_PANEL);
        }, []);
        const onCancel = useCallback(() => {
            setView(VIEWS.DEFAULT);
            setSelectedUsers([]);
        }, []);
        const singleRecordPermissions = useCallback(
            user => multipleIdsPermissions([user.id]),
            [multipleIdsPermissions],
        );

        const selectionContextMenu = useMemo(
            () => [
                {
                    label: t("general.mass-permissions"),
                    onClick: () => multipleIdsPermissions(selectedUsers),
                },
            ],
            [selectedUsers, multipleIdsPermissions],
        );
        const rowSelection = useSyncRowSelection(
            selectedUsers,
            setSelectedUsers,
        );

        const columns = useMemo(() => createColumns(), []);
        const assignUserToAtlassianGroupModal = useDetailDrawerState();
        const removeUserFromAtlassianGroupModal = useDetailDrawerState();
        const isUserInvited = record => record.status === "INVITED";

        const resendActivation = record =>
            userService
                .resendActivation(accountId, record.id)
                .then(() => {
                    message.success(
                        t(
                            "account-admin-users-list.resend-activation-email-ok",
                        ),
                    );
                })
                .catch(() => {
                    message.error(
                        t(
                            "account-admin-users-list.resend-activation-email-failed",
                        ),
                    );
                });

        const actionMenu = useCallback(
            record => (
                <ActionButton
                    wrapperProps={{ onClick: e => e.stopPropagation() }}
                    record={record}
                    items={[
                        {
                            title: t("general.permissions"),
                            onClick: singleRecordPermissions,
                        },
                        {
                            title: t(
                                "account-admin-users-list.atlassian.atlassian-management",
                            ),
                            items: [
                                {
                                    title: t(
                                        "account-admin-users-list.atlassian.assign-to-group",
                                    ),
                                    onClick:
                                        assignUserToAtlassianGroupModal.show,
                                },
                                record.atlassianAccountId && {
                                    title: t(
                                        "account-admin-users-list.atlassian.remove-from-group",
                                    ),
                                    onClick:
                                        removeUserFromAtlassianGroupModal.show,
                                    color: "red",
                                },
                            ],
                        },
                        {
                            title: t(
                                "account-admin-users-list.resend-activation-email",
                            ),
                            visible:
                                isUserInvited(record) &&
                                !isPricefxUsername(record.username),
                            permission: ACCOUNT_USER_MANAGEMENT_PERMISSIONS,
                            onClick: () => resendActivation(record),
                        },
                        {
                            title: t(
                                "account-admin-users-list.remove-user.title",
                            ),
                            confirmMessage: t(
                                "account-admin-users-list.remove-user.message",
                                {
                                    username: record.username,
                                },
                            ),
                            onConfirm: onUserDelete,
                            color: "red",
                        },
                    ]}
                />
            ),
            [
                assignUserToAtlassianGroupModal.show,
                onUserDelete,
                removeUserFromAtlassianGroupModal.show,
                singleRecordPermissions,
            ],
        );

        const [tableProps, { reload }] = useFetchPage(
            (page, size, sort, filter) =>
                userService.fetchUsersTable(
                    page,
                    size,
                    sort,
                    filter,
                    accountId,
                ),
            [accountId, users],
        );
        const afterSuccess = useCallback(() => {
            assignUserToAtlassianGroupModal.hide();
            removeUserFromAtlassianGroupModal.hide();
            reload();
        }, [
            assignUserToAtlassianGroupModal,
            removeUserFromAtlassianGroupModal,
            reload,
        ]);

        return (
            <>
                <TableWithPreferencesManagement
                    actionMenu={actionMenu}
                    columns={columns}
                    fixed
                    restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
                    rowKey="id"
                    rowSelection={rowSelection}
                    selectionContextMenu={selectionContextMenu}
                    datasetSlicing="server"
                    preferencesType={preferencesType}
                    exportUrl={`/api/projects/${accountId}/users/export`}
                    // dataSource={users} // TODO? Currently fetched twice by parent component and this component
                    {...tableProps}
                />
                <AccountUserRightsDrawer
                    visible={Boolean(
                        view === VIEWS.SHOW_ASSETS_PERMISSIONS_PANEL &&
                            !!selectedUsers.length,
                    )}
                    accountId={accountId}
                    userIds={selectedUsers}
                    onClose={onCancel}
                />
                <AssignUserToAtlassianGroupModal
                    projectId={accountId}
                    record={assignUserToAtlassianGroupModal.record}
                    onClose={assignUserToAtlassianGroupModal.hide}
                    afterSuccess={afterSuccess}
                />
                <RemoveUserFromAtlassianGroupModal
                    projectId={accountId}
                    record={removeUserFromAtlassianGroupModal.record}
                    onClose={removeUserFromAtlassianGroupModal.hide}
                    afterSuccess={afterSuccess}
                />
            </>
        );
    },
);

UserAdminSettingsTableContainer.propTypes = {
    accountId: PropTypes.number.isRequired,
    visible: PropTypes.bool,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    onUserDelete: PropTypes.func.isRequired,
};
