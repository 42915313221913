import { useDic } from "@/components/Dic/useDic.hook";
import { useEffect, useRef } from "react";

const RedirPage = () => {
    const hasFetched = useRef(false);
    const { authenticationService } = useDic();

    useEffect(() => {
        if (!hasFetched.current) {
            hasFetched.current = true;
            console.log("try redirecting start");
            authenticationService.redirectToAuthServer();
        }
    }, [authenticationService]);

    return null;
};

export default RedirPage;
