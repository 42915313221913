import { useDataUploadFileErrorsQuery } from "@/components/DataUploads/loadables";
import {
    Alert,
    ButtonMenu,
    Table,
    Tooltip,
    UnityLayout,
} from "@/components/DesignSystem";
import { useExportMutation } from "@/components/DesignSystem/Table/hooks/useExportCsv.hook";
import { isLoading, LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

export const columns = [
    {
        label: t("package-failed-option.table.column.row"),
        name: "row_index",
        width: "200",
    },
    {
        label: t("package-failed-option.table.column.input-key"),
        name: "input_key",
        width: "200",
    },
    {
        label: t("package-failed-option.table.column.input-value"),
        name: "input_value",
        width: "200",
    },
    {
        label: t("package-failed-option.table.column.error"),
        name: "message",
    },
];

const DataUploadAlert = ({ data }) => {
    if (!data) return <LoadableRenderer.Error />;

    if (data.status === 404)
        return (
            <Alert
                showIcon
                type="warning"
                message={t("failed-records.old-unavailable-warning")}
            />
        );
    else
        return (
            <Alert
                showIcon
                type="error"
                message={t("failed-records.no-details-error", {
                    message: data.error || data.message || "",
                })}
            />
        );
};

export const DataUploadFailedRecords = ({
    partitionId,
    uploadId,
    fileId,
    failedRecordsCount,
}) => {
    const errorsQuery = useDataUploadFileErrorsQuery({
        partitionId,
        uploadId,
        fileId,
    });
    const exportMutation = useExportMutation({
        fileName: "data-upload-failed-records.csv",
        exportUrl: `/api/partitions/${partitionId}/data-uploads/${uploadId}/files/${fileId}/error-messages/export`,
        columns,
    });

    return (
        <UnityLayout>
            <UnityLayout.Header
                title={
                    <Tooltip title={t("failed-records.tooltip")}>
                        {t("failed-records-count.title", {
                            recordsCount: failedRecordsCount,
                        })}
                    </Tooltip>
                }
                hideBackButton={true}
                actionButtons={
                    <ButtonMenu
                        buttons={[
                            {
                                label: t("button.export-csv"),
                                key: "button.export-csv",
                                size: "small",
                                type: "secondary",
                                onClick: () => exportMutation.mutate(),
                                loading: isLoading(exportMutation),
                            },
                        ]}
                    />
                }
            />
            <UnityLayout.Content padding={[false, false]}>
                <LoadableRenderer
                    loadable={errorsQuery.loadable}
                    hasValue={({ data: dataSource }) => (
                        <Table
                            rowKey="row_index"
                            dataSource={dataSource}
                            columns={columns}
                            pagination={false}
                            fixed
                            fixedHeight={300}
                            padding={false}
                            loading={isLoading(errorsQuery)}
                        />
                    )}
                    hasError={error => (
                        <DataUploadAlert data={error?.response?.data} />
                    )}
                />
            </UnityLayout.Content>
        </UnityLayout>
    );
};

DataUploadFailedRecords.propTypes = {
    partitionId: PropTypes.number.isRequired,
    uploadId: PropTypes.number.isRequired,
    failedRecordsCount: PropTypes.number.isRequired,
    fileId: PropTypes.string.isRequired,
};
