import { registerMarketplaceApp } from "@/components/Marketplace/marketplaceApp.register";
import { registerAccountApp } from "../apps/accountApp/accountApp.register";
import { registerAdministrationApp } from "../apps/administrationApp/administrationApp.register";
import { registerOverviewApp } from "../apps/overviewApp/overviewApp.register";
import { registerProductEnhancementApp } from "../apps/productEnhancementApp/productEnhancementApp.register";
import { registerWorkflowsApp } from "../apps/workflowsApp/workflowsApp.register";

export function registerApps(dic) {
    registerAccountApp(dic);
    registerAdministrationApp(dic);
    registerMarketplaceApp(dic);
    registerOverviewApp(dic);
    registerProductEnhancementApp(dic);
    registerWorkflowsApp(dic);
}
