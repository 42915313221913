import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    crudSuccess,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getBrowser, getScreenSize } from "@/utils/browserUtils";
import qs from "qs";
import { axiosService } from "../../axios";

const productEnhancementService = {
    getListOfEnhancements(getParams, filter) {
        return axiosService.post(
            `/api/jira-tasks${qs.stringify(getParams, {
                addQueryPrefix: true,
            })}`,
            filter,
        );
    },
};

export const useListOfEnhancements = tableParams =>
    useQueryLoadable(() => {
        const [getParams, filter] = tableParams;
        return productEnhancementService.getListOfEnhancements(
            getParams,
            filter,
        );
    }, [tableParams]);

export const useCreateJiraTaskResource = ({ afterSave }) => {
    const { axiosService } = useDic();
    const currentAfterSave = useCurrentHandler(afterSave);
    return useMutationLoadableWithNotification(
        async ({ attachments = [], ...values }) => {
            const formData = new FormData();
            attachments.map(attachment =>
                formData.append("attachments[]", attachment.originFileObj),
            );
            Object.entries(values).map(([key, value]) => {
                if (Array.isArray(value)) {
                    value.map(value => formData.append(key, value));
                } else {
                    formData.append(key, value);
                }
            });

            formData.append("info.os", navigator.platform);
            formData.append("info.browser", getBrowser());
            formData.append("info.screenSize", getScreenSize());

            const { data } = await axiosService.post(
                `/api/jira-tasks/create`,
                formData,
                {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                },
            );
            currentAfterSave();
            return data;
        },
        [currentAfterSave, axiosService],
        crudSuccess("Task", "key", "C"),
    );
};

export const usePostVoteResource = ({ afterSuccess }) => {
    const { axiosService } = useDic();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async ({ issueKey, customerName }) => {
            const { data } = await axiosService.post(
                `/api/jira-tasks/${issueKey}/vote`,
                {
                    customerName,
                },
            );
            currentAfterSuccess();
        },
        [currentAfterSuccess, axiosService],
        crudSuccess("Request", null, "U"),
    );
};
