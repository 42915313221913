import { useRoute } from "@/modules/router/index";
import { useContext, useMemo } from "react";
import { getResource, RouteDataContext } from "../RouteDataContextProvider";

export const useRouteISVConnection = () => {
    const { route } = useRoute();
    const context = useContext(RouteDataContext);

    const isvConnectionLoadable = useMemo(() => {
        return getResource(
            "connection",
            "isvConnectionId",
            route.params?.isvConnectionId,
            context.resources,
        );
    }, [context.resources, route.params?.isvConnectionId]);

    return {
        isvConnectionLoadable,
    };
};
