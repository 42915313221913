import { Select, Spin } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useParentState } from "@/components/hooks/useParentState.hook";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import * as React from "react";

const DEBOUNCE_TIMEOUT = 500;

const AdminUsersSelect = ({ options: currentOptions, ...props }) => {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = useParentState(currentOptions);
    const fetchRef = React.useRef(0);

    const { workflowService } = useDic();

    const fetchUserAdminList = async username =>
        workflowService
            .getFilteredAdminUserList(username)
            .then(({ data }) => data);

    const debounceFetcher = React.useMemo(() => {
        const loadOptions = filter => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchUserAdminList(filter).then(users => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptions(
                    users.map(user => ({
                        value: user.username,
                        label: user.username,
                    })),
                );
                setFetching(false);
            });
        };

        return debounce(loadOptions, DEBOUNCE_TIMEOUT);
    }, []);

    return (
        <Select
            suffixIcon={SearchOutlined}
            mode="multiple"
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
        />
    );
};

AdminUsersSelect.propTypes = {};

export default AdminUsersSelect;
