import { fieldTypes } from "@/components/DesignSystem/Table/constants";

export const columns = [
    {
        type: fieldTypes.TEXT,
        name: "name",
        label: "Name",
    },
    {
        type: fieldTypes.TEXT,
        name: "description",
        label: "Description",
    },
    {
        type: fieldTypes.TEXT,
        name: "projectName",
        label: "Account",
    },
    {
        type: fieldTypes.DATETIME,
        name: "createdBy",
        label: "Created by",
    },
    {
        type: fieldTypes.DATETIME,
        name: "createdAt",
        label: "Created at",
    },
];
