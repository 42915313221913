import React from "react";
import styles from "./AlertCellWithPopover.style.less";
import { Popover } from "@/components/DesignSystem";
import { ReactComponent as ExclamationCircle } from "@pricefx/unity-components/dist/es/icons/unicons/exclamation-circle.svg";
import { ReactComponent as ExclamationTriangleSolid } from "@pricefx/unity-components/dist/es/icons/unicons/exclamation-triangle-solid.svg";
import PropTypes from "prop-types";

export const AlertCellWithPopover = ({
    type = "error",
    popoverTitle,
    popoverContent,
    popoverVisible = !!popoverTitle || !!popoverContent,
    content = null,
    contentBefore = null,
}) => {
    const IconComponent =
        type === "error" ? ExclamationCircle : ExclamationTriangleSolid;

    return (
        <div className={styles.alertCellContainer}>
            {contentBefore}
            {popoverVisible && (
                <Popover
                    title={popoverTitle}
                    content={popoverContent}
                    placement="topLeft"
                    overlayStyle={{ width: "232px" }}
                >
                    <IconComponent
                        className={
                            type === "error"
                                ? styles.alertIconError
                                : styles.alertIconWarning
                        }
                    />
                </Popover>
            )}
            {content}
        </div>
    );
};

AlertCellWithPopover.propTypes = {
    type: PropTypes.string,
    popoverTitle: PropTypes.string.isRequired,
    popoverContent: PropTypes.node.isRequired,
    popoverVisible: PropTypes.bool,
    content: PropTypes.node,
    contentBefore: PropTypes.node,
};
