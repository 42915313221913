import { Forms } from "@/components/DesignSystem";
import { getIdsByDestination } from "@/components/Marketplace/hooks/useNavigateToPackages.hook";
import { deployToEnum } from "@/constants/deployment.constants";
import { hasError, hasValue } from "@/modules/loadable";
import { maybeError } from "@/modules/loadable/utils";
import { t } from "@/translations";
import { useCallback, useMemo } from "react";

const useDeploymentValidator = ({
    deploymentStateLoadable,
    destination,
    destinationId,
}) => {
    const { partitionId, instanceId } = getIdsByDestination(
        destination,
        destinationId,
    );

    return useCallback(() => {
        if (hasError(deploymentStateLoadable)) {
            return Forms.error(
                t("marketplace.error.cantFetchDeployability", {
                    error: maybeError(deploymentStateLoadable).response?.data
                        ?.message,
                }),
            );
        } else if (
            (!isNaN(partitionId) || !isNaN(instanceId)) &&
            hasValue(deploymentStateLoadable)
        ) {
            const deployStatus = deploymentStateLoadable.valueMaybe();
            const isDestinationInstance = destination === deployToEnum.INSTANCE;

            const canDeployOnDestination = isDestinationInstance
                ? deployStatus?.ableToDeployOnIntegrationManager
                : deployStatus?.ableToDeployOnPartition;

            const minimalVersion = isDestinationInstance
                ? deployStatus?.minimalIntegrationManagerVersion
                : deployStatus?.minimalVersion;

            if (canDeployOnDestination) {
                return Forms.success();
            } else if (!canDeployOnDestination) {
                if (minimalVersion) {
                    return Forms.error(
                        t("marketplace.template.detail.error.minimalVersion", {
                            version: minimalVersion,
                            target: destination,
                        }),
                    );
                } else {
                    return Forms.error(
                        t(
                            "marketplace.template.detail.error.forbiddenDeployment",
                            { type: destination },
                        ),
                    );
                }
            }
        } else {
            return Forms.success();
        }
    }, [deploymentStateLoadable, destination, instanceId, partitionId]);
};

export const useDeployabilityValidator = ({
    deploymentStateLoadable,
    destination,
    destinationId,
}) => {
    const deploymentValidator = useDeploymentValidator({
        deploymentStateLoadable,
        destination,
        destinationId,
    });

    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                deploymentValidator,
            ]),
        [deploymentValidator],
    );
};
