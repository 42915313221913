import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { noop } from "lodash";

export const tap = fn => arg => {
    fn(arg);
    return arg;
};

export const useConnectionsByTypeQuery = ({
    instanceId,
    connectionType,
    afterSuccess = noop,
    onError = noop,
}) => {
    const { connectionsService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const onErrorCurrent = useCurrentHandler(onError);

    return useQueryLoadable(
        async () =>
            connectionsService
                .fetchConnectionsAvailableOnTemplatesByType(
                    instanceId,
                    connectionType,
                )
                .then(getData)
                .then(tap(afterSuccessCurrent))
                .catch(error => {
                    onErrorCurrent(error);
                    throw error;
                }),

        [
            afterSuccessCurrent,
            connectionType,
            connectionsService,
            instanceId,
            onErrorCurrent,
        ],
    );
};
