import { useDic } from "@/components/Dic/useDic.hook";
import {
    crudSuccess,
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useStepContent = ({ templateName, stepName }) => {
    const { templatesReplacementService } = useDic();

    return useQueryLoadable(
        async () =>
            templateName && stepName
                ? templatesReplacementService
                      .getStepContent(templateName, stepName)
                      .then(getData)
                : pendingPromise(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [templateName, stepName],
    );
};

export const useStepContentReplacementMutation = ({
    templateName,
    stepName,
    afterSuccess,
}) => {
    const { templatesReplacementService } = useDic();

    return useMutationLoadableWithNotification(
        async values => {
            const { data } =
                await templatesReplacementService.setStepContentReplacement(
                    templateName,
                    stepName,
                    values,
                );
            afterSuccess(data);
            return { ...values, ...data };
        },
        [afterSuccess, stepName, templateName, templatesReplacementService],
        crudSuccess("Step", "name", !stepName ? "C" : "U"),
    );
};
