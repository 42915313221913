import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import isNumber from "lodash/isNumber";
import React from "react";
import Moment from "react-moment";
import PartitionNameColumnRenderer from "../PartitionNameColumnRenderer/PartitionNameColumnRenderer";
import { Property } from "../Property/Property.component";
import { AccountLinkOrName } from "./AccountLinkOrName.component";

export const createColumns = ({
    hasRights,
    hasCreatedStatus,
    dashboardLocation,
}) => [
    {
        type: fieldTypes.TEXT,
        label: t("general.partition"),
        name: "serialNumber",
        render: (text, record) => {
            return (
                <>
                    <PartitionNameColumnRenderer
                        partitionId={record.partitionId}
                        hasRight={
                            hasCreatedStatus(record) &&
                            hasRights(record.customerId, record.partitionId)
                        }
                        projectId={record.customerId}
                        value={record.serialNumber}
                    />
                </>
            );
        },
    },
    {
        visibleInPanel: true,
        panelOrder: 0,
        type: fieldTypes.TEXT,
        label: t("general.account-name"),
        name: "projectName",
        exportName: "customerName",
        render: (text, record) => (
            <>
                {isNumber(record.customerId) && (
                    <AccountLinkOrName
                        record={record}
                        dashboardLocation={dashboardLocation}
                    />
                )}
            </>
        ),
    },
    {
        visibleInPanel: true,
        panelOrder: 12,
        type: fieldTypes.TEXT,
        label: t("general.account-type"),
        name: "projectType",
        exportName: "customerType",
        render: (_, record) => record.customerType,
    },
    {
        visibleInPanel: true,
        panelOrder: 1,
        type: fieldTypes.TEXT,
        label: t("general.partition-label"),
        name: "name",
    },
    {
        visible: false,
        type: fieldTypes.TEXT,
        label: t("general.uuid"),
        name: "uuid",
    },
    {
        type: fieldTypes.TEXT,
        label: t("general.release-name"),
        name: "releaseName",
    },
    {
        visibleInPanel: true,
        panelOrder: 5,
        type: fieldTypes.TEXT,
        label: t("general.major-version"),
        name: "majorVersion",
        visible: false,
    },
    {
        type: fieldTypes.DATE,
        label: t("partitions-list.header.last-login"),
        name: "lastLogin",
        render: (text, record) =>
            record.lastLogin && (
                <Moment format="DD/MM/YYYY">{record.lastLogin}</Moment>
            ),
    },
    {
        visibleInPanel: true,
        panelOrder: 3,
        visible: false,
        type: fieldTypes.DATE,
        label: t("general.install-date"),
        name: "installDate",
        render: (text, record) =>
            record.installDate && (
                <Moment format="DD/MM/YYYY">{record.installDate}</Moment>
            ),
    },
    {
        visibleInPanel: true,
        panelOrder: 4,
        visible: false,
        type: fieldTypes.TEXT,
        label: t("general.cluster"),
        name: "clusterName",
        panelRenderValue: (text, record) => (
            <Property.Value href={record.baseUrl}>{text}</Property.Value>
        ),
    },
    {
        visibleInPanel: true,
        panelOrder: 6,
        visible: false,
        type: fieldTypes.TEXT,
        label: t("general.modules"),
        name: "modules",
    },
    {
        visibleInPanel: true,
        panelOrder: 7,
        visible: false,
        type: fieldTypes.TEXT,
        label: t("general.contact-emails"),
        name: "contactEmails",
    },
    {
        visibleInPanel: true,
        panelOrder: 8,
        visible: false,
        type: fieldTypes.DATETIME,
        label: t("general.last-changed"),
        name: "updatedAt",
        canFilter: false,
        canSort: true,
        render: (text, record) => (
            <FormattedDateTime fromNow>{record.updatedAt}</FormattedDateTime>
        ),
    },
    {
        visibleInPanel: true,
        panelOrder: 9,
        panelProps: { hideEmpty: true },
        visible: false,
        type: fieldTypes.TEXT,
        label: t("general.host"),
        name: "customSchema.host",
        canFilter: false,
        canSort: false,
        render: (_, record) => record.customSchema?.host,
    },
    {
        visibleInPanel: true,
        panelOrder: 10,
        panelProps: { hideEmpty: true },
        visible: false,
        type: fieldTypes.TEXT,
        label: t("general.database"),
        name: "customSchema.database",
        canFilter: false,
        canSort: false,
        render: (_, record) => record.customSchema?.database,
    },
    {
        visible: false,
        visibleInPanel: true,
        panelOrder: 12,
        type: fieldTypes.TEXT,
        label: t("general.checkin-date"),
        name: "checkinDate",
        render: (text, record) => (
            <FormattedDateTime fromNow>{record.checkinDate}</FormattedDateTime>
        ),
        canFilter: false,
    },
    {
        visible: false,
        type: fieldTypes.NUMBER,
        label: t("general.admin-licences"),
        name: "maxAdminUsers",
    },
    // Temporary hidden, O.Spican request
    // {
    //     type: fieldTypes.BOOLEAN,
    //     label: t("partitions-list.header.marked-to-delete"),
    //     name: "unused"
    // }
];
