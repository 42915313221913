import AccountsList from "@/components/Accounts/AccountsList";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import React from "react";

const AccountsPage = () => {
    useFullWidthTableLayout();
    return <AccountsList />;
};

export default AccountsPage;
