import { FormulaTab } from "@/components/CalculatedFieldTable/FormulaTab/FormulaTab.component";
import { Gap } from "@/components/DesignSystem/Gap";
import { Tabs } from "@/components/DesignSystem/Tabs/Tabs";
import { t } from "@/translations";
import { isEmpty } from "lodash";
import concat from "lodash/fp/concat";
import fromPairs from "lodash/fp/fromPairs";
import groupBy from "lodash/fp/groupBy";
import keyBy from "lodash/fp/keyBy";
import map from "lodash/fp/map";
import pick from "lodash/fp/pick";
import pipe from "lodash/fp/pipe";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { useTableExampleDataToOptions } from "../Mappers/MapperTableWithCustomization/hooks/useTableExampleDataToOptions.hook";
import {
    convertToNumbersForFormula,
    getHeadersOfStringType,
    toLetters,
} from "../Packages/PackageTableDefinitionPanel/components/PackageMandatoryFields/PackageMandatoryFields.utils";
import {
    InputType,
    ParsedType,
} from "../Packages/PackageTableDefinitionPanel/constants";
import { ComposedTab } from "./ComposedTab/ComposedTab.component";
import { ExpertTab } from "./ExpertTab/ExpertTab.component";
import { FieldTab } from "./FieldTab/FieldTab.component";
import {
    calculate,
    calculateScope,
    extractFormulaSymbols,
    renameFormulaSymbols,
} from "./FormulaTab/FormulaTab.utils";
import { GroovyTab } from "./GroovyTab/GroovyTab.component";

const { TabPane } = Tabs;

export const TABS_VALUE_TYPES = {
    FIELD: "FIELD",
    EXPERT_OPTION: "EXPERT_OPTION",
    FORMULA: "FORMULA",
    COMPOSED: "COMPOSED",
    GROOVY: "GROOVY",
};

const defaultHideTabs = [];

export const useVisibleTabs = ({
    numberFields,
    stringFields,
    inputOptions,
    initialValues,
    hideTabs = defaultHideTabs,
}) => {
    const visibleTabs = useMemo(() => {
        if (!initialValues) return [];

        const outputType = initialValues.outputType || ParsedType.STRING;
        return [
            !isEmpty(inputOptions) && TABS_VALUE_TYPES.FIELD,
            !isEmpty(inputOptions) && TABS_VALUE_TYPES.EXPERT_OPTION,
            !isEmpty(numberFields) &&
                (outputType === ParsedType.NUMBER ||
                    outputType === ParsedType.INTEGER) &&
                TABS_VALUE_TYPES.FORMULA,
            !isEmpty(stringFields) &&
                outputType === ParsedType.STRING &&
                TABS_VALUE_TYPES.COMPOSED,
            TABS_VALUE_TYPES.GROOVY,
        ]
            .filter(Boolean)
            .filter(tab => !hideTabs.includes(tab));
    }, [numberFields, stringFields, inputOptions, initialValues, hideTabs]);
    return visibleTabs;
};

// prettier-ignore
const TABS_BY_INPUT_TYPE = {
    [InputType.BODY]:     TABS_VALUE_TYPES.FIELD,
    [InputType.FORMULA]:  TABS_VALUE_TYPES.FORMULA,
    [InputType.COMPOSED]: TABS_VALUE_TYPES.COMPOSED,
    [InputType.GROOVY]:   TABS_VALUE_TYPES.GROOVY,
    [InputType.HEADER]:   TABS_VALUE_TYPES.EXPERT_OPTION,
    [InputType.PROPERTY]: TABS_VALUE_TYPES.EXPERT_OPTION,
    [InputType.SIMPLE]:   TABS_VALUE_TYPES.EXPERT_OPTION,
    [InputType.CONSTANT]: TABS_VALUE_TYPES.EXPERT_OPTION,
};

const formulaDerivedState = ({ input, formulaMapping }) => {
    const scope = calculateScope(formulaMapping);
    const [result, error] = calculate(input, scope);
    return { input, result, error, formulaMapping };
};

const FORMULA_ERRORS = {
    UNKNOWN_SOURCE_FIELD: "UNKNOWN_SOURCE_FIELD",
    MISSING_FIELD: "MISSING_FIELD",
};

const humanizeError = ({ error, key, sourceField }) => {
    switch (error) {
        case FORMULA_ERRORS.UNKNOWN_SOURCE_FIELD:
            return `${key} not found in source fields`;
        case FORMULA_ERRORS.MISSING_FIELD:
            return `"${sourceField}" denoted by "${key}" not found in inputs`;
        default:
            return `Unknown error occured during ${key} (${sourceField}) processing`;
    }
};

export const conformToMapping = ({
    input,
    formulaMapping,
    previousFormulaMapping,
}) => {
    if (!input || !previousFormulaMapping)
        return {
            input,
            error: "",
            formulaMapping,
            missingMapping: [],
            previousMapping: undefined,
        };

    const usedSymbols = extractFormulaSymbols(input);
    const oldSymbolsMap = keyBy("key", previousFormulaMapping);
    const newSymbolsByFieldMap = keyBy("sourceField", formulaMapping);

    // TODO: old to new symbols map (more transparent generation of unique new keys for errors)
    let nextKeyIndex = formulaMapping.length;

    const { errors = [], changes = [] } = pipe(
        map(oldKey => {
            if (!oldKey)
                throw new Error("Bad implementation, missing formula symbol");

            const sourceField = oldSymbolsMap[oldKey]?.sourceField;

            if (!sourceField)
                throw new Error(`Unknown field for formula symbol ${oldKey}`);
            // should not happen, invalid api state
            // return {
            //     error: FORMULA_ERRORS.UNKNOWN_SOURCE_FIELD,
            //     key: toLetters(nextKeyIndex++),
            //     oldKey
            // };
            if (newSymbolsByFieldMap[sourceField])
                return {
                    error: null,
                    key: newSymbolsByFieldMap[sourceField].key,
                    oldKey,
                };
            return {
                error: FORMULA_ERRORS.MISSING_FIELD,
                key: toLetters(nextKeyIndex++),
                oldKey,
                sourceField,
            };
        }),
        groupBy(({ error, key, oldKey }) =>
            error ? "errors" : key !== oldKey ? "changes" : "unchanged",
        ),
    )(usedSymbols);

    const renameMap = pipe(
        concat(errors),
        map(({ key, oldKey }) => [oldKey, key]),
        fromPairs,
    )(changes);

    try {
        const newFormula = renameFormulaSymbols(input, renameMap);

        if (errors.length) {
            return {
                input: newFormula,
                error: errors.map(humanizeError).join(". "),
                formulaMapping,
                missingMapping: pipe(
                    // filter(["error", FORMULA_ERRORS.MISSING_FIELD]),
                    map(({ key, sourceField }) => ({ key, sourceField })),
                )(errors),
                previousMapping: undefined,
            };
        }
        return {
            input: newFormula,
            error: "",
            formulaMapping,
            missingMapping: [],
            previousMapping: undefined,
        };
    } catch (e) {
        console.error("initFormula error:", e);
        return {
            input: input,
            error: e.message,
            formulaMapping,
            missingMapping: [],
            previousMapping: previousFormulaMapping,
        };
    }
};

export const initFormula = ({
    input,
    formulaMapping,
    previousFormulaMapping,
}) => {
    const conformed = conformToMapping({
        input,
        formulaMapping,
        previousFormulaMapping,
    });

    return {
        input: conformed.input,
        error: conformed.error,
        formulaMapping: conformed.formulaMapping,
        missingMapping: conformed.missingMapping,
        previousMapping: conformed.previousMapping,
    };
};

export const validateComposed = (input, stringFields) =>
    input
        ?.filter(fieldName => !stringFields?.includes(fieldName))
        ?.map(missing => `Missing field ${missing}`)
        ?.join(". ");

const initComposed = ({ input, stringFields }) => {
    const error = validateComposed(input, stringFields);
    return {
        error,
    };
};

const init = ({ visibleTabs, initialValues, numberFields, stringFields }) => {
    const tab =
        TABS_BY_INPUT_TYPE[initialValues?.inputType] || TABS_VALUE_TYPES.FIELD;

    if (!initialValues || !visibleTabs.includes(tab))
        return {
            activeTab: visibleTabs[0],
            stateByTabs: {},
        };

    const tabState = {
        ...pick(
            [
                "input",
                "inputType",
                "converterExpression",
                "converterExpressionReadOnly",
            ],
            initialValues,
        ),
        ...(initialValues.inputType === InputType.FORMULA
            ? initFormula({
                  input: initialValues.input,
                  previousFormulaMapping: initialValues.formulaMapping,
                  formulaMapping: numberFields,
              })
            : {}),
        ...(initialValues.inputType === InputType.COMPOSED
            ? initComposed({ input: initialValues.input, stringFields })
            : {}),
    };

    return {
        activeTab: tab,
        stateByTabs: {
            [tab]: tabState,
        },
    };
};

const reducer = (state, action) => {
    switch (action.type) {
        case "init":
            return init(action.payload);

        case "set_active_tab":
            return { ...state, activeTab: action.payload };

        case "set_tab_state": {
            const { tab } = action.payload;
            return {
                ...state,
                activeTab: tab,
                stateByTabs: {
                    ...state.stateByTabs,
                    [tab]: action.payload,
                },
            };
        }

        case "set_formula_tab_state": {
            const tab = TABS_VALUE_TYPES.FORMULA;
            const { input, formulaMapping } = action.payload;

            return {
                ...state,
                activeTab: tab,
                stateByTabs: {
                    ...state.stateByTabs,
                    [tab]: {
                        inputType: InputType.FORMULA,
                        ...formulaDerivedState({ input, formulaMapping }),
                    },
                },
            };
        }

        case "set_active_tab_converter_state": // todo
            return {
                ...state,
                stateByTabs: {
                    ...state.stateByTabs,
                    [state.activeTab]: {
                        ...state.stateByTabs[state.activeTab],
                        converterExpression: action.payload.converterExpression,
                        converterExpressionReadOnly:
                            action.payload.converterExpressionReadOnly,
                    },
                },
            };

        default:
            throw new Error("Unknown action type:" + action.type);
    }
};

export const useFieldCustomizationTabsState = ({
    initialValues,
    tableExampleData,
    treeInputOptions,
    hideTabs,
}) => {
    const inputOptions = useTableExampleDataToOptions(
        tableExampleData,
        treeInputOptions,
    );
    const numberFields = useMemo(
        () => convertToNumbersForFormula(tableExampleData),
        [tableExampleData],
    );
    const stringFields = useMemo(
        () => getHeadersOfStringType(tableExampleData),
        [tableExampleData],
    );

    const tableExampleDataMap = useMemo(() => {
        const map = new Map();

        (tableExampleData?.columns ?? []).forEach(row => {
            map.set(row.name, row);
        });

        return map;
    }, [tableExampleData]);

    const visibleTabs = useVisibleTabs({
        numberFields,
        stringFields,
        inputOptions,
        initialValues,
        hideTabs,
    });
    const [state, dispatch] = useReducer(
        reducer,
        { visibleTabs, initialValues, numberFields, stringFields },
        init,
    );
    useEffect(() => {
        if (initialValues)
            dispatch({
                type: "init",
                payload: {
                    visibleTabs,
                    initialValues,
                    numberFields,
                    stringFields,
                },
            });
    }, [visibleTabs, initialValues, numberFields, stringFields]);

    const handleChange = useCallback(
        ({
            input,
            inputType,
            tab,
            error,
            isValid = !error,
            converterState, // TODO
        }) => {
            dispatch({
                type: "set_tab_state",
                payload: {
                    input,
                    inputType,
                    tab,
                    isValid,
                    error,
                    converterExpression: converterState?.value,
                    converterExpressionReadOnly: converterState?.readOnly,
                },
            });
        },
        [],
    );

    const onFormulaChange = useCallback(({ input, formulaMapping }) => {
        dispatch({
            type: "set_formula_tab_state",
            payload: {
                input,
                formulaMapping,
            },
        });
    }, []);

    const setActiveTab = useCallback(activeTab => {
        dispatch({ type: "set_active_tab", payload: activeTab });
    }, []);

    const activeTabState = useMemo(
        () => state.stateByTabs?.[state.activeTab] ?? {},
        [state],
    );

    const setActiveTabConverterState = useCallback(
        converterState =>
            dispatch({
                type: "set_active_tab_converter_state",
                payload: {
                    converterExpression: converterState?.value,
                    converterExpressionReadOnly: converterState?.readOnly,
                },
            }),
        [],
    );

    return {
        activeTabState,
        setActiveTabConverterState,

        stateByTabs: state.stateByTabs,
        onFormulaChange,
        handleChange,
        activeTab: state.activeTab,
        setActiveTab,
        visibleTabs,
        inputOptions,
        numberFields,
        stringFields,
        tableExampleDataMap,
    };
};

export const FieldCustomizationTabsComponent = ({
    activeTabState,
    numberFields,
    stringFields,
    stateByTabs,
    inputOptions = [],
    handleChange,
    onFormulaChange,
    activeTab,
    setActiveTab,
    visibleTabs,
}) => {
    if (!visibleTabs.length) return null;
    if (activeTab && !visibleTabs.includes(activeTab)) {
        console.error(`State mismatch, active tab is not visible`, {
            activeTab,
            visibleTabs,
            numberFields,
            stringFields,
            stateByTabs,
        });
        activeTab = undefined;
    }

    return (
        <Tabs activeKey={activeTab} onChange={setActiveTab} fullSize={false}>
            {visibleTabs.includes(TABS_VALUE_TYPES.FIELD) && (
                <TabPane
                    tab={t("formula-editor.tab.converter")}
                    key={TABS_VALUE_TYPES.FIELD}
                >
                    <Gap size="small" />
                    <FieldTab
                        input={stateByTabs[TABS_VALUE_TYPES.FIELD]?.input}
                        inputOptions={inputOptions}
                        onChange={handleChange}
                        converterState={{
                            value: stateByTabs[TABS_VALUE_TYPES.FIELD]
                                ?.converterExpression,
                            readOnly:
                                stateByTabs[TABS_VALUE_TYPES.FIELD]
                                    ?.converterExpressionReadOnly,
                        }}
                    />
                </TabPane>
            )}
            {visibleTabs.includes(TABS_VALUE_TYPES.FORMULA) && (
                <TabPane
                    tab={t("formula-editor.tab.formula")}
                    key={TABS_VALUE_TYPES.FORMULA}
                >
                    <Gap size="small" />
                    <FormulaTab
                        activeTabState={activeTabState}
                        input={stateByTabs[TABS_VALUE_TYPES.FORMULA]?.input}
                        formula={stateByTabs[TABS_VALUE_TYPES.FORMULA]?.input}
                        formulaMapping={
                            stateByTabs[TABS_VALUE_TYPES.FORMULA]
                                ?.formulaMapping || numberFields
                        }
                        onChange={onFormulaChange}
                    />
                </TabPane>
            )}
            {visibleTabs.includes(TABS_VALUE_TYPES.COMPOSED) && (
                <TabPane
                    tab={t("formula-editor.tab.multiple-field")}
                    key={TABS_VALUE_TYPES.COMPOSED}
                >
                    <Gap size="small" />
                    <ComposedTab
                        input={stateByTabs[TABS_VALUE_TYPES.COMPOSED]?.input}
                        error={stateByTabs[TABS_VALUE_TYPES.COMPOSED]?.error}
                        data={stringFields}
                        onChange={handleChange}
                    />
                </TabPane>
            )}
            {visibleTabs.includes(TABS_VALUE_TYPES.GROOVY) && (
                <TabPane
                    tab={t("formula-editor.tab.groovy")}
                    key={TABS_VALUE_TYPES.GROOVY}
                >
                    <Gap size="small" />
                    <GroovyTab
                        input={stateByTabs[TABS_VALUE_TYPES.GROOVY]?.input}
                        onChange={handleChange}
                    />
                </TabPane>
            )}
            {visibleTabs.includes(TABS_VALUE_TYPES.EXPERT_OPTION) && (
                <TabPane
                    tab={t("formula-editor.tab.expert-options")}
                    key={TABS_VALUE_TYPES.EXPERT_OPTION}
                    data-test={`field-customization-tab-${TABS_VALUE_TYPES.EXPERT_OPTION}`}
                >
                    <Gap size="small" />
                    <ExpertTab
                        input={
                            stateByTabs[TABS_VALUE_TYPES.EXPERT_OPTION]?.input
                        }
                        inputType={
                            stateByTabs[TABS_VALUE_TYPES.EXPERT_OPTION]
                                ?.inputType
                        }
                        onChange={handleChange}
                    />
                </TabPane>
            )}
        </Tabs>
    );
};

FieldCustomizationTabsComponent.propTypes = {
    inputOptions: PropTypes.array,
    numberFields: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            sourceField: PropTypes.string,
            example: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    ),
    stringFields: PropTypes.arrayOf(PropTypes.string),
    handleChange: PropTypes.func.isRequired,
    onFormulaChange: PropTypes.func.isRequired,
    stateByTabs: PropTypes.object.isRequired,
    activeTab: PropTypes.oneOf(Object.values(TABS_VALUE_TYPES)),
    setActiveTab: PropTypes.func.isRequired,
    visibleTabs: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};
