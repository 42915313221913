import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import React from "react";

export const recordsColumns = [
    {
        label: "Time",
        name: "timestamp",
        exportName: "timestamp",
        render: (_, record) => (
            <FormattedDateTime>{record.timestamp}</FormattedDateTime>
        ),
        width: 180,
    },
    {
        label: "Integration",
        name: "unique",
        exportName: "key",
        type: fieldTypes.TEXT,
        width: 300,
        canSort: false,
    },
    {
        label: "Direction",
        name: "direction",
        exportName: "direction",
        type: fieldTypes.TEXT,
        canSort: false,
    },
    {
        label: "Source",
        name: "source",
        exportName: "direction=in?fileConsumed:fileProduced",
        condition: true,
        type: fieldTypes.TEXT,
        sortable: false,
        render: (text, record) =>
            record.direction === "in"
                ? record.fileConsumed
                : record.fileProduced,
        width: 200,
        canSort: false,
    },
    {
        label: "Object Type",
        name: "objectType",
        exportName: "objectType",
        type: fieldTypes.TEXT,
        canSort: false,
    },
    {
        label: "Object Id",
        name: "objectId",
        exportName: "objectId",
        type: fieldTypes.TEXT,
        canSort: false,
    },
    {
        label: "Successful",
        name: "successful",
        exportName: "successful",
        type: fieldTypes.INTEGER,
    },
    {
        label: "Failed",
        name: "failed",
        exportName: "failed",
        type: fieldTypes.INTEGER,
    },
];
