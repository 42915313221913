import { Forms } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import React from "react";

// TODO: remove constant?
const options = ["body", "groovy", "header", "property", "simple", "constant"];

const MapperInputType = ({ name, initialValue, disabled, onChange }) => {
    return (
        <Forms.Fields.Select
            name={name}
            initialValue={initialValue}
            showSearch
            allowClear={false}
            disabled={disabled}
            options={options.map(name => ({ label: name, value: name }))}
            onChange={onChange}
        />
    );
};

export default MapperInputType;

MapperInputType.propTypes = {
    name: PropTypes.string.isRequired,
    initialValue: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};
