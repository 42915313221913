import { axiosService } from "../axios";

export const convertersService = {
    fetchConverters(instanceId) {
        return axiosService.get(`/api/instances/${instanceId}/converters`);
    },

    isConvertersApiSupported(instanceId) {
        return axiosService.get(
            `/api/instances/${instanceId}/converters/supported`,
        );
    },

    testConverter(instanceId, data) {
        return axiosService.post(
            `/api/instances/${instanceId}/converters/test`,
            data,
        );
    },

    fetchDataLoadConverters() {
        return axiosService.get("/api/data-load/converters");
    },

    testDataLoadConverter(instanceId, data) {
        return axiosService.post("/api/data-load/converters/test", data);
    },

    testDataLoadMapper({ rowData, mapperItems, parserConfig }) {
        return axiosService.post("/api/data-load/mappers/test", {
            rowData,
            mapperItems,
            parserConfig,
        });
    },
};
