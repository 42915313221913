import { Alert, Button, Forms, Gap, Link } from "@/components/DesignSystem";
import { Text } from "@/components/DesignSystem/Text/Text";
import { useDic } from "@/components/Dic/useDic.hook";
import { useConnectionsByTypeQuery } from "@/components/Packages/PackageTableDefinitionPanel/components/IntegrationInit/loadables";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { useSetWarningAlert } from "@/components/PageLayout/useSetAlert.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { hasValue, useQueryLoadable } from "@/modules/loadable";
import { useMarketplaceAppParams } from "@/modules/router/hooks/useMarketplaceAppParams.hook";
import {
    CONNECTION_TYPE_PRICEFX_CLIENT,
    connectionsService,
} from "@/services/connections.service";
import { instancesServices } from "@/services/instance.service";
import { t } from "@/translations";
import { isSuccess } from "@pricefx/unity-components/dist/es/components/Forms/validation";
import { isEmpty } from "lodash";
import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Loading } from "../../../../Loading/Loading";
import { IntegrationInitForm } from "./IntegrationInitForm";
import { integrationInitService } from "./integrationInit.service";

const { success, error } = Forms;

const useTemplateNameValidationLoadable = ({
    instanceId,
    id,
    occurrence,
    label,
    templateUniqueName,
    integrationUniqueName,
}) => {
    const { loadable } = useQueryLoadable(async () => {
        if ((label?.trim().length ?? 0) < 3)
            return error("Name must be longer then 3 characters");

        const validation = await instancesServices.validateName(
            instanceId,
            id,
            templateUniqueName,
            integrationUniqueName,
        );

        if (occurrence === "many" && !validation.integrationNameIsUnique) {
            return error(
                t("integration-templates.init.integration-unique-name"),
            );
        } else if (occurrence !== "many" && !validation.templateNameIsUnique) {
            return error(t("integration-templates.init.template-occurrence"));
        } else if (!integrationUniqueName) {
            return error(t("integration-templates.init.template-empty"));
        } else {
            return success();
        }
    }, [
        instanceId,
        id,
        occurrence,
        label,
        templateUniqueName,
        integrationUniqueName,
    ]);
    return loadable;
};

const isValid = (context, validationLoadable) =>
    context.connectionId &&
    context.valid &&
    hasValue(validationLoadable) &&
    isSuccess(validationLoadable.contents);

const toIntegrationUniqueName = kebabCase;

export const IntegrationInit = ({
    globalState,
    step,
    apiResult,
    onNext,
    onBack,
}) => {
    const { accountId } = useMarketplaceAppParams();
    const {
        instanceId = globalState.instanceId,
        templateUniqueName = globalState.templateUniqueName,
        occurrence = globalState.occurrence ||
            globalState?.mappingTemplate?.occurrence,
    } = step;

    const {
        locationRouterService,
        accountAppLocations: { connectionNewLocation, connectionEditLocation },
    } = useDic();

    const [context, setContext] = useState({
        valid: false,
        loaded: false,
        connectionId: undefined,
        template: {},
        messages: [],
        loadingConnection: false,
    });

    const setHeaderWarningAlert = useSetWarningAlert();

    const newConnectionModal = useVisibility(false);

    const connectionsResource = useConnectionsByTypeQuery({
        instanceId,
        connectionType: CONNECTION_TYPE_PRICEFX_CLIENT,
        afterSuccess: connections => {
            if (isEmpty(connections)) {
                setHeaderWarningAlert(
                    <>
                        {t("integration-templates.init.no-connections", {
                            newConnection: (
                                <Link onClick={newConnectionModal.show}>
                                    {t(
                                        "integration-templates.init.no-connections.new-connection",
                                    )}
                                </Link>
                            ),
                        })}
                    </>,
                );
            }
        },
        onError: () => {
            setContext(context => ({
                ...context,
                messages: [
                    ...context.messages,
                    {
                        type: "error",
                        message: t(
                            "integration-templates.init.unable-fetch-connections",
                        ),
                    },
                ],
            }));
        },
    });

    useEffect(() => {
        integrationInitService
            .validateConfiguration({
                instanceId,
                stateId: apiResult.id,
            })
            .then(response => {
                setContext({
                    ...response,
                    template: {
                        ...response.template,
                        integrationUniqueName: toIntegrationUniqueName(
                            response.template?.label ?? "",
                        ),
                    },
                    loaded: true,
                });
            });
    }, [step]);

    function handleTemplateChange(label) {
        setContext({
            ...context,
            template: {
                ...context.template,
                label,
                templateUniqueName,
                integrationUniqueName: toIntegrationUniqueName(label),
            },
        });
    }

    const validationLoadable = useTemplateNameValidationLoadable({
        id: apiResult.id,
        instanceId,
        occurrence,
        label: context.template?.label,
        templateUniqueName: context.template?.templateUniqueName,
        integrationUniqueName: context.template?.integrationUniqueName,
    });

    const handleConnectionsChange = ({ value: connectionId }) => {
        setContext({
            ...context,
            loadingConnection: true,
        });

        const connection = (
            connectionsResource.loadable.valueMaybe() ?? []
        ).find(c => c.id === connectionId);

        connectionsService
            .testConnection(instanceId, connection)
            .then(() =>
                setContext({
                    ...context,
                    valid: true,
                    loadingConnection: false,
                    messages: context.messages.filter(
                        m => m.errorType !== "connectionTest",
                    ),
                    connectionId,
                }),
            )
            .catch(() =>
                setContext({
                    ...context,
                    connectionId,
                    valid: false,
                    loadingConnection: false,
                    messages: [
                        ...context.messages.filter(
                            m => m.errorType !== "connectionTest",
                        ),
                        {
                            type: "error",
                            message: (
                                <div>
                                    <Text>
                                        {t(
                                            "integration-templates.init.connection-check-failed",
                                        )}
                                    </Text>
                                    <Text>
                                        {t(
                                            "integration-templates.init.connection-check-settings",
                                        )}
                                        <Link
                                            onClick={() =>
                                                locationRouterService.navigate(
                                                    connectionEditLocation,
                                                    {
                                                        accountId,
                                                        instanceId,
                                                        connectionId,
                                                    },
                                                )
                                            }
                                        >
                                            {t(
                                                "integration-templates.init.available-connections-here",
                                            )}
                                        </Link>
                                    </Text>
                                </div>
                            ),
                            errorType: "connectionTest",
                        },
                    ],
                }),
            );
    };

    const handleOnNext = () =>
        onNext({
            connectionId: context.connectionId,
            label: context.template.label,
            templateUniqueName: context.template.templateUniqueName,
            integrationUniqueName: context.template.integrationUniqueName,
        });

    return (
        <Loading
            isLoading={!context.loaded}
            tip={t("integration-templates.init.tip")}
        >
            {context.messages.map(message => {
                return (
                    <Fragment key={message.message}>
                        <Alert
                            banner
                            showIcon
                            closable
                            message={message.message}
                            type={message.type}
                            style={{ maxWidth: "383px" }}
                        />
                        <br />
                    </Fragment>
                );
            })}
            {context.loaded && (
                <IntegrationInitForm
                    instanceId={instanceId}
                    defaultName={context.template ? context.template.label : ""}
                    onChange={handleTemplateChange}
                    handleConnectionsChange={handleConnectionsChange}
                    validationLoadable={validationLoadable}
                    connectionsResource={connectionsResource}
                    newConnectionModal={newConnectionModal}
                />
            )}
            <Gap size="large" />
            <Button
                loading={
                    context.loadingConnection ||
                    validationLoadable.state === "loading"
                }
                type="primary"
                disabled={!isValid(context, validationLoadable)}
                onClick={handleOnNext}
                label={t("package-data-upload.button.next")}
            />
            <BackButton onBack={onBack} />
        </Loading>
    );
};

IntegrationInit.propTypes = {
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    step: PropTypes.object.isRequired,
    globalState: PropTypes.object.isRequired,
    apiResult: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
};
