import { adminReportsService } from "@/components/AdminReports/services/adminReports.service";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { t } from "@/translations";
import { get, map } from "lodash/fp";

export const useAdminReportQuery = ({ reportId, canFetch }) => {
    return useQueryLoadable(
        async () =>
            !canFetch
                ? Promise.resolve(undefined)
                : adminReportsService.fetchAdminReport(reportId),
        [reportId, canFetch],
    );
};

export const usePostAdminReport = ({ afterSave }) => {
    const { axiosService } = useDic();
    const currentAfterSave = useCurrentHandler(afterSave);
    return useMutationLoadableWithNotification(
        async ({ report, id }) => {
            const { data } = id
                ? await axiosService.put(`/api/admin-reports/${id}`, report)
                : await axiosService.post(`/api/admin-reports`, report);

            currentAfterSave(data);
            return data;
        },
        [currentAfterSave, axiosService],
        ({ name }) => t("adminReports.save.success", { name }),
    );
};

export const useDeleteAdminReportMutation = ({ afterSuccess }) => {
    const { axiosService } = useDic();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async record => {
            const { data } = await axiosService.delete(
                `/api/admin-reports/${record.id}`,
            );
            currentAfterSuccess(data);
            return record;
        },
        [axiosService, currentAfterSuccess],
        ({ name }) => t("adminReports.delete.success", { name }),
    );
};

const createIndexOptions = index => ({ value: index, label: index });

export const useIndexOptionsQuery = ({ target, canFetch }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(async () => {
        if (canFetch) {
            return axiosService
                .get(`/api/admin-reports/indexes/${target}`)
                .then(get("data"))
                .then(map(createIndexOptions));
        } else {
            return Promise.resolve([]);
        }
    }, [axiosService, canFetch, target]);
};
