import { Modal, UnityLayout } from "@/components/DesignSystem";
import { PartitionConnectionForm } from "@/components/PartitionDataManagement/Connections/PartitionConnectionForm";
import { useCreateDownloadTargetMutation } from "@/components/PartitionDataManagement/loadables";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React from "react";

export const NewPartitionConnectionModal = ({ modal, afterSuccess }) => {
    const { accountId, partitionId } = useAccountAppParams();
    const mutation = useCreateDownloadTargetMutation({
        accountId,
        afterSuccess,
    });

    return (
        <Modal visible={modal.visible} onClose={modal.hide}>
            <UnityLayout>
                <UnityLayout.Header size={3} title={"New Connection"} />
                <PartitionConnectionForm
                    accountId={accountId}
                    partitionId={partitionId}
                    initialValues={undefined}
                    onSubmit={mutation.mutate}
                    onCancel={modal.hide}
                    isModalVariant
                />
            </UnityLayout>
        </Modal>
    );
};
