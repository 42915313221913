import { t } from "@/translations";
import React from "react";
import { PartitionUpgradesModalComponent } from "../../../../views/Settings/PartitionUpgrades/PartitionUpgradesModal.component";
import { AbstractConstantComponent } from "../Constants/AbstractConstant.component";

export function PartitionUpgradesPage() {
    const type = "partition-upgrade";

    const createNewButtonConfig = partitionUpgrades => {
        const disabled = partitionUpgrades && partitionUpgrades.length === 2;

        return {
            disabled: disabled,
            tooltip:
                disabled &&
                t("partition-upgrade.create-modal.disabled.tooltip"),
        };
    };

    return (
        <AbstractConstantComponent
            type={type}
            deleteProperty="eventName"
            modalComponent={PartitionUpgradesModalComponent}
            createNewButtonConfig={createNewButtonConfig}
        />
    );
}
