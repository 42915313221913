import reduce from "lodash/reduce";
import PropTypes from "prop-types";
import React from "react";
import DangerAlert from "../Upload/DangerAlert";

const PARTITION_CREDENTIALS_ARE_INCORRECT =
    "PARTITION_CREDENTIALS_ARE_INCORRECT";

const ConditionalAttributeErrorAlert = ({
    error,
    messageId,
    children,
    ...props
}) => {
    const input = error || {};

    if (input.error) {
        if (input.error === PARTITION_CREDENTIALS_ARE_INCORRECT) {
            return (
                <DangerAlert
                    {...props}
                    messageId={messageId}
                    message={input.message}
                />
            );
        }

        return (
            <>
                <DangerAlert
                    {...props}
                    messageId={messageId}
                    message={
                        input.error +
                        " - " +
                        (input.messages
                            ? reduce(input.messages, (r, m) => r + m + "\n", "")
                            : input.message)
                    }
                />
                {children}
            </>
        );
    }

    return "";
};

ConditionalAttributeErrorAlert.propTypes = {
    error: PropTypes.object,
    messageId: PropTypes.string,
    children: PropTypes.node,
};

export default ConditionalAttributeErrorAlert;
