import { useDic } from "@/components/Dic/useDic.hook";
import { RestartingRoutesModal } from "@/components/Integrations/components/RestartingRoutesModal/RestartingRoutes.modal";
import { UserAbortError } from "@/components/Mappers/ConflictModal";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useCustomModal } from "@/modules/modal";
import { getData } from "@/services/utils";
import { getErrorMessage } from "@/utils/state/error.utils";
import { noop, size } from "lodash";
import React from "react";

export const useWithRestartingRoutesModal = instanceId => {
    const customModal = useCustomModal();
    const { instanceService, messageService } = useDic();

    return useCurrentHandler(async (nextRequest, changedItems) => {
        const affectedRoutes = await instanceService
            .getDeployInfo(instanceId, changedItems)
            .then(getData)
            .then(response => response.affectedRoutes)
            .catch(noop);

        if (size(affectedRoutes) > 0) {
            return new Promise((resolve, reject) => {
                const onConfirm = async () =>
                    nextRequest()
                        .then(res => {
                            customModal.hide();
                            resolve(res);
                        })
                        .catch(e =>
                            messageService.error({
                                content: getErrorMessage(e.response.data),
                            }),
                        );
                const onCancel = () => {
                    customModal.hide();
                    reject(new UserAbortError("Aborted by the user"));
                };

                customModal.show(
                    <RestartingRoutesModal
                        visible
                        affectedRoutes={affectedRoutes}
                        onConfirm={onConfirm}
                        onCancel={onCancel}
                    />,
                );
            });
        } else {
            return nextRequest();
        }
    });
};
