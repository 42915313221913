import { Option, Select } from "@/components/DesignSystem/Select";
import { filtersService } from "@/services/filters.service";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

export const FilterSelect = ({ instanceId, ...props }) => {
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        filtersService.fetchFilters(instanceId).then(response => {
            setFilters(response.data);
        });
    }, [instanceId]);

    if (instanceId) {
        return (
            <Select {...props}>
                {filters.map(filter => (
                    <Option
                        title={filter.name}
                        key={filter.name}
                        value={filter.name}
                    >
                        {filter.name}
                    </Option>
                ))}
            </Select>
        );
    } else {
        return "Instance must be defined";
    }
};

FilterSelect.propTypes = {
    instanceId: PropTypes.number.isRequired,
};
