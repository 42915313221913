import { Gap, H4 } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { t } from "@/translations";
import PropTypes from "prop-types";
import qs from "qs";
import React, { useCallback, useReducer } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { useFetchPage } from "../PagableTable/useFetchPage.hook";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { CreateProductRequestModal } from "./CreateProductRequestModal";
import {
    DEFAULT_SORT_BY,
    PRODUCT_ENHANCEMENT_PAGE_SIZE_OPTIONS,
    useProductRequestAttachmentDownload,
} from "./ProductEnhancementOverview";
import { productEnhancementOverviewColumns } from "./ProductEnhancementOverview.columns";

export const MyRequests = () => {
    useFullWidthTableLayout();
    const requestModal = useVisibility();
    const [refetchToken, refetch] = useReducer(state => state + 1, 0);
    useBreadcrumbButton(
        {
            label: t("general.create-request"),
            onClick: requestModal.show,
        },
        [],
    );
    const { axiosService } = useDic();
    const [tableProps] = useFetchPage(
        (
            page,
            size = PRODUCT_ENHANCEMENT_PAGE_SIZE_OPTIONS[0],
            sort = DEFAULT_SORT_BY,
            filter,
        ) =>
            axiosService.post(
                `/api/jira-tasks/user-tasks?${qs.stringify({
                    page,
                    size,
                    sort,
                })}`,
                filter,
            ),
        [refetchToken],
    );
    const detailPanel = useDetailDrawerState();
    const afterTaskCreated = useCallback(() => {
        requestModal.hide();
        refetch();
    }, [refetch]);

    const onAttachmentDownload = useProductRequestAttachmentDownload();

    return (
        <>
            <TableWithPreferencesManagement
                fixed
                columns={productEnhancementOverviewColumns({
                    onShowDetail: detailPanel.show,
                    customers: tableProps?.page?.customers,
                    onAttachmentDownload,
                })}
                rowKey="key"
                datasetSlicing="server"
                preferencesType={
                    preferencesTypes.PRODUCT_ENHANCEMENT_MY_REQUESTS_TABLE
                }
                customPageSizeOptions={PRODUCT_ENHANCEMENT_PAGE_SIZE_OPTIONS}
                panelControl={detailPanel}
                renderPanelHeader={record => (
                    <>
                        <H4>{record.key}</H4>
                        <Gap />
                    </>
                )}
                {...tableProps}
                page={tableProps?.page?.page}
            />
            <CreateProductRequestModal
                visible={requestModal.visible}
                onClose={requestModal.hide}
                afterSave={afterTaskCreated}
            />
        </>
    );
};

MyRequests.propTypes = {
    defaultFilter: PropTypes.object,
};
