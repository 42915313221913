import { alertRulesServices } from "@/components/AlertRules/services/alertRules.service";
import { Forms } from "@/components/DesignSystem";
import { t } from "@/translations";
import { debounceAsync } from "@/utils/promises/promise.utils";
import { getErrorMessage } from "@/utils/state/error.utils";
import { isUrlValid } from "@/utils/validation";
import { useCallback, useMemo } from "react";

const { success, error } = Forms;

export const useAlertRuleExistsValidator = ({
    accountId,
    scope,
    alertRuleId,
}) =>
    useCallback(
        async name =>
            alertRulesServices
                .alertRuleExists({
                    id: alertRuleId,
                    name: name.trim(),
                    scope,
                    accountId,
                })
                .then(({ data: exists }) =>
                    exists
                        ? error(t("validation.alert-rule.exists", { name }))
                        : success(),
                )
                .catch(e => error(getErrorMessage(e))),
        [accountId, alertRuleId, scope],
    );

export const useAlertRuleNameValidator = ({
    accountId,
    scope,
    alertRuleId,
}) => {
    const existsValidator = useAlertRuleExistsValidator({
        accountId,
        scope,
        alertRuleId,
    });

    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                Forms.pmValidators.createMinLengthValidation(5),
                debounceAsync(500, existsValidator),
            ]),
        [existsValidator],
    );
};

export const useWebHookValidator = ({ emails }) =>
    useMemo(
        () =>
            Forms.validators.failOnFirst([
                value =>
                    !emails?.length && !value
                        ? error("Required when no recipient emails specified")
                        : success(),
                value =>
                    !value || isUrlValid(value)
                        ? success()
                        : error("Invalid url"),
            ]),
        [emails?.length],
    );
