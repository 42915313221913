import { Forms } from "@/components/DesignSystem";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { useUserBasicInfo } from "@/security/hooks/useUserBasicInfo.hook";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

const { useForm, Fields } = Forms;

const UserInfo = () => {
    useSetPageTitle(t("profile.title.general-user-information"));
    const basicInfo = useUserBasicInfo();
    const loggedUser = basicInfo.userInfo.user;

    const { Form, formId, handleSubmit, setValues, setTouched } = useForm({
        onSubmit: event => event.preventDefault(),
    });

    useSetValidatedInitialValues({
        initialValues: loggedUser,
        setValues,
        setTouched,
    });

    return (
        <>
            <Form formId={formId} onSubmit={handleSubmit}>
                <Fields.Input
                    disabled
                    name="username"
                    value={loggedUser.username}
                    label={t("profile.label.username")}
                />
                <Fields.Input
                    disabled
                    name="fullName"
                    value={loggedUser.fullName}
                    label={t("profile.label.full-name")}
                />
                <Fields.Input
                    disabled
                    name="email"
                    value={loggedUser.email}
                    label={t("profile.label.email")}
                />
                <Fields.Input
                    disabled
                    name="jobTitle"
                    value={loggedUser.jobTitle}
                    label={t("profile.label.job-title")}
                />
            </Form>
        </>
    );
};

UserInfo.propTypes = {
    userName: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    jobTitle: PropTypes.string,
};

export default UserInfo;
