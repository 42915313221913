import { useDic } from "@/components/Dic/useDic.hook";
import React, { useCallback } from "react";
import { Approval } from "./Approval";
import styles from "./styles.less";

export const ApprovalPage = () => {
    const {
        locationRouterService,
        locations: { defaultRouteLocation },
    } = useDic();

    const onLogoClick = useCallback(() => {
        locationRouterService.navigate(defaultRouteLocation);
    }, []);

    return (
        <div className={styles.pageContainer}>
            <Approval onToPlatformManager={onLogoClick} />
        </div>
    );
};
