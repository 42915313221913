import {
    useDeleteInstanceRepoCertificateMutation,
    useInstanceRepoCertificatesQuery,
    useLocalCopyMutation,
} from "@/components/Certificates/loadables";
import { useCertificateNameValidator } from "@/components/Certificates/validators";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT } from "@/components/DesignSystem/Table/constants";
import { CreateLocalCopyModal } from "@/components/Mappers/CreateLocalCopyModal";
import { isLoading } from "@/modules/loadable";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { getInstanceRepoCertificatesListTrackName as getTrackName } from "../../mixpanel/buttonNames";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { instanceRepoCertificatesColumns as columns } from "./InstanceRepoCertificates.columns";

const preferencesType = preferencesTypes.INSTANCE_REPO_CERTIFICATES_LIST_TABLE;
const type = "Certificate";
const FIELD_NAME = "name";

export const InstanceRepoCertificates = ({
    instanceId,
    visible,
    afterLocalCopy,
    afterDelete,
    reloadToken,
}) => {
    const certificatesQuery = useInstanceRepoCertificatesQuery({
        instanceId,
        reloadToken,
    });
    const deleteCertificateMutation = useDeleteInstanceRepoCertificateMutation({
        instanceId,
        afterSuccess: afterDelete,
    });

    const dataSource = certificatesQuery.loadable.valueMaybe() || [];
    const loading =
        isLoading(certificatesQuery) || isLoading(deleteCertificateMutation);

    const nameValidator = useCertificateNameValidator({
        instanceId,
        withOnline: false,
    });

    const actionMenu = useCallback(
        record => (
            <ActionButton
                permission={INTEGRATION_EDIT_PERMISSIONS}
                record={record}
                items={[
                    {
                        visible: record.editable,
                        title: t("mapper-list.create-local-copy.title"),
                        customModal: (
                            <CreateLocalCopyModal
                                instanceId={instanceId}
                                record={record}
                                afterSuccess={afterLocalCopy}
                                fieldName={FIELD_NAME}
                                entityName={type}
                                useLocalCopyMutation={useLocalCopyMutation}
                                nameValidator={nameValidator}
                            />
                        ),
                        track: { name: getTrackName("LocalCopy") },
                    },
                    {
                        title: t("general.modal.delete.title", { type }),
                        confirm: {
                            message: t("general.modal.delete.message", {
                                name: record.name,
                                type,
                            }),
                            onConfirm: deleteCertificateMutation.mutate,
                        },
                        color: "red",
                        track: { name: getTrackName("Delete") },
                    },
                ]}
            />
        ),
        [
            afterLocalCopy,
            deleteCertificateMutation.mutate,
            instanceId,
            nameValidator,
        ],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            fixed
            restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
            rowKey={FIELD_NAME}
            datasetSlicing="local"
            preferencesType={preferencesType}
            visible={visible}
            defaultSort={{
                sortSpecifiers: [
                    { property: "updatedAt", direction: "descending" },
                ],
            }}
        />
    );
};

InstanceRepoCertificates.propTypes = {
    instanceId: PropTypes.number.isRequired,
    afterLocalCopy: PropTypes.func,
    afterDelete: PropTypes.func,
    reloadToken: PropTypes.any,
    reloadAll: PropTypes.func,
    visible: PropTypes.bool,
};
