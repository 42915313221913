import { useDic } from "@/components/Dic/useDic.hook";
import { useQueryLoadable } from "@/modules/loadable";

export const useTrackerTypesQuery = ({ accountId, partitionId }) => {
    const { projectPartitionApiService } = useDic();

    return useQueryLoadable(
        async () =>
            projectPartitionApiService.getTrackerTypes(accountId, partitionId),
        [accountId, partitionId, projectPartitionApiService],
    );
};
