import { defaultQsOptions } from "@/services/constants";
import qs from "qs";
import { withWorkflowFeedback } from "../components/WorkFlow/workflowNotification";

export const createPartitionRequestService = ({
    axiosService,
    messageService,
}) => ({
    fetchClusters(projectId, usage, queryParams = {}) {
        const queryStr = qs.stringify(queryParams, defaultQsOptions);

        return axiosService.get(
            `/api/partition-request/${projectId}/clusters/${usage}${queryStr}`,
        );
    },

    fetchContacts(projectId) {
        return axiosService.get(`/api/partition-request/${projectId}/contacts`);
    },

    fetchUsages(projectId) {
        return axiosService.get(`/api/partition-request/${projectId}/usages`);
    },

    submitRequest(partition, customModal, trackWorkflow) {
        return withWorkflowFeedback(
            { messageService, customModal, trackWorkflow },
            () =>
                axiosService.post(
                    `/api/partition-request/${partition.accountId}/request`,
                    partition,
                ),
        );
    },

    modifyPartition({ accountId, partitionId, payload }) {
        return axiosService.post(
            `/api/projects/${accountId}/partition-assets/${partitionId}`,
            payload,
        );
    },

    submitCloneRequest({ accountId, partitionId, body }) {
        return axiosService.post(
            `/api/projects/${accountId}/partition-assets/${partitionId}/clone`,
            body,
        );
    },

    submitMoveRequest({ accountId, partitionId, body }) {
        return axiosService.post(
            `/api/projects/${accountId}/partition-assets/${partitionId}/move`,
            body,
        );
    },
});
