import { Button } from "@/components/DesignSystem";
import { t } from "@/translations";
import { Pagination } from "antd";
import PropTypes from "prop-types";
import React from "react";
import styles from "./MarketplaceFooter.style.less";
import { ReactComponent as IconUp } from "./icons/angle-ub-b.svg";

export const SCROLL_ELEMENT_ID = "marketplace-start";
export const MarketplaceFooter = ({
    current,
    total = 0,
    pageSize,
    onPageChange,
}) => {
    const scrollTop = () => {
        const element = document.getElementById(SCROLL_ELEMENT_ID);

        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        }
    };
    return (
        <div className={styles.wrapper}>
            <div>
                <Button
                    label={t("marketplace.footer.button.top")}
                    icon={IconUp}
                    onClick={scrollTop}
                />
                <div className={styles.counter}>
                    {total}&nbsp;{t("marketplace.footer.items")}
                </div>
            </div>
            <Pagination
                pageSize={pageSize}
                onChange={page => {
                    onPageChange?.(page);
                    scrollTop();
                }}
                defaultCurrent={0}
                showLessItems
                current={current}
                total={total}
                showSizeChanger={false}
            />
        </div>
    );
};

MarketplaceFooter.propTypes = {
    current: PropTypes.number,
    total: PropTypes.number,
    pageSize: PropTypes.number,
    onPageChange: PropTypes.func,
};
