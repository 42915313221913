import { tap } from "@/components/Connections/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { withWorkflowFeedback } from "@/components/WorkFlow/workflowNotification";
import { TrackingContext } from "@/mixpanel/TrackingContextProvider";
import {
    hasValue,
    pendingPromise,
    useMutationLoadable,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { useCustomModal } from "@/modules/modal";
import { getData } from "@/services/utils";
import get from "lodash/fp/get";
import map from "lodash/fp/map";
import pipe from "lodash/fp/pipe";
import sortBy from "lodash/fp/sortBy";
import uniqBy from "lodash/fp/uniqBy";
import { useContext } from "react";
import { SSOCertificateAccountService } from "./SSOCertificateAccount.service";
import { SSOCertificateAdminService } from "./SSOCertificateAdmin.service";

// PROJECT ADMIN
export const useGetPfxIdentification = ({ projectId, canFetch }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(async () => {
        if (!canFetch) return pendingPromise();

        const { data } = await axiosService.get(
            `/api/projects/${projectId}/saml-management/saml-info`,
        );

        return data;
    }, [projectId, canFetch, axiosService]);
};
export const usePostPfxIdentification = ({ projectId }) => {
    const { axiosService } = useDic();
    return useMutationLoadable(async () => {
        const { data } = await axiosService.post(
            `/api/projects/${projectId}/saml-management/saml-info`,
        );

        return data;
    }, [projectId, axiosService]);
};

export const useGetProjectSamlMgmt = ({ projectId, canFetch }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(async () => {
        if (!canFetch) return pendingPromise();

        const response = await axiosService
            .get(`/api/projects/${projectId}/saml-management`)
            .then(getData)
            .then(({ samlReplyingPartyDto, ...rest }) => ({
                ...rest,
                samlReplyingPartyDto: {
                    ...samlReplyingPartyDto,
                    expireNotificationRecipients:
                        samlReplyingPartyDto.expireNotificationRecipients
                            ? samlReplyingPartyDto.expireNotificationRecipients.split(
                                  ",",
                              )
                            : [],
                },
            }));

        return response;
    }, [projectId, canFetch, axiosService]);
};

export const useGetPfxCertificates = () => {
    const { axiosService } = useDic();
    return useQueryLoadable(async () => {
        const { data } = await axiosService.get(`/api/saml/certificates`);

        return data;
    }, [axiosService]);
};

export const useGetProjectEmailOptions = ({ projectId }) => {
    const { adminApiService } = useDic();
    return useQueryLoadable(
        async () =>
            adminApiService.fetchProjectUsers(projectId).then(
                pipe(
                    get("data"),
                    uniqBy("email"),
                    map(({ email }) => ({ label: email, value: email })),
                ),
            ),
        [projectId, adminApiService],
    );
};

export const useGetProjectSamlCerts = ({ projectId, canFetch }) => {
    return useQueryLoadable(async () => {
        if (!canFetch) return pendingPromise();

        const { data } = await SSOCertificateAccountService.listCertificates({
            projectId,
        });
        return data;
    }, [projectId, canFetch]);
};

export const useEditDomainsSamlMgmt = ({ projectId, afterSuccess }) => {
    const { axiosService, messageService } = useDic();
    const customModal = useCustomModal();
    const { trackWorkflow } = useContext(TrackingContext);

    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadable(
        async ({ oldDomain, newDomain }) => {
            withWorkflowFeedback(
                { messageService, customModal, trackWorkflow },
                () => {
                    return axiosService.post(
                        `/api/projects/${projectId}/saml-management/domain`,
                        {
                            oldDomain,
                            newDomain,
                        },
                    );
                },
            )
                .then(getData)
                .then(tap(afterSuccessCurrent));
        },
        [
            messageService,
            customModal,
            trackWorkflow,
            axiosService,
            projectId,
            afterSuccessCurrent,
        ],
    );
};

export const usePostProjectSamlMgmt = ({ projectId, afterSuccess }) => {
    const { axiosService, messageService } = useDic();
    const customModal = useCustomModal();
    const { trackWorkflow } = useContext(TrackingContext);

    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadable(
        async values => {
            withWorkflowFeedback(
                { messageService, customModal, trackWorkflow },
                () => {
                    const serialized = {
                        ...values,
                        expireNotificationRecipients:
                            values.expireNotificationRecipients?.join(","),
                    };
                    return axiosService.post(
                        `/api/projects/${projectId}/saml-management`,
                        serialized,
                    );
                },
            )
                .then(getData)
                .then(tap(afterSuccessCurrent));
        },
        [
            messageService,
            customModal,
            trackWorkflow,
            axiosService,
            projectId,
            afterSuccessCurrent,
        ],
    );
};

// GENERAL ADMIN
export const useGetAllSamlAccounts = () => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/admin/saml-management/projects`)
                .then(
                    pipe(
                        get("data"),
                        sortBy([({ samlEnabled }) => !samlEnabled, "name"]),
                    ),
                ),
        [axiosService],
    );
};

export const useGetAdminPfxIdentification = ({ projectId, canFetch }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(async () => {
        if (!canFetch) return pendingPromise();

        const { data } = await axiosService.get(
            `/api/admin/saml-management/${projectId}/saml-info`,
        );

        return data;
    }, [projectId, canFetch, axiosService]);
};
export const usePostAdminPfxIdentification = ({ projectId }) => {
    const { axiosService } = useDic();
    return useMutationLoadable(async () => {
        const { data } = await axiosService.post(
            `/api/admin/saml-management/${projectId}/saml-info`,
        );

        return data;
    }, [projectId, axiosService]);
};

export const useGetAdminSamlMgmt = ({ projectId, canFetch }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            canFetch
                ? axiosService
                      .get(`/api/admin/saml-management/${projectId}`)
                      .then(get("data"))
                : pendingPromise(),
        [projectId, canFetch, axiosService],
    );
};

export const usePostAdminSamlMgmt = ({ projectId }) => {
    const { axiosService } = useDic();
    return useMutationLoadableWithNotification(
        async values => {
            const { data } = await axiosService.post(
                `/api/admin/saml-management/${projectId}`,
                values,
            );
            return data;
        },
        [projectId, axiosService],
    );
};

export const useGetAdminSamlCerts = ({ projectId, canFetch }) => {
    return useQueryLoadable(async () => {
        if (!canFetch) return pendingPromise();

        const { data } = await SSOCertificateAdminService.listCertificates({
            projectId,
        });
        return data;
    }, [projectId, canFetch]);
};

export const inMutationInitialState = ({ loadable }) =>
    hasValue(loadable) && loadable.valueMaybe() === undefined;

// export const usePostAdminSamlCerts = ({ projectId }) => {
//     const { axiosService } = useDic();
//     return useMutationLoadableWithNotification(
//         async values => {
//             const { data } = await axiosService.post(
//                 `/api/admin/saml-management/${projectId}/certificates`,
//                 values
//             );
//             return data;
//         },
//         [projectId]
//     );
// };

// export const useDeleteAdminSamlCert = ({ projectId }) => {
//     const { axiosService } = useDic();
//     return useMutationLoadableWithNotification(
//         async id => {
//             const { data } = await axiosService.delete(
//                 `/api/admin/saml-management/${projectId}/certificates/${id}`
//             );
//             return data;
//         },
//         [projectId]
//     );
// };
