import PropTypes from "prop-types";
import React from "react";
import { Loading } from "../../Loading/Loading";
import {
    ParsedType,
    ValueType,
} from "../../Packages/PackageTableDefinitionPanel/constants";
import { MapperGridForm } from "./MapperGridForm/MapperGridForm";

// TODO
// IMHO all {value, valueType} combinations can be replaced by {input, inputType}
// with one-way conversion during submission to conform accepted inputTypes
// groovy value-type dance could be removed
export const MultilevelMapperTable = ({
    mapper,
    inputOptions,
    outputOptions,
    readOnly,
    withConverterWarning,
    parserConfig,
    onSubmit,
    testMapperResource,
    testMapperDisabled,
}) => {
    return (
        <Loading isLoading={!mapper}>
            <MapperGridForm
                inputOptions={inputOptions}
                outputOptions={outputOptions}
                readOnly={readOnly}
                withConverterWarning={withConverterWarning}
                parserConfig={parserConfig}
                mapper={mapper}
                onSubmit={onSubmit}
                testMapperResource={testMapperResource}
                testMapperDisabled={testMapperDisabled}
            />
        </Loading>
    );
};

MultilevelMapperTable.propTypes = {
    mapper: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string.isRequired,
                PropTypes.number.isRequired,
            ]).isRequired,
            input: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
            // inputType: PropTypes.oneOf(inputTypeOptions),
            // {value, valueType} used for calculated fields - represents UI value not accepted by API (except cache?)
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
            valueType: PropTypes.oneOf(Object.values(ValueType)),
            output: PropTypes.string,
            outputType: PropTypes.oneOf([...Object.values(ParsedType), ""]),
        }).isRequired,
    ).isRequired,
    errors: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    parserConfig: PropTypes.object,
    withConverterWarning: PropTypes.bool,
    apiErrors: PropTypes.string, // ???
};
