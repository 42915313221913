import { axiosService } from "../axios";

export const capacitySchedulingService = {
    getAvailableCapacityTimes() {
        return axiosService.get(`/api/user-available-times`);
    },
    updateAvailableCapacityTimes(times) {
        return axiosService.post(`/api/user-available-times`, times);
    },
};
