import { Select } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";

export const options = [
    // { label: "Ownership", value: "package.admin" }, // not ready yet
    {
        label: t("marketplace.template-visibility.users-table.option.deploy"),
        value: "package.deploy",
    },
    {
        label: t("marketplace.template-visibility.users-table.option.view"),
        value: "package.view",
    },
];

export const createUsersPermissionsColumns = ({
    onRemove,
    getPermission,
    onPermissionChange,
}) => [
    {
        name: "username",
        label: t("marketplace.template-visibility.users-table.username"),
        type: fieldTypes.TEXT,
        render: (text, record) => (
            <>
                {text}
                <ActionButton
                    record={record}
                    items={[
                        {
                            title: t("general.delete"),
                            confirmMessage: t(
                                "general.do-you-really-want-to-delete-name",
                                { name: record.username },
                            ),
                            onConfirm: onRemove,
                            color: "red",
                        },
                    ]}
                />
            </>
        ),
    },
    {
        name: "email",
        label: t("marketplace.template-visibility.users-table.email"),
        type: fieldTypes.TEXT,
    },
    {
        name: "permissions",
        label: t("general.permissions"),
        canFilter: false,
        canSort: false,
        width: 122,
        render: (_, record) => (
            <Select
                size="small"
                value={getPermission(record)}
                onChange={permission => {
                    onPermissionChange(permission, record);
                }}
                options={options}
                allowClear={false}
            />
        ),
        // render: (_, record) => (
        //     <RadioButton
        //         buttonStyle="solid"
        //         size="small"
        //         value={getPermission(record)}
        //         onChange={({ target: { value: permission } }) => {
        //             onPermissionChange(permission, record);
        //         }}
        //         options={options}
        //     />
        // )
    },
];

export const addUsersColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("marketplace.template-visibility.users-table.username"),
        name: "username",
    },
    {
        type: fieldTypes.TEXT,
        label: t("marketplace.template-visibility.users-table.email"),
        name: "email",
    },
    {
        type: fieldTypes.TEXT,
        label: t("marketplace.template-visibility.users-table.job-title"),
        name: "jobTitle",
    },
];

export const createAccountsColumns = ({ onRemove }) => [
    {
        name: "name",
        label: t("marketplace.template-visibility.accounts-table.account"),
        type: fieldTypes.TEXT,
        render: (text, record) => (
            <>
                {text}
                {onRemove && (
                    <ActionButton
                        record={record}
                        items={[
                            {
                                title: t("general.delete"),
                                confirmMessage: t(
                                    "general.do-you-really-want-to-delete-name",
                                    { name: record.username },
                                ),
                                onConfirm: onRemove,
                                color: "red",
                            },
                        ]}
                    />
                )}
            </>
        ),
    },
    {
        name: "owner",
        label: t("marketplace.template-visibility.accounts-table.owner"),
        type: fieldTypes.TEXT,
    },
    {
        name: "type",
        label: t("marketplace.template-visibility.accounts-table.type"),
        type: fieldTypes.TEXT,
    },
];
