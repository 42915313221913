import { Card, Gap, Row } from "@/components/DesignSystem";
import { MessagesChart } from "@/components/Integrations/Charts/MessagesChart";
import { Controls } from "@/components/Integrations/Dashboard/Tabs/InstanceMessages/Controls";
import { useElkMessageStatisticsQuery } from "@/components/Integrations/Dashboard/Tabs/InstanceMessages/loadables";
import { MessageStat } from "@/components/Integrations/Dashboard/Tabs/InstanceMessages/MessageStat";
import { ProcessedFilesTable } from "@/components/Integrations/Dashboard/Tabs/InstanceMessages/ProcessedFilesTable";
import { useRefreshAction } from "@/components/PageLayout/useRefreshAction.hook";
import { INSTANCE_MESSAGES } from "@/constants/sessionState.constants";
import { LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import { createSessionState } from "@/utils/sessionState/sessionState";
import { Collapse } from "@pricefx/unity-components";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useReducer } from "react";
import styles from "./InstanceMessages.style.less";

const { useSessionState } = createSessionState(INSTANCE_MESSAGES);

export const InstanceMessages = ({ instanceId }) => {
    const [mustQuery, setMustQuery] = useSessionState("mustQuery", "");
    const [[from, to], setRange] = useSessionState("range", [
        moment().startOf("day"),
        moment().add(1, "d").startOf("day"),
    ]);

    const [reloadToken, reload] = useReducer(i => i + 1, 0);
    useRefreshAction(reload);

    const messagesStatisticsQuery = useElkMessageStatisticsQuery({
        instanceId,
        range: [from, to],
        reloadToken,
    });

    return (
        <>
            <Controls
                range={[from, to]}
                onRangeChange={setRange}
                mustQuery={mustQuery}
                onMustQueryChange={setMustQuery}
            />
            <Gap />
            <Collapse defaultActiveKey={"timeline"} padded={false}>
                <Collapse.Panel header="Timeline" key="timeline">
                    <Card spacing="medium">
                        <LoadableRenderer
                            loadable={messagesStatisticsQuery.loadable}
                            hasValue={messagesStatistics => (
                                <>
                                    <div className={styles.chart}>
                                        <MessagesChart
                                            from={from}
                                            to={to}
                                            data={messagesStatistics}
                                        />
                                    </div>
                                    <Row>
                                        <MessageStat
                                            label={t(
                                                "instance-messages.incoming",
                                            )}
                                            stat={messagesStatistics.incoming}
                                            color="#298000"
                                            onClick={() =>
                                                setMustQuery(
                                                    "level:INFO AND im-direction:in",
                                                )
                                            }
                                        />
                                        <MessageStat
                                            label={t(
                                                "instance-messages.outgoing",
                                            )}
                                            stat={messagesStatistics.outgoing}
                                            color="#0078A2"
                                            onClick={() =>
                                                setMustQuery(
                                                    "level:INFO AND im-direction:out",
                                                )
                                            }
                                        />
                                        <MessageStat
                                            label={t(
                                                "instance-messages.events",
                                            )}
                                            stat={messagesStatistics.events}
                                            color="#FAAD14"
                                            entityName="Events"
                                            onClick={() =>
                                                setMustQuery(
                                                    "level:INFO AND im-type:EVENT",
                                                )
                                            }
                                        />
                                        <MessageStat
                                            label={"Failed"}
                                            stat={messagesStatistics.failed}
                                            color="#E00031"
                                            onClick={() =>
                                                setMustQuery("level:ERROR")
                                            }
                                        />
                                    </Row>
                                </>
                            )}
                        />
                    </Card>
                </Collapse.Panel>
            </Collapse>
            <ProcessedFilesTable
                instanceId={instanceId}
                from={from}
                to={to}
                mustQuery={mustQuery}
                reloadToken={reloadToken}
            />
        </>
    );
};

InstanceMessages.propTypes = {
    instanceId: PropTypes.number,
};
