import { useEffect, useRef } from "react";

export const useCurrentRef = fn => {
    const ref = useRef(fn);
    useEffect(() => {
        ref.current = fn;
    }, [fn]);

    return ref;
};
