import {
    ButtonMenu,
    Gap,
    message,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { UploadErrorInfo } from "./UploadErrorInfo";

export const StepErrorModal = ({
    stepLabel,
    messages,
    log,
    errorData,
    onTryLater,
    onStepRestart,
    partitionLog,
}) => {
    const modalControls = useDetailDrawerState(true);

    const restartStep = async () => {
        onStepRestart()
            .then(() => {
                message.success(
                    t("package-failed-option.message-step-restarted", {
                        stepLabel,
                    }),
                );
                modalControls.hide();
            })
            .catch(() =>
                message.error(
                    t("package-failed-option.message-step-restarted-fail", {
                        stepLabel,
                    }),
                ),
            );
    };

    return (
        <Modal
            visible={!!modalControls.record}
            onClose={modalControls.hide}
            width={950}
        >
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("package-failed-option.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    {t("package-failed-option.failed-message", {
                        stepMessage: stepLabel,
                    })}
                    <UploadErrorInfo
                        fileSuffix={stepLabel}
                        errorData={errorData}
                        log={log}
                        partitionLog={partitionLog}
                        messages={messages}
                        extraElement={
                            <>
                                <Gap />
                                <Text>
                                    {t("package-failed-option.header.message")}
                                </Text>
                                <Gap size="small" />
                            </>
                        }
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t(
                                        "package-failed-option.button.upload-again",
                                    ),
                                    onClick: restartStep,
                                    type: "primary",
                                },
                                {
                                    label: t(
                                        "package-failed-option.button.try-later",
                                    ),
                                    onClick: async () => {
                                        await onTryLater();
                                        modalControls.hide();
                                    },
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

StepErrorModal.propTypes = {
    stepLabel: PropTypes.node,
    messages: PropTypes.array,
    log: PropTypes.string,
    partitionLog: PropTypes.string,
    errorData: PropTypes.array,
    onTryLater: PropTypes.func.isRequired,
    onStepRestart: PropTypes.func.isRequired,
};
