export var endpointCategories = {
    bigdata: {
        label: "Big Data",
        endpoints: ["hdfs", "hbase", "lucene", "solr"],
        endpointIcon: "/img/icons/camel/endpointRepository24.png",
    },
    database: {
        label: "Database",
        endpoints: [
            "couchdb",
            "elasticsearch",
            "hbase",
            "jdbc",
            "jpa",
            "hibernate",
            "mongodb",
            "mybatis",
            "sql",
        ],
        endpointIcon: "/img/icons/camel/endpointRepository24.png",
    },
    cloud: {
        label: "Cloud",
        endpoints: [
            "aws-cw",
            "aws-ddb",
            "aws-sdb",
            "aws-ses",
            "aws-sns",
            "aws-sqs",
            "aws-s3",
            "gauth",
            "ghhtp",
            "glogin",
            "gtask",
            "jclouds",
        ],
    },
    core: {
        label: "Core",
        endpoints: ["bean", "direct", "seda"],
    },
    messaging: {
        label: "Messaging",
        endpoints: [
            "jms",
            "activemq",
            "amqp",
            "cometd",
            "cometds",
            "mqtt",
            "netty",
            "vertx",
            "websocket",
        ],
        endpointIcon: "/img/icons/camel/endpointQueue24.png",
    },
    mobile: {
        label: "Mobile",
        endpoints: ["apns"],
    },
    sass: {
        label: "SaaS",
        endpoints: ["salesforce", "sap-netweaver"],
    },
    social: {
        label: "Social",
        endpoints: [
            "atom",
            "facebook",
            "irc",
            "ircs",
            "rss",
            "smpp",
            "twitter",
            "weather",
        ],
    },
    storage: {
        label: "Storage",
        endpointIcon: "/img/icons/camel/endpointFolder24.png",
        endpoints: ["file", "ftp", "sftp", "scp", "jsch"],
    },
    template: {
        label: "Templating",
        endpoints: [
            "freemarker",
            "velocity",
            "xquery",
            "xslt",
            "scalate",
            "string-template",
        ],
    },
};

export const endpointConfigurations = {
    drools: {
        icon: "/img/icons/camel/endpointQueue24.png",
    },
    quartz: {
        icon: "/img/icons/camel/endpointTimer24.png",
    },
    facebook: {
        icon: "/img/icons/camel/endpoints/facebook24.jpg",
    },
    salesforce: {
        icon: "/img/icons/camel/endpoints/salesForce24.png",
    },
    sap: {
        icon: "/img/icons/camel/endpoints/SAPe24.png",
    },
    "sap-netweaver": {
        icon: "/img/icons/camel/endpoints/SAPNetweaver24.jpg",
    },
    timer: {
        icon: "/img/icons/camel/endpointTimer24.png",
    },
    twitter: {
        icon: "/img/icons/camel/endpoints/twitter24.png",
    },
    weather: {
        icon: "/img/icons/camel/endpoints/weather24.jpg",
    },
};

/**
 * Maps endpoint names to a category object
 * @property
 * @for Camel
 * @type {Object}
 */
export var endpointToCategory = {};

export const endpointService = {
    getEndpointCategory(endpointName) {
        return endpointToCategory[endpointName] || endpointCategories.core;
    },
};
