import { useCallback } from "react";
import { useContext, useEffect } from "react";
import { PageLayoutContext } from "./PageLayoutContext";
import { logger } from "@/modules/logger";

export const useSetMenuItems = ({ id }) => {
    const { setMenuItems } = useContext(PageLayoutContext);

    useEffect(() => () => setMenuItems({ id, items: [] }), [id, setMenuItems]);

    return {
        setMenuItems: useCallback(
            items => setMenuItems({ id, items }),
            [id, setMenuItems],
        ),
    };
};

export const useTableSettings = ({ id, items, visible = true }) => {
    const { setMenuItems: _setMenuItems } = useContext(PageLayoutContext);
    const setMenuItems = useCallback(
        data => {
            logger.debug({
                logGroupKey: ["useTableSettings", "setMenuItems"],
                color: "goldenrod",
                data,
            });
            _setMenuItems(data);
        },
        [_setMenuItems],
    );

    if (!id) throw new Error("Missing id for table menu items");

    useEffect(() => {
        setMenuItems({ id, items: visible ? items : undefined });
        return () => setMenuItems({ id, items: undefined });
    }, [id, items, setMenuItems, visible]);
};
