import { Forms, Hidden, UnityHeading } from "@/components/DesignSystem";
import { TimingFields } from "@/components/EventSchedulers/TimingFields";
import { MAPPER_DIRECTION } from "@/components/ISVMapping/steps/General.step";
import { t } from "@/translations";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import moment from "moment";
import React from "react";

const { Fields, pmValidators } = Forms;

export const SYNCHRONIZATION_TRIGGER = {
    IMMEDIATELY: "NONE",
    SCHEDULER: "SCHEDULER",
};

const SynchronizationStep = ({
    form,
    stepProps,
    stepFormProps,
    allStepsProps,
}) => {
    const { accountId } = allStepsProps;
    const { allValues } = stepFormProps;
    const { formId, getBag } = form;
    const { type: direction } = allValues;

    const schedulerType = useFieldValue({ formId, name: "schedulerType" });
    if (!direction) return null;

    const lastExecution = stepProps?.initialValues?.lastSync
        ? moment.utc(stepProps?.initialValues?.lastSync).local()
        : undefined;

    return (
        <>
            {direction === MAPPER_DIRECTION.UPLOAD ? (
                <>
                    <UnityHeading size={4}>
                        {t(
                            "isv-connections.data-mapping.step.synchronization.title",
                        )}
                    </UnityHeading>

                    <Fields.Radio
                        required
                        valudator={pmValidators.isRequired}
                        name="schedulerType"
                        label={t(
                            "isv-connections.data-mapping.step.synchronization.synchronization-trigger",
                        )}
                        initialValue={SYNCHRONIZATION_TRIGGER.IMMEDIATELY}
                        options={[
                            {
                                label: t(
                                    "isv-connections.data-mapping.step.synchronization.synchronization-trigger.immediately",
                                ),
                                value: SYNCHRONIZATION_TRIGGER.IMMEDIATELY,
                            },
                            {
                                label: t(
                                    "isv-connections.data-mapping.step.synchronization.synchronization-trigger.scheduler",
                                ),
                                value: SYNCHRONIZATION_TRIGGER.SCHEDULER,
                            },
                        ]}
                    />

                    {schedulerType === SYNCHRONIZATION_TRIGGER.SCHEDULER ? (
                        <>
                            <UnityHeading size={4}>
                                {t(
                                    "isv-connections.data-mapping.step.synchronization.title",
                                )}
                            </UnityHeading>
                            <TimingFields
                                accountId={accountId}
                                getBag={getBag}
                                lastExecution={lastExecution}
                            />
                        </>
                    ) : null}
                </>
            ) : (
                <>
                    <Hidden>
                        <Fields.Input
                            type="hidden"
                            name="schedulerType"
                            value={SYNCHRONIZATION_TRIGGER.SCHEDULER}
                        />
                    </Hidden>
                    <UnityHeading size={4}>
                        {t(
                            "isv-connections.data-mapping.step.synchronization.scheduler-only.title",
                        )}
                    </UnityHeading>
                    <TimingFields
                        accountId={accountId}
                        getBag={getBag}
                        lastExecution={lastExecution}
                    />
                </>
            )}
        </>
    );
};

export default SynchronizationStep;
