import { ClassForm } from "@/components/Classes/ClassForm.component";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";

export const EditClassPage = () => {
    const { instanceId, classId } = useAccountAppParams();
    useSetPageTitle(t("class-form.title.edit-class"));
    const {
        locationRouterService,
        accountAppLocations: { classesLocation },
    } = useDic();
    const onCancel = useCallback(
        () => locationRouterService.navigate(classesLocation),
        [classesLocation, locationRouterService],
    );

    return (
        <ClassForm
            instanceId={instanceId}
            classId={classId}
            onCancel={onCancel}
        />
    );
};
