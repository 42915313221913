import { calculateWindowIndexes } from "@/components/PageLayout/package-steps.utils";
import slice from "lodash/slice";
import { useCallback } from "react";
import { useHeaderSteps } from "./useHeaderSteps.hook";

const MAX_SHOWN_STEPS = 5;

export const usePackagesHeaderSteps = () => {
    const { headerSteps, setHeaderSteps: setHeaderStepsState } =
        useHeaderSteps();
    const setHeaderSteps = useCallback(
        state => {
            if (state.steps.length <= MAX_SHOWN_STEPS) {
                setHeaderStepsState({
                    current: state.current,
                    steps: state.steps,
                });
            } else {
                const indexes = calculateWindowIndexes(
                    state.current,
                    state.steps.length,
                );
                const slicedSteps = slice(
                    state.steps,
                    indexes.startIndex,
                    indexes.endIndex,
                );

                setHeaderStepsState({
                    current: indexes.index,
                    steps: slicedSteps,
                });
            }
        },
        [setHeaderStepsState],
    );

    return { headerSteps, setHeaderSteps };
};
