import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { AccountRightsDrawer } from "./AccountRightsDrawer";
import {
    useAccountGroupRightsResource,
    useAccountGroupWorkflowRightsResource,
    useSaveAccountRights,
} from "./loadables";
import { ENTITY_KIND } from "./PermissionsDrawer/sources.utils";

export const AccountGroupRightsDrawer = React.memo(
    function AccountGroupRightsDrawer({
        accountId,
        groupIds = [],
        onClose,
        visible = true,
    }) {
        const rightsResource = useAccountGroupRightsResource({
            accountId,
            groupIds,
            canFetch: visible && !!groupIds?.length,
        });
        const workflowRightsResource = useAccountGroupWorkflowRightsResource({
            accountId,
            groupIds,
            canFetch: visible && !!groupIds?.length,
        });
        const afterSave = useCallback(() => {
            rightsResource.reload();
            onClose();
        }, [onClose, rightsResource]);
        const saveRightsResource = useSaveAccountRights({
            accountId,
            afterSave,
            success: "group-permissions-panel.command.onSave.success",
        });

        return (
            <AccountRightsDrawer
                entityKind={ENTITY_KIND.GROUP}
                selectedEntities={groupIds}
                accountId={accountId}
                rightsResource={rightsResource}
                workflowRightsResource={workflowRightsResource}
                saveRightsResource={saveRightsResource}
                onClose={onClose}
                visible={visible}
            />
        );
    },
);

AccountGroupRightsDrawer.propTypes = {
    accountId: PropTypes.number.isRequired,
    groupIds: PropTypes.arrayOf(PropTypes.number.isRequired),
    onClose: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
};
