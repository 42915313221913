import find from "lodash/find";

export const syntaxHighlight = (json, highlights) => {
    if (!json) {
        return json;
    }

    if (typeof json !== "string") {
        json = JSON.stringify(json, undefined, 2);
    }
    let lastKey = null;
    json = json
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
    return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
        function (match) {
            let cls = "jsonNumber";
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    let key = match.substring(1, match.length - 2);
                    if (find(highlights, h => h === key)) {
                        cls = "jsonKeyHighlight";
                    } else {
                        cls = "jsonKey";
                    }
                    lastKey = key;
                } else {
                    cls = "jsonString";
                }
            } else if (/true|false/.test(match)) {
                cls = "jsonBoolean";
            } else if (/null/.test(match)) {
                cls = "jsonNull";
            }
            return '<span class="' + cls + '">' + match + "</span>";
        },
    );
};

export default {
    syntaxHighlight: syntaxHighlight,
};
