import {
    Button,
    Col,
    Forms,
    Gap,
    message,
    Row,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import DisableSubmitOnEnter from "@/components/FeatureFlags/components/DisableSubmitOnEnter.component";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import InstancePasswordModal from "@/components/Integrations/components/InstancePassword.modal";
import { IM_VERSION_WITH_ENCRYPTION_SUPPORT } from "@/components/Integrations/constants";
import { ElkTags } from "@/components/Integrations/ElkTags";
import { IMArchiveQuery } from "@/components/Integrations/IMArchiveQuery";
import { IMArchiveSettings } from "@/components/Integrations/IMArchiveSettings";
import { ImUpdate } from "@/components/Integrations/ImUpdate/ImUpdate.component";
import { ImUpdate as ImUpdateOld } from "@/components/Integrations/ImUpdate/ImUpdate.old.component";
import { useIMVersionComplyLoadable } from "@/components/Integrations/InstanceEntityWorkspaceAlert/InstanceEntityWorkspaceAlert";
import { useEnvironmentsResource } from "@/components/Integrations/NewProvisionedInstanceForm/loadables";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { HeaderTabs } from "@/components/PageLayout/components/HeaderTabs.component";
import PropertiesRuntimeList from "@/components/Properties/PropertiesRuntimeList";
import { InstanceServerType } from "@/constants/CommonConstats";
import {
    LoadableRenderer,
    STATUSES,
    useQueryLoadable,
    waitForValue,
} from "@/modules/loadable";
import { SecurityContext } from "@/security/authorization";
import { useFeatureFlag } from "@/security/hooks/useFeatureFlag.hook";
import { useHasIntegrationPermission } from "@/security/hooks/useHasIntegrationPermission.hook";
import {
    ACCOUNT_INTEGRATION_PROVISIONING_PERMISSIONS,
    hasPermission,
    INTEGRATION_ADMIN_PERMISSIONS,
    INTEGRATION_EDIT_PERMISSIONS,
    INTEGRATION_READ_EDIT_PERMISSIONS,
} from "@/security/permission.utils";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { isSameOrNewer } from "@/utils/versionUtils";
import { DATE_FORMAT } from "@pricefx/unity-components/dist/es/constants";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { ApplicationProperties } from "./ApplicationProperties/ApplicationProperties";
import {
    validateStringEmails,
    validateUrlAndDomain,
} from "./InstanceForm.validate";

const {
    SubmitButton,
    useForm,
    Form,
    Fields,
    validators,
    pmValidators,
    success,
    error,
} = Forms;

export const IM_VERSION_WITH_SUPPORT_OF_ELK_TAGS = "3.3.0-SNAPSHOT";

export const INSTANCE_TABS = {
    GENERAL: "general",
    RUNTIMEPROPERTIES: "runtimeProperties",
    APPLICATIONPROPERTIES: "applicationProperties",
    MANAGEMENT: "management",
    MONITORING: "monitoring",
    UPDATE: "update",
    TAGS: "tags",
    ARCHIVE_SETTINGS: "archiveSettings",
    ARCHIVE_QUERY: "archiveQuery",
};

export const isPastDate = dateValue => {
    return moment(dateValue, DATE_FORMAT.DATE).isBefore(
        moment().format(DATE_FORMAT.DATE),
    );
};

export const isValidExpirationDateValidator = value => {
    return isPastDate(value) ? error() : success();
};

export const isProvisioned = instance =>
    InstanceServerType.PROVISIONED === instance.serverType;

const EditInstanceForm = ({ instanceId, accountId }) => {
    const securityContext = useContext(SecurityContext);

    const {
        instanceService,
        locationRouterService,
        accountAppLocations: { instancesLocation },
    } = useDic();

    const { activeKey: activeTab } = HeaderTabs.useHeaderTabs();

    const integrationTypesResource = useEnvironmentsResource();

    const instanceQuery = useQueryLoadable(
        async () =>
            isNaN(instanceId)
                ? waitForValue()
                : instanceService.fetchInstance(instanceId).then(getData),
        [instanceId, instanceService],
    );
    const handleInstanceSubmit = ({ values }) => {
        const updateValues = {
            ...values,
            expiration: values.expiration
                ? values.expiration.format(DATE_FORMAT.DATE)
                : undefined,
        };

        instanceService
            .updateInstance(instanceId, updateValues)
            .then(() => {
                message.info("Instance has been saved successfully");
            })
            .catch(error => {
                message.error(getErrorMessage(error?.response?.data));
            });
    };

    const isInCloudInstance = useMemo(
        () => !!instanceQuery.loadable.valueMaybe()?.inCloud,
        [instanceQuery.loadable],
    );

    const isEncryptionSupported = useMemo(
        () =>
            isSameOrNewer(
                instanceQuery.loadable.valueMaybe()?.imVersion,
                IM_VERSION_WITH_ENCRYPTION_SUPPORT,
            ) && isInCloudInstance,
        [instanceQuery.loadable],
    );

    const canSeeApplicationProperties =
        useHasIntegrationPermission({
            accountId,
            instanceId,
            permissions: INTEGRATION_READ_EDIT_PERMISSIONS,
        }) && isInCloudInstance;

    const canSeeImUpgrade = useHasIntegrationPermission({
        accountId,
        instanceId,
        permissions: INTEGRATION_EDIT_PERMISSIONS,
    });
    const isAdmin = useHasIntegrationPermission({
        accountId,
        instanceId,
        permissions: INTEGRATION_ADMIN_PERMISSIONS,
    });
    const elkTagsVersionComplyLoadable = useIMVersionComplyLoadable({
        instanceQuery,
        minVersion: IM_VERSION_WITH_SUPPORT_OF_ELK_TAGS,
    });
    const initialValues = instanceQuery.loadable.valueMaybe();
    const canSeeElkTags =
        isAdmin &&
        elkTagsVersionComplyLoadable.valueMaybe()?.comply &&
        initialValues &&
        isProvisioned(initialValues);

    const { formId, handleSubmit, setValues, addFields, setInitialValues } =
        useForm({
            onSubmit: handleInstanceSubmit,
        });

    const updateInitialValues = useCallback(
        values => {
            if (instanceQuery.loadable.state === STATUSES.hasValue) {
                const initialValues = {
                    ...values,
                    expiration: values?.expiration
                        ? moment(values?.expiration, DATE_FORMAT.DATE)
                        : undefined,
                };

                addFields?.(Object.keys(initialValues));
                setInitialValues(initialValues);
                setValues(initialValues);
            }
        },
        [addFields, instanceQuery.loadable.state, setInitialValues, setValues],
    );

    useEffect(() => updateInitialValues(initialValues), [initialValues]);

    const isMonitorEnabled = Forms.useFieldValue({
        formId,
        name: "monitored",
    });

    const isMonitorReportEnabled = Forms.useFieldValue({
        formId,
        name: "reportMonitored",
    });

    const integrationTypesProps = getLoadableSelectProps(
        integrationTypesResource.loadable,
    );

    const instancePasswordModal = useVisibility(false);

    const hasPermissionToSetExpiration = hasPermission(
        securityContext.permissions,
        ACCOUNT_INTEGRATION_PROVISIONING_PERMISSIONS,
    );

    const newUpdateFeature = useFeatureFlag("im-upgrade-new");
    // const newUpdateFeature = true; // TODO, rm

    return (
        <LoadableRenderer
            loadable={instanceQuery.loadable}
            hasValue={instance => (
                <Form disableBanner formId={formId} onSubmit={handleSubmit}>
                    <HeaderTabs defaultActiveKey={INSTANCE_TABS.GENERAL}>
                        <HeaderTabs.TabPane
                            tab={t("instance-form.label.general")}
                            key={INSTANCE_TABS.GENERAL}
                        >
                            <Fields.Input
                                name="instanceName"
                                data-test="integration-name-input"
                                label={t(
                                    "instance-form.label.integration-name",
                                )}
                                placeholder={t(
                                    "instance-form.placeholder.integration-name",
                                )}
                                disabled={isProvisioned(instance)}
                                required
                                validator={validators.failOnFirst([
                                    pmValidators.isRequired,
                                    Forms.pmValidators.notBlank(),
                                    pmValidators.createMinLengthValidation(5),
                                ])}
                                preserveStateAfterUnmount
                            />

                            <Fields.Select
                                name="type"
                                data-test="integration-type-select"
                                label={t(
                                    "instance-form.label.integration-type",
                                )}
                                placeholder={t(
                                    "instance-form.placeholder.integration-type",
                                )}
                                required
                                validator={pmValidators.isRequired}
                                preserveStateAfterUnmount
                                disabled={isInCloudInstance}
                                {...integrationTypesProps}
                            />

                            <Fields.Input
                                name="server"
                                data-test="integration-server-input"
                                label={t("instance-form.label.instance-server")}
                                placeholder={t(
                                    "instance-form.placeholder.instance-server",
                                )}
                                disabled={isProvisioned(instance)}
                                required
                                validator={pmValidators.isRequired}
                                preserveStateAfterUnmount
                            />

                            <Fields.Input
                                name="partitionName"
                                data-test="integration-partition-name-input"
                                label={t("instance-form.label.partition-name")}
                                placeholder={t(
                                    "instance-form.placeholder.partition-name",
                                )}
                                disabled={isProvisioned(instance)}
                                preserveStateAfterUnmount
                            />

                            <Fields.DatePicker
                                name="expiration"
                                data-test="integration-expiration-date-picker"
                                label={t("instance-form.label.expiration-date")}
                                placeholder={t("placeholder.please-select")}
                                disabledDate={isPastDate}
                                disabled={!hasPermissionToSetExpiration}
                                validator={pmValidators.noValueOr(
                                    isValidExpirationDateValidator,
                                )}
                            />

                            <SubmitButton
                                type="primary"
                                label={t("general.save")}
                            />
                        </HeaderTabs.TabPane>
                        <HeaderTabs.TabPane
                            tab={t("instance-form.label.runtime-properties")}
                            key={INSTANCE_TABS.RUNTIMEPROPERTIES}
                        >
                            {activeTab === INSTANCE_TABS.RUNTIMEPROPERTIES && (
                                <Row>
                                    <Col sm={24}>
                                        <DisableSubmitOnEnter>
                                            <PropertiesRuntimeList
                                                encryptionSupported={
                                                    isEncryptionSupported
                                                }
                                                instanceId={instanceId}
                                                entityId={instanceId}
                                                entityType="INTEGRATION_MANAGER_INSTANCE"
                                            />
                                        </DisableSubmitOnEnter>
                                    </Col>
                                </Row>
                            )}
                        </HeaderTabs.TabPane>
                        {canSeeApplicationProperties && (
                            <HeaderTabs.TabPane
                                tab={t(
                                    "instance-form.label.application-properties",
                                )}
                                key={INSTANCE_TABS.APPLICATIONPROPERTIES}
                            >
                                <DisableSubmitOnEnter>
                                    <ApplicationProperties
                                        accountId={accountId}
                                        instanceId={instanceId}
                                        activeTab={activeTab}
                                        visible={
                                            activeTab ===
                                            INSTANCE_TABS.APPLICATIONPROPERTIES
                                        }
                                    />
                                </DisableSubmitOnEnter>
                            </HeaderTabs.TabPane>
                        )}
                        <HeaderTabs.TabPane
                            tab={t("instance-form.label.management")}
                            key={INSTANCE_TABS.MANAGEMENT}
                        >
                            <InstancePasswordModal
                                instanceId={instanceId}
                                isProvisionedInstance={isProvisioned(instance)}
                                visible={instancePasswordModal.visible}
                                onSave={instancePasswordModal.hide}
                                onCancel={instancePasswordModal.hide}
                            />

                            <Fields.Input
                                name="managementUsername"
                                data-test="instance-form-management-username"
                                label={t(
                                    "instance-form.label.management-username",
                                )}
                                disabled={isProvisioned(instance)}
                                required
                                validator={pmValidators.isRequired}
                                preserveStateAfterUnmount
                            />

                            <Fields.Input
                                name="managementUrl"
                                label={t("instance-form.label.management-url")}
                                disabled={isProvisioned(instance)}
                                validator={pmValidators.noValueOr(
                                    validateUrlAndDomain,
                                )}
                                preserveStateAfterUnmount
                            />

                            <div>
                                <Button
                                    disabled={isProvisioned(instance)}
                                    label={t("instance-password.modal.title")}
                                    type="link"
                                    onClick={instancePasswordModal.show}
                                />
                            </div>
                            <Gap />
                            <SubmitButton
                                type="primary"
                                label={t("general.save")}
                            />
                        </HeaderTabs.TabPane>
                        <HeaderTabs.TabPane
                            tab={t("instance-form.label.monitoring")}
                            key={INSTANCE_TABS.MONITORING}
                        >
                            <Fields.Switch
                                layout="vertical"
                                name="monitored"
                                label={t("instance-form.label.monitored")}
                                preserveStateAfterUnmount
                                inputWidth="max"
                            />

                            <Fields.Input
                                hidden={!isMonitorEnabled}
                                name="emails"
                                label={t("instance-form.label.email")}
                                required={isMonitorEnabled}
                                validator={pmValidators.noValueOrConditional(
                                    isMonitorEnabled,
                                    validators.failOnFirst([
                                        pmValidators.isRequired,
                                        validateStringEmails,
                                    ]),
                                )}
                                preserveStateAfterUnmount
                            />

                            <Fields.Switch
                                layout="vertical"
                                name="reportMonitored"
                                label={t(
                                    "instance-form.label.report-monitored",
                                )}
                                inputWidth="max"
                                preserveStateAfterUnmount
                            />
                            <Fields.Input
                                hidden={!isMonitorReportEnabled}
                                name="reportRecipients"
                                label={t("instance-form.label.email")}
                                required={isMonitorReportEnabled}
                                validator={pmValidators.noValueOrConditional(
                                    isMonitorReportEnabled,
                                    validators.failOnFirst([
                                        pmValidators.isRequired,
                                        validateStringEmails,
                                    ]),
                                )}
                                preserveStateAfterUnmount
                            />
                            <SubmitButton
                                label={t("general.save")}
                                type="primary"
                            />
                        </HeaderTabs.TabPane>
                        {isProvisioned(instance) && canSeeImUpgrade && (
                            <HeaderTabs.TabPane
                                tab={t("instance-form.label.update")}
                                key={INSTANCE_TABS.UPDATE}
                            >
                                {newUpdateFeature ? (
                                    <ImUpdate
                                        instanceQuery={instanceQuery}
                                        onUpdate={() => {
                                            locationRouterService.navigate(
                                                instancesLocation,
                                            );
                                        }}
                                    />
                                ) : (
                                    <ImUpdateOld
                                        instanceQuery={instanceQuery}
                                        onUpdate={() => {
                                            locationRouterService.navigate(
                                                instancesLocation,
                                            );
                                        }}
                                    />
                                )}
                            </HeaderTabs.TabPane>
                        )}
                        <HeaderTabs.TabPane
                            tab={t("instance-form.label.tags")}
                            key={INSTANCE_TABS.TAGS}
                            visible={canSeeElkTags}
                        >
                            <ElkTags instanceId={instanceId} />
                        </HeaderTabs.TabPane>
                        <HeaderTabs.TabPane
                            tab={t("instance-form.label.archive-settings")}
                            key={INSTANCE_TABS.ARCHIVE_SETTINGS}
                            visible={isAdmin && instance.backupSupported}
                        >
                            <IMArchiveSettings instanceId={instanceId} />
                        </HeaderTabs.TabPane>
                        <HeaderTabs.TabPane
                            tab={t("instance-form.label.archive-query")}
                            key={INSTANCE_TABS.ARCHIVE_QUERY}
                            visible={isAdmin && instance.backupSupported}
                        >
                            <IMArchiveQuery instanceId={instanceId} />
                        </HeaderTabs.TabPane>
                    </HeaderTabs>
                </Form>
            )}
        />
    );
};

EditInstanceForm.propTypes = {
    instanceId: PropTypes.number,
    accountId: PropTypes.number,
};

export default EditInstanceForm;
