import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import React from "react";
import NodesList from "./NodesList";

const NodesListPage = () => {
    useFullWidthTableLayout();
    return <NodesList />;
};

export default NodesListPage;
