import { AUTH_SERVICE_URL } from "@/components/Authentication/constants";

export const remoteLoginService = {
    async doRemoteLogin(url, partition, user) {
        const id = {
            partitionName: partition,
            baseUrl: url,
            user,
        };
        const encodedId = btoa(JSON.stringify(id));

        window.open(
            `${AUTH_SERVICE_URL}/pricefx-o365auth/${encodedId}/partition-redirect-login`,
        );
    },
};
