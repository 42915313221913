import { Col, Gap, Row } from "@/components/DesignSystem";
import { StepErrorModal } from "@/components/Packages/FailedOptionPanel/StepErrorModal";
import LayoutWithStepper from "@/components/Packages/LayoutWithStepper";
import PackageConfirmation from "@/components/Packages/PackageUploadPanel/components/PackageConfirmation/PackageConfirmation";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { TrackingContext } from "../../../../mixpanel/TrackingContextProvider";
import { getStepLabel } from "../../../../views/Package/package.utils";
import { PackageDefinitionComponent } from "../PackageDefinition/PackageDefinition.component";
import {
    inErrorState,
    inInitState,
    inPackageDeployedState,
    inStepDefinitionState,
    inStepFinishedState,
    inStepIsRunningState,
    inStepSkipDecision,
} from "./deployPackageState.enum";
import { PackageProgress } from "./PackageProgress/PackageProgress.component";

export function DeployPackage({
    accountId,
    packageName,
    partitionId,
    instanceId,
    $steps,
    currentStepIndex,
    $deploymentState,
    deployPackageState,
    progressInPercent,
    onStepFinished,
    onGoToNextStep,
    onPackageConfirmation,
    onPackageCanceled,
    onStepRestart,
    processGlobalState,
}) {
    const { trackStepEngine } = useContext(TrackingContext);
    const apiStep = $deploymentState.currentStep;
    useEffect(() => {
        const deps = { accountId, partitionId, instanceId };
        const attributes = {
            packageName,
            apiStep,
            deployPackageState,
        };
        if (
            (partitionId || instanceId) &&
            accountId &&
            Object.values(attributes).every(Boolean)
        ) {
            trackStepEngine(deps, {
                ...attributes,
                result: inPackageDeployedState(deployPackageState)
                    ? "success"
                    : inErrorState(deployPackageState)
                    ? "failed"
                    : undefined,
            });
        } else
            console.log("%cMissing mixpanel attributes", "color:brown;", {
                deps,
                attributes,
            });
    }, [
        packageName,
        partitionId,
        instanceId,
        accountId,
        apiStep,
        trackStepEngine,
        deployPackageState,
    ]);
    if (inErrorState(deployPackageState))
        return (
            <>
                <LayoutWithStepper
                    steps={$steps}
                    currentStepIndex={currentStepIndex}
                />
                <Gap size="huge" />
                <Row>
                    <Col xs={15}>
                        <PackageProgress
                            processStateId={$deploymentState.id}
                            $currentStep={$deploymentState.currentStepData}
                            deployPackageState={deployPackageState}
                            percent={progressInPercent}
                            onGoToNextStep={onGoToNextStep}
                            onStepFinished={onStepFinished}
                        />
                    </Col>
                </Row>
                <StepErrorModal
                    stepLabel={getStepLabel(
                        $deploymentState.currentStepData?.type,
                    )}
                    messages={$deploymentState.messages}
                    log={$deploymentState.log}
                    partitionLog={$deploymentState.partitionLog}
                    errorData={$deploymentState.errorData}
                    onTryLater={onPackageConfirmation}
                    onStepRestart={onStepRestart}
                />
            </>
        );

    if (
        inStepDefinitionState(deployPackageState) &&
        $deploymentState.currentStepData
    )
        return (
            <PackageDefinitionComponent
                accountId={accountId}
                partitionId={partitionId}
                instanceId={instanceId}
                packageName={packageName}
                apiResult={$deploymentState}
                onStepFinish={onStepFinished}
                onCancel={onPackageCanceled}
                deployPackageState={deployPackageState}
                steps={$steps}
                currentStepIndex={currentStepIndex}
            />
        );

    if (canDisplayProgress(deployPackageState))
        return (
            <>
                <LayoutWithStepper
                    steps={$steps}
                    currentStepIndex={currentStepIndex}
                />
                <Gap size="huge" />
                <Row>
                    <Col xs={15}>
                        <PackageProgress
                            processStateId={$deploymentState.id}
                            $currentStep={$deploymentState.currentStepData}
                            deployPackageState={deployPackageState}
                            percent={progressInPercent}
                            onGoToNextStep={onGoToNextStep}
                            onStepFinished={onStepFinished}
                        />
                    </Col>
                </Row>
            </>
        );

    if (inPackageDeployedState(deployPackageState))
        return (
            <>
                <LayoutWithStepper
                    steps={$steps}
                    currentStepIndex={currentStepIndex}
                />
                <Gap size="huge" />
                <PackageConfirmation
                    accountId={accountId}
                    instanceId={instanceId}
                    baseUrl={$deploymentState.baseUrl}
                    redirectUrl={processGlobalState?.redirectUrl}
                    onNext={() => onPackageConfirmation()}
                />
            </>
        );

    return null;
}

DeployPackage.propTypes = {
    $deploymentState: PropTypes.object,
    $steps: PropTypes.array.isRequired,
    accountId: PropTypes.number.isRequired,
    currentStepIndex: PropTypes.number.isRequired,
    deployPackageState: PropTypes.string,
    instanceId: PropTypes.number,
    onGoToNextStep: PropTypes.func.isRequired,
    onPackageConfirmation: PropTypes.func.isRequired,
    onPackageCanceled: PropTypes.func.isRequired,
    onStepFinished: PropTypes.func.isRequired,
    packageName: PropTypes.string,
    partitionId: PropTypes.number,
    progressInPercent: PropTypes.number,
    processGlobalState: PropTypes.object,
};

function canDisplayProgress(deployPackageState) {
    return (
        inInitState(deployPackageState) ||
        inStepIsRunningState(deployPackageState) ||
        inStepFinishedState(deployPackageState) ||
        inStepSkipDecision(deployPackageState)
    );
}
