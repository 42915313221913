import { useDic } from "@/components/Dic/useDic.hook";
import { useMutationLoadable, useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useClusterPartitions = ({ clusterName }) => {
    const { clusterService } = useDic();

    return useQueryLoadable(
        async () =>
            clusterService.fetchClusterPartitions(clusterName).then(getData),
        [clusterName, clusterService],
    );
};

export const useClusterEditability = ({ clusterName }) => {
    const { clusterService } = useDic();

    return useQueryLoadable(
        async () =>
            clusterService.checkClusterEditability(clusterName).then(getData),
        [clusterName, clusterService],
    );
};

export const useUpdateClusterConfigurationMutation = ({ clusterName }) => {
    const { clusterService } = useDic();

    return useMutationLoadable(
        async values =>
            clusterService
                .updateClusterConfiguration(clusterName, values)
                .then(getData),
        [clusterName, clusterService],
    );
};
