/**
 * Maps a given function (returning a promise) to every given key,
 * returns an object, where: key => resolved value
 * @param fn
 * @param keys
 * @returns {Promise<Object>}
 */
export function allToObject(fn, keys) {
    return Promise.all(
        keys.map(key => fn(key).then(value => [key, value])),
    ).then(Object.fromEntries);
}

export const debounceAsync = (ms, callback) => {
    let timeoutId = null;

    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);

        return new Promise(resolve => {
            timeoutId = setTimeout(() => resolve(callback(...args)), ms);
        });
    };
};
