import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    crudSuccess,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { t } from "@/translations";
import get from "lodash/fp/get";

export const useSsoUsernameResource = () => {
    const { axiosService } = useDic();
    return useQueryLoadable(async () => {
        return axiosService
            .get(`/api/user-sso`)
            .then(({ data }) => data)
            .catch(res => {
                throw get("response.data", res);
            });
    }, [axiosService]);
};

export const usePostSsoUsernameResource = ({ afterSave }) => {
    const { axiosService } = useDic();
    const currentAfterSave = useCurrentHandler(afterSave);
    return useMutationLoadableWithNotification(
        async ({ ssoLogin }) => {
            const res = await axiosService.post(`/api/user-sso`, {
                ssoLogin,
            });
            currentAfterSave();
            return res;
        },
        [axiosService, currentAfterSave],
        crudSuccess(t("profile.nav-item.sso-username")),
    );
};
