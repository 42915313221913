import { Card } from "@/components/DesignSystem";
import { getSizeWithLabelUnknownWrapper } from "@/utils/formats/bytes";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { chartSettings } from "./chartSettings";

const data = {
    datasets: [
        {
            ...chartSettings,
            data: [0, 0],
        },
    ],
};

const options = {
    maintainAspectRatio: false,
    tooltips: {
        callbacks: {
            label: (tooltipItem, data) =>
                data.labels[tooltipItem.index] +
                ": " +
                getSizeWithLabelUnknownWrapper(
                    data.datasets[0].data[tooltipItem.index],
                ),
        },
    },
};

const freeAsPercentage = (total, free) => {
    return ((free / total) * 100).toFixed(2);
};

const DiskSpace = ({ total, used, free }) => {
    data.labels = [];
    data.labels.push("Used Space (" + freeAsPercentage(total, used) + "%)");
    data.labels.push("Free Space (" + freeAsPercentage(total, free) + "%)");
    data.datasets[0].data = [used, free];

    return (
        <Card title="Disk Space" spacing="medium">
            <Doughnut data={data} options={options} width={200} height={200} />
        </Card>
    );
};

export default DiskSpace;
