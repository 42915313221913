// Need to use antd table because of missing row height option (need taller rows because of selects etc.)
import { mapUnityColumnsToAntd } from "@/components/DesignSystem/Table/features/mapper";
import { Table } from "antd";
import PropTypes from "prop-types";
import React from "react";

export const PackageTable = ({ fields, columns, handleRowClassName }) => {
    return (
        <Table
            rowKey="name"
            pagination={false}
            dataSource={fields}
            rowClassName={handleRowClassName}
            columns={mapUnityColumnsToAntd(columns)}
        />
    );
};

PackageTable.propTypes = {
    fields: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    handleRowClassName: PropTypes.func,
};
