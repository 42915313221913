import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT } from "@/components/DesignSystem/Table/constants";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { LoadableRenderer } from "@/modules/loadable";
import { useHasPermission } from "@/security/hooks/useHasPermission.hook";
import { t } from "@/translations";
import React, { useCallback, useMemo, useState } from "react";
import { preferencesTypes } from "../../../../constants/preferencesTypes.constants";
import { AssetRoleModal } from "../../../accountApp/components/AccountAssetAdminSettings/AssetRoleModal.component";
import { AssetRoleAssetsDrawer } from "./AssetRoleAssetsDrawer";
import { columns } from "./columns";
import {
    useAdminAssetRolesResource,
    useDeleteAdminAssetRoleResource,
    usePostAdminAssetRoleResource,
} from "./loadables";

const VIEWS = {
    MODAL: "MODAL",
    DRAWER: "DRAWER",
};

const EDIT_PERMISSION = "user_mgmt.asset_roles.edit";

const preferencesType = preferencesTypes.ADMIN_ASSET_ROLES_LIST_TABLE;

export const AssetRolesPage = () => {
    const [view, setView] = useState(null);
    const [selectedAssetRole, setSelectedAssetRole] = useState();
    const readOnly = !useHasPermission([EDIT_PERMISSION]);

    useFullWidthTableLayout();
    useHideBackButton();
    useBreadcrumbButton(
        {
            label: "account-admin-asset-role-list.button.create-asset-role",
            onClick: () => {
                setSelectedAssetRole(undefined);
                setView(VIEWS.MODAL);
            },
            permissions: [EDIT_PERMISSION],
        },
        [setSelectedAssetRole],
    );

    const assetRolesResource = useAdminAssetRolesResource();
    const postAssetRoleResource = usePostAdminAssetRoleResource(() => {
        assetRolesResource.reload();
        setView(null);
    });
    const deleteAssetRoleResource = useDeleteAdminAssetRoleResource(
        assetRolesResource.reload,
    );

    const onAssetRoleClick = useCallback(record => {
        setSelectedAssetRole(record);
        setView(VIEWS.DRAWER);
    }, []);
    const onAssetRoleEdit = useCallback(record => {
        setSelectedAssetRole(record);
        setView(VIEWS.MODAL);
    }, []);
    const resetView = useCallback(() => {
        setView(null);
    }, []);

    const actionMenu = useMemo(
        () => record =>
            (
                <ActionButton
                    record={record}
                    items={[
                        {
                            title: t("general.tooltip.edit"),
                            onClick: onAssetRoleEdit,
                            permission: [EDIT_PERMISSION],
                        },
                        {
                            title: t(
                                "account-admin-asset-role-list-delete-action.title",
                            ),
                            confirmMessage: t(
                                "account-admin-asset-role-list-delete-action.message",
                                {
                                    name: record.name,
                                },
                            ),
                            onConfirm: deleteAssetRoleResource.mutate,
                            permission: [EDIT_PERMISSION],
                            color: "red",
                        },
                    ]}
                />
            ),
        [onAssetRoleEdit, deleteAssetRoleResource.mutate],
    );

    return (
        <>
            <LoadableRenderer
                loadable={assetRolesResource.loadable}
                hasValue={dataSource => (
                    <TableWithPreferencesManagement
                        actionMenu={actionMenu}
                        columns={columns(onAssetRoleClick)}
                        dataSource={dataSource}
                        fixed
                        onChangeFilter={resetView}
                        onChangePaging={resetView}
                        restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
                        rowKey="id"
                        datasetSlicing="local"
                        preferencesType={preferencesType}
                    />
                )}
            />
            <AssetRoleAssetsDrawer
                visible={view === VIEWS.DRAWER}
                assetRole={selectedAssetRole}
                onSave={postAssetRoleResource.mutate}
                onClose={resetView}
                readOnly={readOnly}
            />
            <AssetRoleModal
                visible={view === VIEWS.MODAL}
                onCancel={resetView}
                onOk={postAssetRoleResource.mutate}
                role={selectedAssetRole}
                okText={selectedAssetRole ? t("general.save") : undefined}
                title={
                    selectedAssetRole
                        ? t("account-admin-asset-role-list.modal.title-edit")
                        : undefined
                }
            />
        </>
    );
};
