import Changed from "@/components/DateTime/Changed";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { ParentWarning } from "@/components/Mappers/ParentWarning";
import { t } from "@/translations";
import { getColumnOptions } from "@/utils/tableUtils";
import React from "react";
import { RouteStatus } from "../IntegrationRoutes/components/RouteStatus";

export const mappersListColumns = ({ mappers }) => [
    {
        type: fieldTypes.TEXT,
        label: t("mapper-list.header.name"),
        name: "name",
        className: "pmTable__column--withActionButton",
    },
    {
        type: fieldTypes.TEXT,
        label: t("general.parent"),
        name: "parent",
        render: (text, record) => (
            <ParentWarning parent={record.parent} synced={record.synced} />
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("mapper-list.header.type"),
        name: "type",
    },
    {
        type: fieldTypes.OPTION,
        options: mappers && getColumnOptions(mappers, "status"),
        label: t("mapper-list.header.status"),
        name: "status",
        render: (text, record) => (
            <>
                <RouteStatus status={record.status} />
            </>
        ),
    },
    {
        type: fieldTypes.TEXT,
        canFilter: false,
        label: t("mapper-list.header.changed"),
        name: "updatedAt",
        render: (text, record) => <Changed key={record.id} data={record} />,
    },
    {
        type: fieldTypes.TEXT,
        label: t("mapper-list.header.revision-number"),
        name: "revisionNumber",
    },
];
