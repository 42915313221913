import { useRouteAccount } from "@/mixpanel/hooks/useRouteAccount.hook";
import { hasValue, isLoading } from "@/modules/loadable";

export const useIntegrationArchitectureInfoVisibility = () => {
    const HIDDEN_FOR_ACC_TYPES = ["Admin", "System Integrator"];
    const { accountLoadable } = useRouteAccount();

    const visibility = {
        isLoading: isLoading(accountLoadable),
        isDisplayed:
            hasValue(accountLoadable) &&
            !HIDDEN_FOR_ACC_TYPES.includes(accountLoadable.valueMaybe()?.type),
    };

    return visibility;
};
