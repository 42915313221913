import { ButtonMenu, Modal, UnityLayout } from "@/components/DesignSystem";
import UploadErrorInfo from "@/components/Packages/FailedOptionPanel/UploadErrorInfo";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

export const ErrorLogModal = ({ modalProviderProps, record }) => {
    return (
        <Modal {...modalProviderProps} width={745}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("failed-deployments-errorlog-modal.title")}
                />
                <UnityLayout.Content padding={[true, true]}>
                    <UploadErrorInfo
                        fileSuffix={record.id}
                        log={record.log}
                        partitionLog={record.partitionLog}
                        messages={record.messages}
                        errorData={record.errorData}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.close"),
                                    onClick: modalProviderProps.onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
ErrorLogModal.propTypes = {
    record: PropTypes.object.isRequired,
    modalProviderProps: PropTypes.object,
};
