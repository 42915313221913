import { SubMenu } from "@/components/DesignSystem/Menu/SubMenu/SubMenu.component";
import { useDic } from "@/components/Dic/useDic.hook";
import { VisibleWhenHasPermission } from "@/security/authorization";
import { T } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

export function LocationSubMenu({
    $location,
    $locationParams = {},
    children,
    ...props
}) {
    const { locationRouterService } = useDic();

    const onTitleClick = useCallback(
        () => locationRouterService.navigate($location, $locationParams),
        [$location, $locationParams],
    );

    return (
        <VisibleWhenHasPermission
            permission={$location.permission}
            permissionCheckFunc={$location.permissionFunc}
        >
            <SubMenu
                data-test={$location.routeName}
                key={$location.routeName}
                title={<T id={`location.${$location.routeName}`} />}
                onTitleClick={onTitleClick}
                {...props}
            >
                {children}
            </SubMenu>
        </VisibleWhenHasPermission>
    );
}

LocationSubMenu.propTypes = {
    $location: PropTypes.object.isRequired,
    $locationParams: PropTypes.object,
    $locationProps: PropTypes.object,
    children: PropTypes.node,
};
