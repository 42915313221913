import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";

export const createPickerTableColumns = ({ onRemove }) => [
    {
        type: fieldTypes.TEXT,
        name: "emailAddress",
        label: "Username",
        render: (text, record) => (
            <>
                {text}
                {onRemove && (
                    <ActionButton
                        record={record}
                        items={[
                            {
                                title: t("group-detail.title.remove-user"),
                                confirmMessage: t(
                                    "group-detail.message.remove-user",
                                    {
                                        userName: record.displayName,
                                    },
                                ),
                                onConfirm: onRemove,
                                color: "red",
                            },
                        ]}
                    />
                )}
            </>
        ),
    },
    {
        type: fieldTypes.TEXT,
        name: "displayName",
        label: "Name",
    },
];
