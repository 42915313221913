export class OffersService {
    /**
     *
     * @type {OffersApiService}
     */
    offersApiService = null;

    constructor({ offersApiService }) {
        this.offersApiService = offersApiService;
    }

    /**
     *
     * @returns {Promise<OfferApiSchema[]>}
     */
    fetchOffers() {
        return this.offersApiService.fetchOffers();
    }

    /**
     * @param offerId {number}
     * @returns {Promise<OfferApiSchema>}
     */
    fetchOffer(offerId) {
        return this.offersApiService.fetchOffer(offerId);
    }

    /**
     *
     * @param offerId {number}
     * @param offer {OfferApiSchema}
     * @returns {Promise<OfferApiSchema>}
     */
    editOffer(offerId, offer) {
        return this.offersApiService.editOffer(offerId, offer);
    }

    /**
     *
     * @param offer {OfferApiSchema}
     * @returns {Promise<OfferApiSchema>}
     */
    createOffer(offer) {
        return this.offersApiService.createOffer(offer);
    }

    /**
     *
     * @param offerId {number}
     * @returns {Promise<void>}
     */
    deleteOffer(offerId) {
        return this.offersApiService.deleteOffer(offerId);
    }
}
