import { connect } from "react-redux";
import {
    cleanButtonData,
    setButtonData,
} from "../BreadcrumbButton/duck/actions";
import ReportsList from "./ReportsList";

export const ReportsListContainer = connect(null, {
    setButtonData,
    cleanButtonData,
})(ReportsList);
