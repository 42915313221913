import { Button, SwitchAsync } from "@/components/DesignSystem";
import { colors } from "@/components/DesignSystem/colors";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { downloadFile } from "@/utils/downloadUtils";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DownloadOutlined,
} from "@ant-design/icons";
import React from "react";
import Moment from "react-moment";
import { ReactComponent as CloudDeploy } from "../../../../../assets/icons/cloud-deploy.svg";

export const templateManagementDetailColumns = ({
    uniqueName,
    templateAdminService,
    hasPublishPermissions,
    hasDeployPermissions,
    onDeploy,
}) => [
    {
        name: "version",
        width: 200,
        label: t("templates-management-detail.header.version"),
    },
    {
        name: "createdAt",
        label: t("templates-management-detail.header.createdAt"),
        render: (text, record) =>
            record.createdAt && (
                <Moment format="DD/MM/YYYY">{record.createdAt}</Moment>
            ),
    },
    {
        name: "validationDate",
        label: t("templates-management-detail.header.validation-date"),
        render: (text, record) =>
            record.validationDate && (
                <Moment format="DD/MM/YYYY HH:mm">
                    {record.validationDate}
                </Moment>
            ),
    },
    {
        name: "published",
        label: t("templates-management-detail.header.published"),
        render: (enabled, row) => {
            return (
                <SwitchAsync
                    initialValue={enabled}
                    performCall={({ checked }) =>
                        templateAdminService
                            .changeTemplateVersionPublishing(
                                uniqueName,
                                row.version,
                                checked,
                            )
                            .then(getData)
                    }
                    // BE bug on Sales insights https://pricefx.atlassian.net/browse/PFIM-7340
                    // enabledResponseProp="published"
                    textOn={t("general.yes")}
                    textOff={t("general.no")}
                    disabled={!hasPublishPermissions}
                    data-test="published-switch"
                />
            );
        },
    },
    {
        name: "minimalIntegrationVersion",
        label: t(
            "templates-management-detail.header.minimal-integration-version",
        ),
    },
    {
        name: "minimalPartitionVersion",
        label: t(
            "templates-management-detail.header.minimal-partition-version",
        ),
    },
    {
        name: "isValid",
        label: t("templates-management-detail.header.is-valid"),
        render: (cell, row) => {
            return row.isValid ? (
                <CheckCircleOutlined
                    style={{ color: colors.success.primary }}
                />
            ) : (
                <>
                    <CloseCircleOutlined
                        style={{
                            color: colors.danger.primary,
                            marginRight: "8px",
                        }}
                    />
                    <DownloadOutlined
                        onClick={() =>
                            downloadFile(
                                row.validationResult,
                                row.version + "-validation.txt",
                            )
                        }
                    />
                </>
            );
        },
    },
    {
        name: "deploy",
        label: "",
        width: 40,
        render: (_, record) =>
            hasDeployPermissions ? (
                <Button
                    icon={CloudDeploy}
                    size="small"
                    tooltip="common_deploy"
                    onClick={() => onDeploy(record)}
                />
            ) : null,
    },
];
