import qs from "qs";

const prependQuestionMark = { addQueryPrefix: true };

export class AdminAssetRoleApiService {
    baseUrl = "/api/admin/assets";
    /**
     *
     * @type {HttpService}
     */
    httpService = null;

    constructor({ httpService }) {
        this.httpService = httpService;
    }

    fetchProjects(page, size, sort, filter, params) {
        return this.httpService.post(
            this.getUrl("projects", {
                page,
                size,
                sort,
            }),
            { filter, ...params },
        );
    }

    fetchPartitions(page, size, sort, filter, params) {
        return this.httpService.post(
            this.getUrl("partitions", {
                page,
                size,
                sort,
            }),
            { filter, ...params },
        );
    }

    fetchIntegrations(page, size, sort, filter, params) {
        return this.httpService.post(
            this.getUrl("integrations", {
                page,
                size,
                sort,
            }),
            { filter, ...params },
        );
    }

    getUrl(assetType, query) {
        return `${this.baseUrl}/${assetType}${
            query ? qs.stringify(query, prependQuestionMark) : ""
        }`;
    }
}
