import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_HEIGHT_WITH_BREADCRUMB } from "@/components/DesignSystem/Table/constants";
import { CopyFilterModal } from "@/components/Filters/CopyFilter";
import {
    useDeleteFilterMutation,
    useDeployFilterMutation,
    useFiltersQuery,
} from "@/components/Filters/loadables";
import { useInstanceQuery } from "@/components/IntegrationRoutes/loadables";
import { useWorkspaceInstanceDeletionAlert } from "@/components/Integrations/InstanceEntityWorkspaceAlert/InstanceEntityWorkspaceAlert";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { isLoading, LoadableRenderer } from "@/modules/loadable";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { getFiltersListTrackName as getTrackName } from "../../mixpanel/buttonNames";
import ConditionalErrorAlert from "../Error/ConditionalErrorAlert";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { filtersListColumns } from "./FiltersList.columns";

const preferencesType = preferencesTypes.FILTER_LIST_TABLE;

const initialDataSource = [];

export const FiltersList = ({
    instanceId,
    accountId,
    visible,
    onEdit,
    reloadToken,
    afterDeploy,
}) => {
    const instanceQuery = useInstanceQuery({ instanceId });
    useWorkspaceInstanceDeletionAlert({ instanceQuery, visible });
    const filtersQuery = useFiltersQuery({ instanceId, reloadToken });
    const deleteFilterMutation = useDeleteFilterMutation({
        afterSuccess: filtersQuery.reload,
    });
    const deployFilterMutation = useDeployFilterMutation({
        instanceId,
        afterSuccess: afterDeploy,
    });

    const dataSource = filtersQuery.loadable.valueMaybe() || initialDataSource;
    const loading =
        isLoading(filtersQuery) ||
        isLoading(deleteFilterMutation) ||
        isLoading(deployFilterMutation);
    const columns = useMemo(() => filtersListColumns(dataSource), [dataSource]);

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.tooltip.edit"),
                        onClick: onEdit,
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                        track: { name: getTrackName("Edit") },
                    },
                    {
                        title: t("filter-list.copy.title"),
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                        customModal: (
                            <CopyFilterModal
                                accountId={accountId}
                                filterId={record.id}
                                initialValues={{ name: record.name }}
                                afterSuccess={filtersQuery.reload}
                            />
                        ),
                        track: { name: getTrackName("Copy") },
                    },
                    {
                        title: t("general.deploy"),
                        onClick: deployFilterMutation.mutate,
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                        track: { name: getTrackName("Deploy") },
                    },
                    {
                        title: t("general.modal.delete.title", {
                            type: "filter",
                        }),
                        confirm: {
                            message: t("general.modal.delete.message", {
                                type: "filter",
                                name: record.name,
                            }),
                            onConfirm: deleteFilterMutation.mutate,
                        },
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                        color: "red",
                        track: { name: getTrackName("Delete") },
                    },
                ]}
            />
        ),
        [
            accountId,
            deleteFilterMutation.mutate,
            deployFilterMutation.mutate,
            filtersQuery.reload,
            onEdit,
        ],
    );

    return (
        <>
            <LoadableRenderer
                loadable={filtersQuery.loadable}
                hasValue={() => null}
                loading={() => null}
                hasError={e => (
                    <ConditionalErrorAlert error={e} size="medium" />
                )}
            />
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                dataSource={dataSource}
                fixed
                restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
                loading={loading}
                rowKey="id"
                columns={columns}
                datasetSlicing="local"
                preferencesType={preferencesType}
                visible={visible}
            />
        </>
    );
};

FiltersList.propTypes = {
    accountId: PropTypes.number.isRequired,
    instanceId: PropTypes.number.isRequired,
    onEdit: PropTypes.func.isRequired,
    reloadToken: PropTypes.any,
    afterDeploy: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};
