import { asClass, asValue } from "awilix";

import { accountAppLocationsMap } from "./accountApp.locations";
import { WorkflowDefinitionApiService } from "./api/workflow/WorkflowDefinitionApiService.class";
import { BusinessRoleApiMapper } from "./mappers/BusinessRoleApiMapper.class";
import { BusinessRolesApiMapper } from "./mappers/BusinessRolesApiMapper.class";
import { AssetRoleApiService } from "./services/assetRoleApiService.class";
import { BusinessRoleApiService } from "./services/businessRoleApiService.class";
import { DataUploadService } from "./services/DataUpload.service";
import { PermissionsApiService } from "./services/PermissionsApiService.class";
import { ProjectPartitionApiService } from "./services/projectPartitionApiService.class";
import { RightsApiService } from "./services/RightsApiService.class";
import { WorkflowDefinitionService } from "./services/workflow/WorkflowDefinitionService.class";

export function registerAccountApp({
    register,
    cradle: { locationRouterService },
}) {
    register({
        //api
        workflowDefinitionApiService: asClass(
            WorkflowDefinitionApiService,
        ).singleton(),

        //services
        workflowDefinitionService: asClass(
            WorkflowDefinitionService,
        ).singleton(),

        accountAppLocations: asValue(accountAppLocationsMap),

        businessRoleApiMapper: asClass(BusinessRoleApiMapper).singleton(),
        businessRolesApiMapper: asClass(BusinessRolesApiMapper).singleton(),

        assetRoleApiService: asClass(AssetRoleApiService).singleton(),
        businessRoleApiService: asClass(BusinessRoleApiService).singleton(),
        permissionsApiService: asClass(PermissionsApiService).singleton(),
        rightsApiService: asClass(RightsApiService).singleton(),
        projectPartitionApiService: asClass(
            ProjectPartitionApiService,
        ).singleton(),

        dataUploadService: asClass(DataUploadService).singleton(),
    });

    locationRouterService.registerLocations(accountAppLocationsMap);
}
