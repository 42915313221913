import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import identity from "lodash/identity";
import { useEffect, useMemo, useState } from "react";
import useSSE from "../ServerSideEvents/useSSE.hook";

export const cellHighlight = (cellName, iconTooltip) => {
    return {
        [cellName]: iconTooltip,
        [`${cellName}_ALERT_TYPE`]: "YELLOW",
    };
};

export const useTableCellUpdater = (
    dataSource = [],
    {
        eventName,
        highlightCell,
        highlightFunc,
        mapFunc = identity,
        getKey,
        iconTooltip,
    },
) => {
    const getItemKey = useCurrentHandler(getKey);
    const mapItem = useCurrentHandler(mapFunc);
    const highlightItem = useCurrentHandler(highlightFunc);

    const [events, setEvents] = useState({});
    const saveEvent = item =>
        setEvents(events => ({ ...events, [getKey(item)]: item }));

    useEffect(() => {
        return setEvents({});
    }, []);

    useSSE(eventName, item => {
        saveEvent(item);
    });

    const content = useMemo(
        () =>
            dataSource
                .map(item =>
                    events[getItemKey(item)] !== undefined
                        ? {
                              ...item,
                              ...mapItem(events[getItemKey(item)]),
                          }
                        : item,
                )
                .map(item =>
                    highlightItem(item)
                        ? {
                              ...item,
                              alerts: cellHighlight(highlightCell, iconTooltip),
                          }
                        : item,
                ),

        [
            iconTooltip,
            dataSource,
            events,
            mapItem,
            highlightItem,
            getItemKey,
            highlightCell,
        ],
    );

    return content;
};
