import { Form } from "@/components/DesignSystem";
import React from "react";
import { InputType } from "../../../Packages/PackageTableDefinitionPanel/constants";
import { MapperCascader } from "../MapperCascader";
import { getConverterWarning } from "../MapperGridForm/validations";
import { ConverterButton } from "./ConverterButton";
import style from "./MapperCascaderInput.styles.less";

export const formatValue = (input, inputType) => {
    if (inputType === InputType.COMPOSED) {
        return input.join(", ");
    } else {
        return input;
    }
};

export const DIRECTLY_EDITABLE_INPUT_TYPES = [InputType.BODY];

export const MapperCascaderInput = ({
    disabled,
    input,
    inputType,
    options,
    onChange,
    error,
    onOpenEditor,
    converterProps: { converter, withConverterWarning, parserConfig } = {},
}) => {
    const warning = getConverterWarning({
        withConverterWarning,
        converterExpression: converter,
        parserConfig,
    });
    const validateStatus = error ? "error" : warning ? "warning" : "success";
    return (
        <Form.Item validateStatus={validateStatus} help={error || warning}>
            <MapperCascader
                disabled={
                    disabled ||
                    !DIRECTLY_EDITABLE_INPUT_TYPES.includes(inputType)
                }
                value={formatValue(input, inputType)}
                options={options}
                onChange={onChange}
                allowClear={false}
                // suffix={<MapperItemPropertyError error={error} />}
                className={style.selectInput}
                onAddonClick={e => {
                    onOpenEditor();
                    e.stopPropagation();
                }}
                addonAfter={
                    <ConverterButton
                        inputType={inputType}
                        converter={converter}
                    />
                }
            />
        </Form.Item>
    );
};
