import { useEffect, useState } from "react";
import { isEqual } from "lodash";

export const useMemoBy = (data, equalityFnInit = isEqual) => {
    const [memoizedData, setMemoizedData] = useState(data);
    useEffect(() => {
        if (!equalityFnInit(data, memoizedData)) setMemoizedData(data);
    }, [data]);

    return memoizedData;
};
