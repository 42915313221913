import { hooks as tableHooks } from "@/components/DesignSystem/Table/Table.component";
import { usePmFeatureFlagsRestrictionsQuery } from "@/components/PmFeatureFlags/loadables";
import { mapRestrictionsToExpandableDataSource } from "@/components/PmFeatureFlags/utils";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import React, { useEffect } from "react";

const columns = [
    {
        name: `col_0`,
        label: t("pm-feature-flags.restrictions-modal.label.main-type"),
    },
    {
        name: `col_1`,
        label: t("pm-feature-flags.restrictions-modal.label.secondary"),
    },
];
export const PmFeatureFlagsRestrictionsTable = ({ featureFlagName }) => {
    const pmFeatureFlagsRestrictionsQuery = usePmFeatureFlagsRestrictionsQuery({
        featureFlagName,
    });

    const restrictionsDataSource = mapRestrictionsToExpandableDataSource(
        (
            pmFeatureFlagsRestrictionsQuery.loadable.valueMaybe() ?? {
                restrictions: [],
            }
        ).restrictions,
    );
    const rowKey = "key";
    const { dataSource, expandable, setExpandedRowKeys } =
        tableHooks.useExpandable({
            dataSource: restrictionsDataSource,
            rowKey,
        });

    useEffect(() => {
        setExpandedRowKeys(dataSource.map(r => r[rowKey]));
    }, [dataSource, setExpandedRowKeys]);

    return (
        <TableLocalFiltered
            columns={columns}
            customHeight={{ height: 308 }}
            dataSource={dataSource}
            expandable={expandable}
            rowKey={rowKey}
            disablePagination
            loading={isLoading(pmFeatureFlagsRestrictionsQuery)}
        />
    );
};
