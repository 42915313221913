import {
    uniqueArrayValuesValidator,
    validEmailsValidator,
} from "@/components/CreateJobTaskAlert/validators";
import { Button, Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import { getTimeZonesOptions } from "@/utils/timezone/timezone.utils";
import { identity } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import {
    useInitialValuesQuery,
    useSaveReportMutation,
    useServicesOptionsQuery,
} from "./loadables";

const reportTypes = [
    {
        label: "Daily integration statistics report",
        value: "DAILY_INTEGRATION_STATISTICS",
    },
    {
        label: "Daily Processed Batches report",
        value: "DAILY_PROCESSED_BATCH_STATISTICS",
    },
];

const timezones = getTimeZonesOptions();

export const ReportsForm = ({ accountId, reportId }) => {
    const {
        locationRouterService,
        accountAppLocations: { accountReportsLocation },
    } = useDic();

    useSetPageTitle(
        t(
            reportId === undefined
                ? "reports-form.label.create"
                : "reports-form.label.edit",
        ),
    );

    const initialValuesQuery = useInitialValuesQuery({ reportId });
    const servicesOptionsQuery = useServicesOptionsQuery({ accountId });
    const saveReportMutation = useSaveReportMutation({
        afterSuccess: () =>
            locationRouterService.navigate(accountReportsLocation, {
                accountId,
            }),
    });
    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit: ({ values }) =>
            saveReportMutation.mutate({
                ...initialValuesQuery.loadable.valueMaybe(),
                ...values,
                entityType: values.service.split("|")[0],
                entityId: values.service.split("|")[1],
                recipients: values.recipients.join(","),
                projectId: accountId,
            }),
    });
    const initialValues = initialValuesQuery.loadable.valueMaybe();
    useSetValidatedInitialValues({ initialValues, setValues, setTouched }, [
        initialValues,
    ]);

    return (
        <Forms.Form formId={formId} onSubmit={handleSubmit}>
            <Forms.Fields.Input
                name="name"
                label={t("reports-form.label.name")}
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    Forms.pmValidators.createMinLengthValidation(5),
                ])}
                required
            />
            <Forms.Fields.Switch
                name="enabled"
                label={t("reports-form.label.enabled")}
                layout="vertical"
                textOn={t("general.on")}
                textOff={t("general.off")}
            />
            <Forms.Fields.Select
                name="service"
                label={`${t("reports-form.label.for")} ${t(
                    "reports-form.label.service",
                )}`}
                validator={Forms.pmValidators.isRequired}
                required
                allowClear={false}
                useRawOptions
                {...getLoadableSelectProps(servicesOptionsQuery.loadable)}
            />
            <Forms.Fields.Select
                name="reportType"
                label={t("reports-form.label.report-type")}
                options={reportTypes}
                validator={Forms.pmValidators.isRequired}
                required
                allowClear={false}
            />
            <Forms.FieldGrid layout={[{ width: 264 }, { width: 100 }]}>
                <Forms.FieldGrid.Row>
                    <Forms.Fields.Select
                        name="timezone"
                        label={t("reports-form.label.scheduled")}
                        options={timezones}
                        validator={Forms.pmValidators.isRequired}
                        required
                        allowClear={false}
                        style={{ left: "-10px" }}
                    />
                    <Forms.Fields.TimePicker
                        name="time"
                        label={""}
                        format="HH:mm"
                        validator={Forms.pmValidators.isRequired}
                        required
                        allowClear={false}
                    />
                </Forms.FieldGrid.Row>
            </Forms.FieldGrid>
            <Forms.Fields.Select_tempFixDeselect
                name="recipients"
                label={t("reports-form.label.recipients")}
                mode="tags"
                allowClear={false}
                required
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    Forms.pmValidators.createMinLengthValidation(1, {
                        map: identity,
                    }),
                    uniqueArrayValuesValidator,
                    validEmailsValidator,
                ])}
                // props={{ id: "recipients-field" }}
            />
            <Forms.SubmitButton>
                <Button
                    label={t("general.save")}
                    type="primary"
                    htmlType="submit"
                    disabled={
                        isLoading(servicesOptionsQuery) ||
                        isLoading(initialValuesQuery)
                    }
                />
            </Forms.SubmitButton>
            {accountId && (
                <Button
                    label={t("instance-form.button.cancel")}
                    type="text"
                    htmlType="button"
                    onClick={() =>
                        locationRouterService.navigate(accountReportsLocation, {
                            accountId,
                        })
                    }
                />
            )}
        </Forms.Form>
    );
};

ReportsForm.propTypes = {
    accountId: PropTypes.number,
    reportId: PropTypes.number,
};
