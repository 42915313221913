import Header from "@/components/Header/Header";
import { PlatformManagerTemplate } from "@/components/PageTemplates";
import React from "react";

export const FullPage = ({ children }) => {
    return (
        <PlatformManagerTemplate header={<Header />}>
            {children}
        </PlatformManagerTemplate>
    );
};
