import { message } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    useComposeLoadablesMemoized,
    useMapLoadableMemoized,
    useQueryLoadable,
} from "@/modules/loadable";
import {
    PROJECT_WORKFLOW_EDIT_PERMISSION,
    WORKFLOW_PERMISSION_FUNC,
} from "@/security/permission.utils";
import { t } from "@/translations";
import get from "lodash/fp/get";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { accountsService } from "../../apps/accountApp/services/accounts.service";
import {
    cleanButtonData,
    setButtonData,
} from "../BreadcrumbButton/duck/actions";
import { CreateWorkflow } from "./components/CreateWorkflow.component";
import { WorkflowDefinitionTableComponent } from "./components/WorkflowDefinitionTable.component";

export const getCleanModalData = () => {
    return { data: null, visible: true };
};

export const getCloseModalData = () => {
    return { data: null, visible: false };
};

export const getEditModalData = data => {
    return { data: data, visible: true };
};

export const useWorkflowList = () => {
    const { workflowService } = useDic();
    return useQueryLoadable(() => {
        return workflowService.getWorkflows().then(get("data"));
    }, [workflowService]);
};

function WorkflowsAdminPage({ setButtonData, cleanButtonData }) {
    useFullWidthTableLayout();

    const { workflowService } = useDic();
    const [modalData, setModalData] = useState({ data: null, visible: false });

    const showCleanModal = () => {
        setModalData(getCleanModalData());
    };

    const closeModal = () => {
        setModalData(getCloseModalData());
    };

    const showEditModal = data => {
        setModalData(getEditModalData(data));
    };

    const accounts = useQueryLoadable(() => {
        return accountsService
            .fetchAccountsByPermissions([PROJECT_WORKFLOW_EDIT_PERMISSION])
            .then(get("data"));
    }, []);

    const workflows = useWorkflowList();

    const loadables = useComposeLoadablesMemoized([
        accounts.loadable,
        workflows.loadable,
    ]);

    const workflowsLoadable = useMapLoadableMemoized(
        loadables,
        useCurrentHandler(([accountsList, workflowList]) => {
            return workflowList.map(workflow => {
                return {
                    ...workflow,
                    account: accountsList.find(
                        account => account.id === workflow.projectId,
                    ),
                };
            });
        }),
    );

    // TODO: Load from API, will not be working when worflows list will be paginated
    const usedTypesAndConditions = useMemo(
        () =>
            (workflowsLoadable.valueMaybe() ?? []).map(
                workflow => workflow.wfType + workflow.wfCondition,
            ),
        [workflowsLoadable],
    );

    useEffect(() => {
        setButtonData(
            "workflow-list.button.create-new",
            () => showCleanModal(),
            [],
            { permissionsFunc: WORKFLOW_PERMISSION_FUNC },
        );
        return cleanButtonData;
    }, []);

    const onWorkflowSave = ({ id: workflowId, values }) => {
        closeModal();
        workflowService
            .createOrUpdateWorkflow(values, workflowId)
            .then(workflows.reload)
            .catch(() => {
                message.error(t("workflow-list.error.create"));
            });
    };

    const deleteWorkflow = workflowId => {
        workflowService
            .deleteWorkflow(workflowId)
            .then(workflows.reload)
            .catch(() => {
                message.error(t("workflow-list.error.delete"));
            });
    };

    return (
        <>
            {modalData.visible && (
                <CreateWorkflow
                    accountsLoadable={accounts.loadable}
                    onCancel={closeModal}
                    onSave={onWorkflowSave}
                    initialValues={modalData.data}
                    usedTypesAndConditions={usedTypesAndConditions}
                />
            )}
            <WorkflowDefinitionTableComponent
                onDelete={deleteWorkflow}
                onEdit={showEditModal}
                workflowsLoadable={workflowsLoadable}
                showCreateModal={showCleanModal}
            />
        </>
    );
}

export default connect(undefined, { setButtonData, cleanButtonData })(
    WorkflowsAdminPage,
);
