import { useParentState } from "@/components/hooks/useParentState.hook";
import { useSomeAsyncTasksLiveQuery } from "@/modules/async-tasks";
import { hasValue, loadableFromMaybeValue } from "@/modules/loadable";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
export const useImUpdateProcess = initJobId => {
    const [jobId, setJobId] = useParentState(initJobId);
    const [currentProcessState, setCurrentProcessState] = useState(undefined);

    const maybeAsyncJobQuery = useSomeAsyncTasksLiveQuery({
        filterPredicate: useCallback(
            job => job.id === jobId || job.jobId === jobId,
            [jobId],
        ),
    });

    useEffect(() => {
        if (hasValue(maybeAsyncJobQuery.loadable)) {
            const jobState = maybeAsyncJobQuery.loadable.valueMaybe();

            if (!isEmpty(jobState)) {
                setCurrentProcessState(jobState[0]);
            } else {
                setCurrentProcessState(null);
            }
        }
    }, [maybeAsyncJobQuery.loadable]);

    return {
        loadable: loadableFromMaybeValue(currentProcessState),
        setNextJobId: setJobId,
    };
};
