import { LoadableRenderer, QueryResourcePropType } from "@/modules/loadable";
import { SecurityContext } from "@/security/authorization";
import {
    includesPermission,
    INTEGRATION_EDIT_PERMISSIONS,
} from "@/security/permission.utils";
import PropTypes from "prop-types";
import React, { useContext, useMemo } from "react";
import RouteEditForm from "./RouteEditForm";

const ProjectIntegrationForm = ({
    isRepositoryView,
    projectId,
    instanceId,
    configurableSupported = true,
    routeResource,
}) => {
    const securityContext = useContext(SecurityContext);

    const readOnly = useMemo(
        () =>
            !configurableSupported ||
            (projectId !== "new" &&
                !includesPermission(
                    securityContext,
                    INTEGRATION_EDIT_PERMISSIONS,
                )),
        [projectId, securityContext, configurableSupported],
    );

    return (
        <LoadableRenderer
            loadable={routeResource.loadable}
            hasValue={route => {
                return (
                    <RouteEditForm
                        readOnly={readOnly}
                        route={route}
                        projectId={projectId}
                        instanceId={instanceId}
                        isRepositoryView={isRepositoryView}
                    />
                );
            }}
        />
    );
};

ProjectIntegrationForm.propTypes = {
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    instanceId: PropTypes.number,
    integrationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    configurableSupported: PropTypes.any,
    routeResource: QueryResourcePropType(),
    isRepositoryView: PropTypes.bool,
};

export default ProjectIntegrationForm;
