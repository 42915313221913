import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useMutationLoadableWithNotification } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { noop } from "lodash/fp";

export const useCreateServerMutation = ({ afterSuccess = noop }) => {
    const { serverService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async values =>
            serverService
                .createServer(values)
                .then(getData)
                .then(data => {
                    afterSuccessCurrent();
                    return data;
                }),

        [afterSuccessCurrent, serverService],
        () => t("servers.create.success"),
    );
};

export const useDeleteServerMutation = ({ afterSuccess = noop }) => {
    const { serverService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record =>
            serverService.deleteServer(record.id).then(() => {
                afterSuccessCurrent();
                return record;
            }),

        [afterSuccessCurrent, serverService],
        ({ name }) =>
            t("general.message.deleted", {
                type: "server",
                name,
            }),
    );
};
