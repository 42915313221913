import { Cascader } from "@/components/DesignSystem";
import cx from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import "./MapperCascader.styles.less";

const stringify = val => val?.join(".");
const parse = val => val?.split(".");

const displayRender = (labels, selectedOptions) => stringify(labels);
const from = (valueArr, selectedOptions) => stringify(valueArr);
const to = value => parse(value);

export const MapperCascader = ({
    options,
    value: valueProp,
    onChange: onChangeProp,
    onChangeBypassUnityForms,
    className,
    addonAfter: addonAfterProp,
    onAddonClick,
    testId = "mapper-cascader",
    ...rest
}) => {
    // console.log("MapperCascader", { addonAfterProp });
    const onChange = useCallback(
        (value, selected) => {
            onChangeBypassUnityForms?.(from(value), selected);
            onChangeProp(from(value), selected);
        },
        [onChangeProp],
    );
    const value = useMemo(() => to(valueProp), [valueProp]);

    const addonAfter = !addonAfterProp ? undefined : (
        <div
            onClick={onAddonClick}
            style={{
                margin: "-11px",
                padding: "11px",
                cursor: onAddonClick ? "pointer" : "initial",
            }} // expand clickable area, TODO: move to UC
        >
            {addonAfterProp}
        </div>
    );

    return (
        <div data-test={testId}>
            <Cascader
                showSearch
                className={cx("cascaderWithAddonFix", className)}
                options={options}
                value={value}
                onChange={onChange}
                displayRender={displayRender}
                allowClear={false}
                addonAfter={addonAfter}
                {...rest}
            />
        </div>
    );
};

MapperCascader.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};
