import { useDic } from "@/components/Dic/useDic.hook";
import { EventWFForm } from "@/components/EventWorkflows/EventWFForm";
import { useCreateEventWFMutation } from "@/components/EventWorkflows/loadables";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";

export const NewEventWorkflowPage = () => {
    const { accountId } = useAccountAppParams();
    const {
        locationRouterService,
        accountAppLocations: { eventWorkflowsLocation },
    } = useDic();
    const onCancel = useCallback(
        () => locationRouterService.navigate(eventWorkflowsLocation),
        [eventWorkflowsLocation, locationRouterService],
    );
    const createMutation = useCreateEventWFMutation({
        accountId,
        afterSuccess: onCancel,
    });

    return (
        <EventWFForm
            accountId={accountId}
            title={t(
                "location.accountApp.eventOrchestration.eventWorkflows.create",
            )}
            // initialValues={undefined}
            onSubmit={createMutation.mutate}
            onCancel={onCancel}
        />
    );
};
