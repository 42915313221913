import { useEffect, useRef } from "react";
import { useSetAlert } from "./useSetAlert.hook";

export const useAlertEffect = (visible, getAlert) => {
    const setAlert = useSetAlert();

    const getAlertRef = useRef(getAlert);
    if (getAlertRef.current !== getAlert) getAlertRef.current = getAlert;

    useEffect(() => {
        if (visible) {
            const alert = getAlertRef.current();
            setAlert(alert);
        } else {
            setAlert(null);
        }

        return () => {
            setAlert(null);
        };
    }, [visible, setAlert]);
};
