import { Modal, UnityLayout } from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { FORM_TYPE, UserForm, useUserForm } from "./UserForm";

export const NewUserModal = ({ visible, onClose, reloadUsers }) => {
    const { submit, cancel, ...rest } = useUserForm({
        canFetch: visible,
        formType: FORM_TYPE.CREATE,
        onCancel: onClose,
        initialValues: undefined,
        afterSave: () => {
            reloadUsers();
            onClose();
        },
    });

    return (
        <Modal visible={visible} onCancel={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("user-detail.new.title")}
                />
                <UnityLayout.Content padding={[true, true]}>
                    <UserForm {...rest} inputWidth="max" />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {submit}
                            {cancel}
                        </div>
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

NewUserModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    reloadUsers: PropTypes.func.isRequired,
};
