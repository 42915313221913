import { Input } from "@/components/DesignSystem";
import { Select } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useMemo } from "react";

const TIME_UNITS = [
    {
        label: "seconds",
        value: 1,
    },
    {
        label: "minutes",
        value: 60,
    },
    {
        label: "hours",
        value: 3600,
    },
    {
        label: "days",
        value: 86400,
    },
];

const findLargestMultiplier = currentValue => {
    const foundUnit = [...TIME_UNITS]
        .reverse()
        .find(option => currentValue % option.value === 0);

    return foundUnit?.value ?? TIME_UNITS[2].value;
};

export const TimeWithUnits = ({
    disabled,
    value,
    onChange,
    condensed,
    ...restProps
}) => {
    const [multiplier, setMultiplier] = useState(findLargestMultiplier(value));

    const inputValue = value / multiplier;

    const onInputChange = e => onChange(e.target.value * multiplier);

    const onUnitChange = multi => (
        setMultiplier(multi), onChange(multi * inputValue)
    );
    const options = useMemo(
        () =>
            condensed
                ? TIME_UNITS.map(o => ({
                      ...o,
                      label: o.label.substring(0, 1),
                  }))
                : TIME_UNITS,
        [condensed],
    );

    return (
        <>
            <Input
                type="number"
                pattern="[0-9]*"
                onChange={onInputChange}
                value={inputValue}
                disabled={disabled}
                addonAfter={
                    <Select
                        disabled={disabled}
                        options={options}
                        defaultValue={multiplier}
                        style={{ minWidth: condensed ? "60px" : "100px" }}
                        onChange={onUnitChange}
                    />
                }
                {...restProps}
            />
        </>
    );
};

TimeWithUnits.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    condensed: PropTypes.bool,
};
