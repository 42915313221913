import FieldGridNotPadded from "@/components/DataUploads/SftpManagement/FieldGridNotPadded.component";
import { Button, Forms, Gap, H4, Text } from "@/components/DesignSystem";
import { DeleteRowButton } from "@/components/Mappers/MapperTableWithCustomization/components/DeleteRowButton";
import { t } from "@/translations";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import * as R from "ramda";
import React from "react";

const LAYOUT = [{ width: "320px" }, { width: "85px" }];

const EmailNotificationForm = ({ name = "emails", emails }) => {
    const initialValue = (
        R.isEmpty(emails) || R.isNil(emails) ? [""] : emails
    ).map(email => ({ email }));

    return (
        <>
            <H4>{t("sftp-servers.email-notifications.title")}</H4>
            <Text>{t("sftp-servers.email-notifications.perex")}</Text>
            <FieldGridNotPadded>
                <Forms.FieldGrid layout={LAYOUT}>
                    <Forms.List name={name} initialValue={initialValue}>
                        {({ rows, add, remove }) => (
                            <>
                                {rows.map(({ id, fieldProps }, index) => (
                                    <Forms.FieldGrid.Row key={id}>
                                        <Forms.Fields.Input
                                            label={t(
                                                "sftp-servers.email-notifications.fields.emails.label",
                                            )}
                                            {...fieldProps("email")}
                                            autoComplete={
                                                rows.length === index + 1
                                                    ? "email"
                                                    : "nofill"
                                            }
                                            validator={
                                                rows.length === 1
                                                    ? Forms.pmValidators.noValueOr(
                                                          Forms.pmValidators
                                                              .emailValid,
                                                      )
                                                    : Forms.validators.failOnFirst(
                                                          [
                                                              Forms.pmValidators
                                                                  .isRequired,
                                                              Forms.pmValidators
                                                                  .emailValid,
                                                          ],
                                                      )
                                            }
                                        />
                                        <Forms.UIField name="actions">
                                            <DeleteRowButton
                                                onClick={() => remove(id)}
                                            />
                                        </Forms.UIField>
                                    </Forms.FieldGrid.Row>
                                ))}
                                <Gap size="small" />
                                <Button
                                    icon={Plus}
                                    label={t(
                                        "sftp-servers.email-notifications.add-email",
                                    )}
                                    onClick={() => add()}
                                    disabled={false}
                                />
                            </>
                        )}
                    </Forms.List>
                </Forms.FieldGrid>
            </FieldGridNotPadded>
        </>
    );
};

export default EmailNotificationForm;
