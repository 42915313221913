import { isThenable } from "@/modules/modal/imperative/ConfirmModalProvider";
import { useCallback, useState } from "react";

export const useWithPending = asyncHandler => {
    const [pending, setPending] = useState(false);

    const handler = useCallback(
        async (...args) => {
            let handlerResult = asyncHandler?.(...args);
            if (isThenable(handlerResult)) {
                setPending(true);
                handlerResult = await handlerResult.catch(e => {
                    setPending(false);
                    throw e;
                });
                setPending(false);
            }
            return handlerResult;
        },
        [asyncHandler],
    );

    return { handler, pending };
};
