export const getCheckboxStates = (grantedBy, getTooltip = () => null) => {
    const disabled = grantedBy.some(({ disabled }) => disabled);

    if (!grantedBy.length) {
        return [
            {
                disabled: true,
                checked: false,
                indeterminate: false,
                tooltipText: "Selection is empty.",
            },
        ];
    }
    if (grantedBy.every(({ readOnly }) => readOnly))
        return [
            {
                disabled: true,
                checked: true,
                indeterminate: false,
                tooltipText: getTooltip("all items", grantedBy),
            },
        ];
    if (grantedBy.some(({ readOnly }) => readOnly)) {
        const tooltipText = getTooltip("some items", grantedBy);

        if (grantedBy.every(({ readOnly, editable }) => readOnly || editable)) {
            return [
                {
                    disabled: disabled,
                    checked: true,
                    indeterminate: false,
                    tooltipText,
                },
                {
                    disabled: disabled,
                    checked: false,
                    indeterminate: true,
                    action: "remove",
                    tooltipText,
                },
            ];
        } else {
            // not possible to differentiate two possible indeterminate states in UI:
            // 1/ some editable and some read only permissions granted
            // 2/ none editable granted, but some read only granted
            // for both cases, permissions are added, but when some are granted, they cannot be removed in batch mode

            return [
                {
                    disabled: disabled,
                    checked: false,
                    indeterminate: true,
                    tooltipText,
                },
                {
                    disabled: disabled,
                    checked: true,
                    indeterminate: false,
                    action: "add",
                    tooltipText,
                },
            ];
        }
    }
    if (grantedBy.every(({ readOnly }) => !readOnly)) {
        const tooltipText = getTooltip("", grantedBy); // TODO: remove?

        if (grantedBy.every(({ editable }) => editable))
            return [
                {
                    disabled: disabled,
                    checked: true,
                    indeterminate: false,
                    tooltipText: tooltipText,
                },
                {
                    disabled: disabled,
                    checked: false,
                    indeterminate: false,
                    action: "remove",
                    tooltipText: tooltipText,
                },
            ];
        if (grantedBy.every(({ editable }) => !editable))
            return [
                {
                    disabled: disabled,
                    checked: false,
                    indeterminate: false,
                    tooltipText: tooltipText,
                },
                {
                    disabled: disabled,
                    checked: true,
                    indeterminate: false,
                    action: "add",
                    tooltipText: tooltipText,
                },
            ];
        if (grantedBy.some(({ editable }) => editable))
            return [
                {
                    disabled: disabled,
                    checked: false,
                    indeterminate: true,
                    tooltipText: tooltipText,
                },
                {
                    disabled: disabled,
                    checked: true,
                    indeterminate: false,
                    action: "add",
                    tooltipText: tooltipText,
                },
                {
                    disabled: disabled,
                    checked: false,
                    indeterminate: false,
                    action: "remove",
                    tooltipText: tooltipText,
                },
            ];
    }
};
