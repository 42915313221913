export const NotificationType = {
    TASK: "TASK",
    TRANSPORT_IM_ENVIRONMENT: "TRANSPORT_IM_ENVIRONMENT",
    WORKFLOW_APPROVAL: "WORKFLOW_APPROVAL",
    PACKAGE_TERMINATION: "PACKAGE_TERMINATION",
    PACKAGE_ROLLBACK: "PACKAGE_ROLLBACK",
    INSTANCE_DELETED: "INSTANCE_DELETED",
    WORKFLOW_DELETED: "WORKFLOW_DELETED",
    DATA_DOWNLOAD_DELETED: "DATA_DOWNLOAD_DELETED",
};

export const TaskType = {
    SOLUTION_DEPLOY: "SOLUTION_DEPLOY",
    SOLUTION_DELETE: "SOLUTION_DELETE",
    INTEGRATION_TEMPLATE_DEPLOY: "INTEGRATION_TEMPLATE_DEPLOY",
    UPLOAD_FILE: "UPLOAD_FILE",
    DOWNLOAD_FILE: "DOWNLOAD_FILE",
    DOWNLOAD_FILE_EXTERNAL_STORAGE: "DOWNLOAD_FILE_EXTERNAL_STORAGE",
    PACKAGE_DEPLOY: "PACKAGE_DEPLOY",
    COPY_EVENT_WORKFLOW: "COPY_EVENT_WORKFLOW",
};
