import { useDic } from "@/components/Dic/useDic.hook";
import { identity } from "lodash/fp";
import { useEffect, useRef, useState } from "react";
import { transitDeserializer, transitSerializer } from "./serializers";

export const useSessionStorageState = (
    key,
    defaultValue,
    {
        serialize = transitSerializer,
        deserialize = transitDeserializer,
        reviveState = identity,
    } = {},
) => {
    const { sessionStorage } = useDic();
    const [state, setState] = useState(() => {
        const valueInSessionStorage = sessionStorage.getItem(key);
        if (valueInSessionStorage) {
            return reviveState(deserialize(valueInSessionStorage));
        }
        return typeof defaultValue === "function"
            ? defaultValue()
            : defaultValue;
    });

    const prevKeyRef = useRef(key);

    useEffect(() => {
        const prevKey = prevKeyRef.current;
        if (prevKey !== key) {
            sessionStorage.removeItem(prevKey);
        }
        prevKeyRef.current = key;
        sessionStorage.setItem(key, serialize(state));
    }, [key, state, serialize, sessionStorage]);

    return [state, setState];
};
