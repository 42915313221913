export const CODE_TO_LABEL = {
    C: "Customer",
    CX: "Customer Extension",
    CX3: "Customer Extension",
    CX6: "Customer Extension",
    CX8: "Customer Extension",
    CX10: "Customer Extension",
    CX20: "Customer Extension",
    CX30: "Customer Extension",
    CX50: "Customer Extension",
    DM: "Datamart",
    MLTV: "Matrix Lookup Table Value",
    MLTV2: "Matrix Lookup Table Value",
    MLTV3: "Matrix Lookup Table Value",
    MLTV4: "Matrix Lookup Table Value",
    MLTV5: "Matrix Lookup Table Value",
    MLTV6: "Matrix Lookup Table Value",
    JLTV: "Json Lookup Table Value",
    JLTV2: "Json Lookup Table Value",
    MLTVM: "Matrix Lookup Table Attribute Meta",
    MPLI: "Manual Price List Item",
    P: "Product",
    PGI: "Price Grid Item",
    PLI: "Price List Item",
    PR: "Price Record",
    PX: "Product Extension",
    PX3: "Product Extension",
    PX6: "Product Extension",
    PX8: "Product Extension",
    PX10: "Product Extension",
    PX20: "Product Extension",
    PX30: "Product Extension",
    PX50: "Product Extension",
    RAT: "Rebate Agreement Template",
    RBT: "Rebate Type",
    RR: "Rebate Record",
    SIMI: "Simulation Item",
    XPGI: "Matrix Price Grid Item",
    XPLI: "Matrix Price List Item",

    AP: "Application Properties",
    APIK: "Api Key",
    BD: "Binary Data",
    BPT: "Bound Partition",
    CA: "Customer Assignment",
    CAM: "Customer Attribute Meta",
    CDESC: "Customer Description",
    CF: "Calculation Flow",
    CFS: "Calculation Field Set",
    CFT: "Calculation Flow Trait",
    CH: "Comment History",
    CN: "Instance Node",
    CS: "Calculation Schedule",
    CTLI: "Contract Line Item",
    CTT: "Contract Term Type",
    CTTAM: "Contract Term Type Attribute Meta",
    CTTREE: "Contract Tree",
    CXAM: "Customer Extension Attribute Meta",
    DA: "Data Archive",
    DB: "Dashboard",
    DCR: "Data Change Request",
    DCRAM: "Data Change Request Item",
    DCRL: "Data Change Request Log",
    DCRMC: "Data Change Request Mass Change",
    DCRT: "Data Change Request Type",
    DE: "Data Export",
    DI: "Data Import",
    DMDC: "Data Chunk",
    DMDL: "Data Load",
    DMDS: "Data Source",
    DMF: "Data Feed",
    DMM: "Model",
    DMQC: "Query Cache",
    DMSIM: "Simulation Datamart",
    DMSSCALC: "Sales Simulation Datamart Calculation",
    DMV: "View",
    DREG: "Device Registration",
    DWT: "Table",
    ET: "Email Task",
    EVT: "Event Task",
    F: "Pricing Formula",
    FE: "Pricing Formula Element",
    FI: "Formula Invariant",
    FIE: "Formula Invariant Element",
    FIER: "Formula Invariant Engine Run",
    FN: "Filter Node",
    IDC: "Inline Details Configuration",
    IE: "Internal Event",
    IFP: "Information Flow Processor",
    ISH: "Integration Status History",
    JST: "Job Status Tracker",
    LAT: "Login Audit Trail",
    LT: "Lookup Table",
    LTT: "Lookup Table Tree",
    LTV: "Lookup Table Value",
    MPL: "Manual Price List",
    MPLAM: "Manual Price List Item Attribute Meta",
    MPLIT: "Manual Price List Integration Tag",
    MPLT: "Manual Price List Tree",
    PAM: "Product Attribute Meta",
    PAPIJ: "Price Api Job",
    PBOME: "Product Bomentry",
    PCOMP: "Product Competition",
    SL: "Seller",
    PDESC: "Product Description",
    PG: "Price Grid",
    PGIM: "Price Grid Item Attribute Meta",
    PGT: "PRice Grid Tree",
    PH: "Partition Help",
    PL: "Price List",
    PLIM: "Price List Item Attribute Meta",
    PLT: "Price List Tree",
    PRAM: "Price Record Attribute Meta",
    PREF: "Preferences",
    PT: "Partition",
    PWH: "Password History",
    PXAM: "Product Extension Attribute Meta",
    PXREF: "Product Preference",
    Q: "Quote",
    QLI: "Quote Line Item",
    QT: "Quote Tree",
    R: "Role",
    RATM: "Rebate Agreement Template Attribute Meta",
    RBA: "Rebate Agreement",
    RBALI: "Rebate Agreement line Item",
    RBAT: "Rebate Agreement Tree",
    RBTAM: "Rebate Type Attribute Meta",
    RRAM: "Rebate Record Attribute Meta",
    RRS: "Rebate Record Set",
    RRSC: "Rebate Record Set Calculation",
    RT: "Refresh Token",
    SAT: "Security Audit Trail",
    SC: "Saved Chart",
    SCT: "Saved Chart Tree",
    SIAM: "Simulation Item Attribute Meta",
    SIM: "Simulation",
    TFA: "Tfa Token",
    TODO: "Todo Item",
    U: "User",
    UG: "User Group",
    US: "Upload Status",
    W: "Workflow",
    WF: "Workflow Formula",
    WFE: "Workflow Formula Element",
    XSIMI: "Matrix Simulation Item",
};
