export const IM_VERSION_WITH_OBJECT_DELETION_AFTER_DEPLOY_SUPPORT = "3.1.0";
export const IM_VERSION_WITH_CONNECTION_DELETION_AFTER_DEPLOY_SUPPORT = "3.2.0";
export const IM_VERSION_BEFORE_CERTIFICATES_SUPPORT = "3.0.0";
export const IM_VERSION_WITH_MAX_LENGTH_SUPPORT_1 = ["4.10.8", "5.0.0"];
export const IM_VERSION_WITH_MAX_LENGTH_SUPPORT_2 = "5.3.0";

export const IM_VERSION_WITH_ENCRYPTION_SUPPORT = "4.6.0-SNAPSHOT";
export const IM_VERSION_WITH_CERTIFICATE_ENCRYPTION_SUPPORT = "5.7.1";

export const entityDeploymentStatus = {
    SUCCESSFUL: "Successful",
    FAILED: "Failed",
};
