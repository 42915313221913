import {
    Button,
    ButtonGroup,
    Forms,
    Gap,
    Text,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetWarningAlert } from "@/components/PageLayout/useSetAlert.hook";
import Upload from "@/components/Upload/Upload";
import {
    isLoading,
    LoadableRenderer,
    useQueryLoadable,
    waitForValue,
} from "@/modules/loadable";
import { instancesServices } from "@/services/instance.service";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";

const { SubmitButton, useForm, Form } = Forms;

export const NewResource = ({ instanceId }) => {
    const {
        messageService,
        resourcesService,
        locationRouterService,
        accountAppLocations: { resourcesLocation },
    } = useDic();
    const [uploadedFile, setUploadedFile] = useState(null);

    const goToResourcesList = () => {
        locationRouterService.navigate(resourcesLocation);
    };

    const setPageAlert = useSetWarningAlert();

    const allowedExtensionsResource = useQueryLoadable(() => {
        return resourcesService
            .getAllowedExtensions(instanceId)
            .then(getData)
            .then(extensions =>
                extensions.map(extension => `.${extension}`).join(","),
            );
    }, [instanceId, resourcesService]);

    const { formId, handleSubmit } = useForm({
        onSubmit: ({ values }) => {
            if (!uploadedFile) {
                setPageAlert(t("new-resource.messages.file-not-uploaded-yet"));
            }
            return resourcesService
                .deploy(instanceId, uploadedFile.id, values.encrypt)
                .then(() => {
                    messageService.success({
                        content: t("new-resource.messages.success"),
                    });
                    goToResourcesList();
                })
                .catch(e =>
                    messageService.error({
                        content: getErrorMessage(e.response.data),
                    }),
                );
        },
    });

    const instancePropertiesQuery = useQueryLoadable(
        async () =>
            isNaN(instanceId)
                ? waitForValue()
                : instancesServices
                      .fetchInstanceProperties(instanceId)
                      .then(getData),
        [instanceId],
    );

    const isResourceEncryptionSupported = useMemo(
        () =>
            !!instancePropertiesQuery.loadable.valueMaybe()
                ?.resourceEncryptionSupported,
        [instancePropertiesQuery.loadable],
    );

    return (
        <>
            <Text>{t("new-resource.perex")}</Text>
            <Gap />
            <LoadableRenderer
                loadable={allowedExtensionsResource.loadable}
                hasValue={allowedExtensions => (
                    <Form formId={formId} onSubmit={handleSubmit}>
                        <Upload
                            variant="withIcon"
                            onFileUploaded={file => {
                                setUploadedFile(file);
                            }}
                            data={{
                                url: `/api/instances/${instanceId}/resources`,
                            }}
                            multiple={false}
                            accept={allowedExtensions}
                            onAbort={() => setUploadedFile(null)}
                        />
                        <Gap />
                        {!isLoading(instancePropertiesQuery) &&
                            isResourceEncryptionSupported && (
                                <Forms.Fields.Switch
                                    name="encrypt"
                                    layout="horizontal"
                                    noMaxWidth
                                    textOn={t("general.encrypted")}
                                    textOff={t("general.not-encrypted")}
                                />
                            )}

                        <ButtonGroup>
                            <SubmitButton
                                type="primary"
                                label={t("general.deploy")}
                            />
                            <Button
                                type="text"
                                onClick={goToResourcesList}
                                label={t("general.cancel")}
                            />
                        </ButtonGroup>
                    </Form>
                )}
            />
        </>
    );
};

NewResource.propTypes = {
    instanceId: PropTypes.number,
};
