import { useDataLoadWarningsQuery } from "@/components/DataUploads/Wizard/loadables";
import { Alert, Gap } from "@/components/DesignSystem";
import { t } from "@/translations";
import { isEmpty, join } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { ENTITY_TYPE_CODES_ALL } from "../../../constants/SupportedTableImportTypes";

export const warningToMessage = ({ warning, entityType, entityNames } = {}) => {
    if (warning === "PA_IN_USE") {
        return t("data-upload.warnings.pa-in-use", {
            entityType: ENTITY_TYPE_CODES_ALL[entityType]?.label,
            entityNames: join(entityNames, ", "),
        });
    }

    if (warning === "IN_USE" && !isEmpty(entityNames)) {
        return t("data-upload.warnings.in-use", {
            entityType: ENTITY_TYPE_CODES_ALL[entityType]?.label,
            entityNames: join(entityNames, ", "),
        });
    }

    if (warning === "IN_USE") {
        return t("data-upload.warnings.in-use.master-table", {
            entityType: ENTITY_TYPE_CODES_ALL[entityType]?.label,
            entityNames: null,
        });
    }
};

export const DataUploadWarningComponent = ({ partitionId }) => {
    const warnings =
        useDataLoadWarningsQuery({
            partitionId,
        }).loadable.valueMaybe() || [];

    return (
        <>
            {warnings.map(warning => (
                <>
                    <Alert
                        message={warningToMessage(warning)}
                        type="warning"
                        showIcon
                        closable
                    />
                    <Gap size="small" />
                </>
            ))}
        </>
    );
};

DataUploadWarningComponent.propTypes = {
    warnings: PropTypes.arrayOf(
        PropTypes.shape({
            warning: PropTypes.string.isRequired,
            entityType: PropTypes.string.isRequired,
            entityNames: PropTypes.arrayOf(PropTypes.string),
        }),
    ),
};
