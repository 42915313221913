import { Col, Row } from "@/components/DesignSystem";
import React from "react";
import Widget01 from "../../../../../views/Widgets/Widget01";
import DayWeekMonthRange from "../../../../DayWeekMonthRange/DayWeekMonthRange";

const RecordsHeader = ({ failed, successful, from, to, onDateRangeChange }) => {
    const failedColor = failed === 0 ? "primary" : "danger";

    return (
        <Row>
            <Col span={12}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Widget01
                            items={[{ value: failed }]}
                            mainText="Failed records"
                            color={failedColor}
                        />
                    </Col>
                    <Col span={12}>
                        <Widget01
                            items={[{ value: successful }]}
                            mainText="Successful records"
                            color="primary"
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={12}>
                <Row justify="end">
                    <DayWeekMonthRange
                        from={from}
                        to={to}
                        onDateRangeChange={onDateRangeChange}
                    />
                </Row>
            </Col>
        </Row>
    );
};

export default RecordsHeader;
