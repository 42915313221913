import { axiosService } from "../axios";

const BASE_PATH = "/api/im-updates/";
export const integrationManagerUpdateService = {
    directUpdate(instanceId, versionTo) {
        return axiosService.post(
            `${BASE_PATH}${instanceId}/direct-update?versionTo=${versionTo}`,
        );
    },
    getAvailableVersions(instanceId) {
        return axiosService.get(`${BASE_PATH}${instanceId}/available-versions`);
    },
    getChangeLog(instanceId, versionTo) {
        return axiosService.get(
            `${BASE_PATH}${instanceId}/changelog?versionTo=${versionTo}`,
        );
    },
    getState(instanceId) {
        return axiosService.get(`${BASE_PATH}${instanceId}/state`);
    },
    createSupportTicket(instanceId) {
        return axiosService.post(
            `${BASE_PATH}${instanceId}/modification-error-ticket`,
        );
    },
    commonGetStepState(step, instanceId) {
        return axiosService.get(`${BASE_PATH}${instanceId}/${step}`);
    },
    commonDelete(step, instanceId) {
        return axiosService.delete(`${BASE_PATH}${instanceId}/${step}`);
    },
    startAnalysis(instanceId, versionTo) {
        return axiosService.post(
            `${BASE_PATH}${instanceId}/analyze?versionTo=${versionTo}`,
        );
    },
    getAnalysisState(instanceId) {
        return axiosService.get(`${BASE_PATH}${instanceId}/analyze`);
    },
    deleteAnalysis(instanceId) {
        return axiosService.delete(`${BASE_PATH}${instanceId}/analyze`);
    },
    startModification(instanceId) {
        return axiosService.post(`${BASE_PATH}${instanceId}/modification`);
    },
    getModificationState(instanceId) {
        return axiosService.get(`${BASE_PATH}${instanceId}/modification`);
    },
    deleteModification(instanceId) {
        return axiosService.delete(`${BASE_PATH}${instanceId}/modification`);
    },
    startTest(instanceId) {
        return axiosService.post(`${BASE_PATH}${instanceId}/test`);
    },
    getTestState(instanceId) {
        return axiosService.get(`${BASE_PATH}${instanceId}/test`);
    },
    deleteTest(instanceId) {
        return axiosService.delete(`${BASE_PATH}${instanceId}/test`);
    },
    finishUpdate(instanceId) {
        return axiosService.post(`${BASE_PATH}${instanceId}/finish-update`);
    },
    getFinishUpdateState(instanceId) {
        return axiosService.get(`${BASE_PATH}${instanceId}/finish-update`);
    },
    cleanupUpdate(instanceId) {
        return axiosService.post(`${BASE_PATH}${instanceId}/cleanup-update`);
    },
};
