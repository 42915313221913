import {
    Alert,
    Button,
    ButtonGroup,
    Forms,
    Gap,
    H4,
    P,
} from "@/components/DesignSystem";
import { PropertyTable as PropertyTableAsField } from "@/components/DesignSystem/Forms/fields/PropertyTable";
import { useDic } from "@/components/Dic/useDic.hook";
import { useRouteDefinitionResource } from "@/components/IntegrationRoutes/routes.loadables";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { Collapse } from "@pricefx/unity-components";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { getRouteSendPayloadTrackName as getTrackName } from "../../../mixpanel/buttonNames";

const { useForm, Form, Fields, pmValidators } = Forms;

const propertiesArrayToObject = propertiesArray =>
    propertiesArray.reduce(
        (acc, { name, value }) => ({ ...acc, [name]: value }),
        {},
    );

const RouteSendPayload = ({ accountId, instanceId, routeId }) => {
    useSetPageTitle(t("routes-list.action.send-payload"));

    const {
        locationRouterService,
        routesService,
        accountAppLocations: { integrationsLocation },
        messageService,
    } = useDic();

    const routeDefinitionResource = useRouteDefinitionResource(
        instanceId,
        routeId,
    );

    const onBack = useCallback(
        () =>
            locationRouterService.navigate(integrationsLocation, {
                instanceId,
                accountId,
            }),
        [accountId, instanceId, integrationsLocation, locationRouterService],
    );
    useSetBackButtonAction(onBack);

    const onSubmit = ({ values }) => {
        const payload = values.payload;
        const headers = propertiesArrayToObject(values.headers ?? []);
        const properties = propertiesArrayToObject(values.properties ?? []);

        routesService
            .sendPayloadToRoute(
                instanceId,
                routeId,
                payload,
                headers,
                properties,
            )
            .then(() => {
                messageService.success({
                    content: t("route-payload.form.success"),
                });
            })
            .catch(error => {
                messageService.error({
                    content: getErrorMessage(error.response.data),
                });
            });
    };

    const { formId, handleSubmit } = useForm({
        onSubmit,
    });

    return (
        <LoadableRenderer
            loadable={routeDefinitionResource.loadable}
            hasValue={({
                allowSendPayload,
                allowedComponent,
                uri: fromUri,
            }) => (
                <>
                    {!allowSendPayload && (
                        <Alert
                            message={`Send payload to endpoint is not supported.
                               Only ${
                                   allowedComponent &&
                                   allowedComponent.join(", ")
                               } endpoints are supported.`}
                            type="error"
                            showIcon
                            closable
                            banner
                        />
                    )}
                    <Form formId={formId} onSubmit={handleSubmit}>
                        <H4>Send to route</H4>
                        {routeId}
                        <Gap size="small" />
                        <H4>With Endpoint</H4>
                        {fromUri}
                        <Gap size="small" />
                        <Collapse defaultActiveKey="1" size={4}>
                            <Collapse.Panel header="Payload" key="1">
                                <Fields.TextArea
                                    name="payload"
                                    label="Payload content"
                                    rows="10"
                                    validator={pmValidators.isRequired}
                                    width="max"
                                    disabled={!allowSendPayload}
                                />
                            </Collapse.Panel>
                            <Collapse.Panel header="Headers" key="2">
                                <PropertyTableAsField
                                    name="headers"
                                    addButtonLabel={"Add Header"}
                                    width="max"
                                />
                            </Collapse.Panel>
                            <Collapse.Panel header="Properties" key="3">
                                <PropertyTableAsField
                                    name="properties"
                                    addButtonLabel={"Add Property"}
                                    width="max"
                                />
                            </Collapse.Panel>
                        </Collapse>
                        <ButtonGroup>
                            <Forms.SubmitButton
                                withoutSpinner
                                disabled={!allowSendPayload}
                            >
                                <Button
                                    label={t("general.send")}
                                    type="primary"
                                    htmlType="submit"
                                    track={{ name: getTrackName("Send") }}
                                />
                            </Forms.SubmitButton>
                            <Button
                                type="text"
                                htmlType="button"
                                onClick={() => {
                                    locationRouterService.navigate(
                                        integrationsLocation,
                                        {
                                            instanceId,
                                            accountId,
                                        },
                                    );
                                }}
                                label={t("general.back")}
                                track={{ name: getTrackName("Back") }}
                            />
                        </ButtonGroup>
                    </Form>
                </>
            )}
            hasError={error => (
                <>
                    <P>Sorry! There was an error sending payload.</P>
                    <Alert type="error" message={JSON.stringify(error)} />
                    <Button
                        htmlType="button"
                        type="danger"
                        onClick={onBack}
                        label={t("general.back")}
                    />
                </>
            )}
        />
    );
};

RouteSendPayload.propTypes = {
    accountId: PropTypes.number.isRequired,
    instanceId: PropTypes.number.isRequired,
    routeId: PropTypes.string.isRequired,
};

export default RouteSendPayload;
