import { Gap, H5, Text } from "@/components/DesignSystem";
import { useConfirmModal } from "@/modules/modal/imperative/useConfirmModal.hook";
import { T } from "@/translations";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import merge from "lodash/merge";
import sortBy from "lodash/sortBy";
import union from "lodash/union";
import values from "lodash/values";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import uuid from "uuid/v4";
import { conditionalOptionsModal } from "../../../../../apps/marketplaceApp/components/PackageDefinition/MappingOption.modal";
import { t } from "../../../../../translations/translations.service";
import { getNotSkippedStepNames } from "../../../../../views/Package/package.utils";
import { CompositeSelect } from "../../../../CompositeSelect/CompositeSelect";
import {
    addConditionalFields,
    disableNextButton,
    getAllAvailableFields,
    getAvailableOptions,
    getSelectedType,
    getTypeLabel,
    resolveNewFieldValue,
} from "../../../packagesPanel.utils";
import { CurrencySettings } from "../CurrencySettings/CurrencySetting";
import { MultipleTitle } from "../MultipleTitle/MultipleTitle";
import PackageDataMappingLayout from "../PackageDataMappingLayout/PackageDataMappingLayout";
import { PackageTable } from "../PackageTable/PackageTable";
import { handleRowClassName } from "../PricePrameters/priceParemeters.utils";
import { SignificantFields } from "../SignificantFields/SignificantFields";
// assets
import { defaultPreviewCellProps } from "@/components/Packages/PackageTableDefinitionPanel/components/PackageDataMappingLayout/DataPreviewTable.component";
import styles from "../styles.less";
import { calculateExistingFields } from "./PackageMandatoryFields.utils";

const TRANSACTION_STEP = "create-datasource";

export const PackageMandatoryFields = ({
    globalState,
    componentState,
    step,
    accountId,
    onNext,
    onBack,
    changeState,
    onCancel,
    apiResult,
}) => {
    const confirmModal = useConfirmModal();

    const mandatoryFields = useMemo(
        () =>
            sortBy(
                addConditionalFields(
                    step.mandatoryFields,
                    filter(
                        step.conditionalFields,
                        cf => !isEmpty(cf.mandatoryFields),
                    ),
                    getNotSkippedStepNames(apiResult),
                    "mandatoryFields",
                ),
                ["label"],
            ),
        [apiResult, step],
    );

    const {
        fileInfo: tableExampleData,
        mandatoryFieldsMap,
        significantFields,
        optionalFields,
    } = globalState;

    const [renderId] = useState(uuid());
    const { initialized } = componentState;
    const canRender = renderId === initialized;

    useEffect(() => {
        changeState(
            {
                mandatoryFieldsMap: calculateExistingFields(
                    mandatoryFieldsMap,
                    mandatoryFields,
                    tableExampleData.columns,
                ),
                significantFields: calculateExistingFields(
                    significantFields,
                    step.significantFields,
                    tableExampleData.columns,
                ),
            },
            { initialized: renderId },
        );
    }, [tableExampleData.columns]);

    const handleOnNext = () =>
        conditionalOptionsModal({
            condition: step.showMappingOptions && step.skipOptionalFields,
            data: {
                mandatoryFieldsMap,
                significantFields,
            },
            accountId,
            onNext,
            onCancel,
            confirmModal,
        });

    const customColumns = ({ name, type }) => {
        return {
            ...defaultPreviewCellProps,
            label: createTitle(
                name,
                getSelectedType(
                    union(values(mandatoryFieldsMap), optionalFields),
                    name,
                ) || type,
            ),
            name,
            render: text => <div>{`${text}`}</div>,
        };
    };

    const createTitle = (name, type) => {
        return (
            <div>
                {name}
                <div className={styles.type}>{getTypeLabel(type)}</div>
            </div>
        );
    };

    const columns = [
        {
            name: "pfxFieldName",
            width: "60%",
            title: () => t("package-data-upload.label.mandatory-fields"),
            render: (text, record) => (
                <Fragment>
                    <H5 className={styles.headingColor}>{record.label}</H5>
                    <Text size="small" className={styles.descriptionColor}>
                        {record.description}
                    </Text>
                </Fragment>
            ),
        },
        {
            name: "valueFromFile",
            className: "column-valueFromFile",
            // TODO: fix padding in multiple title when moving advanced field editor icon
            title: () => (
                <MultipleTitle
                    mandatoryFields={mandatoryFields}
                    fileName={tableExampleData.fileName}
                />
            ),
            render: (text, record) => (
                <CompositeSelect
                    type={record.type}
                    input={record.value}
                    inputType={record.inputType}
                    formulaMapping={record.formulaMapping}
                    tableExampleData={tableExampleData}
                    onChange={({ input: value, inputType, formulaMapping }) =>
                        changeState({
                            mandatoryFieldsMap: resolveNewFieldValue(
                                record,
                                { value, inputType, formulaMapping },
                                tableExampleData.columns,
                                mandatoryFieldsMap,
                            ),
                        })
                    }
                >
                    {getAvailableOptions(
                        record,
                        tableExampleData.columns,
                        mandatoryFieldsMap,
                        significantFields,
                    )}
                </CompositeSelect>
            ),
        },
    ];

    return mandatoryFieldsMap && canRender ? (
        <PackageDataMappingLayout
            dataExample={tableExampleData}
            disableNextButton={disableNextButton(
                merge({}, mandatoryFieldsMap, significantFields),
            )}
            customColumns={customColumns}
            onNext={handleOnNext}
            onBack={onBack}
            onCancel={onCancel}
        >
            <T
                id="package-data-upload.mandatory-files.message"
                values={{ fileName: tableExampleData.fileName }}
            />
            <Gap size="small" />
            <PackageTable
                fields={values(mandatoryFieldsMap)}
                handleRowClassName={handleRowClassName}
                columns={columns}
            />
            {step.significantFields && canRender && (
                <SignificantFields
                    tableExampleData={tableExampleData}
                    availableFields={getAllAvailableFields(
                        mandatoryFieldsMap,
                        significantFields,
                        tableExampleData.columns,
                    )}
                    significantFields={significantFields}
                    setSignificantFields={s =>
                        changeState({ significantFields: s })
                    }
                    defaultCurrency={step.defaultCurrency}
                />
            )}
            <Gap />
            {TRANSACTION_STEP === step.type && (
                <CurrencySettings
                    currencySettings={globalState.currencySettings}
                    setCurrencySettings={settings =>
                        changeState({ currencySettings: settings })
                    }
                />
            )}
        </PackageDataMappingLayout>
    ) : (
        <div />
    );
};

PackageMandatoryFields.propTypes = {
    accountId: PropTypes.number.isRequired,
    globalState: PropTypes.object.isRequired,
    step: PropTypes.object.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    changeState: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    apiResult: PropTypes.object.isRequired,
};
