import {
    Button,
    ButtonMenu,
    Drawer,
    Forms,
    UnityLayout,
} from "@/components/DesignSystem/index";
import {
    fieldTypes,
    HEADER_WITH_PAGE_TITLE,
} from "@/components/DesignSystem/Table/constants";
import { hooks } from "@/components/DesignSystem/Table/Table.component";
import { useDic } from "@/components/Dic/useDic.hook";
import { getFieldByType } from "@/components/FeatureFlags/FeatureFlagValue";
import { useCurrentRef } from "@/components/hooks/useCurrentRef.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { isLoading, useQueryLoadable, waitForValue } from "@/modules/loadable";
import { SecurityContext } from "@/security/authorization";
import {
    FEATURE_FLAGS_EDIT,
    includesPermission,
} from "@/security/permission.utils";
import { t } from "@/translations";
import { ReactComponent as IconClose } from "@pricefx/unity-components/dist/es/icons/unicons/multiply.svg";
import { Alert } from "antd";
import { isEqual, noop, reduce } from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useMemo, useState } from "react";
import { TableLocalFiltered } from "../../TableLocalFiltered";
import { FF_ROW_HEIGHT } from "../constants";
import { FeatureFlagValue } from "../FeatureFlagValue";
import { useApplyAdminFeatureFlagsMutation } from "../loadables";
import DisableSubmitOnEnter from "./DisableSubmitOnEnter.component";
import MassEditModal from "./MassEdit.modal.component";
import NotAvailablePartitionsModal from "./NotAvailablePartitions.modal.component";
import "./PartitionsWithFlag.style.less";

export const PartitionsWithFlagPanel = ({
    selectedFeatureFlag,
    visible,
    onClose: onPassedClose,
    onUpdate,
}) => {
    const { axiosService } = useDic();
    const securityContext = useContext(SecurityContext);
    const canEdit = includesPermission(securityContext, [FEATURE_FLAGS_EDIT]);
    const applyFeatureFlagsMutation = useApplyAdminFeatureFlagsMutation({
        onSuccess: noop,
    });
    const columns = useMemo(
        () => [
            {
                type: fieldTypes.TEXT,
                label: t(
                    "feature-flag.paritions-with-flag.table.column.partitionName",
                ),
                name: "partitionName",
            },
            {
                type: fieldTypes.TEXT,
                label: t(
                    "feature-flag.paritions-with-flag.table.column.projectName",
                ),
                name: "projectName",
            },
            {
                label: t("feature-flag.paritions-with-flag.table.column.value"),
                name: "value",
                canSort: false,
                canFilter: false,
                render: (_, record) => {
                    return (
                        <FeatureFlagValue
                            enableHighlight
                            name={`${record.partitionId}`}
                            record={record.featureFlagDto}
                        />
                    );
                },
            },
        ],
        [],
    );

    const { addFields, formId, handleSubmit, setInitialValues, setValues } =
        Forms.useForm({
            onSubmit: async ({ values, initialValues }) => {
                const partitionsToUpdate = reduce(
                    values,
                    (result, value, key) => {
                        return isEqual(value, initialValues[key])
                            ? result
                            : result.concat(parseInt(key, 10));
                    },
                    [],
                );

                const data = partitionsToUpdate.map(partitionId => ({
                    params: { partitionId },
                    data: [
                        { ...selectedFeatureFlag, value: values[partitionId] },
                    ],
                }));

                return applyFeatureFlagsMutation.mutate(data).then(() => {
                    onUpdate([...touchedPartitions, ...partitionsToUpdate]);
                });
            },
        });

    const addFieldIdsRef = useCurrentRef(addFields);
    const partitionsWithFlagsResource = useQueryLoadable(
        () =>
            selectedFeatureFlag?.path
                ? axiosService
                      .get(
                          `/api/partition-assets/with-feature-flag/${selectedFeatureFlag?.path}`,
                      )
                      .then(({ data: result }) => {
                          const partitionIds = Object.keys(
                              result.partitionsWithResult,
                          );
                          const initialValues = Object.values(
                              result.partitionsWithResult,
                          )
                              .map(partition => ({
                                  [`${partition.partitionInfo.id}`]:
                                      getFieldByType(
                                          partition.featureFlagDto.type,
                                      ).from(partition.featureFlagDto.value),
                              }))
                              .reduce(
                                  (result, partitionItem) => ({
                                      ...result,
                                      ...partitionItem,
                                  }),
                                  {},
                              );

                          addFieldIdsRef.current?.(partitionIds);
                          setInitialValues(initialValues);
                          setValues(initialValues);
                          return {
                              partitionsWithResult: (
                                  Object.values(
                                      result.partitionsWithResult ?? {},
                                  ) ?? []
                              ).map(item => ({
                                  partitionId: item.partitionInfo.id,
                                  projectName: item.partitionInfo.projectName,
                                  partitionName:
                                      item.partitionInfo.partitionName,
                                  key: item.partitionInfo.id,
                                  featureFlagDto: item.featureFlagDto,
                              })),
                              partitionsWithErrors: (
                                  Object.values(
                                      result.partitionsWithErrors ?? {},
                                  ) ?? []
                              ).map(item => ({
                                  partitionId: item.partitionInfo.id,
                                  projectName: item.partitionInfo.projectName,
                                  partitionName:
                                      item.partitionInfo.partitionName,
                                  status: item.error,
                              })),
                          };
                      })
                : waitForValue(),
        [selectedFeatureFlag, setValues, setInitialValues],
    );

    const rowSelection = hooks.useRowSelection({
        disableOnRowClick: true,
    });

    const massEditModal = useVisibility(false);
    const notAvailablePartitionModal = useVisibility(false);

    const selectionContextMenu = useMemo(
        () =>
            canEdit
                ? [
                      {
                          label: t("general.mass-edit"),
                          onClick: massEditModal.show,
                      },
                  ]
                : undefined,
        [canEdit, massEditModal.show],
    );

    const isDataLoading = isLoading(partitionsWithFlagsResource.loadable);

    const [touchedPartitions, setTouchedPartitions] = useState([]);

    const onClose = () => {
        onUpdate(touchedPartitions);
        onPassedClose?.();
    };

    return (
        <>
            <Drawer
                visible={visible}
                onClose={onClose}
                width={600}
                closable={false}
                panel
            >
                <UnityLayout>
                    <UnityLayout.Header
                        size={4}
                        title={selectedFeatureFlag.name}
                        alert={
                            partitionsWithFlagsResource.loadable.valueMaybe()
                                ?.partitionsWithErrors.length ? (
                                <Alert
                                    banner
                                    showIcon
                                    type="warning"
                                    message={
                                        <>
                                            {t(
                                                "feature-flag.paritions-with-flag.info.not-all-available",
                                            )}
                                            <Button
                                                onClick={
                                                    notAvailablePartitionModal.show
                                                }
                                                type="link"
                                                size="small"
                                                label={t(
                                                    "feature-flag.paritions-with-flag.info.list-of-partititions",
                                                )}
                                            />
                                        </>
                                    }
                                />
                            ) : null
                        }
                        actionButtons={
                            <ButtonMenu
                                buttons={[
                                    {
                                        "data-test": "close-button",
                                        "data-test-type": "close-menu-item",
                                        icon: IconClose,
                                        onClick: onClose,
                                        tooltip: "common_close",
                                        type: "default",
                                    },
                                ]}
                            />
                        }
                    />
                    <UnityLayout.Content padding={[false, true]}>
                        <Forms.Form onSubmit={handleSubmit} formId={formId}>
                            <DisableSubmitOnEnter>
                                <TableLocalFiltered
                                    fixed
                                    restHeight={HEADER_WITH_PAGE_TITLE + 177}
                                    rowHeight={FF_ROW_HEIGHT}
                                    columns={columns}
                                    loading={isDataLoading}
                                    dataSource={
                                        partitionsWithFlagsResource.loadable.valueMaybe()
                                            ?.partitionsWithResult ?? []
                                    }
                                    pagination={false}
                                    rowKey="key"
                                    padding={false}
                                    rowSelection={rowSelection}
                                    selectionContextMenu={selectionContextMenu}
                                />
                            </DisableSubmitOnEnter>
                        </Forms.Form>
                    </UnityLayout.Content>
                    <UnityLayout.Footer
                        actionButtons={
                            <ButtonMenu
                                buttons={[
                                    {
                                        visible: canEdit,
                                        label: t("general.save"),
                                        type: "primary",
                                        formId,
                                        disabled: isDataLoading,
                                    },
                                    {
                                        label: t("general.cancel"),
                                        onClick: onClose,
                                        type: "text",
                                    },
                                ]}
                            />
                        }
                    />
                </UnityLayout>
            </Drawer>
            <MassEditModal
                selectedRowKeys={rowSelection.selectedRowKeys}
                selectedFeatureFlag={selectedFeatureFlag}
                visible={massEditModal.visible}
                onSave={updatedPartitions => {
                    massEditModal.hide();
                    partitionsWithFlagsResource.reload();
                    setTouchedPartitions(p => [...p, ...updatedPartitions]);
                }}
                onCancel={massEditModal.hide}
            />
            <NotAvailablePartitionsModal
                partitionsWithErrors={
                    partitionsWithFlagsResource.loadable.valueMaybe()
                        ?.partitionsWithErrors
                }
                visible={notAvailablePartitionModal.visible}
                onCancel={notAvailablePartitionModal.hide}
            />
        </>
    );
};

PartitionsWithFlagPanel.propTypes = {
    visible: PropTypes.bool,
    selectedFeatureFlag: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};
