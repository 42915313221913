import CustomerPartitionsList from "@/components/CustomerPartitions/CustomerPartitionsList";
import { useDic } from "@/components/Dic/useDic.hook";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { ACCOUNT_PARTITION_PROVISIONING_PERMISSIONS } from "@/security/permission.utils";
import React from "react";

export const CustomerPartitionsPage = () => {
    const {
        locationRouterService,
        accountAppLocations: { partitionNewLocation },
    } = useDic();

    const { accountId } = useAccountAppParams();

    useFullWidthTableLayout();

    useBreadcrumbButton({
        label: "customer-management.nav-item.create-partition",
        onClick: () =>
            locationRouterService.navigate(partitionNewLocation, {
                accountId,
            }),
        permissions: ACCOUNT_PARTITION_PROVISIONING_PERMISSIONS,
    });

    return <CustomerPartitionsList accountId={accountId} />;
};
