import FieldGridNotPadded from "@/components/DataUploads/SftpManagement/FieldGridNotPadded.component";
import { Alert, Button, Forms, Gap } from "@/components/DesignSystem";
import { Hidden } from "@/components/Mappers/ExportMapper/ExportMapperList";
import { DeleteRowButton } from "@/components/Mappers/MapperTableWithCustomization/components/DeleteRowButton";
import MapperInputType from "@/components/Mappers/form/MapperInputType";
import { ConvertorButton } from "@/components/Mappers/form/MapperRow";
import MapperRowConverterModal from "@/components/Mappers/form/MapperRowConverter.modal";
import { useConvertersQuery } from "@/components/Mappers/loadables";
import { InputType } from "@/components/Packages/PackageTableDefinitionPanel/constants";
import { useValueVisibility } from "@/components/hooks/useValueVisibility.hook";
import { t } from "@/translations";
import { useFieldValidation } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import { $values } from "@pricefx/unity-components/dist/es/components/Forms/selectors";
import {
    isError,
    isSuccess,
} from "@pricefx/unity-components/dist/es/components/Forms/validation";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import {
    fromPairs,
    get,
    getOr,
    identity,
    map,
    pipe,
    tap,
    toPairs,
} from "lodash/fp";
import React, { Suspense, useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import cx from "classnames";

import style from "@/components/DesignSystem/Forms/fields/RestrictedPassword/style.less";

export const LAYOUT_WITHOUT_MAX_LENGTH = [{}, {}, { width: 32 }];

const removeFp = index => array => array.filter((_, i) => i !== index);

const useListFieldValues = ({ formId, listName, subfieldName, omitIndex }) => {
    const otherRowsValues = pipe(
        $values,
        useRecoilValue,
        // tap(values => { console.log("all recoil values", { values, listName, subfieldName, }); }),
        getOr([], [listName]),
        map(get(subfieldName)),
        typeof omitIndex === "number" ? removeFp(omitIndex) : identity,
    )(formId);

    return useMemo(() => otherRowsValues, [otherRowsValues.join("-")]);
};

const useListFieldUniqueValidator = ({
    formId,
    listName,
    subfieldName,
    thisIndex,
    message = "Not unique",
}) => {
    const otherFieldsValues = useListFieldValues({
        formId,
        listName,
        subfieldName,
        omitIndex: thisIndex,
    });
    return useCallback(
        async (value, getBag) => {
            const isDuplicated = otherFieldsValues.find(
                otherValue => otherValue === value,
            );
            return isDuplicated ? Forms.error(message) : Forms.success();
        },
        [message, otherFieldsValues],
    );
};

const MapperRow = ({
    id,
    index,
    formId,
    listName,
    fieldProps,
    isOptional,
    readOnly,
    remove,
    getRowBag,
    getFieldName,
    converterRowModal,
    canBeRemoved,
}) => {
    const outputUniqueValidator = useListFieldUniqueValidator({
        formId,
        thisIndex: index,
        listName,
        subfieldName: "output",
        message: t("data-upload.mapper-table.validation-not-unique-output"),
    });
    const outputValidator = useMemo(
        () =>
            Forms.validators.failOnFirst([
                ...(isOptional ? [] : [Forms.pmValidators.isRequired]),
                outputUniqueValidator,
            ]),
        [outputUniqueValidator, isOptional],
    );

    const converterExpressionValue = Forms.useFieldValue({
        formId,
        name: getFieldName(id, "converterExpression"),
    });

    return (
        <>
            <Hidden {...fieldProps("id")} />
            <Hidden {...fieldProps("converterExpression")} />
            <Hidden {...fieldProps("converterExpressionReadOnly")} />
            <Hidden {...fieldProps("maxLength")} />
            <Forms.FieldGrid.Row key={id}>
                <Forms.Fields.Input
                    {...fieldProps("input")}
                    label={t("mapper-form.form.row.input")}
                    validator={
                        isOptional ? undefined : Forms.pmValidators.isRequired
                    }
                    disabled={readOnly}
                    addonBefore={
                        <MapperInputType
                            {...fieldProps("inputType")}
                            initialValue={InputType.BODY}
                            disabled={readOnly}
                        />
                    }
                    fieldSuffix={
                        <ConvertorButton
                            converterExpression={converterExpressionValue}
                            onConverterOpen={() =>
                                getRowBag(id).then(rowBag => {
                                    converterRowModal.show({
                                        getFieldName,
                                        rowId: id,
                                        ...rowBag.value,
                                    });
                                })
                            }
                            initialMaxLength={
                                fieldProps("maxLength").initialValue
                            }
                        />
                    }
                />

                <Forms.Fields.Input
                    {...fieldProps("output")}
                    label={t("mapper-form.form.row.output")}
                    validator={outputValidator}
                    disabled={readOnly}
                />
                <Forms.UIField name="actions">
                    <DeleteRowButton
                        disabled={readOnly || !canBeRemoved}
                        onClick={
                            () => remove(id)
                            // deleteRow(rowId)
                        }
                    />
                </Forms.UIField>
            </Forms.FieldGrid.Row>
        </>
    );
};

const ValidationResultInner = ({ name, formId, touched }) => {
    const validation = useFieldValidation({ formId, name });
    const valid = isSuccess(validation);
    const result = validation.value;
    const message = "Must have at least one row";

    console.log("[ValidationResultInner.rndr]", { validation, valid, result });

    return null;

    // return (
    //     <span
    //         key={message}
    //         className={cx(
    //             style.validation,
    //             style.errorColor,
    //             // touched && result && isError(result) && !valid
    //             //     ? style.errorColor
    //             //     : result && isSuccess(result)
    //             //     ? style.successColor
    //             //     : style.greyColor,
    //         )}
    //     >
    //         {message}
    //     </span>
    // );
};

const ValidationResult = ({ formId, name }) => {
    const field = Forms.useField({ formId, name });

    console.log("[ValidationResult.rndr]", { formId, name, field });

    return (
        <Suspense fallback={"Fallback"}>
            <ValidationResultInner
                name={name}
                formId={formId}
                touched={field.touched}
            />
        </Suspense>
    );
};

const MapperRows = ({
    instanceId,
    formId,
    readOnly,
    setValues,
    initialDefinition,
    isOptional,
    isSupportingMaxLengthProperty,
}) => {
    const listFieldName = "definition";
    const convertersResource = useConvertersQuery({ instanceId });
    const converterRowModal = useValueVisibility();
    const listValidator = useMemo(
        // () =>
        //     isOptional
        //         ? undefined
        //         : Forms.validators.failOnFirst([
        //               Forms.pmValidators.isRequired,
        //               Forms.pmValidators.createMinLengthValidation(1, {
        //                   map: identity,
        //               }),
        //           ]),
        () => async value => {
            // TODO: not called on row delete
            console.log("listValidator 1 >>>", { value, isOptional });
            const isValid = isOptional || (await value).length;
            console.log("listValidator 2 >>>", { isValid });
            return isValid
                ? Forms.success()
                : Forms.error("At least one row is required");
        },
        [isOptional],
    );

    console.log("[MapperRows.rndr]", {
        initialDefinition,
        isOptional,
    });

    return (
        <>
            <FieldGridNotPadded>
                <Forms.FieldGrid layout={LAYOUT_WITHOUT_MAX_LENGTH}>
                    <Forms.List
                        name={listFieldName}
                        initialValue={initialDefinition}
                        // initialValue={[]}
                        validator={listValidator}
                    >
                        {({
                            rows,
                            add,
                            remove,
                            getRowBag,
                            getFieldName,
                            ...rest
                        }) => (
                            <>
                                {!isOptional && !rows.length ? (
                                    <Alert
                                        type="error"
                                        message="At least one row is required"
                                    />
                                ) : null}
                                {rows.map(({ id, fieldProps }, index) => {
                                    // console.log("[row.rndr]", index, { rows, inputFieldProps: fieldProps("input"), inputTypeFieldProps: fieldProps("inputType"), getRowBag, getFieldName, rest, });
                                    return (
                                        <MapperRow
                                            key={id}
                                            id={id}
                                            index={index}
                                            formId={formId}
                                            listName={listFieldName}
                                            fieldProps={fieldProps}
                                            isOptional={isOptional}
                                            readOnly={readOnly}
                                            remove={remove}
                                            getRowBag={getRowBag}
                                            getFieldName={getFieldName}
                                            converterRowModal={
                                                converterRowModal
                                            }
                                            canBeRemoved={
                                                rows.length > 1 || isOptional
                                            }
                                        />
                                    );
                                })}
                                <Gap size="small" />
                                <Button
                                    icon={Plus}
                                    label={t("data-upload.mapper.add.field")}
                                    onClick={() => add()}
                                    disabled={readOnly}
                                />
                            </>
                        )}
                    </Forms.List>
                </Forms.FieldGrid>
            </FieldGridNotPadded>
            <ValidationResult name={listFieldName} formId={formId} />
            {/* <div style={{ marginBottom: "-0.8rem" }}>
                <Forms.Field
                    name={listFieldName}
                    as={Nothing}
                    label={null}
                    from={identity}
                    validator={listValidator}
                />
            </div> */}

            <MapperRowConverterModal
                visible={converterRowModal.visible}
                value={converterRowModal.value}
                onApply={innerValues => {
                    const values = pipe(
                        toPairs,
                        map(([key, value]) => [
                            converterRowModal.value?.getFieldName(
                                converterRowModal.value?.rowId,
                                key,
                            ),
                            value,
                        ]),
                        fromPairs,
                    )(innerValues);
                    setValues(values);
                    converterRowModal.hide();
                }}
                onCancel={converterRowModal.hide}
                converters={convertersResource.loadable.valueMaybe()}
                readOnly={readOnly}
                isSupportingMaxLengthProperty={isSupportingMaxLengthProperty}
            />
        </>
    );
};

export default MapperRows;
