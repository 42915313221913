import { Forms } from "@/components/DesignSystem";
import { t } from "@/translations";
import * as React from "react";
import { mapPartitionsToOptions } from "../../helpers";

export const PartitionSelectForm = ({ partitions, formId, handleSubmit }) => {
    const partitionsOptions = mapPartitionsToOptions(partitions);
    return (
        <Forms.Form formId={formId} onSubmit={handleSubmit}>
            <Forms.FieldGroup width="max" inputWidth="max">
                <Forms.Fields.Select
                    required
                    name="partitionId"
                    label={t("general.partition")}
                    placeholder={t("partitionSelectForm.partition.placeholder")}
                    allowClear={false}
                    showSearch
                    validator={Forms.pmValidators.isRequired}
                    options={partitionsOptions}
                />
            </Forms.FieldGroup>
        </Forms.Form>
    );
};
