import { useDic } from "@/components/Dic/useDic.hook";
import {
    mapInstancesToOptions,
    mapPartitionsToOptions,
} from "@/components/Marketplace/helpers";
import { useSetErrorAlert } from "@/components/PageLayout/useSetAlert.hook";
import {
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { getErrorMessageFromError } from "@/utils/state/error.utils";

export const useIntegrationsOptionsForDeploy = ({
    accountId,
    canFetch = false,
}) => {
    const { templateService } = useDic();
    const setErrorAlert = useSetErrorAlert();

    return useQueryLoadable(
        async () =>
            canFetch
                ? templateService
                      .fetchIntegrationsForDeploy(accountId)
                      .then(mapInstancesToOptions)
                      .catch(() =>
                          setErrorAlert(
                              t(
                                  "templates-management.error.instances-unavailable",
                              ),
                          ),
                      )
                : pendingPromise(),
        [accountId, canFetch, setErrorAlert, templateService],
    );
};

export const usePartitionsOptionsForDeploy = ({
    accountId,
    canFetch = false,
}) => {
    const { templateService } = useDic();
    const setErrorAlert = useSetErrorAlert();
    return useQueryLoadable(
        async () =>
            canFetch
                ? templateService
                      .fetchPartitionForDeploy(accountId)
                      .then(mapPartitionsToOptions)
                      .catch(() =>
                          setErrorAlert(
                              t(
                                  "templates-management.error.partitions-unavailable",
                              ),
                          ),
                      )
                : pendingPromise(),
        [accountId, canFetch, setErrorAlert, templateService],
    );
};

export const useTemplateVersionsOptions = ({
    packageName,
    accountId,
    canFetch = false,
}) => {
    const { templateService } = useDic();
    const setErrorAlert = useSetErrorAlert();
    return useQueryLoadable(
        async () =>
            canFetch
                ? templateService
                      .fetchTemplateVersions(packageName, accountId)
                      .then(versions =>
                          versions.reverse().map(({ version }) => ({
                              label: version,
                              value: version,
                          })),
                      )
                      .catch(() =>
                          setErrorAlert(
                              t(
                                  "templates-management.error.versionsUnavailable",
                              ),
                          ),
                      )
                : pendingPromise(),
        [canFetch, templateService, packageName, accountId, setErrorAlert],
    );
};

export const useAdminTemplateVersions = ({
    uniqueName,
    canFetch = !!uniqueName,
}) => {
    const { templateAdminService } = useDic();

    return useQueryLoadable(
        async () =>
            canFetch
                ? templateAdminService
                      .fetchTemplateVersions(uniqueName)
                      .then(getData)
                : pendingPromise(),
        [canFetch, templateAdminService, uniqueName],
    );
};

export const useRequestTermination = ({
    accountId,
    partitionId,
    deploymentId,
    afterSuccess,
}) => {
    const { packageService } = useDic();

    return useMutationLoadableWithNotification(
        async () =>
            packageService
                .requestTermination({
                    accountId,
                    partitionId,
                    deploymentId,
                })
                .then(() => {
                    afterSuccess();
                }),
        [packageService, accountId, partitionId, deploymentId, afterSuccess],
        "package.import-info-modal.termination.success",
        error => getErrorMessageFromError(error.response.data),
    );
};

export const useRequestRollbackMutation = ({
    accountId,
    partitionId,
    deploymentId,
    afterSuccess,
}) => {
    const { packageService } = useDic();

    return useMutationLoadableWithNotification(
        async rollbackOptions =>
            packageService
                .requestRollback({
                    accountId,
                    partitionId,
                    deploymentId,
                    rollbackOptions,
                })
                .then(() => {
                    afterSuccess();
                }),
        [packageService, accountId, partitionId, deploymentId, afterSuccess],
        "package.import-info-modal.rollback.success",
        error => getErrorMessageFromError(error.response.data),
    );
};

export const useRollbackMutation = ({
    accountId,
    partitionId,
    deploymentId,
    afterSuccess,
}) => {
    const { packageService } = useDic();

    return useMutationLoadableWithNotification(
        async rollbackOptions =>
            packageService
                .rollbackDeployment({
                    accountId,
                    partitionId,
                    deploymentId,
                    rollbackOptions,
                })
                .then(() => {
                    afterSuccess();
                }),
        [packageService, accountId, partitionId, deploymentId, afterSuccess],
        "package-deployment.action.delete-deployment.info",
        error => getErrorMessageFromError(error.response.data),
    );
};
