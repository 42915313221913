import { axiosService } from "../axios";

export const imVersionsService = {
    fetchVersions() {
        return axiosService.get(`/api/im-versions`);
    },
    updateVersion(version) {
        return axiosService.post(`/api/im-versions`, version);
    },
    getAvailabeleInfraVersions() {
        return axiosService.get(`/api/im-versions/available-infra-versions`);
    },
};
