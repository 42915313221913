import { Button, Image, P } from "@/components/DesignSystem";
import { HEADER_HEIGHT_WITH_BREADCRUMB } from "@/components/DesignSystem/Table/constants";
import { FeatureFlags } from "@/components/FeatureFlags/FeatureFlags.component";
import { ACCOUNT_FEATURE_FLAGS_EDIT } from "@/security/permission.utils";
import { t } from "@/translations";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import { get } from "lodash/fp";
import React, { useCallback } from "react";
import featureFlagPlaceholderIcon from "../../assets/img/feature-flags-add-icon.png";

const fetcher = (axiosService, { projectId, partitionId, configurationName }) =>
    axiosService
        .get(
            `/api/projects/${projectId}/partition-assets/${partitionId}/feature-flags/${configurationName}`,
        )
        .then(get("data"));

const getEmptyScreenComponent = ({ onChangeSet }) => (
    <div className="pmFeatureFlags-emptyScreen">
        <Image alt="" src={featureFlagPlaceholderIcon} />
        <P>{t("feature-flags.partition.add-set.desc")}</P>
        <Button
            type="primary"
            label={t("feature-flags.partition.add-set.button")}
            onClick={onChangeSet}
            icon={Plus}
        />
    </div>
);

const getBreadcrumbButtons = ({
    submit,
    loading,
    areFeatureFlagsLoaded,
    onChangeSet,
    onCopy,
}) =>
    areFeatureFlagsLoaded
        ? [
              {
                  label: "feature-flags.apply-settings",
                  onClick: submit,
                  permissions: [ACCOUNT_FEATURE_FLAGS_EDIT],
                  config: {
                      disabled: loading,
                  },
              },
              {
                  label: "feature-flags.partition.copy-all.button",
                  onClick: onCopy,
                  config: {
                      disabled: loading,
                  },
              },
              {
                  label: "feature-flags.partition.change-set.button",
                  onClick: onChangeSet,
                  config: {
                      disabled: loading,
                  },
              },
          ]
        : null;

export const PartitionFeatureFlagsPage = () => {
    return (
        <FeatureFlags
            fetcher={fetcher}
            getBreadcrumbButtons={useCallback(getBreadcrumbButtons, [])}
            getEmptyScreenComponent={useCallback(getEmptyScreenComponent, [])}
            displaySingleSet={true}
            restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
        ></FeatureFlags>
    );
};
