import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { ConnectionsMapper } from "../../../../Mappers/MapperTableWithCustomization/ConnectionsMapper";

export const GeneralMapper = ({ instanceId, apiResult, onNext }) => {
    const {
        currentStepData: {
            mapper,
            sourceConnectionTypes,
            targetConnectionTypes,
            connectionId,
        },
    } = apiResult;

    const handleOnNext = useCallback(
        ({
            mapper,
            sourceParams,
            targetParams,
            convertEmptyStringToNull = false,
        }) => {
            onNext({
                mapper,
                mapperProperties: {
                    aggregate: false,
                    convertEmptyStringToNull,
                },
                params: {
                    objectType: targetParams.entityType,
                    objectName: targetParams.entityName,
                    usePricingParameterName: false,
                    sourceConnection: sourceParams.connection.connectionName,
                    targetConnection: targetParams.connection.connectionName,
                    sourceUri: sourceParams.uri, // "/services/data/v48.0/query/",
                    targetUri: targetParams.uri,
                    sourceMetadataUri: sourceParams.metadataUri,
                    targetMetadataUri: targetParams.metadataUri,
                },
            });
        },
        [onNext],
    );

    return (
        <ConnectionsMapper
            initMapper={mapper}
            sourceConnectionTypes={sourceConnectionTypes}
            targetConnectionTypes={targetConnectionTypes}
            instanceId={instanceId}
            submitButtonText={t("package-data-upload.button.next")}
            onSubmit={handleOnNext}
            initialTargetConnectionId={connectionId}
        />
    );
};

GeneralMapper.propTypes = {
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    instanceId: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
    ]).isRequired,
    step: PropTypes.object.isRequired,
    apiResult: PropTypes.object.isRequired,
};
