import { message } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback, useEffect, useState } from "react";
import { PartitionClonePage } from "./PartitionClonePage.component";

export function PartitionClonePageContainer() {
    const {
        locationRouterService,
        partitionRequestService,
        projectPartitionApiService,
        accountAppLocations: { partitionsLocation },
    } = useDic();
    const onCancel = useCallback(() => {
        locationRouterService.navigate(partitionsLocation);
    }, [locationRouterService, partitionsLocation]);
    useSetBackButtonAction(onCancel);

    const { accountId, partitionId } = useAccountAppParams();

    const [clustersList, setClustersList] = useState([]);
    const [contactsList, setContactsList] = useState([]);
    const [partitionNamesList, setPartitionNamesList] = useState([]);
    const [purposeList, setPurposeList] = useState([]);

    const onSubmit = values => {
        partitionRequestService
            .submitCloneRequest({
                accountId,
                partitionId,
                body: values,
            })
            .then(() => {
                message.success(t("partition-clones.success-message"));
                locationRouterService.navigate(partitionsLocation);
            })
            .catch(response => {
                message.error(response.response.data.message);
            });
    };

    const onPurposeChange = ({ value: purpose }) => {
        setClustersList([]);
        // setPurpose(purpose);
        if (!purpose) return;
        fetchClusters(accountId, purpose);
    };

    const fetchClusters = (projectId, purpose) =>
        partitionRequestService
            .fetchClusters(projectId, purpose, { partitionId })
            .then(res => setClustersList(res.data));

    const fetchContacts = projectId =>
        partitionRequestService
            .fetchContacts(projectId)
            .then(res => setContactsList(res.data));

    const fetchPartitionNames = projectId =>
        projectPartitionApiService
            .getPartitions(projectId)
            .then(data => data.map(partition => partition.partitionName))
            .then(partitionNamesList =>
                setPartitionNamesList(partitionNamesList),
            );

    const fetchPurposes = projectId =>
        partitionRequestService
            .fetchUsages(projectId)
            .then(res => setPurposeList(res.data));

    useEffect(() => {
        fetchPurposes(accountId);
        fetchContacts(accountId);
        fetchPartitionNames(accountId);
    }, [accountId]);

    return (
        <PartitionClonePage
            accountId={accountId}
            clustersList={clustersList}
            contactsList={contactsList}
            onCancel={onCancel}
            onPurposeChange={onPurposeChange}
            onSubmit={onSubmit}
            partitionId={partitionId}
            partitionNamesList={partitionNamesList}
            purposeList={purposeList}
        />
    );
}
