import { toVersionNumber } from "@/utils/versionUtils";
import qs from "qs";
import { axiosService } from "../axios";

const qsOptions = { addQueryPrefix: true };

export const clusterService = {
    fetchClusters(page, size, sort, filter) {
        const updatedSort = toVersionNumber(
            sort,
            "releaseName",
            "releaseNameNumber",
        );
        return axiosService.post(
            updatedSort
                ? `/api/clusters?page=${page}&size=${size}&sort=${updatedSort}`
                : `/api/clusters?page=${page}&size=${size}`,
            filter,
        );
    },
    fetchClusterNodes(clusterName, { filter, ...options }) {
        return axiosService.post(
            `/api/clusters/${clusterName}/configuration${qs.stringify(
                options,
                qsOptions,
            )}`,
            filter,
        );
    },
    updateClusterConfiguration(clusterName, update) {
        return axiosService.post(
            `/api/clusters/${clusterName}/configuration/update`,
            update,
        );
    },
    fetchClusterPartitions(clusterName) {
        return axiosService.get(`/api/clusters/${clusterName}/partitions`);
    },
    editClusterUpgradeDuration(data) {
        return axiosService.post("/api/clusters/upgrade-duration", data);
    },
    checkClusterEditability(clusterName) {
        return axiosService.get(`/api/clusters/${clusterName}/editability`);
    },
};
