import { Gap } from "@/components/DesignSystem";
import { LoadablePropType, LoadableRenderer } from "@/modules/loadable";
import { getErrorMessageFromError } from "@/utils/state/error.utils";
import React from "react";
import ConditionalErrorAlert from "../../../Error/ConditionalErrorAlert";

export const OptionsLoadableErrors = ({ loadable }) => (
    <LoadableRenderer
        loadable={loadable}
        hasValue={() => null}
        loading={() => null}
        hasError={errors =>
            [].concat(errors ?? []).map(error => (
                <React.Fragment key={error}>
                    <Gap size="small" />
                    <ConditionalErrorAlert
                        error={getErrorMessageFromError(error) || error}
                    />
                    <Gap size="small" />
                </React.Fragment>
            ))
        }
    />
);

OptionsLoadableErrors.propTypes = {
    loadable: LoadablePropType(),
};
