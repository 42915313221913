import { useDic } from "@/components/Dic/useDic.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import {
    crudSuccess,
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import get from "lodash/fp/get";
import isEmpty from "lodash/fp/isEmpty";
import map from "lodash/fp/map";
import pipe from "lodash/fp/pipe";

export const useAtlassianGroupsResource = () => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService.get(`/api/admin/atlassian/groups`).then(get("data")),
        [axiosService],
    );
};

export const useDeleteAtlassianGroupResource = ({ afterSuccess }) => {
    const { axiosService } = useDic();

    return useMutationLoadableWithNotification(
        async ({ name }) => {
            const { data } = await axiosService.delete(
                `/api/admin/atlassian/groups/${name}`,
            );
            afterSuccess(data);
            return { name, ...data };
        },
        [afterSuccess, axiosService],
        crudSuccess("Atlassian Group", "name", "D"),
    );
};

export const useAtlassianGroupDetail = ({ record, setValues, setTouched }) => {
    const { axiosService } = useDic();
    const isEdit = !isEmpty(record);

    const groupDetailResource = useQueryLoadable(async () => {
        if (!record) return pendingPromise();
        if (!isEdit) return {};
        return axiosService
            .get(`/api/admin/atlassian/groups/${record.name}`)
            .then(get("data"));
    }, [record, axiosService, isEdit]);
    const initialValues = groupDetailResource.loadable.valueMaybe();

    useSetValidatedInitialValues(
        {
            initialValues,
            setValues: setValues,
            setTouched: setTouched,
        },
        [initialValues],
    );

    return { loadableGroupDetail: groupDetailResource.loadable, isEdit };
};

const accountToOption = ({ id, name }) => ({ value: id, label: name });
export const useAdminAccountOptionsResource = ({ canFetch }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            canFetch
                ? axiosService
                      .get(`/api/admin/projects`)
                      .then(pipe(get("data"), map(accountToOption)))
                : pendingPromise(),
        [canFetch, axiosService],
    );
};

export const usePostAtlassianGroupResource = ({ afterSuccess, isEdit }) => {
    const { axiosService } = useDic();
    return useMutationLoadableWithNotification(
        async values => {
            const { data } = await axiosService.post(
                `/api/admin/atlassian/groups${isEdit ? `/${values.name}` : ""}`,
                values,
            );
            afterSuccess(data);
            return { ...values, ...data }; // API not returning created/updated entity
        },
        [isEdit, afterSuccess, axiosService],
        crudSuccess("Atlassian Group", "name", isEdit ? "U" : "C"),
    );
};

export const useAtlassianUsersResource = ({ canFetch }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            canFetch
                ? axiosService
                      .get(`/api/admin/atlassian/users`)
                      .then(get("data"))
                : pendingPromise(),
        [canFetch, axiosService],
    );
};
