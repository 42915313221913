import { Button, Forms, Gap } from "@/components/DesignSystem";
import NewFilterModal from "@/components/Filters/form/NewFilter.modal";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { useIncludesRightsForPartition } from "@/components/hooks/useIncludesRightsForPartition.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { isLoading, useMapLoadableMemoized } from "@/modules/loadable/index";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import PropTypes from "prop-types";
import React from "react";
import { useInstanceRepoFiltersQuery } from "../../../../Filters/loadables";
const { SubmitButton, useForm, Form, Fields, pmValidators } = Forms;

export const IntegrationFilter = ({ instanceId, onNext, onBack, step }) => {
    const filtersListResource = useInstanceRepoFiltersQuery({
        instanceId,
    });

    const filtersOptionsLoadable = useMapLoadableMemoized(
        filtersListResource.loadable,
        filters =>
            filters.map(filter => ({
                label: filter.name,
                value: filter.name,
            })),
    );

    const { formId, handleSubmit, setValues } = useForm({
        onSubmit: () =>
            onNext({
                name: step.name,
                type: step.type,
                params: {
                    filterName,
                },
            }),
    });

    const newFilterModal = useVisibility(false);

    const hasEditRights = useIncludesRightsForPartition(
        INTEGRATION_EDIT_PERMISSIONS,
    );
    const filterName = useFieldValue({ formId, name: "filterName" });
    return (
        <>
            <Form formId={formId} onSubmit={handleSubmit}>
                <Fields.Select
                    label={t("package.integration-filter.filter-name")}
                    name="filterName"
                    required
                    validator={pmValidators.isRequired}
                    {...getLoadableSelectProps(filtersOptionsLoadable)}
                />
                {hasEditRights && (
                    <Button
                        size="small"
                        icon={Plus}
                        label={t("filter-list.button.new")}
                        onClick={newFilterModal.show}
                    />
                )}
                <Gap />
                <SubmitButton
                    loading={isLoading(filtersListResource)}
                    type="primary"
                    label={t("package-data-upload.button.next")}
                />
                <BackButton onBack={onBack} />
            </Form>
            <NewFilterModal
                visible={newFilterModal.visible}
                filterId="new"
                instanceId={instanceId}
                onSave={({ filterName }) => {
                    filtersListResource.reload();
                    newFilterModal.hide();
                    setValues({ filterName });
                }}
                onCancel={newFilterModal.hide}
            />
        </>
    );
};

IntegrationFilter.propTypes = {
    step: PropTypes.object.isRequired,
    instanceId: PropTypes.number.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
};
