import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import {
    fieldTypes,
    HEADER_HEIGHT_WITH_BREADCRUMB,
} from "@/components/DesignSystem/Table/constants";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { useHasIntegrationPermission } from "@/security/hooks/useHasIntegrationPermission.hook";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { TableWithPreferencesManagement } from "../../TableWithPreferences/TableWithPreferencesManagement.container";
import {
    useApplicationPropertiesQuery,
    useLoadableTableProps,
} from "./loadables";
import { PropertiesEditorModal } from "./PropertiesEditorModal";

const preferencesType = preferencesTypes.INTEGRATION_APPLICATION_PROPERTIES;

const columns = [
    {
        type: fieldTypes.TEXT,
        label: t("instance-settings.application-properties.columns.name"),
        name: "name",
    },
    // {
    //     type: fieldTypes.TEXT,
    //     label: t("instance-settings.application-properties.columns.createdBy"),
    //     name: "createdBy"
    // },
    // {
    //     type: fieldTypes.TEXT,
    //     label: t("instance-settings.application-properties.columns.createdAt"),
    //     name: "createdAt"
    // },
    // {
    //     type: fieldTypes.TEXT,
    //     label: t("instance-settings.application-properties.columns.lastEditBy"),
    //     name: "lastEditBy"
    // },
    // {
    //     type: fieldTypes.TEXT,
    //     label: t("instance-settings.application-properties.columns.lastEditAt"),
    //     name: "lastEditAt"
    // }
];

export const ApplicationProperties = ({
    accountId,
    instanceId,
    visible = true,
}) => {
    const readOnly = !useHasIntegrationPermission({
        accountId,
        instanceId,
        permissions: INTEGRATION_EDIT_PERMISSIONS,
    });
    const applicationPropertiesQuery = useApplicationPropertiesQuery({
        instanceId,
        canFetch: !!instanceId,
    });
    const loadableTableProps = useLoadableTableProps(
        applicationPropertiesQuery.loadable,
    );
    const modalControls = useDetailDrawerState();
    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t(
                            readOnly ? "general.view" : "general.view-edit",
                        ),
                        onClick: modalControls.show,
                    },
                ]}
            />
        ),
        [modalControls.show, readOnly],
    );

    return (
        <>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                columns={columns}
                fixed
                restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
                rowKey="name"
                datasetSlicing="local"
                preferencesType={preferencesType}
                padding={false}
                visible={visible}
                {...loadableTableProps}
            />
            <PropertiesEditorModal
                visible={!!modalControls.record}
                onClose={modalControls.hide}
                afterSuccess={modalControls.hide}
                name={modalControls.record?.name}
                instanceId={instanceId}
                readOnly={readOnly}
            />
        </>
    );
};

ApplicationProperties.propTypes = {
    instanceId: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
    visible: PropTypes.bool,
};
