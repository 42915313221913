import { useDic } from "@/components/Dic/useDic.hook";
import { useMemoByDeepEquality } from "@/components/hooks/useMemoByDeepEquality.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import React from "react";
import { AdminAssetsTable } from "./AdminAssetsTable.component";

export const AdminAssetsTableContainer = React.memo(
    function AdminAssetsTableContainer({
        hasRights,
        setHasRights,
        assetsWithRightsFetchParams,
        ...props
    }) {
        const { adminAssetRoleApiService } = useDic();
        const params = hasRights ? assetsWithRightsFetchParams : {};
        const fetchParams = useMemoByDeepEquality(params);

        const [tableProps] = useFetchPage(
            (page, size, sort = "name,asc", filter) =>
                adminAssetRoleApiService.fetchProjects(
                    page,
                    size,
                    sort,
                    filter,
                    fetchParams,
                ),
            [fetchParams],
        );

        return (
            <AdminAssetsTable
                assetType="project"
                {...tableProps}
                {...props}
                hasRights={hasRights}
                setHasRights={setHasRights}
                fetchParams={fetchParams}
            />
        );
    },
);
