import { mapLoggersToTable } from "@/components/Integrations/Dashboard/Tabs/Loggers/LoggersTable.component";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { actuatorsService } from "@/services/actuators.service";
import React, { useEffect, useState } from "react";
import { loggersAdministrationColumns } from "./LoggersAdministrationTable.columns";

const preferencesType = preferencesTypes.ADMIN_LOGGERS_LIST_TABLE;

export const LoggersAdministrationTable = () => {
    const [loggers, setLoggers] = useState([]);
    const [levels, setLevels] = useState([]);

    useEffect(() => {
        fetchLoggers();
    }, []);

    const handleLevelChange = (component, level) => {
        actuatorsService.setLoggerLevel(component, level).then(() => {
            fetchLoggers();
        });
    };

    const fetchLoggers = () => {
        actuatorsService.fetchLoggers().then(response => {
            setLoggers(mapLoggersToTable(response.data.loggers));
            setLevels(response.data.levels);
        });
    };

    return (
        <TableWithPreferencesManagement
            columns={loggersAdministrationColumns(levels, handleLevelChange)}
            dataSource={loggers}
            fixed
            rowKey="component"
            datasetSlicing="local"
            preferencesType={preferencesType}
        />
    );
};
