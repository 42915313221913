import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import ProcessingTime from "@/components/DateTime/ProcessingTime";
import {
    fieldTypes,
    HEADER_WITH_PAGE_TITLE,
} from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { useSetPageTitle } from "../PageLayout/useSetPageTitle.hook";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";

const preferencesType = preferencesTypes.TASKS_LIST;

export const taskStatuses = {
    STARTED: "STARTED",
    DONE: "DONE",
    FAILED: "FAILED",
    CANCELLED: "CANCELLED",
};

const TasksTable = ({ status, loading, tasks }) => {
    const filteredTasks = useMemo(() => {
        return !status ? tasks : tasks.filter(t => t.status === status);
    }, [tasks, status]);

    useSetPageTitle(t(`tasks.menu.${status}`));

    return (
        <TableWithPreferencesManagement
            rowKey="id"
            fixed
            restHeight={HEADER_WITH_PAGE_TITLE}
            loading={loading}
            dataSource={filteredTasks}
            datasetSlicing="local"
            preferencesType={preferencesType}
            columns={[
                {
                    type: fieldTypes.TEXT,
                    label: t("task-table.headers.type"),
                    name: "type",
                },
                {
                    type: fieldTypes.DATETIME,
                    label: t("task-table.headers.start"),
                    name: "createdAt",
                    render: text => (
                        <FormattedDateTime>{text}</FormattedDateTime>
                    ),
                },
                {
                    type: fieldTypes.DATETIME,
                    label: t("task-table.headers.end"),
                    name: "updatedAt",
                    render: text => {
                        return <FormattedDateTime>{text}</FormattedDateTime>;
                    },
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("task-table.headers.initiated-by"),
                    name: "createdBy",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("task-table.headers.took"),
                    name: "took",
                    canFilter: false,
                    render: (text, record) => (
                        <ProcessingTime
                            from={record.createdAt}
                            to={record.updatedAt}
                        />
                    ),
                },
            ]}
        />
    );
};

TasksTable.propTypes = {
    status: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    tasks: PropTypes.array.isRequired,
};

export default TasksTable;
