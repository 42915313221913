import React from "react";
import Moment from "react-moment";

const FormattedDateTime = ({ children, fromNow, utcStr }) => {
    const props = fromNow
        ? { fromNow: true }
        : { format: "DD/MM/YYYY HH:mm:ss" };

    if (!children && !utcStr) return null;

    if (utcStr)
        return (
            <Moment {...props}>
                {utcStr.endsWith("Z") ? utcStr : utcStr + "Z"}
            </Moment>
        );

    return <Moment {...props}>{children}</Moment>;
};

export default FormattedDateTime;
