import { usePackagesHeaderSteps } from "@/components/PageLayout/usePackagesHeaderSteps.hook";
import PropTypes from "prop-types";
import { useEffect } from "react";

const LayoutWithStepper = ({ steps, currentStepIndex }) => {
    const { setHeaderSteps } = usePackagesHeaderSteps();

    useEffect(() => {
        if (steps?.length > 1) {
            setHeaderSteps({
                current: currentStepIndex,
                steps: steps.map(step => ({
                    title: step.stepLabel ? step.stepLabel : step.name,
                })),
            });
        }
    }, [currentStepIndex, steps, setHeaderSteps]);

    return null;
};

LayoutWithStepper.propTypes = {
    stepper: PropTypes.node.isRequired,
};

export default LayoutWithStepper;
