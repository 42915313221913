export class BusinessRolesApiMapper {
    /**
     *
     * @type {BusinessRoleApiMapper}
     */
    businessRoleApiMapper = null;

    constructor({ businessRoleApiMapper }) {
        this.businessRoleApiMapper = businessRoleApiMapper;
    }

    decode(apiResponse) {
        return apiResponse.map(br => this.businessRoleApiMapper.decode(br));
    }
}
