import { axiosService } from "../axios";
import { buildPageableUrl } from "../utils/urlUtils";

export const templateAdminService = {
    fetchTemplates(page, size, sort, filter) {
        return axiosService.post(
            buildPageableUrl("/api/admin/templates", page, size, sort),
            filter,
        );
    },

    getTemplateTags() {
        return axiosService.get(`/api/admin/templates/tags`);
    },

    fetchTemplate(uniqueName) {
        return axiosService.get(`/api/admin/templates/${uniqueName}`);
    },

    fetchTemplateVersions(uniqueName) {
        return axiosService.get(`/api/admin/templates/${uniqueName}/versions`);
    },

    changeTemplateVersionPublishing(uniqueName, version, publish) {
        return axiosService.post(
            `/api/admin/templates/${uniqueName}/versions/${version}`,
            {
                publish,
            },
        );
    },

    deleteTemplateVersion(uniqueName, version) {
        return axiosService.delete(
            `/api/admin/templates/${uniqueName}/versions/${version}`,
        );
    },

    updateTemplateVisibility(uniqueName, visibility) {
        return axiosService.post(`/api/admin/templates/${uniqueName}`, {
            visibility,
        });
    },

    editTemplate(uniqueName, data) {
        return axiosService.patch(`/api/admin/templates/${uniqueName}`, data);
    },

    getTemplateUsers(uniqueName) {
        return axiosService.get(`/api/admin/templates/${uniqueName}/users`);
    },

    setTemplateUsers(uniqueName, users) {
        return axiosService.put(
            `/api/admin/templates/${uniqueName}/users`,
            users,
        );
    },

    getTemplateAccounts(uniqueName) {
        return axiosService.get(`/api/admin/templates/${uniqueName}/accounts`);
    },

    setTemplateAccounts(uniqueName, accounts) {
        return axiosService.post(
            `/api/admin/templates/${uniqueName}/accounts`,
            accounts,
        );
    },

    getTemplateAvailableAccounts(uniqueName) {
        return axiosService.get(
            `/api/admin/templates/${uniqueName}/available-accounts`,
        );
    },
};
