import { values } from "lodash";
import uuid from "uuid/v4";
import { conformToMapping } from "../../CalculatedFieldTable/FieldCustomizationTabs.component";
import { convertToNumbersForFormula } from "../../Packages/PackageTableDefinitionPanel/components/PackageMandatoryFields/PackageMandatoryFields.utils";
import {
    InputType,
    ParsedType,
} from "../../Packages/PackageTableDefinitionPanel/constants";
import { converterExpressions } from "../../Packages/PackageTableDefinitionPanel/stateReducer";
import { curryN } from "lodash/fp";

const renameFormulaSymbols = (row, tableData) => {
    if (row.inputType !== InputType.FORMULA) return {};

    const conformed = conformToMapping({
        input: row.input,
        formulaMapping: convertToNumbersForFormula(tableData),
        previousFormulaMapping: row.formulaMapping,
    });
    const mapping =
        conformed.previousMapping ||
        conformed.formulaMapping.concat(conformed.missingMapping || []);
    console.log("renameFormulaSymbols", { row, tableData, conformed, mapping });
    return {
        input: conformed.input,
        formulaMapping: mapping,
    };
};

export const addComputableMapperProperties = (
    definition,
    outputOptions,
    tableData,
) => {
    const outputOptionsByValue = Object.fromEntries(
        outputOptions.map(option => [option.value, option]),
    );

    return definition.map(row => ({
        id: uuid(),
        ...row,
        outputType: outputOptionsByValue[row.output]?.type ?? "",
        ...renameFormulaSymbols(row, tableData),
    }));
};

const addConversion = (mapperField, inputField, outputField) => {
    // TODO: when api will provide parserConfig (on i/o field level?)
    // call conformToOutputType or add similar logic
    console.log("TODO: add converter/groovy when api will be ready", {
        mapperField,
        inputField,
        outputField,
    });

    return {
        id: mapperField.id,
        input: mapperField.input,
        inputType: mapperField.inputType,
        output: mapperField.output,
        outputType: mapperField.outputType,
        converterExpression: mapperField.converterExpression,
    };
};

const applyConversions =
    (flatInputOptionsByValue, flatOutputOptionsByValue) => field => {
        const inputField = flatInputOptionsByValue[field.input];
        const outputField = flatOutputOptionsByValue[field.output];

        if (
            field.inputType?.toLowerCase() !== "body" ||
            !inputField?.type ||
            !outputField?.type ||
            inputField.type.toLowerCase() === outputField.type.toLowerCase()
        )
            return {
                id: field.id,
                input: field.input,
                inputType: field.inputType,
                output: field.output,
                outputType: field.outputType,
                converterExpression: field.converterExpression,
            };

        return addConversion(field, inputField, outputField);
    };

// TODO: not used - validate
export const toGeneralMapper = (
    mapper,
    flatInputOptionsByValue,
    flatOutputOptionsByValue,
) =>
    mapper.map(
        applyConversions(flatInputOptionsByValue, flatOutputOptionsByValue),
    );

export const addMapperRowConverter = curryN(2, (parserConfig, field) => {
    const converterExpression =
        field.converterExpression || conformToOutputType(field, parserConfig);
    return {
        id: field.id,
        input: field.input,
        inputType: field.inputType,
        formulaMapping: field.formulaMapping ?? null,
        output: field.output || field.name,
        ...(converterExpression ? { converterExpression } : {}),
    };
});

export const toApiMapper = (extensionFields, parserConfig) => {
    const result = values(extensionFields)
        .filter(field => !field.skip)
        .map(addMapperRowConverter(parserConfig));
    return result;
};

const logInvalidInputConverterExpression = (inputType, input) => {
    if (![InputType.BODY, InputType.GROOVY].includes(inputType))
        console.error(
            "Only 'body' and 'groovy' input types can be converted with converter expression!",
            { input, inputType },
        );
};

export const conformToOutputType = (
    { input, inputType, outputType },
    parserConfig,
) => {
    if (outputType === ParsedType.NUMBER && inputType === InputType.BODY) {
        logInvalidInputConverterExpression(inputType, input);
        return converterExpressions.create.number(parserConfig);
    }
    if (outputType === ParsedType.INTEGER && inputType === InputType.BODY) {
        logInvalidInputConverterExpression(inputType, input);
        return converterExpressions.create.integer(parserConfig);
    }
    switch (outputType) {
        case ParsedType.DATE: {
            logInvalidInputConverterExpression(inputType, input);
            return converterExpressions.create.date(parserConfig);
        }
        case ParsedType.DATE_TIME: {
            logInvalidInputConverterExpression(inputType, input);
            return converterExpressions.create.datetime(parserConfig);
        }

        // TODO: is it used? Move to validations and unify
        case ParsedType.COMBINED: {
            if (!Array.isArray(input))
                throw new Error("Combined input (value) must be array");
            break;
        }
    }
};
