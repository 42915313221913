import { Forms } from "@/components/DesignSystem";
import { orEmpty } from "@/components/DesignSystem/Forms/validators";
import EntityPFXData from "@/components/GenericForm/components/EntityPfxData.component";
import SelectPFXData from "@/components/GenericForm/components/SelectPfxData.component";
import SubFieldElement from "@/components/GenericForm/components/SubFieldElement";
import { mapperMappingSourceType } from "@/services/mapperMappingSourceType.enum";
import { t } from "@/translations";
import { fromPairs, pipe, uniq, map } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { GenericFormInputTypes } from "./GenericForm.utils";

const { Fields } = Forms;

const VALIDATOR_TYPE = {
    REGEX: "regex-validator",
};

const matchReStrWithFlags = str => {
    const match = str.match(/^\/(.+)\/(\w*)$/);
    if (match) {
        const [whole, body, flags] = match;
        return [body, flags];
    }

    return [str];
};

const createValidator = ({ type, value, errorMessage }) => {
    if (type === VALIDATOR_TYPE.REGEX) {
        const [reStr, flags] = matchReStrWithFlags(value);

        return Forms.pmValidators.createPatternValidator(
            new RegExp(reStr, flags),
            errorMessage || t("general.validation.re", { pattern: value }),
        );
    }
};

export const mapFieldToProps = field => ({
    key: field.name,
    mode: field.mode ?? undefined,
    name: field.name,
    label: field.label,
    placeholder: field.placeholder,
    tooltip: field.tooltip,
    disabled: field.fixed,
    required: field.required ?? false,
    validator:
        field.required || field.validators
            ? Forms.validators.failOnFirst(
                  []
                      .concat([field.required && Forms.pmValidators.isRequired])
                      .concat(
                          field.validators?.map(pipe(createValidator, orEmpty)),
                      )
                      .filter(Boolean),
              )
            : undefined,
});

const DC_TYPE = {
    FIELD_EQUALS: "FIELD_EQUALS",
    FIELD_CONTAINS: "FIELD_CONTAINS",
    NOT: "NOT",
    AND: "AND",
    OR: "OR",
};

const fieldEquals = ({ fieldName, value }, values) =>
    values[fieldName] === value;

const fieldContains = ({ fieldName, value }, values) => {
    const fieldValue = values[fieldName];
    const canPerformMatch =
        Array.isArray(fieldValue) || typeof fieldValue === "string";

    if (canPerformMatch) return fieldValue.includes(value);

    return false;
};

const displayConditionFulfilled = (node, values) => {
    switch (node.type) {
        case null: // backward compatibility, definitions without type
        case undefined:
        case DC_TYPE.FIELD_EQUALS:
            return fieldEquals(node, values);
        case DC_TYPE.FIELD_CONTAINS:
            return fieldContains(node, values);
        case DC_TYPE.NOT:
            return !displayConditionFulfilled(node.condition, values);
        case DC_TYPE.AND:
            return node.conditions.every(child =>
                displayConditionFulfilled(child, values),
            );
        case DC_TYPE.OR:
            return node.conditions.some(child =>
                displayConditionFulfilled(child, values),
            );
        default:
            throw new Error("Unknown displayCondition type:" + node.type);
    }
};

const extractFieldNames = node => {
    if (!node) return [];
    if (node.fieldName) return [node.fieldName];
    else {
        const conditions = node.conditions || [node.condition];
        return [].concat(...conditions.map(extractFieldNames));
    }
};

const useValues = ({ formId, displayCondition }) => {
    const values = pipe(
        extractFieldNames,
        uniq,
        map(name => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const value = Forms.useFieldValue({ formId, name });
            return [name, value];
        }),
        fromPairs,
    )(displayCondition);
    return values;
};

const useDisplayCondition = ({ formId, field }) => {
    const [displayCondition] = useState(field.displayCondition); // useFieldValue called n-times, n needs to be constant among renders
    const values = useValues({ formId, displayCondition });
    const visible =
        !displayCondition ||
        displayConditionFulfilled(displayCondition, values);

    return { visible };
};

const FieldByType = ({ field, objectId, objectType, formId, setValues }) => {
    const fieldProps = mapFieldToProps(field);
    const { visible } = useDisplayCondition({ formId, field });

    if (!visible) return null;

    switch (field.type) {
        case GenericFormInputTypes.STRING:
            return <Fields.Input {...fieldProps} />;
        case GenericFormInputTypes.INT:
            return <Fields.InputNumber {...fieldProps} />;
        case GenericFormInputTypes.PFX_DATA_FIELD:
            return (
                <SubFieldElement
                    objectId={objectId}
                    objectType={objectType}
                    entityName={field.tableName}
                    entityType={field.tableType}
                    field={field}
                    {...fieldProps}
                />
            );
        case GenericFormInputTypes.SELECT:
            return <Fields.Select options={field.options} {...fieldProps} />;
        case GenericFormInputTypes.MULTI_SELECT:
            return (
                <Fields.Select
                    options={field.options}
                    {...fieldProps}
                    mode="multiple"
                />
            );
        case GenericFormInputTypes.CHECKBOX:
            return <Fields.Checkbox {...fieldProps} />;
        case GenericFormInputTypes.PFX_DATA:
            return (
                <SelectPFXData
                    key={field.name}
                    formId={formId}
                    selectProps={fieldProps}
                    field={field}
                    objectId={objectId}
                    objectType={objectType}
                    entityType={field.dataType}
                    setValues={setValues}
                />
            );

        case GenericFormInputTypes.PFX_DATA_SELECT: {
            return (
                <EntityPFXData
                    key={field.name}
                    formId={formId}
                    fieldProps={fieldProps}
                    objectId={objectId}
                    objectType={objectType}
                    field={field}
                    setValues={setValues}
                />
            );
        }
    }
};

export const GenericFormInner = ({
    formId,
    setValues,
    objectId,
    objectType = mapperMappingSourceType.partitions,
    formDefinitions,
}) => {
    return (
        <>
            {map(
                field => (
                    <FieldByType
                        key={field.name}
                        field={field}
                        objectId={objectId}
                        objectType={objectType}
                        formId={formId}
                        setValues={setValues}
                    />
                ),
                formDefinitions ?? [],
            )}
        </>
    );
};

GenericFormInner.propTypes = {
    formId: PropTypes.string.isRequired,
    objectId: PropTypes.number.isRequired,
    objectType: PropTypes.string,
    formDefinitions: PropTypes.array.isRequired,
    setValues: PropTypes.func.isRequired,
};
