import { Drawer, Gap, Tabs, Text } from "@/components/DesignSystem";
import { T } from "@/translations";
import concat from "lodash/fp/concat";
import entries from "lodash/fp/entries";
import flatMap from "lodash/fp/flatMap";
import pipe from "lodash/fp/flow";
import get from "lodash/fp/get";
import identity from "lodash/fp/identity";
import isEqual from "lodash/fp/isEqual";
import mapValues from "lodash/fp/mapValues";
import reduce from "lodash/fp/reduce";
import sortBy from "lodash/fp/sortBy";
import update from "lodash/fp/update";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { AdminAssetsTableContainer } from "../../../accountApp/components/AccountUserAdminSettings/RightsDrawer/AdminAssetsTable/AdminAssetsTable.container";
import {
    emptySelectedAssets,
    useAssetsSelection,
} from "../../../accountApp/components/AccountUserAdminSettings/RightsDrawer/hooks/useAssetsSelection.hook";
import styles from "./styles.less";

const toSelection = reduce(
    (acc, { entityId, type }) =>
        update(type.toLowerCase(), concat(parseInt(entityId, 10)), acc),
    emptySelectedAssets(),
);
const fromSelection = pipe(
    entries,
    flatMap(([type, ids]) =>
        ids.map(entityId => ({ entityId, type: type.toUpperCase() })),
    ),
);

const sortSelection = mapValues(sortBy(identity));

export const AssetRoleAssetsDrawer = ({
    visible,
    onClose,
    onSave,
    assetRole,
    readOnly,
}) => {
    const reinitSelected = useMemo(
        () =>
            !visible
                ? emptySelectedAssets()
                : pipe(get("assets"), toSelection, sortSelection)(assetRole),
        [assetRole, visible],
    );
    const { selectedAssets, onAssetsSelection } = useAssetsSelection({
        reinitSelected,
    });
    const onSaveClick = useCallback(
        () =>
            onSave({
                ...assetRole,
                assets: fromSelection(selectedAssets),
            }),
        [assetRole, selectedAssets, onSave],
    );
    const disabled = useMemo(
        () =>
            readOnly || isEqual(reinitSelected, sortSelection(selectedAssets)),
        [reinitSelected, selectedAssets, readOnly],
    );

    return (
        <Drawer
            width={630}
            visible={visible}
            onClose={onClose}
            okButtonProps={{
                onClick: onSaveClick,
                children: <T id="general.save" />,
                disabled,
            }}
            cancelButtonProps={{ onClick: onClose }}
            panel
        >
            <Tabs defaultActiveKey="assets">
                <Tabs.TabPane
                    tab={<T id="account-admin-asset-role.asset-list.header" />}
                    key="assets"
                >
                    <Text className={styles.description} type="small">
                        {assetRole?.description}
                    </Text>
                    <Gap size="x-large" />
                    <AdminAssetsTableContainer
                        selectionChange={onAssetsSelection}
                        selectedAssets={selectedAssets}
                        hasRights={false}
                        readOnly={readOnly}
                    />
                </Tabs.TabPane>
            </Tabs>
        </Drawer>
    );
};

AssetRoleAssetsDrawer.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    assetRole: PropTypes.shape({
        id: PropTypes.any,
        name: PropTypes.string,
        description: PropTypes.string,
        assets: PropTypes.arrayOf(PropTypes.object),
    }),
    readOnly: PropTypes.bool,
};
