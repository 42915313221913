import {
    ButtonMenu,
    Forms,
    Gap,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import DisableSubmitOnEnter from "@/components/FeatureFlags/components/DisableSubmitOnEnter.component";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

const { useForm, Form, Fields, pmValidators, validators } = Forms;

export const AddPropertyModal = ({
    visible,
    onClose,
    onAdd,
    addModalTitle,
    addModalDescription,
    properties,
}) => {
    const { formId, handleSubmit } = useForm({
        onSubmit: ({ values }) => {
            onAdd(values);
            onClose();
        },
    });

    const uniquePropertyNameValidator = useCallback(
        value =>
            (properties || []).some(obj => obj.name === value)
                ? Forms.error("Must be unique")
                : Forms.success(),
        [properties],
    );

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header size={3} title={addModalTitle} />
                <UnityLayout.Content padding={[false, true]}>
                    {addModalDescription && (
                        <>
                            {addModalDescription}
                            <Gap />
                        </>
                    )}
                    <DisableSubmitOnEnter>
                        <Form formId={formId} onSubmit={handleSubmit}>
                            <Fields.Input
                                name="name"
                                label={t("edit-property.modal.form.name.label")}
                                placeholder={t(
                                    "edit-property.modal.form.name.placeholder",
                                )}
                                required
                                validator={validators.failOnFirst([
                                    pmValidators.isRequired,
                                    uniquePropertyNameValidator,
                                ])}
                                inputWidth="max"
                            />
                            <Fields.TextArea
                                name="value"
                                label={t(
                                    "edit-property.modal.form.value.label",
                                )}
                                placeholder={t(
                                    "edit-property.modal.form.value.placeholder",
                                )}
                                required
                                validator={pmValidators.isRequired}
                                inputWidth="max"
                            />
                        </Form>
                    </DisableSubmitOnEnter>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.add"),
                                    formId,
                                    type: "primary",
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

AddPropertyModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    addModalTitle: PropTypes.node,
    addModalDescription: PropTypes.node,
    properties: PropTypes.array,
};
