import isEqual from "lodash/isEqual";
import { useEffect, useRef, useState } from "react";

// sets child state from parent state
// allows local modifications until parent state changes and resets child
export const useParentState = parentState => {
    const [state, setState] = useState(parentState);
    const stateRef = useRef(state);
    stateRef.current = state;

    useEffect(() => {
        if (!isEqual(stateRef.current, parentState)) setState(parentState);
    }, [parentState]);

    return [state, setState];
};
