import {
    Button,
    Col,
    Empty,
    Gap,
    Row,
    Timeline,
} from "@/components/DesignSystem/";
import { useDic } from "@/components/Dic/useDic.hook";
import { isLoading, useQueryLoadable } from "@/modules/loadable";
import { LocationLink } from "@/modules/router";
import { getData } from "@/services/utils";
import PropTypes from "prop-types";
import React from "react";
import uuid from "uuid/v4";
import style from "./ActivityLogTimeline.less";
import ActivityLogTimelineItem from "./ActivityLogTimelineItem";

const MAX_TIMELINE_ITEMS = 8;

const createTimelineItems = eventList => {
    return eventList.map((eventListItem, index) => {
        const isLastTimeLineItem = eventList.length === index + 1;
        return (
            <ActivityLogTimelineItem
                key={eventListItem.id || uuid()}
                data={eventListItem}
                isLast={isLastTimeLineItem}
            />
        );
    });
};

const ActivityLogTimeline = ({ accountId }) => {
    const {
        axiosService,
        accountAppLocations: { accountActivityLogLocation },
    } = useDic();

    const activityLogsQuery = useQueryLoadable(() =>
        axiosService
            .post(`/api/audit-logs/projects/${accountId}/timeline`, {
                from: 0,
                size: MAX_TIMELINE_ITEMS,
                sort: [{ id: "time", order: "desc" }],
            })
            .then(getData),
    );

    if (isLoading(activityLogsQuery.loadable)) {
        return "Loading...";
    } else {
        const logsMaybe = activityLogsQuery.loadable.valueMaybe();
        return (
            <React.Fragment>
                <div className={style.activityLog}>
                    {!logsMaybe?.length ? (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    ) : (
                        <Timeline>{createTimelineItems(logsMaybe)}</Timeline>
                    )}
                </div>
                <Row type="flex" justify="center" align="middle">
                    <Col>
                        <Gap size="small" />
                        <LocationLink
                            $location={accountActivityLogLocation}
                            $params={{ accountId: accountId }}
                        >
                            <Button
                                type="default"
                                size="small"
                                label="Show more"
                            />
                        </LocationLink>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
};

ActivityLogTimeline.propTypes = {
    accountId: PropTypes.number.isRequired,
};

export default ActivityLogTimeline;
