import { SidebarAccountSelect } from "@/components/Sidebar/SidebarAccountSelect/SidebarAccountSelect.component";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { AccountAppMenu } from "../AccountAppMenu/AccountAppMenu.component";
import { atom, useRecoilState } from "recoil";

const $accountSelectDisabled = atom({
    key: "accountSelectDisabled",
    default: false,
});

export const useAccountSelectDisabled = () => {
    const [, setAccountSelectDisabled] = useRecoilState($accountSelectDisabled);

    useEffect(() => {
        setAccountSelectDisabled(true);
        return () => setAccountSelectDisabled(false);
    }, [setAccountSelectDisabled]);
};

export const AccountAppSidebar = () => {
    const { accountId } = useAccountAppParams();
    const [accountSelectDisabled] = useRecoilState($accountSelectDisabled);

    return (
        <>
            <SidebarAccountSelect disabled={accountSelectDisabled} />
            <AccountAppMenu accountId={accountId} />
        </>
    );
};

AccountAppSidebar.propTypes = {
    onAccountChange: PropTypes.func,
};
