import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";

export const partitionLimitColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("partition-limit.column.project-name"),
        name: "projectName",
    },
    {
        type: fieldTypes.TEXT,
        label: t("partition-limit.column.env"),
        name: "env",
    },
    {
        type: fieldTypes.TEXT,
        label: t("partition-limit.column.cluster-name"),
        name: "clusterName",
    },
    {
        type: fieldTypes.NUMBER,
        label: t("partition-limit.column.max"),
        name: "max",
        render: text =>
            text === null ? t("partition-limit.edit.unlimited.label") : text,
    },
    {
        type: fieldTypes.NUMBER,
        label: t("partition-limit.column.existing"),
        name: "existing",
    },
];
