import { P } from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { InsufficientRightsPanel } from "./InsufficientRightsPanel";

export const PartitionEntitlementInfo = ({
    partition,
    currentUser,
    missingRightsMaybe,
}) => {
    return (
        <>
            <P data-test="partition-access-message">
                {t("partition-password.message", {
                    partition: partition?.serialNumber,
                })}
            </P>
            {partition.technicalPmUserSupported && (
                <P data-test="partition-access-message-2">
                    {t("partition-password.message2", {
                        partition: partition?.serialNumber,
                    })}
                </P>
            )}
            <P data-test="partition-access-current-user">
                <>
                    {t("partition-password.current-user")}:
                    {currentUser && (
                        <b data-test="partition-current-user">{currentUser}</b>
                    )}
                </>
                {missingRightsMaybe && (
                    <InsufficientRightsPanel
                        missingRights={missingRightsMaybe}
                    />
                )}
            </P>
        </>
    );
};

PartitionEntitlementInfo.propTypes = {
    partition: PropTypes.object,
    currentUser: PropTypes.string,
    missingRightsMaybe: PropTypes.object,
};
