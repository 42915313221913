import { Col, Row } from "@/components/DesignSystem";
import { H1 } from "@/components/DesignSystem/heading";
import { H4, P } from "@/components/DesignSystem/index";
import { t } from "@/translations";
import React from "react";
import pageStyles from "./styles.less";

// FIXME: according to Design https://www.figma.com/file/SI5qYBoGnWxKEkL0mFbxmOTB/01.18_Notifications-and-Error-Messages?node-id=226%3A198
export const ServerErrorPage = () => (
    <Row
        className={pageStyles.page}
        type="flex"
        justify="center"
        align="middle"
    >
        <Col>
            <H1>500</H1>
            <H4>{t("page.500.title")}</H4>
            <P>{t("page.500.desc")}</P>
        </Col>
    </Row>
);
