import { IM_VERSION_WITH_SUPPORT_OF_CERTIFICATES } from "@/components/Certificates/CertificateForm.component";
import {
    useCertificatesQuery,
    useDeleteCertificateMutation,
    useDeployCertificateMutation,
} from "@/components/Certificates/loadables";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useInstanceQuery } from "@/components/IntegrationRoutes/loadables";
import {
    useIMVersionComplyLoadable,
    useWorkspaceInstanceDeletionAlert,
} from "@/components/Integrations/InstanceEntityWorkspaceAlert/InstanceEntityWorkspaceAlert";
import { isLoading, mapLoadableItems } from "@/modules/loadable";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { certificatesListColumns } from "../Certificates/CertificatesList.columns";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";

const preferencesType = preferencesTypes.CERTIFICATES_LIST_TABLE;

const initialCertificates = [];

export default function CertificatesList({
    instanceId,
    accountId,
    visible,
    onEdit,
    reloadToken,
    afterDeploy,
}) {
    const certificatesQuery = useCertificatesQuery({ instanceId, reloadToken });
    const deleteCertificateMutation = useDeleteCertificateMutation({
        instanceId,
        afterSuccess: certificatesQuery.reload,
    });
    const deployCertificateMutation = useDeployCertificateMutation({
        instanceId,
        afterSuccess: afterDeploy,
    });

    const dataSource =
        certificatesQuery.loadable.valueMaybe() || initialCertificates;
    const loading =
        isLoading(certificatesQuery) ||
        isLoading(deleteCertificateMutation) ||
        isLoading(deployCertificateMutation);
    const columns = useMemo(
        () => certificatesListColumns(dataSource),
        [dataSource],
    );

    const instanceQuery = useInstanceQuery({ instanceId });
    useWorkspaceInstanceDeletionAlert({ instanceQuery, visible });

    const isCertificateDeploySupportedLoadable = useIMVersionComplyLoadable({
        instanceQuery,
        minVersion: IM_VERSION_WITH_SUPPORT_OF_CERTIFICATES,
    });

    const mapMenuItems = useCallback(
        record =>
            ({ comply: isAllowedToDeploy }) =>
                [
                    {
                        title: t("general.tooltip.edit"),
                        onClick: onEdit,
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                    },
                    {
                        title: t("general.deploy"),
                        onClick: deployCertificateMutation.mutate,
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                        visible: isAllowedToDeploy,
                    },
                    {
                        title: t("general.modal.delete.title", {
                            type: "certificate",
                        }),
                        confirmMessage: t("general.modal.delete.message", {
                            type: "certificate",
                            name: record.name,
                        }),
                        onConfirm: deleteCertificateMutation.mutate,
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                        color: "red",
                    },
                ],
        [
            deleteCertificateMutation.mutate,
            deployCertificateMutation.mutate,
            onEdit,
        ],
    );

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={mapLoadableItems(
                    isCertificateDeploySupportedLoadable,
                    mapMenuItems(record),
                ).valueMaybe()}
            />
        ),
        [isCertificateDeploySupportedLoadable, mapMenuItems],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            dataSource={dataSource}
            fixed
            loading={loading}
            rowKey="id"
            columns={columns}
            datasetSlicing="local"
            preferencesType={preferencesType}
            visible={visible}
        />
    );
}

CertificatesList.propTypes = {
    accountId: PropTypes.number.isRequired,
    instanceId: PropTypes.number.isRequired,
    onEdit: PropTypes.func.isRequired,
    reloadToken: PropTypes.any,
    afterDeploy: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};
