import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { t } from "@/translations";
import { getErrorMessageFromError } from "@/utils/state/error.utils";
import PropTypes from "prop-types";
import React from "react";

const certificateMock =
    "---- BEGIN CERTIFICATE----- NSDHBCKndkjnc CdNSDHBCKndkjnc CdNSDHBCKndkjnc CdNSDHBCKndkjnc CdNSDHBCKndkjnc CdNSDHBCKndkjnc CdNSDHBCKndkjnc CdNSDHBCKndkjnc CdNSDHBCKndkjnc CdNSDHBCKndkjnc CdNSDHBCKndkjnc CdNSDHBCKndkjnc CdNSDHBCKndkjnc CdNSDHBCKndkjnc CdNSDHBCKndkjnc CdNSDHBCKndkjnc CdNSDHBCKndkjnc Cd ----END CERTIVFICATE------";

export const AddCertificateModal = ({
    certificateService,
    projectId,
    visible,
    onClose,
    onSubmit,
}) => {
    const { messageService } = useDic();

    const { formId, handleSubmit } = Forms.useForm({
        onSubmit: ({ values }) => {
            certificateService
                .createCertficate(
                    { projectId },
                    {
                        name: values.certificateId,
                        value: values.certificate,
                    },
                )
                .then(() => {
                    onSubmit({ values });
                })
                .catch(error => {
                    const message = getErrorMessageFromError(error);
                    messageService.error({ content: message });
                });
        },
    });

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("sso.add-certificate.modal.title")}
                />
                <UnityLayout.Content padding={[true, true]}>
                    <Forms.Form formId={formId} onSubmit={handleSubmit}>
                        <Forms.FieldGroup width="max" inputWidth="max">
                            <Forms.Fields.Input
                                required
                                name="certificateId"
                                label={t("sso.add-certificate.label.id")}
                                placeholder={t(
                                    "sso.add-certificate.placeholder.id",
                                )}
                                validator={Forms.validators.failOnFirst([
                                    Forms.pmValidators.isRequired,
                                    Forms.pmValidators.createMinLengthValidation(
                                        5,
                                    ),
                                    Forms.pmValidators.createMaxLengthValidation(
                                        255,
                                    ),
                                ])}
                            />
                            <Forms.Fields.TextArea
                                required
                                name="certificate"
                                label={t(
                                    "sso.add-certificate.label.certificate",
                                )}
                                placeholder={certificateMock}
                                rows={7}
                                validator={Forms.pmValidators.isRequired}
                            />
                        </Forms.FieldGroup>
                    </Forms.Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.add"),
                                    type: "primary",
                                    formId,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
AddCertificateModal.propTypes = {
    certificateService: PropTypes.object.isRequired,
    projectId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};
