import { useDic } from "@/components/Dic/useDic.hook";
import { useQueryLoadable, waitForValue } from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useRouteDefinitionResource = (instanceId, routeId) => {
    const { routesService } = useDic();

    return useQueryLoadable(
        () =>
            isNaN(instanceId) && !routeId
                ? waitForValue()
                : routesService
                      .fetchRouteDefinition(instanceId, routeId)
                      .then(getData),
        [routesService, instanceId, routeId],
    );
};
