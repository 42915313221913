import { UnityIcon } from "@/components/DesignSystem";
import { ReactComponent as IconCheckCircle } from "@pricefx/unity-components/dist/es/icons/unicons/check-circle.svg";
import { ReactComponent as IconExclamationCircleSolid } from "@pricefx/unity-components/dist/es/icons/unicons/exclamation-circle-solid.svg";
import { ReactComponent as IconExclamationCircle } from "@pricefx/unity-components/dist/es/icons/unicons/exclamation-circle.svg";
import { ReactComponent as IconInfoCircle } from "@pricefx/unity-components/dist/es/icons/unicons/info-circle.svg";
import cx from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import "./HeaderNotificationsItem.style.less";

export const NOTIFICATION_SEVERITY = {
    FAILED: "FAILED",
    SUCCESS: "SUCCESS",
    WARNING: "WARNING",
    INFO: "INFO",
};

export const ICON_TYPES = {
    [NOTIFICATION_SEVERITY.FAILED]: IconExclamationCircleSolid,
    [NOTIFICATION_SEVERITY.SUCCESS]: IconCheckCircle,
    [NOTIFICATION_SEVERITY.WARNING]: IconExclamationCircle,
    [NOTIFICATION_SEVERITY.INFO]: IconInfoCircle,
};

const componentClass = "pmHeaderNotificationsItem";
const statusClass = `${componentClass}__status`;
const unreadMarkClass = `${componentClass}__unreadMark`;
const titleClass = `${componentClass}__title`;
const timeClass = `${componentClass}__time`;
const messageClass = `${componentClass}__message`;

const formatDate = date => {
    const dateMoment = moment(date);

    return `${dateMoment.format(`DD/MM/YYYY`)} at ${dateMoment.format(
        `H:mm A`,
    )}`;
};

export const HeaderNotificationsItem = ({
    status,
    onClick,
    title,
    message,
    updatedAt,
    isRead,
}) => {
    const statusClasses = cx(statusClass, {
        [`${statusClass}--type-${status.toLowerCase()}`]: !!status,
    });

    return (
        <div className={componentClass} onClick={onClick}>
            <UnityIcon
                className={statusClasses}
                component={ICON_TYPES[status]}
            />
            <div className={titleClass}>{title}</div>
            {message ? <div className={messageClass}>{message}</div> : null}
            <div className={timeClass}>{formatDate(updatedAt)}</div>
            {!isRead && <div className={unreadMarkClass} />}
        </div>
    );
};

HeaderNotificationsItem.propTypes = {
    status: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    isRead: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    message: PropTypes.string,
};
