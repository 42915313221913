import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthLayout } from "@/components/hooks/useContentLayout.hook";
import { NonStandardDefinition } from "@/components/IntegrationRoutes/components/NonStandardDefinition.component";
import Mapper, { OPERATION } from "@/components/Mappers/Mapper";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";

export const MapperDefinitionPage = () => {
    const {
        accountId,
        instanceId,
        mapperIdOrPrn: mapperIdOrPrnEncoded,
        isNonStandardMapper,
    } = useAccountAppParams();
    const mapperIdOrPrn = decodeURIComponent(mapperIdOrPrnEncoded);

    const {
        locationRouterService,
        accountAppLocations: { mappersLocation },
    } = useDic();

    useSetPageTitle(t("mapper-definition.title", { prn: mapperIdOrPrn }));

    const navigateToMappersList = useCallback(() => {
        locationRouterService.navigate(mappersLocation, {
            instanceId,
            accountId,
        });
    }, [accountId, instanceId, locationRouterService, mappersLocation]);

    useSetBackButtonAction(navigateToMappersList);

    useFullWidthLayout();

    return isNonStandardMapper ? (
        <NonStandardDefinition instanceId={instanceId} prn={mapperIdOrPrn} />
    ) : (
        <Mapper
            projectId={accountId}
            instanceId={instanceId}
            mapperId={mapperIdOrPrn}
            operation={OPERATION.SHOW}
        />
    );
};
