import { useDic } from "@/components/Dic/useDic.hook";
import {
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { PREFERENCES_CHANGE_ACTION, useTrackPreferences } from "./tracking";

export const useFetchPreferences = type => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        () =>
            axiosService.get(`/api/preferences?type=${type}`).then(({ data }) =>
                data.map(pref => ({
                    ...pref,
                    viewState: JSON.parse(pref.json),
                })),
            ),
        [type, axiosService],
    );
};

export const useAddPreference = onSaved => {
    const { axiosService } = useDic();
    const trackPreferences = useTrackPreferences();

    return useMutationLoadableWithNotification(
        async preference => {
            await axiosService.post("/api/preferences", preference);
            trackPreferences(PREFERENCES_CHANGE_ACTION.CREATE, preference.type);
            onSaved?.(preference);
        },
        [onSaved, axiosService, trackPreferences],
        "preference.new.saved",
        error => `Error occured. ${error?.response?.data?.messages?.join(",")}`,
    );
};

export const useDeletePreference = onDeleted => {
    const { axiosService } = useDic();
    const trackPreferences = useTrackPreferences();

    return useMutationLoadableWithNotification(
        async preference => {
            await axiosService.delete(`/api/preferences/${preference.id}`);
            trackPreferences(PREFERENCES_CHANGE_ACTION.DELETE, preference.type);
            onDeleted?.(preference.id);
        },
        [onDeleted, axiosService, trackPreferences],
        "preference.deleted",
    );
};

export const useEditPreference = onSaved => {
    const { axiosService } = useDic();
    const trackPreferences = useTrackPreferences();

    return useMutationLoadableWithNotification(
        async preference => {
            await axiosService.post(
                `/api/preferences/${preference.id}`,
                preference,
            );
            trackPreferences(PREFERENCES_CHANGE_ACTION.UPDATE, preference.type);
            onSaved?.(preference);
        },
        [onSaved, axiosService, trackPreferences],
        "preference.changes.saved",
    );
};
