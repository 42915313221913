import WarningAlert from "@/components/Alerts/WarningAlert";
import { Gap, H3, Switch, Text } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    allowSkipWhenDeployed,
    isAlreadyDeployed,
    isOptional,
} from "@/components/PageLayout/package-steps.utils";
import Progress from "@/components/Progress/Progress";
import { T, t } from "@/translations";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { getStepLabel } from "../../../../../views/Package/package.utils";
import {
    inErrorState,
    inStepFinishedState,
    inStepSkipDecision,
} from "../deployPackageState.enum";
import { GoToNextStepButton } from "./GoToNextStepButton.component";
import styles from "./PackageProgress.less";
import TextWithSkipButtonComponent from "./TextWithSkipButton.component";

export function PackageProgress({
    processStateId,
    percent,
    $currentStep,
    deployPackageState,
    onGoToNextStep,
    onStepFinished,
    timeWarningInterval = 30000,
}) {
    const [showTimeWarning, setShowTimeWarning] = useState(false);
    const [isNotificationRequested, setNotificationRequest] = useState(false);

    const { packageService } = useDic();

    const optional = isOptional($currentStep);
    const deployed = isAlreadyDeployed($currentStep);

    const inSkip = inStepSkipDecision(deployPackageState);
    const inFinished = inStepFinishedState(deployPackageState);

    useEffect(() => {
        const timer = setInterval(
            () => setShowTimeWarning(true),
            timeWarningInterval,
        );
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (deployed || inSkip || inFinished) {
            setShowTimeWarning(false);
        }
    }, [deployed, inSkip, inFinished]);

    const handleNotificationChange = value => {
        value
            ? packageService.sendNotificationRequest(processStateId)
            : packageService.deleteNotificationRequest(processStateId);
        setNotificationRequest(value);
    };

    const getSkipMessage = () => {
        if (deployed && optional) {
            if (allowSkipWhenDeployed($currentStep))
                return (
                    <TextWithSkipButtonComponent
                        textId="package.process.skip.deployed-and-optional-allow-use-existing"
                        onSkip={() => onStepFinished({ skipped: true })}
                        onUseExisting={() =>
                            onStepFinished({ skippedBecauseDeployed: true })
                        }
                    />
                );
            return (
                <TextWithSkipButtonComponent
                    textId="package.process.skip.deployed-and-optional"
                    onSkip={() => onStepFinished({ skipped: true })}
                />
            );
        }
        if (deployed) {
            return (
                <TextWithSkipButtonComponent
                    textId="package.process.skip.deployed"
                    onSkip={() =>
                        onStepFinished({ skippedBecauseDeployed: true })
                    }
                />
            );
        }
        if (optional) {
            return (
                <TextWithSkipButtonComponent
                    textId="package.process.skip.optional"
                    onSkip={() => onStepFinished({ skipped: true })}
                />
            );
        }
    };

    return (
        <>
            <H3>
                {inSkip
                    ? $currentStep?.stepLabel ?? getHeading($currentStep)
                    : getHeading($currentStep)}
            </H3>
            {showTimeWarning && !inSkip && !inFinished && (
                <>
                    <Text>
                        <T id="package.process.default-10-minutes" />
                    </Text>
                    <Gap size="small" />
                    <div className={styles.notificationRow}>
                        <Switch
                            value={isNotificationRequested}
                            onChange={handleNotificationChange}
                        />
                        <Text>
                            <T id="package.process.set-notification" />
                        </Text>
                    </div>
                    <Gap size="small" />
                    <WarningAlert
                        visible={isNotificationRequested}
                        message={t("package.process.spam-warning")}
                    />
                </>
            )}
            {$currentStep?.processDescription && (
                <Text>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: $currentStep.processDescription,
                        }}
                    />
                </Text>
            )}
            {inSkip && (
                <React.Fragment>
                    <br />
                    <Text>{getSkipMessage()}</Text>
                </React.Fragment>
            )}
            {!inSkip && (
                <Progress
                    className={styles.progress}
                    status={getStatus(deployPackageState)}
                    percent={percent}
                    showInfo={true}
                    message={inFinished ? "" : getMessage($currentStep)}
                />
            )}
            {(inFinished || inSkip) && (
                <GoToNextStepButton onGoToNextStep={onGoToNextStep} />
            )}
        </>
    );
}

PackageProgress.propTypes = {
    processStateId: PropTypes.number.isRequired,
    $currentStep: PropTypes.object,
    deployPackageState: PropTypes.string.isRequired,
    percent: PropTypes.number,
    timeWarningInterval: PropTypes.number,
    onGoToNextStep: PropTypes.func.isRequired,
    onStepFinished: PropTypes.func.isRequired,
};

function getHeading(currentStep) {
    return currentStep ? (
        <T id="package.header.processing" />
    ) : (
        <T id="package.header.starting-process" />
    );
}

function getMessage(currentStep) {
    return currentStep ? (
        <T id={getStepLabel(currentStep.type)} values={{ step: currentStep }} />
    ) : (
        <T id="package.message.starting-process" />
    );
}

function getStatus(deployPackageState) {
    return inErrorState(deployPackageState) ? "exception" : "active";
}
