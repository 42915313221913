import { Button } from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

export const BackButton = ({ onBack, ...props }) => {
    if (onBack) {
        return (
            <Button
                data-test="back"
                type="default"
                onClick={() => onBack()}
                label={t("stepper.button.back")}
                {...props}
            />
        );
    } else {
        return null;
    }
};

BackButton.propTypes = {
    onNext: PropTypes.func,
};
