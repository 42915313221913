import { Menu, UnityIcon } from "@/components/DesignSystem";
import { useContextTemplateCategoriesQuery } from "@/components/Marketplace/components/TagsMenu/TemplateCategories.context";
import { LoadableRenderer, loadableFromMaybeValue } from "@/modules/loadable";
import { useLoadableHasValueChangedEffect } from "@/modules/loadable/useLoadableHasValueChangedEffect.hook";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { ReactComponent as FavoriteTag } from "../FavoriteButton/icons/tag.svg";
import "./TagsMenu.styles.less";

const MENU_ALL_KEY = "All";
const MENU_FAVORITES_KEY = "Favorites";

const formatAnchor = (tagName, categoriesCounterMap) => {
    const count = categoriesCounterMap?.[tagName];

    return `${tagName}${isNaN(count) ? "" : ` (${count})`}`;
};

export const TagsMenu = ({ onTagClick, tag }) => {
    const [categoriesCounterMap, setCategoriesCounterMap] = useState(undefined);

    const templateCategoriesResource = useContextTemplateCategoriesQuery();

    const selectedKeys = [tag ?? MENU_ALL_KEY];
    const categoriesCounterMapLoadable =
        loadableFromMaybeValue(categoriesCounterMap);
    useLoadableHasValueChangedEffect(
        templateCategoriesResource.loadable,
        setCategoriesCounterMap,
    );

    const omitedTags = useMemo(
        () =>
            Object.keys(categoriesCounterMap ?? {})
                .filter(
                    tag => ![MENU_ALL_KEY, MENU_FAVORITES_KEY].includes(tag),
                )
                .sort((a, b) => a.localeCompare(b)),
        [categoriesCounterMap],
    );

    return (
        <LoadableRenderer
            loadable={categoriesCounterMapLoadable}
            loading={() => (
                <LoadableRenderer.SkeletonLoader translationKey="" />
            )}
            hasValue={categoriesCounterMap => {
                return (
                    <div className="pmTagsMenu">
                        <div className="pmTagsMenu-title">Tags</div>
                        <Menu
                            selectedKeys={selectedKeys}
                            theme="pm-tags"
                            items={[
                                {
                                    onClick: () =>
                                        onTagClick(MENU_FAVORITES_KEY),
                                    anchor: (
                                        <>
                                            <UnityIcon
                                                component={FavoriteTag}
                                                style={{
                                                    display: "inline-block",
                                                    position: "relative",
                                                    left: "auto",
                                                    top: "auto",
                                                    fill: "#575858",
                                                    marginRight: "6px",
                                                }}
                                            />
                                            {formatAnchor(
                                                MENU_FAVORITES_KEY,
                                                categoriesCounterMap,
                                            )}
                                        </>
                                    ),
                                    key: "Favorites",
                                },
                                {
                                    onClick: () => onTagClick(undefined),
                                    anchor: formatAnchor(
                                        MENU_ALL_KEY,
                                        categoriesCounterMap,
                                    ),
                                    key: MENU_ALL_KEY,
                                },
                                ...omitedTags.map(tag => ({
                                    key: tag,
                                    anchor: formatAnchor(
                                        tag,
                                        categoriesCounterMap,
                                    ),
                                    onClick: () => onTagClick(tag),
                                })),
                            ]}
                            mode="inline"
                            onClick={function noRefCheck() {}}
                        />
                    </div>
                );
            }}
        />
    );
};

TagsMenu.propTypes = {
    onTagClick: PropTypes.func.isRequired,
    tag: PropTypes.string.isRequired,
};
