import {
    fieldTypes,
    HEADER_HEIGHT_WITH_BREADCRUMB,
} from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { INSTANCE_TABS } from "@/components/Integrations/EditInstanceForm";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { LocationLink } from "@/modules/router";
import { t } from "@/translations";
import { buildPageableUrl } from "@/utils/urlUtils";
import { get } from "lodash/fp";
import React, { useMemo } from "react";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";

const preferencesType = preferencesTypes.INTEGRATION_ARCHIVES_LIST_TABLE;

const URL = `/api/dashboard/integration-archives`;

const createColumns = ({ imArchiveLocation }) => [
    {
        type: fieldTypes.TEXT,
        label: t("integration-archives.columns.instance-name"),
        name: "instanceName",
        render: (text, record) =>
            !record.instanceId || !record.hasRight ? (
                text
            ) : (
                <LocationLink
                    key={record.id}
                    $location={imArchiveLocation}
                    $params={{
                        instanceId: record.instanceId,
                        accountId: record.accountId,
                        tab: INSTANCE_TABS.ARCHIVE,
                    }}
                >
                    {text}
                </LocationLink>
            ),
    },
    {
        type: fieldTypes.BOOLEAN,
        label: t("integration-archives.columns.status"),
        name: "enabled",
        canSort: false,
        canFilter: false,
        render: enabled =>
            t(
                enabled
                    ? "im-archive.account.fields.enabled.checked"
                    : "im-archive.account.fields.enabled.unchecked",
            ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("integration-archives.columns.dir-count"),
        name: "dirCount",
        canSort: false,
        canFilter: false,
    },
];

export const IntegrationArchivesList = () => {
    useFullWidthTableLayout();
    const {
        accountAppLocations: { settingsLocation },
    } = useDic();
    const columns = useMemo(
        () =>
            createColumns({
                imArchiveLocation: settingsLocation,
            }),
        [settingsLocation],
    );
    const { axiosService } = useDic();
    const [tableProps] = useFetchPage(
        (page, size, sort, filter) =>
            axiosService.post(buildPageableUrl(URL, page, size, sort), filter),
        [],
    );

    return (
        <TableWithPreferencesManagement
            columns={columns}
            rowKey="instanceId"
            datasetSlicing="server"
            preferencesType={preferencesType}
            fixed
            restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
            pmExpandable={{
                expandedRowHeight: 150,
                rowExpandable: record => !!record.dirs?.length,
                expandedRowRender: record => (
                    <pre>{record.dirs?.map(get("folderName")).join("\n")}</pre>
                ),
            }}
            {...tableProps}
        />
    );
};
