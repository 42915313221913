import _ from "lodash";
import "lodash.product";
import curryN from "lodash/fp/curryN";
import entries from "lodash/fp/entries";
import filter from "lodash/fp/filter";
import reject from "lodash/fp/reject";
import isMatch from "lodash/fp/isMatch";
import flatMap from "lodash/fp/flatMap";
import get from "lodash/fp/get";
import groupBy from "lodash/fp/groupBy";
import map from "lodash/fp/map";
import mapValues from "lodash/fp/mapValues";
import omit from "lodash/fp/omit";
import pipe from "lodash/fp/pipe";
import { omitAssetRoleAssetType } from "./assetRole.utils";
import { ADMIN_KIND, ASSET_KIND, ENTITY_KIND } from "./sources.utils";
import { treeToArr } from "./utils";

const assetPermissionChangeProduct = {
    [ASSET_KIND.ASSET]: (changes, assets) =>
        pipe(
            entries,
            flatMap(([type, ids]) => {
                const changesOfType = changes.filter(
                    ({ assetType }) =>
                        assetType.toUpperCase() === type.toUpperCase(),
                );
                return _.product(changesOfType, ids).map(
                    ([change, assetId]) => ({
                        ...change,
                        assetId: `${assetId}`,
                    }),
                );
            }),
        )(assets),
    [ASSET_KIND.ASSET_ROLE]: (changes, assetRoles) => {
        const assetRoleIds = assetRoles.map(({ id }) => id);
        return _.product(changes, assetRoleIds).map(
            ([change, assetRoleId]) => ({
                ...omitAssetRoleAssetType(change),
                assetRoleId,
            }),
        );
    },
    [ASSET_KIND.SUPER_ASSET]: (changes, superAssetTypes) => {
        return _.product(changes, superAssetTypes).map(
            ([change, superAssetType]) => ({
                ...change,
                assetType: superAssetType,
            }),
        );
    },
};

const assetBusinessRoleChangeProduct = {
    [ASSET_KIND.ASSET]: (changes, assets) =>
        pipe(
            entries,
            flatMap(([type, ids]) => {
                return _.product(changes, ids).map(([change, assetId]) => ({
                    ...change,
                    assetId: `${assetId}`,
                    assetType: type.toUpperCase(),
                }));
            }),
        )(assets),
    [ASSET_KIND.ASSET_ROLE]:
        assetPermissionChangeProduct[ASSET_KIND.ASSET_ROLE],
    [ASSET_KIND.SUPER_ASSET]:
        assetPermissionChangeProduct[ASSET_KIND.SUPER_ASSET],
};

const getEntityIdKey = ({ adminKind, entityKind }) => {
    if (entityKind === ENTITY_KIND.USER) return "userId";
    if (entityKind === ENTITY_KIND.GROUP) {
        if (adminKind === ADMIN_KIND.PROJECT) return "groupId";
        if (adminKind === ADMIN_KIND.GENERAL) return "groupName";
    }
    throw new Error("Missing entityIdKey");
};

// Maybe it would be better to reuse selection cartesian product in mapPermissionEntities
const removeWorkflowRequestedRights = workflowRights => rightsChanges => {
    const applicableChanges = reject(
        change => workflowRights.some(isMatch(change)),
        rightsChanges,
    );
    return applicableChanges;
};

export const permissionChanges = (
    changes,
    entities,
    assets,
    source,
    workflowRights,
) => {
    const apiChanges = pipe(
        curryN(2, treeToArr)(["assetType", "permissionName"]),
        filter(get(["state", "action"])),
        pChanges => {
            const apProduct = assetPermissionChangeProduct[source.assetKind](
                pChanges,
                assets,
            );
            const entitiesObjs = entities.map(id => ({
                [getEntityIdKey(source)]: id,
            }));
            const withEntities = _.product(apProduct, entitiesObjs);
            return withEntities.map(([p1, p2]) => ({ ...p1, ...p2 }));
        },
        groupBy(get(["state", "action"])),
        mapValues(
            pipe(
                map(omit(["state", "stateIndex"])),
                removeWorkflowRequestedRights(workflowRights),
            ),
        ),
    )(changes);
    console.log("permissionChanges", {
        apiChanges,
        inputs: { changes, entities, assets, source, workflowRights },
    });
    return apiChanges;
};
export const businessRoleChanges = (
    changes,
    entities,
    assets,
    source,
    workflowRights,
) => {
    const apiChanges = pipe(
        curryN(2, treeToArr)(["businessRoleId"]),
        filter(get(["state", "action"])),
        map(change => ({
            ...change,
            businessRoleId: parseInt(change.businessRoleId, 10),
        })),
        brChanges => {
            const abrProduct = assetBusinessRoleChangeProduct[source.assetKind](
                brChanges,
                assets,
            );
            const entitiesObjs = entities.map(id => ({
                [getEntityIdKey(source)]: id,
            }));
            const withEntities = _.product(abrProduct, entitiesObjs);
            return withEntities.map(([p1, p2]) => ({ ...p1, ...p2 }));
        },
        groupBy(get(["state", "action"])),
        mapValues(
            pipe(
                map(omit(["state", "stateIndex"])),
                removeWorkflowRequestedRights(workflowRights),
            ),
        ),
    )(changes);
    console.log("businessRoleChanges", {
        apiChanges,
        inputs: { changes, entities, assets, source, workflowRights },
    });
    return apiChanges;
};
