import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useMutationLoadable, useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useLoggersListQuery = ({ instanceId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(`api/instances/${instanceId}/loggers`)
                .then(getData),
        [axiosService, instanceId],
    );
};

export const useSetLoggerLevelMutation = ({ instanceId, afterSuccess }) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadable(
        async ({ component, level }) =>
            axiosService
                .post(`api/instances/${instanceId}/loggers/level`, {
                    name: component,
                    level: level,
                })
                .then(getData)
                .then(data => {
                    afterSuccessCurrent();
                    return data;
                }),

        [afterSuccessCurrent, axiosService, instanceId],
    );
};
