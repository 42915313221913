import { colors } from "@/components/DesignSystem/colors";
import moment from "moment";
import React from "react";
import { Chart } from "react-chartjs-2";

const mainChart = {
    labels: [],
    datasets: [
        {
            label: "Incoming",
            backgroundColor: colors.success.background,
            borderColor: colors.success.border,
            pointHoverBackgroundColor: colors.white,
            borderWidth: 2,
            data: [],
        },
        {
            label: "Outgoing",
            backgroundColor: "transparent",
            borderColor: colors.info.border,
            pointHoverBackgroundColor: colors.white,
            borderWidth: 2,
            data: [],
        },
        {
            label: "Events",
            backgroundColor: "transparent",
            borderColor: colors.warning.border,
            pointHoverBackgroundColor: colors.white,
            borderWidth: 2,
            data: [],
        },
        {
            label: "Failed",
            backgroundColor: "transparent",
            borderColor: colors.danger.border,
            pointHoverBackgroundColor: colors.white,
            borderWidth: 2,
            data: [],
        },
    ],
};

const mainChartOpts = {
    tooltips: {
        enabled: false,
        intersect: true,
        mode: "index",
        position: "nearest",
        callbacks: {
            labelColor: function (tooltipItem, chart) {
                return {
                    backgroundColor:
                        chart.data.datasets[tooltipItem.datasetIndex]
                            .borderColor,
                };
            },
        },
    },
    maintainAspectRatio: false,
    legend: {
        display: false,
    },
    scales: {
        xAxes: [
            {
                gridLines: {
                    drawOnChartArea: false,
                },
            },
        ],
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    stepSize: Math.ceil(250 / 5),
                },
            },
        ],
    },
    elements: {
        point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3,
        },
    },
};

export const MessagesChart = ({ from: fromProp, to: toProp, data }) => {
    const from = moment(fromProp);
    const to = moment(toProp);

    var duration = to.diff(from, "days");
    let date = from.clone();

    mainChart.labels = [date.format("DD/MM/YYYY")];

    for (var i = 0; i < duration; i++) {
        date = date.add(1, "days");
        mainChart.labels.push(date.format("DD/MM/YYYY"));
    }

    if (data?.incoming) {
        mainChart.datasets[0].data = data.incoming?.dailyDocCount;
        mainChart.datasets[1].data = data.outgoing?.dailyDocCount;
        mainChart.datasets[2].data = data.events?.dailyDocCount;
        mainChart.datasets[3].data = data.failed?.dailyDocCount;
    }

    return (
        <Chart
            type="line"
            data={mainChart}
            options={mainChartOpts}
            height={300}
        />
    );
};
