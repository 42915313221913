import { tap } from "@/components/Connections/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    UserAbortError,
    useWithConflictModal,
} from "@/components/Mappers/ConflictModal";
import {
    crudSuccess,
    responseErrorMessage,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { getErrorMessageFromError } from "@/utils/state/error.utils";
import { message } from "antd";
import { noop } from "lodash/fp";

export const useInstanceRepoClassesQuery = ({ instanceId, reloadToken }) => {
    const { classService } = useDic();

    return useQueryLoadable(
        async () =>
            classService.fetchInstanceRepoClasses(instanceId).then(getData),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [instanceId, reloadToken, classService],
    );
};
export const useDeleteInstanceRepoClassesMutation = ({ afterSuccess }) => {
    const { classService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record =>
            classService
                .deleteInstanceRepoClass(record.prn, record.id)
                .then(getData)
                .then(data => {
                    afterSuccessCurrent();
                    return { ...record, ...data };
                }),

        [afterSuccessCurrent, classService],
        ({ name }) =>
            t("general.message.deleted", {
                type: "Instance repo class",
                name,
            }),
    );
};

export const useCopyMutation = ({ afterSuccess = noop }) => {
    const { classService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async (classId, targetInstanceid, filename) => {
            const res = await classService.copy(
                classId,
                targetInstanceid,
                filename,
            );
            afterSuccessCurrent();
            return res;
        },
        [afterSuccessCurrent, classService],
        crudSuccess("Class Copy", "data.name", "C"),
    );
};

export const useLocalCopyMutation = ({ afterSuccess = noop }) => {
    const { classService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record => {
            const res = await classService.createLocalCopy(record.id, {
                filename: record.filename,
                parent: record.parent,
            });
            afterSuccessCurrent();
            return res;
        },
        [afterSuccessCurrent, classService],
        crudSuccess("Class Local Copy", "data.name", "C"),
    );
};

export const useClassQuery = ({ classId, reloadToken, canFetch }) => {
    const { classService } = useDic();

    return useQueryLoadable(
        async () =>
            canFetch
                ? classService
                      .fetchClass(classId)
                      .then(getData)
                      .catch(e => {
                          message.error(getErrorMessageFromError(e));
                          throw e;
                      })
                : Promise.resolve({}),
        [canFetch, classId, classService],
    );
};

export const useClassesQuery = ({ instanceId, reloadToken }) => {
    const { classService } = useDic();

    return useQueryLoadable(
        async () =>
            classService
                .fetchClasses(instanceId)
                .then(getData)
                .catch(e => {
                    message.error(getErrorMessageFromError(e));
                    throw e;
                }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [instanceId, reloadToken, classService],
    );
};

export const useDeleteClassMutation = ({ afterSuccess = noop }) => {
    const { classService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record =>
            classService
                .deleteClass(record.id)
                .then(getData)
                .then(data => {
                    afterSuccessCurrent();
                    return { ...record, ...data };
                }),

        [afterSuccessCurrent, classService],
        ({ name }) =>
            t("general.message.deleted", {
                type: "class",
                name,
            }),
    );
};

export const useDeployClassMutation = ({ instanceId, afterSuccess = noop }) => {
    const { classService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const withConflictModal = useWithConflictModal();

    return useMutationLoadableWithNotification(
        async record =>
            withConflictModal(forceCommit =>
                classService.deployClass(instanceId, record.id, {
                    forceCommit,
                }),
            )
                .then(getData)
                .then(tap(afterSuccessCurrent)),

        [afterSuccessCurrent, classService, instanceId, withConflictModal],
        "class-form.deployment.success",
        e => (e instanceof UserAbortError ? null : responseErrorMessage(e)),
    );
};

export const useCreateClassMutation = ({ instanceId, afterSuccess }) => {
    const { classService } = useDic();
    const withConflictModal = useWithConflictModal();

    return useMutationLoadableWithNotification(
        async ({ values, withDeploy }) =>
            withConflictModal(forceCommit =>
                classService.createClass(
                    { instanceId, ...values },
                    {
                        withDeploy,
                        forceCommit,
                    },
                ),
            ).then(response => {
                afterSuccess({
                    classId: response.data.id,
                    withDeploy,
                });
                return withDeploy;
            }),
        [withConflictModal, classService, instanceId, afterSuccess],
        withDeploy =>
            withDeploy
                ? t("class-form.created-and-deployed")
                : t("class-form.created"),
        e => (e instanceof UserAbortError ? null : responseErrorMessage(e)),
    );
};

export const useSaveClassMutation = ({ instanceId, classId, afterSuccess }) => {
    const { classService } = useDic();
    const withConflictModal = useWithConflictModal();

    return useMutationLoadableWithNotification(
        async ({ values, withDeploy }) =>
            withConflictModal(forceCommit =>
                classService.saveClass(
                    classId,
                    { instanceId, ...values },
                    {
                        withDeploy,
                        forceCommit,
                    },
                ),
            ).then(() => {
                afterSuccess({ classId, withDeploy });
                return withDeploy;
            }),
        [withConflictModal, classService, classId, instanceId, afterSuccess],
        withDeploy =>
            withDeploy
                ? t("class-form.updated-and-deployed")
                : t("class-form.updated"),
        e => (e instanceof UserAbortError ? null : responseErrorMessage(e)),
    );
};
