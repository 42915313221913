import {
    Button,
    ButtonGroup,
    Forms,
    Gap,
    H3,
    Text,
} from "@/components/DesignSystem";
import { isLoading, LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {
    usePostSsoUsernameResource,
    useSsoUsernameResource,
} from "./SsoUsername.loadables";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";

const SsoUsername = () => {
    useSetPageTitle(t("profile.nav-item.sso-username"));
    const ssoUsernameResource = useSsoUsernameResource();
    const postSsoUsernameResource = usePostSsoUsernameResource({
        afterSave: () => {
            ssoUsernameResource.reload();
        },
    });

    const submitDisabled =
        ssoUsernameResource.loadable.state !== "hasValue" ||
        isLoading(postSsoUsernameResource);

    const onSsoLoginSubmit = ({ values }) => {
        postSsoUsernameResource.mutate({
            ssoLogin: values.ssoLogin,
        });
    };

    return (
        <>
            <LoadableRenderer
                loadable={ssoUsernameResource.loadable}
                hasValue={value => {
                    return (
                        <>
                            <Text>
                                {t("profile.sso-username.text")}
                                <Gap size="small" />
                            </Text>
                            <SsoUsernameForm
                                ssoLogin={value.ssoLogin}
                                onSubmit={onSsoLoginSubmit}
                                submitDisabled={submitDisabled}
                            />
                        </>
                    );
                }}
                hasError={e => (
                    <LoadableRenderer.Error>
                        {getErrorMessage(e)}
                    </LoadableRenderer.Error>
                )}
            />
        </>
    );
};

const SsoUsernameForm = ({ ssoLogin, onSubmit, submitDisabled }) => {
    const { useForm, Form, Fields, SubmitButton } = Forms;
    const { formId, handleSubmit, setValues } = useForm({
        onSubmit,
    });

    useEffect(() => {
        setValues({ ssoLogin });
    }, [setValues, ssoLogin]);

    return (
        <Form formId={formId} onSubmit={handleSubmit}>
            <Fields.Input
                name="ssoLogin"
                label={t("profile.nav-item.sso-username")}
            />
            <ButtonGroup>
                <SubmitButton>
                    <Button
                        label={t("general.save")}
                        type="primary"
                        htmlType="submit"
                        submitDisabled={submitDisabled}
                    />
                </SubmitButton>
            </ButtonGroup>
        </Form>
    );
};

SsoUsernameForm.propTypes = {
    ssoLogin: PropTypes.string,
    onSubmit: PropTypes.func,
    submitDisabled: PropTypes.bool,
};

export default SsoUsername;
