import { DeploymentStatus } from "@/components/DeployedPackages/DeployedPackagesList.component";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { useUserBasicInfo } from "@/security/hooks/useUserBasicInfo.hook";
import React from "react";
import { isPricefxUsername } from "../../../accountApp/components/AccountUserAdminSettings/RightsDrawer/loadables";
import { FailedDeploymentsList } from "./FailedDeploymentsList.component";

export const FailedDeploymentsListContainer =
    function DeployedPackagesListContainer() {
        const { packageService } = useDic();
        const { accountId } = useAccountAppParams();
        const basicInfo = useUserBasicInfo();
        const isInternalUser = isPricefxUsername(
            basicInfo.userInfo.user.username,
        );

        const [tableProps] = useFetchPage(
            (page, size, sort, filter) =>
                isInternalUser
                    ? packageService.getAllDeployedPackages(
                          page,
                          size,
                          sort,
                          filter,
                      )
                    : packageService.getDeployedPackagesFiltered(
                          page,
                          size,
                          sort,
                          filter,
                          accountId,
                      ),
            [],
        );
        return (
            <FailedDeploymentsList
                accountId={isInternalUser ? undefined : accountId}
                {...tableProps}
                subsetFilter={{
                    criteria: [
                        {
                            fieldName: "status",
                            operator: "equals",
                            value: DeploymentStatus.FAILED,
                        },
                    ],
                    operator: "and",
                    _constructor: "AdvancedCriteria",
                }}
            />
        );
    };
