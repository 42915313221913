import { Gap, Input, Option, Select } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useQueryLoadable } from "@/modules/loadable";
import { T } from "@/translations";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { apiMetaToOptions } from "../metadata.utils";
import { MetadataAdvancedOptions } from "./MetadataAdvancedOptions";
import { OptionsLoadableErrors } from "./OptionsLoadableErrors";

const useGeneralMapperOptionsResource = ({
    instanceId,
    connectionName,
    configurationName,
    connectionSelectErrors,
    fetchParams,
}) => {
    const { metadataService } = useDic();
    const resource = useQueryLoadable(async () => {
        if (!instanceId) throw ["Missing instanceId ID"];
        if (!connectionName) throw ["Missing connectionName"];
        if (!configurationName) throw ["Missing configurationName"];
        if (connectionSelectErrors?.length) throw connectionSelectErrors;

        const connectionOptions = await metadataService
            .fetchGeneralMapperInstanceMetadata(
                instanceId,
                connectionName,
                configurationName,
                fetchParams,
            )
            .then(({ data }) => apiMetaToOptions(data));

        return connectionOptions; // connectionOptionsMock;
    }, [
        instanceId,
        connectionName,
        configurationName,
        fetchParams,
        metadataService,
        connectionSelectErrors,
    ]);

    return resource;
};

export const ConnectionConfigurationSelectOptionsResourceFetcher = ({
    maybeSelectedConnection,
    setOptionsResource,
    instanceId,
    resetResource,
    setParams,
}) => {
    const [configurationName, setConfigurationName] = useState();
    const [fetchParams, setFetchParams] = useState({});
    const [uri, setUri] = useState("");
    const configurationOptions = useMemo(() => {
        if (!maybeSelectedConnection?.configurations) return [];

        const options = maybeSelectedConnection?.configurations.reduce(
            (acc, { configurationNames, systemName }) =>
                acc.concat(
                    configurationNames?.map(name => ({
                        label: [systemName, name].filter(Boolean).join(" - "),
                        value: name,
                    })),
                ),
            [],
        );
        return options;
    }, [maybeSelectedConnection, maybeSelectedConnection?.configurations]);
    // const connectionSelect = useConnectionConfigurationSelect({ connections });
    const optionsResource = useGeneralMapperOptionsResource({
        instanceId,
        connectionName: maybeSelectedConnection?.connectionName,
        configurationName,
        connectionSelectErrors: undefined,
        fetchParams,
    });
    useEffect(() => {
        setOptionsResource(optionsResource);
    }, [optionsResource.loadable]);
    useEffect(
        // Reset state on unmount
        () => () => {
            setParams({});
            resetResource();
        },
        [],
    );
    useEffect(() => {
        setParams({
            connection: maybeSelectedConnection || {},
            uri,
            configurationName,
            metadataUri: fetchParams?.metadataUri,
            systemName: maybeSelectedConnection?.configurations?.find(conf =>
                conf.configurationNames.some(
                    name => name === configurationName,
                ),
            )?.systemName,
        });
    }, [
        maybeSelectedConnection,
        configurationName,
        uri,
        setParams,
        fetchParams?.metadataUri,
    ]);

    return (
        <div data-test="configuration-select">
            {maybeSelectedConnection?.connectionName && (
                <>
                    <div>
                        <T id="metadata-params-form.configuration.label" />
                        <Select
                            showSearch
                            name="configurationName"
                            placeholder="Select configuration"
                            value={configurationName}
                            onChange={setConfigurationName}
                        >
                            {configurationOptions.map(({ value, label }) => (
                                <Option title={label} key={value}>
                                    {label}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <Gap size="small" />
                </>
            )}
            {maybeSelectedConnection && (
                <>
                    <T id="metadata-params-form.connection-uri.label" />
                    <Input
                        placeholder="Connection uri"
                        onChange={e => setUri(e.target.value)}
                        value={uri}
                    />
                    <Gap size="small" />
                </>
            )}
            {maybeSelectedConnection && configurationName && (
                <MetadataAdvancedOptions setFetchParams={setFetchParams} />
            )}
            <OptionsLoadableErrors loadable={optionsResource.loadable} />
        </div>
    );
};

ConnectionConfigurationSelectOptionsResourceFetcher.propTypes = {
    setParams: PropTypes.func.isRequired,
};
