import { Forms } from "@/components/DesignSystem";
import { useUnsavedWarning } from "@/modules/router/PreventTransition/hooks/useUnsavedWarning.hook";
import { t } from "@/translations";
import React, { Suspense } from "react";

const ConfirmLeaveInner = ({ formId, onConfirm, onCancel }) => {
    const { dirty } = Forms.useFormDirty(formId);

    useUnsavedWarning(dirty, {
        title: t("feature-flags.unsaved-warning.confirm.title"),
        message: t("feature-flags.unsaved-warning.confirm.message"),
        onConfirm,
        onCancel,
    });
    return null;
};

export const ConfirmLeave = props => (
    <Suspense fallback={null}>
        <ConfirmLeaveInner {...props} />
    </Suspense>
);
