import AccountsPage from "@/components/Accounts/Accounts.page";
import ClusterAdminPage from "@/components/Clusters/ClusterAdmin.page";
import ClusterListPage from "@/components/Clusters/ClustersList.page";
import { IntegrationArchivesList } from "@/components/IntegrationArchives/IntegrationArchivesList";
import ClustersVersionHistoryPage from "@/components/Integrations/ClustersVersionHistory.overview.page";
import IntegrationsOverviewPage from "@/components/Integrations/Integrations.overview.page";
import IntegrationsVersionHistoryPage from "@/components/Integrations/IntegrationsVersionHistory.overview.page";
import PartitionsVersionHistoryPage from "@/components/Integrations/PartitionsVersionHistory.overview.page";
import NodesListPage from "@/components/Nodes/NodesList.page";
import PartitionsPage from "@/components/Partitions/Partitions.overview.page";
import RemoteLoginPage from "@/components/RemoteLogin/RemoteLogin.page";
import { NewServerPage } from "@/components/Servers/NewServer.page";
import ServersListPage from "@/components/Servers/ServersList.page";
import { UpdatesPage } from "@/components/Updates/Updates.page";
import { ClusterUpgradeOverview } from "@/components/UpgradeScheduling/ClusterUpgrade.overview.page";
import { UpgradeSlotsPage } from "@/components/UpgradeScheduling/UpgradeSlotsPage";
import { createLocation } from "@/modules/router";
import {
    ACCOUNT_LIST_READ_PERMISSION,
    CLUSTERS_LIST_READ_PERMISSIONS,
    CLUSTER_ADMIN,
    CLUSTER_UPGRADES_LIST_READ_PERMISSIONS,
    CLUSTER_UPGRADES_SLOTS_OVERVIEW_PERMISSIONS,
    INTEGRATION_LIST_READ_PERMISSION,
    NODES_LIST_READ_PERMISSION,
    PARTITION_LIST_READ_PERMISSION,
    REMOTE_LOGIN_PERMISSIONS,
    SERVERS_PERMISSION,
    UPDATES_READ_PERMISSION,
} from "@/security/permission.utils";
import { overviewAppLocation } from "../../locations";

const accountsLocation = createLocation(
    overviewAppLocation,
    "accounts",
    "/accounts",
    {
        component: AccountsPage,
        permission: ACCOUNT_LIST_READ_PERMISSION,
    },
);

const partitionsLocation = createLocation(
    overviewAppLocation,
    "partitions",
    "/partitions",
    {
        component: PartitionsPage,
        permission: PARTITION_LIST_READ_PERMISSION,
    },
);
const integrationsLocation = createLocation(
    overviewAppLocation,
    "integrations",
    "/integrations",
    {
        component: IntegrationsOverviewPage,
        permission: INTEGRATION_LIST_READ_PERMISSION,
    },
);

const nodesLocation = createLocation(overviewAppLocation, "nodes", "/nodes", {
    component: NodesListPage,
    permission: NODES_LIST_READ_PERMISSION,
});

const updatesLocation = createLocation(
    overviewAppLocation,
    "updates",
    "/updates",
    {
        component: UpdatesPage,
        permission: UPDATES_READ_PERMISSION,
    },
);

const remoteLoginLocation = createLocation(
    overviewAppLocation,
    "remoteLogin",
    "/remote-login",
    {
        component: RemoteLoginPage,
        permission: REMOTE_LOGIN_PERMISSIONS,
    },
);

const serversLocation = createLocation(
    overviewAppLocation,
    "servers",
    "/servers",
    {
        permission: SERVERS_PERMISSION,
        component: ServersListPage,
    },
);

const newServerLocation = createLocation(serversLocation, "new", "/new", {
    permission: SERVERS_PERMISSION,
    component: NewServerPage,
});

const upgradesLocation = createLocation(
    overviewAppLocation,
    "upgrades",
    "/upgrades",
    {
        component: ClusterUpgradeOverview,
        permission: [
            CLUSTER_UPGRADES_LIST_READ_PERMISSIONS,
            CLUSTER_UPGRADES_SLOTS_OVERVIEW_PERMISSIONS,
        ],
    },
);

const upgradesSlotsLocation = createLocation(
    upgradesLocation,
    "slots",
    "/slots",
    {
        component: UpgradeSlotsPage,
        permission: CLUSTER_UPGRADES_SLOTS_OVERVIEW_PERMISSIONS,
    },
);

const clustersLocation = createLocation(
    overviewAppLocation,
    "clusters",
    "/clusters",
    {
        component: ClusterListPage,
        permission: CLUSTERS_LIST_READ_PERMISSIONS,
    },
);

const clusterAdminLocation = createLocation(
    clustersLocation,
    "clusterAdmin",
    "/admin/:clusterName",
    {
        component: ClusterAdminPage,
        permission: CLUSTER_ADMIN,
    },
);

const integrationArchivesLocation = createLocation(
    overviewAppLocation,
    "integrationArchives",
    "/integration-archives",
    {
        component: IntegrationArchivesList,
        permission: [INTEGRATION_LIST_READ_PERMISSION],
    },
);

const clustersVersionHistoryLocation = createLocation(
    clustersLocation,
    "clusters-version-history",
    "/version-history/:entityType/:entityId",
    {
        component: ClustersVersionHistoryPage,
        permission: CLUSTERS_LIST_READ_PERMISSIONS,
    },
);

const partitionsVersionHistoryLocation = createLocation(
    partitionsLocation,
    "partitions-version-history",
    "/version-history/:entityType/:entityId",
    {
        component: PartitionsVersionHistoryPage,
        permission: PARTITION_LIST_READ_PERMISSION,
    },
);

const integrationsVersionHistoryLocation = createLocation(
    integrationsLocation,
    "integrations-version-history",
    "/version-history/:entityType/:entityId",
    {
        component: IntegrationsVersionHistoryPage,
        permission: INTEGRATION_LIST_READ_PERMISSION,
    },
);

export const overviewAppLocationsMap = {
    accountsLocation,
    clustersLocation,
    clusterAdminLocation,
    nodesLocation,
    partitionsLocation,
    integrationsLocation,
    updatesLocation,
    remoteLoginLocation,
    serversLocation,
    newServerLocation,
    upgradesLocation,
    upgradesSlotsLocation,
    integrationArchivesLocation,
    integrationsVersionHistoryLocation,
    clustersVersionHistoryLocation,
    partitionsVersionHistoryLocation,
};
