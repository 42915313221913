import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { colors } from "@/components/DesignSystem/colors";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { DEPLOYMENT_STATUS } from "@/components/IntegrationRoutes/InstanceRepoIntegrationsRoutes";
import { IconWithTooltip } from "@/components/Integrations/components/IconWithTooltip";
import { t } from "@/translations";
import React from "react";

export const instanceRepoClassesColumns = () => [
    {
        type: fieldTypes.TEXT,
        label: t("classes-list.header.name"),
        name: "filename",
    },
    // {
    //     type: fieldTypes.TEXT,
    //     label: t("classes-list.header.status"),
    //     name: "parent"
    // },
    // {
    //     type: fieldTypes.TEXT,
    //     label: t("classes-list.header.parent"),
    //     name: "parent"
    // },
    {
        type: fieldTypes.TEXT,
        label: t("classes-list.header.revisionNumber"),
        name: "revisionNumber",
    },
    // {
    //     type: fieldTypes.DATETIME,
    //     label: t("classes-list.header.createdAt"),
    //     name: "createdAt",
    //     render: (text, d) => (
    //         <FormattedDateTime>{d.createdAt}</FormattedDateTime>
    //     )
    // },
    {
        type: fieldTypes.TEXT,
        label: t("customer-integration-list.header.deployment-status"),
        name: "deploymentStatus",
        render: (text, record) => (
            <>
                <IconWithTooltip
                    display={
                        record.deploymentStatus === DEPLOYMENT_STATUS.FAILED
                    }
                    tooltip={record.message}
                    icon={"info-circle"}
                    color={colors.danger.primary}
                />
                {text}
            </>
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("classes-list.header.createdBy"),
        name: "updatedBy",
    },
    {
        type: fieldTypes.DATETIME,
        label: t("classes-list.header.updatedAt"),
        name: "updatedAt",
        render: (text, d) => (
            <FormattedDateTime>{d.updatedAt}</FormattedDateTime>
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("classes-list.header.path"),
        name: "path",
        visible: false,
    },
];
