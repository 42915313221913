import { T } from "@/translations";
import { selectChangedAt } from "@/utils/tableUtils";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

const Changed = ({ data }) => {
    const updatedAt = selectChangedAt(data);
    const humanReadableDifference = moment(updatedAt).fromNow();
    const updatedBy =
        data.updatedBy === undefined ? data.createdBy : data.updatedBy;
    return (
        <T
            id="instance-list.header.updated"
            values={{ when: humanReadableDifference, whom: updatedBy }}
        />
    );
};

export default Changed;

Changed.propTypes = {
    data: PropTypes.object.isRequired,
};
