import { useDic } from "@/components/Dic/useDic.hook";
import { useQueryLoadable } from "@/modules/loadable";

export const useAccountGroups = accountId => {
    const { adminApiService } = useDic();
    const accountGroupsResource = useQueryLoadable(
        async () => adminApiService.fetchProjectGroups(accountId),
        [accountId, adminApiService],
    );
    return accountGroupsResource;
};
