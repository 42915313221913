import { useDic } from "@/components/Dic/useDic.hook";
import { EventSchedulerForm } from "@/components/EventSchedulers/EventSchedulerForm";
import {
    useEventSchedulerQuery,
    useUpdateEventSchedulerMutation,
} from "@/components/EventSchedulers/loadables";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { LoadableRenderer } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";

export const EditEventSchedulerPage = () => {
    const { accountId, schedulerId } = useAccountAppParams();
    useSetPageTitle(
        t("location.accountApp.eventOrchestration.eventSchedulers.edit"),
    );
    const {
        locationRouterService,
        accountAppLocations: { eventSchedulersLocation },
    } = useDic();
    const onCancel = useCallback(
        () => locationRouterService.navigate(eventSchedulersLocation),
        [eventSchedulersLocation, locationRouterService],
    );
    useSetBackButtonAction(onCancel);
    const eventWFQuery = useEventSchedulerQuery({ accountId, schedulerId });
    const editMutation = useUpdateEventSchedulerMutation({
        accountId,
        schedulerId,
        afterSuccess: onCancel,
    });

    return (
        <LoadableRenderer
            loadable={eventWFQuery.loadable}
            hasValue={initialValues => (
                <EventSchedulerForm
                    accountId={accountId}
                    initialValues={initialValues}
                    onSubmit={editMutation.mutate}
                    onCancel={onCancel}
                />
            )}
        />
    );
};
