import { Button, Gap, H3, Text } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { updateStep } from "@/components/Integrations/ImUpdate/ImUpdate.component";
import { STEPS_ESTIMATIONS_SECONDS } from "@/components/Integrations/ImUpdate/constants";
import {
    isProcessDone,
    isProcessRunning,
    useStepErrorHandling,
} from "@/components/Integrations/ImUpdate/helpers";
import { useIntegrationUpdateStepState } from "@/components/Integrations/ImUpdate/loadables";
import Progress from "@/components/Progress/Progress";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { ReactComponent as AngleRight } from "@pricefx/unity-components/src/icons/unicons/angle-right-b.svg";
import PropTypes from "prop-types";
import React from "react";
import styles from "../styles.less";

export const CodeModificationStep = ({
    instanceId,
    process,
    onNextStep,
    onBack,
    onCancel,
    wasAlreadyCompleted = false,
}) => {
    const { integrationManagerUpdateService, messageService } = useDic();

    const modificationResource = useIntegrationUpdateStepState({
        instanceId,
        step: "modification",
        canFetch: isProcessDone(process),
    });

    const modificationResult = modificationResource.loadable.valueMaybe();
    useStepErrorHandling(modificationResult);

    return (
        <>
            {isProcessRunning(process) ? (
                <>
                    <Text size="large">
                        {t("im-instance.update.code-modification.in-progress", {
                            seconds: STEPS_ESTIMATIONS_SECONDS.MODIFICATION,
                        })}
                    </Text>
                    <Progress
                        className={styles.progress}
                        showInfo
                        percent={process?.progress}
                        message={t(
                            "im-instance.update.code-modification.progress",
                        )}
                    />
                </>
            ) : isProcessDone(process) ? (
                <>
                    <H3 data-test="code-modification-result-title">
                        {t("im-instance.update.code-modification.result")}
                    </H3>
                    <Text size="large">
                        {t("im-instance.update.code-modification.success")}
                    </Text>
                    <Gap size="medium" />

                    <H3>{t("im-instance.update.continue-to-next-step")}</H3>
                    <Text size="large">
                        {t(
                            "im-instance.update.code-modification.continue-to-next-step-perex",
                        )}
                    </Text>
                </>
            ) : null}
            <>
                <Gap size="medium" />
                {modificationResult?.exception ? (
                    <Button
                        type="primary"
                        label={t(
                            "im-instance.update.button.create-support-ticket",
                        )}
                        rightIcon={AngleRight}
                        // TODO
                        // onClick={onNextStep}
                    />
                ) : (
                    <Button
                        type="primary"
                        label={t("im-instance.update.button.test-update")}
                        data-test="test-update-button"
                        rightIcon={AngleRight}
                        disabled={isProcessRunning(process)}
                        onClick={() => {
                            if (!wasAlreadyCompleted) {
                                integrationManagerUpdateService
                                    .startTest(instanceId)
                                    .then(getData)
                                    .then(onNextStep(updateStep.TEST_UPDATE))
                                    .catch(e =>
                                        messageService.error({
                                            content: getErrorMessage(
                                                e.response.data,
                                            ),
                                        }),
                                    );
                            } else {
                                onNextStep(updateStep.TEST_UPDATE)();
                            }
                        }}
                    />
                )}
                <Button
                    label={t("general.back")}
                    data-test="back-button"
                    disabled={isProcessRunning(process)}
                    onClick={() => onBack(updateStep.CODE_ANALYSIS)}
                />
                <Button
                    type="link"
                    data-test="cancel-button"
                    label={t("general.cancel")}
                    onClick={onCancel}
                />
            </>
        </>
    );
};

CodeModificationStep.propTypes = {
    instanceId: PropTypes.number.isRequired,
    process: PropTypes.object.isRequired,
    onNextStep: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    wasAlreadyCompleted: PropTypes.bool,
};
