import { LoadableRenderer } from "@/modules/loadable";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { AdminRightsDrawer } from "./AdminRightsDrawer";
import {
    useAdminGroupRightsResource,
    useAdminGroupWorkflowRightsResource,
    useDisplaySuperAssetsForGroupResource,
    useSaveAdminRights,
} from "./loadables";
import { ENTITY_KIND } from "./PermissionsDrawer/sources.utils";

export const AdminGroupRightsDrawer = React.memo(
    function AdminGroupRightsDrawer({
        groupNames = [],
        onClose,
        visible = true,
    }) {
        const rightsResource = useAdminGroupRightsResource({
            canFetch: visible && !!groupNames?.length,
            groupNames,
        });
        const workflowRightsResource = useAdminGroupWorkflowRightsResource({
            canFetch: visible && !!groupNames?.length,
            groupNames,
        });
        const displaySuperAssetsForGroupResource =
            useDisplaySuperAssetsForGroupResource({
                canFetch: visible,
                groupNames,
            });
        const afterSave = useCallback(() => {
            rightsResource.reload();
            onClose();
        }, [onClose, rightsResource]);
        const saveRightsResource = useSaveAdminRights({
            afterSave,
        });

        const assetsWithRightsFetchParams = useMemo(
            () => ({
                groupNames,
            }),
            [JSON.stringify(groupNames)],
        );

        return (
            <LoadableRenderer
                loadable={displaySuperAssetsForGroupResource.loadable}
                loading={() => null}
                hasValue={displaySuperAssets => (
                    <AdminRightsDrawer
                        entityKind={ENTITY_KIND.GROUP}
                        selectedEntities={groupNames}
                        assetsWithRightsFetchParams={
                            assetsWithRightsFetchParams
                        }
                        rightsResource={rightsResource}
                        workflowRightsResource={workflowRightsResource}
                        saveRightsResource={saveRightsResource}
                        onClose={onClose}
                        visible={visible}
                        displaySuperAssets={displaySuperAssets}
                    />
                )}
            />
        );
    },
);

AdminGroupRightsDrawer.propTypes = {
    groupNames: PropTypes.arrayOf(PropTypes.string.isRequired),
    onClose: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
};
