import _ from "lodash";
import "lodash.product";
import flatMap from "lodash/fp/flatMap";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import groupBy from "lodash/fp/groupBy";
import map from "lodash/fp/map";
import mapValues from "lodash/fp/mapValues";
import pipe from "lodash/fp/pipe";
import uniq from "lodash/fp/uniq";
import overSome from "lodash/fp/overSome";
import countBy from "lodash/fp/countBy";
import { getPermissionsTooltip, isEditable, serialize } from "../sources.utils";

const quantifyBy = (fn, arr, getEmptyArrayQuantifier = () => "none") => {
    const totalCount = arr.length;
    const { truthy, falsy } = countBy(
        val => (fn(val) ? "truthy" : "falsy"),
        arr,
    );

    switch (totalCount) {
        case 0:
            return getEmptyArrayQuantifier(); // the best would be to throw, but it would require ErrorBoundary for handling not visible but rendered drawers
        // throw new Error("Cannot quantify empty array");
        case truthy:
            return "all";
        case falsy:
            return "none";
        default:
            return "some";
    }
};

const getCheckboxUIStates = selectionProduct => {
    // TODO: remove following - doesn't depend on quantification, can be directly in UI, probably some leftover
    const disabled = selectionProduct.some(({ disabled }) => disabled);

    const readOnlyUiQuantifier = quantifyBy(
        overSome([get("readOnly"), get("workflowApprovalRequested")]),
        selectionProduct,
    );

    if (readOnlyUiQuantifier === "all") {
        return [
            {
                disabled: true,
                checked: true,
                indeterminate: false,
            },
        ];
    } else if (readOnlyUiQuantifier === "some") {
        const checkedQuantifier = quantifyBy(
            overSome([
                get("readOnly"),
                get("workflowApprovalRequested"),
                get("editable"),
            ]),
            selectionProduct,
        );
        if (checkedQuantifier === "all") {
            return [
                {
                    disabled: disabled,
                    checked: true,
                    indeterminate: false,
                },
                {
                    disabled: disabled,
                    checked: false,
                    indeterminate: true,
                    action: "remove",
                },
            ];
        } else {
            // not possible to differentiate two possible indeterminate states in UI:
            // 1/ some editable and some read only permissions granted
            // 2/ none editable granted, but some read only granted
            // for both cases, permissions are added, but when some are granted, they cannot be removed in batch mode
            return [
                {
                    disabled: disabled,
                    checked: false,
                    indeterminate: true,
                },
                {
                    disabled: disabled,
                    checked: true,
                    indeterminate: false,
                    action: "add",
                },
            ];
        }
    } else if (readOnlyUiQuantifier === "none") {
        const editableQuantifier = quantifyBy(
            get("editable"),
            selectionProduct,
        );
        if (editableQuantifier === "all") {
            return [
                {
                    disabled: disabled,
                    checked: true,
                    indeterminate: false,
                },
                {
                    disabled: disabled,
                    checked: false,
                    indeterminate: false,
                    action: "remove",
                },
            ];
        } else if (editableQuantifier === "some") {
            return [
                {
                    disabled: disabled,
                    checked: false,
                    indeterminate: true,
                },
                {
                    disabled: disabled,
                    checked: true,
                    indeterminate: false,
                    action: "add",
                },
                {
                    disabled: disabled,
                    checked: false,
                    indeterminate: false,
                    action: "remove",
                },
            ];
        } else if (editableQuantifier === "none") {
            return [
                {
                    disabled: disabled,
                    checked: false,
                    indeterminate: false,
                },
                {
                    disabled: disabled,
                    checked: true,
                    indeterminate: false,
                    action: "add",
                },
            ];
        } else {
            throw new Error(
                `Unknown editableQuantifier "${editableQuantifier}"`,
            );
        }
    } else {
        throw new Error(
            `Unknown readOnlyUiQuantifier "${readOnlyUiQuantifier}"`,
        );
    }
};

const getReadOnlySourcesText = selectionProduct => {
    const serializedReadOnlySources = pipe(
        flatMap(get("serializedReadOnlySources")),
        uniq,
    )(selectionProduct);
    const readOnlyQuantifier = quantifyBy(get("readOnly"), selectionProduct);

    return getPermissionsTooltip(serializedReadOnlySources)(
        readOnlyQuantifier + " items",
    );
};

const getWorkflowTooltipText = selectionProduct => {
    const quantifier = quantifyBy(
        get("workflowApprovalRequested"),
        selectionProduct,
    );
    if (quantifier === "none") return "";
    return `Workflow approval process already running for ${quantifier} items.`;
};

const getTooltipText = selectionProduct => {
    const tooltipText = [
        getReadOnlySourcesText(selectionProduct),
        getWorkflowTooltipText(selectionProduct),
    ]
        .filter(Boolean)
        .join("\n");
    return tooltipText;
};

export const mapPermissions = (
    editableSources,
    rightsMap,
    wfRightsMap,
    selectedEntities,
    permissions,
    filterGranting,
    getPermissionSignificantSelection,
) =>
    mapValues(
        map(permission => {
            const { readOnly = [], editable = [] } = pipe(
                getOr([], [permission.type, permission.name]),
                groupBy(isEditable(editableSources)),
            )(rightsMap);
            const wfRequests = getOr(
                [],
                [permission.type, permission.name],
                wfRightsMap,
            );
            const assetEntities =
                getPermissionSignificantSelection(permission) ?? [];
            const selectionProduct = _.product(
                assetEntities,
                selectedEntities,
            ).map(([assetEntity, entityId]) => {
                const readOnlyGrantingRecords = filterGranting(
                    entityId,
                    assetEntity,
                    permission,
                    readOnly,
                );
                const wfRequestRecords = filterGranting(
                    entityId,
                    assetEntity,
                    permission,
                    wfRequests,
                );
                return {
                    entityId,
                    assetEntity,
                    editable: !!filterGranting(
                        entityId,
                        assetEntity,
                        permission,
                        editable,
                    ).length,
                    readOnly: !!readOnlyGrantingRecords.length,
                    serializedReadOnlySources: readOnlyGrantingRecords.map(
                        ({ source }) => serialize(source),
                    ),
                    workflowApprovalRequested: !!wfRequestRecords.length,
                };
            });

            const checkboxUIStates = getCheckboxUIStates(selectionProduct);
            const checkboxUIStatesWithTooltips = checkboxUIStates.map(
                state => ({
                    ...state,
                    tooltipText: getTooltipText(selectionProduct),
                }),
            );

            return {
                ...permission,
                selectionProduct,
                states: checkboxUIStatesWithTooltips,
            };
        }),
        permissions,
    );

export const mapBusinessRoles = (
    editableSources,
    rightsMap,
    wfRightsMap,
    selectedEntities,
    businessRoles,
    filterGranting,
    getRoleSignificantSelection,
) =>
    businessRoles.map(role => {
        const { readOnly, editable } = pipe(
            getOr([], [`${role.id}`]),
            groupBy(isEditable(editableSources)),
        )(rightsMap);
        const wfRequests = getOr([], [`${role.id}`], wfRightsMap);
        const assetEntities = getRoleSignificantSelection(role) ?? [];
        const selectionProduct = _.product(assetEntities, selectedEntities).map(
            ([assetEntity, entityId]) => {
                const readOnlyGrantingRecords = filterGranting(
                    entityId,
                    assetEntity,
                    role,
                    readOnly,
                );
                const wfRequestRecords = filterGranting(
                    entityId,
                    assetEntity,
                    role,
                    wfRequests,
                );
                return {
                    entityId,
                    assetEntity,
                    editable: !!filterGranting(
                        entityId,
                        assetEntity,
                        role,
                        editable,
                    ).length,
                    readOnly: !!readOnlyGrantingRecords.length,
                    serializedReadOnlySources: readOnlyGrantingRecords.map(
                        ({ source }) => serialize(source),
                    ),
                    workflowApprovalRequested: !!wfRequestRecords.length,
                };
            },
        );

        const checkboxUIStates = getCheckboxUIStates(selectionProduct);
        const checkboxUIStatesWithTooltips = checkboxUIStates.map(state => ({
            ...state,
            tooltipText: getTooltipText(selectionProduct),
        }));

        return {
            ...role,
            selectionProduct,
            states: checkboxUIStatesWithTooltips,
        };
    });
