import { $values } from "@pricefx/unity-components/dist/es/components/Forms/selectors";
import isMatchWith from "lodash/fp/isMatchWith";
import pipe from "lodash/fp/pipe";
import { useRecoilValueLoadable } from "recoil";

export const useMaybeFormValues = formId =>
    pipe($values, useRecoilValueLoadable, loadable => loadable.valueMaybe())(
        formId,
    );

const sameOrBothFalsy = (objVal, srcVal) =>
    !objVal && !srcVal ? true : undefined;

export const useIsPristineWith = ({
    formId,
    initialValues,
    customizer = sameOrBothFalsy,
}) => {
    const maybeFormValues = useMaybeFormValues(formId); // Without memoized/constant reference validators enters endless cycle
    return isMatchWith(customizer, maybeFormValues, initialValues);
};
