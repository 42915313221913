import { Col, Row } from "@/components/DesignSystem";
import { CodeMirror } from "@/components/DesignSystem/CodeMirror";
import { t } from "@/translations";
import React, { useEffect, useState } from "react";
import { routesService } from "../../services/routes.service";
import ConditionalErrorAlert from "../Error/ConditionalErrorAlert";

export const RouteDefinitionDiff = ({ instanceId, routeId }) => {
    const [platformContext, setPlatformContext] = useState({
        definition: undefined,
        error: undefined,
    });
    const [imContext, setImContext] = useState({
        definition: undefined,
        error: undefined,
    });

    useEffect(() => {
        if (routeId === undefined) {
            return;
        }

        setImContext({ definition: undefined, error: undefined });

        routesService
            .fetchRoute(routeId)
            .then(response => {
                setPlatformContext({
                    definition: response.xml,
                    error: undefined,
                });
                if (response.status != "DRAFT") {
                    routesService
                        .fetchRouteDefinitionFromRepository(instanceId, routeId)
                        .then(response => {
                            setImContext({
                                definition: response.data,
                                error: undefined,
                            });
                        })
                        .catch(error => {
                            setImContext({
                                definition: undefined,
                                error: t(
                                    "route-definition-diff.im.definition.error",
                                ),
                            });
                        });
                }
            })
            .catch(error => {
                setPlatformContext({
                    definition: undefined,
                    error: t("route-definition-diff.im.definition.error"),
                });
            });
    }, [routeId]);

    return (
        <Row>
            <Col sm={12}>
                <ConditionalErrorAlert error={platformContext.error} />
                {platformContext.definition && (
                    <CodeMirror
                        value={platformContext.definition}
                        options={{
                            mode: "xml",
                            lineNumbers: true,
                            readOnly: true,
                        }}
                    />
                )}
            </Col>
            <Col sm={12}>
                <ConditionalErrorAlert error={imContext.error} />
                {imContext.definition && (
                    <CodeMirror
                        value={imContext.definition}
                        options={{
                            mode: "xml",
                            lineNumbers: true,
                            readOnly: true,
                        }}
                    />
                )}
            </Col>
        </Row>
    );
};
