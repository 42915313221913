import { useDic } from "@/components/Dic/useDic.hook";
import { pendingPromise, useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useAccountInstancesQuery = ({
    accountId,
    canFetch = !!accountId,
}) => {
    const { accountsService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : accountsService.fetchInstances(accountId).then(getData),
        [accountId, accountsService, canFetch],
    );
};
