import Changed from "@/components/DateTime/Changed";
import { Icon } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { ParentWarning } from "@/components/Mappers/ParentWarning";
import { IntegrationType } from "@/constants/CommonConstats";
import { t } from "@/translations";
import { getColumnOptions, selectChangedAt } from "@/utils/tableUtils";
import { Tooltip } from "antd";
import React from "react";
import { RouteStatus } from "./components/RouteStatus";

export const routesColumns = ({ routes, onShowDiff }) => [
    {
        type: fieldTypes.TEXT,
        label: t("customer-integration-list.header.name"),
        name: "name",
        className: "pmTable__column--withActionButton",
        visible: false,
    },
    {
        type: fieldTypes.TEXT,
        label: t("customer-integration-list.header.unique-name"),
        name: "uniqueName",
    },
    {
        type: fieldTypes.TEXT,
        label: t("general.parent"),
        name: "parent",
        render: (text, record) => (
            <ParentWarning parent={record.parent} synced={record.synced} />
        ),
    },
    {
        type: fieldTypes.OPTION,
        options: routes && getColumnOptions(routes, "status"),
        label: t("customer-integration-list.header.status"),
        name: "status",
        render: (text, record) => {
            return record.status ? (
                <>
                    <Tooltip title={record.message} autoAdjustOverflow={true}>
                        <RouteStatus status={record.status} />
                        {record.status === "CHANGED" ||
                            (record.status === "DEPLOYED" && (
                                <Icon
                                    key="edit"
                                    type="cloud-info"
                                    onClick={() => onShowDiff(record.id)}
                                />
                            ))}
                    </Tooltip>
                </>
            ) : null;
        },
    },
    {
        type: fieldTypes.TEXT,
        label: t("customer-integration-list.header.revision-number"),
        name: "revisionNumber",
    },
    {
        type: fieldTypes.OPTION,
        options: [
            {
                label: t("general.all"),
                value: null,
            },
            {
                label: IntegrationType.translate(IntegrationType.CUSTOM),
                value: IntegrationType.CUSTOM,
            },
            {
                label: IntegrationType.translate(IntegrationType.TEMPLATE),
                value: IntegrationType.TEMPLATE,
            },
        ],
        label: t("customer-integration-list.header.type"),
        name: "type",
    },
    {
        type: fieldTypes.TEXT,
        canFilter: false,
        label: t("customer-integration-list.header.changed"),
        name: "updatedAt",
        render: (text, record) =>
            selectChangedAt(record) ? (
                <Changed key={record.id} data={record} />
            ) : null,
    },
];
