import WorkflowsAdminPage from "@/components/WorkFlow/WorkflowsAdminPage";
import { createLocation } from "@/modules/router";
import { WORKFLOW_PERMISSION_FUNC } from "@/security/permission.utils";
import { workflowsAppLocation } from "../../locations";

const workflowsList = createLocation(workflowsAppLocation, "list", "/list", {
    component: WorkflowsAdminPage,
    permissionFunc: WORKFLOW_PERMISSION_FUNC,
});

export const workflowsAppLocationsMap = {
    workflowsList,
};
