import { TIME_HOURS_MINUTES } from "@/components/UpgradeScheduling/date-formats";
import moment from "moment";

export const toDuration = value => {
    if (!value) {
        return value;
    }
    const formatted = moment(value).format(TIME_HOURS_MINUTES);
    return moment.duration(formatted).toISOString();
};

export const fromDuration = value => {
    if (!value) {
        return value;
    }
    const duration = moment.duration(value);
    const toMoment = moment({
        hour: duration.hours(),
        minute: duration.minutes(),
    });
    return moment(toMoment, TIME_HOURS_MINUTES);
};
