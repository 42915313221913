import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useRoute } from "react-router5";
import { LocationMenu } from "./LocationMenu.component";
import { LocationMenuItem } from "./LocationMenuItem";
import { createRoutesPath } from "./LocationMenu.utils";

/**
 * Renders and highlights a menu of locations.
 * You can provide locations array or children if you want need to
 * have more fine grained control over how locations are rendered.
 *
 * If you will provide children, set the "key" property to
 * location.routeName / location.node on a location item,
 * so the menu knows which location it should highlight.
 */
export function LocationMenuContainer({
    children,
    locations = [],
    renderItem,
    eager = false,
    ...props
}) {
    const { route } = useRoute();
    const routeName = route.name;

    const routesPath = useMemo(() => createRoutesPath(routeName), [routeName]);

    return (
        <LocationMenu
            routeName={eager ? routesPath : [routeName]}
            routesPath={routesPath}
            {...props}
        >
            {renderItems(children, locations, renderItem)}
        </LocationMenu>
    );
}

LocationMenuContainer.propTypes = {
    children: PropTypes.any,
    locations: PropTypes.arrayOf(
        PropTypes.shape({
            $location: PropTypes.object.isRequired,
            $locationParams: PropTypes.object,
            linkTextTranslationId: PropTypes.string,
        }),
    ),
    renderItem: PropTypes.func,
    // Eager mans, that a location item will be marked as selected
    // even when its children are selected and not directly the parent location
    eager: PropTypes.bool,
};

function renderItem({
    $location,
    $locationParams,
    linkTextTranslationId,
    ...props
}) {
    return (
        <LocationMenuItem
            key={$location.routeName}
            $location={$location}
            $locationParams={$locationParams}
            $locationProps={props}
            linkTextTranslationId={linkTextTranslationId}
        />
    );
}

function renderItems(children, locations, itemRenderer = renderItem) {
    // if children is specified then it is preferred over locations
    return children || locations.map(itemRenderer);
}
