// Need to use antd table because of missing row height option (need taller rows because of selects etc.)
import { Alert, Form, Gap, H5, Input, Text } from "@/components/DesignSystem";
import { mapUnityColumnsToAntd } from "@/components/DesignSystem/Table/features/mapper";
import { defaultPreviewCellProps } from "@/components/Packages/PackageTableDefinitionPanel/components/PackageDataMappingLayout/DataPreviewTable.component";
import { useConfirmModal } from "@/modules/modal/imperative/useConfirmModal.hook";
import { T } from "@/translations";
import {
    findByMatchingValues,
    findStringCaseInsensitive,
} from "@/utils/array.utils";
import { Table } from "antd";
import { produce } from "immer";
import { groupBy } from "lodash";
import find from "lodash/find";
import join from "lodash/join";
import values from "lodash/values";
import PropTypes from "prop-types";
import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import { conditionalOptionsModal } from "../../../../../apps/marketplaceApp/components/PackageDefinition/MappingOption.modal";
import { t } from "../../../../../translations/translations.service";
import {
    disableTypeOptions,
    getOptionalFields,
    getSelectedType,
    getTypeLabel,
    resolveTextColor,
} from "../../../packagesPanel.utils";
import PackageDataMappingLayout from "../PackageDataMappingLayout/PackageDataMappingLayout";
import { TypeSelect } from "../PricePrameters/TypeSelect";
import { handleRowClassName } from "../PricePrameters/priceParemeters.utils";
import { Skip, resolveSkip, resolveSkipAll } from "../TableComponents/Skip";
import styles from "../styles.less";

const ERRORS = {
    DUPLICATED: "DUPLICATED",
    IN_MANDATORY: "IN_MANDATORY",
    FORBIDDEN: "FORBIDDEN",
};

const errorMessages = {
    DUPLICATED: `Duplicated labels are not allowed`,
    IN_MANDATORY: `Label cannot be the same as one of mandatory fields`,
    FORBIDDEN: `Label cannot be the same as one of forbidden keys`,
};

const errorMessagesDescriptive = {
    FORBIDDEN: keys =>
        `We identified the conflict of terminology. The field name can not be the same as one of the system's forbidden keys such as ${keys}. Please rename it below.`,
};

const validate = (optionalFields, mandatoryFieldsMap, forbiddenNames) => {
    const mFieldNames = Object.values(mandatoryFieldsMap || {}).map(
        f => f.name,
    );
    const mFieldLabels = Object.values(mandatoryFieldsMap || {}).map(
        f => f.label,
    );
    const usedOptionalFieldLabels = optionalFields
        .filter(o => !o.skip)
        .map(({ label }) => label);

    const entries = usedOptionalFieldLabels.map((label, _index, fieldNames) => {
        const inForbidden = findStringCaseInsensitive(forbiddenNames, label);
        const inMandatory =
            findStringCaseInsensitive(mFieldLabels, label) ||
            findStringCaseInsensitive(mFieldNames, label);
        const duplicated =
            fieldNames.filter(
                fName => fName.toLowerCase() === label.toLowerCase(),
            ).length > 1;

        const errors = []
            .concat(duplicated ? { type: ERRORS.DUPLICATED, label } : [])
            .concat(inMandatory ? { type: ERRORS.IN_MANDATORY, label } : [])
            .concat(inForbidden ? { type: ERRORS.FORBIDDEN, label } : []);

        return [label, errors];
    });
    const errorsByFieldName = Object.fromEntries(entries);

    return errorsByFieldName;
};

const forbiddenNamesList = (type, forbiddenNames) =>
    type === ERRORS.FORBIDDEN ? `(${forbiddenNames})` : "";

const alertErrorMessages = (errorsByFieldName, { forbiddenNames }) => {
    const allErrors = Object.values(errorsByFieldName).reduce(
        (acc, errors) => acc.concat(errors),
        [],
    );
    const byType = groupBy(allErrors, "type");
    const messages = Object.entries(byType).map(([type, errors]) => {
        if (errorMessagesDescriptive[type]) {
            let keys = `${forbiddenNamesList(type, forbiddenNames)}: ${errors
                .map(({ label }) => label)
                .join(", ")}`;
            return `${errorMessagesDescriptive[type](keys)}`;
        } else {
            return `${errorMessages[type]}${forbiddenNamesList(
                type,
                forbiddenNames,
            )}: ${errors.map(({ label }) => label).join(", ")} `;
        }
    });
    return messages;
};

const findFieldByName = (fields, name) => find(fields, f => f.name === name);

const PackageOptionalFieldsMap = ({
    accountId,
    globalState,
    step,
    onNext,
    onBack,
    changeState,
    onCancel,
}) => {
    const {
        fileInfo: tableExampleData,
        mandatoryFieldsMap = {},
        significantFields = {},
        optionalFields = [],
    } = globalState;
    const setOptionalFields = f => changeState({ optionalFields: f });
    const confirmModal = useConfirmModal();
    const { forbiddenNames = [] } = step;

    useEffect(() => {
        const fields = getOptionalFields(
            { mandatoryFieldsMap, significantFields, optionalFields },
            tableExampleData,
        );
        setOptionalFields(fields);
    }, []);

    const errorsByFieldName = useMemo(() => {
        const errorsByFieldName = validate(
            optionalFields,
            {
                ...mandatoryFieldsMap,
                ...significantFields,
            },
            forbiddenNames,
        );
        return errorsByFieldName;
    }, [optionalFields, mandatoryFieldsMap, significantFields, forbiddenNames]);
    const [firstError] = alertErrorMessages(errorsByFieldName, {
        forbiddenNames,
    });

    const setLabel = (fieldName, value) => {
        setOptionalFields(
            produce(optionalFields, draft => {
                const field = findFieldByName(draft, fieldName);
                field.label = value;
            }),
        );
    };

    const onSkipAll = checked =>
        setOptionalFields(resolveSkipAll(checked, optionalFields));

    const onSkip = (record, value) =>
        setOptionalFields(resolveSkip(record, value, optionalFields));

    const handleOnNext = () => {
        conditionalOptionsModal({
            condition: step.showMappingOptions,
            data: { optionalFields },
            accountId,
            onNext,
            onCancel,
            confirmModal,
        });
    };

    const shouldNextButtonDisabled = () =>
        optionalFields
            .map(field => !field.skip && !field.label)
            .includes(true) || firstError;

    const handleType = (record, value) => {
        setOptionalFields(
            produce(optionalFields, draft => {
                const field = findByMatchingValues("label", record, draft);
                field.type = value;
            }),
        );
    };

    const disableOption = (record, type) =>
        disableTypeOptions(tableExampleData.columns, record, type);

    const customColumns = ({ name, type }) => {
        return {
            ...defaultPreviewCellProps,
            label: createTitle(
                name,
                getSelectedType(optionalFields, name) || type,
            ),
            name,
            render: text => <div>{`${text}`}</div>,
        };
    };

    const createTitle = (name, type) => {
        return (
            <div>
                {name}
                <div className={styles.type}>{getTypeLabel(type)}</div>
            </div>
        );
    };

    const getError = useCallback(
        label => errorsByFieldName[label]?.[0],
        [errorsByFieldName],
    );

    return (
        <PackageDataMappingLayout
            dataExample={tableExampleData}
            disableNextButton={shouldNextButtonDisabled()}
            customColumns={customColumns}
            onNext={handleOnNext}
            onBack={onBack}
            onCancel={onCancel}
        >
            <T id="package-data-upload.optional-files.message" />
            <Gap size="small" />
            {firstError && <Alert message={firstError} type="error" />}
            <Gap size="small" />
            <Table
                pagination={false}
                dataSource={values(optionalFields)}
                rowClassName={record =>
                    handleRowClassName({
                        ...record,
                        error: !!getError(record.label),
                    })
                }
                columns={mapUnityColumnsToAntd([
                    {
                        key: "fileFields",
                        width: "40%",
                        title: () =>
                            t("package-data-upload.label.optional-fields", {
                                fileName: tableExampleData.fileName,
                            }),
                        render: (text, record) => (
                            <Fragment>
                                <H5
                                    className={
                                        resolveTextColor(
                                            optionalFields,
                                            record.name,
                                        ).labelColor
                                    }
                                >
                                    {record.originalFieldName}
                                </H5>
                                <Text
                                    size="small"
                                    className={
                                        resolveTextColor(
                                            optionalFields,
                                            record.name,
                                        ).descriptionColor
                                    }
                                >
                                    {`Sample: ${join(
                                        record.parsedValues,
                                        ", ",
                                    )}`}
                                </Text>
                            </Fragment>
                        ),
                    },
                    {
                        key: "label",
                        width: "25%",
                        title: () => t("package-data-upload.label.name"),
                        render: (text, record) => {
                            const error = getError(record.label);
                            return (
                                <Form.Item
                                    validateStatus={error ? "error" : "success"}
                                    help={
                                        error
                                            ? errorMessages[error.type]
                                            : undefined
                                    }
                                >
                                    <Input
                                        onChange={e =>
                                            setLabel(
                                                record.name,
                                                e.target.value,
                                            )
                                        }
                                        disabled={record.skip}
                                        value={record.label}
                                    />
                                </Form.Item>
                            );
                        },
                    },
                    {
                        key: "type",
                        width: "25%",
                        title: () => t("package-data-upload.label.type"),
                        render: (text, record) => (
                            <Form.Item>
                                <TypeSelect
                                    record={record}
                                    disableOption={disableOption}
                                    handleType={handleType}
                                    disabled={record.skip}
                                />
                            </Form.Item>
                        ),
                    },
                    Skip(onSkipAll, onSkip, optionalFields),
                ])}
            />
        </PackageDataMappingLayout>
    );
};

PackageOptionalFieldsMap.propTypes = {
    accountId: PropTypes.number.isRequired,
    globalState: PropTypes.object.isRequired,
    componentState: PropTypes.object.isRequired,
    step: PropTypes.object.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    changeState: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default PackageOptionalFieldsMap;
