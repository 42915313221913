import {
    ButtonMenu,
    Gap,
    Modal,
    Table,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { getStopIntegrationsTrackName as getTrackName } from "../../../mixpanel/buttonNames";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    LoadableRenderer,
    isLoading,
    pendingPromise,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";

const useRunningInstancesQuery = ({
    partitionId,
    canFetch = !!partitionId,
}) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            canFetch
                ? axiosService
                      .get(
                          `/api/partition-assets/${partitionId}/running-instances`,
                      )
                      .then(getData)
                : pendingPromise(),
        [canFetch, axiosService, partitionId],
    );
};

const columns = [
    {
        label: "Instance name",
        name: "name",
        type: fieldTypes.TEXT,
    },
];

// success:
// The request to move the partition was submitted to a workflow and needs to be approved. Please wait for the workflow to finish.

export const StopIntegrationsModal = ({
    partitionId,
    visible,
    onClose,
    disabled,
    confirmProps = {},
}) => {
    const runningInstancesQuery = useRunningInstancesQuery({
        partitionId,
    });
    console.log("[StopIntegrationsModal.rndr]", { runningInstancesQuery });

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("partition-move.stop-integrations.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Text>{t("partition-move.stop-integrations.info")}</Text>
                    <Gap size="medium" />
                    <LoadableRenderer
                        loadable={runningInstancesQuery.loadable}
                        hasValue={integrationNames => (
                            <Table
                                rowKey="name"
                                columns={columns}
                                dataSource={integrationNames.map(name => ({
                                    name,
                                }))}
                                pagination={false}
                                fixed
                                fixedHeight={325}
                            />
                        )}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.continue"),
                                    type: "primary",
                                    track: { name: getTrackName("Continue") },
                                    ...confirmProps,
                                    disabled:
                                        disabled ||
                                        isLoading(runningInstancesQuery),
                                },
                                {
                                    label: t("general.cancel"),
                                    type: "text",
                                    onClick: onClose,
                                    disabled,
                                    track: { name: getTrackName("Cancel") },
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
