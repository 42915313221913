import { Button, ButtonGroup, Gap, H3, Text } from "@/components/DesignSystem";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { T, t } from "@/translations";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import SwitchOption from "./SwitchOption";

const FilterForm = ({ step, onNext, onBack }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(step.options.map(option => ({ ...option, checked: true })));
    }, [step]);

    const switchOption = (name, value) => {
        setOptions(prevOptions =>
            prevOptions.map(option =>
                option.name === name ? { ...option, checked: value } : option,
            ),
        );
    };

    const switchAllOptions = value =>
        setOptions(prevOptions =>
            prevOptions.map(option => ({ ...option, checked: value })),
        );

    const notPickedOptions = options.flatMap(option =>
        !option.checked ? option.name : [],
    );
    const onNextClick = () => {
        onNext({ notPickedOptions });
    };

    return (
        <div>
            <H3>{step.ui.title}</H3>
            <Text size="large">{step.ui.description}</Text>
            <Gap size="large" />

            <Text>
                <T id="filter-form.text" />
            </Text>
            <Gap size="large" />
            <Button
                label={t("filter-form.select-all")}
                onClick={() => switchAllOptions(true)}
                size="small"
            />
            <Button
                label={t("filter-form.deselect-all")}
                onClick={() => switchAllOptions(false)}
                size="small"
            />
            <Gap size="medium" />

            {options.map(({ description, name, title, checked }) => (
                <>
                    <SwitchOption
                        key={name}
                        checked={checked}
                        description={description}
                        onChange={v => switchOption(name, v)}
                        name={title}
                    />
                </>
            ))}

            <ButtonGroup>
                <Button
                    type="primary"
                    onClick={onNextClick}
                    label={
                        <FormattedMessage id="package-data-upload.button.next" />
                    }
                />
                <BackButton onBack={onBack} />
            </ButtonGroup>
        </div>
    );
};

FilterForm.propTypes = {
    step: PropTypes.object.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
};

export default FilterForm;
