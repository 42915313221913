import { Gap, Table, Text } from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

export const columns = [
    {
        label: t("package-failed-option.table.column.row"),
        name: "row_index",
        width: "150",
    },
    {
        label: t("package-failed-option.table.column.input-key"),
        name: "input_key",
        width: "150",
    },
    {
        label: t("package-failed-option.table.column.input-value"),
        name: "input_value",
        width: "150",
    },
    {
        label: t("package-failed-option.table.column.error"),
        name: "message",
    },
];

export const ErrorData = ({ errorData: { statistics = {}, data = [] } }) => {
    return (
        <>
            <Text size="small">
                {t("package-failed-option.statistics.info")}
            </Text>
            <Gap size="small" />
            <div>
                {Object.entries(statistics).map(([k, v]) => (
                    <Text key={k}>
                        {`${v} ${t(
                            "package-failed-option.statistics.column.error",
                        )} ${k}`}
                    </Text>
                ))}
            </div>
            <Gap />
            <Table
                rowKey="row_index"
                dataSource={data}
                columns={columns}
                pagination={false}
                fixed={true}
                fixedHeight={214}
                padding={false}
            />
        </>
    );
};

ErrorData.propTypes = {
    errorData: PropTypes.shape({
        statistics: PropTypes.object,
        data: PropTypes.array,
    }).isRequired,
};
