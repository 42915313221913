import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import ObjectTypeSelector from "../../../Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/ObjectTypeSelector";
import { useObjectTypeSelect } from "../../../Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/hooks/useObjectTypeSelect.hook";
import { useOutputOptions } from "../loadableResurces";
import { OptionsLoadableErrors } from "./OptionsLoadableErrors";

export const ObjectTypeSelectOptionsResourceFetcher = ({
    maybeSelectedConnection,
    setOptionsResource,
    resetResource,
    setParams,
}) => {
    const sourceType = "connections";
    const objectTypeSelect = useObjectTypeSelect({
        initialSourceType: sourceType,
        initialSourceId: maybeSelectedConnection?.connectionId,
    });
    const memoErrors = useMemo(
        () => Object.values(objectTypeSelect.errors).filter(Boolean),
        [objectTypeSelect.errors],
    );
    const optionsResource = useOutputOptions({
        sourceType,
        sourceId: maybeSelectedConnection?.connectionId,
        entityType: objectTypeSelect.entityType,
        entityName: objectTypeSelect.entityName,
        objectTypeErrors: memoErrors,
    });
    console.log({ objectTypeSelect, optionsResource });

    useEffect(() => {
        setOptionsResource(optionsResource);
    }, [optionsResource.loadable]);
    useEffect(
        // Reset state on unmount
        () => () => {
            setParams({});
            resetResource();
        },
        [],
    );
    useEffect(() => {
        setParams({
            connection: maybeSelectedConnection || {},
            entityType: objectTypeSelect.entityType,
            entityName: objectTypeSelect.entityName,
        });
    }, [
        maybeSelectedConnection,
        objectTypeSelect.entityType,
        objectTypeSelect.entityName,
        setParams,
    ]);

    return (
        <div data-test="object-type-select">
            {maybeSelectedConnection?.connectionId && (
                <ObjectTypeSelector {...objectTypeSelect} />
            )}
            <OptionsLoadableErrors loadable={optionsResource.loadable} />
        </div>
    );
};

ObjectTypeSelectOptionsResourceFetcher.propTypes = {
    setParams: PropTypes.func.isRequired,
};
