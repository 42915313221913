import { Avatar } from "@/components/DesignSystem";
import React from "react";

const gravatarUrl = "https://www.gravatar.com/avatar/";
const uiAvatarUrl = "https://ui-avatars.com/api/?size=32&name=";

const AvatarIcon = props => {
    const { gravatarHash, name } = props;

    if (gravatarHash) {
        return (
            <Avatar
                shape="circle"
                size="small"
                src={gravatarUrl + gravatarHash + "?d=wavatar"}
                alt="Avatar image"
            />
        );
    }

    if (name) {
        return (
            <Avatar
                shape="circle"
                size="small"
                src={uiAvatarUrl + encodeURIComponent(name)}
                alt="Avatar image"
            />
        );
    }

    return <div />;
};

export default AvatarIcon;
