import { produce } from "immer";
import { TemplateEntity } from "../../entities/templates/TemplateEntity.class";

export class TemplatesApiMapper {
    decode(templates) {
        return templates.map(apiTemplate =>
            TemplateEntity.create(
                produce(apiTemplate, draft => {
                    draft.id = apiTemplate.id || apiTemplate.uniqueName;
                    draft.components = apiTemplate.components
                        ? apiTemplate.components.split(",")
                        : [];
                }),
            ),
        );
    }
}
