import { asValue } from "awilix";
import { overviewAppLocationsMap } from "./overviewApp.locations";
import { overviewAppAliases } from "./overviewApp.aliases";

export function registerOverviewApp(dic) {
    const { locationRouterService, routerService } = dic.cradle;

    dic.register({
        overviewAppLocations: asValue(overviewAppLocationsMap),
    });

    locationRouterService.registerLocations(overviewAppLocationsMap);
    routerService.add(overviewAppAliases);
}
