import { Gap, H5, Select, Text } from "@/components/DesignSystem";
import { t, T } from "@/translations";
import values from "lodash/values";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import {
    getHeaders,
    getOptions,
    resolveNewFieldValue,
    resolveTextColor,
} from "../../../packagesPanel.utils";
import { InputType } from "../../constants";
import { PackageTable } from "../PackageTable/PackageTable";
import { handleRowClassName } from "../PricePrameters/priceParemeters.utils";
import styles from "../styles.less";
import { resolveSkip, resolveSkipAll, Skip } from "../TableComponents/Skip";

export const SignificantFields = ({
    tableExampleData,
    availableFields,
    significantFields,
    setSignificantFields,
}) => {
    const onSkipAll = checked =>
        setSignificantFields(resolveSkipAll(checked, significantFields));

    const onSkip = (record, value) =>
        setSignificantFields(resolveSkip(record, value, significantFields));

    const columns = [
        {
            name: "pfxFieldName",
            width: "60%",
            title: () => t("package-data-upload.label.significant-fields"),
            render: (text, record) => (
                <>
                    <H5
                        className={
                            resolveTextColor(significantFields, record.name)
                                .labelColor
                        }
                    >
                        {record.label || record.name}
                    </H5>
                    <Text size="small" className={styles.descriptionColor}>
                        {record.description}
                    </Text>
                </>
            ),
        },
        {
            name: "valueFromFile",
            title: () => (
                <span className={styles.descriptionColor}>
                    <FormattedMessage
                        id="package-data-upload.label.file-fields"
                        values={{
                            fileName: tableExampleData.fileName,
                        }}
                    />
                </span>
            ),
            render: (text, record) => selectRenderField(text, record),
        },
        Skip(onSkipAll, onSkip, significantFields),
    ];
    const selectRenderField = (text, record) => {
        return (
            <Select
                placeholder={t("packages.select.placeholder")}
                showSearch
                allowClear
                value={record.value}
                onChange={value =>
                    setSignificantFields(
                        resolveNewFieldValue(
                            record,
                            { value, inputType: InputType.BODY },
                            tableExampleData.columns,
                            significantFields,
                        ),
                    )
                }
                className={styles.itemSelect}
                disabled={record.skip}
            >
                {getOptions(getHeaders(availableFields))}
            </Select>
        );
    };

    return (
        <Fragment>
            <Gap size="x-large " />
            <Text>
                <T id="package-data-upload.significant-fields.table.header" />
            </Text>
            <Gap size="small" />
            <PackageTable
                fields={values(significantFields)}
                handleRowClassName={handleRowClassName}
                columns={columns}
            />
        </Fragment>
    );
};

SignificantFields.propTypes = {
    availableFields: PropTypes.array.isRequired,
    setSignificantFields: PropTypes.func.isRequired,
    significantFields: PropTypes.object.isRequired,
    tableExampleData: PropTypes.object.isRequired,
};
