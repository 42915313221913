import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import React from "react";
import { AccountAssetAdminSettingsComponent } from "./AccountAssetAdminSettings.component";

export function AssetAdminPage() {
    useFullWidthTableLayout();
    useHideBackButton();

    return <AccountAssetAdminSettingsComponent />;
}
