import qs from "qs";
import { axiosService } from "../axios";

const qsOptions = {
    addQueryPrefix: true,
};

export const upgradeEventsService = {
    getClusterUpgradeEvents(getParams, filter) {
        return axiosService.post(
            `/api/cluster-upgrade-events${qs.stringify(getParams, qsOptions)}`,
            filter,
        );
    },
    getProjectClusterUpgradeEvents(page, size, sort, filter, projectId) {
        return axiosService.post(
            `/api/projects/${projectId}/cluster-upgrade-events${qs.stringify(
                {
                    page,
                    size,
                    sort,
                },
                qsOptions,
            )}`,
            filter,
        );
    },
};
