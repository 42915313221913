import { Modal, UnityLayout } from "@/components/DesignSystem";
import { FilterForm } from "@/components/Filters/form/FilterForm.component";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

const NewFilterModal = ({
    instanceId,
    filterId,
    visible,
    onSave,
    onCancel,
}) => {
    return (
        <Modal width={606} visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header size={3} title={t("filter-form.title")} />
                <FilterForm
                    confirmDeployed
                    isModalVariant
                    instanceId={instanceId}
                    filterId={filterId}
                    onSave={onSave}
                    onBack={onCancel}
                />
            </UnityLayout>
        </Modal>
    );
};

NewFilterModal.propTypes = {
    instanceId: PropTypes.number.isRequired,
    filterId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    visible: PropTypes.bool.isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
};

export default NewFilterModal;
