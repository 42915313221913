import { Forms } from "@/components/DesignSystem";
import MapperRows from "@/components/Mappers/form/MapperRows";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { StepUiHeaders } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/Skip";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import {
    CONVERT_EMPTY_VALUES,
    CONVERT_EMPTY_VALUES_OPTIONS,
} from "../../../../Mappers/MapperTableWithCustomization/MapperGridForm/MapperGridForm";

export const IntegrationMapper = ({ instanceId, step, onNext, onBack }) => {
    const { Form, formId, handleSubmit, setValues } = Forms.useForm({
        onSubmit: ({ values }) => {
            onNext({
                mapper: values.definition,
                convertEmptyStringToNull:
                    values.convertEmptyStringToNull ===
                    CONVERT_EMPTY_VALUES.NULL,
            });
        },
    });

    return (
        <>
            <StepUiHeaders ui={step.ui} />
            <Form onSubmit={handleSubmit}>
                <MapperRows
                    instanceId={instanceId}
                    formId={formId}
                    setValues={setValues}
                    initialDefinition={step.mapper}
                />
                <Forms.Fields.Radio
                    name="convertEmptyStringToNull"
                    label={t("mapper-form.form.send-empty-value-as")}
                    initialValue={false}
                    options={CONVERT_EMPTY_VALUES_OPTIONS}
                    validator={Forms.pmValidators.isRequired}
                />
                <Forms.SubmitButton
                    label={t("package-data-upload.button.next")}
                    type="primary"
                />
                <BackButton onBack={onBack} />
            </Form>
        </>
    );
};

IntegrationMapper.propTypes = {
    instanceId: PropTypes.number.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    step: PropTypes.object.isRequired,
};
