import pipe from "lodash/fp/flow";
import reduce from "lodash/fp/reduce";
import set from "lodash/fp/set";
import entries from "lodash/fp/toPairs";

export const SEPARATOR = "__";
export const serialize = (rowId, fieldName) =>
    `${rowId}${SEPARATOR}${fieldName}`;
export const deserialize = (fieldName = "") => {
    const [id, name] = fieldName.split(SEPARATOR);
    return { id, name };
};

export const toMap = pipe(
    entries,
    reduce((acc, [fullName, value]) => {
        const { id, name } = deserialize(fullName);
        return set([id, name], value, acc);
    }, {}),
);
