// import confirmationImg from "@/assets/img/packages/icons/confirmation.svg";
import confirmationImg from "../../../../../assets/img/packages/icons/confirmation.svg";
import { Button, Link } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import Result from "@/components/Result/Result";
import { t } from "@/translations";
import { func, number, string } from "prop-types";
import React from "react";

const DEFAULT_URL = "app/#/login";

export const buildUrl = (baseUrl, redirectUrl) => {
    const url = `${baseUrl}/${redirectUrl || DEFAULT_URL}`;
    console.log("Partition url: ", url);
    return url;
};

const PackageConfirmation = ({
    onNext,
    baseUrl,
    redirectUrl,
    accountId,
    instanceId,
}) => {
    const {
        locationRouterService,
        accountAppLocations: { instanceOverviewLocation },
    } = useDic();

    return (
        <div>
            <Result
                title={t("package-confirmation.title")}
                description={t("package-confirmation.description")}
                imageSrc={confirmationImg}
                actions={
                    <>
                        <Button
                            htmlType="button"
                            type="primary"
                            onClick={onNext}
                            label={t("package-data-process.button.finish")}
                        />
                        {instanceId ? (
                            <Button
                                type="primary"
                                onClick={() =>
                                    locationRouterService.navigate(
                                        instanceOverviewLocation,
                                        {
                                            accountId,
                                            instanceId,
                                        },
                                    )
                                }
                                label={t(
                                    "package-data-process.button.integration-redirect",
                                )}
                            />
                        ) : (
                            <>
                                &nbsp; &nbsp;
                                <Link
                                    href={buildUrl(baseUrl, redirectUrl)}
                                    targetBlank
                                >
                                    <Button
                                        type="primary"
                                        label={t(
                                            "package-data-process.button.partition-redirect",
                                        )}
                                    />
                                </Link>
                            </>
                        )}
                    </>
                }
            />
        </div>
    );
};

PackageConfirmation.propTypes = {
    onNext: func.isRequired,
    baseUrl: string.isRequired,
    redirectUrl: string,
    instanceId: number,
    accountId: number,
};

export default PackageConfirmation;
