import { useDic } from "@/components/Dic/useDic.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { useUserBasicInfo } from "@/security/hooks/useUserBasicInfo.hook";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CustomerPartitionGeneralAdmin from "./CustomerPartitionGeneralAdmin";
import { createGeneralAdmin, getGeneralAdmin } from "./duck/actions";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";

export const CustomerPartitionGeneralAdminContainer = connect(null, {
    createGeneralAdmin,
    getGeneralAdmin,
})(function GeneralAdminFormContainer({
    getGeneralAdmin,
    createGeneralAdmin,
    ...props
}) {
    const basicInfo = useUserBasicInfo();
    const userName = basicInfo.userInfo.user.username;
    const { accountId, partitionId } = useAccountAppParams();
    const [generalAdminInfo, setGeneralAdminInfo] = useState();
    const {
        locationRouterService,
        accountAppLocations: { partitionsLocation },
    } = useDic();

    useEffect(() => {
        getGeneralAdmin(accountId, partitionId).then(res =>
            setGeneralAdminInfo(res.payload.data),
        );
    }, [accountId, partitionId]);

    const submitRequest = async ({ values }) => {
        const res = await createGeneralAdmin({
            projectId: accountId,
            partitionId: partitionId,
            password: values.password,
            expiration: values.expiration?.toISOString?.() ?? values.expiration,
        });
        if (res.payload.status === 204)
            locationRouterService.navigate(partitionsLocation);
    };

    const handleOnCancel = () => {
        locationRouterService.navigate(partitionsLocation);
    };
    useSetBackButtonAction(handleOnCancel);

    return (
        <CustomerPartitionGeneralAdmin
            {...props}
            onSubmit={submitRequest}
            userName={userName}
            projectId={accountId}
            partitionId={partitionId}
            generalAdminInfo={generalAdminInfo}
            onCancel={handleOnCancel}
        />
    );
});
