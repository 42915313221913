import { apacheCamelModel } from "./camelModel";
import { formHelpers } from "./formHelpers";
import {
    endpointConfigurations,
    endpointService,
} from "./endpointConfigurations";

export const defaultMaximumLabelWidth = 34;

export var endpointIcon = "/img/icons/camel/endpoint24.png";

export const routeDiagramService = {
    loadRouteXmlNodes(scope, doc, selectedRouteId, nodes, links, width) {
        const allRoutes = doc.getElementsByTagName("route");
        const routeDelta = width / allRoutes.length;
        let rowX = 0;
        for (let i = 0; i < allRoutes.length; i++) {
            const route = allRoutes[i];
            const routeId = route.getAttribute("id");
            if (!selectedRouteId || !routeId || selectedRouteId === routeId) {
                this.addRouteXmlChildren(
                    scope,
                    route,
                    nodes,
                    links,
                    null,
                    rowX,
                    0,
                );
                rowX += routeDelta;
            }
        }
    },

    addRouteXmlChildren(
        scope,
        parent,
        nodes,
        links,
        parentId,
        parentX,
        parentY,
        parentNode = null,
    ) {
        const delta = 150;
        let x = parentX;
        let y = parentY + delta;
        let rid = parent.getAttribute("id");
        let siblingNodes = [];
        const parenNodeName = parent.localName;
        for (let i = 0; i < parent.children.length; i++) {
            const route = parent.children[i];
            let id = nodes.length;
            // from acts as a parent even though its a previous sibling :)
            const nodeId = route.localName;
            if (nodeId === "from" && !parentId) {
                parentId = id;
            }
            const nodeSettings = this.getCamelSchema(nodeId);
            let node = null;
            if (nodeSettings) {
                let label = nodeSettings["title"] || nodeId;
                let uri = this.getRouteNodeUri(route);
                if (uri) {
                    label += " " + uri.split("?")[0];
                }
                let tooltip =
                    nodeSettings["tooltip"] ||
                    nodeSettings["description"] ||
                    label;
                if (uri) {
                    tooltip += " " + uri;
                }
                const elementID = route.getAttribute("id");
                let labelSummary = label;
                if (elementID) {
                    const customId = route.getAttribute("customId");
                    if (
                        scope.camelIgnoreIdForLabel ||
                        !customId ||
                        customId === "false"
                    ) {
                        labelSummary = "id: " + elementID;
                    } else {
                        label = elementID;
                    }
                }
                // lets check if we need to trim the label
                // var labelLimit = scope.camelMaximumLabelWidth || defaultMaximumLabelWidth;
                const labelLimit = defaultMaximumLabelWidth;
                const length = label.length;
                if (length > labelLimit) {
                    labelSummary = label + "\n\n" + labelSummary;
                    label = label.substring(0, labelLimit) + "..";
                }

                let imageUrl = this.getRouteNodeIcon(nodeSettings);
                if ((nodeId === "from" || nodeId === "to") && uri) {
                    const uriIdx = uri.indexOf(":");
                    if (uriIdx > 0) {
                        const componentScheme = uri.substring(0, uriIdx);
                        if (componentScheme) {
                            const value = this.getEndpointIcon(componentScheme);
                            if (value) {
                                // imageUrl = Core.url(value);
                                imageUrl = value;
                            }
                        }
                    }
                }

                let cid =
                    route.getAttribute("_cid") || route.getAttribute("id");
                node = {
                    name: name,
                    label: label,
                    labelSummary: labelSummary,
                    group: 1,
                    id: id,
                    elementId: elementID,
                    x: x,
                    y: y,
                    imageUrl: imageUrl,
                    cid: cid,
                    tooltip: tooltip,
                    type: nodeId,
                    uri: uri,
                };
                if (rid) {
                    node["rid"] = rid;
                    if (scope.routeNodes) scope.routeNodes = {};
                    scope.routeNodes[rid] = node;
                }
                if (!cid) {
                    cid = nodeId + (nodes.length + 1);
                }
                if (cid) {
                    node["cid"] = cid;
                    if (!scope.nodes) scope.nodes = {};
                    scope.nodes[cid] = node;
                }
                // only use the route id on the first from node
                rid = null;
                nodes.push(node);
                if (parentId !== null && parentId !== id) {
                    if (
                        siblingNodes.length === 0 ||
                        parenNodeName === "choice"
                    ) {
                        links.push({ source: parentId, target: id, value: 1 });
                    } else {
                        siblingNodes.forEach(function (nodeId) {
                            links.push({
                                source: nodeId,
                                target: id,
                                value: 1,
                            });
                        });
                        siblingNodes.length = 0;
                    }
                }
            } else {
                // ignore non EIP nodes, though we should add expressions...
                const langSettings = this.camelLanguageSettings(nodeId);
                if (langSettings && parentNode) {
                    // lets add the language kind
                    const name = langSettings["name"] || nodeId;
                    const text = route.textContent;
                    if (text) {
                        parentNode["tooltip"] =
                            parentNode["label"] + " " + name + " " + text;
                        parentNode["label"] +=
                            ": " + this.appendLabel(route, text, true);
                    } else {
                        parentNode["label"] +=
                            ": " + this.appendLabel(route, name, false);
                    }
                }
            }
            let siblings = this.addRouteXmlChildren(
                scope,
                route,
                nodes,
                links,
                id,
                x,
                y,
                node,
            );
            if (parenNodeName === "choice") {
                siblingNodes = siblingNodes.concat(siblings);
                x += delta;
            } else if (
                nodeId === "aggregate" ||
                nodeId === "choice" ||
                nodeId === "delay" ||
                nodeId === "filter" ||
                nodeId === "loadBalance" ||
                nodeId === "loop" ||
                nodeId === "multicast" ||
                nodeId === "resequence" ||
                nodeId === "split" ||
                nodeId === "doTry" ||
                nodeId === "doCatch" ||
                nodeId === "doFinally" ||
                nodeId === "idempotentConsumer" ||
                nodeId === "onCompletion"
            ) {
                siblingNodes = siblings;
                y += delta;
            } else {
                siblingNodes = [nodes.length - 1];
                y += delta;
            }
        }
        return siblingNodes;
    },

    /**
     * Looks up the given node name in the Camel schema
     * @method
     */
    getCamelSchema(nodeIdOrDefinition) {
        return nodeIdOrDefinition === "object"
            ? nodeIdOrDefinition
            : formHelpers.lookupDefinition(
                  nodeIdOrDefinition,
                  apacheCamelModel,
              );
    },

    /**
     * Returns the URI string for the given EIP pattern node or null if it is not applicable
     * @method getRouteNodeUri
     * @param {Object} node
     * @return {String}
     */
    getRouteNodeUri(node) {
        if (!node) {
            return null;
        }

        const uri = node.getAttribute("uri");

        if (uri) {
            return uri;
        }

        const ref = node.getAttribute("ref");

        if (!ref) {
            return null;
        }

        const method = node.getAttribute("method");
        if (method) {
            return ref + "." + method + "()";
        }

        return "ref:" + ref;
    },

    getRouteNodeIcon(nodeSettingsOrXmlNode) {
        if (!nodeSettingsOrXmlNode) {
            return null;
        }

        const nodeName = nodeSettingsOrXmlNode.localName;
        const nodeSettings = nodeName
            ? this.getCamelSchema(nodeName)
            : nodeSettingsOrXmlNode;

        if (!nodeSettings) {
            return null;
        }

        const imageName = nodeSettings["icon"] || "generic24.png";
        return "img/icons/camel/" + imageName;
    },

    getEndpointIcon(endpointName) {
        const value = this.getEndpointConfig(endpointName, null);
        const answer = formHelpers.pathGet(value, ["icon"]);

        if (answer) {
            return answer;
        }

        const category = endpointService.getEndpointCategory(endpointName);

        return formHelpers.pathGet(category, ["endpointIcon"]) || endpointIcon;
    },

    getEndpointConfig(endpointName, category) {
        let answer = endpointConfigurations[endpointName];
        if (!answer) {
            answer = {};
            endpointConfigurations[endpointName] = answer;
        }
        if (!answer.label) {
            answer.label = endpointName;
        }
        if (!answer.icon) {
            answer.icon =
                formHelpers.pathGet(category, ["endpointIcon"]) || endpointIcon;
        }
        if (!answer.category) {
            answer.category = category;
        }
        return answer;
    },

    /**
     * Looks up the Camel language settings for the given language name
     * @method
     */
    camelLanguageSettings(nodeName) {
        return apacheCamelModel.languages[nodeName];
    },

    appendLabel(route, label, text) {
        switch (route.localName) {
            case "method":
                if (!text) {
                    if (route.getAttribute("bean")) {
                        label += " " + route.getAttribute("bean");
                    } else if (route.getAttribute("ref")) {
                        label += " " + route.getAttribute("ref");
                    } else if (route.getAttribute("beanType")) {
                        label += " " + route.getAttribute("beanType");
                    }
                }
                if (route.getAttribute("method")) {
                    label += " " + route.getAttribute("method");
                }
                break;
            default:
        }
        return label;
    },
};
