import { loadableFromMaybeValue } from "@/modules/loadable";
import { cloneDeep, difference, intersection, keyBy } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import uuid from "uuid/v4";
import { flatMapOptions } from "../metadata.utils";

const createMapperItemFactory =
    (inputsByValue = {}, outputsByValue = {}) =>
    (input, output, { id, converterExpression, inputType } = {}) => ({
        id: id || uuid(),
        input,
        inputType: inputType || "body",
        inputValueType: inputsByValue[input]?.type, // TODO!
        inputLabel: inputsByValue[input]?.label ?? input,
        output,
        outputType: outputsByValue[output]?.type,
        outputLabel: outputsByValue[output]?.label ?? output,
        converterExpression,
    });
/*
    treeOptions: [{
      value: "obj1",
      children: [{value: "num1"}, {value: "str1"}]
    }]
    returns: {
      "obj1.num1": {value: "obj1.num1"},
      "obj1.str1": {value: "obj1.str1"}
    }
*/
export const createFlatOptionsByValue = treeOptions => {
    const flatOptions = flatMapOptions(treeOptions);
    const byValue = keyBy(flatOptions, ({ value }) => value);
    return byValue;
};

const createDefaultMapper = (inputOptions, outputOptions) => {
    const inputByValue = createFlatOptionsByValue(inputOptions);
    const outputByValue = createFlatOptionsByValue(outputOptions);
    const mapperItemFactory = createMapperItemFactory(
        inputByValue,
        outputByValue,
    );

    const inputValues = Object.keys(inputByValue);
    const outputValues = Object.keys(outputByValue);

    const pairableValues = intersection(inputValues, outputValues);
    const extraInputValues = difference(inputValues, outputValues);
    const extraOutputValues = difference(outputValues, inputValues);

    const mapper = [].concat(
        pairableValues.map(value => mapperItemFactory(value, value)),
    );
    console.log("%ccreateDefaultMapper", "color:firebrick;", {
        mapper,
        inputByValue,
        outputByValue,
        other: {
            inputOptions,
            outputOptions,
            setOpsResults: {
                pairableValues,
                extraInputValues,
                extraOutputValues,
            },
        },
    });
    return mapper;
};

// TODO - inputOptions -> initFormula
const addComputableMapperProperties = (
    initMapper,
    inputOptions,
    outputOptions,
) => {
    const inputByValue = createFlatOptionsByValue(inputOptions);
    const outputByValue = createFlatOptionsByValue(outputOptions);
    const mapperItemFactory = createMapperItemFactory(
        inputByValue,
        outputByValue,
    );
    const mapper = initMapper.map(
        ({ id, input, inputType, output, converterExpression }) =>
            mapperItemFactory(input, output, {
                id,
                converterExpression,
                inputType,
            }),
    );
    return mapper;
};

const useDelayedInitMapper = maybeInitMapper => {
    const [maybeMapper, setMapper] = useState(maybeInitMapper);

    const initMapperRef = useRef(maybeInitMapper);
    useEffect(() => {
        // tricky part regarding mappers - when to use new one and discard old one, may be disabled with flag
        if (initMapperRef.current !== maybeInitMapper) {
            console.log("useDelayedInitMapper reinicializing", {
                "current mapper - to be overriden": cloneDeep(maybeMapper),
                "previously initialized with": cloneDeep(initMapperRef.current),
                "new init mapper - to be used": cloneDeep(maybeInitMapper),
            });
            setMapper(maybeInitMapper);
            initMapperRef.current = maybeInitMapper;
        }
    }, [maybeInitMapper, maybeMapper]);

    return {
        maybeMapper,
        setMapper,
    };
};

export const useConnectionsMapper = ({
    initMapper,
    inputOptionsLoadable,
    outputOptionsLoadable,
}) => {
    const maybeInputOptions = inputOptionsLoadable.valueMaybe();
    const maybeOutputOptions = outputOptionsLoadable.valueMaybe();

    const maybeMapperWithDefault = useMemo(() => {
        if (!maybeInputOptions || !maybeOutputOptions) return undefined;

        const initMapperWithDefault = initMapper
            ? // mapper from somewhere (api, cache, ...)
              addComputableMapperProperties(
                  initMapper,
                  maybeInputOptions,
                  maybeOutputOptions,
              )
            : // Create default mapper
              createDefaultMapper(maybeInputOptions, maybeOutputOptions);

        return initMapperWithDefault;
    }, [maybeInputOptions, maybeOutputOptions, initMapper]);

    const { maybeMapper } = useDelayedInitMapper(maybeMapperWithDefault);

    const loadableInitMapper = useMemo(
        () => loadableFromMaybeValue(maybeMapper),
        [maybeMapper],
    );
    return { loadableInitMapper };
};
