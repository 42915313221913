import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import ElasticSearchPanel from "@/components/Integrations/Dashboard/Tabs/ElasticSearchPanel";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { EntityType } from "@/constants/CommonConstats";
import { EVENT_WF_LOG_LIST } from "@/constants/sessionState.constants";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { getData } from "@/services/utils";
import React from "react";
import PropTypes from "prop-types";

export const EventLogsPage = () => {
    const { accountId } = useAccountAppParams();
    const { axiosService } = useDic();

    useFullWidthTableLayout();
    useHideBackButton();

    const DEFAULT_COLUMNS = { sourceName: true };

    const fetchData = params =>
        axiosService
            .post(`/api/accounts/${accountId}/event-workflow-logs`, params)
            .then(getData);

    return (
        <ElasticSearchPanel
            fetchData={fetchData}
            defaultColumns={DEFAULT_COLUMNS}
            entityId={accountId}
            entityType={EntityType.PROJECT}
            sessionStateName={EVENT_WF_LOG_LIST}
        />
    );
};

EventLogsPage.propTypes = {
    accountId: PropTypes.number.isRequired,
};
