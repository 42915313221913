import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";
export const engSupportUsersColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("eng-support-users.column.partitionSerialNumber"),
        name: "partitionSerialNumber",
    },
    {
        type: fieldTypes.TEXT,
        label: t("eng-support-users.column.username"),
        name: "username",
    },
    {
        type: fieldTypes.DATETIME,
        label: t("eng-support-users.column.created-at"),
        name: "createdAt",
        render: date => <FormattedDateTime>{date}</FormattedDateTime>,
    },
    {
        type: fieldTypes.DATETIME,
        label: t("eng-support-users.column.expiration"),
        name: "expiration",
        render: date => <FormattedDateTime>{date}</FormattedDateTime>,
    },
    {
        type: fieldTypes.TEXT,
        label: t("eng-support-users.column.approved-by"),
        name: "approvedBy",
    },
    {
        type: fieldTypes.OPTION,
        label: t("eng-support-users.column.is-active"),
        name: "isActive",
        options: [
            { value: false, label: t("general.inactive") },
            { value: true, label: t("general.active") },
        ],
    },
];
