import { Timeline } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import React from "react";
import ActivityLogMessage from "../ActivityLog/ActivityLogMessage";
import style from "./ActivityLogTimeline.less";

const ActivityLogTimelineItem = ({ data, isLast }) => {
    return (
        <Timeline.Item
            key={data.time}
            className={
                isLast
                    ? `ant-timeline-item-last ${style.lastItemWithoutPadding}`
                    : ""
            }
        >
            <ActivityLogMessage
                message={data.message}
                executorName={data.executorName}
                executorGravatarHash={data.executorGravatarHash}
                time={data.time}
                long={true}
                showAvatar={false}
                showIcon={false}
            />
        </Timeline.Item>
    );
};

ActivityLogTimelineItem.propTypes = {
    data: PropTypes.any,
    isLast: PropTypes.bool,
};

export default ActivityLogTimelineItem;
