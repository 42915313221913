import { getUserTimezone } from "@/utils/timezone/timezone.utils";
import moment from "moment-timezone";

const userTzNow = moment.tz(Date.now(), getUserTimezone());

const defaultFilters = {
    all: {
        from: userTzNow.clone().subtract(3, "months"),
        to: userTzNow.clone().add(10, "years"),
    },
    today: {
        from: userTzNow.clone().startOf("day"),
        to: userTzNow.clone().endOf("day"),
    },
    tomorrow: {
        from: userTzNow.clone().add(1, "days").startOf("day"),
        to: userTzNow.clone().add(1, "days").endOf("day"),
    },
    next3days: {
        from: userTzNow.clone().add(1, "days").startOf("day"),
        to: userTzNow.clone().add(3, "days").endOf("day"),
    },
    thisWeek: {
        from: userTzNow.clone().startOf("week").add(1, "days"),
        to: userTzNow.clone().endOf("week").add(1, "days"),
    },
};

export default defaultFilters;
