import { mqClusterTo } from "@/components/CustomerPartitions/EventConfigurator/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import {
    crudSuccess,
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { t } from "@/translations";
import get from "lodash/fp/get";
import { useMemo } from "react";

export const useEventConfiguration = ({
    projectId,
    partitionId,
    setValues,
    setTouched,
}) => {
    const { axiosService } = useDic();

    const eventConfigurationResource = useQueryLoadable(
        async () =>
            projectId && partitionId
                ? axiosService
                      .get(
                          `/api/projects/${projectId}/partition-assets/${partitionId}/event-configuration`,
                      )
                      .then(get("data"))
                : pendingPromise(),
        [projectId, partitionId, axiosService],
    );
    const initialValuesRaw = eventConfigurationResource.loadable.valueMaybe();
    const initialValues = useMemo(
        () =>
            initialValuesRaw && {
                ...initialValuesRaw,
                mqCluster: mqClusterTo(initialValuesRaw.mqCluster),
            },
        [initialValuesRaw],
    );

    useSetValidatedInitialValues(
        {
            initialValues,
            setValues: setValues,
            setTouched: setTouched,
        },
        [initialValues],
    );

    return {
        loadableEventConfiguration: eventConfigurationResource.loadable,
        reload: eventConfigurationResource.reload,
    };
};

export const usePostEventConfigurationResource = ({
    projectId,
    partitionId,
    afterSuccess,
}) => {
    const { axiosService } = useDic();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async values => {
            const { data } = await axiosService.post(
                `/api/projects/${projectId}/partition-assets/${partitionId}/event-configuration`,
                values,
            );
            currentAfterSuccess(data);
            return { ...values, ...data };
        },
        [projectId, partitionId, currentAfterSuccess, axiosService],
        crudSuccess(t("partition.event-configurator.title"), null, "U"),
    );
};
