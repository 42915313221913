import { useCenteredLayout } from "@/components/hooks/useContentLayout.hook";
import { useRouteAccount } from "@/mixpanel/hooks/useRouteAccount.hook";
import { hasValue } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React from "react";
import {
    inMutationInitialState,
    useGetPfxCertificates,
    useGetPfxIdentification,
    useGetProjectEmailOptions,
    useGetProjectSamlCerts,
    useGetProjectSamlMgmt,
    usePostPfxIdentification,
    usePostProjectSamlMgmt,
} from "./loadables";
import { SingleSignOnForm } from "./SingleSignOnForm";

export const SingleSignOnAccountPage = () => {
    const { accountId } = useAccountAppParams();
    const { accountLoadable } = useRouteAccount();
    const account = accountLoadable.valueMaybe();

    useCenteredLayout();
    const getPfxIdentificationResource = useGetPfxIdentification({
        projectId: accountId,
        canFetch: !!accountId,
    });
    const postPfxIdentificationResource = usePostPfxIdentification({
        projectId: accountId,
    });
    const pfxIdentificationLoadable = inMutationInitialState(
        postPfxIdentificationResource,
    )
        ? getPfxIdentificationResource.loadable
        : postPfxIdentificationResource.loadable;

    const projectSamlMgmt = useGetProjectSamlMgmt({
        projectId: accountId,
        canFetch: !!accountId && hasValue(pfxIdentificationLoadable),
    });
    const emailOptions = useGetProjectEmailOptions({ projectId: accountId });
    const pfxCertificatesResource = useGetPfxCertificates();
    const certificatesResource = useGetProjectSamlCerts({
        projectId: accountId,
        canFetch: !!accountId && hasValue(pfxIdentificationLoadable),
    });
    const { mutate } = usePostProjectSamlMgmt({
        projectId: accountId,
        afterSuccess: projectSamlMgmt.reload,
    });

    return (
        <SingleSignOnForm
            projectId={accountId}
            projectName={account?.name}
            initialValuesLoadable={projectSamlMgmt.loadable}
            emailsLoadable={emailOptions.loadable}
            onSubmit={mutate}
            onDomainEdit={projectSamlMgmt.reload}
            certificatesResource={certificatesResource}
            pfxCertificatesResource={pfxCertificatesResource}
            isDeletable
            pfxIdentificationLoadable={pfxIdentificationLoadable}
            generatePfxIdentification={postPfxIdentificationResource.mutate}
        />
    );
};

SingleSignOnAccountPage.propTypes = {};
