import { CertificateForm } from "@/components/Certificates/CertificateForm.component";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React from "react";

export const NewCertificatePage = () => {
    const { instanceId } = useAccountAppParams();

    useSetPageTitle(t("certificate-form.title.new-certificate"));

    return <CertificateForm instanceId={instanceId} certificateId="new" />;
};
