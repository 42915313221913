import { ButtonMenu, Forms, UnityLayout } from "@/components/DesignSystem";
import { useConnectionsOptionsQuery } from "@/components/Filters/form/loadables";
import { useFilterNameValidator } from "@/components/Filters/form/validators";
import { useCopyFilterMutation } from "@/components/Filters/loadables";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useAlertEffect } from "@/components/PageLayout/useAlertEffect.hook";
import { hasError, useMapLoadableMemoized } from "@/modules/loadable";
import { t } from "@/translations";
import { getErrorMessageFromError } from "@/utils/state/error.utils";
import { map } from "lodash/fp";
import React from "react";
import { useAccountProvisionedInstancesQuery } from "@/components/Integrations/hooks/useAccountProvisionedInstancesQuery.hook";

export const CopyFilterForm = ({
    accountId,
    filterId,
    initialValues,
    afterSuccess,
    onCancel,
}) => {
    const copyFilterMutation = useCopyFilterMutation({
        filterId,
        afterSuccess,
    });
    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        initialValues,
        onSubmit: copyFilterMutation.mutate,
    });
    const instancesOptionsLoadable = useMapLoadableMemoized(
        useAccountProvisionedInstancesQuery({ accountId }).loadable,
        map(instance => ({
            value: instance.id,
            label: instance.instanceName,
        })),
    );
    const targetInstanceId = Forms.useFieldValue({
        formId,
        name: "targetInstanceId",
    });
    const connectionOptionsQuery = useConnectionsOptionsQuery({
        instanceId: targetInstanceId,
        canFetch: !!targetInstanceId,
    });
    useAlertEffect(hasError(connectionOptionsQuery), () => ({
        type: "error",
        message: getErrorMessageFromError(
            connectionOptionsQuery.loadable.contents,
        ),
    }));

    return (
        <>
            <UnityLayout.Content padding={[false, true]}>
                <Forms.Form formId={formId} onSubmit={handleSubmit}>
                    <Forms.FieldGroup width="max" inputWidth="max">
                        <Forms.Fields.Select
                            name="targetInstanceId"
                            label={t("filter.copy.label.integration-instance")}
                            required
                            validator={Forms.pmValidators.isRequired}
                            onChange={() => {
                                setValues({ targetConnectionId: undefined });
                                setTouched({ targetConnectionId: false });
                            }}
                            {...getLoadableSelectProps(
                                instancesOptionsLoadable,
                            )}
                            // placeholder={}
                        />

                        <Forms.Fields.Select
                            name="targetConnectionId"
                            label={t("filter.copy.label.connection")}
                            required
                            validator={Forms.pmValidators.isRequired}
                            {...getLoadableSelectProps(
                                connectionOptionsQuery.loadable,
                            )}
                            {...(!targetInstanceId
                                ? {
                                      disabled: true,
                                      placeholder: "",
                                      options: [],
                                  }
                                : {})}
                        />
                        <Forms.Fields.Input
                            name="name"
                            label={t("filter.copy.label.filter-name")}
                            required
                            validator={useFilterNameValidator({
                                instanceId: targetInstanceId,
                                currentName: initialValues?.name,
                                withOnline: false,
                            })}
                        />
                    </Forms.FieldGroup>
                </Forms.Form>
            </UnityLayout.Content>
            <UnityLayout.Footer
                actionButtons={
                    <ButtonMenu
                        buttons={[
                            {
                                label: t("general.copy"),
                                type: "primary",
                                htmlType: "submit",
                                formId,
                            },

                            {
                                label: t("general.cancel"),
                                type: "text",
                                onClick: onCancel,
                            },
                        ]}
                    />
                }
            />
        </>
    );
};
