import { AlertsContextProvider } from "@/components/PageLayout/Alerts/AlertsContextProvider";
import BreadcrumbContextProvider from "@/components/PageLayout/BreadcrumbContext";
import HeaderStepsContextProvider from "@/components/PageLayout/HeaderStepsContext";
import PageLayoutContextProvider from "@/components/PageLayout/PageLayoutContext";
import PropTypes from "prop-types";
import React from "react";

const PageLayoutContexts = ({ children }) => {
    return (
        <HeaderStepsContextProvider>
            <AlertsContextProvider>
                <PageLayoutContextProvider>
                    <BreadcrumbContextProvider>
                        {children}
                    </BreadcrumbContextProvider>
                </PageLayoutContextProvider>
            </AlertsContextProvider>
        </HeaderStepsContextProvider>
    );
};

PageLayoutContexts.propTypes = {
    children: PropTypes.node.isRequired,
};

export default PageLayoutContexts;
