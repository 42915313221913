import { Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import qs from "qs";
import { useCallback, useMemo } from "react";

const qsOptions = { addQueryPrefix: true };

const useRouteExistsValidator = ({
    instanceId,
    skipValidationFor,
    withOnline = true,
}) => {
    const { axiosService } = useDic();

    return useCallback(
        value =>
            skipValidationFor && skipValidationFor === value
                ? Forms.success()
                : axiosService
                      .get(
                          `/api/instance/${instanceId}/route-exists${qs.stringify(
                              { name: value, withOnline },
                              qsOptions,
                          )}`,
                      )
                      .then(({ data: exists }) =>
                          exists
                              ? Forms.error("Already exists")
                              : Forms.success(),
                      )
                      .catch(e => Forms.error(getErrorMessage(e))),
        [axiosService, instanceId, skipValidationFor, withOnline],
    );
};

export const useRouteNameValidator = ({
    instanceId,
    currentName,
    withOnline,
}) => {
    const routeExistsValidator = useRouteExistsValidator({
        instanceId,
        skipValidationFor: currentName,
        withOnline,
    });

    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                // TODO: pattern?
                Forms.pmValidators.createMinLengthValidation(5),
                routeExistsValidator,
            ]),
        [routeExistsValidator],
    );
};

export const useRouteXmlValidator = ({ instanceId }) => {
    const { routesService } = useDic();

    return useCallback(
        value =>
            routesService
                .validate(instanceId, value)
                .then(({ data: { valid, errorMessage } }) =>
                    valid
                        ? Forms.success()
                        : Forms.error(
                              errorMessage ??
                                  t("general.validation.is-valid-xml"),
                          ),
                )
                .catch(e => Forms.error(getErrorMessage(e))),
        [routesService, instanceId],
    );
};
