import { axiosService } from "../../../../../axios";

export const integrationSftpService = {
    testSftpConnection(values) {
        return axiosService.post(
            `/api/integration-template//sftp/test`,
            values,
        );
    },
};
