import cx from "classnames";
import React from "react";
import styles from "./flag.style.less";

export default ({ flag, className, ...rest }) => {
    const classes = cx(styles.flag, className);
    return (
        <div
            className={classes}
            {...rest}
            style={{ backgroundImage: `url("/img/flags/${flag}.svg")` }}
        />
    );
};
