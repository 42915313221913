import RouteDiagram from "@/components/Camel/RouteDiagram/RouteDiagram";
import { Button, Col, Row } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthLayout } from "@/components/hooks/useContentLayout.hook";
import { useRouteDefinitionResource } from "@/components/IntegrationRoutes/routes.loadables";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { LoadableRenderer } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React from "react";

export const RouteDiagramPage = () => {
    const { instanceId, routeId, accountId } = useAccountAppParams();
    const {
        locationRouterService,
        accountAppLocations: { integrationsLocation },
    } = useDic();

    useFullWidthLayout();

    const routeDiagramResource = useRouteDefinitionResource(
        instanceId,
        routeId,
    );

    useSetPageTitle(`Route ${routeId} diagram`);

    return (
        <>
            <Row>
                <Col>
                    <LoadableRenderer
                        loadable={routeDiagramResource.loadable}
                        hasValue={routeDiagram => (
                            <RouteDiagram
                                definition={routeDiagram.xmlDefinition}
                                routeId={routeId}
                            />
                        )}
                    />
                </Col>

                <Col>
                    <Button
                        htmlType="button"
                        onClick={() => {
                            locationRouterService.navigate(
                                integrationsLocation,
                                {
                                    instanceId,
                                    accountId,
                                },
                            );
                        }}
                        label={t("general.back")}
                    />
                </Col>
            </Row>
        </>
    );
};
