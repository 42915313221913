import {
    Button,
    ButtonGroup,
    Col,
    Form,
    Gap,
    Input,
    InputNumber,
    Option,
    Row,
    Select,
    Text,
} from "@/components/DesignSystem";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { StepUiHeaders } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/Skip";
import { T, t } from "@/translations";
import { filterOutNonDigit } from "@/utils/regexUtils";
import { produce } from "immer";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connectionsService } from "../../../../../services/connections.service";
import { FilterSelect } from "./FilterSelect";

const DEFAULT_TEXT_AREA_ROWS = 4;
export const SELECT_EXT_TYPE = "select_ext";

const getComponentByType = ({
    property,
    onChange,
    index,
    instanceId,
    selectExtPropertyValues,
}) => {
    switch (property.type) {
        case "textarea":
            return (
                <Input.TextArea
                    rows={
                        property.rows === undefined
                            ? DEFAULT_TEXT_AREA_ROWS
                            : property.rows
                    }
                    id={property.name}
                    onChange={e => onChange(e.target.value, index)}
                    placeholder={property.placeholder}
                />
            );
        case "filter":
            return (
                <FilterSelect
                    instanceId={instanceId}
                    id={property.name}
                    placeholder={property.placeholder}
                    onChange={value => onChange(value, index)}
                    showSearch={true}
                />
            );
        case "password":
            return (
                <Input.Password
                    id={property.name}
                    onChange={e => onChange(e.target.value, index)}
                    placeholder={property.placeholder}
                />
            );
        case "select":
            return (
                <Select
                    id={property.name}
                    placeholder={property.placeholder}
                    onChange={value => onChange(value, index)}
                    showSearch={true}
                >
                    {property.options.map(option => (
                        <Option
                            title={option.label}
                            key={option.label}
                            value={option.value}
                        >
                            {option.label}
                        </Option>
                    ))}
                </Select>
            );
        case SELECT_EXT_TYPE:
            return (
                <Select
                    id={property.name}
                    placeholder={property.placeholder}
                    onChange={value => onChange(value, index)}
                    showSearch={true}
                >
                    {selectExtPropertyValues[property.name]?.map(option => (
                        <Option title={option} key={option} value={option}>
                            {option}
                        </Option>
                    ))}
                </Select>
            );
        case "int":
            return (
                <InputNumber
                    id={property.name}
                    onChange={value => onChange(value, index)}
                    formatter={value => filterOutNonDigit(value)}
                    placeholder={property.placeholder}
                />
            );
        case "text":
        default:
            return (
                <Input
                    id={property.name}
                    onChange={e => onChange(e.target.value, index)}
                    placeholder={property.placeholder}
                />
            );
    }
};

const IntegrationProperties = ({
    step,
    globalState,
    changeState,
    form,
    onNext,
    onBack,
    buttons,
}) => {
    useEffect(() => {
        if (!globalState.properties) {
            const field = globalState.runIMTemplate
                ? globalState?.mappingTemplate?.mandatoryFields
                : step.mandatoryFields;
            setInternalProperties(field);
        }
    }, []);

    const { properties = [] } = globalState;
    const { getFieldDecorator } = form;
    const { instanceId = globalState.instanceId } = step;

    const setInternalProperties = p => changeState({ properties: p });

    const onChange = (value, index) => {
        setInternalProperties(
            produce(properties, draft => {
                draft[index].value = value;
            }),
        );
    };

    const handleOnCustomButtonClick = onClick => {
        form.validateFields((err, properties) => {
            if (!err) {
                onClick(properties);
            }
        });
    };

    const customButtons = () => {
        if (buttons) {
            return buttons.map(button => (
                <Button
                    type="primary"
                    onClick={() => handleOnCustomButtonClick(button.onClick)}
                    key={button.title}
                    label={button.title}
                />
            ));
        } else {
            return <></>;
        }
    };

    const [connections, setConnections] = useState([]);
    const [selectedConnection, setSelectedConnection] = useState([]);
    const [connectionProperties, setConnectionProperties] = useState([]);
    const [connectionPropertyValues, setConnectionPropertyValues] = useState(
        {},
    );
    const [selectExtPropertyValues, setSelectExtPropertyValues] = useState([]);
    const [selectExtPropertyIndex, setSelectExtPropertyIndex] = useState(0);

    useEffect(() => {
        if (instanceId)
            connectionsService
                .fetchConfigurationsData(instanceId)
                .then(connections => {
                    setConnections(JSON.parse(connections.request.response));
                });
    }, [instanceId]);

    const onChangeConnection = value => {
        setConnectionProperties(null);
        var valueParts = value.split("/");
        setSelectedConnection([valueParts[0], valueParts[1], valueParts[2]]);
        let connection = connections.find(
            c => c.connectionName === valueParts[0],
        );
        if (valueParts[1]) {
            let configuration = connection.configurations.find(
                c => c.systemName === valueParts[1],
            );
            if (valueParts[2]) {
                let properties = configuration.properties[valueParts[2]];
                setConnectionProperties(properties);
                setConnectionPropertyValues({});
            }
        }
    };

    const onChangeConnectionProperty = e => {
        setConnectionPropertyValues({
            ...connectionPropertyValues,
            [e.target.name]: e.target.value,
        });
    };

    const handleConnectionOnNext = property => {
        connectionsService
            .fetchConnectionData(
                instanceId,
                selectedConnection[0],
                selectedConnection[2],
                connectionPropertyValues,
            )
            .then(result => {
                setSelectExtPropertyValues({
                    ...selectExtPropertyValues,
                    [property.name]: result.data,
                });
                setSelectExtPropertyIndex(selectExtPropertyIndex + 1);
            });
    };

    const selectExtProperties = properties?.filter(
        p => p.type === SELECT_EXT_TYPE,
    );

    const handleOnNext = values => {
        form.validateFields((err, values) => {
            if (!err) {
                onNext({ properties });
            }
        });
    };
    const selectedProperty = selectExtProperties[selectExtPropertyIndex];
    console.log("%c[IntegrationProperties.rndr]", "color:blue;", {
        properties,
        selectedProperty,
        selectExtProperties,
        selectExtPropertyIndex,
        selectExtPropertyValues,
    });

    return (
        <Row>
            <Col sm={12}>
                <StepUiHeaders ui={step.ui} />
                {connections?.length > 0 && selectedProperty ? (
                    <>
                        {[selectedProperty].map(property => {
                            return (
                                <>
                                    <Form onSubmit={handleOnNext}>
                                        <Text>
                                            <T
                                                id="package-select-ext-connection-description"
                                                values={{
                                                    label: property.label,
                                                }}
                                            />
                                        </Text>
                                        <Gap size="medium" />
                                        <Form.Item
                                            key="connection"
                                            label={t(
                                                "package-select-ext-connection-label",
                                            )}
                                        >
                                            <Select
                                                id="connection"
                                                showSearch={true}
                                                onChange={value =>
                                                    onChangeConnection(value)
                                                }
                                            >
                                                {connections.map(connection => {
                                                    return connection
                                                        .configurations
                                                        ?.length == 0 ? (
                                                        <Option
                                                            title={
                                                                connection.connectionName
                                                            }
                                                            key={
                                                                connection.connectionName
                                                            }
                                                            value={
                                                                connection.connectionName
                                                            }
                                                        >
                                                            {
                                                                connection.connectionName
                                                            }
                                                        </Option>
                                                    ) : (
                                                        connection.configurations.map(
                                                            configuration => {
                                                                return configuration.configurationNames.map(
                                                                    configName => {
                                                                        var item = `${connection.connectionName}/${configuration.systemName}/${configName}`;
                                                                        return (
                                                                            <Option
                                                                                title={
                                                                                    item
                                                                                }
                                                                                key={
                                                                                    item
                                                                                }
                                                                                value={
                                                                                    item
                                                                                }
                                                                            >
                                                                                {
                                                                                    item
                                                                                }
                                                                            </Option>
                                                                        );
                                                                    },
                                                                );
                                                            },
                                                        )
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                        {connectionProperties && (
                                            <>
                                                {connectionProperties.map(
                                                    property => (
                                                        <Form.Item
                                                            key={property.name}
                                                            label={
                                                                property.name
                                                            }
                                                        >
                                                            <Input
                                                                id={
                                                                    property.name
                                                                }
                                                                name={
                                                                    property.name
                                                                }
                                                                value={
                                                                    connectionPropertyValues[
                                                                        property
                                                                            .name
                                                                    ]
                                                                }
                                                                onChange={
                                                                    onChangeConnectionProperty
                                                                }
                                                            />
                                                        </Form.Item>
                                                    ),
                                                )}
                                            </>
                                        )}
                                    </Form>
                                    <ButtonGroup>
                                        {customButtons()}
                                        <Button
                                            type="primary"
                                            onClick={() =>
                                                handleConnectionOnNext(property)
                                            }
                                            label={
                                                <FormattedMessage id="package-data-upload.button.next" />
                                            }
                                        />
                                    </ButtonGroup>
                                </>
                            );
                        })}
                    </>
                ) : (
                    <>
                        <Form onSubmit={handleOnNext}>
                            {properties.map((property, index) => {
                                return (
                                    <Form.Item
                                        key={property.name}
                                        label={property.label}
                                    >
                                        {getFieldDecorator(property.name, {
                                            initialValue: property.value,
                                            rules: [
                                                {
                                                    required: property.required
                                                        ? property.required
                                                        : false,
                                                    message: `Please input ${property.label}`,
                                                },
                                            ],
                                        })(
                                            getComponentByType({
                                                property,
                                                onChange,
                                                index,
                                                instanceId,
                                                selectExtPropertyValues,
                                            }),
                                        )}
                                    </Form.Item>
                                );
                            })}
                        </Form>
                        <ButtonGroup>
                            {customButtons()}
                            <Button
                                type="primary"
                                onClick={handleOnNext}
                                label={
                                    <FormattedMessage id="package-data-upload.button.next" />
                                }
                            />
                            <BackButton onBack={onBack} />
                        </ButtonGroup>
                    </>
                )}
            </Col>
        </Row>
    );
};

IntegrationProperties.propTypes = {
    step: PropTypes.object.isRequired,
    globalState: PropTypes.object.isRequired,
    changeState: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    buttons: PropTypes.array,
};

export default Form.create({})(IntegrationProperties);
