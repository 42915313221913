import { Forms, Gap, Text } from "@/components/DesignSystem/";
import { useIntegrationsOptionsForDeploy } from "@/components/Marketplace/components/DeployTemplateCard/loadables";
import {
    LoadableRenderer,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { InstanceSelectForm } from "./InstanceSelectForm.component";
import Warning from "./Warning.component";

const InstanceWarning = ({
    onConfirm,
    onCancel,
    accountId,
    instanceId,
    allowSelect,
    templateResource,
    pending,
    visible,
    alert,
}) => {
    const { formId, handleSubmit } = Forms.useForm({
        onSubmit: ({ values }) => {
            onConfirm({ destinationId: values.instanceId || instanceId });
        },
    });

    const instancesOptionsResource = useIntegrationsOptionsForDeploy({
        accountId,
        canFetch: accountId && visible,
    });

    const resources = useComposeLoadablesMemoized([
        templateResource.loadable,
        instancesOptionsResource.loadable,
    ]);

    return (
        <LoadableRenderer
            loading={() => null}
            loadable={resources}
            hasValue={([template, instances]) => {
                const selectedInstance = instances.find(
                    instance => instance.value === instanceId,
                );
                return (
                    <Warning
                        onConfirm={() =>
                            onConfirm({ destinationId: instanceId })
                        }
                        onCancel={onCancel}
                        formId={allowSelect ? formId : undefined}
                        title={t("warning-step.title.template")}
                        isDisabledConfirm={pending}
                        visible={visible}
                        alert={alert}
                    >
                        <Text>
                            {allowSelect
                                ? t("warning-step.message.no-instance", {
                                      templateName: (
                                          <strong
                                              key="templateName"
                                              id={`${instanceId}-templateName`}
                                          >
                                              {template.label}
                                          </strong>
                                      ),
                                  })
                                : t("warning-step.message.instance", {
                                      templateName: (
                                          <strong
                                              key="templateName"
                                              id={`${instanceId}-templateName`}
                                          >
                                              {template.label}
                                          </strong>
                                      ),
                                      instanceName: (
                                          <strong
                                              key="instanceName"
                                              id={`${instanceId}-instanceName`}
                                          >
                                              {selectedInstance.label}
                                          </strong>
                                      ),
                                  })}
                            {t("warning-step.message.instance-warn")}
                        </Text>
                        <Text>{t("warning-step.message.note")}</Text>
                        <Gap />
                        <Text type="error">
                            {t("warning-step.message.importantPart")}
                        </Text>
                        {allowSelect && (
                            <InstanceSelectForm
                                formId={formId}
                                instancesOptionsResource={
                                    instancesOptionsResource
                                }
                                handleSubmit={handleSubmit}
                            />
                        )}
                    </Warning>
                );
            }}
        />
    );
};

InstanceWarning.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    instanceId: PropTypes.number.isRequired,
    allowSelect: PropTypes.bool.isRequired,
    pending: PropTypes.bool,
    visible: PropTypes.bool,
    templateResource: PropTypes.object.isRequired,
};

export default InstanceWarning;
