import { TYPE } from "@/components/CreateJobTaskAlert/constants";
import { CreateJobTaskAlertModal } from "@/components/CreateJobTaskAlert/CreateJobTaskAlert.modal";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { instanceRepoIntegrationsRoutesColumns } from "@/components/IntegrationRoutes/InstanceRepoIntegrationsRoutes.columns";
import { useRouteNameValidator } from "@/components/IntegrationRoutes/validators";
import { CreateLocalCopyModal } from "@/components/Mappers/CreateLocalCopyModal";
import { isLoading } from "@/modules/loadable";
import {
    ACCOUNT_ALERT_EDIT_PERMISSIONS,
    INTEGRATION_EDIT_PERMISSIONS,
    INTEGRATION_READ_EDIT_PERMISSIONS,
} from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { getInstanceRepoIntegrationsRoutesTrackName as getTrackName } from "../../mixpanel/buttonNames";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import {
    useDeleteInstanceRepoRouteMutation,
    useInstanceRepoIntegrationsRoutesQuery,
    useLocalCopyMutation,
    useStartRouteMutation,
    useStopRouteMutation,
} from "./loadables";

const preferencesType = preferencesTypes.INSTANCE_REPO_INTEGRATIONS_ROUTES;
const type = "Route";
export const FIELD_NAME = "uniqueName";

export const DEPLOYMENT_STATUS = {
    FAILED: "Failed",
};

export const InstanceRepoIntegrationsRoutes = ({
    accountId,
    instanceId,
    visible,
    afterLocalCopy,
    afterDelete,
    reloadToken,
}) => {
    const {
        locationRouterService,
        accountAppLocations: {
            routeDefinitionLocation,
            routeDiagramLocation,
            routeDocumentationLocation,
            routeSendPayloadLocation,
        },
    } = useDic();
    const alertModal = useDetailDrawerState();
    const integrationsRoutesQuery = useInstanceRepoIntegrationsRoutesQuery({
        instanceId,
        reloadToken,
    });
    const mutationParams = {
        instanceId,
        afterSuccess: integrationsRoutesQuery.reload,
    };
    const startRouteMutation = useStartRouteMutation(mutationParams);
    const stopRouteMutation = useStopRouteMutation(mutationParams);
    const deleteRouteMutation = useDeleteInstanceRepoRouteMutation({
        instanceId,
        afterSuccess: afterDelete,
    });

    const initialValues = useMemo(
        () => ({
            targetType: "ROUTE",
            target: alertModal.record?.uniqueName,
            entityIds: [instanceId],
            type: TYPE.ROUTE_CHECK,
        }),
        [alertModal.record?.uniqueName, instanceId],
    );

    const dataSource = integrationsRoutesQuery.loadable.valueMaybe() || [];
    const loading = isLoading(integrationsRoutesQuery);

    const goToRoute = useCallback(
        routeLocation => record =>
            locationRouterService.navigate(routeLocation, {
                instanceId,
                accountId,
                routeId: record.id,
                routeIdOrPrn: encodeURIComponent(
                    !record.standard ? record.prn : record.id,
                ),
                isNonStandardRoute: !record.standard,
                key: record.key,
            }),
        [accountId, instanceId, locationRouterService],
    );

    const nameValidator = useRouteNameValidator({
        instanceId,
        withOnline: false,
    });

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        visible: record.editable,
                        title: t("mapper-list.create-local-copy.title"),
                        customModal: (
                            <CreateLocalCopyModal
                                instanceId={instanceId}
                                record={record}
                                afterSuccess={afterLocalCopy}
                                fieldName={FIELD_NAME}
                                entityName={type}
                                useLocalCopyMutation={useLocalCopyMutation}
                                nameValidator={nameValidator}
                            />
                        ),
                        track: { name: getTrackName("LocalCopy") },
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                    },
                    {
                        title: t("routes-list.action.send-payload"),
                        onClick: goToRoute(routeSendPayloadLocation),
                        visible:
                            record.status !== "Stopped" &&
                            record.type === "PUBLIC" &&
                            record.deploymentStatus !==
                                DEPLOYMENT_STATUS.FAILED,
                        track: { name: getTrackName("SendPayload") },
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                    },
                    {
                        title: t("routes-list.action.definition"),
                        onClick: goToRoute(routeDefinitionLocation),
                        permission: INTEGRATION_READ_EDIT_PERMISSIONS,
                    },
                    {
                        title: t("routes-list.action.diagram"),
                        onClick: goToRoute(routeDiagramLocation),
                        visible:
                            record.deploymentStatus !==
                            DEPLOYMENT_STATUS.FAILED,
                        permission: INTEGRATION_READ_EDIT_PERMISSIONS,
                    },
                    {
                        title: t("table.action.create.notification"),
                        onClick: alertModal.show,
                        permission: ACCOUNT_ALERT_EDIT_PERMISSIONS,
                    },
                    {
                        title: t("routes-list.action.documentation"),
                        onClick: goToRoute(routeDocumentationLocation),
                        visible:
                            record.deploymentStatus !==
                            DEPLOYMENT_STATUS.FAILED,
                        permission: INTEGRATION_READ_EDIT_PERMISSIONS,
                    },
                    {
                        title: t("routes-list.action.stop"),
                        onClick: stopRouteMutation.mutate,
                        visible:
                            record.type === "PUBLIC" &&
                            record.status === "Started",
                        track: { name: getTrackName("Stop") },
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                    },
                    {
                        title: t("routes-list.action.start"),
                        onClick: startRouteMutation.mutate,
                        visible:
                            record.type === "PUBLIC" &&
                            record.status === "Stopped",
                        track: { name: getTrackName("Start") },
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                    },
                    {
                        visible: record.deletable,
                        title: t("general.modal.delete.title", { type }),
                        confirm: {
                            message: t("general.modal.delete.message", {
                                name: record.uniqueName,
                                type,
                            }),
                            onConfirm: deleteRouteMutation.mutate,
                        },
                        color: "red",
                        track: { name: getTrackName("Delete") },
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                    },
                ]}
            />
        ),
        [
            afterLocalCopy,
            alertModal.show,
            deleteRouteMutation.mutate,
            goToRoute,
            instanceId,
            nameValidator,
            routeDefinitionLocation,
            routeDiagramLocation,
            routeDocumentationLocation,
            routeSendPayloadLocation,
            startRouteMutation.mutate,
            stopRouteMutation.mutate,
        ],
    );

    return (
        <>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                columns={instanceRepoIntegrationsRoutesColumns}
                dataSource={dataSource}
                loading={loading}
                fixed
                restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
                rowKey={FIELD_NAME}
                datasetSlicing="local"
                preferencesType={preferencesType}
                visible={visible}
                defaultSort={{
                    sortSpecifiers: [
                        { property: "updatedAt", direction: "descending" },
                    ],
                }}
            />
            <CreateJobTaskAlertModal
                title={t("alerts.modal-create.integration-title")}
                visible={alertModal.record}
                afterSave={alertModal.hide}
                onCancel={alertModal.hide}
                projectId={accountId}
                initialValues={initialValues}
            />
        </>
    );
};

InstanceRepoIntegrationsRoutes.propTypes = {
    instanceId: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
    afterLocalCopy: PropTypes.func,
    afterDelete: PropTypes.func,
    reloadToken: PropTypes.any,
    visible: PropTypes.bool,
};
