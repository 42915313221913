export const API_STEP = {
    SETUP_PRODUCT_MASTER: "setup-product-master",
    SETUP_CUSTOMER_MASTER: "setup-customer-master",
    SETUP_SELLER_MASTER: "setup-seller-master",
    CREATE_DATASOURCE: "create-datasource",
    DATA_UPLOAD_UI: "data-upload-ui",
    CREATE_PX_UI: "create-px-ui",
    CREATE_CX_UI: "create-cx-ui",
    CREATE_PP_UI: "create-pp-ui",
    GENERIC_FORM_UI: "generic-form-ui",
    FORM_INTEGRATION_PROPERTIES: "form-integration-properties",
    PRODUCT_COMPETITION_UI: "create-pcomp-ui",
    INTEGRATION_INIT: "integration-init",
    INTEGRATION_FILTER: "integration-filter",
    EXPORT_MAPPER: "export-mapper",
    INTEGRATION_PROPERTIES: "integration-properties",
    CONDITION_STEP: "condition-step",
    INTEGRATION_SFTP: "integration-sftp",
    INTEGRATION_CONNECTION: "integration-connection",
    INTEGRATION_MAPPER: "integration-mapper",
    GENERAL_MAPPER: "general-mapper",
    INTEGRATION_CSV_MAPPER: "integration-csv-mapper",
    FILTER_FORM_UI: "filter-form-ui",
    APPEND_TO_PP_GENERIC_FORM_UI: "append-to-pp-ui",
    WATERFALL_CONFIGURATION: "waterfall-configuration",
    SKIP_DEPENDENCIES_UI: "skip-dependencies-option-ui",
    SETUP_SIMULATION: "setup-simulation",
    SETUP_REBATE_HEADER_TYPE: "setup-rebate-header-type-step",
};

export const API_STEP_NON_UI = {
    DATA_LOAD: "data-load",
    UOM_DATASOURCE_LOAD: "uom-datasource-load",
    SYNC_PRODUCT_DL: "sync-product-dl",
    SYNC_CUSTOMER_DL: "sync-customer-dl",
    SYNC_PRODUCT_METADATA: "sync-product-metadata",
    SYNC_CUSTOMER_METADATA: "sync-customer-metadata",
    DS_STRING_TO_DIMENSION_CHANGE: "ds-string-to-dimension-change",
    DEPLOY_LOGIC: "deploy-logic",
    CURRENCY_DATASOURCE_LOAD: "currency-datasource-load",
    CALENDAR_DATASOURCE_LOAD: "calendar-datasource-load",
    CREATE_DATAMART: "create-data-mart",
    TRIGGER_LOGIC: "trigger-logic",
    TRIGGER_CFS: "trigger-cfs",
    STORE_STATE: "store-state",
};

export const FE_STEP = {
    DATA_UPLOAD: "DATA_UPLOAD",
    MANDATORY_FIELDS: "MANDATORY_FIELDS",
    FILE_UPDATE: "FILE_UPDATE",
    FILE_PARSING: "FILE_PARSING",
    OPTIONAL_FIELDS_SELECT: "OPTIONAL_FIELDS_SELECT",
    OPTIONAL_FIELDS: "OPTIONAL_FIELDS",
    EXTENSION_MANDATORY_FIELDS: "EXTENSION_MANDATORY_FIELDS",
    EXTENSION_OPTIONAL_FIELDS: "EXTENSION_OPTIONAL_FIELDS",
    EXTENSION_PREVIEW: "EXTENSION_PREVIEW",
    PRICE_PARAMETERS: "create-pp-ui",
    GENERIC_FORM: "GENERIC_FORM",
    FORM_INTEGRATION_PROPERTIES: "FORM_INTEGRATION_PROPERTIES",
    INTEGRATION_MAPPER_DEFINITION: "INTEGRATION_MAPPER_DEFINITION",
    GENERAL_MAPPER_DEFINITION: "GENERAL_MAPPER_DEFINITION",
    INTEGRATION_CSV_MAPPER_DEFINITION: "INTEGRATION_CSV_MAPPER_DEFINITION",
    FILTER_FORM: "FILTER_FORM",
    CREATE_DATA_UPLOAD: "CREATE_DATA_UPLOAD",
    SELECT_IM_INSTANCE: "SELECT_IM_INSTANCE",
    PP_SIMPLE: "SIMPLE",
    PP_RANGE: "RANGE",
    PP_MATRIX: "MATRIX",
    PP_MATRIX_KEYS: "PP_MATRIX_KEYS",
    PP_MATRIX_ATTRIBUTES: "PP_MATRIX_ATTRIBUTES",
    SKIP_DEPENDENCIES: "SKIP_DEPENDENCIES",
};

export const labelsByStep = {
    [API_STEP.SETUP_PRODUCT_MASTER]: "package.message.setup-product-master",
    [API_STEP.SETUP_CUSTOMER_MASTER]: "package.message.setup-customer-master",
    [API_STEP.CREATE_DATASOURCE]: "package.message.create-datasource",
    [API_STEP.DATA_UPLOAD_UI]: "package.message.data-upload-ui",
    [API_STEP.CREATE_PX_UI]: "package.message.create-px",
    [API_STEP.CREATE_PP_UI]: "package.message.create-pp",
    [API_STEP.CREATE_CX_UI]: "package.message.create-cx",
    [API_STEP.GENERIC_FORM_UI]: "package.message.generic-form",
    [API_STEP.FORM_INTEGRATION_PROPERTIES]:
        "package.message.integration-properties",
    [API_STEP.INTEGRATION_INIT]: "package.message.integration-init",
    [API_STEP.INTEGRATION_PROPERTIES]: "package.message.integration-properties",
    [API_STEP.INTEGRATION_SFTP]: "package.message.integration-sftp",
    [API_STEP.INTEGRATION_CONNECTION]: "package.message.integration-connection",
    [API_STEP.PRODUCT_COMPETITION_UI]: "package.message.competition-data",
    [API_STEP.INTEGRATION_MAPPER]: "package.message.integration-mapper",
    [API_STEP.INTEGRATION_CSV_MAPPER]: "package.message.integration-csv-mapper",
    [API_STEP.APPEND_TO_PP_GENERIC_FORM_UI]: "package.message.append-to-pp",
    //non ui steps
    [API_STEP_NON_UI.DATA_LOAD]: "package.message.data-load",
    [API_STEP_NON_UI.UOM_DATASOURCE_LOAD]:
        "package.message.uom-datasource-load",
    [API_STEP_NON_UI.SYNC_PRODUCT_DL]: "package.message.sync-product-dl",
    [API_STEP_NON_UI.SYNC_CUSTOMER_DL]: "package.message.sync-customer-dl",
    [API_STEP_NON_UI.SYNC_PRODUCT_METADATA]:
        "package.message.sync-product-metadata",
    [API_STEP_NON_UI.SYNC_CUSTOMER_METADATA]:
        "package.message.sync-customer-metadata",
    [API_STEP_NON_UI.DS_STRING_TO_DIMENSION_CHANGE]:
        "package.message.ds-string-to-dimension-change",
    [API_STEP_NON_UI.DEPLOY_LOGIC]: "package.message.deploy-logic",
    [API_STEP_NON_UI.CURRENCY_DATASOURCE_LOAD]:
        "package.message.currency-datasource-load",
    [API_STEP_NON_UI.CALENDAR_DATASOURCE_LOAD]:
        "package.message.calendar-datasource-load",
    [API_STEP_NON_UI.CREATE_DATAMART]: "package.message.create-data-mart",
    [API_STEP_NON_UI.TRIGGER_LOGIC]: "package.message.trigger-logic",
    [API_STEP_NON_UI.TRIGGER_CFS]: "package.message.trigger-cfs",
    [API_STEP_NON_UI.STORE_STATE]: "package.message.store-state",
};

export const MAX_EXTENSION_COLUMNS = 50;
// export const CURRENCY = "Currency";

export const EMPTY_MAPPING_FUNC = (step, data) => data;

export const InputType = Object.freeze({
    BODY: "body",
    HEADER: "header",
    PROPERTY: "property",
    SIMPLE: "simple",
    CONSTANT: "constant",
    GROOVY: "groovy",
    FORMULA: "formula",
    COMPOSED: "composed",
});

export const ParsedType = Object.freeze({
    NUMBER: "NUMBER",
    STRING: "STRING",
    DATE: "DATE",
    DATE_TIME: "DATE_TIME",
    BOOLEAN: "BOOLEAN",
    COMBINED: "COMBINED",
    INTEGER: "INTEGER",
});

// TODO: IMHO sould not be GROOVY
export const ValueType = Object.freeze({
    FIELD: "FIELD", // type string
    EXPERT_OPTION: "EXPERT_OPTION", // type header, simple, property, constant
    COMPOSED: "COMPOSED",
    GROOVY: "GROOVY",
    FORMULA: "FORMULA",
});

export const types = [
    {
        label: "Number",
        name: ParsedType.NUMBER,
    },
    {
        label: "String",
        name: ParsedType.STRING,
    },
    {
        label: "Date",
        name: ParsedType.DATE,
    },
    {
        label: "DateTime",
        name: ParsedType.DATE_TIME,
    },
    {
        label: "Boolean",
        name: ParsedType.BOOLEAN,
    },
];
