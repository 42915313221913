import { Modal, UnityLayout } from "@/components/DesignSystem";
import { t } from "@/translations";
import React from "react";
import { GroupForm, useGroupForm } from "./GroupForm";

export const NewGroupModal = ({ visible, onClose, reloadGroups }) => {
    const { submit, cancel, ...rest } = useGroupForm({
        onCancel: onClose,
        initialValues: undefined,
        afterSave: () => {
            reloadGroups();
            onClose();
        },
    });

    return (
        <Modal visible={visible} onCancel={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("group-form.title.new-group")}
                />
                <UnityLayout.Content padding={[true, true]}>
                    <GroupForm {...rest} inputWidth="max" />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {submit}
                            {cancel}
                        </div>
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
