import { useDic } from "@/components/Dic/useDic.hook";
import { pendingPromise, useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { isNil } from "lodash";

export const useRemoteLoginInstancesQuery = () => {
    const { registryService } = useDic();

    return useQueryLoadable(
        async () => registryService.getRemoteLoginInstances().then(getData),
        [registryService],
    );
};

export const useRemoteLoginUsersQuery = ({ hasPermission = false }) => {
    const { registryService } = useDic();

    return useQueryLoadable(
        async () =>
            !hasPermission
                ? pendingPromise()
                : registryService.getRemoteLoginUsers().then(getData),
        [hasPermission, registryService],
    );
};

export const useRemoteLoginPartitionNamesQuery = ({ clusterName }) => {
    const { registryService } = useDic();
    return useQueryLoadable(
        async () =>
            isNil(clusterName)
                ? []
                : registryService
                      .partitionNamesByCluster(clusterName)
                      .then(getData),
        [registryService, clusterName],
    );
};
