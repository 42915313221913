import { Icon, Tooltip } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import React from "react";

export const IconWithTooltip = ({ tooltip, display, color, icon }) => {
    if (!display) return null;

    return (
        <Tooltip title={tooltip}>
            <Icon type={icon} style={{ color }} />
        </Tooltip>
    );
};

IconWithTooltip.propTypes = {
    tooltip: PropTypes.string,
    display: PropTypes.any,
    color: PropTypes.any,
    icon: PropTypes.string,
};
