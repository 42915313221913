import {
    Alert,
    ButtonMenu,
    Gap,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { formatDate } from "../../../../views/Package/package.utils";

const MODAL_WIDTH = 672;

export function UnfinishedPartitionWarningModal({
    visible,
    pending,
    template,
    unfinishedDeployment,
    onRollback,
    requestTerminationMutation,
    onContinue,
    onCancel,
}) {
    const isDifferentDeployment =
        template.uniqueName !== unfinishedDeployment?.templateName;

    return (
        <Modal visible={visible} onClose={onCancel} width={MODAL_WIDTH}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t(
                        "package.import-info-modal.unfinished-deployment.title",
                    )}
                />
                <UnityLayout.Content padding={[false, true]}>
                    {isDifferentDeployment && (
                        <>
                            <Alert
                                showIcon
                                type="warning"
                                message={t(
                                    `package.import-info-modal.alert-different-deployment`,
                                    {
                                        templateName:
                                            unfinishedDeployment?.templateName,
                                        user: unfinishedDeployment?.startedBy,
                                        date: formatDate(
                                            unfinishedDeployment?.startedAt,
                                        ),
                                    },
                                )}
                            />
                            <Gap />
                        </>
                    )}
                    <Text>
                        {t("package.import-info-modal.before-continue", {
                            templateName: <b>{template.uniqueName}</b>,
                        })}
                    </Text>
                    <Gap />
                    <ul style={{ fontSize: "14px", paddingLeft: "24px" }}>
                        <li>
                            {t(
                                "package.import-info-modal.option.finish-previous",
                                {
                                    templateName: (
                                        <b>
                                            {unfinishedDeployment?.templateName}
                                        </b>
                                    ),
                                },
                            )}
                        </li>
                        {onRollback && (
                            <li>
                                {t(
                                    "package.import-info-modal.option.rollback",
                                    {
                                        templateName: (
                                            <b>
                                                {
                                                    unfinishedDeployment?.templateName
                                                }
                                            </b>
                                        ),
                                    },
                                )}
                            </li>
                        )}
                        <li>
                            {t("package.import-info-modal.option.termination")}
                        </li>
                    </ul>
                    <Gap size="large" />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t(
                                        "package.import-info-modal.continue-deployment",
                                    ),
                                    type: "primary",
                                    onClick: onContinue,
                                    loading: pending,
                                },
                                onRollback && {
                                    label: t(
                                        "package.import-info-modal.rollback",
                                    ),
                                    onClick: onRollback,
                                },
                                requestTerminationMutation && {
                                    label: t(
                                        "package.import-info-modal.request-termination",
                                    ),
                                    onClick: requestTerminationMutation.mutate,
                                    loading: isLoading(
                                        requestTerminationMutation,
                                    ),
                                },
                                {
                                    label: t("general.cancel"),
                                    type: "text",
                                    onClick: onCancel,
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
}

UnfinishedPartitionWarningModal.propTypes = {
    unfinishedDeployment: PropTypes.shape({
        templateName: PropTypes.string,
        startedBy: PropTypes.string,
        startedAt: PropTypes.string,
    }),
    template: PropTypes.object.isRequired,
    onContinue: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    onRollback: PropTypes.func,
    requestTerminationMutation: PropTypes.object,
    hide: PropTypes.func,
    visible: PropTypes.bool,
};
