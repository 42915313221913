import { Button, Gap, Link, Table, Text } from "@/components/DesignSystem";
import { useValueVisibility } from "@/components/hooks/useValueVisibility.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { ACCOUNT_API_TOKEN_DOCUMENTATION_URL } from "@/constants/CommonConstats";
import { messageService } from "@/modules/Message/message.service";
import { LoadableRenderer } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import React from "react";
import { columns } from "./AccountApiTokenTable.columns";
import { ModalApiTokenCreated } from "./ModalApiTokenCreated";
import { ModalCreateApiToken } from "./ModalCreateApiToken";
import { useApiTokenQuery, useDeleteApiTokenMutation } from "./loadables";

export const AccountApiTokenPage = () => {
    const message = messageService();

    const { accountId } = useAccountAppParams();

    const apiTokenResource = useApiTokenQuery({ accountId });

    const deleteApiTokenMutation = useDeleteApiTokenMutation({
        accountId,
        afterSuccess: apiTokenResource.reload,
    });

    const createApiTokenModal = useVisibility();
    const apiTokenModalCreated = useValueVisibility();

    return (
        <>
            {!!accountId && (
                <LoadableRenderer
                    loadable={apiTokenResource.loadable}
                    hasValue={apiTokenSource => {
                        return (
                            <>
                                <Text
                                    dataTest="api-token-description"
                                    size="large"
                                >
                                    {t("api-token.description")}
                                    <Link
                                        href={
                                            ACCOUNT_API_TOKEN_DOCUMENTATION_URL
                                        }
                                        target="_blank"
                                        data-test="api-token-documentation-link"
                                    >
                                        {t("general.documentation-lowercase")}
                                    </Link>
                                    .
                                </Text>
                                <Gap size="large" />
                                <Table
                                    dataSource={apiTokenSource}
                                    columns={columns({
                                        onDelete: deleteApiTokenMutation.mutate,
                                    })}
                                    padding={false}
                                />
                                <Gap size="small" />
                                <Button
                                    icon={Plus}
                                    data-test="new-api-token-button"
                                    label={t("api-token.button.new-token")}
                                    size="small"
                                    onClick={() =>
                                        apiTokenSource.length
                                            ? message.warn({
                                                  content: t(
                                                      "api-token.message.already-existing",
                                                  ),
                                                  duration: 2,
                                              })
                                            : createApiTokenModal.show()
                                    }
                                />
                            </>
                        );
                    }}
                />
            )}
            <ModalCreateApiToken
                visible={createApiTokenModal.visible}
                onClose={createApiTokenModal.hide}
                onSuccess={({ token }) => {
                    apiTokenResource.reload();
                    apiTokenModalCreated.show(token);
                }}
                accountId={accountId}
            />
            <ModalApiTokenCreated
                visible={apiTokenModalCreated.visible}
                onClose={apiTokenModalCreated.hide}
                token={apiTokenModalCreated.value}
            />
        </>
    );
};
