import { Badge, Gap, UnityLayout } from "@/components/DesignSystem";
import { colors } from "@/components/DesignSystem/colors";
import {
    LoadableRenderer,
    STATUSES,
    useQueryLoadable,
    waitForValue,
} from "@/modules/loadable";
import { t } from "@/translations";
import { groupBy } from "lodash/fp";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { axiosService } from "../../axios";
import { Property } from "../Property/Property.component";
import SlotsCalendar from "./components/SlotsCalendar.component";
import SlotStatus from "./components/SlotStatus.component";
import { DATE, DATETIME } from "./date-formats";
import { UpgradeTime } from "./UpgradeTime";
import styles from "./UserUpgradeSlots.style.less";

export const UserUpgradeSlots = ({ record }) => {
    const getUserUpgradeSlots = userId =>
        axiosService.post(`/api/user-available-times/${userId}`);

    const canFetch = record === null;
    const userAvailableTimes = useQueryLoadable(() => {
        return canFetch
            ? waitForValue()
            : getUserUpgradeSlots(record.userId).then(({ data }) => data);
    }, [canFetch, record?.userId]);

    const grouppedTimeslots = useMemo(
        () =>
            userAvailableTimes.loadable.state === STATUSES.hasValue
                ? groupBy(item => moment(item.timeFrom, DATETIME).format(DATE))(
                      userAvailableTimes.loadable.contents,
                  )
                : {},
        [userAvailableTimes.loadable.state],
    );

    const [selectedDate, setSelectedDate] = useState(
        moment(record?.timeFrom).format(DATE),
    );

    useEffect(() => {
        setSelectedDate(moment(record?.timeFrom).format(DATE));
    }, [record.id]);

    const [selectedTimeslot, setSelectedTimeslot] = useState(
        grouppedTimeslots[selectedDate]?.[0],
    );

    useEffect(() => {
        setSelectedTimeslot(grouppedTimeslots[selectedDate]?.[0]);
    }, [grouppedTimeslots, selectedDate]);

    const dateCellRender = cellDate => {
        const hasTimeSlots =
            grouppedTimeslots[cellDate.format(DATE)]?.length > 0;
        return hasTimeSlots ? (
            <Badge color={colors.primary} status="default" />
        ) : null;
    };

    return (
        <LoadableRenderer
            loadable={userAvailableTimes.loadable}
            hasValue={() => {
                return (
                    <UnityLayout>
                        <UnityLayout.Header
                            size={4}
                            title={t("cluster.upgrade.slots.detail.title", {
                                name: record.username,
                            })}
                        />
                        <UnityLayout.Content
                            padding={[true, true]}
                            className="templateEditForm"
                        >
                            <SlotsCalendar
                                value={moment(selectedDate, DATE)}
                                onChange={date =>
                                    setSelectedDate(date.format(DATE))
                                }
                                fullscreen={false}
                                dateCellRender={dateCellRender}
                            />
                            <Gap size="small" />
                            {grouppedTimeslots[selectedDate]?.length > 0 && (
                                <div className={styles.wrapper}>
                                    {grouppedTimeslots[selectedDate].map(
                                        timeslot => (
                                            <UpgradeTime
                                                key={timeslot.id}
                                                start={timeslot.timeFrom}
                                                end={timeslot.timeTo}
                                                onClick={() =>
                                                    setSelectedTimeslot(
                                                        timeslot,
                                                    )
                                                }
                                                isSelected={
                                                    selectedTimeslot?.id ===
                                                    timeslot.id
                                                }
                                            />
                                        ),
                                    )}
                                </div>
                            )}
                            <Gap size="medium" />
                            <Property
                                label={t("cluster.upgrade.slots.status")}
                                value={
                                    <SlotStatus>
                                        {selectedTimeslot?.status}
                                    </SlotStatus>
                                }
                            />
                        </UnityLayout.Content>
                    </UnityLayout>
                );
            }}
        />
    );
};

UserUpgradeSlots.propTypes = {
    record: PropTypes.object.isRequired,
};
