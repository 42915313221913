import { Forms } from "@/components/DesignSystem";
import { Button } from "@/components/DesignSystem/Button";
import { Input } from "@/components/DesignSystem/Input";
import { Menu } from "@/components/DesignSystem/Menu";
import { SubMenu } from "@/components/DesignSystem/Menu/SubMenu/SubMenu.component";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import { Dropdown } from "antd";
import { noop } from "lodash";
import { toPairs } from "lodash/object";
import React, { useMemo } from "react";

const calculateConvertersDropdown = (
    cs,
    onChange,
    converterExpressionReadOnly,
) => {
    const menu = (
        <Menu onClick={({ key }) => onChange(key)}>
            {toPairs(cs).map(pair => (
                <SubMenu title={pair[0]} key={pair[0]}>
                    {pair[1].map(c => (
                        <Menu.Item key={c}>{c}</Menu.Item>
                    ))}
                </SubMenu>
            ))}
        </Menu>
    );
    if (converterExpressionReadOnly)
        return <Button type="text" disabled={true} icon={Plus} />;

    return (
        <Dropdown overlay={menu}>
            <Button type="text" icon={Plus} size="small" />
        </Dropdown>
    );
};

export const ConverterExpressionComponent = ({
    value,
    onChange,
    converters,
    converterExpressionReadOnly,
    isForm = false,
    ...rest
}) => {
    const dropdown = useMemo(
        () =>
            calculateConvertersDropdown(
                converters,
                onChange,
                converterExpressionReadOnly,
            ),
        [converters, onChange, converterExpressionReadOnly],
    );
    const InputOrFormInput = isForm ? Forms.Fields.Input : Input;
    return (
        <InputOrFormInput
            disabled={converterExpressionReadOnly}
            type="text"
            value={value}
            placeholder="Converter"
            onChange={isForm ? noop : e => onChange?.(e?.target?.value)}
            addonAfter={dropdown}
            className="pmConvertorExpression"
            {...rest}
        />
    );
};

ConverterExpressionComponent.propTypes = {};
