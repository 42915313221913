import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";

export const useTokensListQuery = () => {
    const { tokensService } = useDic();

    return useQueryLoadable(
        async () => tokensService.fetchTokens().then(getData),
        [tokensService],
    );
};

export const useDeleteTokenMutation = ({ afterSuccess }) => {
    const { tokensService } = useDic();

    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async id =>
            tokensService.deleteToken(id).then(res => {
                afterSuccessCurrent();
                return res;
            }),
        [afterSuccessCurrent, tokensService],
        // eslint-disable-next-line no-undef
        ({ name }) => t("general.message.deleted", { type: "token", name }),
    );
};
