import { useConfirmModal } from "@/modules/modal";

export const useDeleteWithConfirmation = (
    callback,
    {
        title = "Do you really want to delete?",
        okText = "Delete",
        message = "This action can't be undone.",
    } = {},
) => {
    const confirmModal = useConfirmModal();
    return (...args) =>
        confirmModal.confirmDelete({
            title,
            message,
            okText,
            onConfirm: callback.bind(null, ...args),
        });
};
