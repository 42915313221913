import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";

const preferencesType = preferencesTypes.OFFERS_LIST_TABLE;

const columns = [
    {
        type: fieldTypes.TEXT,
        label: t("offer-list.header.label"),
        name: "label",
    },
    {
        type: fieldTypes.OPTION,
        options: [
            {
                label: t("general.yes"),
                value: true,
            },
            {
                label: t("general.no"),
                value: false,
            },
        ],
        label: t("offer-list.header.published"),
        name: "published",
        render: (text, record) => (
            <React.Fragment>
                {record.published ? t("general.yes") : t("general.no")}
            </React.Fragment>
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("offer-list.header.type"),
        name: "type",
    },
];

export const OffersTableComponent = ({ data, onEditClick, onDeleteClick }) => {
    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("offer-list.action.edit"),
                        onClick: () => onEditClick(record.id),
                    },
                    {
                        title: t("offer-list.action.delete"),
                        confirmMessage: t("offer-list.message.delete-confirm", {
                            name: record.label,
                        }),
                        onConfirm: () => onDeleteClick(record.id),
                        color: "red",
                    },
                ]}
            />
        ),
        [onEditClick, onDeleteClick],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            columns={columns}
            dataSource={data}
            fixed
            rowKey="id"
            datasetSlicing="local"
            preferencesType={preferencesType}
        />
    );
};

OffersTableComponent.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            published: PropTypes.bool.isRequired,
            type: PropTypes.string.isRequired,
        }),
    ),
    onEditClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
};
