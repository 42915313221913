import { API_STEP, FE_STEP } from "./constants";
import {
    basicIntegrationMappingFunc,
    createCompetitionMappingFunc,
    createDataSourceMappingFunc,
    createExtensionMappingFunc,
    createPPMappingFunc,
    dataUploadMappingFunc,
    genericFormMappingFunc,
    integrationCsvMapperMappingFunc,
    masterMappingFunc,
} from "./StepPlanMapping.func";

export const stepsPlans = {
    [API_STEP.SETUP_PRODUCT_MASTER]: stepPlanOptions =>
        calculateStepPlan(
            [
                FE_STEP.FILE_UPDATE,
                FE_STEP.FILE_PARSING,
                FE_STEP.MANDATORY_FIELDS,
                FE_STEP.OPTIONAL_FIELDS,
            ],
            stepPlanOptions,
        ),

    [API_STEP.SETUP_CUSTOMER_MASTER]: stepPlanOptions =>
        calculateStepPlan(
            [
                FE_STEP.FILE_UPDATE,
                FE_STEP.FILE_PARSING,
                FE_STEP.MANDATORY_FIELDS,
                FE_STEP.OPTIONAL_FIELDS,
            ],
            stepPlanOptions,
        ),

    [API_STEP.SETUP_SELLER_MASTER]: stepPlanOptions =>
        calculateStepPlan(
            [
                FE_STEP.FILE_UPDATE,
                FE_STEP.FILE_PARSING,
                FE_STEP.MANDATORY_FIELDS,
                FE_STEP.OPTIONAL_FIELDS,
            ],
            stepPlanOptions,
        ),

    [API_STEP.CREATE_DATASOURCE]: stepPlanOptions =>
        calculateStepPlan(
            [
                FE_STEP.FILE_UPDATE,
                FE_STEP.FILE_PARSING,
                FE_STEP.MANDATORY_FIELDS,
                FE_STEP.OPTIONAL_FIELDS_SELECT,
            ],
            stepPlanOptions,
        ),

    [API_STEP.DATA_UPLOAD_UI]: stepPlanOptions =>
        calculateStepPlan(
            [FE_STEP.FILE_UPDATE, FE_STEP.FILE_PARSING, FE_STEP.DATA_UPLOAD],
            stepPlanOptions,
        ),

    [API_STEP.CREATE_PX_UI]: stepPlanOptions =>
        calculateStepPlan(
            [
                FE_STEP.FILE_UPDATE,
                FE_STEP.FILE_PARSING,
                FE_STEP.EXTENSION_MANDATORY_FIELDS,
                FE_STEP.EXTENSION_OPTIONAL_FIELDS,
                FE_STEP.EXTENSION_PREVIEW,
            ],
            stepPlanOptions,
        ),

    [API_STEP.CREATE_CX_UI]: stepPlanOptions =>
        calculateStepPlan(
            [
                FE_STEP.FILE_UPDATE,
                FE_STEP.FILE_PARSING,
                FE_STEP.EXTENSION_MANDATORY_FIELDS,
                FE_STEP.EXTENSION_OPTIONAL_FIELDS,
                FE_STEP.EXTENSION_PREVIEW,
            ],
            stepPlanOptions,
        ),

    [API_STEP.INTEGRATION_FILTER]: stepPlanOptions => [
        API_STEP.INTEGRATION_FILTER,
    ],
    [API_STEP.GENERIC_FORM_UI]: stepPlanOptions => [FE_STEP.GENERIC_FORM],
    [API_STEP.FORM_INTEGRATION_PROPERTIES]: stepPlanOptions => [
        FE_STEP.FORM_INTEGRATION_PROPERTIES,
    ],

    [API_STEP.APPEND_TO_PP_GENERIC_FORM_UI]: stepPlanOptions => [
        FE_STEP.GENERIC_FORM,
    ],
    [FE_STEP.PRICE_PARAMETERS]: stepPlanOptions =>
        calculatePpPlan(stepPlanOptions),

    [API_STEP.INTEGRATION_PROPERTIES]: stepPlanOptions => [
        API_STEP.INTEGRATION_PROPERTIES,
    ],

    [API_STEP.INTEGRATION_SFTP]: stepPlanOptions => [API_STEP.INTEGRATION_SFTP],

    [API_STEP.INTEGRATION_CONNECTION]: stepPlanOptions => [
        API_STEP.INTEGRATION_CONNECTION,
    ],

    [API_STEP.PRODUCT_COMPETITION_UI]: stepPlanOptions =>
        calculateStepPlan(
            [
                FE_STEP.FILE_UPDATE,
                FE_STEP.FILE_PARSING,
                FE_STEP.MANDATORY_FIELDS,
                FE_STEP.OPTIONAL_FIELDS_SELECT,
            ],
            stepPlanOptions,
        ),

    [API_STEP.INTEGRATION_MAPPER]: stepPlanOptions => [
        FE_STEP.INTEGRATION_MAPPER_DEFINITION,
    ],
    [API_STEP.GENERAL_MAPPER]: stepPlanOptions => [
        FE_STEP.GENERAL_MAPPER_DEFINITION,
    ],

    [API_STEP.INTEGRATION_CSV_MAPPER]: stepPlanOptions => [
        FE_STEP.FILE_UPDATE,
        FE_STEP.FILE_PARSING,
        FE_STEP.INTEGRATION_CSV_MAPPER_DEFINITION,
    ],

    [API_STEP.INTEGRATION_INIT]: stepPlanOptions => [API_STEP.INTEGRATION_INIT],

    [API_STEP.FILTER_FORM_UI]: stepPlanOptions => [FE_STEP.FILTER_FORM],

    [API_STEP.WATERFALL_CONFIGURATION]: stepPlanOptions => [
        API_STEP.WATERFALL_CONFIGURATION,
    ],

    [API_STEP.SKIP_DEPENDENCIES_UI]: stepPlanOptions => [
        FE_STEP.SKIP_DEPENDENCIES,
    ],

    [API_STEP.SETUP_SIMULATION]: stepPlanOptions => [API_STEP.SETUP_SIMULATION],

    [API_STEP.CONDITION_STEP]: stepPlanOptions => [API_STEP.CONDITION_STEP],

    [API_STEP.SETUP_REBATE_HEADER_TYPE]: stepPlanOptions => [
        API_STEP.SETUP_REBATE_HEADER_TYPE,
    ],
    [API_STEP.EXPORT_MAPPER]: stepPlanOptions => [API_STEP.EXPORT_MAPPER],
};

export const stepPlanMappingFunction = {
    [API_STEP.SETUP_PRODUCT_MASTER]: (stepDefinition, data) =>
        masterMappingFunc(stepDefinition, data),
    [API_STEP.SETUP_CUSTOMER_MASTER]: (stepDefinition, data) =>
        masterMappingFunc(stepDefinition, data),
    [API_STEP.SETUP_SELLER_MASTER]: (stepDefinition, data) =>
        masterMappingFunc(stepDefinition, data),
    [API_STEP.CREATE_DATASOURCE]: (stepDefinition, data) =>
        createDataSourceMappingFunc(stepDefinition, data),
    [API_STEP.DATA_UPLOAD_UI]: (stepDefinition, data) =>
        dataUploadMappingFunc(stepDefinition, data),
    [API_STEP.CREATE_PX_UI]: (stepDefinition, data) =>
        createExtensionMappingFunc(stepDefinition, data),
    [API_STEP.CREATE_CX_UI]: (stepDefinition, data) =>
        createExtensionMappingFunc(stepDefinition, data),
    [FE_STEP.PRICE_PARAMETERS]: (stepDefinition, data) =>
        createPPMappingFunc(stepDefinition, data),
    [API_STEP.PRODUCT_COMPETITION_UI]: (stepDefinition, data) =>
        createCompetitionMappingFunc(stepDefinition, data),
    [API_STEP.INTEGRATION_CSV_MAPPER]: (stepDefinition, data) =>
        integrationCsvMapperMappingFunc(stepDefinition, data),
    [API_STEP.INTEGRATION_MAPPER]: (stepDefinition, data) =>
        integrationCsvMapperMappingFunc(stepDefinition, data),
    [API_STEP.GENERAL_MAPPER]: (stepDefinition, data) =>
        basicIntegrationMappingFunc(stepDefinition, data),
    [API_STEP.INTEGRATION_SFTP]: (stepDefinition, data) =>
        basicIntegrationMappingFunc(stepDefinition, data),
    [API_STEP.INTEGRATION_PROPERTIES]: (stepDefinition, data) =>
        basicIntegrationMappingFunc(stepDefinition, data),
    [API_STEP.INTEGRATION_CONNECTION]: (stepDefinition, data) =>
        basicIntegrationMappingFunc(stepDefinition, data),
    [API_STEP.INTEGRATION_INIT]: (stepDefinition, data) =>
        basicIntegrationMappingFunc(stepDefinition, data),
    [API_STEP.CONDITION_STEP]: (stepDefinition, data) =>
        basicIntegrationMappingFunc(stepDefinition, data),
    [API_STEP.SETUP_REBATE_HEADER_TYPE]: (stepDefinition, data) =>
        basicIntegrationMappingFunc(stepDefinition, data),
    [API_STEP.GENERIC_FORM_UI]: genericFormMappingFunc,
    [API_STEP.APPEND_TO_PP_GENERIC_FORM_UI]: genericFormMappingFunc,
};

const calculateStepPlan = (currentPlan, { globalState, step }) => {
    if (step?.skipOptionalFields) {
        currentPlan = currentPlan.filter(
            item =>
                item !== FE_STEP.OPTIONAL_FIELDS &&
                item !== FE_STEP.OPTIONAL_FIELDS_SELECT &&
                item !== FE_STEP.EXTENSION_OPTIONAL_FIELDS,
        );
    }

    return addDataOptions(currentPlan, globalState);
};

const calculatePpPlan = ({ step, globalState }) => {
    const steps = [FE_STEP.FILE_UPDATE, FE_STEP.FILE_PARSING];

    if (step.ppType === FE_STEP.PP_MATRIX) {
        steps.push(FE_STEP.PP_MATRIX_KEYS);
        if (step?.skipOptionalFields !== true) {
            steps.push(FE_STEP.PP_MATRIX_ATTRIBUTES);
        }
    } else {
        steps.push(step.ppType);
    }

    return addDataOptions(steps, globalState);
};

const addDataOptions = (stepsPlan, globalState) => {
    if (globalState?.createDataUpload) {
        stepsPlan.push(FE_STEP.CREATE_DATA_UPLOAD);
    }
    if (globalState?.runIMTemplate) {
        stepsPlan.push(
            FE_STEP.SELECT_IM_INSTANCE,
            API_STEP.INTEGRATION_INIT,
            API_STEP.INTEGRATION_CONNECTION,
            API_STEP.INTEGRATION_PROPERTIES,
        );
    }

    return stepsPlan;
};
