import {
    ButtonMenu,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { getTransportReadyModalTrackName as getTrackName } from "../../../mixpanel/buttonNames";

const MERGE_REQUEST_ACTION = {
    ACCEPT: "ACCEPT",
    CLOSE: "CLOSE",
};

const TransportReadyModal = ({
    instanceId,
    mergeRequestId,
    instanceNameFrom,
    instanceNameTo,
    visible,
    onFinish,
    onDiscard,
    onCancel,
}) => {
    const { instanceService } = useDic();

    const onFinishRequest = () => {
        instanceService
            .processMergeRequest({
                instanceId,
                mergeRequestId,
                mergeRequestAction: MERGE_REQUEST_ACTION.ACCEPT,
            })
            .then(response => {
                onFinish?.(response);
            });
    };
    const onDiscardRequest = () => {
        instanceService
            .processMergeRequest({
                instanceId,
                mergeRequestId,
                mergeRequestAction: MERGE_REQUEST_ACTION.CLOSE,
            })
            .then(response => {
                onDiscard?.(response);
            });
    };

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("instances.transport.ready.modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Text>
                        {t("instances.transport.ready.modal.content", {
                            instanceNameFrom: (
                                <b key="transport.ready.instanceNameFrom">
                                    {instanceNameFrom}
                                </b>
                            ),
                            instanceNameTo: (
                                <b key="transport.ready.instanceNameTo">
                                    {instanceNameTo}
                                </b>
                            ),
                        })}
                    </Text>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.deploy"),
                                    type: "primary",
                                    onClick: onFinishRequest,
                                    track: { name: getTrackName("Deploy") },
                                },
                                {
                                    label: t("general.discard"),
                                    type: "default",
                                    danger: true,
                                    onClick: onDiscardRequest,
                                    track: { name: getTrackName("Discard") },
                                },
                                {
                                    label: t("general.cancel"),
                                    type: "text",
                                    onClick: onCancel,
                                    track: { name: getTrackName("Cancel") },
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

TransportReadyModal.propTypes = {
    instanceId: PropTypes.number.isRequired,
    mergeRequestId: PropTypes.number.isRequired,
    instanceNameFrom: PropTypes.string.isRequired,
    instanceNameTo: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onFinish: PropTypes.func.isRequired,
    onDiscard: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
};

export default TransportReadyModal;
