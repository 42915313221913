import { Button, Forms, P } from "@/components/DesignSystem";
import { setValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { T, t } from "@/translations";
import { ACCEPTED_IMAGE_FORMATS } from "@/utils/image/imageUtils";
import { startsWith } from "lodash";
import "lodash.product";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import "./OfferForm.styles.less";

export const startsWithHttp = value => {
    return startsWith(value, "http://") || startsWith(value, "https://")
        ? Forms.success()
        : Forms.error(t("offer-edit.validator.starts-with-http"));
};

const MAX_SHORT_DESCRIPTION_LENGTH = 100;

const offerTypes = [
    { value: "PACKAGES", label: t("location.marketplaceApp.packages") },
    {
        value: "SOLUTIONS",
        label: t("location.marketplaceApp.solutionTemplates"),
    },
    {
        value: "INTEGRATIONS",
        label: t("location.marketplaceApp.integrationTemplates"),
    },
    {
        value: "NON_APPROVED",
        label: t("location.marketplaceApp.nonApprovedTemplates"),
    },
];

export const OfferForm = ({ offer, onSubmit, onCancel }) => {
    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit: ({ values }) => onSubmit(values),
    });

    useEffect(() => {
        if (offer && setValues && setTouched) {
            setValidatedInitialValues({
                initialValues: offer,
                setValues,
                setTouched,
            });
        }
    }, [offer, setValues, setTouched]);

    return (
        <>
            <P>{t("offer-edit.perex")}</P>
            <Forms.Form
                formId={formId}
                onSubmit={template => handleSubmit(template)}
            >
                <Forms.Fields.Input
                    name="label"
                    label={t("offer-edit.name.label")}
                    placeholder={t("offer-edit.name.placeholder")}
                    validator={Forms.pmValidators.isRequired}
                    required
                />

                <Forms.Fields.TextArea
                    autoSize={{ minRows: 1, maxRows: 6 }}
                    name="description"
                    label={t("offer-edit.description.label")}
                    placeholder={t("offer-edit.description.placeholder")}
                    validator={Forms.validators.failOnFirst([
                        Forms.pmValidators.isRequired,
                        Forms.pmValidators.createMaxLengthValidation(
                            MAX_SHORT_DESCRIPTION_LENGTH,
                        ),
                    ])}
                    required
                />

                <Forms.Fields.TextArea
                    autoSize={{ minRows: 3 }}
                    name="detailedDescription"
                    label={t("offer-edit.detailedDescription.label")}
                    placeholder={t(
                        "offer-edit.detailedDescription.placeholder",
                    )}
                />

                <Forms.Fields.Input
                    name="link"
                    label={t("offer-edit.link.label")}
                    placeholder={t("offer-edit.link.placeholder")}
                    validator={Forms.validators.failOnFirst([
                        Forms.pmValidators.isRequired,
                        startsWithHttp,
                    ])}
                    required
                />

                <Forms.Fields.Select
                    name="type"
                    label={t("offer-edit.type.label")}
                    options={offerTypes}
                    validator={Forms.pmValidators.isRequired}
                    required
                />

                <Forms.Fields.IconUpload
                    name="image"
                    accept={ACCEPTED_IMAGE_FORMATS.join(",")}
                />

                <Forms.Fields.Switch
                    name="published"
                    label={t("offer-edit.published.label")}
                    initialValue={offer?.published || false}
                />

                <Forms.SubmitButton withoutSpinner>
                    <Button
                        label={!offer ? t("general.create") : t("general.save")}
                        type="primary"
                        htmlType="submit"
                    />
                </Forms.SubmitButton>

                <Button
                    type="text"
                    onClick={onCancel}
                    label={<T id="offer-edit.button.cancel" />}
                />
            </Forms.Form>
        </>
    );
};

OfferForm.propTypes = {
    offer: PropTypes.shape({
        published: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        detailedDescription: PropTypes.string,
        link: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }),
};
