import { message } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { TrackingContext } from "@/mixpanel/TrackingContextProvider";
import { useCustomModal } from "@/modules/modal";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useContext, useEffect, useState } from "react";
import { PartitionMovePage } from "./PartitionMovePage.component";

export function PartitionMovePageContainer() {
    const {
        messageService,
        locationRouterService,
        partitionRequestService,
        accountAppLocations: { partitionsLocation },
    } = useDic();

    const customModal = useCustomModal();
    const { trackWorkflow } = useContext(TrackingContext);

    const onCancel = () => {
        locationRouterService.navigate(partitionsLocation);
    };
    useSetBackButtonAction(onCancel);

    const { accountId, partitionId } = useAccountAppParams();

    const [clustersList, setClustersList] = useState([]);
    const [purposeList, setPurposeList] = useState([]);

    const onSubmit = values => {
        partitionRequestService
            .submitMoveRequest({
                accountId,
                partitionId,
                body: values,
            })
            .then(() => {
                message.success(t("partition-move.success-message"));
                locationRouterService.navigate(partitionsLocation);
            })
            .catch(response => {
                message.error(response.response.data.message);
            });
    };

    const onPurposeChange = ({ value: purpose }) => {
        setClustersList([]);
        if (!purpose) return;
        fetchClusters(accountId, purpose);
    };

    const fetchClusters = (projectId, purpose) =>
        partitionRequestService
            .fetchClusters(projectId, purpose, { partitionId })
            .then(res => setClustersList(res.data));

    const fetchPurposes = projectId =>
        partitionRequestService
            .fetchUsages(projectId)
            .then(res => setPurposeList(res.data));

    useEffect(() => {
        fetchPurposes(accountId);
    }, [accountId]);

    return (
        <PartitionMovePage
            accountId={accountId}
            clustersList={clustersList}
            onCancel={onCancel}
            onPurposeChange={onPurposeChange}
            onSubmit={onSubmit}
            partitionId={partitionId}
            purposeList={purposeList}
        />
    );
}
