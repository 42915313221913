import { Input, InputNumber, Select } from "@/components/DesignSystem";
import { t } from "@/translations";
import {
    constants,
    DatePicker,
    DateTimePicker,
} from "@pricefx/unity-components";
import moment from "moment";
import React from "react";
import InputMultiple from "./InputMultiple";
import InputNumberMultiple from "./InputNumberMultiple";
const { DATE_FORMAT, FIELD_TYPES, NUMERIC_FIELD_TYPES } = constants;

const dateApiFormat = DATE_FORMAT.DATE;
const dateTimeFormat = DATE_FORMAT.DATETIME;
const dateTimeApiFormat = "YYYY-MM-DDTHH:mm:ss";
const timePickerFormat = { format: DATE_FORMAT.TIME };

const RuleValueRenderer = (
    control,
    value,
    onChange,
    { field, placeholder, separator },
) => {
    if (control === "setControl") {
        const Component = NUMERIC_FIELD_TYPES.includes(field.type)
            ? InputNumberMultiple
            : InputMultiple;
        return (
            <Component
                valueSeparator={separator}
                onChange={onChange}
                value={value}
                column={field}
                placeholder={placeholder}
                size="small"
            />
        );
    }

    if (NUMERIC_FIELD_TYPES.includes(field.type))
        return <InputNumber onChange={onChange} value={value} size="small" />;

    switch (field.type) {
        case FIELD_TYPES.TEXT:
        case "STRING":
            return (
                <Input
                    onChange={event => onChange(event.target.value)}
                    value={value}
                    size="small"
                />
            );
        case FIELD_TYPES.BOOLEAN:
            return (
                <Select
                    allowClear={false}
                    onChange={value => onChange(!!value)}
                    options={[
                        { value: 1, label: t("general.true") },
                        { value: 0, label: t("general.false") },
                    ]}
                    value={value ? 1 : 0}
                    size="small"
                />
            );
        case FIELD_TYPES.DATE:
            return (
                <DatePicker
                    onChange={result => {
                        onChange(result.format(dateApiFormat));
                    }}
                    renderExtraFooter={() => (
                        <a
                            className="ucTableEditableCellDateFooter"
                            onClick={() => onChange(null)}
                        >
                            {t("general.clear")}
                        </a>
                    )}
                    value={
                        value ? moment(value.toString(), dateApiFormat) : null
                    }
                    width="200"
                />
            );
        case FIELD_TYPES.DATETIME:
            return (
                <DateTimePicker
                    format={dateTimeFormat}
                    onChange={result =>
                        onChange(
                            result.value &&
                                moment(result.value)
                                    .utc()
                                    .format(dateTimeApiFormat),
                        )
                    }
                    renderExtraFooter={() => (
                        <a
                            className="ucTableEditableCellDateTimeFooter"
                            onClick={() => onChange(null)}
                        >
                            {t("general.clear")}
                        </a>
                    )}
                    showTime={timePickerFormat}
                    value={
                        value
                            ? moment
                                  .utc(value.toString(), dateTimeApiFormat)
                                  .local()
                            : null
                    }
                    size="small"
                />
            );

        default:
            return <></>;
    }
};

export default RuleValueRenderer;
