import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { tokensService } from "@/services/token.service";
import { getData } from "@/services/utils";
import { t } from "@/translations";

export const useApiTokenQuery = ({ accountId }) => {
    return useQueryLoadable(
        async () =>
            tokensService
                .fetchAccountTokens({ accountId })
                .then(getData)
                .then(res => (res ? [res] : [])),
        [accountId],
    );
};

export const useCreateApiTokenMutation = ({ accountId, afterSuccess }) => {
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async ({ comment }) =>
            tokensService
                .createAccountToken({ accountId, comment })
                .then(getData)
                .then(res => {
                    afterSuccessCurrent(res);
                    return res;
                }),
        [accountId, afterSuccessCurrent],
        ({ comment }) => t("api-token.message.created", { tokenName: comment }),
    );
};

export const useDeleteApiTokenMutation = ({ accountId, afterSuccess }) => {
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async record =>
            tokensService
                .deleteAccountToken({ accountId, id: record.id })
                .then(_ => {
                    afterSuccessCurrent();
                    return { tokenName: record.comment };
                }),
        [accountId, afterSuccessCurrent],
        ({ tokenName }) => t("api-token.message.deleted", { tokenName }),
    );
};
