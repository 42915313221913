import { message } from "@/components/DesignSystem";
import { IntlProvider } from "@/translations";
import React from "react";

const DEFAULT_DURATION = 5;

const notify =
    level =>
    ({ content, ...rest }) => {
        return message[level]({
            content: <IntlProvider>{content}</IntlProvider>,
            duration: DEFAULT_DURATION,
            ...rest,
        });
    };

export const messageService = () => ({
    success: notify("success"),
    info: notify("info"),
    warn: notify("warn"),
    error: notify("error"),
    loading: notify("loading"),
});
