import { message } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { EntityType } from "@/components/Integrations/IntegrationsVersionHistory.overview.page";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import {
    isAuthorizedByPermissionOr,
    SecurityContext,
} from "@/security/authorization";
import {
    CLUSTER_ADMIN,
    PERMISSION_POWER_USER_PERMISSION,
    PRICEFX_TECH_OPS_PERMISSION,
} from "@/security/permission.utils";
import { clusterService } from "@/services/clusterService";
import { t } from "@/translations";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { clusterColumns } from "./ClusterList.columns";
import { EditUpgradeDurationModal } from "./EditUpgradeDurationModal";

const preferencesType = preferencesTypes.CLUSTER_LIST_TABLE;

export const ClusterList = () => {
    useFullWidthTableLayout();
    const { locationRouterService, overviewAppLocations } = useDic();

    const [recordUpdated, setRecordUpdated] = useState(false);

    const [selectedCluster, setSelectedCluster] = useState();

    const [
        editUpgradeDurationModalVisible,
        setEditUpgradeDurationModalVisible,
    ] = useState(false);

    const securityContext = useContext(SecurityContext);

    const [tableProps] = useFetchPage(
        (page, size, sort = "name,asc", filter) =>
            clusterService.fetchClusters(page, size, sort, filter),
        [recordUpdated],
    );

    const canEditUpgradeDuration = useMemo(() => {
        return isAuthorizedByPermissionOr(
            [PERMISSION_POWER_USER_PERMISSION, PRICEFX_TECH_OPS_PERMISSION],
            securityContext.permissions,
        );
    }, [securityContext]);

    const saveUpgradeDuration = record => {
        clusterService
            .editClusterUpgradeDuration({
                clusterName: record.name,
                majorUpgradeDuration: record.majorUpgradeDuration,
                minorUpgradeDuration: record.minorUpgradeDuration,
            })
            .then(() => {
                setRecordUpdated(!recordUpdated);
                message.success(
                    t("clusters-list.custom-upgrade-time.edit.success"),
                );
            })
            .catch(() =>
                message.error(t("clusters-list.custom-upgrade-time.edit.fail")),
            );

        setEditUpgradeDurationModalVisible(false);
    };

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("clusters-list.custom-upgrade-time.edit"),
                        onClick: () => {
                            setSelectedCluster(record);
                            setEditUpgradeDurationModalVisible(true);
                        },
                        permission: [
                            PERMISSION_POWER_USER_PERMISSION,
                            PRICEFX_TECH_OPS_PERMISSION,
                        ],
                    },
                    {
                        title: t("clusters-list.cluster-admin"),
                        onClick: () => {
                            locationRouterService.navigate(
                                overviewAppLocations.clusterAdminLocation,
                                {
                                    clusterName: record.name,
                                },
                            );
                        },
                        permission: [CLUSTER_ADMIN],
                    },
                    {
                        title: t("general.show-logs"),
                        onClick: () => {
                            window.open(record.logUrl, "_blank", "noopener");
                        },
                        disabled: !record?.logUrl,
                        permission: [
                            PERMISSION_POWER_USER_PERMISSION,
                            PRICEFX_TECH_OPS_PERMISSION,
                        ],
                    },
                    {
                        title: t("general.version-history"),
                        onClick: () => {
                            locationRouterService.navigate(
                                overviewAppLocations.clustersVersionHistoryLocation,
                                {
                                    entityId: record.id,
                                    entityType: EntityType.CLUSTER,
                                },
                            );
                        },
                    },
                ]}
            />
        ),
        [
            locationRouterService,
            overviewAppLocations.clusterAdminLocation,
            overviewAppLocations.clustersVersionHistoryLocation,
        ],
    );

    return (
        <>
            <TableWithPreferencesManagement
                columns={clusterColumns(canEditUpgradeDuration)}
                defaultSort={{
                    fieldName: "name",
                    sortDir: "ascending",
                }}
                fixed
                rowKey="name"
                datasetSlicing="server"
                preferencesType={preferencesType}
                actionMenu={actionMenu}
                exportUrl="/api/clusters/export"
                {...tableProps}
            />
            <EditUpgradeDurationModal
                visible={editUpgradeDurationModalVisible}
                onCancel={() => setEditUpgradeDurationModalVisible(false)}
                record={selectedCluster}
                onOk={saveUpgradeDuration}
            />
        </>
    );
};

export default ClusterList;
