import {
    ActionButton,
    CONFIRM_TYPE,
} from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_HEIGHT_WITH_BREADCRUMB } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import {
    sftpServersListColumns,
    sftpStatus,
} from "@/components/SFTPServers/sftpServersListColumns";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import React, { useCallback, useMemo } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { useFetchPage } from "../PagableTable/useFetchPage.hook";
import SSEType from "../ServerSideEvents/types";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { useTableCellUpdater } from "./useTableCellUpdater.hook";

const preferencesType = preferencesTypes.SFTP_LIST_TABLE;

const SFTPServersList = () => {
    const { instanceId, accountId: projectId } = useAccountAppParams();
    useFullWidthTableLayout();
    const {
        sftpService,
        messageService,
        locationRouterService,
        accountAppLocations: { sftpServerUsersLocation },
    } = useDic();

    const [tableProps] = useFetchPage(
        (page, size, sort, filter) =>
            sftpService.serversList({
                instanceId,
                page,
                size,
                sort,
                filter,
            }),
        [instanceId, projectId],
    );

    const stopService = useCallback(
        record => {
            sftpService
                .stopServer({ serverId: record.id })
                .then(() => {
                    messageService.success({
                        content: t("sftp-servers.list.action.stopping-server", {
                            name: record.name,
                        }),
                    });
                })
                .catch(e => {
                    messageService.error({
                        content: getErrorMessage(e.response.data),
                    });
                });
        },
        [messageService, sftpService],
    );

    const startService = useCallback(
        record => {
            sftpService
                .startServer({ serverId: record.id })
                .then(() => {
                    messageService.success({
                        content: t("sftp-servers.list.action.starting-server", {
                            name: record.name,
                        }),
                    });
                })
                .catch(e => {
                    messageService.error({
                        content: getErrorMessage(e.response.data),
                    });
                });
        },
        [messageService, sftpService],
    );

    const getControlActionsByStatus = useCallback(
        ({ status, type }) => {
            if (type === "LDAP") return null;

            switch (status) {
                case sftpStatus.STARTED:
                    return {
                        title: t("sftp-servers.list.action.stop-server"),
                        confirm: {
                            type: CONFIRM_TYPE.CONFIRM,
                            title: t("sftp-servers.list.action.stop-server"),
                            message: t(
                                "sftp-servers.list.action.stop-server.confirm",
                            ),
                            okText: t("general.confirm"),
                            cancelText: t("general.cancel"),
                            onConfirm: stopService,
                        },
                    };
                case sftpStatus.STOPPED:
                    return {
                        title: t("sftp-servers.list.action.start-server"),
                        confirm: {
                            type: CONFIRM_TYPE.CONFIRM,
                            title: t("sftp-servers.list.action.start-server"),
                            message: t(
                                "sftp-servers.list.action.start-server.confirm",
                            ),
                            okText: t("general.confirm"),
                            cancelText: t("general.cancel"),
                            onConfirm: startService,
                        },
                    };
            }
        },
        [startService, stopService],
    );

    const contentWithSSE = useTableCellUpdater(tableProps?.page?.content, {
        eventName: SSEType.SFTP_LIST_STATUS,
        highlightCell: "status",
        highlightFunc: item => {
            return (
                item.status === sftpStatus.STARTING ||
                item.status === sftpStatus.STOPPING
            );
        },
        mapFunc: receivedItem => ({ status: receivedItem.status }),
        getKey: item => item.id,
        iconTooltip: t("general.update-in-progress"),
    });

    const tablePropsWithSSE = useMemo(
        () => ({
            ...tableProps,
            page: {
                ...tableProps.page,
                content: contentWithSSE,
            },
        }),
        [contentWithSSE, tableProps],
    );

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    getControlActionsByStatus(record),
                    // @TODO: in next task
                    // {
                    //     title: t("sftp-servers.list.action.show-server-log"),
                    //     onClick: () => {}
                    // },
                    {
                        visible: record.status === "STARTED",
                        title: t("sftp-servers.list.action.user-management"),
                        onClick: record =>
                            locationRouterService.navigate(
                                sftpServerUsersLocation,
                                { serverId: record.id },
                            ),
                    },
                ]}
            />
        ),
        [
            getControlActionsByStatus,
            locationRouterService,
            sftpServerUsersLocation,
        ],
    );

    return (
        <TableWithPreferencesManagement
            fixed
            datasetSlicing="server"
            actionMenu={actionMenu}
            defaultSort={{
                fieldName: "name",
                sortDir: "ascending",
            }}
            restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
            columns={sftpServersListColumns}
            rowKey="id"
            preferencesType={preferencesType}
            {...tablePropsWithSSE}
        />
    );
};

SFTPServersList.propTypes = {};

export default SFTPServersList;
