import { array, object, string } from "yup";

export const templateApiSchema = object().shape({
    uniqueName: string().required(),
    label: string().required(),
    description: string().required(),
    components: string().required(),
    type: string().required(),
});

export const templatesApiSchema = array().of(templateApiSchema);
