import {
    ButtonMenu,
    Forms,
    Gap,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { MAX_LENGTH_VALIDATION_PATTERN } from "@/components/Mappers/form/MapperRow";
import { t } from "@/translations";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { ConverterExpressionComponent } from "../../ConvertorExpression/ConverterExpression.component";

const { useForm } = Forms;

const MapperRowConverterModal = ({
    visible,
    value,
    onApply,
    onCancel,
    converters,
    readOnly,
    isSupportingMaxLengthProperty,
}) => {
    const { Form, formId, handleSubmit, setValues, setTouched } = useForm({
        onSubmit: ({ values }) => {
            onApply({
                ...values,
                ...(isSupportingMaxLengthProperty
                    ? {
                          maxLength: isEmpty(values.maxLength)
                              ? null
                              : parseInt(values.maxLength),
                      }
                    : {}),
            });
        },
    });

    useSetValidatedInitialValues(
        { initialValues: value ?? {}, setValues, setTouched },
        [value],
    );

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header size={3} title="Converter expression" />
                <UnityLayout.Content padding={[true, true]}>
                    <Form disableBanner onSubmit={handleSubmit}>
                        <ConverterExpressionComponent
                            isForm
                            label="Converter"
                            name="converterExpression"
                            onChange={converterExpression =>
                                setValues({ converterExpression })
                            }
                            converters={converters ?? []}
                            converterExpressionReadOnly={readOnly}
                        />

                        {isSupportingMaxLengthProperty ? (
                            <Forms.Fields.Input
                                autoComplete="off"
                                initialValue={value?.initialMaxLength}
                                // name={serialize(value?.rowId, "maxLength")}
                                name="maxLength"
                                label={t("mapper-form.form.row.maxLength")}
                                min={1}
                                validator={Forms.pmValidators.noValueOr(
                                    Forms.validators.failOnFirst([
                                        Forms.pmValidators.isRequired,
                                        Forms.pmValidators.createPatternValidator(
                                            MAX_LENGTH_VALIDATION_PATTERN,
                                            "Valid format is the whole number.",
                                        ),
                                    ]),
                                )}
                                disabled={readOnly}
                                tooltip={t(
                                    "mapper-form.form.row.maxLength.tooltip",
                                )}
                            />
                        ) : null}

                        {!isSupportingMaxLengthProperty ? (
                            <>
                                <Gap />
                                <Text size="small">
                                    {t("mapper-form.form.info.maxLength")}
                                </Text>
                            </>
                        ) : null}
                    </Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.ok"),
                                    formId,
                                    type: "primary",
                                    visible: !readOnly,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onCancel,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

MapperRowConverterModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    value: PropTypes.any,
    onApply: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    converters: PropTypes.object,
    readOnly: PropTypes.bool.isRequired,
    isSupportingMaxLengthProperty: PropTypes.bool.isRequired,
};

export default MapperRowConverterModal;
