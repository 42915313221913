import { useDic } from "@/components/Dic/useDic.hook";
import { useQueryLoadable } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { getData } from "@/services/utils";

export const useInstanceLimitQuery = () => {
    const { axiosService } = useDic();
    const { accountId } = useAccountAppParams();
    const imInstancesLimitQuery = useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/project-integration-limit/${accountId}`)
                .then(getData),
        [accountId, axiosService],
    );
    return imInstancesLimitQuery;
};
