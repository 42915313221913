import { Button, Collapse, Gap, Switch, Text } from "@/components/DesignSystem";
import PmFeatureFlagRestrictionsModal from "@/components/PmFeatureFlags/PmFeatureFlagRestrictionsModal";
import { PmFeatureFlagsRestrictionsTable } from "@/components/PmFeatureFlags/PmFeatureFlagsRestrictionsTable";
import {
    useChangePmFeatureFlagStatusMutation,
    usePmFeatureFlagRestrictionsMutation,
    usePmFeatureFlagsQuery,
} from "@/components/PmFeatureFlags/loadables";
import { LoadableRenderer, isLoading } from "@/modules/loadable";
import { useCustomModal } from "@/modules/modal";
import { t } from "@/translations";
import React, { useCallback } from "react";
import styles from "./PmFeatureFlags.less";

const PmFeatureFlags = () => {
    const pmFeatureFlagsResource = usePmFeatureFlagsQuery({ canFetch: true });

    const statusChangeMutation = useChangePmFeatureFlagStatusMutation({
        afterSuccess: pmFeatureFlagsResource.reload,
    });

    const customModal = useCustomModal();

    const pmFeatureFlagRestrictionsMutation =
        usePmFeatureFlagRestrictionsMutation(
            {
                afterSuccess: () => {
                    customModal.hide();
                    pmFeatureFlagsResource.reload();
                },
            },
            [customModal],
        );

    const onStatusChange = useCallback(
        ({ featureFlagName, active }) => {
            statusChangeMutation.mutate({ featureFlagName, active });
        },
        [statusChangeMutation],
    );

    const onManageRestriction = useCallback(
        featureFlagName =>
            customModal.show(
                <PmFeatureFlagRestrictionsModal
                    featureFlagName={featureFlagName}
                    onSave={pmFeatureFlagRestrictionsMutation.mutate}
                />,
            ),
        [customModal, pmFeatureFlagRestrictionsMutation.mutate],
    );

    return (
        <>
            <Text size="large">{t("pm-feature-flags.perex")}</Text>
            <Gap size="large" />
            <LoadableRenderer
                loadable={pmFeatureFlagsResource.loadable}
                hasValue={pmFeatureFlagsResource => {
                    const gridRows = pmFeatureFlagsResource.data.map(
                        (featureFlag, index) => {
                            const isEvenRow = index % 2;
                            const classNames = `${styles.gridItem} ${
                                isEvenRow ? styles.greyBackground : ""
                            }`;
                            const collapseClassName = isEvenRow
                                ? styles.collapseGrey
                                : styles.collapseWhite;
                            return (
                                <>
                                    <div className={classNames}>
                                        <Text>{featureFlag.name}</Text>
                                    </div>
                                    <div className={classNames}>
                                        <Text>{featureFlag.description}</Text>
                                    </div>
                                    <div className={classNames}>
                                        <Button
                                            label={`${t(
                                                "pm-feature-flags.button.restrictions",
                                            )}
                                                ${
                                                    featureFlag.numberOfRestrictions
                                                        ? ` (${featureFlag.numberOfRestrictions})`
                                                        : ""
                                                }
                                            `}
                                            size="small"
                                            onClick={() =>
                                                onManageRestriction(
                                                    featureFlag.name,
                                                )
                                            }
                                        />
                                    </div>
                                    <div className={classNames}>
                                        <Switch
                                            value={featureFlag.active}
                                            onChange={value =>
                                                onStatusChange({
                                                    featureFlagName:
                                                        featureFlag.name,
                                                    active: value,
                                                })
                                            }
                                            disabled={isLoading(
                                                statusChangeMutation,
                                            )}
                                        />
                                    </div>

                                    {!!featureFlag.numberOfRestrictions && (
                                        <div
                                            className={`${classNames} ${styles.columnSpan}`}
                                        >
                                            <Collapse bordered={false}>
                                                <Collapse.Panel
                                                    header={t(
                                                        "pm-feature-flags.collapse.header",
                                                    )}
                                                    className={
                                                        collapseClassName
                                                    }
                                                >
                                                    <PmFeatureFlagsRestrictionsTable
                                                        featureFlagName={
                                                            featureFlag.name
                                                        }
                                                    />
                                                </Collapse.Panel>
                                            </Collapse>
                                        </div>
                                    )}
                                </>
                            );
                        },
                    );
                    return (
                        <>
                            <div className={styles.gridContainer}>
                                <div className={styles.gridItem}>
                                    <b>{t("pm-feature-flags.label.name")}</b>
                                </div>
                                <div className={styles.gridItem}>
                                    <b>
                                        {t(
                                            "pm-feature-flags.label.description",
                                        )}
                                    </b>
                                </div>
                                <div className={styles.gridItem}>
                                    <b>
                                        {t(
                                            "pm-feature-flags.label.restriction",
                                        )}
                                    </b>
                                </div>
                                <div className={styles.gridItem}>
                                    <b>{t("pm-feature-flags.label.active")}</b>
                                </div>
                                {gridRows}
                            </div>
                        </>
                    );
                }}
            />
        </>
    );
};

export default PmFeatureFlags;
