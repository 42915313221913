import { Button, ButtonGroup, Forms, P } from "@/components/DesignSystem";
import ConfirmChangesModalContent from "@/components/Integrations/InstanceGroupEdit/ConfirmChangesModalContent";
import { ProvisionedInstanceEditFormComponent } from "@/components/Integrations/InstanceGroupEdit/ProvisionedInstanceEditForm.component";
import {
    useCreateNewInstancePossibleQuery,
    useInstancesDeleteMutation,
    useInstancesRestartMutation,
} from "@/components/Integrations/loadables";
import { useAlertEffect } from "@/components/PageLayout/useAlertEffect.hook";
import { hasValue } from "@/modules/loadable";
import { useConfirmModal } from "@/modules/modal";
import { t } from "@/translations";
import { DATE_FORMAT } from "@pricefx/unity-components/dist/es/constants";
import { entries, pipe, reduce, set } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import { useAccountSelectDisabled } from "../../../apps/accountApp/components/AccountAppSidebar";
const { useForm, Form, SubmitButton } = Forms;

const compareEnvs = (original, modified) =>
    modified
        .map(modItem => {
            let originalItem =
                original.find(o => o.envName === modItem.envName) || {};
            let changes = [];

            for (let key in modItem) {
                if (
                    modItem[key] !== originalItem[key] &&
                    (modItem[key] || originalItem[key])
                ) {
                    changes.push({
                        item: key,
                        original: originalItem[key] || "-",
                        newValue: modItem[key],
                    });
                }
            }

            return { envName: modItem.envName, changes };
        })
        .filter(diff => diff.changes.length > 0);

const parseEnvs = environments =>
    Object.values(environments).map(env => {
        const { isExisting, expiration, ...envRest } = env;
        return {
            ...envRest,
            expiration: expiration
                ? expiration.format(DATE_FORMAT.DATE)
                : undefined,
        };
    });

export const InstanceGroupEditForm = ({
    accountId,
    instanceGroup,
    navigateToInstancesList,
    onSubmit,
    reload,
}) => {
    useAccountSelectDisabled();
    const confirmModal = useConfirmModal();
    const createNewInstancePossibleQuery = useCreateNewInstancePossibleQuery(
        instanceGroup.cloudProvider,
        instanceGroup.region,
    );

    const isNotCreateNewInstancePossible =
        hasValue(createNewInstancePossibleQuery) &&
        !createNewInstancePossibleQuery.loadable.valueMaybe();

    useAlertEffect(isNotCreateNewInstancePossible, () => ({
        type: "warning",
        message: t("new-instance.alert.not-enough-space"),
    }));

    const handleInstanceSubmit = ({ values: _values }) => {
        const values = pipe(
            entries,
            reduce((acc, [k, v]) => set(k, v, acc), {}),
        )(_values);

        const parsedEnvs = parseEnvs(values.environments);

        const submitResult = () =>
            onSubmit({
                values: {
                    integrationArchitecture: values.integrationArchitecture,
                    instanceName: instanceGroup.instanceName,
                    serverType: "PROVISIONED",
                    cloudProvider: instanceGroup.cloudProvider,
                    region: instanceGroup.region,
                    environments: parsedEnvs,
                },
                successMessage: t("instance-edit.message.submit-provisioned"),
            });

        const deletedEnvNames = instanceGroup.environments
            .map(env => env.envName)
            .filter(
                envName =>
                    !Object.values(values.environments).some(
                        e => e.envName === envName,
                    ),
            );
        const comparedEnvs = compareEnvs(
            instanceGroup.environments,
            parsedEnvs,
        );

        if (comparedEnvs.length > 0 || deletedEnvNames.length > 0) {
            return confirmModal.confirm({
                title: t("instance-edit-form.confirmation.title"),
                message: (
                    <ConfirmChangesModalContent
                        comparedEnvs={comparedEnvs}
                        deletedEnvNames={deletedEnvNames}
                    />
                ),
                okText: t("general.confirm"),
                cancelText: t("general.cancel"),
                onConfirm: () => submitResult(),
            });
        }
    };

    const { formId, handleSubmit } = useForm({
        onSubmit: handleInstanceSubmit,
    });

    const instancesRestartMutation = useInstancesRestartMutation({
        projectId: accountId,
        provider: instanceGroup.cloudProvider,
        afterSuccess: navigateToInstancesList,
    });

    const instancesDeleteMutation = useInstancesDeleteMutation({
        afterSuccess: navigateToInstancesList,
    });

    return (
        <>
            <P>{t("instance-edit-form.perex")}</P>
            <P>{t("instance-edit-form.perex-note")}</P>
            <Form formId={formId} layout="vertical" onSubmit={handleSubmit}>
                <ProvisionedInstanceEditFormComponent
                    instanceGroup={instanceGroup}
                    formId={formId}
                    disableEnvsEdit={isNotCreateNewInstancePossible}
                    instancesRestartMutation={instancesRestartMutation}
                    instancesDeleteMutation={instancesDeleteMutation}
                />
                <ButtonGroup>
                    <SubmitButton>
                        <Button
                            label={t("general.save")}
                            htmlType="submit"
                            type="primary"
                        />
                    </SubmitButton>
                    <Button
                        type="text"
                        onClick={navigateToInstancesList}
                        label={t("general.cancel")}
                    />
                </ButtonGroup>
            </Form>
        </>
    );
};

InstanceGroupEditForm.propTypes = {
    accountId: PropTypes.number.isRequired,
    instanceGroup: PropTypes.object.isRequired,
    navigateToInstancesList: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
};
