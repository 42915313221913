import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";

export const propertiesColumns = ({
    instanceId,
    onDeploy,
    onDelete,
    setSelectedProperty,
    isReadOnly,
}) => [
    {
        name: "name",
        label: t("properties.column.name"),
        type: fieldTypes.TEXT,
        render: (text, record) => (
            <>
                {text}
                {!isReadOnly && (
                    <ActionButton
                        record={record}
                        items={[
                            onDeploy && {
                                title: t("general.deploy"),
                                onClick: record => {
                                    onDeploy(instanceId, record.id);
                                },
                            },
                            {
                                title: t("general.edit"),
                                onClick: record => {
                                    setSelectedProperty(record);
                                },
                            },
                            {
                                title: t("general.modal.delete.title", {
                                    type: "property",
                                }),
                                confirmMessage: t(
                                    "general.modal.delete.message",
                                    {
                                        type: "property",
                                        name: record.name,
                                    },
                                ),
                                onConfirm: () => onDelete(record),
                                color: "red",
                            },
                        ]}
                    />
                )}
            </>
        ),
    },
    {
        name: "type",
        label: t("properties.column.type"),
        type: fieldTypes.TEXT,
    },
    {
        name: "value",
        label: t("properties.column.value"),
        type: fieldTypes.TEXT,
    },
];
