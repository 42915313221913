import DateRangeFilter from "@/components/DateRangeFilter/DateRangeFilter.component";
import defaultFilters from "@/components/DateRangeFilter/DateRangeFilter.filter";
import { Gap } from "@/components/DesignSystem";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { t } from "@/translations";
import moment from "moment-timezone";
import React, { useMemo, useState } from "react";
import PageLayout from "../PageLayout";
import UpgradeSlotsOverviewTable from "./UpgradeSlotsOverviewTable";
import { DATETIME } from "./date-formats";

export const FILTER_HEIGHT = 47;

export function UpgradeSlotsPage() {
    useFullWidthTableLayout();
    useHideBackButton();

    const [activeFilter, setActiveFilter] = useState(defaultFilters.all);
    const filter = useMemo(
        () => ({
            timeFrom: {
                value: [
                    moment(activeFilter.from).utc().format(DATETIME),
                    moment(activeFilter.to).utc().format(DATETIME),
                ],
            },
        }),
        [activeFilter.from, activeFilter.to],
    );

    useSetPageTitle(t("cluster.upgrade.slots.overview.header"));

    return (
        <>
            <PageLayout.Perex>
                <DateRangeFilter
                    title={t("cluster.upgrade.slots.overview.filter")}
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                />
            </PageLayout.Perex>
            <Gap size="small" />

            <UpgradeSlotsOverviewTable filter={filter} />
        </>
    );
}
