const { t } = require("@/translations");

export const instanceHealthStates = {
    UP: "UP",
    DOWN: "DOWN",
    NOT_MONITORED: "NOT_MONITORED",
    UNKNOWN: "UNKNOWN",
    WARNING: "WARNING",
};

export const getHealthStateTranslation = healthState => {
    return t(`health-state.state.${healthState}`);
};
