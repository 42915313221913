export const notifyAction = (successMessage, failMessage) => ({
    notification: {
        failMessage,
        successMessage,
    },
});

const createAction = (type, method, url) => ({
    type,
    payload: {
        request: {
            method,
            url,
        },
    },
});

export const createPostAction = (type, url, data) => ({
    type,
    payload: {
        request: {
            method: "post",
            url,
            data: JSON.stringify(data),
        },
    },
});

export const createGetAction = (type, url) => createAction(type, "get", url);

export const createDeleteAction = (type, url) =>
    createAction(type, "delete", url);

export const createHeadAction = (type, url) => createAction(type, "head", url);
