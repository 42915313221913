import React, { useEffect, useRef } from "react";
import dagreD3 from "dagre-d3";
import * as d3 from "d3";

const DagreD3 = ({ nodes, links }) => {
    const graph = useRef();

    useEffect(() => {
        const g = new dagreD3.graphlib.Graph().setGraph({});

        nodes.map(node => {
            g.setNode(node.id, {
                label: node.label,
                labelType: "html",
                imageUrl: node.imageUrl,
            });
        });

        links.map(link => {
            g.setEdge(link.source, link.target, {});
        });

        const render = new dagreD3.render();
        const svg = d3.select(graph.current);
        const svgGroup = svg.append("g");

        render(svgGroup, g);

        // // Center the graph
        const xCenterOffset = (svg.attr("width") - g.graph().width) / 2;
        svgGroup.attr("transform", "translate(" + xCenterOffset + ", 20)");
        svg.attr("height", g.graph().height + 40);
    }, [nodes, links]);

    return (
        <svg className="dagre-d3" ref={graph} width="960" height="600"></svg>
    );
};

export default DagreD3;
