import { H4 } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useDic } from "@/components/Dic/useDic.hook";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import {
    isAuthorizedByPermissionOr,
    SecurityContext,
} from "@/security/authorization";
import { t } from "@/translations";
import React, { useCallback, useContext } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { useDeleteClusterMutation, useDeleteNodeMutation } from "./loadables";
import { columns } from "./NodeList.columns";

const preferencesType = preferencesTypes.NODES_LIST_TABLE;

const NodesList = () => {
    const { nodeService } = useDic();

    const nodePanel = useDetailDrawerState();
    const securityContext = useContext(SecurityContext);

    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort = "nodeName,asc", filter) =>
            nodeService.nodesList({
                queryParams: { page, size, sort, filter },
            }),
        [nodeService],
    );

    const deleteNodeMutation = useDeleteNodeMutation({ afterSuccess: reload });
    const deleteClusterMutation = useDeleteClusterMutation({
        afterSuccess: reload,
    });

    const authorizedToRemoveNode = isAuthorizedByPermissionOr(
        ["loginAs_root", "loginAs_pricefx-support"],
        securityContext.permissions,
    );

    const authorizedToRemoveCluster = isAuthorizedByPermissionOr(
        ["loginAs_root", "loginAs_pricefx-support"],
        securityContext.permissions,
    );

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.show-detail"),
                        onClick: nodePanel.show,
                    },
                    authorizedToRemoveNode && {
                        title: t("nodes-list.modal.remove.title"),
                        confirmMessage: t("nodes-list.modal.remove.message", {
                            nodeName: record.nodeName,
                            lastCheckin: record.checkinDate,
                        }),
                        onConfirm: deleteNodeMutation.mutate,
                        color: "red",
                    },
                    authorizedToRemoveCluster && {
                        title: t("nodes-list.header.remove-cluster.title"),
                        confirmTitle: t(
                            "nodes-list.modal.remove-cluster.title",
                        ),
                        confirmMessage: t(
                            "nodes-list.modal.remove-cluster.message",
                            { clusterName: record.clusterName },
                        ),
                        onConfirm: deleteClusterMutation.mutate,
                        color: "red",
                    },
                    // To be delivered in a separate ticket
                    // {
                    //     title: t("general.version-history"),
                    //     onClick: () => {
                    //         locationRouterService.navigate(
                    //             overviewAppLocations.versionHistoryLocation,
                    //             {
                    //                 entityId: record.id,
                    //                 entityType: EntityType.NODE,
                    //             },
                    //         );
                    //     },
                    // },
                ]}
            />
        ),
        [
            authorizedToRemoveCluster,
            authorizedToRemoveNode,
            deleteClusterMutation.mutate,
            deleteNodeMutation.mutate,
            nodePanel.show,
        ],
    );

    return (
        <>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                columns={columns}
                defaultSort={{
                    fieldName: "nodeName",
                    sortDir: "ascending",
                }}
                fixed
                rowKey="nodeId"
                datasetSlicing="server"
                preferencesType={preferencesType}
                exportUrl="/api/registry/node-info/export"
                panelControl={nodePanel}
                renderPanelHeader={record => <H4>{record.hostName}</H4>}
                {...tableProps}
            />
        </>
    );
};

export default NodesList;
