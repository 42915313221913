import React from "react";
import cx from "classnames";
// assets
import styles from "./SidebarContent.styles.less";

export function SidebarContent({ children, withTopPadding = true }) {
    const classes = cx({
        [styles.sidebarContentWithTopPadding]: withTopPadding,
    });

    return <div className={classes}>{children}</div>;
}
