import { Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { fromEOSch } from "@/components/EventSchedulers/EventSchedulerForm";
import { SYNCHRONIZATION_TRIGGER } from "@/components/ISVMapping/steps/Synchronization.step";
import { debounceAsync } from "@/utils/promises/promise.utils";
import { getErrorMessage } from "@/utils/state/error.utils";
import { size } from "lodash";
import { pipe } from "lodash/fp";
import moment from "moment";
import { useCallback, useMemo } from "react";

export const mapFromFormValues = values => {
    const formValues = pipe(fromEOSch)(values);
    return {
        name: formValues.name,
        type: formValues.type,
        pfxEntityType: formValues.pfxEntityType,
        pfxEntityName: formValues?.pfxEntityName,
        baseFilePattern: formValues.baseFilePattern,
        customEmptyStringValue: formValues.customEmptyStringValue,
        enabled: formValues.enabled,
        mapper: formValues.mapper,
        ...(formValues.schedulerType === SYNCHRONIZATION_TRIGGER.IMMEDIATELY
            ? {}
            : {
                  scheduler: {
                      type: formValues.schedulerDefinition.type,
                      interval: formValues.schedulerDefinition?.interval,
                      eventTime: formValues.schedulerDefinition?.eventTime,
                      atTime: formValues.schedulerDefinition?.atTime,
                      dayOfWeeks: formValues.schedulerDefinition?.dayOfWeeks,
                      dayOfMonth: formValues.schedulerDefinition?.dayOfMonth,
                      dayOfYear: formValues.schedulerDefinition?.dayOfYear,
                      startTime: formValues.schedulerDefinition.startTime,
                      eventTimeZone:
                          formValues.schedulerDefinition.eventTimeZone,
                      cron: formValues.schedulerDefinition?.cron,
                      endTime: formValues.schedulerDefinition.endTime,
                  },
              }),
    };
};

const toMoment = (value, format) => {
    return value ? moment(value, format) : undefined;
};
export const mapToInitValues = mapper => {
    const { scheduler, ...restValues } = mapper;
    return {
        ...restValues,
        schedulerType: scheduler
            ? SYNCHRONIZATION_TRIGGER.SCHEDULER
            : SYNCHRONIZATION_TRIGGER.IMMEDIATELY,
        ...(scheduler
            ? {
                  "schedulerDefinition.type": scheduler.type,
                  "schedulerDefinition.atTime": toMoment(
                      scheduler?.atTime,
                      fromEOSch.TIME_FORMAT,
                  ),
                  "schedulerDefinition.eventTime": toMoment(
                      scheduler?.eventTime,
                      fromEOSch.TIME_FORMAT,
                  ),
                  "schedulerDefinition.interval": scheduler?.interval,
                  "schedulerDefinition.dayOfWeeks": scheduler?.dayOfWeeks,
                  "schedulerDefinition.dayOfMonth": scheduler?.dayOfMonth,
                  "schedulerDefinition.dayOfYear": scheduler?.dayOfYear,
                  "schedulerDefinition.startTime": toMoment(
                      scheduler.startTime,
                      fromEOSch.DATETIME_FORMAT,
                  ),
                  "schedulerDefinition.eventTimeZone": scheduler.eventTimeZone,
                  "schedulerDefinition.cron": scheduler.cron,
                  "schedulerDefinition.endTime": toMoment(
                      scheduler.endTime,
                      fromEOSch.DATETIME_FORMAT,
                  ),
              }
            : {}),
    };
};

export const mapVendorToVendorName = vendor => {
    switch (vendor) {
        case "ENABLE":
            return "Enable";
        case "PRICEFX":
            return "Pricefx";
        default:
            return vendor;
    }
};

export const useIsvMapperNameExistsValidator = ({
    connectionId,
    skipValidationFor,
}) => {
    const { isvMappingService } = useDic();
    return useCallback(
        connectionName =>
            skipValidationFor && skipValidationFor === connectionName
                ? Forms.success()
                : isvMappingService
                      .connectionNameExists(connectionId, connectionName)
                      .then(foundConnections =>
                          size(foundConnections) > 0
                              ? Forms.error("Already exists")
                              : Forms.success(),
                      )
                      .catch(e => Forms.error(getErrorMessage(e))),
        [skipValidationFor, isvMappingService, connectionId],
    );
};

export const useIsvMapperNameValidator = ({ initialValues, connectionId }) => {
    const connectionExistsValidator = useIsvMapperNameExistsValidator({
        connectionId,
        skipValidationFor: initialValues?.name,
    });

    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                debounceAsync(500, connectionExistsValidator),
            ]),
        [connectionExistsValidator],
    );
};
