import { asClass, asValue } from "awilix";

import { administrationAppLocationsMap } from "./administrationApp.locations";
import { AdminAssetRoleApiService } from "./services/AdminAssetRoleApiService.class";
import { AdminRightsApiService } from "./services/AdminRightsApiService.class";
import { userAdminService } from "./services/userAdmin.service";

export function registerAdministrationApp({
    register,
    cradle: { locationRouterService },
}) {
    register({
        administrationAppLocations: asValue(administrationAppLocationsMap),
        adminAssetRoleApiService: asClass(AdminAssetRoleApiService).singleton(),
        adminRightsApiService: asClass(AdminRightsApiService).singleton(),
        userAdminService: asValue(userAdminService),
    });

    locationRouterService.registerLocations(administrationAppLocationsMap);
}
