import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { Tag } from "@/components/DesignSystem";
import { t } from "@/translations/index.js";
import React from "react";

export const columns = [
    {
        label: t("processed-files.column.time"),
        name: "@timestamp",
        render: (_, record) => {
            return (
                <FormattedDateTime>
                    {record.source["@timestamp"]}
                </FormattedDateTime>
            );
        },
        width: 180,
    },
    {
        label: t("processed-files.column.integration"),
        name: "camel.routeId",
        canSort: false,
        skipParseDotNotation: true,
        render: (_, record) => record.source["camel.routeId"],
        width: 300,
    },
    {
        label: t("processed-files.column.direction"),
        name: "im-direction",
        canSort: false,
        render: (_, record) => record.source["im-direction"],

        width: 100,
    },
    {
        label: t("processed-files.column.source"),
        name: "im-labels",
        exportName: "im-direction=in?im-file-consumed:im-file-produced",
        condition: true,
        canSort: false,
        render: (_, record) => {
            return record.source["im-direction"] === "in"
                ? record.source["im-file-consumed"]
                : record.source["im-file-produced"];
        },
        width: 200,
    },
    {
        label: t("processed-files.column.type"),
        name: "im-type",
        canSort: false,
        render: (_, record) => record.source["im-type"],
        width: 100,
    },
    {
        label: t("processed-files.column.status"),
        canSort: false,
        width: 100,
        name: "level",
        render: (_, record) => (
            <span>
                <Tag
                    size="small"
                    color={
                        record.source?.level === "INFO" ? "#4dbd74" : "#f86c6b"
                    }
                >
                    {t(
                        record.source?.level === "INFO"
                            ? "processed-files.column.status.successful"
                            : "processed-files.column.status.failed",
                    )}
                </Tag>
            </span>
        ),
    },
    {
        label: t("processed-files.column.exception"),
        name: "im-exception",
        canSort: false,
        render: (_, record) => record.source["im-exception"],
    },
];
