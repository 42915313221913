import { tap } from "@/components/Connections/loadables";
import { useMutationLoadableWithNotification } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { supportUserService } from "../../services/supportUser.service";

export const useCreateSupportUserMutation = ({
    accountId,
    partitionId,
    afterSuccess,
}) => {
    return useMutationLoadableWithNotification(
        async values =>
            supportUserService
                .createSupportUser({
                    projectId: accountId,
                    partitionId: partitionId,
                    username: values.username,
                    password: values.password,
                    expiration:
                        values.expiration?.toISOString?.() ?? values.expiration,
                })
                .then(getData)
                .then(tap(afterSuccess)),
        [accountId, afterSuccess, partitionId],
        "partition.create-support-user.created",
    );
};
