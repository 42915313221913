import { Checkbox, Tooltip, UnityHelp } from "@/components/DesignSystem";
import React from "react";

const tid = uiState =>
    ["checkbox"]
        .concat(uiState.indeterminate ? "indeterminate" : [])
        .concat(uiState.checked ? "checked" : [])
        .concat(uiState.disabled ? "disabled" : [])
        .join("-");

export const createColumns = ({
    title,
    changes,
    onChange,
    disabled,
    isWFPermission,
}) => [
    {
        label: title,
        name: "description",
        render: (text, record) => (
            <>
                {text}
                {isWFPermission?.(record) ? (
                    <UnityHelp content="Assignment of this permission will go through approval workflow" />
                ) : null}
            </>
        ),
    },
    {
        width: 35,
        name: "checked",
        render: (text, record) => {
            const stateIndex = changes?.[record.name]?.stateIndex ?? 0;
            const uiState = record.states[stateIndex] || {}; // when entity selection cleared for one render before drawer closes https://pricefx.atlassian.net/browse/PFIM-6557

            return (
                <Tooltip title={uiState.tooltipText}>
                    <Checkbox
                        onChange={e => onChange(record, e.target.checked)}
                        checked={uiState.checked}
                        indeterminate={!!uiState.indeterminate}
                        disabled={!!uiState.disabled || disabled}
                        data-test={tid(uiState)}
                    />
                </Tooltip>
            );
        },
    },
];
