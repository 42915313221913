import { Forms } from "@/components/DesignSystem";
import { certificatesService } from "@/services/certificates.service";
import { getErrorMessage } from "@/utils/state/error.utils";
import { useCallback, useMemo } from "react";

const useCertificateExistsValidator = ({
    instanceId,
    skipValidationFor,
    withOnline,
}) => {
    return useCallback(
        value =>
            skipValidationFor && skipValidationFor === value
                ? Forms.success()
                : certificatesService
                      .certificateExists({
                          instanceId,
                          name: value,
                          withOnline,
                      })
                      .then(({ data: exists }) =>
                          exists
                              ? Forms.error("Already exists")
                              : Forms.success(),
                      )
                      .catch(e => Forms.error(getErrorMessage(e))),
        [instanceId, skipValidationFor, withOnline],
    );
};

export const useCertificateNameValidator = ({
    instanceId,
    currentName,
    withOnline,
}) => {
    const certificateExistsValidator = useCertificateExistsValidator({
        instanceId,
        skipValidationFor: currentName,
        withOnline,
    });

    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                certificateExistsValidator,
            ]),
        [certificateExistsValidator],
    );
};
