import { axiosService } from "../../../axios";

export const alertsServices = {
    fetchAlerts(accountId, page, size, sort = "fired,desc", filter) {
        if (accountId) {
            return axiosService.post(
                `/api/alerts?accountId=${accountId}&page=${page}&size=${size}&sort=${sort}`,
                filter,
            );
        } else {
            return axiosService.post(
                `/api/alerts?page=${page}&size=${size}&sort=${sort}`,
                filter,
            );
        }
    },

    fetchAlertDetails(alertId) {
        return axiosService.get(`/api/alerts/${alertId}`);
    },
};
