import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { LocationLink } from "@/modules/router";
import { t } from "@/translations";
import isNumber from "lodash/isNumber";
import React from "react";

export const Territory = Object.freeze({
    APAC: "APAC",
    CANADA: "Canada",
    EMEA: "EMEA",
    GLOBAL: "Global",
    MEXICO: "Mexico",
    NORTH_AMERICA: "North America",
    SOUTH_AMERICA: "South America",
});

export const createColumns = ({ dashboardLocation, accountTypes }) => [
    {
        type: fieldTypes.TEXT,
        label: t("customer-list.header.account-name"),
        name: "name",
        className: "pmTable__column--withActionButton",
        render: (text, record) => (
            <>
                {record.hasRight ? (
                    <LocationLink
                        key={record.id}
                        $location={dashboardLocation}
                        $params={{
                            accountId: record.id,
                        }}
                    >
                        {record.name}
                    </LocationLink>
                ) : (
                    record.name
                )}
            </>
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("customer-list.header.parent"),
        name: "parentName",
        visibleInPanel: true,
        panelProps: { hideEmpty: true },
        render: (text, record) =>
            isNumber(record.parentId) ? (
                record.hasRightForParent && record.parentId ? (
                    <>
                        <LocationLink
                            key={record.parentId}
                            $location={dashboardLocation}
                            $params={{
                                accountId: record.parentId,
                            }}
                        >
                            {record.parentName}
                        </LocationLink>
                    </>
                ) : (
                    record.parentName
                )
            ) : null,
    },
    {
        type: fieldTypes.TEXT,
        label: t("customer-list.header.account-owner"),
        name: "ownerName",
        visibleInPanel: true,
    },
    {
        visible: false,
        type: fieldTypes.TEXT,
        label: t("customer-list.header.account-num"),
        name: "accountNum",
        visibleInPanel: true,
    },
    {
        type: fieldTypes.OPTION,
        label: t("customer-list.header.type"),
        name: "type",
        options: accountTypes,
        visibleInPanel: true,
    },
    {
        type: fieldTypes.TEXT,
        label: t("customer-list.header.account-status"),
        name: "accountStatus",
        visibleInPanel: true,
    },
    {
        type: fieldTypes.OPTION,
        label: t("customer-list.header.territory"),
        name: "territory",
        options: Object.values(Territory),
        visibleInPanel: true,
    },
    {
        visible: false,
        type: fieldTypes.TEXT,
        label: t("general.owner-contact"),
        name: "ownerEmail",
        visibleInPanel: true,
    },
    {
        visible: false,
        type: fieldTypes.DATETIME,
        label: t("general.last-changed"),
        name: "updatedAt",
        visibleInPanel: true,
        canFilter: false,
        canSort: true,
        render: (text, record) => (
            <FormattedDateTime fromNow>{record.updatedAt}</FormattedDateTime>
        ),
        panelRenderValue: (text, record) => (
            <>
                <FormattedDateTime fromNow>
                    {record.updatedAt}
                </FormattedDateTime>
                {record.updatedBy ? <span> by {record.updatedBy}</span> : null}
            </>
        ),
    },
];
