import { DataUploadFailedRecords } from "@/components/DataUploads/DataUploadFailedRecords";
import {
    useDataUploadQuery,
    useFilesQuery,
} from "@/components/DataUploads/loadables";
import FromNowFormatter from "@/components/DateTime/FromNowFormatter";
import {
    fieldTypes,
    HEADER_HEIGHT_WITH_BREADCRUMB,
} from "@/components/DesignSystem/Table/constants";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { ENTITY_TYPE_CODES_FE } from "@/constants/SupportedTableImportTypes";
import { t } from "@/translations";
import has from "lodash/has";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useSetPageTitle } from "../PageLayout/useSetPageTitle.hook";
import { TableLocalFiltered } from "../TableLocalFiltered";

// TODO: translations?
export const targetTypeSelector = upload => {
    if (isObject(upload)) {
        return ENTITY_TYPE_CODES_FE[upload.entityType]?.label ?? "";
    } else {
        return "";
    }
};

export const targetNameSelector = upload => {
    if (has(upload, "entityName")) {
        return isString(upload.entityName) ? upload.entityName : "";
    } else {
        return "";
    }
};

const columns = [
    {
        label: t("data-upload-history.header.imported"),
        type: fieldTypes.DATETIME,
        name: "uploadedTime",
        render: uploadedTime => <FromNowFormatter date={uploadedTime} />,
    },
    {
        label: t("data-upload-history.header.target"),
        type: fieldTypes.TEXT,
        name: "_composedTarget",
    },
    {
        type: fieldTypes.TEXT,
        label: t("data-upload-history.header.filename"),
        name: "originalName",
    },
    {
        type: fieldTypes.INTEGER,
        label: t("data-upload-history.header.records"),
        name: "recordCount",
    },
    {
        type: fieldTypes.TEXT,
        label: t("data-upload-history.header.status"),
        name: "status",
    },
    {
        type: fieldTypes.TEXT,
        label: t("data-upload-history.header.failed"),
        name: "failedInputRecords",
    },
];

export const DataUploadHistory = ({ partitionId, uploadId, expand }) => {
    const dataUploadQuery = useDataUploadQuery({ partitionId, uploadId });
    const filesQuery = useFilesQuery({ partitionId, uploadId });

    const upload = dataUploadQuery.loadable.valueMaybe() ?? {};
    const _composedTarget = `${targetTypeSelector(upload)} ${targetNameSelector(
        upload,
    )}`;

    useFullWidthTableLayout();
    useSetPageTitle(upload.name);

    const mappedDataSource = useMemo(
        () =>
            filesQuery.loadable.valueMaybe()?.map(file => ({
                ...file,
                _composedTarget,
            })) ?? [],
        [_composedTarget, filesQuery.loadable],
    );

    return (
        <TableLocalFiltered
            columns={columns}
            dataSource={mappedDataSource}
            rowKey="id"
            expand={expand}
            pmExpandable={{
                expandedRowHeight: 400,
                expandedRowRender: record => (
                    <DataUploadFailedRecords
                        partitionId={partitionId}
                        uploadId={uploadId}
                        fileId={`${record.id}`}
                        failedRecordsCount={record.failedInputRecords}
                    />
                ),
            }}
            fixed
            restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
        />
    );
};

DataUploadHistory.propTypes = {
    partitionId: PropTypes.number.isRequired,
    uploadId: PropTypes.number.isRequired,
    expand: PropTypes.array,
};
