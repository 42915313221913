import {
    idleLoadableProps,
    useBusinessKeysQuery,
    useCreateTableMutation,
    usePfxUserGroupsQuery,
} from "@/components/DataUploads/Wizard/loadables";
import { useFooterButtons } from "@/components/DataUploads/Wizard/NewTable/components/FooterButtonsContext";
import { Forms } from "@/components/DesignSystem";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { t } from "@/translations";
import React from "react";

// https://platform-manager-fe-eu-west-1.plqa.pricefx.com/swagger-ui/index.html#/data-load-partition-controller/createExtension
export const NAME_VALIDATION_PATTERN = new RegExp(/^[a-zA-Z0-9_-]*$/);

const numberOfAttributesOptions = [
    { value: 3, label: "3" },
    { value: 6, label: "6" },
    { value: 8, label: "8" },
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
    { value: 50, label: "50" },
];

export const CreateExtensionStep = ({
    stepProps: {
        visible,
        partitionId,
        tableType,
        setTableParams,
        entityTypeNames,
        onCancel,
        afterSave,
    },
    stepperProps: { nextStep, prevStep },
}) => {
    const createTableMutation = useCreateTableMutation({
        partitionId,
        tableType,
        afterSave: ({ name }) => {
            setTableParams({ name });
            afterSave();
            nextStep();
        },
    });
    const { formId, handleSubmit } = Forms.useForm({
        onSubmit: ({
            values: { userGroupEdit, userGroupViewDetails, ...values },
        }) =>
            createTableMutation.mutate({
                ...values,
                userGroupEdit: userGroupEdit?.join?.(","),
                userGroupViewDetails: userGroupViewDetails?.join?.(","),
            }),
    });
    useFooterButtons([
        {
            label: t("general.continue"),
            type: "primary",
            formId,
        },
        {
            label: t("general.cancel"),
            type: "text",
            onClick: onCancel,
        },
    ]);
    const pfxUserGroupsQuery = usePfxUserGroupsQuery({
        partitionId,
        canFetch: visible,
    });
    const size = Forms.useFieldValue({ formId, name: "numberOfAttributes" });
    const businessKeysQuery = useBusinessKeysQuery({
        partitionId,
        tableType,
        size,
        canFetch: typeof size === "number",
    });
    const name =
        {
            CX: t("new-table.cx-name.label"),
            PX: t("new-table.px-name.label"),
            SX: t("new-table.sx-name.label"),
        }[tableType] ?? t("general.name");
    const existsValidator = Forms.pmValidators.createForbidValuesValidation(
        entityTypeNames,
        "Already exists",
    );

    return (
        <Forms.Form formId={formId} onSubmit={handleSubmit}>
            <Forms.FieldGroup width="max" inputWidth="max">
                <Forms.Fields.Input
                    name="name"
                    label={name}
                    required
                    validator={Forms.validators.failOnFirst([
                        Forms.pmValidators.isRequired,
                        Forms.pmValidators.createPatternValidator(
                            NAME_VALIDATION_PATTERN,
                            "Name has a wrong format. Valid characters are a-z A-Z, 0-9 _ - without spaces",
                        ),
                        existsValidator,
                    ])}
                    allowClear={false}
                />
                <Forms.Fields.Input
                    name="label"
                    label={t("new-table.label.label")}
                    allowClear={false}
                />
                <Forms.Fields.Select
                    name="numberOfAttributes"
                    label={t("new-table.numberOfAttributes.label")}
                    options={numberOfAttributesOptions}
                    required
                    validator={Forms.pmValidators.isRequired}
                    allowClear={false}
                />
                <Forms.Fields.Checkbox
                    name="allowSearch"
                    label={t("new-table.allowSearch.label")}
                />
                <Forms.Fields.Checkbox
                    name="allowPASearch"
                    label={t("new-table.allowPASearch.label")}
                />
                <Forms.Fields.Select
                    name="userGroupEdit"
                    label={t("new-table.userGroupEdit.label")}
                    mode="tags"
                    {...getLoadableSelectProps(pfxUserGroupsQuery.loadable)}
                />
                <Forms.Fields.Select
                    name="userGroupViewDetails"
                    label={t("new-table.userGroupViewDetails.label")}
                    mode="tags"
                    {...getLoadableSelectProps(pfxUserGroupsQuery.loadable)}
                />
                <Forms.Fields.Select
                    name="businessKey"
                    label={t("new-table.businessKey.label")}
                    mode="tags"
                    {...getLoadableSelectProps(businessKeysQuery.loadable)}
                    {...idleLoadableProps(
                        businessKeysQuery.loadable,
                        "Please, select size",
                    )}
                />
            </Forms.FieldGroup>
        </Forms.Form>
    );
};
