import {
    HeaderStepsContext,
    initialState,
} from "@/components/PageLayout/HeaderStepsContext";
import { useContext, useEffect } from "react";

export const useHeaderSteps = () => {
    const { headerSteps, setHeaderSteps } = useContext(HeaderStepsContext);
    useEffect(() => {
        return () => {
            setHeaderSteps(initialState);
        };
    }, [setHeaderSteps]);

    return { headerSteps, setHeaderSteps };
};
