import { isNil } from "lodash";
import { useCallback, useMemo, useState } from "react";

export function useValueVisibility(initialValue = null) {
    const [value, setValue] = useState(initialValue);
    const visible = !isNil(value);

    const show = useCallback(value => setValue(value), [setValue]);

    const hide = useCallback(() => setValue(null), [setValue]);

    const visibility = useMemo(
        () => ({ visible, show, hide, value }),
        [visible, show, hide, value],
    );

    return visibility;
}
