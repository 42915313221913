import { Input as AntInput } from "antd";
import identity from "lodash/fp/identity";
import trim from "lodash/fp/trim";
import PropTypes from "prop-types";
import React, { forwardRef, useCallback, useEffect, useState } from "react";

const emptyValues = Object.freeze([]);

export const parseStringValue = (value, separator) => {
    if (!value) {
        return emptyValues;
    }
    return value.split(separator).map(trim).filter(identity);
};

export const formatValues = (values, separator) => {
    if (!Array.isArray(values)) {
        return "";
    }
    return values.filter(identity).join(`${separator} `);
};

const InputMultiple = forwardRef((props, ref) => {
    const { valueSeparator = ",", onBlur, onChange, value, ...other } = props;

    const [localValue, setLocalValue] = useState("");

    useEffect(() => {
        setLocalValue(formatValues(value, valueSeparator));
    }, [value]);

    const localOnBlur = useCallback(() => {
        onChange && onChange(parseStringValue(localValue, valueSeparator));
        onBlur && onBlur();
    }, [onBlur, onChange, localValue]);
    const localOnChange = useCallback(event => {
        setLocalValue(event?.target?.value);
    }, []);

    return (
        <AntInput
            {...other}
            onBlur={localOnBlur}
            onChange={localOnChange}
            ref={ref}
            value={localValue}
        />
    );
});

export default InputMultiple;

InputMultiple.propTypes = {
    valueSeparator: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.any,
};
