import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useMutationLoadableWithNotification } from "@/modules/loadable";
import { t } from "@/translations";
import { noop } from "lodash";

export const useRemovePartitionMutation = ({ afterSuccess = noop }) => {
    const { registryService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async partitionId =>
            registryService.removePartition(partitionId).then(res => {
                afterSuccessCurrent();
                return res;
            }),
        [afterSuccessCurrent, registryService],
        ({ name }) => t("general.message.deleted", { type: "partition", name }),
    );
};
