import { useDic } from "@/components/Dic/useDic.hook";
import { ISV_SETTINGS_TABS } from "@/components/ISVConnections/ISVConnectionsSettings.page";
import { mapVendorToVendorName } from "@/components/ISVMapping/helpers";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { SFTPServerUserCreation } from "@/components/SFTPServers/SFTPServerUserCreation.component";
import { useRouteISVConnection } from "@/mixpanel/hooks/useRouteISVConnection.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { sftpEntityType } from "@/services/dataLoadSftp.service";
import { t } from "@/translations";
import React, { useCallback } from "react";

export const IsvSftpServerUserPage = () => {
    const { isvConnectionId, userId } = useAccountAppParams();
    const { isvConnectionLoadable } = useRouteISVConnection();

    const {
        locationRouterService,
        accountAppLocations: { isvConnectionsSettingsLocation },
    } = useDic();

    const navigateToUsersList = useCallback(() => {
        locationRouterService.navigate(isvConnectionsSettingsLocation, {
            tab: ISV_SETTINGS_TABS.PRICEFX,
        });
    }, [locationRouterService, isvConnectionsSettingsLocation]);

    useSetBackButtonAction(navigateToUsersList);
    return (
        <SFTPServerUserCreation
            perex={t("isv-connections.sftp.perex", {
                vendorName: mapVendorToVendorName(
                    isvConnectionLoadable.valueMaybe()?.vendor,
                ),
            })}
            dataLoadId={isvConnectionId}
            entityType={sftpEntityType.ISV_ENABLE}
            userId={userId}
            navigateToUsersList={navigateToUsersList}
        />
    );
};

IsvSftpServerUserPage.propTypes = {};
