import { useEffect, useState } from "react";

const isDefinedFieldWithSourceAndLabel = fields =>
    fields && fields.some(f => f.name?.length > 0 && f.label?.length > 0);

const fieldNeedsSource = fields =>
    fields &&
    fields
        .filter(f => noSubfieldsDefined(f) && !f.isSum)
        .every(f => f.name !== null);

const isDefinedFieldWithSumAndLabel = fields =>
    fields &&
    someFieldsExists(fields) &&
    fields.some(f => f.isSum === true && f.label?.length > 0);

const isDefinedFieldsWithLabels = fields =>
    fields &&
    someFieldsExists(fields) &&
    fields.every(f => f.label?.length > 0);

const isDefinedLastFieldAsSum = fields =>
    fields &&
    someFieldsExists(fields) &&
    fields[fields.length - 1].isSum === true;

const cannotSelectSourceIfSumSelected = record => record.isSum === true;
const cannotSelectSourceIfSubfieldSelected = record =>
    record.subLevel && record.subLevel[0].name !== null;
const cannotSelectSumIfSourceSelected = record => !!record.name;

const noSubfieldsDefined = record =>
    record.subLevel.length === 1 && record.subLevel[0].name === null;

const cannotCheckSubtractIfNoSubfieldsDefined = record =>
    noSubfieldsDefined(record) && record.name === null;

const isExistingMaxOnePercentBase = fields =>
    fields &&
    (fields.every(f => f.isPercentBase === false) ||
        fields.filter(f => f.isPercentBase === true).length === 1);

const isFirstRow = index => index === 0;

const someFieldsExists = inputFields => inputFields?.length > 0;

const isFieldWithSumFirst = fields =>
    someFieldsExists(fields) && fields[0].isSum === true;

const isFirstRowWithSource = fields =>
    someFieldsExists(fields) && fields[0].name !== null;

export const useWaterfallValidation = inputFields => {
    const [validationErrors, setValidationErrors] = useState([]);

    const addValidationError = error =>
        setValidationErrors(errors => [
            ...errors.filter(e => e !== error),
            error,
        ]);
    const removeValidationError = error =>
        setValidationErrors(errors => errors.filter(e => e !== error));

    useEffect(() => {
        if (isFieldWithSumFirst(inputFields)) {
            addValidationError("isFieldWithSumFirst");
        } else {
            removeValidationError("isFieldWithSumFirst");
        }

        if (!isExistingMaxOnePercentBase(inputFields)) {
            addValidationError("isExistingMaxOnePercentBase");
        } else {
            removeValidationError("isExistingMaxOnePercentBase");
        }

        if (!isDefinedFieldWithSourceAndLabel(inputFields)) {
            addValidationError("isDefinedFieldWithSourceAndLabel");
        } else {
            removeValidationError("isDefinedFieldWithSourceAndLabel");
        }

        if (!isFirstRowWithSource(inputFields)) {
            addValidationError("isFirstRowWithSource");
        } else {
            removeValidationError("isFirstRowWithSource");
        }

        if (!isDefinedFieldWithSumAndLabel(inputFields)) {
            addValidationError("isDefinedFieldWithSumAndLabel");
        } else {
            removeValidationError("isDefinedFieldWithSumAndLabel");
        }

        if (!isDefinedLastFieldAsSum(inputFields)) {
            addValidationError("isDefinedLastFieldAsSum");
        } else {
            removeValidationError("isDefinedLastFieldAsSum");
        }

        if (!fieldNeedsSource(inputFields)) {
            addValidationError("fieldNeedsSource");
        } else {
            removeValidationError("fieldNeedsSource");
        }

        if (!isDefinedFieldsWithLabels(inputFields)) {
            addValidationError("isDefinedFieldsWithLabels");
        } else {
            removeValidationError("isDefinedFieldsWithLabels");
        }
    }, [inputFields]);

    return {
        errors: validationErrors,
        ui: {
            columns: {
                name: record => ({
                    disabled:
                        cannotSelectSourceIfSumSelected(record) ||
                        cannotSelectSourceIfSubfieldSelected(record),
                }),
                isSum: (record, index) => ({
                    disabled:
                        cannotSelectSumIfSourceSelected(record) ||
                        isFirstRow(index),
                }),
                isSubtract: record => ({
                    disabled: cannotCheckSubtractIfNoSubfieldsDefined(record),
                }),
            },
            submitButton: {
                disabled: validationErrors.length !== 0,
            },
        },
        fields: inputFields,
    };
};
