import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { partitionLimitColumns } from "@/components/PartitionLimit/PartitionLimit.columns";
import { PartitionLimitModal } from "@/components/PartitionLimit/PartitionLimitModal";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { t } from "@/translations";
import { buildPageableUrl } from "@/utils/urlUtils";
import React, { useCallback } from "react";

const preferencesType = preferencesTypes.PARTITIONS_LIMIT_TABLE;

export const PartitionLimitPage = () => {
    useFullWidthTableLayout();
    useHideBackButton();

    const { axiosService } = useDic();
    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort, filter) =>
            axiosService.post(
                buildPageableUrl(
                    `/api/partition-limit/table`,
                    page,
                    size,
                    sort,
                ),
                filter,
            ),
        [],
    );
    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.tooltip.edit"),
                        customModal: (
                            <PartitionLimitModal
                                record={record}
                                afterSuccess={reload}
                            />
                        ),
                    },
                ]}
            />
        ),
        [reload],
    );

    return (
        <TableWithPreferencesManagement
            columns={partitionLimitColumns}
            actionMenu={actionMenu}
            rowKey="projectId"
            datasetSlicing="server"
            preferencesType={preferencesType}
            {...tableProps}
        />
    );
};
