import { T } from "@/translations";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Property } from "../Property/Property.component";

const partitionStatus = {
    CREATION_REQUESTED: "Creation Requested",
    DELETION_REQUESTED: "Deletion Requested",
};

const supportSystemTicketTypes = {
    CREATE_PARTITION: "CREATE_PARTITION",
    DELETE_PARTITION: "DELETE_PARTITION",
    CLONE_PARTITION: "CLONE_PARTITION",
    MOVE_PARTITION: "MOVE_PARTITION",
};

export const RequestTicketProperty = ({ record, fetchRequestTicketUrl }) => {
    const [requestTicketUrl, setRequestTicketUrl] = useState();

    const isInCreationRequestedState = record =>
        record.status === partitionStatus.CREATION_REQUESTED;
    const isInDeletionRequestedState = record =>
        record.status === partitionStatus.DELETION_REQUESTED;

    const canRenderRequestTicketUrl = record =>
        record &&
        fetchRequestTicketUrl &&
        requestTicketUrl &&
        (isInCreationRequestedState(record) ||
            isInDeletionRequestedState(record) ||
            record.moveRequestExists);

    const requestTicketUrlLabel = record => {
        if (isInCreationRequestedState(record)) {
            return "general.create-request-ticket-url";
        } else if (isInDeletionRequestedState(record)) {
            return "general.delete-request-ticket-url";
        } else if (record.moveRequestExists) {
            return "general.move-request-ticket-url";
        }
    };

    const fetchTicketByType = (record, type) =>
        fetchRequestTicketUrl(record.id, type)
            .then(data => setRequestTicketUrl(data))
            .catch(() => setRequestTicketUrl(undefined));

    useEffect(() => {
        if (
            record &&
            isInCreationRequestedState(record) &&
            fetchRequestTicketUrl
        ) {
            fetchRequestTicketUrl(
                record.id,
                supportSystemTicketTypes.CREATE_PARTITION,
            )
                .then(data => setRequestTicketUrl(data))
                .catch(() =>
                    fetchTicketByType(
                        record,
                        supportSystemTicketTypes.CLONE_PARTITION,
                    ),
                );
        }
        if (
            record &&
            isInDeletionRequestedState(record) &&
            fetchRequestTicketUrl
        ) {
            fetchTicketByType(
                record,
                supportSystemTicketTypes.DELETE_PARTITION,
            );
        }
        if (record && record.moveRequestExists) {
            fetchTicketByType(record, supportSystemTicketTypes.MOVE_PARTITION);
        }
    }, [record]);

    return !canRenderRequestTicketUrl(record) ? null : (
        <Property
            label={<T id={requestTicketUrlLabel(record)} />}
            value={requestTicketUrl}
            href={requestTicketUrl}
        />
    );
};

RequestTicketProperty.propTypes = {
    record: PropTypes.object,
    fetchRequestTicketUrl: PropTypes.func,
};
