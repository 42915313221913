import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";
import { TemplateType } from "../../../../constants/CommonConstats";

export const templatesManagementColumns = () => [
    {
        type: fieldTypes.TEXT,
        label: t("templates-management-list.header.label"),
        name: "label",
    },
    {
        type: fieldTypes.TEXT,
        label: t("templates-management-list.header.uniqueName"),
        name: "uniqueName",
    },
    {
        type: fieldTypes.OPTION,
        options: [
            {
                label: t("general.yes"),
                value: true,
            },
            {
                label: t("general.no"),
                value: false,
            },
        ],
        label: t("templates-management-list.header.published"),
        name: "published",
        render: (text, record) => (
            <React.Fragment>
                {record.published ? t("general.yes") : t("general.no")}
            </React.Fragment>
        ),
    },
    {
        type: fieldTypes.OPTION,
        label: t("templates-management-list.header.type"),
        name: "type",
        render: (text, record) => record.type,
        options: [
            {
                label: TemplateType.PACKAGES.toLowerCase(),
                value: TemplateType.PACKAGES,
            },
            {
                label: TemplateType.INTEGRATIONS.toLowerCase(),
                value: TemplateType.INTEGRATIONS,
            },
            {
                label: TemplateType.SOLUTIONS.toLowerCase(),
                value: TemplateType.SOLUTIONS,
            },
            {
                label: TemplateType.NON_APPROVED.toLowerCase(),
                value: TemplateType.NON_APPROVED,
            },
        ],
    },
];
