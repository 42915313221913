import { axiosService } from "../../axios";

export const SSOCertificateAccountService = {
    listCertificates: ({ projectId }) => {
        return axiosService.get(
            `/api/projects/${projectId}/saml-management/certificates`,
        );
    },
    createCertficate: ({ projectId }, body) => {
        return axiosService.post(
            `/api/projects/${projectId}/saml-management/certificates`,
            body,
        );
    },
    deleteCertficate: ({ projectId, certificateId }) => {
        return axiosService.delete(
            `/api/projects/${projectId}/saml-management/certificates/${certificateId}`,
        );
    },
};
