import {
    RowSkeleton,
    RowSkeletonVariant,
} from "@/components/DesignSystem/Skeleton/RowSkeleton.component";
import { useDic } from "@/components/Dic/useDic.hook";
import { NUMBER_ITEMS_PER_PAGE } from "@/components/Marketplace/Marketplace.page";
import { useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";
import PropTypes from "prop-types";
import React from "react";
import "./TemplatesList.style.less";

const limitItems = (numberOfItems, max) => {
    return numberOfItems > max ? max : numberOfItems;
};

export const useTemplates = ({ accountId, category }) => {
    const { marketplaceItemsService } = useDic();

    return useQueryLoadable(
        async () =>
            marketplaceItemsService
                .getItems({ accountId, category })
                .then(getData),
        [accountId, category, marketplaceItemsService],
    );
};

export const TemplatesList = ({ templates, renderItem }) => {
    return <div className="pmTemplatesList">{templates.map(renderItem)}</div>;
};

export const TemplateListSkeleton = ({ numberOfItems = 12 }) => (
    <div className="pmTemplatesList">
        {Array.from(
            Array(limitItems(numberOfItems, NUMBER_ITEMS_PER_PAGE)).keys(),
        ).map(index => (
            <RowSkeleton
                key={index}
                variant={RowSkeletonVariant.MARKETPLACE_ITEM}
            />
        ))}
    </div>
);

TemplatesList.propTypes = {
    renderItem: PropTypes.func.isRequired,
};
