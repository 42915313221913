import { buildPageableUrl } from "@/utils/urlUtils";
import { axiosService } from "../../../axios";

export const groupService = {
    getGroupsList() {
        return axiosService.get(`/api/admin/groups`);
    },

    fetchGroups(page, size, sort, filter) {
        return axiosService.post(
            buildPageableUrl(`/api/admin/groups/table`, page, size, sort),
            filter,
        );
    },
};
