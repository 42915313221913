import { useDic } from "@/components/Dic/useDic.hook";
import { ConditionalImConfigErrorAlert } from "@/components/Error/ConditionalImConfigErrorAlert";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { InstanceRepoMappersList } from "@/components/Mappers/InstanceRepoMappersList";
import {
    HeaderTabs,
    useHeaderTabs,
} from "@/components/PageLayout/components/HeaderTabs.component";
import { useRefreshAction } from "@/components/PageLayout/useRefreshAction.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import React, { useReducer } from "react";
import { useEditInstanceBreadcrumbButton } from "../../apps/accountApp/components/hooks/useEditInstanceBreadcrumbButton.hook";
import { getMappersListTrackName } from "../../mixpanel/buttonNames";
import { MappersList } from "./MappersList";

export const TABS = {
    INSTANCE_REPOSITORY: "INSTANCE_REPOSITORY",
    WORKSPACE: "WORKSPACE",
};

export const MAPPER_KIND = {
    INSTANCE_REPOSITORY: "instance-repo",
    WORKSPACE: "workspace",
};

export const MappersListPage = () => {
    const { accountId, instanceId } = useAccountAppParams();
    const { activeKey, setActiveKey } = useHeaderTabs();
    const [reloadToken, reloadAll] = useReducer(s => ++s, 0);

    useFullWidthTableLayout();
    useRefreshAction(reloadAll);

    const {
        locationRouterService,
        accountAppLocations: {
            mapperNewLocation,
            mapperEditLocation,
            mappersDefinitionLocation,
        },
    } = useDic();

    useEditInstanceBreadcrumbButton({
        instanceId,
        label: "mapper-list.button.new",
        onClick: () => locationRouterService.navigate(mapperNewLocation),
        permissions: INTEGRATION_EDIT_PERMISSIONS,
        track: { name: getMappersListTrackName("New") },
    });
    const afterLocalCopy = useCurrentHandler(() => {
        setActiveKey(TABS.WORKSPACE);
        reloadAll();
    });
    const afterDeploy = useCurrentHandler(() => {
        setActiveKey(TABS.INSTANCE_REPOSITORY);
        reloadAll();
    });
    const onEdit = useCurrentHandler(record =>
        locationRouterService.navigate(mapperEditLocation, {
            mapperId: record.id,
        }),
    );

    const onShowDefinition = useCurrentHandler(record =>
        locationRouterService.navigate(mappersDefinitionLocation, {
            mapperIdOrPrn: encodeURIComponent(
                !record.standard ? record.prn : record.id,
            ),
            isNonStandardMapper: !record.standard,
        }),
    );

    return (
        <>
            <ConditionalImConfigErrorAlert instanceId={instanceId} />
            <HeaderTabs defaultActiveKey={TABS.INSTANCE_REPOSITORY}>
                <HeaderTabs.TabPane
                    tab="Instance Repository"
                    key={TABS.INSTANCE_REPOSITORY}
                    forceRender
                >
                    <InstanceRepoMappersList
                        instanceId={instanceId}
                        visible={activeKey === TABS.INSTANCE_REPOSITORY}
                        afterLocalCopy={afterLocalCopy}
                        onShowDefinition={onShowDefinition}
                        afterDelete={reloadAll}
                        reloadToken={reloadToken}
                    />
                </HeaderTabs.TabPane>
                <HeaderTabs.TabPane
                    tab="Workspace"
                    key={TABS.WORKSPACE}
                    forceRender
                >
                    <MappersList
                        accountId={accountId}
                        instanceId={instanceId}
                        visible={activeKey === TABS.WORKSPACE}
                        onEdit={onEdit}
                        reloadToken={reloadToken}
                        afterDeploy={afterDeploy}
                    />
                </HeaderTabs.TabPane>
            </HeaderTabs>
        </>
    );
};
