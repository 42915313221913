import { Option, Select } from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { types } from "../../constants";
import styles from "../styles.less";

export const TypeSelect = ({ handleType, disableOption, record, disabled }) => (
    <Select
        showSearch
        onChange={value => handleType(record, value)}
        disabled={disabled}
        placeholder={t("packages-type.select.placeholder")}
        defaultValue={record.type}
        className={styles.itemSelect}
        value={record.type}
    >
        {types.map(t => (
            <Option
                title={t.label}
                key={t.label}
                value={t.name}
                disabled={disableOption(record, t.name)}
            >
                {t.label}
            </Option>
        ))}
    </Select>
);

TypeSelect.propTypes = {
    disableOption: PropTypes.func.isRequired,
    handleType: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
};
