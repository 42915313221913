import { useConfirmModal } from "@/modules/modal/imperative/useConfirmModal.hook";
import PropTypes from "prop-types";
import React from "react";
import { conditionalOptionsModal } from "../../../../../apps/marketplaceApp/components/PackageDefinition/MappingOption.modal";
import { DataUploadFieldMapping } from "../../../../DataUploads/Wizard/DataUploadFieldMapping";

const PackageDataUploadFieldMappingContainer = ({
    accountId,
    partitionId,
    globalState,
    step,
    onNext,
    onBack,
    onCancel,
    handleSubmit,
}) => {
    const { fileInfo, definition, convertEmptyStringToNull } = globalState;
    const { entityType, entityName } = step;
    const confirmModal = useConfirmModal();

    const handleOnNext = values =>
        conditionalOptionsModal({
            condition: step.showMappingOptions,
            data: values,
            accountId,
            onNext,
            onCancel,
            confirmModal,
        });

    return (
        <DataUploadFieldMapping
            afterSubmit={handleOnNext}
            entityName={entityName}
            entityType={entityType}
            handleSubmit={handleSubmit}
            onCancel={onCancel}
            partitionId={partitionId}
            readOnly={false}
            tableData={fileInfo}
            initMapper={definition}
            onBack={onBack}
            // submitting={} // TODO: refactor and pass submitting
            // TODO: unify API (properties.convertEmptyStringToNull vs mapperProperties.convertEmptyStringToNull) and pass here?
            initialConvertEmptyStringToNull={convertEmptyStringToNull}
            parserConfig={globalState.parserConfig}
        />
    );
};

PackageDataUploadFieldMappingContainer.propTypes = {
    accountId: PropTypes.number.isRequired,
    partitionId: PropTypes.number.isRequired,
    globalState: PropTypes.object.isRequired,
    step: PropTypes.object.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default PackageDataUploadFieldMappingContainer;
