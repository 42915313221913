import { Table } from "@/components/DesignSystem";
import {
    DRAWER_FOOTER_HEIGHT,
    DRAWER_HEADER_HEIGHT,
    TAB_HEIGHT,
} from "@/components/DesignSystem/Table/constants";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import styles from "../../../../AccountAssetAdminSettings/accountAdminSettings.style.less";
import { createColumns } from "./PermissionsTable.columns";

export const PermissionsTable = ({
    title,
    onChange,
    dataSource,
    changes,
    disabled,
    testId,
    isWFPermission,
    isSinglePermissionType,
}) => {
    const columns = useMemo(
        () =>
            createColumns({
                title,
                changes,
                onChange,
                disabled,
                isWFPermission,
            }),
        [onChange, changes, title, disabled],
    );

    const fixedTableProps = isSinglePermissionType
        ? {
              fixed: true,
              restHeight:
                  DRAWER_HEADER_HEIGHT + TAB_HEIGHT + DRAWER_FOOTER_HEIGHT,
          }
        : {};
    return (
        <Table
            {...fixedTableProps}
            className={styles.permissionTable}
            columns={columns}
            dataSource={dataSource}
            bordered={false}
            pagination={false}
            rowKey="name"
            dataTest={testId}
        />
    );
};

const checkboxStatePropType = PropTypes.shape({
    tooltipText: PropTypes.node,
    checked: PropTypes.bool.isRequired,
    indeterminate: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
}).isRequired;

PermissionsTable.propTypes = {
    dataSource: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string.isRequired,
            states: PropTypes.arrayOf(checkboxStatePropType).isRequired,
        }).isRequired,
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    testId: PropTypes.any,
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    changes: PropTypes.objectOf(
        // keyed by permission name
        PropTypes.shape({ stateIndex: PropTypes.number.isRequired }).isRequired,
    ),
    isWFPermission: PropTypes.func,
    isSinglePermissionType: PropTypes.bool,
};
