import { HEADER_HEIGHT } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { getData } from "@/services/utils";
import React from "react";
import { EntityType } from "../../constants/CommonConstats";
import { ACTIVITY_LOG_ADMINISTRATION_LIST } from "../../constants/sessionState.constants";
import { DEFAULT_COLUMNS } from "../ActivityLog/ActivityLogList";
import ElasticSearchPanel from "../Integrations/Dashboard/Tabs/ElasticSearchPanel";

const ActivityLogAdministrationList = () => {
    const { axiosService } = useDic();

    const fetchData = params =>
        axiosService.post(`/api/admin/audit-logs`, params).then(getData);

    const exportLogs = params =>
        axiosService.post(
            `/api/admin/audit-logs/elastic-search/export`,
            params,
        );

    return (
        <ElasticSearchPanel
            fetchData={fetchData}
            defaultColumns={DEFAULT_COLUMNS}
            exportLogMessages={exportLogs}
            entityType={EntityType.PROJECT}
            sessionStateName={ACTIVITY_LOG_ADMINISTRATION_LIST}
            restHeight={HEADER_HEIGHT + 145}
        />
    );
};

export default ActivityLogAdministrationList;
