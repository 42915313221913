export const isOptional = step => step && step.mandatory === false;
export const isAlreadyDeployed = step => step && step.alreadyDeployed === true;
export const allowSkipWhenDeployed = step =>
    step && !(step.allowSkipWhenDeployed === false);

export const isSkippable = step =>
    isOptional(step) ||
    (isAlreadyDeployed(step) && allowSkipWhenDeployed(step));

export const isFirstUiStep = stepIndex => stepIndex === 0;

export const calculateWindowIndexes = (currentIndex, maxSize) => {
    let startIndex = currentIndex - 2;
    let endIndex = currentIndex + 3;
    let index = 2;

    if (startIndex < 0) {
        const shift = 0 - startIndex;
        startIndex = startIndex + shift;
        endIndex = endIndex + shift;
        index = index - shift;
        index = index >= 0 ? index : 0;
    }

    if (endIndex >= maxSize) {
        const shift = endIndex - maxSize;
        startIndex = startIndex - shift;
        endIndex = endIndex - shift;
        index = index + shift;
        index = index <= maxSize ? index : maxSize;
    }

    return { startIndex, endIndex, index };
};
