export const CONNECTION_TYPE = {
    PFX_CLIENT_CONNECTION_TYPE: "PriceFxClient",
    PFX_CONNECTION_TYPE: "PriceFxConnection",
    BASIC_CONNECTION_TYPE: "BasicConnection",
    OAUTH2_CONNECTION_TYPE: "OAuth2Connection",
};

export const VENDOR = {
    PRICEFX: "PRICEFX",
    ENABLE: "ENABLE",
};
