import { useDic } from "@/components/Dic/useDic.hook";
import {
    crudSuccess,
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { VISIBILITY } from "./TemplateVisibilityPanel";

export const useTemplateUsersQuery = ({ canFetch, uniqueName }) => {
    const { templateAdminService } = useDic();
    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : templateAdminService
                      .getTemplateUsers(uniqueName)
                      .then(r => r.data),
        [canFetch, uniqueName, templateAdminService],
    );
};

export const useTemplateAccountsQuery = ({ canFetch, uniqueName }) => {
    const { templateAdminService } = useDic();
    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : templateAdminService
                      .getTemplateAccounts(uniqueName)
                      .then(r => r.data),
        [canFetch, uniqueName, templateAdminService],
    );
};

export const useTemplateAvailableAccountsQuery = ({ canFetch, uniqueName }) => {
    const { templateAdminService } = useDic();
    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : templateAdminService
                      .getTemplateAvailableAccounts(uniqueName)
                      .then(r => r.data),
        [canFetch, uniqueName, templateAdminService],
    );
};

export const useProjectUsersQuery = ({ accountId }) => {
    const { adminApiService } = useDic();
    return useQueryLoadable(
        async () =>
            adminApiService.fetchProjectUsers(accountId).then(r => r.data),
        [accountId, adminApiService],
    );
};

export const useUpdateTemplateMutation = ({ uniqueName }) => {
    const { templateAdminService } = useDic();
    return useMutationLoadableWithNotification(
        async ({ visibility, users, accounts }) => {
            await templateAdminService.updateTemplateVisibility(
                uniqueName,
                visibility,
            );
            if (visibility === VISIBILITY.PROTECTED) {
                await Promise.all([
                    templateAdminService.setTemplateUsers(uniqueName, users),
                    templateAdminService.setTemplateAccounts(
                        uniqueName,
                        accounts,
                    ),
                ]);
            }
            return { uniqueName };
        },
        [uniqueName, templateAdminService],
        crudSuccess("Template", "uniqueName", "U"),
    );
};
