import { AlertRulesForm } from "@/components/AlertRules/AlertRulesForm";
import { alertRuleScope } from "@/components/AlertRules/services/alertRules.service";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React, { useCallback } from "react";

export function NewAlertRuleAccountPage() {
    const { accountId, alertRuleId } = useAccountAppParams();
    const { locationRouterService, accountAppLocations } = useDic();
    const handleBack = useCallback(
        () =>
            locationRouterService.navigate(
                accountAppLocations.accountAlertRulesLocation,
            ),
        [accountAppLocations.accountAlertRulesLocation, locationRouterService],
    );
    useSetBackButtonAction(handleBack);

    return (
        <AlertRulesForm
            accountId={accountId}
            alertRuleId={alertRuleId}
            scope={alertRuleScope.SINGLE}
        />
    );
}
