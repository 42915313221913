import { Button, Gap, message } from "@/components/DesignSystem";
import EditPropertyModal from "@/components/Properties/EditProperty.modal";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import { isLoading, useQueryLoadable, waitForValue } from "@/modules/loadable";
import { t } from "@/translations";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { integrationPropertiesService } from "../../services/integration.property.service";
import { propertiesColumns } from "./PropertiesList.columns";

const PropertiesList = ({
    entityType,
    entityId,
    prefix,
    instanceId,
    encryptionSupported,
}) => {
    const propertiesResource = useQueryLoadable(
        async () =>
            isNaN(entityId) && entityType === ""
                ? waitForValue()
                : integrationPropertiesService.fetchProperties(
                      entityType,
                      entityId,
                  ),
        [entityId, entityType],
    );

    const onDelete = useCallback(
        property => {
            integrationPropertiesService
                .deleteProperty(property)
                .then(() => {
                    message.success("Property has been deleted.");
                    propertiesResource.reload();
                })
                .catch(error => {
                    message.error(error.response.data.message);
                });
        },
        [propertiesResource],
    );

    const onDeploy = () => {
        setSelectedProperty(null);
        propertiesResource.reload();
    };
    const [selectedProperty, setSelectedProperty] = useState(null);

    return (
        <>
            <TableLocalFiltered
                rowHeight={48}
                loading={isLoading(propertiesResource)}
                dataSource={propertiesResource.loadable.valueMaybe() || []}
                padding={false}
                rowKey="id"
                columns={propertiesColumns({
                    onDelete,
                    setSelectedProperty,
                })}
                pagination={true}
            />
            <Gap />
            <Button
                size="small"
                onClick={() => setSelectedProperty({})}
                icon={Plus}
                label={t("properties.deploy-property")}
            />
            {selectedProperty && (
                <EditPropertyModal
                    encryptionSupported={encryptionSupported}
                    property={selectedProperty}
                    visible
                    instanceId={instanceId}
                    entityId={entityId}
                    entityType={entityType}
                    onSave={onDeploy}
                    onCancel={() => setSelectedProperty(null)}
                    prefix={prefix}
                    runtime={true}
                />
            )}
        </>
    );
};
PropertiesList.propTypes = {
    instanceId: PropTypes.number.isRequired,
    entityType: PropTypes.string.isRequired,
    entityId: PropTypes.number.isRequired,
    prefix: PropTypes.string,
};

export default PropertiesList;
