import { useEffect, useRef, useState } from "react";

export const useStateReinitialize = (
    reinitState,
    updater = (oldState, newState) => newState,
) => {
    const [state, setState] = useState(reinitState);

    const updaterRef = useRef(updater);
    if (updaterRef.current !== updater) updaterRef.current = updater;

    useEffect(() => {
        setState(oldState => updaterRef.current(oldState, reinitState));
    }, [reinitState]);
    return [state, setState];
};
