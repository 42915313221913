import { Gap } from "@/components/DesignSystem/Gap";
import { Text } from "@/components/DesignSystem/Text/Text";
import { mapInstancesToOptions } from "@/components/Marketplace/helpers";
import { t, T } from "@/translations";
import React from "react";
import { templatesService } from "../../../../services/templates.service";

const MODAL_WIDTH = 600;

// TODO: refactor
export const conditionalOptionsModal = ({
    condition,
    data,
    onNext,
    onCancel,
    accountId,
    confirmModal,
}) => {
    if (condition) {
        mappingOptionsModal({
            onNext: v => onNext({ ...data, ...v }),
            accountId,
            onCancel,
            confirmModal,
        });
    } else {
        onNext(data);
    }
};

const mappingOptionsModal = ({ onNext, onCancel, accountId, confirmModal }) =>
    templatesService.fetchIntegrationsForDeploy(accountId).then(d =>
        createMappingOptionsModal({
            instances: mapInstancesToOptions(d),
            onNext,
            onCancel,
            confirmModal,
        }),
    );

const createMappingOptionsModal = ({
    onNext,
    onCancel,
    instances,
    confirmModal,
}) => {
    confirmModal.confirm({
        title: <T id="package.mapping-options" />,
        message: (
            <>
                <Text>
                    <T id="package.mapping-options.description" />
                </Text>
                <Gap size="large" />
                <Text>
                    <T id="package.mapping-options.option1" />
                </Text>
                <Text>
                    <T id="package.mapping-options.option2" />
                </Text>
                <Text>
                    <T id="package.mapping-options.option3" />
                </Text>
                <Gap size="large" />
            </>
        ),
        width: MODAL_WIDTH,
        onConfirm: onNext,
        onCancel,
        customButtons: ({ onConfirm, onCancel }) => [
            {
                label: t("package.mapping-options.continue"),
                type: "primary",
                onClick: () =>
                    onConfirm({
                        createDataUpload: false,
                        runIMTemplate: false,
                    }),
            },
            {
                label: t("package.mapping-options.create-data-upload"),
                onClick: () =>
                    onConfirm({ createDataUpload: true, runIMTemplate: false }),
            },
            {
                label: t("package.mapping-options.run-im-sftp-template"),
                disabled: instances.length === 0,
                onClick: () =>
                    onConfirm({ createDataUpload: false, runIMTemplate: true }),
            },
            {
                label: t("package.mapping-options.cancel"),
                type: "text",
                onClick: onCancel,
            },
        ],
    });
};
