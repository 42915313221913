import NewConnectionModal from "@/components/Connections/NewConnection.modal";
import { Button, Forms } from "@/components/DesignSystem";
import { useIncludesRightsForPartition } from "@/components/hooks/useIncludesRightsForPartition.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import {
    hasValue,
    LoadablePropType,
    pendingPromise,
    useMapLoadableMemoized,
} from "@/modules/loadable";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React from "react";

const { useForm, Fields, Form, pmValidators } = Forms;

const checkValidation = async validationLoadable =>
    hasValue(validationLoadable)
        ? validationLoadable.contents
        : pendingPromise();

export const IntegrationInitForm = ({
    instanceId,
    defaultName,
    validationLoadable,
    onChange,
    handleConnectionsChange,
    connectionsResource,
    newConnectionModal,
}) => {
    function handleNameChange({ value }) {
        onChange(value);
    }

    const { formId, setValues, setTouched } = useForm();

    useSetValidatedInitialValues(
        { initialValues: { name: defaultName }, setValues, setTouched },
        [defaultName],
    );

    const connectionsOptionsLoadable = useMapLoadableMemoized(
        connectionsResource.loadable,
        connections =>
            connections.map(connection => ({
                label: connection.name,
                value: connection.id,
            })),
    );

    const hasEditRights = useIncludesRightsForPartition(
        INTEGRATION_EDIT_PERMISSIONS,
    );

    return (
        <>
            <Form formId={formId}>
                <Fields.Input
                    name="name"
                    label={t("integration-templates.init.form.name")}
                    required
                    validator={Forms.validators.failOnFirst([
                        pmValidators.isRequired,
                        () => checkValidation(validationLoadable),
                    ])}
                    autoComplete="off"
                    onChange={debounce(handleNameChange, 750)}
                />
                <Fields.Select
                    label={t("integration-templates.init.form.connection")}
                    name="connection"
                    required
                    validator={pmValidators.isRequired}
                    allowClear={false}
                    onChange={handleConnectionsChange}
                    {...getLoadableSelectProps(connectionsOptionsLoadable)}
                />
                {hasEditRights && (
                    <Button
                        size="small"
                        icon={Plus}
                        label={t("connection-list.button.new")}
                        onClick={newConnectionModal.show}
                    />
                )}
            </Form>
            <NewConnectionModal
                instanceId={instanceId}
                visible={newConnectionModal.visible}
                onSave={({ deployedConnectionId }) => {
                    connectionsResource.reload();
                    newConnectionModal.hide();
                    // TODO: chain reload and selection
                    // setValues({ connection: deployedConnectionId });
                    // handleConnectionsChange({ value: deployedConnectionId });
                }}
                onCancel={newConnectionModal.hide}
            />
        </>
    );
};

IntegrationInitForm.propTypes = {
    defaultName: PropTypes.string.isRequired,
    validationLoadable: LoadablePropType(
        PropTypes.shape({
            valid: PropTypes.bool,
            message: PropTypes.string,
        }),
    ),
    instanceId: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    handleConnectionsChange: PropTypes.func.isRequired,
    connectionsResource: PropTypes.object,
    newConnectionModal: PropTypes.object,
};
