import { Button, ButtonGroup, DatePicker } from "@/components/DesignSystem";
import { t } from "@/translations";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styles from "./DayWeekMonthRange.style.less";

const { RangePicker } = DatePicker;

const tomorrow = moment().add(1, "days").startOf("day");
const today = moment().startOf("day");
const beforeWeek = moment().subtract(1, "weeks").startOf("day");
const beforeMonth = moment().subtract(1, "month").startOf("day");

const knownDateRange = (from, to) => {
    if (!tomorrow.isSame(to, "minutes")) return null;
    if (today.isSame(from, "minutes")) return 1;
    if (beforeWeek.isSame(from, "minutes")) return 2;
    if (beforeMonth.isSame(from, "minutes")) return 3;
};

const DayWeekMonthRange = ({ from, to, onDateRangeChange }) => {
    const [selected, setSelected] = useState(knownDateRange(from, to));

    const onRadioBtnClick = radioButtonIndex => {
        if (selected !== radioButtonIndex) {
            let from = null;
            const to = tomorrow;
            switch (radioButtonIndex) {
                case 2:
                    from = beforeWeek;
                    break;
                case 3:
                    from = beforeMonth;
                    break;
                default:
                    from = moment();
            }

            setSelected(radioButtonIndex);
            onDateRangeChange(from.startOf("day"), to.startOf("day"));
        }
    };

    const applySelectedDateRange = (from, to) => {
        setSelected(null);
        onDateRangeChange(from, to);
    };

    const getButtonType = radioButtonIndex =>
        selected === radioButtonIndex ? "primary" : "ghost";

    return (
        <>
            <ButtonGroup spacing="none" className={styles.wrapper}>
                <Button
                    htmlType="button"
                    type={getButtonType(1)}
                    onClick={() => onRadioBtnClick(1)}
                    data-test="range-button-day"
                    label={t("health-chart.filter.day")}
                />
                <Button
                    htmlType="button"
                    type={getButtonType(2)}
                    onClick={() => onRadioBtnClick(2)}
                    data-test="range-button-week"
                    label={t("health-chart.filter.week")}
                />
                <Button
                    htmlType="button"
                    type={getButtonType(3)}
                    onClick={() => onRadioBtnClick(3)}
                    data-test="range-button-month"
                    label={t("health-chart.filter.month")}
                />
                <RangePicker
                    size="default"
                    allowClear={false}
                    showTime={{ format: "HH:mm" }}
                    format="DD/MM/YYYY HH:mm"
                    value={[from, to]}
                    onChange={([from, to]) => applySelectedDateRange(from, to)}
                    className={styles.rangePicker}
                />
            </ButtonGroup>
        </>
    );
};

DayWeekMonthRange.propTypes = {
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    onDateRangeChange: PropTypes.func.isRequired,
};

export default DayWeekMonthRange;
