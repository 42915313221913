import ActivityLogAdministrationList from "@/components/ActivityLogAdministration/ActivityLogAdministrationList";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import React from "react";

export function ActivityLogPage() {
    useFullWidthTableLayout();
    useHideBackButton();

    return <ActivityLogAdministrationList />;
}
