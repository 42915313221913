import { StopIntegrationsModal } from "@/components/CustomerPartitions/components/StopIntegrations.modal";
import {
    useClustersOptionsQuery,
    useCopyPartitionResource,
    useSalesforceContactsOptionsQuery,
} from "@/components/CustomerPartitions/loadables";
import { useClusterPartitionNameValidator } from "@/components/CustomerPartitions/validators";
import {
    ButtonMenu,
    Forms,
    Gap,
    Label,
    Link,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useValueVisibility } from "@/components/hooks/useValueVisibility.hook";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { getCopyPartitionTrackName as getTrackName } from "../../../mixpanel/buttonNames";
const { useForm, Form, Fields, pmValidators } = Forms;

const CopyPartitionModal = ({
    visible = false,
    accountId,
    sourceCluster,
    sourceClusterLink,
    partitionId,
    serialNumber,
    onClose,
    onSubmit,
}) => {
    const { locationRouterService, accountAppLocations } = useDic();

    const { formId, handleSubmit } = useForm({
        onSubmit: ({ values }) => {
            onSubmit(values);
        },
    });

    const salesforceContactsOptionsResource =
        useSalesforceContactsOptionsQuery(partitionId);
    const clustersOptionsQueryResource = useClustersOptionsQuery(partitionId);

    const clusterName = useFieldValue({ formId, name: "clusterName" });

    const partitionNameValidator = useClusterPartitionNameValidator({
        partitionId,
        clusterName,
    });

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("copy-partition.form.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Text>{t("copy-partition.form.description")}</Text>
                    <Gap size="medium" />
                    <Form formId={formId} onSubmit={handleSubmit}>
                        <Label
                            label={t("partition-move.source-cluster.label")}
                        />
                        <Link href={sourceClusterLink} targetBlank>
                            {sourceCluster}
                        </Link>
                        <Gap />

                        <Label
                            label={t("copy-partition.sourcePartition.label")}
                        />
                        <Link
                            onClick={() =>
                                locationRouterService.navigate(
                                    accountAppLocations.partitionOverviewLocation,
                                    { accountId, partitionId },
                                )
                            }
                        >
                            {serialNumber}
                        </Link>
                        <Fields.Select
                            required
                            label={t("copy-partition.destinationCluster.label")}
                            name="clusterName"
                            validator={pmValidators.isRequired}
                            tooltip={t(
                                "copy-partition.destinationCluster.tooltip",
                            )}
                            inputWidth="max"
                            {...getLoadableSelectProps(
                                clustersOptionsQueryResource.loadable,
                            )}
                        />

                        <Fields.Input
                            label={t("copy-partition.form.partitionName.label")}
                            placeholder={t(
                                "copy-partition.form.partitionName.placeholder",
                            )}
                            required
                            name="partitionName"
                            inputWidth="max"
                            validator={partitionNameValidator}
                            tooltip={t("partition-request.tooltip.name")}
                        />
                        <Fields.Input
                            required
                            label={t(
                                "copy-partition.form.partitionLabel.label",
                            )}
                            placeholder={t(
                                "copy-partition.form.partitionLabel.placeholder",
                            )}
                            name="partitionLabel"
                            inputWidth="max"
                            validator={pmValidators.isRequired}
                            tooltip={t("partition-request.tooltip.label")}
                        />
                        <Fields.TextArea
                            label={t("copy-partition.form.description.label")}
                            placeholder={t(
                                "copy-partition.form.description.placeholder",
                            )}
                            name="description"
                            rows="3"
                            inputWidth="max"
                        />
                        <Fields.Select
                            required
                            label={t(
                                "copy-partition.form.salesforce-contact.label",
                            )}
                            name="contactId"
                            validator={pmValidators.isRequired}
                            inputWidth="max"
                            {...getLoadableSelectProps(
                                salesforceContactsOptionsResource.loadable,
                            )}
                        />
                    </Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    formId,
                                    label: t("general.copy"),
                                    type: "primary",
                                    track: { name: getTrackName("Copy") },
                                },

                                {
                                    label: t("general.cancel"),
                                    type: "text",
                                    onClick: onClose,
                                    track: { name: getTrackName("Cancel") },
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

CopyPartitionModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    partitionId: PropTypes.number.isRequired,
    serialNumber: PropTypes.string.isRequired,
    visible: PropTypes.bool,
};

export const CopyPartitionModalFlow = ({
    visible,
    sourceCluster,
    sourceClusterLink,
    accountId,
    partitionId,
    serialNumber,
    onClose,
    onSuccess,
}) => {
    const mutation = useCopyPartitionResource({
        partitionId,
        afterSuccess: onSuccess,
    });
    const formModal = useValueVisibility(true);
    const stopModal = useValueVisibility();
    const onSubmit = useCallback(
        values => {
            stopModal.show(values);
            formModal.hide();
        },
        [formModal, stopModal],
    );
    const onConfirm = useCallback(() => {
        mutation.mutate(stopModal.value);
    }, [mutation, stopModal]);
    const onCloseInner = useCallback(() => {
        formModal.hide();
        stopModal.hide();
        onClose();
    }, [formModal, onClose, stopModal]);

    return (
        <>
            <CopyPartitionModal
                visible={visible && formModal.visible}
                sourceCluster={sourceCluster}
                sourceClusterLink={sourceClusterLink}
                accountId={accountId}
                partitionId={partitionId}
                serialNumber={serialNumber}
                onClose={onCloseInner}
                onSubmit={onSubmit}
            />
            <StopIntegrationsModal
                visible={visible && stopModal.visible}
                partitionId={partitionId}
                onClose={onCloseInner}
                disabled={isLoading(mutation)}
                confirmProps={{
                    onClick: onConfirm,
                }}
            />
        </>
    );
};
