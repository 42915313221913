import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useCenteredLayout } from "@/components/hooks/useContentLayout.hook";
import { LoadableRenderer, useQueryLoadable } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { upgradeSchedulingService } from "@/services/upgradeSchedulingService";
import { t } from "@/translations";
import {
    transformIanaToWindows,
    trimTimezone,
} from "@/utils/timezone/timezone.utils";
import moment from "moment";
import React, { useCallback, useEffect, useRef } from "react";
import { useRoute } from "react-router5";
import UpgradeScheduling from "./UpgradeScheduling";
import { clusterUpgradeTypes } from "./constants";
import { DATETIME_WITHOUT_OFFSET } from "./date-formats";

const mapTimeslotFromInitialValues = initialValues => {
    const timeslot = {
        versionFrom: initialValues.currentVersion,
        versionTo: initialValues.targetVersion,
        start: {
            dateTime: moment(initialValues.upgradeDate).format(
                DATETIME_WITHOUT_OFFSET,
            ),
            timeZone: transformIanaToWindows(initialValues.timezone),
        },

        eligibleAttendee: initialValues.responsiblePersonUsername,
        eligibleAttendees: [initialValues.responsiblePersonUsername],
    };

    return initialValues.type === clusterUpgradeTypes.AUTOMATED
        ? timeslot
        : {
              ...timeslot,
              ...{
                  end: {
                      dateTime: moment(initialValues.upgradeDate)
                          .add(moment.duration(initialValues.duration))
                          .format(DATETIME_WITHOUT_OFFSET),
                      timeZone: transformIanaToWindows(initialValues.timezone),
                  },
              },
          };
};

const mapInitialValuesFromEdit = initialValues => ({
    cluster: initialValues.cluster,
    snapshot: initialValues.targetVersion,
    upgradeDate: moment(trimTimezone(initialValues.upgradeDate)),
    upgradeTime: moment(trimTimezone(initialValues.upgradeDate)),
    timezone: initialValues.timezone,
    note: initialValues.note,
    additionalAttendees: initialValues.additionalAttendees,
    timeslot: mapTimeslotFromInitialValues(initialValues),
    type: initialValues.type,
});

export function UpgradePage() {
    useCenteredLayout();

    const {
        locationRouterService,
        accountAppLocations: { upgradesLocation },
    } = useDic();

    const { accountId } = useAccountAppParams();

    const navigateToClusterListLocation = useCallback(
        () =>
            locationRouterService.navigate(upgradesLocation, {
                accountId,
            }),
        [accountId, locationRouterService, upgradesLocation],
    );

    useSetBackButtonAction(navigateToClusterListLocation);

    const routeContext = useRoute();
    const eventId = routeContext.route?.params?.eventId;

    //redirect to account upgrades list when editing and account is changed
    const currentAccountId = useRef();
    useEffect(() => {
        if (!currentAccountId.current) {
            currentAccountId.current = accountId;
            return;
        }
        eventId &&
            currentAccountId.current !== accountId &&
            navigateToClusterListLocation();
    }, [accountId]);

    const loadedEvent = useQueryLoadable(async () => {
        if (!eventId) return new Promise(resolve => resolve({}));
        return upgradeSchedulingService.loadEvent(accountId, eventId);
    }, [eventId, accountId]);

    const editProps = initialValues =>
        eventId
            ? {
                  initialValues: mapInitialValuesFromEdit(initialValues.data),
                  eventId: initialValues.data.eventId,
              }
            : {};
    useSetPageTitle(t("location.accountApp.upgrades.new"));
    return (
        <LoadableRenderer
            loadable={loadedEvent.loadable}
            hasValue={initialValues => {
                return (
                    <UpgradeScheduling
                        accountId={accountId}
                        navigateToClusterListLocation={
                            navigateToClusterListLocation
                        }
                        {...editProps(initialValues)}
                    />
                );
            }}
        />
    );
}
