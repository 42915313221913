import {
    ButtonMenu,
    Gap,
    Input,
    Modal,
    Select,
    UnityLayout,
} from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import { T, t } from "@/translations";
import uniq from "lodash/uniq";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { TableLocalFiltered } from "../TableLocalFiltered";
import styles from "./PickUsersModal.styles.less";
import { createOptions_tempFixDeselect } from "@/components/DesignSystem/Forms/fields";

const columns = [
    {
        type: fieldTypes.TEXT,
        label: t("account-admin-users-list.header.username"),
        name: "username",
    },
    {
        type: fieldTypes.TEXT,
        label: t("account-admin-users-list.header.email"),
        name: "email",
    },
    {
        type: fieldTypes.TEXT,
        label: t("account-admin-users-list.header.job-title"),
        name: "jobTitle",
    },
];
/**
 * @deprecated Use Forms.Fields.EntityIdsPickerTable instead
 */
export const PickUsersModal = ({
    visible,
    onOk,
    onCancel,
    title,
    description,
    hideInvite,
    inviteMessage = (
        <T id="account-admin-group-add-user.modal.invite-message" />
    ),
    users,
}) => {
    const [inviteUsernames, setInviteUsernames] = useState([]);
    const [selectedUsernames, setSelectedUsernames] = useState([]);

    useEffect(() => {
        setInviteUsernames([]);
        setSelectedUsernames([]);
    }, [visible]);

    const onOkClick = () => {
        const allUsernames = uniq(inviteUsernames.concat(selectedUsernames));
        onOk(
            allUsernames,
            users.filter(({ username }) =>
                selectedUsernames.includes(username),
            ),
            inviteUsernames,
        );
    };
    const rowSelection = useSyncRowSelection(
        selectedUsernames,
        setSelectedUsernames,
    );
    const disabled = !selectedUsernames.length && !inviteUsernames.length;
    const options = useMemo(
        () => createOptions_tempFixDeselect(inviteUsernames),
        [inviteUsernames],
    );

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header size={3} title={title} />
                <UnityLayout.Content padding={[false, true]}>
                    {description}
                    <Gap />
                    <TableLocalFiltered
                        className={styles.groupTable}
                        columns={columns}
                        dataSource={users}
                        rowKey="username"
                        rowSelection={rowSelection}
                        customHeight={{ allowShrink: false, height: 300 }}
                    />
                    <Gap size="small" />
                    {!hideInvite && (
                        <>
                            {inviteMessage}
                            <Select
                                mode="tags"
                                style={{ width: "100%" }}
                                onChange={setInviteUsernames}
                                value={inviteUsernames}
                                options={options}
                                dataTest="username-dropdown"
                            />
                            {/* TODO: this is dummy Input placed here as a workaround for the issue
                             when keystrokes on the last element are always captured.
                             This could be caused by TableLocalFiltered above and should be investigated  */}
                            <Input
                                size="small"
                                style={{ visibility: "hidden" }}
                            />
                        </>
                    )}
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.add"),
                                    onClick: onOkClick,
                                    type: "primary",
                                    disabled,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onCancel,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

PickUsersModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    title: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
    inviteMessage: PropTypes.node,
    hideInvite: PropTypes.bool,
};
