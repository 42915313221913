import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { message } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { VisibleWhenHasPermission } from "@/security/authorization";
import {
    ACCOUNTS_ADMIN_EVENTS,
    ACCOUNT_EVENTS_JOBS_EDIT_PERMISSIONS,
    includesPermissionInAssetTree,
} from "@/security/permission.utils";
import { t } from "@/translations";
import { ReactComponent as IconCog } from "@pricefx/unity-components/src/icons/unicons/cog.svg";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { EventConfiguratorModal } from "../CustomerPartitions/EventConfigurator/EventConfiguratorModal";
import { useFetchPage } from "../PagableTable/useFetchPage.hook";
import { useRefreshAction } from "../PageLayout/useRefreshAction.hook";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { useAlertEffect } from "@/components/PageLayout/useAlertEffect.hook";
import { getErrorMessageFromError } from "@/utils/state/error.utils";

const columns = [
    {
        name: "lastUpdateDate",
        label: t("partition.event-admin.list.last-update"),
        type: fieldTypes.DATETIME,
        render: (text, record) =>
            record.lastUpdateDate && (
                <FormattedDateTime utcStr={record.lastUpdateDate} />
            ),
    },
    {
        name: "createDate",
        label: t("partition.event-admin.list.created"),
        type: fieldTypes.DATETIME,
        render: (text, record) =>
            record.createDate && (
                <FormattedDateTime utcStr={record.createDate} />
            ),
    },
    {
        name: "name",
        label: t("partition.event-admin.list.name"),
        type: fieldTypes.TEXT,
    },
    {
        name: "message",
        label: t("partition.event-admin.list.messages"),
        type: fieldTypes.TEXT,
    },
    {
        name: "retriesLeft",
        label: t("partition.event-admin.list.retries-left"),
        type: fieldTypes.INTEGER,
    },
    {
        name: "httpResponse",
        label: t("partition.event-admin.list.response"),
        type: fieldTypes.INTEGER,
    },
    {
        name: "errorMessage",
        label: t("partition.event-admin.list.error-message"),
        type: fieldTypes.TEXT,
    },
];

const preferencesType = preferencesTypes.PARTITION_EVENT_ADMIN_LIST_TABLE;

const PartitionEventAdminList = ({ accountId, partitionId, restHeight }) => {
    const { projectPartitionApiService } = useDic();
    const eventConfiguratorModal = useVisibility();
    const breadcrumbButtons = {
        label: "partition.event-admin.button.even-configurator",
        onClick: eventConfiguratorModal.show,
        type: "default",
        icon: IconCog,
        permissions: ACCOUNTS_ADMIN_EVENTS,
        permissionsFunc: includesPermissionInAssetTree,
    };
    useBreadcrumbButton(breadcrumbButtons);

    const [recordUpdated, setRecordUpdated] = useState(false);
    const [error, setError] = useState();

    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort = "createDate,desc", filter) =>
            projectPartitionApiService
                .getEventAdminRecords(
                    accountId,
                    partitionId,
                    page,
                    size,
                    sort,
                    filter,
                )
                .catch(e => {
                    setError(e);
                }),
        [recordUpdated, accountId, partitionId],
    );

    useAlertEffect(error, () => ({
        type: "error",
        message: getErrorMessageFromError(error) || "Partition not available",
    }));

    useRefreshAction(reload);

    const rerunEvent = record => {
        projectPartitionApiService
            .rerunEventAdminRecord(accountId, partitionId, record)
            .then(() => {
                message.success(
                    t("partition.event-admin.list.rerun-event.success"),
                );
                setRecordUpdated(!recordUpdated);
            })
            .catch(() =>
                message.error(t("partition.event-admin.list.rerun-event.fail")),
            );
    };

    const actionMenu = useCallback(
        record => (
            <VisibleWhenHasPermission
                permission={ACCOUNT_EVENTS_JOBS_EDIT_PERMISSIONS}
            >
                {record.retriesLeft === 0 && (
                    <ActionButton
                        record={record}
                        items={[
                            {
                                title: t(
                                    "partition.event-admin.list.rerun-event",
                                ),
                                onClick: () => rerunEvent(record),
                            },
                        ]}
                    />
                )}
            </VisibleWhenHasPermission>
        ),
        [accountId, partitionId],
    );

    const afterSave = useCallback(() => {
        eventConfiguratorModal.hide();
    }, []);

    return (
        <>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                columns={columns}
                fixed
                restHeight={restHeight}
                rowKey="id"
                datasetSlicing="server"
                preferencesType={preferencesType}
                {...tableProps}
            />
            <EventConfiguratorModal
                visible={eventConfiguratorModal.visible}
                onClose={eventConfiguratorModal.hide}
                afterSave={afterSave}
                projectId={accountId}
                partitionId={partitionId}
            />
        </>
    );
};

PartitionEventAdminList.propTypes = {
    accountId: PropTypes.number,
    partitionId: PropTypes.number,
};

export default PartitionEventAdminList;
