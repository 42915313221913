import {
    Button,
    ButtonMenu,
    Forms,
    Gap,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { DeleteRowButton } from "@/components/Mappers/MapperTableWithCustomization/components/DeleteRowButton";
import {
    usePmFeatureFlagsEntitiesQuery,
    usePmFeatureFlagsRestrictionsQuery,
} from "@/components/PmFeatureFlags/loadables";
import {
    mapEntitiesQueryToTree,
    mapInitialRestrictions,
    valueFromTreeMultipleSelect,
    valueFromTreeSelect,
    valueToTreeMultipleSelect,
    valueToTreeSelect,
} from "@/components/PmFeatureFlags/utils";
import { LoadableRenderer, isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import { TreeSelect as AntdTreeSelect } from "antd";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

const COLUMN_WIDTH = [140, 255, 318, 32];

const LAYOUT = [
    // { flex: "0 0 19%" },
    // { flex: "0 0 32%" },
    // { flex: "0 0 40%" },
    { width: COLUMN_WIDTH[0] },
    { width: COLUMN_WIDTH[1] },
    { width: COLUMN_WIDTH[2] },
    { width: COLUMN_WIDTH[3] },
];

const SECONDARY_TYPES = {
    USER: "ACCOUNT",
    ADMIN_GROUP: "ACCOUNT",
    ACCOUNT: "USER_AND_USER_GROUP",
};

const RestrictionRow = ({
    id,
    fieldProps,
    remove,
    formId,
    setValues,
    setTouched,
}) => {
    const [primarySearchString, setPrimarySearchString] = useState();
    const [secondarySearchString, setSecondarySearchString] = useState();

    const mainType = Forms.useFieldValue({
        formId,
        name: fieldProps("mainType").name,
    });
    const primary = Forms.useFieldValue({
        formId,
        name: fieldProps("primary").name,
    });

    const primaryEntitiesQuery = usePmFeatureFlagsEntitiesQuery({
        type: mainType,
        name: primarySearchString,
    });

    const isPrimaryOfTypeAccount = primary?.type === "ACCOUNT";

    const secondaryEntitiesQuery = usePmFeatureFlagsEntitiesQuery({
        type: primary ? SECONDARY_TYPES[primary?.type] : null,
        accountId: isPrimaryOfTypeAccount ? primary?.id : null,
        name: secondarySearchString,
    });

    const resetPrimaryRestrictionField = () => {
        const primaryFiledName = fieldProps("primary").name;
        setValues({ [primaryFiledName]: undefined });
        setTouched({ [primaryFiledName]: false });
        setPrimarySearchString();
        resetSecondaryRestrictionField();
    };

    const resetSecondaryRestrictionField = () => {
        const secondaryFiledName = fieldProps("secondary").name;
        setValues({ [secondaryFiledName]: undefined });
        setTouched({ [secondaryFiledName]: false });
        setSecondarySearchString();
    };

    return (
        <Forms.FieldGrid.Row>
            <Forms.Fields.Select
                {...fieldProps("mainType")}
                label={t("pm-feature-flags.restrictions-modal.label.main-type")}
                options={[
                    {
                        label: t(
                            "pm-feature-flags.restrictions-modal.form.type.account",
                        ),
                        value: "ACCOUNT",
                    },
                    {
                        label: t(
                            "pm-feature-flags.restrictions-modal.form.type.user",
                        ),
                        value: "USER",
                    },
                    {
                        label: t(
                            "pm-feature-flags.restrictions-modal.form.type.user-group",
                        ),
                        value: "ADMIN_GROUP",
                    },
                ]}
                validator={Forms.pmValidators.isRequired}
                showSearch={false}
                allowClear={false}
                onChange={resetPrimaryRestrictionField}
                style={{ maxWidth: COLUMN_WIDTH[0] }}
            />
            <Forms.Field
                as={AntdTreeSelect}
                {...fieldProps("primary")}
                to={valueToTreeSelect}
                from={valueFromTreeSelect}
                labelInValue
                label={t("pm-feature-flags.restrictions-modal.label.primary")}
                treeData={mapEntitiesQueryToTree({
                    query: primaryEntitiesQuery,
                })}
                loading={isLoading(primaryEntitiesQuery)}
                showSearch
                treeNodeFilterProp={"title"}
                onSearch={debounce(value => setPrimarySearchString(value), 750)}
                onChange={resetSecondaryRestrictionField}
                validator={Forms.pmValidators.isRequired}
                style={{ maxWidth: COLUMN_WIDTH[1] }}
            />
            <Forms.Field
                as={AntdTreeSelect}
                {...fieldProps("secondary")}
                to={valueToTreeMultipleSelect}
                from={valueFromTreeMultipleSelect}
                labelInValue
                label={t("pm-feature-flags.restrictions-modal.label.secondary")}
                treeData={mapEntitiesQueryToTree({
                    query: secondaryEntitiesQuery,
                    isUserAndGroup: isPrimaryOfTypeAccount,
                })}
                multiple
                loading={isLoading(secondaryEntitiesQuery)}
                showSearch
                treeNodeFilterProp={"title"}
                treeDefaultExpandAll
                onSearch={debounce(
                    value => setSecondarySearchString(value),
                    750,
                )}
                style={{ maxWidth: COLUMN_WIDTH[2] }}
            />

            <Forms.UIField name="actions">
                <DeleteRowButton onClick={() => remove(id)} />
            </Forms.UIField>
        </Forms.FieldGrid.Row>
    );
};

const PmFeatureFlagRestrictionsModal = ({
    featureFlagName,
    modalProviderProps,
    onSave,
}) => {
    const pmFeatureFlagsRestrictionsQuery = usePmFeatureFlagsRestrictionsQuery({
        featureFlagName,
    });

    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit: ({ values }) => {
            const restrictions = values.restrictions.map(restriction => ({
                ...restriction,
                primary: [restriction.primary],
            }));
            onSave({ featureFlagName, restrictions });
        },
    });

    return (
        <Modal {...modalProviderProps} width={832} maskClosable={false}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("pm-feature-flags.button.restrictions")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Forms.Form formId={formId} onSubmit={handleSubmit}>
                        <LoadableRenderer
                            loadable={pmFeatureFlagsRestrictionsQuery.loadable}
                            hasValue={({ restrictions }) => {
                                return (
                                    <Forms.FieldGrid layout={LAYOUT}>
                                        <Forms.List
                                            formId={formId}
                                            name={"restrictions"}
                                            initialValue={mapInitialRestrictions(
                                                restrictions,
                                            )}
                                        >
                                            {({ rows, add, remove }) => (
                                                <>
                                                    {rows.map(
                                                        ({
                                                            id,
                                                            fieldProps,
                                                        }) => (
                                                            <RestrictionRow
                                                                key={id}
                                                                id={id}
                                                                fieldProps={
                                                                    fieldProps
                                                                }
                                                                formId={formId}
                                                                remove={remove}
                                                                setValues={
                                                                    setValues
                                                                }
                                                                setTouched={
                                                                    setTouched
                                                                }
                                                            />
                                                        ),
                                                    )}
                                                    <Gap size="small" />
                                                    <Button
                                                        size="small"
                                                        onClick={() => add()}
                                                        icon={Plus}
                                                        label={t(
                                                            "pm-feature-flags.restrictions-modal.form.button.add-new",
                                                        )}
                                                        style={{
                                                            marginLeft: "12px",
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Forms.List>
                                    </Forms.FieldGrid>
                                );
                            }}
                        />
                    </Forms.Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.save"),
                                    formId,
                                    type: "primary",
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: modalProviderProps.onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

PmFeatureFlagRestrictionsModal.propTypes = {
    featureFlagName: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    modalProviderProps: PropTypes.object,
};

export default PmFeatureFlagRestrictionsModal;
