import { Menu } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { SidebarAndContentLayout } from "@/components/Layouts";
import NotificationsList from "@/components/Notifications/NotificationsList.component";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { t } from "@/translations";
import React from "react";

const NotificationsPage = () => {
    const {
        locationRouterService,
        locations: { notificationsLocation },
    } = useDic();

    useFullWidthTableLayout();
    useSetPageTitle(t(`notifications.menu.title`));

    return (
        <SidebarAndContentLayout
            sidebar={
                <Menu selectedKeys={["notifications"]} mode="inline" condensed>
                    <Menu.Item
                        key={"notifications"}
                        onClick={() => {
                            locationRouterService.navigate(
                                notificationsLocation,
                            );
                        }}
                    >
                        {t("notifications.menu.title")}
                    </Menu.Item>
                </Menu>
            }
        >
            <NotificationsList />
        </SidebarAndContentLayout>
    );
};

export default NotificationsPage;
