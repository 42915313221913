import { OfferEditForm } from "@/components/Offers/OfferEditForm.container";
import { useMarketplaceAppParams } from "@/modules/router/hooks/useMarketplaceAppParams.hook";
import React from "react";

export const OfferEditPage = () => {
    const { accountId, offerId } = useMarketplaceAppParams();

    return <OfferEditForm offerId={offerId} accountId={accountId} />;
};

OfferEditPage.propTypes = {};
