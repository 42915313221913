import {
    ButtonMenu,
    Checkbox,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const SelectColumnsDialog = ({
    selectedColumns: inputSelectedColumns,
    availableColumns,
    visible,
    onConfirm,
    onClose,
}) => {
    const [selectedColumns, setSelectedColumns] =
        useState(inputSelectedColumns);

    useEffect(() => {
        setSelectedColumns(inputSelectedColumns);
    }, [inputSelectedColumns]);

    const handleOnClick = event => {
        event.preventDefault();
        onConfirm(selectedColumns);
    };

    const handleSelectColumn = (value, checked) => {
        const sc = Object.assign({}, selectedColumns);
        sc[value] = checked;
        setSelectedColumns(sc);
    };

    // TODO: both buttons doing the same
    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("select-columns.modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    {availableColumns.map(column => (
                        <Checkbox
                            key={column.value}
                            defaultChecked={selectedColumns[column.value]}
                            onClick={e =>
                                handleSelectColumn(
                                    column.value,
                                    e.target.checked,
                                )
                            }
                            label={column.value}
                        />
                    ))}
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.ok"),
                                    onClick: handleOnClick,
                                    type: "primary",
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

SelectColumnsDialog.propTypes = {
    availableColumns: PropTypes.array.isRequired,
    visible: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedColumns: PropTypes.object.isRequired,
};

export default SelectColumnsDialog;
