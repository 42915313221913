import { Button } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import {
    LoadableRenderer,
    useQueryLoadable,
    waitForValue,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import Markdown from "markdown-to-jsx";
import PropTypes from "prop-types";
import React from "react";

const RouteDocumentation = ({ accountId, instanceId, routeId }) => {
    const {
        locationRouterService,
        routesService,
        accountAppLocations: { integrationsLocation },
    } = useDic();

    const routeDocumentationResource = useQueryLoadable(
        () =>
            isNaN(instanceId) && !routeId
                ? waitForValue()
                : routesService
                      .fetchRouteDocumentation(instanceId, routeId)
                      .then(getData),
        [routesService, instanceId, routeId],
    );

    useSetPageTitle(`Route's ${routeId} documentation`);

    return (
        <>
            <LoadableRenderer
                loadable={routeDocumentationResource.loadable}
                hasValue={documentation => (
                    <Markdown>{documentation || ""}</Markdown>
                )}
            />
            <Button
                htmlType="button"
                color="danger"
                onClick={() => {
                    locationRouterService.navigate(integrationsLocation, {
                        instanceId,
                        accountId,
                    });
                }}
                label={t("general.back")}
            />
        </>
    );
};

RouteDocumentation.propTypes = {
    accountId: PropTypes.number.isRequired,
    instanceId: PropTypes.number.isRequired,
    routeId: PropTypes.string.isRequired,
};

export default RouteDocumentation;
