import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { preferencesTypes } from "../../../../constants/preferencesTypes.constants";
import { templatesManagementColumns } from "./TemplatesManagementList.columns";

const preferencesType = preferencesTypes.TEMPLATES_MANAGEMENT_LIST_TABLE;

const TemplatesManagementList = ({
    onSetPermissions,
    refetchTemplatesToken,
}) => {
    const {
        locationRouterService,
        marketplaceAppLocations: {
            templateManagementDetailLocation,
            templateManagementEditLocation,
        },
        templateAdminService,
    } = useDic();

    const [tableProps] = useFetchPage(
        (page, size, sort, filter) =>
            templateAdminService.fetchTemplates(page, size, sort, filter),
        [refetchTemplatesToken],
    );

    const tableColumns = useMemo(() => templatesManagementColumns(), []);

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.show-detail"),
                        onClick: record =>
                            locationRouterService.navigate(
                                templateManagementDetailLocation,
                                {
                                    uniqueName: record.uniqueName,
                                },
                            ),
                    },
                    {
                        title: t("general.edit"),
                        onClick: record =>
                            locationRouterService.navigate(
                                templateManagementEditLocation,
                                {
                                    uniqueName: record.uniqueName,
                                },
                            ),
                    },
                    {
                        title: t("general.permissions"),
                        onClick: onSetPermissions,
                    },
                ]}
            />
        ),
        [
            locationRouterService,
            onSetPermissions,
            templateManagementDetailLocation,
            templateManagementEditLocation,
        ],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            defaultSort={{
                fieldName: "label",
                sortDir: "ascending",
            }}
            fixed
            rowKey="uniqueName"
            columns={tableColumns}
            datasetSlicing="server"
            preferencesType={preferencesType}
            exportUrl="api/admin/templates/export"
            {...tableProps}
        />
    );
};

TemplatesManagementList.propTypes = {
    onSetPermissions: PropTypes.func.isRequired,
    refetchTemplatesToken: PropTypes.any,
};

export default TemplatesManagementList;
