import { CodeMirror } from "@/components/DesignSystem/CodeMirror/";
import React, { useEffect, useState } from "react";

const CamelRouteXmlEditor = ({ value, readOnly, onChange }) => {
    const [internalValue, setInternalValue] = useState(value);

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    return (
        <CodeMirror
            mode="xml"
            value={internalValue}
            readOnly={readOnly}
            onChange={onChange}
        />
    );
};

export default CamelRouteXmlEditor;
