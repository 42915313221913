import { DataObject } from "@/utils/dataObjects/DataObject.class";
import { PermissionEntity } from "./PermissionEntity.class";

export class BusinessRoleEntity extends DataObject {
    /**
     *
     * @type {number}
     */
    id = null;
    /**
     *
     * @type {string}
     */
    name = null;
    /**
     *
     * @type {string}
     */
    description = null;

    /**
     *
     * @type {number}
     */
    project = null;

    /**
     *
     * @type {PermissionEntity[]}
     */
    permissions = null;
}
