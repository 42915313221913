import { usePartitionQuery } from "@/components/CustomerPartitions/loadables";
import PartitionRequestContainer from "@/components/PartitionRequest/PartitionRequestContainer";
import { LoadableRenderer } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React from "react";

export function EditPartitionPage() {
    const { accountId, partitionId } = useAccountAppParams();
    const query = usePartitionQuery({ accountId, partitionId });

    return (
        <LoadableRenderer
            loadable={query.loadable}
            hasValue={data => (
                <PartitionRequestContainer
                    projectId={accountId}
                    partitionId={partitionId}
                    initialValues={data}
                />
            )}
        />
    );
}
