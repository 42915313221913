import { useDic } from "@/components/Dic/useDic.hook";
import { useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useIntegrationsDeploymentsQuery = ({
    accountId,
    integrationId,
}) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            axiosService
                .post(
                    `/api/audit-logs/projects/${accountId}/integrations/${integrationId}`,
                    {
                        sort: [{ id: "time", order: "desc" }],
                    },
                )
                .then(getData),
        [accountId, axiosService, integrationId],
    );
};
