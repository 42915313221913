import { Button, ButtonGroup, Forms, H3, P } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { LoadableRenderer, useQueryLoadable } from "@/modules/loadable";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { transformIanaToWindows } from "@/utils/timezone/timezone.utils";
import moment from "moment";
import React, { useState } from "react";
import uuid from "uuid";
import { preselectedTimezone } from "../TimezoneSelect/TimezoneSelect.component";
import styles from "./CapacityConfiguration.style.less";
import CapacityEntry from "./CapacityEntry";
import {
    DATE,
    DATETIME,
    DATETIME_WITHOUT_OFFSET,
    TIME_HOURS_MINUTES,
} from "./date-formats";

const { useForm, Form, Fields, SubmitButton, success, error, validators } =
    Forms;

export const required = value =>
    value ? success() : error(t("general.validation.required"));

const entryTransform = date => ({
    timeFrom: value =>
        moment(
            date.format(DATE) + " " + value.format(TIME_HOURS_MINUTES),
            DATETIME,
        ).format(DATETIME_WITHOUT_OFFSET),
    timeTo: value => {
        return moment(
            date.format(DATE) + " " + value.format(TIME_HOURS_MINUTES),
            DATETIME,
        ).format(DATETIME_WITHOUT_OFFSET);
    },
    recurrence: value => value || false,
});

const mapEntriesToAvailableTimes = (usedIds, values) => {
    return usedIds.map(id => {
        const transformObject = entryTransform(values[`${id}_date`]);
        return {
            id: Number.isInteger(id) ? id : null,
            windowsTimezone: transformIanaToWindows(values.timezone),
            ianaTimezone: values.timezone,
            ...Object.keys(transformObject).reduce((all, entryName) => {
                return {
                    ...all,
                    [entryName]: transformObject[entryName](
                        values[`${id}_${entryName}`],
                    ),
                };
            }, {}),
        };
    });
};

export const CapacityConfiguration = () => {
    const [usedIds, setUsedIds] = useState([]);
    const { messageService, capacitySchedulingService } = useDic();

    const availableCapacity = useQueryLoadable(async () => {
        const capacity = await capacitySchedulingService
            .getAvailableCapacityTimes()
            .then(response => response.data);

        const itemsIds = capacity.map(c => c.id);
        setUsedIds(itemsIds);

        return capacity.reduce(
            (capacities, capacity) => ({
                ...capacities,
                [capacity.id]: capacity,
            }),
            {},
        );
    }, [capacitySchedulingService]);

    const onSubmit = ({ values }) => {
        capacitySchedulingService
            .updateAvailableCapacityTimes(
                mapEntriesToAvailableTimes(usedIds, values),
            )
            .then(() => {
                messageService.success({
                    content: t("capacity-configuration.saved"),
                });
                availableCapacity.reload();
            })
            .catch(error => {
                messageService.error({
                    content: getErrorMessage(error.response.data),
                });
            });
    };

    const { formId, handleSubmit } = useForm({
        onSubmit,
    });

    return (
        <>
            <H3>{t("capacity-configuration.header")}</H3>
            <P>{t("capacity-configuration.perex")}</P>
            <LoadableRenderer
                loadable={availableCapacity.loadable}
                hasValue={capacities => {
                    const firstSavedCapacity =
                        Object.values(capacities) &&
                        Object.values(capacities)[0];
                    return (
                        <div className="capacityConfiguration">
                            <Form
                                formId={formId}
                                layout="vertical"
                                onSubmit={handleSubmit}
                            >
                                <div className={styles.row}>
                                    <Fields.TimezoneSelect
                                        name="timezone"
                                        initialValue={
                                            (firstSavedCapacity &&
                                                firstSavedCapacity.ianaTimezone) ||
                                            preselectedTimezone
                                        }
                                        label={t(
                                            "capacity-configuration.timezone.label",
                                        )}
                                        placeholder={t(
                                            "placeholder.please-select",
                                        )}
                                        allowClear={false}
                                        inputWidth="max"
                                    />
                                </div>
                                <div className="capacityEntry">
                                    <Forms.FieldGrid
                                        breakpoint={200}
                                        layout={[
                                            { flex: "0 0 14%" },
                                            { flex: "0 0 10%" },
                                            { width: 10 },
                                            { flex: "0 0 10%" },
                                            { width: 150 },
                                            { width: 32 },
                                        ]}
                                    >
                                        {usedIds.map(id => (
                                            <CapacityEntry
                                                key={id}
                                                id={id}
                                                initials={
                                                    capacities[id]
                                                        ? capacities[id]
                                                        : null
                                                }
                                                validators={validators}
                                                onRowRemove={() =>
                                                    setUsedIds(ids =>
                                                        ids.filter(
                                                            entryId =>
                                                                entryId !== id,
                                                        ),
                                                    )
                                                }
                                            />
                                        ))}
                                    </Forms.FieldGrid>
                                </div>
                                <div className={styles.row}>
                                    <Button
                                        type="default"
                                        className={styles.addRowButton}
                                        onClick={() =>
                                            setUsedIds(ids => [...ids, uuid()])
                                        }
                                        label={
                                            "+ " +
                                            t(
                                                "capacity-configuration.add-capacity",
                                            )
                                        }
                                    />
                                </div>
                                <ButtonGroup>
                                    <SubmitButton>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            label={t("general.save")}
                                        />
                                    </SubmitButton>
                                </ButtonGroup>
                            </Form>
                        </div>
                    );
                }}
            />
        </>
    );
};
