import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";

export const assetsColumns = () => [
    {
        label: "name",
        name: "name",
        type: fieldTypes.TEXT,
    },
];

export const partitionColumns = () => [
    {
        label: t("assets-table.partition.name"),
        name: "name",
        type: fieldTypes.TEXT,
    },
    {
        label: t("assets-table.partition.serialNumber"),
        name: "serialNumber",
        type: fieldTypes.TEXT,
    },
    {
        label: t("assets-table.partition.clusterName"),
        name: "clusterName",
        type: fieldTypes.TEXT,
    },
];

export const instanceColumns = () => [
    {
        label: t("account-admin-instance-list.header.name"),
        name: "instanceName",
        type: fieldTypes.TEXT,
    },
    {
        label: t("account-admin-instance-list.header.server"),
        name: "server",
        type: fieldTypes.TEXT,
    },
    {
        label: t("account-admin-instance-list.header.cluster"),
        name: "type",
        type: fieldTypes.TEXT,
    },
];
