import {
    ButtonMenu,
    Drawer,
    Forms,
    Gap,
    H4,
    Tabs,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import {
    isLoading,
    LoadableRenderer,
    useComposeLoadablesMemoized,
    useMapLoadableMemoized,
} from "@/modules/loadable";
import { useMarketplaceAppParams } from "@/modules/router/hooks/useMarketplaceAppParams.hook";
import { T, t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import {
    addUsersColumns,
    createAccountsColumns,
    createUsersPermissionsColumns,
} from "./columns";
import { FullWidthWrapper } from "./FullWidthWrapper.component";
import {
    useProjectUsersQuery,
    useTemplateAccountsQuery,
    useTemplateAvailableAccountsQuery,
    useTemplateUsersQuery,
    useUpdateTemplateMutation,
} from "./loadables";

export const VISIBILITY = {
    PUBLIC: "PUBLIC",
    PROTECTED: "PROTECTED",
};
const VISIBILITY_OPTIONS = [
    {
        label: t("marketplace.template-visibility.visibility.public"),
        value: VISIBILITY.PUBLIC,
    },
    {
        label: t("marketplace.template-visibility.visibility.protected"),
        value: VISIBILITY.PROTECTED,
    },
];

const TABS = {
    USERS: "USERS",
    ACCOUNTS: "ACCOUNTS",
};

const DEFAULT_USER_PERMISSION = "package.view";

const USER_ID_KEY = "username";
const ACCOUNT_ID_KEY = "id";

const addButtonProps = { style: { marginLeft: 16 } };

export const TemplateVisibilityPanel = ({
    visible,
    template,
    onClose,
    onUpdated,
}) => {
    const { accountId } = useMarketplaceAppParams();
    const updateTemplateMutation = useUpdateTemplateMutation({
        uniqueName: template?.uniqueName,
    });
    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit: async ({ values: { visibility, users, accounts } }) => {
            await updateTemplateMutation.mutate({
                visibility,
                accounts,
                users: users.map(uid => ({
                    [USER_ID_KEY]: uid,
                    permissions: permissionsByUid[uid] || [
                        DEFAULT_USER_PERMISSION,
                    ],
                })),
            });
            onUpdated();
        },
    });
    // USERS
    const templateUsersQuery = useTemplateUsersQuery({
        canFetch: !!template,
        uniqueName: template?.uniqueName,
    });
    const projectUsersQuery = useProjectUsersQuery({ accountId });

    // ACCOUNTS
    const templateAccountsQuery = useTemplateAccountsQuery({
        canFetch: !!template,
        uniqueName: template?.uniqueName,
    });
    const templateAvailableAccountsQuery = useTemplateAvailableAccountsQuery({
        canFetch: !!template,
        uniqueName: template?.uniqueName,
    });

    // INIT
    const initialValuesLoadable = useMapLoadableMemoized(
        useComposeLoadablesMemoized([
            templateUsersQuery.loadable,
            templateAccountsQuery.loadable,
        ]),
        useCallback(
            ([templateUsers, templateAccounts]) => ({
                visibility: template?.visibility,
                users: templateUsers.map(user => user[USER_ID_KEY]),
                accounts: templateAccounts.map(acc => acc[ACCOUNT_ID_KEY]),
            }),
            [template?.visibility],
        ),
    );
    const maybeInitialValues = initialValuesLoadable.valueMaybe();
    useSetValidatedInitialValues(
        {
            initialValues: maybeInitialValues,
            setValues,
            setTouched,
        },
        [maybeInitialValues],
    );
    // Per-user permissions state, separated from Forms state
    const [permissionsByUid, setPermissionsByUid] = useState({});
    const getPermission = useCallback(
        user =>
            permissionsByUid[user[USER_ID_KEY]]?.[0] || DEFAULT_USER_PERMISSION,
        [permissionsByUid],
    );
    const onPermissionChange = useCallback(
        (permission, record) =>
            setPermissionsByUid(p => ({
                ...p,
                [record[USER_ID_KEY]]: [permission],
            })),
        [],
    );
    const maybeTemplateUsers = templateUsersQuery.loadable.valueMaybe();
    useEffect(() => {
        setPermissionsByUid(
            maybeTemplateUsers
                ? Object.fromEntries(
                      maybeTemplateUsers.map(u => [
                          u[USER_ID_KEY],
                          u.permissions,
                      ]),
                  )
                : {},
        );
    }, [maybeTemplateUsers]);
    // Other
    const createUsersColumns = useCallback(
        ({ onRemove }) =>
            onRemove
                ? createUsersPermissionsColumns({
                      onRemove,
                      getPermission,
                      onPermissionChange,
                  })
                : addUsersColumns,
        [onPermissionChange, getPermission],
    );
    const visibility = Forms.useFieldValue({ formId, name: "visibility" });
    const submitDisabled =
        isLoading(templateUsersQuery) ||
        isLoading(projectUsersQuery) ||
        isLoading(templateAccountsQuery) ||
        isLoading(templateAvailableAccountsQuery);

    return (
        <Drawer visible={visible} onClose={onClose} width={600} panel>
            <UnityLayout>
                <UnityLayout.Header size={4} title={template?.label} />
                <UnityLayout.Content padding={[false, true]}>
                    <Text>{template?.uniqueName}</Text>
                    <Gap size="medium" />
                    <Forms.Form formId={formId} onSubmit={handleSubmit}>
                        <Forms.Fields.Radio
                            required
                            name="visibility"
                            label={t(
                                "marketplace.template-visibility.visibility.label",
                            )}
                            options={VISIBILITY_OPTIONS}
                            validator={Forms.pmValidators.isRequired}
                        />
                        <H4>
                            <T id="general.permissions" />
                        </H4>
                        {visibility === VISIBILITY.PUBLIC ? (
                            <Text>
                                {t(
                                    "marketplace.template-visibility.public-permissions",
                                    { name: template?.uniqueName },
                                )}
                            </Text>
                        ) : null}
                        <div
                            style={{
                                display:
                                    visibility === VISIBILITY.PROTECTED
                                        ? "block"
                                        : "none",
                            }}
                        >
                            <FullWidthWrapper md>
                                <Tabs>
                                    <Tabs.TabPane
                                        forceRender
                                        tab={t(
                                            "marketplace.template-visibility.tab.users",
                                        )}
                                        key={TABS.USERS}
                                    >
                                        <LoadableRenderer
                                            loadable={
                                                projectUsersQuery.loadable
                                            }
                                            hasValue={projectUsers => (
                                                <Forms.Fields.EntityIdsPickerTable
                                                    name="users"
                                                    label=""
                                                    width="max"
                                                    inputWidth="max"
                                                    entities={projectUsers}
                                                    idKey={USER_ID_KEY}
                                                    title=""
                                                    addButtonLabel={t(
                                                        "general.add-user",
                                                    )}
                                                    addModalTitle={t(
                                                        "marketplace.template-visibility.pick-users-modal.title",
                                                    )}
                                                    addButtonProps={
                                                        addButtonProps
                                                    }
                                                    createColumns={
                                                        createUsersColumns
                                                    }
                                                    rowHeight={36}
                                                />
                                            )}
                                        />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane
                                        forceRender
                                        tab={t(
                                            "marketplace.template-visibility.tab.accounts",
                                        )}
                                        key={TABS.ACCOUNTS}
                                    >
                                        <LoadableRenderer
                                            loadable={
                                                templateAvailableAccountsQuery.loadable
                                            }
                                            hasValue={availableAccounts => (
                                                <Forms.Fields.EntityIdsPickerTable
                                                    name="accounts"
                                                    label=""
                                                    width="max"
                                                    inputWidth="max"
                                                    entities={availableAccounts}
                                                    idKey={ACCOUNT_ID_KEY}
                                                    title=""
                                                    addButtonLabel={t(
                                                        "general.add-account",
                                                    )}
                                                    addModalTitle={t(
                                                        "marketplace.template-visibility.pick-accounts-modal.title",
                                                    )}
                                                    addButtonProps={
                                                        addButtonProps
                                                    }
                                                    createColumns={
                                                        createAccountsColumns
                                                    }
                                                />
                                            )}
                                        />
                                    </Tabs.TabPane>
                                </Tabs>
                            </FullWidthWrapper>
                        </div>
                    </Forms.Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.save"),
                                    type: "primary",
                                    formId,
                                    disabled: submitDisabled,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Drawer>
    );
};

TemplateVisibilityPanel.propTypes = {
    visible: PropTypes.bool,
    template: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onUpdated: PropTypes.func.isRequired,
};
