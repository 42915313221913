import { Modal, UnityLayout } from "@/components/DesignSystem";
import {
    LoadableRenderer,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { useHasPermission } from "@/security/hooks/useHasPermission.hook";
import { USER_MANAGEMENT_EDIT_PERMISSION } from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useUserAllowedGroupsResource, useUserResource } from "./loadables";
import { FORM_TYPE, UserForm, useUserForm } from "./UserForm";
import { UserGroups } from "./UserGroups";

export const EditUserModal = ({ visible, user, onClose, reloadUsers }) => {
    const canEdit = useHasPermission([USER_MANAGEMENT_EDIT_PERMISSION]);
    const userResource = useUserResource({
        userId: user?.id,
        canFetch: visible,
        map: ({ data: { groups, ...user } }) => user,
    });
    // inconsistent api on groups and users - needs to be fetched separately to prevent user form reset on group add/remove
    const userGroupsResource = useUserResource({
        userId: user?.id,
        canFetch: visible,
        map: ({ data: { groups } }) => groups,
    });
    const userAllowedGroupsResource = useUserAllowedGroupsResource({
        userId: user?.id,
        canFetch: visible,
    });
    const reloadUserGroups = useCallback(() => {
        userGroupsResource.reload();
        userAllowedGroupsResource.reload();
        reloadUsers?.();
    }, [userGroupsResource, userAllowedGroupsResource, reloadUsers]);
    const afterSave = useCallback(() => {
        userResource.reload();
        reloadUserGroups();
        onClose();
    }, [userResource, reloadUserGroups, onClose]);

    const { submit, cancel, ...userFormProps } = useUserForm({
        canFetch: visible,
        formType: FORM_TYPE.EDIT,
        onCancel: onClose,
        initialValues: userResource.loadable.valueMaybe(),
        afterSave,
        // TODO: reset values on visible: true -> false change?
    });

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("user-detail.edit.title")}
                />
                <UnityLayout.Content padding={[true, true]}>
                    <LoadableRenderer
                        loadable={userResource.loadable}
                        hasValue={user => (
                            <UserForm inputWidth="max" {...userFormProps} />
                        )}
                    />
                    <LoadableRenderer
                        loadable={useComposeLoadablesMemoized([
                            userResource.loadable,
                            userGroupsResource.loadable,
                            userAllowedGroupsResource.loadable,
                        ])}
                        hasValue={([user, userGroups, allowedGroups]) => (
                            <UserGroups
                                userGroups={userGroups}
                                allowedGroups={allowedGroups}
                                user={user}
                                canEdit={canEdit}
                                reload={reloadUserGroups}
                            />
                        )}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {canEdit && submit}
                            {cancel}
                        </div>
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

EditUserModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    reloadUsers: PropTypes.func.isRequired,
    user: PropTypes.object,
};
