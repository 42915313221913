import { Input } from "@/components/DesignSystem";
import {
    entries,
    filter,
    fromPairs,
    includes,
    join,
    map,
    pipe,
    split,
} from "lodash/fp";
import React, { useCallback, useEffect, useState } from "react";

const toTextArea = pipe(entries, map(join("=")), join("\n"));
const fromTextArea = pipe(
    split("\n"),
    filter(includes("=")),
    map(split("=")),
    fromPairs,
);

export const ConnectionFieldMap = ({ onBlur, onChange, value, ...props }) => {
    const [localValue, setLocalValue] = useState(() =>
        !value ? "" : toTextArea(value),
    );
    useEffect(() => {
        const nextLocalValue = toTextArea(value);
        setLocalValue(nextLocalValue);
    }, [value]);
    const onChangeLocal = useCallback(e => {
        setLocalValue(e.target.value);
    }, []);
    const onBlurLocal = useCallback(
        e => {
            onChange(fromTextArea(localValue));
            onBlur(e);
        },
        [localValue, onChange, onBlur],
    );

    return (
        <Input.TextArea
            onChange={onChangeLocal}
            onBlur={onBlurLocal}
            value={localValue}
            rows={4}
            {...props}
        />
    );
};
