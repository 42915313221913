import { tap } from "@/components/Connections/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { noop } from "lodash/fp";

export const usePmFeatureFlagsQuery = ({ canFetch = true }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            !canFetch
                ? Promise.resolve(undefined)
                : await axiosService.get(`/api/admin/feature-flags`),
        [axiosService, canFetch],
    );
};

export const usePmFeatureFlagsRestrictionsQuery = ({
    featureFlagName,
    canFetch = true,
}) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            !canFetch
                ? Promise.resolve(undefined)
                : await axiosService
                      .get(
                          `/api/admin/feature-flags/${featureFlagName}/restrictions`,
                      )
                      .then(getData)
                      .catch(e => {
                          // handle no restrictions records found
                          if (e.response.status === 404) return {};
                          throw e;
                      }),
        [featureFlagName, axiosService, canFetch],
    );
};

export const usePmFeatureFlagsEntitiesQuery = ({
    type,
    name = "",
    canFetch = true,
    accountId,
}) => {
    const { axiosService } = useDic();
    return useQueryLoadable(async () => {
        if (!canFetch) return Promise.resolve(undefined);
        switch (type) {
            case "ACCOUNT":
            case "USER":
            case "ADMIN_GROUP":
                return await axiosService.get(
                    `/api/admin/flag-restrictions/types/${type}/entities?name=${name}`,
                );
            case "USER_AND_USER_GROUP":
                if (!accountId) return Promise.resolve(undefined);
                return Promise.all([
                    await axiosService.get(
                        `/api/admin/flag-restrictions/types/USER/entities?accountId=${accountId}&name=${name}`,
                    ),
                    await axiosService.get(
                        `/api/admin/flag-restrictions/types/USER_GROUP/entities?accountId=${accountId}&name=${name}`,
                    ),
                ]);
            default:
                return Promise.resolve(undefined);
        }
    }, [accountId, axiosService, canFetch, name, type]);
};

export const useChangePmFeatureFlagStatusMutation = ({
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ featureFlagName, active }) =>
            axiosService
                .post(`/api/admin/feature-flags/${featureFlagName}`, { active })
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, axiosService],
    );
};

export const usePmFeatureFlagRestrictionsMutation = ({
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ featureFlagName, restrictions }) =>
            axiosService
                .post(
                    `/api/admin/feature-flags/${featureFlagName}/restrictions`,
                    { restrictions },
                )
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, axiosService],
    );
};
