import {
    MAX_KEY_VALUE,
    MIN_KEY_VALUE,
} from "@/components/DataUploads/Wizard/constants";
import { Forms } from "@/components/DesignSystem";
import { StopOnKeyDownPropagation } from "@/components/StopOnKeyDownPropagation";
import React from "react";

export const createColumns = ({ disabled }) => [
    {
        label: "Business Key",
        name: "businessKey",
    },
    {
        label: "Size (characters)",
        name: "size",
        render: (text, { businessKey, initialValue }) => {
            return (
                <StopOnKeyDownPropagation>
                    <Forms.Fields.InputNumber
                        name={businessKey}
                        min={MIN_KEY_VALUE}
                        max={MAX_KEY_VALUE}
                        inputWidth="min"
                        validator={Forms.pmValidators.isRequired}
                        disabled={businessKey === "name" || disabled}
                        initialValue={initialValue}
                    />
                </StopOnKeyDownPropagation>
            );
        },
    },
];
