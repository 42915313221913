import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { TableButton } from "@/components/TableButton";
import { t } from "@/translations";
import React from "react";

export const createColumns = onClick => [
    {
        type: fieldTypes.TEXT,
        label: t("account-admin-users-list.header.username"),
        name: "username",
        width: 200,
        className: "pmTable__column--withActionButton",
        render: (text, record) => (
            <>
                {!onClick ? (
                    text
                ) : (
                    <TableButton onClick={() => onClick(record)}>
                        {text}
                    </TableButton>
                )}
            </>
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("account-admin-users-list.header.full-name"),
        name: "fullName",
    },
    {
        type: fieldTypes.TEXT,
        label: t("account-admin-users-list.header.email"),
        name: "email",
    },
    {
        type: fieldTypes.TEXT,
        label: t("account-admin-users-list.header.status"),
        name: "status",
    },
    {
        type: fieldTypes.TEXT,
        label: t("account-admin-users-list.header.job-title"),
        name: "jobTitle",
    },
    {
        type: fieldTypes.TEXT,
        label: t("account-admin-users-list.header.created-by"),
        name: "createdBy",
    },
    {
        type: fieldTypes.DATETIME,
        label: t("account-admin-users-list.header.created-at"),
        name: "createdAt",
        render: (text, record) =>
            record.createdAt && (
                <FormattedDateTime>{record.createdAt}</FormattedDateTime>
            ),
    },
    {
        type: fieldTypes.DATETIME,
        label: t("account-admin-users-list.header.last-login"),
        name: "lastLogin",
        render: (text, record) =>
            record.lastLogin && (
                <FormattedDateTime>{record.lastLogin}</FormattedDateTime>
            ),
    },
];
