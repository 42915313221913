import {
    alertRuleEntityType,
    alertRuleScope,
    alertRulesServices,
} from "@/components/AlertRules/services/alertRules.service";
import { tap } from "@/components/Connections/loadables";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";

export const useInitialValuesQuery = ({ alertRuleId, scope }) => {
    return useQueryLoadable(
        async () =>
            !alertRuleId
                ? Promise.resolve({
                      enabled: true,
                      cron: "Every 5 minutes",
                      threshold: 10,
                      ...(scope === alertRuleScope.GLOBAL
                          ? { service: alertRuleEntityType.PARTITION }
                          : {}),
                  })
                : alertRulesServices
                      .get(alertRuleId)
                      .then(getData)
                      .then(({ recipients, ...alertRule }) => ({
                          ...alertRule,
                          recipients: recipients ? recipients.split(",") : [],
                          service:
                              alertRule.scope === alertRuleScope.GLOBAL
                                  ? alertRule.entityType
                                  : alertRule.entityId
                                  ? `${alertRule.entityType}|${alertRule.entityId}`
                                  : undefined,
                      })),
        [alertRuleId, scope],
    );
};

export const useServicesOptionsQuery = ({
    accountId,
    canFetch = !!accountId,
}) => {
    return useQueryLoadable(
        async () =>
            !canFetch
                ? Promise.reject("Missing accountId")
                : alertRulesServices
                      .fetchAlertRuleServices(accountId)
                      .then(getData)
                      .then(({ integrations, partitions }) => [
                          {
                              label: "Integrations",
                              options: integrations.map(({ id, label }) => ({
                                  label,
                                  value: `INTEGRATION|${id}`,
                              })),
                          },
                          {
                              label: "Partitions",
                              options: partitions.map(({ id, label }) => ({
                                  label,
                                  value: `PARTITION|${id}`,
                              })),
                          },
                      ]),
        [accountId, canFetch],
    );
};

export const useSaveAlertRuleMutation = ({ afterSuccess }) => {
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async values =>
            alertRulesServices
                .save(values)
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent],
        ({ name }) => `The Alert Rule ${name} was saved.`,
        // () => `The Scheduled Report ${values.name} wasn't saved.`
    );
};

export const useTestAlertRuleMutation = () => {
    return useMutationLoadableWithNotification(
        async values => alertRulesServices.test(values).then(getData),
        [],
        text => text,
        () => `The Alert Rule test failed.`,
    );
};

export const useCopyAlertRuleMutation = ({ afterSuccess }) => {
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async (ruleId, values) =>
            alertRulesServices
                .copy(ruleId, values)
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent],
        () => t("alerts-rules.copy.success"),
        () => `The Alert Rule saving failed.`,
    );
};
