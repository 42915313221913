import { Alert, Gap, P } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    UserAbortError,
    useWithConflictModal,
} from "@/components/Mappers/ConflictModal";
import { TABS } from "@/components/Mappers/MappersList.page";
import { useMapperResourceByOperation } from "@/components/Mappers/loadables";
import { LoadableRenderer } from "@/modules/loadable";
import UserSecurityContentContext from "@/security/UserSecurityContentContext";
import { mappersService } from "@/services/mapper.service";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import React from "react";
import { getMapperFormTrackName as getTrackName } from "../../mixpanel/buttonNames";
import { useTrackButtonClick } from "../../mixpanel/hooks/useTrackButtonClick.hook";
import MapperForm from "./form/MapperForm";

export const isNewMapper = mapperId => {
    return !mapperId || mapperId === "new";
};

export const OPERATION = {
    NEW: "NEW",
    EDIT: "EDIT",
    SHOW: "SHOW",
};

const Mapper = ({ operation, projectId, instanceId, mapperId }) => {
    const {
        messageService,
        locationRouterService,
        accountAppLocations: { mappersLocation, mapperEditLocation },
    } = useDic();

    const mapperResource = useMapperResourceByOperation({
        operation,
        mapperId,
    });

    const { trackHandler } = useTrackButtonClick();
    const withConflictModal = useWithConflictModal();
    const goToMappersList = params =>
        locationRouterService.navigate(mappersLocation, params);

    const saveMapper = ({ values, withDeploy = false }) => {
        const operations = {
            [OPERATION.NEW]: forceCommit =>
                mappersService.createMapper(
                    { projectId, instanceId, ...values },
                    { withDeploy, forceCommit },
                ),
            [OPERATION.EDIT]: forceCommit =>
                mappersService.updateMapper(
                    mapperId,
                    { instanceId, ...values },
                    { withDeploy, forceCommit },
                ),
        };

        return withConflictModal(operations[operation])
            .then(
                trackHandler(
                    res => {
                        const msgKey = withDeploy
                            ? "mapper-form.form.saved-and-deployed"
                            : "mapper-form.form.saved";
                        messageService.success({ content: t(msgKey) });

                        if (withDeploy) {
                            goToMappersList({ tab: TABS.INSTANCE_REPOSITORY });
                        } else if (operation === OPERATION.NEW) {
                            locationRouterService.navigate(mapperEditLocation, {
                                mapperId: res.data.id,
                            });
                        }
                    },
                    { name: getTrackName("SubmitSuccess") },
                ),
            )
            .catch(e => {
                if (e instanceof UserAbortError) return;
                messageService.error({
                    content: getErrorMessage(e?.response?.data),
                });
                throw e;
            });
    };

    return (
        <UserSecurityContentContext projectId={projectId}>
            <P>{t("mapper-form.description")}</P>

            <LoadableRenderer
                loadable={mapperResource.loadable}
                hasValue={initialValues => {
                    const textModeMapper = initialValues?.valid === false;
                    return (
                        <>
                            {textModeMapper && (
                                <>
                                    <Alert
                                        type="info"
                                        message={
                                            "The Mapper can be displayed only in text mode."
                                        }
                                    />
                                    <Gap size="large" />
                                </>
                            )}
                            <MapperForm
                                instanceId={instanceId}
                                saveMapper={saveMapper}
                                onCancel={() => goToMappersList()}
                                withXmlImport={operation === OPERATION.NEW}
                                withDeploy
                                initialValues={initialValues}
                                readOnly={operation === OPERATION.SHOW}
                                textModeMapper={textModeMapper}
                            />
                        </>
                    );
                }}
            />
        </UserSecurityContentContext>
    );
};

export default Mapper;
