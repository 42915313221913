import { Form, Input } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import React from "react";

export const EditForm = ({
    onUpdateRole,
    role = {},
    nameLabel,
    descriptionLabel,
}) => {
    return (
        <Form layout="vertical">
            <Form.Item colon={false} label={nameLabel}>
                <Input
                    type="name"
                    value={role.name}
                    onChange={event => onUpdateRole(event.target.value, "name")}
                />
            </Form.Item>

            <Form.Item colon={false} label={descriptionLabel}>
                <Input
                    type="description"
                    value={role.description}
                    onChange={event =>
                        onUpdateRole(event.target.value, "description")
                    }
                />
            </Form.Item>
        </Form>
    );
};

EditForm.propTypes = {
    role: PropTypes.object,
    onUpdateRole: PropTypes.func,
    nameLabel: PropTypes.string.isRequired,
    descriptionLabel: PropTypes.string.isRequired,
};
