import { useDic } from "@/components/Dic/useDic.hook";
import { useCallback } from "react";
import { PREFERENCES_CHANGE } from "../../mixpanel/mixpanel.constants";

export const PREFERENCES_CHANGE_ACTION = {
    SET_PANEL_FIELDS: "SET_PANEL_FIELDS",
    SET_TABLE_COLUMNS: "SET_TABLE_COLUMNS",
    CREATE: "CREATE",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
    RESET: "RESET",
    SELECT: "SELECT",
};

export const useTrackPreferences = () => {
    const { mixpanelService } = useDic();
    const track = useCallback(
        (action, preferences_type) => {
            mixpanelService.track(PREFERENCES_CHANGE, {
                action,
                preferences_type,
            });
        },
        [mixpanelService],
    );
    return track;
};
