import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

const { useForm, Form, Fields, pmValidators } = Forms;

export const CreateToken = ({ onSave, visible, onClose }) => {
    const { tokensService } = useDic();

    const onSubmit = ({ values }) => {
        tokensService
            .createToken(values.name)
            .then(getData)
            .then(({ token }) => {
                onSave(token);
            });
    };

    const { formId, handleSubmit } = useForm({
        onSubmit,
    });

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("create-token.title.create-token")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Form formId={formId} onSubmit={handleSubmit}>
                        <Fields.Input
                            name="name"
                            label={t("create-token.label.token-name")}
                            type="text"
                            placeholder={t(
                                "create-token.placeholder.token-name",
                            )}
                            required
                            validator={pmValidators.isRequired}
                            inputWidth="max"
                        />
                    </Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    type: "primary",
                                    formId,
                                    label: t("general.create"),
                                },
                                {
                                    type: "text",
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

CreateToken.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default CreateToken;
