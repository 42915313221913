import qs from "qs";
import { axiosService } from "../axios";
import { getData } from "./utils";

export const templatesService = {
    fetchTemplate: (packageName, accountId) =>
        axiosService
            .get(
                `api/templates/${packageName}${qs.stringify(
                    { accountId },
                    { addQueryPrefix: true, skipNulls: true },
                )}`,
            )
            .then(getData),

    fetchTemplateVersions: (packageName, accountId) =>
        axiosService
            .get(
                `/api/templates/${packageName}/versions?accountId=${accountId}`,
            )
            .then(getData),
    fetchTemplateInfo: (instanceId, templateName) =>
        axiosService
            .get(`api/templates/${instanceId}/${templateName}/info`)
            .then(getData),

    fetchPartitionForDeploy: accountId =>
        axiosService
            .get(`api/packages/projects/${accountId}/partition-assets`)
            .then(getData),

    fetchIntegrationsForDeploy: accountId =>
        axiosService
            .get(`api/packages/projects/${accountId}/marketplace-instances`)
            .then(getData),
};
