import { AlertsList } from "@/components/Alerts/AlertsList/AlertsList.component";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { LoadableRenderer } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React from "react";
import { preferencesTypes } from "../../../../constants/preferencesTypes.constants";
import { useUserPreferencesQuery } from "../../../administrationApp/components/Profile/DefaultRoute.loadables";

export function AlertsPageContainer() {
    const { accountId } = useAccountAppParams();
    const userPreferencesQuery = useUserPreferencesQuery();

    useFullWidthTableLayout();

    return (
        <LoadableRenderer
            loadable={userPreferencesQuery.loadable}
            hasValue={userPreferences => (
                <AlertsList
                    accountId={accountId}
                    preferencesType={preferencesTypes.ALERTS_LIST_TABLE}
                    showAllAlertOptions={userPreferences.showAllAlertOptions}
                />
            )}
        />
    );
}
