import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { Container, Drawer, Gap, H4, H5 } from "@/components/DesignSystem";
import { t } from "@/translations";
import React from "react";
import { WorkflowType } from "../../../constants/CommonConstats";
import { Property } from "../../Property/Property.component";

export const WorkflowApprovalDetailPanel = ({ record, onClose }) => {
    return (
        <Drawer visible={!!record} onClose={onClose} panel>
            {!!record && (
                <Container type="padding" size="sm" scrollable>
                    <H4>{record.name}</H4>
                    <Gap />
                    <Property
                        label={t("account.workflows.detail.label.action")}
                        value={WorkflowType.translate(record.type)}
                    />
                    <Property
                        label={t("account.workflows.detail.label.account")}
                        value={record.account}
                    />
                    <Property
                        label={t("account.workflows.detail.label.asset")}
                        value={record.asset}
                    />
                    <Property
                        label={t("account.workflows.detail.label.created-by")}
                        value={record.createdBy}
                    />
                    <Property
                        label={t("account.workflows.detail.label.created-at")}
                        value={
                            <FormattedDateTime>
                                {record.createdAt}
                            </FormattedDateTime>
                        }
                    />
                    <H4>{"Approve by"}</H4>
                    {record.approvalGroups &&
                        record.approvalGroups.map((group, index) => (
                            <>
                                {index > 0 && (
                                    <>
                                        <H5 type="primary">and</H5>
                                        <Gap size="small" />
                                    </>
                                )}
                                <Property
                                    key={"list" + index}
                                    label={t(
                                        "account.workflows.detail.label.list-of-approvers",
                                    )}
                                    value={group?.users}
                                />
                                <Property
                                    key={"number" + index}
                                    label={t(
                                        "account.workflows.detail.label.number-of-approvers",
                                    )}
                                    value={group.approvedRequires}
                                />
                            </>
                        ))}
                </Container>
            )}
        </Drawer>
    );
};
