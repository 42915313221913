import { asValue } from "awilix";
import { productEnhancementAppLocationsMap } from "./productEnhancementApp.locations";

export function registerProductEnhancementApp(dic) {
    const { locationRouterService } = dic.cradle;

    dic.register({
        productEnhancementAppLocation: asValue(
            productEnhancementAppLocationsMap,
        ),
    });

    locationRouterService.registerLocations(productEnhancementAppLocationsMap);
}
