import { axiosService } from "../axios";

export const passwordsService = {
    resetUserPassword(username) {
        return axiosService.post(
            `/api/public/passwords/reset-requests`,
            JSON.stringify({
                login: username,
            }),
        );
    },

    changePassword(oldPassword, newPassword) {
        return axiosService.post("/api/profile/change-password", {
            oldPassword,
            newPassword,
        });
    },

    submitPassword: (password, requestId) =>
        axiosService.post(`/api/public/passwords/reset-requests/${requestId}`, {
            password,
        }),
};
