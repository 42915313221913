import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { PartitionConnectionForm } from "@/components/PartitionDataManagement/Connections/PartitionConnectionForm";
import { PDM_TABS } from "@/components/PartitionDataManagement/PartitionDataManagementPage";
import { useCreateDownloadTargetMutation } from "@/components/PartitionDataManagement/loadables";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React, { useCallback } from "react";

export const NewPartitionConnectionPage = () => {
    const { accountId, partitionId } = useAccountAppParams();
    const {
        locationRouterService,
        accountAppLocations: { partitionDataManagementLocation },
    } = useDic();
    const onCancel = useCallback(
        () =>
            locationRouterService.navigate(partitionDataManagementLocation, {
                tab: PDM_TABS.CONNECTIONS,
            }),
        [partitionDataManagementLocation, locationRouterService],
    );
    useSetBackButtonAction(onCancel);
    const mutation = useCreateDownloadTargetMutation({
        accountId,
        afterSuccess: onCancel,
    });

    return (
        <PartitionConnectionForm
            accountId={accountId}
            partitionId={partitionId}
            initialValues={undefined}
            onSubmit={mutation.mutate}
            onCancel={onCancel}
        />
    );
};
