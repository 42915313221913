import { Alert, Gap, Tabs, UnityLayout } from "@/components/DesignSystem";
import IntegrationDeploymentHistory from "@/components/IntegrationDeploymentHistory/IntegrationDeploymentHistory";
import ProjectIntegrationForm from "@/components/IntegrationRoutes/form/ProjectIntegrationForm";
import { IM_VERSION_WITH_ENCRYPTION_SUPPORT } from "@/components/Integrations/constants";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import PropertiesList from "@/components/Properties/PropertiesList";
import { useAsyncCall } from "@/components/hooks/useAsyncCall.hook";
import { LoadableRenderer } from "@/modules/loadable";
import UserSecurityContentContext from "@/security/UserSecurityContentContext";
import { instancesServices } from "@/services/instance.service";
import { t } from "@/translations";
import { isSameOrNewer } from "@/utils/versionUtils";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";

const TabPane = Tabs.TabPane;

const getPrefix = routeName => `pfx:${routeName}.`;

export const IntegrationAdministration = ({
    projectId,
    instanceId,
    integrationId,
    routeId,
    routeResource: routeWorkspaceResource,
    routeDefinitionResource,
    objectKey,
}) => {
    const routeResource = routeWorkspaceResource ?? routeDefinitionResource;
    const isRepositoryView = Boolean(routeDefinitionResource);

    const [activeKey, setActiveKey] = useState("definitionTab");

    const onChange = selectedIndex => {
        setActiveKey(selectedIndex);
    };

    useSetPageTitle(
        t(
            isRepositoryView
                ? "routes-list.definition.title"
                : "route-edit-form.edit-route",
        ),
    );

    const { isLoading, error, response } = useAsyncCall(
        () => instancesServices.fetchInstance(instanceId),
        [instanceId],
    );

    const isEncryptionSupported = useMemo(
        () =>
            response != undefined &&
            isSameOrNewer(
                response.data.imVersion,
                IM_VERSION_WITH_ENCRYPTION_SUPPORT,
            ) &&
            response.data.inCloud,
    );

    return (
        <UserSecurityContentContext>
            <Tabs
                defaultActiveKey={activeKey}
                activeKey={activeKey}
                onChange={onChange}
            >
                <TabPane tab="Definition" key="definitionTab" defaultPadding>
                    <UnityLayout.Content padding>
                        {activeKey === "definitionTab" && (
                            <ProjectIntegrationForm
                                isRepositoryView={isRepositoryView}
                                projectId={projectId}
                                instanceId={instanceId}
                                integrationId={integrationId}
                                routeResource={routeResource}
                                configurableSupported={
                                    response
                                        ? response.data.configurableSupported
                                        : false
                                }
                            />
                        )}
                    </UnityLayout.Content>
                </TabPane>
                {(isRepositoryView ? !!objectKey : true) && (
                    <TabPane tab="Properties" key="propertiesTab">
                        <UnityLayout.Content padding>
                            {activeKey === "propertiesTab" && (
                                <>
                                    {!isRepositoryView && (
                                        <>
                                            <Alert
                                                message="Changed properties will be deployed to running environment together with route deployment.
                                    Please notice that property must start with prefix pfx otherwise a change won't be applied immediately but would require restart of a service."
                                                type="info"
                                            />
                                            <Gap size="small" />
                                        </>
                                    )}
                                    <LoadableRenderer
                                        loadable={routeResource.loadable}
                                        hasValue={route => (
                                            <PropertiesList
                                                encryptionSupported={
                                                    isEncryptionSupported
                                                }
                                                instanceId={instanceId}
                                                entityId={integrationId}
                                                entityType={
                                                    isRepositoryView
                                                        ? "repositoryRoute"
                                                        : "ROUTE"
                                                }
                                                prefix={getPrefix(
                                                    route.uniqueName,
                                                )}
                                                objectKey={objectKey}
                                            />
                                        )}
                                    />
                                </>
                            )}
                        </UnityLayout.Content>
                    </TabPane>
                )}
                <TabPane tab="Deployment history" key="deploymentHistoryTab">
                    <UnityLayout.Content padding>
                        {activeKey === "deploymentHistoryTab" && (
                            <IntegrationDeploymentHistory
                                integrationId={integrationId ?? routeId}
                                projectId={projectId}
                            />
                        )}
                    </UnityLayout.Content>
                </TabPane>
            </Tabs>
        </UserSecurityContentContext>
    );
};

IntegrationAdministration.propTypes = {
    projectId: PropTypes.number.isRequired,
    instanceId: PropTypes.number.isRequired,
    integrationId: PropTypes.number.isRequired,
};
