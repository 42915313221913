import pipe from "lodash/fp/flow";
import last from "lodash/fp/last";

export const nextConverterState = (prev, next) =>
    !!prev.readOnly !== !!next.readOnly || next.readOnly ? next : prev;

export const selectedMetaToConverterState = pipe(last, maybeLeaf =>
    maybeLeaf?.converterType === "converterExpression" &&
    maybeLeaf?.converterValue
        ? { value: maybeLeaf.converterValue, readOnly: true }
        : { value: "", readOnly: false },
);
