import { useCallback, useContext } from "react";

import { useDic } from "@/components/Dic/useDic.hook";
import { SecurityContext } from "@/security/authorization";
import { getDefaultIntegrationLocation } from "@/utils/location/defaultLocation.utils";

export function useGetDefaultIntegrationLocation() {
    const securityContext = useContext(SecurityContext);
    const { accountAppLocations } = useDic();

    return useCallback(
        integrationId =>
            getDefaultIntegrationLocation(
                integrationId,
                securityContext,
                accountAppLocations,
            ),
        [securityContext, accountAppLocations],
    );
}
