import { Forms, UnityHeading } from "@/components/DesignSystem";
import { FileInfoFetcher } from "@/components/FileParsing/FileInfoFetcher";
import { FileParsingFields } from "@/components/FileParsing/FileParsingFields";
import { DataPreviewTable } from "@/components/Packages/PackageTableDefinitionPanel/components/PackageDataMappingLayout/DataPreviewTable.component";
import { t } from "@/translations";
import React from "react";

const ParsingStep = ({
    stepFormProps: { allValues, setAllValues },
    allStepsProps,
}) => {
    const { accountId, isvConnection } = allStepsProps;
    const formId = Forms.useFormId();

    return (
        <>
            <UnityHeading size={4}>
                {t("package-file-parsing.options.message")}
            </UnityHeading>
            <FileParsingFields
                allowClear={false}
                hideUseVirtualHeader={false}
            />
            <FileInfoFetcher
                formId={formId}
                projectId={accountId}
                typeEntityId={isvConnection.partitionId}
                type="partitions"
                fileId={allValues.uploadedFile.id}
                onFileInfo={fileInfo =>
                    setAllValues(allValues => ({
                        ...allValues,
                        fileInfo,
                    }))
                }
            >
                {({ fileInfo }) => (
                    <DataPreviewTable
                        showTypes={false}
                        dataExample={fileInfo ?? {}}
                    />
                )}
            </FileInfoFetcher>
        </>
    );
};

export default ParsingStep;
