import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useTrackerTypesQuery } from "@/components/PartitionJobTracking/loadables";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { useLoadableAlert } from "@/modules/loadable/useLoadableAlert.hook";
import { ACCOUNT_ALERT_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import { getErrorMessageFromError } from "@/utils/state/error.utils";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { CreateJobTaskAlertModal } from "../CreateJobTaskAlert/CreateJobTaskAlert.modal";
import { TYPE } from "../CreateJobTaskAlert/constants";
import { useFetchPage } from "../PagableTable/useFetchPage.hook";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";

const trackerStatusOptions = {
    WAITING_FOR_DISPATCH: t("partition.job-task-tracking.list.status.waiting"),
    QUEUED_FOR_EXECUTION: t("partition.job-task-tracking.list.status.queued"),
    PROCESSING: t("partition.job-task-tracking.list.status.processing"),
    FAILED: t("partition.job-task-tracking.list.status.failed"),
    FINISHED: t("partition.job-task-tracking.list.status.finished"),
    CANCELLED: t("partition.job-task-tracking.list.status.cancelled"),
    INTERRUPTED: t("partition.job-task-tracking.list.status.interrupted"),
};

const columns = trackerTypeOptions => [
    {
        name: "id",
        label: t("partition.job-task-tracking.list.id"),
        type: fieldTypes.TEXT,
    },
    {
        name: "createDate",
        label: t("partition.job-task-tracking.list.created"),
        type: fieldTypes.DATETIME,
        render: (text, record) =>
            record.createDate && (
                <FormattedDateTime>{record.createDate}</FormattedDateTime>
            ),
    },
    {
        name: "processingStart",
        label: t("partition.job-task-tracking.list.start-processing"),
        type: fieldTypes.DATETIME,
        render: (text, record) =>
            record.processingStart && (
                <FormattedDateTime>{record.processingStart}</FormattedDateTime>
            ),
    },
    {
        name: "processingEnd",
        label: t("partition.job-task-tracking.list.end-processing"),
        type: fieldTypes.DATETIME,
        render: (text, record) =>
            record.processingEnd && (
                <FormattedDateTime>{record.processingEnd}</FormattedDateTime>
            ),
    },
    {
        name: "status",
        label: t("partition.job-task-tracking.list.status"),
        type: fieldTypes.OPTION,
        options: trackerStatusOptions,
        render: (text, record) => trackerStatusOptions[record.status],
    },
    {
        name: "progress",
        label: t("partition.job-task-tracking.list.progress"),
        type: fieldTypes.TEXT,
    },
    {
        name: "threadUUID",
        label: t("partition.job-task-tracking.list.uuid"),
        type: fieldTypes.TEXT,
    },
    {
        name: "trackerType",
        label: t("partition.job-task-tracking.list.type"),
        type: fieldTypes.OPTION,
        options:
            trackerTypeOptions &&
            trackerTypeOptions.map(type => ({
                value: type,
                label: type,
            })),
    },
    {
        name: "jobName",
        label: t("partition.job-task-tracking.list.name"),
        type: fieldTypes.TEXT,
        render: (_, record) => record.targetName,
    },
    {
        name: "cancelRequested",
        label: t("partition.job-task-tracking.list.cancellation-requested"),
        type: fieldTypes.BOOLEAN,
        canFilter: false,
    },
    {
        name: "processingNode",
        label: t("partition.job-task-tracking.list.node"),
        type: fieldTypes.TEXT,
    },
    {
        name: "targetObject",
        label: t("partition.job-task-tracking.list.target-object"),
        type: fieldTypes.TEXT,
    },
    {
        name: "concurrencyKey",
        label: t("partition.job-task-tracking.list.concurrency-key"),
        type: fieldTypes.TEXT,
    },
    {
        name: "calculationContext",
        label: t("partition.job-task-tracking.list.calculation-context"),
        type: fieldTypes.TEXT,
    },
];

const preferencesType = preferencesTypes.PARTITION_JOB_TRACKING_LIST_TABLE;

const PartitionJobTrackingList = ({ accountId, partitionId, restHeight }) => {
    const { projectPartitionApiService } = useDic();

    const [tableProps] = useFetchPage(
        (page, size, sort = "createDate,desc", filter) =>
            projectPartitionApiService.getJobTrackingRecords(
                accountId,
                partitionId,
                page,
                size,
                sort,
                filter,
            ),
        [accountId, partitionId],
    );
    const trackerTypesQuery = useTrackerTypesQuery({ accountId, partitionId });
    useLoadableAlert({
        loadable: trackerTypesQuery,
        hasError: contents => ({
            type: "error",
            message:
                getErrorMessageFromError(contents) || "Partition not available",
            // closable: false,
        }),
    });
    const maybeTrackerTypes = trackerTypesQuery.loadable.valueMaybe();
    const [selected, setSelected] = useState();
    const onCreateAlert = useCallback(record => setSelected(record), []);
    const actionMenu = useCallback(
        record => (
            <ActionButton
                permission={ACCOUNT_ALERT_EDIT_PERMISSIONS}
                record={record}
                items={[
                    {
                        title: t("table.action.create.notification"),
                        onClick: onCreateAlert,
                    },
                ]}
            />
        ),
        [onCreateAlert],
    );
    const closeModal = useCallback(() => setSelected(), []);
    const initialValues = useMemo(
        () => ({
            targetType: selected?.trackerType,
            targetName: selected?.targetName,
            target: selected?.targetObject,
            triggerValue: [].concat(selected?.status ?? []),
            entityIds: [partitionId],
            type: TYPE.PARTITION_JOB,
        }),
        [selected, partitionId],
    );

    return (
        <>
            <TableWithPreferencesManagement
                columns={columns(maybeTrackerTypes)}
                actionMenu={actionMenu}
                fixed
                restHeight={restHeight}
                rowKey="id"
                datasetSlicing="server"
                preferencesType={preferencesType}
                {...tableProps}
            />
            <CreateJobTaskAlertModal
                title={t("alerts.modal-create.title")}
                visible={!!selected}
                afterSave={closeModal}
                onCancel={closeModal}
                projectId={accountId}
                initialValues={initialValues}
            />
        </>
    );
};

PartitionJobTrackingList.propTypes = {
    accountId: PropTypes.number,
    partitionId: PropTypes.number,
};

export default PartitionJobTrackingList;
