import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_HEIGHT_WITH_BREADCRUMB } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useRefreshAction } from "@/components/PageLayout/useRefreshAction.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { isLoading, useQueryLoadable, waitForValue } from "@/modules/loadable";
import {
    isAuthorizedByPermissionOr,
    SecurityContext,
} from "@/security/authorization";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { downloadFile } from "@/utils/downloadUtils";
import { getErrorMessage } from "@/utils/state/error.utils";
import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { createColumns } from "./ResourcesList.columns";

const preferencesType = preferencesTypes.INTEGRATIONS_RESOURCES_LIST_TABLE;

export const ResourcesList = ({ instanceId }) => {
    const { resourcesService, messageService } = useDic();
    const securityContext = useContext(SecurityContext);

    const resourcesResource = useQueryLoadable(
        async () =>
            isNaN(instanceId)
                ? waitForValue()
                : resourcesService.getResources(instanceId).then(getData),
        [instanceId, resourcesService],
    );

    useRefreshAction(resourcesResource.reload);

    const actionMenu = useCallback(
        record => {
            const items = [];

            items.push({
                title: t("general.download"),
                onClick: () => {
                    resourcesService
                        .getResourceContent(instanceId, record.name)
                        .then(({ data }) => {
                            downloadFile(data, record.name);
                        })
                        .catch(e =>
                            messageService.error({
                                content: getErrorMessage(e.response.data),
                            }),
                        );
                },
            });

            if (
                isAuthorizedByPermissionOr(
                    INTEGRATION_EDIT_PERMISSIONS,
                    securityContext.permissions,
                )
            ) {
                items.push({
                    title: t("general.modal.request-delete.title", {
                        type: "Resource",
                    }),
                    confirmMessage: t(
                        "general.do-you-really-want-to-delete-name",
                        {
                            name: record.name,
                        },
                    ),
                    onConfirm: () => {
                        resourcesService
                            .delete({
                                instanceId,
                                name: record.name,
                                prn: record.prn,
                            })
                            .then(() => {
                                messageService.success({
                                    content: t(
                                        "resources-list.deletion-requested",
                                    ),
                                });
                                resourcesResource.reload();
                            });
                    },
                    color: "red",
                });
            }

            return <ActionButton record={record} items={items} />;
        },
        [
            instanceId,
            messageService,
            resourcesResource,
            resourcesService,
            securityContext.permissions,
        ],
    );

    const dataSource = resourcesResource.loadable.valueMaybe() ?? [];

    return (
        <>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                columns={createColumns()}
                loading={isLoading(resourcesResource)}
                dataSource={dataSource}
                fixed
                restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
                rowKey="name"
                datasetSlicing="local"
                preferencesType={preferencesType}
                defaultSort={{
                    sortSpecifiers: [
                        { property: "updatedAt", direction: "descending" },
                    ],
                }}
            />
        </>
    );
};

ResourcesList.propTypes = {
    accountId: PropTypes.number.isRequired,
    instanceId: PropTypes.number.isRequired,
};
