import { useDic } from "@/components/Dic/useDic.hook";
import { SidebarAndContentLayout } from "@/components/Layouts";
import { LocationRoutes } from "@/modules/router";
import React from "react";
import { WorkflowsSidebar } from "../WorkflowsSidebar.component";

export function WorkflowsApp() {
    const { workflowsAppLocation } = useDic();

    return (
        <SidebarAndContentLayout sidebar={<WorkflowsSidebar />}>
            <LocationRoutes locations={workflowsAppLocation} />
        </SidebarAndContentLayout>
    );
}
