import { Col, Row } from "@/components/DesignSystem";
import { Button } from "@/components/DesignSystem/Button/Button";
import { H1 } from "@/components/DesignSystem/heading";
import { H4, P } from "@/components/DesignSystem/index";
import { t } from "@/translations";
import React from "react";
import pageStyles from "./styles.less";

// FIXME: according to Design https://www.figma.com/file/SI5qYBoGnWxKEkL0mFbxmOTB/01.18_Notifications-and-Error-Messages?node-id=226%3A198
export const NotFoundPage = () => (
    <Row
        className={pageStyles.page}
        type="flex"
        justify="center"
        align="middle"
    >
        <Col>
            <H1>404</H1>
            <H4>{t("page.404.title")}</H4>
            <P>{t("page.404.desc")}</P>

            <Button type="primary" label={t("page.404.go-to-dashboard")} />
        </Col>
    </Row>
);
