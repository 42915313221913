import { Table } from "@/components/DesignSystem";
import { hooks } from "@/components/DesignSystem/Table/Table.component";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { defaultParserConfigValues } from "@/components/FileParsing/FileParsingFields";
import { useOutputOptions } from "@/components/Mappers/MapperTableWithCustomization/loadableResurces";
import {
    LoadableRenderer,
    useComposeLoadablesMemoized,
    useQueryLoadable,
} from "@/modules/loadable";
import { logger } from "@/modules/logger";
import { getData } from "@/services/utils";
import { pick } from "lodash";
import { keys } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useState } from "react";

const COLUMN_WIDTH = 250;

export const getExampleRows = (tableExampleData, numberOfRows) => {
    const result = Array.from(Array(numberOfRows).keys()).map(index =>
        tableExampleData.columns.reduce(
            (result, cell) => ({
                ...result,
                [cell.name]: cell?.originalValues?.[index],
            }),
            {},
        ),
    );
    logger.debug({
        logGroupKey: ["DATALOAD", "getExampleRows"],
        color: "orchid",
        data: {
            result,
            tableExampleData,
            numberOfRows,
        },
    });

    return result;
};

const createDataColumns = ({ apiResults: { result }, definedColumns }) => {
    if (!result.length) return [];

    return Object.keys(result[0])
        .map(key => ({
            name: key,
            originalValues: result.reduce(
                (result, item) => [...result, item[key]],
                [],
            ),
            parsedValues: result.reduce(
                (result, item) => [...result, item[key]],
                [],
            ),
        }))
        .map(column => {
            const foundDefinition = definedColumns.find(
                definedColumn => definedColumn.value === column.name,
            );
            return {
                ...column,
                width: COLUMN_WIDTH,
                name: column.name,
                label: foundDefinition?.label ?? column.name,
                type: foundDefinition?.type,
            };
        });
};

const createDataSource = ({ successRows, errorRows, result, errors }) => {
    const resultCopy = [...result];

    return Array(successRows + errorRows)
        .fill(null)
        .map((_, i) => {
            const errorRow = errors.find(({ rowIndex }) => i === rowIndex);

            if (errorRow)
                return {
                    status: errorRow.reason,
                    alerts: {
                        status_ALERT_TYPE: "RED",
                    },
                };
            else
                return {
                    status: "OK",
                    ...resultCopy.shift(),
                };
        });
};

const PreviewTable = ({ mappedColumnsResponse, definedColumns, onBack }) => {
    const columns = [
        ...createDataColumns({
            apiResults: mappedColumnsResponse,
            definedColumns,
        }),
        {
            // frozen: true, // This is in UC SB but broken now
            fixed: "right",
            width: COLUMN_WIDTH,
            type: fieldTypes.TEXT,
            name: "status",
            label: "Status",
        },
    ];
    const dataSource = createDataSource(mappedColumnsResponse);

    const [enhancedColumns, setEnhancedColumns] = useState(columns);

    const columnFreezing = hooks.useColumnFreezing({
        columns: enhancedColumns,
        setColumns: setEnhancedColumns,
    });

    return (
        <Table
            columns={enhancedColumns}
            columnFreezing={columnFreezing}
            dataSource={dataSource}
            rowKey={() => Math.random().toString(36)}
            padding={false}
            pagination={{
                pageSize: Number.MAX_SAFE_INTEGER,
                pageSizeOptions: [],
                totalRows: mappedColumnsResponse.errorRows,
            }}
            hasColumnResizing
        />
    );
};

export const ResultPreview = ({
    partitionId,
    allValues,
    tableExampleData,
    onBack,
}) => {
    const { convertersService } = useDic();
    const outputOptionsResource = useOutputOptions({
        sourceType: "partitions",
        sourceId: partitionId,
        entityType: allValues.entityType,
        entityName: allValues.entityName,
        objectTypeErrors: undefined,
    });
    const mappedColumnsResource = useQueryLoadable(() =>
        convertersService
            .testDataLoadMapper({
                rowData: getExampleRows(
                    tableExampleData,
                    Math.min(tableExampleData.numberOfRows, 3),
                ),
                mapperItems: allValues.mapper,
                parserConfig: pick(allValues, keys(defaultParserConfigValues)),
            })
            .then(getData),
    );

    return (
        <LoadableRenderer
            loadable={useComposeLoadablesMemoized([
                outputOptionsResource.loadable,
                mappedColumnsResource.loadable,
            ])}
            hasValue={([definedColumns, mappedColumnsResponse]) => (
                <PreviewTable
                    {...{ mappedColumnsResponse, definedColumns, onBack }}
                />
            )}
        />
    );
};

ResultPreview.propTypes = {
    allValues: PropTypes.object.isRequired,
    tableExampleData: PropTypes.shape({
        columns: PropTypes.array,
        numberOfRows: PropTypes.number,
        fileName: PropTypes.string,
    }).isRequired,
};
