import { ConnectionForm } from "@/components/Connections/ConnectionForm";
import { Modal, UnityLayout } from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

const NewConnectionModal = ({
    initValues,
    instanceId,
    visible,
    onSave,
    onCancel,
    disableTypeChange = false,
}) => {
    return (
        <Modal width={606} visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("connection-list.button.new")}
                />
                <ConnectionForm
                    disableTypeChange={disableTypeChange}
                    initValues={initValues}
                    confirmDeployed
                    isModalVariant
                    connectionId="new"
                    instanceId={instanceId}
                    onSave={onSave}
                    onCancel={onCancel}
                />
            </UnityLayout>
        </Modal>
    );
};

NewConnectionModal.propTypes = {
    initValues: PropTypes.object,
    instanceId: PropTypes.number.isRequired,
    disableTypeChange: PropTypes.bool,
    visible: PropTypes.bool.isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
};

export default NewConnectionModal;
