import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";

export const columns = [
    {
        type: fieldTypes.TEXT,
        label: t("failed-events-list.header.event-type"),
        name: "eventType",
    },
    {
        type: fieldTypes.TEXT,
        label: t("failed-events-list.header.count"),
        name: "count",
    },
];
