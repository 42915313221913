import cloneDeep from "lodash/cloneDeep";
import keyBy from "lodash/keyBy";
import merge from "lodash/merge";
import reject from "lodash/reject";
import values from "lodash/values";
import styles from "../styles.less";
import { getSimpleValue } from "../../../packagesPanel.utils";

export const availableOptions = (simpleValues, headers = []) => {
    let foundValues = values(simpleValues).map(simpleValue =>
        getSimpleValue(simpleValue.value),
    );

    return reject(headers, header => foundValues.includes(header));
};

export const handleRowClassName = record => {
    return record.value && !record.error
        ? styles.validRow
        : styles.tableBackground;
};

export const getTitleAttribute = (name, obj1, obj2) => {
    const selectedValues = keyBy(
        values(merge(cloneDeep(obj1), cloneDeep(obj2))),
        "value",
    );
    return selectedValues[name] || {};
};
