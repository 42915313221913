const update = (names, name, action) => {
    if (action === "add") return names.concat(name);
    if (action === "remove") return names.filter(n => n !== name);
    if (action) {
        console.log("Unknown updater action", { names, name, action });
    }
    return names;
};

export const applyGlobalPermissionChanges = (
    userGlobalPermissions,
    changes,
) => {
    const permissionNames = userGlobalPermissions.map(p => p.permissionName);
    const newUserGlobalPermissions = Object.entries(changes).reduce(
        (permissionNames, [name, change]) =>
            update(permissionNames, name, change.state.action),
        permissionNames,
    );

    return newUserGlobalPermissions;
};
