import { useDic } from "@/components/Dic/useDic.hook";
import { pendingPromise, useQueryLoadable } from "@/modules/loadable";

export const useAccountMarketplaceInstancesQuery = ({
    accountId,
    canFetch = !!accountId,
}) => {
    const { templateService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : templateService.fetchIntegrationsForDeploy(accountId),
        [accountId, templateService, canFetch],
    );
};
