import { Button, Forms } from "@/components/DesignSystem";
import { t } from "@/translations";
import { ReactComponent as Trash } from "@pricefx/unity-components/src/icons/unicons/trash-alt.svg";
import moment from "moment";
import React from "react";
import { TIME_HOURS_MINUTES } from "./date-formats";

const { success, error, Fields } = Forms;
export const DEFAULT_MINUTE_STEP = 15;

export const isMinutesDividedBy = dividedBy => value => {
    return value && value.minute() % dividedBy === 0
        ? success()
        : error(t("capacity-configuration.validation.division"));
};

const isValidTimeInterval = id => async (_, getBag) => {
    const { values } = await getBag();
    const timeFrom = values[`${id}_timeFrom`];
    const timeTo = values[`${id}_timeTo`];

    // not validating if second input is missing
    if (!(timeFrom && timeTo)) {
        return success();
    }

    return timeFrom.isBefore(timeTo)
        ? success()
        : error(t("capacity-configuration.validation.invalidRange"));
};

const CapacityEntry = ({ id, initials, onRowRemove, validators }) => (
    <Forms.FieldGrid.Row>
        <Fields.DatePicker
            name={`${id}_date`}
            initialValue={
                initials?.timeFrom ? moment(initials?.timeFrom) : null
            }
            label={t("capacity-entry.date.label")}
            placeholder={t("capacity-entry.date.placeholder")}
            validator={validators.isRequired}
            inputWidth="max"
        />
        <Fields.TimePicker
            name={`${id}_timeFrom`}
            initialValue={
                initials?.timeFrom ? moment(initials?.timeFrom) : null
            }
            format={TIME_HOURS_MINUTES}
            placeholder={t("capacity-entry.time-from.label")}
            label={t("capacity-entry.time-from.placeholder")}
            minuteStep={DEFAULT_MINUTE_STEP}
            validator={validators.failOnFirst([
                validators.isRequired,
                isMinutesDividedBy(DEFAULT_MINUTE_STEP),
                isValidTimeInterval(id),
            ])}
        />
        <Forms.UIField name={`${id}_separator`}>
            <div className="capacityEntry-separator">{"-"}</div>
        </Forms.UIField>
        <Fields.TimePicker
            name={`${id}_timeTo`}
            initialValue={initials?.timeTo ? moment(initials?.timeTo) : false}
            format={TIME_HOURS_MINUTES}
            label={t("capacity-entry.time-to.label")}
            placeholder={t("capacity-entry.time-to.placeholder")}
            minuteStep={DEFAULT_MINUTE_STEP}
            validator={validators.failOnFirst([
                validators.isRequired,
                isMinutesDividedBy(DEFAULT_MINUTE_STEP),
                isValidTimeInterval(id),
            ])}
        />

        <Fields.Checkbox
            name={`${id}_recurrence`}
            initialValue={initials?.recurrence || false}
            label={t("capacity-entry.repeat.label")}
        />
        <Forms.UIField name={`${id}_remove`}>
            <Button onClick={onRowRemove} icon={Trash} />
        </Forms.UIField>
    </Forms.FieldGrid.Row>
);

export default CapacityEntry;
