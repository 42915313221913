import { Drawer } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import React from "react";
import { UserUpgradeSlots } from "./UserUpgradeSlots.component";

export const UpgradeSlotsDetailPanel = ({ record, onClose }) => {
    return (
        <Drawer width={362} visible={!!record} onClose={onClose} panel>
            {record && <UserUpgradeSlots record={record} />}
        </Drawer>
    );
};

UpgradeSlotsDetailPanel.propTypes = {
    record: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};
