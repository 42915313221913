import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

export const momentSort = (a, b) => {
    // force null and undefined to the bottom
    if (a === null || a === undefined) {
        return -1;
    }

    if (b === null || b === undefined) {
        return 1;
    }

    const aMoment = moment(a.props.children);
    const bMoment = moment(b.props.children);

    return aMoment.diff(bMoment, "second");
};

export const compareMoment = (a, b) => {
    // force null and undefined to the bottom
    if (a === null || a === undefined) {
        return -1;
    }

    if (b === null || b === undefined) {
        return 1;
    }

    const aMoment = moment(a);
    const bMoment = moment(b);

    return bMoment.diff(aMoment, "second");
};

export const sortChanged = (a, b) => {
    if (a === null || a === undefined) {
        return -1;
    }

    if (b === null || b === undefined) {
        return 1;
    }

    const updatedAtA = selectChangedAt(a);
    const updatedAtB = selectChangedAt(b);

    return moment(updatedAtB).diff(moment(updatedAtA), "seconds");
};

export const selectChangedAt = data => {
    return data.updatedAt === undefined ? data.createdAt : data.updatedAt;
};

const getByIndex = (data, index) => {
    return get(data, index, "") ? get(data, index, "") : "";
};

export const sort = (a, b, dataIndex) => {
    const valueA = getByIndex(a, dataIndex);
    const valueB = getByIndex(b, dataIndex);

    if (typeof valueA === "string" && typeof valueB == "string") {
        return valueA.toUpperCase().localeCompare(valueB.toUpperCase());
    }
    {
        return valueA - valueB;
    }
};

export const dateSort = date => (a, b) => new Date(b[date]) - new Date(a[date]);

export const getColumnOptions = (dataSource, index) =>
    Object.values(
        dataSource.reduce(
            (options, row) => ({
                ...options,
                [row[index]]: {
                    label: row[index],
                    value: row[index],
                },
            }),
            {},
        ),
    )
        .filter(option => !isEmpty(option.label))
        .sort((a, b) => sort(a, b, "label"));
