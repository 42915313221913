import { useDic } from "@/components/Dic/useDic.hook";
import { useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useInstanceGroupQuery = ({ instanceGroup }) => {
    const { instanceService } = useDic();
    return useQueryLoadable(
        async () =>
            instanceService
                .fetchInstanceGroupEnvSizing(instanceGroup)
                .then(getData),
        [instanceGroup, instanceService],
    );
};
