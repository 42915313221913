import {
    ButtonMenu,
    Drawer,
    Forms,
    Gap,
    H4,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import {
    isLoading,
    LoadableRenderer,
    useComposeLoadablesMemoized,
    useMapLoadableMemoized,
} from "@/modules/loadable";
import { t } from "@/translations";
import { T } from "@/translations/T";
import { get } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import {
    addUsersColumns,
    createUsersPermissionsColumns,
} from "./createColumns";
import {
    useIntegrationGroupUsersQuery,
    useIntegrationUsersMutation,
    useIntegrationUsersQuery,
} from "./loadables";

const USER_ID_KEY = "id";

export const STATUS = {
    BLOCKED: "BLOCKED",
};

export const InstancePermissionsPanel = ({
    projectId,
    panelControl: { record, hide },
}) => {
    const usersQuery = useIntegrationGroupUsersQuery({
        projectId,
        integrationGroupName: record?.groupName,
        canFetch: !!record,
    });
    const integrationUsersQuery = useIntegrationUsersQuery({
        projectId,
        groupName: record?.groupName,
        canFetch: !!record,
    });
    const integrationUsersMutation = useIntegrationUsersMutation({
        projectId,
        groupName: record?.groupName,
        afterSuccess: () => {
            integrationUsersQuery.reload();
            hide();
        },
    });
    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit: async ({ values }) => {
            integrationUsersMutation.mutate({ values });
        },
    });
    const initialValues = useMapLoadableMemoized(
        integrationUsersQuery.loadable,
        useCallback(users => ({ users: users.map(get(USER_ID_KEY)) }), []),
    ).valueMaybe();
    useSetValidatedInitialValues(
        {
            initialValues,
            setValues,
            setTouched,
        },
        [initialValues],
    );
    const createUsersColumns = useCallback(
        ({ onRemove }) =>
            onRemove
                ? createUsersPermissionsColumns({ onRemove })
                : addUsersColumns(),
        [],
    );
    const loadables = useComposeLoadablesMemoized([
        usersQuery.loadable,
        integrationUsersQuery.loadable,
    ]);
    const submitDisabled =
        isLoading(usersQuery) ||
        isLoading(integrationUsersQuery) ||
        isLoading(integrationUsersMutation);

    return (
        <Drawer visible={!!record} onClose={hide} width={600} panel>
            <UnityLayout>
                <UnityLayout.Header size={4} title={t("general.permissions")} />
                <UnityLayout.Content padding={[false, true]}>
                    <Text>{record?.groupName}</Text>
                    <Gap size="medium" />
                    <Forms.Form formId={formId} onSubmit={handleSubmit}>
                        <H4>
                            <T id="general.edit-users" />
                        </H4>
                        <LoadableRenderer
                            loadable={loadables}
                            hasValue={([users, intergationUsers]) => (
                                <Forms.Fields.EntityIdsPickerTable
                                    name="users"
                                    label=""
                                    width="max"
                                    inputWidth="max"
                                    entities={users}
                                    idKey={USER_ID_KEY}
                                    title=""
                                    addButtonLabel={t("general.add-user")}
                                    addModalTitle={t(
                                        "marketplace.template-visibility.pick-users-modal.title",
                                    )}
                                    createColumns={createUsersColumns}
                                    rowHeight={36}
                                />
                            )}
                        />
                    </Forms.Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.save-changes"),
                                    type: "primary",
                                    formId,
                                    disabled: submitDisabled,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: hide,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Drawer>
    );
};

InstancePermissionsPanel.propTypes = {
    projectId: PropTypes.number.isRequired,
    panelControl: PropTypes.shape({
        record: PropTypes.object,
        hide: PropTypes.func.isRequired,
    }).isRequired,
};
