import {
    Alert,
    ButtonMenu,
    Forms,
    Modal,
    Table,
    UnityLayout,
} from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useConfirmModal } from "@/modules/modal";
import { t } from "@/translations";
import { noop } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { FF_ROW_HEIGHT } from "../constants";
import { FeatureFlagName } from "../createColumns";
import { FeatureFlagValue } from "../FeatureFlagValue";
import { useApplyAdminFeatureFlagsMutation } from "../loadables";

export const columns = [
    {
        label: t("feature-flags.mass-edit.table.column.id"),
        name: "name",
        type: fieldTypes.TEXT,
        render: (name, record) => {
            return (
                <FeatureFlagName name={name} description={record.description} />
            );
        },
    },
    {
        label: t("feature-flags.mass-edit.table.column.value"),
        name: "value",
        type: fieldTypes.TEXT,
        canSort: false,
        canFilter: false,
        render: (_, record) => {
            return (
                <FeatureFlagValue
                    enableHighlight={false}
                    name="value"
                    record={record}
                />
            );
        },
    },
];

const MassEditModal = ({
    selectedRowKeys,
    selectedFeatureFlag,
    visible,
    onSave,
    onCancel: onPassedCancel,
}) => {
    const applyFeatureFlagsMutation = useApplyAdminFeatureFlagsMutation({
        onSuccess: noop,
    });
    const confirmModal = useConfirmModal();

    const onSubmit = useCallback(
        async ({ values }) => {
            const data = selectedRowKeys.map(partitionId => ({
                params: { partitionId },
                data: [{ ...selectedFeatureFlag, value: values.value }],
            }));

            return applyFeatureFlagsMutation.mutate(data).then(() => {
                onSave(selectedRowKeys);
            });
        },
        [
            applyFeatureFlagsMutation,
            onSave,
            selectedFeatureFlag,
            selectedRowKeys,
        ],
    );

    const showBeforeSaveConfirm = useCallback(
        record =>
            confirmModal.confirm({
                title: t("feature-flags.mass-edit.confirm.title"),
                message: t("feature-flags.mass-edit.confirm.message"),
                okText: t("general.apply"),
                cancelText: t("general.cancel"),
                onConfirm: () => {
                    onSubmit(record);
                },
            }),
        [confirmModal, onSubmit],
    );

    const { formId, handleSubmit } = Forms.useForm({
        onSubmit: showBeforeSaveConfirm,
    });

    const onCancel = () => {
        onPassedCancel?.();
    };

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("feature-flags.mass-edit.title", {
                        flag: selectedFeatureFlag.name,
                    })}
                    alert={alert && <Alert {...alert} />}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Forms.Form onSubmit={handleSubmit} formId={formId}>
                        <Table
                            columns={columns}
                            dataSource={[selectedFeatureFlag]}
                            pagination={false}
                            rowKey="username"
                            rowHeight={FF_ROW_HEIGHT}
                            padding={false}
                        />
                    </Forms.Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.change"),
                                    type: "primary",
                                    formId,
                                },
                                {
                                    label: t("general.cancel"),
                                    type: "text",
                                    onClick: onCancel,
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

MassEditModal.propTypes = {
    selectedRowKeys: PropTypes.array.isRequired,
    selectedFeatureFlag: PropTypes.object,
    visible: PropTypes.bool.isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
};

export default MassEditModal;
