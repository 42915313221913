import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { useReloadUserPermissions } from "@/security/hooks/useReloadUserPermissions.hook";

// ##############################  QUERIES ##############################
export const useGlobalPermissions = () => {
    const { permissionsApiService } = useDic();
    return useQueryLoadable(
        () => permissionsApiService.fetchGlobalPermission(),
        [permissionsApiService],
    );
};
export const useUserGlobalPermissions = (userId, canFetch = true) => {
    const { permissionsApiService } = useDic();
    return useQueryLoadable(
        () =>
            canFetch && userId
                ? permissionsApiService.fetchUserGlobalPermissions(userId)
                : pendingPromise(),
        [userId, canFetch, permissionsApiService],
    );
};
export const useGroupGlobalPermissions = (groupName, canFetch = true) => {
    const { permissionsApiService } = useDic();
    return useQueryLoadable(
        () =>
            canFetch && groupName
                ? permissionsApiService.fetchGroupGlobalPermissions(groupName)
                : pendingPromise(),
        [groupName, canFetch, permissionsApiService],
    );
};
export const useGroupUserGlobalPermissions = (userId, canFetch = true) => {
    const { permissionsApiService } = useDic();
    return useQueryLoadable(
        () =>
            canFetch && userId
                ? permissionsApiService.fetchGroupUserGlobalPermissions(userId)
                : pendingPromise(),
        [userId, canFetch, permissionsApiService],
    );
};

// ##############################  MUTATIONS ##############################
export const useSaveUserGlobalPermissions = (userId, onSaved) => {
    const reloadUserPermissions = useReloadUserPermissions();
    const { permissionsApiService } = useDic();
    const currentAfterSaved = useCurrentHandler(onSaved);
    return useMutationLoadableWithNotification(
        async permissions => {
            await permissionsApiService.saveUserGlobalPermissions(
                userId,
                permissions,
            );
            await reloadUserPermissions();
            currentAfterSaved();
        },
        [
            userId,
            permissionsApiService,
            reloadUserPermissions,
            currentAfterSaved,
        ],
    );
};
export const useSaveGroupGlobalPermissions = (groupName, onSaved) => {
    const reloadUserPermissions = useReloadUserPermissions();
    const { permissionsApiService } = useDic();
    const currentAfterSaved = useCurrentHandler(onSaved);
    return useMutationLoadableWithNotification(
        async permissions => {
            await permissionsApiService.saveGroupGlobalPermissions(
                groupName,
                permissions,
            );
            await reloadUserPermissions();
            currentAfterSaved();
        },
        [
            groupName,
            permissionsApiService,
            reloadUserPermissions,
            currentAfterSaved,
        ],
    );
};
