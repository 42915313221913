import { Gap, Option, Select } from "@/components/DesignSystem";
import { T } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { CONNECTION_TYPE } from "../constants";
import { useStateReinitialize } from "../MapperGridForm/hooks/useStateReinitialize.hook";
import { ConnectionConfigurationSelectOptionsResourceFetcher } from "./ConnectionConfigurationSelectOptionsResourceFetcher";
import { ObjectTypeSelectOptionsResourceFetcher } from "./ObjectTypeSelectOptionsResourceFetcher";

const shortConnectionType = fullConnectionType =>
    fullConnectionType?.split(".").pop();

const useConnectionSelect = ({
    connections,
    connectionTypes,
    connectionLabelKey,
    initialConnectionId,
}) => {
    const wrapperRef = useRef(document.body);
    const selectableConnections = useMemo(
        () =>
            connections.filter(c =>
                // can be just compared when api will return connectionType without full class name
                connectionTypes.includes(shortConnectionType(c.connectionType)),
            ),
        [connections, connectionTypes],
    );
    const initialConnection = useMemo(
        () =>
            initialConnectionId &&
            selectableConnections.find(
                c => c.connectionId == initialConnectionId,
            ),
        [selectableConnections, initialConnectionId],
    );
    useEffect(() => {
        if (initialConnectionId && !initialConnection)
            console.error("Missing connection matching initialConnectionId");
    }, [initialConnectionId, initialConnection]);
    const [maybeSelectedConnection, setMaybeSelectedConnection] =
        useStateReinitialize(initialConnection);
    const onConnectionSelect = useCallback(
        connectionName => {
            const connection = selectableConnections.find(
                c => c.connectionName === connectionName,
            );
            if (connectionName && !connection?.connectionId)
                console.error(
                    "Selected connection does not have ID",
                    connectionName,
                    connection,
                );
            setMaybeSelectedConnection(connection);
        },
        [selectableConnections],
    );

    const connectionSelectElement = (
        <div ref={wrapperRef} data-test="connection">
            <T id={connectionLabelKey} />
            <Select
                disabled={!!initialConnection}
                showSearch
                name="connectionName"
                placeholder="Select connection"
                value={maybeSelectedConnection?.connectionName}
                onChange={onConnectionSelect}
                getPopupContainer={() => wrapperRef.current}
            >
                {selectableConnections.map(conn => (
                    <Option
                        title={conn.connectionName}
                        key={conn.connectionName}
                    >
                        {conn.connectionName}
                    </Option>
                ))}
            </Select>
            <Gap size="small" />
        </div>
    );

    return { maybeSelectedConnection, connectionSelectElement };
};

export const OptionsResourceSelect = ({
    connections,
    connectionTypes,
    setOptionsResource,
    setParams,
    instanceId,
    resetResource,
    connectionLabelKey,
    initialConnectionId,
}) => {
    const { connectionSelectElement, maybeSelectedConnection } =
        useConnectionSelect({
            connections,
            connectionTypes,
            connectionLabelKey,
            initialConnectionId,
        });
    let metaFetcherElement = null;

    // can be just compared when configurations will return correct connectionType
    if (
        [
            CONNECTION_TYPE.PFX_CLIENT_CONNECTION_TYPE,
            CONNECTION_TYPE.PFX_CONNECTION_TYPE,
        ].includes(shortConnectionType(maybeSelectedConnection?.connectionType))
    )
        metaFetcherElement = (
            <ObjectTypeSelectOptionsResourceFetcher
                key={maybeSelectedConnection?.connectionName}
                setOptionsResource={setOptionsResource}
                maybeSelectedConnection={maybeSelectedConnection}
                resetResource={resetResource}
                setParams={setParams}
            />
        );
    // if (maybeSelectedConnection?.connectionType)
    else
        metaFetcherElement = (
            <ConnectionConfigurationSelectOptionsResourceFetcher
                key={maybeSelectedConnection?.connectionName}
                instanceId={instanceId}
                setOptionsResource={setOptionsResource}
                maybeSelectedConnection={maybeSelectedConnection}
                resetResource={resetResource}
                setParams={setParams}
            />
        );

    return (
        <>
            {connectionSelectElement}
            {metaFetcherElement}
        </>
    );
};

OptionsResourceSelect.propTypes = {
    setParams: PropTypes.func.isRequired,
};
