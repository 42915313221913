import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useCreateSupportUserMutation } from "@/components/SupportUser/loadables";
import { isLoading, useQueryLoadable, waitForValue } from "@/modules/loadable";
import { useUserBasicInfo } from "@/security/hooks/useUserBasicInfo.hook";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { userService } from "../../apps/accountApp/services/user.service";

const { useForm, Form, Fields, pmValidators } = Forms;

const disabledExpirationDate = dateValue => {
    return dateValue < new Date();
};

export const CreateSupportUserModal = ({
    visible,
    onClose,
    partitionId,
    accountId,
}) => {
    const userName = useUserBasicInfo().userInfo.user.username;
    const usernameOptionsResource = useQueryLoadable(
        async () =>
            visible
                ? userService.fetchUsernames().then(({ data }) =>
                      data.map(username => ({
                          value: username,
                          label: username,
                      })),
                  )
                : waitForValue(),
        [visible],
    );
    const usernameOptionsSelectProps = getLoadableSelectProps(
        usernameOptionsResource.loadable,
    );
    const createSupportUserMutation = useCreateSupportUserMutation({
        accountId,
        partitionId,
        afterSuccess: onClose,
    });

    const { formId, handleSubmit } = useForm({
        onSubmit: ({ values }) => createSupportUserMutation.mutate(values),
    });
    const pending =
        isLoading(createSupportUserMutation) ||
        isLoading(usernameOptionsResource);

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("partition.create-support-user.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Form
                        formId={formId}
                        layout="vertical"
                        onSubmit={handleSubmit}
                    >
                        <Fields.Select
                            name="username"
                            label={t("partition.create-support-user.username")}
                            placeholder={t("placeholder.please-select")}
                            showSearch
                            initialValue={userName}
                            validator={pmValidators.isRequired}
                            required
                            {...usernameOptionsSelectProps}
                        />

                        <Fields.DatePicker
                            name="expiration"
                            label={t(
                                "partition.create-support-user.expiration",
                            )}
                            showTime
                            disabledDate={disabledExpirationDate}
                            validator={pmValidators.isRequired}
                            required={true}
                        />
                    </Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.save"),
                                    type: "primary",
                                    formId,
                                    loading: pending,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                    disabled: pending,
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

CreateSupportUserModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    partitionId: PropTypes.number,
    accountId: PropTypes.number,
};
