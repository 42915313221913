import { axiosService } from "../axios";

const TOKEN_BASE_URL = "/api/tokens";

export const tokensService = {
    fetchTokens: () => axiosService.get(TOKEN_BASE_URL),
    createToken: comment => axiosService.post(TOKEN_BASE_URL, { comment }),
    deleteToken: id => axiosService.delete(`${TOKEN_BASE_URL}/${id}`),

    fetchAccountTokens: ({ accountId }) =>
        axiosService.get(`/api/projects/${accountId}/token`),
    createAccountToken: ({ accountId, comment }) =>
        axiosService.post(`/api/projects/${accountId}/token`, { comment }),
    deleteAccountToken: ({ accountId, id }) =>
        axiosService.delete(`/api/projects/${accountId}/token/${id}`),
};
