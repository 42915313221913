import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import {
    HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT,
    fieldTypes,
} from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    useDeleteDownloadTargetMutation,
    useDownloadTargetsQuery,
} from "@/components/PartitionDataManagement/loadables";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { useBreadcrumbButtons } from "@/components/hooks/useBreadcrumbButtons.hook";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { isLoading } from "@/modules/loadable";

const preferencesType = preferencesTypes.PARTITION_CONNECTIONS_LIST_TABLE;

const columns = [
    {
        type: fieldTypes.TEXT,
        label: "Name",
        name: "name",
    },
    {
        type: fieldTypes.TEXT,
        label: "Type",
        name: "type",
    },
];

export const ConnectionsList = ({ projectId, partitionId, visible = true }) => {
    const {
        locationRouterService,
        accountAppLocations: {
            partitionNewConnectionLocation,
            partitionEditConnectionLocation,
        },
    } = useDic();
    const query = useDownloadTargetsQuery({ projectId, partitionId });
    const deleteMutation = useDeleteDownloadTargetMutation({
        partitionId,
        afterSuccess: query.reload,
    });

    useBreadcrumbButtons(
        () =>
            visible && [
                {
                    type: "primary",
                    label: t(
                        "location.accountApp.partitions.partition.data-management.new-connection",
                    ),
                    onClick: () =>
                        locationRouterService.navigate(
                            partitionNewConnectionLocation,
                        ),
                },
            ],
        [visible],
        "ConnectionsList",
    );

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.edit"),
                        onClick: record =>
                            locationRouterService.navigate(
                                partitionEditConnectionLocation,
                                {
                                    targetId: record.id,
                                },
                            ),
                    },
                    {
                        title: t("general.delete"),
                        confirm: {
                            message: t(
                                "partition.download.connections.action.delete.message",
                                { name: record.name },
                            ),
                            onConfirm: deleteMutation.mutate,
                        },
                        color: "red",
                        // track: { name: getTrackName("Delete"), },
                    },
                ]}
            />
        ),
        [
            deleteMutation.mutate,
            locationRouterService,
            partitionEditConnectionLocation,
        ],
    );

    return (
        <>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                columns={columns}
                dataSource={query.loadable.valueMaybe() || []}
                loading={isLoading(query)}
                fixed
                restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
                rowKey="id"
                datasetSlicing="local"
                preferencesType={preferencesType}
                visible={visible}
            />
        </>
    );
};

ConnectionsList.propTypes = {
    projectId: PropTypes.number.isRequired,
    partitionId: PropTypes.number.isRequired,
};
