import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { Gap, Link, Table, Text } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    LoadableRenderer,
    hasError,
    useQueryLoadable,
} from "@/modules/loadable";
import { maybeError } from "@/modules/loadable/utils";
import { UnauthorizedContent } from "@/security/authorization";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import moment from "moment";
import qs from "qs";
import React from "react";

export const columns = [
    {
        label: t("general.major-version"),
        name: "version",
        type: fieldTypes.TEXT,
        render: (_, record) => {
            return record.version;
        },
    },
    {
        label: t("general.date-of-upgrade"),
        name: "dateOfUpgrade",
        type: fieldTypes.DATE,
        render: (_, record) => {
            return (
                <FormattedDateTime>{record.dateOfUpgrade}</FormattedDateTime>
            );
        },
    },
];

const splitCandidateAndCurrentVersion = item => {
    if (item.candidateVersion) {
        return [
            {
                version: `${item.candidateVersion} (${t(
                    "version-history.candidate-version",
                )})`,
                dateOfUpgrade: item.candidateDateOfUpgrade,
                alerts: {
                    version_ALERT_TYPE: "YELLOW",
                    dateOfUpgrade_ALERT_TYPE: "YELLOW",
                },
            },
            {
                version: item.version,
                dateOfUpgrade: item.dateOfUpgrade,
            },
        ];
    } else {
        return [item];
    }
};

export const useVersionHistory = ({ entityId, entityType, queryParams }) => {
    const params = qs.stringify(queryParams, { addQueryPrefix: true });

    const { axiosService } = useDic();
    return useQueryLoadable(
        () =>
            axiosService
                .get(`/api/version-history/${entityType}/${entityId}${params}`)
                .then(getData)
                .then(data =>
                    data
                        .reduce(
                            (result, item) => [
                                ...result,
                                ...splitCandidateAndCurrentVersion(item),
                            ],
                            [],
                        )
                        .sort((a, b) => {
                            return moment(b.dateOfUpgrade).diff(
                                moment(a.dateOfUpgrade),
                            );
                        }),
                ),
        [(axiosService, entityId, entityType)],
    );
};

const KNOWLEDGE_BASE_URL =
    "https://knowledge.pricefx.com/display/UNI/Release+Notes#IntegrationManager";

export const VersionHistory = ({ versionHistoryResource }) => {
    if (
        hasError(versionHistoryResource.loadable) &&
        maybeError(versionHistoryResource.loadable).response?.status === 403
    ) {
        return <UnauthorizedContent />;
    }

    return (
        <LoadableRenderer
            loadable={versionHistoryResource.loadable}
            hasValue={versionHistoryList => {
                return (
                    <>
                        <Text dataTest="version-history-info">
                            {t("version-history.perex")}{" "}
                            <Link
                                data-test="kb-link"
                                href={KNOWLEDGE_BASE_URL}
                                targetBlank
                                displayIcon="always"
                            >
                                {t("version-history.knowledge-base")}
                            </Link>
                        </Text>
                        <Gap />
                        <div style={{ maxWidth: "400px" }}>
                            <Table
                                dataTest="version-history-table"
                                dataSource={versionHistoryList}
                                columns={columns}
                                padding={false}
                            />
                        </div>
                    </>
                );
            }}
        />
    );
};
