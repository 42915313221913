import { ButtonMenu, Modal, UnityLayout } from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

const MODAL_WIDTH = 640;

const Warning = ({
    title,
    children,
    onConfirm,
    onCancel,
    isDisabledConfirm = false,
    formId,
    visible = true,
    alert,
}) => {
    return (
        <Modal width={MODAL_WIDTH} visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header size={2} title={title} alert={alert} />
                <UnityLayout.Content padding={[false, true]}>
                    {children}
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.continue"),
                                    onClick: onConfirm,
                                    type: "primary",
                                    formId,
                                    loading: isDisabledConfirm,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onCancel,
                                    type: "text",
                                    disabled: isDisabledConfirm,
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

Warning.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    isDisabledConfirm: PropTypes.bool,
    visible: PropTypes.bool,
    formId: PropTypes.string,
    onCancel: PropTypes.func,
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export default Warning;
