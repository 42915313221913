import { axiosService } from "../../../axios";

export const accountsService = {
    fetchInstances(accountId) {
        return axiosService.get(`/api/projects/${accountId}/instances`);
    },

    fetchProvisionedInstances(accountId) {
        return axiosService.get(
            `/api/projects/${accountId}/provisioned-instances`,
        );
    },

    fetchAccounts() {
        return axiosService.get("/api/projects");
    },

    fetchAccountsByPermissions(permissions) {
        return axiosService.post("/api/projects", { permissions: permissions });
    },

    fetchAccount(accountId) {
        return axiosService.get(`/api/projects/${accountId}`);
    },

    fetchAccountDetail(accountId) {
        return axiosService.get(`/api/projects/${accountId}/detail`);
    },

    fetchRelatedContact(projectId) {
        return axiosService.get(`/api/projects/${projectId}/related-contacts`);
    },

    updateRelatedContact(projectId, body) {
        return axiosService.post(
            `/api/projects/${projectId}/related-contacts`,
            body,
        );
    },

    getExtendedAddIntegrationPermissions(accountId) {
        return axiosService.get(
            `/api/projects/${accountId}/extended-add-integration-permissions`,
        );
    },

    fetchAccountTypes() {
        return axiosService.get("/api/projects/types");
    },
};
