import { Menu } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { SidebarAndContentLayout } from "@/components/Layouts/SidebarAndContentLayout";
import TasksTable, { taskStatuses } from "@/components/Tasks/TasksTable";
import { useQueryLoadable } from "@/modules/loadable";
import { useRouteParams } from "@/modules/router";
import { t } from "@/translations";
import React from "react";
import { taskService } from "../../services/task.service";

export const useListOfTasks = () =>
    useQueryLoadable(() => {
        return taskService.getTasks();
    }, []);

const Tasks = () => {
    const {
        locationRouterService,
        locations: { tasksLocation },
    } = useDic();

    const { status } = useRouteParams();

    const tasksLoadable = useListOfTasks();

    useFullWidthTableLayout();

    return (
        <SidebarAndContentLayout
            sidebar={
                <Menu selectedKeys={[status]} mode="inline" condensed>
                    <Menu.Item
                        key={taskStatuses.STARTED}
                        onClick={() => {
                            locationRouterService.navigate(tasksLocation, {
                                status: taskStatuses.STARTED,
                            });
                        }}
                    >
                        {t("tasks.menu.STARTED")}
                    </Menu.Item>
                    <Menu.Item
                        key={taskStatuses.FAILED}
                        onClick={() => {
                            locationRouterService.navigate(tasksLocation, {
                                status: taskStatuses.FAILED,
                            });
                        }}
                    >
                        {t("tasks.menu.FAILED")}
                    </Menu.Item>
                    <Menu.Item
                        key={taskStatuses.DONE}
                        onClick={() => {
                            locationRouterService.navigate(tasksLocation, {
                                status: taskStatuses.DONE,
                            });
                        }}
                    >
                        {t("tasks.menu.DONE")}
                    </Menu.Item>
                </Menu>
            }
        >
            <TasksTable
                status={status}
                tasks={tasksLoadable.loadable?.contents?.data ?? []}
                loading={tasksLoadable.loadable.state === "loading"}
            />
        </SidebarAndContentLayout>
    );
};

export default Tasks;
