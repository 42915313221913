import { useDic } from "@/components/Dic/useDic.hook";
import { t } from "@/translations";
import { replace } from "@/utils/commons.utils";
import { getErrorMessage } from "@/utils/state/error.utils";
import { produce } from "immer";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useReducer } from "react";
import { PermissionAdminSettingsComponent } from "./PermissionAdminSettings.component";

const INIT_STATE = {
    businessRolesData: null,
    nonGlobalPermissions: [],
    selectedRole: { permissions: [] },
    grantedPermissions: new Set(),
    drawerVisible: false,
};

const assetReducer = (state, action) => {
    return {
        ...state,
        ...action,
    };
};

export const PermissionAdminSettingsContainer = ({
    setVisibleModal,
    visibleModal,
    accountId,
}) => {
    const [state, dispatch] = useReducer(assetReducer, INIT_STATE);

    const { businessRoleApiService, messageService } = useDic();

    const fetchBusinessRoles = useCallback(async () => {
        try {
            const businessRolesData =
                await businessRoleApiService.fetchBusinessRoles(accountId);

            dispatch({ businessRolesData });
        } catch (e) {
            messageService.error({
                content: getErrorMessage(e.response.data),
            });
        }
    }, [accountId, businessRoleApiService, messageService]);

    useEffect(() => {
        fetchBusinessRoles();
    }, [fetchBusinessRoles]);

    const onModalOk = useCallback(
        businessRole => {
            businessRoleApiService
                .crateBusinessRole(businessRole, accountId)
                .then(res => {
                    dispatch({
                        selectedRole: res.data,
                        drawerVisible: true,
                        grantedPermissions: new Set(),
                    });
                    fetchBusinessRoles();
                    setVisibleModal(false);
                })
                .catch(e => {
                    setVisibleModal(false);
                    messageService.error({
                        content: getErrorMessage(e.response.data),
                    });
                });
        },
        [
            accountId,
            businessRoleApiService,
            fetchBusinessRoles,
            setVisibleModal,
            messageService,
        ],
    );

    const onDrawerOpen = useCallback(record => {
        dispatch({
            selectedRole: record,
            grantedPermissions: getGrantedPermissionsSet(record),
            drawerVisible: true,
        });
    }, []);

    const onDrawerClose = useCallback(() => {
        dispatch({
            drawerVisible: false,
            selectedRole: { ...state.selectedRole },
        });
    }, [state.selectedRole]);

    const onDeleteBusinessRole = useCallback(
        bussinesRoleId => {
            businessRoleApiService
                .deleteBusinessRole(accountId, bussinesRoleId)
                .then(() => {
                    dispatch({
                        businessRolesData: state.businessRolesData.filter(
                            r => r.id !== bussinesRoleId,
                        ),
                    });
                    messageService.success({
                        content: t(
                            "account-admin-business-role-list-delete.message",
                        ),
                    });
                })
                .catch(e =>
                    messageService.error({
                        content: getErrorMessage(e.response.data),
                    }),
                );
        },
        [
            accountId,
            businessRoleApiService,
            messageService,
            state.businessRolesData,
        ],
    );

    const onRoleUpdate = useCallback(
        role => {
            businessRoleApiService
                .updateBusinessRole(role, accountId)
                .then(() => {
                    fetchBusinessRoles();
                })
                .catch(e =>
                    messageService.error({
                        content: getErrorMessage(e.response.data),
                    }),
                );
        },
        [accountId, businessRoleApiService, fetchBusinessRoles, messageService],
    );

    const onDrawerSave = useCallback(
        role => {
            businessRoleApiService
                .updateBusinessRole(
                    enhanceRoleWithPermissions(role, state.grantedPermissions),
                    accountId,
                )
                .then(res => {
                    dispatch({
                        selectedRole: res.data,
                        drawerVisible: false,
                        businessRolesData: replace(
                            state.businessRolesData,
                            res.data,
                            "name",
                        ),
                    });
                })
                .catch(e =>
                    messageService.error({
                        content: getErrorMessage(e.response.data),
                    }),
                );
        },
        [
            accountId,
            businessRoleApiService,
            state.businessRolesData,
            state.grantedPermissions,
            messageService,
        ],
    );

    const onPermissionGrantedChange = useCallback(
        (permission, isGranted) => {
            dispatch({
                grantedPermissions: produce(state.grantedPermissions, draft => {
                    if (isGranted) {
                        draft.add(permission.name);
                    } else {
                        draft.delete(permission.name);
                    }
                }),
            });
        },
        [state.grantedPermissions],
    );

    return (
        state.businessRolesData && (
            <PermissionAdminSettingsComponent
                data={state.businessRolesData}
                grantedPermissions={state.grantedPermissions}
                onModalCancel={() => setVisibleModal(false)}
                onModalOk={onModalOk}
                onDrawerOpen={onDrawerOpen}
                onDrawerClose={onDrawerClose}
                onDrawerSave={onDrawerSave}
                visibleModal={visibleModal}
                drawerVisible={state.drawerVisible}
                selectedRole={state.selectedRole}
                onDeleteBusinessRole={onDeleteBusinessRole}
                onPermissionGrantedChange={onPermissionGrantedChange}
                onRoleUpdate={onRoleUpdate}
            />
        )
    );
};

PermissionAdminSettingsContainer.propTypes = {
    accountId: PropTypes.number.isRequired,
    visibleModal: PropTypes.bool.isRequired,
    setVisibleModal: PropTypes.func.isRequired,
};

export function getGrantedPermissionsSet(businessRole) {
    return new Set(businessRole.permissions.map(p => p.name));
}

export function enhanceRoleWithPermissions(businessRole, permissions) {
    return {
        ...businessRole,
        permissions: [...permissions].map(p => ({
            name: p,
        })),
    };
}
