import { ButtonMenu, Modal, UnityLayout } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import featurePagination from "@/components/DesignSystem/Table/features/pagination";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Moment from "react-moment";
import { TableLocalFiltered } from "../TableLocalFiltered";

const historyColumns = () => [
    {
        type: fieldTypes.TEXT,
        label: t("alert-rules-list.histroy-table.header.operation-type"),
        name: "operationType",
    },
    {
        type: fieldTypes.TEXT,
        label: t("alert-rules-list.histroy-table.header.changed-by"),
        name: "changedBy",
    },
    {
        type: fieldTypes.DATE,
        label: t("alert-rules-list.histroy-table.header.changed-at"),
        name: "changedAt",
        render: (text, record) =>
            record.changedAt && (
                <Moment format="DD/MM/YYYY HH:mm:ss">{record.changedAt}</Moment>
            ),
    },
];

export const HistoryModal = ({ visible, closeHistory, eventLogs }) => {
    const [pageSize, setPageSize] = useState(10);

    const onPageSizeChange = (current, size) => {
        setPageSize(size);
    };

    return (
        <Modal
            // bodyStyle={{ padding: 0 }} // TODO: check
            visible={visible}
            onClose={closeHistory}
        >
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("alert-rule.history.modal.title")}
                />
                <UnityLayout.Content padding={[false, false]}>
                    <TableLocalFiltered
                        condensed
                        columns={historyColumns()}
                        dataSource={eventLogs}
                        pagination={{
                            ...featurePagination({
                                onPageSizeChange,
                                pageSize,
                                totalRows: 0,
                            }).pagination,
                            hideOnSinglePage: false,
                        }}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.close"),
                                    onClick: closeHistory,
                                    type: "primary",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

HistoryModal.propTypes = {
    visible: PropTypes.bool,
    closeHistory: PropTypes.func.isRequired,
    eventLogs: PropTypes.object,
};
