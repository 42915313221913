import { CREATE_TABLE_SUPPORTED_TYPES } from "@/components/DataUploads/Wizard/NewTable/NewTableModal";
import { ACCEPTED_FORMATS } from "@/components/DataUploads/Wizard/constants";
import { getSupportedExtensionsString } from "@/components/DataUploads/utils";
import {
    Alert,
    ButtonMenu,
    Forms,
    Gap,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    EntitySelectFields,
    SOURCE_TYPE,
} from "@/components/Mappers/ExportMapper/EntitySelectFields";
import { STORAGE_VARIANT } from "@/components/Upload/useUploadStorage.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { deployToEnum } from "@/constants/deployment.constants";
import { TrackingContext } from "@/mixpanel/TrackingContextProvider";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { noop } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { defaultParserConfigValues } from "../FileParsing/FileParsingFields";
import Upload from "../Upload/Upload";
const { useForm, Form, Fields, pmValidators } = Forms;

const VIEWS = {
    DEFAULT: "default",
    SUCCESS: "success",
    ERROR: "error",
};

const getActionButtonPerView = ({ view, onReupload, onCancel, formId }) => {
    switch (view) {
        case VIEWS.DEFAULT:
            return [
                {
                    label: t("general.submit"),
                    type: "primary",
                    formId,
                },
                {
                    label: t("general.cancel"),
                    type: "text",
                    onClick: onCancel,
                },
            ];
        case VIEWS.ERROR:
            return [
                {
                    label: t("quick-data-upload.reupload"),
                    type: "primary",
                    onClick: onReupload,
                },
                {
                    label: t("general.cancel"),
                    type: "text",
                    onClick: onCancel,
                },
            ];
        case VIEWS.SUCCESS:
            return [
                {
                    label: t("quick-data-upload.reupload"),
                    type: "text",
                    onClick: onReupload,
                },
                {
                    label: t("general.close"),
                    type: "text",
                    onClick: onCancel,
                },
            ];
        default:
            return [];
    }
};

const QuickDataUploadModal = ({
    projectId,
    partitionId,
    visible,
    onSuccess = noop,
    onCancel: onPassedCancel,
}) => {
    const [view, setView] = useState(VIEWS.DEFAULT);
    const [alert, setAlert] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const { projectPartitionApiService } = useDic();

    const onSuccessCurrent = useCurrentHandler(onSuccess);
    const onCancel = () => {
        setAlert(null);
        setView(VIEWS.DEFAULT);
        onPassedCancel?.();
    };

    const { trackDataUpload } = useContext(TrackingContext);

    const onSubmit = ({ values }) => {
        const trackingProps = {
            uploadFileName: uploadedFile.originalName,
            uploadType: "quick",
            uploadTargetType: values.entityType,
            uploadSchedule: "instant",
        };
        setAlert(null);
        setView(VIEWS.DEFAULT);

        projectPartitionApiService
            .fastUpload(projectId, partitionId, uploadedFile.id, {
                ...values,
                parserConfig: defaultParserConfigValues,
            })
            .then(() => {
                trackDataUpload({
                    ...trackingProps,
                    uploadResult: "success",
                });
                setView(VIEWS.SUCCESS);
                setAlert({
                    message: t("quick-data-upload.upload.success", {
                        fileName: uploadedFile.originalName,
                    }),
                    showIcon: true,
                    type: "success",
                    banner: true,
                });
                onSuccessCurrent();
            })
            .catch(error => {
                trackDataUpload({
                    ...trackingProps,
                    uploadResult: "failed",
                });
                setView(VIEWS.DEFAULT);
                setAlert({
                    message: getErrorMessage(error.response.data),
                    showIcon: true,
                    type: "error",
                    banner: true,
                });
            });
    };

    const { formId, handleSubmit, setTouched, setValues } = useForm({
        onSubmit,
    });

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("quick-data-upload.header.title")}
                    alert={alert && <Alert {...alert} />}
                />
                <UnityLayout.Content padding={[false, true]}>
                    {/* Designed, but not used right now */}
                    {/* {view === VIEWS.ERROR && (
                        <>
                            <FileDownload />{" "}
                            <Link
                                onClick={() =>
                                    downloadFile("value", "name.txt")
                                }
                            >
                                {t("quick-data-upload.download-error-file")}
                            </Link>
                            <Gap size="large" />
                        </>
                    )} */}
                    {view === VIEWS.SUCCESS && <></>}
                    {view === VIEWS.DEFAULT && (
                        <>
                            <Text>{t("quick-data-upload.perex")}</Text>
                            <Form formId={formId} onSubmit={handleSubmit}>
                                <Gap />

                                <Fields.Input
                                    name="name"
                                    label={t(
                                        "quick-data-upload.fields.data-upload-name.label",
                                    )}
                                    placeholder={t(
                                        "quick-data-upload.fields.data-upload-name.placeholder",
                                    )}
                                    required
                                    validator={pmValidators.isRequired}
                                    inputWidth="max"
                                />

                                <EntitySelectFields
                                    allowCreatePartitionTableFor={
                                        CREATE_TABLE_SUPPORTED_TYPES
                                    }
                                    sourceType={SOURCE_TYPE.PARTITIONS}
                                    sourceId={partitionId}
                                    projectId={projectId}
                                    formId={formId}
                                    setTouched={setTouched}
                                    setValues={setValues}
                                    inputWidth="max"
                                />

                                <Upload
                                    accountId={projectId}
                                    storage={STORAGE_VARIANT.S3}
                                    destination={deployToEnum.PARTITION}
                                    destinationId={partitionId}
                                    onFileUploaded={file => {
                                        setUploadedFile(file);
                                        setAlert(null);
                                    }}
                                    hintMessage={t(
                                        "quick-data-upload.fields.upload.description",
                                        {
                                            supportedFormats:
                                                getSupportedExtensionsString(
                                                    ACCEPTED_FORMATS,
                                                ),
                                        },
                                    )}
                                    multiple={false}
                                    accept={ACCEPTED_FORMATS.join(",")}
                                />
                            </Form>
                        </>
                    )}
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={getActionButtonPerView({
                                view,
                                onReupload: () => {
                                    setAlert(null);
                                    setView(VIEWS.DEFAULT);
                                },
                                onCancel,
                                formId,
                            })}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

QuickDataUploadModal.propTypes = {
    projectId: PropTypes.number.isRequired,
    partitionId: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
};

export default QuickDataUploadModal;
