import { Checkbox, Gap, H5, Text } from "@/components/DesignSystem";
import { defaultPreviewCellProps } from "@/components/Packages/PackageTableDefinitionPanel/components/PackageDataMappingLayout/DataPreviewTable.component";
import { T } from "@/translations";
import { produce } from "immer";
import union from "lodash/union";
import values from "lodash/values";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { t } from "../../../../../translations/translations.service";
import { CompositeSelect } from "../../../../CompositeSelect/CompositeSelect";
import {
    disableBusinessKey,
    disableNextButton,
    getAvailableOptions,
    getSelectedType,
    getTypeLabel,
    resolveBusinessColumns,
    resolveNewFieldValue,
} from "../../../packagesPanel.utils";
import { MultipleTitle } from "../MultipleTitle/MultipleTitle";
import PackageDataMappingLayout from "../PackageDataMappingLayout/PackageDataMappingLayout";
import { calculateExistingFields } from "../PackageMandatoryFields/PackageMandatoryFields.utils";
import { PackageTable } from "../PackageTable/PackageTable";
import { handleRowClassName } from "../PricePrameters/priceParemeters.utils";
import styles from "../styles.less";

export const getMandatoryFields = (
    fields,
    mandatoryFields,
    fieldsFromFile,
    businessKey = [],
    businessKeyChangeable = true,
) => {
    const newFields = calculateExistingFields(
        fields,
        mandatoryFields,
        fieldsFromFile,
    );
    return values(newFields).map(m => ({
        ...m,
        businessKey: m?.businessKey || businessKey.find(key => key === m.name),
        businessKeyChangeable,
    }));
};

export const ExtensionMandatoryFields = ({
    globalState,
    step,
    onNext,
    onBack,
    changeState,
    onCancel,
}) => {
    const {
        fileInfo: tableExampleData,
        mandatoryFields = [],
        optionalFields = [],
    } = globalState;
    const setMandatoryFields = f => changeState({ mandatoryFields: f });

    useEffect(() => {
        setMandatoryFields(
            getMandatoryFields(
                mandatoryFields,
                step.mandatoryFields,
                tableExampleData.columns,
                step.businessKey,
                step.allowChangeBusinessKey,
            ),
        );
    }, []);

    const handleBusinessKey = (record, checked) => {
        setMandatoryFields(
            produce(mandatoryFields, draft => {
                const field = draft.find(field => field.name === record.name);
                field.businessKey = checked;
            }),
        );
    };

    const handleNext = () => {
        onNext({ mandatoryFields: mandatoryFields });
    };

    const customColumns = ({ name, type }) => {
        return {
            ...defaultPreviewCellProps,
            label: createTitle(
                name,
                getSelectedType(optionalFields, name) || type,
            ),
            name,
            render: text => <div>{`${text}`}</div>,
        };
    };

    const createTitle = (name, type) => {
        const show = resolveBusinessColumns(
            mandatoryFields,
            optionalFields,
        ).includes(name);
        return (
            <div>
                {name}
                {!show && (
                    <div className={styles.type}>{getTypeLabel(type)}</div>
                )}
                {show && (
                    <div className={styles.businessKey}>
                        <i className={"uil uil-key-skeleton-alt"} />
                        {getTypeLabel(type)}
                    </div>
                )}
            </div>
        );
    };

    const columns = [
        {
            key: "pfxFieldName",
            width: "50%",
            title: () => t("package-data-upload.label.mandatory-fields"),
            render: (text, record) => (
                <Fragment>
                    <H5 className={styles.headingColor}>{record.label}</H5>
                    <Text size="small" className={styles.descriptionColor}>
                        {record.description}
                    </Text>
                </Fragment>
            ),
        },
        {
            key: "valueFromFile",
            className: "column-valueFromFile",
            // TODO: fix padding in multiple title when moving advanced field editor icon
            title: () => (
                <MultipleTitle
                    mandatoryFields={mandatoryFields}
                    fileName={tableExampleData.fileName}
                />
            ),
            render: (text, record) => (
                <CompositeSelect
                    type={record.type}
                    input={record.value}
                    inputType={record.inputType}
                    formulaMapping={record.formulaMapping}
                    tableExampleData={tableExampleData}
                    onChange={({ input: value, inputType, formulaMapping }) =>
                        setMandatoryFields(
                            resolveNewFieldValue(
                                record,
                                { value, inputType, formulaMapping },
                                tableExampleData.columns,
                                mandatoryFields,
                            ),
                        )
                    }
                >
                    {getAvailableOptions(
                        record,
                        tableExampleData.columns,
                        mandatoryFields,
                    )}
                </CompositeSelect>
            ),
        },
        {
            key: "businessKey",
            title: () => t("package-data-upload.label.business-key"),
            render: (text, record) => {
                return (
                    <Checkbox
                        onChange={e =>
                            handleBusinessKey(record, e.target.checked)
                        }
                        checked={record.businessKey}
                        disabled={disableBusinessKey(
                            union(optionalFields, mandatoryFields),
                            record,
                        )}
                    />
                );
            },
        },
    ];

    return (
        (mandatoryFields && (
            <PackageDataMappingLayout
                dataExample={tableExampleData}
                disableNextButton={disableNextButton(mandatoryFields)}
                customColumns={customColumns}
                onNext={handleNext}
                onBack={onBack}
                onCancel={onCancel}
            >
                <Text>
                    <T id="package-data-upload.extension-mandatory-files.message" />
                </Text>
                <Gap size="small" />
                <PackageTable
                    fields={mandatoryFields}
                    handleRowClassName={handleRowClassName}
                    columns={columns}
                />
                <Gap size="medium" />
            </PackageDataMappingLayout>
        )) || <div />
    );
};

ExtensionMandatoryFields.propTypes = {
    globalState: PropTypes.object.isRequired,
    changeState: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    step: PropTypes.object.isRequired,
};
