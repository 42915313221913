import { Menu } from "@/components/DesignSystem";
import { LocationLink } from "@/modules/router";
import { VisibleWhenHasPermission } from "@/security/authorization";
import { T } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import { TrackingContext } from "../../mixpanel/TrackingContextProvider";
import { ErrorBoundary } from "../Error";

export function LocationMenuItem({
    $location,
    $locationParams = {},
    $locationProps = {},
    menuItemComponent = Menu.Item,
    linkTextTranslationId,
    onClick,
    ...props
}) {
    const MenuItemComponent = menuItemComponent;
    const { handleMenuClick } = useContext(TrackingContext);
    const handleClick = useCallback(
        (...args) => {
            handleMenuClick({ location: $location });
            onClick?.(...args);
        },
        [onClick, handleMenuClick, $location],
    );

    return (
        <ErrorBoundary>
            <VisibleWhenHasPermission
                permission={$location.permission}
                permissionCheckFunc={$location.permissionFunc}
            >
                <MenuItemComponent
                    data-test={$location.routeName}
                    key={$location.routeName}
                    onClick={handleClick}
                    {...props}
                >
                    <LocationLink
                        $location={$location}
                        $params={$locationParams}
                        {...$locationProps}
                    >
                        <T
                            id={
                                linkTextTranslationId
                                    ? linkTextTranslationId
                                    : `location.${$location.routeName}`
                            }
                        />
                    </LocationLink>
                </MenuItemComponent>
            </VisibleWhenHasPermission>
        </ErrorBoundary>
    );
}

LocationMenuItem.propTypes = {
    $location: PropTypes.object.isRequired,
    $locationParams: PropTypes.object,
    $locationProps: PropTypes.object,
    menuItemComponent: PropTypes.func,
    linkTextTranslationId: PropTypes.string,
};
