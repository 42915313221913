import {
    Alert,
    ButtonMenu,
    Gap,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import {
    createErrorAlert,
    updateStep,
} from "@/components/Integrations/ImUpdate/ImUpdate.component";
import { isProcessRunning } from "@/components/Integrations/ImUpdate/helpers";
import { useDirectUpdate } from "@/components/Integrations/ImUpdate/useDirectUpdate.hook";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { getDirectUpdateModalTrackName as getTrackName } from "../../../mixpanel/buttonNames";

const DirectUpdateModal = ({
    instanceId,
    versionTo,
    visible,
    onCreateSupportTicket,
    onStartNewUpdate,
    onNextStep,
    onCancel,
}) => {
    const [message, setMessage] = useState(null);

    const directUpdate = useDirectUpdate({
        onError: error =>
            setMessage(
                createErrorAlert({
                    error,
                    onCreateSupportTicket,
                }),
            ),
    });

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("im-instance.update.direct.modal.title")}
                    alert={
                        message ? (
                            <Alert
                                showIcon
                                type="error"
                                banner
                                message={message}
                            />
                        ) : null
                    }
                />
                <UnityLayout.Content padding={[false, true]}>
                    {t("im-instance.update.direct.modal.message")}
                    <Gap />
                    <Alert
                        message={t("im-instance.update.direct.modal.warning")}
                        type="warning"
                        showIcon
                        closable
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.continue"),
                                    type: "primary",
                                    disabled: isProcessRunning(
                                        directUpdate.task,
                                    ),
                                    onClick: () => {
                                        directUpdate.update({
                                            instanceId,
                                            versionTo,
                                            onSuccess: process =>
                                                onNextStep(
                                                    updateStep.FINISH_UPDATE,
                                                )(process),
                                        });
                                    },
                                    track: { name: getTrackName("Deploy") },
                                },
                                {
                                    label: t("general.cancel"),
                                    type: "text",
                                    onClick: onCancel,
                                    track: { name: getTrackName("Cancel") },
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

DirectUpdateModal.propTypes = {
    instanceId: PropTypes.number.isRequired,
    versionTo: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
    onCreateSupportTicket: PropTypes.func.isRequired,
    onStartNewUpdate: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func,
};

export default DirectUpdateModal;
