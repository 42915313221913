import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { SFTPServerUsersList } from "@/components/SFTPServers/SFTPServerUsers.component";
import {
    useDeleteSftpUserMutation,
    useSftpServerQuery,
    useSftpServerUsersQuery,
} from "@/components/SFTPServers/loadables";
import { useBreadcrumbButtons } from "@/components/hooks/useBreadcrumbButtons.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { SFTP_SERVER_TYPE } from "@/services/sftp.service";
import { t } from "@/translations";
import React, { useCallback } from "react";

export const TABS = {
    SFTP_SERVERS: "SFTP_SERVERS",
    EMAIL_NOTIFICATIONS: "EMAIL_NOTIFICATIONS",
};

export function SFTPServerUsersListPage() {
    const { serverId } = useAccountAppParams();
    const { locationRouterService, accountAppLocations } = useDic();

    useFullWidthTableLayout();
    useSetPageTitle(
        t(
            "location.accountApp.instances.instance.sftp-servers.user-management",
        ),
    );

    const sftpServerUsersQuery = useSftpServerUsersQuery({ serverId });
    const deleteSftpUserMutation = useDeleteSftpUserMutation({
        serverId,
        afterSuccess: sftpServerUsersQuery.reload,
    });
    const sftpServerQuery = useSftpServerQuery({ serverId });
    const maybeServerInfo = sftpServerQuery.loadable.valueMaybe();
    const isLDAP = maybeServerInfo?.type === SFTP_SERVER_TYPE.LDAP;

    useSetBackButtonAction(
        useCurrentHandler(() =>
            locationRouterService.navigate(
                accountAppLocations.sftpServersLocation,
            ),
        ),
    );

    const createOrEdit = useCallback(
        userId => () =>
            locationRouterService.navigate(
                accountAppLocations.sftpServerUserLocation,
                { userId },
            ),
        [locationRouterService, accountAppLocations.sftpServerUserLocation],
    );

    useBreadcrumbButtons(
        () =>
            isLDAP
                ? [{ label: "general.add-user", onClick: createOrEdit("new") }]
                : [],
        [createOrEdit, isLDAP],
    );

    return (
        <>
            <SFTPServerUsersList
                createOrEdit={createOrEdit}
                sftpServerUsersQuery={sftpServerUsersQuery}
                deleteSftpUserMutation={deleteSftpUserMutation}
            />
        </>
    );
}
