import { useDic } from "@/components/Dic/useDic.hook";
import { useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useTemplatesTags = () => {
    const { templateAdminService } = useDic();

    return useQueryLoadable(
        async () => templateAdminService.getTemplateTags().then(getData),
        [templateAdminService],
    );
};
export const useTemplatesCategories = () => {
    const { marketplaceItemsService } = useDic();

    return useQueryLoadable(
        async () =>
            marketplaceItemsService
                .getCategories()
                .then(getData)
                .then(({ items }) => items),
        [marketplaceItemsService],
    );
};
