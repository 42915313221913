import { Button } from "@/components/DesignSystem";
import { Icon } from "@/components/DesignSystem/Icon";
import { useDic } from "@/components/Dic/useDic.hook";
import { LocationLink } from "@/modules/router";
import { SecurityContext } from "@/security/authorization";
import { t } from "@/translations";
import { T } from "@/translations";
import { getDefaultPartitionLocation } from "@/utils/location/defaultLocation.utils";
import PropTypes from "prop-types";
import React, { useContext } from "react";

const openPartition = url => {
    const win = window.open(url, "_blank");
    win.focus();
};

const externalLink = url => {
    if (url) {
        return (
            <Icon type="external-link-alt" onClick={() => openPartition(url)} />
        );
    }
};

export const useDefaultPartition = ({ partitionId, accountId, hasRight }) => {
    const { accountAppLocations, locationRouterService } = useDic();
    const secContext = useContext(SecurityContext);

    if (partitionId != null && hasRight) {
        const { location, translationKey } = getDefaultPartitionLocation(
            partitionId,
            secContext,
            accountAppLocations,
        );
        const handleNavigate = () => {
            locationRouterService.navigate(location, {
                accountId,
                partitionId,
            });
        };

        return { location, translation: t(translationKey), handleNavigate };
    } else {
        return {};
    }
};

export const DefaultPartitionButton = ({
    partitionId,
    accountId,
    hasRight,
    type = "text",
}) => {
    const { translation, handleNavigate } = useDefaultPartition({
        partitionId,
        accountId,
        hasRight,
    });

    return (
        <Button
            onClick={handleNavigate}
            label={translation}
            type={type}
            htmlType="button"
        />
    );
};

const PartitionNameColumnRenderer = ({
    url,
    partitionId,
    projectId,
    value,
    hasRight,
}) => {
    const { location, translation } = useDefaultPartition({
        accountId: projectId,
        partitionId,
        hasRight,
    });

    if (location) {
        return (
            <>
                {url ? externalLink(url) : null}
                <LocationLink
                    $location={location}
                    $params={{
                        accountId: projectId,
                        partitionId,
                    }}
                >
                    {value || translation}
                </LocationLink>
            </>
        );
    }

    return (
        <>
            {url ? externalLink(url) : null} {value}
        </>
    );
};

PartitionNameColumnRenderer.propTypes = {
    partitionId: PropTypes.any,
    projectId: PropTypes.number,
    url: PropTypes.string,
    value: PropTypes.string,
    hasRight: PropTypes.bool.isRequired,
    withExternalLink: PropTypes.bool,
};

export default PartitionNameColumnRenderer;
