import { trim } from "lodash";

export const mapPartitionsToOptions = partitions =>
    partitions?.map?.(p => ({
        label: `${p.serialNumber}`,
        value: p.id,
    })) ?? [];

export const mapInstancesToOptions = instances =>
    instances?.map?.(({ instanceName, server, state, id }) => ({
        value: id,
        label: trim(
            `${instanceName} ${server ? `(${server})` : ""}${
                state === "REQUESTED" ? " Requested" : ""
            }`,
        ),
    })) ?? [];
