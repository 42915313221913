import { Button, Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useTemplateAdmin } from "@/components/Marketplace/components/WarningStep/loadableResoures";
import { HeaderTabs } from "@/components/PageLayout/components/HeaderTabs.component";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { setValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { useRouteParams } from "@/modules/router";
import { t } from "@/translations";
import { getErrorMessageFromError } from "@/utils/state/error.utils";
import React, { useCallback, useEffect, useMemo } from "react";
import { locationsMap } from "../../../../locations";
import { StepsManagement } from "./edit/StepsManagement.component";
import { useTemplatesTags } from "@/components/Marketplace/components/TagsMenu/loadables";

export const TemplateEditPage = () => {
    const routeParams = useRouteParams();
    const {
        templateAdminService,
        locationRouterService,
        marketplaceAppLocations,
        messageService,
    } = useDic();

    if (!routeParams?.uniqueName) {
        locationRouterService.navigate(locationsMap.notFoundLocation);
    }

    const templateQuery = useTemplateAdmin(routeParams.uniqueName);
    const template = templateQuery.loadable.valueMaybe();
    const templateTagsQuery = useTemplatesTags();
    const templateTags = templateTagsQuery.loadable.valueMaybe();
    const templateTagsOptions = useMemo(
        () =>
            templateTags?.map(tag => ({
                value: tag,
                label: tag,
            })) || [],
        [templateTags],
    );

    const onBack = useCallback(
        () =>
            locationRouterService.navigate(
                marketplaceAppLocations.templatesManagementLocation,
            ),
        [
            locationRouterService,
            marketplaceAppLocations.templatesManagementLocation,
        ],
    );
    useSetBackButtonAction(onBack);

    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit: async ({ values: template }) => {
            try {
                await templateAdminService.editTemplate(
                    routeParams?.uniqueName,
                    template,
                );
                locationRouterService.navigate(
                    marketplaceAppLocations.templatesManagementLocation,
                );
                messageService.success({
                    content: t("template.edit-form.notification-success"),
                });
            } catch (e) {
                messageService.error({
                    content: getErrorMessageFromError(e),
                });
            }
        },
    });

    useEffect(() => {
        if (template && setValues && setTouched) {
            setValidatedInitialValues({
                initialValues: template,
                setValues,
                setTouched,
            });
        }
    }, [template, setValues, setTouched]);

    useSetPageTitle(template?.label);

    const TABS = {
        GENERAL_SETTINGS: "general-settings",
        STEPS: "steps",
    };

    return (
        <HeaderTabs defaultActiveKey={TABS.GENERAL_SETTINGS}>
            <HeaderTabs.TabPane
                tab={t(`template.edit-form.tab.${TABS.GENERAL_SETTINGS}`)}
                key={TABS.GENERAL_SETTINGS}
            >
                <Forms.Form formId={formId} onSubmit={handleSubmit}>
                    <Forms.Fields.Input
                        name="label"
                        label={t("template.edit-form.label")}
                        placeholder={t("template.edit-form.label.placeholder")}
                        validator={Forms.pmValidators.isRequired}
                        required
                    />
                    <Forms.Fields.TextArea
                        name="documentationLink"
                        label={t("template.edit-form.documentationUrl.label")}
                        placeholder={t(
                            "template.edit-form.documentationUrl.placeholder",
                        )}
                        validator={Forms.pmValidators.noValueOr(
                            Forms.pmValidators.isValidUrl,
                        )}
                    />
                    <Forms.Fields.Select
                        name="type"
                        label={t("template.edit-form.packageType")}
                        options={[
                            {
                                label: "Accelerators Templates",
                                value: "solutions",
                            },
                            {
                                label: "Accelerator Package",
                                value: "packages",
                            },
                            {
                                label: "Integration Templates",
                                value: "integrations",
                            },
                            {
                                label: "Non approved",
                                value: "non_approved",
                            },
                        ]}
                        validator={Forms.pmValidators.isRequired}
                        required
                    />
                    <Forms.Fields.TableSelect
                        name="tags"
                        label={t("template.edit-form.tags-visibility")}
                        labelColumnLabel={t("template.edit-form.tags")}
                        dataSource={templateTagsOptions}
                    />
                    <Forms.SubmitButton>
                        <Button
                            label={t("general.save")}
                            type="primary"
                            htmlType="submit"
                        />
                    </Forms.SubmitButton>
                    <Button
                        type="text"
                        onClick={onBack}
                        label={t("general.cancel")}
                    />
                </Forms.Form>
            </HeaderTabs.TabPane>
            <HeaderTabs.TabPane
                tab={t(`template.edit-form.tab.${TABS.STEPS}`)}
                key={TABS.STEPS}
            >
                <StepsManagement templateName={routeParams?.uniqueName} />
            </HeaderTabs.TabPane>
        </HeaderTabs>
    );
};

TemplateEditPage.propTypes = {};
