import { useCenteredLayout } from "@/components/hooks/useContentLayout.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { hasValue, useMapLoadableMemoized } from "@/modules/loadable";
import map from "lodash/fp/map";
import React, { useMemo, useState } from "react";
import {
    inMutationInitialState,
    useGetAdminPfxIdentification,
    useGetAdminSamlCerts,
    useGetAdminSamlMgmt,
    useGetAllSamlAccounts,
    usePostAdminPfxIdentification,
    usePostAdminSamlMgmt,
} from "./loadables";
import { SingleSignOnForm } from "./SingleSignOnForm";

export const SingleSignOnAdminPage = () => {
    useCenteredLayout();
    const [projectId, setProjectId] = useState();
    const accountOptionsLoadable = useMapLoadableMemoized(
        useGetAllSamlAccounts().loadable,
        useCurrentHandler(
            map(account => ({
                label: account.name,
                value: account.id,
            })),
        ),
    );
    const getPfxIdentificationResource = useGetAdminPfxIdentification({
        projectId,
        canFetch: !!projectId,
    });
    const postPfxIdentificationResource = usePostAdminPfxIdentification({
        projectId,
    });
    const pfxIdentificationLoadable = inMutationInitialState(
        postPfxIdentificationResource,
    )
        ? getPfxIdentificationResource.loadable
        : postPfxIdentificationResource.loadable;

    // TODO: stale pfxIdentificationLoadable for one render cycle on projectId change
    const projectSamlMgmt = useGetAdminSamlMgmt({
        projectId,
        canFetch: !!projectId && hasValue(pfxIdentificationLoadable),
    });
    const certificatesResource = useGetAdminSamlCerts({
        projectId,
        canFetch: !!projectId && hasValue(pfxIdentificationLoadable),
    });
    const { mutate } = usePostAdminSamlMgmt({ projectId });
    const projectName = useMemo(
        () =>
            accountOptionsLoadable
                .valueMaybe()
                ?.find(({ value }) => value === projectId)?.label,
        [accountOptionsLoadable, projectId],
    );

    return (
        <SingleSignOnForm
            isAdmin
            initialValuesLoadable={projectSamlMgmt.loadable}
            onSubmit={mutate}
            accountsLoadable={accountOptionsLoadable}
            onAccountChange={setProjectId}
            projectId={projectId}
            projectName={projectName}
            certificatesResource={certificatesResource}
            pfxIdentificationLoadable={pfxIdentificationLoadable}
            generatePfxIdentification={postPfxIdentificationResource.mutate}
        />
    );
};

SingleSignOnAdminPage.propTypes = {};
