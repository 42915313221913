import qs from "qs";
import { axiosService } from "../axios";

const qsOptions = { addQueryPrefix: true };

// Equals certificate.id when updating existing
export const getObjectKey = ({ name, instanceId }) =>
    btoa(JSON.stringify({ name, instanceId }));

export const certificatesService = {
    saveCertificate(instanceId, certificateId, certificateData, query) {
        const queryStr = qs.stringify(query, qsOptions);
        return axiosService.post(
            `/api/im-certificates/${certificateId}${queryStr}`,
            { ...certificateData, instanceId },
        );
    },

    createCertificate(instanceId, certificateData, query) {
        const queryStr = qs.stringify(query, qsOptions);
        return axiosService.post(`/api/im-certificates${queryStr}`, {
            ...certificateData,
            instanceId,
        });
    },

    fetchCertificates(instanceId) {
        return axiosService.get(`/api/instances/${instanceId}/im-certificates`);
    },

    fetchCertificate(instanceId, certificateId) {
        return axiosService.get(`/api/im-certificates/${certificateId}`);
    },

    deleteCertificate(instanceId, certificateId) {
        return axiosService.delete(`/api/im-certificates/${certificateId}`);
    },

    deployCertificate(instanceId, certificateId, query) {
        const queryStr = qs.stringify(query, qsOptions);
        return axiosService.post(
            `/api/instances/${instanceId}/certificates/${certificateId}/deploy${queryStr}`,
        );
    },

    fetchInstanceRepoCertificates(instanceId) {
        return axiosService.get(
            `/api/instances/${instanceId}/im-certificates/online`,
        );
    },

    createLocalCopy(objectKey, payload) {
        return axiosService.post(
            `/api/im-certificates/${objectKey}/online-copy`,
            payload,
        );
    },

    deleteInstanceRepoCertificate({ objectKey, prn }) {
        return axiosService.delete(`/api/im-certificates/${objectKey}/online`, {
            data: prn ? { prn } : null,
        });
    },
    certificateExists({ instanceId, name, withOnline = true }) {
        const objectKey = getObjectKey({ name, instanceId });
        return axiosService.get(
            `/api/im-certificates/${objectKey}/exists${qs.stringify(
                { name, withOnline },
                qsOptions,
            )}`,
        );
    },
};
