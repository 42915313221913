import { t } from "@/translations";

export const getSupportedExtensionsString = extensionsArray => {
    const upCaseExts = extensionsArray.map(ext =>
        ext.replace(/^\./, "").toUpperCase(),
    );

    return upCaseExts.length <= 2
        ? upCaseExts.join(` ${t("general.or")} `)
        : `${upCaseExts.slice(0, -1).join(", ")}, ${t("general.or")} ${
              upCaseExts[upCaseExts.length - 1]
          }`;
};

export const showMassDelete = entityType =>
    !["DMDS", "LPG", "XLPG", "U", "PLI", "XPLI"].includes(entityType);
