import ProjectIntegrationForm from "@/components/IntegrationRoutes/form/ProjectIntegrationForm";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useQueryLoadable } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React from "react";

export const NewRoutePage = () => {
    const { accountId, instanceId } = useAccountAppParams();
    const routeResource = useQueryLoadable(
        async () =>
            Promise.resolve({
                type: "XML",
                project: { id: accountId },
                xml: '<route id="routeId"/>',
            }),
        [accountId],
    );

    useSetPageTitle(t("route-edit-form.new-route"));

    return (
        <ProjectIntegrationForm
            projectId={accountId}
            instanceId={instanceId}
            integrationId="new"
            configurableSupported={true}
            routeResource={routeResource}
        />
    );
};
