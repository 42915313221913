import { HEADER_HEIGHT_WITH_BREADCRUMB } from "@/components/DesignSystem/Table/constants.js";
import {
    formatDateTime,
    useProcessingFilesQuery,
} from "@/components/Integrations/Dashboard/Tabs/InstanceMessages/loadables";
import { columns } from "@/components/Integrations/Dashboard/Tabs/InstanceMessages/ProcessedFilesTable.columns";
import PageableTable, {
    PAGE_SIZE_OPTIONS,
} from "@/components/PagableTable/PageableTable.js";
import { useAlertEffect } from "@/components/PageLayout/useAlertEffect.hook";
import { PROCESSED_FILES_TABLE } from "@/constants/sessionState.constants";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import { syntaxHighlight } from "@/utils/formats/json.js";
import { createSessionState } from "@/utils/sessionState/sessionState.js";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo } from "react";

const DEFAULT_SORT_BY = "@timestamp,desc";
const BODY_HEADER_HEIGHT = 78;

const [id, order] = DEFAULT_SORT_BY.split(",");
const DEFAULT_PAGINATION = {
    page: 0,
    pageSize: PAGE_SIZE_OPTIONS[0],
    sortBy: [{ id, order }],
};

const jsonReplacer = (name, value) =>
    name === "instance_name" ? undefined : value;

const formatDates = ({ dateFrom, dateTo, ...rest }) => ({
    dateFrom: formatDateTime(dateFrom),
    dateTo: formatDateTime(dateTo),
    ...rest,
});

const getQueryPagination = ({ page, pageSize, sortBy, ...rest }) => ({
    size: pageSize,
    from: pageSize * page,
    sort: sortBy,
    ...rest,
});

const { useSessionState } = createSessionState(PROCESSED_FILES_TABLE);

export const ProcessedFilesTable = ({
    instanceId,
    from,
    to,
    mustQuery,
    reloadToken,
}) => {
    const [pagination, setPagination] = useSessionState(
        "pagination",
        DEFAULT_PAGINATION,
    );
    const filter = useMemo(
        () => ({
            dateFrom: from,
            dateTo: to,
            mustQuery,
        }),
        [from, mustQuery, to],
    );
    useEffect(() => {
        setPagination(DEFAULT_PAGINATION);
    }, [filter, setPagination]);

    const query = useMemo(
        () => ({ ...getQueryPagination(pagination), ...formatDates(filter) }),
        [filter, pagination],
    );
    const processingFilesQuery = useProcessingFilesQuery({
        instanceId,
        query,
        reloadToken,
    });
    const loading = isLoading(processingFilesQuery);
    const {
        files = [],
        total,
        pages,
    } = processingFilesQuery.loadable.valueMaybe() ?? {};

    const handleFetchPage = useCallback(
        (current, pageSize, sortBy = DEFAULT_SORT_BY) => {
            const [id, order] = sortBy.split(",");
            setPagination({
                page: current - 1,
                pageSize,
                sortBy: [{ id, order }],
            });
        },
        [setPagination],
    );
    const page = useMemo(
        () => ({
            content: files,
            numberOfElements: pagination.pageSize,
            totalElements: total,
            size: pagination.pageSize,
            number: pagination.page,
            totalPages: pages,
            pageable: {
                offset: pagination.pageSize * pagination.page,
                pageSize: pagination.pageSize,
                pageNumber: pagination.page,
                paged: true,
                sort: {
                    empty: false,
                    sorted: true,
                    unsorted: false,
                },
            },
        }),
        [files, pages, pagination.page, pagination.pageSize, total],
    );
    useAlertEffect(total > 10000, () => ({
        type: "warning",
        message: t("processed-files.warning"),
    }));

    return (
        <PageableTable
            fixed
            elkQuery={query}
            exportUrl={`/api/instances/${instanceId}/elastic-search/processed-files/export`}
            restHeight={HEADER_HEIGHT_WITH_BREADCRUMB + BODY_HEADER_HEIGHT}
            loading={loading}
            page={page}
            rowKey="id"
            hasQuickFilters={false}
            fetchPage={handleFetchPage}
            pmExpandable={{
                expandedRowRender: record => (
                    <pre
                        className="jsonParent"
                        dangerouslySetInnerHTML={{
                            __html: syntaxHighlight(
                                JSON.stringify(record.source, jsonReplacer, 4),
                                record.highlight &&
                                    Object.keys(record.highlight),
                            ),
                        }}
                    />
                ),
            }}
            columns={columns}
            padding={false}
        />
    );
};

ProcessedFilesTable.propTypes = {
    instanceId: PropTypes.number,
};
