import { Col, Row, SwitchWithState, Text } from "@/components/DesignSystem";
import { Gap } from "@/components/DesignSystem/Gap";
import PropTypes from "prop-types";
import React from "react";

const SwitchOption = ({ name, description, onChange, checked }) => {
    return (
        <React.Fragment>
            <Row type="flex" justify="start" align="top" gutter={8}>
                <Col>
                    <SwitchWithState
                        defaultValue={true}
                        value={checked}
                        onChange={onChange}
                    />
                </Col>
                <Col>
                    <Text>{name}</Text>
                    <br />
                    <Text size="small">{description}</Text>
                </Col>
            </Row>
            <Gap size="large" />
        </React.Fragment>
    );
};

SwitchOption.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
};

export default SwitchOption;
