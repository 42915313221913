import {
    Alert,
    Forms,
    Gap,
    H4,
    Label,
    Link,
    P,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { fromEOSch } from "@/components/EventSchedulers/EventSchedulerForm";
import { preselectedTimezone } from "@/components/TimezoneSelect";
import {
    LoadableRenderer,
    responseErrorMessage,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { debounceAsync } from "@/utils/promises/promise.utils";
import { getUserTimezone } from "@/utils/timezone/timezone.utils";
import { DATE_FORMAT } from "@pricefx/unity-components/dist/es/constants";
import {
    filter,
    identity,
    join,
    pipe,
    split,
    toUpper,
    update,
} from "lodash/fp";
import moment from "moment";
import React, { useCallback, useMemo, useReducer } from "react";

const REPEAT = {
    NONE: "NONE",
    DAILY: "DAILY",
    WEEKLY: "WEEKLY",
    MONTHLY: "MONTHLY",
    YEARLY: "YEARLY",
    CRON: "CRON",
};

export const REPEAT_OPTIONS = [
    { value: REPEAT.NONE, label: "None" },
    { value: REPEAT.DAILY, label: "Daily" },
    { value: REPEAT.WEEKLY, label: "Weekly" },
    { value: REPEAT.MONTHLY, label: "Monthly" },
    { value: REPEAT.YEARLY, label: "Yearly" },
    { value: REPEAT.CRON, label: "Defined by Cron" },
];

const WEEKDAY_OPTIONS = [
    { value: "MONDAY", label: "Monday" },
    { value: "TUESDAY", label: "Tuesday" },
    { value: "WEDNESDAY", label: "Wednesday" },
    { value: "THURSDAY", label: "Thursday" },
    { value: "FRIDAY", label: "Friday" },
    { value: "SATURDAY", label: "Saturday" },
    { value: "SUNDAY", label: "Sunday" },
];

const keyName = "schedulerDefinition";
export const getTimingName = fieldName => `${keyName}.${fieldName}`;
export const TIMING_DATETIME_FIELDS = ["startTime", "eventTime", "endTime"].map(
    getTimingName,
);
export const TIMING_TIME_FIELDS = ["atTime"].map(getTimingName);

const getInitialTime = () => moment().startOf("day");

const AtTime = ({ onChange }) => {
    return (
        <Forms.Fields.TimePicker
            name={getTimingName("atTime")}
            label={t("event-scheduler.form.at.label")}
            format="HH:mm"
            width="max"
            inputWidth="max"
            initialValue={getInitialTime()}
            required
            validator={Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
            ])}
            onChange={onChange}
        />
    );
};

/*
type	[...]
startTime	string($date-time)
eventTimeZone	string
interval	integer($int32)
atTime	LocalTime{...}
endTime	string($date-time)
}
*/
const DailyFields = ({ onChange }) => {
    return (
        <>
            <Forms.Fields.InputNumber
                name={getTimingName("interval")}
                label={t("event-scheduler.form.every.label")}
                addonAfter="Day(s)"
                initialValue={1}
                required
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                ])}
                onChange={onChange}
            />
            <AtTime onChange={onChange} />
        </>
    );
};

/*
type	[...]
startTime	[...]
eventTimeZone	string
dayOfWeeks	[ uniqueItems: true [...]]
atTime	LocalTime{...}
endTime	[...]
*/
const WeeklyFields = ({ onChange }) => {
    return (
        <>
            <Forms.Fields.InputNumber
                name={getTimingName("interval")}
                label={t("event-scheduler.form.every.label")}
                addonAfter="Week(s)"
                initialValue={1}
                required
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                ])}
                onChange={onChange}
            />
            {/* <Forms.Fields.CheckboxGroup // TODO */}
            <Forms.Fields.Select
                name={getTimingName("dayOfWeeks")}
                label={t("event-scheduler.form.day.label")}
                mode="tags"
                options={WEEKDAY_OPTIONS}
                required
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                ])}
                onChange={onChange}
            />
            <AtTime onChange={onChange} />
        </>
    );
};

/*
type	[...]
startTime	[...]
eventTimeZone	string
monthNumber	integer($int32)
dayOfMonth	integer($int32)
atTime	LocalTime{...}
endTime	string($date-time)
*/
const getLabel = i => {
    if ([1, 21, 31].includes(i)) return `${i}st`;
    if ([2, 22].includes(i)) return `${i}nd`;
    if ([3, 23].includes(i)) return `${i}rd`;
    return `${i}th`;
};
const MonthlyFields = ({ onChange }) => {
    const domOptions = Array.from({ length: 31 }, (_, i) => i + 1).map(i => ({
        value: i,
        label: getLabel(i),
    }));
    return (
        <>
            <Forms.Fields.InputNumber
                name={getTimingName("interval")}
                label={t("event-scheduler.form.every.label")}
                addonAfter="month(s)"
                initialValue={1}
                required
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                ])}
                onChange={onChange}
            />
            <Forms.Fields.Select
                name={getTimingName("dayOfMonth")}
                label={t("event-scheduler.form.on.label")}
                addonAfter="day" // TODO: not working
                options={domOptions}
                required
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                ])}
                onChange={onChange}
                initialValue={domOptions[0]?.value}
                allowClear={false}
            />
            <AtTime onChange={onChange} />
        </>
    );
};

/*
type	[...]
startTime	[...]
eventTimeZone	[...]
interval	[...]
dayOfYear	[...]
atTime	LocalTime{...}
endTime	[...]
*/
const YearlyFields = ({ onChange }) => {
    return (
        <>
            <Forms.Fields.InputNumber
                name={getTimingName("interval")}
                label={t("event-scheduler.form.every.label")}
                addonAfter="year(s)"
                initialValue={1}
                onChange={onChange}
            />
            <Forms.Fields.InputNumber
                name={getTimingName("dayOfYear")}
                label={t("event-scheduler.form.dayOfYear.label")}
                addonAfter="day of year"
                onChange={onChange}
                min={0}
                max={366}
            />
            <AtTime onChange={onChange} />
        </>
    );
};

const useCronValidator = ({ accountId }) => {
    const { axiosService } = useDic();

    return useCallback(
        async value => {
            try {
                await axiosService.get(
                    `/api/accounts/${accountId}/event-schedulers/cron?cronExpression=${value}`,
                );
                return Forms.success();
            } catch (e) {
                return Forms.error("Invalid cron expression");
            }
        },
        [accountId, axiosService],
    );
};

/*
type	[...]
startTime	[...]
eventTimeZone	[...]
cron	string
endTime	[...]
*/
const CronFields = ({ accountId, onChange }) => {
    const cronValidator = useCronValidator({ accountId });

    return (
        <>
            <Forms.Fields.Input
                name={getTimingName("cron")}
                label={t("event-scheduler.form.cron.label")}
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    debounceAsync(500, cronValidator),
                ])}
                onChange={onChange}
                // React.Fragment is workaround for UC bug
                description={
                    <>
                        {t("event-scheduler.form.cron.description", {
                            link: (
                                <Link
                                    targetBlank
                                    href="https://docs.aws.amazon.com/eventbridge/latest/userguide/eb-cron-expressions.html"
                                >
                                    {t("general.documentation-lowercase")}
                                </Link>
                            ),
                        })}
                    </>
                }
            />
        </>
    );
};

const useDebouncedSummaryQuery = ({ accountId, refetchToken, getBag }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        debounceAsync(1000, async () => {
            const values = (await getBag()).values;
            const payload = fromEOSch(values)?.[keyName];

            return axiosService
                .post(
                    `/api/accounts/${accountId}/event-schedulers/summary`,
                    payload,
                )
                .then(getData);
        }),
        [accountId, refetchToken],
    );
};

const SummaryField = ({ label, value, format = identity }) => {
    if (!value) return null;

    return (
        <>
            <Label label={label} />
            <span>{format(value)}</span>
            <Gap />
        </>
    );
};

const DATE_TIME_FORMAT = "On dddd DD/MM/YYYY [at] hh:mm A ([UTC]Z)";
const formatDateTime = str => moment(str).format(DATE_TIME_FORMAT);

const formatRepeat = repeatValue =>
    ({
        [REPEAT.NONE]: "once",
        [REPEAT.DAILY]: "daily",
        [REPEAT.WEEKLY]: "weekly",
        [REPEAT.MONTHLY]: "monthly",
        [REPEAT.YEARLY]: "yearly",
        [REPEAT.CRON]: "",
    }[repeatValue]);

const TimingSummary = ({ accountId, repeatValue, refetchToken, getBag }) => {
    const summaryQuery = useDebouncedSummaryQuery({
        accountId,
        refetchToken,
        getBag,
    });
    const repeatPrefix = formatRepeat(repeatValue);
    const addPrefix = value =>
        pipe(
            filter(Boolean),
            join(" "),
            split(""),
            update(0, toUpper),
            join(""),
        )([repeatPrefix, value]);

    return (
        <LoadableRenderer
            loadable={summaryQuery.loadable}
            hasError={error =>
                error?.response?.status >= 400 &&
                error?.response?.status <= 499 && (
                    <>
                        <H4>{t("event-scheduler.timing.summary")}</H4>
                        <Alert
                            type="error"
                            message={responseErrorMessage(error)}
                        />
                    </>
                )
            }
            hasValue={summary => (
                <>
                    <H4>{t("event-scheduler.timing.summary")}</H4>
                    <SummaryField
                        label={t("event-scheduler.form.trigger.label")}
                        value={summary.humanReadable}
                        format={addPrefix}
                    />
                    {summary.nextExecution && (
                        <>
                            <SummaryField
                                label={t("event-scheduler.form.next-run.label")}
                                value={summary.nextExecution}
                                format={formatDateTime}
                            />
                        </>
                    )}
                </>
            )}
        />
    );
};

export const getDateStrWithOffset = ({
    date,
    timezone = getUserTimezone(),
    format = fromEOSch.DATETIME_FORMAT,
}) => {
    const dateStr = moment(date).format(format);
    const offset = moment.tz(timezone).format("Z");
    return `${dateStr}${offset}`;
};

const createIsBeforeLimit =
    ({
        limitDate,
        limitTimezone,
        timezoneValue,
        format = fromEOSch.DATETIME_FORMAT,
    }) =>
    dateValue => {
        const fullLimitStr = getDateStrWithOffset({
            date: limitDate,
            timezone: limitTimezone,
            format,
        });
        const fullDateStr = getDateStrWithOffset({
            date: dateValue,
            timezone: timezoneValue,
            format,
        });
        const isBeforeLimit = moment(
            fullDateStr,
            fromEOSch.DATETIME_FORMAT + "Z",
        ).isBefore(fullLimitStr);

        // console.log("createIsBeforeLimit", { limitDate, limitTimezone, dateValue, timezoneValue, isBeforeLimit, fullLimitStr, fullDateStr });

        return isBeforeLimit;
    };

const getDisabledDatesProps = ({
    mustBeAfter = new Date(),
    limitTimezone = getUserTimezone(),
    timezoneValue,
} = {}) => {
    const isBeforeLimit = createIsBeforeLimit({
        limitDate: mustBeAfter,
        limitTimezone,
        timezoneValue,
    });
    const isAfterValidator = value => {
        return isBeforeLimit(value)
            ? Forms.error(
                  "Must be after " +
                      getDateStrWithOffset({ date: mustBeAfter }),
              )
            : Forms.success();
    };

    return {
        disabledDate: isBeforeLimit,
        validator: Forms.pmValidators.noValueOr(isAfterValidator),
    };
};

export const TimingFields = ({ accountId, getBag, lastExecution }) => {
    const [refetchToken, refetchSummary] = useReducer(i => i + 1, 0);
    const repeatValue = Forms.useFieldValue({ name: getTimingName("type") });
    const timezoneValue = Forms.useFieldValue({
        name: getTimingName("eventTimeZone"),
    });
    const startTimeValue = Forms.useFieldValue({
        name: getTimingName("startTime"),
    });

    const justInTheFuture = {
        mustBeAfter: new Date(),
        limitTimezone: getUserTimezone(),
    };
    const eventTimeProps = getDisabledDatesProps({
        ...justInTheFuture,
        timezoneValue,
    });
    const endTimeVisible = REPEAT.NONE !== repeatValue;
    const endProps = endTimeVisible
        ? getDisabledDatesProps({
              ...(startTimeValue && startTimeValue > new Date()
                  ? {
                        mustBeAfter: startTimeValue,
                        limitTimezone: timezoneValue,
                    }
                  : justInTheFuture),
              timezoneValue,
          })
        : {};

    return (
        <>
            {[REPEAT.NONE].includes(repeatValue) ? (
                <Forms.Fields.DatePicker
                    key="eventTime" // UC bug?
                    name={getTimingName("eventTime")}
                    label={t("event-scheduler.form.eventTime.label")}
                    required={true}
                    validator={Forms.validators.failOnFirst([
                        Forms.pmValidators.isRequired,
                    ])}
                    showTime
                    format={"DD/MM/YYYY HH:mm"}
                    width="max"
                    inputWidth="max"
                    onChange={refetchSummary}
                    initialValue={moment().add({ hour: 1 })}
                    {...eventTimeProps}
                />
            ) : (
                <Forms.Fields.DatePicker
                    key="startTime" // UC bug?
                    name={getTimingName("startTime")}
                    label={t("event-scheduler.form.startTime.label")}
                    showTime
                    format={"DD/MM/YYYY HH:mm"}
                    width="max"
                    inputWidth="max"
                    onChange={refetchSummary}
                />
            )}
            <Forms.Fields.TimezoneSelect
                name={getTimingName("eventTimeZone")}
                label={t("event-scheduler.form.timezone.label")}
                onChange={refetchSummary}
                initialValue={preselectedTimezone}
            />
            <Forms.Fields.Select
                name={getTimingName("type")}
                label={t("event-scheduler.form.repeat.label")}
                initialValue={REPEAT.NONE}
                options={REPEAT_OPTIONS}
                onChange={refetchSummary}
            />
            {repeatValue === REPEAT.DAILY && (
                <DailyFields onChange={refetchSummary} />
            )}
            {repeatValue === REPEAT.WEEKLY && (
                <WeeklyFields onChange={refetchSummary} />
            )}
            {repeatValue === REPEAT.MONTHLY && (
                <MonthlyFields onChange={refetchSummary} />
            )}
            {repeatValue === REPEAT.YEARLY && (
                <YearlyFields onChange={refetchSummary} />
            )}
            {repeatValue === REPEAT.CRON && (
                <CronFields accountId={accountId} onChange={refetchSummary} />
            )}
            {endTimeVisible && (
                <Forms.Fields.DatePicker
                    name={getTimingName("endTime")}
                    label={t("event-scheduler.form.endTime.label")}
                    showTime
                    format={"DD/MM/YYYY HH:mm"}
                    width="max"
                    inputWidth="max"
                    tooltip={t("event-scheduler.form.endTime.tooltip")}
                    onChange={refetchSummary}
                    {...endProps}
                />
            )}

            <TimingSummary
                accountId={accountId}
                repeatValue={repeatValue}
                getBag={getBag}
                refetchToken={refetchToken}
            />
            {lastExecution && (
                <SummaryField
                    label={t("event-scheduler.form.last-run.label")}
                    value={lastExecution}
                    format={formatDateTime}
                />
            )}
        </>
    );
};
