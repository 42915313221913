import Paragraph from "@/components/DesignSystem/Paragraph/Paragraph";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

export const DataPreviewFileInfo = ({ dataExample }) => {
    return (
        <Paragraph size={2}>
            {dataExample.numberOfRows >= 0 ? (
                <div data-test="data-mapping-lines-count">
                    {t(
                        "package-data-upload.table-header.table.header",
                        dataExample,
                    )}
                </div>
            ) : (
                t(
                    "package-data-upload.extension.table-header.table.header",
                    dataExample,
                )
            )}
        </Paragraph>
    );
};

DataPreviewFileInfo.propTypes = {
    dataExample: PropTypes.shape({
        numberOfRows: PropTypes.number,
        fileName: PropTypes.string,
    }),
};
