import WarningAlert from "@/components/Alerts/WarningAlert";
import {
    Alert,
    Button,
    ButtonGroup,
    Forms,
    Gap,
    H3,
    H5,
    message,
    Text,
} from "@/components/DesignSystem";
import { areEmailsValid } from "@/components/DesignSystem/Forms/validators";
import { useDic } from "@/components/Dic/useDic.hook";
import ConditionalAttributeErrorAlert from "@/components/Error/ConditionalAttributeErrorAlert";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { preselectedTimezone } from "@/components/TimezoneSelect";
import { NoTimeslots } from "@/components/UpgradeScheduling/components/NoTimeslots.component";
import { getClusterUpgradeRequestFormName as getTrackName } from "@/mixpanel/buttonNames";
import { useTrackButtonClick } from "@/mixpanel/hooks/useTrackButtonClick.hook";
import {
    LoadableRenderer,
    STATUSES,
    useQueryLoadable,
    waitForValue,
} from "@/modules/loadable";
import { SecurityContext } from "@/security/authorization";
import { upgradeSchedulingService } from "@/services/upgradeSchedulingService";
import { t } from "@/translations";
import { naturallySortOptions } from "@/utils/form.utils";
import { getErrorMessage } from "@/utils/state/error.utils";
import { transformIanaToWindows } from "@/utils/timezone/timezone.utils";
import { createSortVersions, isMajorChange } from "@/utils/versionUtils";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import { Form as AntdForm } from "antd";
import _ from "lodash";
import { get, map, pipe, values } from "lodash/fp";
import moment from "moment";
import PropTypes from "prop-types";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { clusterUpgradeTypes } from "./constants";
import { DATE, DATETIME_WITHOUT_OFFSET } from "./date-formats";
import styles from "./UpgradeScheduling.style.less";
import { UpgradeTime } from "./UpgradeTime";
const {
    useForm,
    Field,
    Fields,
    FieldGroup,
    SubmitButton,
    error,
    pmValidators,
    validators,
    useFormIsSubmitting,
} = Forms;

export const AUTOMATED_UPGRADE_MIN_DELAY = 15;

export const getMinDayToSelect = (today, isAutomatedUpgrade = false) => {
    if (isAutomatedUpgrade) return today.startOf("day");

    let dayOfWeek = today.startOf("day").isoWeekday();

    if (dayOfWeek <= 3 || dayOfWeek === 7) {
        return today.add(3, "day");
    } else if (dayOfWeek > 3 && dayOfWeek <= 5) {
        return today.add(5, "day");
    } else {
        return today.add(4, "day");
    }
};

const getGrouppedTimeslotsByAtendees = timeslots => {
    if (timeslots.length === 0) return [];
    return _.chain(timeslots)
        .groupBy(
            timeslot =>
                moment(timeslot.start.dateTime).format(
                    DATETIME_WITHOUT_OFFSET,
                ) +
                (timeslot.end &&
                    moment(timeslot.end.dateTime).format(
                        DATETIME_WITHOUT_OFFSET,
                    )),
        )
        .values()
        .map(timeslotsGroup =>
            timeslotsGroup.reduce(
                (timeslot, nextTimeslot) => ({
                    ...nextTimeslot,
                    eligibleAttendees: [
                        ...timeslot.eligibleAttendees,
                        nextTimeslot.eligibleAttendee,
                    ],
                }),
                { eligibleAttendees: [] },
            ),
        )
        .value();
};

const getGrouppedTimeslotsByDay = timeslots => {
    if (timeslots.length === 0) return [];
    return _.chain(timeslots)
        .groupBy(timeslot => moment(timeslot.start.dateTime).format(DATE))
        .map((ranges, date) => ({ [date]: { date, ranges } }))
        .reduce((result, current) => ({ ...result, ...current }), {})
        .value();
};

const getTimeslots = (selectedDate, grouppedTimeslots) => {
    return Object.values(grouppedTimeslots).filter(
        timeslot => timeslot.date === selectedDate,
    );
};

const formatClusters = clusters => {
    return Object.entries(clusters).reduce(
        (clusters, [clusterName, cluster]) => ({
            ...clusters,
            [clusterName]: {
                ...cluster,
                availableUpgrades: cluster.availableUpgrades.reduce(
                    (upgrades, upgrade) => ({
                        ...upgrades,
                        [upgrade.version]: upgrade,
                    }),
                    {},
                ),
            },
        }),
        {},
    );
};

const UpgradeScheduling = ({
    accountId,
    eventId,
    initialValues,
    navigateToClusterListLocation,
}) => {
    const { messageService } = useDic();

    const isEditation = !!eventId;
    const [userEmails, setUserEmails] = useState();
    const [availableTimeslotsMap, setAvailableTimeslotsMap] = useState({});

    const securityContext = useContext(SecurityContext);
    const { adminApiService } = useDic();
    const { trackHandler } = useTrackButtonClick();

    const clustersLoadable = useQueryLoadable(async () => {
        return upgradeSchedulingService
            .fetchAvailableUpgrades(accountId)
            .then(response => formatClusters(response.data))
            .catch(() =>
                message.error(t("cluster.upgrade.fetch-available-clusters")),
            );
    }, [accountId]);

    const onSubmit = useCallback(
        ({ values, reset }) => {
            return upgradeSchedulingService
                .scheduleUpgrade(accountId, values.cluster, {
                    automated: values.type === clusterUpgradeTypes.AUTOMATED,
                    eventId,
                    note: values.note,
                    additionalAttendees: values.additionalAttendees,
                    timezone: values.timezone,
                    // in editaton when the same timeslot selected, we send the initialOne to achieve that the timeslot will be assigned to already assigned person
                    ...(isEditation &&
                    initialValues.timeslot.start.dateTime ===
                        values.timeslot.start.dateTime
                        ? initialValues.timeslot
                        : values.timeslot),
                })
                .then(
                    trackHandler(
                        () => {
                            messageService.success({
                                content: isEditation
                                    ? t("cluster.upgrade.event-updated")
                                    : t("cluster.upgrade.event-created"),
                            });
                            if (!isEditation) {
                                navigateToClusterListLocation();
                                //     setValues({ cluster: null });
                                //     setSelectedValues({});
                                //     setUpgradeScheduled(!upgradeScheduled);
                                //     clustersLoadable.reload();
                                //     reset();
                            }
                        },
                        {
                            type: isEditation ? "Edit" : "New",
                            name: getTrackName("SubmitSuccess"),
                        },
                    ),
                )
                .catch(error => {
                    messageService.error({
                        content: getErrorMessage(error.response.data),
                    });
                });
        },
        [
            accountId,
            eventId,
            initialValues?.timeslot,
            isEditation,
            messageService,
            navigateToClusterListLocation,
            trackHandler,
        ],
    );
    const { Form, formId, setValues, setTouched } = useForm({
        onSubmit,
    });

    const selectedType = useFieldValue({ formId, name: "type" });
    const selectedCluster = useFieldValue({ formId, name: "cluster" });
    const selectedSnapshot = useFieldValue({ formId, name: "snapshot" });
    const selectedTimezone = useFieldValue({ formId, name: "timezone" });
    const selectedTimeslot = useFieldValue({ formId, name: "timeslot" });
    const selectedUpgradeDate = useFieldValue({ formId, name: "upgradeDate" });
    const selectedUpgradeTime = useFieldValue({ formId, name: "upgradeTime" });

    const availableTimeSlots = useQueryLoadable(async () => {
        if (
            !selectedSnapshot ||
            clustersLoadable.loadable.state !== STATUSES.hasValue
        )
            return waitForValue();
        return upgradeSchedulingService.fetchAvailableUpgradeTimes(accountId, {
            duration:
                clustersLoadable.loadable.contents[selectedCluster]
                    .availableUpgrades[selectedSnapshot].duration,
            dateFrom: moment(selectedUpgradeDate)
                // .subtract(MAX_CALENDAR_DAYS_COUNT, "days")
                .startOf("day")
                .format(),
            dateTo: moment(selectedUpgradeDate)
                // .add(MAX_CALENDAR_DAYS_COUNT, "days")
                .endOf("day")
                .format(),
            timezone: transformIanaToWindows(selectedTimezone),
        });
    }, [
        accountId,
        clustersLoadable.loadable.contents,
        clustersLoadable.loadable.state,
        selectedCluster,
        selectedSnapshot,
        selectedTimezone,
        selectedUpgradeDate,
    ]);

    useEffect(() => {
        if (availableTimeSlots.loadable.state === STATUSES.hasValue) {
            setAvailableTimeslotsMap({
                ...getGrouppedTimeslotsByDay(
                    getGrouppedTimeslotsByAtendees(
                        isEditation &&
                            initialValues.snapshot === selectedSnapshot
                            ? [
                                  ...availableTimeSlots.loadable.contents,
                                  initialValues.timeslot,
                              ]
                            : availableTimeSlots.loadable.contents,
                    ),
                ),
            });
        }
    }, [
        availableTimeSlots.loadable.contents,
        availableTimeSlots.loadable.state,
        initialValues,
        isEditation,
        selectedSnapshot,
    ]);

    useEffect(() => {
        adminApiService.fetchProjectUsers(accountId).then(r => {
            const emails = r.data
                .filter(user => user.id !== securityContext.user.id)
                .map(user => user.email);
            setUserEmails([...new Set(emails)]);
        });
    }, [accountId]);

    const defaultInitValues = useMemo(
        () =>
            initialValues ?? {
                cluster: null,
                snapshot: null,
                timezone: preselectedTimezone,
                upgradeDate: getMinDayToSelect(moment()),
                timeslot: null,
                type: clusterUpgradeTypes.MANUAL,
            },
        [initialValues],
    );
    useSetValidatedInitialValues(
        {
            initialValues: defaultInitValues,
            setValues,
            setTouched,
        },
        [defaultInitValues, accountId],
    );

    const isSubmitting = useFormIsSubmitting(formId);

    const isAutoUpgradePossible = useMemo(() => {
        const clusters = clustersLoadable.loadable.valueMaybe();
        if (!selectedCluster || !selectedSnapshot || !clusters) return false;
        return clusters[selectedCluster]?.availableUpgrades[selectedSnapshot]
            ?.possibleAutoUpgrade;
    }, [clustersLoadable.loadable.contents, selectedCluster, selectedSnapshot]);

    const setAutomatedTimeSlot = ({ date, time, clusters }) => {
        if (!time || !date) return;
        const dateTime = date
            .set({
                hour: time.get("hour"),
                minute: time.get("minute"),
            })
            .format(DATETIME_WITHOUT_OFFSET);

        const selectedTimeslot = {
            timeslot: {
                versionFrom: clusters[selectedCluster].clusterVersion,
                versionTo:
                    clusters[selectedCluster].availableUpgrades[
                        selectedSnapshot
                    ].version,
                start: {
                    dateTime,
                    timeZone: transformIanaToWindows(selectedTimezone),
                },
            },
        };
        setValues(selectedTimeslot);
    };

    const resetTimeslot = () => {
        setValues({ timeslot: null });
    };

    const isSelectedTimeslot = (date, range) =>
        `${moment(date).format(DATE)}-${range.start.dateTime}-${
            range.end.dateTime
        }` ===
        `${selectedUpgradeDate.format(DATE)}-${
            selectedTimeslot?.start?.dateTime
        }-${selectedTimeslot?.end?.dateTime}`;

    const isDateAvailable = useCallback(
        (dateToCheck, isAutomatedUpgrade) => {
            if (
                dateToCheck.isBefore(
                    getMinDayToSelect(moment(), isAutomatedUpgrade),
                ) ||
                !selectedCluster ||
                !selectedSnapshot
            ) {
                return false;
            } else {
                return true;
                // TODO: Use after MS API applied API fix when we get timeslots for more dates
                // return Object.keys(availableTimeslotsMap).includes(
                //     dateToCheck.format(DATE)
                // );
            }
        },
        [selectedCluster, selectedSnapshot],
    );

    const mapClustersToOptions = clusters => {
        return clusters
            ? naturallySortOptions(
                  getFilteredClusters(clusters).map(([clusterName]) => ({
                      label: clusterName,
                      value: clusterName,
                  })),
              )
            : [];
    };

    const clustersSelectDisabled = clusters => {
        return getFilteredClusters(clusters).length === 0 || isEditation;
    };

    const getFilteredClusters = clusters => {
        return Object.entries(clusters).filter(
            ([, clusterInfo]) => !clusterInfo.upgradeAlreadyScheduled,
        );
    };

    const filterTodaysPastRanges = ranges => {
        if (ranges.length === 0) return [];
        const now = moment();

        if (!moment(ranges[0].start.dateTime).isSame(now, "day")) return ranges;

        return ranges.filter(range =>
            moment(range.start.dateTime).isAfter(now),
        );
    };

    const isToday = date => date.startOf("day").isSame(moment().startOf("day"));

    const getDisabledHours = () =>
        selectedUpgradeDate && isToday(selectedUpgradeDate)
            ? [
                  ...Array(
                      moment()
                          .add(AUTOMATED_UPGRADE_MIN_DELAY, "minutes")
                          .hour(),
                  ).keys(),
              ]
            : [];

    const getDisabledMinutes = selectedHour =>
        selectedHour ===
            moment().add(AUTOMATED_UPGRADE_MIN_DELAY, "minutes").hour() &&
        selectedUpgradeDate &&
        isToday(selectedUpgradeDate)
            ? [
                  ...Array(
                      moment()
                          .add(AUTOMATED_UPGRADE_MIN_DELAY, "minutes")
                          .minute(),
                  ).keys(),
              ]
            : [];

    const upgradeDateValidator = async (validatedValue, getBag) => {
        const { values } = await getBag();

        return isDateAvailable(
            validatedValue,
            values.type === clusterUpgradeTypes.AUTOMATED,
        )
            ? Forms.success()
            : Forms.error(t("cluster.upgrade.validation.date.invalid"));
    };

    const upgradeTimeValidator = async (validatedValue, getBag) => {
        const { values } = await getBag();

        const selectedDateTime = values.upgradeDate.set({
            hour: validatedValue.get("hour"),
            minute: validatedValue.get("minute"),
        });

        return selectedDateTime.isSameOrAfter(
            moment().add(AUTOMATED_UPGRADE_MIN_DELAY, "minutes"),
        )
            ? Forms.success()
            : Forms.error(t("cluster.upgrade.validation.time.invalid"));
    };

    const getMaybeClusters = () => {
        const maybeClusters = clustersLoadable.loadable.valueMaybe();
        return maybeClusters ? maybeClusters : {};
    };

    return (
        <>
            <H3>{t("cluster.upgrade.header")}</H3>
            <Text>{t("cluster.upgrade.title")}</Text>

            <Gap size="medium" />
            {error && (
                <div className={styles.errorWrapper}>
                    <ConditionalAttributeErrorAlert
                        error={error}
                        messageId={error.message}
                        className={styles.flashError}
                    />
                </div>
            )}

            <Form>
                <>
                    <FieldGroup>
                        <Fields.Select
                            name="cluster"
                            label={t("cluster.upgrade.cluster.label")}
                            placeholder={t("placeholder.please-select")}
                            onChange={() => {
                                // if cluster changed, reset the selected snapshot version
                                setValues({ snapshot: null });
                            }}
                            allowClear={false}
                            validateOnBlur={false}
                            validator={pmValidators.isRequired}
                            options={mapClustersToOptions(getMaybeClusters())}
                            disabled={clustersSelectDisabled(
                                getMaybeClusters(),
                            )}
                        />
                        {selectedCluster && (
                            <AntdForm.Item
                                label={t(
                                    "cluster.upgrade.cluster.version.label",
                                )}
                            >
                                <H5>
                                    {
                                        getMaybeClusters()[selectedCluster]
                                            ?.clusterVersion
                                    }
                                </H5>
                            </AntdForm.Item>
                        )}
                    </FieldGroup>
                    <FieldGroup>
                        <Fields.Select
                            name="snapshot"
                            label={t("cluster.upgrade.snapshot.label")}
                            placeholder={t("placeholder.please-select")}
                            onChange={() => {
                                setValues({
                                    type: clusterUpgradeTypes.MANUAL,
                                });
                            }}
                            disabled={
                                isEditation ||
                                !selectedCluster ||
                                (selectedType ===
                                    clusterUpgradeTypes.AUTOMATED &&
                                    isEditation)
                            }
                            allowClear={false}
                            validator={pmValidators.isRequired}
                            {...getLoadableSelectProps(
                                clustersLoadable.loadable,
                                pipe(
                                    get([selectedCluster, "availableUpgrades"]),
                                    values,
                                    map(upgrade => ({
                                        value: upgrade.version,
                                        label: upgrade.version,
                                    })),
                                    createSortVersions({
                                        getter: get("value"),
                                    }),
                                ),
                            )}
                        />
                        <Fields.TimezoneSelect
                            name="timezone"
                            label={t("cluster.upgrade.timezone.label")}
                            placeholder={t("placeholder.please-select")}
                            allowClear={false}
                            // defaultValue={undefined}
                        />
                        {isAutoUpgradePossible && !isEditation && (
                            <Alert
                                type="info"
                                showIcon
                                message={"Automated upgrade is available."}
                                style={{ maxWidth: "384px" }}
                            />
                        )}
                        {isAutoUpgradePossible && (
                            <Forms.Fields.Radio
                                name="type"
                                label={t(
                                    "cluster.upgrade.automatic-upgrade.label",
                                )}
                                tooltip={t(
                                    "cluster.upgrade.automatic-upgrade.tooltip",
                                )}
                                onChange={resetTimeslot}
                                options={[
                                    {
                                        label: t(
                                            "cluster.upgrade.automatic-upgrade.option.manual",
                                        ),
                                        value: clusterUpgradeTypes.MANUAL,
                                        disabled: isEditation,
                                    },
                                    {
                                        label: t(
                                            "cluster.upgrade.automatic-upgrade.option.automated",
                                        ),
                                        value: clusterUpgradeTypes.AUTOMATED,
                                        disabled: isEditation,
                                    },
                                ]}
                            />
                        )}
                    </FieldGroup>
                    <FieldGroup grid>
                        <Fields.Calendar
                            name="upgradeDate"
                            label={t("cluster.upgrade.date-and-slot.title")}
                            fullscreen={false}
                            hiddenYear
                            onChange={e => {
                                if (
                                    selectedType ===
                                    clusterUpgradeTypes.AUTOMATED
                                ) {
                                    setAutomatedTimeSlot({
                                        date: e.value,
                                        time: selectedUpgradeTime,
                                        clusters: getMaybeClusters(),
                                    });
                                } else {
                                    resetTimeslot();
                                }
                            }}
                            mode="month"
                            disabledDate={date =>
                                !isDateAvailable(
                                    date,
                                    selectedType ===
                                        clusterUpgradeTypes.AUTOMATED,
                                )
                            }
                            validator={upgradeDateValidator}
                        />
                        <div>
                            {!isEditation &&
                                selectedCluster &&
                                getFilteredClusters(getMaybeClusters())
                                    .length === 0 && (
                                    <WarningAlert
                                        visible
                                        message={t(
                                            "cluster.upgrade.not-available",
                                        )}
                                    />
                                )}

                            <Gap size="medium" />

                            {/* Placeholder for selected timeslot */}
                            <Field
                                name="timeslot"
                                as="div"
                                validator={pmValidators.isRequired}
                                hidden
                            />

                            {selectedType === clusterUpgradeTypes.AUTOMATED &&
                                selectedSnapshot && (
                                    <Fields.TimePicker
                                        name="upgradeTime"
                                        label={t("general.time")}
                                        allowClear={false}
                                        format="HH:mm"
                                        required
                                        validator={validators.failOnFirst([
                                            pmValidators.isRequired,
                                            upgradeTimeValidator,
                                        ])}
                                        onChange={e => {
                                            setAutomatedTimeSlot({
                                                date: selectedUpgradeDate,
                                                time: e.value,
                                                clusters: getMaybeClusters(),
                                            });
                                        }}
                                        disabledHours={getDisabledHours}
                                        disabledMinutes={getDisabledMinutes}
                                    />
                                )}

                            {!(
                                selectedType === clusterUpgradeTypes.AUTOMATED
                            ) &&
                                selectedCluster &&
                                selectedSnapshot && (
                                    <LoadableRenderer
                                        loadable={availableTimeSlots.loadable}
                                        hasValue={() => {
                                            const timeslots = getTimeslots(
                                                selectedUpgradeDate.format(
                                                    DATE,
                                                ),
                                                availableTimeslotsMap,
                                            );
                                            return (
                                                <>
                                                    {timeslots.length === 0 ? (
                                                        <NoTimeslots />
                                                    ) : (
                                                        <div
                                                            className={
                                                                styles.timeIntervals
                                                            }
                                                        >
                                                            {timeslots.map(
                                                                timeslot => {
                                                                    return filterTodaysPastRanges(
                                                                        timeslot.ranges,
                                                                    ).map(
                                                                        range => {
                                                                            const start =
                                                                                range.start;
                                                                            const end =
                                                                                range.end;

                                                                            return (
                                                                                <UpgradeTime
                                                                                    data-test="timeslot-select"
                                                                                    key={`${selectedUpgradeDate.format()}-${
                                                                                        start.dateTime
                                                                                    }-${
                                                                                        end.dateTime
                                                                                    }`}
                                                                                    start={
                                                                                        start.dateTime
                                                                                    }
                                                                                    end={
                                                                                        end.dateTime
                                                                                    }
                                                                                    onClick={() => {
                                                                                        setValues(
                                                                                            {
                                                                                                timeslot:
                                                                                                    {
                                                                                                        versionFrom:
                                                                                                            getMaybeClusters()[
                                                                                                                selectedCluster
                                                                                                            ]
                                                                                                                .clusterVersion,
                                                                                                        versionTo:
                                                                                                            getMaybeClusters()[
                                                                                                                selectedCluster
                                                                                                            ]
                                                                                                                .availableUpgrades[
                                                                                                                selectedSnapshot
                                                                                                            ]
                                                                                                                .version,
                                                                                                        start,
                                                                                                        end,
                                                                                                        eligibleAttendees:
                                                                                                            range.eligibleAttendees,
                                                                                                    },
                                                                                            },
                                                                                        );
                                                                                    }}
                                                                                    isSelected={isSelectedTimeslot(
                                                                                        timeslot.date,
                                                                                        range,
                                                                                    )}
                                                                                />
                                                                            );
                                                                        },
                                                                    );
                                                                },
                                                            )}
                                                        </div>
                                                    )}

                                                    <Gap size="medium" />
                                                </>
                                            );
                                        }}
                                    />
                                )}
                        </div>
                    </FieldGroup>

                    <Fields.Input
                        name="note"
                        label={t("cluster.upgrade.note.label")}
                    />

                    <Fields.Select
                        mode="tags"
                        name="additionalAttendees"
                        label={t("cluster.upgrade.additional-attendees.label")}
                        validator={areEmailsValid}
                        options={
                            userEmails &&
                            userEmails.map(email => ({
                                label: email,
                                value: email,
                            }))
                        }
                    />

                    {selectedCluster &&
                        getMaybeClusters()[selectedCluster]?.productionType && (
                            <div className="pmUpgradeForm">
                                <Fields.Checkbox
                                    name="consent"
                                    label={t("cluster.upgrade.consent.text")}
                                    initialValue={false}
                                    validator={pmValidators.isRequired}
                                />
                            </div>
                        )}
                    <ButtonGroup>
                        <SubmitButton
                            disabled={isSubmitting}
                            withoutSpinner
                            type="primary"
                            track={{
                                clusterUpgradeType: selectedType,
                                isMajor: isMajorChange(
                                    getMaybeClusters()[selectedCluster]
                                        ?.clusterVersion,
                                    selectedSnapshot,
                                ),
                            }}
                            label={
                                isEditation
                                    ? t("general.save")
                                    : t("general.request")
                            }
                        />
                        <Button
                            label={t("general.cancel")}
                            onClick={navigateToClusterListLocation}
                        />
                    </ButtonGroup>
                </>
            </Form>
        </>
    );
};

UpgradeScheduling.propTypes = {
    accountId: PropTypes.number.isRequired,
    eventId: PropTypes.string,
    initialValues: PropTypes.object,
    navigateToClusterListLocation: PropTypes.func.isRequired,
};

export default UpgradeScheduling;
