import { Checkbox } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useAvailableInfraVersions } from "@/components/IntegrationManagerVersions/loadables";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { CLOUD_PROVIDERS } from "@/constants/cloudProviders.constants";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { useFeatureFlag } from "@/security/hooks/useFeatureFlag.hook";
import { t } from "@/translations";
import { editableCellRenderer } from "@pricefx/unity-components";
import React, { useEffect, useState } from "react";

const preferencesType =
    preferencesTypes.INTEGRATION_MANAGER_VERSIONS_LIST_TABLE;

export function IntegrationManagerVersionsPage() {
    useFullWidthTableLayout();

    const isGCPEnabled = useFeatureFlag("im-gcp-provider");

    const { imVersionsService } = useDic();

    const [integrationManagerVersions, setIntegrationManagerVersions] =
        useState([]);
    const [loading, setLoading] = useState([]);

    const fetchIntegrationManagerVersions = () => {
        setLoading(true);
        imVersionsService.fetchVersions().then(response => {
            setIntegrationManagerVersions(response.data);
            setLoading(false);
        });
    };

    const setCreateInKubernetes = (record, value) => {
        record.createInKubernetes = value;
        imVersionsService
            .updateVersion(record)
            .then(fetchIntegrationManagerVersions);
    };

    const setAllowedNew = (record, value) => {
        if (record.createInKubernetes && !value)
            setCreateInKubernetes(record, false);

        record.allowedNew = value;
        imVersionsService
            .updateVersion(record)
            .then(fetchIntegrationManagerVersions);
    };

    const setAllowedUpgradeInCloud = (record, value) => {
        record.allowedUpgradeInCloud = value;
        imVersionsService
            .updateVersion(record)
            .then(fetchIntegrationManagerVersions);
    };

    const setAllowedUpgradeHetzner = (record, value) => {
        record.allowedUpgradeHetzner = value;
        imVersionsService
            .updateVersion(record)
            .then(fetchIntegrationManagerVersions);
    };

    const setIsTransition = (record, value) => {
        record.transition = value;
        imVersionsService
            .updateVersion(record)
            .then(fetchIntegrationManagerVersions);
    };

    useEffect(() => {
        fetchIntegrationManagerVersions();
    }, []);

    const infraVersions = useAvailableInfraVersions();
    const infraAwsVersionsOptions = (infraVersions.loadable.valueMaybe() ??
        [])?.[CLOUD_PROVIDERS.AWS]?.map(version => ({
        label: version,
        value: version,
    }));
    const infraGcpVersionsOptions = (infraVersions.loadable.valueMaybe() ??
        [])?.[CLOUD_PROVIDERS.GCP]?.map(version => ({
        label: version,
        value: version,
    }));

    const columns = [
        {
            type: fieldTypes.TEXT,
            label: t("integration-manager-versions.label.name"),
            name: "name",
        },
        {
            type: fieldTypes.TEXT,
            editable: true,
            canFilter: false,
            label: t(
                "integration-manager-versions.label.bound-infra-version-aws",
            ),
            name: "boundInfraVersionAws",
            options: infraAwsVersionsOptions,
            render: editableCellRenderer((_, name, value, record) => {
                imVersionsService
                    .updateVersion({ ...record, [name]: value })
                    .then(fetchIntegrationManagerVersions);
            }),
        },
        {
            type: fieldTypes.TEXT,
            editable: true,
            visible: isGCPEnabled,
            canFilter: false,
            label: t(
                "integration-manager-versions.label.bound-infra-version-gcp",
            ),
            name: "boundInfraVersionGcp",
            options: infraGcpVersionsOptions,
            render: editableCellRenderer((_, name, value, record) => {
                imVersionsService
                    .updateVersion({ ...record, [name]: value })
                    .then(fetchIntegrationManagerVersions);
            }),
        },
        {
            name: "transition",
            canFilter: false,
            canSort: false,
            label: t("integration-manager-versions.label.is-transition"),
            render: (text, record) => {
                return (
                    <Checkbox
                        checked={record.transition}
                        onChange={e => {
                            setIsTransition(record, e.target.checked);
                        }}
                    />
                );
            },
        },
        {
            name: "allowedUpgradeInCloud",
            canFilter: false,
            canSort: false,
            label: t("integration-manager-versions.label.allowed-upgrade-aws"),
            render: (text, record) => {
                return (
                    <Checkbox
                        checked={record.allowedUpgradeInCloud}
                        onChange={e => {
                            setAllowedUpgradeInCloud(record, e.target.checked);
                        }}
                    />
                );
            },
        },
        {
            name: "allowedUpgradeHetzner",
            canFilter: false,
            canSort: false,
            label: t(
                "integration-manager-versions.label.allowed-upgrade-hetzner",
            ),
            render: (text, record) => {
                return (
                    <Checkbox
                        checked={record.allowedUpgradeHetzner}
                        onChange={e => {
                            setAllowedUpgradeHetzner(record, e.target.checked);
                        }}
                    />
                );
            },
        },
        {
            name: "allowedNew",
            canFilter: false,
            canSort: false,
            label: t("integration-manager-versions.label.allowed-new"),
            render: (text, record) => {
                return (
                    <Checkbox
                        checked={record.allowedNew}
                        onChange={e => {
                            setAllowedNew(record, e.target.checked);
                        }}
                    />
                );
            },
        },
        {
            name: "createInKubernetes",
            canFilter: false,
            canSort: false,
            label: t("integration-manager-versions.label.create-in-kubernetes"),
            render: (text, record) => {
                return (
                    <Checkbox
                        checked={record.createInKubernetes}
                        disabled={!record.allowedNew}
                        onChange={e => {
                            setCreateInKubernetes(record, e.target.checked);
                        }}
                    />
                );
            },
        },
    ];

    return (
        <TableWithPreferencesManagement
            fixed
            columns={columns}
            dataSource={integrationManagerVersions}
            rowKey="name"
            loading={loading}
            datasetSlicing="local"
            preferencesType={preferencesType}
        />
    );
}
