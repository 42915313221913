import { useDic } from "@/components/Dic/useDic.hook";
import { SidebarAndContentLayout } from "@/components/Layouts";
import { LocationRoutes, useRouteParams } from "@/modules/router";
import { SecurityContext } from "@/security/authorization";
import { defaultAdministrationLocation } from "@/utils/location/location.utils";
import React, { useContext, useEffect } from "react";
import { AdministrationAppMenu } from "../AdministrationAppMenu/AdministrationAppMenu.component";

export function AdministrationApp() {
    const {
        locationRouterService,
        administrationAppLocations,
        locations: { noRightsLocation },
        administrationAppLocations: {
            usersLocation,
            groupsLocation,
            globalAlertsRulesLocation,
            activityLogLocation,
            constantsLocation,
            featureFlagsLocation,
        },
    } = useDic();

    const securityContext = useContext(SecurityContext);

    const isDefaultAdministrationLocation =
        useRouteParams().routeName === "administrationApp";

    useEffect(() => {
        isDefaultAdministrationLocation &&
            defaultAdministrationLocation(
                locationRouterService,
                securityContext.globalPermissions,
                usersLocation,
                groupsLocation,
                globalAlertsRulesLocation,
                activityLogLocation,
                constantsLocation,
                featureFlagsLocation,
                noRightsLocation,
            );
    });

    return (
        <SidebarAndContentLayout sidebar={<AdministrationAppMenu />}>
            <LocationRoutes locations={administrationAppLocations} />
        </SidebarAndContentLayout>
    );
}
