import { DataUploadsList } from "@/components/DataUploads/DataUploadsList";
import { UploadLogsList } from "@/components/DataUploads/UploadLogsList";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    HeaderTabs,
    useHeaderTabs,
} from "@/components/PageLayout/components/HeaderTabs.component";
import { ConnectionsList } from "@/components/PartitionDataManagement/ConnectionsList";
import { DownloadsList } from "@/components/PartitionDataManagement/DownloadsList";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { useFeatureFlag } from "@/security/hooks/useFeatureFlag.hook";
import { t } from "@/translations";
import React, { useReducer } from "react";

export const PDM_TABS = {
    UPLOADS: "UPLOADS",
    LOGS: "LOGS",
    DOWNLOADS: "DOWNLOADS",
    CONNECTIONS: "CONNECTIONS",
};

export const PartitionDataManagementPage = () => {
    const { partitionId, accountId } = useAccountAppParams();
    const { activeKey, setActiveKey } = useHeaderTabs();
    const [reloadToken, reloadAll] = useReducer(s => ++s, 0);

    const downloadEnabled = useFeatureFlag("allow-data-download");

    useFullWidthTableLayout();
    // useRefreshAction(reloadAll);

    const {
        locationRouterService,
        // accountAppLocations: {  },
    } = useDic();
    const isVisible = tab => activeKey === tab;

    return (
        <>
            <HeaderTabs defaultActiveKey={PDM_TABS.UPLOADS}>
                <HeaderTabs.TabPane
                    tab={t("partition.data-management.tabs.uploads")}
                    key={PDM_TABS.UPLOADS}
                    forceRender
                    // permission: ACCOUNT_DATA_LOAD_PERMISSIONS,
                >
                    {isVisible(PDM_TABS.UPLOADS) && (
                        <DataUploadsList
                            projectId={accountId}
                            partitionId={partitionId}
                            visible={isVisible(PDM_TABS.UPLOADS)}
                        />
                    )}
                </HeaderTabs.TabPane>
                <HeaderTabs.TabPane
                    tab={t("partition.data-management.tabs.upload-logs")}
                    key={PDM_TABS.LOGS}
                    forceRender
                    // permission: ACCOUNT_OVERVIEW_PERMISSIONS,
                >
                    {isVisible(PDM_TABS.LOGS) && (
                        <UploadLogsList
                            projectId={accountId}
                            partitionId={partitionId}
                            visible={isVisible(PDM_TABS.LOGS)}
                        />
                    )}
                </HeaderTabs.TabPane>
                <HeaderTabs.TabPane
                    tab={t("partition.data-management.tabs.downloads")}
                    key={PDM_TABS.DOWNLOADS}
                    forceRender
                    visible={downloadEnabled}
                >
                    {isVisible(PDM_TABS.DOWNLOADS) && (
                        <DownloadsList
                            projectId={accountId}
                            partitionId={partitionId}
                            visible={isVisible(PDM_TABS.DOWNLOADS)}
                        />
                    )}
                </HeaderTabs.TabPane>
                <HeaderTabs.TabPane
                    tab={t("partition.data-management.tabs.connections")}
                    key={PDM_TABS.CONNECTIONS}
                    forceRender
                    visible={downloadEnabled}
                >
                    {isVisible(PDM_TABS.CONNECTIONS) && (
                        <ConnectionsList
                            projectId={accountId}
                            partitionId={partitionId}
                            visible={isVisible(PDM_TABS.CONNECTIONS)}
                        />
                    )}
                </HeaderTabs.TabPane>
            </HeaderTabs>
        </>
    );
};
