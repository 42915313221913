import { useDic } from "@/components/Dic/useDic.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";
import { ClassForm } from "./ClassForm.component";

export const NewClassPage = () => {
    const { instanceId } = useAccountAppParams();
    useSetPageTitle(t("class-form.title.new-class"));
    const {
        locationRouterService,
        accountAppLocations: { classesLocation },
    } = useDic();
    const onCancel = useCallback(
        () => locationRouterService.navigate(classesLocation),
        [classesLocation, locationRouterService],
    );

    return (
        <ClassForm instanceId={instanceId} classId="new" onCancel={onCancel} />
    );
};
