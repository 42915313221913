import { Popover, UnityIcon } from "@/components/DesignSystem";
import { t } from "@/translations";
import { ReactComponent as IconInfoCircle } from "@pricefx/unity-components/dist/es/icons/unicons/info-circle.svg";
import React from "react";
import styles from "./styles.less";

export const DeprecatedIcon = ({ style = {} }) => {
    return (
        <Popover
            content={t("deprecated.accelerator.text")}
            title={t("deprecated.title")}
            overlayClassName={styles.wrapper}
        >
            <UnityIcon
                component={IconInfoCircle}
                style={{
                    fontSize: 16,
                    color: "#FAAD14",
                    ...style,
                }}
            />
        </Popover>
    );
};
