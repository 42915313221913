import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import Moment from "react-moment";
import { ErrorLogModal } from "../../../marketplaceApp/pages/FailedDeploymentsPage/ErrorLogModal";

const preferencesType = preferencesTypes.ACCOUNT_DEPLOYED_PACKAGES_TABLE;

const canSeeErrorLogs = record =>
    record.log || record.partitionLog || record.messages || record.errorData;

export const AccountDeployedPackages = ({ accountId }) => {
    useHideBackButton();

    const { packageService } = useDic();

    const [tableProps] = useFetchPage(
        (page, size, sort, filter) =>
            packageService.getDeployedPackagesForAccount(
                page,
                size,
                sort,
                filter,
                accountId,
            ),
        [],
    );
    const actionMenu = useCallback(
        record =>
            !canSeeErrorLogs(record) ? undefined : (
                <ActionButton
                    record={record}
                    items={[
                        {
                            title: t(
                                "failed-deployments-list.action-menu.view-error-logs",
                            ),
                            customModal: <ErrorLogModal record={record} />,
                        },
                    ]}
                />
            ),
        [],
    );

    return (
        <TableWithPreferencesManagement
            fixed
            rowKey="id"
            datasetSlicing="server"
            preferencesType={preferencesType}
            defaultSort={{ fieldName: "updatedAt", sortDir: "descending" }}
            actionMenu={actionMenu}
            columns={[
                {
                    type: fieldTypes.TEXT,
                    label: t("account-deployed-templates.header.label"),
                    name: "name",
                    className: "pmTable__column--withActionButton",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("account-deployed-templates.header.template-name"),
                    name: "templateName",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t(
                        "account-deployed-templates.header.template-version",
                    ),
                    name: "templateVersion",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t(
                        "account-deployed-templates.header.partition-name",
                    ),
                    name: "serialNumber",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("account-deployed-templates.header.instance-name"),
                    name: "instanceName",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("account-deployed-templates.header.deployed-by"),
                    name: "startedBy",
                },
                {
                    type: fieldTypes.DATETIME,
                    label: t("account-deployed-templates.header.deployed-at"),
                    name: "updatedAt",
                    render: (text, record) =>
                        record.updatedAt && (
                            <Moment format="DD/MM/YYYY HH:mm:ss">
                                {record.updatedAt}
                            </Moment>
                        ),
                },
                {
                    type: fieldTypes.OPTION,
                    label: t("account-deployed-templates.header.status"),
                    name: "status",
                    options: [
                        { value: "FAILED", label: "Failed" },
                        { value: "FINISHED", label: "Finished" },
                        { value: "IN_PROGRESS", label: "In progress" },
                        { value: "ROLL_BACKED", label: "Rollback" },
                        {
                            value: "ROLL_BACK_IN_PROGRESS",
                            label: "Rollback in Progress",
                        },
                    ],
                },
            ]}
            {...tableProps}
            pmExpandable={{
                expandedRowRender: record => <pre>{record.log}</pre>,
            }}
            exportUrl={`/api/accounts/${accountId}/package-deployments/export`}
        />
    );
};

AccountDeployedPackages.propTypes = {
    accountId: PropTypes.number.isRequired,
};
