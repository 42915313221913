import {
    useActivateAccountMutation,
    useActivationUsernameQuery,
} from "@/components/ActivateAccount/loadables";
import { Card, Col, Row } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { LoadableRenderer } from "@/modules/loadable";
import { useRouteParams } from "@/modules/router";
import PropTypes from "prop-types";
import React from "react";
import { ActivateAccountForm } from "./ActivateAccountForm";

export const ActivateAccountPage = () => {
    const {
        locationRouterService,
        locations: { defaultRouteLocation },
    } = useDic();
    const { activationToken } = useRouteParams();
    const activationUsernameResource = useActivationUsernameQuery({
        activationToken,
    });
    const activateAccountMutation = useActivateAccountMutation({
        afterSuccess: () =>
            locationRouterService.navigate(defaultRouteLocation),
    });
    const onSubmit = ({ values }) => {
        return activateAccountMutation.mutate(activationToken, {
            ...values,
            repeatedPassword: values.password,
        });
    };

    return (
        <Row type="flex" justify="center" align="middle">
            <Col>
                <Card type="raised" spacing="huge" footer>
                    <LoadableRenderer
                        loadable={activationUsernameResource.loadable}
                        hasValue={username => (
                            <ActivateAccountForm
                                username={username}
                                onSubmit={onSubmit}
                                onCancel={() => {
                                    locationRouterService.navigate(
                                        defaultRouteLocation,
                                    );
                                }}
                            />
                        )}
                        hasError={() => {
                            locationRouterService.navigate(
                                defaultRouteLocation,
                            );
                            return null;
                        }}
                    />
                </Card>
            </Col>
        </Row>
    );
};

ActivateAccountPage.propTypes = {
    username: PropTypes.string,
    onActivateAccount: PropTypes.func.isRequired,
};
