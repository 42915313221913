import { useDic } from "@/components/Dic/useDic.hook";
import { SidebarAndContentLayout } from "@/components/Layouts";
import { LocationRoutes, useRouteParams } from "@/modules/router";
import { SecurityContext } from "@/security/authorization";
import { defaultOverviewLocation } from "@/utils/location/location.utils";
import React, { useContext, useEffect } from "react";
import { OverviewAppSidebar } from "../OverviewAppSidebar.component";

export function OverviewApp() {
    const {
        locationRouterService,
        overviewAppLocations,
        locations: { noRightsLocation },
        overviewAppLocations: {
            accountsLocation,
            clustersLocation,
            nodesLocation,
            partitionsLocation,
            integrationsLocation,
            updatesLocation,
            remoteLoginLocation,
            serversLocation,
            upgradesLocation,
            upgradesSlotsLocation,
        },
    } = useDic();

    const securityContext = useContext(SecurityContext);

    const isDefaultOverviewLocation =
        useRouteParams().routeName === "overviewApp";

    useEffect(() => {
        isDefaultOverviewLocation &&
            defaultOverviewLocation(
                locationRouterService,
                securityContext.globalPermissions,
                accountsLocation,
                clustersLocation,
                nodesLocation,
                partitionsLocation,
                integrationsLocation,
                updatesLocation,
                remoteLoginLocation,
                serversLocation,
                upgradesLocation,
                upgradesSlotsLocation,
                noRightsLocation,
            );
    });

    return (
        <SidebarAndContentLayout sidebar={<OverviewAppSidebar />}>
            <LocationRoutes locations={overviewAppLocations} />
        </SidebarAndContentLayout>
    );
}
