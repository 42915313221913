import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { ContentLayoutContext } from "./ContentLayout.context";
import { contentLayoutsEnum } from "./contentLayouts.enum";

export function ContentLayoutProvider({ children }) {
    const [layout, setLayout] = useState(contentLayoutsEnum.centered);

    const contextValue = useMemo(
        () => ({
            layout,
            setLayout,
        }),
        [layout, setLayout],
    );

    return (
        <ContentLayoutContext.Provider value={contextValue}>
            {children}
        </ContentLayoutContext.Provider>
    );
}

ContentLayoutProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
