import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";
// import { STATUS } from "./InstancePermissionsPanel";

export const createUsersPermissionsColumns = ({
    onRemove,
    // resetPassword,
    // changeStatus
}) => [
    {
        name: "username",
        label: t("general.username"),
        type: fieldTypes.TEXT,
        render: (text, record) => (
            <>
                {text}
                <ActionButton
                    record={record}
                    items={[
                        // {
                        //     title: "Reset GIT password",
                        //     confirmMessage:
                        //         "Instructions with resetting GIT password will be send for the user.",
                        //     confirmButtonTitle: "Reset Password",
                        //     onConfirm: resetPassword
                        // },
                        // {
                        //     title:
                        //         record.status === STATUS.BLOCKED
                        //             ? "Unblock User"
                        //             : "Block User",
                        //     onClick: changeStatus
                        // },
                        {
                            title: t("general.remove-user"),
                            confirmMessage: t(
                                "general.do-you-really-want-to-delete-name",
                                { name: record.username },
                            ),
                            onConfirm: onRemove,
                            color: "red",
                        },
                    ]}
                />
            </>
        ),
    },
    // {
    //     name: "permission",
    //     label: t("general.permission"),
    //     type: fieldTypes.ENTITY_REFERENCE
    // },
    // {
    //     name: "status",
    //     label: t("general.status"),
    //     type: fieldTypes.ENTITY_REFERENCE
    // }
];
export const addUsersColumns = () => [
    {
        name: "username",
        label: t("general.username"),
        type: fieldTypes.TEXT,
    },
    {
        name: "fullName",
        label: t("general.name"),
        type: fieldTypes.TEXT,
    },
];
