import React from "react";
import moment from "moment";

const ProcessingTime = props => {
    const { from, to } = props;

    if (from == null || to == null) {
        return "";
    } else {
        const duration = moment.duration(moment(to).diff(from)).humanize();
        return <div className="text-small-silver">{duration}</div>;
    }
};

export default ProcessingTime;
