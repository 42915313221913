import { useFullWidthLayout } from "@/components/hooks/useContentLayout.hook";
import RouteDocumentation from "@/components/IntegrationRoutes/components/RouteDocumentation";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React from "react";

export const RouteDocumentationPage = () => {
    const { accountId, instanceId, routeId } = useAccountAppParams();

    useFullWidthLayout();

    return (
        <RouteDocumentation
            accountId={accountId}
            routeId={routeId}
            instanceId={instanceId}
        />
    );
};
