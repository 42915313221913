import qs from "qs";
import { axiosService } from "../axios";

const PROJECTS_ENDPOINT = "/api/projects";

export const upgradeSchedulingService = {
    fetchAvailableUpgrades(projectId) {
        return axiosService.get(
            `${PROJECTS_ENDPOINT}/${projectId}/clusters-with-available-upgrades`,
        );
    },
    fetchAvailableUpgradeTimes(projectId, params) {
        return axiosService
            .get(
                `${PROJECTS_ENDPOINT}/${projectId}/available-times?${qs.stringify(
                    params,
                )}`,
            )
            .then(response => response.data);
    },
    scheduleUpgrade(projectId, clusterName, event) {
        return axiosService.post(
            `${PROJECTS_ENDPOINT}/${projectId}/${clusterName}/schedule-upgrade`,
            event,
        );
    },
    deleteUpgradeEvent(projectId, eventId) {
        return axiosService.delete(
            `${PROJECTS_ENDPOINT}/${projectId}/upgrades/${eventId}`,
        );
    },
    loadEvent(projectId, eventId) {
        return axiosService.get(
            `${PROJECTS_ENDPOINT}/${projectId}/cluster-upgrade-events/${eventId}`,
        );
    },
};
