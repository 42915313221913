import {
    ButtonMenu,
    Modal,
    Radio,
    Table,
    UnityLayout,
} from "@/components/DesignSystem";
import { LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./PreferencesManager.styles.less";
import { useDeleteWithConfirmation } from "./hooks/useDeleteWithConfirmation.hook";
import { useEditPreference } from "./loadableResources";

const ManagePreferencesTable = ({
    preferences,
    activeId,
    setActiveId,
    onDeleteWithConfirmation,
}) => {
    return (
        <Table
            columns={[
                { name: "name", label: "Preference name" },
                {
                    name: "active",
                    label: "Default for me",
                    render: (_active, record) => (
                        <Radio
                            onChange={() => setActiveId(record.id)}
                            checked={activeId === record.id}
                            value={record.id}
                        />
                    ),
                },
                {
                    name: "action",
                    label: "Action",
                    render: (_, record) => (
                        <span
                            onClick={() => onDeleteWithConfirmation(record)}
                            style={{
                                color: "red",
                                cursor: "pointer",
                            }}
                        >
                            Delete
                        </span>
                    ),
                },
            ]}
            dataSource={preferences}
            rowKey="id"
            className={styles.preferencesManagerTable}
        />
    );
};

export const ManagePreferencesModal = ({
    visible,
    onOk,
    onCancel,
    preferencesLoadable,
    onDelete,
}) => {
    const initialActivePreference = useMemo(
        () => preferencesLoadable?.contents?.find?.(({ active }) => active),
        [preferencesLoadable],
    );
    const [activeId, setActiveId] = useState();
    useEffect(() => {
        setActiveId(initialActivePreference?.id);
    }, [initialActivePreference]);
    const editPreferenceResource = useEditPreference(onOk);
    const save = () => {
        if (initialActivePreference?.id !== activeId) {
            const activePreference = preferencesLoadable?.contents?.find?.(
                ({ id }) => id === activeId,
            );
            editPreferenceResource.mutate({
                ...activePreference,
                active: true,
            });
        }
    };
    const onDeleteWithConfirmation = useDeleteWithConfirmation(onDelete);

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header size={3} title={"Manage Preferences"} />
                <UnityLayout.Content padding={[true, true]}>
                    <LoadableRenderer
                        loadable={preferencesLoadable}
                        hasValue={preferences => (
                            <ManagePreferencesTable
                                preferences={preferences}
                                activeId={activeId}
                                setActiveId={setActiveId}
                                onDeleteWithConfirmation={
                                    onDeleteWithConfirmation
                                }
                            />
                        )}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.save"),
                                    onClick: save,
                                    type: "primary",
                                    disabled:
                                        initialActivePreference?.id ===
                                        activeId,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onCancel,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

ManagePreferencesModal.propTypes = {
    visible: PropTypes.bool,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    preferencesLoadable: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
};
