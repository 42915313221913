import { Button, Forms, Gap, H3 } from "@/components/DesignSystem";
import { Collapse } from "@pricefx/unity-components";
import PropTypes from "prop-types";
import React from "react";

export const PfxIdentification = ({ onGenerate, values }) => {
    return (
        <>
            <H3>Pricefx identification</H3>
            {values ? (
                <>
                    <Forms.Form>
                        <Collapse>
                            <Collapse.Panel header="Reveal" key="1">
                                <Forms.Fields.Input
                                    name="entityId"
                                    label="Entity ID"
                                    disabled
                                    initialValue={values.entityId}
                                    inputWidth="max"
                                />
                                <Forms.Fields.Input
                                    name="redirectUrl"
                                    label="Redirect Url"
                                    disabled
                                    initialValue={values.redirectUrl}
                                    inputWidth="max"
                                />
                            </Collapse.Panel>
                        </Collapse>
                    </Forms.Form>
                </>
            ) : (
                <Button
                    label="Generate connection information"
                    onClick={onGenerate}
                />
            )}
            <Gap />
        </>
    );
};

PfxIdentification.propTypes = {
    onGenerate: PropTypes.func,
    values: PropTypes.shape({
        entityId: PropTypes.string,
        redirectUrl: PropTypes.string,
    }),
};
