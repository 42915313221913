import { useDic } from "@/components/Dic/useDic.hook";
import {
    LocationMenuContainer,
    LocationMenuItem,
    LocationSubMenu,
} from "@/components/Menu";
import { SidebarContent } from "@/components/Sidebar";
import { SecurityContext } from "@/security/authorization";
import { hasPermission } from "@/security/permission.utils";
import React, { useContext } from "react";

const ItemOrSubMenu = hasChildren =>
    hasChildren ? LocationSubMenu : LocationMenuItem;

export function OverviewAppSidebar() {
    const {
        overviewAppLocations: {
            accountsLocation,
            clustersLocation,
            nodesLocation,
            partitionsLocation,
            integrationsLocation,
            updatesLocation,
            remoteLoginLocation,
            serversLocation,
            upgradesLocation,
            upgradesSlotsLocation,
            integrationArchivesLocation,
        },
    } = useDic();
    const securityContext = useContext(SecurityContext);

    const UpgradesMenu = ItemOrSubMenu(
        hasPermission(
            securityContext.permissions,
            [upgradesSlotsLocation.permission].flat(),
        ),
    );

    return (
        <SidebarContent>
            <LocationMenuContainer mode="inline" condensed eager>
                <LocationMenuItem
                    key={accountsLocation.routeName}
                    $location={accountsLocation}
                />
                <LocationMenuItem
                    key={clustersLocation.routeName}
                    $location={clustersLocation}
                />
                <LocationMenuItem
                    key={nodesLocation.routeName}
                    $location={nodesLocation}
                />
                <LocationMenuItem
                    key={partitionsLocation.routeName}
                    $location={partitionsLocation}
                />
                <LocationMenuItem
                    key={integrationsLocation.routeName}
                    $location={integrationsLocation}
                />
                <LocationMenuItem
                    key={updatesLocation.routeName}
                    $location={updatesLocation}
                />
                <LocationMenuItem
                    key={remoteLoginLocation.routeName}
                    $location={remoteLoginLocation}
                />
                <LocationMenuItem
                    key={serversLocation.routeName}
                    $location={serversLocation}
                />
                <LocationMenuItem
                    key={integrationArchivesLocation.routeName}
                    $location={integrationArchivesLocation}
                />

                <LocationMenuContainer mode="inline" eager condensed>
                    <UpgradesMenu
                        key={upgradesLocation.routeName}
                        $location={upgradesLocation}
                    >
                        <LocationMenuItem
                            key={upgradesSlotsLocation.routeName}
                            $location={upgradesSlotsLocation}
                        />
                    </UpgradesMenu>
                </LocationMenuContainer>
            </LocationMenuContainer>
        </SidebarContent>
    );
}
