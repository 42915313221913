import ActivityLogList from "@/components/ActivityLog/ActivityLogList";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React from "react";

export function AccountActivityLogPage() {
    const { accountId } = useAccountAppParams();

    useFullWidthTableLayout();

    return <ActivityLogList accountId={accountId} />;
}
