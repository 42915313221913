import { DataUploadWizard } from "@/components/DataUploads/Wizard/DataUploadWizard";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { LoadableRenderer, useQueryLoadable } from "@/modules/loadable/index";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { getData } from "@/services/utils";
import { t } from "@/translations/";
import React, { useCallback } from "react";

export function EditUploadPage() {
    const { axiosService } = useDic();
    const { accountId, uploadId, partitionId } = useAccountAppParams();
    useSetPageTitle(t("data-upload-history.title"));
    const { locationRouterService, accountAppLocations } = useDic();
    const showDataUploads = useCallback(
        () =>
            locationRouterService.navigate(
                accountAppLocations.partitionUploadsLocation,
            ),
        [accountAppLocations.partitionUploadsLocation, locationRouterService],
    );
    useSetBackButtonAction(showDataUploads);

    const dataUploadResource = useQueryLoadable(
        async () =>
            axiosService
                .get(`api/partitions/${partitionId}/data-uploads/${uploadId}`)
                .then(getData),
        [axiosService, partitionId, uploadId],
    );

    return (
        <LoadableRenderer
            loadable={dataUploadResource.loadable}
            hasValue={upload => (
                <DataUploadWizard
                    projectId={accountId}
                    partitionId={partitionId}
                    upload={upload}
                />
            )}
        />
    );
}
