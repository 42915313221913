import qs from "qs";
import { axiosService } from "../axios";

export const CONNECTION_TYPE_PRICEFX_CLIENT = "pricefx-client";

const qsOptions = { addQueryPrefix: true };

export const connectionsService = {
    testConnection(instanceId, connectionData) {
        return axiosService.post(
            `/api/instances/${instanceId}/connections/test`,
            connectionData,
        );
    },

    saveConnection(instanceId, connectionId, connectionData, query) {
        const queryStr = qs.stringify(query, qsOptions);
        return axiosService.post(
            `/api/instances/${instanceId}/connections/${connectionId}${queryStr}`,
            { id: connectionId, ...connectionData },
        );
    },

    copy(instanceId, connectionId, payload) {
        return axiosService.post(
            `/api/instances/${instanceId}/connections/${connectionId}/copy`,
            payload,
        );
    },

    createConnection(instanceId, connectionData, query) {
        const queryStr = qs.stringify(query, qsOptions);
        return axiosService.post(
            `/api/instances/${instanceId}/connections${queryStr}`,
            connectionData,
        );
    },

    fetchConnectionsByType(instanceId, type) {
        return axiosService.get(
            `/api/instances/${instanceId}/connections?type=${type}`,
        );
    },

    fetchConnectionsFilterByType(instanceId, type) {
        return axiosService.get(
            `/api/instances/${instanceId}/connections/filters?type=${type}`,
        );
    },

    fetchConnectionsAvailableOnTemplatesByType(instanceId, type) {
        return axiosService.get(
            `/api/instances/${instanceId}/connections/available-on-templates?type=${type}`,
        );
    },

    fetchConnectionType(instanceId, typeName) {
        return axiosService.get(
            `/api/connections/${instanceId}/types/${typeName}`,
        );
    },

    fetchConnectionTypes(instanceId) {
        return axiosService.get(`/api/connections/${instanceId}/types`);
    },

    fetchConnections(instanceId) {
        return axiosService.get(`/api/instances/${instanceId}/connections`);
    },

    fetchConnection(instanceId, connectionId) {
        return axiosService.get(
            `/api/instances/${instanceId}/connections/${connectionId}`,
        );
    },

    deleteConnection(instanceId, connectionId) {
        return axiosService.delete(
            `/api/instances/${instanceId}/connections/${connectionId}`,
        );
    },

    deployConnection(instanceId, connectionId, query) {
        const queryStr = qs.stringify(query, qsOptions);
        return axiosService.post(
            `/api/instances/${instanceId}/connections/${connectionId}/deploy${queryStr}`,
        );
    },

    fetchConfigurations(instanceId) {
        return axiosService.get(
            `/api/general-mapper/instance/${instanceId}/configurations`,
        );
    },

    fetchConfigurationsData(instanceId) {
        return axiosService.get(
            `/api/general-mapper/instance/${instanceId}/configurations-data`,
        );
    },

    fetchConnectionData(
        instanceId,
        connectionName,
        configurationName,
        properties,
    ) {
        return axiosService.post(
            `/api/general-mapper/instance/${instanceId}/connection-data`,
            {
                connectionName: connectionName,
                configurationName: configurationName,
                properties: properties,
            },
        );
    },

    setConnectionDebug(debug, instanceId, name) {
        return axiosService.put(
            `/api/instances/${instanceId}/connections/online/debug`,
            { name, debug },
        );
    },

    fetchInstanceRepoConnections(instanceId) {
        return axiosService.get(
            `/api/instances/${instanceId}/connections/online`,
        );
    },

    createLocalCopy(instanceId, objectKey, payload) {
        return axiosService.post(
            `/api/instances/${instanceId}/connections/${objectKey}/online-copy`,
            payload,
        );
    },

    deleteInstanceRepoConnection({ instanceId, name, prn }) {
        return axiosService.delete(
            `/api/instances/${instanceId}/connections/online/${name}`,
            {
                data: prn ? { prn } : null,
            },
        );
    },

    connectionExists({ instanceId, name, withOnline = true }) {
        return axiosService.get(
            `/api/instances/${instanceId}/connections/exists${qs.stringify(
                { name, withOnline },
                qsOptions,
            )}`,
        );
    },
};
