import { getIdsByDestination } from "@/components/Marketplace/hooks/useNavigateToPackages.hook";
import { defaultQsOptions } from "@/services/constants";
import { encodeBase64 } from "@/utils/token";
import qs from "qs";
import { axiosService } from "../axios";
import { getData } from "./utils";

const PROJECTS_ENDPOINT = "/api/projects";

export const partitionService = {
    getPartition: (accountId, partitionId) =>
        axiosService
            .get(
                `${PROJECTS_ENDPOINT}/${accountId}/partition-assets/${partitionId}`,
            )
            .then(getData),

    getPartitionLogs: (accountId, partitionId, params) =>
        axiosService
            .post(
                `${PROJECTS_ENDPOINT}/${accountId}/partition-assets/${partitionId}/elastic-search`,
                params,
            )
            .then(getData),
    getPartitionLogsExport: (accountId, partitionId, params) =>
        axiosService.post(
            `${PROJECTS_ENDPOINT}/${accountId}/partition-assets/${partitionId}/elastic-search/export`,
            params,
        ),
    deletePartition: (accountId, partitionId) =>
        axiosService.delete(
            `${PROJECTS_ENDPOINT}/${accountId}/partition-assets/${partitionId}`,
        ),
    getPresignedUrlForFileUpload({ fileName, destination, destinationId }) {
        const queryParams = getIdsByDestination(destination, destinationId);
        const queryStr = qs.stringify(queryParams, defaultQsOptions);

        return axiosService.get(
            `/api/data-loads/s3/${encodeBase64(
                fileName,
            )}/presigned-url${queryStr}`,
        );
    },
};
