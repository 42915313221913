import {
    ButtonMenu,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { getIMUpdateLeaveModalTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

export const ContinueUpdateModal = ({
    visible,
    onStartNew,
    onContinue,
    onCancel,
}) => {
    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("im-instance.update.continue.modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Text>
                        {t("im-instance.update.continue.modal.message")}
                    </Text>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.continue"),
                                    type: "primary",
                                    onClick: onContinue,
                                    track: {
                                        name: getTrackName("SaveAndLeave"),
                                    },
                                },
                                {
                                    label: t("general.discard"),
                                    type: "default",
                                    onClick: onStartNew,
                                    track: { name: getTrackName("Discard") },
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

ContinueUpdateModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onStartNew: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
