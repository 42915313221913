import { tap } from "@/components/Connections/loadables";
import { message } from "@/components/DesignSystem/";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    UserAbortError,
    useWithConflictModal,
} from "@/components/Mappers/ConflictModal";
import {
    crudSuccess,
    responseErrorMessage,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { getErrorMessageFromError } from "@/utils/state/error.utils";
import { merge, noop } from "lodash/fp";

export const useInstanceRepoFiltersQuery = ({ instanceId, reloadToken }) => {
    const { filtersService } = useDic();

    return useQueryLoadable(
        async () =>
            filtersService.fetchInstanceRepoFilters(instanceId).then(getData),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [instanceId, reloadToken, filtersService],
    );
};
export const useDeleteInstanceRepoFilterMutation = ({
    instanceId,
    afterSuccess,
}) => {
    const { filtersService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record =>
            filtersService
                .deleteInstanceRepoFilter({
                    objectKey: record.id,
                    prn: record.prn,
                })
                .then(getData)
                .then(merge(record))
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, filtersService],
        ({ name }) =>
            t("general.message.deleted", {
                type: "Instance repo filter",
                name,
            }),
    );
};

export const useLocalCopyMutation = ({ instanceId, afterSuccess = noop }) => {
    const { filtersService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record => {
            const payload = {
                name: record.name,
                parent: record.parent,
            };
            const res = await filtersService.createLocalCopy(
                record.id,
                payload,
            );
            afterSuccessCurrent();
            return res;
        },
        [afterSuccessCurrent, filtersService],
        crudSuccess("Filter Local Copy", "data.name", "C"),
    );
};

export const useFiltersQuery = ({ instanceId, reloadToken }) => {
    const { filtersService } = useDic();

    return useQueryLoadable(
        async () =>
            filtersService
                .fetchFilters(instanceId)
                .then(getData)
                .catch(e => {
                    message.error(getErrorMessageFromError(e));
                    throw e;
                }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [instanceId, reloadToken, filtersService],
    );
};

export const useDeleteFilterMutation = ({ afterSuccess = noop }) => {
    const { filtersService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record =>
            filtersService
                .deleteFilter(record.id)
                .then(getData)
                .then(merge(record))
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, filtersService],
        (
            { name }, // TODO: name? id?
        ) =>
            t("general.message.deleted", {
                type: "filter",
                name,
            }),
    );
};

export const useDeployFilterMutation = ({
    instanceId,
    afterSuccess = noop,
}) => {
    const { deploymentService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const withConflictModal = useWithConflictModal();

    return useMutationLoadableWithNotification(
        async record =>
            withConflictModal(forceCommit =>
                deploymentService.deployFilter(instanceId, record.id, {
                    forceCommit,
                }),
            )
                .then(getData)
                .then(tap(afterSuccessCurrent)),

        [afterSuccessCurrent, deploymentService, instanceId, withConflictModal],
        "deployment.filter.successful",
        e => (e instanceof UserAbortError ? null : responseErrorMessage(e)),
    );
};

export const useCopyFilterMutation = ({ filterId, afterSuccess = noop }) => {
    const { filtersService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ values }) =>
            filtersService
                .copy(filterId, values)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, filterId, filtersService],
        "filter.copy.done",
    );
};
