import { Button, Dropdown, Label, Menu } from "@/components/DesignSystem";
import { Search, searchSizesEnum } from "@/components/DesignSystem/Input";
import { useDic } from "@/components/Dic/useDic.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { useQueryLoadable } from "@/modules/loadable";
import { useConfirmModal } from "@/modules/modal/imperative/useConfirmModal.hook";
import { SecurityContext } from "@/security/authorization";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ReactComponent as IconAngleDown } from "@pricefx/unity-components/src/icons/unicons/angle-down.svg";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useState } from "react";
import { ManageQueriesModal } from "./ManageQueryModal";
import { QueryModal } from "./QueryModal";
import styles from "./QuerySearch.style.less";

const menu = (elkQueries, setSearchQuery, saveAndShare, openManageQueries) => (
    <Menu>
        {elkQueries?.map(query => (
            <Menu.Item key={Math.random()}>
                <div onClick={() => setSearchQuery(query.query)}>
                    {query.name}
                </div>
            </Menu.Item>
        ))}
        <Menu.Divider />
        <Menu.Item>
            <div onClick={saveAndShare}>
                {t("elastic-search.query.dropdown.save-and-search")}
            </div>
        </Menu.Item>
        <Menu.Item>
            <div onClick={openManageQueries}>
                {t("elastic-search.query.dropdown.manage-queries")}
            </div>
        </Menu.Item>
    </Menu>
);

export const QuerySearch = ({
    accountId,
    entityId,
    entityType,
    initialQueryString,
    isValid,
    onSearch,
}) => {
    const { elkQueryService, messageService } = useDic();
    const securityContext = useContext(SecurityContext);
    const confirmModal = useConfirmModal();

    const newQueryModal = useVisibility(false);
    const queriesListModal = useVisibility(false);

    const [state, setState] = useState({
        query: initialQueryString ?? "",
        elkQuery: {},
        queryFormInitValues: {},
    });

    const elkQueriesResource = useQueryLoadable(
        async () =>
            elkQueryService
                .fetchElkQueries(accountId, entityId, entityType)
                .then(getData),
        [accountId, elkQueryService, entityId, entityType],
    );

    const processElkQuery = useCallback(
        elkQuery => {
            elkQueryService
                .processElkQuery(accountId, entityId, entityType, elkQuery)
                .then(() => {
                    elkQueryService
                        .fetchElkQueries(accountId, entityId, entityType)
                        .then(() => elkQueriesResource.reload());
                    if (elkQuery.id) {
                        messageService.info({
                            content: t("elastic-search.message.query-changed"),
                        });
                    } else {
                        messageService.info({
                            content: t("elastic-search.message.query-saved"),
                        });
                    }
                })
                .catch(e =>
                    messageService.error({
                        content: getErrorMessage(e.response.data),
                    }),
                );
        },
        [
            accountId,
            elkQueriesResource,
            elkQueryService,
            entityId,
            entityType,
            messageService,
        ],
    );

    const onSubmit = bag => {
        processElkQuery({ ...bag.values, userId: securityContext.user.id });
        newQueryModal.hide();
    };

    const setSearchQuery = query => {
        setState({ ...state, query });
    };

    const saveAndShare = () => {
        setState({ ...state, queryFormInitValues: {} });
        newQueryModal.show();
    };

    const onQueryEdit = record => {
        setState({ ...state, queryFormInitValues: record });
        newQueryModal.show();
    };

    const handleQueryDelete = queryId => {
        confirmModal.confirm({
            title: t("elastic-search.delete-confirmation.title"),
            message: t("elastic-search.delete-confirmation.message"),
            okText: t("general.delete"),
            cancelText: t("general.cancel"),
            icon: <QuestionCircleOutlined />,
            onConfirm: () => handleElkQueryDelete(queryId),
        });
    };

    const handleElkQueryDelete = useCallback(
        queryId => {
            elkQueryService
                .deleteElkQuery(accountId, entityId, entityType, queryId)
                .then(() => {
                    elkQueryService
                        .fetchElkQueries(accountId, entityId, entityType)
                        .then(() => elkQueriesResource.reload());
                    messageService.info({
                        content: t(
                            "elastic-search.message.query-deleted-success",
                        ),
                    });
                })
                .catch(e =>
                    messageService.error({
                        content: getErrorMessage(e.response.data),
                    }),
                );
        },
        [
            accountId,
            elkQueriesResource,
            elkQueryService,
            entityId,
            entityType,
            messageService,
        ],
    );

    return (
        <div className={styles.wrapper}>
            <QueryModal
                accountId={accountId}
                entityId={entityId}
                entityType={entityType}
                close={newQueryModal.hide}
                visible={newQueryModal.visible}
                onSubmit={onSubmit}
                initialValues={
                    state?.queryFormInitValues?.id
                        ? state.queryFormInitValues
                        : { query: state.query }
                }
                isValid={isValid}
            />

            <ManageQueriesModal
                visible={queriesListModal.visible}
                elkQueries={elkQueriesResource.loadable.valueMaybe() ?? []}
                close={queriesListModal.hide}
                onQueryDelete={handleQueryDelete}
                onQueryEdit={onQueryEdit}
            />

            <label htmlFor="query-search-input" className={styles.searchInput}>
                <Label label={t("elastic-search.query.search")} />
                <Search
                    autoFocus
                    id="query-search-input"
                    size={searchSizesEnum.RESPONSIVE}
                    placeholder={t("select-columns.input.search.placeholder")}
                    onPressEnter={() => onSearch(state.query)}
                    onSearch={() => onSearch(state.query)}
                    onChange={e => setSearchQuery(e.target.value)}
                    value={state.query}
                    allowClear={false}
                />
            </label>

            <Dropdown
                overlay={menu(
                    elkQueriesResource.loadable.valueMaybe() ?? [],
                    setSearchQuery,
                    saveAndShare,
                    queriesListModal.show,
                )}
            >
                <Button
                    type="link"
                    label={
                        <div className={styles.queriesButton}>
                            {t("elastic-search.query.dropdown")}&nbsp;
                            <IconAngleDown className={styles.queriesIcon} />
                        </div>
                    }
                />
            </Dropdown>
        </div>
    );
};

QuerySearch.propTypes = {
    availableUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
    initialQueryString: PropTypes.string,
    isValid: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
};
