import InstanceDashboard from "@/components/Integrations/Dashboard/InstanceDashboard";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React from "react";
import { useRouteInstance } from "../../../mixpanel/hooks/useRouteInstance.hook";

export const InstanceDashboardPage = () => {
    const { accountId, instanceId } = useAccountAppParams();
    const { instanceLoadable } = useRouteInstance();

    return (
        <InstanceDashboard
            projectId={accountId}
            instanceId={instanceId}
            instanceLoadable={instanceLoadable}
        />
    );
};

InstanceDashboardPage.propTypes = {};
