import { Gap } from "@/components/DesignSystem/Gap";
import { Option, Select } from "@/components/DesignSystem/Select";
import { Text } from "@/components/DesignSystem/Text/Text";
import { t } from "@/translations";
import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { packageService } from "../../../../../views/Package/package.service";
import { useSetErrorAlert } from "../../../../PageLayout/useSetAlert.hook";

const DEFAULT_CURRENCY = "EUR";
const DEFAULT_OPTIONAL_CURRENCIES = ["USD", "GBP"];
const DEFAULT_SETTINGS = {
    baseCurrency: DEFAULT_CURRENCY,
    reportingCurrencies: DEFAULT_OPTIONAL_CURRENCIES,
};
export const CurrencySettings = ({
    setCurrencySettings,
    currencySettings = {
        baseCurrency: DEFAULT_CURRENCY,
        reportingCurrencies: [],
    },
}) => {
    const setErrorAlert = useSetErrorAlert();
    const [currencies, setCurrencies] = useState([]);

    useEffect(() => {
        packageService
            .fetchCurrencies()
            .then(res => setCurrencies(JSON.parse(res.data.currencies)))
            .catch(e => setErrorAlert(e.response.data));
        setCurrencySettings(currencySettings);
    }, [currencySettings]);

    const onBaseCurrencySelect = baseCurrency => {
        setCurrencySettings({
            baseCurrency,
            reportingCurrencies: currencySettings.reportingCurrencies,
        });
    };

    const onReportingCurrenciesSelect = reportingCurrencies => {
        setCurrencySettings({
            baseCurrency: currencySettings.baseCurrency,
            reportingCurrencies: reportingCurrencies,
        });
    };

    return (
        <>
            <Text size="large">{t("accelerators.currency-setting.title")}</Text>
            <Text>{t("accelerators.currency-setting.description")}</Text>

            <Gap size="small" />
            <Row type="flex" gutter={16}>
                <Col>
                    <Text type="title">
                        {t("accelerators.select.title.base-currency")}
                    </Text>
                    <Select
                        allowClear={false}
                        showSearch
                        defaultValue={currencySettings.baseCurrency}
                        placeholder={t("placeholder.please-select")}
                        style={{ width: 250 }}
                        onChange={onBaseCurrencySelect}
                    >
                        {currencies
                            .filter(
                                option =>
                                    !currencySettings.reportingCurrencies?.includes(
                                        option.value,
                                    ),
                            )
                            .map(option => (
                                <Option
                                    title={option.label}
                                    key={option.label}
                                    value={option.value}
                                >
                                    {option.label}
                                </Option>
                            ))}
                    </Select>
                </Col>

                <Col>
                    <Text type="title">
                        {t("accelerators.select.title.reporting-currencies")}
                    </Text>
                    <Select
                        showSearch
                        defaultValue={currencySettings.reportingCurrencies}
                        placeholder={t("placeholder.please-select")}
                        style={{ width: 250 }}
                        onChange={onReportingCurrenciesSelect}
                        mode="multiple"
                    >
                        {currencies
                            .filter(option => {
                                return (
                                    option.value !==
                                    currencySettings.baseCurrency
                                );
                            })
                            .map(option => (
                                <Option
                                    title={option.label}
                                    key={option.label}
                                    value={option.value}
                                >
                                    {option.label}
                                </Option>
                            ))}
                    </Select>
                </Col>
            </Row>
        </>
    );
};

CurrencySettings.propTypes = {
    setCurrencySettings: PropTypes.func.isRequired,
    currencySettings: PropTypes.object,
};
