import { CustomerPartitionCredentialsFormInner } from "@/components/CustomerPartitionCredentials/CustomerPartitionCredentialsFormInner";
import {
    getSaveCredentialsAlert,
    resetCredentialsAlert,
    useLoginInfoQuery,
    usePartitionDetailQuery,
    usePfmUsernameQuery,
    useResetCredentialsMutation,
    useSaveCredentialsMutation,
    useTestPartitionRights,
} from "@/components/CustomerPartitionCredentials/loadables";
import { H3, UnityLayout } from "@/components/DesignSystem";
import { useAlertEffect } from "@/components/PageLayout/useAlertEffect.hook";
import { useHeaderAlertProp } from "@/components/PageLayout/useSetAlert.hook";
import {
    hasError,
    isLoading,
    LoadableRenderer,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

export const CustomerPartitionCredentialsForm = ({
    accountId,
    partitionId,
    onCancel,
    afterSaveCredentials,
    insideModal,
}) => {
    const params = { accountId, partitionId };
    const partitionDetailQuery = usePartitionDetailQuery(params);
    const loginInfoQuery = useLoginInfoQuery(params);
    const pfmUsernameQuery = usePfmUsernameQuery(params);

    const { testRightsQuery, missingRightsMaybe, alertMaybe, setTestValues } =
        useTestPartitionRights(params);
    useAlertEffect(alertMaybe, () => alertMaybe);

    const resetCredentialsMutation = useResetCredentialsMutation({
        ...params,
        afterSuccess: testRightsQuery.reload,
    });
    useAlertEffect(
        hasError(resetCredentialsMutation),
        () => resetCredentialsAlert,
    );
    const saveCredentialsMutation = useSaveCredentialsMutation({
        ...params,
        afterSuccess: afterSaveCredentials, // Reload? Reinit when in modal?
    });
    useAlertEffect(hasError(saveCredentialsMutation), () =>
        getSaveCredentialsAlert(saveCredentialsMutation.loadable.contents),
    );

    const loading =
        isLoading(testRightsQuery.loadable) ||
        isLoading(saveCredentialsMutation) ||
        isLoading(resetCredentialsMutation);

    const alerts = useHeaderAlertProp();

    return (
        <LoadableRenderer
            loadable={useComposeLoadablesMemoized([
                partitionDetailQuery.loadable,
                loginInfoQuery.loadable,
                pfmUsernameQuery.loadable,
            ])}
            hasValue={([partition, loginInfo, defaultTechnicalUsername]) => (
                <CustomerPartitionCredentialsFormInner
                    defaultTechnicalUsername={defaultTechnicalUsername}
                    partition={partition}
                    maybeLoginInfo={loginInfo}
                    loading={loading}
                    missingRightsMaybe={missingRightsMaybe}
                    onCancel={onCancel}
                    onTest={setTestValues}
                    onSetCredentials={saveCredentialsMutation.mutate}
                    onResetCredentials={resetCredentialsMutation.mutate}
                    insideModal={insideModal}
                    headerElement={
                        insideModal ? (
                            <UnityLayout.Header
                                size={3}
                                title={t("partition-password.title")}
                                alert={alerts}
                            />
                        ) : (
                            <H3>{t("partition-password.title")}</H3>
                        )
                    }
                />
            )}
        />
    );
};

CustomerPartitionCredentialsForm.propTypes = {
    accountId: PropTypes.number.isRequired,
    partitionId: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    afterSaveCredentials: PropTypes.func.isRequired,
    insideModal: PropTypes.bool,
};
