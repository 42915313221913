import { ModalProviderPropsPropType } from "@/modules/modal/CustomModal";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { accountsService } from "../../apps/accountApp/services/accounts.service";
import { connectionsService } from "../../services/connections.service";
import { CopyConnectionDialog } from "./CopyConnectionDialog";

export const CopyConnectionDialogContainer = ({
    modalProviderProps: { onClose, visible },
    record: { id: connectionId, name },
    accountId,
    instanceId,
    afterSuccess,
}) => {
    const [connections, setConnections] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [instances, setInstances] = useState([]);

    const handleInstanceSelect = id => {
        connectionsService
            .fetchConnections(id)
            .then(response => {
                setConnections(response.data.map(d => d.name));
            })
            .catch(e => setErrorMessage(getErrorMessage(e.response.data)));
    };

    const handleCopy = values => {
        connectionsService
            .copy(instanceId, connectionId, values)
            .then(() => {
                message.info(t("connection.copy.done"));
                onClose();
                afterSuccess?.();
            })
            .catch(e => setErrorMessage(getErrorMessage(e.response.data)));
    };

    useEffect(() => {
        if (visible) {
            accountsService
                .fetchInstances(accountId)
                .then(response => {
                    setInstances(response.data);
                })
                .catch(e => setErrorMessage(getErrorMessage(e.response.data)));
        }
    }, [accountId, visible]);

    return (
        <CopyConnectionDialog
            onInstanceSelect={handleInstanceSelect}
            defaultName={name}
            onCancel={onClose}
            onCopy={handleCopy}
            errorMessage={errorMessage}
            connections={connections}
            instances={instances}
            visible={visible}
        />
    );
};

CopyConnectionDialogContainer.propTypes = {
    modalProviderProps: ModalProviderPropsPropType(),
};
