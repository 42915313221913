import { buildPageableUrl } from "@/utils/urlUtils";
import { axiosService } from "../../../axios";

export const userService = {
    getFilteredUsers(filter) {
        return axiosService.get(`/api/admin/users/filtered?filter=${filter}`);
    },

    fetchUsersTable(page, size, sort, filter, accountId) {
        return axiosService.post(
            buildPageableUrl(
                `/api/projects/${accountId}/users`,
                page,
                size,
                sort,
            ),
            filter,
        );
    },

    fetchUsernames() {
        return axiosService.get(`/api/admin/users/usernames`);
    },

    resendActivation(accountId, userId) {
        return axiosService.post(
            `/api/projects/${accountId}/users/${userId}/resend-activation`,
        );
    },
};
