import { axiosService } from "../axios";

export const favoritesService = {
    saveFavorite({ id, type }) {
        return axiosService.post(`/api/user-favorites`, {
            type,
            itemId: id,
        });
    },

    deleteFavorite({ id, type }) {
        return axiosService.delete(`/api/user-favorites/${type}/${id}`);
    },
};
