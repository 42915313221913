// naturally sort array of form field option objects by the label attribute

import { curryN, get } from "lodash/fp";

export const naturallySortBy = curryN(2, (getter, array) =>
    [...array].sort((a, b) =>
        getter(a).localeCompare(getter(b), undefined, {
            numeric: true,
            sensitivity: "base",
        }),
    ),
);

// e.g. attr1,attr11,attr22,attr3 -> attr1,attr3,attr11,attr22
export const naturallySortOptions = naturallySortBy(get("label"));
