import {
    Breadcrumb as AntdBreadcrumb,
    Col,
    Row,
} from "@/components/DesignSystem";
import { Button } from "@/components/DesignSystem/Button";
import { Tooltip } from "@/components/DesignSystem/Tooltip";
import { useLocation } from "@/components/hooks/useLocation.hook";
import { LocationLink } from "@/modules/router";
import UserSecurityContentContext from "@/security/UserSecurityContentContext";
import { T, t } from "@/translations";
import isObject from "lodash/isObject";
import React, { useContext, useMemo } from "react";
import BreadcrumbButton from "../BreadcrumbButton/BreadcrumbButton";
import { MAX_EXPORT_SIZE } from "../PagableTable/PageableTable";
import { PageLayoutContext } from "../PageLayout/PageLayoutContext";
import { TablePreferencesMenuDropdown } from "../TableWithPreferences/TablePreferencesMenuDropdown";
import style from "./Breadcrumbs.less";

export const Breadcrumbs = React.memo(function Breadcrumbs({ accountId }) {
    const location = useLocation();
    const locations = useMemo(() => {
        if (location) {
            // requirement to hide first location from horizontal menu: https://pricefx.atlassian.net/browse/PFIM-2983
            const [_, ...locations] = generateLocationsStack(location);
            return locations;
        }
        return [];
    }, [location]);

    const { exportButtonSettings } = useContext(PageLayoutContext);

    return (
        <Row className={style.breadcrumb}>
            <Col span={17}>
                <AntdBreadcrumb>
                    <AntdBreadcrumb.Item>..</AntdBreadcrumb.Item>
                    {locations.map(location => (
                        <AntdBreadcrumb.Item key={location.routeName}>
                            <Breadcrumb location={location} />
                        </AntdBreadcrumb.Item>
                    ))}
                </AntdBreadcrumb>
            </Col>
            <Col span={7} className={style.breadcrumbButton}>
                {accountId ? (
                    <UserSecurityContentContext projectId={accountId}>
                        <BreadcrumbButton />
                    </UserSecurityContentContext>
                ) : (
                    <BreadcrumbButton />
                )}
                {exportButtonSettings?.exportVisible && (
                    <Tooltip
                        title={
                            exportButtonSettings.exportDisabled &&
                            t("export.tooltip.max-record", {
                                maxRows: MAX_EXPORT_SIZE,
                            })
                        }
                    >
                        <div />
                        <Button
                            disabled={exportButtonSettings.exportDisabled}
                            size="small"
                            style={{ marginRight: 8, marginLeft: 8 }}
                            label={exportButtonSettings.text}
                            {...exportButtonSettings}
                        />
                    </Tooltip>
                )}
                <TablePreferencesMenuDropdown />
            </Col>
        </Row>
    );
});

function Breadcrumb({ location }) {
    const { routeName, breadcrumbComponent: Com } = location;

    return Com ? (
        <Com />
    ) : (
        <LocationLink $location={location}>
            <T
                id={
                    isOldRoute(routeName)
                        ? getOldRouteTranslationId(routeName)
                        : getLocationTranslationId(routeName)
                }
            />
        </LocationLink>
    );
}

export function generateLocationsStack(location) {
    return generateLocationsListRecursive(location).reverse();
}

function generateLocationsListRecursive(location, locations = []) {
    locations.push(location);

    if (!isObject(location.node)) {
        return locations;
    }

    return generateLocationsListRecursive(location.node, locations);
}

function isOldRoute(routeName) {
    const appName = routeName.split(".")[0];

    return !appName.endsWith("App");
}

function getOldRouteTranslationId(oldRoute) {
    return `route.${oldRoute.split("/")[0]}`;
}

export function getLocationTranslationId(routeName) {
    return `location.${routeName}`;
}
