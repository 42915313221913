import { Icon } from "@/components/DesignSystem/Icon";
// import { ReactComponent as ExclamationTriangleSolid } from "@pricefx/unity-components/dist/es/icons/unicons/exclamation-triangle-solid.svg";
import PropTypes from "prop-types";
import React from "react";
// import Icon from "@ant-design/icons";

const yellow = "#faad14";

export const ParentWarning = ({ parent, synced }) => {
    if (!parent)
        return (
            <Icon
                tooltip={"Instance parent is missing"}
                style={{ color: yellow }}
                type="exclamation-triangle"
                // component={ExclamationTriangleSolid}
            />
        );
    if (!synced)
        return (
            <>
                <Icon
                    tooltip={"Not synced"}
                    style={{ color: yellow }}
                    type="exclamation-triangle"
                    // component={ExclamationTriangleSolid}
                />
                {parent}
            </>
        );
    return parent;
};
ParentWarning.propTypes = {
    parent: PropTypes.string,
    synced: PropTypes.bool,
};
