import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFilterNameValidator } from "@/components/Filters/form/validators";
import {
    useDeleteInstanceRepoFilterMutation,
    useInstanceRepoFiltersQuery,
    useLocalCopyMutation,
} from "@/components/Filters/loadables";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { CreateLocalCopyModal } from "@/components/Mappers/CreateLocalCopyModal";
import { isLoading } from "@/modules/loadable";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { getInstanceRepoFiltersTrackName as getTrackName } from "../../mixpanel/buttonNames";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { instanceRepoFiltersColumns as columns } from "./instanceRepoFilters.columns";

const preferencesType = preferencesTypes.INSTANCE_REPO_FILTERS_TABLE;
const type = "Filter";
const FIELD_NAME = "name";

export const InstanceRepoFilters = ({
    instanceId,
    visible,
    afterLocalCopy,
    afterDelete,
    reloadToken,
}) => {
    const {
        locationRouterService,
        accountAppLocations: { filtersDefinitionLocation },
    } = useDic();

    const filtersQuery = useInstanceRepoFiltersQuery({
        instanceId,
        reloadToken,
    });
    const deleteFilterMutation = useDeleteInstanceRepoFilterMutation({
        instanceId,
        afterSuccess: afterDelete,
    });

    const nameValidator = useFilterNameValidator({
        instanceId,
        withOnline: false,
    });

    const dataSource = filtersQuery.loadable.valueMaybe() || [];
    const loading = isLoading(filtersQuery);

    const onShowDefinition = useCurrentHandler(record =>
        locationRouterService.navigate(filtersDefinitionLocation, {
            mapperId: record.id,
            prn: encodeURIComponent(record.prn),
        }),
    );

    const actionMenu = useCallback(
        record => (
            <ActionButton
                permission={INTEGRATION_EDIT_PERMISSIONS}
                record={record}
                items={[
                    {
                        visible: record.editable,
                        title: t("mapper-list.create-local-copy.title"),
                        customModal: (
                            <CreateLocalCopyModal
                                instanceId={instanceId}
                                record={record}
                                afterSuccess={afterLocalCopy}
                                fieldName={FIELD_NAME}
                                entityName={type}
                                useLocalCopyMutation={useLocalCopyMutation}
                                nameValidator={nameValidator}
                            />
                        ),
                        track: { name: getTrackName("LocalCopy") },
                    },
                    {
                        visible: !!record.prn,
                        title: t("mapper-list.show-definition"),
                        onClick: onShowDefinition,
                    },
                    {
                        visible: record.deletable,
                        title: t("general.modal.delete.title", { type }),
                        confirm: {
                            message: t("general.modal.delete.message", {
                                name: record.name,
                                type,
                            }),
                            onConfirm: deleteFilterMutation.mutate,
                        },
                        color: "red",
                        track: { name: getTrackName("Delete") },
                    },
                ]}
            />
        ),
        [
            afterLocalCopy,
            deleteFilterMutation.mutate,
            instanceId,
            nameValidator,
            onShowDefinition,
        ],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            fixed
            restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
            rowKey={FIELD_NAME}
            datasetSlicing="local"
            preferencesType={preferencesType}
            visible={visible}
            defaultSort={{
                sortSpecifiers: [
                    { property: "updatedAt", direction: "descending" },
                ],
            }}
        />
    );
};

InstanceRepoFilters.propTypes = {
    instanceId: PropTypes.number.isRequired,
    afterLocalCopy: PropTypes.func,
    afterDelete: PropTypes.func,
    reloadToken: PropTypes.any,
    reloadAll: PropTypes.func,
    visible: PropTypes.bool,
};
