import {
    ButtonMenu,
    Forms,
    H2,
    H4,
    P,
    UnityLayout,
} from "@/components/DesignSystem";
import { RowSkeleton } from "@/components/DesignSystem/Skeleton/RowSkeleton.component";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { addLocalFilteredAlerts } from "@/components/EventSchedulers/EventSchedulers.page";
import { AlertCellWithPopover } from "@/components/AlertCellWithPopover/AlertCellWithPopover";
import {
    getOptionLabelKey,
    getOptionLabelMaybe,
} from "@/components/EventWorkflows/ListenerStepForm/SourceEventModal";
import {
    ActionFields,
    ActionFieldsVariant,
    SourceName,
    TargetFields,
} from "@/components/EventWorkflows/ListenerStepForm/listenerStepFormSteps";
import { useEOTActionEntitiesOptionsQuery } from "@/components/EventWorkflows/loadables";
import RemoteLayout from "@/components/PageLayout/RemoteLayout";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import { constant, identity } from "lodash/fp";
import React from "react";

const ListenerEntityPage = ({
    visible,
    onSubmit,
    onClose,
    isEdit,
    initialValues,
    modalExtraProps: {
        accountId,
        eoTypesQuery,
        isSingleSource = false,
        singleSource,
    },
}) => {
    useSetBackButtonAction(onClose);

    const { formId, Form, setValues, setTouched } = Forms.useForm({
        onSubmit: ({ values }) => {
            const valuesWithNames = {
                ...values,
                // [getOptionLabelKey(TargetFields.fieldNames.destinationId)]:
                //     getOptionLabelMaybee(
                //         values.sourceId,
                //         eotSourceEntitiesQuery.loadable,
                //     ),
            };
            return onSubmit(valuesWithNames);
        },
    });
    useSetValidatedInitialValues({ initialValues, setValues, setTouched }, [
        initialValues,
    ]);

    // const sourceType = SourceFields.useSourceType({ formId });
    // const eotSourceEntitiesQuery = useEOTEntitiesOptionsQuery({
    //     accountId,
    //     eoType: sourceType,
    //     canFetch: !!sourceType,
    // });

    // const eotSourceEventsQuery = useEOTEventOptionsQuery({
    //     accountId,
    //     eoType: sourceType,
    //     canFetch: !!sourceType,
    // });
    // const loadableSourceEventTypeValidator = useLoadableOptionsValidatorMaybe({
    //     isRequired: true,
    //     loadable: eotSourceEventsQuery.loadable,
    // });

    const title = t(
        isEdit
            ? "event-wf.listener-form.actions.title.edit"
            : "event-wf.listener-form.actions.title.new",
    );

    return (
        <RemoteLayout>
            <UnityLayout>
                <UnityLayout.Header
                    breadcrumbs={[
                        {
                            label: "Workflows",
                        },
                        {
                            label: "Listener",
                        },
                        {
                            label: title,
                        },
                    ]}
                    onClickBack={onClose}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <P>{t("event-wf.listener-form.steps.action.perex")}</P>
                    <Form>
                        <H4>{t("event-wf.form.target")}</H4>
                        <TargetFields
                            accountId={accountId}
                            isSingleSource={isSingleSource}
                            singleSource={singleSource}
                        />
                        <H4>{t("event-wf.form.action")}</H4>
                        <ActionFields
                            accountId={accountId}
                            variant={ActionFieldsVariant.workflows}
                        />
                    </Form>
                </UnityLayout.Content>

                <UnityLayout.Footer
                    actionButtonsLeft={
                        <ButtonMenu
                            buttons={[
                                {
                                    formId,
                                    label: t(
                                        "event-wf.listener-form.actions.save",
                                    ),
                                    type: "primary",
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </RemoteLayout>
    );
};
ListenerEntityPage.propTypes = {};

const LoadableActionLabel = ({
    actionId,
    destinationType,
    targetType,
    targetId,
    accountId,
}) => {
    const eotActionEntitiesQuery = useEOTActionEntitiesOptionsQuery({
        accountId,
        eoType: destinationType,
        actionType: targetType,
        entityId: targetId,
        canFetch: !!destinationType && !!targetType && !!targetId,
    });
    const labelMaybe = getOptionLabelMaybe(
        actionId,
        eotActionEntitiesQuery.loadable,
    );

    if (labelMaybe)
        return <ActionLabel name={labelMaybe} actionId={actionId} />;
    else if (isLoading(eotActionEntitiesQuery))
        return <ActionLabel name={<RowSkeleton />} actionId={actionId} />;
    else return <ActionLabel name={actionId} actionId={actionId} />;
};

const ActionLabel = ({
    name,
    actionId,
    destinationType,
    targetType,
    targetId,
    accountId,
}) => {
    if (name) return <span title={actionId}>{name}</span>;
    else
        return (
            <LoadableActionLabel
                actionId={actionId}
                destinationType={destinationType}
                targetType={targetType}
                targetId={targetId}
                accountId={accountId}
            />
        );
};

export const ListenerActionStep = ({
    allStepsProps: { accountId, isSingleSource, singleSource },
}) => {
    const actionsColumns = [
        {
            name: TargetFields.fieldNames.destinationType,
            label: t("event-wf.listener-form.actions.column.targetType"),
            type: fieldTypes.TEXT,
        },
        {
            name: getOptionLabelKey(TargetFields.fieldNames.destinationId),
            label: t("event-wf.listener-form.actions.column.targetName"),
            type: fieldTypes.TEXT,
            render: (text, record) => (
                <SourceName
                    name={record.destinationName}
                    accountId={accountId}
                    sourceId={record.destinationId}
                    sourceType={record.destinationType}
                />
            ),
        },
        {
            name: ActionFields.fieldNames.workflows.destinationTargetType,
            label: t("event-wf.listener-form.actions.column.actionType"),
            type: fieldTypes.TEXT,
        },
        {
            name: ActionFields.fieldNames.workflows.destinationTargetId,
            label: t("event-wf.listener-form.actions.column.actionId"),
            type: fieldTypes.TEXT,
            render: (text, record) =>
                record.invalid ? (
                    <AlertCellWithPopover
                        popoverTitle={"No longer exists"}
                        popoverContent={`${
                            record.targetLabel ?? "The target"
                        } does not exist anymore.`}
                        content={"No longer exists"}
                    />
                ) : (
                    <ActionLabel
                        name={record.targetLabel}
                        actionId={text}
                        targetId={record.destinationId}
                        destinationType={record.destinationType}
                        targetType={record.targetType}
                        accountId={accountId}
                    />
                ),
        },
    ];

    return (
        <>
            <H2>{t("event-wf.listener-form.steps.action.title")}</H2>
            <P>{t("event-wf.listener-form.steps.action.perex")}</P>
            <Forms.Fields.EntityEditorTable
                name="actions"
                addButtonProps={{
                    label: t("event-wf.listener-form.actions.addAction"),
                }}
                columns={actionsColumns}
                ModalComponent={ListenerEntityPage}
                getModalExtraProps={() => ({
                    accountId,
                    // eoTypesQuery,
                    isSingleSource,
                    singleSource,
                })}
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    Forms.pmValidators.createMinLengthValidation(1, {
                        map: identity,
                    }),
                ])}
                addAlertFn={addLocalFilteredAlerts([
                    {
                        prop: ActionFields.fieldNames.workflows
                            .destinationTargetId,
                        getVisible: ({ invalid }) => invalid,
                        getType: constant("RED"),
                        getTooltip: constant(t("general.invalid")),
                    },
                ])}
                // rowHeight={36}
            />
        </>
    );
};
