import { tap } from "@/components/Connections/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    hasError,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { getErrorMessageFromError } from "@/utils/state/error.utils";
import { noop, pipe } from "lodash/fp";
import get from "lodash/fp/get";
import groupBy from "lodash/fp/groupBy";
import map from "lodash/fp/map";
import mapValues from "lodash/fp/mapValues";
import values from "lodash/fp/values";
import { useState } from "react";

export const usePartitionDetailQuery = ({ accountId, partitionId }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `/api/projects/${accountId}/partition-assets/${partitionId}`,
                )
                .then(getData),
        [axiosService, partitionId, accountId],
    );
};

export const useLoginInfoQuery = ({ accountId, partitionId }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `/api/projects/${accountId}/partition-assets/${partitionId}/login-info`,
                )
                .then(getData)
                .catch(e => {
                    console.log("[useLoginInfoQuery] err", { e });
                    if (e.response.status === 404) return undefined;
                    throw e;
                }),
        [axiosService, partitionId, accountId],
    );
};

export const usePfmUsernameQuery = ({ accountId, partitionId }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `/api/projects/${accountId}/partition-assets/${partitionId}/pfm-username`,
                )
                .then(getData),
        [axiosService, partitionId, accountId],
    );
};

export const groupMissingRightsByPfxModule = pipe(
    values,
    groupBy(get("pfxModule")),
    mapValues(map(get("pfxRoleLabel"))),
);

const CODES = {
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
    MISSING_RIGHTS_ON_PARTITION: "MISSING_RIGHTS_ON_PARTITION",
    MISSING_PARTITION_LOGIN_INFO: "MISSING_PARTITION_LOGIN_INFO",
    // MISSING_USER_ON_PARTITION: "MISSING_USER_ON_PARTITION" // TODO?
};

const getAlert = ({ code, message, username, type: userType }) => {
    switch (code) {
        case CODES.SUCCESS:
            return;
        case CODES.MISSING_PARTITION_LOGIN_INFO:
            return {
                type: "error",
                message: t("partition-password.test-result"),
                description: message,
            };
        case CODES.MISSING_RIGHTS_ON_PARTITION:
            return {
                type: "warning",
                message: t("partition-password.test-result"),
                description:
                    message ||
                    t("partition-password.test-result.not-enough-rights"),
            };
        default:
            return {
                type: "error",
                message: t("partition-password.test-result"),
                description: message || "Unknown rights error",
            };
    }
};

const INITIAL_TEST_VALUES = {};

export const useTestPartitionRights = ({ accountId, partitionId }) => {
    const [testValues, setTestValues] = useState(INITIAL_TEST_VALUES);

    const { projectPartitionApiService, messageService } = useDic();
    const query = useQueryLoadable(async () => {
        const { userType, username, password } = testValues;

        if (!username) return Promise.resolve(undefined);

        return projectPartitionApiService
            .testLoginInfo({
                accountId,
                partitionId,
                username,
                password,
                type: userType,
                checkUserRights: true,
            })
            .then(res => {
                messageService.success({
                    content: t(
                        "partition-password.test-credentials.successful",
                    ),
                });
                return res;
            })
            .catch(e => {
                const { data } = e.response;

                throw {
                    e,
                    alert: getAlert(data),
                    missingRights: data?.missingRights,
                };
            });
    }, [
        messageService,
        partitionId,
        accountId,
        projectPartitionApiService,
        testValues,
    ]);

    const { alert, missingRights } = hasError(query)
        ? query.loadable.contents
        : {};

    return {
        testRightsQuery: query,
        missingRightsMaybe: missingRights,
        alertMaybe: alert,
        setTestValues,
    };
};

export const resetCredentialsAlert = {
    type: "error",
    message: t("partition-password.test-result"),
    description: t("partition-password.test-result.invalid-credentials"),
};

export const useResetCredentialsMutation = ({
    accountId,
    partitionId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    const mutation = useMutationLoadableWithNotification(
        async () =>
            axiosService
                .post(
                    `/api/projects/${accountId}/partition-assets/${partitionId}/reset-credentials`,
                )
                .then(getData)
                .then(tap(afterSuccessCurrent)),

        [afterSuccessCurrent, axiosService, partitionId, accountId],
        "partition-password.reset-credentials.successful",
        "partition-password.reset-credentials.failed",
    );

    return mutation;
};

export const getSaveCredentialsAlert = e => ({
    type: "error",
    message: t("partition-password.test-result"),
    description: getErrorMessageFromError(e) || "Something went wrong",
});

export const useSaveCredentialsMutation = ({
    accountId,
    partitionId,
    afterSuccess = noop,
}) => {
    const { projectPartitionApiService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    const mutation = useMutationLoadableWithNotification(
        async data =>
            projectPartitionApiService
                .saveCredentials(accountId, partitionId, data)
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [
            afterSuccessCurrent,
            partitionId,
            accountId,
            projectPartitionApiService,
        ],
        "partition-password.set-credentials.successful",
    );

    return mutation;
};
