import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React from "react";
import InstancesList from "./InstancesList";

export const IntegrationsPage = () => {
    const { accountId } = useAccountAppParams();

    useFullWidthTableLayout();

    return <InstancesList accountId={accountId} />;
};
