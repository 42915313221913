import { Tag } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { CODE_TO_LABEL } from "@/constants/codeToLabel";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { CHANGE } from "./loadables";

const Change = ({ change }) => {
    switch (change) {
        case CHANGE.UNCHANGED:
            return (
                <Tag color="success" size="small">
                    Unchanged
                </Tag>
            );
        case CHANGE.UPDATED:
            return (
                <Tag color="warning" size="small">
                    Updated
                </Tag>
            );
        case CHANGE.DELETED:
            return (
                <Tag color="error" size="small">
                    Deleted
                </Tag>
            );
        default:
            return "Unknown";
    }
};

Change.propTypes = {
    monitored: PropTypes.bool,
};

// TODO: move translations from BE
const codeToLabel = code => CODE_TO_LABEL[code] || `Unknown ("${code}")`;

// TODO: add translations when columns will be confirmed
export const columns = [
    {
        name: "entityType",
        label: t("version-integrity-check.table.columns.label.type"),
        type: fieldTypes.TEXT,
        render: code => codeToLabel(code),
    },
    {
        name: "name",
        label: t("version-integrity-check.table.columns.label.name"),
        type: fieldTypes.TEXT,
    },
    {
        name: "change",
        label: t("version-integrity-check.table.columns.label.change"),
        type: fieldTypes.OPTION,
        options: [
            // { value: CHANGE.UNCHANGED, label: "Unchanged" },
            { value: CHANGE.UPDATED, label: "Updated" },
            { value: CHANGE.DELETED, label: "Deleted" },
        ],
        render: change => <Change change={change} />,
    },
];
