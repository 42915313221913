import { UnityIcon } from "@/components/DesignSystem";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import {
    CONFLUENCE_SEARCH_URL,
    PM_DOCUMENTATION,
    PM_RELEASE_DOCUMENTATION_URL,
    PRIVACY_POLICY_URL,
} from "@/constants/CommonConstats";
import { t } from "@/translations";
import Icon from "@ant-design/icons";
import {
    Drawer,
    Forms,
    Input,
    Layout,
    Link,
    Tooltip,
} from "@pricefx/unity-components";
import { ReactComponent as IconHelp } from "@pricefx/unity-components/dist/es/icons/svg/help.svg";
import { ReactComponent as IconSearch } from "@pricefx/unity-components/dist/es/icons/unicons/search.svg";
import { isEmpty } from "lodash";
import qs from "qs";
import React from "react";

const { Field, useForm, SubmitButton } = Forms;

const SEARCH_VALUE = "searchValue";

export const useHeaderItemHelp = () => {
    const helpDrawer = useVisibility();

    return {
        icon: {
            component: () => (
                <Help onClose={helpDrawer.hide} visible={helpDrawer.visible} />
            ),
        },
        key: "help",
        title: t("header.help.title"),
        onClick: helpDrawer.show,
    };
};

const DrawerContent = ({ onClose }) => {
    const onSubmit = ({ values }) => {
        const value = values[SEARCH_VALUE];
        if (!isEmpty(value)) {
            const searchParam = encodeURIComponent(value);
            window.open(
                `${CONFLUENCE_SEARCH_URL}?${qs.stringify({
                    spaces: "PM",
                    text: searchParam,
                })}`,
                "_blank",
            );

            onClose();
        }
    };

    const { formId, Form } = useForm({
        onSubmit,
    });

    return (
        <Layout>
            <Layout.Header title={t("help-search.title")} />
            <Layout.Content padding={[false, true]}>
                <Form>
                    <Field
                        allowClear
                        as={Input}
                        name={SEARCH_VALUE}
                        placeholder={t("help-search.input.placeholder")}
                        prefix={
                            <Icon
                                component={IconSearch}
                                style={{ fontSize: "16px" }}
                            />
                        }
                    />
                    <Field>
                        <SubmitButton
                            formId={formId}
                            label={t("general.search")}
                            type="primary"
                        />
                    </Field>
                </Form>
                <div>
                    <div>
                        <span>{t("help-search.documentation.text")}</span>
                        &nbsp;
                        <Link
                            href={PM_DOCUMENTATION}
                            label={t("help-search.documentation.link")}
                            target="_blank"
                        />
                        .
                    </div>
                    <div>
                        <span>
                            {t("help-search.privacy-policy.see-our")}
                            &nbsp;
                            <Link
                                href={PRIVACY_POLICY_URL}
                                label={t("help-search.privacy-policy.link")}
                                target="_blank"
                            />
                            .
                        </span>
                    </div>
                </div>
                <br />
                <div>
                    <Link
                        href={PM_RELEASE_DOCUMENTATION_URL}
                        label={t("help-search.whats-new.link")}
                        target="_blank"
                    />
                </div>
            </Layout.Content>
        </Layout>
    );
};

const HelpDrawer = ({ onClose, visible, ...props }) => (
    <Drawer onClose={onClose} visible={visible}>
        <DrawerContent onClose={onClose} {...props} />
    </Drawer>
);

const Help = ({ onClose, visible }) => {
    return (
        <>
            <Tooltip disableOnTouchScreen tooltip="main_help">
                <UnityIcon
                    component={IconHelp}
                    style={{
                        fontSize: 16,
                        margin: 0,
                        top: "-1px",
                    }}
                    viewBox="0 0 24 24"
                />
            </Tooltip>
            <HelpDrawer onClose={onClose} visible={visible} />
        </>
    );
};

export default Help;
