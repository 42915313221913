import { isTabStateInvalid } from "@/components/CalculatedFieldTable/FieldCustomization.modal";
import { Gap, H5, Input, Table, Text } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { T, t } from "@/translations";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React from "react";

const columns = [
    {
        label: t("formula-editor.table.header.key"),
        name: "key",
        type: fieldTypes.TEXT,
    },
    {
        label: t("formula-editor.table.header.source-field"),
        name: "sourceField",
        type: fieldTypes.TEXT,
    },
    {
        label: t("formula-editor.table.header.example"),
        name: "example",
        type: fieldTypes.TEXT,
    },
];

const withValidation = input => ({
    ...input,
    isValid: !isEmpty(input.input),
});

export const FormulaTab = ({
    activeTabState,
    formulaMapping,
    formula,
    onChange,
}) => {
    const onFormulaChange = v => {
        const input = v?.target?.value?.toUpperCase();
        onChange(
            withValidation({
                input,
                formulaMapping,
            }),
        );
    };

    return (
        <div data-test="tab-formula">
            <H5>
                <T id="formula-editor.title" />
            </H5>
            <Text size="small">
                <T id="formula-editor.description" />
            </Text>
            <Gap size="small" />
            <Table
                padding={false}
                dataSource={formulaMapping}
                columns={columns}
            />
            <Gap />
            <H5>
                <T id="formula-editor.label.formula" />
            </H5>
            <Input.TextArea
                status={isTabStateInvalid(activeTabState) ? "error" : null}
                rows="1"
                value={formula}
                onChange={onFormulaChange}
            />
        </div>
    );
};

FormulaTab.propTypes = {
    formulaMapping: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            sourceField: PropTypes.string,
            groovyRepresentation: PropTypes.string,
            example: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    ).isRequired,
    formula: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    result: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    error: PropTypes.string,
};
