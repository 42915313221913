import { useDic } from "@/components/Dic/useDic.hook";
import { defaultParserConfigValues } from "@/components/FileParsing/FileParsingFields";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import ISVDataMappingForm from "@/components/ISVMapping/ISVDataMappingForm.component";
import { useCreateMapperMutation } from "@/components/ISVMapping/loadables";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";

const ISVDataMappingCreationPage = () => {
    const { accountId, isvConnectionId } = useAccountAppParams();
    const {
        locationRouterService,
        accountAppLocations: { isvDataMappingListLocation },
    } = useDic();

    useFullWidthTableLayout();

    const navigateToList = useCallback(() => {
        locationRouterService.navigate(isvDataMappingListLocation, {
            accountId,
            isvConnectionId,
        });
    }, [
        locationRouterService,
        isvDataMappingListLocation,
        accountId,
        isvConnectionId,
    ]);

    const createMapperMutation = useCreateMapperMutation({
        afterSuccess: navigateToList,
    });

    return (
        <ISVDataMappingForm
            title={t(
                "location.accountApp.isvConnections.isvDataMappingCreation",
            )}
            accountId={accountId}
            initialValues={{
                ...defaultParserConfigValues,
            }}
            onSave={mapper =>
                createMapperMutation.mutate({ isvConnectionId, ...mapper })
            }
            onCancel={navigateToList}
        />
    );
};

export default ISVDataMappingCreationPage;
