import { axiosService } from "../../../axios";

export const messagesApiService = {
    fetchFailedRecordsMultiple(instanceId, query) {
        return axiosService.post(
            `/api/instances/${instanceId}/elastic-search/failed-records`,
            JSON.stringify({ ...query }),
        );
    },
};
