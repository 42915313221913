import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";
import { FeatureFlagValue } from "./FeatureFlagValue";
import { toKey, toName } from "./utils";

export const FeatureFlagName = ({ name, description }) => (
    <div title={description}>
        <div className="pmFeatureFlags-name">{name}</div>
        <div className="pmFeatureFlags-description">{description}</div>
    </div>
);

export const createColumns = ({
    sets,
    onShowPartitionUsingThisFlag,
    isActionMenuHidden,
}) => [
    {
        type: fieldTypes.TEXT,
        // Because of filtering also parents, the name is path instead of id
        name: "path",
        label: "Id",
        render: (_, record) => {
            return (
                <>
                    <FeatureFlagName
                        name={record.name}
                        description={record.description}
                    />
                    {!record.children && !isActionMenuHidden && (
                        <ActionButton
                            record={record}
                            items={[
                                {
                                    title: t(
                                        "feature-flags.show-partitions-with-flag.button",
                                    ),
                                    onClick: onShowPartitionUsingThisFlag,
                                },
                            ]}
                        />
                    )}
                </>
            );
        },
    },
    ...sets.map(ffSetParams => ({
        type: fieldTypes.TEXT,
        name: toKey(ffSetParams),
        label:
            ffSetParams.partitionName ?? t("feature-flags.table.column.value"),
        canSort: false,
        canFilter: false,
        render: (_, record) => {
            const name = toName({ ...ffSetParams, path: record.path });

            return <FeatureFlagValue name={name} record={record} />;
        },
    })),
];
