import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { LoadableRenderer } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React, { useCallback } from "react";
import { UserAdminSettingsTableContainer } from "../UserAdminSettingsTable/UserAdminSettingsTable.container";
import { InviteUserModalComponent } from "./InviteUserModal.component";
import {
    useAccountUsersResource,
    useDeleteAccountUserResource,
    useInviteAccountUserResource,
} from "./loadables";

export const UserAdminSettings = () => {
    useFullWidthTableLayout();
    useHideBackButton();

    const { accountId } = useAccountAppParams();
    const inviteModal = useVisibility(false);

    const accountUsersResource = useAccountUsersResource({ accountId });
    const afterUsersMutated = useCallback(() => {
        inviteModal.hide();
        accountUsersResource.reload();
    }, [inviteModal, accountUsersResource]);
    const inviteAccountUserResource = useInviteAccountUserResource({
        accountId,
        afterSuccess: afterUsersMutated,
    });
    const deleteAccountUserResource = useDeleteAccountUserResource({
        accountId,
        afterSuccess: afterUsersMutated,
    });

    useBreadcrumbButton(
        {
            label: "account-admin-users-list.button.invite-user",
            onClick: inviteModal.show,
        },
        [inviteModal],
    );

    return (
        <>
            <LoadableRenderer
                loadable={accountUsersResource.loadable}
                hasValue={(
                    users, // TODO: rm, inner table is server-paginated
                ) => (
                    <UserAdminSettingsTableContainer
                        accountId={accountId}
                        users={users}
                        onUserDelete={deleteAccountUserResource.mutate}
                        reload={accountUsersResource.reload}
                    />
                )}
            />
            <InviteUserModalComponent
                visible={inviteModal.visible}
                onCancel={inviteModal.hide}
                onOk={inviteAccountUserResource.mutate}
            />
        </>
    );
};

UserAdminSettings.propTypes = {};
