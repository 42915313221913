import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { H4 } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { preferencesTypes } from "../../../constants/preferencesTypes.constants";
import { TableWithPreferencesManagement } from "../../TableWithPreferences/TableWithPreferencesManagement.container";
import { PanelApproversList } from "./PanelApproversList.component";

const preferencesType = preferencesTypes.WORKFLOW_LIST_TABLE;

const columns = [
    {
        type: fieldTypes.TEXT,
        label: t("workflow-list.header.name"),
        name: "name",
    },
    {
        type: fieldTypes.TEXT,
        label: t("workflow-list.header.action"),
        name: "wfType",
        visibleInPanel: true,
        panelOrder: 0,
    },
    {
        type: fieldTypes.TEXT,
        label: t("workflow-list.header.created-by"),
        name: "createdBy",
        visibleInPanel: true,
        panelOrder: 2,
    },
    {
        type: fieldTypes.TEXT,
        label: t("workflow-list.header.accountName"),
        name: "account.name",
        visibleInPanel: true,
        panelOrder: 1,
        render: (_, record) => record.account?.name,
    },
    {
        type: fieldTypes.DATETIME,
        label: t("workflow-list.header.created-at"),
        name: "createdAt",
        visibleInPanel: true,
        panelOrder: 3,
        render: (_, record) =>
            record.createdAt && (
                <FormattedDateTime>{record.createdAt}</FormattedDateTime>
            ),
    },
];

export const WorkflowDefinitionTableComponent = ({
    workflowsLoadable,
    onDelete,
    onEdit,
}) => {
    const workflowPanel = useDetailDrawerState();

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.show-detail"),
                        onClick: workflowPanel.show,
                    },
                    {
                        title: t("workflow-list.action.edit"),
                        onClick: () => onEdit(record),
                    },
                    {
                        title: t("workflow-list.action.delete"),
                        confirmMessage: t(
                            "workflow-list.message.delete-confirm",
                            {
                                name: record.name,
                            },
                        ),
                        onConfirm: () => onDelete(record.id),
                        color: "red",
                    },
                ]}
            />
        ),
        [],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            columns={columns}
            loading={workflowsLoadable.state === "loading"}
            dataSource={
                workflowsLoadable.state === "hasValue"
                    ? workflowsLoadable.contents
                    : []
            }
            fixed
            rowKey="id"
            datasetSlicing="local"
            preferencesType={preferencesType}
            panelControl={workflowPanel}
            renderPanelHeader={record => <H4>{record.name}</H4>}
            renderPanelExtra={record => <PanelApproversList record={record} />}
        />
    );
};

WorkflowDefinitionTableComponent.propTypes = {
    workflowsLoadable: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
};
