import { useDic } from "@/components/Dic/useDic.hook";
import { useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useAvailableInfraVersions = () => {
    const { imVersionsService } = useDic();

    return useQueryLoadable(
        async () =>
            imVersionsService.getAvailabeleInfraVersions().then(getData),
        [imVersionsService],
    );
};
