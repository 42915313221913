import { useDic } from "@/components/Dic/useDic.hook";
import { SidebarAndContentLayout } from "@/components/Layouts";
import { MarketplaceAppSidebar } from "@/components/Marketplace/Marketplace.sidebar";
import { TemplateCategoriesProvider } from "@/components/Marketplace/components/TagsMenu/TemplateCategories.context";
import { LocationRoutes } from "@/modules/router";
import React from "react";

export function MarketplaceAppLayout() {
    const { marketplaceAppLocations } = useDic();

    return (
        <TemplateCategoriesProvider>
            <SidebarAndContentLayout sidebar={<MarketplaceAppSidebar />}>
                <LocationRoutes locations={marketplaceAppLocations} />
            </SidebarAndContentLayout>
        </TemplateCategoriesProvider>
    );
}
