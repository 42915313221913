import { Checkbox, Table, Tooltip } from "@/components/DesignSystem";
import {
    DRAWER_FOOTER_HEIGHT,
    DRAWER_HEADER_HEIGHT,
    fieldTypes,
    TAB_HEIGHT,
} from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import styles from "../../../../AccountAssetAdminSettings/accountAdminSettings.style.less";

const createColumns = ({ onChange, changes }) => [
    {
        name: "name",
        label: t("account-admin-assets-role-list.header.name"),
        type: fieldTypes.TEXT,
    },
    {
        name: "description",
        label: t("account-admin-assets-role-list.header.description"),
        type: fieldTypes.TEXT,
    },
    {
        name: "checked",
        canFilter: false,
        canSort: false,
        render: (text, record) => {
            const stateIndex = changes?.[record.id]?.stateIndex ?? 0;
            const uiState = record.states[stateIndex];
            return (
                <Tooltip title={uiState.tooltipText}>
                    <Checkbox
                        onChange={e => onChange(record, e.target.checked)}
                        checked={uiState.checked}
                        indeterminate={!!uiState.indeterminate}
                        disabled={!!uiState.disabled}
                    />
                </Tooltip>
            );
        },
    },
];

export function BusinessRolesTable({ businessRoles, onChange, changes }) {
    const columns = useMemo(
        () =>
            createColumns({
                onChange,
                changes,
            }),
        [changes, onChange],
    );

    return (
        <Table
            columns={columns}
            dataSource={businessRoles}
            rowKey="id"
            bordered={false}
            pagination={false}
            className={styles.permissionTable}
            fixed
            restHeight={
                DRAWER_HEADER_HEIGHT + TAB_HEIGHT + DRAWER_FOOTER_HEIGHT
            }
        />
    );
}

BusinessRolesTable.propTypes = {
    businessRoles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
            states: PropTypes.array.isRequired,
        }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    changes: PropTypes.objectOf(
        PropTypes.shape({ stateIndex: PropTypes.number.isRequired }).isRequired,
    ).isRequired,
};
