import { toVersionNumber } from "@/utils/versionUtils";
import qs from "qs";
import { axiosService } from "../axios";

const qsOptions = { addQueryPrefix: true };

export const nodeService = {
    deleteNode(nodeId) {
        return axiosService.delete(`/api/registry/node-info/${nodeId}`);
    },
    deleteCluster(clusterName) {
        return axiosService.delete(`/api/registry/cluster/${clusterName}`);
    },
    nodesList({ queryParams: { sort, filter, ...restGetParams } }) {
        const updatedSort = toVersionNumber(
            sort,
            "releaseVersion",
            "releaseVersionNumber",
        );
        const params = qs.stringify(
            { sort: updatedSort, ...restGetParams },
            qsOptions,
        );

        return axiosService.post(`/api/registry/node-info${params}`, filter);
    },
};
