import { ModalProviderPropsPropType } from "@/modules/modal/CustomModal";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { accountsService } from "../../../apps/accountApp/services/accounts.service";
import { routesService } from "../../../services/routes.service";
import { CopyRouteDialog } from "./CopyRouteDialog";

export const CopyRouteDialogContainer = ({
    modalProviderProps: { visible, onClose },
    record: { id: routeId, uniqueName, name },
    accountId,
    afterSuccess,
}) => {
    const [routes, setRoutes] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [instances, setInstances] = useState([]);
    const [preview, setPreview] = useState(undefined);
    const [valid, setValid] = useState(false);

    const handleInstanceSelect = id => {
        setErrorMessage(undefined);
        setValid(false);
        routesService
            .fetchRoutes(id)
            .then(response => {
                setRoutes(
                    response.map(
                        d =>
                            new Object({
                                name: d.name,
                                uniqueName: d.uniqueName,
                            }),
                    ),
                );
            })
            .catch(e => setErrorMessage(getErrorMessage(e.response.data)));
    };

    const handleCopy = values => {
        routesService
            .copy(routeId, values)
            .then(() => {
                message.info(t("route.copy.done"));
                onClose();
                afterSuccess();
            })
            .catch(e => setErrorMessage(getErrorMessage(e.response.data)));
    };

    const handlePreview = values => {
        routesService
            .copyInfo(routeId, values)
            .then(response => {
                setErrorMessage(undefined);
                setPreview(response.data);
                if (response.data.errors.length == 0) {
                    setValid(true);
                } else {
                    setValid(false);
                    setErrorMessage(response.data.errors.join(". "));
                }
            })
            .catch(e => setErrorMessage(getErrorMessage(e.response.data)));
    };

    useEffect(() => {
        if (visible) {
            accountsService
                .fetchInstances(accountId)
                .then(response => {
                    setInstances(response.data);
                })
                .catch(e => setErrorMessage(getErrorMessage(e.response.data)));
        }
    }, [visible]);

    return (
        <CopyRouteDialog
            onInstanceSelect={handleInstanceSelect}
            defaultName={name}
            defaultUniqueName={uniqueName}
            onCancel={onClose}
            onPreview={handlePreview}
            onCopy={handleCopy}
            errorMessage={errorMessage}
            routes={routes}
            valid={valid}
            preview={preview}
            instances={instances}
            visible={visible}
        />
    );
};

CopyRouteDialogContainer.propTypes = {
    modalProviderProps: ModalProviderPropsPropType(),
};
