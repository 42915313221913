import { useAsyncCall } from "@/components/hooks/useAsyncCall.hook";
import { instancesServices } from "../../services/instance.service";
import { useAlertEffect } from "../PageLayout/useAlertEffect.hook";

export const ConditionalImConfigErrorAlert = ({ instanceId }) => {
    const { response, error } = useAsyncCall(
        () => instancesServices.fetchInstance(instanceId),
        [instanceId],
    );

    useAlertEffect(response && !response.data.configurableSupported, () => ({
        type: "error",
        messageId: getMessageId(response.data),
        closable: false,
    }));
    useAlertEffect(error, () => ({
        type: "error",
        message: "Instance not available",
        closable: false,
    }));

    return null;
};

function getMessageId(imConfiguration) {
    if (!imConfiguration.configurable && imConfiguration.monitored) {
        return "general.error.im-not-configurable";
    } else if (!imConfiguration.monitored) {
        return "general.error.im-not-monitored";
    } else if (imConfiguration.imVersion) {
        return "general.error.im-unsupported-version";
    }

    return "general.error.im-configuration-not-supported";
}
