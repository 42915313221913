import { useContext, useEffect } from "react";
import { PageLayoutContext } from "./PageLayoutContext";

export const useSetPageTitle = title => {
    const { setPageTitle } = useContext(PageLayoutContext);
    setPageTitle(title);
    useEffect(() => {
        return () => {
            setPageTitle(null);
        };
    }, [setPageTitle]);
};
