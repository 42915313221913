import { Alert } from "@/components/DesignSystem";
import { t } from "@/translations";
import React from "react";

export const DeprecatedWarning = () => {
    return (
        <Alert
            type="warning"
            showIcon
            closable
            message={t("deprecated.warning.accelerator.text")}
        />
    );
};
