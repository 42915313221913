import { init } from "@rematch/core";
import loadingPlugin from "@rematch/loading";
import selectPlugin from "@rematch/select";
import axiosMiddleware from "redux-axios-middleware";
import reducers from "../../reducers/reducers";
import resetReducer, { RESET } from "../../reducers/reset_reducer";

export function createStore({ axiosService, rematchModels }) {
    return init({
        plugins: [selectPlugin(), loadingPlugin()],
        models: rematchModels,
        redux: {
            reducers,
            middlewares: [axiosMiddleware(axiosService)],
            rootReducers: {
                [RESET]: resetReducer,
            },
        },
    });
}
