import { logger } from "@/modules/logger";
import { UserContext } from "@/security/UserContext";
import { useContext, useEffect } from "react";

export const LogoutPage = () => {
    const { logout } = useContext(UserContext);

    logger.debug({
        logGroupKey: ["ROUTING", "LogoutPage", "rndr"],
        color: "deeppink",
    });

    useEffect(() => {
        logger.debug({
            logGroupKey: ["ROUTING", "LogoutPage", "eff"],
            color: "deeppink",
            msg: `Running async logout`,
        });
        (async () => {
            logger.debug({
                logGroupKey: ["ROUTING", "LogoutPage", "eff"],
                color: "deeppink",
                msg: `Going to logout`,
            });
            await logout();
            logger.debug({
                logGroupKey: ["ROUTING", "LogoutPage", "eff"],
                color: "deeppink",
                msg: `Logged out, going to navigate`,
            });
        })();
    }, []);

    return null;
};

LogoutPage.propTypes = {};
