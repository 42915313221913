import { Collapse, Forms, Panel, Table, Text } from "@/components/DesignSystem";
import { LoadablePropType, LoadableRenderer } from "@/modules/loadable";
import isEmpty from "lodash/fp/isEmpty";
import PropTypes from "prop-types";
import React from "react";
import { createColumns } from "./BusinessKeyLengthTable.columns";
import "./BusinessKeyLengthTable.less";
import { MAX_KEY_LENGTH } from "./constants";

const createDataSource = businessKeys =>
    Object.entries(businessKeys).map(([businessKey, initialValue]) => ({
        businessKey,
        initialValue,
    }));

export const BusinessKeyLengthTable = ({
    formProps,
    loadableBusinessKeys,
    keysLength,
    disabled,
    expanded,
}) => {
    const panelHeader = (
        <Text size="medium">
            <strong>Business Key</strong> -{" "}
            {keysLength <= MAX_KEY_LENGTH ? (
                `${keysLength} characters used`
            ) : (
                <>
                    <span className="pmBusinessKeyLengthTable-error">
                        {keysLength} characters used
                    </span>
                    , Max size is {MAX_KEY_LENGTH} characters
                </>
            )}
        </Text>
    );

    return (
        <LoadableRenderer
            loadable={loadableBusinessKeys}
            loading={() => null}
            hasError={() => (
                <LoadableRenderer.Error translationKey="alerts.detail.errorLoading" />
            )}
            hasValue={businessKeys =>
                isEmpty(businessKeys) ? null : (
                    <Collapse
                        ghost
                        className="pmBusinessKeyLengthTable"
                        defaultActiveKey={expanded ? ["1"] : undefined}
                    >
                        <Panel header={panelHeader} forceRender key="1">
                            <Forms.Form {...formProps}>
                                <Table
                                    className="pmTable--tallCells pmTable--condensed"
                                    rowKey="businessKey"
                                    columns={createColumns({ disabled })}
                                    dataSource={createDataSource(businessKeys)}
                                    pagination={false}
                                    rowHeight={37}
                                />
                            </Forms.Form>
                        </Panel>
                    </Collapse>
                )
            }
        />
    );
};

BusinessKeyLengthTable.propTypes = {
    formProps: PropTypes.shape({ formId: PropTypes.string.isRequired }),
    loadableBusinessKeys: LoadablePropType({}),
    keysLength: PropTypes.number,
    disabled: PropTypes.bool,
    expanded: PropTypes.bool,
};
