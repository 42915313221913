import { tap } from "@/components/Connections/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useMutationLoadableWithNotification } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { noop } from "lodash/fp";

export const useIMArchiveResultsMutation = ({
    instanceId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async payload =>
            axiosService
                .post(`/api/instances/${instanceId}/backup-query`, payload)
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, axiosService, instanceId],
        "im-archive-query.search.notification.success",
    );
};
