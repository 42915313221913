import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { TableButton } from "@/components/TableButton";
import { t } from "@/translations";
import React from "react";

export const createColumns = ({ onClick }) => [
    {
        type: fieldTypes.TEXT,
        label: t("account-admin-group-list.header.name"),
        name: "name",
        className: "pmTable__column--withActionButton",
        render: (text, record) => (
            <>
                <TableButton onClick={() => onClick(record)}>
                    {record.name}
                </TableButton>
            </>
        ),
    },
    {
        type: fieldTypes.INTEGER,
        label: t("account-admin-group-list.header.number-of-users"),
        name: "userCount",
    },
    {
        type: fieldTypes.TEXT,
        label: t("account-admin-group-list.header.description"),
        name: "description",
    },
    {
        type: fieldTypes.DATETIME,
        label: t("account-admin-group-list.header.created-at"),
        name: "createdAt",
        render: (text, record) =>
            record.createdAt && (
                <FormattedDateTime>{record.createdAt}</FormattedDateTime>
            ),
    },
];
