import { tap } from "@/components/Connections/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { get, noop } from "lodash/fp";

export const useDataUploadsQuery = ({ partitionId }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            axiosService
                .get(`api/partitions/${partitionId}/data-uploads`)
                .then(get("data")),
        [partitionId, axiosService],
    );
};

export const useDeleteDataUploadMutation = ({ partitionId, afterSuccess }) => {
    const { axiosService } = useDic();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async ({ id }) => {
            const res = await axiosService.delete(
                `api/partitions/${partitionId}/data-uploads/${id}`,
            );
            currentAfterSuccess();
            return res;
        },
        [partitionId, currentAfterSuccess, axiosService],
        "data-upload.delete.success",
        "data-upload.delete.error",
    );
};

export const useDataUploadQuery = ({ partitionId, uploadId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(`api/partitions/${partitionId}/data-uploads/${uploadId}`)
                .then(getData),
        [axiosService, uploadId, partitionId],
    );
};

export const useFilesQuery = ({ partitionId, uploadId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `api/partitions/${partitionId}/data-uploads/${uploadId}/files`,
                )
                .then(getData),
        [axiosService, uploadId, partitionId],
    );
};

export const useDataUploadFileErrorsQuery = ({
    partitionId,
    uploadId,
    fileId,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `api/partitions/${partitionId}/data-uploads/${uploadId}/files/${fileId}/error-messages`,
                )
                .then(getData),
        [axiosService, uploadId, fileId, partitionId],
    );
};

export const useDataLoadPartitionsQuery = ({ projectId, canFetch = true }) => {
    const { projectPartitionApiService } = useDic();
    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : projectPartitionApiService.getPartitionsForDataLoadCopy(
                      projectId,
                  ),
        [canFetch, projectId, projectPartitionApiService],
    );
};

export const useCopyUploadMutation = ({ uploadId, afterSuccess, onError }) => {
    const { dataUploadService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess ?? noop);
    const onErrorCurrent = useCurrentHandler(onError ?? noop);

    return useMutationLoadableWithNotification(
        async values =>
            dataUploadService
                .copyDataUpload(uploadId, values)
                .then(tap(afterSuccessCurrent))
                .catch(e => {
                    onErrorCurrent(e);
                    throw e;
                }),
        [afterSuccessCurrent, dataUploadService, onErrorCurrent, uploadId],
        undefined,
        !onError ? undefined : false,
    );
};
