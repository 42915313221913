import { getData } from "@/services/utils";
import { axiosService } from "../axios";
import qs from "qs";

const qsOptions = { addQueryPrefix: true };

export const deploymentService = {
    deployRoute: (instanceId, routeId) =>
        axiosService
            .post(`/api/instances/${instanceId}/integrations/${routeId}/deploy`)
            .then(getData),

    deployMapper: (instanceId, mapperId, forceCommit = false) => {
        const query = forceCommit ? "?forceCommit=true" : "";

        return axiosService.post(
            `/api/instances/${instanceId}/mappers/${mapperId}/deploy${query}`,
        );
    },

    deployFilter: (instanceId, filterId, query) => {
        const queryStr = qs.stringify(query, qsOptions);
        return axiosService.post(
            `/api/instances/${instanceId}/filters/${filterId}/deploy${queryStr}`,
        );
    },

    deployProperty: (instanceId, propertyId) => {
        return axiosService.post(
            `/api/instances/${instanceId}/properties/${propertyId}/deploy`,
        );
    },
};
