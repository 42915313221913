import qs from "qs";
import { axiosService } from "../axios";

const qsOptions = { addQueryPrefix: true };

export const serverService = {
    createServer(values) {
        return axiosService.post("/api/servers", values);
    },
    deleteServer(serverId) {
        return axiosService.delete(`api/servers/${serverId}`);
    },
    serversList({ queryParams: { filter, ...restGetParams } }) {
        const params = qs.stringify(restGetParams, qsOptions);

        return axiosService.post(`/api/servers/list${params}`, filter);
    },
};
