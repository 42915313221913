import { useDic } from "@/components/Dic/useDic.hook";
import { useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";

export const usePfxUsersQuery = () => {
    const { ssoService } = useDic();

    return useQueryLoadable(
        async () => ssoService.getPfxUsers().then(getData),
        [ssoService],
    );
};

export const useNotificationsQuery = params => {
    const { notificationsService } = useDic();
    return useQueryLoadable(() => {
        return notificationsService.getNotifications(params).then(getData);
    }, [notificationsService, params]);
};
