import { Forms } from "@/components/DesignSystem";
import {
    deserialize,
    serialize,
    toMap,
} from "@/components/DesignSystem/Forms/fieldsArray";
import {
    $fieldValue,
    $values,
    fieldId,
} from "@pricefx/unity-components/dist/es/components/Forms/selectors";
import equals from "lodash/fp/equals";
import pipe from "lodash/fp/flow";
import get from "lodash/fp/get";
import identity from "lodash/fp/identity";
import map from "lodash/fp/map";
import omit from "lodash/fp/omit";
import values from "lodash/fp/values";
import PropTypes from "prop-types";
import React, { Suspense, useCallback, useMemo } from "react";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { InputType } from "../../../Packages/PackageTableDefinitionPanel/constants";
import { ConverterButton } from "../components/ConverterButton";
import { DeleteRowButton } from "../components/DeleteRowButton";
import { TypeText } from "../components/TypeText.component";
import { MapperCascader } from "../MapperCascader";
import { flatMapOptions } from "../metadata.utils";
import { nextConverterState, selectedMetaToConverterState } from "./utils";
import { createConverterWarningValidation } from "./validations";

const OutputTypeInner = ({ fieldId, getOutputOptionType }) => {
    const output = useRecoilValue($fieldValue(fieldId));
    const outputType = getOutputOptionType(output);

    return <TypeText>{outputType || "unknown"}</TypeText>;
};

const OutputType = ({ getOutputOptionType, fieldId }) => {
    return (
        <Suspense fallback={"loading"}>
            <OutputTypeInner
                fieldId={fieldId}
                getOutputOptionType={getOutputOptionType}
            />
        </Suspense>
    );
};

// used to trigger duplicites validation on each field
export const useOtherRowsFieldValues = (formId, omitRowId, name) => {
    const otherRowsValues = pipe(
        $values,
        useRecoilValue,
        toMap,
        omit(omitRowId),
        values,
        map(get(name)),
    )(formId);

    return useMemo(() => otherRowsValues, [otherRowsValues.join("-")]);
};

export const MapperRow = ({
    formId,
    rowId,
    initialInput,
    initialOutput,
    inputType = InputType.BODY,
    converterExpression,
    converterExpressionReadOnly,
    setConverter,
    inputOptions,
    outputOptions,
    readOnly,
    onOpenEditor,
    deleteRow,
    flatOutputOptionsByValue,
    withConverterWarning,
    parserConfig,
}) => {
    const getOutputOptionType = useCallback(
        value => flatOutputOptionsByValue[value]?.type,
        [flatOutputOptionsByValue],
    );
    const inInputOptionsValidation = useCallback(
        Forms.pmValidators.createAllowedValuesValidator(
            flatMapOptions(inputOptions).map(({ value }) => value),
            "Input value not found in input options",
        ),
        [inputOptions],
    );
    const inOutputOptionsValidation = useCallback(
        Forms.pmValidators.createAllowedValuesValidator(
            flatMapOptions(outputOptions).map(({ value }) => value),
            "Output value not found in output options",
        ),
        [outputOptions],
    );
    const inputValidator = useMemo(() => {
        if (inputType === InputType.BODY)
            return Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                inInputOptionsValidation,
                createConverterWarningValidation({
                    withConverterWarning,
                    converterExpression,
                    parserConfig,
                }),
            ]);

        return Forms.validators.failOnFirst([
            Forms.pmValidators.isRequired,
            createConverterWarningValidation({
                withConverterWarning,
                converterExpression,
                parserConfig,
            }),
        ]);
    }, [
        inInputOptionsValidation,
        inputType,
        withConverterWarning,
        converterExpression,
        parserConfig,
    ]);

    // trigger uniqueness validation
    const otherThanThisRowOutputValues = useOtherRowsFieldValues(
        formId,
        rowId,
        "output",
    );
    const outputValidator = useMemo(() => {
        return Forms.validators.failOnFirst([
            Forms.pmValidators.isRequired,
            inOutputOptionsValidation,
            Forms.pmValidators.createUniqueValueValidation(
                pipe(get("name"), deserialize, get("name"), equals("output")),
                "Output name must be unique",
            ),
        ]);
    }, [inOutputOptionsValidation, otherThanThisRowOutputValues]);

    const onInputAddonClick = useRecoilCallback(({ snapshot }) => async () => {
        const formValues = await snapshot.getPromise($values(formId));
        const rowFormValues = toMap(formValues)?.[rowId];
        const rowValues = {
            ...rowFormValues,
            converterExpression,
            converterExpressionReadOnly,
            inputType,
            id: rowId,
        };

        onOpenEditor(rowValues);
    });
    const onInputChange = useCallback(
        (value, selected) => {
            const prevConverterState = {
                value: converterExpression,
                readOnly: converterExpressionReadOnly,
            };
            const converterState = nextConverterState(
                prevConverterState,
                selectedMetaToConverterState(selected),
            );

            setConverter(rowId, converterState);
        },
        [rowId, setConverter, converterExpression, converterExpressionReadOnly],
    );

    return (
        <Forms.FieldGrid.Row>
            <Forms.Field
                name={serialize(rowId, "input")}
                label={"Input"}
                options={inputOptions}
                initialValue={initialInput}
                as={MapperCascader}
                from={identity}
                validator={inputValidator}
                disabled={readOnly || inputType !== InputType.BODY}
                onChangeBypassUnityForms={onInputChange}
                onAddonClick={e => {
                    e.stopPropagation();
                    onInputAddonClick();
                }}
                addonAfter={
                    <ConverterButton
                        inputType={inputType}
                        converter={converterExpression}
                    />
                }
            />
            <Forms.Field
                name={serialize(rowId, "output")}
                label={"Output"}
                options={outputOptions}
                initialValue={initialOutput}
                as={MapperCascader}
                from={identity}
                validator={outputValidator}
                disabled={readOnly}
                onAddonClick={e => {
                    e.stopPropagation();
                }}
                addonAfter={
                    <OutputType
                        fieldId={fieldId(formId, serialize(rowId, "output"))}
                        getOutputOptionType={getOutputOptionType}
                    />
                }
            />
            <Forms.UIField name="actions">
                <DeleteRowButton
                    disabled={readOnly}
                    onClick={() => deleteRow(rowId)}
                />
            </Forms.UIField>
        </Forms.FieldGrid.Row>
    );
};

MapperRow.propTypes = {
    formId: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
    ]).isRequired,
    rowId: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
    ]).isRequired,
};
