import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useAddPreference } from "./loadableResources";
import { applyPreferenceChanges } from "./utils";

const {
    useForm,
    Form,
    Fields,
    success,
    error,
    useFieldValidation,
    isError,
    validators,
} = Forms;

// TODO: fix error while in pristine initial state
const ValidatonResult = ({ name }) => {
    const validation = useFieldValidation({ name });
    if (isError(validation)) {
        return `${validation.value}`;
    }
    return null;
};

export const NewPreferenceModal = ({
    visible,
    onCancel,
    onCreated,
    changedPreference,
    selectedPreference,
    preferencesType,
}) => {
    const addPreferenceResource = useAddPreference(onCreated);
    const onSubmit = async ({ values: { name, defaultForMe }, setValues }) => {
        try {
            const newChangedPreference = {
                ...changedPreference,
                type: preferencesType,
                name,
                active: !!defaultForMe,
            };
            const preference = applyPreferenceChanges(
                selectedPreference,
                newChangedPreference,
            );
            await addPreferenceResource.mutate(preference);
            setValues({ name: "", defaultForMe: false });
        } catch (e) {
            console.log("submitPassword", e);
        }
    };

    const { formId, handleSubmit } = useForm({
        onSubmit,
    });
    const failFastValidator = useMemo(() => {
        const required = value => (value ? success() : error("Required"));
        const minLength = value =>
            value.length >= 2 ? success() : error("Min length is 2");

        return validators.failOnFirst([required, minLength]);
    }, []);

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={"Save as New Preferences"}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Form
                        formId={formId}
                        onSubmit={handleSubmit}
                        // className={style.form}
                    >
                        <Fields.Input
                            label={"Preference Name"}
                            name="name"
                            placeholder="Enter new preference name"
                            // renderExtra={({ name }) => (
                            //     <Suspense fallback="Validating">
                            //         <ValidatonResult name={name} />
                            //     </Suspense>
                            // )}
                            getValidateStatus={() => undefined}
                            validator={failFastValidator}
                            inputWidth="max"
                        />
                        <Fields.Checkbox
                            inputWidth="max"
                            name="defaultForMe"
                            label={"Set as default preference only for me"}
                        />
                    </Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.save"),
                                    type: "primary",
                                    formId,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onCancel,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

NewPreferenceModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onCreated: PropTypes.func.isRequired,
    changedPreference: PropTypes.object,
    selectedPreference: PropTypes.object,
    preferencesType: PropTypes.string.isRequired,
};
