import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useMutationLoadableWithNotification } from "@/modules/loadable";
import { t } from "@/translations";
import { noop } from "lodash/fp";

export const useDeleteNodeMutation = ({ afterSuccess = noop }) => {
    const { nodeService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record =>
            nodeService.deleteNode(record.nodeId).then(() => {
                afterSuccessCurrent();
                return record;
            }),

        [afterSuccessCurrent, nodeService],
        ({ nodeName }) =>
            t("general.message.deleted", {
                type: "node",
                name: nodeName,
            }),
    );
};

export const useDeleteClusterMutation = ({ afterSuccess = noop }) => {
    const { nodeService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record =>
            nodeService.deleteCluster(record.clusterName).then(() => {
                afterSuccessCurrent();
                return record;
            }),

        [afterSuccessCurrent, nodeService],
        ({ clusterName }) =>
            t("general.message.deleted", {
                type: "cluster",
                clusterName,
            }),
    );
};
