import useColumnResizing from "@/components/DesignSystem/Table/hooks/useColumnResizing.hook";
import { Table as UnityTable } from "@pricefx/unity-components";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import PageableTable from "../PagableTable/PageableTable";
import { TableLocalFiltered } from "../TableLocalFiltered";
import { addActionMenuRender } from "./utils";

export function TableUsingPreference({
    hasColumnAutofit = true,
    hasColumnResizing = true,
    datasetSlicing,
    columns,
    setColumns,
    pagination,
    onPaginationChange,
    sorter,
    onSortChange,
    filter,
    onFilterChange,
    actionMenu,
    ...props
}) {
    const columnResizing = useColumnResizing(hasColumnResizing, setColumns);
    const columnAutofit = hasColumnAutofit && { setColumns }; // TODO: investigate why is sometimes returning width:NaN
    const datasetSlicingProps = {
        pagination,
        onPaginationChange,
        sorter,
        onSortChange,
        filter,
        onFilterChange,
        controlled: true,
    };

    const columnsWithActionMenu = useMemo(
        () => addActionMenuRender(columns, actionMenu),
        [columns, actionMenu],
    );

    const TableComponent =
        {
            server: PageableTable,
            local: TableLocalFiltered,
        }[datasetSlicing] || UnityTable;

    return (
        <TableComponent
            columns={columnsWithActionMenu}
            columnAutofit={columnAutofit}
            columnResizing={columnResizing}
            {...datasetSlicingProps}
            {...props}
        />
    );
}

TableUsingPreference.propTypes = {
    hasColumnAutofit: PropTypes.bool,
    hasColumnResizing: PropTypes.bool,
    datasetSlicing: PropTypes.string,
    columns: PropTypes.array.isRequired,
    setColumns: PropTypes.func.isRequired,
    pagination: PropTypes.object,
    onPaginationChange: PropTypes.func.isRequired,
    sorter: PropTypes.object,
    onSortChange: PropTypes.func.isRequired,
    filter: PropTypes.object,
    onFilterChange: PropTypes.func.isRequired,
    actionMenu: PropTypes.func,
};
