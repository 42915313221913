import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import React from "react";
import { AccountPermissionsAdminSettingsComponent } from "../../components/AccountPermissionAdminSettings/AccountPermissionsAdminSettings.component";

export function PermissionAdminPage() {
    useFullWidthTableLayout();
    useHideBackButton();

    return <AccountPermissionsAdminSettingsComponent />;
}
