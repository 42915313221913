import React from "react";

import { Button } from "@/components/DesignSystem";
import styles from "./TableButton.style.less";

export const TableButton = ({ children, ...props }) => (
    <Button
        type="link"
        size="small"
        className={styles.tableButton}
        label={children}
        {...props}
    />
);
