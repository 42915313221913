import {
    ButtonMenu,
    Col,
    Gap,
    Label,
    Modal,
    Row,
    UnityLayout,
} from "@/components/DesignSystem";
import { CodeMirror } from "@/components/DesignSystem/CodeMirror";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useWithPending } from "@/components/hooks/useWithPending.hook";
import { useCustomModal } from "@/modules/modal";
import { ModalProviderPropsPropType } from "@/modules/modal/CustomModal";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import PropTypes from "prop-types";
import React from "react";

const hasConflictBool = ({ data }) => data?.conflict;
const getConflictModalProps = ({ data }) => ({
    updatedBy: data.updatedBy, // check
    updatedAt: data.updatedAt, // check
    instanceRepoCode: data.onlineContent,
    workspaceCode: data.content,
});

export class UserAbortError extends Error {}

export const useWithConflictModal = ({
    hasConflict = hasConflictBool,
    getModalProps = getConflictModalProps,
} = {}) => {
    const customModal = useCustomModal();
    const { messageService } = useDic();

    return useCurrentHandler(async performRequest => {
        const res = await performRequest(false);
        if (!hasConflict(res)) return res;

        return new Promise((resolve, reject) => {
            const onConfirm = async () =>
                performRequest(true)
                    .then(res => {
                        customModal.hide();
                        resolve(res);
                    })
                    .catch(e =>
                        messageService.error({
                            content: getErrorMessage(e.response.data),
                        }),
                    );
            const onCancel = () => {
                customModal.hide();
                reject(new UserAbortError("Aborted by the user"));
            };

            customModal.show(
                <ConflictModal
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    {...getModalProps(res)}
                />,
            );
        });
    });
};

const CODE_MIRROR_OPTIONS = {
    readOnly: true,
    extensions: [],
};

export const ConflictModal = ({
    modalProviderProps: { visible },
    onConfirm,
    onCancel,
    updatedBy,
    updatedAt,
    instanceRepoCode,
    workspaceCode,
}) => {
    const confirmWithPending = useWithPending(onConfirm);

    return (
        <Modal visible={visible} onClose={onCancel} width={968}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("instance-repo.conflict.modal.title")}
                />
                <UnityLayout.Content padding={[true, true]}>
                    {t("instance-repo.conflict.modal.perex", {
                        updatedBy,
                        updatedAt,
                    })}
                    <Gap />
                    <Row gutter={24}>
                        <Col span={12}>
                            <Label
                                label={t(
                                    "instance-repo.conflict.modal.instance-repo.label",
                                )}
                            />
                            <CodeMirror
                                mode="xml"
                                value={instanceRepoCode}
                                {...CODE_MIRROR_OPTIONS}
                            />
                        </Col>
                        <Col span={12}>
                            <Label
                                label={t(
                                    "instance-repo.conflict.modal.workspace.label",
                                )}
                            />
                            <CodeMirror
                                mode="xml"
                                value={workspaceCode}
                                {...CODE_MIRROR_OPTIONS}
                            />
                        </Col>
                    </Row>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.deploy"),
                                    type: "primary",
                                    onClick: confirmWithPending.handler,
                                    disabled: confirmWithPending.pending,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onCancel,
                                    type: "text",
                                    disabled: confirmWithPending.pending,
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

ConflictModal.propTypes = {
    modalProviderProps: ModalProviderPropsPropType(),
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    updatedBy: PropTypes.any,
    updatedAt: PropTypes.any,
    instanceRepoCode: PropTypes.string,
    workspaceCode: PropTypes.string,
};
