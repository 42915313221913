import { useDic } from "@/components/Dic/useDic.hook";
import React, { useEffect, useState } from "react";
import DagreD3 from "./DagreD3";

const RouteDiagram = ({ definition, routeId }) => {
    const { routeDiagramService } = useDic();
    const [nodes, setNodes] = useState([]);
    const [links, setLinks] = useState([]);

    useEffect(() => {
        const canvasWidth = 600;

        const scope = {
            routes: definition,
            nodes: {},
            routeNodes: {},
        };

        let nodes = [];
        let links = [];
        if (definition) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(definition, "application/xml");
            routeDiagramService.loadRouteXmlNodes(
                scope,
                doc,
                routeId,
                nodes,
                links,
                canvasWidth,
            );
            setNodes(nodes);
            setLinks(links);
        }
    }, [definition]);

    return (
        <div>
            <div>
                <DagreD3 nodes={nodes} links={links} />
            </div>
        </div>
    );
};

export default RouteDiagram;
