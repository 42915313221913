export class WorkflowDefinitionService {
    /**
     *
     * @type {WorkflowDefinitionApiService}
     */
    workflowDefinitionApiService = null;

    constructor({ workflowDefinitionApiService }) {
        this.workflowDefinitionApiService = workflowDefinitionApiService;
    }

    /**
     *
     * @param {string} accountId
     * @returns {Promise<[]>}
     */
    getWorkflowDefinitions(accountId) {
        return this.workflowDefinitionApiService.getWorkflowsForAccount(
            accountId,
        );
    }

    /**
     *
     * @param {object}
     * @param {any} data
     * @returns {Promise<[]>}
     */
    setWorkflowsForAccount({ accountId, workflowId }, data) {
        return this.workflowDefinitionApiService.setWorkflowsForAccount(
            { accountId, workflowId },
            data,
        );
    }

    /**
     *
     * @param {string} accountId
     * @param {string} workflowId
     * @returns {Promise<[]>}
     */
    deleteWorkflowsForAccount(accountId, workflowId) {
        return this.workflowDefinitionApiService.deleteWorkflowsForAccount(
            accountId,
            workflowId,
        );
    }
}
