import { PFX_LOGIN } from "@/mixpanel/mixpanel.constants";
import { getData } from "@/services/utils";

export const getSsoInfoAndLoginToPFX = ({
    pfxLoginInfo,
    ssoService,
    mixpanelService,
}) => {
    mixpanelService.track(PFX_LOGIN, {
        pfxLogin: pfxLoginInfo,
    });

    ssoService.getSsoInfo(pfxLoginInfo).then(getData).then(loginToPFX);
};

export const loginToPFX = (ssoInfo, target = "_blank") => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = ssoInfo.url;
    form.target = target;

    const jwtToken = document.createElement("input");
    jwtToken.value = ssoInfo.jwtToken;
    jwtToken.name = "token";
    jwtToken.type = "hidden";
    form.appendChild(jwtToken);

    if (ssoInfo.target) {
        const targetUrl = document.createElement("input");
        targetUrl.value = ssoInfo.target;
        targetUrl.name = "target";
        targetUrl.type = "hidden";
        form.appendChild(targetUrl);
    }

    document.body.appendChild(form);
    form.submit();
};
