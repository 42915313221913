import { getData } from "@/services/utils";
import qs from "qs";
import { axiosService } from "../axios";

const qsOptions = { addQueryPrefix: true };

const mapImError = ({ response }) => {
    const error = new Error("Error");

    error.response = {
        data: {
            message:
                getDeployResult(response) ||
                response?.data?.result?.response?.data?.error ||
                response?.data?.message,
        },
    };
    return error;
};

const getDeployResult = res =>
    res?.data?.result?.results?.map(e => e.error)?.join(",");

const throwMappedError = mapper => e => {
    const mapped = mapper(e);
    console.log({ e, mapped });
    throw mapped;
    // throw mapper(e);
};

export const routesService = {
    // TODO: GET /api/instance-integrations?instanceId=1
    fetchRoutes: instanceId =>
        axiosService
            .get(`api/instances/${instanceId}/integrations`)
            .then(getData),

    fetchRoute: routeId =>
        axiosService.get(`/api/instance-integrations/${routeId}`).then(getData),

    deleteRoute(routeId) {
        return axiosService.delete(`/api/instance-integrations/${routeId}`);
    },

    copy(routeId, payload) {
        return axiosService.post(
            `/api/instance-integrations/${routeId}/copy`,
            payload,
        );
    },

    copyInfo(routeId, payload) {
        return axiosService.post(
            `/api/instance-integrations/${routeId}/copy/info`,
            payload,
        );
    },

    stopRoute(instanceId, routeId) {
        return axiosService.post(
            `api/instances/${instanceId}/camel/routes/${routeId}/stop`,
        );
    },

    startRoute(instanceId, routeId) {
        return axiosService.post(
            `api/instances/${instanceId}/camel/routes/${routeId}/start`,
        );
    },

    removeRoute(instanceId, routeId) {
        return axiosService.post(
            `api/instances/${instanceId}/camel/routes/${routeId}/remove`,
        );
    },

    saveRoute(payload, query) {
        const queryStr = qs.stringify(query, qsOptions);
        return axiosService
            .post(`/api/instance-integrations${queryStr}`, payload)
            .catch(throwMappedError(mapImError));
        // .catch(e => console.log(e));
    },

    validate(instanceId, xml) {
        const data = { instanceId, xml };
        return axiosService.post(
            `/api/instance-integrations/xml/validate`,
            JSON.stringify(data),
        );
    },

    fetchRoutesDetailsOnline(instanceId) {
        return axiosService.get(
            `api/instances/${instanceId}/routes/details/online`,
        );
    },

    fetchRoutesDefinitions(instanceId) {
        return axiosService.get(
            `api/instances/${instanceId}/camel/routes/definitions`,
        );
    },

    fetchRouteDefinitionFromRepository(instanceId, routeId) {
        return axiosService.get(
            `api/instances/${instanceId}/camel/routes/${routeId}/definition/repository`,
        );
    },

    fetchRouteDocumentation(instanceId, routeId) {
        return axiosService.get(
            `api/instances/${instanceId}/routes/${routeId}/documentation`,
        );
    },

    fetchRouteDefinition(instanceId, routeId) {
        return axiosService.get(
            `api/instances/${instanceId}/camel/routes/${routeId}/definition`,
        );
    },

    sendPayloadToRoute(instanceId, routeId, payload, headers, properties) {
        return axiosService.post(
            `api/instances/${instanceId}/camel/routes/${routeId}/invoke`,
            { payload, headers, properties },
        );
    },

    fetchInstanceRepoRoutes(instanceId) {
        const queryStr = qs.stringify({ instanceId }, qsOptions);
        return axiosService.get(`/api/instance-integrations/online${queryStr}`);
    },

    createLocalCopy(instanceId, payload) {
        return axiosService.post(
            `/api/instance/${instanceId}/route-online-copy`,
            payload,
        );
    },

    fetchRoutesWithDetail(instanceId) {
        const queryStr = qs.stringify({ instanceId }, qsOptions);
        return axiosService.get(`/api/instance-integrations${queryStr}`);
    },

    deployRoute(instanceId, payload, query) {
        const queryStr = qs.stringify(query, qsOptions);
        return axiosService
            .post(
                `/api/instances/${instanceId}/integrations/${payload.id}/deploy${queryStr}`,
            )
            .catch(throwMappedError(mapImError));
    },
};
