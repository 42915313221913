export const CUSTOMER_ACCOUNT_TYPE = "Customer";
export const PROSPECT_ACCOUNT_TYPE = "Prospect";
export const PROSPECT_PAID_POC_ACCOUNT_TYPE = "Prospect - Paid POC";
export const PARTNER_ACCOUNT_TYPE = "Partner Customer";

export const PARTITION_PURPOSE_DEMO = "DEMO";
export const PARTITION_PURPOSE_POC = "POC";
export const PARTITION_PURPOSE_PRODUCTION = "PRODUCTION";
export const PARTITION_PURPOSE_TEST = "TEST";
export const PARTITION_PURPOSE_TRAINING = "TRAINING";
