import { useDic } from "@/components/Dic/useDic.hook";
import { ConditionalImConfigErrorAlert } from "@/components/Error/ConditionalImConfigErrorAlert";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { InstanceRepoIntegrationsRoutes } from "@/components/IntegrationRoutes/InstanceRepoIntegrationsRoutes";
import { IntegrationsRoutes } from "@/components/IntegrationRoutes/IntegrationRoutes.component";
import {
    HeaderTabs,
    useHeaderTabs,
} from "@/components/PageLayout/components/HeaderTabs.component";
import { useRefreshAction } from "@/components/PageLayout/useRefreshAction.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import React, { useReducer } from "react";
import { useEditInstanceBreadcrumbButton } from "../../apps/accountApp/components/hooks/useEditInstanceBreadcrumbButton.hook";
import { getIntegrationRoutesTrackName } from "../../mixpanel/buttonNames";

export const TABS = {
    INSTANCE_REPOSITORY: "INSTANCE_REPOSITORY",
    WORKSPACE: "WORKSPACE",
};

export const IntegrationRoutesPage = () => {
    const { instanceId, accountId } = useAccountAppParams();
    const { activeKey, setActiveKey } = useHeaderTabs();
    const [reloadToken, reloadAll] = useReducer(s => ++s, 0);

    useFullWidthTableLayout();
    useRefreshAction(reloadAll);

    const {
        locationRouterService,
        accountAppLocations: {
            integrationNewLocation,
            integrationEditLocation,
        },
    } = useDic();

    useEditInstanceBreadcrumbButton({
        instanceId,
        label: "customer-integration-list.button.new",
        onClick: () => locationRouterService.navigate(integrationNewLocation),
        permissions: INTEGRATION_EDIT_PERMISSIONS,
        track: { name: getIntegrationRoutesTrackName("New") },
    });
    const afterLocalCopy = useCurrentHandler(() => {
        setActiveKey(TABS.WORKSPACE);
        reloadAll();
    });
    const afterDeploy = useCurrentHandler(() => {
        setActiveKey(TABS.INSTANCE_REPOSITORY);
        reloadAll();
    });
    const onEdit = useCurrentHandler(record =>
        locationRouterService.navigate(integrationEditLocation, {
            integrationId: record.id,
        }),
    );

    return (
        <>
            <ConditionalImConfigErrorAlert instanceId={instanceId} />
            <HeaderTabs defaultActiveKey={TABS.INSTANCE_REPOSITORY}>
                <HeaderTabs.TabPane
                    tab="Instance Repository"
                    key={TABS.INSTANCE_REPOSITORY}
                    forceRender
                >
                    <InstanceRepoIntegrationsRoutes
                        instanceId={instanceId}
                        accountId={accountId}
                        visible={activeKey === TABS.INSTANCE_REPOSITORY}
                        afterLocalCopy={afterLocalCopy}
                        afterDelete={reloadAll}
                        reloadToken={reloadToken}
                    />
                </HeaderTabs.TabPane>
                <HeaderTabs.TabPane
                    tab="Workspace"
                    key={TABS.WORKSPACE}
                    forceRender
                >
                    <IntegrationsRoutes
                        instanceId={instanceId}
                        accountId={accountId}
                        visible={activeKey === TABS.WORKSPACE}
                        onEdit={onEdit}
                        reloadToken={reloadToken}
                        afterDeploy={afterDeploy}
                    />
                </HeaderTabs.TabPane>
                {/* <HeaderTabs.TabPane
                    tab="Event Listener Workflow"
                    key={TABS.EVENT_LISTENER_WORKFLOW}
                    forceRender
                >
                    <EventListenerWorkflows
                        instanceId={instanceId}
                        accountId={accountId}
                        visible={activeKey === TABS.EVENT_LISTENER_WORKFLOW}
                        onEdit={() => alert("TODO: implement")}
                        reloadToken={reloadToken}
                    />
                </HeaderTabs.TabPane> */}
            </HeaderTabs>
        </>
    );
};
