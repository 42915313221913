import { axiosService } from "../../axios";

export const SSOCertificateAdminService = {
    listCertificates: ({ projectId }) => {
        return axiosService.get(
            `/api/admin/saml-management/${projectId}/certificates`,
        );
    },
    createCertficate: ({ projectId }, body) => {
        return axiosService.post(
            `/api/admin/saml-management/${projectId}/certificates`,
            body,
        );
    },
    deleteCertficate: ({ projectId, certificateId }) => {
        return axiosService.delete(
            `/api/admin/saml-management/${projectId}/certificates/${certificateId}`,
        );
    },
};
