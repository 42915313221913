export class AdminRightsApiService {
    httpService = null;

    constructor({ httpService }) {
        this.httpService = httpService;
    }

    fetchAdminUserRights(userIds) {
        return this.httpService
            .post(`/api/admin/rights/users`, { userIds })
            .then(({ data }) => data);
    }

    fetchAdminUserWorkflowRights(userIds) {
        return this.httpService
            .post(`/api/admin/rights/user-requests`, { userIds })
            .then(({ data }) => data);
    }

    fetchAdminGroupRights(groupNames) {
        return this.httpService
            .post(`/api/admin/rights/groups`, {
                groupNames,
            })
            .then(({ data }) => data);
    }

    fetchAdminGroupWorkflowRights(groupNames) {
        return this.httpService
            .post(`/api/admin/rights/group-requests`, {
                groupNames,
            })
            .then(({ data }) => data);
    }

    modifyRights(changes) {
        return this.httpService.post(`/api/admin/rights`, changes);
    }
}
