import { idleLoadableProps } from "@/components/DataUploads/Wizard/loadables";
import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { hasValue } from "@/modules/loadable";
import { t } from "@/translations";
import { usePartitionOptionsResource } from "@/utils/loadables/partitions.loadables";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useAdminAccountOptionsResource } from "../../views/Settings/AtlassianGroups/loadables";
import { useOptionsValidator } from "../Filters/form/validators";
import { getLoadableSelectProps } from "../Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";

export const DEFAULT_SET = "crm_config";

export const AddPartitionModal = ({ modalProviderProps, onAdd }) => {
    const adminAccountOptionsQuery = useAdminAccountOptionsResource({
        canFetch: true,
    });
    const { formId, handleSubmit } = Forms.useForm({
        onSubmit: async ({
            values: {
                account: projectId,
                partition: partitionId,
                set: configurationName,
            },
        }) => {
            const partition = partitionOptionsQuery.loadable
                .valueMaybe()
                ?.find(({ value }) => value === partitionId);
            onAdd({
                projectId,
                partitionId,
                configurationName,
                partitionName: partition?.label,
            });
            modalProviderProps.onClose();
        },
    });
    const projectId = Forms.useFieldValue({ formId, name: "account" });
    const partitionOptionsQuery = usePartitionOptionsResource({
        projectId,
        canFetch: !!projectId,
    });
    const optionsValidator = useOptionsValidator(
        partitionOptionsQuery.loadable.valueMaybe() ?? [],
    );
    const partitionValidator = useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                optionsValidator,
            ]),
        [optionsValidator],
    );
    const submitDisabled =
        !hasValue(partitionOptionsQuery) || !hasValue(adminAccountOptionsQuery);

    return (
        <Modal {...modalProviderProps}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("feature-flags.add-partition.modal.title")}
                />
                <UnityLayout.Content padding={[true, true]}>
                    <Forms.Form formId={formId} onSubmit={handleSubmit}>
                        <Forms.FieldGroup width="max" inputWidth="max">
                            <Forms.Fields.Select
                                name="account"
                                label={t(
                                    "feature-flags.add-partition.form.account.label",
                                )}
                                placeholder={t(
                                    "feature-flags.add-partition.form.account.placeholder",
                                )}
                                required
                                validator={Forms.pmValidators.isRequired}
                                {...getLoadableSelectProps(
                                    adminAccountOptionsQuery.loadable,
                                )}
                            />
                            <Forms.Fields.Select
                                name="partition"
                                label={t(
                                    "feature-flags.add-partition.form.partition.label",
                                )}
                                placeholder={t(
                                    "feature-flags.add-partition.form.partition.placeholder",
                                )}
                                required
                                validator={partitionValidator}
                                {...getLoadableSelectProps(
                                    partitionOptionsQuery.loadable,
                                )}
                                {...idleLoadableProps(
                                    partitionOptionsQuery.loadable,
                                    "Please, select account",
                                )}
                            />
                            <Forms.Fields.Input
                                name="set"
                                tooltip={t(
                                    "feature-flags.add-partition.form.set.tooltip",
                                    { onlyPossibleSet: DEFAULT_SET },
                                )}
                                label={t(
                                    "feature-flags.add-partition.form.set.label",
                                )}
                                initialValue={DEFAULT_SET}
                                disabled={true}
                            />
                        </Forms.FieldGroup>
                    </Forms.Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.add"),
                                    formId,
                                    type: "primary",
                                    disabled: submitDisabled,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: modalProviderProps.onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
AddPartitionModal.propTypes = {
    onAdd: PropTypes.func.isRequired,
    modalProviderProps: PropTypes.object,
};
