import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { TableButton } from "@/components/TableButton";
import { t } from "@/translations";
import React from "react";

export const createColumns = onClick => [
    {
        name: "name",
        label: t("asset-roles-table.name"),
        type: fieldTypes.TEXT,
        render: (text, record) =>
            !onClick ? (
                text
            ) : (
                <TableButton
                    onClick={e => {
                        // without stopPropagation, Table registers click and adds record to selection
                        e.stopPropagation();
                        onClick(record);
                    }}
                >
                    {text}
                </TableButton>
            ),
    },
    {
        name: "description",
        label: t("asset-roles-table.description"),
        type: fieldTypes.TEXT,
    },
];
