import { useCenteredLayout } from "@/components/hooks/useContentLayout.hook";
import { IntegrationAdministration } from "@/components/IntegrationRoutes/form/IntegrationAdministration";
import { useQueryLoadable } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { routesService } from "@/services/routes.service";
import React from "react";

export const EditRoutePage = () => {
    const { accountId, instanceId, integrationId } = useAccountAppParams();
    useCenteredLayout();

    const routeResource = useQueryLoadable(
        async () => routesService.fetchRoute(integrationId),
        [integrationId],
    );

    return (
        <IntegrationAdministration
            projectId={accountId}
            instanceId={instanceId}
            integrationId={integrationId}
            routeResource={routeResource}
        />
    );
};
