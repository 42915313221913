import {
    Alert,
    ButtonMenu,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import React, { useState } from "react";
import { mappersService } from "../../../services/mapper.service";
import CamelRouteXmlEditor from "../../Editors/CamelRouteXmlEditor/CamelRouteXmlEditor";

const MapperImportFromXml = ({ visible, onOk, onCancel }) => {
    const [xml, setXml] = useState("");
    const [error, setError] = useState(undefined);

    function handleOk() {
        setError(undefined);
        mappersService
            .convertMapper(xml)
            .then(getData)
            .then(data => {
                onOk(data);
                setXml("");
            })
            .catch(() => {
                setError("Definition is not valid");
            });
    }

    function handleCancel() {
        onCancel();
        setXml("");
        setError(undefined);
    }

    return (
        <Modal width="60%" visible={visible} onClose={handleCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("mapper-form.button.import-from-xml")}
                    alert={
                        error && (
                            <Alert
                                showIcon
                                type="error"
                                banner
                                message={error}
                            />
                        )
                    }
                />
                <UnityLayout.Content padding={[false, true]}>
                    <CamelRouteXmlEditor
                        value={xml}
                        readOnly={false}
                        onChange={setXml}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.ok"),
                                    onClick: handleOk,
                                    type: "primary",
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: handleCancel,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

export default MapperImportFromXml;
