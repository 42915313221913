import {
    Button,
    ButtonGroup,
    Forms,
    Gap,
    Text,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCenteredLayout } from "@/components/hooks/useContentLayout.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useCreateServerMutation } from "@/components/Servers/loadables";
import { t } from "@/translations";
import React from "react";

const allowedLocations = [
    { value: "de", label: "Germany" },
    { value: "us", label: "United States of America" },
];
const { SubmitButton, useForm, Fields, pmValidators } = Forms;

const CreateServerForm = () => {
    const { locationRouterService, overviewAppLocations } = useDic();

    useCenteredLayout();
    useSetPageTitle(t("server-create.title.new-server"));

    const createServerMutation = useCreateServerMutation({
        afterSuccess: () =>
            locationRouterService.navigate(
                overviewAppLocations.serversLocation,
            ),
    });

    const onSubmit = ({ values }) => {
        return createServerMutation.mutate(values);
    };

    const { Form, handleSubmit } = useForm({
        onSubmit,
    });

    return (
        <>
            <Text>{t("server-create.label.action-info")}</Text>
            <Gap />
            <Form disableBanner onSubmit={handleSubmit}>
                <Fields.Input
                    name="name"
                    type="text"
                    label={t("server-create.label.server-name")}
                    placeholder={t("server-create.placeholder.server-name")}
                    required
                    validator={pmValidators.isRequired}
                />

                <Fields.Select
                    label={t("server-create.label.server-location")}
                    placeholder={t("server-create.placeholder.select-location")}
                    name="location"
                    required
                    validator={pmValidators.isRequired}
                    allowClear={false}
                    options={allowedLocations}
                />

                <ButtonGroup>
                    <SubmitButton
                        type="primary"
                        label={t("server-create.button.save")}
                    />
                    <Button
                        htmlType="button"
                        type="text"
                        onClick={() => {
                            locationRouterService.navigate(
                                overviewAppLocations.serversLocation,
                            );
                        }}
                        label={t("server-create.button.cancel")}
                    />
                </ButtonGroup>
            </Form>
        </>
    );
};

export default CreateServerForm;
