import {
    ButtonMenu,
    Divider,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useState } from "react";

const isFormatVisible = type => ["NUMBER", "MONEY", "INTEGER"].includes(type);
const isDimensionVisible = type => ["TEXT", "DATE"].includes(type);

const measureType = [
    { label: "Per Unit", value: "PERUNIT" },
    { label: "Extended", value: "EXTENDED" },
    { label: "Fixed", value: "FIXED" },
];

const types = [
    { label: "Number", value: "NUMBER" },
    { label: "Quantity", value: "QUANTITY" },
    { label: "Text", value: "TEXT" },
    { label: "Date", value: "DATE" },
    { label: "Money", value: "MONEY" },
    { label: "Currency", value: "CURRENCY" },
    { label: "UOM", value: "UOM" },
    { label: "LOB", value: "LOB" },
    { label: "DateTime", value: "DATETIME" },
    { label: "Boolean", value: "BOOLEAN" },
    { label: "Integer", value: "INTEGER" },
];
const functionFields = [
    { label: "Key", value: "KEY" },
    { label: "Alt-key", value: "ALTKEY" },
    { label: "Level", value: "LEVEL" },
];
const { useForm, pmValidators } = Forms;

const CreateCustomFieldModal = ({
    visible,
    onSave,
    onCancel,
    availableFields,
}) => {
    const onSubmit = ({ values }) => onSave(values);

    const { Form, formId, handleSubmit } = useForm({
        onSubmit,
    });

    const [type, setSelectedType] = useState();

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("custom-fields.modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Form disableBanner onSubmit={handleSubmit}>
                        <Forms.Fields.Select
                            name="name"
                            label={t("custom-fields.modal.form.name")}
                            options={availableFields.map(({ name }) => ({
                                label: name,
                                value: name,
                            }))}
                            required
                            validator={pmValidators.isRequired}
                            inputWidth="max"
                        />
                        <Forms.Fields.Input
                            name="label"
                            label={t("custom-fields.modal.form.label")}
                            required
                            validator={pmValidators.isRequired}
                            inputWidth="max"
                        />
                        <Forms.Fields.Select
                            name="type"
                            label={t("custom-fields.modal.form.type")}
                            options={types}
                            required
                            validator={pmValidators.isRequired}
                            inputWidth="max"
                            onChange={({ value }) => setSelectedType(value)}
                        />
                        <Divider plain>
                            {t("custom-fields.modal.divider.optional-fields")}
                        </Divider>

                        {isFormatVisible(type) && (
                            <Forms.Fields.Input
                                name="format"
                                label={t("custom-fields.modal.form.format")}
                                inputWidth="max"
                            />
                        )}
                        <Forms.Fields.Select
                            name="measureType"
                            label={t("custom-fields.modal.form.measureType")}
                            options={measureType}
                            allowClear
                            inputWidth="max"
                        />
                        {isDimensionVisible(type) && (
                            <Forms.Fields.Switch
                                name="dimension"
                                label={t("custom-fields.modal.form.dimension")}
                                inputWidth="max"
                            />
                        )}
                        <Forms.Fields.Select
                            name="function"
                            label={t("custom-fields.modal.form.function")}
                            allowClear
                            options={functionFields}
                            inputWidth="max"
                        />
                    </Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    formId,
                                    type: "primary",
                                    label: t("general.create"),
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onCancel,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

CreateCustomFieldModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    availableFields: PropTypes.array.isRequired,
};

export default CreateCustomFieldModal;
