import { UnityAvatar } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import React from "react";

const getInitials = fullName =>
    `${fullName}`
        .split(" ")
        .map(part => part[0])
        .join("");

const AvatarInitials = ({ fullName, className }) => {
    return (
        <UnityAvatar className={className}>{getInitials(fullName)}</UnityAvatar>
    );
};

AvatarInitials.propTypes = {
    fullName: PropTypes.string.isRequired,
};

export default AvatarInitials;
