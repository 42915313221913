/**
 * This class is a wrapper around the axios client
 * so it can cooperate with the HttpService
 */
export class AxiosHttpClient {
    constructor({ axiosService }) {
        this.axiosService = axiosService;
    }

    get(path, opts) {
        return this.axiosService.get(path, opts).then(getData);
    }

    post(path, data, opts) {
        return this.axiosService.post(path, data && data, opts);
    }

    delete(path, opts) {
        return this.axiosService.delete(path, opts);
    }
}

function getData(response) {
    return response.data;
}
