import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import React from "react";

const ISVConnectionsOverviewPage = () => {
    useFullWidthTableLayout();

    return <></>;
};

export default ISVConnectionsOverviewPage;
