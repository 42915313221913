import { Menu } from "@/components/DesignSystem";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { t } from "@/translations";
import React from "react";
import { SidebarAndContentLayout } from "../Layouts/SidebarAndContentLayout";
import { useSetPageTitle } from "../PageLayout/useSetPageTitle.hook";
import { WorkflowApprovals } from "./WorkflowApprovals";

const WorkflowsApprovalPage = () => {
    useFullWidthTableLayout();

    useSetPageTitle(t("workflows.nav-item.my-approvals"));

    return (
        <SidebarAndContentLayout
            sidebar={
                <Menu selectedKeys={["my-approvals"]} mode="inline" condensed>
                    <Menu.Item key="my-approvals">
                        {t("workflows.nav-item.my-approvals")}
                    </Menu.Item>
                </Menu>
            }
        >
            <WorkflowApprovals />
        </SidebarAndContentLayout>
    );
};

export default WorkflowsApprovalPage;
