import { DeploymentStatus } from "@/components/DeployedPackages/DeployedPackagesList.component";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { t } from "@/translations";
import { Table as UnityTable } from "@pricefx/unity-components";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import Moment from "react-moment";
import { ErrorLogModal } from "./ErrorLogModal";

const preferencesType = preferencesTypes.FAILED_DEPLOYMENTS_LIST_TABLE;

export const FailedDeploymentsList = props => {
    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t(
                            "failed-deployments-list.action-menu.view-error-logs",
                        ),
                        customModal: <ErrorLogModal record={record} />,
                    },
                ]}
            />
        ),
        [],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            defaultSort={{ fieldName: "updatedAt", sortDir: "descending" }}
            defaultFilter={{ status: { value: DeploymentStatus.FAILED } }}
            fixed
            rowKey="id"
            datasetSlicing="server"
            preferencesType={preferencesType}
            columns={[
                {
                    type: fieldTypes.TEXT,
                    label: t("failed-deployments-list.header.job-id"),
                    name: "id",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("failed-deployments-list.header.label"),
                    name: "name",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("failed-deployments-list.header.template-name"),
                    name: "templateName",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t(
                        "failed-depoloyments-list.header.template-version",
                    ),
                    name: "templateVersion",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("deployed-templates-list.header.account-name"),
                    name: "accountName",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("deployed-templates-list.header.partition-name"),
                    name: "serialNumber",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("deployed-templates-list.header.instance-name"),
                    name: "instanceName",
                },

                {
                    type: fieldTypes.TEXT,
                    label: t("failed-deployments-list.header.started-by"),
                    name: "startedBy",
                },
                {
                    type: fieldTypes.DATETIME,
                    label: t("general.started-at"),
                    name: "startedAt",
                    render: (text, record) =>
                        record.startedAt && (
                            <Moment format="DD/MM/YYYY HH:mm:ss">
                                {record.startedAt}
                            </Moment>
                        ),
                },
                {
                    type: fieldTypes.DATETIME,
                    label: t("failed-deployments-list.header.failed-at"),
                    name: "updatedAt",
                    render: (text, record) =>
                        record.updatedAt && (
                            <Moment format="DD/MM/YYYY HH:mm:ss">
                                {record.updatedAt}
                            </Moment>
                        ),
                },
            ]}
            pmExpandable={{
                expandedRowRender: record => renderMessagesTable(record),
            }}
            exportUrl={
                props.accountId
                    ? `/api/packages/deployments/${props.accountId}/export`
                    : `/api/packages/deployments/export`
            }
            {...props}
        />
    );
};

const renderMessagesTable = record => {
    const columns = [
        {
            label: t("failed-deployments-list.header.messages"),
            name: "message",
            type: "TEXT",
        },
    ];
    const dataSource =
        record.messages?.map(({ message }, index) => ({
            message,
            key: index,
        })) ?? [];
    return <UnityTable columns={columns} dataSource={dataSource} />;
};

FailedDeploymentsList.propTypes = {
    error: PropTypes.object,
    items: PropTypes.array,
    loading: PropTypes.bool,
};
