import { useDic } from "@/components/Dic/useDic.hook";
import { useEffect, useRef, useState } from "react";

export function useLocation() {
    const [location, setLocation] = useState(null);

    useLocationChanged(setLocation);

    return location;
}

export function useLocationChanged(clb) {
    const { locationRouterService } = useDic();
    const clbRef = useRef(clb);
    if (clb !== clbRef.current) clbRef.current = clb;

    useEffect(() => {
        const closureClb = (...args) => clbRef.current(...args);
        locationRouterService.onLocationChanged(closureClb);

        return () =>
            locationRouterService.removeLocationChangeListener(closureClb);
    }, [locationRouterService]);
}
