import { tap } from "@/components/Connections/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { withWorkflowFeedback } from "@/components/WorkFlow/workflowNotification";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { TrackingContext } from "@/mixpanel/TrackingContextProvider";
import {
    useMutationLoadable,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { useCustomModal } from "@/modules/modal";
import { getData } from "@/services/utils";
import { get, pipe } from "lodash/fp";
import { useContext } from "react";

const dataSource = [
    {
        id: 666,
        name: "Machine Name",
        validTo: "2023-04-22T11:11:11.111Z",
        status: "Pending",
    },
];

export const useCalculationEnginesQuery = ({ partitionId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/partition-assets/${partitionId}/calculation-engines`)
                .then(getData),
        [axiosService, partitionId],
    );
};

export const useCalculationEngineQuery = ({ engineId, partitionId, isNew }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            isNew
                ? Promise.resolve()
                : axiosService
                      .get(
                          `/api/partition-assets/${partitionId}/calculation-engines/${engineId}`,
                      )
                      .then(getData),
        [axiosService, engineId, isNew, partitionId],
    );
};

export const useSubmitCalculationEngineMutation = ({
    partitionId,
    afterSuccess,
}) => {
    const { axiosService, messageService } = useDic();
    const customModal = useCustomModal();
    const { trackWorkflow } = useContext(TrackingContext);
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadable(
        async data =>
            withWorkflowFeedback(
                { messageService, customModal, trackWorkflow },
                () =>
                    axiosService.post(
                        `/api/partition-assets/${partitionId}/calculation-engines`,
                        data,
                    ),
            )
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [
            afterSuccessCurrent,
            axiosService,
            customModal,
            messageService,
            partitionId,
            trackWorkflow,
        ],
    );
};

export const useDeleteCalculationEngineMutation = ({
    partitionId,
    afterSuccess,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        ({ engineId }) =>
            axiosService
                .delete(
                    `/api/partition-assets/${partitionId}/calculation-engines/${engineId}`,
                )
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, axiosService, partitionId],
    );
};

export const useCalculationEnginePresetsQuery = () => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            axiosService.get(`/api/calculation-engines/presets`).then(getData),
        [axiosService],
    );
};
