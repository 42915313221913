import { ConnectionFieldMap } from "@/components/Connections/ConnectionFieldMap";
import { TABS } from "@/components/Connections/Connections.page";
import {
    useConnectionQuery,
    useConnectionTypeQuery,
    useConnectionTypesOptionsQuery,
    useCreateConnectionMutation,
    useSaveConnectionMutation,
    useTestConnectionMutation,
} from "@/components/Connections/loadables";
import {
    getFieldValidator,
    useConnectionNameValidator,
    useTypeValidator,
} from "@/components/Connections/validators";
import {
    Alert,
    Button,
    Forms,
    Gap,
    ModalOrNothing,
    Text,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import {
    isLoading,
    loadableFromMaybeValue,
    LoadableRenderer,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { t } from "@/translations";
import { capitalize, entries, identity, pipe, reduce, set } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import { getConnectionFormTrackName as getTrackName } from "../../mixpanel/buttonNames";
import { useTrackButtonClick } from "../../mixpanel/hooks/useTrackButtonClick.hook";

const ACTION = {
    DEPLOY: "DEPLOY",
    SAVE: "SAVE",
    TEST: "TEST",
};

export const OPERATION = {
    NEW: "NEW",
    EDIT: "EDIT",
};

const createOptions = options => {
    if (!options || typeof options !== "object")
        throw new Error("Missing options");
    if (Array.isArray(options)) return options;
    return Object.entries(options).map(([value, label]) => ({ value, label })); // TODO: ensure just this row can stay
};

const getComponent = field => {
    if (field.type === "select")
        return [Forms.Fields.Select, { options: createOptions(field.options) }];
    switch (field.fieldType) {
        case "int":
            return [Forms.Fields.InputNumber];
        case "java.util.Map":
            return [Forms.Field, { as: ConnectionFieldMap, from: identity }];
    }
    if (field.encrypted)
        return [Forms.Fields.InputPassword, { autoComplete: "new-password" }];
    return [Forms.Fields.Input];
};

export const ConnectionForm = ({
    initValues = {},
    instanceId,
    connectionId,
    onSave,
    onCancel,
    isModalVariant = false,
    confirmDeployed = false,
    disableTypeChange = false,
}) => {
    const {
        locationRouterService,
        accountAppLocations: { connectionsLocation, connectionEditLocation },
    } = useDic();
    const operation = connectionId === "new" ? OPERATION.NEW : OPERATION.EDIT;
    const { formId, submit, handleSubmit, setValues, setTouched } =
        Forms.useForm({
            onSubmit: ({ values: flatValues, args: [action] }) => {
                // {"configuration.uri"} -> {configuration: {uri}}
                const values = pipe(
                    entries,
                    reduce((acc, [k, v]) => set(k.split("."), v, acc), {}),
                )(flatValues);

                if (action === ACTION.TEST)
                    return testConnectionMutation.mutate({ values });

                const operations = {
                    [OPERATION.NEW]: createConnectionMutation.mutate,
                    [OPERATION.EDIT]: saveConnectionMutation.mutate,
                };
                if (action === ACTION.SAVE || action === ACTION.DEPLOY)
                    return operations[operation]({
                        values,
                        withDeploy: action === ACTION.DEPLOY,
                        confirmDeployed,
                    });
            },
        });
    const { trackHandler } = useTrackButtonClick();
    const afterSuccess = trackHandler(
        ({ connectionId, deployedConnectionId, withDeploy }) => {
            if (!onSave) {
                if (withDeploy) {
                    locationRouterService.navigate(connectionsLocation, {
                        tab: TABS.INSTANCE_REPOSITORY,
                    });
                } else {
                    if (connectionId) {
                        locationRouterService.navigate(connectionEditLocation, {
                            connectionId,
                        });
                    } else {
                        locationRouterService.navigate(connectionsLocation);
                    }
                }
            } else {
                onSave({
                    connectionId,
                    deployedConnectionId,
                });
            }
        },
        { name: getTrackName("SubmitSuccess") },
    );

    const testConnectionMutation = useTestConnectionMutation({ instanceId });
    const createConnectionMutation = useCreateConnectionMutation({
        instanceId,
        afterSuccess,
    });
    const saveConnectionMutation = useSaveConnectionMutation({
        instanceId,
        connectionId,
        afterSuccess,
    });
    const editConnectionQuery = useConnectionQuery({
        instanceId,
        connectionId,
        canFetch: operation === OPERATION.EDIT,
    });
    const maybeInitialValues = (
        operation === OPERATION.EDIT
            ? editConnectionQuery.loadable
            : loadableFromMaybeValue(initValues)
    ).valueMaybe();
    useSetValidatedInitialValues(
        { setValues, setTouched, initialValues: maybeInitialValues },
        [maybeInitialValues],
    );
    const connectionTypesOptionsQuery = useConnectionTypesOptionsQuery({
        instanceId,
    });
    const type = Forms.useFieldValue({ formId, name: "type" });
    const connectionTypeQuery = useConnectionTypeQuery({
        canFetch: !!type,
        instanceId,
        type,
    });
    const nameValidator = useConnectionNameValidator({
        instanceId,
        currentName: maybeInitialValues?.name,
    });
    const typeValidator = useTypeValidator();
    const disabled =
        isLoading(editConnectionQuery) ||
        isLoading(testConnectionMutation) ||
        isLoading(createConnectionMutation) ||
        isLoading(saveConnectionMutation);

    const actionButtons = [
        {
            type: "primary",
            htmlType: "button",
            onClick: () => submit(ACTION.DEPLOY),
            label: t("general.deploy"),
            disabled: disabled,
            track: { name: getTrackName("Deploy") },
        },
        !isModalVariant && {
            htmlType: "submit",
            onClick: () => submit(ACTION.SAVE),
            label: t("connection-form.button.save"),
            disabled: disabled,
            track: { name: getTrackName("Save") },
        },
        {
            htmlTyp: "submit",
            type: "outline",
            onClick: () => submit(ACTION.TEST),
            label: t("connection-form.button.test"),
            disabled: disabled,
            track: { name: getTrackName("Test") },
        },
        {
            htmlType: "button",
            type: "text",
            onClick: onCancel,
            label: t("general.cancel"),
            disable: disabled,
            track: { name: getTrackName("Cancel") },
        },
    ].filter(Boolean);

    const inputWidth = isModalVariant ? "max" : "default";

    return (
        <ModalOrNothing
            isEnabled={isModalVariant}
            actionButtons={actionButtons}
        >
            <Forms.Form formId={formId} onSubmit={handleSubmit}>
                <Forms.FieldGroup>
                    {operation === OPERATION.EDIT && (
                        <>
                            <Text size="large">
                                {t(
                                    "connection-form.header-description.new-connection",
                                )}
                            </Text>
                            <Gap size="large" />
                        </>
                    )}
                    {operation === OPERATION.EDIT && (
                        <Alert
                            message={t("instance-entity.alerts.name-change", {
                                entity: "connection",
                            })}
                            type="warning"
                            showIcon
                        />
                    )}
                    <Gap />
                    <Forms.Fields.Input
                        name="name"
                        required
                        label={t("connection-form.label.name")}
                        placeholder={t("connection-form.placeholder.name")}
                        validator={nameValidator}
                        allowClear={false}
                        inputWidth={inputWidth}
                    />

                    <Forms.Fields.Select
                        name="type"
                        disabled={disableTypeChange}
                        required
                        label={t("connection-form.label.connection-type")}
                        placeholder={t(
                            "connection-form.placeholder.connection-type",
                        )}
                        validator={typeValidator}
                        allowClear={false}
                        {...getLoadableSelectProps(
                            connectionTypesOptionsQuery.loadable,
                        )}
                        inputWidth={inputWidth}
                    />
                    <LoadableRenderer
                        loadable={useComposeLoadablesMemoized([
                            connectionTypeQuery.loadable,
                            editConnectionQuery.loadable,
                        ])}
                        hasValue={([connectionType, _initialValues]) => {
                            if (
                                useConnectionTypeQuery.IDLE_VALUE ===
                                connectionType
                            )
                                return null;

                            return connectionType.fields.map(field => {
                                const [Component, props] = getComponent(field);

                                return (
                                    <Component
                                        name={`configuration.${field.name}`}
                                        required={!field.optional}
                                        label={
                                            field.label ??
                                            capitalize(field.name)
                                        }
                                        key={field.name}
                                        placeholder={
                                            field.placeholder ||
                                            `${t("general.enter")} ${
                                                field.name
                                            }`
                                        }
                                        tooltip={field.description}
                                        initialValue={field.defaultValue}
                                        inputWidth={inputWidth}
                                        validator={getFieldValidator(
                                            field,
                                            type,
                                        )}
                                        {...props}
                                    />
                                );
                            });
                        }}
                    />
                </Forms.FieldGroup>
                {!isModalVariant &&
                    actionButtons.map(buttonProps => (
                        <Button key={buttonProps.label} {...buttonProps} />
                    ))}
            </Forms.Form>
        </ModalOrNothing>
    );
};

ConnectionForm.propTypes = {
    initValues: PropTypes.object,
    isModalVariant: PropTypes.bool,
    confirmDeployed: PropTypes.bool,
    disableTypeChange: PropTypes.bool,
    instanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    connectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
};
