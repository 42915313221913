import { TableCellField } from "@/components/DataUploads/Wizard/NewTable/components/ConfigureStep";
import { Forms } from "@/components/DesignSystem";
import { Table, hooks } from "@/components/DesignSystem/Table/Table.component";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import { entries, filter, get, map, pipe } from "lodash/fp";
import React, { useMemo } from "react";

const modulePrefix = "modules_";
const moduleLimitPrefix = "maxModuleUsers_";

export const modules = [
    {
        label: t("partition-request.switch.module.analytics"),
        value: "PriceAnalyzer",
        limitUsers: "maxAnalyticsUsers",
    },
    {
        label: t("partition-request.switch.module.price-setting"),
        value: "PriceBuilder",
        limitUsers: "maxPriceBuilderUsers",
    },
    {
        label: t("partition-request.switch.module.optimization"),
        value: "PriceOptimizer",
        limitUsers: "maxPriceOptimizationUsers",
    },
    {
        label: t("partition-request.switch.module.contract-manager"),
        value: "PromotionManager",
        limitUsers: "maxContractManagerUsers",
    },
    {
        label: t("partition-request.switch.module.rebates"),
        value: "RebateManager",
        limitUsers: "maxRebateManagerUsers",
    },
    {
        label: t("partition-request.switch.module.claim-manager"),
        value: "ChannelManager",
        limitUsers: "maxClaimManagerUsers",
    },
    {
        label: t("partition-request.switch.module.sales-compensation"),
        value: "Sales Compensation",
        limitUsers: "maxSalesCompensationUsers",
    },
    {
        label: t("partition-request.switch.module.quoting"),
        value: "QuoteConfigurator",
        limitUsers: "maxPriceShopUsers",
    },
    {
        label: t("partition-request.switch.module.integration-manager"),
        value: "IntegrationManager",
    },
];

export const PreventClick = ({ children }) => (
    <div onClick={e => e.stopPropagation()}>{children}</div>
);

export const columns = ({ selectedKeys, maxModuleUsersDto }) => [
    {
        type: fieldTypes.TEXT,
        label: t("partition-request.modules.column.module"),
        name: "label",
        render: (text, record) => {
            return (
                <PreventClick>
                    {text}
                    <Forms.Fields.Switch
                        hidden
                        name={`${modulePrefix}${record.key}`}
                        initialValue={selectedKeys.includes(record.key)}
                    />
                </PreventClick>
            );
        },
    },
    {
        type: fieldTypes.TEXT,
        label: t("partition-request.modules.column.number-of-users"),
        name: "number-of-users",
        render: (_, record) => {
            const isDisabled = !selectedKeys.includes(record.key);
            if (!record.limitUsers) return null;

            return (
                <PreventClick>
                    <TableCellField
                        disabled={isDisabled}
                        Component={Forms.Fields.InputNumber}
                        size="small"
                        initialValue={
                            maxModuleUsersDto?.[record.limitUsers] || null
                        }
                        min={1}
                        name={`${moduleLimitPrefix}${record.limitUsers}`}
                        placeholder={
                            isDisabled
                                ? "0"
                                : t(
                                      "partition-request.modules.number-of-users.placeholder",
                                  )
                        }
                    />
                </PreventClick>
            );
        },
    },
];

const getSelectedKeys = pipe(
    entries,
    filter(get(1)),
    map(([key]) => modules.find(({ limitUsers }) => limitUsers === key)?.value),
);

export const PartitionModules = ({
    maxModuleUsersDto,
    selectedRowKeys = !maxModuleUsersDto
        ? []
        : getSelectedKeys(maxModuleUsersDto),
    setValues,
}) => {
    const dataSource = useMemo(() => {
        return modules.map(module => ({
            label: module.label,
            key: module.value,
            limitUsers: module.limitUsers,
        }));
    }, []);
    const rowSelection = hooks.useRowSelection({
        rowClickPersistOthers: true,
        onSelectionChange: selectedRowKeys =>
            setValues(
                modules.reduce(
                    (result, module) => ({
                        ...result,
                        [`${modulePrefix}${module.value}`]:
                            selectedRowKeys.includes(module.value),
                    }),
                    {},
                ),
            ),
        selectedRowKeys,
    });

    return (
        <div style={{ maxWidth: "480px" }}>
            <Table
                className="pmTable--withInputs"
                rowHeight={32}
                rowKey="key"
                columns={columns({
                    selectedKeys: rowSelection.selectedRowKeys,
                    maxModuleUsersDto,
                })}
                dataSource={dataSource}
                rowSelection={rowSelection}
            />
        </div>
    );
};
