import { useDic } from "@/components/Dic/useDic.hook";
import {
    pendingPromise,
    useQueryLoadable,
    waitForValue,
} from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useTemplate = (templateId, accountId) => {
    const { templateService } = useDic();

    return useQueryLoadable(
        () =>
            templateId && accountId
                ? templateService.fetchTemplate(templateId, accountId)
                : waitForValue(),
        [templateId, accountId, templateService],
    );
};

export const useTemplateAdmin = packageName => {
    const { templateAdminService } = useDic();

    return useQueryLoadable(
        () =>
            packageName
                ? templateAdminService.fetchTemplate(packageName).then(getData)
                : waitForValue(packageName),
        [packageName, templateAdminService],
    );
};

// useTemplate or useTemplateAdmin when possible
export const useTemplateOrAdminTemplate = ({
    packageName,
    canFetch = !!packageName,
    accountId,
}) => {
    const { templateService, templateAdminService } = useDic();

    return useQueryLoadable(
        () =>
            canFetch
                ? templateService
                      .fetchTemplate(packageName, accountId)
                      .catch(() =>
                          templateAdminService
                              .fetchTemplate(packageName)
                              .then(getData),
                      )
                : waitForValue(packageName),
        [canFetch, packageName, accountId],
    );
};

export const useCustomerInstance = (accountId, instanceId) => {
    const { templateService } = useDic();

    return useQueryLoadable(
        () =>
            instanceId
                ? templateService
                      .fetchIntegrationsForDeploy(accountId)
                      .then(results =>
                          results.find(instance => instance.id === instanceId),
                      )
                : waitForValue(instanceId),
        [accountId, instanceId, templateService],
    );
};

export const useCustomerPartition = (accountId, partitionId) => {
    const { templateService } = useDic();
    const canFetch = parseInt(accountId, 10) > -1;

    return useQueryLoadable(
        () =>
            canFetch
                ? templateService
                      .fetchPartitionForDeploy(accountId)
                      .then(results =>
                          results.find(template => template.id === partitionId),
                      )
                : pendingPromise(),
        [canFetch, accountId, partitionId, templateService],
    );
};
