import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { Text } from "@/components/DesignSystem";
import { Icon } from "@/components/DesignSystem/Icon";
import PropTypes from "prop-types";
import React from "react";
import Avatar from "../Avatar/AvatarIcon";

const activityLogIcon = () => {
    return <Icon type="info-circle" />;
};

const ActivityLogMessage = ({
    message,
    executorName,
    executorGravatarHash,
    result,
    time,
    long,
    showAvatar,
    showIcon,
}) => {
    return (
        <div>
            {showIcon && activityLogIcon(result)}
            {message}
            {long && (
                <React.Fragment>
                    {" "}
                    by {executorName}
                    <div>
                        <Text size="small" type="secondary">
                            <FormattedDateTime>{time}</FormattedDateTime>
                        </Text>
                    </div>
                </React.Fragment>
            )}
            {showAvatar && (
                <div style={{ textAlign: "right" }}>
                    <Avatar
                        gravatarHash={executorGravatarHash}
                        name={executorName}
                    />
                </div>
            )}
        </div>
    );
};

ActivityLogMessage.defaultProps = {
    showIcon: true,
    showAvatar: false,
};

ActivityLogMessage.propTypes = {
    result: PropTypes.string,
    time: PropTypes.string,
    executorName: PropTypes.string,
    executorGravatarHash: PropTypes.string,
    long: PropTypes.bool,
    showAvatar: PropTypes.bool,
    showIcon: PropTypes.bool,
};

export default ActivityLogMessage;
