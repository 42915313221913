import { buildPageableUrl } from "@/utils/urlUtils";
import { axiosService } from "../../../axios";

export const reportsServices = {
    fetchReports(page, size, sort, filter, accountId) {
        return axiosService.post(
            `${buildPageableUrl(
                "/api/reports/table",
                page,
                size,
                sort,
            )}&accountId=${accountId}`,
            filter,
        );
    },

    executeReport(reportId) {
        return axiosService.get(`/api/reports/${reportId}/execute`);
    },

    get(reportId) {
        return axiosService.get(`/api/reports/${reportId}`);
    },

    save(report) {
        const payload = { ...report, time: report.time.format("HH:mm") };
        return axiosService.post(`/api/reports`, JSON.stringify(payload));
    },

    delete(report) {
        return axiosService.delete(`/api/reports/${report}`);
    },

    fetchServices(accountId) {
        return axiosService.get(`/api/reports/services/${accountId}`);
    },
};
