import { t } from "@/translations";
import { Tag as AntdTag, DatePicker } from "antd";
import PropTypes from "prop-types";
import React from "react";
import defaultFilters from "./DateRangeFilter.filter";
import styles from "./DateRangeFilter.style.less";
import { DATETIME } from "@/components/UpgradeScheduling/date-formats";

const { RangePicker } = DatePicker;

const DateRangeFilter = ({ title, activeFilter, setActiveFilter }) => {
    return (
        <>
            <div className={styles.filterLabel}>{title}:</div>

            {Object.keys(defaultFilters).map(filterName => (
                <AntdTag.CheckableTag
                    key={filterName}
                    checked={
                        defaultFilters[filterName].from === activeFilter.from &&
                        defaultFilters[filterName].to === activeFilter.to
                    }
                    onChange={() => setActiveFilter(defaultFilters[filterName])}
                >
                    {t(`cluster.upgrade.overview.filter.${filterName}`)}
                </AntdTag.CheckableTag>
            ))}

            <RangePicker
                showTime
                size="small"
                format={DATETIME}
                placeholder={[
                    t("processed-files.search.start-time"),
                    t("processed-files.search.end-time"),
                ]}
                allowClear={false}
                value={[activeFilter.from, activeFilter.to]}
                onChange={([from, to]) =>
                    setActiveFilter({
                        from: from.startOf("day"),
                        to: to.endOf("day"),
                    })
                }
            />
        </>
    );
};

DateRangeFilter.propTypes = {
    title: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default DateRangeFilter;
