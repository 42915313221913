import { useDic } from "@/components/Dic/useDic.hook";
import { SidebarAndContentLayout } from "@/components/Layouts";
import { LocationRoutes } from "@/modules/router";
import React from "react";
import { ProductEnhancementSidebar } from "../ProductEnhacementSidebar.component";

export function ProductEnhancementApp() {
    const { productEnhancementAppLocation } = useDic();

    return (
        <SidebarAndContentLayout sidebar={<ProductEnhancementSidebar />}>
            <LocationRoutes locations={productEnhancementAppLocation} />
        </SidebarAndContentLayout>
    );
}
