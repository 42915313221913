import PartitionDashboard from "@/components/CustomerPartitions/PartitionDashboard";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React from "react";

export const PartitionDashboardPage = () => {
    const { partitionId, accountId } = useAccountAppParams();
    return (
        <PartitionDashboard partitionId={partitionId} accountId={accountId} />
    );
};
