import {
    Col,
    Gap,
    H5,
    Input,
    Row,
    Select,
    Text,
} from "@/components/DesignSystem";
import { useConfirmModal } from "@/modules/modal/imperative/useConfirmModal.hook";
import { t, T } from "@/translations";
import cx from "classnames";
import { produce } from "immer";
import get from "lodash/get";
import keyBy from "lodash/keyBy";
import values from "lodash/values";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { conditionalOptionsModal } from "../../../../../apps/marketplaceApp/components/PackageDefinition/MappingOption.modal";
import {
    getAvailableOptions,
    getTypeLabel,
} from "../../../packagesPanel.utils";
import { FE_STEP, ParsedType } from "../../constants";
import PackageDataMappingLayout from "../PackageDataMappingLayout/PackageDataMappingLayout";
import { PackageTable } from "../PackageTable/PackageTable";
// assets
import { defaultPreviewCellProps } from "@/components/Packages/PackageTableDefinitionPanel/components/PackageDataMappingLayout/DataPreviewTable.component";
import styles from "../styles.less";
import { availableOptions, handleRowClassName } from "./priceParemeters.utils";
import { TypeSelect } from "./TypeSelect";

const createSimpleValues = () => {
    return {
        name: { label: "Name", name: "name", value: undefined },
        value: { label: "Value", name: "value", value: undefined },
    };
};

export const PPSimple = ({
    accountId,
    globalState,
    onNext,
    onBack,
    changeState,
    onCancel,
    step,
}) => {
    const { fileInfo: tableExampleData = {}, userInputs = {} } = globalState;
    const confirmModal = useConfirmModal();

    useEffect(() => {
        if (!userInputs.fields) {
            setSimpleValues(createSimpleValues());
        }
    }, []);

    const setSimpleValues = i => {
        userInputs.fields = i;
        changeState({ userInputs });
    };

    const setPPLabel = i => {
        userInputs.label = i;
        changeState({ userInputs });
    };

    const headers = tableExampleData.columns
        ? tableExampleData.columns.map(c => c.name)
        : [];

    const handleSelect = (record, value) =>
        setSimpleValues(
            produce(userInputs.fields, draft => {
                const simple = draft[record.name];
                simple.value = value;
                if (value) {
                    simple.type = tableExampleData.columns.find(
                        column => column.name === value,
                    ).type;
                }
            }),
        );

    const handlePPLabel = label => setPPLabel(label);

    const byValue = keyBy(values(userInputs.fields), "value");

    const createData = () => ({
        valueType: FE_STEP.PP_SIMPLE,
        label: userInputs.label,
        fields: userInputs.fields,
    });

    const handleOnNext = () =>
        conditionalOptionsModal({
            condition: step.showMappingOptions,
            data: createData(),
            accountId,
            onNext,
            onCancel,
            confirmModal,
        });

    const customColumns = ({ name, type }) => {
        const selectedType = byValue[name] && byValue[name].type;
        return {
            ...defaultPreviewCellProps,
            label: createTitle(name, selectedType || type),
            name,
            render: text => {
                return (
                    <div className={cx({ [styles.skipped]: shouldSkip(name) })}>
                        {`${text}`}
                    </div>
                );
            },
        };
    };

    const createTitle = (name, type) => {
        let ppField;

        if (get(userInputs.fields, "name.value") === name) {
            ppField = get(userInputs.fields, "name.label");
        }

        if (get(userInputs.fields, "value.value") === name) {
            ppField = get(userInputs.fields, "value.label");
        }

        return (
            <div className={cx({ [styles.skipped]: shouldSkip(name) })}>
                {name}
                <div className={styles.type}>{getTypeLabel(type)}</div>
                {ppField && <div className={styles.ppField}>{ppField}</div>}
            </div>
        );
    };
    const shouldSkip = name =>
        userInputs?.fields?.name?.value &&
        userInputs?.fields?.value?.value &&
        availableOptions(userInputs?.fields, headers).includes(name);

    const handleType = (record, value) =>
        setSimpleValues(
            produce(userInputs.fields, draft => {
                draft[record.name].type = value;
            }),
        );

    const disableOption = (record, type) => {
        const byName = keyBy(tableExampleData.columns, "name");
        const originalType = byName[record.value];
        const possibleValues = [
            ParsedType.STRING,
            originalType && originalType.type,
        ];
        return !possibleValues.includes(type);
    };

    const columns = [
        {
            name: "pfxFieldName",
            title: () => t("package-data-upload.label.mandatory-fields"),
            render: (text, record) => (
                <Fragment>
                    <H5 className={styles.headingColor}>{record.label}</H5>
                    <Text size="small" className={styles.descriptionColor}>
                        {record.description}
                    </Text>
                </Fragment>
            ),
        },
        {
            name: "valueFromFile",
            width: "30%",
            title: () => (
                <span className={styles.descriptionColor}>
                    <FormattedMessage
                        id="package-data-upload.label.file-fields"
                        values={{
                            fileName: tableExampleData.fileName,
                        }}
                    />
                </span>
            ),
            render: (text, record) => (
                <Select
                    placeholder="Choose field..."
                    showSearch
                    allowClear
                    onChange={value => {
                        handleSelect(record, value);
                    }}
                    className={styles.itemSelect}
                    defaultValue={record.value}
                >
                    {getAvailableOptions(
                        record,
                        tableExampleData.columns,
                        userInputs.fields,
                    )}
                </Select>
            ),
        },
        {
            name: "types",
            width: "30%",
            title: () => (
                <span className={styles.descriptionColor}>
                    <T id="package-label.extension-otp-fields.column-type" />
                </span>
            ),
            render: (text, record) => (
                <TypeSelect
                    record={record}
                    disableOption={disableOption}
                    handleType={handleType}
                    disabled={record.skip || !record.value}
                />
            ),
        },
    ];
    return (
        <PackageDataMappingLayout
            dataExample={tableExampleData}
            disableNextButton={
                !userInputs.fields?.name?.value ||
                !userInputs.fields?.value?.value
            }
            customColumns={customColumns}
            onNext={handleOnNext}
            onBack={onBack}
            onCancel={onCancel}
        >
            <T id="package-data-upload.price-parameters-mapper-message" />
            <Gap size="small" />
            <Row className={styles.extensionInputs}>
                <Col span={6}>
                    <Text size="small">
                        <T id="package-data-upload.pp-simple.input.label" />
                    </Text>
                    <Input
                        placeholder={t(
                            "package-data-upload.pp-simple.input.placeholder",
                        )}
                        onChange={e => handlePPLabel(e.target.value)}
                        defaultValue={userInputs.label}
                    />
                </Col>
            </Row>
            <Gap size="medium" />
            <PackageTable
                fields={values(userInputs.fields)}
                handleRowClassName={handleRowClassName}
                columns={columns}
            />
        </PackageDataMappingLayout>
    );
};

PPSimple.propTypes = {
    changeState: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    globalState: PropTypes.object.isRequired,
    step: PropTypes.object.isRequired,
};
