import {
    useComposeLoadablesMemoized,
    useMapLoadableMemoized,
} from "@/modules/loadable";
import { useEffect, useState } from "react";
import { getCheckboxStates } from "../../../../../apps/accountApp/components/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/checkboxStates";
import { nextIndex } from "../../../../../apps/accountApp/components/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/utils";

const mapPermissions = ([
    globalPermissions,
    userGlobalPermissions,
    disabledPermissions,
    readOnlyPermissions = [],
]) => {
    const mappedPermissions = globalPermissions.map(permission => {
        const granted = [
            {
                // array for multiple users
                readOnly: readOnlyPermissions.some(
                    ({ permissionName }) => permissionName === permission.name,
                ),
                editable: userGlobalPermissions.some(
                    ({ permissionName }) => permissionName === permission.name,
                ),
                disabled: disabledPermissions.some(
                    permissionName => permissionName === permission.name,
                ),
            },
        ];
        const getTooltip = (quantifier, grantedByArr) => {
            if (grantedByArr.some(({ disabled }) => disabled)) {
                return `Only power user can edit this permission`;
            } else if (grantedByArr.some(({ readOnly }) => readOnly)) {
                return `Global permission granted by user group permissions`;
            } else {
                return null;
            }
        };
        const checkboxUIStates = getCheckboxStates(granted, getTooltip);
        return { ...permission, states: checkboxUIStates, granted };
    });

    return mappedPermissions;
};

const nextGlobalPermissionChangesState = (changes, permission) => {
    const stateIndex = nextIndex(
        permission.states,
        changes?.[permission.name]?.stateIndex,
    );
    const nextChanges = {
        ...changes,
        [permission.name]: {
            state: permission.states[stateIndex],
            stateIndex,
        },
    };

    return nextChanges;
};

const emptyLoadable = {
    state: "hasValue",
    contents: undefined,
    valueMaybe: () => undefined,
};

export const useGlobalPermissionsUI = (
    permissionsListLoadable,
    editablePermissionsLoadable,
    disabledPermissions,
    readOnlyPermissionsLoadable = emptyLoadable,
) => {
    const [changes, setChanges] = useState({});
    const resetChanges = () => setChanges({});

    const loadablePermissionSources = useComposeLoadablesMemoized([
        permissionsListLoadable,
        editablePermissionsLoadable,
        disabledPermissions,
        readOnlyPermissionsLoadable,
    ]);
    const permissionsLoadable = useMapLoadableMemoized(
        loadablePermissionSources,
        mapPermissions,
    );
    useEffect(() => {
        resetChanges();
    }, [permissionsLoadable]);
    const onChange = permission => {
        const newPermissionChanges = nextGlobalPermissionChangesState(
            changes,
            permission,
        );

        setChanges(newPermissionChanges);
    };
    const isDirty = changes =>
        Object.values(changes).some(({ stateIndex }) => stateIndex !== 0);

    return {
        permissionsLoadable,
        changes,
        dirty: isDirty(changes),
        onChange,
        resetChanges,
    };
};
