import { Collapse, Panel } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { accountsService } from "../../apps/accountApp/services/accounts.service";
import { useSetErrorAlert } from "../PageLayout/useSetAlert.hook";
import { TableLocalFiltered } from "../TableLocalFiltered";

export const createRelatedContactsColumns = width => [
    {
        label: t("related-contacts.header.name"),
        name: "name",
        width,
        type: fieldTypes.TEXT,
        render: (text, record) => record.name,
    },
    {
        label: t("related-contacts.header.title"),
        name: "title",
        width,
        type: fieldTypes.TEXT,
        render: (text, record) => record.title,
    },
    {
        label: t("related-contacts.header.phone"),
        name: "phone",
        width,
        type: fieldTypes.TEXT,
        render: (text, record) => record.phone,
    },
    {
        label: t("related-contacts.header.email"),
        name: "email",
        width,
        type: fieldTypes.TEXT,
        render: (text, record) => record.email,
    },
];

export const RelatedContacts = ({ accountId }) => {
    const [contacts, setContacts] = useState([]);
    const setErrorAlert = useSetErrorAlert();

    const columns = useMemo(() => createRelatedContactsColumns(), []);

    useEffect(() => {
        accountsService
            .fetchRelatedContact(accountId)
            .then(res => setContacts(res.data.filter(({ visible }) => visible)))
            .catch(e => setErrorAlert(getErrorMessage(e.response.data)));
    }, [accountId]);

    return (
        <>
            {!isEmpty(contacts) && (
                <Collapse
                    className="related-contacts"
                    defaultActiveKey={["1"]}
                    bordered={false}
                >
                    <Panel
                        header={t("related-contacts.drop-down.text")}
                        key="1"
                    >
                        <TableLocalFiltered
                            fixed
                            fixedHeight={320}
                            rowKey="id"
                            columns={columns}
                            dataSource={contacts}
                            pagination={{
                                pageSize: 10,
                                pageSizeOptions: [
                                    "10",
                                    "30",
                                    "50",
                                    "100",
                                    "150",
                                    "200",
                                    "300",
                                ],
                            }}
                        />
                    </Panel>
                </Collapse>
            )}
        </>
    );
};

RelatedContacts.propTypes = {
    accountId: PropTypes.number.isRequired,
};
