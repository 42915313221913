import { axiosService } from "../axios";

export const actuatorsService = {
    fetchLoggers() {
        return axiosService.get(`/actuator/loggers`);
    },

    setLoggerLevel(component, level) {
        return axiosService.post(
            `actuator/loggers/${component}`,
            JSON.stringify({ configuredLevel: level }),
        );
    },
};
