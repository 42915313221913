import {
    IM_VERSION_WITH_CONNECTION_DELETION_AFTER_DEPLOY_SUPPORT,
    IM_VERSION_WITH_OBJECT_DELETION_AFTER_DEPLOY_SUPPORT,
} from "@/components/Integrations/constants";
import { useMapLoadableMemoized } from "@/modules/loadable";
import { useLoadableAlert } from "@/modules/loadable/useLoadableAlert.hook";
import { t } from "@/translations";
import { isSameOrNewer } from "@/utils/versionUtils";
import { useCallback } from "react";

export const useIMVersionComplyLoadable = ({ instanceQuery, minVersion }) => {
    return useMapLoadableMemoized(
        instanceQuery.loadable,
        useCallback(
            data => {
                const comply = isSameOrNewer(data?.imVersion, minVersion);
                return { comply, imVersion: data?.imVersion, minVersion };
            },
            [minVersion],
        ),
    );
};

export const useWorkspaceInstanceDeletionAlert = ({
    instanceQuery,
    visible = true,
    disableError,
    isConnection,
}) => {
    const minVersion = isConnection
        ? IM_VERSION_WITH_CONNECTION_DELETION_AFTER_DEPLOY_SUPPORT
        : IM_VERSION_WITH_OBJECT_DELETION_AFTER_DEPLOY_SUPPORT;

    return useLoadableAlert({
        loadable: instanceQuery,
        hasValue: useCallback(
            ({ imVersion }) => ({
                visible: !!visible && !isSameOrNewer(imVersion, minVersion),
                type: "info",
                message: t("im-instance.version-alert.delete-after-deploy", {
                    currentVersion: imVersion,
                    minVersion,
                }),
                _persistOnLocationChange: true, // location transition (tab param change) happens after tab visibility change (alert hidden without this), TODO: investigate
            }),
            [minVersion, visible],
        ),
        hasError: e => ({
            visible: !disableError,
            type: "error",
            message: t("im-instance.version-alert.fetch-instance-error"),
            _persistOnLocationChange: true,
        }),
    });
};
