import qs from "qs";
import { axiosService } from "../axios";

export const createInstanceService = {
    fetchEnvironments() {
        return axiosService.get(`/api/instances/environments`);
    },
    fetchMem() {
        return axiosService.get(`/api/instances/mem`);
    },
    fetchCpu() {
        return axiosService.get(`/api/instances/cpu`);
    },
    fetchProfiles() {
        return axiosService.get(`/api/instances/profiles`);
    },
    fetchProviders({ accountId }) {
        const query = qs.stringify({ accountId });
        return axiosService.get(`/api/instances/cloud-providers?${query}`);
    },
    fetchStorages() {
        return axiosService.get(`/api/instances/storages`);
    },
    fetchDefaultValues() {
        return axiosService.get(`/api/instances/default-values`);
    },
};
