import { Tag } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import * as React from "react";

const mapStatusToColor = status => {
    switch (status) {
        case "Not Used":
            return "green";
        case "Planned Upgrade":
            return "blue";
        default:
            return "grey";
    }
};
const SlotStatus = ({ children, small = false }) => {
    const color = mapStatusToColor(children);
    return (
        <Tag color={color} size={small ? "small" : undefined}>
            {children}
        </Tag>
    );
};

SlotStatus.propTypes = {
    children: PropTypes.string.isRequired,
    small: PropTypes.bool,
};

export default SlotStatus;
