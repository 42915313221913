import { Link, Text } from "@/components/DesignSystem";
import { t } from "@/translations";
import React from "react";
import EmptyBox from "../../../assets/icons/empty-box.svg";
import styles from "./NoTimeslots.style.less";

export const NoTimeslots = () => {
    return (
        <div className={styles.wrapper}>
            <img src={EmptyBox} className={styles.image} />
            <Text className={styles.title}>
                <b>
                    {t("cluster.upgrade.no-timeslots.title")}
                    <br />
                    {t("cluster.upgrade.no-timeslots.subtitle")}
                </b>
            </Text>
            <Text>
                {t("cluster.upgrade.no-timeslots.content", {
                    link: (
                        <Link
                            targetBlank
                            href="https://support.pricefx.eu/helpdesk/"
                        >
                            Helpdesk
                        </Link>
                    ),
                })}
            </Text>
        </div>
    );
};
