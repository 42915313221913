import { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
    cleanButtonData,
    setButtonData,
} from "../BreadcrumbButton/duck/actions";

export function useBreadcrumbButton(buttonData, effectDeps = []) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!buttonData) {
            dispatch(cleanButtonData());
        } else {
            const { label, onClick, permissions, ...config } = buttonData;
            dispatch(setButtonData(label, onClick, permissions, config));
        }

        return () => dispatch(cleanButtonData());
    }, effectDeps);
}
