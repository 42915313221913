import { useDic } from "@/components/Dic/useDic.hook";
import { TagsMenu } from "@/components/Marketplace/components/TagsMenu/TagsMenu.component";
import {
    LocationMenuContainer,
    LocationMenuItem,
    LocationSubMenu,
} from "@/components/Menu";
import { SidebarAccountSelect } from "@/components/Sidebar/SidebarAccountSelect/SidebarAccountSelect.component";
import { useMarketplaceAppParams } from "@/modules/router/hooks/useMarketplaceAppParams.hook";
import React from "react";
import { useSelector } from "react-redux";
import { useRoute } from "react-router5";

export const MarketplaceAppSidebar = () => {
    const {
        locationRouterService,
        marketplaceAppLocations: {
            deployedTemplatesLocation,
            failedDeploymentsLocation,
            offersManagementLocation,
            templatesManagementLocation,
            marketplaceLocation,
        },
    } = useDic();

    const { route } = useRoute();
    const { accountId, tag } = useMarketplaceAppParams();
    const isPackageRunning = useSelector(
        state => state.deployPackage.isPackageRunning,
    );

    return (
        <>
            <SidebarAccountSelect disabled={isPackageRunning} />
            <LocationMenuContainer mode="inline" condensed eager>
                <LocationMenuItem
                    key={marketplaceLocation.routeName}
                    $location={marketplaceLocation}
                />
                <LocationSubMenu
                    key={templatesManagementLocation.routeName}
                    $location={templatesManagementLocation}
                >
                    <LocationMenuItem
                        key={deployedTemplatesLocation.routeName}
                        $location={deployedTemplatesLocation}
                    />
                    <LocationMenuItem
                        key={failedDeploymentsLocation.routeName}
                        $location={failedDeploymentsLocation}
                    />
                </LocationSubMenu>
                <LocationMenuItem
                    key={offersManagementLocation.routeName}
                    $location={offersManagementLocation}
                />
                {route.name === marketplaceLocation.routeName && (
                    <TagsMenu
                        tag={tag}
                        onTagClick={tag => {
                            locationRouterService.navigate(
                                marketplaceLocation,
                                {
                                    accountId,
                                    tag,
                                    filter: undefined,
                                    page: undefined,
                                },
                            );
                        }}
                    />
                )}
            </LocationMenuContainer>
        </>
    );
};
