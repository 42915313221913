import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { DownloadForm } from "@/components/PartitionDataManagement/Downloads/DownloadForm";
import { PDM_TABS } from "@/components/PartitionDataManagement/PartitionDataManagementPage";
import {
    useCreateDownloadMutation,
    useTriggerDownloadMutation,
} from "@/components/PartitionDataManagement/loadables";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React, { useCallback } from "react";

export const NewDownloadPage = () => {
    const { accountId, partitionId } = useAccountAppParams();
    const {
        locationRouterService,
        accountAppLocations: { partitionDataManagementLocation },
    } = useDic();
    const onCancel = useCallback(
        () =>
            locationRouterService.navigate(partitionDataManagementLocation, {
                tab: PDM_TABS.DOWNLOADS,
            }),
        [],
    );
    useSetBackButtonAction(onCancel);
    const saveMutation = useCreateDownloadMutation({
        accountId,
        partitionId,
        afterSuccess: onCancel,
    });
    const triggerMutation = useTriggerDownloadMutation({
        accountId,
        partitionId,
    });
    const onExport = useCallback(
        async payload => {
            const { id } = await saveMutation.mutate(payload);
            await triggerMutation.mutate({ id });
        },
        [saveMutation, triggerMutation],
    );

    return (
        <DownloadForm
            accountId={accountId}
            partitionId={partitionId}
            initialValues={undefined}
            onSave={saveMutation.mutate}
            onExport={onExport}
            onCancel={onCancel}
        />
    );
};
