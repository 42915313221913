import { useDic } from "@/components/Dic/useDic.hook";
import { useQueryLoadable, waitForValue } from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useConvertersQuery = ({ canFetch }) => {
    const { convertersService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? waitForValue()
                : convertersService.fetchDataLoadConverters().then(getData),
        [canFetch, convertersService],
    );
};
