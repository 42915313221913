import { contentLayoutsEnum } from "@/components/ContentLayout/contentLayouts.enum";
import { Forms } from "@/components/DesignSystem";
import { hooks } from "@/components/DesignSystem/Table/Table.component";
import { AddPartitionModal } from "@/components/FeatureFlags/AddPartitionModal";
import { ChangeSetModal } from "@/components/FeatureFlags/ChangeSetModal";
import DisableSubmitOnEnter from "@/components/FeatureFlags/components/DisableSubmitOnEnter.component";
import { PartitionsWithFlagPanel } from "@/components/FeatureFlags/components/PartitionsWithFlag.panel";
import { ConfirmLeave } from "@/components/FeatureFlags/ConfirmLeave";
import { FF_ROW_HEIGHT } from "@/components/FeatureFlags/constants";
import { CopyModal } from "@/components/FeatureFlags/CopyModal";
import { getFieldByType } from "@/components/FeatureFlags/FeatureFlagValue";
import {
    useApplyAdminFeatureFlagsMutation,
    useApplyPartitionFeatureFlagsMutation,
} from "@/components/FeatureFlags/loadables";
import {
    STATUS,
    useFeatureFlags,
} from "@/components/FeatureFlags/useFeatureFlags.hook";
import { useBreadcrumbButtons } from "@/components/hooks/useBreadcrumbButtons.hook";
import { useContentLayout } from "@/components/hooks/useContentLayout.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useAlertEffect } from "@/components/PageLayout/useAlertEffect.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import { isLoading } from "@/modules/loadable";
import { useConfirmModal, useCustomModal } from "@/modules/modal";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { filter, get, map, pipe } from "lodash/fp";
import React, { Suspense, useCallback } from "react";
import "./AdminFeatureFlagsPage.style.less";
import { toName } from "./utils";

const getValue = (params, item, values) => {
    const name = toName({ ...params, path: item.path });
    const value = get(name, values);
    if (!Object.prototype.hasOwnProperty.call(values, name))
        console.log({
            name,
            value,
            hasValue: Object.prototype.hasOwnProperty.call(values, name),
            item,
            values,
        });
    if (!Object.prototype.hasOwnProperty.call(values, name)) return item.value; // TODO: remove when new Forms will suppport UNSTABLE_preserve

    return value;
};

const FeatureFlagsInner = ({
    fetcher,
    getBreadcrumbButtons,
    getEmptyScreenComponent,
    displaySingleSet,
    restHeight,
}) => {
    const {
        addFields,
        formId,
        reset,
        setValues,
        submit,
        setInitialValues,
        getBag,
    } = Forms.useForm({
        onSubmit: async bag => {
            const { values } = bag;
            const data = pipe(
                filter(({ status }) => status === STATUS.SUCCESS),
                map(({ params, data }) => ({
                    params,
                    data: data.map(item => ({
                        ...item,
                        value: getValue(params, item, values),
                    })),
                })),
            )(featureFlags.resources);

            return displaySingleSet
                ? applyPartitionFeatureFlagsMutation.mutate(data)
                : applyAdminFeatureFlagsMutation.mutate(data);
        },
    });
    const featureFlags = useFeatureFlags({
        onNewValues: ({ params, data }) => {
            const entries = data.map(record => [
                toName({ ...params, path: record.path }),
                getFieldByType(record.type).from(record.value),
            ]);
            const fieldIds = entries.map(([id]) => id);
            const initialValues = Object.fromEntries(entries);

            addFields?.(fieldIds);
            setInitialValues(initialValues);
            setValues(initialValues);
        },
        fetcher,
        displaySingleSet,
    });

    const errorredResource = featureFlags.resources.findLast(
        ({ error }) => error,
    );
    useAlertEffect(errorredResource?.error, () => ({
        type: "error",
        message: `${errorredResource.params.partitionName}: ${getErrorMessage(
            errorredResource?.error.response.data,
        )}`,
    }));

    const firstResourceConfigurationName =
        featureFlags.resources[0]?.params?.configurationName;
    const singleSetPageTitle = firstResourceConfigurationName
        ? t("feature-flags.partition.page-title", {
              set: firstResourceConfigurationName,
          })
        : t("feature-flags.page-title");
    useSetPageTitle(
        displaySingleSet ? singleSetPageTitle : t("feature-flags.page-title"),
    );

    const applyAdminFeatureFlagsMutation = useApplyAdminFeatureFlagsMutation({
        onSuccess: () => {
            reset();
            featureFlags.reload();
        },
    });
    const applyPartitionFeatureFlagsMutation =
        useApplyPartitionFeatureFlagsMutation({
            onSuccess: () => {
                reset();
                featureFlags.reload();
            },
        });
    const handleApplyBeforeLeave = useCallback(() => {
        const shouldBePromise = submit(); // TODO: wait for completion?
        return shouldBePromise;
    }, [submit]);

    const customModal = useCustomModal();
    const onAddPartition = useCallback(
        () =>
            customModal.show(
                <AddPartitionModal onAdd={featureFlags.handleAdd} />,
            ),
        [featureFlags.handleAdd, customModal],
    );
    const onChangeSet = useCallback(
        () =>
            customModal.show(<ChangeSetModal onAdd={featureFlags.handleAdd} />),
        [featureFlags.handleAdd, customModal],
    );

    const rowSelection = hooks.useRowSelection({
        disableOnRowClick: true,
    });

    const firstSetData = featureFlags.resources[0]?.data;

    const confirmModal = useConfirmModal();

    const showUnsavedConfirm = useCallback(
        ({ onConfirm }) =>
            confirmModal.confirm({
                title: t("feature-flags.unsaved-warning.confirm.title"),
                message: t("feature-flags.unsaved-warning.confirm.message"),
                width: 588,
                okText: t("general.apply"),
                cancelText: t("general.cancel"),
                onConfirm: () => {
                    onConfirm();
                },
            }),
        [confirmModal],
    );

    const getBagCurrent = useCurrentHandler(getBag);
    const onCopy = useCallback(
        async ({ selectedRowKeys }) => {
            const { dirty } = await getBagCurrent();
            const data = selectedRowKeys
                ? firstSetData.filter(
                      ({ path, value }) =>
                          value !== null && selectedRowKeys.includes(path),
                  )
                : firstSetData;
            const showCopyModal = () =>
                customModal.show(
                    <CopyModal data={data} copySelected={!!selectedRowKeys} />,
                );
            if (dirty)
                showUnsavedConfirm({ onConfirm: handleApplyBeforeLeave });
            else showCopyModal();
        },
        [
            getBagCurrent,
            firstSetData,
            showUnsavedConfirm,
            handleApplyBeforeLeave,
            customModal,
        ],
    );

    const loading =
        isLoading(applyAdminFeatureFlagsMutation) ||
        isLoading(applyPartitionFeatureFlagsMutation) ||
        featureFlags.loading;

    useBreadcrumbButtons(
        () =>
            getBreadcrumbButtons({
                loading,
                submit,
                areFeatureFlagsLoaded: !!featureFlags.columns.length,
                onAddPartition,
                onChangeSet,
                onCopy,
            }),
        [
            onAddPartition,
            onChangeSet,
            submit,
            featureFlags.columns,
            loading,
            getBreadcrumbButtons,
            onCopy,
        ],
    );

    const featureFlagsColumns = featureFlags.columns.length;

    useContentLayout(
        !featureFlagsColumns
            ? contentLayoutsEnum.centered
            : contentLayoutsEnum.fullWidthTable,
    );

    if (!featureFlagsColumns) {
        return getEmptyScreenComponent({ onAddPartition, onChangeSet });
    }

    const additionalTableProps = displaySingleSet
        ? {
              rowSelection: {
                  ...rowSelection,
                  getCheckboxProps: record => ({
                      disabled: !!record.children,
                  }),
              },
              selectionContextMenu: [
                  {
                      label: t("general.copy-selected"),
                      onClick: () =>
                          onCopy({
                              selectedRowKeys: rowSelection.selectedRowKeys,
                          }),
                  },
              ],
          }
        : {};

    return (
        <>
            <Forms.Form formId={formId}>
                <ConfirmLeave
                    formId={formId}
                    onConfirm={handleApplyBeforeLeave}
                />
                <DisableSubmitOnEnter>
                    <TableLocalFiltered
                        fixed
                        restHeight={restHeight}
                        padding
                        rowKey="path"
                        dataSource={featureFlags.dataSource}
                        columns={featureFlags.columns}
                        loading={loading}
                        rowHeight={FF_ROW_HEIGHT}
                        className="pmFeatureFlags-table"
                        pagination={false}
                        {...additionalTableProps}
                    />
                </DisableSubmitOnEnter>
            </Forms.Form>
            {featureFlags.selectedFeatureFlag && (
                <PartitionsWithFlagPanel
                    selectedFeatureFlag={featureFlags.selectedFeatureFlag}
                    visible
                    onUpdate={updatedPartitions => {
                        featureFlags.resources.map(resource => {
                            if (
                                updatedPartitions.includes(
                                    resource.params.partitionId,
                                )
                            ) {
                                featureFlags.reloadResource(resource);
                            }
                        });
                        featureFlags.setSelectedFeatureFlag(null);
                    }}
                    onClose={() => featureFlags.setSelectedFeatureFlag(null)}
                />
            )}
        </>
    );
};

FeatureFlagsInner.propTypes = {};

const FeatureFlagsInnerMemo = React.memo(FeatureFlagsInner);

export const FeatureFlags = React.memo(props => (
    <Suspense fallback={null}>
        <FeatureFlagsInnerMemo {...props} />
    </Suspense>
));
