import {
    Button,
    ButtonGroup,
    Form,
    Gap,
    Option,
    Select,
    Table,
    Text,
} from "@/components/DesignSystem";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { T, t } from "@/translations";
import { ReactComponent as Trash } from "@pricefx/unity-components/src/icons/unicons/trash-alt.svg";
import { produce } from "immer";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import uuid from "uuid/v4";
import { StepUiHeaders } from "../TableComponents/Skip";
import { filterColumns } from "./filterColumns";
import { simulationColumns } from "./simulationColumns";

const createOption = field => (
    <Option title={field.name} key={field.name} value={field.name}>
        {field.name}
    </Option>
);

const crateGroupBy = (record, sourceField) => ({
    id: uuid(),
    alias: record.label,
    expression: record.name,
    name: record.name,
    sourceField,
});

const SUM_EXPRESSION = "SUM";
const DATE = "DATE";
export const DELETE_OP = "delete";

const getSourceFields = (sources, sourceName) =>
    sources.find(s => s.uniqueName === sourceName)?.fields;

const getSourceFieldsNames = (sources, sourceName) =>
    getSourceFields(sources, sourceName)?.map(f => f.name);

export const SetupSimulation = ({
    step,
    onNext,
    onBack,
    onCancel,
    globalState,
}) => {
    const {
        sources,
        defaultSource,
        groupBy,
        measures,
        requiredTypes,
        criteria,
    } = step;

    const initialState = !isEmpty(globalState)
        ? globalState
        : {
              groupBy: groupBy?.map(g => ({
                  ...g,
                  id: uuid(),
                  sourceField: g.name,
                  requiredType: requiredTypes?.find(rt => rt.name === g.name)
                      ?.type,
                  name: undefined,
              })),
              measures: measures?.map(m => ({
                  ...m,
                  id: uuid(),
                  sourceField: m.name,
                  name: undefined,
              })),
              sourceName: defaultSource,
              criteria: criteria.map(c => ({
                  id: uuid(),
                  ...c,
                  originalFieldName: c.fieldName,
              })),
          };
    const [state, setState] = useState(initialState);

    const handleOnNext = () => {
        onNext(state);
    };
    const getSource = () =>
        sources.find(s => s.uniqueName === state.sourceName);

    const onSourceSelect = sourceName => {
        const resetGroupBy = state.groupBy.map(g => ({
            ...g,
            name: undefined,
        }));

        const resetMeasures = state.measures.map(m => ({
            ...m,
            name: undefined,
        }));

        const criteria = state.criteria.map(c => ({
            ...c,
            fieldName: undefined,
        }));

        setState({
            ...state,
            sourceName,
            groupBy: resetGroupBy,
            measures: resetMeasures,
            criteria,
        });
    };

    const onGroupBySelect = (record, selected) => {
        let selectedField = getSource().fields.find(f => f.name === selected);
        const editedGroupBy = state.groupBy.map(f => {
            if (f.id === record.id) {
                return crateGroupBy(selectedField, record.sourceField);
            }
            return f;
        });
        setState({ ...state, groupBy: editedGroupBy });
    };

    const onGroupByLabelChange = (record, value) => {
        let editedGroupBy = state.groupBy.map(f =>
            f.id === record.id ? { ...f, alias: value } : f,
        );

        setState({ ...state, groupBy: editedGroupBy });
    };

    const onMeasuresSelect = (record, selected) => {
        const selectedField = getSource().fields.find(f => f.name === selected);
        const editedMeasures = state.measures.map(f => {
            if (f.id === record.id) {
                return {
                    ...f,
                    id: uuid(),
                    alias: selectedField.label,
                    name: selectedField.name,
                    label: f.label.replace(f.alias, selectedField.label),
                    parameters: {
                        ...f.parameters,
                        field: selectedField.name,
                    },
                };
            }
            return f;
        });
        setState({ ...state, measures: editedMeasures });
    };

    const onCriteriaSelect = (record, fieldName) => {
        let criteria = state.criteria.map(c => {
            if (c.originalFieldName === record.originalFieldName) {
                return { ...c, fieldName };
            }
            return c;
        });

        setState({ ...state, criteria });
    };

    const onCriteriaValue = (record, value) => {
        let criteria = state.criteria.map(c => {
            if (c.originalFieldName === record.originalFieldName) {
                return { ...c, value };
            }
            return c;
        });

        setState({ ...state, criteria });
    };

    const onMeasureLabelChange = (record, value) => {
        let editedMeasures = state.measures.map(f =>
            f.id === record.id
                ? { ...f, label: f.label.replace(f.alias, value), alias: value }
                : f,
        );

        setState({ ...state, measures: editedMeasures });
    };

    const handleAddGroupBy = () => {
        const editedGroupBy = produce(state.groupBy, draft => {
            draft.push({ id: uuid(), name: undefined });
        });

        setState({ ...state, groupBy: editedGroupBy });
    };

    const generateOptions = (additionalConditionFunc, record) => {
        const groupByNames = state.groupBy.map(f => f.name);
        const measuresNames = state.measures.map(f =>
            f?.name?.replace(/^.+_/, ""),
        );

        const ret = getSource()
            ?.fields?.filter(
                f =>
                    !groupByNames.includes(f.name) &&
                    !measuresNames.includes(f.name) &&
                    additionalConditionFunc(f, record),
            )
            ?.map(field => createOption(field));

        return ret;
    };

    const isApplicableMeasures = (field, record) =>
        record?.expression?.startsWith(SUM_EXPRESSION) ? field.numeric : true;

    const isApplicableGroupBy = (field, record) => {
        if (record.requiredType === DATE) {
            return field.dimension && field.type === DATE;
        }

        return field.dimension && field.type !== DATE;
    };

    const handleRemoveGroupBy = record => {
        const editedGroupBy = produce(state.groupBy, draft => {
            draft = draft.filter(f => f.id !== record.id);
            return draft;
        });

        setState({ ...state, groupBy: editedGroupBy });
    };

    const generateDeleteButton = record => {
        return (
            !record.sourceField && (
                <Button
                    style={{ marginLeft: 8 }}
                    onClick={() => handleRemoveGroupBy(record)}
                    icon={Trash}
                />
            )
        );
    };

    const isValid = () => {
        let groupByValid = state.groupBy.filter(f => !f.alias || !f.name);
        let measuresValid = state.measures.filter(f => !f.alias || !f.name);

        let criteriaFieldsNames = state.criteria?.map(f => f.fieldName) || [];
        let criteriaValid =
            isEmpty(state.criteria) ||
            criteriaFieldsNames.every(criteriaName =>
                getSourceFieldsNames(sources, state.sourceName)?.includes(
                    criteriaName,
                ),
            );

        return isEmpty(groupByValid) && isEmpty(measuresValid) && criteriaValid;
    };

    const onFieldSelectClear = (record, operation) => {
        if (DELETE_OP !== operation) {
            return;
        }

        const editedGroupBy = state.groupBy.map(f =>
            f.id === record.id ? { ...f, name: undefined } : f,
        );

        setState({
            ...state,
            groupBy: editedGroupBy,
        });
    };

    const onMeasuresClear = (record, operation) => {
        if (DELETE_OP !== operation) {
            return;
        }

        const measures = state.measures.map(f =>
            f.id === record.id ? { ...f, name: undefined } : f,
        );

        setState({
            ...state,
            measures,
        });
    };

    const onCriteriaClear = (record, operation) => {
        if (DELETE_OP !== operation) {
            return;
        }

        const criteria = state.criteria.map(c =>
            c.fieldName === record.fieldName
                ? { ...c, fieldName: undefined }
                : c,
        );

        setState({
            ...state,
            criteria,
        });
    };

    const onOperatorSelect = (record, operator) => {
        const newCriteria = state.criteria.map(c => ({
            ...c,
            operator:
                c.originalFieldName === record.originalFieldName
                    ? operator
                    : c.operator,
            value:
                c.originalFieldName === record.originalFieldName &&
                (operator === "isNull" || operator === "notNull")
                    ? ""
                    : c.value,
        }));
        setState({ ...state, criteria: newCriteria });
    };

    return (
        <>
            <StepUiHeaders ui={step.ui} />
            <Gap />
            <Text>{t("packages.simulation.select.sources")}</Text>
            <Form.Item>
                <Select
                    showSearch
                    style={{ width: "300px" }}
                    defaultValue={defaultSource}
                    onSelect={onSourceSelect}
                    placeholder
                >
                    {sources.map(source => (
                        <Option
                            title={source.label}
                            key={source.label}
                            value={source.uniqueName}
                        >
                            {source.label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Table
                rowKey={"id"}
                title={() => t("packages.simulation.table.title.group-by")}
                className="pmTable--condensed pmTable--tallCells"
                rowHeight={45}
                columns={simulationColumns(
                    record => generateOptions(isApplicableGroupBy, record),
                    onGroupBySelect,
                    generateDeleteButton,
                    onGroupByLabelChange,
                    onFieldSelectClear,
                )}
                dataSource={state.groupBy}
                paging={false}
            />
            <ButtonGroup buttonsPosition="center">
                <Button
                    onClick={handleAddGroupBy}
                    size="large"
                    label={t("packages.simulation.button.tooltip.add-field")}
                />
            </ButtonGroup>
            <Table
                rowKey={"id"}
                title={() => t("packages.simulation.table.title.measures")}
                className="pmTable--condensed pmTable--tallCells"
                rowHeight={45}
                columns={simulationColumns(
                    record => generateOptions(isApplicableMeasures, record),
                    onMeasuresSelect,
                    () => {},
                    onMeasureLabelChange,
                    onMeasuresClear,
                )}
                dataSource={state.measures}
                paging={false}
            />
            {!isEmpty(criteria) && (
                <Table
                    rowKey={"id"}
                    title={() => t("packages.simulation.table.title.filters")}
                    className="pmTable--condensed pmTable--tallCells"
                    rowHeight={45}
                    columns={filterColumns(
                        getSourceFields(sources, state.sourceName),
                        onCriteriaSelect,
                        onCriteriaValue,
                        onCriteriaClear,
                        onOperatorSelect,
                    )}
                    dataSource={state.criteria}
                    paging={false}
                />
            )}
            <ButtonGroup>
                <Button
                    disabled={!isValid()}
                    type="primary"
                    onClick={handleOnNext}
                    label={<T id="general.continue" />}
                />
                <BackButton onBack={onBack} />
                <Button
                    type="text"
                    onClick={onCancel}
                    label={<T id="general.cancel" />}
                />
            </ButtonGroup>
        </>
    );
};

SetupSimulation.propTypes = {
    step: PropTypes.object.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
