import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    translateWithMessage,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";

export const useAccountUsersResource = ({ accountId }) => {
    const { adminApiService } = useDic();
    return useQueryLoadable(
        async () =>
            adminApiService.fetchProjectUsers(accountId).then(r => r.data),
        [accountId, adminApiService],
    );
};

export const useInviteAccountUserResource = ({ accountId, afterSuccess }) => {
    const { adminApiService } = useDic();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async username =>
            adminApiService
                .inviteUser(accountId, username)
                .then(currentAfterSuccess),
        [accountId, adminApiService, currentAfterSuccess],
        "user-admin-settings.command.inviteUser.success",
        "user-admin-settings.command.inviteUser.error",
    );
};

export const useDeleteAccountUserResource = ({ accountId, afterSuccess }) => {
    const { adminApiService } = useDic();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async user =>
            adminApiService
                .deleteUser(accountId, user.id)
                .then(currentAfterSuccess),
        [accountId, adminApiService, currentAfterSuccess],
        "user-admin-settings.command.deleteUser.success",
        translateWithMessage("user-admin-settings.command.deleteUser.error"),
    );
};
