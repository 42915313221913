import { useCallback, useMemo, useState } from "react";

const initialSelectedAssetRoles = () => [];

export const useAssetRolesSelection = afterAssetRoleClick => {
    const [selectedAssetRoles, setSelectedAssetRoles] = useState(
        initialSelectedAssetRoles(),
    );
    const onAssetRoleClick = useCallback(
        record => {
            setSelectedAssetRoles([record]);
            afterAssetRoleClick();
        },
        [afterAssetRoleClick],
    );
    const onAssetRolesSelection = useCallback(
        (selection, allRecords) => {
            const selectedRecords = selection.map(selectedId => {
                const record = allRecords.find(({ id }) => id === selectedId);
                if (!record) {
                    console.error("Missing assetRole", {
                        selection,
                        allRecords,
                    });
                    return {};
                }
                return record;
            });
            setSelectedAssetRoles(selectedRecords);
        },
        [setSelectedAssetRoles],
    );
    const noAssetRolesSelected = !selectedAssetRoles.length;
    const resetAssetRoles = useCallback(
        () => setSelectedAssetRoles(initialSelectedAssetRoles()),
        [],
    );
    const selectedAssetRoleIds = useMemo(
        () => selectedAssetRoles.map(({ id }) => id),
        [selectedAssetRoles],
    );

    return {
        selectedAssetRoles,
        selectedAssetRoleIds,
        onAssetRolesSelection,
        onAssetRoleClick,
        noAssetRolesSelected,
        resetAssetRoles,
    };
};
