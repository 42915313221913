import {
    ButtonMenu,
    Gap,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { finishedWithError } from "../../../../views/Package/package.utils";

const MODAL_WIDTH = 672;

export function UnfinishedInstanceWarningModal({
    visible,
    pending,
    deploymentState,
    onNew,
    onContinue,
    onCancel,
}) {
    return (
        <Modal visible={visible} onClose={onCancel} width={MODAL_WIDTH}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t(
                        "package.import-info-modal.unfinished-deployment.title",
                        {
                            label: deploymentState?.label,
                        },
                    )}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <div>
                        <Text>
                            {t("package.import-info-modal.before-continue", {
                                templateName: (
                                    <b>{deploymentState?.templateName}</b>
                                ),
                            })}
                        </Text>
                        <Gap />
                        <ul style={{ fontSize: "14px", paddingLeft: "24px" }}>
                            <li>
                                {t(
                                    "package.import-info-modal.option.finish-previous",
                                    {
                                        templateName: (
                                            <b>
                                                {deploymentState?.templateName}
                                            </b>
                                        ),
                                    },
                                )}
                            </li>
                            <li>
                                {t(
                                    "package.import-info-modal.option.start-new",
                                )}
                            </li>
                        </ul>
                        <Gap size="large" />
                    </div>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                deploymentState?.currentStep && {
                                    label: t(
                                        finishedWithError(
                                            deploymentState?.processState,
                                        )
                                            ? "package.import-info-modal.restart-failed-step"
                                            : "package.import-info-modal.continue-deployment",
                                    ),
                                    type: "primary",
                                    onClick: onContinue,
                                    loading: pending,
                                },
                                {
                                    label: t(
                                        "package.import-info-modal.start-new-import",
                                    ),
                                    onClick: onNew,
                                    loading: pending,
                                },
                                {
                                    label: t("general.cancel"),
                                    type: "text",
                                    onClick: onCancel,
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
}

UnfinishedInstanceWarningModal.propTypes = {
    deploymentState: PropTypes.shape({
        templateName: PropTypes.string,
        currentStep: PropTypes.string,
        startedBy: PropTypes.string,
        startedAt: PropTypes.string,
    }),
    onNew: PropTypes.func.isRequired,
    onContinue: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    hide: PropTypes.func,
    visible: PropTypes.bool,
};
