import { useTemplatesCategories } from "@/components/Marketplace/components/TagsMenu/loadables";
import React, { createContext, useContext } from "react";

const TemplateCategoriesContext = createContext();

export const TemplateCategoriesProvider = ({ children }) => {
    const templateCategories = useTemplatesCategories();

    return (
        <TemplateCategoriesContext.Provider value={{ templateCategories }}>
            {children}
        </TemplateCategoriesContext.Provider>
    );
};

export const useContextTemplateCategoriesQuery = () => {
    const { templateCategories } = useContext(TemplateCategoriesContext);

    return templateCategories;
};
