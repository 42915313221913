import { AlertsList } from "@/components/Alerts/AlertsList/AlertsList.component";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import React from "react";

export function GlobalAlertsPage() {
    useFullWidthTableLayout();

    return (
        <AlertsList
            displayDetails={false}
            preferencesType={preferencesTypes.ALERTS_LIST_TABLE_GLOBAL}
            showAllAlertOptions={true}
        />
    );
}
