import { useDic } from "@/components/Dic/useDic.hook";
import { useAlertEffect } from "@/components/PageLayout/useAlertEffect.hook";
import { ALERT_TYPE } from "@/components/PageLayout/useSetAlert.hook";
import SSEType from "@/components/ServerSideEvents/types";
import useSSE from "@/components/ServerSideEvents/useSSE.hook";
import { taskStatuses } from "@/components/Tasks/TasksTable";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { STATUSES, hasError, useQueryLoadable } from "@/modules/loadable";
import { maybeError } from "@/modules/loadable/utils";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { useCallback, useMemo, useState } from "react";
import { getIdsByDestination } from "./hooks/useNavigateToPackages.hook";

export const useDeploymentStateQuery = ({
    idle,
    destination,
    destinationId,
    packageName,
    templateVersion,
}) => {
    const { packageService } = useDic();
    const { partitionId, instanceId } = getIdsByDestination(
        destination,
        destinationId,
    );
    const query = useQueryLoadable(
        async () =>
            idle
                ? Promise.resolve(undefined)
                : packageService.getDeploymentState(
                      packageName,
                      partitionId,
                      instanceId,
                      templateVersion,
                  ),
        [
            idle,
            packageService,
            packageName,
            partitionId,
            instanceId,
            templateVersion,
        ],
    );
    useAlertEffect(hasError(query), () => ({
        type: ALERT_TYPE.ERROR,
        message: t("marketplace.error.cantFetchDeployability", {
            error: maybeError(query).response?.data?.message,
        }),
    }));

    return query;
};

export const useCheckDeployability = ({
    idle,
    destination,
    destinationId,
    packageName,
    templateVersion,
}) => {
    const { packageService, messageService } = useDic();

    const { partitionId, instanceId } = getIdsByDestination(
        destination,
        destinationId,
    );

    return useQueryLoadable(
        async () =>
            idle || !templateVersion
                ? Promise.resolve(undefined)
                : packageService
                      .checkDeployability({
                          packageName,
                          templateVersion,
                          partitionId,
                          instanceId,
                      })
                      .catch(e => {
                          messageService.error({
                              content: getErrorMessage(e.response.data),
                          });
                          throw e;
                      }),
        [
            idle,
            packageService,
            packageName,
            templateVersion,
            partitionId,
            instanceId,
            messageService,
        ],
    );
};

export const useStartDeploymentMutation = ({
    templateDeployment,
    afterSuccess,
}) => {
    const { destination, destinationId, template, templateVersion } =
        templateDeployment;
    const { packageService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const [state, setState] = useState({
        isLoading: false,
        initialTask: undefined,
    });
    const [result, setResult] = useState(undefined);
    const [error, setError] = useState();

    useSSE(SSEType.NOTIFICATION, receivedTask => {
        if (!state.initialTask) {
            if (receivedTask.data.status === taskStatuses.STARTED)
                console.log("Maybe missed? ", receivedTask.data.type, {
                    receivedTask,
                });

            return;
        }
        if (
            receivedTask.data.type === state.initialTask?.type &&
            receivedTask.data.status === taskStatuses.STARTED
        ) {
            console.log("Task received", receivedTask.data.type, {
                receivedTask,
            });
            packageService
                .getStartDeploymentState(
                    template.uniqueName,
                    receivedTask.data.id,
                )
                .then(result => {
                    setResult(result);
                    afterSuccessCurrent(templateDeployment);
                });
        }

        if (
            receivedTask.data.type === state.initialTask?.type &&
            receivedTask.data.status === taskStatuses.FAILED
        ) {
            setError(
                receivedTask.data.errorMessage ?? t("deployment.start.failed"),
            );
            setState({ isLoading: STATUSES.hasValue, initialTask: undefined });
        }
    });

    const mutation = useCallback(() => {
        setError();
        setState({ status: STATUSES.loading, initialTask: undefined });
        const { partitionId, instanceId } = getIdsByDestination(
            destination,
            destinationId,
        );
        return packageService
            .startDeployment(
                template.uniqueName,
                partitionId,
                instanceId,
                templateVersion,
            )
            .then(getData)
            .then(initialTask => {
                console.log("Started waiting for: ", initialTask?.type);
                setState({ state: STATUSES.loading, initialTask });
            })
            .catch(e => {
                console.log("caught error", e);
                setError(getErrorMessage(e?.response?.data));
                setState({ state: STATUSES.hasError, initialTask: undefined });
            });
    }, [
        destination,
        destinationId,
        packageService,
        template.uniqueName,
        templateVersion,
    ]);

    return useMemo(
        () => ({
            contents: result,
            state: state.state,
            valueMaybe: () => result,
            mutate: mutation,
            error,
            resetError: () => setError(),
        }),
        [error, mutation, result, state.state],
    );
};
