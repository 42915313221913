import AvatarInitials from "@/components/Avatar/AvatarInitials";
import { UnityMenu } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    isAuthorizedByPermission,
    SecurityContext,
} from "@/security/authorization";
import { useUserBasicInfo } from "@/security/hooks/useUserBasicInfo.hook";
import { PRICEFX_TECH_OPS_PERMISSION } from "@/security/permission.utils";
import { t } from "@/translations";
import { filterVisible } from "@/utils/filterVisible";
import React, { useContext } from "react";
import { locationsMap } from "../../../../locations";
import style from "./HeaderProfile.less";

export const useHeaderProfileMenuItems = () => {
    const {
        locationRouterService,
        locations: { logoutLocation, profileLocation },
    } = useDic();

    const securityContext = useContext(SecurityContext);
    return filterVisible([
        {
            anchor: t("header-dropdown.profile"),
            key: "profile",
            onClick: () => locationRouterService.navigate(profileLocation),
        },
        {
            anchor: t("header-dropdown.my-approvals"),
            key: "my-approvals",
            onClick: () =>
                locationRouterService.navigate(
                    locationsMap.myApprovalsLocation,
                ),
        },
        {
            anchor: t("header-dropdown.notifications"),
            key: "notifications-list",
            onClick: () =>
                locationRouterService.navigate(
                    locationsMap.notificationsLocation,
                ),
        },
        {
            anchor: t("header-dropdown.availability"),
            key: "availability",
            onClick: () =>
                locationRouterService.navigate(locationsMap.capacityLocation),
            visible: isAuthorizedByPermission(
                PRICEFX_TECH_OPS_PERMISSION,
                securityContext.permissions,
            ),
        },
        {
            anchor: t("header-dropdown.logout"),
            key: "logout",
            onClick: () => locationRouterService.navigate(logoutLocation),
            divider: true,
        },
    ]);
};

export const HeaderProfile = () => {
    const basicInfo = useUserBasicInfo();
    const fullName = basicInfo?.userInfo?.user?.fullName;

    return (
        <>
            <UnityMenu.ItemContent
                data-test="user-menu"
                tooltip={{
                    overlayStyle: { paddingRight: 6 },
                    placement: "bottomRight",
                    title: fullName,
                }}
            >
                <AvatarInitials
                    className={style.profileIcon}
                    fullName={fullName}
                />
            </UnityMenu.ItemContent>
        </>
    );
};
