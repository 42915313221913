import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthLayout } from "@/components/hooks/useContentLayout.hook";
import { NonStandardDefinition } from "@/components/IntegrationRoutes/components/NonStandardDefinition.component";
import { IntegrationAdministration } from "@/components/IntegrationRoutes/form/IntegrationAdministration";
import { useRouteDefinitionResource } from "@/components/IntegrationRoutes/routes.loadables";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

export const RouteDefinitionPage = () => {
    const {
        instanceId,
        accountId,
        routeIdOrPrn: routeIdOrPrnEncoded,
        isNonStandardRoute,
        key: objectKey,
    } = useAccountAppParams();

    const routeIdOrPrn = decodeURIComponent(routeIdOrPrnEncoded);
    const {
        locationRouterService,
        accountAppLocations: { integrationsLocation },
    } = useDic();

    useSetPageTitle(
        t("routes-list.definition.title-with-id", { routeId: routeIdOrPrn }),
    );

    const navigateToRoutesList = useCallback(() => {
        locationRouterService.navigate(integrationsLocation, {
            instanceId,
            accountId,
        });
    }, [accountId, instanceId, integrationsLocation, locationRouterService]);

    const routeDefinitionResource = useRouteDefinitionResource(
        instanceId,
        routeIdOrPrn,
    );

    useSetBackButtonAction(navigateToRoutesList);

    useFullWidthLayout();

    return isNonStandardRoute ? (
        <NonStandardDefinition instanceId={instanceId} prn={routeIdOrPrn} />
    ) : (
        <IntegrationAdministration
            projectId={accountId}
            instanceId={instanceId}
            routeId={routeIdOrPrn}
            routeDefinitionResource={routeDefinitionResource}
            objectKey={objectKey}
        />
    );
};

RouteDefinitionPage.propTypes = {
    isNonStandardRoute: PropTypes.bool.isRequired,
};
