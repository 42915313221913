import { EditOutlined } from "@ant-design/icons";
import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import React from "react";
import styles from "./ConverterButton.styles.less";
import { TypeText } from "./TypeText.component";

export const ConverterButton = ({ inputType, converter, className }) => {
    const isValueTypeVisible = !inputType || inputType !== "body";
    return (
        <div
            className={cx(
                "pmConverter",
                {
                    "pmConverter--active": !isEmpty(converter),
                },
                className,
            )}
        >
            {isValueTypeVisible && (
                <TypeText className={styles.valueType}>{inputType}</TypeText>
            )}
            <EditOutlined />
        </div>
    );
};
