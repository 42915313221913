import { UnityLayout } from "@/components/DesignSystem";
import { PanelContainer } from "@/components/DesignSystem/Drawer/PanelContainer";
import { REMOTE_LAYOUT_ID } from "@/components/PageLayout/RemoteLayout";
import "@/components/PageLayout/RemoteLayout.styles.less";
import PropTypes from "prop-types";
import React from "react";
import { ContentLayout } from "../ContentLayout/ContentLayout.component";
import PageLayout from "../PageLayout";
import { Sidebar } from "../Sidebar/Sidebar";

export const SidebarAndContentLayout = ({
    children,
    sidebar,
    showHeader = true,
}) => {
    return (
        <>
            <UnityLayout theme="white" hasSider={!!sidebar}>
                {sidebar && <Sidebar>{sidebar}</Sidebar>}
                <UnityLayout.Content padding={[false, false]}>
                    <div id={REMOTE_LAYOUT_ID}>
                        <UnityLayout theme="white" hasSider={false}>
                            {showHeader && <PageLayout.Header />}
                            <UnityLayout.Content className="pmContent">
                                <PanelContainer>
                                    <ContentLayout>{children}</ContentLayout>
                                </PanelContainer>
                            </UnityLayout.Content>
                        </UnityLayout>
                    </div>
                </UnityLayout.Content>
            </UnityLayout>
        </>
    );
};
SidebarAndContentLayout.propTypes = {
    showHeader: PropTypes.bool,
    children: PropTypes.node,
    sidebar: PropTypes.node,
};
