import qs from "qs";
import { axiosService } from "../axios";

const qsOptions = { addQueryPrefix: true };

// Equals filter.id when updating existing
const getObjectKey = ({ name, instanceId }) =>
    btoa(JSON.stringify({ name, instanceId }));

export const filtersService = {
    fetchFilters(instanceId) {
        return axiosService.get(`/api/instances/${instanceId}/im-filters`);
    },

    copy(filterId, payload) {
        return axiosService.post(`/api/im-filters/${filterId}/copy`, payload);
    },

    createFilter(filter, query) {
        const queryStr = qs.stringify(query, qsOptions);
        return axiosService.post(`/api/im-filters${queryStr}`, filter);
    },

    fetchFilter(filterId) {
        return axiosService.get(`/api/im-filters/${filterId}`);
    },

    updateFilter(filter, query) {
        const { filterId, ...payload } = filter;
        const queryStr = qs.stringify(query, qsOptions);
        return axiosService.post(
            `/api/im-filters/${filterId}${queryStr}`,
            payload,
        );
    },

    deleteFilter(filterId) {
        return axiosService.delete(`/api/im-filters/${filterId}`);
    },

    fetchInstanceRepoFilters(instanceId) {
        return axiosService.get(
            `/api/instances/${instanceId}/im-filters/online`,
        );
    },

    createLocalCopy(id, payload) {
        return axiosService.post(`/api/im-filters/${id}/online-copy`, payload);
    },

    deleteInstanceRepoFilter({ objectKey, prn }) {
        return axiosService.delete(`/api/im-filters/${objectKey}/online`, {
            data: prn ? { prn } : null,
        });
    },

    filterExists({ instanceId, name, withOnline = true }) {
        const objectKey = getObjectKey({ name, instanceId });
        return axiosService.get(
            `/api/im-filters/${objectKey}/exists${qs.stringify(
                { withOnline },
                qsOptions,
            )}`,
        );
    },
};
