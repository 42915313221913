import { useLocationChanged } from "@/components/hooks/useLocation.hook";
import { logger } from "@/modules/logger";
import { filter, reject } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

const logMissing = () => console.log("Missing AlertsContext");

const defaults = {
    alerts: [],
    setAlert: logMissing,
    setAlerts: logMissing,
};

export const AlertsContext = React.createContext(defaults);

export const AlertsContextProvider = ({ children }) => {
    const [alerts, _setAlerts] = useState(defaults.alerts);
    const setAlerts = useCallback((...args) => {
        logger.debug({
            logGroupKey: ["ALERTS", "AlertsContextProvider", "setAlerts"],
            data: { args },
            color: "lime",
        });
        _setAlerts(...args);
    }, []);

    const setAlert = useCallback(
        ({ id, props }) =>
            _setAlerts(alerts => {
                const defaults = {
                    banner: true,
                    type: "error",
                    closable: true,
                    afterClose: () => setAlert({ id, props: undefined }),
                    _persistOnLocationChange: false,
                };
                const isIdMatch = alert => alert.id === id;
                const visible =
                    (props?.visible ?? true) &&
                    (props?.messageId || props?.message);

                let newAlerts;
                if (!visible) newAlerts = reject(isIdMatch, alerts);
                else if (!alerts.some(isIdMatch))
                    newAlerts = [...alerts, { id, ...defaults, ...props }];
                else
                    newAlerts = alerts.map(alert =>
                        isIdMatch(alert)
                            ? { ...alert, ...defaults, ...props }
                            : alert,
                    );

                logger.debug({
                    logGroupKey: [
                        "ALERTS",
                        "AlertsContextProvider",
                        "setAlert",
                    ],
                    data: {
                        alert: { id, props },
                        oldAlerts: alerts,
                        newAlerts,
                    },
                    color: "lime",
                });
                return newAlerts;
            }),
        [],
    );

    logger.debug({
        logGroupKey: ["ALERTS", "AlertsContextProvider", "rndr"],
        data: { alerts },
        color: "lime",
    });

    useLocationChanged(() => setAlerts(filter("_persistOnLocationChange")), []);

    return (
        <AlertsContext.Provider value={{ alerts, setAlerts, setAlert }}>
            {children}
        </AlertsContext.Provider>
    );
};

AlertsContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
