import { useCenteredLayout } from "@/components/hooks/useContentLayout.hook";
import React from "react";
import Tokens from "./Tokens";
import { t } from "@/translations";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";

const TokenPanel = () => {
    useSetPageTitle(t("profile.title.api-tokens"));
    useCenteredLayout();

    return <Tokens />;
};

export default TokenPanel;
