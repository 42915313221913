import { useFavoriteAction } from "@/components/Marketplace/components/FavoriteButton/useFavoriteAction.hook";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./FavoriteButton.styles.less";

export const FavoriteButton = ({
    favoriteId,
    favoriteType,
    onToggle,
    isActive: isActiveParent,
    className = "",
}) => {
    const { FavoriteIcon, toggleFavorite, isActive, title } = useFavoriteAction(
        {
            favoriteId,
            favoriteType,
            onToggle,
            isActive: isActiveParent,
        },
    );
    return (
        <FavoriteIcon
            data-test="favorite-button"
            title={title}
            onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                toggleFavorite();
            }}
            className={classNames({
                pmFavoriteButton: true,
                "pmFavoriteButton--active": isActive,
                [className]: true,
            })}
        />
    );
};

FavoriteButton.propTypes = {
    favoriteId: PropTypes.string.isRequired,
    favoriteType: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    className: PropTypes.string,
    onToggle: PropTypes.func,
};
