export const toKey = ({ projectId, partitionId, configurationName }) =>
    JSON.stringify([projectId, partitionId, configurationName]);

const denestifyPath = path => path.replace(".", "-");

export const toName = ({ projectId, partitionId, configurationName, path }) =>
    JSON.stringify([
        projectId,
        partitionId,
        configurationName,
        denestifyPath(path),
    ]);

export const toApiKey = params => params.partitionId;
