import { UnityMenu } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { usePfxUsersQuery } from "@/components/Header/loadables";
import { PFX_LOGIN } from "@/mixpanel/mixpanel.constants";
import { getData } from "@/services/utils";
import { loginToPFX } from "@/utils/loginToPfx";
import { size } from "lodash/fp";
import map from "lodash/map";
import React from "react";
import pfxSvg from "../../../assets/img/pfx.svg";

export const useHeaderGoToPfxMenuItems = ({ partitions }) => {
    const { mixpanelService, ssoService } = useDic();

    const goToPartition = pfxLogin => {
        mixpanelService.track(PFX_LOGIN, {
            pfxLogin,
        });

        ssoService.getSsoInfo(pfxLogin).then(getData).then(loginToPFX);
    };

    return map(partitions, partition => {
        let partitionId = JSON.stringify(partition);
        return {
            key: partitionId,
            onClick: () => goToPartition(partitionId),
            anchor: `${partition.pfxPartition}
            ${partitions.length > 1 ? "/ " + partition.pfxCluster : ""}`,
        };
    });
};

export const useHeaderItemGoToPfx = () => {
    const pfxUsersResource = usePfxUsersQuery();
    const availablePartitions = pfxUsersResource.loadable.valueMaybe() ?? [];
    const pfxItems = useHeaderGoToPfxMenuItems({
        partitions: availablePartitions,
    });

    return {
        key: "go-to-pfx",
        component: HeaderGoToPFX,
        items: pfxItems,
        visible: size(availablePartitions) > 0,
    };
};

const HeaderGoToPFX = () => (
    <UnityMenu.ItemContent data-test="pfx-menu">
        <img src={pfxSvg} alt="pfx" />
    </UnityMenu.ItemContent>
);

export default HeaderGoToPFX;
