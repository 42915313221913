import { useEffect, useRef } from "react";

export const useLeaveCallback = ({ triggerWhen = true, onLeave }) => {
    const wasTriggered = useRef(false);
    const prevVariable = useRef(window.location.href);

    useEffect(() => {
        const handleLeave = () => {
            if (
                prevVariable.current !== window.location.href &&
                triggerWhen &&
                !wasTriggered.current
            ) {
                wasTriggered.current = true;
                onLeave?.();
            }
        };
        handleLeave();
        return () => handleLeave();
    }, [window.location.href, onLeave, triggerWhen]);

    useEffect(() => {
        const handleLeave = () => {
            if (triggerWhen && !wasTriggered.current) {
                wasTriggered.current = true;
                onLeave?.();
            }
        };

        window.addEventListener("beforeunload", handleLeave);

        return () => {
            window.removeEventListener("beforeunload", handleLeave);
        };
    }, [onLeave, triggerWhen]);
};
