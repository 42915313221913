import { useDic } from "@/components/Dic/useDic.hook";
import { useNotificationsQuery } from "@/components/Header/loadables";
import SSEType from "@/components/ServerSideEvents/types";
import useSSE from "@/components/ServerSideEvents/useSSE.hook";
import { hasValue } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { throttle } from "lodash";
import { size } from "lodash/fp";
import { useCallback, useEffect, useMemo, useRef } from "react";
import {
    atom,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState,
} from "recoil";
import "./HeaderNotifications.style.less";

const DEFAULT_PAGE_SIZE = 10;
const INIT_NOTIFICATION_STATE = [[], false];
const INIT_RESPONSE_STATE = {};
const sort = "createdAt,desc";

const notificationsState = atom({
    key: "notificationsState",
    default: INIT_NOTIFICATION_STATE,
});

export const useNotificationsValue = () => {
    return useRecoilValue(notificationsState);
};

export const useNotificationControls = () => {
    const { notificationsService } = useDic();
    const lastScrollPosition = useRef(0);
    const lastResponse = useRef(INIT_RESPONSE_STATE);
    const [[notifications, isLoading], setNotifications] =
        useRecoilState(notificationsState);

    const getNotifications = useCallback(
        page => {
            setNotifications(([state]) => [state, true]);

            if (lastResponse.current?.pageable?.pageNumber === page) {
                return;
            }

            return notificationsService
                .getNotifications({
                    page,
                    size: DEFAULT_PAGE_SIZE,
                    sort: sort,
                })
                .then(getData)
                .then(response => (lastResponse.current = response))
                .then(({ content: olderNotifications }) => {
                    setNotifications(([notifications]) => [
                        page === 0
                            ? olderNotifications
                            : [...notifications, ...olderNotifications],
                        false,
                    ]);
                });
        },
        [notificationsService, setNotifications],
    );

    useEffect(() => {
        getNotifications(0);
    }, []);

    const isScrollingDown = element =>
        element.scrollTop > lastScrollPosition.current;

    const onScroll = throttle(event => {
        const element = event.target;
        const scrollPosition = element.scrollTop;
        const totalHeight = element.scrollHeight - element.clientHeight;
        const percentage = (scrollPosition / totalHeight) * 100;

        if (!isLoading && isScrollingDown(element) && percentage > 95) {
            getNotifications(
                (lastResponse.current?.pageable?.pageNumber ?? 0) + 1,
            );
        }

        lastScrollPosition.current = scrollPosition;
    }, 500);

    const markAllAsRead = () =>
        notificationsService.markAllAsRead().then(() => {
            setNotifications(INIT_NOTIFICATION_STATE);
            lastResponse.current = INIT_RESPONSE_STATE;
            getNotifications(0);
        });

    const markAsRead = notificationIds => {
        notificationsService.markAsRead(notificationIds).then(() =>
            setNotifications(([notifications]) => [
                notifications.map(notification => ({
                    ...notification,
                    readAt: notificationIds.includes(notification.id)
                        ? new Date().toString()
                        : notification.readAt,
                })),
                false,
            ]),
        );
    };

    return {
        hasNotifications: size(notifications) > 0,
        notifications,
        isLoading,
        onScroll,
        markAllAsRead,
        markAsRead,
    };
};

export const useNotificationListener = () => {
    const setNotifications = useSetRecoilState(notificationsState);

    const initialNotificationsResource = useNotificationsQuery(
        useMemo(
            () => ({
                page: 0,
                size: DEFAULT_PAGE_SIZE,
                sort: sort,
            }),
            [],
        ),
    );

    useEffect(() => {
        if (hasValue(initialNotificationsResource)) {
            const response = initialNotificationsResource.loadable.valueMaybe();
            setNotifications([response.content, false]);
        }
    }, [initialNotificationsResource, setNotifications]);

    useSSE(SSEType.NOTIFICATION, newNotification => {
        setNotifications(([notifications]) => [
            [newNotification, ...notifications],
            false,
        ]);
    });
};
