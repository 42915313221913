import { Link, Text, UnityIcon } from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { ReactComponent as Bulb } from "./bulb.svg";

const DocumentationLink = ({ link }) => {
    return link ? (
        <Text size="small">
            <UnityIcon
                component={Bulb}
                style={{
                    padding: "12px 12px 12px 0px",
                }}
            />
            {t("documentation-link.info", {
                documentationLink: (
                    <Link href={link} targetBlank>
                        {t("general.documentation")}
                    </Link>
                ),
            })}
        </Text>
    ) : null;
};

DocumentationLink.propTypes = {
    link: PropTypes.string,
};

export default DocumentationLink;
