import { Input } from "@/components/DesignSystem";
import { t } from "@/translations";
import { ReactComponent as IconSearch } from "@pricefx/unity-components/src/icons/unicons/search.svg";
import PropTypes from "prop-types";
import React from "react";
import styles from "./MarketplaceSearch.style.less";

export const MarketplaceSearch = ({ id, value, onSearch }) => {
    return (
        <Input
            id={id}
            data-test="marketplace-search"
            placeholder={t("search.placeholder")}
            prefix={<IconSearch className={styles.icon} />}
            onChange={e => onSearch?.(e.target.value)}
            value={value}
            allowClear
            autoFocus
            className={styles.input}
        />
    );
};

MarketplaceSearch.propTypes = {
    id: PropTypes.string,
    onSearch: PropTypes.func,
};
