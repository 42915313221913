import { usePossiblyStaleValue } from "@/components/hooks/usePossiblyStaleValue.hook";
import { hasSubFilter } from "@/constants/SupportedTableImportTypes";
import values from "lodash/values";
import { useMemo, useState } from "react";
import { useEntityNamesQuery } from "../loadables";

/**
 * @deprecated Use useEntitySelectFields instead
 */
export const useObjectTypeSelect = initialValues => {
    let {
        initialSourceType,
        initialSourceId,
        initialEntityType,
        initialEntityName,
    } = usePossiblyStaleValue(initialValues);

    if (!hasSubFilter(initialEntityType) && initialEntityName) {
        console.error("Invalid combination", {
            initialEntityType,
            initialEntityName,
        });
        initialEntityName = undefined;
    }
    const [selected, setSelected] = useState({
        entityType: initialEntityType,
        entityName: initialEntityName,
    });

    const entityNamesResource = useEntityNamesQuery({
        entityType: selected.entityType,
        sourceType: initialSourceType,
        sourceId: initialSourceId,
    });

    const subFilter = hasSubFilter(selected.entityType);

    const handleEntityTypeChange = entityType =>
        setSelected({ entityType, entityName: undefined });
    const handleEntityNameChange = entityName =>
        setSelected(selected => ({ ...selected, entityName }));
    const errors = useMemo(
        () => ({
            entityType: !selected.entityType
                ? "Entity type must not be empty"
                : undefined,
            entityName:
                subFilter && !selected.entityName
                    ? "Entity name must not be empty"
                    : undefined,
        }),
        [selected.entityType, selected.entityName, subFilter],
    );
    const valid = !values(errors).filter(value => value !== undefined).length;

    return {
        entityType: selected.entityType,
        entityName: selected.entityName,
        subFilter,
        handleEntityTypeChange,
        handleEntityNameChange,
        entityNamesResource,
        valid,
        errors,
    };
};
