import { tap } from "@/components/Connections/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { sftpEntityType } from "@/services/dataLoadSftp.service";
import { getData } from "@/services/utils";
import { omit } from "lodash";

export const useSftpServerInfoQuery = ({
    type = sftpEntityType.DATA_UPLOAD,
    dataLoadId,
}) => {
    const { dataLoadSftpService } = useDic();

    return useQueryLoadable(
        () =>
            dataLoadSftpService
                .getSFTPConfig({ type, dataLoadId })
                .then(getData),
        [dataLoadSftpService, type, dataLoadId],
    );
};

export const useSftpServerUsersQuery = ({
    type = sftpEntityType.DATA_UPLOAD,
    dataLoadId,
}) => {
    const { dataLoadSftpService } = useDic();

    return useQueryLoadable(
        () =>
            dataLoadSftpService
                .listSftpUsers({ type, dataLoadId })
                .then(getData),
        [dataLoadId, dataLoadSftpService, type],
    );
};

export const useDeleteSftpUserMutation = ({
    type = sftpEntityType.DATA_UPLOAD,
    dataLoadId,
    afterSuccess,
}) => {
    const { dataLoadSftpService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ username }) =>
            dataLoadSftpService
                .deleteUser({ type, dataLoadId, username })
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [dataLoadSftpService, type, dataLoadId, afterSuccessCurrent],
        "sftp-servers.user-management.deleted",
    );
};

export const useSftpServerQuery = ({
    type = sftpEntityType.DATA_UPLOAD,
    dataLoadId,
}) => {
    const { dataLoadSftpService } = useDic();

    return useQueryLoadable(
        async () =>
            dataLoadSftpService
                .getUserPrefix({ type, dataLoadId })
                .then(getData),
        [dataLoadSftpService, type, dataLoadId],
    );
};

export const useSftpServerUserQuery = ({
    type = sftpEntityType.DATA_UPLOAD,
    dataLoadId,
    userId,
    isNew,
}) => {
    const { dataLoadSftpService } = useDic();

    return useQueryLoadable(
        () =>
            isNew
                ? Promise.resolve({})
                : dataLoadSftpService
                      .getUser({ type, dataLoadId, username: userId })
                      .then(getData),
        [isNew, dataLoadSftpService, type, dataLoadId, userId],
    );
};

export const useSftpServerUserMutation = ({
    type = sftpEntityType.DATA_UPLOAD,
    dataLoadId,
    username,
    afterSuccess,
}) => {
    const { dataLoadSftpService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async values => {
            (username
                ? dataLoadSftpService.updateUser({
                      type,
                      dataLoadId,
                      username,
                      values: omit(values, "username"),
                  })
                : dataLoadSftpService.createUser({ type, dataLoadId, values })
            )
                .then(getData)
                .then(res => {
                    afterSuccessCurrent(res);
                    return res;
                });
        },
        [afterSuccessCurrent, dataLoadId, dataLoadSftpService, type, username],
        username
            ? "sftp-servers.user-management.saved"
            : "sftp-servers.user-management.created",
    );
};
