import qs from "qs";
import { axiosService } from "../axios";

export const SFTP_SERVER_TYPE = {
    CLASSIC: "CLASSIC",
    LDAP: "LDAP",
};

const sftpService = {
    serversList({ instanceId, page, size, sort, filter }) {
        return axiosService.post(
            `/api/instances/${instanceId}/sftp-servers${qs.stringify(
                { page, size, sort },
                { addQueryPrefix: true },
            )}`,
            filter,
        );
    },
    getServer({ serverId }) {
        return axiosService.get(`/api/sftp-servers/${serverId}`);
    },
    startServer({ serverId }) {
        return axiosService.post(`/api/sftp-servers/${serverId}/start`);
    },
    stopServer({ serverId }) {
        return axiosService.post(`/api/sftp-servers/${serverId}/stop`);
    },
    deleteServer({ serverId }) {
        return axiosService.delete(`/api/sftp-servers/${serverId}/delete`);
    },
    listSftpUsers({ serverId }) {
        return axiosService.get(`/api/sftp-servers/${serverId}/users`);
    },
    getUser({ serverId, username }) {
        return axiosService.get(
            `/api/sftp-servers/${serverId}/users/${username}`,
        );
    },
    deleteUser({ serverId, username }) {
        return axiosService.delete(
            `/api/sftp-servers/${serverId}/users/${username}`,
        );
    },
    updateUser({ serverId, username, values }) {
        return axiosService.post(
            `/api/sftp-servers/${serverId}/users/${username}`,
            values,
        );
    },
    createUser({ serverId, values }) {
        return axiosService.post(`/api/sftp-servers/${serverId}/users`, values);
    },
    addSftpUsers({ serverId, users }) {
        return axiosService.post(`/api/sftp-servers/${serverId}/users`, users);
    },
    deleteSftpUser({ serverId }) {
        return axiosService.delete(`/api/sftp-servers/${serverId}/users`);
    },
    changeSftpUserPassword({ serverId, user }) {
        return axiosService.patch(`/api/sftp-servers/${serverId}/users`, user);
    },
    parseKey({ key }) {
        return axiosService.post(`/api/ssh-keys`, key);
    },
};

export default sftpService;
