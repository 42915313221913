import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";
import { ReportType } from "../../constants/CommonConstats";

export const reportsColumns = () => [
    {
        type: fieldTypes.TEXT,
        label: t("reports-list.header.name"),
        name: "name",
        className: "pmTable__column--withActionButton",
    },
    {
        type: fieldTypes.OPTION,
        options: [
            {
                label: ReportType.STARTED,
                value: ReportType.STARTED,
            },
            {
                label: ReportType.FINISHED,
                value: ReportType.FINISHED,
            },
            {
                label: ReportType.FAILED,
                value: ReportType.FAILED,
            },
        ],

        label: t("reports-list.header.status"),
        name: "status",
    },
    {
        type: fieldTypes.DATETIME,
        label: t("reports-list.header.next-execution-time"),
        name: "nextExecutionTime",
        render: (text, record) => {
            return record.nextExecutionTime ? (
                <FormattedDateTime>
                    {record.nextExecutionTime}
                </FormattedDateTime>
            ) : (
                "Not defined"
            );
        },
    },
    {
        type: fieldTypes.TEXT,
        canFilter: false,
        label: t("reports-list.header.enabled"),
        name: "enabled",
        render: (text, record) => {
            return record.enabled
                ? t("reports-list.filled.enabled")
                : t("reports-list.filled.disabled");
        },
    },
];
