import { ButtonMenu, Modal, UnityLayout } from "@/components/DesignSystem";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import { LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { IntegrityCheckError } from "./IntegrityCheckError";
import { columns } from "./columns";
import { useTemplateChanges } from "./loadables";

export const VersionIntegrityCheckModal = ({
    visible,
    packageName,
    partitionId,
    accountId,
    templateVersion,
    partitionName,
    onClose,
}) => {
    const templateChangesResource = useTemplateChanges({
        canFetch: visible,
        packageName,
        partitionId,
        templateVersion,
    });

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("version-integrity-check.modal.title")}
                />
                <UnityLayout.Content padding={[true, true]}>
                    <LoadableRenderer
                        loadable={templateChangesResource.loadable}
                        hasValue={dataSource => (
                            <TableLocalFiltered
                                columns={columns}
                                dataSource={dataSource}
                                pagination={false}
                                rowKey="id"
                            />
                        )}
                        hasError={error => (
                            <IntegrityCheckError
                                error={error}
                                partitionName={partitionName}
                                partitionId={partitionId}
                                accountId={accountId}
                            />
                        )}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.close"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
VersionIntegrityCheckModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    packageName: PropTypes.string,
    partitionId: PropTypes.number,
    accountId: PropTypes.number,
    templateVersion: PropTypes.string,
    partitionName: PropTypes.string,
};
