import { buildPageableUrl } from "@/utils/urlUtils";
import { axiosService } from "../../../axios";

export const userAdminService = {
    fetchDeactivatedByUsername(username) {
        return axiosService.get(`/api/admin/users/deactivated/${username}`);
    },

    fetchUsers(page, size, sort, filter) {
        return axiosService.post(
            buildPageableUrl(`/api/admin/users/table`, page, size, sort),
            filter,
        );
    },

    resendActivation(userId) {
        return axiosService.post(
            `/api/admin/users/${userId}/resend-activation`,
        );
    },
};
