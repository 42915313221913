import { useDic } from "@/components/Dic/useDic.hook";
import { SOURCE_TYPE } from "@/components/Mappers/ExportMapper/EntitySelectFields";
import { pendingPromise, useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { naturallySortOptions } from "@/utils/form.utils";
import { pick, pipe, values } from "lodash/fp";
import {
    ENTITY_TYPE_CODES_FE as TYPES,
    hasSubFilter,
} from "../../../../../constants/SupportedTableImportTypes";

export const useSupportedEntityTypesQuery = ({
    sourceId,
    sourceType,
    sort = naturallySortOptions,
}) => {
    const { dataUploadService } = useDic();

    return useQueryLoadable(async () => {
        switch (sourceType) {
            case SOURCE_TYPE.PARTITIONS: {
                if (isNaN(sourceId)) return pendingPromise();
                return dataUploadService
                    .getSupportedEntityTypes(sourceId)
                    .then(types => pipe(pick(types), values, sort)(TYPES));
            }
            case SOURCE_TYPE.CONNECTIONS: {
                return Promise.resolve(values(TYPES));
            }
            default:
                throw new Error(`Unsupported sourceType: "${sourceType}"`);
        }
    }, [sourceType, sourceId, dataUploadService, sort]);
};

export const useEntityNamesQuery = ({
    entityType,
    sourceType,
    sourceId,
    canFetch = entityType && hasSubFilter(entityType) && sourceType && sourceId,
}) => {
    const { metadataService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : metadataService
                      .fetchEntityTypeNames(sourceType, sourceId, entityType)
                      .then(getData),
        [canFetch, metadataService, sourceType, sourceId, entityType],
    );
};
