import { ButtonMenu, Modal, UnityLayout } from "@/components/DesignSystem";
import { CodeMirror } from "@/components/DesignSystem/CodeMirror";
import "@/components/DesignSystem/CodeMirror/extensions/password-encrypt";
import { passwordEncryption } from "@/components/DesignSystem/CodeMirror/extensions/password-encrypt";
import { useDic } from "@/components/Dic/useDic.hook";
import { useParentState } from "@/components/hooks/useParentState.hook";
import { LoadableRenderer, isLoading } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useFileContentMutation, useFileContentQuery } from "./loadables";

export const PropertiesEditorModal = ({
    visible,
    readOnly,
    onClose,
    afterSuccess,
    name,
    instanceId,
    theme = "default",
    mode = "properties",
}) => {
    const { messageService, instanceService } = useDic();
    const fileContentQuery = useFileContentQuery({
        name,
        instanceId,
        canFetch: !!name,
    });
    const fileContentMutation = useFileContentMutation({
        name,
        instanceId,
        afterSuccess,
    });
    const [value, setValue] = useParentState(
        fileContentQuery.loadable.valueMaybe(),
    );
    const [valid, setValid] = useState(true);
    const submitDisabled =
        !valid || isLoading(fileContentQuery) || isLoading(fileContentMutation);
    const handleConfirm = useCallback(() => {
        fileContentMutation.mutate(value);
    }, [value, fileContentMutation]);

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header size={3} title={name} />
                <UnityLayout.Content padding={[true, true]}>
                    <LoadableRenderer
                        loadable={fileContentQuery.loadable}
                        hasValue={() => (
                            <CodeMirror
                                mode={mode}
                                value={value}
                                onChange={setValue}
                                extensions={[
                                    passwordEncryption(mode, secret => {
                                        return instanceService
                                            .encryptData(instanceId, secret)
                                            .then(getData)
                                            .catch(e => {
                                                messageService.error({
                                                    content: getErrorMessage(
                                                        e.response.data,
                                                    ),
                                                });
                                            });
                                    }),
                                ]}
                            />
                        )}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                !readOnly && {
                                    label: t("general.save"),
                                    onClick: handleConfirm,
                                    type: "primary",
                                    disabled: submitDisabled,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

PropertiesEditorModal.propTypes = {
    visible: PropTypes.bool,
};
