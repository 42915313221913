import { useContext, useEffect } from "react";

import { ContentLayoutContext } from "../ContentLayout/ContentLayout.context";
import { contentLayoutsEnum } from "../ContentLayout/contentLayouts.enum";

export function useContentLayout(layout, resetLayoutOnUnmount = true) {
    const { setLayout } = useContext(ContentLayoutContext);

    useEffect(() => {
        setLayout(layout);

        return () => {
            if (!resetLayoutOnUnmount) {
                return;
            }

            setLayout(contentLayoutsEnum.default);
        };
    }, [layout]);
}

export function useFullWidthTableLayout(resetLayoutOnUnmount = true) {
    useContentLayout(contentLayoutsEnum.fullWidthTable, resetLayoutOnUnmount);
}

export function useFullWidthLayout(resetLayoutOnUnmount = true) {
    useContentLayout(contentLayoutsEnum.fullWidth, resetLayoutOnUnmount);
}

export function useCenteredLayout(resetLayoutOnUnmount = true) {
    useContentLayout(contentLayoutsEnum.centered, resetLayoutOnUnmount);
}
export function useLeftAlignedLayout(resetLayoutOnUnmount = true) {
    useContentLayout(contentLayoutsEnum.leftAligned, resetLayoutOnUnmount);
}
