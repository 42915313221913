import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { Gap, Link, P, Text } from "@/components/DesignSystem";
import {
    fieldTypes,
    HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT,
} from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { PDM_TABS } from "@/components/PartitionDataManagement/PartitionDataManagementPage";
import {
    useAvailableFilesQuery,
    useDownloadQuery,
} from "@/components/PartitionDataManagement/loadables";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { LoadableRenderer } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { downloadFileFromS3 } from "@/utils/downloadUtils";
import { getSizeWithLabelUnknownWrapper } from "@/utils/formats/bytes";
import React, { useCallback } from "react";

const preferencesType = preferencesTypes.PARTITION_AVAILABLE_FILES_LIST_TABLE;

export const useDataDownload = downloadId => {
    const { axiosService } = useDic();

    const downloadDataDownloadFile = useCallback(
        ({ fileName, filePath }) => {
            axiosService
                .get(
                    `/api/data-downloads/${downloadId}/available-files/download?filePath=${filePath}`,
                )
                .then(({ data }) => {
                    downloadFileFromS3(data.presignedUrl, fileName);
                });
        },
        [downloadId],
    );
    return { downloadFile: downloadDataDownloadFile };
};
export const AvailableFilesPage = () => {
    const { downloadId } = useAccountAppParams();
    const {
        axiosService,
        locationRouterService,
        accountAppLocations: { partitionDataManagementLocation },
    } = useDic();
    const onCancel = useCallback(
        () =>
            locationRouterService.navigate(partitionDataManagementLocation, {
                tab: PDM_TABS.DOWNLOADS,
            }),
        [partitionDataManagementLocation, locationRouterService],
    );
    useSetBackButtonAction(onCancel);

    const { downloadFile } = useDataDownload(downloadId);

    const downloadQuery = useDownloadQuery({
        downloadId,
    });

    useSetPageTitle(downloadQuery.loadable.valueMaybe()?.name ?? "");

    const query = useAvailableFilesQuery({
        downloadId,
    });

    const columns = [
        {
            name: "fileName",
            label: t("partition.download.available-files.columns.files"),
            type: fieldTypes.TEXT,
            render: (fileName, record) => {
                return (
                    <Link onClick={() => downloadFile(record)}>{fileName}</Link>
                );
            },
        },
        {
            name: "size",
            label: t("partition.download.available-files.columns.size"),
            type: fieldTypes.NUMBER,
            render: size => getSizeWithLabelUnknownWrapper(size),
        },
        {
            name: "createdAt",
            label: t("partition.download.available-files.columns.created-at"),
            type: fieldTypes.DATETIME,
            render: text => <FormattedDateTime>{text}</FormattedDateTime>,
        },
        // {
        //     name: "triggeredBy",
        //     label: t("partition.download.available-files.columns.triggered-by"),
        //     type: fieldTypes.TEXT,
        // },
    ];

    return (
        <>
            <P>{t("partition.download.available-files.perex")}</P>
            <LoadableRenderer
                loadable={query.loadable}
                hasValue={dataSource => {
                    return (
                        <TableWithPreferencesManagement
                            // actionMenu={actionMenu}
                            columns={columns}
                            datasetSlicing="local"
                            dataSource={dataSource}
                            fixed
                            preferencesType={preferencesType}
                            restHeight={
                                HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT + 14 + 44
                            }
                            rowKey="fileName"
                            padding={false}
                        />
                    );
                }}
            />
        </>
    );
};
