import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import {
    useSftpServerQuery,
    useSftpServerUserMutation,
    useSftpServerUserQuery,
    useSftpServerUsersQuery,
} from "@/components/SFTPServers/loadables";
import SFTPUserForm from "@/components/SFTPServers/SFTPUserForm.component";
import { useMapLoadableMemoized } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { SFTP_SERVER_TYPE } from "@/services/sftp.service";
import { get, map } from "lodash/fp";
import React, { useCallback } from "react";

export const SFTPServerUserPage = () => {
    const { serverId, userId } = useAccountAppParams();
    const {
        locationRouterService,
        accountAppLocations: { sftpServerUsersLocation },
    } = useDic();
    const goBack = useCallback(
        () => locationRouterService.navigate(sftpServerUsersLocation),
        [locationRouterService, sftpServerUsersLocation],
    );

    useSetBackButtonAction(goBack);
    const sftpServerUsernamesLoadable = useMapLoadableMemoized(
        useSftpServerUsersQuery({ serverId }).loadable,
        map(get("username")),
    );

    const isNew = userId === "new";

    const sftpServerQuery = useSftpServerQuery({ serverId });
    const sftpServerUserQuery = useSftpServerUserQuery({
        serverId,
        userId,
        isNew,
    });
    const initialValues = sftpServerUserQuery.loadable.valueMaybe();
    const sftpServerUserMutation = useSftpServerUserMutation({
        serverId,
        username: initialValues?.username,
        afterSuccess: goBack,
    });

    const { prefix: maybePrefix, type: maybeType } =
        sftpServerQuery.loadable.valueMaybe() ?? {};
    const isLDAP = maybeType === SFTP_SERVER_TYPE.LDAP;

    return (
        <SFTPUserForm
            sftpServerQuery={sftpServerQuery}
            sftpServerUserQuery={sftpServerUserQuery}
            sftpServerUserMutation={sftpServerUserMutation}
            sftpServerUsernamesLoadable={sftpServerUsernamesLoadable}
            isNew={isNew}
            maybePrefix={maybePrefix}
            isLDAP={isLDAP}
            initialValues={initialValues}
            goBack={goBack}
        />
    );
};

SFTPServerUserPage.propTypes = {};
