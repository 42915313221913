import Changed from "@/components/DateTime/Changed";
import { colors } from "@/components/DesignSystem/colors";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { DEPLOYMENT_STATUS } from "@/components/IntegrationRoutes/InstanceRepoIntegrationsRoutes";
import { IconWithTooltip } from "@/components/Integrations/components/IconWithTooltip";
import { NonStandardEntity } from "@/components/Integrations/components/NonStandardEntity";
import { t } from "@/translations";
import get from "lodash/get";
import React from "react";

const getOrEmpty = (obj, key) => get(obj, key, "");

export const instanceRepoIntegrationsRoutesColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("mapper-list.header.name"),
        name: "uniqueName",
        className: "pmTable__column--withActionButton",
        render: (text, record) => (
            <>
                <NonStandardEntity
                    display={!record.standard}
                    tooltip={t("mapper-list.header.non-standard-route")}
                />
                {text}
            </>
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("mapper-list.header.type"),
        name: "type",
    },
    {
        type: fieldTypes.TEXT,
        label: t("customer-integration-list.header.status"),
        name: "status",
    },
    {
        type: fieldTypes.TEXT,
        label: t("customer-integration-list.header.deployment-status"),
        name: "deploymentStatus",
        render: (text, record) => (
            <>
                <IconWithTooltip
                    display={
                        record.deploymentStatus === DEPLOYMENT_STATUS.FAILED
                    }
                    tooltip={record.message}
                    icon={"info-circle"}
                    color={colors.danger.primary}
                />
                {text}
            </>
        ),
    },
    {
        type: fieldTypes.TEXT,
        canFilter: false,
        label: t("mapper-list.header.changed"),
        name: "updatedAt",
        render: (text, record) => <Changed key={record.id} data={record} />,
    },
    {
        type: fieldTypes.TEXT,
        label: t("mapper-list.header.revision-number"),
        name: "revisionNumber",
    },
    {
        type: fieldTypes.INTEGER,
        label: t("routes-list.header.failuresHandled"),
        name: "details.failuresHandled",
        render: (text, record) => getOrEmpty(record.details, "failuresHandled"),
    },
    {
        type: fieldTypes.INTEGER,
        label: t("routes-list.header.exchangesTotal"),
        name: "details.exchangesTotal",
        render: (text, record) => getOrEmpty(record.details, "exchangesTotal"),
    },
    {
        type: fieldTypes.INTEGER,
        label: t("routes-list.header.exchangesInflight"),
        name: "details.exchangesInflight",
        render: (text, record) =>
            getOrEmpty(record.details, "exchangesInflight"),
    },
    {
        type: fieldTypes.INTEGER,
        label: t("routes-list.header.meanProcessingTime"),
        name: "details.meanProcessingTime",
        render: (text, record) =>
            getOrEmpty(record.details, "meanProcessingTime"),
    },
    {
        type: fieldTypes.INTEGER,
        label: t("routes-list.header.minProcessingTime"),
        name: "details.minProcessingTime",
        render: (text, record) =>
            getOrEmpty(record.details, "minProcessingTime"),
    },
    {
        type: fieldTypes.INTEGER,
        label: t("routes-list.header.maxProcessingTime"),
        name: "details.maxProcessingTime",
        render: (text, record) =>
            getOrEmpty(record.details, "maxProcessingTime"),
    },
    {
        type: fieldTypes.INTEGER,
        label: t("routes-list.header.totalProcessingTime"),
        name: "details.totalProcessingTime",
        render: (text, record) =>
            getOrEmpty(record.details, "totalProcessingTime"),
    },
    {
        type: fieldTypes.INTEGER,
        label: t("routes-list.header.deltaProcessingTime"),
        name: "details.deltaProcessingTime",
        render: (text, record) =>
            getOrEmpty(record.details, "deltaProcessingTime"),
    },
    {
        type: fieldTypes.TEXT,
        label: t("routes-list.header.uptime"),
        name: "uptimeMillis",
        canFilter: false,
        render: (_, record) => record.uptime,
    },
    {
        type: fieldTypes.TEXT,
        label: t("routes-list.header.path"),
        name: "path",
        visible: false,
    },
];
