import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { get } from "lodash/fp";

export const useIntegrationGroupUsersQuery = ({
    projectId,
    integrationGroupName,
    canFetch,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : axiosService
                      .get(
                          `/api/projects/${projectId}/git/${integrationGroupName}/users/assignable`,
                      )
                      .then(get("data")),
        [canFetch, axiosService, projectId, integrationGroupName],
    );
};

export const useIntegrationUsersQuery = ({
    projectId,
    groupName,
    canFetch,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : axiosService
                      .get(`/api/projects/${projectId}/git/${groupName}/users`)
                      .then(get("data")),
        [projectId, groupName, canFetch, axiosService],
    );
};

export const useIntegrationUsersMutation = ({
    projectId,
    groupName,
    afterSuccess,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ values: { users } }) =>
            axiosService
                .post(
                    `/api/projects/${projectId}/git/${groupName}/users`,
                    users,
                )
                .then(({ data }) => {
                    afterSuccessCurrent(data);
                    return data;
                }),
        [projectId, groupName, axiosService, afterSuccessCurrent],
    );
};
