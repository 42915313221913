export class WorkflowDefinitionApiService {
    baseUrl = "api/projects";
    /**
     *
     * @type {HttpService}
     */
    httpService = null;

    constructor({ httpService }) {
        this.httpService = httpService;
    }

    /**
     *
     * @param {string} accountId
     * @returns {Promise<TemplateEntity[]>}
     */
    getWorkflowsForAccount(accountId) {
        return this.httpService.get(
            this.getWorkflowDefinitionBaseUrl(accountId),
        );
    }

    /**
     *
     * @param {object}
     * @param {any} data
     * @returns {Promise<TemplateEntity[]>}
     */
    setWorkflowsForAccount({ accountId, workflowId }, data) {
        return this.httpService.post(
            this.getWorkflowDefinitionBaseUrl(accountId, workflowId),
            data,
        );
    }

    /**
     *
     * @param {string} accountId
     * @param {string} workflowId
     * @returns {Promise<TemplateEntity[]>}
     */
    deleteWorkflowsForAccount(accountId, workflowId) {
        return this.httpService.delete(
            this.getWorkflowDefinitionBaseUrl(accountId, workflowId),
        );
    }

    /**
     *
     * @param {string} accountId
     * @returns {string}
     */
    getWorkflowDefinitionBaseUrl(accountId, workflowId = null) {
        return `${this.baseUrl}/${accountId}/workflows${
            workflowId ? `/${workflowId}` : ""
        }`;
    }
}
