import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { Empty, Timeline } from "@/components/DesignSystem";
import { useIntegrationsDeploymentsQuery } from "@/components/IntegrationDeploymentHistory/loadables";
import { LoadableRenderer } from "@/modules/loadable";
import size from "lodash/size";
import React from "react";
import Avatar from "../Avatar/AvatarIcon";

const getTimeLineItem = deployment => {
    const color = deployment.result === "SUCCESS" ? "green" : "red";
    const message =
        deployment.result === "SUCCESS"
            ? "Successfully deployed"
            : "Deploy failed";

    return (
        <Timeline.Item color={color} key={deployment.id}>
            <Avatar
                gravatarHash={deployment.executorGravatarHash}
                name={deployment.executorName}
            />{" "}
            {message} (<FormattedDateTime>{deployment.time}</FormattedDateTime>)
        </Timeline.Item>
    );
};

const IntegrationDeploymentHistory = ({ projectId, integrationId }) => {
    const integrationsDeploymentsResource = useIntegrationsDeploymentsQuery({
        accountId: projectId,
        integrationId,
    });

    return (
        <LoadableRenderer
            loadable={integrationsDeploymentsResource.loadable}
            hasValue={deployments => {
                return size(deployments) == 0 ? (
                    <Empty />
                ) : (
                    <Timeline style={{ paddingTop: "10px" }}>
                        {deployments.map(deployment =>
                            getTimeLineItem(deployment),
                        )}
                    </Timeline>
                );
            }}
        />
    );
};

export default IntegrationDeploymentHistory;
