import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import React from "react";
import { FailedDeploymentsListContainer } from "./FailedDeploymentsList.container";

export function FailedDeploymentsPage() {
    useFullWidthTableLayout();
    useHideBackButton();

    return <FailedDeploymentsListContainer />;
}
