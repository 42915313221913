import React from "react";

import { LocationLink } from "@/modules/router";

export const AccountLinkOrName = ({ dashboardLocation, record }) =>
    record.hasRightForAccount ? (
        <LocationLink
            $location={dashboardLocation}
            $params={{ accountId: record.customerId }}
        >
            {record.customerName}
        </LocationLink>
    ) : (
        record.customerName
    );
