import { Gap, Text } from "@/components/DesignSystem";
import { ErrorData } from "@/components/Packages/FailedOptionPanel/ErrorData";
import { Log } from "@/components/Packages/FailedOptionPanel/Log";
import { Messages } from "@/components/Packages/FailedOptionPanel/Messages";
import { t } from "@/translations";
import { Collapse } from "@pricefx/unity-components";
import PropTypes from "prop-types";
import React from "react";

const PANEL = {
    PARTITION_LOG: "PARTITION_LOG",
    ACCELERATOR_LOG: "ACCELERATOR_LOG",
};

export const UploadErrorInfo = ({
    fileSuffix,
    errorData,
    log,
    partitionLog,
    messages,
    extraElement,
}) => {
    const defaultActiveKey = [];
    if (partitionLog) defaultActiveKey.push(PANEL.PARTITION_LOG);
    if (log) defaultActiveKey.push(PANEL.ACCELERATOR_LOG);

    return (
        <>
            {errorData && (
                <>
                    <ErrorData errorData={errorData} />
                    <Gap />
                </>
            )}
            <Collapse
                size={5}
                padded={false}
                defaultActiveKey={defaultActiveKey}
            >
                <Collapse.Panel
                    header={t(
                        "failed-deployments-errorlog-modal.partition-log",
                    )}
                    key={PANEL.PARTITION_LOG}
                >
                    <Log
                        value={partitionLog}
                        downloadFileName={`partition-error-log-${fileSuffix}.txt`}
                        // downloadLabel="Partition Log"
                    />
                </Collapse.Panel>
                <Collapse.Panel
                    header={t(
                        "failed-deployments-errorlog-modal.accelerator-log",
                    )}
                    key={PANEL.ACCELERATOR_LOG}
                >
                    <Log
                        value={log}
                        downloadFileName={`accelerator-error-log-${fileSuffix}.txt`}
                        // downloadLabel="Accelerator Log"
                    />
                    {messages && <Messages messages={messages} />}
                </Collapse.Panel>
            </Collapse>
            {extraElement}
        </>
    );
};

UploadErrorInfo.propTypes = {
    fileSuffix: PropTypes.string,
    log: PropTypes.string,
    partitionLog: PropTypes.string,
    messages: PropTypes.array,
    errorData: PropTypes.array,
    extraElement: PropTypes.node,
};

export default UploadErrorInfo;
