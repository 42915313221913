import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { SSH_KEY_FIELD } from "@/components/SFTPServers/constants";
import { useParseSftpKeyMutation } from "@/components/SFTPServers/loadables";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

export const AddKeyModal = ({ visible, onAdd, onCancel }) => {
    const parseSftpKeyMutation = useParseSftpKeyMutation();
    const { formId, handleSubmit } = Forms.useForm({
        onSubmit: async ({ values }) => {
            const parsed = await parseSftpKeyMutation.mutate({
                key: values[SSH_KEY_FIELD.KEY],
            });
            return onAdd(parsed);
        },
    });

    return (
        <Modal visible={visible} onClose={onCancel}>
            <div
                onSubmit={e => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <UnityLayout>
                    <UnityLayout.Header
                        size={3}
                        title={t("sftp-servers.user-management.add-key")}
                    />
                    <UnityLayout.Content padding={[false, true]}>
                        <Forms.Form formId={formId} onSubmit={handleSubmit}>
                            <Forms.FieldGroup
                                width="max"
                                inputWidth="max"
                                spacing="small"
                            >
                                <Forms.Fields.TextArea
                                    name={SSH_KEY_FIELD.KEY}
                                    label={t(
                                        "sftp-servers.user-management.fields.key.label",
                                    )}
                                    tooltip={t(
                                        "sftp-servers.user-management.fields.key.tooltip",
                                    )}
                                    validator={Forms.pmValidators.isRequired}
                                    required
                                    rows="10"
                                />
                            </Forms.FieldGroup>
                        </Forms.Form>
                    </UnityLayout.Content>
                    <UnityLayout.Footer
                        actionButtons={
                            <ButtonMenu
                                buttons={[
                                    {
                                        label: t("general.add"),
                                        type: "primary",
                                        formId,
                                    },
                                    {
                                        label: t("general.cancel"),
                                        onClick: onCancel,
                                        type: "text",
                                    },
                                ]}
                            />
                        }
                    />
                </UnityLayout>
            </div>
        </Modal>
    );
};

AddKeyModal.propTypes = {
    visible: PropTypes.bool,
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
