import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { useStepContent, useStepContentReplacementMutation } from "./loadables";
const { useForm, Form, Fields, pmValidators } = Forms;

const EditStepModal = ({ templateName, stepName, visible, onCancel }) => {
    const stepContentResource = useStepContent({
        templateName,
        stepName,
    });
    const stepContentMutatition = useStepContentReplacementMutation({
        templateName,
        stepName,
        afterSuccess: onCancel,
    });
    const onSubmit = ({ values: { title, description, ...placeholders } }) => {
        return stepContentMutatition.mutate({
            title,
            description,
            placeholders: Object.entries(placeholders).map(([id, value]) => ({
                id,
                value,
            })),
        });
    };

    const { formId, handleSubmit } = useForm({
        onSubmit,
    });

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("step-edit-form.modal.title", { stepName })}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <LoadableRenderer
                        loadable={stepContentResource.loadable}
                        hasValue={({ title, description, placeholders }) => (
                            <Form formId={formId} onSubmit={handleSubmit}>
                                <Fields.Input
                                    label={t("step-edit-form.title.label")}
                                    placeholder={t(
                                        "step-edit-form.title.placeholder",
                                    )}
                                    name="title"
                                    inputWidth="max"
                                    initialValue={title}
                                />
                                <Fields.TextArea
                                    label={t(
                                        "step-edit-form.description.label",
                                    )}
                                    placeholder={t(
                                        "step-edit-form.description.placeholder",
                                    )}
                                    name="description"
                                    inputWidth="max"
                                    initialValue={description}
                                />
                                {placeholders.map(placeholder => (
                                    <Fields.Input
                                        key={placeholder.id}
                                        label={`${placeholder.name} (${placeholder.field})`}
                                        placeholder={t(
                                            "step-edit-form.placeholder.placeholder",
                                            {
                                                fieldName: `${placeholder.name} (${placeholder.field})`,
                                            },
                                        )}
                                        name={placeholder.id}
                                        inputWidth="max"
                                        initialValue={placeholder.value}
                                    />
                                ))}
                            </Form>
                        )}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.save"),
                                    type: "primary",
                                    formId,
                                },
                                {
                                    label: t("general.cancel"),
                                    type: "text",
                                    onClick: onCancel,
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

EditStepModal.propTypes = {
    templateName: PropTypes.string.isRequired,
    stepName: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
};

export default EditStepModal;
