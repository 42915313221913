import { businessRolesApiSchema } from "./businesRoleApi.schemas";

export class BusinessRoleApiService {
    baseUrl = "/api/projects";
    /**
     *
     * @type {HttpService}
     */
    httpService = null;
    /**
     *
     * @type {Mapper}
     */
    businessRolesApiMapper = null;

    constructor({ httpService, businessRolesApiMapper }) {
        this.httpService = httpService;
        this.businessRolesApiMapper = businessRolesApiMapper;
    }

    /**
     *  @param {number} accountId
     *  @returns {Promise<BusinessRoleEntity>}
     */
    fetchBusinessRoles(accountId) {
        return this.httpService.get(this.getUrl(accountId), {
            schema: businessRolesApiSchema,
            mapper: this.businessRolesApiMapper,
        });
    }

    /**
     *  @param {BusinessRoleEntity} businessRole
     *  @param {number} accountId
     *
     */
    crateBusinessRole(businessRole, accountId) {
        return this.httpService.post(this.getUrl(accountId), businessRole);
    }

    /**
     *  @param {BusinessRoleEntity} businessRole
     *  @param {number} accountId
     *
     */
    updateBusinessRole(businessRole, accountId) {
        return this.httpService.post(
            this.getUrl(accountId, businessRole.id),
            businessRole,
        );
    }

    /**
     *  @param {number} bossinessRoleId
     *  @param {number} accountId
     *
     */
    deleteBusinessRole = (accountId, bossinessRoleId) => {
        return this.httpService.delete(this.getUrl(accountId, bossinessRoleId));
    };

    getUrl(accountId, businessRoleId) {
        const businessUrl = `${this.baseUrl}/${accountId}/business-roles`;
        return businessRoleId
            ? `${businessUrl}/${businessRoleId}`
            : businessUrl;
    }
}
