import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthLayout } from "@/components/hooks/useContentLayout.hook";
import { NonStandardDefinition } from "@/components/IntegrationRoutes/components/NonStandardDefinition.component";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";

export const FilterDefinitionPage = () => {
    const { accountId, instanceId, prn: prnEncoded } = useAccountAppParams();
    const prn = decodeURIComponent(prnEncoded);

    const {
        locationRouterService,
        accountAppLocations: { filtersLocation },
    } = useDic();

    useSetPageTitle(t("filter-definition.title", { prn }));

    const navigateToFiltersList = useCallback(() => {
        locationRouterService.navigate(filtersLocation, {
            instanceId,
            accountId,
        });
    }, [accountId, instanceId, locationRouterService, filtersLocation]);

    useSetBackButtonAction(navigateToFiltersList);

    useFullWidthLayout();

    return <NonStandardDefinition instanceId={instanceId} prn={prn} />;
};
