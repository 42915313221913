import {
    useSftpServerQuery,
    useSftpServerUserMutation,
    useSftpServerUserQuery,
    useSftpServerUsersQuery,
} from "@/components/DataUploads/SftpManagement/loadables";
import { useParseSftpKeyMutation } from "@/components/SFTPServers/loadables";

import SFTPUserForm from "@/components/SFTPServers/SFTPUserForm.component";
import { useMapLoadableMemoized } from "@/modules/loadable";
import { sftpEntityType } from "@/services/dataLoadSftp.service";
import { get, map } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";

export const SFTPServerUserCreation = ({
    perex,
    dataLoadId,
    entityType,
    userId,
    navigateToUsersList,
}) => {
    const sftpServerUsernamesLoadable = useMapLoadableMemoized(
        useSftpServerUsersQuery({
            dataLoadId,
            type: entityType,
        }).loadable,
        map(get("username")),
    );

    const isNew = userId === "new";

    const sftpServerQuery = useSftpServerQuery({
        dataLoadId,
        type: entityType,
    });
    const sftpServerUserQuery = useSftpServerUserQuery({
        dataLoadId,
        type: entityType,
        userId,
        isNew,
    });
    const initialValues = sftpServerUserQuery.loadable.valueMaybe();
    const sftpServerUserMutation = useSftpServerUserMutation({
        dataLoadId,
        type: entityType,
        username: initialValues?.username,
        afterSuccess: navigateToUsersList,
    });

    const parseSftpKeyMutation = useParseSftpKeyMutation();

    const { prefix: maybePrefix } = sftpServerQuery.loadable.valueMaybe() ?? {};
    return (
        <SFTPUserForm
            perex={perex}
            withEmailNotifications={entityType === sftpEntityType.DATA_UPLOAD}
            sftpServerQuery={sftpServerQuery}
            sftpServerUserQuery={sftpServerUserQuery}
            sftpServerUserMutation={sftpServerUserMutation}
            sftpServerUsernamesLoadable={sftpServerUsernamesLoadable}
            parseSftpKeyMutation={parseSftpKeyMutation}
            isNew={isNew}
            maybePrefix={maybePrefix}
            isLDAP
            initialValues={initialValues}
            goBack={navigateToUsersList}
        />
    );
};

SFTPServerUserCreation.propTypes = {
    perex: PropTypes.string,
    dataLoadId: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    navigateToUsersList: PropTypes.func.isRequired,
};
