import { useContext, useEffect } from "react";
import { PageLayoutContext } from "./PageLayoutContext";

export const useHideBackButton = () => {
    const { setHideBackButton } = useContext(PageLayoutContext);

    useEffect(() => {
        setHideBackButton(true);
        return () => {
            setHideBackButton(false);
        };
    }, [setHideBackButton]);
};
