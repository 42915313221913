import cx from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { TIME_HOURS_MINUTES } from "./date-formats";
import styles from "./UpgradeTime.style.less";

export function UpgradeTime({ start, end, isSelected, ...props }) {
    const classes = cx(styles.wrapper, isSelected && styles.isSelected);
    const startFormatted = moment(start).format(TIME_HOURS_MINUTES);
    const endFormatted = moment(end).format(TIME_HOURS_MINUTES);

    return (
        <div className={classes} {...props}>
            {`${startFormatted} - ${endFormatted}`}
        </div>
    );
}

UpgradeTime.propTypes = {
    className: PropTypes.string,
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
};
