import moment from "moment";
import PropTypes from "prop-types";

const FromNowFormatter = ({ date }) => {
    if (!date) return null;
    return moment(date).fromNow();
};

FromNowFormatter.propTypes = {
    date: PropTypes.any.isRequired,
};

export default FromNowFormatter;
