import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { LoadableRenderer } from "@/modules/loadable";
import { createSessionState } from "@/utils/sessionState/sessionState";
import { cloneDeep } from "lodash";
import flip from "lodash/fp/flip";
import merge from "lodash/fp/merge";
import pick from "lodash/fp/pick";
import pipe from "lodash/fp/pipe";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import {
    useDeletePreference,
    useEditPreference,
    useFetchPreferences,
} from "./loadableResources";
import { ManagePreferencesModal } from "./ManagePreferencesModal";
import { TableWithPreferencesManagementComponent } from "./TableWithPreferencesManagement.component";
import { PREFERENCES_CHANGE_ACTION, useTrackPreferences } from "./tracking";
import { applyPreferenceChanges, findActivePref } from "./utils";

const useSelectedPreference = (preferencesLoadable, activePreference) => {
    return useMemo(
        () =>
            preferencesLoadable.state === "hasValue"
                ? activePreference ||
                  findActivePref(preferencesLoadable.contents)
                : undefined,
        [preferencesLoadable, activePreference],
    );
};

export const TableWithPreferencesManagement = ({
    preferencesType,
    ...props
}) => {
    if (!preferencesType) throw new Error("Missing preference type");

    const trackPreferences = useTrackPreferences();
    const { useSessionState } = useMemo(
        () => createSessionState(preferencesType),
        [preferencesType],
    );
    const [changedPreference, setChangedPreference] =
        useSessionState("changedPreference");

    const newPreferenceModal = useVisibility();
    const managePreferencesModal = useVisibility();

    const preferencesResource = useFetchPreferences(preferencesType);
    const onCreated = useCallback(() => {
        preferencesResource.reload();
        setActivePreference();
        setChangedPreference();
        newPreferenceModal.hide();
    }, [newPreferenceModal, preferencesResource, setChangedPreference]);
    const onSaved = useCallback(
        preference => {
            setActivePreference(cloneDeep(preference));
            setChangedPreference();
            preferencesResource.reload();
        },
        [preferencesResource, setChangedPreference],
    );
    const onDeleted = id => {
        preferencesResource.reload();
        if (activePreference?.id && id === activePreference?.id) {
            setActivePreference();
            pipe(
                pick(["viewState"]),
                flip(merge)(changedPreference),
                setChangedPreference,
            )(activePreference);
        }
    };
    const { mutate: editPreference } = useEditPreference(onSaved);
    const deletePreferenceResource = useDeletePreference(onDeleted);

    const [activePreference, setActivePreference] = useState();
    const selectedPreference = useSelectedPreference(
        preferencesResource.loadable,
        activePreference,
    );

    const onDeletePreference = useCallback(
        record => deletePreferenceResource.mutate(record),
        [deletePreferenceResource],
    );
    const onSavePreference = useCallback(() => {
        const preference = applyPreferenceChanges(
            selectedPreference,
            changedPreference,
        );
        editPreference(preference);
    }, [changedPreference, selectedPreference, editPreference]);
    const onPreferenceSelect = useCallback(
        preference => {
            trackPreferences(PREFERENCES_CHANGE_ACTION.SELECT, preference.type);
            setActivePreference(cloneDeep(preference));
            setChangedPreference();
        },
        [setChangedPreference, trackPreferences],
    );

    const onResetPreference = useCallback(() => {
        const preference = {
            viewState: {},
        };
        trackPreferences(PREFERENCES_CHANGE_ACTION.RESET, preference.type);
        setActivePreference(preference);
        setChangedPreference();
    }, [setChangedPreference, trackPreferences]);
    const onSetActive = useCallback(() => {
        managePreferencesModal.hide();
        preferencesResource.reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ManagePreferencesModal
                visible={managePreferencesModal.visible}
                onOk={onSetActive}
                onDelete={onDeletePreference}
                onCancel={managePreferencesModal.hide}
                preferencesLoadable={preferencesResource.loadable}
            />
            <LoadableRenderer
                loadable={preferencesResource.loadable}
                hasValue={preferences => (
                    <TableWithPreferencesManagementComponent
                        preferences={preferences}
                        changedPreference={changedPreference}
                        onPreferenceChange={setChangedPreference}
                        newPreferenceModal={newPreferenceModal}
                        managePreferencesModal={managePreferencesModal}
                        preferencesType={preferencesType}
                        selectedPreference={selectedPreference}
                        onCreated={onCreated}
                        onDeletePreference={onDeletePreference}
                        onSavePreference={onSavePreference}
                        onPreferenceSelect={onPreferenceSelect}
                        onResetPreference={onResetPreference}
                        {...props}
                    />
                )}
            />
        </>
    );
};

TableWithPreferencesManagement.propTypes = {
    preferencesType: PropTypes.string.isRequired,
};
