import { Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { getData } from "@/services/utils";
import { always } from "lodash/fp";
import qs from "qs";
import { useCallback } from "react";

export const useWfNameExistsValidation = ({
    accountId,
    skipValidation = always(false),
    isSingleSource,
    sourceType,
    sourceId,
}) => {
    const { axiosService } = useDic();

    return useCallback(
        async (name, getBag) => {
            if (skipValidation({ name, sourceType, sourceId }))
                return Forms.success();

            const params = qs.stringify(
                {
                    byName: name,
                    ...(isSingleSource ? { sourceType, sourceId } : {}),
                },
                {
                    addQueryPrefix: true,
                },
            );

            return axiosService
                .get(
                    `/api/accounts/${accountId}/event-workflows/exists${params}`,
                )
                .then(getData)
                .then(({ exist }) => {
                    if (exist) return Forms.error("Already exists");
                    else return Forms.success();
                });
        },
        [
            accountId,
            axiosService,
            isSingleSource,
            skipValidation,
            sourceId,
            sourceType,
        ],
    );
};
