import { Button, Gap, Link, Text } from "@/components/DesignSystem";
import React from "react";

const isEmpty = value =>
    value === null ||
    value === undefined ||
    (typeof value === "string" && value.length === 0) ||
    (typeof value === "number" && isNaN(value)) ||
    (Array.isArray(value) && value.length === 0);

export const Property = ({
    label,
    value,
    hideEmpty = false,
    href,
    onClick,
    ...rest
}) => {
    const empty = isEmpty(value);

    if (empty && hideEmpty) {
        return null;
    }

    return (
        <div>
            <Text type="secondary">{label}</Text>
            {empty ? (
                <Gap />
            ) : Array.isArray(value) ? (
                value.map((val, index) => (
                    <Property.Value
                        key={index}
                        href={href}
                        onClick={onClick}
                        {...rest}
                    >
                        {val}
                    </Property.Value>
                ))
            ) : (
                <>
                    <Property.Value href={href} onClick={onClick} {...rest}>
                        {value}
                    </Property.Value>
                </>
            )}
            <Gap />
        </div>
    );
};

Property.Value = ({ href, onClick, children, ...rest }) => {
    if (href) {
        return (
            <Link href={href} targetBlank displayIcon="hover" {...rest}>
                {children}
            </Link>
        );
    }

    if (onClick) {
        return (
            <Button type="link" onClick={onClick} {...rest} label={children} />
        );
    }

    return (
        <Text type="primary" {...rest}>
            {children}
        </Text>
    );
};
