import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import React from "react";
import IntegrationsList from "./IntegrationsList";

const IntegrationsOverviewPage = () => {
    useFullWidthTableLayout();
    return <IntegrationsList />;
};

export default IntegrationsOverviewPage;
