import { Gap, Table } from "@/components/DesignSystem";
import React from "react";
import {
    filterColumns,
    mapperColumns,
    propertyColumns,
} from "./CopyRoutePreview.columns";

export const CopyRoutePreview = ({ preview }) => {
    return (
        <>
            <Gap />
            <Table
                columns={propertyColumns}
                dataSource={preview.properties}
                rowKey="originalName"
            />
            {preview.mappers.length > 0 && (
                <>
                    <Gap />
                    <Table
                        columns={mapperColumns}
                        dataSource={preview.mappers}
                        rowKey="name"
                    />
                </>
            )}
            {preview.filters.length > 0 && (
                <>
                    f <Gap />
                    <Table
                        columns={filterColumns}
                        dataSource={preview.filters}
                        rowKey="name"
                    />
                </>
            )}
        </>
    );
};
