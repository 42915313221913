import { instancesServices } from "@/services/instance.service";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { axiosService } from "../../../../../axios";

export const integrationInitService = {
    validateConfiguration: async ({ instanceId, stateId }) => {
        const validationResponse = {
            messages: [],
            valid: false,
            template: {},
        };

        try {
            await instancesServices.fetchInstanceHealth(instanceId);
            validationResponse.messages.push({
                type: "success",
                message: t("integration-templates.init.available"),
            });
        } catch (error) {
            validationResponse.messages.push({
                type: "error",
                message: t("integration-templates.init.im-not-available"),
            });
            return validationResponse;
        }

        if (stateId) {
            try {
                const template = await axiosService
                    .get(`/api/package-states/${stateId}/template-info`)
                    .then(getData);
                validationResponse.template = {
                    label: template.label,
                    integrationUniqueName: template.uniqueName,
                    templateUniqueName: template.uniqueName,
                };
                validationResponse.templateLoading = false;
            } catch (error) {
                validationResponse.messages.push({
                    type: "error",
                    message: t(
                        "integration-templates.init.template-not-available",
                    ),
                });
            }
        } else {
            validationResponse.template = {
                integrationUniqueName: "",
                templateUniqueName: "",
            };
            validationResponse.templateLoading = false;
        }

        validationResponse.valid = true;
        return validationResponse;
    },
};
