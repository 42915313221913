import { axiosService } from "../axios";
import { getData } from "./utils";

export const integrationPropertiesService = {
    fetchProperties: (entityType, entityId) =>
        axiosService
            .get(`/api/properties/${entityType}/${entityId}`)
            .then(getData),

    fetchRepositoryRouteProperties: objectKey =>
        axiosService.get(`/api/properties/routes/${objectKey}`).then(getData),

    saveProperty(entityType, entityId, data) {
        return axiosService.post(
            `/api/properties/${entityType}/${entityId}`,
            JSON.stringify(data),
        );
    },

    deleteProperty(property) {
        return axiosService.delete(`/api/properties`, {
            data: JSON.stringify(property, [
                "id",
                "name",
                "entityId",
                "entityType",
            ]),
        });
    },
};
