import { H4, Tag } from "@/components/DesignSystem";
import { t } from "@/translations";
import React from "react";
export const TemplateDetailSidebar = ({ onTagClick, templateResource }) => {
    const availableTags = templateResource.loadable.valueMaybe()?.tags ?? [];

    return (
        <div
            style={{
                borderLeft: "1px solid #EBECF0",
                padding: "32px",
            }}
        >
            <H4>{t("marketplace.template.detail.tags")}</H4>
            {availableTags.map(tag => (
                <Tag
                    key={tag}
                    onClick={() => onTagClick(tag)}
                    style={{ marginBottom: "4px", cursor: "pointer" }}
                >
                    {tag}
                </Tag>
            ))}
        </div>
    );
};
