import WarningAlert from "@/components/Alerts/WarningAlert";
import { Button, CancelButton, Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import ConditionalAttributeErrorAlert from "@/components/Error/ConditionalAttributeErrorAlert";
import { useIsPristineWith } from "@/components/hooks/useIsPristineWith.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { LoadableRenderer, useQueryLoadable } from "@/modules/loadable";
import { VisibleWhenHasPermission } from "@/security/authorization";
import { USER_MANAGEMENT_EDIT_PERMISSION } from "@/security/permission.utils";
import { t } from "@/translations";
import { isEmailValid, isUserNameValid } from "@/utils/validation";
import PropTypes from "prop-types";
import React from "react";
import { usePostUserResource } from "./loadables";

export const FORM_TYPE = {
    CREATE: "CREATE",
    EDIT: "EDIT",
};

// TODO: api for validation that user already exists?
const usernameValidator = Forms.validators.failOnFirst([
    Forms.pmValidators.notBlank("user-form.error.missing-username"),
    Forms.pmValidators.emailValid,
    value =>
        isUserNameValid(value)
            ? Forms.success()
            : Forms.error(t("user-form.error.wrong-group-name-format")),
]);

// TODO: Memoize creators!
const firstNameValidator = Forms.pmValidators.notBlank(
    "user-form.error.missing-first-name",
);
const lastNameValidator = Forms.pmValidators.notBlank(
    "user-form.error.missing-last-name",
);
const emailValidator = Forms.validators.failOnFirst([
    Forms.pmValidators.notBlank("user-form.error.missing-email"),
    Forms.pmValidators.emailValid,
]);

const useMaybeDeactivatedUser = ({ username, canFetch }) => {
    const { userAdminService } = useDic();
    const deactivatedUserResource = useQueryLoadable(async () => {
        if (!canFetch || !username || !isEmailValid(username)) return;

        return userAdminService
            .fetchDeactivatedByUsername(username)
            .then(res => res.data);
    }, [username, canFetch, userAdminService]);

    const maybeDeactivatedUser = deactivatedUserResource.loadable.valueMaybe();

    return maybeDeactivatedUser;
};

export const useUserForm = ({
    initialValues,
    formType,
    onCancel,
    afterSave = onCancel,
    canFetch,
}) => {
    const postUserResource = usePostUserResource(afterSave);
    const onSubmit = ({ values }) => postUserResource.mutate(values);
    const form = Forms.useForm({ onSubmit });
    const maybeDeactivatedUser = useMaybeDeactivatedUser({
        username: Forms.useFieldValue({
            formId: form.formId,
            name: "username",
        }),
        canFetch: canFetch && formType === FORM_TYPE.CREATE,
    });
    useSetValidatedInitialValues(
        {
            initialValues: maybeDeactivatedUser || initialValues,
            setValues: form.setValues,
            setTouched: form.setTouched,
        },
        [maybeDeactivatedUser, initialValues],
    );
    const pristine = useIsPristineWith({ formId: form.formId, initialValues });

    const error = (
        <LoadableRenderer
            loadable={postUserResource.loadable}
            hasValue={() => null}
            loading={() => null}
            hasError={error => <ConditionalAttributeErrorAlert error={error} />}
        />
    );
    const warning = (
        <WarningAlert
            visible={!!maybeDeactivatedUser}
            message={t("user-form.warning.existing-user")}
        />
    );
    const submit = (
        <VisibleWhenHasPermission permission={USER_MANAGEMENT_EDIT_PERMISSION}>
            <Forms.SubmitButton
                formId={form.formId}
                key="submit"
                withoutSpinner
                disabled={pristine}
            >
                <Button
                    htmlType="submit"
                    type="primary"
                    onClick={form.handleSubmit}
                    label={t("general.save")}
                />
            </Forms.SubmitButton>
        </VisibleWhenHasPermission>
    );
    const cancel = (
        <CancelButton onClick={onCancel} key="cancel">
            {t("general.cancel")}
        </CancelButton>
    );

    return { form, formType, error, warning, submit, cancel, initialValues };
};

export const UserForm = ({
    initialValues,
    form,
    formType,
    error,
    warning,
    inputWidth,
}) => {
    return (
        <Forms.Form formId={form.formId} layout="vertical">
            {error}
            {warning}
            <Forms.FieldGroup inputWidth={inputWidth}>
                {formType === FORM_TYPE.EDIT && (
                    <Forms.Fields.Input
                        name="id"
                        type="hidden"
                        initialValue={initialValues?.id}
                    />
                )}
                <Forms.Fields.Input
                    name="username"
                    type="email"
                    label={t("user-form.label.username")}
                    disabled={formType === FORM_TYPE.EDIT}
                    required
                    validator={
                        formType === FORM_TYPE.EDIT
                            ? undefined
                            : usernameValidator
                    }
                />
                <Forms.Fields.Input
                    name="firstName"
                    label={t("user-form.label.first-name")}
                    required
                    validator={firstNameValidator}
                />
                <Forms.Fields.Input
                    name="lastName"
                    label={t("user-form.label.last-name")}
                    required
                    validator={lastNameValidator}
                />
                <Forms.Fields.Input
                    name="email"
                    type="email"
                    label={t("user-form.label.email")}
                    required
                    validator={emailValidator}
                />
                <Forms.Fields.Input
                    name="jobTitle"
                    label={t("user-form.label.job-title")}
                />
            </Forms.FieldGroup>
        </Forms.Form>
    );
};

UserForm.propTypes = {
    form: PropTypes.shape({
        formId: PropTypes.string.isRequired,
    }),
    initialValues: PropTypes.shape({
        id: PropTypes.any,
        username: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        jobTitle: PropTypes.string,
    }),
    formType: PropTypes.oneOf(Object.values(FORM_TYPE)),
    onCancel: PropTypes.func.isRequired,
    error: PropTypes.node,
    warning: PropTypes.node,
    inputWidth: PropTypes.string,
};
