import { useRowIds } from "@/components/DesignSystem/Forms/fieldsArray";
import { useCallback, useMemo } from "react";
import uuid from "uuid/v4";
import { useStateReinitialize } from "./useStateReinitialize.hook";

// Creates:
// - visible rows with possibility to remove/add (rowIds)
// - initial values later consumed by Forms
// - other values which are not validated/changed directly (converter  exp., input type)
const parse = (mapper = []) =>
    mapper.reduce(
        (
            acc,
            {
                id: maybeId,
                input,
                inputType,
                output,
                converterExpression,
                maxLength,
            },
        ) => {
            const id = maybeId ?? uuid();
            return {
                rowIds: [...acc.rowIds, id],
                initialValuesByRowId: {
                    ...acc.initialValuesByRowId,
                    [id]: { input, output },
                },
                valuesByRowId: {
                    ...acc.valuesByRowId,
                    [id]: {
                        converterExpression,
                        inputType,
                        maxLength,
                    },
                },
            };
        },
        {
            rowIds: [],
            initialValuesByRowId: {},
            valuesByRowId: {},
        },
    );

// Splits mapper state in three:
// - initial values for input/output managed by uc/Forms
// - settable values (inputType+converterExpression) not managed by uc/Forms (managed in modal, read-only in mapper table)
// - rowIds to know which rows to render
export const useMapperGridFormState = initMapper => {
    const parsed = useMemo(() => parse(initMapper), [initMapper]);
    const { rowIds, addRow, deleteRow } = useRowIds({
        reinitRowIds: parsed.rowIds,
    });
    const [valuesByRowId, setValuesByRowId] = useStateReinitialize(
        parsed.valuesByRowId,
    );
    const mergeRow = useCallback(
        (rowId, source) =>
            setValuesByRowId(rowsMap => ({
                ...rowsMap,
                [rowId]: { ...rowsMap[rowId], ...source },
            })),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    const setInputType = useCallback(
        (rowId, inputType) => {
            if (!rowId || !inputType)
                throw new Error(
                    `Missing params: rowId:${rowId} inputType:${inputType}`,
                );
            mergeRow(rowId, { inputType });
        },
        [mergeRow],
    );
    const setConverter = useCallback(
        (rowId, { value, readOnly = false }) =>
            mergeRow(rowId, {
                converterExpression: value,
                converterExpressionReadOnly: readOnly,
            }),
        [mergeRow],
    );
    const setRowValues = useCallback(
        (rowId, values) => mergeRow(rowId, values),
        [mergeRow],
    );
    return {
        rowIds,
        addRow,
        deleteRow,
        initialValuesByRowId: parsed.initialValuesByRowId,
        valuesByRowId, // [rowId]: { inputTypes, converters }
        setInputType,
        setConverter,
        setRowValues,
    };
};
