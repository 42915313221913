import {
    useCalculationEnginesQuery,
    useDeleteCalculationEngineMutation,
} from "@/components/CalculationEngines/loadables";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import {
    HEADER_HEIGHT_WITH_BREADCRUMB,
    fieldTypes,
} from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { useBreadcrumbButtons } from "@/components/hooks/useBreadcrumbButtons.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { getCalculationEnginesListTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { isLoading } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";

const preferencesType = preferencesTypes.CALCULATION_ENGINES_LIST_TABLE;

const columns = [
    {
        type: fieldTypes.TEXT,
        name: "name",
        label: t("calculation-engines.list.columns.engines.label"),
    },
    // {
    //     type: fieldTypes.TEXT,
    //     name: "validTo",
    //     label: "Valid to",
    //     render: validTo => <FormattedDate>{validTo}</FormattedDate>,
    //     // render: validTo => <FormattedDateTime>{validTo}</FormattedDateTime>,
    // },
    {
        type: fieldTypes.TEXT,
        name: "status",
        label: "Status",
    },
    {
        type: fieldTypes.TEXT,
        name: "workflowStatus",
        label: "Workflow Status",
    },
    {
        type: fieldTypes.TEXT,
        name: "reason",
        label: "Reason",
    },
];

export const CalculationEnginesPage = () => {
    const { accountId, partitionId } = useAccountAppParams();
    useFullWidthTableLayout();

    const listQuery = useCalculationEnginesQuery({ partitionId });
    const dataSource = listQuery.loadable.valueMaybe() ?? [];
    const loading = isLoading(listQuery);
    const deleteMutation = useDeleteCalculationEngineMutation({
        partitionId,
        afterSuccess: listQuery.reload,
    });

    const {
        locationRouterService,
        accountAppLocations: { calculationEngineLocation },
    } = useDic();
    const onEdit = useCallback(
        ({ engineId = "new" }) => {
            locationRouterService.navigate(calculationEngineLocation, {
                engineId,
            });
        },
        [calculationEngineLocation, locationRouterService],
    );
    useBreadcrumbButtons(
        () => [
            {
                type: "primary",
                label: "calculation-engines.action.new",
                onClick: () => onEdit({}),
                // permissions: [],
            },
        ],
        [onEdit],
    );
    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.edit"),
                        onClick: onEdit,
                        visible: record.canEdit,
                        // visible: ["DENIED", "APPROVED", null].includes(record.workflowStatus),
                        track: {
                            name: getTrackName("Edit"),
                        },
                    },
                    // {
                    //     title: t("calculation-engines.action.retry.title"),
                    //     disabled: true,
                    //     onClick: record => {
                    //         throw new Error("TODO");
                    //     },
                    //     track: {
                    //         name: getTrackName("Retry"),
                    //     },
                    // },
                    {
                        title: t("general.delete"),
                        // permission: [],
                        confirmMessage: t(
                            "general.do-you-really-want-to-delete-name",
                            { name: record.name },
                        ),
                        onConfirm: deleteMutation.mutate,
                        visible: record.canDelete,
                        // visible: ["APPROVED", null].includes(record.workflowStatus) || (record.workflowStatus === "DENIED" && record.existsInConfig),
                        color: "red",
                        track: {
                            name: getTrackName("Delete"),
                        },
                    },
                ]}
            />
        ),
        [deleteMutation.mutate, onEdit],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            fixed
            restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
            rowKey="id"
            datasetSlicing="local"
            preferencesType={preferencesType}
        />
    );
};
