import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { isLoading } from "@/modules/loadable";
import { ModalProviderPropsPropType } from "@/modules/modal/CustomModal";
import { t } from "@/translations";
import { get, merge, pick, pipe } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import { getCreateLocalCopyModalTrackName as getTrackName } from "../../mixpanel/buttonNames";

export const CreateLocalCopyModal = ({
    modalProviderProps,
    instanceId,
    record,
    afterSuccess,
    fieldName,
    useLocalCopyMutation,
    nameValidator,
    entityName,
}) => {
    const localCopyMutation = useLocalCopyMutation({
        instanceId,
        afterSuccess: () => {
            modalProviderProps.onClose();
            afterSuccess();
        },
    });

    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit: pipe(
            get("values"),
            pick([fieldName]),
            merge({ ...record, parent: record[fieldName] }),
            localCopyMutation.mutate,
        ),
    });
    useSetValidatedInitialValues({
        initialValues: pick([fieldName], record),
        setValues,
        setTouched,
    });

    const disabled = isLoading(localCopyMutation);

    return (
        <Modal {...modalProviderProps}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("mapper.local-copy.modal.title", {
                        entityName,
                    })}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Forms.Form formId={formId} onSubmit={handleSubmit}>
                        <Forms.FieldGroup width="max" inputWidth="max">
                            <Forms.Fields.Input
                                required
                                autoFocus
                                name={fieldName}
                                label={t("mapper.local-copy.form.name.label")}
                                placeholder={t(
                                    "mapper.local-copy.form.name.placeholder",
                                )}
                                validator={nameValidator}
                            />
                        </Forms.FieldGroup>
                    </Forms.Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    formId,
                                    label: t("general.copy"),
                                    type: "primary",
                                    disabled,
                                    track: { name: getTrackName("Copy") },
                                },
                                {
                                    label: t("general.cancel"),
                                    type: "text",
                                    disabled,
                                    onClick: modalProviderProps.onClose,
                                    track: { name: getTrackName("Cancel") },
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

CreateLocalCopyModal.propTypes = {
    instanceId: PropTypes.number.isRequired,
    record: PropTypes.object.isRequired,
    afterSuccess: PropTypes.func,
    modalProviderProps: ModalProviderPropsPropType(),
    fieldName: PropTypes.string.isRequired,
    useLocalCopyMutation: PropTypes.func.isRequired,
    nameValidator: PropTypes.func.isRequired,
    entityName: PropTypes.string,
};
