import {
    Forms,
    ModalOrNothing,
    SubmitButtonTempFix,
} from "@/components/DesignSystem";
import React from "react";

export const PartitionConnectionForm = ({
    accountId,
    partitionId,
    initialValues: { definition = {}, ...rest } = {},
    onSubmit,
    onCancel,
    isModalVariant,
}) => {
    const { Form, formId, handleSubmit } = Forms.useForm({
        onSubmit: ({ values: { name, ...rest } }) =>
            onSubmit({
                partitionId,
                name,
                definition: {
                    ...rest,
                },
            }),
        initialValues: { ...rest, ...definition },
    });
    const actionButtons = [
        {
            label: "Save",
            formId,
            type: "primary",
            htmlType: "submit",
            Component: SubmitButtonTempFix,
        },
        {
            label: "Cancel",
            type: "text",
            htmlType: "button",
            onClick: onCancel,
        },
    ];

    return (
        <ModalOrNothing
            isEnabled={isModalVariant}
            actionButtons={actionButtons}
            alwaysWithButtons
        >
            <Form disableBanner onSubmit={handleSubmit}>
                <Forms.Fields.Input
                    name="name"
                    label="Name"
                    required
                    validator={Forms.pmValidators.isRequired}
                />
                <Forms.Fields.Input
                    name="type"
                    label="Connection Type"
                    initialValue="sftp"
                    disabled
                />
                <Forms.Fields.Input name="host" label="Host" />
                <Forms.Fields.InputNumber name="port" label="Port" />
                <Forms.Fields.Input name="username" label="Username" />
                <Forms.Fields.InputPassword name="password" label="Password" />
            </Form>
        </ModalOrNothing>
    );
};
