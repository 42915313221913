import { Button, Forms, Gap } from "@/components/DesignSystem";
import { ReactComponent as IconDownload } from "@pricefx/unity-components/dist/es/icons/unicons/download-alt.svg";
import PropTypes from "prop-types";
import React from "react";

export const TaskDone = ({ task, filename = "archive-query.zip" }) => {
    const link = task.responseData.link;
    const fileList = task.responseData.index;
    const { formId, handleSubmit } = Forms.useForm({
        initialValues: { _results: fileList.join("\n") },
        onSubmit: ({ values }) => console.log("onSubmit", { values }),
    });

    return (
        <Forms.Form formId={formId} onSubmit={handleSubmit}>
            <Forms.Fields.TextArea
                width="max"
                inputWidth="max"
                name="_results"
                label={"File Results"}
                placeholder=""
                allowClear={false}
                rows={"8"}
            />
            {!!link && (
                <Button
                    type="default"
                    label={"Download .zip"}
                    icon={IconDownload}
                    href={link}
                    download={filename}
                />
            )}
            <Gap />
        </Forms.Form>
    );
};
TaskDone.propTypes = {
    task: PropTypes.object.isRequired,
    filename: PropTypes.string,
};
