import { t } from "@/translations";

export const TYPE = {
    PARTITION_JOB: "PARTITION_JOB",
    ROUTE_CHECK: "ROUTE_CHECK",
    WORKFLOW: "WORKFLOW",
};

export const ALL_TRIGGERS_VALUE = "ALL";
export const ALL_TARGETS_VALUE = "ALL";
export const DID_NOT_RUN_VALUE = "DID_NOT_RUN";
export const DID_NOT_RUN_LABEL = "Did Not Run";

export const ALERT_TRIGGER_STATE_BY_TYPE = {
    [TYPE.PARTITION_JOB]: {
        [ALL_TRIGGERS_VALUE]: "All",
        WAITING_FOR_DISPATCH: t(
            "partition.job-task-tracking.list.status.waiting",
        ),
        QUEUED_FOR_EXECUTION: t(
            "partition.job-task-tracking.list.status.queued",
        ),
        PROCESSING: t("partition.job-task-tracking.list.status.processing"),
        FAILED: t("partition.job-task-tracking.list.status.failed"),
        FINISHED: t("partition.job-task-tracking.list.status.finished"),
        CANCELLED: t("partition.job-task-tracking.list.status.cancelled"),
        INTERRUPTED: t("partition.job-task-tracking.list.status.interrupted"),
        [DID_NOT_RUN_VALUE]: DID_NOT_RUN_LABEL,
    },
    [TYPE.ROUTE_CHECK]: {
        [ALL_TRIGGERS_VALUE]: "All",
        SUCCESS: "Success",
        ERROR: "Error",
        WARNING: "Warning",
        [DID_NOT_RUN_VALUE]: DID_NOT_RUN_LABEL,
    },
    [TYPE.WORKFLOW]: {
        [ALL_TRIGGERS_VALUE]: "All",
        WORKFLOW_SUCCESS: "Success",
        WORKFLOW_ERROR: "Error",
    },
};

export const SCHEDULE_TYPE = {
    REALTIME: "REALTIME",
    SCHEDULED: "SCHEDULED",
};

export const ENTITY_TYPE = {
    PARTITION: "PARTITION",
    INTEGRATION_MANAGER_INSTANCE: "INTEGRATION_MANAGER_INSTANCE",
    WORKFLOW: "WORKFLOW",
};

export const REAL_TIME_MONITORING_LABEL = "Real Time Monitoring";
export const NOTIFICATION_TYPE_OPTIONS = [
    { label: REAL_TIME_MONITORING_LABEL, value: SCHEDULE_TYPE.REALTIME },
    {
        label: "Scheduled Time & Lookback Interval",
        value: SCHEDULE_TYPE.SCHEDULED,
    },
];

export const TYPE_OPTIONS = [
    {
        label: TYPE.PARTITION_JOB,
        value: TYPE.PARTITION_JOB,
    },
    {
        label: TYPE.ROUTE_CHECK,
        value: TYPE.ROUTE_CHECK,
    },
    {
        label: TYPE.WORKFLOW,
        value: TYPE.WORKFLOW,
    },
];

export const MAX_RECIPIENTS_CHARS_LENGTH = 4000;
