import { getData } from "@/services/utils";
import { buildPageableUrl } from "@/utils/urlUtils";
import qs from "qs";
import { axiosService } from "../../axios";
import { CURRENCIES } from "../../constants/SelectOptionTypes";

const DEPLOYMENT_ENDPOINT = "/api/packages/deployments";
const DEPLOYMENT_STATE_ENDPOINT = "/api/packages/deployments/states";

const qsOptions = { skipNulls: true };

const checkDeployability = ({
    packageName,
    templateVersion,
    partitionId,
    instanceId,
}) => {
    const queryStr = qs.stringify(
        { partitionId, instanceId, templateVersion },
        qsOptions,
    );

    return axiosService
        .post(`api/packages/${packageName}/check?${queryStr}`)
        .then(getData);
};

const startDeployment = (
    packageName,
    partitionId,
    instanceId,
    templateVersion,
) => {
    const queryStr = qs.stringify(
        { partitionId, instanceId, templateVersion },
        qsOptions,
    );

    return axiosService.post(`/api/packages/${packageName}?${queryStr}`);
};

const getStartDeploymentState = (packageName, taskId) => {
    return axiosService.get(`/api/packages/${packageName}/tasks/${taskId}`);
};

const restartDeploymentStep = (packageName, partitionId, instanceId) => {
    return axiosService.put(
        `/api/packages/${packageName}?${qs.stringify({
            partitionId,
            instanceId,
        })}`,
    );
};

const getDeploymentState = (
    packageName,
    partitionId,
    instanceId,
    templateVersion,
) => {
    const queryStr = qs.stringify(
        { partitionId, instanceId, templateVersion },
        qsOptions,
    );

    return axiosService
        .get(`/api/packages/${packageName}?${queryStr}`)
        .then(getData);
};

const addDataToStep = (
    packageName,
    partitionId,
    instanceId,
    stepName,
    data,
) => {
    const body = data ? JSON.stringify(data) : null;
    const queryStr = qs.stringify(
        partitionId ? { partitionId } : { instanceId },
        qsOptions,
    );

    return axiosService
        .post(
            `/api/packages/${packageName}/steps/${stepName}?${queryStr}`,
            body,
        )
        .then(getData);
};

const getUiSteps = (packageName, partitionId, instanceId) => {
    const partitionQuery = partitionId ? "partitionId=" + partitionId : "";
    const instanceQuery = instanceId ? "instanceId=" + instanceId : "";

    return axiosService
        .get(
            `/api/packages/${packageName}/steps?` +
                partitionQuery +
                instanceQuery,
        )
        .then(getData);
};

const fetchCurrencies = () =>
    axiosService.get(`/api/select-options?selectNames=${CURRENCIES}`);

const fetchStepProcessCache = stepId =>
    axiosService.get(`/api/packages/cache/${stepId}`);

const setStepProcessCache = (stepId, data) =>
    axiosService.post(`/api/packages/cache/${stepId}`, JSON.stringify(data));

const sendNotificationRequest = stepId =>
    axiosService.post(`/api/packages/event-notification/${stepId}`);

const deleteNotificationRequest = stepId =>
    axiosService.delete(`/api/packages/event-notification/${stepId}`);

const getExampleFile = (projectId, partitionId, filePath) =>
    axiosService.post(
        ` /api/projects/${projectId}/partitions/${partitionId}/files/exampleFile`,
        filePath,
    );

const rollbackDeployment = ({
    accountId,
    partitionId,
    deploymentId,
    rollbackOptions,
}) =>
    axiosService.post(
        `/api/accounts/${accountId}/package-deployments/${deploymentId}/partitions/${partitionId}`,
        { rollback: rollbackOptions },
    );

const requestRollback = ({
    accountId,
    partitionId,
    deploymentId,
    rollbackOptions,
}) =>
    axiosService.post(
        `/api/accounts/${accountId}/package-deployments/${deploymentId}/partitions/${partitionId}/request-rollback`,
        { rollback: rollbackOptions },
    );

const requestTermination = ({ deploymentId, accountId, partitionId }) =>
    axiosService.post(
        `/api/accounts/${accountId}/package-deployments/${deploymentId}/partitions/${partitionId}/request-termination
            `,
    );

const getAllDeployedPackages = (page, size, sort, filter) =>
    axiosService.post(
        buildPageableUrl(DEPLOYMENT_ENDPOINT, page, size, sort),
        filter,
    );

const getDeployedPackagesFiltered = (page, size, sort, filter, accountId) =>
    axiosService.post(
        buildPageableUrl(
            `${DEPLOYMENT_ENDPOINT}/${accountId}`,
            page,
            size,
            sort,
        ),
        filter,
    );

const getDeployedPackageState = id =>
    axiosService.get(`${DEPLOYMENT_STATE_ENDPOINT}/${id}`).then(getData);

const getDeployedPackagesForAccount = (page, size, sort, filter, accountId) =>
    axiosService.post(
        buildPageableUrl(
            `/api/accounts/${accountId}/package-deployments`,
            page,
            size,
            sort,
        ),
        filter,
    );

const getDeployedPackagesForAccountPartition = (
    page,
    size,
    sort,
    filter,
    accountId,
    partitionId,
) =>
    axiosService.post(
        buildPageableUrl(
            `/api/accounts/${accountId}/package-deployments/partitions/${partitionId}`,
            page,
            size,
            sort,
        ),
        filter,
    );

const getDeployedPackagesForAccountInstance = (
    page,
    size,
    sort,
    filter,
    accountId,
    instanceId,
) =>
    axiosService.post(
        buildPageableUrl(
            `/api/accounts/${accountId}/package-deployments/instances/${instanceId}`,
            page,
            size,
            sort,
        ),
        filter,
    );

const validateGenericForm = ({ templateName, formValues, query }) => {
    const queryStr = qs.stringify(query);
    return axiosService.post(
        `/api/packages/${templateName}/validate?${queryStr}`,
        formValues,
    );
};
export const packageService = {
    addDataToStep,
    checkDeployability,
    fetchStepProcessCache,
    setStepProcessCache,
    fetchCurrencies,
    getDeploymentState,
    getUiSteps,
    startDeployment,
    getStartDeploymentState,
    restartDeploymentStep,
    sendNotificationRequest,
    deleteNotificationRequest,
    getExampleFile,
    rollbackDeployment,
    requestRollback,
    requestTermination,
    getAllDeployedPackages,
    getDeployedPackagesFiltered,
    getDeployedPackageState,
    getDeployedPackagesForAccount,
    getDeployedPackagesForAccountPartition,
    getDeployedPackagesForAccountInstance,
    validateGenericForm,
};
