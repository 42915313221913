import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";

export const imInstancesLimitColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("im-instances-limit.column.project-name"),
        name: "name",
    },
    {
        type: fieldTypes.INTEGER,
        label: t("im-instances-limit.column.max"),
        name: "maxIntegrationInstances",
        render: text =>
            text === null ? t("im-instances-limit.edit.unlimited.label") : text,
    },
    {
        type: fieldTypes.INTEGER,
        label: t("im-instances-limit.column.existing"),
        name: "existingInstances",
    },
];
