import { axiosService } from "../axios";

const workflowService = {
    getWorkflows() {
        return axiosService.get("/api/admin/workflows");
    },

    createOrUpdateWorkflow(values, workflowId) {
        return axiosService.post(
            `/api/admin/workflows${workflowId ? `/${workflowId}` : ""}`,
            values,
        );
    },

    deleteWorkflow(workflowId) {
        return axiosService.delete(`/api/admin/workflows/${workflowId}`);
    },

    getFilteredAdminUserList(username) {
        return axiosService.get(`/api/admin/users/filtered?filter=${username}`);
    },
};

export default workflowService;
