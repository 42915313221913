import { useEffect, useRef } from "react";

export const useDelayedEffect = (callback, delay, deps = []) => {
    const callbackRef = useRef(callback);
    if (callbackRef.current !== callback) callbackRef.current = callback;

    useEffect(() => {
        if (typeof delay === "number") {
            const id = setTimeout(() => callbackRef.current(), delay);
            return () => clearTimeout(id);
        }
    }, [delay, ...deps]);
};
