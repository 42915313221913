import { Link } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";

export const columns = onClick => [
    {
        type: fieldTypes.TEXT,
        label: t("account-admin-assets-role-list.header.name"),
        name: "name",
        className: "pmTable__column--withActionButton",
        render: (text, record) => {
            return <Link onClick={() => onClick(record)}>{record.name}</Link>;
        },
    },
    {
        type: fieldTypes.TEXT,
        label: t("account-admin-assets-role-list.header.description"),
        name: "description",
    },
];
