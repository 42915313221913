import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { OffersTableContainer } from "@/components/Offers/OffersTable.container";
import React from "react";

export const OffersTablePage = () => {
    useFullWidthTableLayout();

    return <OffersTableContainer />;
};

OffersTablePage.propTypes = {};
