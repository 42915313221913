import { Forms } from "@/components/DesignSystem";
import SelectPFXData from "@/components/GenericForm/components/SelectPfxData.component";
import { hasSubFilter } from "@/constants/SupportedTableImportTypes";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useMemo } from "react";
const { Fields } = Forms;

const EntityPFXData = ({
    formId,
    fieldProps,
    objectId,
    objectType,
    field,
    setValues,
}) => {
    const entityType = useFieldValue({
        formId,
        name: field.name,
    });
    const entityNameRequired =
        field.table?.required ?? hasSubFilter(entityType);

    const resetChildSelect = useCallback(
        () =>
            setValues({
                [field.table.name]: undefined,
            }),
        [field.table.name, setValues],
    );
    const validator = useMemo(
        () =>
            Forms.validators.failOnFirst(
                [entityNameRequired && Forms.pmValidators.isRequired].filter(
                    Boolean,
                ),
            ),
        [entityNameRequired],
    );

    return (
        <>
            <Fields.Select
                {...fieldProps}
                name={field.name}
                options={field.options}
                onChange={resetChildSelect}
            />
            {entityNameRequired && (
                <SelectPFXData
                    selectProps={{
                        name: field.table.name,
                        label: field.table.label,
                        disabled: field.table?.disabled ?? false,
                        required: entityNameRequired,
                        validator,
                    }}
                    field={field}
                    objectId={objectId}
                    objectType={objectType}
                    entityType={entityType}
                    setValues={setValues}
                />
            )}
        </>
    );
};

EntityPFXData.propTypes = {
    formId: PropTypes.string.isRequired,
    fieldProps: PropTypes.object.isRequired,
    objectId: PropTypes.number.isRequired,
    objectType: PropTypes.string,
    field: PropTypes.object.isRequired,
    setValues: PropTypes.func.isRequired,
};

export default EntityPFXData;
