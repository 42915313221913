import {
    fieldTypes,
    HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT,
} from "@/components/DesignSystem/Table/constants";
import {
    useLoggersListQuery,
    useSetLoggerLevelMutation,
} from "@/components/Integrations/Dashboard/Tabs/Loggers/loadables";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import { hasValue, isLoading } from "@/modules/loadable";
import { enabledWhenAuthorizedByPermissionOr } from "@/security/authorization";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import map from "lodash/map";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { LoggerLevelsButtonGroup } from "../LoggerLevelsButtonGroup";

export const mapLoggersToTable = loggers => {
    return map(loggers, (value, key) => ({
        component: key,
        effectiveLevel: value.effectiveLevel,
    }));
};

const LoggerLevelsActions = enabledWhenAuthorizedByPermissionOr(
    INTEGRATION_EDIT_PERMISSIONS,
)(LoggerLevelsButtonGroup);

const LoggersTable = ({ instanceId }) => {
    const loggersResource = useLoggersListQuery({ instanceId });

    const { loggers, levels } = hasValue(loggersResource.loadable)
        ? loggersResource.loadable.valueMaybe()
        : { loggers: {}, levels: [] };

    const dataSource = useMemo(() => mapLoggersToTable(loggers), [loggers]);

    const setLoggerLevelMutation = useSetLoggerLevelMutation({
        instanceId,
        afterSuccess: loggersResource.reload,
    });

    return (
        <TableLocalFiltered
            fixed
            restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
            loading={isLoading(loggersResource)}
            dataSource={dataSource}
            rowKey="component"
            columns={[
                {
                    type: fieldTypes.TEXT,
                    label: t("logger-panel.column.component"),
                    name: "component",
                },
                {
                    type: fieldTypes.OPTION,
                    label: t("logger-panel.column.level"),
                    name: "effectiveLevel",
                    options: levels,
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("logger-panel.column.actions"),
                    name: "actions",
                    canFilter: false,
                    canSort: false,
                    width: "380px",
                    render: (_, record) => (
                        <LoggerLevelsActions
                            levels={levels}
                            effectiveLevel={record.effectiveLevel}
                            component={record.component}
                            onChange={(component, level) =>
                                setLoggerLevelMutation.mutate({
                                    component,
                                    level,
                                })
                            }
                        />
                    ),
                },
            ]}
        />
    );
};

LoggersTable.propTypes = {
    instanceId: PropTypes.number.isRequired,
};

export default LoggersTable;
