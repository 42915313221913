import {
    ButtonMenu,
    Input,
    Modal,
    Option,
    Select,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { t } from "@/translations";
import { Form } from "antd";
import React from "react";
import { getCopyConnectionDialogTrackName } from "../../mixpanel/buttonNames";
import ConditionalErrorAlert from "../Error/ConditionalErrorAlert";

export const CopyConnectionDialog = ({
    defaultName,
    onCancel,
    onCopy,
    errorMessage,
    onInstanceSelect,
    instances,
    connections,
    visible,
}) => {
    const PATTERN_CONNECTION_NAME = /^[a-zA-Z0-9_:.-]*$/;

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("connection.copy.modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    {errorMessage && (
                        <ConditionalErrorAlert
                            error={errorMessage}
                            size="medium"
                        />
                    )}
                    <Form
                        id="copyConnectionForm"
                        onFinish={onCopy}
                        initialValues={{
                            ["name"]: defaultName,
                        }}
                    >
                        <Text>
                            {t("connection.copy.label.integration-instances")}
                        </Text>
                        <Form.Item
                            name="targetInstanceId"
                            rules={[
                                {
                                    required: true,
                                    message: t("general.validation.required"),
                                },
                            ]}
                        >
                            <Select onSelect={onInstanceSelect}>
                                {instances.map(instance => (
                                    <Option
                                        title={instance.instanceName}
                                        key={instance.id}
                                        value={instance.id}
                                    >
                                        {instance.instanceName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Text>
                            {t("connection.copy.label.connection-name")}
                        </Text>
                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: t("general.validation.required"),
                                },
                                {
                                    pattern: PATTERN_CONNECTION_NAME,
                                    message:
                                        t("general.validation.pattern") +
                                        PATTERN_CONNECTION_NAME,
                                },
                                props => ({
                                    validator: (rule, value) => {
                                        let fieldValue =
                                            props.getFieldValue("name");

                                        if (connections.includes(fieldValue)) {
                                            return Promise.reject(
                                                t(
                                                    "connection.copy.validation-message.name-duplication",
                                                ),
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.copy"),
                                    type: "primary",
                                    form: "copyConnectionForm",
                                    htmlType: "submit",
                                    track: {
                                        name: getCopyConnectionDialogTrackName(
                                            "Submit",
                                        ),
                                    },
                                },
                                {
                                    type: "text",
                                    onClick: onCancel,
                                    label: t("general.cancel"),
                                    track: {
                                        name: getCopyConnectionDialogTrackName(
                                            "Cancel",
                                        ),
                                    },
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
