import { useAppProperties } from "@/components/AppProperties/useAppProperties.hook";
import { LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import React from "react";

export const AppVersion = () => {
    const { appPropertiesResource } = useAppProperties();

    return (
        <LoadableRenderer
            loadable={appPropertiesResource.loadable}
            loading={() => null}
            hasValue={({ data: appInfo }) => (
                <>
                    {t("sidebar.footer.be-version", {
                        version: appInfo.platformVersion,
                    })}
                    <br />
                    {t("sidebar.footer.fe-version", {
                        version: process.env.VERSION,
                    })}
                </>
            )}
        />
    );
};
