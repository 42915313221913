import { Button, ButtonGroup, Forms, Spinner } from "@/components/DesignSystem";
import { EntitySelectFields } from "@/components/Mappers/ExportMapper/EntitySelectFields";
import { ExportMapperList } from "@/components/Mappers/ExportMapper/ExportMapperList";
import { useOutputOptions } from "@/components/Mappers/MapperTableWithCustomization/loadableResurces";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import {
    LoadableRenderer,
    useComposeLoadablesMemoized,
    useMapLoadableMemoized,
} from "@/modules/loadable";
import { mapperMappingSourceType } from "@/services/mapperMappingSourceType.enum";
import { t } from "@/translations";
import { noop } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import uuid from "uuid/v4";

const CONVERT_EMPTY_VALUES_OPTIONS = [
    { value: false, label: t("mapper.convert-empty.empty") },
    { value: true, label: t("mapper.convert-empty.null") },
];

const createMapperFromOptions = options =>
    options.map(({ value, type, label }) => ({
        id: uuid(),
        input: value,
        inputType: "body",
        type, // just for UI, should not be sent to api
        output: value,
    }));

const removeTypeFromMapper = values => ({
    ...values,
    mapper: values.mapper?.map(({ type, ...row }) => row),
});

export const ExportMapperInner = ({
    onSubmit,
    onBack,
    onCancel = noop,
    connectionId,
    readOnly = false,
}) => {
    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit: ({ values }) => onSubmit(removeTypeFromMapper(values)),
    });

    const { entityType, entityName, requiredSelected } =
        EntitySelectFields.useEntitySelectFields({
            formId,
        });
    const optionsQuery = useOutputOptions({
        sourceType: mapperMappingSourceType.connections,
        sourceId: connectionId,
        entityType,
        entityName,
        canFetch: requiredSelected,
        includeAll: true,
    });
    const initialMapperLoadable = useMapLoadableMemoized(
        optionsQuery.loadable,
        createMapperFromOptions,
    );
    const submitDisabled = !initialMapperLoadable.valueMaybe();

    return (
        <div data-test="export-mapper">
            <Forms.Form formId={formId} onSubmit={handleSubmit}>
                <EntitySelectFields
                    formId={formId}
                    sourceId={connectionId}
                    setTouched={setTouched}
                    setValues={setValues}
                />
                <LoadableRenderer
                    loadable={useComposeLoadablesMemoized([
                        initialMapperLoadable,
                        optionsQuery.loadable,
                    ])}
                    loading={() => <Spinner />}
                    hasError={error => null}
                    hasValue={([initialMapper, options]) => (
                        <>
                            <ExportMapperList
                                name="mapper"
                                initialValues={initialMapper}
                                options={options}
                                setValues={setValues}
                                readOnly={readOnly}
                                formId={formId}
                            />
                            <Forms.Fields.Radio
                                name="convertEmptyStringToNull"
                                label={t(
                                    "mapper-form.form.send-empty-value-as",
                                )}
                                initialValue={false}
                                options={CONVERT_EMPTY_VALUES_OPTIONS}
                                validator={Forms.pmValidators.isRequired}
                            />
                        </>
                    )}
                />
                <ButtonGroup>
                    <Forms.SubmitButton
                        formId={formId}
                        disabled={submitDisabled}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            label={"Export"}
                        />
                    </Forms.SubmitButton>
                    <BackButton onBack={onBack} />
                    <Button type="text" label={"Cancel"} onClick={onCancel} />
                </ButtonGroup>
            </Forms.Form>
        </div>
    );
};

ExportMapperInner.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    convertEmptyStringToNull: PropTypes.bool,
    connectionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
};

export const ExportMapper = ({ apiResult, onNext, onBack, onCancel }) => {
    const {
        currentStepData: { name, type, connectionId },
    } = apiResult;

    if (!connectionId) throw new Error("Missing connectionId");

    const handleSubmit = useCallback(
        ({ entityName, entityType, convertEmptyStringToNull, ...values }) => {
            onNext({
                name,
                type,
                params: { objectType: entityType, entityName },
                mapperProperties: { convertEmptyStringToNull },
                ...values,
            });
        },
        [name, onNext, type],
    );

    return (
        <ExportMapperInner
            connectionId={connectionId}
            onSubmit={handleSubmit}
            onBack={onBack}
            onCancel={onCancel}
        />
    );
};

ExportMapper.propTypes = {
    apiResult: PropTypes.object.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
};
