import Changed from "@/components/DateTime/Changed";
import FormattedDate from "@/components/DateTime/FormattedDate";
import { Link, Status, Tag } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import {
    getHealthStateTranslation,
    instanceHealthStates,
} from "@/components/InstanceHealth/utils";
import { IM_OPERATIONAL_STATE } from "@/components/Integrations/InstancesList";
import { t } from "@/translations";
import { map } from "lodash/fp";
import { capitalize } from "lodash/string";
import React from "react";
import { InstanceHealth } from "../InstanceHealth/InstanceHealth";
import { InstanceMonitored } from "../InstanceMonitored/InstanceMonitored";
import { IntegrationLink } from "../Integrations/IntegrationLink.component";
import {
    formatCreatedAt,
    formatMonitored,
    formatReportMonitored,
    monitoringOptions,
} from "../Integrations/IntegrationsList.columns";
import { IntegrationManagerInstanceState } from "./IntegrationManagerInstanceState";

export const noRenderForParent = render => {
    return (text, record) => {
        if (record._isExpandable) {
            return null;
        }
        return render ? render(text, record) : text;
    };
};

export const createColumns = ({ accountId, getDefaultLocation }) => [
    {
        type: fieldTypes.TEXT,
        label: t("instance-list.header.instance-name"),
        name: "instanceName",
        className: "pmTable__column--withActionButton",
        render: (text, record) => (
            <>
                {record.state === "CREATED" ||
                record.state === "REQUESTED" ||
                record.state === "STOPPED_BY_WORKFLOW" ? (
                    <IntegrationLink
                        accountId={accountId}
                        instanceId={record.id}
                        getDefaultLocation={getDefaultLocation}
                    >
                        {record.instanceName}
                    </IntegrationLink>
                ) : (
                    record.instanceName
                )}
            </>
        ),
    },
    {
        type: fieldTypes.OPTION,
        label: t("instance-list.header.state"),
        name: "state",
        options: [
            { value: "CREATED", label: "Created" },
            { value: "UPGRADING", label: "Upgrading" },
            { value: "REQUESTED", label: "Requested" },
            { value: "REQUEST_FAILED", label: "Requested Failed" },
            { value: "PENDING", label: "Pending" },
            { value: "STOPPED_BY_WORKFLOW", label: "Stopped by Workflow" },
            { value: "DELETED", label: "Deleted" },
            { value: "DELETE_REQUESTED", label: "Delete Requested" },
            { value: "DELETE_FAILED", label: "Delete Failed" },
        ],
        render: noRenderForParent((text, record) => {
            return <IntegrationManagerInstanceState state={record.state} />;
        }),
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance-list.header.operational-state"),
        name: "operationalState",
        render: noRenderForParent(operationalState => {
            return operationalState ? (
                <Status
                    loading={[
                        IM_OPERATIONAL_STATE.STARTING,
                        IM_OPERATIONAL_STATE.STOPPING,
                        IM_OPERATIONAL_STATE.INITIATED_UPDATE,
                        IM_OPERATIONAL_STATE.REQUESTED_UPDATE,
                    ].includes(operationalState)}
                    type={
                        operationalState === IM_OPERATIONAL_STATE.STARTED
                            ? Status.type.success
                            : null
                    }
                    text={t(
                        `instance-list.header.operational-state.state.${operationalState}`,
                    )}
                />
            ) : null;
        }),
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance-list.header.instance-server"),
        name: "server",
        render: noRenderForParent(),
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance-list.header.environment"),
        name: "type",
        render: noRenderForParent(),
    },
    {
        visibleInPanel: true,
        type: fieldTypes.OPTION,
        label: t("instance-list.header.server-type"),
        name: "serverType",
        options: [
            { value: null, label: "All" },
            { value: "MANUAL", label: "Manual" },
            { value: "PROVISIONED", label: "Provisioned" },
        ],
        render: noRenderForParent((text, record) =>
            capitalize(record.serverType),
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance-list.header.partition"),
        name: "partitionName", // TODO:
        // name: "serialNumber",
        render: noRenderForParent(),
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance-list.header.im-version"),
        name: "imVersion",
        render: noRenderForParent(),
    },
    {
        visibleInPanel: true,
        type: fieldTypes.OPTION,
        label: t("dashboard-instances.header.monitoring"),
        name: "monitored",
        options: monitoringOptions,
        render: noRenderForParent((text, record) => (
            <InstanceMonitored monitored={record.monitored} />
        )),
        panelRenderValue: (text, record) => formatMonitored(record),
    },
    {
        visible: false,
        visibleInPanel: true,
        type: fieldTypes.TEXT,
        label: t("general.monitoring-recipients"),
        name: "emails",
        render: noRenderForParent(),
    },
    {
        type: fieldTypes.OPTION,
        label: t("dashboard-instances.header.health"),
        name: "healthState",
        options: [
            { value: null, label: t("general.all") },
            ...Object.keys(instanceHealthStates).map(state => ({
                value: state,
                label: getHealthStateTranslation(state),
            })),
        ],
        canSort: false,
        render: noRenderForParent((text, record) => (
            <InstanceHealth
                status={record.healthState}
                warnings={record.lastInstanceWarnings}
                lastCheckTimestamp={record.lastHealthCheckDate}
                small
            />
        )),
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance-list.header.cloud-provider"),
        name: "cloudProvider",
        render: noRenderForParent(text => text),
    },
    {
        visibleInPanel: true,
        type: fieldTypes.OPTION,
        label: t("dashboard-instances.header.report-monitoring"),
        name: "reportMonitored",
        options: monitoringOptions,
        render: noRenderForParent((text, record) => (
            <InstanceMonitored monitored={record.reportMonitored} />
        )),
        panelRenderValue: (text, record) => formatReportMonitored(record),
    },
    {
        visible: false,
        visibleInPanel: true,
        type: fieldTypes.TEXT,
        label: t("dashboard-instances.header.report-monitoring-recipients"),
        name: "reportRecipients",
        render: noRenderForParent(),
    },
    {
        type: fieldTypes.TEXT,
        label: t("nodes-list.header.git-commit"),
        name: "gitCommit",
        render: noRenderForParent((text, record) =>
            record.gitCommit ? record.gitCommit.substring(0, 8) : "",
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("nodes-list.header.build-id"),
        name: "buildId",
        render: noRenderForParent(),
    },
    {
        type: fieldTypes.DATE,
        label: t("customer-list.header.changed"),
        name: "updatedAt",
        render: noRenderForParent((text, record) => (
            <Changed key={record.id} data={record} />
        )),
    },
    {
        visible: false,
        visibleInPanel: true,
        type: fieldTypes.TEXT,
        label: t("general.created-by"),
        name: "createdBy",
        render: noRenderForParent(),
    },
    {
        visible: false,
        visibleInPanel: true,
        type: fieldTypes.DATE,
        label: t("general.created-at"),
        name: "createdAt",
        render: noRenderForParent(
            (text, record) =>
                record.createdAt && formatCreatedAt(record.createdAt),
        ),
    },
    {
        visible: false,
        visibleInPanel: true,
        type: fieldTypes.DATE,
        label: t("general.expiration-date"),
        name: "expiration",
        render: (_, record) =>
            record.expiration && (
                <FormattedDate>{record.expiration}</FormattedDate>
            ),
    },
    {
        visible: false,
        visibleInPanel: true,
        type: fieldTypes.BOOLEAN,
        label: t("general.remote-configuration-supported"),
        name: "configurableSupported",
        panelRenderValue: (text, record) =>
            record.configurableSupported ? "Yes" : "No",
        canFilter: false,
        canSort: false,
        render: noRenderForParent(),
    },
    {
        visible: false,
        visibleInPanel: true,
        type: fieldTypes.TEXT,
        label: t("general.server-log"),
        name: "instanceLog",
        render: noRenderForParent((text, record) => (
            <Link href={record.instanceLog} targetBlank={true}>
                <div
                    style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {record.instanceLog}
                </div>
            </Link>
        )),
    },
    {
        visible: false,
        visibleInPanel: true,
        type: fieldTypes.TEXT,
        label: t("general.git-log"),
        name: "imGitLog",
        render: noRenderForParent((text, record) => (
            <Link href={record.imGitLog} targetBlank={true}>
                <div
                    style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {record.imGitLog}
                </div>
            </Link>
        )),
    },
    {
        visible: false,
        visibleInPanel: false,
        type: fieldTypes.TEXT,
        label: t("dashboard-instances.header.integration-tags"),
        name: "integrationTags",
        render: map(tag => (
            <Tag key={tag} size="small">
                {tag}
            </Tag>
        )),
    },
];
