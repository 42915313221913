import { useDic } from "@/components/Dic/useDic.hook";
import { TemplateType } from "../../../constants/CommonConstats";
import { deployToEnum } from "../../../constants/deployment.constants";
import { isPackageRunning } from "../../../views/Package/package.utils";

const isInstance = destination => destination === deployToEnum.INSTANCE;
const isPartition = destination => destination === deployToEnum.PARTITION;

export const getDestination = ({ partitionId, instanceId }) => {
    if (partitionId) {
        return {
            destination: deployToEnum.PARTITION,
            destinationId: partitionId,
        };
    }
    if (instanceId) {
        return {
            destination: deployToEnum.INSTANCE,
            destinationId: instanceId,
        };
    }

    throw new Error("At least one parameter is required");
};

export const getIdsByDestination = (destination, destinationId) => {
    const instanceId = isInstance(destination) ? destinationId : undefined;
    const partitionId = isPartition(destination) ? destinationId : undefined;
    return {
        partitionId,
        instanceId,
    };
};

export const isDeploymentRunning = deploymentStateResource =>
    isPackageRunning(deploymentStateResource.loadable.contents?.processState);

export function useNavigateToPackages() {
    const {
        locationRouterService,
        marketplaceAppLocations: {
            packagePartitionLocation,
            packageInstanceLocation,
        },
    } = useDic();

    const navigateToPackages = ({
        destination,
        destinationId,
        accountId,
        template,
    }) => {
        const { partitionId, instanceId } = getIdsByDestination(
            destination,
            destinationId,
        );
        const lowerCaseType = `${template.type}`.toUpperCase();
        switch (lowerCaseType) {
            case TemplateType.INTEGRATIONS:
                locationRouterService.navigate(packageInstanceLocation, {
                    accountId,
                    partitionId,
                    instanceId,
                    packageName: template.uniqueName,
                });
                break;
            case TemplateType.PACKAGES:
            case TemplateType.SOLUTIONS:
            case TemplateType.NON_APPROVED:
                locationRouterService.navigate(packagePartitionLocation, {
                    accountId,
                    partitionId,
                    instanceId,
                    packageName: template.uniqueName,
                });
                break;
        }
    };

    return {
        navigateToPackages,
    };
}
