import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";

export const failedRecordsColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("failed-records.column.time"),
        name: "timestamp",
        render: (_, record) => {
            return <FormattedDateTime>{record.timestamp}</FormattedDateTime>;
        },
        width: 180,
        canSort: true,
    },
    {
        type: fieldTypes.TEXT,
        label: t("failed-records.column.payload"),
        name: "payload",
        canSort: false,
        render: (_, record) => {
            return record.payload;
        },
        width: 400,
    },
    {
        type: fieldTypes.TEXT,
        label: t("failed-records.column.message"),
        canSort: false,
        name: "message",
        render: (_, record) => {
            return record.message;
        },
    },
];
