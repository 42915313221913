import FormattedDate from "@/components/DateTime/FormattedDate";
import { Tag } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import {
    getHealthStateTranslation,
    instanceHealthStates,
} from "@/components/InstanceHealth/utils";
import { LocationLink } from "@/modules/router";
import { t } from "@/translations";
import { map } from "lodash/fp";
import { capitalize } from "lodash/string";
import moment from "moment";
import React from "react";
import { InstanceHealth } from "../InstanceHealth/InstanceHealth";
import { InstanceMonitored } from "../InstanceMonitored/InstanceMonitored";
import { IntegrationLink } from "./IntegrationLink.component";

const format = "DD/MM/YYYY HH:mm:ss";
export const formatMonitored = ({ monitored, instanceMonitored }) =>
    monitored || instanceMonitored ? t("general.yes") : t("general.no");

export const formatReportMonitored = ({ reportMonitored }) =>
    reportMonitored ? t("general.yes") : t("general.no");

export const formatCreatedAt = createdAt =>
    `${moment(createdAt).format(format)} (${moment(createdAt).fromNow()})`;

export const monitoringOptions = [
    { value: null, label: "All" },
    { value: "true", label: "Enabled" },
    { value: "false", label: "Disabled" },
];

export const createColumns = (
    dashboardLocation,
    nodesLocation,
    getDefaultLocation,
) => [
    {
        type: fieldTypes.TEXT,
        label: t("dashboard-instances.header.name"),
        name: "instanceName",
        render: (text, record) => (
            <>
                {record.hasRight ? (
                    <IntegrationLink
                        accountId={record.accountId}
                        instanceId={record.id}
                        getDefaultLocation={getDefaultLocation}
                    >
                        {record.instanceName}
                    </IntegrationLink>
                ) : (
                    record.instanceName
                )}
            </>
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("dashboard-instances.header.customer"),
        name: "accountName",
        render: (text, record) => (
            <>
                {record.hasRightForAccount ? (
                    <LocationLink
                        key={record.accountId}
                        $location={dashboardLocation}
                        $params={{ accountId: record.accountId }}
                    >
                        {record.accountName}
                    </LocationLink>
                ) : (
                    record.accountName
                )}
            </>
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("dashboard-instances.header.server"),
        name: "server",
    },
    {
        type: fieldTypes.TEXT,
        label: t("dashboard-instances.header.environment"),
        name: "type",
    },
    {
        type: fieldTypes.TEXT,
        label: t("dashboard-instances.header.cloudProvider"),
        name: "cloudProvider",
    },
    {
        visibleInPanel: true,
        type: fieldTypes.OPTION,
        label: t("dashboard-instances.header.server-type"),
        name: "serverType",
        options: [
            { value: null, label: "All" },
            { value: "MANUAL", label: "Manual" },
            { value: "PROVISIONED", label: "Provisioned" },
        ],
        render: (text, record) => capitalize(record.serverType),
    },
    {
        type: fieldTypes.TEXT,
        label: t("instance-list.header.im-version"),
        name: "imVersion",
    },
    {
        type: fieldTypes.TEXT,
        label: t("dashboard-instances.header.ports"),
        name: "ports",
    },
    {
        visibleInPanel: true,
        type: fieldTypes.OPTION,
        label: t("dashboard-instances.header.monitoring"),
        name: "monitored",
        options: monitoringOptions,
        render: (text, record) => (
            <InstanceMonitored monitored={record.monitored} />
        ),
        panelRenderValue: (text, record) => formatMonitored(record),
    },
    {
        visible: false,
        visibleInPanel: true,
        type: fieldTypes.TEXT,
        label: t("general.monitoring-recipients"),
        name: "emails",
    },
    {
        type: fieldTypes.OPTION,
        label: t("dashboard-instances.header.health"),
        name: "healthState",
        options: [
            { value: null, label: t("general.all") },
            ...Object.keys(instanceHealthStates).map(state => ({
                value: state,
                label: getHealthStateTranslation(state),
            })),
        ],
        canSort: false,
        render: (text, record) => (
            <InstanceHealth
                status={record?.healthState}
                warnings={record.lastInstanceWarnings}
                lastCheckTimestamp={record?.lastHealthCheckDate}
                small
            />
        ),
    },
    {
        visibleInPanel: true,
        type: fieldTypes.OPTION,
        label: t("dashboard-instances.header.report-monitoring"),
        name: "reportMonitored",
        options: monitoringOptions,
        render: (text, record) => (
            <InstanceMonitored monitored={record.reportMonitored} />
        ),
        panelRenderValue: (text, record) => formatReportMonitored(record),
    },
    {
        visible: false,
        visibleInPanel: true,
        type: fieldTypes.TEXT,
        label: t("dashboard-instances.header.report-monitoring-recipients"),
        name: "reportRecipients",
    },
    {
        visible: false,
        visibleInPanel: true,
        type: fieldTypes.TEXT,
        label: t("general.created-by"),
        name: "createdBy",
    },
    {
        visible: false,
        visibleInPanel: true,
        type: fieldTypes.DATE,
        label: t("general.created-at"),
        name: "createdAt",
        render: (text, record) =>
            record.createdAt && formatCreatedAt(record.createdAt),
    },
    {
        visible: false,
        visibleInPanel: true,
        type: fieldTypes.DATE,
        label: t("general.expiration-date"),
        name: "expiration",
        render: (_, record) =>
            record.expiration && (
                <FormattedDate>{record.expiration}</FormattedDate>
            ),
    },
    {
        visible: false,
        visibleInPanel: true,
        type: fieldTypes.BOOLEAN,
        label: t("general.remote-configuration-supported"),
        name: "configurableSupported",
        panelRenderValue: (text, record) =>
            record.configurableSupported ? "Yes" : "No",
        canFilter: false,
        canSort: false,
    },
    {
        visible: false,
        visibleInPanel: true,
        type: fieldTypes.TEXT,
        label: t("dashboard-instances.header.partition"),
        name: "partitionName",
    },
    {
        visible: false,
        visibleInPanel: false,
        type: fieldTypes.TEXT,
        label: t("dashboard-instances.header.integration-tags"),
        name: "integrationTags",
        render: map(tag => (
            <Tag key={tag} size="small">
                {tag}
            </Tag>
        )),
    },
];
