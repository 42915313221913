import {
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getErrorMessage } from "@/utils/state/error.utils";
import get from "lodash/fp/get";
import userPreferencesService from "../../../../services/userPreferences.service";

export const useListOfDefaultRoutes = () =>
    useQueryLoadable(() => {
        return userPreferencesService
            .getListOfDefaultRoutes()
            .then(get("data"));
    }, []);

export const useUserPreferencesQuery = () =>
    useQueryLoadable(() => {
        return userPreferencesService.getUserPreferences().then(get("data"));
    }, []);

export const useUserPreferencesMutation = () =>
    useMutationLoadableWithNotification(
        async data => {
            return userPreferencesService
                .saveUserPreferences(data)
                .then(get("data"));
        },
        [],
        "defaultRouteForm.notification.saved",
        getErrorMessage,
    );

export { useAccounts } from "@/utils/loadables/accounts.loadables";
