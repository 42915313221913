export const createTypedNode = ({ name, ...rest }, children) => ({
    value: name,
    label: name,
    ...rest,
    ...(children && { children }),
});

const apiMetaToOptionsRecursive = node => {
    switch (node.type) {
        case "array": {
            const name = (node.name ?? "") + "[]";
            const [item] = node.items; // just one item shape supported now

            switch (item.type) {
                case "object":
                    return createTypedNode(
                        { name },
                        item.items.map(apiMetaToOptionsRecursive),
                    );
                case "array":
                    return createTypedNode({ name }, [
                        apiMetaToOptionsRecursive(item.items),
                    ]); // TODO: validate
                default:
                    return createTypedNode({ ...item, name });
            }
        }

        case "object":
            return createTypedNode(
                node,
                node.items.map(apiMetaToOptionsRecursive),
            );

        default:
            return createTypedNode(node);
    }
};

export const apiMetaToOptions = node => {
    // remove "root" node name and parse
    switch (node.type) {
        case "array":
            return [apiMetaToOptionsRecursive({ ...node, name: "" })];
        case "object":
            return node.items.map(apiMetaToOptionsRecursive);
        default:
            return [apiMetaToOptionsRecursive(node)]; // should not happen
    }
};

const flatOption = ({ children, value, label, type }, optionPath) => ({
    value: optionPath,
    label: optionPath,
    type,
});

export const flatMapOptions = (treeOptions, prevPath = "") => {
    const flat = treeOptions.reduce((acc, option) => {
        const optionPath = [prevPath, option.value].filter(Boolean).join(".");
        const currentOption = flatOption(option, optionPath);
        const childrenOptions = option.children
            ? flatMapOptions(option.children, optionPath)
            : [currentOption];
        return acc.concat(childrenOptions);
    }, []);

    return flat;
};
