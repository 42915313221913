import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { TableButton } from "@/components/TableButton";
import { t } from "@/translations";
import React from "react";
import { assetType } from "../../../../../../constants/assets.constants";

export const createColumns = onClick => ({
    [assetType.project]: [
        {
            name: "name",
            label: t("assets-table.project.name"),
            type: fieldTypes.TEXT,
            render: (text, record) =>
                !onClick ? (
                    text
                ) : (
                    <TableButton
                        onClick={e => {
                            // without stopPropagation, Table registers click and adds record to selection
                            e.stopPropagation();
                            onClick(assetType.project, record);
                        }}
                    >
                        {text}
                    </TableButton>
                ),
        },
        {
            name: "createdBy",
            label: t("assets-table.project.createdBy"),
            type: fieldTypes.TEXT,
        },
        {
            name: "type",
            label: t("assets-table.project.type"),
            type: fieldTypes.TEXT,
        },
        {
            name: "territory",
            label: t("assets-table.project.territory"),
            type: fieldTypes.TEXT,
        },
    ],
    [assetType.partition]: [
        {
            name: "name",
            label: t("assets-table.partition.name"),
            type: fieldTypes.TEXT,
            render: (text, record) =>
                !onClick ? (
                    text
                ) : (
                    <TableButton
                        onClick={e => {
                            e.stopPropagation();
                            onClick(assetType.partition, record);
                        }}
                    >
                        {text}
                    </TableButton>
                ),
        },
        {
            name: "serialNumber",
            label: t("assets-table.partition.serialNumber"),
            type: fieldTypes.TEXT,
        },
        {
            name: "clusterName",
            label: t("assets-table.partition.clusterName"),
            type: fieldTypes.TEXT,
        },
    ],
    [assetType.integration]: [
        {
            name: "instanceName",
            label: t("assets-table.integration.instanceName"),
            type: fieldTypes.TEXT,
            render: (text, record) =>
                !onClick ? (
                    text
                ) : (
                    <TableButton
                        onClick={e => {
                            e.stopPropagation();
                            onClick(assetType.integration, record);
                        }}
                    >
                        {text}
                    </TableButton>
                ),
        },
        {
            name: "server",
            label: t("assets-table.integration.server"),
            type: fieldTypes.TEXT,
        },
        {
            name: "type",
            label: t("assets-table.integration.type"),
            type: fieldTypes.TEXT,
        },
    ],
});
