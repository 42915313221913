import {
    Alert,
    ButtonMenu,
    Forms,
    message,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import DisableSubmitOnEnter from "@/components/FeatureFlags/components/DisableSubmitOnEnter.component";
import { instancesServices } from "@/services/instance.service";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

const {
    useForm,
    Form,
    Fields,
    pmValidators,
    validators,
    success,
    useFieldValue,
    error,
} = Forms;

const InstancePasswordModal = ({
    instanceId,
    isProvisionedInstance,
    visible,
    onSave,
    onCancel,
}) => {
    const handleOnSubmit = ({ values }) => {
        instancesServices
            .setPassword({
                instanceId,
                body: {
                    ...values,
                    id: instanceId,
                    entity: "integrationManagerInstance",
                },
            })
            .then(() => {
                onSave?.();
                message.success(t("instance-form.message.password-saved"));
            })
            .catch(() => {
                message.error(t("instance-form.message.password-error"));
            });
    };

    const { formId, handleSubmit } = useForm({
        onSubmit: handleOnSubmit,
    });

    const matchPassword = useCallback(
        (otherFieldName, message) => async (value, getBag) => {
            const { values } = await getBag();
            return values[otherFieldName] === value
                ? success()
                : error(message ?? `Doesn't match '${otherFieldName}'`);
        },
        [useFieldValue({ formId, name: "password" })],
    );

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("instance-password.modal.title")}
                    alert={
                        alert && (
                            <Alert
                                showIcon
                                banner
                                type="error"
                                message={alert}
                            />
                        )
                    }
                />
                <UnityLayout.Content padding={[false, true]}>
                    <DisableSubmitOnEnter>
                        <Form formId={formId} onSubmit={handleSubmit}>
                            <Fields.InputPassword
                                name="password"
                                label={t("instance-form.label.password")}
                                disabled={isProvisionedInstance}
                                validator={pmValidators.isRequired}
                                inputWidth="max"
                            />
                            <Fields.InputPassword
                                name="repeatedPassword"
                                label={t("instance-form.label.repeat-password")}
                                disabled={isProvisionedInstance}
                                validator={validators.failOnFirst([
                                    pmValidators.isRequired,
                                    matchPassword(
                                        "password",
                                        t("instance-form.error.password-match"),
                                    ),
                                ])}
                                inputWidth="max"
                            />
                        </Form>
                    </DisableSubmitOnEnter>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.save"),
                                    type: "primary",
                                    onClick: e => {
                                        e.stopPropagation();
                                        handleSubmit(e);
                                    },
                                },
                                {
                                    label: t("general.cancel"),
                                    type: "text",
                                    onClick: onCancel,
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

InstancePasswordModal.propTypes = {
    isProvisionedInstance: PropTypes.bool.isRequired,
    instanceId: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
};

export default InstancePasswordModal;
