import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { ReportsListContainer } from "@/components/Reports/ReportsListContainer";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React from "react";

export function AccountReportsPageContainer() {
    const { accountId } = useAccountAppParams();

    useFullWidthTableLayout();

    return <ReportsListContainer accountId={accountId} />;
}
