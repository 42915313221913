import { useRoute } from "@/modules/router/index";
import { useContext } from "react";
import { getResource, RouteDataContext } from "../RouteDataContextProvider";

export const useRouteInstance = () => {
    const { route } = useRoute();
    const context = useContext(RouteDataContext);

    return {
        instanceLoadable: getResource(
            "instance",
            "instanceId",
            route.params?.instanceId,
            context.resources,
        ),
    };
};
