import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { LoggerLevelsButtonGroup } from "@/components/Integrations/Dashboard/Tabs/LoggerLevelsButtonGroup";
import { t } from "@/translations";
import React from "react";

export const loggersAdministrationColumns = (levels, handleLevelChange) => [
    {
        type: fieldTypes.TEXT,
        label: t("loggers-management-list.header.component"),
        name: "component",
    },
    {
        type: fieldTypes.TEXT,
        label: t("loggers-management-list.header.effective-level"),
        name: "effectiveLevel",
    },
    {
        name: "actions",
        label: t("general.header.actions"),
        align: "left",
        canFilter: false,
        canSort: false,
        render: (text, record) => (
            <LoggerLevelsButtonGroup
                component={record.component}
                effectiveLevel={record.effectiveLevel}
                levels={levels}
                onChange={handleLevelChange}
            />
        ),
    },
];
