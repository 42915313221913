import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { PartitionConnectionForm } from "@/components/PartitionDataManagement/Connections/PartitionConnectionForm";
import { PDM_TABS } from "@/components/PartitionDataManagement/PartitionDataManagementPage";
import {
    useDownloadTargetQuery,
    useEditDownloadTargetMutation,
} from "@/components/PartitionDataManagement/loadables";
import { LoadableRenderer } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";

export const EditPartitionConnectionPage = () => {
    const { accountId, partitionId, targetId } = useAccountAppParams();
    const {
        locationRouterService,
        accountAppLocations: { partitionDataManagementLocation },
    } = useDic();
    const onCancel = useCallback(
        () =>
            locationRouterService.navigate(partitionDataManagementLocation, {
                tab: PDM_TABS.CONNECTIONS,
            }),
        [partitionDataManagementLocation, locationRouterService],
    );
    useSetBackButtonAction(onCancel);
    useSetPageTitle(
        t(
            "location.accountApp.partitions.partition.data-management.edit-connection",
        ),
    );
    const query = useDownloadTargetQuery({
        accountId,
        partitionId,
        targetId,
    });
    const mutation = useEditDownloadTargetMutation({
        accountId,
        targetId,
        afterSuccess: onCancel,
    });

    return (
        <LoadableRenderer
            loadable={query.loadable}
            hasValue={initialValues => (
                <PartitionConnectionForm
                    accountId={accountId}
                    partitionId={partitionId}
                    initialValues={initialValues}
                    onSubmit={mutation.mutate}
                    onCancel={onCancel}
                />
            )}
        />
    );
};
