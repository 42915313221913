import { getSupportedExtensionsString } from "@/components/DataUploads/utils";
import { ACCEPTED_FORMATS } from "@/components/DataUploads/Wizard/constants";
import { Button, ButtonGroup, Gap, H3, Link } from "@/components/DesignSystem";
import Paragraph from "@/components/DesignSystem/Paragraph/Paragraph";
import Upload from "@/components/Upload/Upload";
import { STORAGE_VARIANT } from "@/components/Upload/useUploadStorage.hook";
import { deployToEnum } from "@/constants/deployment.constants";
import { PM_DATA_UPLOAD_KNOWLEDGE_URL } from "@/constants/knowledge.constants";
import { TrackingContext } from "@/mixpanel/TrackingContextProvider";
import { t } from "@/translations";
import React, { useCallback, useContext, useState } from "react";

const WizardNewDataUpload2 = ({
    onNext,
    projectId,
    partitionId,
    dataUploadId = "new",
    allValues: { fileId } = {},
    tableExampleData = {},
}) => {
    const [uploading, setUploading] = useState(false);
    const setUploadFinished = useCallback(() => setUploading(false), []);
    const setUploadStarted = useCallback(() => setUploading(true), []);
    const { trackDataUpload } = useContext(TrackingContext);
    const onError = useCallback(
        ({ errorMessage }) => {
            trackDataUpload({ dataUploadId, fileUploadResult: "failed" });
        },
        [trackDataUpload, dataUploadId],
    );
    const onUploaded = useCallback(
        file => {
            trackDataUpload({
                uploadFileName: file.originalName,
                dataUploadId,
                fileUploadResult: "success",
            });
            setUploadFinished();
            onNext({ storageVariant: file.storageVariant, fileId: file.id });
        },
        [setUploadFinished, trackDataUpload, dataUploadId, onNext],
    );

    return (
        <>
            <H3>{t("partition-send-data.step2.title")}</H3>
            <Paragraph size={2}>
                {t("partition-send-data.step2.description", {
                    link: (
                        <Link targetBlank href={PM_DATA_UPLOAD_KNOWLEDGE_URL}>
                            {t(
                                "partition-send-data.step2.description.link-text",
                            )}
                        </Link>
                    ),
                })}
            </Paragraph>
            <Upload
                accountId={projectId}
                storage={STORAGE_VARIANT.S3}
                destination={deployToEnum.PARTITION}
                destinationId={partitionId}
                variant="withIcon"
                onUploadStart={setUploadStarted}
                onFileUploaded={onUploaded}
                onUploadError={setUploadFinished}
                accept={ACCEPTED_FORMATS.join(",")}
                hintMessage={t("partition-send-data.step2.upload.hint", {
                    supportedFormats:
                        getSupportedExtensionsString(ACCEPTED_FORMATS),
                })}
                onError={onError}
            />
            {!!fileId && (
                <>
                    <Gap />
                    {tableExampleData.fileName}
                    <Gap size="large" />
                    <ButtonGroup spacing="none">
                        <Button
                            htmlType="button"
                            type="primary"
                            label={t("partition-send-data.button.continue")}
                            onClick={() => onNext({ fileId })}
                            disabled={uploading}
                            data-test="submit"
                        />
                    </ButtonGroup>
                </>
            )}
        </>
    );
};

export default WizardNewDataUpload2;
