import { gutter, GutterMarker } from "@codemirror/view";
import "./style.less";

var GUTTER_ID = "CodeMirror-password-encrypt";
var CLASS_PREFIX = "CodeMirror-password-encrypt";
var CLASS_MARKER = CLASS_PREFIX + "-marker-fixable";

export const matchAll = (regex, str) => {
    const matches = [];

    let groups;
    while ((groups = regex.exec(str)) !== null) {
        matches.push(...Array.from(groups));
    }

    return matches;
};

const matchers = {
    json: line => {
        const REGEX =
            /['"]*(?:password|secret|secret-key)['"]*\s*[=:]\s*['"]*([^'"<> \n]*)['"]*/gim;

        const found = matchAll(REGEX, line);

        if (found.length > 0 && !isEncrypted(found[1])) {
            return found[1];
        }
        return null;
    },
    xml: line => {
        const REGEX =
            /<(?:password|secret|secret-key)>\s*([^'"<> \n{}]*)\s*</gm;

        const found = matchAll(REGEX, line);

        if (found.length > 0 && !isEncrypted(found[1])) {
            return found[1];
        }
        return null;
    },
    properties: line => {
        const REGEX = /(password|secret|secret-key)=(.*)/g;

        const found = matchAll(REGEX, line);

        if (found.length > 1 && !isEncrypted(found[2])) {
            return found[2];
        }
        return null;
    },
};

const isEncrypted = foundPassword => `${foundPassword}`.startsWith("{ENC}");

const checkNeedOfPaswordEncryption = mode => (view, line) => {
    var text = view.state.doc.lineAt(line.from).text;

    if (!matchers[mode]) {
        console.warn(`Password encrypt for mode ${mode} is not supported yet`);
        return;
    }
    const foundPassword = matchers[mode](text);

    if (foundPassword) {
        return marker;
    } else {
        return null;
    }
};

const marker = new (class extends GutterMarker {
    toDOM() {
        const marker = document.createElement("div");
        marker.className = CLASS_MARKER;
        marker.innerHTML = "";
        marker.title = "Encrypt Password";
        return marker;
    }
})();

export const passwordEncryption = (mode, passwordEncryption) => {
    // https://codemirror.net/docs/ref/#view.gutter^config
    return gutter({
        class: GUTTER_ID,
        lineMarker: checkNeedOfPaswordEncryption(mode),
        domEventHandlers: {
            mousedown: async (view, line) => {
                var text = view.state.doc.lineAt(line.from).text;
                const foundPassword = matchers[mode](text);

                if (!foundPassword) return;

                await passwordEncryption(foundPassword).then(
                    encryptedPassword => {
                        if (encryptedPassword) {
                            const lineRef = view.state.doc.lineAt(line.from);

                            view.dispatch({
                                changes: {
                                    from:
                                        lineRef.from +
                                        lineRef.text.indexOf(foundPassword),
                                    to:
                                        lineRef.from +
                                        lineRef.text.indexOf(foundPassword) +
                                        foundPassword.length,
                                    insert: encryptedPassword,
                                },
                            });
                        }
                    },
                );

                return true;
            },
        },
    });
};
