import { HEADER_WITH_PAGE_TITLE } from "@/components/DesignSystem/Table/constants";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { useRefreshAction } from "@/components/PageLayout/useRefreshAction.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { upgradeEventsService } from "@/services/upgradeEvents.service";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { clusterUpgradeOverviewColumns } from "./ClusterUpgradeOverviewTable.columns";

const CLUSTER_HEADER_HEIGHT = 82;
const preferencesType = preferencesTypes.UPGRADES_TABLE;
const DEFAULT_FILTER = {};

const createCriteria = criteria => (criteria.value ? [criteria] : []);
export const convertFromToCriteria = ({ from, to, fieldName }) => {
    const fromCriteria = createCriteria({
        fieldName,
        operator: "greaterOrEqual",
        value: from,
    });
    const toCriteria = createCriteria({
        fieldName,
        operator: "lessOrEqual",
        value: to,
    });
    return [...fromCriteria, ...toCriteria];
};

const ClusterUpgradeOverviewTable = ({ filter = DEFAULT_FILTER }) => {
    const dateRangeFilterCriteria = useMemo(() => {
        const { upgradeDate: { value: [from, to] = [] } = {} } = filter;

        return convertFromToCriteria({
            from,
            to,
            fieldName: "upgradeDate",
        });
    }, [filter]);

    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort = "name,asc", tableFilter) =>
            upgradeEventsService.getClusterUpgradeEvents(
                { page, size, sort },
                tableFilter,
            ),
        [filter],
    );
    useRefreshAction(reload);

    return (
        <TableWithPreferencesManagement
            fixed
            datasetSlicing="server"
            defaultSort={{
                fieldName: "cluster",
                sortDir: "ascending",
            }}
            restHeight={HEADER_WITH_PAGE_TITLE + CLUSTER_HEADER_HEIGHT}
            columns={clusterUpgradeOverviewColumns(true)}
            rowKey="_id"
            preferencesType={preferencesType}
            exportUrl="/api/cluster-upgrade-events/export"
            subsetFilter={{
                criteria: dateRangeFilterCriteria,
                operator: "and",
                _constructor: "AdvancedCriteria",
            }}
            {...tableProps}
        />
    );
};

ClusterUpgradeOverviewTable.propTypes = {
    filter: PropTypes.object,
};

export default ClusterUpgradeOverviewTable;
