import { array, number, object, string } from "yup";

export const businessRoleApiSchema = object().shape({
    id: number().required(),
    name: string().required(),
    description: string().nullable(),
    project: number(),
    permissions: array(),
});

export const businessRolesApiSchema = array().of(businessRoleApiSchema);
