import { Button, ButtonGroup, Forms, Gap } from "@/components/DesignSystem";
import { orEmpty } from "@/components/DesignSystem/Forms/validators";
import { useOptionsValidator } from "@/components/Filters/form/validators";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { StepUiHeaders } from "../TableComponents/Skip";

export const SetupRebateHeaderType = ({
    onNext,
    onBack,
    apiResult: {
        currentStepData: {
            rebateAgreementTypes,
            ui: { title = "Header Rebate Type", description } = {},
        },
    },
    globalState: { headerRebateTypeId },
}) => {
    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit: ({ values }) => onNext(values),
    });
    const options = useMemo(
        () =>
            rebateAgreementTypes.map(({ id, label }) => ({
                label,
                value: id,
            })),
        [rebateAgreementTypes],
    );
    const validator = orEmpty(useOptionsValidator(options));
    useSetValidatedInitialValues({
        initialValues: useMemo(
            () =>
                options.some(({ value }) => value === headerRebateTypeId)
                    ? { headerRebateTypeId }
                    : {},
            [headerRebateTypeId, options],
        ),
        setValues,
        setTouched,
    });

    return (
        <>
            <StepUiHeaders ui={{ title, description }} />
            <Forms.Form formId={formId} onSubmit={handleSubmit}>
                <Forms.Fields.Select
                    required
                    name="headerRebateTypeId"
                    label={t(
                        "package.setup-rebate-header-type.form.header-rebate-type-id.label",
                    )}
                    tooltip={t(
                        "package.setup-rebate-header-type.form.header-rebate-type-id.tooltip",
                    )}
                    options={options}
                    validator={validator}
                />
                <Gap size="small" />
                <ButtonGroup>
                    <Forms.SubmitButton formId={formId}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            label={t(
                                "package.setup-rebate-header-type.submit.label",
                            )}
                        />
                    </Forms.SubmitButton>
                    <BackButton onBack={onBack} />
                </ButtonGroup>
            </Forms.Form>
        </>
    );
};

SetupRebateHeaderType.propTypes = {
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    apiResult: PropTypes.shape({
        currentStepData: PropTypes.shape({
            rebateAgreementTypes: PropTypes.array.isRequired,
            ui: PropTypes.object,
        }).isRequired,
    }).isRequired,
    globalState: PropTypes.object,
};
