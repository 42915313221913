import { Forms } from "@/components/DesignSystem";
import { lessThanDay } from "@/components/DesignSystem/Forms/fields";
import { t } from "@/translations";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { preselectedTimezone } from "../TimezoneSelect/TimezoneSelect.component";

export const DEFAULT_EXECUTION_PERIOD = "ONE_TIME";
const EXECUTION_PERIOD_OPTIONS = [
    { label: "Never", value: "ONE_TIME" },
    { label: "Daily", value: "DAILY" },
    { label: "Weekly", value: "WEEKLY" },
    { label: "Monthly", value: "MONTHLY" },
    { label: "Yearly", value: "YEARLY" },
];

export const SchedulingDetails = ({ formId, visible }) => {
    // do not reset field values when SCHEDULED -> REALTIME -> SCHEDULED is performed PFIM-3788
    const style = useMemo(
        () => (visible ? {} : { display: "none" }),
        [visible],
    );
    return (
        <div style={style}>
            <Forms.Fields.TimezoneSelect
                name="timezone"
                initialValue={preselectedTimezone}
                label={t("capacity-configuration.timezone.label")}
                placeholder={t("placeholder.please-select")}
            />
            <Forms.FieldGrid breakpoint={200}>
                <Forms.FieldGrid.Row>
                    <Forms.Fields.DatePicker
                        name="date"
                        initialValue={moment().startOf("day")}
                        label={t("alerts.modal-create.date.label")}
                        allowClear={false}
                        format="DD/MM/YYYY"
                    />
                    <Forms.Fields.TimePicker
                        name="time"
                        initialValue={moment(0).startOf("day")}
                        label={t("alerts.modal-create.time.label")}
                        allowClear={false}
                        format="HH:mm"
                    />
                    <Forms.Fields.SelectTimeWithAdd
                        formId={formId}
                        name="lookBackMinutes"
                        label={t("alerts.modal-create.lookback.label")}
                        addItemValidator={lessThanDay(7)}
                    />
                    <Forms.Fields.Select
                        name="executionPeriod"
                        label={t("alerts.modal-create.repeat-interval.label")}
                        options={EXECUTION_PERIOD_OPTIONS}
                        // initialValue={DEFAULT_EXECUTION_PERIOD}
                        allowClear={false}
                    />
                </Forms.FieldGrid.Row>
            </Forms.FieldGrid>
        </div>
    );

    // return (
    //     <Forms.Field
    //         name="schedulingDetails"
    //         as={SchedulingDetailsForm}
    //         from={identity}
    //         to={(...args) =>
    //             console.log("to schedulingDetails", args) || args[0]
    //         }
    //     />
    // );
};

SchedulingDetails.propTypes = {
    visible: PropTypes.bool,
    formId: PropTypes.string.isRequired,
};
