import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "./TypeText.styles.less";

export const TypeText = ({ className, hasError, children }) => {
    return (
        <span
            className={cx(
                {
                    [styles.valueType]: true,
                    [styles.error]: hasError,
                },
                className,
            )}
        >
            {children}
        </span>
    );
};

TypeText.propTypes = {
    hasError: PropTypes.bool,
    children: PropTypes.string,
    className: PropTypes.string,
};
