import { Layout } from "@/components/DesignSystem";
import cx from "classnames";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { ContentLayoutContext } from "./ContentLayout.context";
import "./ContentLayout.styles.less";

const { Content } = Layout;
const baseClass = "contentLayout";

/**
 * @deprecated Should Be migrated to Unity Content types (centered, stretch) but PM centered != Unity centered
 */
export function ContentLayout({ children, className }) {
    const { layout } = useContext(ContentLayoutContext);

    const classes = cx(`${baseClass}--${layout}`, className);

    return (
        <Content className={classes}>
            <div className={`${baseClass}__inner`}>{children}</div>
        </Content>
    );
}

ContentLayout.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
