export class TemplatesService {
    /**
     *
     * @type {TemplatesApiService}
     */
    templatesApiService = null;

    constructor({ templatesApiService }) {
        this.templatesApiService = templatesApiService;
    }

    /**
     *
     * @param {string} type
     * @param {string} accountId
     * @returns {Promise<TemplateEntity[]>}
     */
    getTemplatesByType(type, accountId) {
        return this.templatesApiService.getTemplatesByType(type, accountId);
    }

    /**
     *
     * @param {TemplateDeploymentEntity} templateDeployment
     * @returns {Promise<*>}
     */
    async deleteDeployment(templateDeployment) {
        await this.templatesApiService.deleteApplicationProperties(
            templateDeployment,
        );

        return this.templatesApiService.deleteDeployment(templateDeployment, {
            includeData: true,
        });
    }
}
