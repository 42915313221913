import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT } from "@/components/DesignSystem/Table/constants";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { LoadableRenderer } from "@/modules/loadable";
import { useHasPermission } from "@/security/hooks/useHasPermission.hook";
import { t } from "@/translations";
import React, { useCallback, useMemo, useState } from "react";
import { preferencesTypes } from "../../../../constants/preferencesTypes.constants";
import { BusinessRoleModal } from "../../../accountApp/components/AccountPermissionAdminSettings/BusinessRoleModal.component";
import { businessRoleColumns } from "../../../accountApp/components/AccountPermissionAdminSettings/PermissionAdminSettings.columns";
import { BusinessRolePermissionsDrawer } from "./BusinessRolePermissionsDrawer";
import {
    useAdminBusinessRolesResource,
    useDeleteAdminBusinessRoleResource,
    usePostAdminBusinessRoleResource,
} from "./loadables";

const VIEWS = {
    MODAL: "MODAL",
    DRAWER: "DRAWER",
};

const EDIT_PERMISSION = "user_mgmt.business_roles.edit";

const preferencesType = preferencesTypes.ADMIN_BUSINESS_ROLES_LIST_TABLE;

export const BusinessRolesPage = () => {
    const [view, setView] = useState(null);
    const [selectedBusinessRole, setSelectedBusinessRole] = useState();
    const readOnly = !useHasPermission([EDIT_PERMISSION]);

    useFullWidthTableLayout();
    useHideBackButton();
    useBreadcrumbButton(
        {
            label: "account-admin-business-roles-list.button.create-business-role",
            onClick: () => {
                setSelectedBusinessRole(undefined);
                setView(VIEWS.MODAL);
            },
            permissions: [EDIT_PERMISSION],
        },
        [setSelectedBusinessRole],
    );

    const businessRolesResource = useAdminBusinessRolesResource();
    const postBusinessRoleResource = usePostAdminBusinessRoleResource(() => {
        businessRolesResource.reload();
        setView(null);
    });
    const deleteBusinessRoleResource = useDeleteAdminBusinessRoleResource(
        () => {
            businessRolesResource.reload();
            setView(null);
        },
    );

    const onBusinessRoleClick = useCallback(record => {
        setSelectedBusinessRole(record);
        setView(VIEWS.DRAWER);
    }, []);
    const onBusinessRoleEdit = useCallback(record => {
        setSelectedBusinessRole(record);
        setView(VIEWS.MODAL);
    }, []);
    const resetView = useCallback(() => {
        setView(null);
    }, []);

    const actionMenu = useMemo(
        () => record =>
            (
                <ActionButton
                    record={record}
                    items={[
                        {
                            title: t("general.tooltip.edit"),
                            permission: [EDIT_PERMISSION],
                            onClick: onBusinessRoleEdit,
                        },
                        {
                            title: t(
                                "account-admin-business-role-list-delete-action.title",
                            ),
                            confirmMessage: t(
                                "account-admin-business-role-list-delete-action.message",
                                {
                                    name: record.name,
                                },
                            ),
                            onConfirm: deleteBusinessRoleResource.mutate,
                            permission: [EDIT_PERMISSION],
                            color: "red",
                        },
                    ]}
                />
            ),
        [onBusinessRoleEdit, deleteBusinessRoleResource.mutate],
    );

    return (
        <>
            <LoadableRenderer
                loadable={businessRolesResource.loadable}
                hasValue={dataSource => (
                    <TableWithPreferencesManagement
                        actionMenu={actionMenu}
                        columns={businessRoleColumns(onBusinessRoleClick)}
                        dataSource={dataSource}
                        fixed
                        onChangeFilter={resetView}
                        onChangePaging={resetView}
                        restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
                        rowKey="id"
                        datasetSlicing="local"
                        preferencesType={preferencesType}
                    />
                )}
            />
            <BusinessRolePermissionsDrawer
                visible={view === VIEWS.DRAWER}
                businessRole={selectedBusinessRole}
                onSave={postBusinessRoleResource.mutate}
                onClose={resetView}
                readOnly={readOnly}
            />
            <BusinessRoleModal
                visible={view === VIEWS.MODAL}
                onCancel={resetView}
                onOk={postBusinessRoleResource.mutate}
                role={selectedBusinessRole}
                okText={selectedBusinessRole ? t("general.save") : undefined}
                title={
                    selectedBusinessRole
                        ? t("account-admin-business-role-list.modal.title-edit")
                        : undefined
                }
            />
        </>
    );
};

BusinessRolesPage.propTypes = {};
