import { useDic } from "@/components/Dic/useDic.hook";
import { useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useEnvironmentsResource = () => {
    const { createInstanceService } = useDic();
    return useQueryLoadable(async () => {
        return createInstanceService
            .fetchEnvironments()
            .then(getData)
            .then(data => {
                try {
                    return data.map(({ name }) => ({
                        label: name,
                        value: name,
                    }));
                } catch (_) {
                    return [];
                }
            });
    }, [createInstanceService]);
};

export const useInstanceEnvironmentsOptionsResource = ({
    canSetAdvancedOptions,
}) => {
    const { createInstanceService } = useDic();
    return useQueryLoadable(async () => {
        // await new Promise(res => setTimeout(res, 30000));
        if (canSetAdvancedOptions) {
            return Promise.all([
                createInstanceService.fetchEnvironments().then(getData),
                createInstanceService.fetchCpu().then(getData),
                createInstanceService.fetchMem().then(getData),
                createInstanceService.fetchStorages().then(getData),
            ]);
        } else {
            return Promise.all([
                createInstanceService.fetchEnvironments().then(getData),
                createInstanceService.fetchDefaultValues().then(getData),
            ]).then(([environments, defaultEnv]) => {
                return [
                    environments,
                    [{ name: defaultEnv.cpu }],
                    [{ name: defaultEnv.memory }],
                    [{ name: defaultEnv.storage }],
                ];
            });
        }
    }, [canSetAdvancedOptions, createInstanceService]);
};
