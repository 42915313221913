import { TARGET_OPTIONS } from "@/components/AdminReports/AdminReportsList";
import {
    useAdminReportQuery,
    useIndexOptionsQuery,
} from "@/components/AdminReports/loadables";
import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { preselectedTimezone } from "@/components/TimezoneSelect";
import { LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import { get, some } from "lodash/fp";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { axiosService } from "../../axios";

const from = formValues => {
    const { date, time, recipients, ...rest } = formValues;

    const scheduledAt = moment(date)
        .set({
            hour: time?.hour?.(),
            minute: time?.minute?.(),
            second: time?.second?.(),
        })
        ?.format("YYYY-MM-DDTHH:mm:ss");

    return {
        scheduledAt,
        recipients: recipients.join(","),
        ...rest,
    };
};

const to = report => {
    if (!report) {
        return {};
    }
    const { scheduledAt, recipients, ...rest } = report;

    const scheduledAtMoment = moment(scheduledAt);

    return {
        date: scheduledAtMoment,
        time: scheduledAtMoment,
        recipients: recipients && recipients.split(","),
        ...rest,
    };
};

export const AdminReportModal = ({ modalProviderProps, onSave, id }) => {
    const editMode = !!id;
    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit: async ({ values }) => {
            onSave({ id, report: from(values) });
        },
    });
    const targetValue = Forms.useFieldValue({
        formId,
        name: "target",
    });

    const adminReportQuery = useAdminReportQuery({
        reportId: id,
        canFetch: editMode,
    });

    const report = adminReportQuery.loadable.valueMaybe()?.data;

    const indexOptionsQuery = useIndexOptionsQuery({
        target: targetValue,
        canFetch: !!targetValue,
    });

    useSetValidatedInitialValues(
        {
            initialValues: {
                ...to(report),
            },
            setValues,
            setTouched,
        },
        [report],
    );

    const getReportNameExistsValidator = useCallback(
        ({ initialReportName }) =>
            async reportName => {
                if (reportName === initialReportName) return Forms.success();

                const exists = await axiosService
                    .post(`/api/admin-reports/exists`, { name: reportName })
                    .then(get("data"));
                return exists
                    ? Forms.error(
                          t("adminReports-modal.form.name.validation.exists"),
                      )
                    : Forms.success();
            },
        [],
    );

    const elkIndexFieldProps = getLoadableSelectProps(
        indexOptionsQuery.loadable,
    );

    const elkIndexValidator = async elkIndex => {
        return some({ value: elkIndex }, elkIndexFieldProps.options)
            ? Forms.success()
            : Forms.error(t("adminReports-modal.form.elIndex.validation"));
    };

    return (
        <Modal {...modalProviderProps} width={650}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={
                        editMode
                            ? t("adminReports-list.rowMenu.edit")
                            : t("adminReports-list.button.create-report")
                    }
                />
                <UnityLayout.Content padding={[true, true]}>
                    <LoadableRenderer
                        loadable={adminReportQuery.loadable}
                        hasValue={() => (
                            <Forms.Form formId={formId} onSubmit={handleSubmit}>
                                <Forms.FieldGroup width="max" inputWidth="max">
                                    <Forms.Fields.Input
                                        name="name"
                                        label={t(
                                            "adminReports-modal.form.name.label",
                                        )}
                                        required
                                        validator={Forms.validators.failOnFirst(
                                            [
                                                Forms.pmValidators.isRequired,
                                                getReportNameExistsValidator({
                                                    initialReportName:
                                                        report?.name,
                                                }),
                                            ],
                                        )}
                                    />
                                    <Forms.Fields.Switch
                                        name="enabled"
                                        label={t(
                                            "adminReports-modal.form.enabled.label",
                                        )}
                                        initialValue={true}
                                    />
                                    <Forms.Fields.Select
                                        name="target"
                                        label={t(
                                            "adminReports-modal.form.target.label",
                                        )}
                                        options={TARGET_OPTIONS}
                                        required
                                        validator={
                                            Forms.pmValidators.isRequired
                                        }
                                    />
                                    <Forms.Fields.Select
                                        name="elkIndex"
                                        label={t(
                                            "adminReports-modal.form.elkIndex.label",
                                        )}
                                        required
                                        validator={Forms.validators.failOnFirst(
                                            [
                                                Forms.pmValidators.isRequired,
                                                elkIndexValidator,
                                            ],
                                        )}
                                        {...elkIndexFieldProps}
                                    />
                                    <Forms.Fields.TextArea
                                        name="query"
                                        label={t(
                                            "adminReports-modal.form.query.label",
                                        )}
                                        required
                                        validator={
                                            Forms.pmValidators.isRequired
                                        }
                                        tooltip={t(
                                            "adminReports-modal.form.query.tooltip",
                                        )}
                                    />
                                    <Forms.Fields.TimezoneSelect
                                        name="timeZone"
                                        initialValue={preselectedTimezone}
                                        label={t(
                                            "adminReports-modal.form.timeZone.label",
                                        )}
                                        placeholder={t(
                                            "placeholder.please-select",
                                        )}
                                    />
                                    <Forms.FieldGrid breakpoint={200}>
                                        <Forms.FieldGrid.Row>
                                            <Forms.Fields.DatePicker
                                                name="date"
                                                initialValue={moment().startOf(
                                                    "day",
                                                )}
                                                label={t("general.date")}
                                                allowClear={false}
                                                format="DD/MM/YYYY"
                                            />
                                            <Forms.Fields.TimePicker
                                                name="time"
                                                initialValue={moment(0).startOf(
                                                    "day",
                                                )}
                                                label={t("general.time")}
                                                allowClear={false}
                                                format="HH:mm"
                                            />
                                            <Forms.Fields.InputNumber
                                                name="repeatPeriod"
                                                label={t(
                                                    "adminReports-modal.form.repeatPeriod.label",
                                                )}
                                                initialValue={1}
                                                min={1}
                                            />
                                            <Forms.Fields.InputNumber
                                                name="lookBack"
                                                label={t(
                                                    "adminReports-modal.form.lookBack.label",
                                                )}
                                                initialValue={7}
                                                min={1}
                                            />
                                        </Forms.FieldGrid.Row>
                                    </Forms.FieldGrid>
                                    <Forms.Fields.Select_tempFixDeselect
                                        required
                                        name="recipients"
                                        initialValue={[]}
                                        label={t(
                                            "alerts.modal-create.recipients.label",
                                        )}
                                        placeholder={t(
                                            "alert-rules-form.placeholder.email",
                                        )}
                                        validator={Forms.validators.failOnFirst(
                                            [
                                                Forms.pmValidators.isRequired,
                                                Forms.pmValidators
                                                    .areEmailsValid,
                                            ],
                                        )}
                                        allowClear={false}
                                        mode="tags"
                                    />
                                </Forms.FieldGroup>
                            </Forms.Form>
                        )}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: editMode
                                        ? t("general.save")
                                        : t("general.create"),
                                    formId,
                                    type: "primary",
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: modalProviderProps.onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
AdminReportModal.propTypes = {
    onSave: PropTypes.func.isRequired,
    modalProviderProps: PropTypes.object.isRequired,
    id: PropTypes.number,
};
