import { cloneDeep, isEqual, merge, pick } from "lodash";
import concat from "lodash/fp/concat";
import groupBy from "lodash/fp/groupBy";
import map from "lodash/fp/map";
import pipe from "lodash/fp/pipe";
import { useCallback, useMemo } from "react";

const keyBy = (prop, array = []) =>
    Object.fromEntries(array.map(item => [item[prop], item]));

const addOrder = (item, index) => ({
    tableOrder: index,
    panelOrder: item.panelOrder ?? index,
    ...item,
});
const addVisible = item => ({ ...item, visible: item.visible ?? true });

const createAscNumOrder = (prop1, prop2) => (a, b) =>
    (a[prop1] ?? a[prop2] + 1000) - (b[prop1] ?? b[prop2] + 1000);

const mergeColumnsPreferences = (columns, preferences) => {
    if (!preferences) return columns;

    const definedColumns = columns
        .map(({ name }) => name)
        .filter(name => {
            if (!name) console.log("missing column name!", { columns });
            return !!name;
        });
    const columnsByName = [columns, preferences]
        .filter(Array.isArray)
        .map(arr => keyBy("name", arr.map(addOrder).map(addVisible)))
        .reduce((acc, itemsByName) => merge(acc, itemsByName), {});
    const sorted = Object.values(columnsByName)
        .filter(({ name }) => definedColumns.includes(name))
        .sort(createAscNumOrder("tableOrder"));
    return sorted;
};

const pickPreferences = (columns, hasPanel) =>
    columns.map(col =>
        pick(col, [
            "name",
            "width",
            "visible",
            ...(hasPanel ? ["visibleInPanel", "panelOrder"] : []),
        ]),
    );

const usePreferencesColumns = (
    setChangedPreference,
    columnsProp,
    activePreference,
    changedPreference,
    hasPanel,
) => {
    const columns = useMemo(() => {
        const preferences = changedPreference || activePreference;
        return mergeColumnsPreferences(columnsProp, preferences);
    }, [changedPreference, activePreference, columnsProp]);

    const setColumns = useCallback(
        newColumns => {
            const newPreferences = pickPreferences(newColumns, hasPanel);
            const oldPreferences = pickPreferences(
                mergeColumnsPreferences(columnsProp, activePreference),
                hasPanel,
            );
            const dirty = !isEqual(newPreferences, oldPreferences);
            if (!dirty)
                console.error(
                    "TODO: set new preferences to undefined, as they are equal to old ones",
                );
            setChangedPreference(preference => ({
                ...preference,
                viewState: {
                    ...preference?.viewState,
                    columns: dirty ? newPreferences : undefined,
                },
            }));
        },
        [columnsProp, activePreference, setChangedPreference, hasPanel],
    );

    const { notTableColumns, tableColumns } = useMemo(
        () =>
            groupBy(
                ({ onlyIn }) =>
                    !onlyIn || onlyIn.includes("table")
                        ? "tableColumns"
                        : "notTableColumns",
                columns,
            ),
        [columns],
    );
    const setTableColumns = useCallback(
        valueOrFn => {
            const newColumns =
                typeof valueOrFn === "function"
                    ? valueOrFn(cloneDeep(tableColumns))
                    : valueOrFn;
            const allColumns = newColumns.concat(notTableColumns ?? []);
            setColumns(allColumns);
        },
        [notTableColumns, setColumns, tableColumns],
    );

    const { notPanelColumns, panelColumns } = useMemo(
        () =>
            pipe(
                arr =>
                    // lodash/fp/map cb is unary
                    arr.map((column, tableOrder) => ({
                        ...column,
                        tableOrder,
                        visibleInTable: column.visible,
                        visible: column.visibleInPanel ?? false,
                    })),
                arr => arr.sort(createAscNumOrder("panelOrder", "tableOrder")),
                groupBy(({ onlyIn }) =>
                    !onlyIn || onlyIn.includes("panel")
                        ? "panelColumns"
                        : "notPanelColumns",
                ),
            )(columns),
        [columns],
    );
    const setPanelColumns = useCallback(
        newColumns => {
            const columns = pipe(
                arr =>
                    arr.map((column, panelOrder) => ({
                        ...column,
                        panelOrder,
                    })),
                concat(notPanelColumns ?? []),
                map(column => ({
                    ...column,
                    visibleInPanel: column.visible,
                    visible: column.visibleInTable,
                })),
                arr => arr.sort(createAscNumOrder("tableOrder")),
            )(newColumns);
            setColumns(columns);
        },
        [setColumns, notPanelColumns],
    );

    return {
        columns: tableColumns,
        setColumns: setTableColumns,
        panelColumns,
        setPanelColumns,
    };
};

const usePreferencesPagination = (
    setChangedPreference,
    paginationProp,
    paginationPreferences,
    changedPagination,
) => {
    const pagination = useMemo(
        () => ({
            ...{ pageSize: 30, current: 1 },
            ...paginationProp,
            ...paginationPreferences,
            ...changedPagination,
        }),
        [paginationProp, paginationPreferences, changedPagination],
    );

    const onPaginationChange = useCallback(
        pagination => {
            const oldPagination = pick(
                { ...paginationProp, ...paginationPreferences },
                "pageSize",
            );
            const newPagination = pick(pagination, "pageSize");
            const dirty = !isEqual(oldPagination, newPagination);
            setChangedPreference(preference => ({
                ...preference,
                viewState: {
                    ...preference?.viewState,
                    pagination: dirty ? newPagination : undefined,
                },
            }));
        },
        [paginationPreferences, paginationProp, setChangedPreference],
    );
    return { pagination, onPaginationChange };
};

const usePreferencesSort = (
    setChangedPreference,
    sorterProp,
    sorterPreferences,
    changedSorter,
) => {
    const sorter = useMemo(
        () => ({
            ...sorterProp,
            ...sorterPreferences,
            ...changedSorter,
        }),
        [sorterProp, sorterPreferences, changedSorter],
    );

    const onSortChange = useCallback(
        newSorter => {
            const oldSort = { ...sorterProp, ...sorterPreferences };
            const dirty = !isEqual(oldSort, newSorter);
            setChangedPreference(preference => ({
                ...preference,
                viewState: {
                    ...preference?.viewState,
                    sorter: dirty ? newSorter : undefined,
                },
            }));
        },
        [sorterProp, sorterPreferences, setChangedPreference],
    );
    return { sorter, onSortChange };
};

const usePreferencesFilter = (
    setChangedPreference,
    filterPreferences,
    changedFilter,
) => {
    const filter = useMemo(
        () => changedFilter || filterPreferences,
        [filterPreferences, changedFilter],
    );
    const onFilterChange = useCallback(
        newFilter => {
            const dirty = !isEqual(filterPreferences, newFilter);
            setChangedPreference(preference => ({
                ...preference,
                viewState: {
                    ...preference?.viewState,
                    filter: dirty ? newFilter : undefined,
                },
            }));
        },
        [filterPreferences, setChangedPreference],
    );
    return { filter, onFilterChange };
};

const usePreferencesAllExpanded = (
    setChangedPreference,
    allExpandedPreferences,
    changedAllExpanded,
) => {
    const allExpandedPreference = useMemo(
        () => changedAllExpanded || allExpandedPreferences,
        [allExpandedPreferences, changedAllExpanded],
    );
    const onAllExpandedChange = useCallback(
        newAllExpanded => {
            const dirty = !isEqual(allExpandedPreferences, newAllExpanded);
            setChangedPreference(preference => ({
                ...preference,
                viewState: {
                    ...preference?.viewState,
                    allExpanded: dirty ? newAllExpanded : undefined,
                },
            }));
        },
        [allExpandedPreferences, setChangedPreference],
    );
    return { allExpandedPreference, onAllExpandedChange };
};

export const useTablePreferences = (
    activePreference,
    props,
    changedPreference,
    setChangedPreference,
    hasPanel,
) => {
    const { columns, setColumns, panelColumns, setPanelColumns } =
        usePreferencesColumns(
            setChangedPreference,
            props.columns,
            activePreference?.viewState?.columns,
            changedPreference?.viewState?.columns,
            hasPanel,
        );
    const { pagination, onPaginationChange } = usePreferencesPagination(
        setChangedPreference,
        props.pagination,
        activePreference?.viewState?.pagination,
        changedPreference?.viewState?.pagination,
    );
    const { sorter, onSortChange } = usePreferencesSort(
        setChangedPreference,
        props.sorter,
        activePreference?.viewState?.sorter,
        changedPreference?.viewState?.sorter,
    );
    const { filter, onFilterChange } = usePreferencesFilter(
        setChangedPreference,
        activePreference?.viewState?.filter,
        changedPreference?.viewState?.filter,
    );
    const { allExpandedPreference, onAllExpandedChange } =
        usePreferencesAllExpanded(
            setChangedPreference,
            activePreference?.viewState?.allExpanded,
            changedPreference?.viewState?.allExpanded,
        );

    return {
        columns,
        setColumns,
        pagination,
        onPaginationChange,
        sorter,
        onSortChange,
        filter,
        onFilterChange,
        panelColumns,
        setPanelColumns,
        allExpandedPreference,
        onAllExpandedChange,
    };
};
