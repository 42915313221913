import { BusinessRoleEntity } from "../entities/BusinessRoleEntity.class";
import { PermissionEntity } from "../entities/PermissionEntity.class";

export class BusinessRoleApiMapper {
    decode(apiResponse) {
        return BusinessRoleEntity.create({
            ...apiResponse,
            permissions: (apiResponse.permissions || []).map(p =>
                PermissionEntity.create(p),
            ),
        });
    }

    encode(entity) {}
}
