import { DataUploadEntityStep } from "@/components/DataUploads/Wizard/DataUploadEntityStep";
import { UploadFinish } from "@/components/DataUploads/Wizard/UploadFinish";
import { UploadReview } from "@/components/DataUploads/Wizard/UploadReview";
import { useStartDataUploadMutation } from "@/components/DataUploads/Wizard/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { toApiMapper } from "@/components/Mappers/form/mapper.utils";
import { useFullWidthLayout } from "@/components/hooks/useContentLayout.hook";
import { isLoading } from "@/modules/loadable";
import { logger } from "@/modules/logger";
import { t } from "@/translations";
import { isProduction } from "@/utils/env.utils";
import { get, identity, keys, omit, pick, reject } from "lodash/fp";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useReducer, useState } from "react";
import { defaultParserConfigValues as defaultParserConfig } from "../../FileParsing/FileParsingFields";
import LayoutWithStepper from "../../Packages/LayoutWithStepper";
import { DataUploadWarningComponent } from "../Warning/DataUploadWarning.component";
import WizardNewDataUpload2 from "./WizardNewDataUpload2";
import { WizardNewDataUpload3 } from "./WizardNewDataUpload3";
import WizardParserSettings from "./WizardParserSettings";
import { config as defaultConfig } from "./validate";

export const ENTITY_FIELDS = ["name", "entityType", "entityName"];

// Whatever child form submits, needs to be defined in either "fieldNames" or "_temp_omit" to explicitly specify, what we expect
// !!! When adding new fields, you eed to check initialization from upload too !!!
const EDIT_STEPS = [
    {
        name: t("data-upload.step-name.upload-file"),
        StepComponent: WizardNewDataUpload2,
        fieldNames: ["fileId", "storageVariant"],
    },
    {
        name: t("data-upload.step-name.options"),
        StepComponent: WizardParserSettings,
        fieldNames: [
            ...keys(defaultParserConfig),
            ...keys(defaultConfig),
            "schedule",
            "sendEmail",
        ],
    },
    {
        name: t("data-upload.step-name.data-mapping"),
        StepComponent: WizardNewDataUpload3,
        fieldNames: [
            "mapper",
            "convertEmptyStringToNull",
            "usePricingParameterName",
            "businessKey",
        ],
        _temp_omit: [
            "name",
            "entityType",
            "entityName",
            "definition",
            "config", // mapped to businessKey
        ],
        from: (stepValues, allValues) => ({
            ...stepValues,
            mapper: toApiMapper(stepValues.definition, allValues),
            businessKey: stepValues.config?.businessKey,
        }),
    },
    {
        name: t("data-upload.step-name.upload-review"),
        StepComponent: UploadReview,
        fieldNames: [],
    },
    {
        name: "",
        StepComponent: UploadFinish,
        fieldNames: [],
        stepperHidden: true,
        hiddenInStepper: true,
    },
];
const NEW_STEPS = [
    {
        name: t("data-upload.step-name.entity-type"),
        StepComponent: DataUploadEntityStep,
        fieldNames: ENTITY_FIELDS,
    },
    ...EDIT_STEPS,
];

const ACTION = {
    NEXT: "NEXT",
    SUBMITTED: "SUBMITTED",
    BACK: "BACK",
};

const initState = ({ isEdit = false, initialValues }) => ({
    currentStepIndex: 0,
    steps: isEdit ? EDIT_STEPS : NEW_STEPS,
    allValues: initialValues,
    taskId: undefined,
});

const checkForgotten = ({ fieldNames, _temp_omit = [] }, received) => {
    const omitted = omit(_temp_omit, received);
    const picked = pick(fieldNames, received);
    const extra = omit(fieldNames, omitted);
    const extraKeys = keys(extra);

    if (extraKeys.length) {
        console.log("[next step] Forgotten fields", {
            extra,
            data: { fieldNames, _temp_omit, received, picked, omitted, extra },
        });

        throw new Error("Forgotten fields: " + extraKeys.join(","));
    }
};

const getCurrentStep = state => state.steps[state.currentStepIndex];

const reducer = (state, { type, payload }) => {
    switch (type) {
        case ACTION.NEXT: {
            const step = {
                fieldNames: [],
                from: identity,
                ...getCurrentStep(state),
            };
            const stepValues = step.from(payload, state.allValues);
            const onlyStepValues = pick(step.fieldNames, stepValues);

            if (!isProduction()) checkForgotten(step, stepValues);

            const newState = {
                ...state,
                currentStepIndex: Math.min(
                    state.steps.length - 1,
                    state.currentStepIndex + 1,
                ),
                allValues: {
                    ...state.allValues,
                    ...onlyStepValues,
                },
            };
            return newState;
        }
        case ACTION.SUBMITTED:
            return {
                ...state,
                taskId: payload.taskId,
                currentStepIndex: state.steps.findIndex(
                    ({ StepComponent }) => StepComponent === UploadFinish,
                ),
            };
        case ACTION.BACK:
            return {
                ...state,
                currentStepIndex: Math.max(0, state.currentStepIndex - 1),
            };

        default:
            throw new Error("Unknown action");
    }
};

const AS_IS_FIELDS = [
    ...ENTITY_FIELDS,
    "fileId",
    "mapper",
    "sendEmail",
    "schedule",
];

const configKeys = [...keys(defaultConfig), "businessKey"];

const fromWizard = (
    allValues,
    fileInfo,
    { projectId, partitionId, dataUploadId },
) => ({
    projectId,
    partitionId,
    dataUploadId,
    ...pick(AS_IS_FIELDS, allValues),
    parserConfig: pick(keys(defaultParserConfig), allValues),
    config: pick(configKeys, allValues),
    validationSchema: fileInfo?.columns?.map(pick(["name", "type"])),
    mapperProperties: pick(["convertEmptyStringToNull"], allValues),
});

const toWizard = (upload = {}) => ({
    ...defaultParserConfig,
    ...pick(keys(defaultParserConfig), upload?.parserConfig ?? {}),
    ...defaultConfig,
    ...pick(configKeys, upload?.config ?? {}),
    convertEmptyStringToNull:
        !!upload?.mapperProperties?.convertEmptyStringToNull,
    ...pick(AS_IS_FIELDS, upload),
    schedule: upload?.scheduled && moment(upload?.scheduled), // wtf: d
});

export const DataUploadWizard = ({ projectId, partitionId, upload }) => {
    useFullWidthLayout();

    const dataUploadId = upload?.id;
    const isEdit = !!dataUploadId;

    const [fileInfo, setFileInfo] = useState();
    const [state, dispatch] = useReducer(
        reducer,
        { isEdit, initialValues: toWizard(upload) },
        initState,
    );
    const { StepComponent, stepperHidden } = getCurrentStep(state);

    const startDataUploadMutation = useStartDataUploadMutation({
        projectId,
        partitionId,
        fileId: state.allValues.fileId,
        afterSuccess: data => {
            dispatch({ type: ACTION.SUBMITTED, payload: { taskId: data.id } });
        },
    });

    const { locationRouterService, accountAppLocations } = useDic();
    const showDataUploads = useCallback(
        () =>
            locationRouterService.navigate(
                accountAppLocations.partitionUploadsLocation,
            ),
        [accountAppLocations.partitionUploadsLocation, locationRouterService],
    );

    const handleNext = useCallback(
        values => dispatch({ type: ACTION.NEXT, payload: values }),
        [],
    );
    const handleSubmit = useCallback(() => {
        const payload = fromWizard(state.allValues, fileInfo, {
            projectId,
            partitionId,
            dataUploadId,
        });
        return startDataUploadMutation.mutate(payload);
    }, [
        dataUploadId,
        fileInfo,
        partitionId,
        projectId,
        startDataUploadMutation,
        state.allValues,
    ]);
    const handleBack = useCallback(() => dispatch({ type: ACTION.BACK }), []);

    logger.debug({
        logGroupKey: ["DATALOAD", "DataUploadWizard", "rndr"],
        color: "orchid",
        data: {
            state,
            fileInfo,
            isEdit,
            upload,
        },
    });

    return (
        <>
            <DataUploadWarningComponent partitionId={partitionId} />
            {!stepperHidden && (
                <LayoutWithStepper
                    steps={reject(get("hiddenInStepper"), state.steps)}
                    currentStepIndex={state.currentStepIndex}
                    onBack={!!state.currentStepIndex && handleBack}
                />
            )}
            <StepComponent
                projectId={projectId}
                partitionId={partitionId}
                dataUploadId={dataUploadId}
                allValues={state.allValues}
                onFileInfo={setFileInfo} // parser
                tableExampleData={fileInfo} // WNDU3C
                onNext={handleNext}
                onBack={handleBack}
                onSubmit={handleSubmit}
                isSubmitting={isLoading(startDataUploadMutation)}
                onCancel={showDataUploads}
                taskId={state.taskId}
            />
        </>
    );
};

DataUploadWizard.propTypes = {
    projectId: PropTypes.number.isRequired,
    partitionId: PropTypes.number.isRequired,
    upload: PropTypes.object,
};
