import { Gap, H4 } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useDic } from "@/components/Dic/useDic.hook";
import { EntityType } from "@/components/Integrations/IntegrationsVersionHistory.overview.page";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { useGetDefaultIntegrationLocation } from "@/components/hooks/useGetDefaultIntegrationLocation.hook";
import { t } from "@/translations";
import { toVersionNumber } from "@/utils/versionUtils";
import qs from "qs";
import React, { useCallback, useMemo } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { useFetchPage } from "../PagableTable/useFetchPage.hook";
import { useRefreshAction } from "../PageLayout/useRefreshAction.hook";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { IntegrationLink } from "./IntegrationLink.component";
import { createColumns } from "./IntegrationsList.columns";

const preferencesType = preferencesTypes.INTEGRATIONS_LIST_TABLE;

const IntegrationsList = () => {
    const {
        locationRouterService,
        accountAppLocations,
        overviewAppLocations,
        axiosService,
    } = useDic();
    const { dashboardLocation } = accountAppLocations;
    const { nodesLocation } = overviewAppLocations;
    const integrationPanel = useDetailDrawerState();
    const getDefaultLocation = useGetDefaultIntegrationLocation();
    const columns = useMemo(
        () =>
            createColumns(dashboardLocation, nodesLocation, getDefaultLocation),
        [],
    );

    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort, filter) =>
            axiosService.post(
                `/api/dashboard?${qs.stringify({
                    page,
                    size,
                    sort: toVersionNumber(sort, "imVersion", "imVersionNumber"),
                })}`,
                filter,
            ),
        [],
    );

    useRefreshAction(reload);

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.show-detail"),
                        onClick: integrationPanel.show,
                    },
                    {
                        title: t("general.version-history"),
                        onClick: () => {
                            locationRouterService.navigate(
                                overviewAppLocations.integrationsVersionHistoryLocation,
                                {
                                    entityId: record.id,
                                    entityType: EntityType.IM,
                                },
                            );
                        },
                    },
                ]}
            />
        ),
        [
            integrationPanel.show,
            locationRouterService,
            overviewAppLocations.integrationsVersionHistoryLocation,
        ],
    );

    return (
        <TableWithPreferencesManagement
            {...tableProps}
            actionMenu={actionMenu}
            defaultSort={{
                fieldName: "instanceName",
                sortDir: "ascending",
            }}
            columns={columns}
            fixed
            rowKey="id"
            datasetSlicing="server"
            preferencesType={preferencesType}
            exportUrl="api/dashboard/export"
            panelControl={integrationPanel}
            renderPanelHeader={record => (
                <>
                    <H4>{record.instanceName}</H4>
                    {record.hasRight && (
                        <IntegrationLink
                            accountId={record.accountId}
                            instanceId={record.id}
                            getDefaultLocation={getDefaultLocation}
                        />
                    )}
                    <Gap />
                </>
            )}
        />
    );
};

IntegrationsList.propTypes = {};

export default IntegrationsList;
