import { Button, ButtonGroup, Card, H3, Row } from "@/components/DesignSystem";
import { colors } from "@/components/DesignSystem/colors";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    getHealthStateTranslation,
    instanceHealthStates,
} from "@/components/InstanceHealth/utils";
import { HEALTH_CHART } from "@/constants/sessionState.constants";
import { useQueryLoadable } from "@/modules/loadable";
import { LoadableRenderer } from "@/modules/loadable/LoadableRenderer.component";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { createSessionState } from "@/utils/sessionState/sessionState";
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import forEachRight from "lodash/forEachRight";
import PropTypes from "prop-types";
import React from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    ArcElement,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
);

const mainChartOpts = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: true,
        position: "bottom",
        labels: {
            fontColor: colors.primary,
        },
    },
    scales: {
        x: {
            gridLines: {
                drawOnChartArea: false,
            },
        },
        y: {
            type: "category",
            labels: [
                instanceHealthStates.UP,
                instanceHealthStates.DOWN,
                instanceHealthStates.NOT_MONITORED,
            ].map(getHealthStateTranslation),
        },
    },
    elements: {
        point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3,
        },
    },
};

const { useSessionState } = createSessionState(HEALTH_CHART);

const HealthChart = ({ instanceId }) => {
    const [radioSelected, setRadioSelected] = useSessionState(
        "radioSelected",
        3,
    );
    const { instanceService } = useDic();
    const healthHistoryResource = useQueryLoadable(async () => {
        const days = { 1: 1, 2: 7, 3: 30 }[radioSelected];
        return instanceService
            .fetchInstanceHealthHistory(instanceId, days)
            .then(getData)
            .then(health => {
                const labels = [];
                const values = [];
                forEachRight(health, h => {
                    labels.push(formatLabel(h.date, radioSelected));
                    values.push(getHealthStateTranslation(h.healthState));
                });

                return {
                    labels: labels,
                    datasets: [
                        {
                            fill: true,
                            label: "Status",
                            lineTension: 0,
                            steppedLine: "after",
                            borderColor: colors.info.border,
                            backgroundColor: colors.info.background,
                            pointHoverBackgroundColor: "#fff",
                            borderWidth: 1,
                            borderDash: [8, 5],
                            data: values,
                        },
                    ],
                };
            });
    }, [instanceId, instanceService, radioSelected]);

    const formatLabel = (dateInString, range) => {
        if (range === 1) {
            return new Date(dateInString).toLocaleTimeString();
        }
        return new Date(dateInString).toLocaleString();
    };

    const getButtonType = buttonNumber =>
        radioSelected === buttonNumber ? "primary" : "default";

    return (
        <Card spacing="medium">
            <Row justify="space-between">
                <H3>{t("health-chart.title")}</H3>
                <ButtonGroup spacing="none">
                    <Button
                        htmlType="button"
                        type={getButtonType(1)}
                        onClick={() => setRadioSelected(1)}
                        label={t("health-chart.filter.day")}
                    />
                    <Button
                        htmlType="button"
                        type={getButtonType(2)}
                        onClick={() => setRadioSelected(2)}
                        label={t("health-chart.filter.week")}
                    />
                    <Button
                        htmlType="button"
                        type={getButtonType(3)}
                        onClick={() => setRadioSelected(3)}
                        label={t("health-chart.filter.month")}
                    />
                </ButtonGroup>
            </Row>
            <LoadableRenderer
                loadable={healthHistoryResource.loadable}
                hasValue={mainChart => (
                    <div
                        className="chart-wrapper"
                        style={{ height: 300 + "px", marginTop: 40 + "px" }}
                    >
                        <Line
                            data={mainChart}
                            options={mainChartOpts}
                            height={300}
                        />
                    </div>
                )}
            />
        </Card>
    );
};

HealthChart.propTypes = {
    instanceId: PropTypes.number.isRequired,
};

export default HealthChart;
