import { useIncludesRightsForPartition } from "@/components/hooks/useIncludesRightsForPartition.hook";
import React from "react";
import { DataUploadFieldMapping } from "./DataUploadFieldMapping";

export const WizardNewDataUpload3 = ({
    allValues = {},
    entityName = allValues.entityName,
    entityType = allValues.entityType,
    onCancel,
    onNext,
    onBack,
    partitionId,
    projectId,
    tableExampleData,
    definition = allValues.mapper,
    parserConfig = allValues,
    initialConvertEmptyStringToNull = allValues.convertEmptyStringToNull,
    initialBusinessKey = allValues.businessKey,
    onFileInfo,
}) => {
    const hasDataLoadEditRights = useIncludesRightsForPartition([
        "partition.data_management.edit",
    ]);

    const tableData = tableExampleData || {};

    return (
        <DataUploadFieldMapping
            afterSubmit={onNext}
            entityName={entityName}
            entityType={entityType}
            // apiErrors={maybeError(startDataUploadMutation)?.response?.data} // TODO!!!
            onCancel={onCancel}
            onBack={onBack}
            partitionId={partitionId}
            readOnly={!hasDataLoadEditRights(projectId, partitionId)}
            tableData={tableData}
            initMapper={definition}
            parserConfig={parserConfig}
            initialConvertEmptyStringToNull={initialConvertEmptyStringToNull}
            initialBusinessKey={initialBusinessKey}
            onFileInfo={onFileInfo}
        />
    );
};
