import { Calendar } from "@/components/DesignSystem";
import * as React from "react";
import "./SlotsCalendar.style.less";
const SlotsCalendar = ({ ...props }) => {
    return (
        <div className="pmSlotsCalendar">
            <Calendar {...props} />
        </div>
    );
};

export default SlotsCalendar;
