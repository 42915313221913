import { MyRequests } from "@/components/ProductEnhancement/MyRequests";
import { ProductEnhancementPage } from "@/components/ProductEnhancement/ProductEnhancementPage";
import { createLocation } from "@/modules/router";
import { productEnhancementAppLocation } from "../../locations";

const allRequests = createLocation(
    productEnhancementAppLocation,
    "allRequests",
    "/all-requests",
    {
        component: ProductEnhancementPage,
    },
);
const myRequests = createLocation(
    productEnhancementAppLocation,
    "myRequests",
    "/my-requests",
    {
        component: MyRequests,
    },
);

export const productEnhancementAppLocationsMap = {
    allRequests,
    myRequests,
};
