import Changed from "@/components/DateTime/Changed";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import {
    useConnectionsListQuery,
    useDeleteConnectionMutation,
} from "@/components/ISVConnections/loadables";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { getISVConnectionsTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { useRouteAccountPartitions } from "@/mixpanel/hooks/useRouteAccountPartitions.hook";
import { useRouteDataRefetch } from "@/mixpanel/hooks/useRouteDataRefetch.hook";
import { isLoading } from "@/modules/loadable";
import { LocationLink } from "@/modules/router";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";

const preferencesType = preferencesTypes.ISV_CONNECTIONS_LIST_TABLE;

const createColumns = ({ accountId, partitionsById, overviewLocation }) => [
    {
        type: fieldTypes.TEXT,
        name: "name",
        label: t("isv-connections.list.name"),
        canSort: false,
        canFilter: false,
        render: (name, record) => (
            <LocationLink
                key={record.id}
                $location={overviewLocation}
                $params={{
                    accountId,
                    isvConnectionId: record.id,
                }}
            >
                {name}
            </LocationLink>
        ),
    },
    {
        type: fieldTypes.TEXT,
        name: "vendor",
        label: t("isv-connections.list.vendor"),
        canSort: false,
        canFilter: false,
    },
    {
        type: fieldTypes.TEXT,
        name: "state",
        label: t("isv-connections.list.status"),
        canSort: false,
        canFilter: false,
    },
    {
        type: fieldTypes.TEXT,
        name: "partitionId",
        label: t("isv-connections.list.partition"),
        canSort: false,
        canFilter: false,
        render: (text, record) =>
            partitionsById[record.partitionId]?.serialNumber,
    },
    {
        type: fieldTypes.DATE,
        name: "updatedAt",
        label: t("isv-connections.list.last-change"),
        canSort: false,
        canFilter: false,
        render: (text, record) => <Changed key={record.id} data={record} />,
    },
    // {
    //     type: fieldTypes.TEXT,
    //     name: "updatedBy",
    //     label: t("isv-connections.list.date-connected"),
    //     canSort: false,
    //     canFilter: false,
    // },
];

const ISVConnectionsListPage = () => {
    const {
        locationRouterService,
        accountAppLocations: {
            isvConnectionsNewLocation,
            isvConnectionsOverviewLocation,
            isvConnectionsSettingsLocation,
        },
    } = useDic();

    const refetchRouteData = useRouteDataRefetch();
    const { accountPartitionsLoadable } = useRouteAccountPartitions();

    const partitionsById = (
        accountPartitionsLoadable.valueMaybe() ?? []
    ).reduce((acc, partition) => ({ [partition.id]: partition, ...acc }), {});

    const { accountId } = useAccountAppParams();

    useBreadcrumbButton(
        {
            label: "location.accountApp.isvConnections.create",
            onClick: () =>
                locationRouterService.navigate(isvConnectionsNewLocation),
            track: { name: getTrackName("New") },
        },
        [],
    );

    const connectionsListResource = useConnectionsListQuery({ accountId });
    const deleteConnectionMutation = useDeleteConnectionMutation({
        afterSuccess: () => {
            refetchRouteData();
            connectionsListResource.reload();
        },
    });
    useFullWidthTableLayout();

    const navigateToSettings = useCallback(
        record => {
            locationRouterService.navigate(isvConnectionsSettingsLocation, {
                accountId,
                isvConnectionId: record.id,
            });
        },
        [locationRouterService, isvConnectionsSettingsLocation, accountId],
    );

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.edit"),
                        onClick: navigateToSettings,
                    },
                    {
                        title: t("general.delete"),
                        confirmMessage: t(
                            "general.do-you-really-want-to-delete-name",
                            { name: record.name },
                        ),
                        onConfirm: deleteConnectionMutation.mutate,
                        color: "red",
                    },
                ]}
            />
        ),
        [deleteConnectionMutation.mutate, navigateToSettings],
    );

    return (
        <TableWithPreferencesManagement
            loading={isLoading(connectionsListResource.loadable)}
            dataSource={connectionsListResource.loadable.valueMaybe() ?? []}
            actionMenu={actionMenu}
            defaultSort={{
                fieldName: "name",
                sortDir: "ascending",
            }}
            columns={createColumns({
                accountId,
                partitionsById,
                overviewLocation: isvConnectionsOverviewLocation,
            })}
            pagination={false}
            fixed
            rowKey="id"
            datasetSlicing="local"
            preferencesType={preferencesType}
        />
    );
};

export default ISVConnectionsListPage;
