import { axiosService } from "../axios";

const baseUrl = "/api/public/users/";

export const accountActivationService = {
    fetchUsername(activationToken) {
        return axiosService.get(`${baseUrl}${activationToken}`);
    },

    activateAccount(activationToken, user) {
        return axiosService.post(`${baseUrl}${activationToken}/activate`, user);
    },
};
