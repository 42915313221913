import { Gap } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    TemplateListSkeleton,
    TemplatesList,
    useTemplates,
} from "@/components/Marketplace/TemplatesList/TemplatesList.component";
import {
    MarketplaceFooter,
    SCROLL_ELEMENT_ID,
} from "@/components/Marketplace/components/MarketplaceFooter/MarketplaceFooter.component";
import { MarketplaceItem } from "@/components/Marketplace/components/MarketplaceItem/MarketplaceItem.component";
import { MarketplaceSearch } from "@/components/Marketplace/components/MarketplaceSearch/MarketplaceSearch.component";
import { useContextTemplateCategoriesQuery } from "@/components/Marketplace/components/TagsMenu/TemplateCategories.context";
import { LoadableRenderer, mapLoadableItems } from "@/modules/loadable";
import { useMarketplaceAppParams } from "@/modules/router/hooks/useMarketplaceAppParams.hook";
import { SecurityContext, UnauthorizedContent } from "@/security/authorization";
import {
    hasAnyPermissionsFunc,
    hasMarketplaceGlobalPermission,
} from "@/security/permission.utils";
import { size } from "lodash";
import * as React from "react";
import { TemplatesManagementPageContainer } from "../../apps/marketplaceApp/pages/TemplatesManagementPage/TemplatesManagementPage.container";

export const NUMBER_ITEMS_PER_PAGE = 15;

export const MarketplacePage = () => {
    const {
        locationRouterService,
        marketplaceAppLocations: {
            marketplaceLocation,
            marketplaceDetailLocation,
        },
    } = useDic();
    const templateCategoriesResource = useContextTemplateCategoriesQuery();
    const securityContext = React.useContext(SecurityContext);
    const { accountId, tag, filter = "", page = 1 } = useMarketplaceAppParams();

    const templatesResource = useTemplates({
        accountId,
        category: tag,
    });

    const templatesLoadable = mapLoadableItems(templatesResource, templates => {
        const filteredTemplates = templates.filter(template => {
            return template.label.toLowerCase().includes(filter.toLowerCase());
        });

        const pagedTemplates = filteredTemplates.slice(
            page * NUMBER_ITEMS_PER_PAGE - NUMBER_ITEMS_PER_PAGE,
            page * NUMBER_ITEMS_PER_PAGE,
        );
        return {
            templates: pagedTemplates,
            current: page,
            total: size(filteredTemplates),
            pageSize: NUMBER_ITEMS_PER_PAGE,
        };
    });
    return (
        (hasAnyPermissionsFunc(securityContext) && (
            <>
                <MarketplaceSearch
                    id={SCROLL_ELEMENT_ID}
                    value={filter}
                    onSearch={value => {
                        locationRouterService.navigate(marketplaceLocation, {
                            accountId,
                            filter: value,
                            page,
                        });
                    }}
                />
                <Gap size="medium" />
                <LoadableRenderer
                    loadable={templatesLoadable}
                    loading={() => (
                        <TemplateListSkeleton
                            numberOfItems={
                                templateCategoriesResource.loadable.valueMaybe()?.[
                                    tag
                                ]
                            }
                        />
                    )}
                    hasValue={({ templates, current, total, pageSize }) => {
                        return (
                            <>
                                <TemplatesList
                                    templates={templates}
                                    renderItem={template => (
                                        <MarketplaceItem
                                            key={template.uniqueName}
                                            accountId={accountId}
                                            template={template}
                                            onFavoriteChange={
                                                templateCategoriesResource.reload
                                            }
                                            navigateToDetail={() => {
                                                locationRouterService.navigate(
                                                    marketplaceDetailLocation,
                                                    {
                                                        accountId,
                                                        packageName:
                                                            template.uniqueName,
                                                    },
                                                );
                                            }}
                                        />
                                    )}
                                />
                                <Gap size="medium" />
                                {total > NUMBER_ITEMS_PER_PAGE ? (
                                    <MarketplaceFooter
                                        current={current}
                                        total={total}
                                        pageSize={pageSize}
                                        onPageChange={page => {
                                            locationRouterService.navigate(
                                                marketplaceLocation,
                                                {
                                                    accountId,
                                                    filter,
                                                    page,
                                                },
                                            );
                                        }}
                                    />
                                ) : null}
                            </>
                        );
                    }}
                />
            </>
        )) ||
        (hasMarketplaceGlobalPermission(securityContext.globalPermissions) && (
            <TemplatesManagementPageContainer />
        )) || <UnauthorizedContent />
    );
};
