import flatMap from "lodash/flatMap";
import pipe from "lodash/fp/pipe";
import entries from "lodash/fp/entries";
import map from "lodash/fp/map";
import curry from "lodash/fp/curry";
import fromPairs from "lodash/fp/fromPairs";

const concat = (acc, v) => acc.concat(v);

export const treeToArr = ([prop, ...props], obj, merge = {}) =>
    Object.entries(obj)
        .map(([key, value]) =>
            props.length
                ? treeToArr(props, value, { ...merge, [prop]: key })
                : { ...value, ...merge, [prop]: key },
        )
        .reduce(concat, []);

export const filterObject = curry((predicate, obj) =>
    Object.fromEntries(Object.entries(obj).filter(([k, v]) => predicate(v, k))),
);

export const flatMapProp = (prop, collection = {}) =>
    flatMap(collection, item => item?.[prop] ?? []);

export const nextIndex = (array, currentIndex = 0) =>
    (currentIndex + 1) % array.length;

export const mapObjectBinaryIteratee = iteratee =>
    pipe(
        entries,
        map(([k, v]) => [k, iteratee(v, k)]),
        fromPairs,
    );
