import { useMarketplaceAppParams } from "@/modules/router/hooks/useMarketplaceAppParams.hook";
import React from "react";
import TemplatesManagementDetail from "./TemplatesManagementDetail";

export const TemplatesManagementDetailPageContainer =
    function TemplatesManagementDetailPageContainer() {
        const { uniqueName } = useMarketplaceAppParams();

        return <TemplatesManagementDetail uniqueName={uniqueName} />;
    };
