import { RollbackModal } from "@/components/CustomerPartitions/RollbackModal.component";
import { Alert } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { useNavigateToPackages } from "@/components/Marketplace/hooks/useNavigateToPackages.hook";
import {
    hasValue,
    isLoading,
    MutationResourcePropType,
} from "@/modules/loadable";
import { HasValueLoadable } from "@/modules/loadable/useQueryLoadable.hook";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { deployToEnum } from "../../../../constants/deployment.constants";
import {
    formatDate,
    isPackageRunning,
} from "../../../../views/Package/package.utils";
import {
    useCheckDeployability,
    useDeploymentStateQuery,
    useStartDeploymentMutation,
} from "../../loadables";
import InstanceWarning from "../WarningStep/InstanceWarning.component";
import PartitionWarning from "../WarningStep/PartitionWarning.component";
import { useRequestRollbackMutation, useRequestTermination } from "./loadables";
import { UnfinishedInstanceWarningModal } from "./UnfinishedInstanceWarningModal.component";
import { UnfinishedPartitionWarningModal } from "./UnfinishedPartitionWarningModal.component";

const isIdle = resource =>
    hasValue(resource) && resource.loadable.valueMaybe() === undefined;

const isFetched = resource => hasValue(resource) && !isIdle(resource);

const BeforeDeploymentModals = ({
    templateResource,
    visible,
    onCancel,
    onDeployment,
    templateDeployment,
    onDestinationIdChange,
}) => {
    const {
        locationRouterService,
        marketplaceAppLocations: { packagePartitionLocation },
    } = useDic();
    const { destinationId, destination, accountId, template, templateVersion } =
        templateDeployment;

    const { navigateToPackages } = useNavigateToPackages();
    const startDeploymentMutation = useStartDeploymentMutation({
        templateDeployment,
        afterSuccess: templateDeployment => {
            onCancel();
            navigateToPackages(templateDeployment);
        },
    });

    const [isDataWarningConfirmed, setDataWarningConfirmation] =
        useState(false);

    useEffect(() => {
        if (!visible) {
            setDataWarningConfirmation(false);
        }
    }, [visible]);

    const onDataWarningConfirm = ({ destinationId }) => {
        onDestinationIdChange?.(destinationId);
        deploymentStateQuery.reload();
        unfinishedDeploymentQuery.reload();
        setDataWarningConfirmation(true);
    };

    const deploymentStateQuery = useDeploymentStateQuery({
        idle: !(
            destination === deployToEnum.INSTANCE &&
            isDataWarningConfirmed &&
            template.uniqueName &&
            destinationId
        ),
        destination,
        destinationId,
        packageName: template.uniqueName,
        templateVersion,
    });

    const params = {
        idle: !(
            destination === deployToEnum.PARTITION &&
            isDataWarningConfirmed &&
            template.uniqueName &&
            destinationId
        ),
        packageName: template.uniqueName,
        templateVersion,
        destination,
        destinationId,
    };
    const unfinishedDeploymentQuery = useCheckDeployability(params);

    const unfinishedDeployment =
        unfinishedDeploymentQuery.loadable.contents?.unfinishedDeployment;

    const isDeploymentRunning =
        isPackageRunning(
            deploymentStateQuery.loadable.valueMaybe()?.processState,
        ) || !!unfinishedDeployment?.deploymentId;

    const startDeployment = useCurrentHandler(startDeploymentMutation.mutate);
    useEffect(() => {
        if (
            (isFetched(deploymentStateQuery) ||
                isFetched(unfinishedDeploymentQuery)) &&
            !isDeploymentRunning &&
            !startDeploymentMutation.error
        ) {
            startDeployment();
        }
    }, [
        deploymentStateQuery,
        destinationId,
        isDeploymentRunning,
        unfinishedDeploymentQuery,
        startDeployment,
        startDeploymentMutation.error,
    ]);

    const onCancelHandler = useCallback(() => {
        setDataWarningConfirmation(false);
        onCancel?.();
    }, [onCancel]);

    const warningModalVisible =
        (!isFetched(deploymentStateQuery) &&
            !isFetched(unfinishedDeploymentQuery)) ||
        !isDeploymentRunning;

    const deploymentRunningModalVisible =
        (isFetched(deploymentStateQuery) ||
            isFetched(unfinishedDeploymentQuery)) &&
        isDeploymentRunning;

    const rollbackModal = useVisibility(false);

    const requestRollbackMutation = useRequestRollbackMutation({
        accountId,
        partitionId: destinationId,
        deploymentId: unfinishedDeployment?.deploymentId,
        afterSuccess: () => {
            rollbackModal.hide();
            onCancel();
        },
    });

    const requestTerminationMutation = useRequestTermination({
        accountId,
        partitionId: destinationId,
        deploymentId: unfinishedDeployment?.deploymentId,
        afterSuccess: onCancel,
    });

    const continueDeployment = useCallback(() => {
        onDeployment({
            accountId,
            destinationId,
            template,
        });
    }, [accountId, destinationId, onDeployment, template]);

    const continueUnfinishedDeployment = useCallback(() => {
        locationRouterService.navigate(packagePartitionLocation, {
            accountId,
            partitionId: destinationId,
            packageName: unfinishedDeployment?.templateName,
        });
    }, [
        accountId,
        destinationId,
        locationRouterService,
        packagePartitionLocation,
        unfinishedDeployment?.templateName,
    ]);
    const alert = startDeploymentMutation.error && (
        <Alert
            type="error"
            showIcon
            banner={true}
            message={startDeploymentMutation.error}
        />
    );
    useEffect(() => {
        if (!visible) startDeploymentMutation.resetError();
    }, [startDeploymentMutation, visible]);

    return (
        <>
            {destination === deployToEnum.INSTANCE && (
                <InstanceWarning
                    accountId={accountId}
                    onCancel={onCancelHandler}
                    onConfirm={onDataWarningConfirm}
                    packageName={template.uniqueName}
                    instanceId={destinationId}
                    allowSelect={!!onDestinationIdChange}
                    templateResource={templateResource}
                    visible={visible && warningModalVisible}
                    pending={
                        isLoading(deploymentStateQuery) ||
                        isLoading(startDeploymentMutation)
                    }
                    alert={alert}
                    key={+visible}
                />
            )}
            {destination === deployToEnum.PARTITION && (
                <PartitionWarning
                    accountId={accountId}
                    onCancel={onCancelHandler}
                    onConfirm={onDataWarningConfirm}
                    packageName={template.uniqueName}
                    partitionId={destinationId}
                    allowSelect={!!onDestinationIdChange}
                    templateResource={templateResource}
                    visible={visible && warningModalVisible}
                    alert={alert}
                    pending={
                        isLoading(unfinishedDeploymentQuery) ||
                        isLoading(startDeploymentMutation)
                    }
                    key={+visible}
                />
            )}
            <UnfinishedInstanceWarningModal
                visible={
                    deploymentRunningModalVisible &&
                    destination === deployToEnum.INSTANCE
                }
                deploymentState={deploymentStateQuery.loadable.valueMaybe()}
                pending={isLoading(startDeploymentMutation)}
                onNew={() => startDeploymentMutation.mutate(templateDeployment)}
                onContinue={continueDeployment}
                onCancel={onCancelHandler}
            />
            <UnfinishedPartitionWarningModal
                visible={
                    deploymentRunningModalVisible &&
                    destination === deployToEnum.PARTITION
                }
                template={template}
                unfinishedDeployment={unfinishedDeployment}
                pending={isLoading(startDeploymentMutation)}
                onRollback={
                    unfinishedDeployment?.rollbackSupported
                        ? rollbackModal.show
                        : null
                }
                requestTerminationMutation={requestTerminationMutation}
                onContinue={continueUnfinishedDeployment}
                onCancel={onCancelHandler}
            />
            {rollbackModal.visible && (
                <RollbackModal
                    accountId={accountId}
                    partitionId={destinationId}
                    deploymentId={unfinishedDeployment?.deploymentId}
                    perex={t("rollback.modal.perex-unfinished-deployment", {
                        templateName: unfinishedDeployment?.templateName,
                        date: formatDate(unfinishedDeployment?.startedAt),
                    })}
                    rollbackMutation={requestRollbackMutation}
                    rollbackOptionsLoadable={HasValueLoadable(
                        unfinishedDeployment?.rollbackOptions,
                    )}
                    visible
                    onClose={rollbackModal.hide}
                />
            )}
        </>
    );
};

// BeforeDeploymentModals.whyDidYouRender = true;
BeforeDeploymentModals.propTypes = {
    visible: PropTypes.bool.isRequired,
    startDeploymentMutation: MutationResourcePropType(),
    onCancel: PropTypes.func,
    onDeployment: PropTypes.func,
    onDestinationIdChange: PropTypes.func,
    templateDeployment: PropTypes.object,
    templateResource: PropTypes.object.isRequired,
};

export default BeforeDeploymentModals;
