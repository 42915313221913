import { Button, ButtonGroup, Gap, H3, Text } from "@/components/DesignSystem";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { T } from "@/translations";
import reduce from "lodash/reduce";
import remove from "lodash/remove";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import SwitchOption from "../FilterForm/SwitchOption";

export const ModuleMultipleSelectComponent = ({
    step,
    onNext,
    onBack,
    globalState,
    changeState,
}) => {
    const { skipDependencies = [] } = globalState;

    const onChange = (name, value) => {
        value
            ? remove(skipDependencies, n => n === name)
            : skipDependencies.push(name);
        changeState({ skipDependencies });
    };

    const onNextClick = () => {
        onNext({ skipDependencies });
    };

    const skipDependenciesMap = reduce(
        skipDependencies,
        (result, value) => {
            result[value] = false;
            return result;
        },
        {},
    );

    return (
        <div>
            <H3>{step.ui?.title}</H3>
            <Text size="large">{step.ui?.description}</Text>
            <Gap size="large" />
            <Text>
                <T id="module-multiple-select.text" />
            </Text>
            <Gap size="large" />

            {step.options.map(({ description, name, title }) => (
                <SwitchOption
                    key={name}
                    description={description}
                    onChange={v => onChange(name, v)}
                    checked={
                        skipDependenciesMap[name] !== undefined
                            ? skipDependenciesMap[name]
                            : true
                    }
                    name={title}
                />
            ))}

            <ButtonGroup>
                <Button
                    type="primary"
                    onClick={onNextClick}
                    label={
                        <FormattedMessage id="module-multiple-select.button.next" />
                    }
                />
                <BackButton onBack={onBack} />
            </ButtonGroup>
        </div>
    );
};

ModuleMultipleSelectComponent.propTypes = {
    step: PropTypes.object.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    globalState: PropTypes.object.isRequired,
    changeState: PropTypes.func.isRequired,
};
