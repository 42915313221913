import { t } from "@/translations";

//TODO refactor function for general use
export const mapEntitiesQueryToTree = ({ query, isUserAndGroup = false }) => {
    const entitiesToTreeFn = entity => ({
        title: entity.name,
        value: JSON.stringify({
            type: entity.type,
            id: entity.id,
            groupName: entity.groupName,
            name: entity.name,
        }),
    });

    if (isUserAndGroup) {
        const data = query.loadable.valueMaybe() || [];
        return [
            {
                title: t("pm-feature-flags.restrictions-modal.form.type.user"),
                value: t("pm-feature-flags.restrictions-modal.form.type.user"),
                selectable: false,
                children: data[0]?.data.map(entitiesToTreeFn),
            },
            {
                title: t(
                    "pm-feature-flags.restrictions-modal.form.type.user-group",
                ),
                value: t(
                    "pm-feature-flags.restrictions-modal.form.type.user-group",
                ),
                selectable: false,
                children: data[1]?.data.map(entitiesToTreeFn),
            },
        ];
    } else {
        const entities = query.loadable.valueMaybe()?.data || [];
        return entities.map(entitiesToTreeFn);
    }
};

export const mapInitialRestrictions = restrictions =>
    restrictions?.map(r => ({
        ...r,
        primary: r.primary[0],
    }));

export const valueToTreeSelect = e =>
    e && { label: e.name, value: JSON.stringify(e) };

export const valueFromTreeSelect = e => e && JSON.parse(e.value);

export const valueToTreeMultipleSelect = v => v?.map(valueToTreeSelect);

export const valueFromTreeMultipleSelect = v => v?.map(valueFromTreeSelect);

const TYPE_TRANSLATION = {
    ACCOUNT: t("pm-feature-flags.restrictions-modal.form.type.account"),
    USER: t("pm-feature-flags.restrictions-modal.form.type.user"),
    ADMIN_GROUP: t("pm-feature-flags.restrictions-modal.form.type.user-group"),
};

export const mapRestrictionsToExpandableDataSource = data => {
    const groupedObj = data.reduce(
        (result, obj) => ({
            ...result,
            [obj.mainType]: [
                ...(result[obj.mainType] ?? []),
                { primary: obj.primary, secondary: obj.secondary },
            ],
        }),
        {},
    );

    const arr = Object.entries(groupedObj).map(([key, value]) => ({
        key,
        col_0: TYPE_TRANSLATION[key] ?? key,
        col_1: "",
        children: value.map((c, i) => ({
            key: `${key}${i}`,
            col_0: c.primary[0].name,
            col_1:
                c.secondary.length === 0
                    ? t("general.all")
                    : c.secondary.map(s => s.name).join(", "),
        })),
    }));

    return arr;
};
