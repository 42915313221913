import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    pendingPromise,
    responseErrorMessage,
    useQueryLoadable,
} from "@/modules/loadable";
import { noop } from "lodash/fp";

export const useDocumentationQuery = ({
    instanceId,
    canFetch,
    onError = noop,
}) => {
    const { instanceService } = useDic();
    const onErrorCurrent = useCurrentHandler(onError);

    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : instanceService.fetchDocumentation(instanceId).catch(e => {
                      onErrorCurrent(responseErrorMessage(e));
                      throw e;
                  }),
        [canFetch, instanceService, instanceId, onErrorCurrent],
    );
};
