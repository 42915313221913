import {
    Alert,
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { EncryptedField } from "@/components/EncryptedField/EncryptedField";
import DisableSubmitOnEnter from "@/components/FeatureFlags/components/DisableSubmitOnEnter.component";
import { useWithRestartingRoutesModal } from "@/components/Integrations/components/RestartingRoutesModal/useWithRestartingRoutesModal.hook";
import { useSaveIntegrationPropertyMutation } from "@/components/Properties/loadables";
import { propertyType } from "@/components/Properties/propertyType.enum";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { isEmpty, omit } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useState } from "react";
const { useForm, Form, Fields, pmValidators } = Forms;

const initName = (maybePrefix, maybeName) => {
    if (
        maybePrefix &&
        maybeName?.substr?.(0, maybePrefix.length) === maybePrefix
    )
        return maybeName.substr(maybePrefix.length);
    return maybeName;
};

const prefixName = (maybePrefix, maybeName) => {
    if (maybePrefix && maybeName) return maybePrefix + maybeName;
    return maybeName;
};

const getMessage = (isEdit, runtime) => {
    if (runtime) {
        return t("general.deploy");
    }
    return isEdit ? t("general.save") : t("general.add");
};

const EditPropertyModal = ({
    property,
    entityType,
    entityId,
    visible,
    onSave,
    onCancel,
    prefix,
    runtime,
    instanceId,
    encryptionSupported = false,
}) => {
    const isEdit = !isEmpty(property);
    const [alert, setAlert] = useState(null);

    const withRestartingRoutesModal = useWithRestartingRoutesModal(instanceId);

    const saveIntegrationPropertyMutation = useSaveIntegrationPropertyMutation({
        entityType,
        entityId,
        afterSuccess: onSave,
        onError: error => {
            setAlert(getErrorMessage(error.response.data));
        },
    });

    const { formId, handleSubmit, setValues } = useForm({
        initialValues: {
            ...property,
            name: initName(prefix, property?.name),
        },
        onSubmit: ({ values }) =>
            withRestartingRoutesModal(
                () =>
                    saveIntegrationPropertyMutation.mutate({
                        values: omit("encrypted", {
                            ...values,
                            name: prefixName(prefix, values?.name),
                        }),
                    }),
                {
                    changedProperties: [values?.name],
                },
            ),
    });

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={
                        isEdit
                            ? t("edit-property.modal.title.edit-property")
                            : t("edit-property.modal.title.add-property")
                    }
                    alert={
                        alert && (
                            <Alert
                                showIcon
                                banner
                                type="error"
                                message={alert}
                            />
                        )
                    }
                />
                <UnityLayout.Content padding={[false, true]}>
                    <DisableSubmitOnEnter>
                        <Form formId={formId} onSubmit={handleSubmit}>
                            {isEdit && <Fields.Input name="id" type="hidden" />}
                            <Fields.Input
                                name="name"
                                label={t("edit-property.modal.form.name.label")}
                                placeholder={t(
                                    "edit-property.modal.form.name.placeholder",
                                )}
                                required
                                validator={pmValidators.isRequired}
                                inputWidth="max"
                                addonBefore={prefix}
                            />
                            <Fields.Select
                                label={t("edit-property.modal.form.type.label")}
                                placeholder={t(
                                    "edit-property.modal.form.type.placeholder",
                                )}
                                name="type"
                                required
                                validator={pmValidators.isRequired}
                                inputWidth="max"
                                options={Object.values(propertyType).map(
                                    value => ({
                                        label: value,
                                        value,
                                    }),
                                )}
                            />

                            <EncryptedField
                                type={Fields.TextArea}
                                formId={formId}
                                setValues={setValues}
                                name="value"
                                label={t(
                                    "edit-property.modal.form.value.label",
                                )}
                                placeholder={t(
                                    "edit-property.modal.form.value.placeholder",
                                )}
                                inputWidth="max"
                                disabled={!encryptionSupported}
                            />
                        </Form>
                    </DisableSubmitOnEnter>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: getMessage(isEdit, runtime),
                                    type: "primary",
                                    formId,
                                },
                                {
                                    label: t("general.cancel"),
                                    type: "text",
                                    onClick: onCancel,
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

EditPropertyModal.propTypes = {
    property: PropTypes.object.isRequired,
    instanceId: PropTypes.number.isRequired,
    entityId: PropTypes.number.isRequired,
    entityType: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    prefix: PropTypes.string,
    runtime: PropTypes.bool,
    encryptionSupported: PropTypes.bool,
};

export default EditPropertyModal;
