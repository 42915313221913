import { Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCallback } from "react";
import qs from "qs";
import { getData } from "@/services/utils";

export const useSchedulerNameExistsValidation = ({
    accountId,
    skipValidation,
    destinationType,
    destinationId,
}) => {
    const { axiosService } = useDic();

    return useCallback(
        async (value, getBag) => {
            if (skipValidation({ name, destinationType, destinationId }))
                return Forms.success();

            const isSingleSource = true;
            const params = qs.stringify(
                {
                    name: value,
                    ...(isSingleSource
                        ? {
                              destinationType,
                              destinationId,
                          }
                        : {}),
                },
                {
                    addQueryPrefix: true,
                },
            );

            return axiosService
                .get(`/api/accounts/${accountId}/event-schedulers${params}`)
                .then(getData)
                .then(list => {
                    if (list?.length) return Forms.error("Already exists");
                    else return Forms.success();
                });
        },
        [
            accountId,
            axiosService,
            skipValidation,
            destinationType,
            destinationId,
        ],
    );
};
