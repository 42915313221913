import { getObjectKey } from "@/services/certificates.service";
import qs from "qs";
import { axiosService } from "../axios";

const qsOptions = { addQueryPrefix: true };

const getMapperPayload = values => ({
    name: values.name,
    definition: values.definition,
    type: values.type,
    instanceId: values.instanceId,
    properties: values.properties || {
        convertEmptyStringToNull: values.convertEmptyStringToNull ?? false,
    },
});

export const mappersService = {
    fetchMappers(instanceId) {
        return axiosService.get(`/api/mappers?instanceId=${instanceId}`);
    },

    fetchInstanceRepoMapper(objectKey) {
        return axiosService.get(`/api/mappers/online/${objectKey}`);
    },

    fetchInstanceRepoMappers(instanceId) {
        return axiosService.get(`/api/mappers/online?instanceId=${instanceId}`);
    },

    fetchMapper(mapperId) {
        return axiosService.get(`/api/mappers/${mapperId}`);
    },

    instanceRepoLocalCopy(instanceId, payload) {
        return axiosService.post(
            `/api/instance/${instanceId}/mappers-online-copy`,
            payload,
        );
    },

    copy(mapperId, payload) {
        return axiosService.post(`/api/mappers/${mapperId}/copy`, payload);
    },

    createMapper(values, query) {
        const queryStr = qs.stringify(query, qsOptions);
        const data = {
            projectId: values.projectId,
            ...getMapperPayload(values),
        };
        return axiosService.post(`/api/mappers${queryStr}`, data);
    },

    updateMapper(mapperId, values, query) {
        const queryStr = qs.stringify(query, qsOptions);
        const data = getMapperPayload(values);
        return axiosService.post(`/api/mappers/${mapperId}${queryStr}`, data);
    },

    deleteMapper(mapperId) {
        return axiosService.delete(`/api/mappers/${mapperId}`);
    },

    deleteInstanceRepoMapper({ instanceId, mapperName, prn }) {
        return axiosService.delete(
            `/api/instance/${instanceId}/mappers-online-delete/${mapperName}`,
            {
                data: prn ? { prn } : null,
            },
        );
    },

    convertMapper(xmlDefinition) {
        return axiosService.post(`/api/mappers/convert`, xmlDefinition);
    },

    mapperExists({ instanceId, name, withOnline = true }) {
        const objectKey = getObjectKey({ name, instanceId });
        return axiosService.get(
            `/api/mappers/${objectKey}/exists${qs.stringify(
                { withOnline },
                qsOptions,
            )}`,
        );
    },
};
