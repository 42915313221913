import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { message } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Loading } from "../../../../Loading/Loading";
import IntegrationProperties from "../IntegrationProperties/IntegrationProperties";
import { integrationSftpService } from "./integrationSftp.service";

export const IntegrationSftp = props => {
    const [loading, setLoading] = useState(false);

    const testConnection = data => {
        setLoading(true);
        integrationSftpService
            .testSftpConnection(data.ftp)
            .then(res => {
                message.success(
                    t("integration.template.integration-sftp.success"),
                );
            })
            .catch(e => {
                message.error(getErrorMessage(e.response.data));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const buttons = [
        {
            title: t("integration.template.integration-sftp.check.button"),
            onClick: testConnection,
        },
    ];

    return (
        <Loading
            isLoading={loading}
            tip={t("integration.template.integration-sftp.check.tip")}
        >
            <IntegrationProperties {...props} buttons={buttons} />
        </Loading>
    );
};

IntegrationSftp.propTypes = {
    onNext: PropTypes.func.isRequired,
    step: PropTypes.object.isRequired,
};
