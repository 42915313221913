import { useDic } from "@/components/Dic/useDic.hook";
import { useSubmitPartitionMutation } from "@/components/PartitionRequest/loadables";
import React, { useEffect, useState } from "react";
import {
    CUSTOMER_ACCOUNT_TYPE,
    PARTITION_PURPOSE_POC,
    PARTITION_PURPOSE_TRAINING,
    PARTNER_ACCOUNT_TYPE,
    PROSPECT_ACCOUNT_TYPE,
    PROSPECT_PAID_POC_ACCOUNT_TYPE,
} from "../../constants/account.constants";
import { packageService } from "../../views/Package/package.service";
import { PartitionRequestForm } from "./PartitionRequestForm";

const LIMITED_ACCOUNT_TYPES = [
    PROSPECT_ACCOUNT_TYPE,
    PROSPECT_PAID_POC_ACCOUNT_TYPE,
];

export default function PartitionRequestFormContainer({
    projectId,
    partitionId,
    initialValues,
}) {
    const isEdit = !!initialValues;
    const {
        accountsService,
        locationRouterService,
        partitionRequestService,
        projectPartitionApiService,
        accountAppLocations: { partitionsLocation },
    } = useDic();

    const [account, setAccount] = useState();
    const [currencies, setCurrencies] = useState();
    const [clustersList, setClustersList] = useState([]);
    const [contactsList, setContactsList] = useState([]);
    const [partitionNamesList, setPartitionNamesList] = useState([]);
    const [purpose, setPurpose] = useState();
    const [purposeList, setPurposeList] = useState([]);

    const submitMutation = useSubmitPartitionMutation({
        isEdit,
        accountId: projectId,
        partitionId,
    });

    const onCancel = () => {
        locationRouterService.navigate(partitionsLocation);
    };

    const onPurposeChange = ({ value: purpose }) => {
        setClustersList([]);
        setPurpose(purpose);
        if (!purpose) return;
        fetchClusters(projectId, purpose);
    };

    const fetchAccount = projectId =>
        accountsService
            .fetchAccount(projectId)
            .then(res => setAccount(res.data));

    const fetchClusters = (projectId, purpose) =>
        partitionRequestService
            .fetchClusters(projectId, purpose)
            .then(res => setClustersList(res.data));

    const fetchContacts = projectId =>
        partitionRequestService
            .fetchContacts(projectId)
            .then(res => setContactsList(res.data));

    const fetchPartitionNames = projectId =>
        projectPartitionApiService
            .getPartitions(projectId)
            .then(data => data.map(partition => partition.partitionName))
            .then(partitionNamesList =>
                setPartitionNamesList(partitionNamesList),
            );

    const fetchPurposes = projectId =>
        partitionRequestService
            .fetchUsages(projectId)
            .then(res => setPurposeList(res.data));

    const isPricefxProject = account => {
        switch (account?.type) {
            case CUSTOMER_ACCOUNT_TYPE:
            case PROSPECT_ACCOUNT_TYPE:
            case PROSPECT_PAID_POC_ACCOUNT_TYPE:
            case PARTNER_ACCOUNT_TYPE:
                return false;

            default:
                return true;
        }
    };

    const isUsageEndDateLimited =
        (isPricefxProject(account) && purpose === PARTITION_PURPOSE_TRAINING) ||
        (LIMITED_ACCOUNT_TYPES.includes(account?.type) &&
            purpose === PARTITION_PURPOSE_POC);

    useEffect(() => {
        packageService
            .fetchCurrencies()
            .then(res => setCurrencies(JSON.parse(res.data.currencies)));
        fetchAccount(projectId);
        fetchPurposes(projectId);
        fetchContacts(projectId);
        fetchPartitionNames(projectId);
    }, [projectId]);

    return (
        <PartitionRequestForm
            projectId={projectId}
            clustersList={clustersList}
            contactsList={contactsList}
            isUsageEndDateLimited={isUsageEndDateLimited}
            partitionNamesList={partitionNamesList}
            purposeList={purposeList}
            onSubmit={submitMutation.mutate}
            onCancel={onCancel}
            onPurposeChange={onPurposeChange}
            currencies={currencies}
            initialValues={initialValues}
        />
    );
}
