import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { t } from "@/translations";
import { fromDuration, toDuration } from "@/utils/duration";
import PropTypes from "prop-types";
import React from "react";
import {
    getDisabledMinutes,
    getPlaceholder,
} from "../../views/Settings/PartitionUpgrades/PartitionUpgradesModal.component";
import { DEFAULT_MINUTE_STEP } from "../UpgradeScheduling/CapacityEntry";
import { TIME_HOURS_MINUTES } from "../UpgradeScheduling/date-formats";

const { useForm, Form, Fields, Field, pmValidators } = Forms;

export const EditUpgradeDurationModal = ({
    visible,
    onOk,
    onCancel,
    record: initState,
}) => {
    const { formId, handleSubmit } = useForm({
        onSubmit: ({ values }) =>
            onOk({
                ...values,
                majorUpgradeDuration:
                    toDuration(values.majorUpgradeDuration) ?? null,
                minorUpgradeDuration:
                    toDuration(values.minorUpgradeDuration) ?? null,
            }),
    });

    return (
        <Modal visible={visible} onClose={onCancel} width={456}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("clusters-list.custom-upgrade-time.edit")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Form
                        formId={formId}
                        layout="vertical"
                        onSubmit={handleSubmit}
                    >
                        <Field
                            as="div"
                            name="name"
                            initialValue={initState?.name}
                            hidden
                        />

                        <Fields.TimePicker
                            inputWidth="max"
                            label={t("clusters-list.custom-upgrade-time.major")}
                            name="majorUpgradeDuration"
                            format={TIME_HOURS_MINUTES}
                            initialValue={fromDuration(
                                initState?.majorUpgradeDuration,
                            )}
                            placeholder={getPlaceholder(
                                "partition-upgrade.duration",
                            )}
                            showNow={false}
                            minuteStep={DEFAULT_MINUTE_STEP}
                            disabledMinutes={getDisabledMinutes}
                            inputReadOnly={true}
                            validator={pmValidators.noValueOr(
                                pmValidators.durationMinimalMinutes(
                                    DEFAULT_MINUTE_STEP,
                                ),
                            )}
                        />
                        <Fields.TimePicker
                            inputWidth="max"
                            label={t("clusters-list.custom-upgrade-time.minor")}
                            name="minorUpgradeDuration"
                            format={TIME_HOURS_MINUTES}
                            initialValue={fromDuration(
                                initState?.minorUpgradeDuration,
                            )}
                            placeholder={getPlaceholder(
                                "partition-upgrade.duration",
                            )}
                            showNow={false}
                            minuteStep={DEFAULT_MINUTE_STEP}
                            disabledMinutes={getDisabledMinutes}
                            inputReadOnly={true}
                            validator={pmValidators.noValueOr(
                                pmValidators.durationMinimalMinutes(
                                    DEFAULT_MINUTE_STEP,
                                ),
                            )}
                        />
                    </Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.create"),
                                    type: "primary",
                                    formId,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onCancel,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

EditUpgradeDurationModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    record: PropTypes.object,
};
