import { getEntityLabel } from "@/components/DataUploads/DataUploadsList";
import FromNowFormatter from "@/components/DateTime/FromNowFormatter";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import {
    HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT,
    fieldTypes,
} from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    useDataDownloadsQuery,
    useDeleteDataDownloadMutation,
    useTriggerDownloadMutation,
} from "@/components/PartitionDataManagement/loadables";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { useBreadcrumbButtons } from "@/components/hooks/useBreadcrumbButtons.hook";
// import { ENTITY_TYPE_CODES_FE } from "@/constants/SupportedTableImportTypes";
import { sftpEntityType } from "@/services/dataLoadSftp.service";
import { t } from "@/translations";
import { defaultTo, map, pipe } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { getDataDownloadsListTrackName } from "../../mixpanel/buttonNames";
import { Button } from "../DesignSystem";

// const USER_ENTITY_TYPE = ENTITY_TYPE_CODES_FE["U"].value;
const preferencesType = preferencesTypes.DATA_DOWNLOADS_LIST_TABLE;

const createColumns = navigateToAvailableFiles => [
    {
        type: fieldTypes.TEXT,
        label: t("data-uploads-list.header.name"),
        name: "name",
        render: (text, record) => (
            <Button
                size="small"
                type="link"
                onClick={() => navigateToAvailableFiles(record)}
                label={text}
                track={{
                    name: getDataDownloadsListTrackName("Available Files"),
                }}
            />
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("data-uploads-list.header.entity"),
        name: "__composedEntity",
    },
    {
        type: fieldTypes.INTEGER,
        label: t("data-uploads-list.header.number-of-sftp-users"),
        name: "numberOfSftpUsers",
    },
    {
        type: fieldTypes.DATETIME,
        label: t("data-uploads-list.header.created-at"),
        name: "createdAt",
        render: text => <FromNowFormatter date={text} />,
    },
    {
        type: fieldTypes.TEXT,
        label: t("data-uploads-list.header.created-by"),
        name: "createdBy",
    },
    {
        type: fieldTypes.DATETIME,
        label: "Downloaded at",
        name: "lastRunAt",
        render: text => <FromNowFormatter date={text} />,
        // render: (text, record) => <Changed key={record.id} data={record} />,
    },
];

export const DownloadsList = ({ projectId, partitionId, visible = true }) => {
    const {
        locationRouterService,
        accountAppLocations: {
            partitionNewDownloadLocation,
            partitionEditDownloadLocation,
            partitionDataUploadSFTPManagementLocation,
            partitionAvailableFilesLocation,
        },
    } = useDic();
    const query = useDataDownloadsQuery({ projectId, partitionId });
    const uploads = useMemo(
        () =>
            pipe(
                defaultTo([]),
                map(row => ({
                    ...row,
                    __composedEntity: getEntityLabel(row),
                })),
            )(query.loadable.valueMaybe()),
        [query],
    );
    const deleteMutation = useDeleteDataDownloadMutation({
        afterSuccess: query.reload,
    });
    const triggerMutation = useTriggerDownloadMutation({
        accountId: projectId,
        partitionId,
    });

    useBreadcrumbButtons(
        () =>
            visible && [
                {
                    type: "primary",
                    label: "partition.download.list.action.create",
                    onClick: () =>
                        locationRouterService.navigate(
                            partitionNewDownloadLocation,
                        ),
                    // permissions: ["partition.data_download.edit"], // TODO
                },
            ],
        [visible],
        "DownloadsList",
    );

    const navigateToEdit = useCallback(
        record =>
            locationRouterService.navigate(partitionEditDownloadLocation, {
                downloadId: record.id,
            }),
        [locationRouterService, partitionEditDownloadLocation],
    );
    const navigateToSFTPMangement = useCallback(
        record => {
            locationRouterService.navigate(
                partitionDataUploadSFTPManagementLocation,
                {
                    uploadId: record.id,
                    SFTP_ENTITY_TYPE: sftpEntityType.DATA_DOWNLOAD,
                },
            );
        },
        [locationRouterService, partitionDataUploadSFTPManagementLocation],
    );

    const navigateToAvailableFiles = useCallback(
        record => {
            locationRouterService.navigate(partitionAvailableFilesLocation, {
                downloadId: record.id,
            });
        },
        [locationRouterService, partitionAvailableFilesLocation],
    );

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                wrapperProps={{}} // TODO: rm or fix in ActionButtonInner before merge
                items={[
                    {
                        title: t("partition.download.list.action.run"),
                        onClick: triggerMutation.mutate,
                        track: {
                            name: getDataDownloadsListTrackName("Run"),
                        },
                    },
                    {
                        title: t("partition.download.list.action.edit"),
                        onClick: navigateToEdit,
                        track: {
                            name: getDataDownloadsListTrackName("Edit"),
                        },
                        //     visible: !maybeInUseWarning,
                        //     onClick: handlePartitionUpload,
                        //     permission:
                        //         record.entityType === USER_ENTITY_TYPE
                        //             ? ["partition.data_upload.users_import"]
                        //             : [
                        //                   "partition.data_management.use",
                        //                   "partition.data_management.edit",
                        //               ],
                    },
                    {
                        title: t(
                            "partition.download.list.action.available-files",
                        ),
                        onClick: navigateToAvailableFiles,
                        track: {
                            name: getDataDownloadsListTrackName(
                                "Available Files",
                            ),
                        },
                    },
                    {
                        title: t(
                            "partition.download.list.action.sftp-management",
                        ),
                        // permission:
                        //     record.entityType === USER_ENTITY_TYPE
                        //         ? ["partition.data_upload.users_import"]
                        //         : ["partition.data_management.edit"],
                        onClick: navigateToSFTPMangement,
                        track: {
                            name: getDataDownloadsListTrackName(
                                "SFTP User Management",
                            ),
                        },
                    },
                    {
                        title: t("partition.download.list.action.delete"),
                        // permission: ["partition.data_management.edit"],
                        confirm: {
                            title: t(
                                "partition.download.list.action.modal.delete.title",
                            ),
                            message: t(
                                "partition.download.list.action.modal.delete.message",
                                {
                                    downloadName: record.name,
                                },
                            ),
                            onConfirm: deleteMutation.mutate,
                        },
                        color: "red",
                        track: {
                            name: getDataDownloadsListTrackName("Delete"),
                        },
                    },
                ]}
            />
        ),
        [
            deleteMutation.mutate,
            navigateToSFTPMangement,
            navigateToAvailableFiles,
            navigateToEdit,
            triggerMutation.mutate,
        ],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            columns={createColumns(navigateToAvailableFiles)}
            dataSource={uploads}
            fixed
            restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
            rowKey="id"
            datasetSlicing="local"
            preferencesType={preferencesType}
            defaultSort={{
                sortSpecifiers: [
                    { property: "updatedAt", direction: "descending" },
                ],
            }}
            visible={visible}
        />
    );
};

DownloadsList.propTypes = {
    projectId: PropTypes.number.isRequired,
    partitionId: PropTypes.number.isRequired,
    visible: PropTypes.bool,
};
