import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    get,
    isEmpty,
    isEqual,
    map,
    merge,
    pick,
    pipe,
    reduce,
    some,
    update,
    values,
} from "lodash/fp";
import { useCallback, useMemo, useReducer, useRef, useState } from "react";
import { createColumns } from "./createColumns";

const FF_NODE_PROPS = ["name", "description", "path", "type", "category"];

const getNodePath = path => path.replaceAll(".", ".children.");

const toTree = updater =>
    reduce((acc, item) =>
        update(getNodePath(item.path), node => updater(node, item), acc),
    );

const childrenArrayRec = pipe(
    values,
    map(item => ({
        ...item,
        ...(item.children ? { children: childrenArrayRec(item.children) } : {}),
    })),
);

const createDataSource = (updater, previousDataSource, data) =>
    pipe(map(pick(FF_NODE_PROPS)), toTree(updater)(previousDataSource))(data);

const validateAndMerge = (node, item) => {
    if (!isEqual(pick(FF_NODE_PROPS, node), item))
        console.log(
            "[ERROR] corrupted datasource, feature sets does not match",
            { previous: node, next: item },
        );
    return merge(node, item);
};

const ACTIONS = {
    FETCH_START: "FETCH_START",
    FETCH_SUCCESS: "FETCH_SUCCESS",
    FETCH_ERROR: "FETCH_ERROR",
    RELOAD_START: "RELOAD_START",
    RELOAD_SUCCESS: "RELOAD_SUCCESS",
};

export const STATUS = {
    LOADING: "LOADING",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
};

const INITIAL_STATE = {
    resources: [],
    treeDataSource: {},
};

const FETCH_TYPE = {
    FETCH: "FETCH",
    RELOAD: "RELOAD",
};

const mapResourcesWithParams = (params, mapper, resources) =>
    resources.map(r => (r.params === params ? mapper(r) : r));

const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_START: {
            const { params } = action.payload;
            const resources = action.meta.replaceResources
                ? [{ status: STATUS.LOADING, params }]
                : [...state.resources, { status: STATUS.LOADING, params }];
            return {
                ...state,
                resources,
            };
        }
        case ACTIONS.FETCH_SUCCESS: {
            const { params, data } = action.payload;

            const newTreeDataSource = isEmpty(state.treeDataSource)
                ? createDataSource(merge, {}, data)
                : createDataSource(
                      validateAndMerge,
                      state.treeDataSource,
                      data,
                  );
            const unchanged = isEqual(state.treeDataSource, newTreeDataSource);
            const treeDataSource = unchanged
                ? state.treeDataSource
                : newTreeDataSource;

            return {
                ...state,
                treeDataSource,
                resources: mapResourcesWithParams(
                    params,
                    r => ({
                        ...r,
                        status: STATUS.SUCCESS,
                        data,
                    }),
                    state.resources,
                ),
            };
        }
        case ACTIONS.RELOAD_START: {
            const { params } = action.payload;
            return {
                ...state,
                resources: state.resources.map(resource => {
                    if (resource.params.partitionId === params.partitionId) {
                        return { ...resource, status: STATUS.LOADING };
                    } else {
                        return resource;
                    }
                }),
            };
        }
        case ACTIONS.RELOAD_SUCCESS: {
            const { params, data } = action.payload;

            return {
                ...state,
                resources: state.resources.map(resource => {
                    if (resource.params.partitionId === params.partitionId) {
                        return { ...resource, status: STATUS.SUCCESS, data };
                    } else {
                        return resource;
                    }
                }),
            };
        }
        case ACTIONS.FETCH_ERROR: {
            const { params, error } = action.payload;
            return {
                ...state,
                resources: mapResourcesWithParams(
                    params,
                    r => ({
                        ...r,
                        status: STATUS.ERROR,
                        error,
                    }),
                    state.resources,
                ),
            };
        }
    }
};

export const useFeatureFlags = ({ onNewValues, fetcher, displaySingleSet }) => {
    const { axiosService } = useDic();
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    const onNewValuesCurrent = useCurrentHandler(onNewValues);
    const resourcesRef = useRef(state.resources);
    resourcesRef.current = state.resources;

    const fetch = useCallback(
        (type = FETCH_TYPE.FETCH, params) => {
            dispatch({
                type:
                    type === FETCH_TYPE.FETCH
                        ? ACTIONS.FETCH_START
                        : ACTIONS.RELOAD_START,
                payload: { type, params },
                meta: { replaceResources: displaySingleSet },
            });

            fetcher(axiosService, params)
                .then(data => {
                    dispatch({
                        type:
                            type === FETCH_TYPE.FETCH
                                ? ACTIONS.FETCH_SUCCESS
                                : ACTIONS.RELOAD_SUCCESS,
                        payload: { params, data },
                    });
                    onNewValuesCurrent({ params, data });
                })
                .catch(error => {
                    console.log("[useFeatureFlags]", "error", error);
                    dispatch({
                        type: ACTIONS.FETCH_ERROR,
                        payload: { params, error },
                    });
                });
        },
        [axiosService, onNewValuesCurrent, fetcher, displaySingleSet],
    );

    const handleAdd = useCallback(
        params => {
            if (
                some(pipe(get("params"), isEqual(params)), resourcesRef.current)
            )
                return;

            fetch(FETCH_TYPE.FETCH, params);
        },
        [fetch],
    );

    const reloadResource = useCallback(
        resource => {
            fetch(FETCH_TYPE.RELOAD, resource.params);
        },
        [fetch],
    );

    const reload = useCallback(() => {
        state.resources.map(resource => reloadResource(resource));
    }, [state.resources, reloadResource]);

    const loading = state.resources.some(
        ({ status }) => status === STATUS.LOADING,
    );

    const [selectedFeatureFlag, setSelectedFeatureFlag] = useState(null);

    const columns = useMemo(() => {
        const paramsList = state.resources
            .filter(({ status }) =>
                [STATUS.LOADING, STATUS.SUCCESS].includes(status),
            )
            .map(({ params }) => params);

        return !paramsList.length
            ? []
            : createColumns({
                  sets: paramsList,
                  onShowPartitionUsingThisFlag: record => {
                      setSelectedFeatureFlag(record);
                  },
                  isActionMenuHidden: displaySingleSet,
              });
    }, [state.resources, displaySingleSet]);

    const dataSource = useMemo(
        () => childrenArrayRec(state.treeDataSource),
        [state.treeDataSource],
    );

    return {
        columns,
        dataSource,
        handleAdd,
        loading,
        resources: state.resources,
        selectedFeatureFlag,
        setSelectedFeatureFlag,
        reloadResource,
        reload,
    };
};
