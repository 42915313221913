import FormattedDate from "@/components/DateTime/FormattedDate";
import FormattedDuration from "@/components/DateTime/FormattedDuration";
import FormattedTime from "@/components/DateTime/FormattedTime";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import moment from "moment";
import React from "react";
import SlotStatus from "./components/SlotStatus.component";
import { DATETIME_WITHOUT_OFFSET } from "./date-formats";

export const upgradeSlotsOverviewColumns = ({ onDetail }) => [
    {
        type: fieldTypes.TEXT,
        label: t("cluster.upgrade.slots.overview.column.name"),
        name: "username",
        render: (text, record) => (
            <>
                {text}
                <ActionButton
                    record={record}
                    items={[
                        {
                            title: t("general.show-detail"),
                            onClick: onDetail,
                        },
                    ]}
                />
            </>
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("cluster.upgrade.slots.overview.column.date"),
        name: "timeFrom",
        canSort: true,
        canFilter: false,
        render: date => <FormattedDate>{date}</FormattedDate>,
    },
    {
        type: fieldTypes.TEXT,
        label: t("cluster.upgrade.slots.overview.column.time"),
        name: "timeFrom",
        canSort: false,
        canFilter: false,
        render: date => <FormattedTime>{date}</FormattedTime>,
    },
    {
        type: fieldTypes.TEXT,
        label: t("cluster.upgrade.slots.overview.column.duration"),
        name: "duration",
        canSort: false,
        canFilter: false,
        exportHidden: true,
        render: (_, record) => (
            <FormattedDuration>
                {moment.duration(
                    moment(record.timeTo, DATETIME_WITHOUT_OFFSET).diff(
                        moment(record.timeFrom, DATETIME_WITHOUT_OFFSET),
                    ),
                )}
            </FormattedDuration>
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("cluster.upgrade.slots.overview.column.timezone"),
        name: "timezone",
    },
    {
        type: fieldTypes.BOOLEAN,
        label: t("cluster.upgrade.slots.overview.column.recurrent"),
        name: "recurrent",
    },
    {
        type: fieldTypes.OPTION,
        label: t("cluster.upgrade.slots.overview.column.status"),
        name: "status",
        options: ["Not Used", "Planned Upgrade"],
        render: status => <SlotStatus small>{status}</SlotStatus>,
    },
];
