import PropTypes from "prop-types";
import React from "react";

import styles from "./FullWidthWrapper.styles.less";

export const FullWidthWrapper = ({ children, md }) => (
    <div className={md ? styles.negativeMarginMd : styles.negativeMarginSm}>
        {children}
    </div>
);

FullWidthWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};
