import cloneDeep from "lodash/cloneDeep";
import kebabCase from "lodash/kebabCase";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import merge from "lodash/merge";
import snakeCase from "lodash/snakeCase";
import sortBy from "lodash/sortBy";
import union from "lodash/union";
import values from "lodash/values";
import { InputType, ParsedType } from "./constants";

const MATRIX = "MATRIX";
const ATTRIBUTE_PREFIX = "attribute";
const EN_DECIMAL_SEPARATOR = ".";

const isEnNumberFormat = decimalSeparator =>
    EN_DECIMAL_SEPARATOR === decimalSeparator || !decimalSeparator;

export const prepareValues = (keys, attributes) => {
    const keysCopy = cloneDeep(keys);

    if (
        values(keysCopy).filter(f => !f.name.startsWith(ATTRIBUTE_PREFIX))
            .length === 1
    ) {
        keysCopy["key1"].name = "name";
    }

    const selectedAttributes = keyBy(
        values(attributes).filter(a => !a.skip),
        "name",
    );

    return values(merge(keysCopy, selectedAttributes));
};

export const getValueType = keys => {
    const keyValues = values(keys).filter(
        f => !f.name.startsWith(ATTRIBUTE_PREFIX),
    );
    if (keyValues.length === 1) {
        return MATRIX;
    }

    return `MATRIX${keyValues.length}`;
};

export const calculateCustomFieldNames = (optionalFieldsArr, step) => {
    switch (step.entityType) {
        case "DMDS":
            return map(optionalFieldsArr, f => ({
                label: f.label,
                type: getFieldType(f),
                fieldName: kebabCase(f.name),
                originalFieldName: f.originalFieldName,
            }));
        default: {
            return map(optionalFieldsArr, f => ({
                label: snakeCase(f.label),
                type: getFieldType(f),
                fieldName: f.name,
                labelTranslations: JSON.stringify({ "": f.label }),
                originalFieldName: f.originalFieldName,
            }));
        }
    }
};
export const calculateSignificantFields = significantFields => {
    if (!significantFields) {
        return null;
    }
    return Object.values(significantFields)
        .filter(f => !f.skip)
        .map(f => ({
            fieldName: f.name,
            labelTranslations: f.label ? JSON.stringify({ "": f.label }) : null,
        }));
};

export const recalculateAvailableFields = fileInfo => {
    const columns = fileInfo?.columns ?? [];

    let fields = map(columns, ({ name, originalValues, type }) => ({
        name,
        type,
        examples: originalValues,
    }));

    return sortBy(fields, ["name"]);
};

export const createMapper = (extensionFields, parserConfig) => {
    let currentId = 0;

    return values(extensionFields)
        .filter(field => !field.skip)
        .map(field =>
            buildMapperField(
                {
                    id: currentId++, // TODO: check that fields have id generated upfront
                    ...field,
                },
                parserConfig,
            ),
        );
};

// Problem: we need to show warning, when previously autogenerated converter does not match new parser config
// e.g. 1/ New data upload with us number format. We generate converter expression stringToDecimal(us)
//      2/ Edit data upload - new file uses de number format, converter stays stringToDecimal(us)
// There is no way to differentiate between autogenerated and user-defined converter
// hence each autogenerated converter needs to have test method to match it :-/
const autoGeneratedConverters = {
    number: {
        create: arg => {
            if (!["us", "de"].includes(arg))
                throw new Error(
                    "Not supported number converter, add it to test",
                );
            return `stringToDecimal(${arg})`;
        },
        test: converter => /^stringToDecimal\((us|de)\)$/.test(converter ?? ""),
    },
    integer: {
        create: arg => {
            if (!["us", "de"].includes(arg))
                throw new Error(
                    "Not supported integer converter, add it to test",
                );
            return `stringToInteger(${arg})`;
        },
        test: converter => /^stringToInteger\((us|de)\)$/.test(converter ?? ""),
    },
    date: {
        create: (arg = "") => {
            return `stringToDate(${arg})`;
        },
        test: converter => /^stringToDate\(.*\)$/.test(converter ?? ""),
    },
    datetime: {
        create: (arg = "") => {
            return `stringToDateTime(${arg})`;
        },
        test: converter => /^stringToDateTime\(.*\)$/.test(converter ?? ""),
    },
};

export const converterExpressions = {
    create: {
        number: parserConfig => {
            if (isEnNumberFormat(parserConfig.decimalSeparator)) {
                return autoGeneratedConverters.number.create("us");
            } else if (parserConfig.decimalSeparator === ",") {
                return autoGeneratedConverters.number.create("de");
            } else {
                throw new Error(
                    "Unknown decimal separator to generate converter expression: " +
                        parserConfig.decimalSeparator,
                );
            }
        },
        integer: parserConfig => {
            if (isEnNumberFormat(parserConfig.decimalSeparator)) {
                return autoGeneratedConverters.integer.create("us");
            } else if (parserConfig.decimalSeparator === ",") {
                return autoGeneratedConverters.integer.create("de");
            } else {
                throw new Error(
                    "Unknown decimal separator to generate converter expression: " +
                        parserConfig.decimalSeparator,
                );
            }
        },
        date: parserConfig =>
            autoGeneratedConverters.date.create(parserConfig?.dateFormat),
        datetime: parserConfig =>
            autoGeneratedConverters.datetime.create(parserConfig?.dateFormat),
    },
    validAutogenerated: (type, converterExpression, parserConfig) => {
        if (!type)
            throw new Error(
                "Cannot validate converter, no type specified, type=" + type,
            );
        if (!converterExpressions.create[type])
            throw new Error(
                "Cannot validate autogenerated converter, unknown type:" + type,
            );
        return (
            converterExpressions.create[type](parserConfig) ===
            converterExpression
        );
    },
    match: {
        autoGeneratedType: converterExpression => {
            const maybeAutoGeneratedType = Object.entries(
                autoGeneratedConverters,
            ).find(([, { test }]) => test(converterExpression))?.[0];
            return maybeAutoGeneratedType;
        },
    },
};

const buildMapperField = (field, parserConfig) => {
    const outputType = getFieldType(field);

    // No need to add converter:
    if (field.inputType === InputType.FORMULA) {
        {
            if (!field.formulaMapping) {
                throw new Error("Missing formula mapping");
            }
            return {
                id: field.id,
                input: field.value,
                inputType: InputType.FORMULA,
                formulaMapping: field.formulaMapping,
                output: field.name,
            };
        }
    }
    if (
        field.inputType === InputType.COMPOSED ||
        outputType === ParsedType.COMBINED
    )
        return {
            id: field.id,
            input: [].concat(field.value),
            inputType: InputType.COMPOSED,
            output: field.name,
        };
    if (field.inputType === InputType.GROOVY)
        return {
            id: field.id,
            input: field.value,
            inputType: InputType.GROOVY,
            output: field.name,
        };

    // Maybe needs to add converter
    switch (outputType) {
        case ParsedType.DATE:
        case ParsedType.DATE_TIME:
            return {
                ...getMapperField(field),
                converterExpression:
                    converterExpressions.create.date(parserConfig),
            };
        case ParsedType.NUMBER:
            return {
                ...getMapperField(field),
                converterExpression:
                    converterExpressions.create.number(parserConfig),
            };
        case ParsedType.INTEGER:
            return {
                ...getMapperField(field),
                converterExpression:
                    converterExpressions.create.integer(parserConfig),
            };
        default:
            return getMapperField(field);
    }
};
const getMapperField = field => {
    return {
        id: field.id,
        inputType: InputType.BODY,
        input: field.value || field.label,
        output: field.name,
    };
};

export const createAttributes = extensionFields =>
    extensionFields
        .filter(field => !field.skip)
        .map(field => {
            return {
                label: field.label,
                fieldName: field.name,
                type: getFieldType(field),
            };
        });

export const createMatrixAttributes = fields =>
    fields.map(field => {
        return {
            label: field.value,
            fieldName: field.name,
            type: getFieldType(field),
        };
    });

export const createBusinessKeys = extensionFields =>
    extensionFields.filter(field => field.businessKey).map(field => field.name);

export const getCustomKeys = (map1, array2) =>
    union(values(cloneDeep(map1)), array2)
        .filter(f => f.skip !== true)
        .filter(f => f.name)
        .filter(f => f.isUserDefinedCustomField !== true)
        .map(f => f.name);

const getFieldType = field => field.resolvedType || field.type;
