import PropTypes from "prop-types";
import React from "react";

import { LocationLink } from "@/modules/router";
import { T } from "@/translations";

export const IntegrationLink = ({
    accountId,
    instanceId,
    getDefaultLocation,
    children,
}) => {
    if (!getDefaultLocation || !accountId || !instanceId) return null;

    const { location, translationKey } = getDefaultLocation(instanceId);
    const text = children || <T id={translationKey} />;

    return (
        <LocationLink
            key={instanceId}
            $location={location}
            $params={{
                accountId: accountId,
                instanceId: instanceId,
            }}
        >
            {text}
        </LocationLink>
    );
};

IntegrationLink.propTypes = {
    accountId: PropTypes.number,
    instanceId: PropTypes.number,
    getDefaultLocation: PropTypes.func,
    children: PropTypes.node,
};
