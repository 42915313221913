import { CustomerPartitionCredentialsForm } from "@/components/CustomerPartitionCredentials/CustomerPartitionCredentialsForm";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React, { useCallback } from "react";

export const PartitionCredentialsPage = () => {
    const { accountId, partitionId } = useAccountAppParams();
    const {
        locationRouterService,
        accountAppLocations: { partitionsLocation },
    } = useDic();

    const goToList = useCallback(
        () =>
            locationRouterService.navigate(partitionsLocation, {
                accountId,
            }),
        [accountId, locationRouterService, partitionsLocation],
    );
    useSetBackButtonAction(goToList);

    return (
        <CustomerPartitionCredentialsForm
            accountId={accountId}
            partitionId={partitionId}
            onCancel={goToList}
            afterSaveCredentials={goToList}
        />
    );
};
