import { useContext, useEffect } from "react";
import { PageLayoutContext } from "./PageLayoutContext";

export const useRefreshAction = fn => {
    const { setRefreshAction } = useContext(PageLayoutContext);

    useEffect(() => {
        setRefreshAction(() => fn);
        return () => {
            setRefreshAction(null);
        };
    }, [setRefreshAction, fn]);
};
