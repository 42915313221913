import { Gap } from "@/components/DesignSystem";
import ConditionalErrorAlert from "@/components/Error/ConditionalErrorAlert";
import { logFileService } from "@/components/Integrations/Dashboard/Tabs/LogFileTab/logfile.service";
import { usePoll } from "@/components/hooks/usePoll.hook";
import { getErrorMessage } from "@/utils/state/error.utils";
import PropTypes from "prop-types";
import React, { useCallback, useRef, useState } from "react";
import LogFileHeader from "./LogFileHeader";
import styles from "./LogFilePanel.less";

const POLLS = {
    SUCCESS: { interval: 5000, runImmediately: true },
    ERROR: { interval: 60000, runImmediately: false },
    CONFLICT: { interval: 30000, runImmediately: false },
};
const initialSize = 50 * 1024;
const range = `bytes=-${initialSize}`;

const LogFilePanel = ({ instanceId, instanceVersion, isRequested = false }) => {
    const textLog = useRef(null);
    const [pollStatus, setPollStatus] = useState("SUCCESS");
    const [logFileData, setLogFileData] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const fetchLogs = useCallback(
        () => logFileService.download(instanceId, range),
        [instanceId],
    );
    const onSuccess = fileResponse => {
        const isInitialLoad = !logFileData;
        setLogFileData(fileResponse.data);
        setErrorMessage(null);
        scrollToBottom(isInitialLoad);
        if (pollStatus !== "SUCCESS") {
            setPollStatus("SUCCESS");
        }
    };
    const onError = error => {
        setErrorMessage(getErrorMessage(error?.response?.data));
        setPollStatus("ERROR");
    };
    const onConflict = () => {
        setLogFileData("No logfile available yet...");
        setErrorMessage(null);
        if (pollStatus !== "CONFLICT") setPollStatus("CONFLICT");
    };

    usePoll({
        asyncFn: fetchLogs,
        onSuccess,
        onError,
        onConflict,
        interval: POLLS[pollStatus].interval,
        runImmediately: POLLS[pollStatus].runImmediately,
    });

    function scrollToBottom(scrollDownRequired) {
        if (textLog) {
            const textLogElement = textLog.current;

            var actualScrollbarPosition =
                textLogElement.scrollHeight - textLogElement.clientHeight;

            if (scrollDownRequired) {
                textLogElement.scrollTop = textLogElement.scrollHeight;
            } else if (textLogElement.scrollTop > actualScrollbarPosition) {
                textLogElement.scrollTop = textLogElement.scrollHeight;
            }
        }
    }

    return (
        <>
            {errorMessage ? (
                <ConditionalErrorAlert error={errorMessage} />
            ) : (
                <>
                    {!isRequested && (
                        <>
                            <LogFileHeader
                                instanceId={instanceId}
                                instanceVersion={instanceVersion}
                            />
                            <Gap size="small" />
                        </>
                    )}
                    <div className={styles.logFileParent}>
                        <pre ref={textLog} className={styles.logFile}>
                            {logFileData}
                        </pre>
                    </div>
                </>
            )}
        </>
    );
};

LogFilePanel.propTypes = {
    instanceId: PropTypes.number.isRequired,
    instanceVersion: PropTypes.string,
    isRequested: PropTypes.bool,
};

export default LogFilePanel;
