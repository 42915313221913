import {
    ButtonMenu,
    Forms,
    H5,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { mapValuesToConnection } from "@/components/ISVConnections/ISVConnection.page";
import { useEditConnectionMutation } from "@/components/ISVConnections/loadables";
import { mapVendorToVendorName } from "@/components/ISVMapping/helpers";
import { useRouteISVConnection } from "@/mixpanel/hooks/useRouteISVConnection.hook";
import { t } from "@/translations";
import React from "react";

const { Form, Fields, pmValidators } = Forms;

const VendorStepFields = ({ vendorName }) => {
    return (
        <>
            <H5>{t("isv-connections.steps.vendor.title.channel")}</H5>
            <Fields.Input
                required
                name="channelId"
                label={t("isv-connections.steps.vendor.id")}
                validator={pmValidators.isRequired}
            />
            <Fields.Input
                required
                name="baseUrl"
                label={t("isv-connections.steps.vendor.baseUrl")}
                validator={pmValidators.isRequired}
            />
            <H5>{t("isv-connections.steps.vendor.title.sftp")}</H5>
            <Text size="medium">
                {t("isv-connections.steps.vendor.perex.sftp", {
                    vendorName,
                })}
            </Text>
            <Fields.Input
                required
                name="sftpUrl"
                label={t("isv-connections.steps.vendor.sftpUrl")}
                validator={pmValidators.isRequired}
            />
            <Fields.Input
                required
                name="sftpPort"
                label={t("isv-connections.steps.vendor.sftpPort")}
                validator={Forms.validators.failOnFirst([
                    pmValidators.isRequired,
                    pmValidators.isInteger,
                ])}
            />
            <Fields.Input
                required
                name="sftpUsername"
                label={t("isv-connections.steps.vendor.sftpUsername")}
                validator={pmValidators.isRequired}
            />
            <Fields.InputPassword
                required
                name="sftpPassword"
                label={t("isv-connections.steps.vendor.sftpPassword")}
                validator={pmValidators.isRequired}
            />
            {/* <>
                <ConnectionTest />
                <Gap />
            </> */}
        </>
    );
};

export const VendorStepEditForm = ({
    accountId,
    initialValues,
    onSuccess,
    onCancel,
}) => {
    const { isvConnectionLoadable } = useRouteISVConnection();

    const editConnectionMutation = useEditConnectionMutation({
        connectionId: initialValues.id,
        afterSuccess: onSuccess,
    });
    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit: ({ values }) =>
            editConnectionMutation.mutate(
                mapValuesToConnection({
                    projectId: accountId,
                    ...initialValues,
                    ...values,
                }),
            ),
    });

    useSetValidatedInitialValues({ initialValues, setValues, setTouched }, [
        initialValues,
    ]);

    return (
        <UnityLayout>
            <UnityLayout.Content padding={[true, true, false, true]}>
                <Form formId={formId} onSubmit={handleSubmit}>
                    <VendorStepFields
                        vendorName={mapVendorToVendorName(
                            isvConnectionLoadable.valueMaybe()?.vendor,
                        )}
                    />
                </Form>
            </UnityLayout.Content>
            <UnityLayout.Footer
                actionButtonsLeft={
                    <ButtonMenu
                        buttons={[
                            {
                                formId,
                                label: t("general.save"),
                                type: "primary",
                            },
                            {
                                label: t("general.cancel"),
                                onClick: onCancel,
                                type: "text",
                            },
                        ]}
                    />
                }
            />
        </UnityLayout>
    );
};

export default VendorStepFields;
