import { HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT } from "@/components/DesignSystem/Table/constants";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { isLoading, useQueryLoadable } from "@/modules/loadable";
import { instancesServices } from "@/services/instance.service";
import PropTypes from "prop-types";
import React from "react";
import { createColumns } from "./MergeRequestsList.columns";

export const MergeRequestsList = ({ instanceId }) => {
    const mergeRequestsResource = useQueryLoadable(
        async () => instancesServices.fetchMergeRequests(instanceId),
        [instanceId],
    );

    const preferencesType = preferencesTypes.INSTANCE_MERGE_REQUESTS_TABLE;

    return (
        <>
            <TableWithPreferencesManagement
                isLoading={isLoading(mergeRequestsResource)}
                dataSource={mergeRequestsResource.loadable?.valueMaybe() || []}
                columns={createColumns()}
                rowKey="id"
                datasetSlicing="local"
                preferencesType={preferencesType}
                exportUrl={`/api/instances/${instanceId}/merge-requests/export`}
                fixed
                restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
            />
        </>
    );
};

MergeRequestsList.propTypes = {
    instanceId: PropTypes.string.isRequired,
};
