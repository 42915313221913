import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useApplyPartitionFeatureFlagsMutation } from "@/components/FeatureFlags/loadables";
import { useIncludesRightsForPartition } from "@/components/hooks/useIncludesRightsForPartition.hook";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { hasValue, isLoading } from "@/modules/loadable";
import { useConfirmModal } from "@/modules/modal";
import { ACCOUNT_FEATURE_FLAGS_EDIT } from "@/security/permission.utils";
import { t } from "@/translations";
import { useAccountOptionsResource } from "@/utils/loadables/accounts.loadables";
import { usePartitionOptionsResource } from "@/utils/loadables/partitions.loadables";
import { get } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo } from "react";
import { idleLoadableProps } from "@/components/DataUploads/Wizard/loadables";

export const CopyModal = ({ modalProviderProps, data, copySelected }) => {
    const { axiosService } = useDic();
    const applyPartitionFeatureFlagsMutation =
        useApplyPartitionFeatureFlagsMutation({
            onSuccess: () => {
                modalProviderProps.onClose();
            },
        });

    const accountOptionsQuery = useAccountOptionsResource();

    const singleProjectIdMemo = useMemo(() => {
        const maybeValue = accountOptionsQuery.loadable.valueMaybe();
        return maybeValue?.length === 1 ? maybeValue[0].value : undefined;
    }, [accountOptionsQuery]);

    const confirmModal = useConfirmModal();

    const showBeforeCopyConfirm = useCallback(
        ({ onConfirm, setName }) =>
            confirmModal.confirm({
                title: t("feature-flags.copy-modal.confirm.title"),
                message: t("feature-flags.copy-modal.confirm.message", {
                    setName,
                }),
                width: 588,
                okText: t("feature-flags.copy-modal.confirm.confirm-button"),
                cancelText: t("general.cancel"),
                onConfirm: () => {
                    onConfirm();
                },
            }),
        [confirmModal],
    );

    const isSetAlreadyExists = useCallback(
        async ({ projectId, partitionId, configurationName }) =>
            await axiosService
                .get(
                    `/api/projects/${projectId}/partition-assets/${partitionId}/feature-flags/${configurationName}/exists`,
                )
                .then(get("data")),
        [axiosService],
    );

    const onSubmit = useCallback(
        async ({
            values: {
                account: projectId,
                partition: partitionId,
                set: configurationName,
            },
        }) => {
            const paramsAndFeatureSets = [
                {
                    params: {
                        projectId: projectId || singleProjectIdMemo,
                        partitionId,
                        configurationName,
                    },
                    data,
                },
            ];
            const copyFeatureFlagsSubmit = () =>
                applyPartitionFeatureFlagsMutation.mutate(paramsAndFeatureSets);

            if (
                !(await isSetAlreadyExists({
                    projectId,
                    partitionId,
                    configurationName,
                }))
            ) {
                return showBeforeCopyConfirm({
                    onConfirm: copyFeatureFlagsSubmit,
                    setName: configurationName,
                });
            } else {
                return copyFeatureFlagsSubmit();
            }
        },
        [
            applyPartitionFeatureFlagsMutation,
            data,
            isSetAlreadyExists,
            showBeforeCopyConfirm,
            singleProjectIdMemo,
        ],
    );

    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit,
    });

    const projectId =
        Forms.useFieldValue({ formId, name: "account" }) || singleProjectIdMemo;
    const partitionOptionsQuery = usePartitionOptionsResource({
        projectId,
        canFetch: !!projectId,
    });

    const isSubmitting = isLoading(applyPartitionFeatureFlagsMutation);

    useEffect(() => {
        setValues({ partition: undefined });
        setTouched({ partition: false });
    }, [projectId, setValues, setTouched]);

    const submitDisabled =
        usePartitionOptionsResource.isIdle(partitionOptionsQuery) ||
        !hasValue(partitionOptionsQuery) ||
        !hasValue(accountOptionsQuery) ||
        isSubmitting;

    const hasFlagsEditRights = useIncludesRightsForPartition([
        ACCOUNT_FEATURE_FLAGS_EDIT,
    ]);

    const partitionOptionProps = useMemo(() => {
        const props = {
            ...getLoadableSelectProps(partitionOptionsQuery.loadable),
            ...idleLoadableProps(
                partitionOptionsQuery.loadable,
                "Please, select account",
            ),
        };

        return {
            ...props,
            options: props.options.map(o => {
                const disabled = !hasFlagsEditRights(projectId, o.value);
                return {
                    ...o,
                    disabled,
                    title: disabled
                        ? t(
                              "feature-flags.copy-modal.partition-select.disabled-option-tooltip",
                          )
                        : undefined,
                };
            }),
        };
    }, [partitionOptionsQuery, projectId, hasFlagsEditRights]);

    return (
        <Modal {...modalProviderProps}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={
                        copySelected
                            ? t("feature-flags.copy-modal.title.copy-selected")
                            : t("feature-flags.copy-modal.title.copy-all")
                    }
                />
                <UnityLayout.Content padding={[true, true]}>
                    <Forms.Form formId={formId} onSubmit={handleSubmit}>
                        <Forms.FieldGroup width="max" inputWidth="max">
                            {hasValue(accountOptionsQuery) &&
                                !singleProjectIdMemo && (
                                    <Forms.Fields.Select
                                        name="account"
                                        label={t(
                                            "feature-flags.copy-modal.form.account.label",
                                        )}
                                        placeholder={t(
                                            "feature-flags.copy-modal.form.account.placeholder",
                                        )}
                                        required
                                        validator={
                                            Forms.pmValidators.isRequired
                                        }
                                        {...getLoadableSelectProps(
                                            accountOptionsQuery.loadable,
                                        )}
                                    />
                                )}
                            <Forms.Fields.Select
                                name="partition"
                                label={t(
                                    "feature-flags.copy-modal.form.partition.label",
                                )}
                                placeholder={t(
                                    "feature-flags.copy-modal.form.partition.placeholder",
                                )}
                                required
                                validator={Forms.pmValidators.isRequired}
                                {...(projectId
                                    ? partitionOptionProps
                                    : { options: [], disabled: true })}
                            />
                            <Forms.Fields.Input
                                name="set"
                                label={t(
                                    "feature-flags.copy-modal.form.set.label",
                                )}
                                placeholder={t(
                                    "feature-flags.copy-modal.form.set.placeholder",
                                )}
                                required
                                validator={Forms.pmValidators.isRequired}
                            />
                        </Forms.FieldGroup>
                    </Forms.Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.copy"),
                                    formId,
                                    type: "primary",
                                    disabled: submitDisabled,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: modalProviderProps.onClose,
                                    type: "text",
                                    disabled: isSubmitting,
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
CopyModal.propTypes = {
    data: PropTypes.array,
    modalProviderProps: PropTypes.object,
    copySelected: PropTypes.bool,
};
