import { Checkbox, Gap, H3, Text, Tooltip } from "@/components/DesignSystem";
import { T } from "@/translations";
import cx from "classnames";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import values from "lodash/values";
import PropTypes from "prop-types";
import React from "react";
import styles from "../styles.less";

const WIDTH = "6%";

export const resolveSkipAll = (checked, fields) => {
    const clonedFields = cloneDeep(fields);
    values(clonedFields).forEach(f => (f.skip = checked));
    return clonedFields;
};

export const resolveSkip = (record, value, fields) => {
    const clonedFields = cloneDeep(fields);
    values(clonedFields).find(f => f.name === record.name).skip = value;
    return clonedFields;
};

export const isDisabledAll = (fields, prop) => {
    return (
        !isEmpty(fields) &&
        values(fields).filter(field => !field[prop]).length === 0
    );
};

export const SkipAll = (
    onSkipAll,
    fields,
    className,
    disabled = false,
    testId,
) => {
    return (
        <label className={cx(className, styles.skipAll)} data-test={testId}>
            <Checkbox
                className={styles.skipTitle}
                onClick={e => e.stopPropagation()}
                onChange={e => onSkipAll(e.target.checked, fields)}
                checked={isDisabledAll(fields, "skip")}
                disabled={disabled}
            />
            <T id="package-data-upload.label.skip" />
        </label>
    );
};

export const checkRecord = (onSkip, record, prop, disabled = false) => {
    return (
        <Checkbox
            onChange={e => onSkip(record, e.target.checked)}
            checked={record[prop]}
            disabled={disabled}
        />
    );
};

// TODO: move to somewhere else
export const checkboxColumn =
    (isCheckedFn, onChecked, isIndeterminate = () => false, readOnly = false) =>
    (text, record) => {
        return (
            <Tooltip title={record.tooltipText}>
                <Checkbox
                    onChange={e => onChecked(record, e.target.checked)}
                    checked={isCheckedFn(record)}
                    indeterminate={isIndeterminate(record)}
                    disabled={readOnly}
                />
            </Tooltip>
        );
    };
export const Skip = (onSkipAll, onSkip, fields, disabled = false) => ({
    key: "skip",
    title: () => SkipAll(onSkipAll, fields, null, disabled),
    width: WIDTH,
    render: (text, record) => checkRecord(onSkip, record, "skip", disabled),
});

export const SimpleSkip = (onSkip, disable = false) => ({
    key: "skip",
    width: WIDTH,
    render: (text, record) => checkRecord(onSkip, record, "skip", disable),
});

export const checkAll = (onCheckAll, onSkip, fields) => ({
    key: "checked",
    title: () => CheckAll(onCheckAll, fields, null, true),
    width: WIDTH,
    render: (text, record) => checkRecord(onSkip, record, "checked"),
});

export const CheckAll = (onCheckAll, fields) => {
    return (
        <label className={cx(styles.skipAll)}>
            <Checkbox
                className={styles.skipTitle}
                onChange={e => onCheckAll(e.target.checked, fields)}
                checked={isDisabledAll(fields, "checked")}
            />
        </label>
    );
};

export const StepUiHeaders = ({ ui, rich = true }) => {
    return ui ? (
        <>
            {ui.title && <H3>{ui.title}</H3>}
            {ui.description &&
                (rich ? (
                    <>
                        <Text size="large">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: ui.description,
                                }}
                            />
                        </Text>
                        <Gap size="large" />
                    </>
                ) : (
                    <>
                        <Text size="large">{ui.description}</Text>
                        <Gap size="large" />
                    </>
                ))}
        </>
    ) : (
        <div />
    );
};
StepUiHeaders.propTypes = {
    description: PropTypes.string,
};
