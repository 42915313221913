import React from "react";
import { FormattedMessage } from "react-intl";

const showTotalFunction = totalRows => (
    <FormattedMessage id="table-total-rows" values={{ totalRows }} />
);

const featurePagination = ({
    pageSize = 10,
    totalRows = 0,
    onPageSizeChange,
    ...customOptions
}) => ({
    pagination: {
        onShowSizeChange: onPageSizeChange,
        pageSize,
        pageSizeOptions: ["10", "50", "100", "150", "200", "300"],
        showSizeChanger: true,
        showQuickJumper: true,
        hideOnSinglePage: true,
        showTotal: showTotalFunction,
        total: totalRows,
        ...customOptions,
    },
});
export default featurePagination;
