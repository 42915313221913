import { Col, Row, Spin } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import React from "react";

export const Loading = ({ isLoading, children, tip }) =>
    isLoading ? (
        <Row type="flex" justify="center" align="middle">
            <Col>
                <div>
                    <Spin tip={tip} />
                </div>
            </Col>
        </Row>
    ) : (
        children
    );

Loading.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    tip: PropTypes.string,
};
