import { DataObject } from "@/utils/dataObjects/DataObject.class";

export class TemplateEntity extends DataObject {
    /**
     *
     * @type {string}
     */
    id = null;
    /**
     *
     * @type {string}
     */
    uniqueName = null;
    /**
     *
     * @type {string}
     */
    label = null;
    /**
     *
     * @type {string}
     */
    description = null;
    /**
     *
     * @type {string[]}
     */
    components = null;
    /**
     *
     * @type {string}
     */
    type = null;
}
