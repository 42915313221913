import { Forms, P } from "@/components/DesignSystem";
import { MAX_INT_INSTANCES } from "@/components/ImInstancesLimit/AccountInstancesLimitModal";
import { IntegrationArchitectureFields } from "@/components/Integrations/components/IntegrationArchitecture/IntegrationArchitectureFields";
import { useIntegrationArchitectureInfoVisibility } from "@/components/Integrations/components/IntegrationArchitecture/useIntegrationArchitectureInfoVisibility.hook";
import { ProvisionedInstanceEnvironments } from "@/components/Integrations/NewProvisionedInstanceForm/ProvisionedInstanceEnvironments";
import { useInstanceLimitQuery } from "@/components/Integrations/NewProvisionedInstanceForm/useInstanceLimit.hook";
import { LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

const { Fields } = Forms;

export const ProvisionedInstanceEditFormComponent = ({
    instanceGroup,
    formId,
    disableEnvsEdit,
    instancesRestartMutation,
    instancesDeleteMutation,
}) => {
    const architectureVisibility = useIntegrationArchitectureInfoVisibility();
    const existingEnvironments = useMemo(
        () =>
            instanceGroup.environments.map(env => ({
                isExisting: true,
                ...env,
            })),
        [instanceGroup.environments],
    );

    const imInstancesLimitQuery = useInstanceLimitQuery();
    const version = Forms.useFieldValue({ formId, name: "version" });

    return (
        <LoadableRenderer
            loadable={imInstancesLimitQuery.loadable}
            hasValue={({ maxIntegrationInstances, existingInstances }) => (
                <>
                    <Fields.Input
                        label={t("instance-form.label.integration-name")}
                        tooltip={t("instance-form.tooltip.integration-name")}
                        addonBefore="pim-"
                        value={instanceGroup.instanceName.replace(/^pim-/, "")}
                        disabled
                    />
                    <Fields.Select
                        hidden
                        label={t("instance-form.label.cloud-provider")}
                        value={instanceGroup.cloudProvider}
                        disabled
                    />
                    <Fields.Select
                        label={t("instance-form.label.region")}
                        value={instanceGroup.region}
                        disabled
                    />
                    <ProvisionedInstanceEnvironments
                        name="environments"
                        initialValue={existingEnvironments}
                        existingInstances={existingInstances}
                        maxIntegrationInstances={
                            maxIntegrationInstances ?? MAX_INT_INSTANCES
                        }
                        newEnvVersion={version}
                        disableEnvsEdit={disableEnvsEdit}
                        instanceGroupName={instanceGroup.instanceName}
                        instancesRestartMutation={instancesRestartMutation}
                        instancesDeleteMutation={instancesDeleteMutation}
                    />
                    {architectureVisibility.isDisplayed && (
                        <>
                            <P>
                                {t(
                                    "instance-form.collapse.architecture-information.perex",
                                )}
                            </P>
                            <IntegrationArchitectureFields
                                initialValues={
                                    instanceGroup?.integrationArchitecture
                                }
                            />
                        </>
                    )}
                </>
            )}
        />
    );
};

ProvisionedInstanceEditFormComponent.propTypes = {
    instanceGroup: PropTypes.object,
    formId: PropTypes.string.isRequired,
    disableEnvsEdit: PropTypes.bool,
    instancesRestartMutation: PropTypes.object.isRequired,
    instancesDeleteMutation: PropTypes.object.isRequired,
};
