import { message } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { NewInstanceForm } from "@/components/Integrations/NewInstanceForm";
import { InstanceServerType } from "@/constants/CommonConstats";
import { getNewIntegrationFormName as getTrackName } from "@/mixpanel/buttonNames";
import { useRouteDataRefetch } from "@/mixpanel/hooks/useRouteDataRefetch.hook";
import { useTrackButtonClick } from "@/mixpanel/hooks/useTrackButtonClick.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { instancesServices } from "@/services/instance.service";
import { entries, pipe, reduce, set } from "lodash/fp";
import React, { useCallback } from "react";

export function NewInstancePage() {
    const { accountId } = useAccountAppParams();
    const refetchRouteData = useRouteDataRefetch();

    const {
        locationRouterService,
        accountAppLocations: { instancesLocation },
    } = useDic();
    const { trackHandler } = useTrackButtonClick();

    const handleOnCancel = useCallback(() => {
        locationRouterService.navigate(instancesLocation, {
            accountId: accountId,
        });
    }, [locationRouterService, instancesLocation, accountId]);

    function setCorrectValuesForProvisionedType({
        version,
        environments,
        ...restValues
    }) {
        return restValues.serverType === InstanceServerType.MANUAL
            ? { version, environments, ...restValues }
            : {
                  ...restValues,
                  instanceName:
                      `pim-${restValues.instanceName}` +
                      (restValues.type ? `-${restValues.type}` : ""),
                  environments: environments.map(env => ({
                      ...env,
                      version,
                  })),
              };
    }

    const handleOnSubmit = ({ values, successMessage }) => {
        const parsed = pipe(
            entries,
            reduce((acc, [k, v]) => set(k, v, acc), {}),
        )(values);
        const adjustedValues = setCorrectValuesForProvisionedType(parsed);

        return instancesServices
            .createInstance(accountId, adjustedValues, true)
            .then(
                trackHandler(
                    () => {
                        message.info(successMessage);
                        refetchRouteData();
                        handleOnCancel();
                    },
                    { name: getTrackName("SubmitSuccess") },
                ),
            )
            .catch(error => {
                message.error(error.response.data.message);
            });
    };

    return (
        <NewInstanceForm
            projectId={accountId}
            onCancel={handleOnCancel}
            onSubmit={handleOnSubmit}
        />
    );
}
