import {
    ButtonMenu,
    Gap,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { responseErrorMessage } from "@/modules/loadable";
import { t } from "@/translations";
import React from "react";
import uuid from "uuid/v4";
import { TableLocalFiltered } from "../TableLocalFiltered";

export const WF_ACTION_TYPES = {
    WORKFLOW_INITIATED: "WORKFLOW_INITIATED",
    WORKFLOW_ALREADY_RUNNING: "WORKFLOW_ALREADY_RUNNING",
    WORKFLOW_NOT_FEASIBLE: "WORKFLOW_NOT_FEASIBLE",
};

const WorkflowAlreadyRunningModal = ({
    modalProviderProps,
    workflowResponse: { actionType, wfName, wfId, waitingFor } = {},
}) => {
    return (
        <Modal {...modalProviderProps}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("workflow-running-modal.title")}
                />
                <UnityLayout.Content padding={[true, true]}>
                    <Text>
                        {t("workflow-running-modal.description", {
                            action: wfName,
                        })}
                    </Text>
                    <Gap size="small" />
                    <Text>{t("workflow-running-modal.table.description")}</Text>
                    <Gap size="small" />
                    <TableLocalFiltered
                        columns={[
                            {
                                type: fieldTypes.TEXT,
                                label: "Name",
                                name: "name",
                            },
                            {
                                type: fieldTypes.TEXT,
                                label: "Email",
                                name: "email",
                            },
                            {
                                type: fieldTypes.TEXT,
                                label: "Username",
                                name: "username",
                            },
                        ]}
                        dataSource={waitingFor.map(user => ({
                            ...user,
                            uuid: uuid(),
                        }))}
                        pagination={false}
                        rowKey="uuid"
                        customHeight={{ allowShrink: false, height: 300 }}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.close"),
                                    onClick: modalProviderProps.onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

const needsWorkflowApproval = axiosResponse => axiosResponse.status === 202;

const workflowFeedback = (
    axiosResponse,
    { messageService, customModal, trackWorkflow },
) => {
    if (!needsWorkflowApproval(axiosResponse)) return false;

    const { actionType, wfName } = axiosResponse.data;
    trackWorkflow?.({ actionType, wfName });

    switch (actionType) {
        case WF_ACTION_TYPES.WORKFLOW_INITIATED:
            messageService.info({
                content: `The selected action needs to be approved using the '${wfName}' workflow. Please wait for the workflow to finish.`,
            });
            return true;
        case WF_ACTION_TYPES.WORKFLOW_ALREADY_RUNNING:
            customModal.show(
                <WorkflowAlreadyRunningModal
                    workflowResponse={axiosResponse.data}
                />,
            );
            return true;
        case WF_ACTION_TYPES.WORKFLOW_NOT_FEASIBLE:
            messageService.error({
                content: `The selected action needs to be approved using the '${wfName}' workflow, but workflow is not feasible. Please contact a platform administrator.`,
            });
            return true;
        default:
            console.error(`Unknown workflow actionType: ${actionType}`);
            messageService.info({
                content: `Needs workflow approval: ${actionType}`,
            });
            return true;
    }
};

export const withWorkflowFeedback = async (
    services,
    createAxiosPromise,
    success = "general.saved",
    error = responseErrorMessage,
) => {
    const { messageService } = services;

    if (
        !services.messageService ||
        !services.customModal ||
        !services.trackWorkflow
    ) {
        console.error("Missing services to display workflow feedback!", {
            services,
        });
    }
    try {
        const result = await createAxiosPromise();

        if (!workflowFeedback(result, services))
            messageService.success({
                content:
                    typeof success === "function"
                        ? success(result)
                        : t(success),
            });

        return result;
    } catch (e) {
        messageService.error({
            content: typeof error === "function" ? error(e) : t(error),
        });

        throw e;
    }
};
