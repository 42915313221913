import { Forms } from "@/components/DesignSystem";
import { extractFieldKeys } from "@/components/DesignSystem/PfxEntityAdvancedFilter/PfxEntityAdvancedFilter";
import { useDic } from "@/components/Dic/useDic.hook";
import { getErrorMessage } from "@/utils/state/error.utils";
import { get, map, pipe } from "lodash/fp";
import { useCallback, useMemo } from "react";

export const useOptionsValidator = options => {
    const valuesStr = pipe(
        map(get("value")),
        JSON.stringify.bind(JSON),
    )(options);
    return useMemo(
        () =>
            Forms.pmValidators.createAllowedValuesValidator(
                JSON.parse(valuesStr),
                "Value is not allowed",
            ),
        [valuesStr],
    );
};

const useFilterExistsValidator = ({
    instanceId,
    skipValidationFor,
    withOnline,
}) => {
    const { filtersService } = useDic();

    return useCallback(
        value =>
            skipValidationFor && skipValidationFor === value
                ? Forms.success()
                : filtersService
                      .filterExists({ instanceId, name: value, withOnline })
                      .then(({ data: exists }) =>
                          exists
                              ? Forms.error("Already exists")
                              : Forms.success(),
                      )
                      .catch(e => Forms.error(getErrorMessage(e))),
        [filtersService, instanceId, skipValidationFor, withOnline],
    );
};

export const useFilterNameValidator = ({
    instanceId,
    currentName,
    withOnline,
}) => {
    const filterExistsValidator = useFilterExistsValidator({
        instanceId,
        skipValidationFor: currentName,
        withOnline,
    });

    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                Forms.pmValidators.createPatternValidator(
                    /^[A-Za-z0-9_-]*$/, // TODO: from old CopyFilterDialog: /^[a-zA-Z0-9_:.-]*$/
                    `Name does not match pattern ^[A-Za-z0-9_-]*$`,
                ),
                filterExistsValidator,
            ]),
        [filterExistsValidator],
    );
};

export const useEntityTypeValidator = options => {
    const optionsValidator = useOptionsValidator(options);
    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                optionsValidator,
            ]),
        [optionsValidator],
    );
};

export const useEntityNameValidator = options => {
    const optionsValidator = useOptionsValidator(options);
    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                optionsValidator,
            ]),
        [optionsValidator],
    );
};

const arr = [];
export const useFilterConfigurationValidator = ({ maybeOptions }) => {
    const optionsValidator = useOptionsValidator(maybeOptions ?? arr);
    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                optionsValidator,
            ]),
        [optionsValidator],
    );
};

export const useDefinedFieldKeysValidator = ({
    maybeFieldKeys,
    enabled = !!maybeFieldKeys,
}) =>
    useMemo(() => {
        const allowedValues = (maybeFieldKeys ?? []).map(({ name }) => name);
        const validator = Forms.pmValidators.createMultiAllowedValuesValidator(
            allowedValues,
            notAllowed =>
                `Filter uses fields not found in possible values: ${notAllowed.join(
                    ", ",
                )}`,
        );

        return async value => {
            if (!value || !enabled) return Forms.success();
            const usedFieldKeys = extractFieldKeys(value).map(
                ({ name }) => name,
            );
            return validator(usedFieldKeys);
        };
    }, [enabled, maybeFieldKeys]);
