import { useAccountPartitionsQuery } from "@/components/CustomerPartitions/hooks/useAccountPartitionsQuery.hook";
import { InstanceSelect } from "@/components/InstanceSelect/InstanceSelect.component";
import { useAccountMarketplaceInstancesQuery } from "@/components/Integrations/hooks/useAccountMarketplaceInstancesQuery.hook";
import { PartitionSelect } from "@/components/PartitionSelect/PartitionSelect.component";
import { deployToEnum } from "@/constants/deployment.constants";
import { useMapLoadableMemoized } from "@/modules/loadable";
import { map } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import { mapPartitionsToOptions } from "../../../../components/Marketplace/helpers";

export function SelectDeployDestination({
    destination,
    destinationId,
    ...props
}) {
    return destination === deployToEnum.PARTITION ? (
        <PartitionSelect
            {...props}
            partitionId={destinationId}
            allowClear
            autoFocus
        />
    ) : (
        <InstanceSelect
            instanceId={destinationId}
            allowClear
            autoFocus
            {...props}
        />
    );
}

SelectDeployDestination.propTypes = {
    destination: PropTypes.string.isRequired,
    destinationId: PropTypes.any,
};

export const useSelectDeployDestinationOptions = ({ accountId }) => {
    const availableInstancesLoadable = useMapLoadableMemoized(
        useAccountMarketplaceInstancesQuery({ accountId }).loadable,
        map(instance => ({
            id: instance.id,
            instanceName: instance.instanceName,
        })),
    );
    const partitionsOptionsLoadable = useMapLoadableMemoized(
        useAccountPartitionsQuery({ accountId }).loadable,
        mapPartitionsToOptions,
    );

    return { availableInstancesLoadable, partitionsOptionsLoadable };
};
