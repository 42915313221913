import { Card, H3, Tag } from "@/components/DesignSystem";
import { LoadablePropType, LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

export const ElkTags = ({ instanceLoadable }) => {
    return (
        <Card spacing="medium">
            <H3>{t("dashboard-instances.header.integration-tags")}</H3>
            <LoadableRenderer
                loadable={instanceLoadable}
                hasValue={instance =>
                    instance?.integrationTags.map(tag => (
                        <Tag key={tag} size="small">
                            {tag}
                        </Tag>
                    ))
                }
            />
        </Card>
    );
};

ElkTags.propTypes = {
    instanceLoadable: LoadablePropType(
        PropTypes.shape({ integrationTags: PropTypes.array }),
    ),
};
