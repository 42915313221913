import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useEngSupportUsersQuery = () => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            axiosService.get(`/api/admin/pfx-eng-support-users`).then(getData),
        [axiosService],
    );
};

export const useDisableEngSupportUserMutation = ({ afterSuccess }) => {
    const { axiosService } = useDic();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async ({ partitionSerialNumber, pfxUserId }) => {
            const { data } = await axiosService.delete(
                `/api/admin/pfx-eng-support-users/${partitionSerialNumber}/${pfxUserId}`,
            );
            currentAfterSuccess(data);
        },
        [axiosService, currentAfterSuccess],
        "User deactivated successfully",
    );
};
