import {
    Alert,
    Button,
    ButtonGroup,
    Forms,
    H3,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { GenericFormInner } from "@/components/GenericForm/GenericFormInner";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { mapperMappingSourceType } from "@/services/mapperMappingSourceType.enum";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { size } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { StepUiHeaders } from "../TableComponents/Skip";

const { useForm, Form, SubmitButton } = Forms;

const mapErrorMessageObject = (errorMessageObj, mapperFunc) => {
    const transformedObj = {};
    for (const key in errorMessageObj) {
        const value = errorMessageObj[key];

        if (Array.isArray(value)) {
            transformedObj[key] = mapperFunc(
                value.length > 0 ? `${value[0]}` : "",
            );
        } else {
            transformedObj[key] = mapErrorMessageObject(value, mapperFunc);
        }
    }

    return transformedObj;
};

export const useValidateGenericForm = ({ templateName, query, onNext }) => {
    const { packageService } = useDic();
    const [errorMessages, setErrorMessages] = useState([]);

    const onSubmit = useCallback(
        ({ values, setErrors, setAllToTouched }) =>
            packageService
                .validateGenericForm({
                    query,
                    templateName,
                    formValues: values,
                })
                .then(getData)
                .then(validationResult => {
                    if (validationResult.valid) {
                        return onNext(values);
                    } else {
                        setTimeout(() => {
                            setAllToTouched();
                            const errors = mapErrorMessageObject(
                                validationResult.formMessages,
                                Forms.error,
                            );
                            setErrors(errors);

                            if (
                                size(validationResult?.sharedStateMessages) > 0
                            ) {
                                setErrorMessages(
                                    validationResult.sharedStateMessages,
                                );
                            }
                        }, 200);
                    }
                }),
        [onNext, packageService, query, templateName],
    );

    return { onSubmit, errorMessages };
};

const GenericForm = ({
    step = {},
    partitionId,
    onNext,
    onBack,
    onCancel,
    apiResult,
}) => {
    const { onSubmit, errorMessages } = useValidateGenericForm({
        templateName: apiResult.templateName,
        query: useMemo(() => ({ partitionId }), [partitionId]),
        onNext,
    });
    const { formId, handleSubmit, setValues, setTouched } = useForm({
        onSubmit,
    });

    const initialValues = useMemo(() => {
        return {
            ...step.form.reduce(
                (result, field) => ({ ...result, [field.name]: field.default }),
                {},
            ),
            ...step.oldValues,
        };
    }, [step.form, step.oldValues]);

    useSetValidatedInitialValues({ initialValues, setValues, setTouched }, [
        initialValues,
    ]);

    return (
        <Form formId={formId} onSubmit={handleSubmit}>
            {!step?.ui?.title && <H3>{t("generic-form.title")}</H3>}
            <StepUiHeaders ui={step.ui} />
            {size(errorMessages) > 0 && (
                <Alert message={errorMessages[0]} type="error" showIcon />
            )}
            <GenericFormInner
                formId={formId}
                formDefinitions={step.form}
                objectId={partitionId}
                objectType={mapperMappingSourceType.partitions}
                setValues={setValues}
            />

            <ButtonGroup>
                <SubmitButton
                    type="primary"
                    label={t("partition-send-data.button.continue")}
                />
                <BackButton onBack={onBack} />
                <Button
                    type="text"
                    onClick={onCancel}
                    size="large"
                    label={t("partition-send-data.button.cancel")}
                />
            </ButtonGroup>
        </Form>
    );
};

GenericForm.propTypes = {
    step: PropTypes.object.isRequired,
    apiResult: PropTypes.object,
    partitionId: PropTypes.number.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
};

export default GenericForm;
