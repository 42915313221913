import { Gap, Text } from "@/components/DesignSystem";
import RemoteLoginForm from "@/components/RemoteLogin/RemoteLoginForm";
import {
    isAuthorizedByPermissionOr,
    SecurityContext,
} from "@/security/authorization";
import { t } from "@/translations";
import React, { useContext } from "react";

const RemoteLoginPage = () => {
    const securityContext = useContext(SecurityContext);

    return (
        isAuthorizedByPermissionOr(
            ["loginAs_pricefx-support", "loginAs_root"],
            securityContext.permissions,
        ) && (
            <>
                <Text>{t("remote-login.perex")}</Text>
                <Gap size="x-large" />
                <RemoteLoginForm />
            </>
        )
    );
};

export default RemoteLoginPage;
