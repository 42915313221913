import { Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { getErrorMessage } from "@/utils/state/error.utils";
import { useMemo } from "react";
import { useCallback } from "react";

export const NAME_VALIDATION_PATTERN = new RegExp(/^[a-zA-Z0-9_-]*$/);

const useClassExistsValidator = ({
    skipValidationFor,
    instanceId,
    withOnline,
}) => {
    const { classService } = useDic();

    return useCallback(
        name =>
            skipValidationFor && skipValidationFor === name
                ? Forms.success()
                : classService
                      .classExists({ instanceId, name, withOnline })
                      .then(({ data: exists }) =>
                          exists
                              ? Forms.error("Already exists")
                              : Forms.success(),
                      )
                      .catch(e => Forms.error(getErrorMessage(e))),
        [classService, instanceId, skipValidationFor, withOnline],
    );
};

export const useClassNameValidator = ({
    instanceId,
    currentName,
    withOnline,
}) => {
    const classExistsValidator = useClassExistsValidator({
        instanceId,
        skipValidationFor: currentName,
        withOnline,
    });
    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                Forms.pmValidators.notBlank(),
                Forms.pmValidators.createPatternValidator(
                    NAME_VALIDATION_PATTERN,
                    "Name has a wrong format. Valid format is a-z A-Z, 0-9 _ - without spaces",
                ),
                classExistsValidator,
            ]),
        [classExistsValidator],
    );
};
