import { Gap, H5, Select } from "@/components/DesignSystem";
import { useConfirmModal } from "@/modules/modal/imperative/useConfirmModal.hook";
import { t, T } from "@/translations";
import cx from "classnames";
import { produce } from "immer";
import cloneDeep from "lodash/cloneDeep";
import keyBy from "lodash/keyBy";
import merge from "lodash/merge";
import values from "lodash/values";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { conditionalOptionsModal } from "../../../../../apps/marketplaceApp/components/PackageDefinition/MappingOption.modal";
import {
    getAvailableOptions,
    getTypeLabel,
} from "../../../packagesPanel.utils";
import { ParsedType } from "../../constants";
import PackageDataMappingLayout from "../PackageDataMappingLayout/PackageDataMappingLayout";
import { PackageTable } from "../PackageTable/PackageTable";
// assets
import { defaultPreviewCellProps } from "@/components/Packages/PackageTableDefinitionPanel/components/PackageDataMappingLayout/DataPreviewTable.component";
import styles from "../styles.less";
import { Skip } from "../TableComponents/Skip";
import { getTitleAttribute, handleRowClassName } from "./priceParemeters.utils";
import { TypeSelect } from "./TypeSelect";

export const getAttributes = (count, mandatoryFields) => {
    let start = 1;
    let groupedByName = {};
    let result = [];
    if (mandatoryFields) {
        start += mandatoryFields.length;
        groupedByName = keyBy(mandatoryFields, "name");
    }

    for (let i = start; i < count + start; i++) {
        if (!groupedByName[`attribute${i}`]) {
            result.push({
                name: `attribute${i}`,
                label: `Attribute ${i}`,
                value: undefined,
                skip: false,
            });
        }
    }

    return result;
};

const resolveTextColor = record =>
    record.skip ? styles.textDisabled : styles.headingColor;

const shouldDisable = (attributesValues, name) =>
    allFieldsSelected(attributesValues) &&
    !attributesValues.map(a => a.value).includes(name);

const allFieldsSelected = attributesValues =>
    attributesValues.filter(v => v.value || v.skip).length ===
    attributesValues.length;

export const PPMatrixAttributes = ({
    accountId,
    globalState,
    onNext,
    onBack,
    changeState,
    onCancel,
    step,
}) => {
    const { fileInfo: tableExampleData = {}, userInputs = {} } = globalState;

    const clonedInputs = cloneDeep(userInputs);

    const { keyValues, attributes } = clonedInputs;

    const { mandatoryFields } = step;

    const confirmModal = useConfirmModal();

    const setAttributes = i => {
        userInputs.attributes = i;
        changeState({ userInputs });
    };

    useEffect(() => {
        if (!attributes) {
            setAttributes(
                keyBy(
                    getAttributes(
                        headers.length - values(keyValues).length,
                        mandatoryFields,
                    ),
                    "name",
                ),
            );
        }
    }, []);

    const headers = tableExampleData.columns
        ? tableExampleData.columns.map(c => c.name)
        : [];

    const byValue = keyBy(values(attributes), "value");

    const createData = () => ({
        attributes: cloneDeep(attributes),
        stayOnIndex: true,
    });

    const modifyAttribute = (name, clb) =>
        setAttributes(
            produce(attributes, draft => {
                clb(draft[name]);
            }),
        );

    const handleSelect = (record, value) => {
        modifyAttribute(record.name, attribute => {
            attribute.value = value;
            const field = tableExampleData.columns.find(
                column => column.name === value,
            );

            if (field) {
                attribute.type = field.type;
            }
        });
    };

    const handleType = (record, value) => {
        modifyAttribute(record.name, attribute => (attribute.type = value));
    };

    const onSkip = (record, checked) => {
        modifyAttribute(record.name, attribute => (attribute.skip = checked));
    };

    const onSkipAll = checked => {
        setAttributes(
            produce(attributes, draft => {
                values(draft).forEach(attribute => (attribute.skip = checked));
            }),
        );
    };

    const handleOnNext = () =>
        conditionalOptionsModal({
            condition: step.showMappingOptions,
            data: createData(),
            accountId,
            onNext,
            onCancel,
            confirmModal,
        });

    const customColumns = ({ name, type }) => {
        const selectedType = byValue[name] && byValue[name].type;
        return {
            ...defaultPreviewCellProps,
            label: createTitle(name, selectedType || type),
            name,
            render: text => {
                return (
                    <div className={cx({ [styles.skipped]: shouldSkip(name) })}>
                        {`${text}`}
                    </div>
                );
            },
        };
    };

    const createTitle = (name, type) => {
        const value = getTitleAttribute(name, keyValues, attributes);

        return (
            <div className={cx({ [styles.skipped]: shouldSkip(name) })}>
                {name}
                <div
                    className={
                        shouldSkip(name) ? styles.textDisabled : styles.type
                    }
                >
                    {getTypeLabel(type)}
                </div>
                <div className={styles.ppField}>{value.label}</div>
            </div>
        );
    };

    const shouldSkip = name => {
        const value = getTitleAttribute(name, keyValues, attributes);
        const attributesValues = values(merge(keyValues, attributes));
        return shouldDisable(attributesValues, name) ? true : value.skip;
    };

    const disableNextButton = () =>
        values(attributes).filter(v => !v.value && !v.skip).length > 0;

    const disableOption = (record, type) => {
        const byName = keyBy(tableExampleData.columns, "name");
        const originalType = byName[record.value];
        const possibleValues = [
            ParsedType.STRING,
            originalType && originalType.type,
        ];
        return !possibleValues.includes(type);
    };

    const columns = [
        {
            key: "pfxFieldName",
            title: () => t("package-data-upload.label.additional-fields"),
            render: (text, record) => (
                <H5 className={resolveTextColor(record)}>{record.label}</H5>
            ),
        },
        {
            key: "valueFromFile",
            width: "30%",
            title: () => (
                <span className={styles.descriptionColor}>
                    <FormattedMessage
                        id="package-data-upload.label.file-fields"
                        values={{
                            fileName: tableExampleData.fileName,
                        }}
                    />
                </span>
            ),
            render: (text, record) => (
                <Select
                    placeholder={t("packages.select.placeholder")}
                    showSearch
                    allowClear
                    onDeselect={value => {
                        handleSelect(record, value);
                    }}
                    onChange={value => {
                        handleSelect(record, value);
                    }}
                    className={styles.itemSelect}
                    defaultValue={record.value}
                    disabled={record.skip}
                >
                    {getAvailableOptions(
                        record,
                        tableExampleData.columns,
                        userInputs.keyValues,
                        attributes,
                    )}
                </Select>
            ),
        },
        {
            key: "types",
            width: "30%",
            title: () => (
                <span className={styles.descriptionColor}>
                    <T id="package-label.extension-otp-fields.column-type" />
                </span>
            ),
            render: (text, record) => (
                <TypeSelect
                    record={record}
                    disableOption={disableOption}
                    handleType={handleType}
                    disabled={record.skip || !record.value}
                />
            ),
        },
        Skip(onSkipAll, onSkip, attributes),
    ];
    return (
        <PackageDataMappingLayout
            dataExample={tableExampleData}
            disableNextButton={disableNextButton()}
            customColumns={customColumns}
            onNext={handleOnNext}
            onBack={onBack}
            onCancel={onCancel}
        >
            <T
                id="package-data-upload.additional-price-parameters-mapper-message"
                values={{ fileName: tableExampleData.fileName }}
            />
            <Gap size="small" />

            <PackageTable
                fields={values(attributes)}
                handleRowClassName={handleRowClassName}
                columns={columns}
            />
        </PackageDataMappingLayout>
    );
};

PPMatrixAttributes.propTypes = {
    changeState: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    globalState: PropTypes.object.isRequired,
    step: PropTypes.object.isRequired,
};
