import { Gap } from "@/components/DesignSystem";
import {
    DRAWER_FOOTER_HEIGHT,
    DRAWER_HEADER_HEIGHT,
    TAB_HEIGHT,
} from "@/components/DesignSystem/Table/constants";
import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import PageableTable from "@/components/PagableTable/PageableTable";
import { useFetchPagePropTypes } from "@/components/PagableTable/useFetchPage.hook";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { createColumns } from "../AssetsTable/AssetsTable.columns";
import { CheckboxDropdown } from "./CheckboxDropdown.component";
import { IntegrationsTableContainer } from "./IntegrationsTable.container";
import { PartitionsTableContainer } from "./PartitionsTable.container";

const createProjectIdSubsetFilter = ({ projectId }) => ({
    criteria: [
        {
            fieldName: "project.id",
            operator: "equals",
            value: projectId,
        },
    ],
    operator: "and",
    _constructor: "AdvancedCriteria",
});
export function AdminAssetsTable({
    page = {},
    fetchPage,
    loading,
    onAssetClick,
    assetType,
    defaultSort = {
        fieldName: "name",
        sortDir: "ascending",
    },
    selectionChange,
    selectedAssets,
    hasAssetRights,
    hasRights,
    setHasRights,
    fetchParams,
    testId = "admin-assets-table-" + assetType,
    readOnly = false,
    tableProps,
}) {
    const rowHighlights = useMemo(
        () =>
            (
                { entityType, id }, // entityType missing for projects, investigate
            ) =>
                hasAssetRights && hasAssetRights(id, assetType)
                    ? "green"
                    : false,
        [hasAssetRights, assetType],
    );
    const rowSelection = useSyncRowSelection(
        selectedAssets[assetType],
        selectionChange,
        [assetType],
        _record => readOnly,
    );

    const columns = createColumns(onAssetClick);
    const projectsTableProps =
        assetType === "project"
            ? {
                  pmExpandable: {
                      expandedRowRender: record => (
                          <div
                              style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                              }}
                          >
                              <PartitionsTableContainer
                                  projectId={record.id}
                                  onAssetClick={onAssetClick}
                                  selectionChange={selectionChange}
                                  selectedAssets={selectedAssets}
                                  hasAssetRights={hasAssetRights}
                                  hasRights={hasRights}
                                  fetchParams={fetchParams}
                                  tableProps={{
                                      fixed: true,
                                      fixedHeight: 323,
                                      subsetFilter: createProjectIdSubsetFilter(
                                          { projectId: record.id },
                                      ),
                                  }}
                              />
                              <Gap size="small" />
                              <IntegrationsTableContainer
                                  projectId={record.id}
                                  onAssetClick={onAssetClick}
                                  selectionChange={selectionChange}
                                  selectedAssets={selectedAssets}
                                  hasAssetRights={hasAssetRights}
                                  hasRights={hasRights}
                                  fetchParams={fetchParams}
                                  tableProps={{
                                      fixed: true,
                                      fixedHeight: 323,
                                      subsetFilter: createProjectIdSubsetFilter(
                                          { projectId: record.id },
                                      ),
                                  }}
                              />
                          </div>
                      ),
                      expandedRowHeight: 670,
                  },
              }
            : {};

    return (
        <>
            <PageableTable
                columnsAutoWidth
                rowKey="id"
                columns={columns[assetType]}
                defaultSort={defaultSort}
                page={page}
                fetchPage={fetchPage}
                loading={loading}
                rowSelection={rowSelection}
                className="pmTable--fixCollapseWidth"
                dataTest={testId}
                rowHighlights={rowHighlights}
                {...projectsTableProps}
                {...tableProps}
                fixed
                restHeight={
                    DRAWER_HEADER_HEIGHT + TAB_HEIGHT + DRAWER_FOOTER_HEIGHT
                }
            />
            {assetType === "project" &&
                setHasRights && ( // temp solution until UC Table will support it
                    <div
                        style={{
                            position: "absolute",
                            top: "18px",
                            left: "22px",
                            zIndex: 1000,
                        }}
                    >
                        <CheckboxDropdown
                            checked={hasRights}
                            onChange={setHasRights}
                            checkItemText="Show with rights"
                            uncheckItemText="All"
                        />
                    </div>
                )}
        </>
    );
}

AdminAssetsTable.propTypes = {
    ...useFetchPagePropTypes,
    onAssetClick: PropTypes.func,
    selectionChange: PropTypes.func.isRequired,
    selectedAssets: PropTypes.shape({
        project: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
        integration: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
        partition: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    }).isRequired,
    assetType: PropTypes.oneOf(["project", "integration", "partition"])
        .isRequired,
    defaultSort: PropTypes.object,
};
