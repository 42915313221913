import { axiosService } from "../axios";

export const resourceContentPath = (instanceId, resourceName) =>
    `/api/instances/${instanceId}/resources/${resourceName}`;

export const resourcesService = {
    getResources(instanceId) {
        return axiosService.get(`/api/instances/${instanceId}/resources`);
    },
    getResourceContent(instanceId, resourceName) {
        return axiosService.get(resourceContentPath(instanceId, resourceName), {
            responseType: "blob",
        });
    },
    create(instanceId, values) {
        return axiosService.post(`/api/instances/${instanceId}/resources`, {
            ...values,
        });
    },
    deploy(instanceId, fileId, encrypt = false) {
        return axiosService.post(
            `/api/instances/${instanceId}/resources/${fileId}`,
            { encrypt },
        );
    },
    delete({ instanceId, name, prn }) {
        return axiosService.delete(
            `/api/instances/${instanceId}/resources/${name}`,
            {
                data: prn ? { prn } : null,
            },
        );
    },
    getAllowedExtensions(instanceId) {
        return axiosService.get(
            `/api/instances/${instanceId}/allowed-resource-extensions`,
        );
    },
};
