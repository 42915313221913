import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";

export const propertyColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("route.copy.properties.original-name"),
        name: "originalName",
    },
    {
        type: fieldTypes.TEXT,
        label: t("route.copy.properties.new-name"),
        name: "newName",
    },
    {
        type: fieldTypes.TEXT,
        label: t("route.copy.properties.type"),
        name: "type",
    },
];

export const mapperColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("route.copy.mappers.name"),
        name: "name",
    },
    {
        type: fieldTypes.TEXT,
        label: t("route.copy.mappers.exists"),
        name: "exists",
        render: text => {
            return text ? "Yes" : "No. Please copy mapper manually";
        },
    },
];

export const filterColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("route.copy.filters.name"),
        name: "name",
    },
    {
        type: fieldTypes.TEXT,
        label: t("route.copy.filters.exists"),
        name: "exists",
        render: text => {
            return text ? "Yes" : "No. Please copy filter manually";
        },
    },
];
