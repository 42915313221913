const mapperMethod = {
    encode: "encode",
    decode: "decode",
};

export class HttpService {
    constructor({ httpClient }) {
        this.httpClient = httpClient;
    }

    /**
     *
     * @param {string} url
     * @param {Object} opts
     * @param {Mapper} opts.mapper
     * @param {Schema} opts.schema
     * @param {Object} opts.defaultResponse
     * @param {Object} opts.httpOptions
     * @returns {Promise<Object>}
     */
    async get(url, { mapper, schema, defaultResponse, httpOptions = {} } = {}) {
        const response = await this.httpClient.get(url, httpOptions);

        if (!response && defaultResponse) {
            return defaultResponse;
        }

        return this.validateAndMapData({
            schema,
            mapper,
            mapperMethod: mapperMethod.decode,
            data: response,
            defaultResponse,
        });
    }

    /**
     *
     * @param {string} url
     * @param {*} data
     * @param {Object} opts
     * @param {Mapper} opts.mapper
     * @param {Schema} opts.schema
     * @param {Object} opts.httpOptions
     * @returns {Promise<*>}
     */
    async post(url, data, { mapper, schema, httpOptions = {} } = {}) {
        const dataToSend = this.validateAndMapData({
            schema,
            mapper,
            mapperMethod: mapperMethod.encode,
            data,
        });

        return this.httpClient.post(url, dataToSend, httpOptions);
    }

    /**
     *
     * @param {string} url
     * @param {Object} opts
     * @param {Mapper} opts.mapper
     * @param {Schema} opts.schema
     * @param {Object} opts.defaultResponse
     * @param {Object} opts.httpOptions
     * @returns {Promise<Object>}
     */
    async delete(url, { mapper, schema, defaultResponse, httpOptions } = {}) {
        const response = await this.httpClient.delete(url, httpOptions);

        if (!response && defaultResponse) {
            return defaultResponse;
        }

        return this.validateAndMapData({
            schema,
            mapper,
            mapperMethod: mapperMethod.decode,
            data: response,
            defaultResponse,
        });
    }

    /**
     *
     * @param {object} opts
     * @param {Schema} opts.schema
     * @param {Mapper} opts.mapper
     * @param {string} opts.mapperMethod
     * @param {object} opts.data
     * @returns {object}
     */
    validateAndMapData({ schema, mapper, mapperMethod, data }) {
        this.validateData(schema, data);
        const casted = this.castData(schema, data);

        return this.mapData(mapper, mapperMethod, casted);
    }

    /**
     *
     * @param {Mapper} mapper
     * @param {string} mapperMethod
     * @param {*} data
     * @returns {*}
     */
    mapData(mapper, mapperMethod, data) {
        if (!mapper) {
            return data;
        }

        return mapper[mapperMethod](data);
    }

    validateData(
        schema,
        data,
        // eslint-disable-next-line no-console
        errorReporter = console.error.bind(console),
    ) {
        if (!schema) {
            return null;
        }

        try {
            schema.validateSync(data, { strict: true });
            return null;
        } catch (e) {
            errorReporter({
                message: "[HttpService] validation failed",
                error: e,
                data,
                schema,
            });
            return e;
        }
    }

    /**
     *
     * @param {Schema} schema
     * @param {*} data
     * @returns {*}
     */
    castData(schema, data) {
        if (!schema) {
            return data;
        }

        return schema.cast(data);
    }
}
