import { DataObject } from "@/utils/dataObjects/DataObject.class";

export class DeploymentStateEntity extends DataObject {
    id = null;
    partition = null;
    instance = null;
    templateName = null;
    state = null;
    processState = null;
    currentStep = null;
    currentStepData = null;
    startedBy = null;
    updatedAt = null;
    log = null;
}
