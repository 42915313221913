import { array, object, string, number, bool } from "yup";

export const OfferApiSchema = object().shape({
    id: number().required(),
    label: string().required(),
    description: string().required(),
    type: string().required(),
    detailedDescription: string().nullable(),
    link: string().nullable(),
    published: bool().required(),
});

export const OffersApiSchema = array().of(OfferApiSchema);
