import { useDic } from "@/components/Dic/useDic.hook";
import { LocationMenuContainer } from "@/components/Menu";
import { SidebarContent } from "@/components/Sidebar";
import { useLocationsList } from "@/modules/router";
import React from "react";
import { createLocationStruct } from "../../lib/locationMenu.utils";

export function ProductEnhancementSidebar() {
    const { productEnhancementAppLocation } = useDic();
    const locations = useLocationsList(
        productEnhancementAppLocation,
        createLocationStruct,
    );

    return (
        <SidebarContent>
            <LocationMenuContainer
                locations={locations}
                mode="inline"
                eager
                condensed
            />
        </SidebarContent>
    );
}
