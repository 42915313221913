import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT } from "@/components/DesignSystem/Table/constants";
import { hooks } from "@/components/DesignSystem/Table/Table.component";
import {
    useFailedEventsQuery,
    useRetryEventTypesMutation,
} from "@/components/Integrations/Dashboard/Tabs/FailedEventsList/loadables";
import { useRefreshAction } from "@/components/PageLayout/useRefreshAction.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { isLoading } from "@/modules/loadable";
import { useHasPermission } from "@/security/hooks/useHasPermission.hook";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { getFailedEventsListTrackName as getTrackName } from "../../../../../mixpanel/buttonNames";
import { columns } from "./failedEvents.columns";

const preferencesType = preferencesTypes.INSTANCE_FAILED_EVENTS_LIST_TABLE;

const EVENT_TYPE_KEY = "eventType";

export const FailedEventsList = ({ instanceId, visible }) => {
    const canEdit = useHasPermission(INTEGRATION_EDIT_PERMISSIONS);
    const failedEventsQuery = useFailedEventsQuery({
        instanceId,
        canFetch: !!instanceId && visible,
    });
    const retryEventTypesMutation = useRetryEventTypesMutation({
        instanceId,
    });

    useRefreshAction(visible ? failedEventsQuery.reload : null);

    const rowSelection = hooks.useRowSelection({
        disableOnRowClick: true,
    });
    const selectionContextMenu = useMemo(
        () => [
            {
                label: t("failed-events-list.retry-event-types"),
                onClick: () =>
                    retryEventTypesMutation.mutate(
                        rowSelection.selectedRowKeys,
                    ),
            },
        ],
        [retryEventTypesMutation, rowSelection.selectedRowKeys],
    );
    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("failed-events-list.retry-event-type.title"),
                        onClick: record =>
                            retryEventTypesMutation.mutate([
                                record[EVENT_TYPE_KEY],
                            ]),
                        track: { name: getTrackName("RetryEventType") },
                    },
                ]}
            />
        ),
        [retryEventTypesMutation],
    );
    const canEditProps = canEdit
        ? { rowSelection, selectionContextMenu, actionMenu }
        : {};

    const dataSource = failedEventsQuery.loadable.valueMaybe() || [];
    const loading = isLoading(failedEventsQuery);

    return (
        <TableWithPreferencesManagement
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            rowKey={EVENT_TYPE_KEY}
            datasetSlicing="local"
            preferencesType={preferencesType}
            visible={visible}
            fixed
            restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
            {...canEditProps}
            // exportUrl={`/api/instances/${instanceId}/.../export`}
        />
    );
};

FailedEventsList.propTypes = {
    instanceId: PropTypes.number.isRequired,
    visible: PropTypes.bool,
};
