import { Button, ButtonGroup, Forms, H3, P } from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

const { useForm, Form, Fields, SubmitButton, validators, pmValidators } = Forms;

const disabledExpirationDate = dateValue => {
    return dateValue < new Date();
};

const MIN_PASSWORD_LENGTH = 6;

const CustomerPartitionGeneralAdmin = ({
    generalAdminInfo,
    onSubmit,
    onCancel,
    userName,
}) => {
    const { formId, handleSubmit } = useForm({
        onSubmit,
    });

    return (
        <>
            <H3 data-test="general-admin-title">
                {t("partition.general-admin.title")}
            </H3>
            <P data-test="general-admin-info">
                {generalAdminInfo
                    ? generalAdminInfo.pfxRoleInfo
                        ? generalAdminInfo.pfxRoleInfo.inserted
                            ? t("partition.general-admin.exists.inserted", {
                                  userName: userName,
                              })
                            : t("partition.general-admin.exists.notinserted", {
                                  userName: userName,
                              })
                        : t(
                              "partition.general-admin.assign-role-to-existing-user",
                              {
                                  userName: userName,
                              },
                          )
                    : t("partition.general-admin.create-user-and-role")}
            </P>
            <Form formId={formId} layout="vertical" onSubmit={handleSubmit}>
                <Fields.Input
                    name="username"
                    data-test="username-input"
                    initialValue={userName}
                    label={t("partition.general-admin.loginName")}
                    disabled
                />

                <Fields.InputPassword
                    name="password"
                    data-test="password-input"
                    initialValue=""
                    placeholder={t("partition.general-admin.password")}
                    label={t("partition.general-admin.password")}
                    required
                    validator={validators.failOnFirst([
                        pmValidators.isRequired,
                        pmValidators.validatePasswordLength(
                            MIN_PASSWORD_LENGTH,
                        ),
                    ])}
                />

                <Fields.DatePicker
                    name="expiration"
                    data-test="expiration-input"
                    label={t("partition.general-admin.expiration")}
                    showTime
                    disabledDate={disabledExpirationDate}
                />

                <ButtonGroup>
                    <SubmitButton withoutSpinner>
                        <Button
                            label={t("general.save")}
                            type="primary"
                            htmlType="submit"
                            data-test="submit"
                        />
                    </SubmitButton>
                    <Button
                        type="text"
                        onClick={onCancel}
                        label={t("general.cancel")}
                        data-test="cancel"
                    />
                </ButtonGroup>
            </Form>
        </>
    );
};

CustomerPartitionGeneralAdmin.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    userName: PropTypes.string,
    generalAdminInfo: PropTypes.object,
};

export default CustomerPartitionGeneralAdmin;
