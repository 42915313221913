import {
    Alert,
    ButtonMenu,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { getIMConvertImageModalTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { messageService } from "@/modules/Message/message.service";
import {
    isLoading,
    useMutationLoadableWithNotification,
} from "@/modules/loadable";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import React from "react";

export const useConvertImageMutation = ({
    isRebuild,
    instanceName,
    afterSuccess,
}) => {
    const successMessage = t(
        isRebuild
            ? "image-info.modal.conversion.success-rebuild"
            : "image-info.modal.conversion.success",
        {
            instanceName: instanceName,
        },
    );

    const { instanceService } = useDic();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async ({ instanceId }) => {
            const res = await instanceService
                .convertToCustomImage(instanceId)
                .catch(e =>
                    messageService.error({
                        content: getErrorMessage(e.response.data),
                    }),
                );

            currentAfterSuccess();
            return res;
        },
        [isRebuild, instanceName, instanceService, currentAfterSuccess],
        successMessage,
    );
};

export const ConvertToCustomImageModal = ({
    isRebuild,
    instanceName,
    instanceId,
    visible,
    onConvert,
    onCancel,
}) => {
    const convertImageMutation = useConvertImageMutation({
        isRebuild,
        instanceName,
        afterSuccess: onConvert,
    });

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t(
                        isRebuild
                            ? "image-info.modal.conversion.title-rebuild"
                            : "image-info.modal.conversion.title",
                    )}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Alert
                        type="warning"
                        message={t(
                            isRebuild
                                ? "image-info.modal.conversion.warning-rebuild"
                                : "image-info.modal.conversion.warning",
                        )}
                    />
                    {/* <Gap /> */}
                    <Text>
                        {t(
                            isRebuild
                                ? "image-info.modal.conversion.message"
                                : "image-info.modal.conversion.message-rebuild",
                        )}
                    </Text>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    disabled: isLoading(convertImageMutation),
                                    label: t(
                                        isRebuild
                                            ? "custom-image-state.action.rebuild"
                                            : "custom-image-state.action.convert",
                                    ),
                                    type: "primary",
                                    onClick: () =>
                                        convertImageMutation.mutate({
                                            instanceId,
                                        }),
                                    track: {
                                        name: getTrackName("Convert"),
                                    },
                                },
                                {
                                    disabled: isLoading(convertImageMutation),
                                    label: t("general.cancel"),
                                    type: "default",
                                    onClick: onCancel,
                                    track: { name: getTrackName("Cancel") },
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
