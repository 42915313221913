import {
    ButtonMenu,
    Forms,
    Modal,
    P,
    UnityLayout,
} from "@/components/DesignSystem";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { useEditDomainsSamlMgmt } from "@/components/SingleSignOn/loadables";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

export const EditSamlDomainsModal = ({
    visible,
    onClose,
    projectId,
    initialValues,
    onSubmit,
}) => {
    const editDomainMutation = useEditDomainsSamlMgmt({
        projectId,
        afterSuccess: onSubmit,
    });

    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit: ({ values }) => {
            editDomainMutation.mutate({
                oldDomain: initialValues.domain,
                newDomain: values.domain,
            });
        },
    });

    useSetValidatedInitialValues(
        {
            initialValues,
            setValues,
            setTouched,
        },
        [initialValues],
    );

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("sso.form.domain.label")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <P size={2}>{t("sso.form.domain.tooltip")}</P>
                    <Forms.Form formId={formId} onSubmit={handleSubmit}>
                        <Forms.Fields.Input
                            required
                            name="domain"
                            label={t("sso.form.domain.label")}
                            placeholder=""
                            validator={Forms.pmValidators.isRequired}
                        />
                        {/* In future */}
                        {/* <Forms.Fields.List
                            required
                            name="domain"
                            // tooltip={t("sso.form.domain.tooltip")}
                            label={t("sso.form.domain.label")}
                            placeholder=""
                            validator={Forms.pmValidators.isRequired}
                            initialValue={initialValue}
                        /> */}
                    </Forms.Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.submit-for-approval"),
                                    type: "primary",
                                    formId,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
EditSamlDomainsModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
};
