import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { useUserBasicInfo } from "@/security/hooks/useUserBasicInfo.hook";
import { t } from "@/translations";
import { downloadFile } from "@/utils/downloadUtils";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import Moment from "react-moment";
import { isPricefxUsername } from "../../apps/accountApp/components/AccountUserAdminSettings/RightsDrawer/loadables";

export const DeploymentStatus = Object.freeze({
    FINISHED: "FINISHED",
    FAILED: "FAILED",
});

const preferencesType = preferencesTypes.DEPLOYED_PACKAGES_LIST_TABLE;

export const DeployedPackagesList = () => {
    const { packageService, messageService } = useDic();
    const { accountId: passedAccountId } = useAccountAppParams();
    const basicInfo = useUserBasicInfo();
    const isInternalUser = isPricefxUsername(basicInfo.userInfo.user.username);

    const accountId = isInternalUser ? undefined : passedAccountId;

    const [tableProps] = useFetchPage(
        (page, size, sort, filter) =>
            isInternalUser
                ? packageService.getAllDeployedPackages(
                      page,
                      size,
                      sort,
                      filter,
                  )
                : packageService.getDeployedPackagesFiltered(
                      page,
                      size,
                      sort,
                      filter,
                      accountId,
                  ),
        [],
    );

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("deployed-templates-list.download-state"),
                        onClick: () => {
                            packageService
                                .getDeployedPackageState(record.id)
                                .then(response =>
                                    downloadFile(
                                        JSON.stringify(response || {}),
                                        "package-deployment-state.json",
                                    ),
                                )
                                .catch(() =>
                                    messageService.error({
                                        content: t(
                                            "deployed-templates-list.download-state.error",
                                        ),
                                    }),
                                );
                        },
                    },
                ]}
            />
        ),
        [messageService, packageService],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            fixed
            rowKey="id"
            datasetSlicing="server"
            preferencesType={preferencesType}
            defaultSort={{ fieldName: "updatedAt", sortDir: "descending" }}
            columns={[
                {
                    type: fieldTypes.TEXT,
                    label: t("deployed-templates-list.header.job-id"),
                    name: "id",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("deployed-templates-list.header.label"),
                    name: "name",
                    className: "pmTable__column--withActionButton",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("deployed-templates-list.header.template-name"),
                    name: "templateName",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("deployed-templates-list.header.template-version"),
                    name: "templateVersion",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("deployed-templates-list.header.account-name"),
                    name: "accountName",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("deployed-templates-list.header.partition-name"),
                    name: "serialNumber",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("deployed-templates-list.header.instance-name"),
                    name: "instanceName",
                },
                {
                    type: fieldTypes.TEXT,
                    label: t("deployed-templates-list.header.user-name"),
                    name: "startedBy",
                },

                {
                    type: fieldTypes.DATETIME,
                    label: t("general.started-at"),
                    name: "startedAt",
                    render: (text, record) =>
                        record.startedAt && (
                            <Moment format="DD/MM/YYYY HH:mm:ss">
                                {record.startedAt}
                            </Moment>
                        ),
                },
                {
                    // TODO: fix locales and use type: fieldTypes.DATETIME,
                    type: fieldTypes.DATETIME,
                    label: t("deployed-templates-list.header.date"),
                    name: "updatedAt",
                    render: (text, record) =>
                        record.updatedAt && (
                            <Moment format="DD/MM/YYYY HH:mm:ss">
                                {record.updatedAt}
                            </Moment>
                        ),
                },
            ]}
            {...tableProps}
            exportUrl={
                accountId
                    ? `/api/packages/deployments/${accountId}/export`
                    : `/api/packages/deployments/export`
            }
            subsetFilter={{
                criteria: [
                    {
                        fieldName: "status",
                        operator: "equals",
                        value: DeploymentStatus.FINISHED,
                    },
                ],
                operator: "and",
                _constructor: "AdvancedCriteria",
            }}
        />
    );
};

DeployedPackagesList.propTypes = {
    error: PropTypes.object,
    items: PropTypes.array,
    loading: PropTypes.bool,
    onExportDeployedPackageState: PropTypes.func.isRequired,
};
