import { Link } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";

const TextWithSkipButtonComponent = ({
    textId,
    onSkip,
    onUseExisting = () => {},
}) => {
    return (
        <FormattedMessage
            id={textId}
            values={{
                skip: (
                    <Link onClick={onSkip}>
                        <FormattedMessage id="package.process.skip" />
                    </Link>
                ),
                useExisting: (
                    <Link onClick={onUseExisting}>
                        <FormattedMessage id="package.process.use-existing" />
                    </Link>
                ),
            }}
        />
    );
};

TextWithSkipButtonComponent.propTypes = {
    textId: PropTypes.string.isRequired,
    onSkip: PropTypes.func.isRequired,
};

export default TextWithSkipButtonComponent;
