import { tap } from "@/components/Connections/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import {
    entries,
    filter,
    flatMap,
    get,
    keys,
    map,
    noop,
    pipe,
} from "lodash/fp";

const getRowKey = ({ type, path }) => `${type}_${path}`;
const mapModificationsOld = pipe(
    entries,
    filter(get([1, "length"])),
    flatMap(([type, fileList]) =>
        fileList.map(path => ({ _id: getRowKey({ type, path }), path, type })),
    ),
);
export const useModificationsQuery = ({
    instanceId,
    targetEnvironment,
    map = mapModificationsOld,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `/api/instances/${instanceId}/modifications/${targetEnvironment}`,
                )
                .then(getData)
                .then(map),
        [axiosService, instanceId, map, targetEnvironment],
    );
};

const mapStorable = pipe(
    entries,
    map(([type, map]) => [type, keys(map)]),
    filter(get([1, "length"])),
    flatMap(([type, fileList]) =>
        fileList.map(path => ({ _id: getRowKey({ type, path }), path, type })),
    ),
);
const mapOther = pipe(
    keys,
    map(path => ({
        _id: getRowKey({ type: UNCAT_TYPE, path }),
        path,
        type: UNCAT_TYPE,
    })),
);

const UNCAT_TYPE = " ";
const mapDetailedModifications = pipe(({ otherObjects, storableObjects }) => [
    ...mapStorable(storableObjects),
    ...mapOther(otherObjects),
]);

export const useModificationsDetailedQuery = ({
    instanceId,
    targetEnvironment,
    map = mapDetailedModifications,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `/api/instances/${instanceId}/modifications-detailed/${targetEnvironment}`,
                )
                .then(getData)
                .then(map),
        [axiosService, instanceId, map, targetEnvironment],
    );
};

export const usePropertiesUsageQuery = ({
    instanceId,
    targetEnvironment,
    payload,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .post(
                    `/api/instances/${instanceId}/properties-usage/${targetEnvironment}`,
                    payload,
                )
                .then(getData),
        [axiosService, instanceId, payload, targetEnvironment],
    );
};

export const useTransportMutation = ({
    instanceId,
    targetEnvironment,
    afterSuccess = noop,
    afterError = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const afterErrorCurrent = useCurrentHandler(afterError);
    const url = `/api/instances/${instanceId}/merge-requests-precise/${targetEnvironment}`;

    return useMutationLoadableWithNotification(
        async payload =>
            axiosService
                .post(url, payload)
                .then(getData)
                .then(tap(afterSuccessCurrent))
                .catch(afterErrorCurrent),
        [afterErrorCurrent, afterSuccessCurrent, axiosService, url],
        "instances.transport.to.modal.success",
    );
};
