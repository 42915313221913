import PropTypes from "prop-types";
import React from "react";

import { UnityLayout } from "@/components/DesignSystem";
import { t } from "@/translations";
import { PermissionsTable } from "./PermissionsTable.component";

export const PERMISSION_TYPES = {
    PROJECT: "PROJECT",
    PARTITION: "PARTITION",
    INTEGRATION: "INTEGRATION",
};

export const PermissionsTables = ({
    groupedPermissions,
    permissionChanges,
    onChange,
    isWFPermission,
}) => {
    const titles = {
        PROJECT: t("permissions-table.account"),
        PARTITION: t("permissions-table.partition"),
        INTEGRATION: t("permissions-table.integration"),
        // PACKAGE: t("permissions-table.package") // uncomment to display package permissions
    };

    const isSinglePermissionType = Object.keys(groupedPermissions).length === 1;

    return (
        <UnityLayout>
            <UnityLayout.Content>
                {Object.entries(groupedPermissions).map(
                    ([key, permissions]) =>
                        titles[key] && (
                            <PermissionsTable
                                title={titles[key]}
                                onChange={onChange}
                                dataSource={permissions}
                                changes={permissionChanges[key]}
                                key={key}
                                testId={`permissions-table-${key}`}
                                isWFPermission={isWFPermission}
                                isSinglePermissionType={isSinglePermissionType}
                            />
                        ),
                )}
            </UnityLayout.Content>
        </UnityLayout>
    );
};

PermissionsTables.propTypes = {
    groupedPermissions: PropTypes.shape({
        PROJECT: PropTypes.arrayOf(PropTypes.object.isRequired),
        PARTITION: PropTypes.arrayOf(PropTypes.object.isRequired),
        INTEGRATION: PropTypes.arrayOf(PropTypes.object.isRequired),
    }).isRequired,
    permissionChanges: PropTypes.objectOf(PropTypes.object.isRequired)
        .isRequired,
    onChange: PropTypes.func.isRequired,
    isWFPermission: PropTypes.func,
};
