import {
    ButtonMenu,
    Modal,
    Table,
    UnityLayout,
} from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { manageQueriesColumns } from "./ManageQueriesColumns";

export const ManageQueriesModal = ({
    visible,
    elkQueries,
    close,
    onQueryEdit,
    onQueryDelete,
}) => {
    return (
        <Modal width={675} visible={visible} onClose={close} onOk={close}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("elastic-search.modal.title.manage-queries")}
                />
                <UnityLayout.Content padding={[false, false]}>
                    <Table
                        condensed
                        columns={manageQueriesColumns(
                            onQueryEdit,
                            onQueryDelete,
                        )}
                        dataSource={elkQueries}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.close"),
                                    onClick: close,
                                    type: "primary",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

ManageQueriesModal.propTypes = {
    visible: PropTypes.bool,
    elkQueries: PropTypes.arrayOf(PropTypes.object).isRequired,
    close: PropTypes.func.isRequired,
    onQueryEdit: PropTypes.func.isRequired,
    onQueryDelete: PropTypes.func.isRequired,
};
