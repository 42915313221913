import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import isNil from "lodash/isNil";

export const useActivationUsernameQuery = ({ activationToken }) => {
    const { accountActivationService } = useDic();

    return useQueryLoadable(
        async () =>
            isNil(activationToken)
                ? pendingPromise()
                : accountActivationService
                      .fetchUsername(activationToken)
                      .then(getData),
        [accountActivationService, activationToken],
    );
};

export const useActivateAccountMutation = ({ afterSuccess }) => {
    const { accountActivationService } = useDic();

    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async (activationToken, user) =>
            accountActivationService
                .activateAccount(activationToken, user)
                .then(res => {
                    afterSuccessCurrent();
                    return res;
                }),
        [accountActivationService, afterSuccessCurrent],
    );
};
