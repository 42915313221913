import { CreateOfferFormContainer } from "@/components/Offers/CreateOfferForm.container";
import { useMarketplaceAppParams } from "@/modules/router/hooks/useMarketplaceAppParams.hook";
import React from "react";

export const OfferCreatePage = () => {
    const { accountId } = useMarketplaceAppParams();

    return <CreateOfferFormContainer accountId={accountId} />;
};

OfferCreatePage.propTypes = {};
