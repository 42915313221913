import { buildPageableUrl } from "@/utils/urlUtils";
import { axiosService } from "../axios";

export const notificationsService = {
    getNotifications({ page, size, sort, filter } = {}) {
        return axiosService.post(
            buildPageableUrl(`/api/notifications`, page + 1, size, sort),
            filter,
        );

        // const params = qs.stringify({ page, size }, qsOptions);
        // return axiosService.get(`/api/notifications${params}`);
    },
    markAsRead(notificationIds = []) {
        return axiosService.post(`/api/notifications/mark-as-read`, {
            byIds: notificationIds,
        });
    },
    markAllAsRead() {
        return axiosService.post(`/api/notifications/mark-as-read`, {
            markAllAsRead: true,
        });
    },
};
