import {
    useClusterEditability,
    useClusterPartitions,
    useUpdateClusterConfigurationMutation,
} from "@/components/Clusters/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { useRefreshAction } from "@/components/PageLayout/useRefreshAction.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { useRouteParams } from "@/modules/router";
import { clusterService } from "@/services/clusterService";
import { getErrorMessage } from "@/utils/state/error.utils";
import React, { useCallback } from "react";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { clusterNodesColumns } from "./ClusterNodesList.columns";

const preferencesType = preferencesTypes.CLUSTER_ADMIN_TABLE;

export const ClusterNodesList = () => {
    useFullWidthTableLayout();
    const routeParams = useRouteParams();
    const {
        locationRouterService,
        overviewAppLocations: { clustersLocation },
        messageService,
    } = useDic();

    useSetPageTitle(routeParams.clusterName);

    const clusterPartitions = useClusterPartitions({
        clusterName: routeParams.clusterName,
    });

    const clusterUpdateMutation = useUpdateClusterConfigurationMutation({
        clusterName: routeParams.clusterName,
    });

    const navigateToList = useCallback(() => {
        locationRouterService.navigate(clustersLocation);
    }, [locationRouterService, clustersLocation]);

    useSetBackButtonAction(navigateToList);

    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort = "uniqueName,asc", filter) =>
            clusterService
                .fetchClusterNodes(routeParams.clusterName, {
                    page,
                    size,
                    sort,
                    filter,
                })
                .catch(e =>
                    messageService.error({
                        content: getErrorMessage(e.response.data),
                    }),
                ),
        [routeParams.clusterName],
    );

    useRefreshAction(reload);

    const mutateContent = (index, name, value, record) => {
        return clusterUpdateMutation
            .mutate({
                typedId: record.typedId,
                uniqueName: record.uniqueName,
                [name]: value,
            })
            .then(reload);
    };

    const onClusterPartitionChange = (index, name, value, record) => {
        mutateContent(index, name, value, record);
    };

    const isClusterEditable = useClusterEditability({
        clusterName: routeParams.clusterName,
    });

    return (
        <>
            <TableWithPreferencesManagement
                columns={clusterNodesColumns({
                    isClusterEditable:
                        isClusterEditable.loadable.valueMaybe()?.isEditable ??
                        false,
                    onClusterPartitionChange,
                    clusterPartitions:
                        clusterPartitions.loadable.valueMaybe() ?? [],
                })}
                defaultSort={{
                    fieldName: "uniqueName",
                    sortDir: "ascending",
                }}
                fixed
                rowKey="uniqueName"
                datasetSlicing="server"
                preferencesType={preferencesType}
                // exportUrl="/api/clusters/export"
                {...tableProps}
                page={tableProps.page}
            />
        </>
    );
};

export default ClusterNodesList;
