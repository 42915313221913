import {
    ButtonMenu,
    Forms,
    Gap,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { t } from "@/translations";
import React from "react";

const {
    pmValidators: { isRequired },
    useForm,
} = Forms;

export const VoteModal = ({
    visible,
    onClose,
    issueKey,
    customers,
    postVote,
    voteSubmitDisabled,
}) => {
    const customerNameOptions = customers.map(customer => ({
        value: customer,
        label: customer,
    }));

    const { formId, handleSubmit } = useForm({
        onSubmit: ({ values }) => {
            postVote({ issueKey, customerName: values.customerName });
        },
    });

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header size={3} title={`Vote for ${issueKey}`} />
                <UnityLayout.Content padding={[true, true]}>
                    <Text>
                        Choose your account to promote this idea. You have one
                        vote per account.
                    </Text>
                    <Gap />
                    <Forms.Form formId={formId} onSubmit={handleSubmit}>
                        <Forms.FieldGroup width="max" inputWidth="max">
                            <Forms.Fields.Select
                                required
                                name="customerName"
                                label={"Account"}
                                placeholder={"Select account"}
                                allowClear={false}
                                options={customerNameOptions}
                                validator={isRequired}
                            />
                        </Forms.FieldGroup>
                    </Forms.Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: "Add Vote",
                                    type: "primary",
                                    formId,
                                    htmlType: "button",
                                    disabled: voteSubmitDisabled,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
