import { useDic } from "@/components/Dic/useDic.hook";
import TemplateDetail from "@/components/Marketplace/components/TemplateDetail/TemplateDetail.component";
import { TemplateDetailGrid } from "@/components/Marketplace/components/TemplateDetailGrid.component";
import { TemplateDetailSidebar } from "@/components/Marketplace/components/TemplateDetailSidebar.component";
import { useTemplate } from "@/components/Marketplace/components/WarningStep/loadableResoures";
import { acceleratorTypeEnum } from "@/constants/accelerators.constants";
import { deployToEnum } from "@/constants/deployment.constants";
import { LoadableRenderer } from "@/modules/loadable";
import { useLoadableAlert } from "@/modules/loadable/useLoadableAlert.hook";
import { useMarketplaceAppParams } from "@/modules/router/hooks/useMarketplaceAppParams.hook";
import { t } from "@/translations";
import { size } from "lodash";
import * as React from "react";

export const deployDestinationByTemplateType = type => {
    switch (type) {
        case acceleratorTypeEnum.INTEGRATION:
            return deployToEnum.INSTANCE;
        case acceleratorTypeEnum.NON_APPROVED:
        case acceleratorTypeEnum.PACKAGE:
        case acceleratorTypeEnum.SOLUTION:
        default:
            return deployToEnum.PARTITION;
    }
};

export const MarketplaceDetailPage = () => {
    const {
        locationRouterService,
        marketplaceAppLocations: { marketplaceLocation },
    } = useDic();
    const { accountId, packageName } = useMarketplaceAppParams();
    const templateResource = useTemplate(packageName, accountId);
    useLoadableAlert({
        loadable: templateResource.loadable,
        hasError: e => ({
            type: "error",
            message: t("templates-management.error.unavailable"),
        }),
    });
    const availableTags = templateResource.loadable.valueMaybe()?.tags ?? [];

    const navigateToCategory = tag => {
        locationRouterService.navigate(marketplaceLocation, {
            accountId,
            tag,
        });
    };

    return (
        <LoadableRenderer
            loadable={templateResource.loadable}
            loading={() => (
                <LoadableRenderer.SkeletonLoader translationKey="" />
            )}
            hasValue={() => {
                return (
                    <TemplateDetailGrid>
                        <TemplateDetail
                            templateResource={templateResource}
                            accountId={accountId}
                        />
                        {size(availableTags) > 0 ? (
                            <TemplateDetailSidebar
                                onTagClick={navigateToCategory}
                                templateResource={templateResource}
                            />
                        ) : null}
                    </TemplateDetailGrid>
                );
            }}
        />
    );
};
