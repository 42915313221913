import { useCopyMutation } from "@/components/Classes/loadables";
import {
    NAME_VALIDATION_PATTERN,
    useClassNameValidator,
} from "@/components/Classes/validations";
import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useMapLoadableMemoized } from "@/modules/loadable";
import { ModalProviderPropsPropType } from "@/modules/modal/CustomModal";
import { t } from "@/translations";
import { map } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import { getCopyClassDialogTrackName } from "../../mixpanel/buttonNames";
import { useAccountProvisionedInstancesQuery } from "@/components/Integrations/hooks/useAccountProvisionedInstancesQuery.hook";

const { useForm, Form, Fields, pmValidators } = Forms;

export const CopyClassModal = ({
    modalProviderProps: { onClose, visible },
    classId,
    accountId,
    onFinish,
}) => {
    const instancesOptionsLoadable = useMapLoadableMemoized(
        useAccountProvisionedInstancesQuery({ accountId }).loadable,
        map(instance => ({
            value: instance.id,
            label: instance.instanceName,
        })),
    );

    const copyMutation = useCopyMutation({
        afterSuccess: () => {
            onClose();
            onFinish?.();
        },
    });

    const { formId, handleSubmit } = useForm({
        onSubmit: ({ values }) => {
            copyMutation.mutate(
                classId,
                values.targetInstanceId,
                values.filename,
            );
        },
    });

    const targetInstanceId = Forms.useFieldValue({
        formId,
        name: "targetInstanceId",
    });
    const nameValidator = useClassNameValidator({
        instanceId: targetInstanceId,
        withOnline: false,
    });

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("class-form-copy.modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Form formId={formId} onSubmit={handleSubmit}>
                        <Fields.Select
                            inputWidth="max"
                            required
                            name="targetInstanceId"
                            label={t("class-form-copy.instance.label")}
                            allowClear={false}
                            showSearch
                            validator={pmValidators.isRequired}
                            {...getLoadableSelectProps(
                                instancesOptionsLoadable,
                            )}
                        />

                        <Fields.Input
                            inputWidth="max"
                            required
                            name="filename"
                            label={t("class-form-copy.class-name.label")}
                            placeholder={t(
                                "class-form-copy.class-name.placeholder",
                            )}
                            validator={Forms.validators.failOnFirst([
                                Forms.pmValidators.isRequired,
                                Forms.pmValidators.notBlank(),
                                Forms.pmValidators.createPatternValidator(
                                    NAME_VALIDATION_PATTERN,
                                    "Name has a wrong format. Valid format is a-z A-Z, 0-9 _ - without spaces",
                                ),
                                nameValidator,
                            ])}
                        />
                    </Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.copy"),
                                    type: "primary",
                                    formId,
                                    track: {
                                        name: getCopyClassDialogTrackName(
                                            "Submit",
                                        ),
                                    },
                                },
                                {
                                    type: "text",
                                    onClick: onClose,
                                    label: t("general.cancel"),
                                    track: {
                                        name: getCopyClassDialogTrackName(
                                            "Cancel",
                                        ),
                                    },
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

CopyClassModal.propTypes = {
    classId: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
    onFinish: PropTypes.func,
    modalProviderProps: ModalProviderPropsPropType(),
};
