import { Tabs } from "@/components/DesignSystem";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useState } from "react";
import { PermissionAdminSettingsContainer } from "./PermissionAdminSettings.container";

const TabPane = Tabs.TabPane;

export const TABS = {
    ASSET_ROLES: "asset-roles",
};

export const AccountPermissionsAdminSettingsComponent = () => {
    const { accountId, activeTab } = useAccountAppParams();
    const [tab, setTab] = useState(activeTab);
    const [visibleInviteModal, setVisibleInviteModal] = useState(false);

    useBreadcrumbButton(
        {
            label: "account-admin-business-roles-list.button.create-business-role",
            onClick: () => setVisibleInviteModal(true),
        },
        [setVisibleInviteModal],
    );

    useSetPageTitle(t("account-admin-business-role-list.business-roles"));

    return (
        <Tabs defaultActiveKey={tab} onChange={setTab}>
            <TabPane
                tab={t("account-admin-business-role-list.business-roles")}
                key={TABS.ASSET_ROLES}
            >
                <PermissionAdminSettingsContainer
                    accountId={accountId}
                    visibleModal={visibleInviteModal}
                    setVisibleModal={setVisibleInviteModal}
                />
            </TabPane>
        </Tabs>
    );
};

AccountPermissionsAdminSettingsComponent.propTypes = {};
