import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { useReloadUserPermissions } from "@/security/hooks/useReloadUserPermissions.hook";

export const useAccountGroupsResource = ({ accountId }) => {
    const { adminApiService } = useDic();
    return useQueryLoadable(
        async () =>
            adminApiService.fetchProjectGroups(accountId).then(r => r.data),
        [accountId, adminApiService],
    );
};

export const useDeleteGroupResource = ({ accountId, afterSuccess }) => {
    const { adminApiService } = useDic();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async group =>
            adminApiService
                .deleteProjectGroup(accountId, group.id)
                .then(currentAfterSuccess),
        [accountId, adminApiService, currentAfterSuccess],
        "user-admin-settings.command.deleteGroup.success",
        "user-admin-settings.command.deleteGroup.error",
    );
};

export const useAddGroupResource = ({ accountId, afterSuccess }) => {
    const { adminApiService } = useDic();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async group =>
            adminApiService
                .createProjectGroup(accountId, group)
                .then(currentAfterSuccess),
        [accountId, adminApiService, currentAfterSuccess],
        "user-admin-settings.command.addGroup.success",
        "user-admin-settings.command.addGroup.error",
    );
};

export const useEditGroupResource = ({ accountId, afterSuccess }) => {
    const { adminApiService } = useDic();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async group =>
            adminApiService
                .updateProjectGroup(accountId, group)
                .then(currentAfterSuccess),
        [accountId, adminApiService, currentAfterSuccess],
        "user-admin-settings.command.editGroup.success",
        "user-admin-settings.command.editGroup.error",
    );
};

export const useRemoveUserFromGroupResource = ({ accountId, afterSuccess }) => {
    const { adminApiService } = useDic();
    const reloadUserPermissions = useReloadUserPermissions();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async (groupId, userId) =>
            adminApiService
                .removeUserFromProjectGroup(accountId, groupId, userId)
                .then(d => {
                    currentAfterSuccess(d);
                    reloadUserPermissions();
                }),
        [
            accountId,
            adminApiService,
            currentAfterSuccess,
            reloadUserPermissions,
        ],
        "user-admin-settings.command.removeUserFromGroup.success",
        "user-admin-settings.command.removeUserFromGroup.error",
    );
};

export const useRemoveUsersFromGroupResource = ({
    accountId,
    afterSuccess,
}) => {
    const { adminApiService } = useDic();
    const reloadUserPermissions = useReloadUserPermissions();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async (groupId, userIds) => {
            const deleteUser = userId =>
                adminApiService.removeUserFromProjectGroup(
                    accountId,
                    groupId,
                    userId,
                );
            await userIds.reduce(async (acc, userId) => {
                await acc;
                return deleteUser(userId);
            }, Promise.resolve());
            currentAfterSuccess();
            reloadUserPermissions();
        },
        [
            accountId,
            adminApiService,
            currentAfterSuccess,
            reloadUserPermissions,
        ],
        "user-admin-settings.command.removeUsersFromGroup.success",
        "user-admin-settings.command.removeUsersFromGroup.error",
    );
};

export const useInviteUsersResource = ({ accountId, afterSuccess }) => {
    const { adminApiService } = useDic();
    const reloadUserPermissions = useReloadUserPermissions();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async (groupId, users) =>
            adminApiService
                .inviteUsersToGroup(accountId, groupId, users)
                .then(d => {
                    currentAfterSuccess(d);
                    reloadUserPermissions();
                }),
        [
            accountId,
            adminApiService,
            reloadUserPermissions,
            currentAfterSuccess,
        ],
        "user-admin-settings.command.inviteUsers.success",
        "user-admin-settings.command.inviteUsers.error",
    );
};
