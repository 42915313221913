import { message } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useDic } from "@/components/Dic/useDic.hook";
import { SecurityContext } from "@/security/authorization";
import {
    ACCOUNT_REPORTS_EDIT_PERMISSIONS,
    includesPermissionInAssetTree,
} from "@/security/permission.utils";
import { t, T } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { useFetchPage } from "../PagableTable/useFetchPage.hook";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { reportsColumns } from "./ReportsList.columns";
import { reportsServices } from "./services/reports.service";

const preferencesType = preferencesTypes.REPORTS_LIST_TABLE;

const ReportsList = ({ accountId, setButtonData, cleanButtonData }) => {
    const {
        locationRouterService,
        accountAppLocations: {
            accountReportsEditLocation,
            accountReportsNewLocation,
        },
    } = useDic();
    const securityContext = useContext(SecurityContext);

    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort, filter) =>
            reportsServices.fetchReports(page, size, sort, filter, accountId),
        [accountId],
    );

    useEffect(() => {
        setButtonData(
            "reports-form.button.new",
            () =>
                locationRouterService.navigate(accountReportsNewLocation, {
                    accountId: accountId,
                }),
            ["reports.edit"],
            { permissionsFunc: includesPermissionInAssetTree },
        );
        return cleanButtonData;
    }, []);

    function executeReport(reportId) {
        reportsServices
            .executeReport(reportId)
            .then(() => {
                message.info(t("reports-list.action.execute.successful"));
            })
            .catch(() => {
                message.info(t("reports-list.action.execute.failed"));
            });
    }

    const handleDelete = useCallback(
        record => {
            reportsServices.delete(record.id).then(() => {
                message.success(
                    <T
                        id="general.message.deleted"
                        values={{
                            type: "rule",
                            name: record.name,
                        }}
                    />,
                );
                reload();
            });
        },
        [accountId, reload],
    );

    const actionMenu = useCallback(
        record =>
            includesPermissionInAssetTree(
                securityContext,
                ACCOUNT_REPORTS_EDIT_PERMISSIONS,
            ) && (
                <ActionButton
                    record={record}
                    items={[
                        {
                            title: t("reports-list.action.execute"),
                            onClick: () => executeReport(record.id),
                        },
                        {
                            title: t("general.tooltip.edit"),
                            onClick: () =>
                                locationRouterService.navigate(
                                    accountReportsEditLocation,
                                    {
                                        reportId: record.id,
                                    },
                                ),
                        },
                        {
                            title: t("general.modal.delete.title", {
                                type: "Scheduled Report",
                            }),
                            confirmMessage: t("general.modal.delete.message", {
                                name: record.name,
                                type: "Scheduled Report",
                            }),
                            onConfirm: handleDelete,
                            color: "red",
                        },
                    ]}
                />
            ),
        [
            accountReportsEditLocation,
            handleDelete,
            locationRouterService,
            securityContext,
        ],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            defaultSort={{
                fieldName: "name",
                sortDir: "ascending",
            }}
            columns={reportsColumns()}
            fixed
            rowKey="id"
            datasetSlicing="server"
            preferencesType={preferencesType}
            exportUrl={`/api/reports/table/export?accountId=${accountId}`}
            {...tableProps}
        />
    );
};

ReportsList.propTypes = {
    accountId: PropTypes.number,
    setButtonData: PropTypes.func.isRequired,
    cleanButtonData: PropTypes.func.isRequired,
};

export default ReportsList;
