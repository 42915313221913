export const GenericFormInputTypes = {
    INT: "int",
    STRING: "string",
    SELECT: "select",
    MULTI_SELECT: "multi_select",
    CHECKBOX: "checkbox",
    PFX_DATA: "pfxData",
    PFX_DATA_FIELD: "pfxDataField",
    PFX_DATA_SELECT: "pfxDataTypeSelect",
};
