import { Menu, Popover, Table } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { getTypeLabel } from "@/components/Packages/packagesPanel.utils";
import { T } from "@/translations";
import { CaretDownOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Dropdown } from "antd";
import map from "lodash/map";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { ParsedType } from "../../constants";
import styles from "./DataPreviewTable.style.less";

export const defaultPreviewCellProps = {
    type: fieldTypes.TEXT,
    width: "150px",
};
const warningByTypes = {
    [ParsedType.NUMBER]: {
        title: (
            <T id="parsing-warning.type-parsing" values={{ type: "Number" }} />
        ),
        content: (
            <>
                <div>
                    <T
                        id="parsing-warning.is-type-in-correct-format"
                        values={{ type: "number" }}
                    />
                </div>
                <div>
                    <T id="parsing-warning.set-decimal-separator" />
                </div>
            </>
        ),
    },
    [ParsedType.DATE]: {
        title: (
            <T id="parsing-warning.type-parsing" values={{ type: "Date" }} />
        ),
        content: (
            <>
                <div>
                    <T
                        id="parsing-warning.is-type-in-correct-format"
                        values={{ type: "date" }}
                    />
                </div>
                <div>
                    <T id="parsing-warning.set-date-format" />
                </div>
            </>
        ),
    },
    [ParsedType.DATE_TIME]: {
        title: (
            <T
                id="parsing-warning.type-parsing"
                values={{ type: "DateTime" }}
            />
        ),
        content: (
            <>
                <div>
                    <T
                        id="parsing-warning.is-type-in-correct-format"
                        values={{ type: "date-time" }}
                    />
                </div>
                <div>
                    <T id="parsing-warning.set-date-time-format" />
                </div>
            </>
        ),
    },
};

const ExampleDataHeaderCell = ({
    name,
    type,
    setColumnType,
    typeOptions = [],
}) => {
    const typeLabel = getTypeLabel(type);
    const typeEl =
        setColumnType && typeOptions.length ? (
            <Dropdown
                trigger={["click"]}
                // visible={visible}
                // onVisibleChange={setVisible}
                overlay={
                    <Menu>
                        {typeOptions.map(type => (
                            <Menu.Item
                                key={type}
                                onClick={() => setColumnType({ name, type })}
                            >
                                {getTypeLabel(type)}
                            </Menu.Item>
                        ))}
                    </Menu>
                }
            >
                <div
                    // onClick={() => setVisible(true)}
                    style={{
                        display: "inline-flex",
                        alignItems: "center",
                        color: "#0078A2",
                        cursor: "pointer",
                    }}
                >
                    {typeLabel}
                    &nbsp;
                    <CaretDownOutlined />
                </div>
            </Dropdown>
        ) : (
            typeLabel
        );
    const popoverProps = warningByTypes[type];
    const popover = popoverProps ? (
        <Popover title={popoverProps.title} content={popoverProps.content}>
            <ExclamationCircleFilled className={styles.icon} />
        </Popover>
    ) : null;

    return (
        <div style={{ whiteSpace: "nowrap" }}>
            {name}
            <div className={styles.type}>
                {typeEl}&nbsp;{popover}
            </div>
        </div>
    );
};

const createColumn =
    ({ showTypes, changableColumnTypes, setColumnType }) =>
    ({ name, type }) => ({
        ...defaultPreviewCellProps,
        label: showTypes ? (
            <ExampleDataHeaderCell
                name={name}
                type={type}
                typeOptions={changableColumnTypes?.[name]}
                setColumnType={setColumnType}
            />
        ) : (
            name
        ),
        name: name,
        render: value => `${value}`,
    });

const convertToRows = (columns = [], valuesProp = () => "parsedValues") => {
    const rowsCount = columns[0]?.originalValues?.length ?? 0;
    const getRow = (columns, index) =>
        Object.fromEntries(
            columns.map(column => [
                column.name,
                column[valuesProp(column)][index],
            ]),
        );
    return Array(rowsCount)
        .fill()
        .map((_, index) => getRow(columns, index));
};

const getValuesProp = changableColumnTypes => column =>
    column.type === ParsedType.STRING && changableColumnTypes?.[column.name]
        ? "originalValues"
        : "parsedValues";

export const DataPreviewTable = ({
    dataExample,
    changableColumnTypes,
    setColumnType,
    showTypes = true,
    customColumns = createColumn({
        showTypes,
        changableColumnTypes,
        setColumnType,
    }),
}) => {
    const dataSource = convertToRows(
        dataExample.columns,
        changableColumnTypes ? getValuesProp(changableColumnTypes) : undefined,
    );
    const columns = useMemo(
        () => map(dataExample.columns, customColumns),
        [customColumns, dataExample.columns],
    );

    return (
        <Table
            className={showTypes ? "pmTable--headerWithTwoRows" : undefined}
            bordered
            columns={columns}
            dataSource={dataSource}
            rowKey={() => Math.random().toString(36)}
            padding={false}
            pagination={{
                pageSize: Number.MAX_SAFE_INTEGER,
                pageSizeOptions: [],
                totalRows: dataExample.numberOfRows,
            }}
            hasColumnResizing
        />
    );
};

DataPreviewTable.propTypes = {
    dataExample: PropTypes.shape({
        numberOfRows: PropTypes.number,
        fileName: PropTypes.string,
    }),
    setColumnType: PropTypes.func,
    changableColumnTypes: PropTypes.objectOf(
        PropTypes.arrayOf(PropTypes.string),
    ),
    showTypes: PropTypes.bool,
    customColumns: PropTypes.func,
};
