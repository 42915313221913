export const toParams = obj => {
    return Object.entries(obj)
        .filter(([key, val]) => val)
        .map(
            ([key, val]) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
        )
        .join("&");
};

export const buildPageableUrl = (url, page, size, sort) =>
    sort
        ? `${url}?page=${page}&size=${size}&sort=${sort}`
        : `${url}?page=${page}&size=${size}`;
