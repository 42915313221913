import { useCallback, useState } from "react";

const initialSelectedSuperAssets = () => [];

export const useSuperAssetsSelection = afterClick => {
    const [selectedSuperAssets, setSelectedSuperAssets] = useState(
        initialSelectedSuperAssets(),
    );
    const onSuperAssetClick = useCallback(
        record => {
            setSelectedSuperAssets([record.assetType]);
            afterClick();
        },
        [afterClick],
    );
    const onSuperAssetsSelection = useCallback(
        selection => {
            setSelectedSuperAssets(selection);
        },
        [setSelectedSuperAssets],
    );
    const noSuperAssetsSelected = !selectedSuperAssets.length;
    const resetSuperAssets = useCallback(
        () => setSelectedSuperAssets(initialSelectedSuperAssets()),
        [],
    );

    return {
        selectedSuperAssets,
        onSuperAssetsSelection,
        onSuperAssetClick,
        noSuperAssetsSelected,
        resetSuperAssets,
    };
};
