import { alertRuleEntityType } from "@/components/AlertRules/services/alertRules.service";
import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";
import AlertSeverityFormatter from "../Alerts/AlertSeverityFormatter";

export const alertRulesColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("alert-rules-list.header.name"),
        name: "name",
        className: "pmTable__column--withActionButton",
    },
    {
        type: fieldTypes.OPTION,
        label: t("alert-rules-list.header.severity"),
        name: "severity",
        options: [
            { value: null, label: "All" },
            { value: "CRITICAL", label: "Critical" },
            { value: "HIGH", label: "High" },
            { value: "MEDIUM", label: "Medium" },
            { value: "LOW", label: "Low" },
        ],
        render: (text, record) => {
            return <AlertSeverityFormatter value={record.severity} />;
        },
    },
    {
        type: fieldTypes.OPTION,
        label: t("alert-rules-list.header.entity-type"),
        name: "entityType",
        options: [
            { value: null, label: "All" },
            {
                value: alertRuleEntityType.PARTITION,
                label: t("alert-rules-list.entity.partition"),
            },
            {
                value: alertRuleEntityType.INTEGRATION,
                label: t("alert-rules-list.entity.integration"),
            },
            {
                value: alertRuleEntityType.ACCELERATOR,
                label: t("alert-rules-list.entity.accelerator"),
            },
        ],
        render: (text, record) => {
            switch (record.entityType) {
                case alertRuleEntityType.PARTITION:
                    return t("alert-rules-list.entity.partition");
                case alertRuleEntityType.INTEGRATION:
                    return t("alert-rules-list.entity.integration");
                case alertRuleEntityType.ACCELERATOR:
                    return t("alert-rules-list.entity.accelerator");
            }
        },
    },
    {
        type: fieldTypes.OPTION,
        label: t("alert-rules-list.header.enabled"),
        name: "enabled",
        options: [
            { value: null, label: "All" },
            { value: false, label: "Disabled" },
            { value: true, label: "Enabled" },
        ],
        render: (text, record) => {
            return record.enabled
                ? t("alert-rules-list.filled.enabled")
                : t("alert-rules-list.filled.disabled");
        },
    },
    {
        type: fieldTypes.TEXT,
        label: t("alert-rules-list.header.definition"),
        name: "definition",
    },
    {
        type: fieldTypes.TEXT,
        label: t("alert-rules-list.header.threshold"),
        name: "threshold",
    },
    {
        type: fieldTypes.TEXT,
        label: t("alert-rules-list.header.cron"),
        name: "cron",
    },
    {
        type: fieldTypes.DATETIME,
        label: t("alert-rules-list.header.lastExecutionTime"),
        name: "lastExecutionTime",
        render: (text, record) => {
            return record.lastExecutionTime ? (
                <FormattedDateTime>
                    {record.lastExecutionTime}
                </FormattedDateTime>
            ) : (
                "Never"
            );
        },
    },
    {
        type: fieldTypes.DATETIME,
        label: t("alert-rules-list.header.updated-at"),
        name: "updatedAt",
        render: (text, record) => {
            return record.updatedAt ? (
                <FormattedDateTime>{record.updatedAt}</FormattedDateTime>
            ) : (
                "Never"
            );
        },
    },
    {
        type: fieldTypes.TEXT,
        label: t("alert-rules-list.header.created-by"),
        name: "createdBy",
    },
    {
        type: fieldTypes.TEXT,
        label: t("alert-rules-list.header.updated-by"),
        name: "updatedBy",
    },
    {
        type: fieldTypes.TEXT,
        label: t("alert-rules-list.header.recipients"),
        name: "recipients",
    },
    {
        type: fieldTypes.TEXT,
        label: t("alert-rules-list.header.webhook"),
        name: "webHook",
    },
];
