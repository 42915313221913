import { Button, Forms, H3 } from "@/components/DesignSystem";
import { T, t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

const { SubmitButton, useForm, Form, Fields, pmValidators } = Forms;

export const ActivateAccountForm = ({ username, onSubmit, onCancel }) => {
    const { formId, handleSubmit } = useForm({
        onSubmit,
    });

    return (
        <>
            <H3>
                <T id="user-activation.header" />
            </H3>
            <Form formId={formId} onSubmit={handleSubmit}>
                <Fields.Input
                    label={t("user-form.label.username")}
                    name="username"
                    autoComplete="off"
                    initialValue={username}
                    disabled
                />

                <Fields.Input
                    label={t("user-form.label.first-name")}
                    name="firstName"
                    required
                    validator={pmValidators.isRequired}
                    autoFocus
                />

                <Fields.Input
                    name="lastName"
                    label={t("user-form.label.last-name")}
                    required
                    validator={pmValidators.isRequired}
                />

                <Fields.Input
                    name="jobTitle"
                    label={t("user-form.label.job-title")}
                />

                <Fields.RestrictedPassword
                    name="password"
                    label={t("user-form.label.password")}
                    placeholder="password"
                    autoComplete="new-password"
                    required
                />

                <SubmitButton
                    type="primary"
                    label={t("user-activation.action")}
                />

                <Button
                    type="text"
                    htmlType="button"
                    onClick={onCancel}
                    label={t("user-activation.back-to-login-page")}
                />
            </Form>
        </>
    );
};

ActivateAccountForm.propTypes = {
    username: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
