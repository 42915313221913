import { axiosService } from "../axios";
import { EntityType } from "../constants/CommonConstats";

export const elkQueryService = {
    baseQueryIntegration(accountId, entityId) {
        return `/api/project/${accountId}/instance/${entityId}`;
    },

    baseQueryPartition(accountId, entityId) {
        return `/api/project/${accountId}/partition/${entityId}`;
    },

    baseQueryProject(accountId) {
        return `/api/project/${accountId}`;
    },

    baseQuery(accountId, entityId, entityType) {
        if (accountId) {
            switch (entityType) {
                case EntityType.INTEGRATION:
                    return this.baseQueryIntegration(accountId, entityId);
                case EntityType.PARTITION:
                    return this.baseQueryPartition(accountId, entityId);
                case EntityType.PROJECT:
                    return this.baseQueryProject(accountId);
            }
        } else {
            return "/api/admin";
        }
    },

    fetchElkQueries(accountId, entityId, entityType) {
        return axiosService.get(
            `${this.baseQuery(accountId, entityId, entityType)}/elk-query`,
        );
    },

    processElkQuery(accountId, entityId, entityType, query) {
        return axiosService.post(
            `${this.baseQuery(accountId, entityId, entityType)}/elk-query`,
            query,
        );
    },

    deleteElkQuery(accountId, entityId, entityType, queryId) {
        return axiosService.delete(
            `${this.baseQuery(
                accountId,
                entityId,
                entityType,
            )}/elk-query/${queryId}`,
        );
    },

    fetchUsers(accountId, entityId, entityType) {
        return axiosService.get(
            `${this.baseQuery(
                accountId,
                entityId,
                entityType,
            )}/elk-query/users`,
        );
    },

    isValid(accountId, entityId, entityType, query) {
        return axiosService.post(
            `${this.baseQuery(
                accountId,
                entityId,
                entityType,
            )}/elk-query/is-valid`,
            query,
        );
    },
};
