import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { useValueVisibility } from "@/components/hooks/useValueVisibility.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { noop } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import EditStepModal from "./EditStep.modal";

export const columns = [
    {
        name: "stepName",
        label: t("template.edit-form.steps-table.step"),
        type: fieldTypes.TEXT,
    },
];

export const StepsManagement = ({ templateName }) => {
    const { templatesReplacementService } = useDic();
    const templateStepsResource = useQueryLoadable(
        async () =>
            templatesReplacementService
                .getSteps(templateName)
                .then(getData)
                .then(steps =>
                    steps.reduce(
                        (result, stepName) => [...result, { stepName }],
                        [],
                    ),
                ),
        [templateName, templatesReplacementService],
    );

    const editModal = useValueVisibility();

    const actionMenu = useMemo(
        () => record =>
            (
                <ActionButton
                    record={record}
                    items={[
                        {
                            title: t("general.edit"),
                            onClick: record => editModal.show(record.stepName),
                            // permission: [EDIT_PERMISSION],
                        },
                    ]}
                />
            ),
        [editModal],
    );

    return (
        <>
            <div style={{ maxWidth: "519px" }}>
                <TableWithPreferencesManagement
                    actionMenu={actionMenu}
                    dataSource={
                        templateStepsResource.loadable.valueMaybe() ?? []
                    }
                    rowKey="stepName"
                    datasetSlicing="local"
                    columns={columns}
                    preferencesType={
                        preferencesTypes.TEMPLATES_PACKAGE_STEPS_MANAGEMENT
                    }
                />
            </div>
            <EditStepModal
                templateName={templateName}
                stepName={editModal.value}
                onSave={noop}
                onCancel={editModal.hide}
                visible={editModal.visible}
            />
        </>
    );
};

StepsManagement.propTypes = {
    templateName: PropTypes.string.isRequired,
};
