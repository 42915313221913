import {
    ButtonMenu,
    Gap,
    Modal,
    Table,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useWithPending } from "@/components/hooks/useWithPending.hook";
import { pendingPromise, useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { isNil } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { getRestartingRoutesModalTrackName as getTrackName } from "../../../../mixpanel/buttonNames";

const columns = [
    {
        name: "name",
        label: t("instances.restarting-routes-modal.columns.routes"),
    },
    {
        name: "prn",
        label: t("instances.restarting-routes-modal.columns.prn"),
    },
];

export const useRestartingRoutesQuery = (instanceId, changedItems) => {
    const { instanceService } = useDic();
    return useQueryLoadable(
        async () =>
            !isNil(changedItems)
                ? instanceService
                      .getDeployInfo(instanceId, changedItems)
                      .then(getData)
                      .then(response => response.affectedRoutes)
                : pendingPromise(),
        [changedItems, instanceId, instanceService],
    );
};

export const RestartingRoutesModal = ({
    affectedRoutes,
    visible,
    onConfirm,
    onCancel,
}) => {
    const confirmWithPending = useWithPending(onConfirm);

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("instances.restarting-routes-modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Text>
                        {t("instances.restarting-routes-modal.description")}
                    </Text>
                    <Gap />
                    <Table
                        rowKey="_id"
                        columns={columns}
                        fixedHeight={325}
                        pagination={false}
                        padding={false}
                        dataSource={affectedRoutes}
                    />
                    <Gap />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.restart"),
                                    type: "primary",
                                    onClick: confirmWithPending.handler,
                                    disabled: confirmWithPending.pending,
                                    track: { name: getTrackName("Restart") },
                                },
                                {
                                    label: t("general.cancel"),
                                    type: "text",
                                    onClick: onCancel,
                                    track: { name: getTrackName("Cancel") },
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

RestartingRoutesModal.propTypes = {
    affectedRoutes: PropTypes.array.isRequired,
    visible: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
};
