import uuid from "uuid/v4";
import { useCallback } from "react";
import { useStateReinitialize } from "../../../../Mappers/MapperTableWithCustomization/MapperGridForm/hooks/useStateReinitialize.hook";

export const useRowIds = ({ reinitRowIds }) => {
    const [rowIds, setRowIds] = useStateReinitialize(reinitRowIds);
    const addRow = useCallback(
        () => setRowIds(rowIds => [...rowIds, uuid()]),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    const deleteRow = useCallback(
        rowId => setRowIds(rowIds => rowIds.filter(id => id !== rowId)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return {
        rowIds,
        addRow,
        deleteRow,
    };
};
