import { MAPPER_DIRECTION } from "@/components/ISVMapping/steps/General.step";
import { defaultVendors } from "@/components/Mappers/MapperTableWithCustomization/MapperTableWithCustomization";
import {
    hasValue,
    loadableFromMaybeValue,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { useMemo } from "react";
import { mapperMappingService } from "../../../../services/mapperMapping.service";
import { addComputableMapperProperties } from "../../form/mapper.utils";
import { useValidatedMapperState } from "./useValidatedMapperState.hook";

export const useMapperStateWithInitialization = ({
    vendors = defaultVendors,
    direction = MAPPER_DIRECTION.UPLOAD,
    entityType,
    entityName,
    initMapper,
    businessKey,
    inputOptionsLoadable,
    outputOptionsLoadable,
    tableData,
}) => {
    const optionsLoadables = useComposeLoadablesMemoized([
        inputOptionsLoadable,
        outputOptionsLoadable,
    ]);

    const maybeMapperWithDefault = useMemo(() => {
        if (!hasValue(optionsLoadables)) return undefined;
        const [inputOptions, outputOptions] = optionsLoadables.valueMaybe();

        const initMapperWithDefault = initMapper
            ? // mapper from somewhere (api, cache, ...)
              addComputableMapperProperties(
                  initMapper,
                  outputOptions,
                  tableData,
              )
            : // Create default mapper
              mapperMappingService.mapOptionsToMetadata(
                  inputOptions,
                  outputOptions,
                  direction,
              );
        return initMapperWithDefault;
    }, [outputOptionsLoadable, initMapper, tableData]);

    const { mapper, setMapper, errors } = useValidatedMapperState({
        vendors,
        entityType,
        entityName,
        businessKey,
        initMapper: maybeMapperWithDefault,
        inputOptionsMaybe: inputOptionsLoadable.valueMaybe(),
        outputOptionsMaybe: outputOptionsLoadable.valueMaybe(),
        tableData,
    });

    const loadableMapper = useMemo(
        () => loadableFromMaybeValue(mapper),
        [mapper],
    );

    return { mapper, setMapper, errors, loadableMapper };
};
