import { tap } from "@/components/Connections/loadables";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { reportsServices } from "@/components/Reports/services/reports.service";
import {
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { getUserTimezone } from "@/utils/timezone/timezone.utils";
import moment from "moment-timezone";

export const useInitialValuesQuery = ({ reportId }) => {
    return useQueryLoadable(
        async () =>
            !reportId
                ? Promise.resolve({
                      enabled: true,
                      time: moment(),
                      timezone: getUserTimezone(),
                  })
                : reportsServices
                      .get(reportId)
                      .then(getData)
                      .then(({ recipients, time, ...report }) => ({
                          ...report,
                          recipients: recipients ? recipients.split(",") : [],
                          time: moment(time, "HH:mm:ss"),
                          service: report.entityId
                              ? `${report.entityType}|${report.entityId}`
                              : undefined,
                      })),
        [reportId],
    );
};

export const useServicesOptionsQuery = ({
    accountId,
    canFetch = !!accountId,
}) => {
    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : reportsServices
                      .fetchServices(accountId)
                      .then(getData)
                      .then(({ integrations }) => [
                          {
                              label: "Integrations",
                              options: integrations.map(({ id, label }) => ({
                                  value: `INTEGRATION_MANAGER_INSTANCE|${id}`,
                                  label,
                              })),
                          },
                          // There is only one report type for now which is used for integrations
                          // {
                          //     type: "group",
                          //     label: "Partitions",
                          //     options: partitionsSelector(partitions)
                          // }
                      ]),
        [accountId, canFetch],
    );
};

export const useSaveReportMutation = ({ afterSuccess }) => {
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async values =>
            reportsServices
                .save(values)
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent],
        ({ name }) => `The Scheduled Report ${name} was saved.`,
        // () => `The Scheduled Report ${values.name} wasn't saved.`
    );
};
