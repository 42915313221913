import { Alert, Button } from "@/components/DesignSystem";
import Progress from "@/components/Progress/Progress";
import { useCancelTaskMutation } from "@/modules/async-tasks/hooks";
import { isLoading } from "@/modules/loadable";
import { fromPairs, map, pipe, values } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";

export const TASK_STATUS = {
    CREATED: "CREATED",
    STARTED: "STARTED",
    DONE: "DONE",
    FAILED: "FAILED",
    CANCELLED: "CANCELLED",
};

export const TaskResult = ({ task, components, props }) => {
    // console.log(
    //     "%c[TaskResult.rndr]",
    //     "color:coral;",
    //     task.status,
    //     task.progress,
    //     { task },
    // );
    const cancelMutation = useCancelTaskMutation({ jobId: task.jobId });

    const Component = {
        [TASK_STATUS.CREATED]: TaskInProgress,
        [TASK_STATUS.STARTED]: TaskInProgress,
        // [TASK_STATUS.DONE]: TaskDone,
        [TASK_STATUS.FAILED]: TaskFailed,
        [TASK_STATUS.CANCELLED]: TaskFailed,
        ...components,
    }[task.status];
    const componentProps = {
        [TASK_STATUS.CANCELLED]: { defaultMessage: "Task cancelled" },
        ...props,
    }[task.status];

    if (!Component) return <pre>{JSON.stringify(task, null, 2)}</pre>;

    return (
        <Component
            task={task}
            cancelMutation={cancelMutation}
            {...componentProps}
        />
    );
};

TaskResult.propTypes = {
    task: PropTypes.object.isRequired,
    components: PropTypes.shape(
        pipe(
            values,
            map(status => [status, PropTypes.elementType]),
            fromPairs,
        )(TASK_STATUS),
    ),
    props: PropTypes.shape(
        pipe(
            values,
            map(status => [status, PropTypes.object]),
            fromPairs,
        )(TASK_STATUS),
    ),
};

const TaskFailed = ({ task, defaultMessage = "Oops!" }) => {
    return (
        <Alert
            showIcon
            type="error"
            message={task.errorMessage ?? defaultMessage}
        />
    );
};

const renderCancelButton = ({ cancelMutation }) => (
    <Button
        label="Cancel"
        disabled={isLoading(cancelMutation)}
        onClick={() => cancelMutation.mutate()}
    />
);

const TaskInProgress = ({
    task,
    cancelMutation,

    message,
    renderCancelAlert = renderCancelButton,
}) => {
    const percent = task.progress || 0;

    return (
        <>
            {task.allowedToInterrupt && renderCancelAlert({ cancelMutation })}
            <Progress percent={percent} message={message} showInfo />
        </>
    );
};
