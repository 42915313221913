import { reduce, toNumber } from "lodash";
import curryN from "lodash/fp/curryN";
import { parse, SymbolNode } from "mathjs";

const DEFAULT_RESULT_VALUE = 0;

export const calculateScope = data => {
    return reduce(
        data,
        (result, row) => {
            const value = toNumber(row.example);
            result[row.key] = value;
            result[row.key.toLowerCase()] = value;
            return result;
        },
        {},
    );
};

export const calculate = (formula, scope) => {
    if (formula) {
        try {
            const root = parse(formula);
            root.forEach(isNodeSupported);
            const result = root.compile().evaluate(scope);
            if (typeof result === "object") throw new Error("Evaluation error");
            return [result, null];
        } catch (e) {
            return [DEFAULT_RESULT_VALUE, e.message];
        }
    }
    return [DEFAULT_RESULT_VALUE, "Cannot be empty"];
};

export const extractFormulaSymbols = formula =>
    parse(formula)
        .filter(node => node.isSymbolNode)
        .map(({ name }) => name);

export const renameFormulaSymbols = curryN(2, (formula, oldToNewSymbols) => {
    const root = parse(formula);
    root.forEach(isNodeSupported);

    return root
        .transform(node =>
            node.isSymbolNode && oldToNewSymbols[node.name]
                ? new SymbolNode(oldToNewSymbols[node.name])
                : node,
        )
        .toString();
});

const isNodeSupported = node => {
    switch (node.type) {
        case "OperatorNode":
        case "ConstantNode":
        case "SymbolNode":
        case "ParenthesisNode":
            break;
        default:
            throw new Error("Unsupported node: " + node);
    }
};
