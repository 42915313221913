import ErrorAlert from "@/components/Alerts/ErrorAlert";
import { Col, Row } from "@/components/DesignSystem";
import { Button } from "@/components/DesignSystem/Button";
import { Gap } from "@/components/DesignSystem/Gap";
import { Image } from "@/components/DesignSystem/Image";
import { Text } from "@/components/DesignSystem/Text/Text";
import { useDic } from "@/components/Dic/useDic.hook";
import { ApprovalType } from "@/components/WorkFlow/WorkflowApprovals";
import { useRouteParams } from "@/modules/router";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import relaxLogo from "../../../assets/img/relax.svg";
import styles from "./styles.less";

const getResult = (state, isTitle) => {
    switch (state.action) {
        case ApprovalType.APPROVED:
            return t("general.approved").toLowerCase();
        case ApprovalType.DECLINED:
            return t("general.rejected").toLowerCase();
        default:
            return isTitle
                ? t("workflow-approval-page.action-taken")
                : t("workflow-approval-page.repeated action");
    }
};

export const Approval = ({ onToPlatformManager }) => {
    const { hash, action } = useRouteParams();

    const { axiosService } = useDic();

    const [state, setResult] = useState({ action: null, error: null });

    useEffect(() => {
        axiosService
            .post(`/api/public/approvals`, {
                hash,
                action: action.toUpperCase(),
            })
            .then(res => setResult({ action: res.data, error: null }))
            .catch(e =>
                setResult({
                    action: null,
                    error: getErrorMessage(e.response.data),
                }),
            );
    }, []);

    return (
        <div>
            {state.error ? (
                <ErrorAlert visible={state.error} message={state.error} />
            ) : (
                <Row type="flex" align="middle" justify="center">
                    <Col>
                        <div className={styles.container}>
                            <Image src={relaxLogo} alt="Approval action" />
                            <Gap size="x-large" />
                            <Gap size="x-large" />
                            <Text size="large" type="title">
                                {t("workflow-approval-page.title", {
                                    action: getResult(state, true),
                                })}
                            </Text>
                            <Gap size="small" />
                            <Text>
                                {t("workflow-approval-page.description", {
                                    action: getResult(state, false),
                                })}
                            </Text>
                            <Gap size="x-large" />
                            <Button
                                type="primary"
                                onClick={onToPlatformManager}
                                label={t("workflow-approval-page.button.to-pm")}
                            />
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    );
};

Approval.propTypes = {
    onToPlatformManager: PropTypes.func.isRequired,
};
