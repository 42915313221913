import { HEADER_WITH_PAGE_TITLE } from "@/components/DesignSystem/Table/constants";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { useRefreshAction } from "@/components/PageLayout/useRefreshAction.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { convertFromToCriteria } from "@/components/UpgradeScheduling/ClusterUpgradeOverviewTable";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import PropTypes from "prop-types";
import qs from "qs";
import React, { useMemo, useState } from "react";
import { axiosService } from "../../axios";
import { UpgradeSlotsDetailPanel } from "./UpgradeSlotsDetailPanel.component";
import { upgradeSlotsOverviewColumns } from "./UpgradeSlotsOverviewTable.columns";
import { FILTER_HEIGHT } from "./UpgradeSlotsPage";

const preferencesType = preferencesTypes.UPGRADE_SLOTS_TABLE;
const DEFAULT_FILTER = {};

const UpgradeSlotsOverviewTable = ({ filter = DEFAULT_FILTER }) => {
    const dateRangeFilterCriteria = useMemo(() => {
        const { timeFrom: { value: [from, to] = [] } = {} } = filter;

        return convertFromToCriteria({
            from,
            to,
            fieldName: "timeFrom",
        });
    }, [filter]);

    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort = "name,asc", tableFilter) =>
            axiosService.post(
                `/api/available-times/table${qs.stringify(
                    { page, size, sort },
                    {
                        addQueryPrefix: true,
                    },
                )}`,
                tableFilter,
            ),
        [filter],
    );
    useRefreshAction(reload);

    const [selectedRecord, setSelectedRecord] = useState(null);

    return (
        <>
            <TableWithPreferencesManagement
                fixed
                datasetSlicing="server"
                defaultSort={{
                    fieldName: "timeFrom",
                    sortDir: "ascending",
                }}
                restHeight={HEADER_WITH_PAGE_TITLE + FILTER_HEIGHT}
                columns={upgradeSlotsOverviewColumns({
                    onDetail: setSelectedRecord,
                })}
                rowKey="id"
                preferencesType={preferencesType}
                exportUrl="/api/available-times/table/export"
                {...tableProps}
                subsetFilter={{
                    criteria: dateRangeFilterCriteria,
                    operator: "and",
                    _constructor: "AdvancedCriteria",
                }}
            />
            <UpgradeSlotsDetailPanel
                record={selectedRecord}
                onClose={() => setSelectedRecord(null)}
            />
        </>
    );
};

UpgradeSlotsOverviewTable.propTypes = {
    filter: PropTypes.object,
};

export default UpgradeSlotsOverviewTable;
