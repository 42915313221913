import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { AdminRightsDrawer } from "./AdminRightsDrawer";
import {
    useAdminUserRightsResource,
    useAdminUserWorkflowRightsResource,
    useSaveAdminRights,
} from "./loadables";
import { ENTITY_KIND } from "./PermissionsDrawer/sources.utils";

export const AdminUserRightsDrawer = React.memo(function AdminUserRightsDrawer({
    userIds = [],
    onClose,
    visible = true,
    displaySuperAssets,
}) {
    const rightsResource = useAdminUserRightsResource({
        canFetch: visible && !!userIds.length,
        userIds,
    });
    const workflowRightsResource = useAdminUserWorkflowRightsResource({
        canFetch: visible && !!userIds.length,
        userIds,
    });
    const afterSave = useCallback(() => {
        rightsResource.reload();
        onClose();
    }, [onClose, rightsResource]);
    const saveRightsResource = useSaveAdminRights({
        afterSave,
    });
    const assetsWithRightsFetchParams = useMemo(
        () => ({
            userIds,
        }),
        [JSON.stringify(userIds)],
    );

    return (
        <AdminRightsDrawer
            entityKind={ENTITY_KIND.USER}
            selectedEntities={userIds}
            assetsWithRightsFetchParams={assetsWithRightsFetchParams}
            rightsResource={rightsResource}
            workflowRightsResource={workflowRightsResource}
            saveRightsResource={saveRightsResource}
            onClose={onClose}
            visible={visible}
            displaySuperAssets={displaySuperAssets}
        />
    );
});

AdminUserRightsDrawer.propTypes = {
    userIds: PropTypes.arrayOf(PropTypes.number.isRequired),
    onClose: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    displaySuperAssets: PropTypes.bool,
};
