import {
    useConnectionsQuery,
    useDeleteConnectionMutation,
    useDeployConnectionMutation,
} from "@/components/Connections/loadables";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_HEIGHT_WITH_BREADCRUMB } from "@/components/DesignSystem/Table/constants";
import { useInstanceQuery } from "@/components/IntegrationRoutes/loadables";
import { useWorkspaceInstanceDeletionAlert } from "@/components/Integrations/InstanceEntityWorkspaceAlert/InstanceEntityWorkspaceAlert";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { isLoading } from "@/modules/loadable";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import { getErrorMessageFromError } from "@/utils/state/error.utils";
import { message } from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { getConnectionsListTrackName as getTrackName } from "../../mixpanel/buttonNames";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { connectionsListColumns } from "./ConnectionsList.columns";
import { CopyConnectionDialogContainer } from "./CopyConnectionDialogContainer";

const preferencesType = preferencesTypes.CONNECTIONS_LIST_TABLE;
const initialConnections = [];

export default function ConnectionsList({
    instanceId,
    accountId,
    visible,
    onEdit,
    reloadToken,
    afterDeploy,
}) {
    const instanceQuery = useInstanceQuery({ instanceId });
    useWorkspaceInstanceDeletionAlert({
        instanceQuery,
        visible,
        isConnection: true,
    });
    const connectionsQuery = useConnectionsQuery({
        instanceId,
        reloadToken,
        onError: e => message.error(getErrorMessageFromError(e)),
    });
    const deleteConnectionMutation = useDeleteConnectionMutation({
        instanceId,
        afterSuccess: connectionsQuery.reload,
    });
    const deployConnectionMutation = useDeployConnectionMutation({
        instanceId,
        afterSuccess: afterDeploy,
    });

    const dataSource =
        connectionsQuery.loadable.valueMaybe() || initialConnections;
    const loading =
        isLoading(connectionsQuery) ||
        isLoading(deleteConnectionMutation) ||
        isLoading(deployConnectionMutation);
    const columns = useMemo(
        () => connectionsListColumns(dataSource),
        [dataSource],
    );

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.tooltip.edit"),
                        onClick: onEdit,
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                        track: { name: getTrackName("Edit") },
                    },
                    {
                        title: t("general.deploy"),
                        onClick: deployConnectionMutation.mutate,
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                        track: { name: getTrackName("Deploy") },
                    },
                    {
                        title: t("connection-list.copy.title"),
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                        customModal: (
                            <CopyConnectionDialogContainer
                                accountId={accountId}
                                instanceId={instanceId}
                                record={record}
                                afterSuccess={connectionsQuery.reload}
                            />
                        ),
                        track: { name: getTrackName("Copy") },
                    },
                    {
                        title: t("general.modal.delete.title", {
                            type: "connection",
                        }),
                        confirm: {
                            message: t("general.modal.delete.message", {
                                type: "connection",
                                name: record.name,
                            }),
                            onConfirm: deleteConnectionMutation.mutate,
                        },
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                        color: "red",
                        track: { name: getTrackName("Delete") },
                    },
                ]}
            />
        ),
        [
            accountId,
            connectionsQuery.reload,
            deleteConnectionMutation.mutate,
            deployConnectionMutation.mutate,
            instanceId,
            onEdit,
        ],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            dataSource={dataSource}
            fixed
            restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
            loading={loading}
            rowKey="id"
            columns={columns}
            datasetSlicing="local"
            preferencesType={preferencesType}
            visible={visible}
        />
    );
}

ConnectionsList.propTypes = {
    accountId: PropTypes.number.isRequired,
    instanceId: PropTypes.number.isRequired,
    onEdit: PropTypes.func.isRequired,
    reloadToken: PropTypes.any,
    afterDeploy: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};
