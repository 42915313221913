import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { useRequestEngSupportUserMutation } from "@/components/RequestEngSupportUser/loadables";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

const { useForm, Form, Fields, pmValidators } = Forms;

export const RequestEngSupportUserModal = ({
    visible,
    onClose,
    partitionId,
}) => {
    const requestEngSupportUserMutation = useRequestEngSupportUserMutation({
        partitionId,
        afterSuccess: onClose,
    });

    const { formId, handleSubmit } = useForm({
        onSubmit: ({ values }) => requestEngSupportUserMutation.mutate(values),
    });
    const pending = isLoading(requestEngSupportUserMutation);

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("partition.request-eng-support-user.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Form
                        formId={formId}
                        layout="vertical"
                        onSubmit={handleSubmit}
                    >
                        <Fields.Input
                            required
                            name="reason"
                            label={t(
                                "partition.request-eng-support-user.reason",
                            )}
                            validator={pmValidators.isRequired}
                        />
                        <Fields.DatePicker
                            name="expiration"
                            label={t(
                                "partition.request-eng-support-user.expiration",
                            )}
                            showTime
                            validator={pmValidators.isRequired}
                            required={true}
                        />
                    </Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.create"),
                                    type: "primary",
                                    formId,
                                    loading: pending,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                    disabled: pending,
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

RequestEngSupportUserModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    partitionId: PropTypes.number,
};
