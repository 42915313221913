import { axiosService } from "../axios";

export const sftpEntityType = {
    DATA_UPLOAD: "DATA_UPLOAD",
    DATA_DOWNLOAD: "DATA_DOWNLOAD",
    ISV_ENABLE: "ISV_ENABLE",
};

const BASE_URL = `/api/data-sftp`;
export const dataLoadSftpService = {
    listSftpUsers({ type = sftpEntityType.NEVER, dataLoadId }) {
        return axiosService.get(`${BASE_URL}/${type}/${dataLoadId}/sftp-users`);
    },

    createUser({ type = sftpEntityType.NEVER, dataLoadId, values }) {
        return axiosService.post(
            `${BASE_URL}/${type}/${dataLoadId}/sftp-users`,
            values,
        );
    },
    patchUsers({ type = sftpEntityType.NEVER, dataLoadId, payload }) {
        return axiosService.patch(
            `${BASE_URL}/${type}/${dataLoadId}/sftp-users`,
            payload,
        );
    },
    getUser({ type = sftpEntityType.NEVER, dataLoadId, username }) {
        return axiosService.get(
            `${BASE_URL}/${type}/${dataLoadId}/sftp-users/${username}`,
        );
    },
    updateUser({ type = sftpEntityType.NEVER, dataLoadId, username, values }) {
        return axiosService.post(
            `${BASE_URL}/${type}/${dataLoadId}/sftp-users/${encodeURIComponent(
                username,
            )}`,
            values,
        );
    },
    deleteUser({ type = sftpEntityType.NEVER, dataLoadId, username }) {
        return axiosService.delete(
            `${BASE_URL}/${type}/${dataLoadId}/sftp-users/${username}`,
        );
    },
    getUserPrefix({ type = sftpEntityType.NEVER, dataLoadId }) {
        return axiosService.get(
            `${BASE_URL}/${type}/${dataLoadId}/sftp-user-prefix`,
        );
    },
    getSFTPConfig({ type = sftpEntityType.NEVER, dataLoadId }) {
        return axiosService.get(
            `${BASE_URL}/${type}/${dataLoadId}/sftp-config`,
        );
    },
};
