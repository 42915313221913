import { stepsPlans } from "@/components/Packages/PackageTableDefinitionPanel/stepsPlans";
import isEmpty from "lodash/isEmpty";
import { getDepGlobalState } from "../../../../views/Package/package.utils";
import { logger } from "@/modules/logger";

export const ON_NEXT = "ON_NEXT";
export const ON_BACK = "ON_BACK";
export const CHANGE_STATE = "CHANGE_STATE";
export const INIT_GLOBAL_STATE = "INIT_GLOBAL_STATE";
export const FINISH_INIT = "FINISH_INIT";

export const initialState = apiResult => {
    const stepsPlan = stepsPlans[apiResult.currentStepData?.type](
        createStepPlanOptions(apiResult, {}),
    );

    return {
        planIndex: 0,
        global: {},
        componentsState: {},
        stepsPlan,
        processFinished: false,
        componentToShow: stepsPlan[0],
        showComponent: false,
    };
};

export const reducer = (oldState, action) => {
    logger.debug({
        logGroupKey: ["PACKAGES", "PackageDefinition", "reducer"],
        msg: action.type,
        color: "lime",
        data: { action, oldState },
    });
    switch (action.type) {
        case FINISH_INIT: {
            return {
                ...oldState,
                showComponent: true,
            };
        }

        case INIT_GLOBAL_STATE: {
            const newState = {
                ...oldState,
                global: action.data,
                showComponent: true,
            };
            return newState;
        }

        case ON_NEXT: {
            const { componentsState, global, planIndex } = oldState;
            if (!isEmpty(action.componentState)) {
                componentsState[oldState.componentToShow] =
                    action.componentState;
            }

            const newGlobal = action.globalState
                ? { ...global, ...action.globalState }
                : global;

            const newPlanIndex = planIndex + 1;
            const newStepsPlan = stepsPlans[
                action.apiResult.currentStepData?.type
            ](createStepPlanOptions(action.apiResult, newGlobal));
            const processFinished = newPlanIndex >= newStepsPlan.length;

            return {
                ...oldState,
                planIndex: newPlanIndex,
                global: newGlobal,
                stepsPlan: newStepsPlan,
                componentsState,
                processFinished,
                componentToShow: processFinished
                    ? null
                    : newStepsPlan[newPlanIndex],
            };
        }

        case ON_BACK: {
            const planIndex = oldState.planIndex - 1;

            return {
                ...oldState,
                planIndex,
                componentToShow: oldState.stepsPlan[planIndex],
            };
        }
        case CHANGE_STATE: {
            return {
                ...oldState,
                // TODO: check that no effect rely on unchanged "global" and "componentsState" refs and remove ternary ops
                global: action.globalState
                    ? { ...oldState.global, ...action.globalState }
                    : oldState.global,
                componentsState: !isEmpty(action.componentState)
                    ? {
                          ...oldState.componentsState,
                          [oldState.componentToShow]: {
                              //   ...oldState.componentsState[oldState.componentToShow],
                              ...action.componentState,
                          },
                      }
                    : oldState.componentsState,
            };
        }
        default:
            return oldState;
    }
};

const createStepPlanOptions = (apiResult, globalState) => ({
    step: apiResult?.currentStepData,
    processGlobalState: getDepGlobalState(apiResult) || {},
    globalState,
});
