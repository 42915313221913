import { labelsByStep } from "@/components/Packages/PackageTableDefinitionPanel/constants";
import get from "lodash/get";
import keys from "lodash/keys";
import split from "lodash/split";
import startsWith from "lodash/startsWith";

export const FINISHED = "FINISHED";
export const WAITING_FOR_UI = "WAITING_FOR_UI";
export const FINISHED_WITH_ERROR = "FINISHED_WITH_ERROR";
export const RUNNING = "RUNNING";
export const WAITING_FOR_DATA_LOAD = "WAITING_FOR_DATA_LOAD";
export const FINISHED_WITH_ROLLBACK = "FINISHED_WITH_ROLLBACK";

export const STEP_FULL_NAME_DELIMITER = "::";

export const isPackageRunning = processState =>
    !!processState &&
    ![FINISHED, FINISHED_WITH_ROLLBACK, FINISHED_WITH_ERROR].includes(
        processState,
    );

export const getStepLabel = type => {
    const l = get(labelsByStep, type);
    return l ? l : "package.message.default";
};

export const packageFinished = state =>
    FINISHED === state || FINISHED_WITH_ROLLBACK === state;

export const waitingForUi = state => WAITING_FOR_UI === state;

export const finishedWithError = state => FINISHED_WITH_ERROR === state;

export const deploymentIsRunning = state =>
    RUNNING === state || WAITING_FOR_DATA_LOAD === state;

export const getCurrentStep = apiResult => get(apiResult, "currentStep");

export const getDependencyNameFromApiResult = apiResult =>
    getStepDependencyName(getCurrentStep(apiResult));

export const getNotSkippedStepNames = apiResult => {
    const depName = getDependencyNameFromApiResult(apiResult);
    const stateMap = get(apiResult, ["state", "stateMap"]);
    return keys(stateMap)
        .filter(k => startsWith(k, depName))
        .filter(k => !stateMap[k].skipped)
        .map(k => split(k, STEP_FULL_NAME_DELIMITER)[1]);
};

export const getStepDependencyName = stepId =>
    split(stepId, STEP_FULL_NAME_DELIMITER)[0] + STEP_FULL_NAME_DELIMITER;

export const getDepGlobalState = apiResult => {
    const depName = getDependencyNameFromApiResult(apiResult);
    return get(apiResult, ["state", depName]);
};

export const formatDate = (dateString, delimiter = ".") => {
    if (dateString) {
        const date = new Date(dateString);
        return (
            date.getDate() +
            delimiter +
            (date.getMonth() + 1) +
            delimiter +
            date.getFullYear()
        );
    }
    return dateString;
};
