import { useDic } from "@/components/Dic/useDic.hook";
import { FilterForm } from "@/components/Filters/form/FilterForm.component";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import UserSecurityContentContext from "@/security/UserSecurityContentContext";
import { t } from "@/translations";
import React, { useCallback } from "react";

export const NewFilterPage = () => {
    const { accountId, instanceId } = useAccountAppParams();
    useSetPageTitle(t("filter-form.title"));

    const {
        locationRouterService,
        accountAppLocations: { filtersLocation, filterEditLocation },
    } = useDic();

    const goToFilterList = useCallback(
        () => locationRouterService.navigate(filtersLocation),
        [locationRouterService, filtersLocation],
    );
    return (
        <UserSecurityContentContext projectId={accountId}>
            <FilterForm
                filterId="new"
                instanceId={instanceId}
                onSave={({ withDeploy, filterId }) => {
                    withDeploy
                        ? goToFilterList()
                        : locationRouterService.navigate(filterEditLocation, {
                              filterId,
                          });
                }}
                onBack={goToFilterList}
            />
        </UserSecurityContentContext>
    );
};
