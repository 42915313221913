import { useRef } from "react";
import { useDetailDrawerState } from "./useDetailDrawerState.hook";

export const useOneVisible = count => {
    const countRef = useRef(count);
    const controls = Array(countRef.current)
        .fill(null)
        .map(useDetailDrawerState);
    const showOne = control => record => {
        controls.forEach(c => c.id !== control.id && c.record && c.hide());
        return control.show(record);
    };
    const coupled = controls.map(control => ({
        ...control,
        show: showOne(control),
    }));

    return coupled;
};
