import {
    generateLocationsStack,
    getLocationTranslationId,
} from "@/components/Breadcrumb/Breadcrumbs";
import { Button, ButtonGroup, Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import {
    LoadableRenderer,
    hasValue,
    isLoading,
    useMapLoadableMemoized,
} from "@/modules/loadable";
import { UserContext } from "@/security/UserContext";
import { t } from "@/translations";
import { concat, join, keys, map, prop } from "lodash/fp";
import pipe from "lodash/fp/flow";
import isEmpty from "lodash/fp/isEmpty";
import isNil from "lodash/fp/isNil";
import negate from "lodash/fp/negate";
import pickBy from "lodash/fp/pickBy";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
    useAccounts,
    useListOfDefaultRoutes,
    useUserPreferencesMutation,
    useUserPreferencesQuery,
} from "./DefaultRoute.loadables";
import { logger } from "@/modules/logger";
import { naturallySortBy } from "@/utils/form.utils";
import { useAlertEffect } from "@/components/PageLayout/useAlertEffect.hook";
import { isProduction } from "@/utils/env.utils";

const { useForm, Form, Fields, SubmitButton, pmValidators } = Forms;

const NONEXISTENT_ROUTE_TITLE = "Removed from PM frontend";

const getLabelMaybe = location =>
    !location
        ? undefined
        : pipe(
              generateLocationsStack,
              map(pipe(prop("routeName"), getLocationTranslationId, t)),
              join(" > "),
          )(location);

const getOption = lrs => routeName => {
    const locationMaybe = lrs.getLocationByRouteName(routeName);
    const labelMaybe = getLabelMaybe(locationMaybe);

    return {
        label: labelMaybe || `${routeName} [invalid]`,
        value: routeName,
        disabled: !locationMaybe,
        title: !locationMaybe ? NONEXISTENT_ROUTE_TITLE : undefined,
    };
};

const DefaultRouteForm = () => {
    const { locationRouterService: lrs } = useDic();
    const { refetchUser } = useContext(UserContext);
    const userPreferencesQuery = useUserPreferencesQuery();
    const userPreferencesMutation = useUserPreferencesMutation();
    const defaultRoutes = useListOfDefaultRoutes();
    const accounts = useAccounts();
    const [selectedRouteName, setSelectedRouteName] = useState(null);

    useEffect(() => {
        if (hasValue(userPreferencesQuery)) {
            setSelectedRouteName(
                userPreferencesQuery.loadable.contents.defaultPage?.page,
            );
        }
    }, [userPreferencesQuery.loadable.state]);

    const isLinkedToAccountId =
        hasValue(defaultRoutes) &&
        defaultRoutes.loadable.contents[selectedRouteName]?.linkedToAccountId;

    const accountsOptions =
        hasValue(accounts) && isLinkedToAccountId
            ? accounts.loadable.contents
                  .filter(
                      account =>
                          defaultRoutes.loadable.contents[
                              selectedRouteName
                          ]?.accountIds.includes(account.id) || false,
                  )
                  .map(account => ({
                      label: account.name,
                      value: account.id,
                  }))
            : [];

    const loadableRoutesOptions = useMapLoadableMemoized(
        defaultRoutes.loadable,
        useCallback(
            pipe(keys, map(getOption(lrs)), naturallySortBy(prop("label"))),
            [],
        ),
    );
    const missingRouteKeysMaybe = loadableRoutesOptions.contents
        ?.filter?.(({ title }) => title === NONEXISTENT_ROUTE_TITLE)
        ?.map(({ value }) => value);
    const translatedErrorMsg =
        !isProduction() && missingRouteKeysMaybe?.length
            ? t("defaultRouteForm.error.missingRouteKeys", {
                  missing:
                      missingRouteKeysMaybe?.map(k => `"${k}"`)?.join(", ") ||
                      "",
              })
            : "";
    useEffect(() => {
        if (translatedErrorMsg) {
            logger.debug({
                msg: translatedErrorMsg,
                logGroupKey: ["Profile", "DefaultRouteForm", "rndr"],
                color: "deepskyblue",
                data: { missingRouteKeysMaybe },
            });
        }
    }, [missingRouteKeysMaybe, translatedErrorMsg]);
    useAlertEffect(translatedErrorMsg, () => ({
        type: "error",
        message: translatedErrorMsg,
        closable: false,
    }));

    const { formId, handleSubmit, getBag } = useForm({
        onSubmit: async ({
            values: { routeName, showAllAlertOptions, ...routeParams },
        }) => {
            const newPreference = {
                showAllAlertOptions,
                defaultPage: pipe(pickBy(negate(isNil)), obj =>
                    isEmpty(obj) ? undefined : obj,
                )({
                    page: routeName,
                    ...routeParams,
                }),
            };
            userPreferencesMutation.mutate(newPreference).then(refetchUser);
        },
    });

    return (
        <LoadableRenderer
            loadable={userPreferencesQuery.loadable}
            hasValue={userPreference => {
                return (
                    <Form
                        formId={formId}
                        layout="vertical"
                        onSubmit={handleSubmit}
                    >
                        <Fields.Select
                            tooltip={t(
                                "defaultRouteForm.defaultRoute.description",
                            )}
                            name="routeName"
                            allowClear
                            showSearch
                            onClear={() => setSelectedRouteName(null)}
                            onChange={async () => {
                                const { values } = await getBag();

                                setSelectedRouteName(values.routeName);
                            }}
                            initialValue={userPreference.defaultPage?.page}
                            label={t("defaultRouteForm.defaultRoute.label")}
                            placeholder={t(
                                "defaultRouteForm.defaultRoute.placeholder",
                            )}
                            {...getLoadableSelectProps(loadableRoutesOptions)}
                        />
                        {accountsOptions.length > 0 &&
                            selectedRouteName &&
                            isLinkedToAccountId && (
                                <Fields.Select
                                    required
                                    name="accountId"
                                    allowClear
                                    showSearch
                                    label={t(
                                        "defaultRouteForm.accountId.label",
                                    )}
                                    placeholder={t(
                                        "defaultRouteForm.accountId.placeholder",
                                    )}
                                    initialValue={
                                        userPreference.defaultPage?.accountId
                                    }
                                    loading={isLoading(accounts)}
                                    options={accountsOptions}
                                    validator={pmValidators.isRequired}
                                />
                            )}
                        <Fields.Checkbox
                            name="showAllAlertOptions"
                            label="Show All Alert Options" // TODO: missing in design
                            initialValue={userPreference.showAllAlertOptions}
                        />
                        <ButtonGroup>
                            <SubmitButton
                                disabled={
                                    isLoading(accounts) ||
                                    isLoading(userPreferencesMutation)
                                }
                            >
                                <Button
                                    label={t("general.save")}
                                    type="primary"
                                    htmlType="submit"
                                />
                            </SubmitButton>
                        </ButtonGroup>
                    </Form>
                );
            }}
        />
    );
};

export default DefaultRouteForm;
