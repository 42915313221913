import { getObjectKey } from "@/services/certificates.service";
import qs from "qs";
import { axiosService } from "../axios";

export const CONNECTION_TYPE_PRICEFX_CLIENT = "pricefx-client";

const qsOptions = { addQueryPrefix: true };

export const classService = {
    copy(classId, targetInstanceId, filename) {
        return axiosService.post(`/api/im-classes/${classId}/copy`, {
            targetInstanceId,
            filename,
        });
    },

    createClass(payload, query) {
        const queryStr = qs.stringify(query, qsOptions);
        return axiosService.post(`/api/im-classes${queryStr}`, payload);
    },

    saveClass(classId, payload, query) {
        const queryStr = qs.stringify(query, qsOptions);
        return axiosService.post(
            `/api/im-classes/${classId}${queryStr}`,
            payload,
        );
    },

    fetchClasses(instanceId) {
        return axiosService.get(`/api/instances/${instanceId}/im-classes`);
    },

    fetchClass(classId) {
        return axiosService.get(`/api/im-classes/${classId}`);
    },

    deleteClass(classId) {
        return axiosService.delete(`/api/im-classes/${classId}`);
    },

    deployClass(instanceId, classId, query) {
        const queryStr = qs.stringify(instanceId, query, qsOptions);
        return axiosService.post(
            `/api/instances/${instanceId}/classes/${classId}/deploy${queryStr}`,
        );
    },

    fetchInstanceRepoClasses(instanceId) {
        return axiosService.get(
            `/api/instances/${instanceId}/im-classes/online`,
        );
    },

    createLocalCopy(objectKey, payload) {
        return axiosService.post(
            `/api/im-classes/${objectKey}/online-copy`,
            payload,
        );
    },

    deleteInstanceRepoClass(prn, objectKey) {
        const payload = prn ? { prn: prn } : null;
        return axiosService.delete(`/api/im-classes/${objectKey}/online`, {
            data: payload,
        });
    },

    classExists({ instanceId, name, withOnline = true }) {
        const objectKey = getObjectKey({ name, instanceId });
        return axiosService.get(
            `/api/im-classes/${objectKey}/exists${qs.stringify(
                { name, withOnline },
                qsOptions,
            )}`,
        );
    },
};
