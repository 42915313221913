import { DataObject } from "@/utils/dataObjects/DataObject.class";

export class PermissionEntity extends DataObject {
    /**
     *
     * @type {string}
     */
    name = null;
    /**
     *
     * @type {string}
     */
    description = null;
    /**
     *
     * @type {boolean}
     */
    global = null;
    /**
     *
     * @type {string} AssetType
     */
    type = null;
}
