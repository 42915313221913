import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { DownloadForm } from "@/components/PartitionDataManagement/Downloads/DownloadForm";
import { PDM_TABS } from "@/components/PartitionDataManagement/PartitionDataManagementPage";
import {
    useDownloadQuery,
    useEditDownloadMutation,
    useTriggerDownloadMutation,
} from "@/components/PartitionDataManagement/loadables";
import { LoadableRenderer } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React, { useCallback } from "react";

export const EditDownloadPage = () => {
    const { accountId, partitionId, downloadId } = useAccountAppParams();
    const {
        locationRouterService,
        accountAppLocations: { partitionDataManagementLocation },
    } = useDic();
    const onCancel = useCallback(
        () =>
            locationRouterService.navigate(partitionDataManagementLocation, {
                tab: PDM_TABS.DOWNLOADS,
            }),
        [],
    );
    useSetBackButtonAction(onCancel);
    const query = useDownloadQuery({
        accountId,
        partitionId,
        downloadId,
    });
    const saveMutation = useEditDownloadMutation({
        accountId,
        partitionId,
        downloadId,
        afterSuccess: onCancel,
    });
    const triggerMutation = useTriggerDownloadMutation({
        accountId,
        partitionId,
    });
    const onExport = useCallback(
        async payload => {
            await saveMutation.mutate(payload);
            await triggerMutation.mutate({ id: downloadId });
        },
        [downloadId, saveMutation, triggerMutation],
    );

    return (
        <LoadableRenderer
            loadable={query.loadable}
            hasValue={initialValues => (
                <DownloadForm
                    accountId={accountId}
                    partitionId={partitionId}
                    initialValues={initialValues}
                    onSave={saveMutation.mutate}
                    onExport={onExport}
                    onCancel={onCancel}
                />
            )}
        />
    );
};
