import { Forms, Gap, UnityHeading } from "@/components/DesignSystem";
import { GreyForm } from "@/components/ISVMapping/GreyForm.component";
import { mapVendorToVendorName } from "@/components/ISVMapping/helpers";
import { MAPPER_DIRECTION } from "@/components/ISVMapping/steps/General.step";
import { SOURCE_TYPE } from "@/components/Mappers/ExportMapper/EntitySelectFields";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import {
    useEntityNamesQuery,
    useSupportedEntityTypesQuery,
} from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/loadables";
import { hasSubFilter } from "@/constants/SupportedTableImportTypes";
import { t } from "@/translations";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import React from "react";

const PricefxEntity = ({ setValues, isvConnection }) => {
    const entityTypesQuery = useSupportedEntityTypesQuery({
        sourceType: SOURCE_TYPE.PARTITIONS,
        sourceId: isvConnection.partitionId,
    });
    const formId = Forms.useFormId();

    const entityType = useFieldValue({ formId, name: "pfxEntityType" });

    const entityNamesQuery = useEntityNamesQuery({
        entityType,
        sourceType: SOURCE_TYPE.PARTITIONS,
        sourceId: isvConnection.partitionId,
    });

    const entityNameRequired = hasSubFilter(entityType);
    return (
        <GreyForm>
            <UnityHeading size={4}>
                {t(
                    "isv-connections.data-mapping.step.entities.send-empty-value-as.entity",
                    {
                        vendorName: "Pricefx",
                    },
                )}
            </UnityHeading>
            <Forms.FieldGroup grid>
                <Forms.Fields.Select
                    required
                    name="pfxEntityType"
                    label={t(
                        "isv-connections.data-mapping.step.entities.send-empty-value-as.entity-type",
                        {
                            vendorName: "Pricefx",
                        },
                    )}
                    allowClear={false}
                    showSearch
                    onChange={() => {
                        setValues({ pfxEntityName: undefined });
                    }}
                    validator={Forms.pmValidators.isRequired}
                    {...getLoadableSelectProps(entityTypesQuery.loadable)}
                />
                {entityNameRequired && (
                    <Forms.Fields.Select
                        required
                        name="pfxEntityName"
                        label={t(
                            "isv-connections.data-mapping.step.entities.send-empty-value-as.table",
                            {
                                vendorName: "Pricefx",
                            },
                        )}
                        allowClear={false}
                        showSearch
                        validator={Forms.pmValidators.isRequired}
                        {...getLoadableSelectProps(entityNamesQuery.loadable)}
                    />
                )}
            </Forms.FieldGroup>
        </GreyForm>
    );
};

const VendorEntity = ({ isvConnection }) => {
    return (
        <GreyForm>
            <UnityHeading size={4}>
                {t(
                    "isv-connections.data-mapping.step.entities.send-empty-value-as.entity",
                    {
                        vendorName: mapVendorToVendorName(isvConnection.vendor),
                    },
                )}
            </UnityHeading>
            <Forms.FieldGroup grid>
                <Forms.Fields.Input
                    required
                    name="baseFilePattern"
                    label={t(
                        "isv-connections.data-mapping.step.entities.send-empty-value-as.table",
                        {
                            vendorName: mapVendorToVendorName(
                                isvConnection.vendor,
                            ),
                        },
                    )}
                    placeholder="Please specify file pattern"
                    validator={Forms.pmValidators.isRequired}
                />
            </Forms.FieldGroup>
        </GreyForm>
    );
};

const ArrowDown = () => {
    return (
        <>
            <Gap size="small" />
            ↓
            <Gap size="small" />
        </>
    );
};

const EntitiesStep = ({ form, allStepsProps }) => {
    const { isvConnection } = allStepsProps;

    const formId = Forms.useFormId();

    const entityType = useFieldValue({ formId, name: "type" });

    if (!entityType) return null;
    return (
        <>
            <div style={{ maxWidth: "578px" }}>
                {entityType === MAPPER_DIRECTION.UPLOAD ? (
                    <>
                        <VendorEntity isvConnection={isvConnection} />
                        <ArrowDown />
                        <PricefxEntity
                            setValues={form.setValues}
                            isvConnection={isvConnection}
                        />
                    </>
                ) : (
                    <>
                        <PricefxEntity
                            setValues={form.setValues}
                            isvConnection={isvConnection}
                        />
                        <ArrowDown />
                        <VendorEntity isvConnection={isvConnection} />
                    </>
                )}
            </div>
        </>
    );
};

export default EntitiesStep;
