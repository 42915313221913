export const deployPackageStateEnum = {
    error: "error",
    init: "init",
    stepSkipDecision: "stepSkipDecision",
    stepDefinition: "stepDefinition",
    stepIsRunning: "stepIsRunning",
    stepFinished: "stepFinished",
    packageDeployed: "packageDeployed",
};

export const inErrorState = state => state === deployPackageStateEnum.error;
export const inInitState = state => state === deployPackageStateEnum.init;
export const inStepSkipDecision = state =>
    state === deployPackageStateEnum.stepSkipDecision;
export const inStepDefinitionState = state =>
    state === deployPackageStateEnum.stepDefinition;
export const inStepIsRunningState = state =>
    state === deployPackageStateEnum.stepIsRunning;
export const inStepFinishedState = state =>
    state === deployPackageStateEnum.stepFinished;
export const inPackageDeployedState = state =>
    state === deployPackageStateEnum.packageDeployed;
