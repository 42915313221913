import { cancelable } from "cancelable-promise";
import { useEffect, useRef } from "react";

const HTTP_CONFLICT = 409;

export const usePoll = ({
    asyncFn,
    onSuccess,
    onError,
    onConflict,
    interval,
    runImmediately = true,
    canStart = true,
}) => {
    const promiseRef = useRef();
    const intervalIdRef = useRef(null);

    const clear = () => {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
        promiseRef.current?.cancel();
    };

    const onSuccessRef = useRef(onSuccess);
    useEffect(() => {
        onSuccessRef.current = onSuccess;
    }, [onSuccess]);
    const onErrorRef = useRef(onError);
    useEffect(() => {
        onErrorRef.current = onError;
    }, [onError]);
    const onConflictRef = useRef(onConflict);
    useEffect(() => {
        onConflictRef.current = onConflict;
    }, [onConflict]);

    useEffect(() => {
        const run = () => {
            promiseRef.current = cancelable(asyncFn())
                .then(result => {
                    promiseRef.current = undefined;
                    result === undefined
                        ? onErrorRef.current("Error.")
                        : onSuccessRef.current(result);
                })
                .catch(e => {
                    promiseRef.current = undefined;
                    e?.response?.status === HTTP_CONFLICT
                        ? onConflictRef.current(e.response)
                        : onErrorRef.current(e);
                });
        };
        const wait = () => {
            if (intervalIdRef.current === null) {
                intervalIdRef.current = setInterval(run, interval);
            }
        };

        if (intervalIdRef.current !== null) {
            clear();
        }

        if (runImmediately) run();
        if (canStart) wait();

        return clear;
    }, [interval, asyncFn, runImmediately, canStart]);
};
