import { Button, ButtonGroup, Forms, H3, P } from "@/components/DesignSystem";
import { t } from "@/translations";
import React from "react";

const { useForm, Form, Fields, SubmitButton, validators, pmValidators } = Forms;

const createPurposeOptions = (purposeList = []) =>
    purposeList?.map(purpose => ({
        label: t(`partition-request.radio.purpose.${purpose.toLowerCase()}`),
        value: purpose,
    }));

export const PartitionMovePage = ({
    accountId,
    partitionId,
    clustersList,
    onCancel,
    onPurposeChange,
    onSubmit,
    purposeList,
}) => {
    const submit = ({ values }) => {
        onSubmit({ ...values, accountId });
    };

    const { formId, handleSubmit, setValues } = useForm({
        onSubmit: submit,
    });

    const handlePurposeChange = event => {
        setValues({ clusterId: undefined });
        onPurposeChange(event);
    };

    return (
        <>
            <H3>{t("partition-move.title")}</H3>
            <P>{t("partition-move.info")}</P>
            <Form formId={formId} onSubmit={handleSubmit}>
                <Fields.Select
                    required
                    name="purpose"
                    allowClear={false}
                    label={t("partition-request.label.purpose")}
                    onChange={handlePurposeChange}
                    options={createPurposeOptions(purposeList)}
                    placeholder={t("partition-request.placeholder.purpose")}
                    showSearch
                    validator={pmValidators.isRequired}
                />

                <Fields.Select
                    required
                    disabled={!clustersList.length}
                    name="clusterId"
                    label={t("partition-request.label.cluster")}
                    options={clustersList?.map(cluster => ({
                        label: cluster.optionLabel,
                        value: cluster.id,
                    }))}
                    placeholder={t("partition-request.placeholder.cluster")}
                    validator={pmValidators.isRequired}
                />

                <ButtonGroup>
                    <SubmitButton>
                        <Button
                            label={t("partition-request.button.submit")}
                            type="primary"
                            htmlType="submit"
                        />
                    </SubmitButton>
                    <Button
                        type="text"
                        onClick={onCancel}
                        label={t("general.cancel")}
                    />
                </ButtonGroup>
            </Form>
        </>
    );
};
