import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { useRefreshAction } from "@/components/PageLayout/useRefreshAction.hook";
import { t } from "@/translations";
import React from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";

const preferencesType = preferencesTypes.UPDATES_LIST_TABLE;

const columns = [
    {
        type: fieldTypes.TEXT,
        label: t("updates-list.header.id"),
        name: "id",
    },
    {
        type: fieldTypes.TEXT,
        label: t("updates-list.header.url"),
        name: "newestVersionUrl",
    },
    {
        type: fieldTypes.TEXT,
        label: t("updates-list.header.name"),
        name: "newestVersionName",
    },
    {
        type: fieldTypes.TEXT,
        label: t("updates-list.header.build-id"),
        name: "buildId",
    },
];

const UpdatesList = () => {
    const { registryService } = useDic();

    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort = "id,asc", filter) =>
            registryService.getUpdates({
                queryParams: { page, size, sort, filter },
            }),
        [registryService],
    );

    useRefreshAction(reload);

    return (
        <TableWithPreferencesManagement
            columns={columns}
            defaultSort={{
                fieldName: "id",
                sortDir: "ascending",
            }}
            fixed
            rowKey="id"
            datasetSlicing="server"
            preferencesType={preferencesType}
            exportUrl="api/registry/update-info/export"
            {...tableProps}
        />
    );
};

UpdatesList.propTypes = {};

export default UpdatesList;
