import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    UserAbortError,
    useWithConflictModal,
} from "@/components/Mappers/ConflictModal";
import { OPERATION } from "@/components/Mappers/Mapper";
import {
    crudSuccess,
    pendingPromise,
    responseErrorMessage,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { noop } from "lodash/fp";
import { mappersService } from "../../services/mapper.service";

const serviceByOperation = operation => {
    switch (operation) {
        case OPERATION.EDIT:
            return mappersService.fetchMapper;
        case OPERATION.SHOW:
            return mappersService.fetchInstanceRepoMapper;
    }
};

const initialEmptyValues = { definition: [{}] };

export const useMapperResourceByOperation = ({ operation, mapperId }) => {
    return useQueryLoadable(
        async () =>
            operation === OPERATION.NEW
                ? Promise.resolve(initialEmptyValues)
                : serviceByOperation(operation)(mapperId).then(getData),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mapperId],
    );
};

export const useMappersListQuery = ({ instanceId, reloadToken }) => {
    return useQueryLoadable(
        async () =>
            isNaN(instanceId)
                ? pendingPromise()
                : mappersService.fetchMappers(instanceId).then(getData),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [instanceId, reloadToken],
    );
};

export const useDeployMapperMutation = ({
    instanceId,
    afterSuccess = noop,
}) => {
    const { deploymentService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const withConflictModal = useWithConflictModal();

    return useMutationLoadableWithNotification(
        async record => {
            const res = await withConflictModal(forceCommit =>
                deploymentService.deployMapper(
                    instanceId,
                    record.id,
                    forceCommit,
                ),
            );
            afterSuccessCurrent();
            return res;
        },
        [afterSuccessCurrent, deploymentService, instanceId, withConflictModal],
        "deployment.mapper.successful",
        e => (e instanceof UserAbortError ? null : responseErrorMessage(e)),
    );
};

export const useDeleteMapperMutation = ({ afterSuccess }) => {
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async record =>
            mappersService.deleteMapper(record.id).then(res => {
                afterSuccessCurrent();
                return res;
            }),
        [afterSuccessCurrent],
        ({ name }) => t("general.message.deleted", { type: "mapper", name }),
    );
};

export const useInstanceRepoMappersListQuery = ({
    instanceId,
    reloadToken,
}) => {
    return useQueryLoadable(
        async () =>
            isNaN(instanceId)
                ? pendingPromise()
                : mappersService
                      .fetchInstanceRepoMappers(instanceId)
                      .then(getData),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [instanceId, reloadToken],
    );
};

export const useLocalCopyMutation = ({ instanceId, afterSuccess = noop }) => {
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record => {
            const payload = {
                name: record.name,
                type: record.type,
                definition: record.definition,
                revisionNumber: record.revisionNumber,
                properties: record.properties,
                parent: record.parent,
            };
            const res = await mappersService.instanceRepoLocalCopy(
                instanceId,
                payload,
            );
            afterSuccessCurrent();
            return res;
        },
        [afterSuccessCurrent, instanceId],
        crudSuccess("Mapper Local Copy", "data.name", "C"),
    );
};

export const useDeleteInstanceRepoMapperMutation = ({
    instanceId,
    afterSuccess = noop,
}) => {
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record =>
            mappersService
                .deleteInstanceRepoMapper({
                    instanceId,
                    mapperName: record.name,
                    prn: record.prn,
                })
                .then(res => {
                    afterSuccessCurrent();
                    return res;
                }),
        [afterSuccessCurrent, instanceId],
        ({ name }) => t("general.message.deleted", { type: "mapper", name }),
    );
};

export const useConvertersQuery = ({ instanceId, reloadToken }) => {
    const { convertersService } = useDic();

    return useQueryLoadable(
        async () =>
            isNaN(instanceId)
                ? pendingPromise()
                : convertersService
                      .isConvertersApiSupported(instanceId)
                      .then(getData)
                      .then(response => {
                          return response.supported
                              ? convertersService
                                    .fetchConverters(instanceId)
                                    .then(getData)
                              : {};
                      }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [instanceId, reloadToken],
    );
};
