import { P, Table } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { addActionMenuRender } from "@/components/TableWithPreferences/utils";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { deployToEnum } from "@/constants/deployment.constants";
import { useRouteAccount } from "@/mixpanel/hooks/useRouteAccount.hook";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import BeforeDeploymentModals from "../../../../../components/Marketplace/components/DeployTemplateCard/BeforeDeploymentModals.component.js";
import { useTemplateAdmin } from "../../../../../components/Marketplace/components/WarningStep/loadableResoures.js";
import { useNavigateToPackages } from "../../../../../components/Marketplace/hooks/useNavigateToPackages.hook.js";
import { templateManagementDetailColumns } from "./TemplatesManagementDetail.columns";
import { useLoadableAlert } from "@/modules/loadable/useLoadableAlert.hook.js";
import { isLoading } from "@/modules/loadable/utils.js";
import { useAdminTemplateVersions } from "@/components/Marketplace/components/DeployTemplateCard/loadables.js";

const TemplatesManagementDetail = ({ uniqueName }) => {
    const { messageService, templateAdminService } = useDic();
    const [selectedVersion, setSelectedVersion] = useState();
    const [selectedDestinationId, setSelectedDestinationId] = useState(null);
    const projectDetail = useRouteAccount().accountLoadable.valueMaybe();
    const { navigateToPackages } = useNavigateToPackages();
    const beforeDeploymentModal = useVisibility(false);

    const templateQuery = useTemplateAdmin(uniqueName);
    useLoadableAlert({
        loadable: templateQuery.loadable,
        hasError: e => ({
            type: "error",
            message: t("templates-management.error.unavailable"),
        }),
    });
    const templateMaybe = templateQuery.loadable.valueMaybe();

    const versionsQuery = useAdminTemplateVersions({
        uniqueName,
        canFetch: !!uniqueName,
    });
    useLoadableAlert({
        loadable: versionsQuery.loadable,
        hasError: e => ({
            type: "error",
            message: t("templates-management.error.versionsUnavailable"),
        }),
    });
    const versionsMaybe = versionsQuery.loadable.valueMaybe();

    useSetPageTitle(templateMaybe?.label);

    const templateDeployment = {
        accountId: projectDetail?.id,
        destination:
            templateMaybe?.type === "integrations"
                ? deployToEnum.INSTANCE
                : deployToEnum.PARTITION,
        destinationId: selectedDestinationId,
        template: {
            id: templateMaybe?.uniqueName,
            ...(templateMaybe || {}),
        },
        templateVersion: selectedVersion,
    };

    const deleteTemplateVersion = useCallback(
        async ({ version }) => {
            try {
                await templateAdminService.deleteTemplateVersion(
                    uniqueName,
                    version,
                );
            } catch (error) {
                messageService.error({
                    content: t("templates-management.error.delete"),
                });
            }

            versionsQuery.reload();
        },
        [uniqueName],
    );

    const hasDeployPermissions = !!templateMaybe?.canDeploy;
    const hasPublishPermissions = !!templateMaybe?.canPublish;

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.delete"),
                        color: "red",
                        confirm: {
                            title: t(
                                "templates-management-detail.delete-confirmation.title",
                            ),
                            message: t(
                                "templates-management-detail.delete-confirmation.message",
                                record,
                            ),
                            onConfirm: deleteTemplateVersion,
                        },
                    },
                ]}
            />
        ),
        [deleteTemplateVersion],
    );

    const onDeploy = useCallback(
        record => {
            setSelectedVersion(record.version);
            beforeDeploymentModal.show();
        },
        [beforeDeploymentModal],
    );

    const columnsWithActionMenu = useMemo(
        () =>
            addActionMenuRender(
                templateManagementDetailColumns({
                    templateAdminService,
                    uniqueName,
                    hasPublishPermissions,
                    hasDeployPermissions,
                    onDeploy,
                }),
                actionMenu,
            ),
        [
            templateAdminService,
            uniqueName,
            hasPublishPermissions,
            hasDeployPermissions,
            onDeploy,
            actionMenu,
        ],
    );

    return (
        <>
            <BeforeDeploymentModals
                templateResource={templateQuery}
                visible={beforeDeploymentModal.visible}
                templateDeployment={templateDeployment}
                onCancel={() => {
                    beforeDeploymentModal.hide();
                    setSelectedVersion(null);
                }}
                onContinueDeployment={() => {
                    beforeDeploymentModal.hide();
                    navigateToPackages(templateDeployment);
                }}
                onDestinationIdChange={setSelectedDestinationId}
            />

            <P>{templateMaybe?.uniqueName}</P>
            <Table
                actionMenu={actionMenu}
                columns={columnsWithActionMenu}
                dataSource={versionsMaybe || []}
                rowKey="version"
                pagination={false}
                loading={isLoading(templateQuery) || isLoading(versionsQuery)}
            />
        </>
    );
};

TemplatesManagementDetail.propTypes = {
    uniqueName: PropTypes.string.isRequired,
};

export default TemplatesManagementDetail;
