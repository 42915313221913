import { message } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { t } from "@/translations";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    cleanButtonData,
    setButtonData,
} from "../BreadcrumbButton/duck/actions";
import { OFFERS_PERMISSIONS } from "../Marketplace/marketplaceApp.locations";
import { OffersTableComponent } from "./OffersTable.component";

export const OffersTableContainer = connect(null, {
    setButtonData,
    cleanButtonData,
})(function OffersTableContainer({ setButtonData, cleanButtonData }) {
    const {
        offersService,
        locationRouterService,
        marketplaceAppLocations: { offersEditLocation, offersCreateLocation },
    } = useDic();

    const [offers, setOffers] = useState([]);

    useEffect(() => {
        setButtonData(
            "offer-list.button.create-new",
            () => locationRouterService.navigate(offersCreateLocation),
            OFFERS_PERMISSIONS,
        );
        return cleanButtonData;
    }, []);

    const fetchOffers = () =>
        offersService
            .fetchOffers()
            .then(setOffers)
            .catch(() => {
                message.error(t("offer-list.error.fetch"));
            });

    const onEditClick = offerId => {
        locationRouterService.navigate(offersEditLocation, { offerId });
    };

    const onDeleteClick = offerId => {
        offersService
            .deleteOffer(offerId)
            .then(() => fetchOffers())
            .catch(() => {
                message.error(t("offer-list.error.delete"));
            });
    };

    useEffect(() => {
        fetchOffers();
    }, []);

    return (
        <OffersTableComponent
            data={offers}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
        />
    );
});

OffersTableContainer.propTypes = {};
