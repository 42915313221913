import {
    ActionButton,
    CONFIRM_TYPE,
} from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT } from "@/components/DesignSystem/Table/constants";
import { useInstanceQuery } from "@/components/IntegrationRoutes/loadables";
import { useWorkspaceInstanceDeletionAlert } from "@/components/Integrations/InstanceEntityWorkspaceAlert/InstanceEntityWorkspaceAlert";
import { UserAbortError } from "@/components/Mappers/ConflictModal";
import {
    useDeleteMapperMutation,
    useDeployMapperMutation,
    useMappersListQuery,
} from "@/components/Mappers/loadables";
import { isLoading } from "@/modules/loadable";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { getMappersListTrackName } from "../../mixpanel/buttonNames";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { CopyMapperDialogContainer } from "./CopyMapperDialogContainer";
import { mappersListColumns } from "./MappersList.columns";

const preferencesType = preferencesTypes.MAPPERS_LIST_TABLE;

export const MappersList = ({
    instanceId,
    accountId,
    onEdit,
    reloadToken,
    afterDeploy,
    visible,
}) => {
    const instanceQuery = useInstanceQuery({ instanceId });
    useWorkspaceInstanceDeletionAlert({ instanceQuery, visible });

    const mappersListQuery = useMappersListQuery({
        instanceId,
        reloadToken,
    });
    const deployMapperMutation = useDeployMapperMutation({
        instanceId,
        afterSuccess: afterDeploy,
    });
    const deleteMapperMutation = useDeleteMapperMutation({
        afterSuccess: mappersListQuery.reload,
    });

    const actionMenu = useCallback(
        record => (
            <ActionButton
                permission={INTEGRATION_EDIT_PERMISSIONS}
                record={record}
                items={[
                    {
                        title: t("general.tooltip.edit"),
                        onClick: onEdit,
                        track: { name: getMappersListTrackName("Edit") },
                    },
                    {
                        title: t("general.deploy"),
                        confirm: {
                            type: CONFIRM_TYPE.CONFIRM,
                            title: t("mapper-list.deploy.confirm.title"),
                            message: t("mapper-list.deploy.confirm.message", {
                                name: record.name,
                            }),
                            okText: t("general.deploy"),
                            onConfirm: deployMapperMutation.mutate,
                            cancelText: t("general.cancel"),
                            onHandlerError: (e, { hide }) =>
                                e instanceof UserAbortError && hide(),
                        },
                        track: {
                            name: getMappersListTrackName("Deploy"),
                        },
                    },
                    {
                        title: t("mapper-list.copy.title"),
                        customModal: (
                            <CopyMapperDialogContainer
                                accountId={accountId}
                                mapperId={record.id}
                                name={record.name}
                                afterSuccess={mappersListQuery.reload}
                            />
                        ),
                        track: { name: getMappersListTrackName("Copy") },
                    },
                    {
                        title: t("general.modal.delete.title", {
                            type: "Mapper",
                        }),
                        confirm: {
                            message: t("general.modal.delete.message", {
                                name: record.name,
                                type: "Mapper",
                            }),
                            onConfirm: deleteMapperMutation.mutate,
                        },
                        color: "red",
                        track: {
                            name: getMappersListTrackName("Delete"),
                        },
                    },
                ]}
            />
        ),
        [
            accountId,
            onEdit,
            deployMapperMutation.mutate,
            mappersListQuery.reload,
            deleteMapperMutation.mutate,
        ],
    );

    const dataSource = mappersListQuery.loadable.valueMaybe() || [];
    const loading = isLoading(mappersListQuery);

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            columns={mappersListColumns({ mappers: dataSource })}
            dataSource={dataSource}
            loading={loading}
            fixed
            restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
            rowKey="id"
            datasetSlicing="local"
            preferencesType={preferencesType}
            visible={visible}
            defaultSort={{
                sortSpecifiers: [
                    { property: "updatedAt", direction: "descending" },
                ],
            }}
        />
    );
};

MappersList.propTypes = {
    instanceId: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
    onEdit: PropTypes.func.isRequired,
    reloadToken: PropTypes.any,
    afterDeploy: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};
