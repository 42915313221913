import { ACCEPTED_FORMATS } from "@/components/DataUploads/Wizard/constants";
import { getSupportedExtensionsString } from "@/components/DataUploads/utils";
import { Gap, Label, Link, UnityHeading } from "@/components/DesignSystem";
import Paragraph from "@/components/DesignSystem/Paragraph/Paragraph";
import Upload from "@/components/Upload/Upload";
import { STORAGE_VARIANT } from "@/components/Upload/useUploadStorage.hook";
import { deployToEnum } from "@/constants/deployment.constants";
import { PM_DATA_UPLOAD_KNOWLEDGE_URL } from "@/constants/knowledge.constants";
import { t } from "@/translations";
import React from "react";

const UploadStep = ({
    stepperProps,
    stepFormProps: { setAllValues },
    allStepsProps,
}) => {
    const { isvConnection } = allStepsProps;

    return (
        <>
            <div style={{ maxWidth: "600px" }}>
                <UnityHeading size={4}>
                    {t(
                        "isv-connections.data-mapping.step.entities.send-empty-value-as.entity",
                        {
                            vendorName: "Pricefx",
                        },
                    )}
                </UnityHeading>
                <Paragraph size={2}>
                    {t("isv-connections.data-mapping.step.upload.perex", {
                        knowledgeBaseLink: (
                            <Link
                                data-test="kb-link"
                                href={PM_DATA_UPLOAD_KNOWLEDGE_URL}
                                targetBlank
                            >
                                {t(
                                    "isv-connections.data-mapping.step.upload.knowledge-base",
                                )}
                            </Link>
                        ),
                    })}
                </Paragraph>
                <Paragraph size={2}>
                    {t(
                        "isv-connections.data-mapping.step.upload.next-step-info",
                    )}
                </Paragraph>
                <Gap />
            </div>
            <div style={{ maxWidth: "400px" }}>
                <Label
                    label={t(
                        "isv-connections.data-mapping.step.upload.upload-csv-file",
                    )}
                    required
                />
                <Gap size="small" />
                <Upload
                    accountId={allStepsProps.accountId}
                    storage={STORAGE_VARIANT.S3}
                    destination={deployToEnum.PARTITION}
                    destinationId={isvConnection.partitionId}
                    onFileUploaded={file => {
                        setAllValues(allValues => ({
                            ...allValues,
                            uploadedFile: file,
                        }));
                        stepperProps.nextStep();
                        // setAlert(null);
                    }}
                    hintMessage={t(
                        "quick-data-upload.fields.upload.description",
                        {
                            supportedFormats:
                                getSupportedExtensionsString(ACCEPTED_FORMATS),
                        },
                    )}
                    variant={"withIcon"}
                    multiple={false}
                    accept={ACCEPTED_FORMATS.join(",")}
                />
            </div>
        </>
    );
};

export default UploadStep;
