import { useDic } from "@/components/Dic/useDic.hook";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { MARKETPLACE_TEMPLATE_DEPLOY_PERMISSIONS } from "@/security/permission.utils";
import React, { useCallback, useState } from "react";
import TemplatesManagementList from "../../apps/marketplaceApp/pages/TemplatesManagementPage/TemplatesManagementList";
import { TemplateVisibilityPanel } from "../../apps/marketplaceApp/pages/TemplatesManagementPage/TemplateVisibilityPanel/TemplateVisibilityPanel";

const Packages = () => {
    const {
        locationRouterService,
        marketplaceAppLocations: { templateManagementUploadLocation },
    } = useDic();

    useBreadcrumbButton(
        {
            label: "marketplace.packages.upload.button",
            onClick: () =>
                locationRouterService.navigate(
                    templateManagementUploadLocation,
                ),
            permissions: MARKETPLACE_TEMPLATE_DEPLOY_PERMISSIONS,
        },
        [],
    );

    const [selectedTemplate, setSelectedTemplate] = useState(undefined);
    const [refetchTemplatesToken, setRefetchTemplatesToken] = useState(0);
    const onTemplateUpdated = useCallback(() => {
        setRefetchTemplatesToken(token => token + 1);
        setSelectedTemplate(undefined);
    }, []);

    return (
        <>
            <TemplatesManagementList
                onSetPermissions={setSelectedTemplate}
                refetchTemplatesToken={refetchTemplatesToken}
            />
            <TemplateVisibilityPanel
                template={selectedTemplate}
                visible={!!selectedTemplate}
                onClose={() => setSelectedTemplate(undefined)}
                onUpdated={onTemplateUpdated}
            />
        </>
    );
};

export default Packages;
