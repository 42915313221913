import "lodash.product";
import filter from "lodash/fp/filter";
import groupBy from "lodash/fp/groupBy";
import map from "lodash/fp/map";
import overEvery from "lodash/fp/overEvery";
import pipe from "lodash/fp/pipe";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
    createAssetsArray,
    hasAnyBusinessRolesChanges,
    nextBusinessRolesChangesState,
} from "../rights.utils";
import {
    addSource,
    ADMIN_KIND,
    ASSET_KIND,
    ENTITY_KIND,
    expandSource,
    getAssetRecordFilter,
    getEntityRecordFilter,
    PERMISSION_KIND,
} from "../sources.utils";
import { mapBusinessRoles } from "./mapPermissionEntities";

// prettier-ignore
const significantSelection = {
    [ASSET_KIND.ASSET]: (selection) => createAssetsArray(selection) ?? [],
    [ASSET_KIND.ASSET_ROLE]: (selection) => selection.map(({ id }) => id), // TODO: consider
    [ASSET_KIND.SUPER_ASSET]: (selection) => selection,
}

export const useBusinessRoles = (
    adminKind = ADMIN_KIND.PROJECT,
    entityKind = ENTITY_KIND.USER,
    assetKind = ASSET_KIND.ASSET,

    flatRights,
    workflowRights,
    selectedEntities,
    selectedAssets,
    apiBusinessRoles,
) => {
    const editableSources = useMemo(
        () =>
            expandSource({
                adminKind,
                entityKind,
                assetKind,
                permissionKind: PERMISSION_KIND.BUSINESS_ROLE,
            }),
        [adminKind, entityKind, assetKind],
    );
    const [businessRolesChanges, setBusinessRolesChanges] = useState({});
    const rightsMap = useMemo(
        () =>
            pipe(
                map(addSource),
                // filter(pipe(get("source"), isMatch({ permissionKind: PERMISSION_KIND.BUSINESS_ROLE }))), // TODO: consider
                groupBy("businessRoleId"),
            )(flatRights),
        [flatRights],
    );
    const wfRightsMap = useMemo(
        () =>
            pipe(
                map(addSource),
                // filter(pipe(get("source"), isMatch({ permissionKind: PERMISSION_KIND.BUSINESS_ROLE }))), // TODO: consider
                groupBy("businessRoleId"),
            )(workflowRights),
        [workflowRights],
    );
    const entityMatch = useCallback(
        getEntityRecordFilter({ entityKind, adminKind }),
        [entityKind, adminKind],
    );
    const assetMatch = useCallback(getAssetRecordFilter(assetKind), [
        assetKind,
    ]);
    const filterSelectionProductRecords = useCallback(
        (entityId, assetEntity, businessRole, records) =>
            filter(
                overEvery([entityMatch(entityId), assetMatch(assetEntity)]),
                records,
            ),
        [entityMatch, assetMatch],
    );
    const getRoleSignificantSelection = useCallback(
        role => significantSelection[assetKind](selectedAssets, role),
        [selectedAssets, assetKind],
    );

    const businessRoles = useMemo(
        () =>
            mapBusinessRoles(
                editableSources,
                rightsMap,
                wfRightsMap,
                selectedEntities,
                apiBusinessRoles,
                filterSelectionProductRecords,
                getRoleSignificantSelection,
            ),
        [
            editableSources,
            rightsMap,
            wfRightsMap,
            selectedEntities,
            apiBusinessRoles,
            filterSelectionProductRecords,
            getRoleSignificantSelection,
        ],
    );
    const onChange = businessRole => {
        const newBusinessRolesChanges = nextBusinessRolesChangesState(
            businessRolesChanges,
            businessRole,
        );

        setBusinessRolesChanges(newBusinessRolesChanges);
    };
    const reset = () => setBusinessRolesChanges({});
    useEffect(() => reset(), [businessRoles]);

    return {
        onChange,
        dirty: hasAnyBusinessRolesChanges(businessRolesChanges),
        reset,
        businessRoles,
        businessRolesChanges,
    };
};
