import { message } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useDic } from "@/components/Dic/useDic.hook";
import { useBreadcrumbButtons } from "@/components/hooks/useBreadcrumbButtons.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { SecurityContext } from "@/security/authorization";
import { includesPermissionInAssetTree } from "@/security/permission.utils";
import { t, T } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useState } from "react";
import { useFetchPage } from "../PagableTable/useFetchPage.hook";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { alertRulesColumns } from "./AlertRulesList.columns";
import CopyAlertRuleModal from "./CopyAlertRuleModal";
import { HistoryModal } from "./HistoryModal";
import {
    alertRuleScope,
    alertRuleScope as AlertRuleScope,
    alertRulesServices,
    convertAlertRuleTypeToEntityType,
} from "./services/alertRules.service";

const AlertRulesList = ({ accountId, preferencesType, scope }) => {
    const {
        locationRouterService,
        accountAppLocations: {
            accountAlertRulesEditLocation,
            accountAlertRulesNewLocation,
        },
        administrationAppLocations: {
            newGlobalAlertRuleLocation,
            globalAlertRulesEditLocation,
        },
    } = useDic();
    const securityContext = useContext(SecurityContext);

    const copyAlertRuleModal = useVisibility(false);
    const [selectedAlertRuleId, setSelectedAlertRuleId] = useState();

    const [historyState, setHistoryState] = useState({
        eventLogs: [],
        modalVisible: false,
    });

    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort, filter) =>
            alertRulesServices.fetchAlertRules(
                page,
                size,
                sort,
                filter,
                (accountId =
                    alertRuleScope.SINGLE === scope ? accountId : null),
            ),
        [accountId],
    );

    useBreadcrumbButtons(() => {
        if (scope === alertRuleScope.SINGLE) {
            return [
                {
                    label: "alert-rules-list.button.new",
                    onClick: () =>
                        locationRouterService.navigate(
                            accountAlertRulesNewLocation,
                            {
                                accountId: accountId,
                            },
                        ),
                    permissions: ["alerts.edit"],
                    permissionsFunc: includesPermissionInAssetTree,
                },
            ];
        } else {
            return [
                {
                    label: "alert-rules-list.button.new",
                    onClick: () =>
                        locationRouterService.navigate(
                            newGlobalAlertRuleLocation,
                        ),
                    permissions: ["alerts.global.edit"],
                },
            ];
        }
    }, [
        accountAlertRulesNewLocation,
        accountId,
        locationRouterService,
        newGlobalAlertRuleLocation,
        scope,
    ]);

    const editLocation =
        scope === alertRuleScope.SINGLE
            ? accountAlertRulesEditLocation
            : globalAlertRulesEditLocation;

    const handleDelete = useCallback(
        record => {
            alertRulesServices.delete(record.id).then(() => {
                message.success(
                    <T
                        id="general.message.deleted"
                        values={{
                            type: "rule",
                            name: record.name,
                        }}
                    />,
                );
                reload();
            });
        },
        [reload],
    );

    const handleCopyAlertRule = record => {
        setSelectedAlertRuleId(record.id);
        copyAlertRuleModal.show();
    };

    const actionMenu = useCallback(
        record =>
            canShowActions(securityContext, scope, record) && (
                <ActionButton
                    record={record}
                    items={[
                        {
                            title: t("general.tooltip.edit"),
                            onClick: () =>
                                locationRouterService.navigate(editLocation, {
                                    alertRuleId: record.id,
                                }),
                        },
                        AlertRuleScope.SINGLE === scope && {
                            title: t("alerts-rules.copy"),
                            onClick: handleCopyAlertRule,
                        },
                        {
                            title: t("alerts-rules.history"),
                            onClick: () => showHistory(record.id),
                        },
                        {
                            title: t("general.modal.delete.title", {
                                type: "Alert Rule",
                            }),
                            confirmMessage: t("general.modal.delete.message", {
                                name: record.name,
                                type: "Alert Rule",
                            }),
                            onConfirm: handleDelete,
                            color: "red",
                        },
                    ]}
                />
            ),
        [
            editLocation,
            handleCopyAlertRule,
            handleDelete,
            locationRouterService,
            scope,
            securityContext,
        ],
    );

    const showHistory = alertRuleId => {
        alertRulesServices.fetchEventLogs(alertRuleId).then(res => {
            setHistoryState({ eventLogs: res.data, modalVisible: true });
        });
    };

    const closeHistory = () => {
        setHistoryState({ ...historyState, modalVisible: false });
    };

    return (
        <>
            <HistoryModal
                visible={historyState.modalVisible}
                closeHistory={closeHistory}
                eventLogs={historyState.eventLogs}
            />
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                defaultSort={{
                    fieldName: "name",
                    sortDir: "ascending",
                }}
                columns={alertRulesColumns}
                fixed
                rowKey="id"
                datasetSlicing="server"
                preferencesType={preferencesType}
                exportUrl={
                    scope === alertRuleScope.SINGLE
                        ? `/api/alert-rules/table/export?accountId=${accountId}`
                        : "/api/alert-rules/table/export"
                }
                {...tableProps}
            />
            <CopyAlertRuleModal
                accountId={accountId}
                alertRuleId={selectedAlertRuleId}
                scope={scope}
                visible={copyAlertRuleModal.visible}
                onClose={copyAlertRuleModal.hide}
                onSuccess={reload}
            />
        </>
    );
};

AlertRulesList.propTypes = {
    accountId: PropTypes.number,
    preferencesType: PropTypes.string.isRequired,
    scope: PropTypes.oneOf(Object.keys(alertRuleScope)),
};

export const canShowActions = (securityContext, scope, record) => {
    if (AlertRuleScope.GLOBAL === scope) {
        const hasGlobalPermission =
            securityContext.globalPermissions.includes("alerts.global.edit");

        return hasGlobalPermission;
    } else if (AlertRuleScope.SINGLE === scope) {
        const entityType = convertAlertRuleTypeToEntityType(record.entityType);
        const hasEditPermissionForEntity = securityContext
            .getPermissions(entityType, record.entityId)
            .includes("alerts.edit");
        const hasEditPermissionForCurrentProject =
            securityContext.projectSpecificPermissions.includes("alerts.edit");

        return hasEditPermissionForEntity || hasEditPermissionForCurrentProject;
    }

    console.error(
        `Unknown scope, implement permission check for access. scope:${scope}`,
    );
};

export default AlertRulesList;
