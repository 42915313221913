import { useConfirmModal } from "../../../modal";
import { useOnLeave } from "./useOnLeave.hook";

export const useUnsavedWarning = (
    enabled,
    modalProps,
    modalType = "confirm",
) => {
    const modal = useConfirmModal();
    const {
        onConfirm,
        onCancel,
        okButtonProps = { type: "primary", label: "Apply" },
        cancelButtonProps = { danger: true, label: "Discard" },
        ...rest
    } = modalProps;
    const onLeave = !enabled
        ? null
        : done =>
              modal[modalType]({
                  onConfirm: async () => {
                      await onConfirm();
                      done();
                  },
                  okButtonProps,
                  onCancel: () => {
                      onCancel?.();
                      done();
                  },
                  onClose: () => {
                      done(new Error("transition prevented"));
                  },
                  cancelButtonProps,
                  ...rest,
              });

    useOnLeave(onLeave);
};
