import {
    useClassesQuery,
    useDeleteClassMutation,
    useDeployClassMutation,
} from "@/components/Classes/loadables";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_HEIGHT_WITH_BREADCRUMB } from "@/components/DesignSystem/Table/constants";
import { useInstanceQuery } from "@/components/IntegrationRoutes/loadables";
import { useWorkspaceInstanceDeletionAlert } from "@/components/Integrations/InstanceEntityWorkspaceAlert/InstanceEntityWorkspaceAlert";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { isLoading } from "@/modules/loadable";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { getClassesListTrackName as getTrackName } from "../../mixpanel/buttonNames";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { classesListColumns } from "./ClassesList.columns";
import { CopyClassModal } from "./CopyClass.modal";

const preferencesType = preferencesTypes.CLASSES_LIST_TABLE;
const initialConnections = [];

export default function ClassesList({
    instanceId,
    accountId,
    visible,
    onEdit,
    reloadToken,
    reloadAll,
}) {
    const instanceQuery = useInstanceQuery({ instanceId });
    useWorkspaceInstanceDeletionAlert({ instanceQuery, visible });
    const classesQuery = useClassesQuery({ instanceId, reloadToken });
    const deleteClassMutation = useDeleteClassMutation({
        afterSuccess: classesQuery.reload,
    });
    const deployClassMutation = useDeployClassMutation({
        instanceId,
        afterSuccess: reloadAll,
    });

    const dataSource = classesQuery.loadable.valueMaybe() || initialConnections;
    const loading =
        isLoading(classesQuery) ||
        isLoading(deleteClassMutation) ||
        isLoading(deployClassMutation);
    const columns = useMemo(() => classesListColumns(dataSource), [dataSource]);

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.tooltip.edit"),
                        onClick: onEdit,
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                        track: { name: getTrackName("Edit") },
                    },
                    {
                        title: t("general.deploy"),
                        onClick: deployClassMutation.mutate,
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                        track: { name: getTrackName("Deploy") },
                    },
                    {
                        title: t("classes-list.copy.title"),
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                        customModal: (
                            <CopyClassModal
                                accountId={accountId}
                                classId={record.id}
                                onFinish={classesQuery.reload}
                            />
                        ),
                        track: { name: getTrackName("Copy") },
                    },
                    {
                        title: t("general.modal.delete.title", {
                            type: "class",
                        }),
                        confirm: {
                            message: t("general.modal.delete.message", {
                                type: "class",
                                name: record.filename,
                            }),
                            onConfirm: deleteClassMutation.mutate,
                        },
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                        color: "red",
                        track: { name: getTrackName("Delete") },
                    },
                ]}
            />
        ),
        [
            accountId,
            classesQuery.reload,
            deleteClassMutation.mutate,
            deployClassMutation.mutate,
            onEdit,
        ],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            dataSource={dataSource}
            fixed
            restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
            loading={loading}
            rowKey="id"
            columns={columns}
            datasetSlicing="local"
            preferencesType={preferencesType}
            visible={visible}
        />
    );
}

ClassesList.propTypes = {
    accountId: PropTypes.number.isRequired,
    instanceId: PropTypes.number.isRequired,
    onEdit: PropTypes.func.isRequired,
    reloadToken: PropTypes.any,
    reloadAll: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};
