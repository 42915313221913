import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDepGlobalState } from "../../../../views/Package/package.utils";
import { DeployPackage } from "./DeployPackage.component";

export function DeployPackageContainer({
    accountId,
    partitionId,
    instanceId,
    packageName,
    onPackageConfirmation,
    onPackageCanceled,
}) {
    const {
        steps,
        currentStepIndex,
        deploymentState,
        progressInPercent,
        deployPackageState,
    } = useSelector(state => state.deployPackage);

    const { deployPackage } = useDispatch();

    useEffect(() => {
        deployPackage.initDeploymentProcess({
            packageName,
            partitionId,
            instanceId,
        });
        return () => deployPackage.resetState();
    }, [packageName, partitionId, instanceId]);

    function onStepFinished(data) {
        deployPackage.saveStepData({
            stepId: deploymentState.currentStepData.id,
            packageName,
            partitionId,
            instanceId,
            data,
        });
    }

    function onStepRestart(andStartWaiting) {
        return deployPackage.restartCurrentStepInDeploymentProcess({
            packageName,
            partitionId,
            instanceId,
            andStartWaiting,
        });
    }

    return (
        <DeployPackage
            onStepRestart={onStepRestart}
            accountId={accountId}
            packageName={packageName}
            partitionId={partitionId}
            instanceId={instanceId}
            $steps={steps}
            currentStepIndex={currentStepIndex}
            $deploymentState={deploymentState}
            deployPackageState={deployPackageState}
            progressInPercent={progressInPercent}
            onStepFinished={onStepFinished}
            onGoToNextStep={deployPackage.goToNextStep}
            onPackageConfirmation={onPackageConfirmation}
            onPackageCanceled={onPackageCanceled}
            processGlobalState={getDepGlobalState(deploymentState) || {}}
        />
    );
}

DeployPackageContainer.propTypes = {
    accountId: PropTypes.number.isRequired,
    instanceId: PropTypes.number,
    onPackageConfirmation: PropTypes.func.isRequired,
    onPackageCanceled: PropTypes.func.isRequired,
    packageName: PropTypes.string.isRequired,
    partitionId: PropTypes.number,
};
