import { T } from "@/translations";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import {
    Button,
    Col,
    Form,
    Option,
    Row,
    Select,
} from "@/components/DesignSystem";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { StepUiHeaders } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/Skip";

const ConditionStep = ({
    step,
    globalState,
    changeState,
    form,
    onNext,
    onBack,
}) => {
    useEffect(() => {
        if (!globalState.properties) {
            const field = globalState.runIMTemplate
                ? globalState?.mappingTemplate?.mandatoryFields
                : step.mandatoryFields;
            setInternalProperties(field);
        }
    }, [step, globalState]);

    const { properties = [] } = globalState;
    const { getFieldDecorator } = form;

    const setInternalProperties = p => changeState({ properties: p });

    const [state, setState] = useState({ conditions: {} });

    const onChange = (value, index) => {
        setState({
            conditions: {
                ...state.condidions,
                [properties[index].name]: value,
            },
        });
    };

    const getComponentByType = (property, onChange, index) => {
        return (
            <Select
                id={property.name}
                placeholder={property.placeholder}
                onChange={value => onChange(value, index)}
                showSearch={true}
            >
                {property.options.map(option => (
                    <Option
                        title={option.label}
                        key={option.label}
                        value={option.value}
                    >
                        {option.label}
                    </Option>
                ))}
            </Select>
        );
    };

    const handleOnNext = values => {
        form.validateFields((err, values) => {
            if (!err) {
                onNext({ ...state });
            }
        });
    };

    return (
        <Row>
            <Col sm={12}>
                <Form onSubmit={handleOnNext}>
                    <StepUiHeaders ui={step.ui} />
                    {properties.map((property, index) => {
                        return (
                            <Form.Item
                                key={property.name}
                                label={property.label}
                            >
                                {getFieldDecorator(property.name, {
                                    initialValue: property.value,
                                    rules: [
                                        {
                                            required: property.required
                                                ? property.required
                                                : false,
                                            message: `Please input ${property.label}`,
                                        },
                                    ],
                                })(
                                    getComponentByType(
                                        property,
                                        onChange,
                                        index,
                                    ),
                                )}
                            </Form.Item>
                        );
                    })}
                </Form>
                <Button
                    type="primary"
                    onClick={handleOnNext}
                    label={<T id="package-data-upload.button.next" />}
                />
                <BackButton onBack={onBack} />
            </Col>
        </Row>
    );
};

ConditionStep.propTypes = {
    step: PropTypes.object.isRequired,
    globalState: PropTypes.object.isRequired,
    changeState: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
};

export default Form.create({})(ConditionStep);
