import { Gap, H3, Text } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import React from "react";
import style from "./Result.less";

const Result = ({ title, description, actions, imageSrc }) => {
    return (
        <div>
            <H3>{title}</H3>
            <Text size="large">{description}</Text>
            <Gap size="large" />
            {actions && <div className={style.action}>{actions}</div>}
            {imageSrc && (
                <div className={style.image}>
                    <img src={imageSrc} alt="Result image" />
                </div>
            )}
        </div>
    );
};

export default Result;

Result.propTypes = {
    description: PropTypes.string.isRequired,
    imageSrc: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    actions: PropTypes.element,
};
