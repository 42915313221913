import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { noop } from "lodash/fp";

export const useFailedEventsQuery = ({ instanceId, canFetch }) => {
    const { instanceService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : instanceService.fetchFailedEvents(instanceId),
        [instanceId, canFetch, instanceService],
    );
};

export const useRetryEventTypesMutation = ({
    instanceId,
    afterSuccess = noop,
}) => {
    const { instanceService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async eventTypes =>
            instanceService
                .retryEventTypes(instanceId, eventTypes)
                .then(res => {
                    afterSuccessCurrent();
                    return res;
                }),
        [instanceId, afterSuccessCurrent, instanceService],
        "failed-events-list.retry-event-types.success",
    );
};
