import { useDic } from "@/components/Dic/useDic.hook";
import { isProcessDone } from "@/components/Integrations/ImUpdate/helpers";
import { useSomeAsyncTasksLiveQuery } from "@/modules/async-tasks";
import { hasValue } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

export const initTask = { id: -1, status: "", progress: 0 };
export const useDeleteUpdate = ({ endpoint, instanceId }) => {
    const { messageService, integrationManagerUpdateService } = useDic();
    const onSuccessRef = useRef();
    const [task, setTask] = useState(initTask);
    const maybeAsyncJobQuery = useSomeAsyncTasksLiveQuery({
        filterPredicate: useCallback(job => job.id === task.id, [task.id]),
    });

    useEffect(() => {
        if (hasValue(maybeAsyncJobQuery.loadable)) {
            const jobState = maybeAsyncJobQuery.loadable.valueMaybe();
            if (!isEmpty(jobState)) {
                const deleteTask = jobState?.[0];
                setTask(deleteTask);
                if (isProcessDone(deleteTask)) {
                    messageService.success({
                        content: t(
                            "im-instance.update.leave.model.cancel.success",
                        ),
                    });
                    onSuccessRef.current?.();
                    setTask(initTask);
                }
            }
        }
    }, [maybeAsyncJobQuery.loadable, messageService]);

    const deleteUpdate = useCallback(
        ({ onSuccess }) => {
            onSuccessRef.current = onSuccess;
            integrationManagerUpdateService
                .commonDelete(endpoint, instanceId)
                .then(getData)
                .then(setTask)
                .catch(e => {
                    messageService.error({
                        content: getErrorMessage(e.response.data),
                    });
                });
        },
        [endpoint, instanceId, integrationManagerUpdateService, messageService],
    );

    return {
        deleteUpdate,
        task,
    };
};
