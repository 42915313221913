import { Button, Gap, H3, P, Table } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useValueVisibility } from "@/components/hooks/useValueVisibility.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import {
    useDeleteTokenMutation,
    useTokensListQuery,
} from "@/components/Tokens/loadables";
import { isLoading } from "@/modules/loadable";
import { useConfirmModal } from "@/modules/modal/imperative/useConfirmModal.hook";
import { t } from "@/translations";
import React from "react";
import CreateTokenModal from "./CreateTokenModal";
import ShowTokenModal from "./TokenCreatedModal";
import styles from "./Tokens.style.less";

export const Tokens = () => {
    const tokensListResource = useTokensListQuery();
    const deleteTokenMutation = useDeleteTokenMutation({
        afterSuccess: tokensListResource.reload,
    });

    const tokenModal = useVisibility();
    const createdModal = useValueVisibility();
    const confirmModal = useConfirmModal();

    return (
        <>
            <P>
                {t("profile.message", {
                    header: (
                        <strong key="profile-header">
                            x-pricefx-generated-token
                        </strong>
                    ),
                    format: (
                        <strong key="profile-token-format">login:token</strong>
                    ),
                })}
            </P>
            <Button
                type="primary"
                htmlType="button"
                onClick={tokenModal.show}
                label={t("profile.button.create-token")}
            />
            <Gap size="large" />
            <div className={styles.tableWrapper}>
                <Table
                    className="pmTable--tallCells"
                    rowKey="id"
                    padding={false}
                    loading={isLoading(tokensListResource)}
                    dataSource={tokensListResource.loadable.valueMaybe() ?? []}
                    columns={[
                        {
                            type: fieldTypes.TEXT,
                            label: t("profile.header.token-name"),
                            name: "comment",
                        },
                        {
                            type: fieldTypes.TEXT,
                            label: t("profile.header.action"),
                            name: "action",
                            render: (text, record) => (
                                <Button
                                    type="danger"
                                    record={record}
                                    onClick={() =>
                                        confirmModal.confirmDelete({
                                            title: t("profile.button.delete"),
                                            okText: t("general.delete"),
                                            message: t(
                                                "profile.remove-token.message",
                                                { name: record.comment },
                                            ),
                                            onConfirm: () =>
                                                deleteTokenMutation.mutate(
                                                    record.id,
                                                ),
                                        })
                                    }
                                    label={t("general.delete")}
                                    size="small"
                                />
                            ),
                        },
                    ]}
                />
            </div>
            <CreateTokenModal
                visible={tokenModal.visible}
                onClose={tokenModal.hide}
                onSave={token => {
                    tokenModal.hide();
                    tokensListResource.reload();
                    createdModal.show(token);
                }}
            />
            <ShowTokenModal
                visible={createdModal.visible}
                token={createdModal.value}
                onClose={createdModal.hide}
            />
        </>
    );
};

export default Tokens;
