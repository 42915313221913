import { isProduction } from "@/utils/env.utils";
import { isEmpty } from "lodash/fp";

export const expandParam = {
    parse: str => JSON.parse(atob(str)),
    stringify: expand => btoa(JSON.stringify(expand)),
};

export const getExpandMaybe = paramString => {
    try {
        const expandParsed = expandParam.parse(paramString);
        if (isEmpty(expandParsed)) return undefined;
        else return expandParsed;
    } catch (err) {
        if (!isProduction())
            console.error("Failed to parse expandParam", { paramString, err });
        return undefined;
    }
};
