import {
    Button,
    ButtonGroup,
    Forms,
    Gap,
    H3,
    Text,
} from "@/components/DesignSystem";
import { usePartitionDataUploadNameValidator } from "@/components/Packages/PackageTableDefinitionPanel/components/NewDataUploadFromDeployment/validators";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { t } from "@/translations";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import React from "react";
import style from "../../../../DataUploads/Wizard/wizard.style.less";
const { useForm, Fields, SubmitButton } = Forms;

const NewDataUploadFromDeployment = ({
    partitionId,
    onNext,
    onBack,
    onCancel,
}) => {
    const onSubmit = ({ values: { dataUploadName } }) =>
        onNext({ dataUploadName });

    const { formId, Form, handleSubmit } = useForm({
        onSubmit,
    });

    const dataUploadName = useFieldValue({ formId, name: "dataUploadName" });

    const nameValidator = usePartitionDataUploadNameValidator({
        partitionId,
    });

    return (
        <div className={style.wizard}>
            <H3>{t("package.mapping-options.create-data-upload.title")}</H3>
            <Text size="large">
                {t("package.mapping-options.create-data-upload.description")}
            </Text>
            <Gap size="large" />

            <Form disableBanner onSubmit={handleSubmit}>
                <Fields.Input
                    initialValue={dataUploadName}
                    name="dataUploadName"
                    type="text"
                    required
                    label={t("partition-send-data.label.name")}
                    validator={nameValidator}
                    placeholder={t("partition-send-data.placeholder.name")}
                />

                <ButtonGroup spacing="none">
                    <SubmitButton
                        type="primary"
                        label={t(
                            "package.mapping-options.create-data-upload.create-button",
                        )}
                    />
                    <BackButton onBack={onBack} />

                    <Button
                        type="text"
                        size="large"
                        onClick={onCancel}
                        label={t(
                            "package.mapping-options.create-data-upload.cancel-button",
                        )}
                    />
                </ButtonGroup>
            </Form>
        </div>
    );
};

export default NewDataUploadFromDeployment;
