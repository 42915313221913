import { Col, Gap, Input, Row, Text } from "@/components/DesignSystem";
import { useConfirmModal } from "@/modules/modal/imperative/useConfirmModal.hook";
import { T, t } from "@/translations";
import cx from "classnames";
import { produce } from "immer";
import union from "lodash/union";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { conditionalOptionsModal } from "../../../../../apps/marketplaceApp/components/PackageDefinition/MappingOption.modal";
import {
    getSelectedType,
    getTypeLabel,
    resolveBusinessColumns,
} from "../../../packagesPanel.utils";
import PackageDataMappingLayout from "../PackageDataMappingLayout/PackageDataMappingLayout";
// assets
import { defaultPreviewCellProps } from "@/components/Packages/PackageTableDefinitionPanel/components/PackageDataMappingLayout/DataPreviewTable.component";
import styles from "../styles.less";

const initInputs = (step, previewInputs) =>
    produce(previewInputs, draft => {
        if (step.uniqueName) {
            draft.uniqueName = step.uniqueName;
        }
    });

export const ExtensionPreview = ({
    globalState,
    changeState,
    step,
    accountId,
    onNext,
    onBack,
    onCancel,
}) => {
    const {
        fileInfo: tableExampleData = {},
        mandatoryFields = [],
        optionalFields = [],
        userInputs = {},
    } = globalState;

    const setInputs = i => changeState({ userInputs: i });

    const confirmModal = useConfirmModal();

    useEffect(() => {
        changeState({ userInputs: initInputs(step, userInputs) });
    }, []);

    const handleInputs = (type, value) =>
        setInputs(
            produce(userInputs, draft => {
                draft[type] = value;
            }),
        );

    const handleOnNext = () =>
        conditionalOptionsModal({
            condition: step.showMappingOptions,
            data: { userInputs },
            accountId,
            onNext,
            onCancel,
            confirmModal,
        });

    const resolveSkippedColumns = () =>
        union(mandatoryFields, optionalFields)
            .filter(field => field.skip === true)
            .map(field => field.value || field.label);

    const customColumns = ({ name, type }) => {
        const skippedColumns = resolveSkippedColumns();
        const skip = (skippedColumns && skippedColumns.includes(name)) || false;
        return {
            ...defaultPreviewCellProps,
            label: createTitle(
                name,
                getSelectedType(optionalFields, name) || type,
            ),
            name,
            render: text => {
                return (
                    <div
                        className={cx({ [styles.skipped]: skip })}
                    >{`${text}`}</div>
                );
            },
        };
    };

    const createTitle = (name, type) => {
        const skippedColumns = resolveSkippedColumns();
        const businessColumns = resolveBusinessColumns(
            mandatoryFields,
            optionalFields,
        );
        const show = businessColumns && businessColumns.includes(name);
        const skip = (skippedColumns && skippedColumns.includes(name)) || false;

        return (
            <div className={cx({ [styles.skipped]: skip })}>
                {name}
                <div className={styles.type}>{getTypeLabel(type)}</div>
                {show && (
                    <div className={styles.businessKey}>
                        <i className={"uil uil-key-skeleton-alt"} />
                        {getTypeLabel(type)}
                    </div>
                )}
            </div>
        );
    };

    return (
        <PackageDataMappingLayout
            dataExample={tableExampleData}
            skippedColumns={resolveSkippedColumns()}
            customColumns={customColumns}
            disableNextButton={!userInputs.uniqueName || !userInputs.label}
            onNext={handleOnNext}
            onBack={onBack}
            onCancel={onCancel}
        >
            <T id="package-data-upload.extension-preview.message" />
            <Gap size="small" />
            <Row className={styles.extensionInputs}>
                <Col span={6}>
                    <Text>
                        <T id="package-data-upload.extension-preview.extension-name" />
                    </Text>
                    <Input
                        placeholder={t(
                            "package-data-upload.extension-preview.extension-name",
                        )}
                        disabled={step.uniqueName}
                        value={userInputs.uniqueName}
                        onChange={e =>
                            handleInputs("uniqueName", e.target.value)
                        }
                    />
                </Col>
                <Col span={6}>
                    <Text>
                        <T id="package-data-upload.extension-preview.extension-label" />
                    </Text>
                    <Input
                        placeholder={t(
                            "package-data-upload.extension-preview.extension-label",
                        )}
                        onChange={e => handleInputs("label", e.target.value)}
                        value={userInputs.label}
                    />
                </Col>
            </Row>
        </PackageDataMappingLayout>
    );
};

ExtensionPreview.propTypes = {
    globalState: PropTypes.object.isRequired,
    changeState: PropTypes.func.isRequired,
    step: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
};
