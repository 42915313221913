import { Gap, H4, Tabs } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { LoadableRenderer, useQueryLoadable } from "@/modules/loadable";
import { t, T } from "@/translations";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { useFetchPage } from "../../PagableTable/useFetchPage.hook";
import { TableWithPreferencesManagement } from "../../TableWithPreferences/TableWithPreferencesManagement.container";
import { alertsServices } from "../services/alerts.service";
import { createAlertsColumns } from "./AlertsList.columns";

const AlertDetail = ({ record }) => {
    const alertDetailsQuery = useQueryLoadable(
        async () =>
            alertsServices
                .fetchAlertDetails(record.id)
                .then(response => response.data),

        [record?.id],
    );

    return (
        <LoadableRenderer
            hasError={() => (
                <LoadableRenderer.Error translationKey="alerts.detail.errorLoading" />
            )}
            hasValue={alertDetails => {
                if (isEmpty(alertDetails))
                    return <T id="alerts.detail.emptyInfo" />;

                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                        }}
                    >
                        <Tabs>
                            {alertDetails.map(alertDetail => (
                                <Tabs.TabPane
                                    tab={`ELK id: ${alertDetail.id}`}
                                    key={alertDetail.id}
                                >
                                    <pre
                                        className="jsonParent"
                                        style={{
                                            display: "flex",
                                            width: "100%",
                                            height: 480,
                                            overflow: "auto",
                                        }}
                                    >
                                        {JSON.stringify(
                                            alertDetail.source,
                                            null,
                                            4,
                                        )}
                                    </pre>
                                </Tabs.TabPane>
                            ))}
                        </Tabs>
                    </div>
                );
            }}
            loadable={alertDetailsQuery.loadable}
            loading={() => <LoadableRenderer.Spinner />}
        />
    );
};

AlertDetail.propTypes = {
    record: PropTypes.object,
};

const NOT_EXPANDABLE_ALERT_TYPES = ["PARTITION_JOB", "ROUTE_CHECK"];

const addAlert = row => {
    if (row.deliveryStatus === "FAILED")
        return {
            alerts: {
                // fired: row.deliveryMessage,
                fired_ALERT_TYPE: "YELLOW",
            },
        };
    else return {};
};

export const AlertsList = ({
    accountId,
    displayDetails = true,
    preferencesType,
    showAllAlertOptions,
}) => {
    const panelControl = useDetailDrawerState();
    const [tableProps] = useFetchPage(
        (page, size, sort = "fired,desc", filter) =>
            alertsServices.fetchAlerts(accountId, page, size, sort, {
                ...filter,
                ...(showAllAlertOptions
                    ? {}
                    : { alertType: NOT_EXPANDABLE_ALERT_TYPES }),
            }),
        [accountId, showAllAlertOptions],
    );
    const mappedTableProps = useMemo(
        () => ({
            ...tableProps,
            page: {
                ...tableProps.page,
                content: tableProps.page?.content?.map(row => ({
                    ...row,
                    ...addAlert(row),
                })),
            },
        }),
        [tableProps],
    );
    const columns = useMemo(() => createAlertsColumns(), []);
    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.show-detail"),
                        onClick: panelControl.show,
                    },
                ]}
            />
        ),
        [panelControl.show],
    );

    return (
        <TableWithPreferencesManagement
            columns={columns}
            actionMenu={actionMenu}
            panelControl={panelControl}
            renderPanelHeader={record => (
                <>
                    <H4>{record.name}</H4>
                    <Gap />
                </>
            )}
            defaultSort={{
                fieldName: "fired",
                sortDir: "descending",
            }}
            datasetSlicing="server"
            pmExpandable={{
                expandedRowHeight: 600,
                expandedRowRender: record => <AlertDetail record={record} />,
                rowExpandable: record =>
                    displayDetails &&
                    !NOT_EXPANDABLE_ALERT_TYPES.includes(record.alertType),
            }}
            fixed
            preferencesType={preferencesType}
            rowKey="id"
            exportUrl={
                accountId
                    ? `/api/alerts/export?accountId=${accountId}`
                    : "/api/alerts/export"
            }
            {...mappedTableProps}
        />
    );
};

AlertsList.propTypes = {
    accountId: PropTypes.number,
    displayDetails: PropTypes.bool,
    preferencesType: PropTypes.string.isRequired,
    showAllAlertOptions: PropTypes.bool.isRequired,
};
