import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { ParentWarning } from "@/components/Mappers/ParentWarning";
import { t } from "@/translations";
import { getColumnOptions } from "@/utils/tableUtils";
import React from "react";
import { RouteStatus } from "../IntegrationRoutes/components/RouteStatus";

export const connectionsListColumns = connections => [
    {
        label: t("connection-list.header.unique-name"),
        type: fieldTypes.TEXT,
        name: "name",
        className: "pmTable__column--withActionButton",
    },
    {
        type: fieldTypes.TEXT,
        label: t("general.parent"),
        name: "parent",
        render: (text, record) => (
            <ParentWarning parent={record.parent} synced={record.synced} />
        ),
    },
    {
        label: t("connection-list.header.type"),
        type: fieldTypes.TEXT,
        name: "type",
    },
    {
        type: fieldTypes.OPTION,
        options: connections && getColumnOptions(connections, "status"),
        label: t("mapper-list.header.status"),
        name: "status",
        render: (text, record) => (
            <>
                <RouteStatus status={record.status} />
            </>
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("mapper-list.header.revision-number"),
        name: "revisionNumber",
    },
    {
        label: t("connection-list.header.created-at"),
        name: "createdAt",
        type: fieldTypes.DATETIME,
        render: (text, d) => (
            <FormattedDateTime>{d.createdAt}</FormattedDateTime>
        ),
    },
    {
        label: t("connection-list.header.created-by"),
        type: fieldTypes.TEXT,
        name: "createdBy",
    },
    {
        label: t("connection-list.header.updated-at"),
        type: fieldTypes.DATETIME,
        name: "updatedAt",
        render: (text, d) => (
            <FormattedDateTime>{d.updatedAt}</FormattedDateTime>
        ),
    },
    {
        label: t("connection-list.header.updated-by"),
        type: fieldTypes.TEXT,
        name: "updatedBy",
    },
];
