import { PartitionEntitlementInfo } from "@/components/CustomerPartitionCredentials/PartitionEntitlementInfo";
import {
    Button,
    ButtonMenu,
    Forms,
    Gap,
    UnityLayout,
} from "@/components/DesignSystem";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { getCustomerPartitionCredentialsFormTrackName as getTrackName } from "../../mixpanel/buttonNames";
import { partitionUserType } from "./partitionUserType.constants";

const USER_TYPE_OPTIONS = [
    {
        label: t("partition-password.use-existing"),
        value: partitionUserType.NORMAL_USER,
    },
    {
        label: t("partition-password.create-pfm-technical-user"),
        value: partitionUserType.TECHNICAL_USER,
    },
];

const ACTION = {
    TEST: "TEST",
    SAVE: "SAVE",
};

export const renderFooterButtons = buttons => (
    <UnityLayout.Footer
        actionButtons={
            <ButtonMenu buttonSize="middle" align="left" buttons={buttons} />
        }
    />
);
export const renderClassicButtons = buttons => (
    <>
        <Gap />
        {buttons.map(button => (
            <Button key={button.label} {...button} />
        ))}
    </>
);

export const CustomerPartitionCredentialsFormInner = ({
    missingRightsMaybe,
    loading,
    partition,
    maybeLoginInfo,
    defaultTechnicalUsername,
    onCancel,
    onTest,
    onSetCredentials,
    onResetCredentials,
    insideModal,
    headerElement,
}) => {
    const [OuterWrapperComponent, FormWrapperComponent, renderButtons] =
        insideModal
            ? [UnityLayout, UnityLayout.Content, renderFooterButtons]
            : [React.Fragment, React.Fragment, renderClassicButtons];

    const { formId, submit, handleSubmit, setValues, setTouched } =
        Forms.useForm({
            onSubmit: ({ values, args: [action] }) =>
                ({
                    [ACTION.TEST]: onTest,
                    [ACTION.SAVE]: onSetCredentials,
                }[action](values)),
        });

    const initialValues = useMemo(() => {
        if (maybeLoginInfo) return maybeLoginInfo;

        return partition.technicalPmUserSupported
            ? {
                  userType: partitionUserType.TECHNICAL_USER,
                  username: defaultTechnicalUsername,
              }
            : {
                  userType: partitionUserType.NORMAL_USER,
                  username: "",
              };
    }, [
        defaultTechnicalUsername,
        maybeLoginInfo,
        partition.technicalPmUserSupported,
    ]);

    useSetValidatedInitialValues({ setValues, setTouched, initialValues }, [
        maybeLoginInfo,
    ]);
    const userType = Forms.useFieldValue({ formId, name: "userType" });
    const updateAdminUserFlag = ({ name, value, meta }) => {
        const username = {
            [partitionUserType.TECHNICAL_USER]: defaultTechnicalUsername,
            [partitionUserType.NORMAL_USER]:
                maybeLoginInfo?.userType === partitionUserType.NORMAL_USER
                    ? maybeLoginInfo?.username
                    : "",
        }[value];

        setValues({ username });
        setTouched({ username: false });
    };
    const validator = Forms.validators.failOnFirst([
        Forms.pmValidators.isRequired,
        Forms.pmValidators.createMinLengthValidation(1),
    ]);

    return (
        <OuterWrapperComponent>
            {headerElement}
            <FormWrapperComponent
                {...(insideModal ? { padding: [false, true] } : {})}
            >
                <PartitionEntitlementInfo
                    partition={partition}
                    currentUser={maybeLoginInfo?.username}
                    missingRightsMaybe={missingRightsMaybe}
                />
                <Forms.Form formId={formId} onSubmit={handleSubmit}>
                    <Forms.FieldGroup width="max" inputWidth="max">
                        {partition.technicalPmUserSupported && (
                            <Forms.Fields.Radio
                                name="userType"
                                label={null}
                                // label={t("partition-password.current-user")}
                                options={USER_TYPE_OPTIONS}
                                onChange={updateAdminUserFlag}
                            />
                        )}
                        <Forms.Fields.Input
                            required
                            validator={validator}
                            name="username"
                            data-test="partition-username-field-input"
                            label={t("partition-password.label.user-name")}
                            placeholder={t(
                                "partition-password.placeholder.user-name",
                            )}
                            disabled={
                                userType === partitionUserType.TECHNICAL_USER
                            }
                        />
                        {userType === partitionUserType.NORMAL_USER && (
                            <Forms.Fields.InputPassword
                                required
                                validator={validator}
                                name="password"
                                data-test="partition-password-field-input"
                                label={t("partition-password.label.password")}
                                placeholder={t(
                                    "partition-password.placeholder.password",
                                )}
                            />
                        )}
                    </Forms.FieldGroup>
                </Forms.Form>
            </FormWrapperComponent>
            {renderButtons([
                {
                    onClick: () => submit(ACTION.SAVE),
                    type: "primary",
                    htmlType: "submit",
                    label:
                        userType === partitionUserType.NORMAL_USER
                            ? t("partition-password.button.save")
                            : t("partition-password.button.test-and-save"),
                    disabled: loading,
                    track: { name: getTrackName("Save") },
                },
                {
                    onClick: () => submit(ACTION.TEST),
                    label: t("partition-password.button.test"),
                    visible: userType === partitionUserType.NORMAL_USER,
                    disabled:
                        loading ||
                        (userType === partitionUserType.TECHNICAL_USER &&
                            !maybeLoginInfo),
                    track: { name: getTrackName("Test") },
                },
                {
                    onClick: onResetCredentials,
                    label: t("partition-password.button.reset-credentials"),
                    disabled: loading,
                    visible:
                        userType === partitionUserType.TECHNICAL_USER &&
                        !!maybeLoginInfo,
                    track: { name: getTrackName("Reset") },
                },
                {
                    onClick: onCancel,
                    label: t("partition-password.button.cancel"),
                    disabled: loading,
                    track: { name: getTrackName("Cancel") },
                },
            ])}
        </OuterWrapperComponent>
    );
};

CustomerPartitionCredentialsFormInner.propTypes = {
    missingRightsMaybe: PropTypes.object,
    maybeLoginInfo: PropTypes.object,
    loading: PropTypes.bool,
    partition: PropTypes.object.isRequired,
    defaultTechnicalUsername: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onTest: PropTypes.func.isRequired,
    onSetCredentials: PropTypes.func.isRequired,
    onResetCredentials: PropTypes.func.isRequired,
    insideModal: PropTypes.bool,
    headerElement: PropTypes.node,
};
