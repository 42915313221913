import { mapInputTypeToUsedEditorTab } from "@/components/CalculatedFieldTable/FieldCustomization.modal";
import { Button, Forms, UnityTooltip } from "@/components/DesignSystem";
import { ROW_ENTITIES } from "@/components/Mappers/MapperTableWithCustomization/MapperTableWithCustomization";
import { InputType } from "@/components/Packages/PackageTableDefinitionPanel/constants";
import { t } from "@/translations";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import * as React from "react";
const { Fields } = Forms;

export const MapperConverter = ({ id, onClick, isDetailVisible }) => {
    const converterExpression = useFieldValue({
        name: `${id}_${ROW_ENTITIES.converterExpression}`,
    });
    const inputType = useFieldValue({
        name: `${id}_${ROW_ENTITIES.inputType}`,
    });

    const isConverted =
        (inputType === InputType.BODY && converterExpression) ||
        inputType !== InputType.BODY;
    const usedConverter = mapInputTypeToUsedEditorTab(inputType);
    return (
        <div className="pmMapperTableWithCustomization-convert">
            <div style={{ whiteSpace: "nowrap" }}>
                <Fields.Input
                    hidden
                    type="hidden"
                    placeholder={t("general.select")}
                    allowClear={false}
                    name={`${name}Type`}
                />
                <Button
                    label={
                        isConverted
                            ? t("data-upload.mapper.converted.label")
                            : t("data-upload.mapper.convert.label")
                    }
                    type="link"
                    onClick={onClick}
                />
                {isConverted && (
                    <UnityTooltip tooltip={usedConverter}>
                        <InfoCircleOutlined className="pmConverterIcon" />
                    </UnityTooltip>
                )}
            </div>
            <UnityTooltip tooltip={converterExpression}>
                <div
                    data-test="converter-expression"
                    hidden={!isDetailVisible}
                    className="pmMapperTableWithCustomization-cell pmMapperTableWithCustomization-converter"
                >
                    {converterExpression}
                </div>
            </UnityTooltip>
        </div>
    );
};
