import { Button, Gap, H4 } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useDic } from "@/components/Dic/useDic.hook";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { isLoading, STATUSES } from "@/modules/loadable";
import { useUserBasicInfo } from "@/security/hooks/useUserBasicInfo.hook";
import { t } from "@/translations";
import { downloadFile } from "@/utils/downloadUtils";
import { getErrorMessage } from "@/utils/state/error.utils";
import isEmpty from "lodash/fp/isEmpty";
import xor from "lodash/fp/xor";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useReducer, useState } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { PAGE_SIZE_OPTIONS } from "../PagableTable/PageableTable";
import { Property } from "../Property/Property.component";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { CreateProductRequestModal } from "./CreateProductRequestModal";
import { useListOfEnhancements, usePostVoteResource } from "./loadables";
import { productEnhancementOverviewColumns } from "./ProductEnhancementOverview.columns";
import { VoteModal } from "./VoteModal";

export const DEFAULT_SORT_BY = "key,desc";

export const PRODUCT_ENHANCEMENT_PAGE_SIZE_OPTIONS = ["30", "50", "100"];

const NO_VOTE_USERNAME_SUFFIXES = ["pricefx.com", "pricefx.eu"];

const getCustomersEligibleToVote = (customers, customerVotes) =>
    xor(customers, customerVotes);

export const useProductRequestAttachmentDownload = () => {
    const { messageService, axiosService } = useDic();

    const onAttachmentDownload = useCallback(
        (attachmentName, attchmentId) => {
            axiosService
                .get(`/api/jira-tasks/attachments/${attchmentId}/content`, {
                    responseType: "blob",
                })
                .then(fileResponse =>
                    downloadFile(fileResponse.data, attachmentName),
                )
                .catch(e => {
                    messageService.error({
                        content: getErrorMessage(e.response.data),
                    });
                });
        },
        [axiosService, messageService],
    );

    return onAttachmentDownload;
};

const ProductEnhancementOverview = () => {
    const requestModal = useVisibility();

    const voteModal = useVisibility(false);
    const [voteModalIssuesKey, setVoteModalIssuesKey] = useState();
    const [voteModalCustomers, setVoteModalCustomers] = useState([]);

    const basicInfo = useUserBasicInfo();
    const canUserVote = useMemo(
        () =>
            !NO_VOTE_USERNAME_SUFFIXES.some(suffix =>
                basicInfo.userInfo.user.username.endsWith(suffix),
            ),
        [basicInfo],
    );

    const [refetchToken, refetch] = useReducer(state => state + 1, 0);
    useBreadcrumbButton(
        {
            label: t("general.create-request"),
            onClick: requestModal.show,
        },
        [],
    );
    const [tableParams, setTableParams] = useState([
        {
            page: 1,
            size: PAGE_SIZE_OPTIONS[0],
            sort: DEFAULT_SORT_BY,
        },
        {},
    ]);

    const fetchPage = useCallback(
        (current, pageSize, sortBy = DEFAULT_SORT_BY, filter) => {
            setTableParams([
                {
                    page: current,
                    size: pageSize,
                    sort: sortBy,
                },
                filter,
            ]);
        },
        [refetchToken],
    );

    const detailPanel = useDetailDrawerState();

    const productEnhancementsList = useListOfEnhancements(tableParams);

    const getData = dataType =>
        productEnhancementsList.loadable.state === STATUSES.hasValue &&
        productEnhancementsList.loadable.contents.data[dataType];
    const customers = getData("customers") || [];

    const afterPostVoteSuccess = useCallback(() => {
        refetch();
        voteModal.hide();
        detailPanel.hide();
    }, [detailPanel, voteModal]);

    const postVoteResource = usePostVoteResource({
        afterSuccess: afterPostVoteSuccess,
    });

    const postVote = useCallback(
        ({ issueKey, customerName }) =>
            postVoteResource.mutate({
                issueKey,
                customerName,
            }),
        [postVoteResource],
    );

    const handleVote = useCallback(
        (record, customersEligibleToVote) => {
            if (isEmpty(customersEligibleToVote)) return;
            if (customers.length > 1) {
                setVoteModalIssuesKey(record.key);
                setVoteModalCustomers(customersEligibleToVote);
                voteModal.show();
            } else {
                postVote({
                    issueKey: record.key,
                    customerName: customersEligibleToVote[0],
                });
            }
        },
        [customers, postVote, voteModal],
    );
    const afterTaskCreated = useCallback(() => {
        requestModal.hide();
        refetch();
    }, [refetch]);

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.detail"),
                        onClick: () => detailPanel.show(record),
                    },
                ]}
            />
        ),
        [detailPanel],
    );

    const onAttachmentDownload = useProductRequestAttachmentDownload();

    const voteSubmitDisabled = isLoading(postVoteResource);

    const votePanelExtra = record => {
        const customersEligibleToVote = getCustomersEligibleToVote(
            customers,
            record.customers,
        );
        const voted = isEmpty(customersEligibleToVote);
        return (
            <>
                <Property
                    label="Vote"
                    value={
                        voted
                            ? "Already voted"
                            : "Promote this idea by adding your vote."
                    }
                />
                <Button
                    disabled={voted || voteSubmitDisabled}
                    label="Add Vote"
                    type="primary"
                    onClick={() => handleVote(record, customersEligibleToVote)}
                />
            </>
        );
    };

    return (
        <>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                fixed
                columns={productEnhancementOverviewColumns({
                    onShowDetail: detailPanel.show,
                    onAttachmentDownload,
                    customers,
                })}
                fetchPage={fetchPage}
                loading={
                    productEnhancementsList.loadable.state === STATUSES.loading
                }
                rowKey="key"
                datasetSlicing="server"
                page={getData("page") || {}}
                preferencesType={
                    preferencesTypes.PRODUCT_ENHANCEMENT_OVERVIEW_TABLE
                }
                customPageSizeOptions={PRODUCT_ENHANCEMENT_PAGE_SIZE_OPTIONS}
                panelControl={detailPanel}
                renderPanelHeader={record => (
                    <>
                        <H4>{record.key}</H4>
                        <Gap />
                    </>
                )}
                renderPanelExtra={canUserVote ? votePanelExtra : () => <></>}
            />
            <CreateProductRequestModal
                visible={requestModal.visible}
                onClose={requestModal.hide}
                afterSave={afterTaskCreated}
            />
            <VoteModal
                visible={voteModal.visible}
                onClose={voteModal.hide}
                issueKey={voteModalIssuesKey}
                customers={voteModalCustomers}
                postVote={postVote}
                voteSubmitDisabled={voteSubmitDisabled}
            />
        </>
    );
};

ProductEnhancementOverview.propTypes = {
    defaultFilter: PropTypes.object,
};

export default ProductEnhancementOverview;
