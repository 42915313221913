import React from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";

/**
 * For override
 * @see https://github.com/aeolun/react-diff-viewer-continued
 */
const pfxStyle = {
    diffContainer: {
        background: "#F4F5F7",
        border: "1px solid #DFE1E6",
        borderRadius: "4px",
        display: "block",
        overflow: "clip",
    },

    contentText: {
        lineHeight: "20px!important",
    },
    gutter: {
        minWidth: "20px",
        background: "#F4F5F7",
    },
    // codeFold?: {},
    emptyLine: {
        background: "#F4F5F7",
    },

    wordDiff: {
        padding: 0,
    },
};

export const Diff = ({ ...diffProps }) => {
    return (
        <ReactDiffViewer
            useDarkTheme={false}
            styles={pfxStyle}
            compareMethod={DiffMethod.WORDS}
            splitView={false}
            {...diffProps}
        />
    );
};
