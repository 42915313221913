import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { engSupportUsersColumns } from "@/components/EngSupportUsers/EngSupportUsers.columns";
import {
    useDisableEngSupportUserMutation,
    useEngSupportUsersQuery,
} from "@/components/EngSupportUsers/loadables";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { isLoading } from "@/modules/loadable";
import React, { useCallback } from "react";

const preferencesType = preferencesTypes.ENG_SUPPORT_USERS_TABLE;

export const EngSupportUsersPage = () => {
    useFullWidthTableLayout();
    useHideBackButton();

    const engSupportUsersQuery = useEngSupportUsersQuery();

    const disableEngSupportUserMutation = useDisableEngSupportUserMutation({
        afterSuccess: engSupportUsersQuery.reload,
    });
    const dataSource = engSupportUsersQuery.loadable.valueMaybe() || [];
    const loading = isLoading(engSupportUsersQuery);

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: "Deactivate user",
                        confirmMessage: `Are you sure you want to deactivate the user ${record.username} on ${record.partitionSerialNumber}?`,
                        onConfirm: record =>
                            disableEngSupportUserMutation.mutate({
                                partitionSerialNumber:
                                    record.partitionSerialNumber,
                                pfxUserId: record.pfxUserId,
                            }),
                        color: "red",
                        visible: record.isActive,
                    },
                ]}
            />
        ),
        [],
    );

    return (
        <TableWithPreferencesManagement
            dataSource={dataSource}
            columns={engSupportUsersColumns}
            actionMenu={actionMenu}
            rowKey="projectId"
            datasetSlicing="local"
            preferencesType={preferencesType}
            loading={loading}
            // {...tableProps}
        />
    );
};
