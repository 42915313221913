import pipe from "lodash/fp/pipe";
import entries from "lodash/fp/entries";
import groupBy from "lodash/fp/groupBy";
import omit from "lodash/fp/omit";
import flatMap from "lodash/fp/flatMap";
import mapValues from "lodash/fp/mapValues";
import uniqBy from "lodash/fp/uniqBy";

// assetRole does not allow to set permissions with the same name per assetType

export const uniqAssetRolePermissions = pipe(
    entries,
    flatMap(([_type, permissions = []]) =>
        permissions.map(p => ({ ...p, _type })),
    ),
    uniqBy("name"),
    groupBy("_type"),
    mapValues(omit(["_type"])),
);

export const omitAssetRoleAssetType = omit(["assetType"]);
