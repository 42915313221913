import {
    Col,
    Forms,
    Row,
    useOptionsWithAddItem,
} from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

// TODO: use FieldGroup + FieldGroup.Col instead of Row/Col
// not working for row of Fields now, only supports 1 or 2 columns layout
const {
    pmValidators: {
        isRequired,
        createMaxLengthValidation,
        createForbidValuesValidation,
    },
    validators: { failOnFirst },
} = Forms;

export const defaultDecimalSeparators = [
    { value: ".", label: "." },
    { value: ",", label: "," },
];

export const defaultSeparators = [
    { label: "` (Backquote)", value: "`" },
    { label: ", (Comma)", value: "," },
    { label: "| (Pipe)", value: "|" },
    { label: "; (Semicolon)", value: ";" },
    { label: "\t (Tab)", value: "\t" },
];

export const defaultParserConfigValues = {
    separator: ",",
    quoteChar: '"',
    escapeChar: "\\",
    decimalSeparator: ".",
    dateFormat: "yyyy-MM-dd",
    useVirtualHeader: false,
};

export const FileParsingFields = ({
    disabled,
    allowClear,
    hideUseVirtualHeader,
}) => {
    const [separators, addSeparator] = useOptionsWithAddItem(defaultSeparators);
    const maxLength1 = useMemo(() => createMaxLengthValidation(1), []);
    const required1CharValidator = useMemo(
        () => failOnFirst([isRequired, maxLength1]),
        [maxLength1],
    );
    const forbidPresentSeparatorsValidation = useMemo(
        () =>
            createForbidValuesValidation(
                separators.map(({ value }) => value),
                t("general.validation.already-present"),
            ),
        [separators],
    );
    const addItemValidator = useMemo(
        () =>
            failOnFirst([
                isRequired,
                maxLength1,
                forbidPresentSeparatorsValidation,
            ]),
        [forbidPresentSeparatorsValidation, maxLength1],
    );

    return (
        <>
            <Row type="flex" justify="space-between" gutter={16}>
                <Col span={4}>
                    <Forms.Fields.SelectWithAddItem
                        name="separator"
                        label={t("file-parsing.separator.label")}
                        placeholder={t("file-parsing.separator.label")}
                        disabled={disabled}
                        validator={required1CharValidator}
                        options={separators}
                        required
                        onAddNewItem={addSeparator}
                        addItemFieldProps={{
                            validator: addItemValidator,
                        }}
                        allowClear={allowClear}
                    />
                </Col>
                <Col span={4}>
                    <Forms.Fields.Input
                        name="quoteChar"
                        label={t("file-parsing.quote-char.label")}
                        placeholder={t("file-parsing.quote-char.label")}
                        disabled={disabled}
                        validator={required1CharValidator}
                        allowClear={allowClear}
                    />
                </Col>
                <Col span={4}>
                    <Forms.Fields.Input
                        name="escapeChar"
                        label={t("file-parsing.escape-char.label")}
                        placeholder={t("file-parsing.escape-char.label")}
                        disabled={disabled}
                        allowClear={allowClear}
                    />
                </Col>
                <Col span={4}>
                    <Forms.Fields.Select
                        name="decimalSeparator"
                        label={t("file-parsing.decimal-separator.label")}
                        placeholder={t("file-parsing.decimal-separator.label")}
                        disabled={disabled}
                        validator={required1CharValidator}
                        options={defaultDecimalSeparators}
                        allowClear={allowClear}
                    />
                </Col>
                <Col span={4}>
                    <Forms.Fields.Input
                        name="dateFormat"
                        label={t("file-parsing.date-format.label")}
                        placeholder={t("file-parsing.date-format.label")}
                        disabled={disabled}
                        validator={isRequired}
                        allowClear={allowClear}
                    />
                </Col>
            </Row>
            {!hideUseVirtualHeader && (
                <Forms.Fields.InvertedCheckbox
                    name="useVirtualHeader"
                    label={t("file-parsing.use-virtual-header.label")}
                />
            )}
        </>
    );
};

FileParsingFields.propTypes = {
    disabled: PropTypes.bool,
    allowClear: PropTypes.bool,
    hideUseVirtualHeader: PropTypes.bool,
};
