import {
    ButtonMenu,
    Forms,
    Gap,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { useCreateApiTokenMutation } from "./loadables";

const { useForm, Fields, pmValidators } = Forms;

export const ModalCreateApiToken = ({
    visible,
    onClose,
    onSuccess,
    accountId,
}) => {
    const createApiTokenMutation = useCreateApiTokenMutation({
        accountId,
        afterSuccess: ({ token }) => {
            onSuccess?.({ token });
            onClose();
        },
    });

    const onSubmit = ({ values }) => {
        return createApiTokenMutation.mutate({
            comment: values.tokenName,
        });
    };

    const { formId, Form } = useForm({
        onSubmit,
    });

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("api-token.modal.create.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Form>
                        <Fields.Input
                            autoFocus
                            label={t("api-token.modal.create.form.tokenName")}
                            name="tokenName"
                            inputWidth="max"
                            required
                            validator={pmValidators.isRequired}
                        />
                    </Form>
                    <Gap size="medium" />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    formId,
                                    label: t("general.create"),
                                    type: "primary",
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

ModalCreateApiToken.propTypes = {
    accountId: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
};
