import { DataObject } from "@/utils/dataObjects/DataObject.class";

export class TemplateDeploymentEntity extends DataObject {
    /**
     *
     * @type {number}
     */
    accountId = null;
    /**
     *
     * @type {TemplateEntity}
     */
    template = null;
    /**
     *
     * @type {string}
     */
    destination = null;
    /**
     *
     * @type {number}
     */
    destinationId = null;
    /**
     *
     * @type {string}
     */
    templateVersion = null;
}
