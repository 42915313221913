import { Forms, Switch, Tooltip } from "@/components/DesignSystem";
import { useDataEncryption } from "@/components/Integrations/hooks/useEncryptData.hook";
import { t } from "@/translations";
import { isEmpty } from "lodash";
import React from "react";
const { Fields } = Forms;

export const EncryptedField = ({
    type,
    formId,
    setValues,
    disabled: disabledProp,
    ...props
}) => {
    const dataEncryption = useDataEncryption({
        onSuccess: response => setValues({ [props.name]: response }),
    });

    const fieldValue = Forms.useFieldValue({
        formId,
        name: props.name,
    });

    const isEncryptionDisabled =
        disabledProp ||
        isEmpty(fieldValue) ||
        dataEncryption.isEncryptedFn(fieldValue) ||
        dataEncryption.isLoading;

    const FormFieldType = type ?? Fields.TextArea;

    const value = dataEncryption.isEncryptedFn(fieldValue);

    return (
        <>
            <FormFieldType
                {...props}
                disabled={props?.disabled || dataEncryption.isLoading}
            />

            {dataEncryption.isSupported && (
                <Switch
                    textOn={
                        <Tooltip title={t("general.encrypt.tooltip")}>
                            {t("general.encrypted")}
                        </Tooltip>
                    }
                    textOff={
                        <Tooltip title={t("general.encrypt.tooltip")}>
                            {t("general.not-encrypted")}
                        </Tooltip>
                    }
                    disabled={isEncryptionDisabled}
                    onChange={isEnabled =>
                        isEnabled && dataEncryption.encrypt(fieldValue)
                    }
                    value={value}
                />
            )}
        </>
    );
};
