import { Button } from "@/components/DesignSystem/Button";
import PropTypes from "prop-types";
import React from "react";

export const LoggerLevelsButtonGroup = ({
    levels,
    component,
    effectiveLevel,
    onChange,
}) => {
    return levels.map(level => {
        const color = effectiveLevel === level ? "primary" : "ghost";
        return (
            <Button
                htmlType="button"
                size="small"
                type={color}
                onClick={() => onChange(component, level)}
                key={level}
                label={level}
            />
        );
    });
};

LoggerLevelsButtonGroup.propTypes = {
    levels: PropTypes.array.isRequired,
    component: PropTypes.string.isRequired,
    effectiveLevel: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
