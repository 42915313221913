import { flatMap, get, pick } from "lodash/fp";
import isEmpty from "lodash/isEmpty";
import pickBy from "lodash/pickBy";
import union from "lodash/union";
import unionBy from "lodash/unionBy";
import values from "lodash/values";
import { toApiMapper } from "../../Mappers/form/mapper.utils";
import { getFixedAttributes, reorderAttributes } from "../packagesPanel.utils";
import { FE_STEP } from "./constants";
import {
    calculateCustomFieldNames,
    calculateSignificantFields,
    createAttributes,
    createBusinessKeys,
    createMapper,
    createMatrixAttributes,
    getCustomKeys,
    getValueType,
    prepareValues,
} from "./stateReducer";

export const masterMappingFunc = (stepDefinition, data) => {
    const mandatoryAndSignificantFieldsMap = {
        ...data.mandatoryFieldsMap,
        ...data.significantFields,
    };

    const preparedOptionalFields = reorderAttributes(
        data.optionalFields.filter(f => !f.skip),
        getFixedAttributes(mandatoryAndSignificantFieldsMap),
    );

    const customFields = calculateCustomFieldNames(
        preparedOptionalFields,
        stepDefinition,
    );

    const significantFields = calculateSignificantFields(
        data.significantFields,
        stepDefinition,
    );

    const mapper = createMapper(
        union(values(mandatoryAndSignificantFieldsMap), preparedOptionalFields),
        data.parserConfig,
    );

    return {
        fileId: data.fileId || stepDefinition.demoDataFileId,
        parserConfig: data.parserConfig,
        significantFields,
        customFields,
        mapper,
        ...mappingOptionData(data),
        validationSchema: data.validationSchema,
    };
};

export const createDataSourceMappingFunc = (stepDefinition, data) => {
    const {
        userDefinedCustomFieldValues = [],
        optionalFields = [],
        validationSchema = [],
    } = data;
    const optionalFieldsNotSkipped = optionalFields.filter(f => !f.skip);

    const customFields = getCustomKeys(
        data.significantFields,
        unionBy(userDefinedCustomFieldValues, optionalFieldsNotSkipped, "name"),
    );

    const currencySettings = data.currencySettings;
    const mapper = createMapper(
        union(
            values(data.mandatoryFieldsMap),
            optionalFieldsNotSkipped,
            values(data.significantFields),
            userDefinedCustomFieldValues,
        ),
        data.parserConfig,
    );

    return {
        fileId: data.fileId || stepDefinition.demoDataFileId,
        parserConfig: data.parserConfig,
        customFields,
        userDefinedCustomFields: data.userDefinedCustomFields,
        baseCurrency: currencySettings.baseCurrency,
        currencies: [
            currencySettings.baseCurrency,
            ...currencySettings.reportingCurrencies,
        ],
        mapper,
        ...mappingOptionData(data),
        validationSchema,
    };
};

export const dataUploadMappingFunc = (stepDefinition, data) => {
    return {
        fileId: data.fileId,
        parserConfig: data.parserConfig,
        mapper: toApiMapper(data.definition, data.parserConfig, data.fileInfo),
        config: data.config,
        mapperProperties: {
            convertEmptyStringToNull: !!data.convertEmptyStringToNull,
        },
        usePricingParameterName: data.usePricingParameterName,
        connectionId: data.connectionId,
        ...mappingOptionData(data),
        validationSchema: data.validationSchema,
    };
};

export const createExtensionMappingFunc = (stepDefinition, data) => {
    const mandatoryFieldNames = getFixedAttributes(data.mandatoryFields);
    const allFields = unionBy(
        data.mandatoryFields,
        reorderAttributes(
            (data.optionalFields || []).filter(f => !f.skip),
            mandatoryFieldNames,
        ),
        "name",
    );
    return {
        fileId: data.fileId,
        parserConfig: data.parserConfig,
        uniqueName: data.userInputs.uniqueName,
        label: data.userInputs.label,
        attributes: createAttributes(allFields),
        businessKey: createBusinessKeys(allFields),
        mapper: createMapper(allFields, data.parserConfig),
        ...mappingOptionData(data),
        validationSchema: data.validationSchema,
    };
};

export const createPPMappingFunc = (step, data) => {
    if (step.ppType === FE_STEP.PP_SIMPLE || step.ppType === FE_STEP.PP_RANGE) {
        return {
            fileId: data.fileId,
            parserConfig: data.parserConfig,
            label: data.userInputs.label,
            mapper: createMapper(
                values(data.userInputs.fields),
                data.parserConfig,
            ),
            valueType: step.valueType || data.valueType,
            ...mappingOptionData(data),
        };
    }

    const allValues = prepareValues(data.keyValues, data.attributes);

    return {
        fileId: data.fileId,
        parserConfig: data.parserConfig,
        label: data.userInputs?.inputs?.label,
        valueType: getValueType(data.userInputs.keyValues),
        mapper: createMapper(allValues, data.parserConfig),
        attributes: createMatrixAttributes(allValues),
        ...mappingOptionData(data),
        validationSchema: data.validationSchema,
    };
};

export const createCompetitionMappingFunc = (stepDefinition, data) => {
    const optionalFieldsNotSkipped = data.optionalFields?.filter(f => !f.skip);

    return {
        fileId: data.fileId || stepDefinition.demoDataFileId,
        parserConfig: data.parserConfig,
        data: data.config,
        mapper: createMapper(
            union(values(data.mandatoryFieldsMap), optionalFieldsNotSkipped),
            data.parserConfig,
        ),
        ...mappingOptionData(data),
        validationSchema: data.validationSchema,
    };
};

export const integrationCsvMapperMappingFunc = ({ name, type }, data) => {
    return {
        name,
        type,
        mapper: toApiMapper(data.mapper, data.parserConfig, data.fileInfo),
        config: data.config,
        mapperProperties: {
            convertEmptyStringToNull: !!data.convertEmptyStringToNull,
        },
        params: {
            delimiter: data.parserConfig?.separator || ",",
            quoteCharacter: data.parserConfig?.quoteChar || '"',
            escapeCharacter: data.parserConfig?.escapeChar ?? "",
            decimalSeparator: data.parserConfig?.decimalSeparator || ".",
            objectType: data.entityType,
            entityName: data.entityName,
            usePricingParameterName: data.usePricingParameterName,
            useVirtualHeader: data.parserConfig?.useVirtualHeader,
        },
        ...mappingOptionData(data),
        validationSchema: (data.fileInfo?.columns ?? []).map(row => ({
            name: row.name,
            type: row.type,
        })),
    };
};

export const basicIntegrationMappingFunc = ({ type, name }, data) => {
    return {
        type,
        name,
        ...data,
    };
};

export const genericFormMappingFunc = (step, data) => {
    const definedFieldNames = flatMap(
        ({ name, subFields, table }) =>
            [name]
                .concat(subFields?.map?.(get("name")) ?? [])
                .concat(table?.name || []),
        step.form,
    );
    const definedFieldValues = pick(definedFieldNames, data);

    return definedFieldValues;
};

const mappingOptionData = ({
    dataUploadName,
    instanceId,
    connectionId,
    templateUniqueName,
    integrationUniqueName,
    properties,
    label,
    createDataUpload,
    runIMTemplate,
    connectionProperties,
}) => {
    const result = {};

    if (dataUploadName && createDataUpload) {
        result.dataUploadName = dataUploadName;
    }

    const imData = pickBy(
        {
            instanceId,
            connectionId,
            templateUniqueName,
            integrationUniqueName,
            properties,
            connectionProperties,
            label,
        },
        v => v !== undefined,
    );

    if (!isEmpty(imData) && runIMTemplate) {
        result.imTemplateDeployData = imData;
    }

    return result;
};
