import { axiosService } from "../../../../../axios";

export const logFileService = {
    download(instanceId, range) {
        return axiosService.get(`api/instances/${instanceId}/logfile`, {
            headers: { range: range },
        });
    },

    getArchivedLogFiles(instanceId) {
        return axiosService.get(
            `api/instances/${instanceId}/archived-log-files`,
        );
    },

    downloadArchivedLogFile(instanceId, archivedLogFile) {
        return axiosService.get(
            `api/instances/${instanceId}/archived-log-files/${archivedLogFile}`,
            {
                responseType: "blob",
                headers: {
                    Accept: "application/x-gzip",
                },
            },
        );
    },
};
