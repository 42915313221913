import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { SidebarMenuSelect } from "../SidebarMenuSelect";

const filterOption = (input, option) =>
    option.children.toLowerCase().includes(input.toLowerCase());

export const AccountSelect = ({ onChange, value, accounts, disabled }) => {
    const options = useMemo(
        () =>
            accounts.map(account => ({
                label: account.name,
                value: account.id,
            })),
        [accounts],
    );

    return (
        <SidebarMenuSelect
            value={value}
            onChange={onChange}
            filterOption={filterOption}
            options={options}
            disabled={disabled}
            dataTest="accounts"
        />
    );
};

AccountSelect.propTypes = {
    accounts: PropTypes.array.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};
