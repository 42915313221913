import { Input, Option, Select } from "@/components/DesignSystem";
import { StopOnKeyDownPropagation } from "@/components/StopOnKeyDownPropagation";
import { t } from "@/translations";
import React from "react";
import { DELETE_OP } from "./SetupSimulation.component";
import styles from "./SetupSimulation.style.less";

const operators = [
    {
        name: "greaterOrEqual",
        label: "greater than or equal to",
    },
    {
        name: "equals",
        label: "equals (match case)",
    },
    {
        name: "notEqual",
        label: "not equal (match case)",
    },
    {
        name: "isNull",
        label: "is null",
    },
    {
        name: "greaterThan",
        label: "greater than",
    },
    {
        name: "lessThan",
        label: "less than",
    },
    {
        name: "lessOrEqual",
        label: "less than or equal to",
    },
    {
        name: "notNull",
        label: "is not null",
    },
    {
        name: "inSet",
        label: "is one of",
    },
    {
        name: "notInSet",
        label: "is not one of",
    },
    {
        name: "iContains",
        label: "contains / start with /end with",
    },

    {
        name: "not",
        label: "not",
    },
    {
        name: "and",
        label: "and",
    },
    {
        name: "or",
        label: "or",
    },
];

export const filterColumns = (
    fieldOptions = [],
    onCriteriaSelect,
    onCriteriaValue,
    onCriteriaClear,
    onOperatorSelect,
) => [
    {
        label: t("packages.simulation.table.header.source-field"),
        name: "Source",
        render: (text, record) => (
            <div className={styles.error}>
                <div className={styles.inlineContainer}>
                    <Select
                        onChange={() => onCriteriaClear(record, DELETE_OP)}
                        styles={{ borderColor: "red" }}
                        className={styles.error}
                        onSelect={value => onCriteriaSelect(record, value)}
                        value={
                            fieldOptions
                                ?.map(f => f.name)
                                ?.includes(record.fieldName) && record.fieldName
                        }
                    >
                        {fieldOptions?.map(o => (
                            <Option title={o.name} key={o.name} value={o.name}>
                                {o.name}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
        ),
    },
    {
        label: t("packages.simulation.table.header.operator"),
        name: "operator",
        render: (text, record) => (
            <div className={styles.error}>
                <div className={styles.inlineContainer}>
                    <Select
                        styles={{ borderColor: "red" }}
                        className={styles.error}
                        onSelect={value => onOperatorSelect(record, value)}
                        value={
                            operators
                                .map(o => o.name)
                                .includes(record.operator) && record.operator
                        }
                    >
                        {operators.map(o => (
                            <Option title={o.label} key={o.name} value={o.name}>
                                {o.label}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
        ),
    },
    {
        label: t("packages.simulation.table.header.filter-value"),
        name: "value",
        render: (text, record) => (
            <StopOnKeyDownPropagation>
                <Input
                    value={record.value}
                    onChange={e =>
                        onCriteriaValue(record, e.target.value?.trim())
                    }
                />
            </StopOnKeyDownPropagation>
        ),
    },
];
