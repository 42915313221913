import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { map } from "lodash/fp";

export const usePartitionQueueEventsQuery = ({
    partitionId,
    canFetch = true,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch || !partitionId
                ? Promise.resolve(undefined)
                : axiosService
                      .get(`/api/partitions/${partitionId}/queue-events`)
                      .then(getData),
        [axiosService, canFetch, partitionId],
    );
};

export const usePartitionQueueEventsDelete = ({
    partitionId,
    afterSuccess,
}) => {
    const { axiosService } = useDic();

    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async ({ id }) =>
            axiosService
                .delete(`/api/partitions/${partitionId}/queue-events/${id}`)
                .then(getData)
                .then(res => {
                    afterSuccessCurrent(res);
                    return res;
                }),
        [axiosService, partitionId, afterSuccessCurrent],
        "partition.upload-queue.message.force-stopped",
    );
};
export const usePartitionQueueScheduledEventsQuery = ({
    partitionId,
    canFetch = true,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch || !partitionId
                ? Promise.resolve(undefined)
                : axiosService
                      .get(
                          `/api/partitions/${partitionId}/queue-scheduled-events`,
                      )
                      .then(getData)
                      .then(
                          map(e => ({
                              ...e,
                              status: "WAITING",
                              _scheduled: true,
                          })),
                      ),
        [axiosService, canFetch, partitionId],
    );
};

export const usePartitionQueueScheduledEventsDelete = ({
    partitionId,
    afterSuccess,
}) => {
    const { axiosService } = useDic();

    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async ({ id }) =>
            axiosService
                .delete(
                    `/api/partitions/${partitionId}/queue-scheduled-events/${id}`,
                )
                .then(getData)
                .then(res => {
                    afterSuccessCurrent(res);
                    return res;
                }),
        [axiosService, partitionId, afterSuccessCurrent],
        "partition.upload-queue.message.removed",
    );
};
