import { tap } from "@/components/Connections/loadables";
import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useMutationLoadableWithNotification } from "@/modules/loadable";
import { ModalProviderPropsPropType } from "@/modules/modal/CustomModal";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

export const MAX_PARTITIONS = 2147483647;

export const PartitionLimitModal = ({
    record = {},
    afterSuccess,
    modalProviderProps,
}) => {
    const { axiosService } = useDic();
    const partitionsLimitMutation = useMutationLoadableWithNotification(
        async values =>
            axiosService
                .post(`/api/partition-limit`, values)
                .then(tap(modalProviderProps.onClose))
                .then(tap(afterSuccess)),
        [afterSuccess, axiosService, modalProviderProps.onClose],
    );
    const { formId, handleSubmit, setValues } = Forms.useForm({
        initialValues: {
            max: record.max,
            _unlimited: record.max === null,
        },
        onSubmit: ({ values: { max, _unlimited } }) =>
            partitionsLimitMutation.mutate({
                ...record,
                max: _unlimited ? null : max,
            }),
    });
    const unlimited = Forms.useFieldValue({ formId, name: "_unlimited" });
    const onMaxChange = useCallback(
        ({ value }) => {
            if (value > MAX_PARTITIONS)
                setValues({
                    _unlimited: true,
                    max: MAX_PARTITIONS,
                });
        },
        [setValues],
    );

    return (
        <Modal {...modalProviderProps} width={650}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("partition-limit.edit.title", {
                        account: record.projectName,
                    })}
                />
                <UnityLayout.Content padding={[true, true]}>
                    <Forms.Form formId={formId} onSubmit={handleSubmit}>
                        <Forms.FieldGroup width="max" inputWidth="max">
                            <Forms.Fields.InputNumber
                                name="max"
                                label={t("partition-limit.column.max")}
                                required={!unlimited}
                                disabled={unlimited}
                                onChange={onMaxChange}
                                validator={
                                    unlimited
                                        ? undefined
                                        : Forms.validators.failOnFirst([
                                              Forms.pmValidators.isRequired,
                                              Forms.pmValidators.min(0),
                                          ])
                                }
                            />
                            <Forms.Fields.Checkbox
                                name="_unlimited"
                                label={t(
                                    "im-instances-limit.edit.unlimited.label",
                                )}
                            />
                        </Forms.FieldGroup>
                    </Forms.Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.save"),
                                    formId,
                                    type: "primary",
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: modalProviderProps.onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

PartitionLimitModal.propTypes = {
    record: PropTypes.object,
    afterSuccess: PropTypes.func.isRequired,
    modalProviderProps: ModalProviderPropsPropType(),
};
