import NewConnectionModal from "@/components/Connections/NewConnection.modal";
import { Button, Forms, Gap, Link } from "@/components/DesignSystem";
import { useIncludesRightsForPartition } from "@/components/hooks/useIncludesRightsForPartition.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { useConnectionsByTypeQuery } from "@/components/Packages/PackageTableDefinitionPanel/components/IntegrationInit/loadables";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { useSetWarningAlert } from "@/components/PageLayout/useSetAlert.hook";
import { useMapLoadableMemoized } from "@/modules/loadable/index";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";

const { SubmitButton, useForm, Fields, pmValidators } = Forms;

const IntegrationConnection = ({
    step,
    globalState,
    instanceId = globalState?.instanceId,
    onNext,
    onBack,
}) => {
    const {
        connectionType = globalState.mappingTemplate?.connectionType,
        connectionPlaceholder = globalState.mappingTemplate
            ?.connectionPlaceholder,
    } = step;
    const isSFTP = connectionType?.toUpperCase() === "SFTP";
    const setHeaderWarningAlert = useSetWarningAlert();

    const connectionsResource = useConnectionsByTypeQuery({
        instanceId,
        connectionType,
        afterSuccess: connections => {
            if (isEmpty(connections)) {
                setHeaderWarningAlert(
                    <>
                        {t("integration-templates.init.no-connections", {
                            newConnection: (
                                <Link onClick={newConnectionModal.show}>
                                    {t(
                                        "integration-templates.init.no-connections.new-connection",
                                    )}
                                </Link>
                            ),
                        })}
                    </>,
                );
            }
        },
    });
    const { Form } = useForm({
        initialValues: {
            connection: globalState.connectionProperties?.value,
        },
        onSubmit: ({ values: { connection } }) =>
            onNext({
                connectionProperties: {
                    name: connectionPlaceholder,
                    value: connection,
                },
            }),
    });

    const newConnectionModal = useVisibility(false);

    const connectionsOptionsLoadable = useMapLoadableMemoized(
        connectionsResource.loadable,
        connections =>
            connections.map(connection => ({
                label: connection.name,
                value: connection.name,
            })),
    );

    const hasEditRights = useIncludesRightsForPartition(
        INTEGRATION_EDIT_PERMISSIONS,
    );

    return (
        <>
            <Form>
                <Fields.Select
                    label={t(
                        isSFTP
                            ? "integration-templates.init.form.sftp-connection"
                            : "integration-templates.init.form.connection",
                    )}
                    name="connection"
                    required
                    validator={pmValidators.isRequired}
                    allowClear={false}
                    // onChange={handleConnectionsChange}
                    {...getLoadableSelectProps(connectionsOptionsLoadable)}
                />
                {hasEditRights && (
                    <Button
                        size="small"
                        icon={Plus}
                        label={t("connection-list.button.new")}
                        onClick={newConnectionModal.show}
                    />
                )}
                <Gap />
                <SubmitButton
                    type="primary"
                    label={t("package-data-upload.button.next")}
                />
                <BackButton onBack={onBack} />
            </Form>
            <NewConnectionModal
                initValues={{
                    type: connectionType,
                }}
                instanceId={instanceId}
                visible={newConnectionModal.visible}
                onSave={({ deployedConnectionId }) => {
                    connectionsResource.reload();
                    newConnectionModal.hide();
                    // TODO: chain reload and selection
                    // setValues({ connection: deployedConnectionId });
                    // handleConnectionsChange({ value: deployedConnectionId });
                }}
                onCancel={newConnectionModal.hide}
            />
        </>
    );
};

IntegrationConnection.propTypes = {
    step: PropTypes.object.isRequired,
    instanceId: PropTypes.number.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
};

export default IntegrationConnection;
