import { defaultQsOptions } from "@/services/constants";
import qs from "qs";
import { axiosService } from "../axios";

const BASE_URL = `/api/marketplace-items`;
export const marketplaceItemsService = {
    getItems({ accountId, category }) {
        const queryStr = qs.stringify(
            { accountId, category },
            defaultQsOptions,
        );
        return axiosService.get(`${BASE_URL}${queryStr}`);
    },
    getCategories() {
        return axiosService.get(`${BASE_URL}/categories`);
    },
};
