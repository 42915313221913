import EditInstanceForm from "@/components/Integrations/EditInstanceForm";
import { useLeftAlignedLayout } from "@/components/hooks/useContentLayout.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React from "react";

export const IntegrationSettingsPage = () => {
    const { instanceId, accountId } = useAccountAppParams();
    useLeftAlignedLayout();

    return <EditInstanceForm instanceId={instanceId} accountId={accountId} />;
};
