import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";

export const columns = onDelete => [
    {
        type: fieldTypes.TEXT,
        label: t("account-admin-group-detail.header.user-name"),
        name: "username",
        className: "pmTable__column--withActionButton",
        render: (text, record) => (
            <>
                {text}
                <ActionButton
                    record={record}
                    items={[
                        {
                            title: t("account-admin-group-detail.header.title"),
                            confirmMessage: t(
                                "account-admin-group-detail.header.message",
                                {
                                    username: record.username,
                                },
                            ),
                            onConfirm: onDelete,
                        },
                    ]}
                />
            </>
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("account-admin-group-detail.header.email"),
        name: "email",
    },
];
