/**
 * @deprecated
 */
export class DataObject {
    static create(data) {
        const instance = new this();
        const enhanced = this.mapOnCreate(data);

        Object.assign(instance, enhanced);

        return instance;
    }

    static mapOnCreate(data) {
        return data;
    }

    clone(data) {
        const clonedInstance = new this.constructor();
        Object.assign(clonedInstance, this, data);
        return clonedInstance;
    }
}
