import Changed from "@/components/DateTime/Changed";
import FromNowFormatter from "@/components/DateTime/FromNowFormatter";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { useDeleteServerMutation } from "@/components/Servers/loadables";
import { t } from "@/translations";
import React, { useCallback } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import Flag from "../Flag";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";

const preferencesType = preferencesTypes.SERVERS_LIST_TABLE;

const FORBIDDEN_FLAGS = ["apec", "apac"];

const columns = [
    {
        type: fieldTypes.TEXT,
        label: t("server-list.header.server-name"),
        name: "name",
    },
    {
        type: fieldTypes.TEXT,
        canFilter: false,
        label: t("server-list.header.location"),
        name: "location",
        render: (text, record) => {
            return (
                !FORBIDDEN_FLAGS.includes(record.location) && (
                    <Flag
                        id={record.location}
                        title={record.location}
                        flag={record.location}
                    />
                )
            );
        },
    },
    {
        type: fieldTypes.TEXT,
        canFilter: false,
        label: t("server-list.header.created-at"),
        name: "createdAt",
        render: (text, record) => <FromNowFormatter date={record.createdAt} />,
    },
    {
        type: fieldTypes.TEXT,
        canFilter: false,
        label: t("server-list.header.created-by"),
        name: "createdBy",
    },
    {
        type: fieldTypes.TEXT,
        canFilter: false,
        label: t("server-list.header.updated-at"),
        name: "updatedAt",
        render: (text, record) => <Changed key={record.id} data={record} />,
    },
];

const ServersList = () => {
    const { locationRouterService, overviewAppLocations, serverService } =
        useDic();

    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort = "name,asc", filter) =>
            serverService.serversList({
                queryParams: { page, size, sort, filter },
            }),
        [serverService],
    );

    const deleteMutation = useDeleteServerMutation({ afterSuccess: reload });

    useBreadcrumbButton(
        {
            label: "data-uploads-list.button.new-server",
            onClick: () =>
                locationRouterService.navigate(
                    overviewAppLocations.newServerLocation,
                ),
            permissions: ["servers.edit"],
        },
        [],
    );

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("server-list.modal.title"),
                        confirmMessage: t("server-list.modal.message", {
                            name: record.name,
                        }),
                        permission: ["servers.edit"],
                        onConfirm: deleteMutation.mutate,
                        color: "red",
                    },
                ]}
            />
        ),
        [deleteMutation],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            columns={columns}
            defaultSort={{
                fieldName: "name",
                sortDir: "ascending",
            }}
            fixed
            rowKey="id"
            datasetSlicing="server"
            preferencesType={preferencesType}
            exportUrl="/api/servers/list/export"
            {...tableProps}
        />
    );
};

export default ServersList;
