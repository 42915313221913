import { useQueryLoadable } from "@/modules/loadable";
import { get, map, pipe } from "lodash/fp";
import { accountsService } from "../../apps/accountApp/services/accounts.service";

export const useAccounts = () =>
    useQueryLoadable(() => {
        return accountsService.fetchAccounts().then(get("data"));
    }, []);

const accountToOption = ({ id, name }) => ({ value: id, label: name });
export const useAccountOptionsResource = () =>
    useQueryLoadable(() => {
        return accountsService
            .fetchAccounts()
            .then(pipe(get("data"), map(accountToOption)));
    }, []);
