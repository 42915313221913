import React from "react";
export const TemplateDetailGrid = ({ children }) => {
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr",
                gap: "24px",
            }}
        >
            {children}
        </div>
    );
};
