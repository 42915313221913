import { Forms } from "@/components/DesignSystem";
import { t } from "@/translations";
import { useCallback, useMemo } from "react";

export const usePartitionsListValidator = ({ partitionsList }) => {
    return useCallback(
        value =>
            value && partitionsList.includes(value)
                ? Forms.error(
                      t(
                          "partition-request.validation.partition-name-already-exists",
                      ),
                  )
                : Forms.success(),
        [partitionsList],
    );
};
export const usePartitionNameValidator = ({ nextValidator }) => {
    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                Forms.pmValidators.createMinLengthValidation(2),
                Forms.pmValidators.createMaxLengthValidation(30),
                Forms.pmValidators.createPatternValidator(
                    /^([a-z0-9_\-'])+$/,
                    t("common.validation.invalidChar") + ` a-z0-9_-`,
                ),
                nextValidator,
            ]),
        [nextValidator],
    );
};
