import {
    useDeleteFavoriteMutation,
    useSaveFavoriteMutation,
} from "@/components/Marketplace/components/FavoriteButton/loadables";
import { useParentState } from "@/components/hooks/useParentState.hook";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import { ReactComponent as FavoriteTagActive } from "./icons/tag-filled.svg";
import { ReactComponent as FavoriteTag } from "./icons/tag.svg";

const FavoriteResult = {
    SAVED: "SAVED",
    DELETED: "DELETED",
};

export const useFavoriteAction = ({
    favoriteId,
    favoriteType,
    onToggle,
    isActive: isActiveParent = false,
}) => {
    const [isActive, setActive] = useParentState(isActiveParent);

    const saveFavoriteMutation = useSaveFavoriteMutation({
        afterSuccess: () => {
            setActive(true);
            onToggle?.({
                result: FavoriteResult.SAVED,
                favoriteId,
                favoriteType,
            });
        },
    });
    const deleteFavoriteMutation = useDeleteFavoriteMutation({
        afterSuccess: () => {
            setActive(false);
            onToggle?.({
                result: FavoriteResult.DELETED,
                favoriteId,
                favoriteType,
            });
        },
    });

    const onClick = () => {
        if (
            isLoading(deleteFavoriteMutation) ||
            isLoading(saveFavoriteMutation)
        ) {
            return;
        }

        isActive
            ? deleteFavoriteMutation.mutate({
                  id: favoriteId,
                  type: favoriteType,
              })
            : saveFavoriteMutation.mutate({
                  id: favoriteId,
                  type: favoriteType,
              });
        //
        setActive(!isActive);
    };

    return {
        toggleFavorite: onClick,
        isActive,
        FavoriteIcon: isActive ? FavoriteTagActive : FavoriteTag,
        title: t(
            isActive
                ? "marketplace.item.favorites.remove"
                : "marketplace.item.favorites.add",
        ),
    };
};
