import { useCallback, useEffect, useState } from "react";

export function useAsyncCall(fn, deps = []) {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState();

    const handleError = useCallback(error => {
        setError(error);

        if (process.env.NODE_ENV !== "production") {
            console.error("useAsyncCall: an error occurred", error);
        }
    }, []);

    useEffect(() => {
        fn()
            .then(setResponse)
            .catch(handleError)
            .finally(() => setIsLoading(false));
    }, deps);

    return {
        isLoading,
        error,
        response,
    };
}

export function useAsyncCallTrigger(fnToTrigger) {
    const [trigger, setTrigger] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState();

    const handleError = useCallback(error => {
        setError(error);

        if (process.env.NODE_ENV !== "production") {
            console.error("useAsyncCallTrigger: an error occurred", error);
        }
    }, []);

    useEffect(() => {
        if (!trigger || isLoading) {
            return;
        }

        setIsLoading(true);
        setError(null);
        setTrigger(false);

        fnToTrigger()
            .then(setResponse)
            .catch(handleError)
            .finally(() => setIsLoading(false));
    }, [trigger]);

    return [
        {
            isLoading,
            error,
            response,
        },
        () => setTrigger(true),
    ];
}
