import { message } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { upgradeEventsService } from "../../services/upgradeEvents.service";
import { upgradeSchedulingService } from "../../services/upgradeSchedulingService";
import { useFetchPage } from "../PagableTable/useFetchPage.hook";
import { clusterUpgradeOverviewColumns } from "./ClusterUpgradeOverviewTable.columns";

const preferencesType = preferencesTypes.CLUSTER_UPGRADE_OVERVIEW_ACCOUNT;

export const ClusterUpgradeOverviewAccount = ({ accountId, ...rest }) => {
    const [clusterUpgradeDeleted, setClusterUpgradeDeleted] = useState(false);

    const {
        locationRouterService,
        accountAppLocations: { upgradesEditLocation },
    } = useDic();

    const [tableProps] = useFetchPage(
        (page, size, sort, filter) => {
            return upgradeEventsService.getProjectClusterUpgradeEvents(
                page,
                size,
                sort,
                filter,
                accountId,
            );
        },
        [accountId, clusterUpgradeDeleted],
    );

    const deleteUpgradeEvent = eventId => {
        upgradeSchedulingService
            .deleteUpgradeEvent(accountId, eventId)
            .then(() => {
                setClusterUpgradeDeleted(!clusterUpgradeDeleted);
                message.success(t("cluster.upgrade.delete.success"));
            })
            .catch(e => message.error(t("cluster.upgrade.delete.fail")));
    };

    const editEvent = record =>
        locationRouterService.navigate(upgradesEditLocation, {
            eventId: record.eventId,
        });

    const columns = clusterUpgradeOverviewColumns(
        false,
        editEvent,
        deleteUpgradeEvent,
    );

    return (
        <TableWithPreferencesManagement
            rowKey="eventId"
            defaultSort={{
                fieldName: "upgradeDate",
                sortDir: "ascending",
            }}
            columns={columns}
            datasetSlicing="server"
            preferencesType={preferencesType}
            {...tableProps}
            {...rest}
        />
    );
};

ClusterUpgradeOverviewAccount.propTypes = {
    accountId: PropTypes.number.isRequired,
    tableProps: PropTypes.object.isRequired,
};
