import { message } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { InstanceGroupEditForm } from "@/components/Integrations/InstanceGroupEdit/InstanceGroupEditForm";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useRouteDataRefetch } from "@/mixpanel/hooks/useRouteDataRefetch.hook";
import { LoadableRenderer } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { instancesServices } from "@/services/instance.service";
import { t } from "@/translations";
import React, { useCallback } from "react";
import { useInstanceGroupQuery } from "./loadables";

export function InstanceGroupEditPage() {
    const { accountId, instanceGroup } = useAccountAppParams();
    const refetchRouteData = useRouteDataRefetch();

    const instanceGroupQuery = useInstanceGroupQuery({ instanceGroup });

    const {
        locationRouterService,
        accountAppLocations: { instancesLocation },
    } = useDic();

    const navigateToInstancesList = useCallback(() => {
        locationRouterService.navigate(instancesLocation, {
            accountId: accountId,
        });
    }, [locationRouterService, instancesLocation, accountId]);

    const handleOnSubmit = ({ values, successMessage }) => {
        return instancesServices
            .createInstance(accountId, values)
            .then(() => {
                message.info(successMessage);
                refetchRouteData();
                navigateToInstancesList();
            })
            .catch(error => {
                message.error(error.response.data.message);
            });
    };

    useSetPageTitle(t("instance-edit-form.title"));

    return (
        <LoadableRenderer
            loadable={instanceGroupQuery.loadable}
            hasValue={instanceGroup => (
                <InstanceGroupEditForm
                    accountId={accountId}
                    instanceGroup={instanceGroup}
                    navigateToInstancesList={navigateToInstancesList}
                    onSubmit={handleOnSubmit}
                    reload={instanceGroupQuery.reload}
                />
            )}
        />
    );
}
