import { Button, Tooltip } from "@/components/DesignSystem";
import { showWhenHasPermissionsOtherwise } from "@/security/authorization";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

const BreadcrumbButton = ({ breadcrumbButtons }) => {
    if (!breadcrumbButtons.length) {
        return <div />;
    }

    return breadcrumbButtons.map(
        ({ onClick, label, type = "primary", permissions, config }) => {
            const component = (
                <Button
                    key={label}
                    size="small"
                    type={type}
                    onClick={onClick}
                    disabled={config ? config.disabled : false}
                    tooltip={config?.tooltip}
                    icon={config?.icon}
                    // TODO: is this file even needed?
                    track={config.track}
                    label={<FormattedMessage id={label} />}
                />
            );

            const wrappedComponent =
                config && config.tooltip ? (
                    <Tooltip title={config.tooltip}>{component}</Tooltip>
                ) : (
                    component
                );

            if (permissions) {
                return (
                    <div
                        key={label}
                        style={{ display: "inline-block", marginRight: "8px" }}
                    >
                        {showWhenHasPermissionsOtherwise(
                            permissions,
                            wrappedComponent,
                            <div key={label} />,
                            config.permissionsFunc,
                        )}
                    </div>
                );
            }

            return wrappedComponent;
        },
    );
};

export default connect(
    state => ({ breadcrumbButtons: state.breadcrumbButtons }),
    null,
)(BreadcrumbButton);
