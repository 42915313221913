import { CodeMirror } from "@/components/DesignSystem/CodeMirror";
import { useDic } from "@/components/Dic/useDic.hook";
import CamelRouteXmlEditor from "@/components/Editors/CamelRouteXmlEditor/CamelRouteXmlEditor";
import {
    LoadableRenderer,
    pendingPromise,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { isXML } from "@/utils/xml.utils";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import PropTypes from "prop-types";
import React from "react";

export const NonStandardDefinition = ({ instanceId, prn }) => {
    const { instanceService } = useDic();

    const entityResource = useQueryLoadable(() => {
        return instanceId && prn
            ? instanceService.fetchEntity(instanceId, prn).then(getData)
            : pendingPromise();
    }, [instanceId, instanceService, prn]);

    return (
        <LoadableRenderer
            loadable={entityResource.loadable}
            hasValue={entity => {
                if (isString(entity) && isXML(entity)) {
                    return <CamelRouteXmlEditor value={entity} readOnly />;
                } else if (isObject(entity)) {
                    return (
                        <CodeMirror
                            value={JSON.stringify(entity, null, 2)}
                            readOnly
                        />
                    );
                }
            }}
        />
    );
};

NonStandardDefinition.propTypes = {
    instanceId: PropTypes.number.isRequired,
    prn: PropTypes.string.isRequired,
};
