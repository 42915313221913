import { Tag } from "@/components/DesignSystem";
import { DeprecatedIcon } from "@/components/Marketplace/components/Deprecated/DeprecatedIcon.component";
import { isDeprecatedTemplate } from "@/components/Marketplace/components/Deprecated/helpers";
import { FavoriteButton } from "@/components/Marketplace/components/FavoriteButton/FavoriteButton.component";
import { FavoriteType } from "@/components/Marketplace/components/FavoriteButton/favorite.types";
import { size } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { TemplateEntity } from "../../entities/templates/TemplateEntity.class";
import "./MarketplaceItem.styles.less";

export function MarketplaceItem({
    template,
    accountId,
    navigateToDetail,
    onFavoriteChange,
}) {
    const onDetailClick = () =>
        navigateToDetail({
            accountId,
            template,
        });

    return (
        <div
            data-test="marketplace-item"
            onClick={onDetailClick}
            className="pmMarketplaceItem"
        >
            <FavoriteButton
                favoriteId={template.uniqueName}
                favoriteType={FavoriteType.TEMPLATE}
                isActive={template.userFavorite}
                className="pmMarketplaceItem-favorites"
                onToggle={onFavoriteChange}
            />
            <h4
                data-test="marketplace-item-title"
                className="pmMarketplaceItem-title"
            >
                {template.label}
                {isDeprecatedTemplate(template.uniqueName) ? (
                    <DeprecatedIcon style={{ marginLeft: "8px" }} />
                ) : null}
            </h4>
            {size(template.tags) > 0 && (
                <div>
                    {template.tags.map(tag => (
                        <Tag
                            key={`${template.label}${tag}`}
                            data-test="marketplace-item-tag"
                            className="pmMarketplaceItem-tag"
                        >
                            {tag}
                        </Tag>
                    ))}
                </div>
            )}
        </div>
    );
}

MarketplaceItem.propTypes = {
    accountId: PropTypes.number.isRequired,
    navigateToDetail: PropTypes.func,
    onFavoriteChange: PropTypes.func,
    template: PropTypes.instanceOf(TemplateEntity).isRequired,
};
