import { colors } from "@/components/DesignSystem/colors";
import { IconWithTooltip } from "@/components/Integrations/components/IconWithTooltip";
import PropTypes from "prop-types";
import React from "react";

export const NonStandardEntity = ({ tooltip, display }) => {
    if (!display) return null;

    return (
        <IconWithTooltip
            display={display}
            tooltip={tooltip}
            color={colors.info.primary}
            icon="info-circle"
        />
    );
};

NonStandardEntity.propTypes = {
    tooltip: PropTypes.string,
    display: PropTypes.any,
};
