import { Forms } from "@/components/DesignSystem";
import { areEmailsValid } from "@/components/DesignSystem/Forms/validators";
import { t } from "@/translations";

export const allowedDomains = [
    "pricefx.net",
    "pricefx.eu",
    "pricefx.io",
    "pricefx.com",
];

const { success, error } = Forms;

export const validateUrlAndDomain = value => {
    let url;

    try {
        url = new URL(value);
    } catch (_) {
        return error(t("general.validation.is-valid-url"));
    }

    if (!allowedDomains.some(domain => url.hostname.endsWith(domain))) {
        return error(t("general.validation.is-valid-url"));
    }

    if (url.port && parseInt(url.port) < 8000) {
        return error(t("general.validation.is-valid-url"));
    }

    return url.protocol === "http:" || url.protocol === "https:"
        ? success()
        : error(t("general.validation.is-valid-url"));
};

export const validateStringEmails = value => {
    return areEmailsValid(getEmailsArray(value));
};

const getEmailsArray = emails => emails.replace(";", ",").split(",");
