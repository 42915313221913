import { ExportMapper } from "@/components/Mappers/ExportMapper/ExportMapper";
import ConditionStep from "@/components/Packages/PackageTableDefinitionPanel/components/ConditionStep/ConditionStep";
import { ExtensionMandatoryFields } from "@/components/Packages/PackageTableDefinitionPanel/components/ExtensionMandatoryFields/ExtensionMandatoryFields";
import { ExtensionOptionalFields } from "@/components/Packages/PackageTableDefinitionPanel/components/ExtensionOptionalFields/ExtensionOptionalFields";
import { ExtensionPreview } from "@/components/Packages/PackageTableDefinitionPanel/components/ExtensionPreview/ExtensionPreview";
import FilterForm from "@/components/Packages/PackageTableDefinitionPanel/components/FilterForm/FilterForm";
import { FormIntegrationProperties } from "@/components/Packages/PackageTableDefinitionPanel/components/FormIntegrationProperties/FormIntegrationProperties";
import { GeneralMapper } from "@/components/Packages/PackageTableDefinitionPanel/components/GeneralMapper/GeneralMapper";
import GenericForm from "@/components/Packages/PackageTableDefinitionPanel/components/GenericForm/GenericForm";
import IntegrationConnection from "@/components/Packages/PackageTableDefinitionPanel/components/IntegrationConnection/IntegrationConnection";
import { IntegrationCsvMapper } from "@/components/Packages/PackageTableDefinitionPanel/components/IntegrationCsvMapper/IntegrationCsvMapper";
import { IntegrationFilter } from "@/components/Packages/PackageTableDefinitionPanel/components/IntegrationFilter/IntegrationFilter";
import { IntegrationInit } from "@/components/Packages/PackageTableDefinitionPanel/components/IntegrationInit/IntegrationInit";
import { IntegrationManagerInstanceSelect } from "@/components/Packages/PackageTableDefinitionPanel/components/IntegrationManagerInstanceSelect/IntegrationManagerInstanceSelect";
import { IntegrationMapper } from "@/components/Packages/PackageTableDefinitionPanel/components/IntegrationMapper/IntegrationMapper";
import IntegrationProperties from "@/components/Packages/PackageTableDefinitionPanel/components/IntegrationProperties/IntegrationProperties";
import { IntegrationSftp } from "@/components/Packages/PackageTableDefinitionPanel/components/IntegrationSftp/IntegrationSftp";
import { ModuleMultipleSelectComponent } from "@/components/Packages/PackageTableDefinitionPanel/components/ModuleMultipleSelect/ModuleMultipleSelect.component";
import NewDataUploadFromDeployment from "@/components/Packages/PackageTableDefinitionPanel/components/NewDataUploadFromDeployment/NewDataUploadFromDeployment";
import PackageDataUploadFieldMappingContainer from "@/components/Packages/PackageTableDefinitionPanel/components/PackageDataUpload/PackageDataUploadFieldMappingContainer";
import PackageFileUpload from "@/components/Packages/PackageTableDefinitionPanel/components/PackageDataUpload/PackageFileUpload";
import PackageFileParsing from "@/components/Packages/PackageTableDefinitionPanel/components/PackageFileParsing/PackageFileParsing";
import { PackageMandatoryFields } from "@/components/Packages/PackageTableDefinitionPanel/components/PackageMandatoryFields/PackageMandatoryFields";
import PackageOptionalFieldsMap from "@/components/Packages/PackageTableDefinitionPanel/components/PackageOptionalFieldsMap/PackageOptionalFieldsMap";
import PackageOptionalFieldsSelectMap from "@/components/Packages/PackageTableDefinitionPanel/components/PackageOtionalFieldsSelectMap/PackageOptionalFieldsSelectMap";
import { PPMatrixAttributes } from "@/components/Packages/PackageTableDefinitionPanel/components/PricePrameters/PPMatrixAttributes";
import { PPMatrixKeys } from "@/components/Packages/PackageTableDefinitionPanel/components/PricePrameters/PPMatrixKeys";
import { PPRange } from "@/components/Packages/PackageTableDefinitionPanel/components/PricePrameters/PPRange";
import { PPSimple } from "@/components/Packages/PackageTableDefinitionPanel/components/PricePrameters/PPSimple";
import { SetupRebateHeaderType } from "@/components/Packages/PackageTableDefinitionPanel/components/SetupRebateHeaderType/SetupRebateHeaderType";
import { SetupSimulation } from "@/components/Packages/PackageTableDefinitionPanel/components/SetUpSimulation/SetupSimulation.component";
import { WaterfallConfiguration } from "@/components/Packages/PackageTableDefinitionPanel/components/WaterfallConfiguration/WaterfallConfiguration.component";
import {
    API_STEP,
    FE_STEP,
} from "@/components/Packages/PackageTableDefinitionPanel/constants";

export const components = {
    [FE_STEP.FILE_UPDATE]: PackageFileUpload,
    [FE_STEP.FILE_PARSING]: PackageFileParsing,
    [FE_STEP.DATA_UPLOAD]: PackageDataUploadFieldMappingContainer,
    [FE_STEP.MANDATORY_FIELDS]: PackageMandatoryFields,
    [FE_STEP.OPTIONAL_FIELDS_SELECT]: PackageOptionalFieldsSelectMap,
    [FE_STEP.OPTIONAL_FIELDS]: PackageOptionalFieldsMap,
    [FE_STEP.EXTENSION_MANDATORY_FIELDS]: ExtensionMandatoryFields,
    [FE_STEP.EXTENSION_OPTIONAL_FIELDS]: ExtensionOptionalFields,
    [FE_STEP.EXTENSION_PREVIEW]: ExtensionPreview,
    [FE_STEP.GENERIC_FORM]: GenericForm,
    [FE_STEP.FORM_INTEGRATION_PROPERTIES]: FormIntegrationProperties,
    [API_STEP.INTEGRATION_FILTER]: IntegrationFilter,
    [API_STEP.INTEGRATION_CONNECTION]: IntegrationConnection,
    [API_STEP.INTEGRATION_PROPERTIES]: IntegrationProperties,
    [API_STEP.CONDITION_STEP]: ConditionStep,
    [API_STEP.INTEGRATION_SFTP]: IntegrationSftp,
    [FE_STEP.INTEGRATION_CSV_MAPPER_DEFINITION]: IntegrationCsvMapper,
    [FE_STEP.INTEGRATION_MAPPER_DEFINITION]: IntegrationMapper,
    [FE_STEP.GENERAL_MAPPER_DEFINITION]: GeneralMapper,
    [API_STEP.EXPORT_MAPPER]: ExportMapper,
    [API_STEP.INTEGRATION_INIT]: IntegrationInit,
    [FE_STEP.FILTER_FORM]: FilterForm,
    [FE_STEP.CREATE_DATA_UPLOAD]: NewDataUploadFromDeployment,
    [FE_STEP.SELECT_IM_INSTANCE]: IntegrationManagerInstanceSelect,
    [API_STEP.WATERFALL_CONFIGURATION]: WaterfallConfiguration,
    [FE_STEP.PP_SIMPLE]: PPSimple,
    [FE_STEP.PP_RANGE]: PPRange,
    [FE_STEP.PP_MATRIX_KEYS]: PPMatrixKeys,
    [FE_STEP.PP_MATRIX_ATTRIBUTES]: PPMatrixAttributes,
    [FE_STEP.SKIP_DEPENDENCIES]: ModuleMultipleSelectComponent,
    [API_STEP.SETUP_SIMULATION]: SetupSimulation,
    [API_STEP.SETUP_REBATE_HEADER_TYPE]: SetupRebateHeaderType,
};
