import {
    algorithm,
    client,
    issuer,
    redirect_uri,
} from "@/components/Authentication/constants";
import * as oauth from "oauth4webapi";
import { useEffect } from "react";

let nonce;

const VerifyPage = () => {
    //eslint-disable-next-line
    useEffect(async () => {
        const code_verifier = oauth.generateRandomCodeVerifier();
        // const code_challenge = await oauth.calculatePKCECodeChallenge(
        //     code_verifier,
        // );
        const as = await oauth
            .discoveryRequest(issuer, {
                algorithm,
            })
            .then(response => oauth.processDiscoveryResponse(issuer, response));

        // @ts-expect-error
        // const currentUrl = getCurrentUrl();
        const currentUrl = new URL(window.location);
        const params = oauth.validateAuthResponse(as, client, currentUrl);
        if (oauth.isOAuth2Error(params)) {
            console.error("Error Response", params);
            throw new Error(); // Handle OAuth 2.0 redirect error
        }

        const response = await oauth.authorizationCodeGrantRequest(
            as,
            client,
            params,
            redirect_uri,
            code_verifier,
        );

        let challenges;
        if ((challenges = oauth.parseWwwAuthenticateChallenges(response))) {
            for (const challenge of challenges) {
                console.error("WWW-Authenticate Challenge", challenge);
            }
            throw new Error(); // Handle WWW-Authenticate Challenges as needed
        }

        const result = await oauth.processAuthorizationCodeOpenIDResponse(
            as,
            client,
            response,
            nonce,
        );
        if (oauth.isOAuth2Error(result)) {
            console.error("Error Response", result);
            throw new Error(); // Handle OAuth 2.0 response body error
        }

        console.log("Access Token Response", result);
        // ({ access_token } = result);
        const claims = oauth.getValidatedIdTokenClaims(result);
        console.log("ID Token Claims", claims);
        // ({ sub } = claims);
    }, []);

    return null;
};

export default VerifyPage;
