import { IM_VERSION_WITH_ENCRYPTION_SUPPORT } from "@/components/Integrations/constants";
import { useEncryptDataMutation } from "@/components/Integrations/loadables";
import { useRouteInstance } from "@/mixpanel/hooks/useRouteInstance.hook";
import { isLoading } from "@/modules/loadable";
import { useRouteParams } from "@/modules/router";
import { isSameOrNewer } from "@/utils/versionUtils";
import { useMemo } from "react";

const encryptedRegexPattern = /^{ENC}.{1,}$/;

export const useDataEncryption = ({ onSuccess, onError }) => {
    const { instanceId } = useRouteParams();
    const { instanceLoadable } = useRouteInstance();

    const encryptDataMutation = useEncryptDataMutation({
        instanceId,
        afterSuccess: onSuccess,
        onError: onError,
    });

    const isEncryptionSupported = useMemo(
        () =>
            instanceLoadable.valueMaybe()?.imVersion != undefined &&
            isSameOrNewer(
                instanceLoadable.valueMaybe()?.imVersion,
                IM_VERSION_WITH_ENCRYPTION_SUPPORT,
            ) &&
            instanceLoadable.valueMaybe()?.inCloud,
        [instanceLoadable],
    );

    const isEncryptedFn = value => encryptedRegexPattern.test(value);

    return {
        isLoading: isLoading(encryptDataMutation),
        isSupported: isEncryptionSupported,
        isEncryptedFn,
        encrypt: value => encryptDataMutation.mutate(value),
    };
};
