import { templatesApiSchema } from "./templatesApi.schemas";

export class TemplatesApiService {
    baseUrl = "api/templates";
    /**
     *
     * @type {HttpService}
     */
    httpService = null;
    /**
     *
     * @type {TemplatesApiMapper}
     */
    templatesApiMapper = null;

    constructor({ httpService, templatesApiMapper }) {
        this.httpService = httpService;
        this.templatesApiMapper = templatesApiMapper;
    }

    /**
     *
     * @param {string} type
     * @param {number} accountId
     * @returns {Promise<TemplateEntity[]>}
     */
    getTemplatesByType(type, accountId) {
        return this.httpService.get(this.baseUrl, {
            schema: templatesApiSchema,
            mapper: this.templatesApiMapper,
            httpOptions: {
                params: {
                    type,
                    accountId,
                },
            },
        });
    }

    /**
     *
     * @param {TemplateDeploymentEntity} templateDeployment
     * @param {object} opts
     * @param {boolean} opts.includeData
     * @returns {Promise<*>}
     */
    deleteDeployment(templateDeployment, { includeData = false } = {}) {
        return this.httpService.delete(
            this.getDeleteDeploymentUrl(templateDeployment),
            {
                httpOptions: {
                    params: {
                        includeData,
                    },
                },
            },
        );
    }

    /**
     *
     * @param {TemplateDeploymentEntity} templateDeployment
     * @returns {Promise<*>}
     */
    deleteApplicationProperties(templateDeployment) {
        return this.httpService.delete(
            this.getDeleteApplicationPropertiesUrl(templateDeployment),
        );
    }

    /**
     *
     * @param {TemplateDeploymentEntity} templateDeployment
     * @returns {string}
     */
    getDeleteDeploymentUrl(templateDeployment) {
        return `${this.baseUrl}/${templateDeployment.template.uniqueName}/deploy/${templateDeployment.destinationId}`;
    }

    /**
     *
     * @param {TemplateDeploymentEntity} templateDeployment
     * @returns {string}
     */
    getDeleteApplicationPropertiesUrl(templateDeployment) {
        return `${this.getDeleteDeploymentUrl(
            templateDeployment,
        )}/application-properties`;
    }
}
