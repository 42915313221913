import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React from "react";
import Mapper, { OPERATION } from "./Mapper";

export const EditMapperPage = () => {
    const { accountId, instanceId, mapperId } = useAccountAppParams();

    useSetPageTitle(t("mapper-form.title.edit"));

    return (
        <Mapper
            projectId={accountId}
            instanceId={instanceId}
            mapperId={mapperId}
            operation={OPERATION.EDIT}
        />
    );
};
