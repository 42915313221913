import cx from "classnames";
import React from "react";
import uuid from "uuid/v4";
import DangerAlert from "../Upload/DangerAlert";
import "./ConditionalErrorAlert.style.less";

const CONDITIONAL_PREFIX = "pmConditionalErrorAlert--";

const ConditionalErrorAlert = ({ className, size = "none", error }) => {
    const classes = cx(
        "pmConditionalErrorAlert",
        `${CONDITIONAL_PREFIX}${size}`,
        className,
    );

    // Previous alert could handle array of strings as error messages, new UC alert can't
    return [].concat(error ?? []).map(error => (
        <div className={classes} key={uuid()}>
            <DangerAlert message={error.message ? error.message : error} />
        </div>
    ));
};

export default ConditionalErrorAlert;
