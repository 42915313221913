import { useCenteredLayout } from "@/components/hooks/useContentLayout.hook";
import { NewResource } from "@/components/Resources/NewResource.component";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React from "react";

export const NewResourcePage = () => {
    const { instanceId } = useAccountAppParams();

    useCenteredLayout();

    return <NewResource instanceId={instanceId} />;
};
