import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { mapConnectionToInitValues } from "@/components/ISVConnections/ISVConnection.page";
import { IsvSftpConfiguration } from "@/components/ISVConnections/IsvSftpConfiguration.component";
import { GeneralStepEditForm } from "@/components/ISVConnections/steps/General.step";
import { VendorStepEditForm } from "@/components/ISVConnections/steps/Vendor.step";
import { HeaderTabs } from "@/components/PageLayout/components/HeaderTabs.component";
import { useRouteDataRefetch } from "@/mixpanel/hooks/useRouteDataRefetch.hook";
import { useRouteISVConnection } from "@/mixpanel/hooks/useRouteISVConnection.hook";
import { LoadableRenderer, useMapLoadableMemoized } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";

export const ISV_SETTINGS_TABS = {
    GENERAL: "general",
    VENDOR: "vendor",
    PRICEFX: "pricefx",
};

const ISVConnectionsSettingsPage = () => {
    const { accountId, isvConnectionId } = useAccountAppParams();
    const {
        locationRouterService,
        accountAppLocations: { isvConnectionsOverviewLocation },
    } = useDic();
    useFullWidthTableLayout();
    const refetchRouteData = useRouteDataRefetch();

    const { isvConnectionLoadable } = useRouteISVConnection();
    const connectionLoadable = useMapLoadableMemoized(
        isvConnectionLoadable,
        mapConnectionToInitValues,
    );

    const navigateToOverview = useCallback(() => {
        locationRouterService.navigate(isvConnectionsOverviewLocation, {
            accountId,
            isvConnectionId,
        });
    }, [
        locationRouterService,
        isvConnectionsOverviewLocation,
        accountId,
        isvConnectionId,
    ]);

    return (
        <>
            <HeaderTabs
                defaultActiveKey={ISV_SETTINGS_TABS.INSTANCE_REPOSITORY}
            >
                <HeaderTabs.TabPane
                    tab={t("isv-connections.stepper.general")}
                    key={ISV_SETTINGS_TABS.GENERAL}
                    forceRender
                >
                    <LoadableRenderer
                        loadable={connectionLoadable}
                        hasValue={initialValues => {
                            return (
                                <GeneralStepEditForm
                                    accountId={accountId}
                                    initialValues={initialValues}
                                    onCancel={navigateToOverview}
                                    onSuccess={refetchRouteData}
                                />
                            );
                        }}
                    />
                </HeaderTabs.TabPane>
                <HeaderTabs.TabPane
                    tab={t("isv-connections.stepper.vendor")}
                    key={ISV_SETTINGS_TABS.VENDOR}
                    forceRender
                >
                    <LoadableRenderer
                        loadable={connectionLoadable}
                        hasValue={initialValues => {
                            return (
                                <VendorStepEditForm
                                    accountId={accountId}
                                    initialValues={initialValues}
                                    onCancel={navigateToOverview}
                                    onSuccess={refetchRouteData}
                                />
                            );
                        }}
                    />
                </HeaderTabs.TabPane>
                <HeaderTabs.TabPane
                    tab={t("isv-connections.stepper.pricefx")}
                    key={ISV_SETTINGS_TABS.PRICEFX}
                    forceRender
                >
                    <LoadableRenderer
                        loadable={connectionLoadable}
                        hasValue={connection => {
                            return (
                                <IsvSftpConfiguration
                                    isvConnectionId={connection.id}
                                />
                            );
                        }}
                    />
                </HeaderTabs.TabPane>
            </HeaderTabs>
        </>
    );
};

export default ISVConnectionsSettingsPage;
