import { useDic } from "@/components/Dic/useDic.hook";
import { pendingPromise, useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useAccountPartitionsQuery = ({
    accountId,
    canFetch = !!accountId,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : axiosService
                      .get(`/api/projects/${accountId}/partition-assets`)
                      .then(getData),
        [accountId, axiosService, canFetch],
    );
};
