import {
    CLEAN_BREADCRUMB_DATA,
    SET_BREADCRUMB_DATA,
    SET_BREADCRUMB_BUTTONS,
} from "./actionTypes";

export const setButtonData = (label, onClick, permissions, config = {}) => ({
    type: SET_BREADCRUMB_DATA,
    onClick,
    label,
    permissions,
    config,
});

export const setBreadcrumbButtons = buttons => ({
    type: SET_BREADCRUMB_BUTTONS,
    buttons,
});

export const cleanButtonData = () => ({
    type: CLEAN_BREADCRUMB_DATA,
});
