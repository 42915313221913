import { defaultParserConfigValues } from "@/components/FileParsing/FileParsingFields";
import {
    mapFromFormValues,
    mapVendorToVendorName,
} from "@/components/ISVMapping/helpers";
import EntitiesStep from "@/components/ISVMapping/steps/Entities.step";
import FieldsStep from "@/components/ISVMapping/steps/Fields.step";
import GeneralStep from "@/components/ISVMapping/steps/General.step";
import ParsingStep from "@/components/ISVMapping/steps/Parsing.step";
import SynchronizationStep from "@/components/ISVMapping/steps/Synchronization.step";
import UploadStep from "@/components/ISVMapping/steps/Upload.step";
import { StepForm } from "@/components/StepForm";
import { useRouteISVConnection } from "@/mixpanel/hooks/useRouteISVConnection.hook";
import { LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import React, { useCallback, useState } from "react";

const ISV_MAPPER_STEP_KEY = {
    GENERAL: "General",
    ENTITIES: "Entities",
    LOGIN: "Login",
    UPLOAD: "Upload",
    PARSING: "Parsing",
    FIELDS: "Fields",
    SYNCHRONIZATION: "Synchronization",
};

const ISVDataMappingForm = ({
    accountId,
    title,
    initialValues = {
        ...defaultParserConfigValues,
    },
    isEdit,
    onSave,
    onCancel,
}) => {
    const { isvConnectionLoadable } = useRouteISVConnection();
    const [allValues, setAllValues] = useState(initialValues);

    const handleSave = useCallback(
        ({ values }) => onSave(mapFromFormValues(values)),
        [onSave],
    );

    const [isValidMapper, setIsValidMapper] = useState(true);

    const getStepButtons = useCallback(
        ({ stepperProps, formId, submit }) => [
            {
                visible: stepperProps.isLastStep,
                label: isEdit ? t("general.save") : t("general.create"),
                type: "primary",
                onClick: () => submit(handleSave),
            },
            {
                visible:
                    !stepperProps.isLastStep &&
                    stepperProps.currentStep.key !== ISV_MAPPER_STEP_KEY.UPLOAD,
                label: t("general.continue"),
                type: "primary",
                formId,
                disabled: !isValidMapper,
            },
            {
                visible: !stepperProps.isFirstStep,
                label: t("general.back"),
                type: "secondary",
                onClick: stepperProps.prevStep,
            },
            {
                visible: true,
                label: t("general.cancel"),
                type: "text",
                onClick: onCancel,
                "data-test": "cancel-button",
            },
        ],
        [isEdit, isValidMapper, onCancel, handleSave],
    );

    const steps = [
        {
            key: ISV_MAPPER_STEP_KEY.GENERAL,
            title: t("isv-connections.data-mapping.steps.general"),
            Component: GeneralStep,
            stepProps: { initialValues },
        },
        {
            key: ISV_MAPPER_STEP_KEY.ENTITIES,
            title: t("isv-connections.data-mapping.steps.entities"),
            Component: EntitiesStep,
        },
        {
            key: ISV_MAPPER_STEP_KEY.UPLOAD,
            title: t("isv-connections.data-mapping.steps.upload"),
            Component: UploadStep,
        },
        {
            key: ISV_MAPPER_STEP_KEY.PARSING,
            title: t("isv-connections.data-mapping.steps.parsing"),
            Component: ParsingStep,
        },
        {
            key: ISV_MAPPER_STEP_KEY.FIELDS,
            title: t("isv-connections.data-mapping.steps.fields"),
            Component: FieldsStep,
            stepProps: { setIsValidMapper },
        },
        {
            key: ISV_MAPPER_STEP_KEY.SYNCHRONIZATION,
            title: t("isv-connections.data-mapping.steps.synchronization"),
            Component: SynchronizationStep,
        },
    ];

    return (
        <LoadableRenderer
            loadable={isvConnectionLoadable}
            hasValue={isvConnection => (
                <StepForm
                    title={title}
                    initialValues={initialValues}
                    steps={steps}
                    onCancel={onCancel}
                    allStepsProps={{
                        accountId,
                        isvConnection,
                        vendorName: mapVendorToVendorName(isvConnection.vendor),
                    }}
                    getStepButtons={getStepButtons}
                    allValues={allValues}
                    setAllValues={setAllValues}
                    isTabVariant={false}
                    onSubmit={handleSave} // Tab change -> save
                    // initialStepIndex={4}
                />
            )}
        />
    );
};

export default ISVDataMappingForm;
