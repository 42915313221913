import { message } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { t } from "@/translations";
import { getErrorMessageFromError } from "@/utils/state/error.utils";
import PropTypes from "prop-types";
import React from "react";
import { useSetPageTitle } from "../PageLayout/useSetPageTitle.hook";
import { OfferForm } from "./OfferForm.component";

export const CreateOfferFormContainer = ({ accountId }) => {
    const {
        offersService,
        locationRouterService,
        marketplaceAppLocations: { offersManagementLocation },
    } = useDic();

    useSetPageTitle(t("offer-edit.header.new-offer"));

    const onSubmit = async offer => {
        try {
            await offersService.createOffer(offer);

            locationRouterService.navigate(offersManagementLocation, {
                accountId,
            });
        } catch (e) {
            message.error(getErrorMessageFromError(e));
        }
    };

    const onCancel = () =>
        locationRouterService.navigate(offersManagementLocation, { accountId });

    return <OfferForm onSubmit={onSubmit} onCancel={onCancel} />;
};

CreateOfferFormContainer.propTypes = {
    accountId: PropTypes.number.isRequired,
};
