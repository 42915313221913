import { Statistic } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { LocationLink } from "@/modules/router";
import { SecurityContext } from "@/security/authorization";
import {
    INTEGRATION_PERMISSION_FUNC,
    PARTITION_PERMISSION_FUNC,
} from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useContext, useMemo } from "react";
import "./CustomerDashboardHeader.style.less";

const CustomerDashboardHeader = ({
    partitionsCount,
    integrationsCount,
    accountId,
    parentAccountName,
    parentAccountId,
    ownerName,
    hasRightsForParentAccount,
}) => {
    const { accountAppLocations } = useDic();
    const securityContext = useContext(SecurityContext);
    const partitionsLinkEnabled = useMemo(
        () => PARTITION_PERMISSION_FUNC(securityContext),
        [securityContext],
    );
    const integrationsLinkEnabled = useMemo(
        () => INTEGRATION_PERMISSION_FUNC(securityContext),
        [securityContext],
    );

    return (
        <div className="pmCustomerDashboard">
            <Statistic
                title={t("dashboard.header-partitions")}
                value={partitionsCount}
                formatter={value =>
                    partitionsLinkEnabled ? (
                        <LocationLink
                            $location={accountAppLocations.partitionsLocation}
                            $params={{ accountId: accountId }}
                        >
                            {value}
                        </LocationLink>
                    ) : (
                        <>{value}</>
                    )
                }
            />
            <Statistic
                title={t("dashboard.header-integrations")}
                value={integrationsCount}
                formatter={value =>
                    integrationsLinkEnabled ? (
                        <LocationLink
                            $location={accountAppLocations.instancesLocation}
                            $params={{ accountId: accountId }}
                        >
                            {value}
                        </LocationLink>
                    ) : (
                        <>{value}</>
                    )
                }
            />
            {parentAccountId && (
                <Statistic
                    title={t("dashboard.parent-account")}
                    value={parentAccountName}
                    formatter={value =>
                        hasRightsForParentAccount ? (
                            <LocationLink
                                $location={
                                    accountAppLocations.dashboardLocation
                                }
                                $params={{ accountId: parentAccountId }}
                            >
                                {value}
                            </LocationLink>
                        ) : (
                            <>{value}</>
                        )
                    }
                />
            )}
            <Statistic
                title={t("customer-list.header.account-owner")}
                value={ownerName}
            />
        </div>
    );
};

CustomerDashboardHeader.propTypes = {
    accountId: PropTypes.number.isRequired,
    parentAccountName: PropTypes.string,
    parentAccountId: PropTypes.number,
    partitionsCount: PropTypes.number.isRequired,
    integrationsCount: PropTypes.number.isRequired,
    onPartitionsClick: PropTypes.func,
    onIntegrationsClick: PropTypes.func,
};

export default CustomerDashboardHeader;
