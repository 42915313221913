import { Alert, Button, Forms, Gap } from "@/components/DesignSystem";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { T, t } from "@/translations";
import { filter, pickBy } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FileInfoFetcher } from "../../../../FileParsing/FileInfoFetcher";
import {
    FileParsingFields,
    defaultParserConfigValues,
} from "../../../../FileParsing/FileParsingFields";
import PackageDataMappingLayout from "../PackageDataMappingLayout/PackageDataMappingLayout";

const fileInfoNoValues = maybeFileInfo =>
    !!maybeFileInfo &&
    filter(maybeFileInfo?.columns ?? [], c => c.originalValues.length === 0)
        .length > 0;

// always visible in accelerators, in templates resolved by headlessCsvSupport from steps.json
const getHideUseVirtualHeaderValue = (step, instanceId) =>
    instanceId !== undefined && !step?.headlessCsvSupport;

const PackageFileParsing = ({
    accountId,
    partitionId,
    instanceId,
    globalState,
    onNext,
    onBack,
    onCancel,
    step,
}) => {
    const { fileId, storageVariant } = globalState;
    const [fileInfo, setFileInfo] = useState();

    const onSubmit = async bag => {
        onNext({
            parserConfig: bag.values,
            fileInfo,
            validationSchema: fileInfo.columns.map(row => ({
                name: row.name,
                type: row.type,
            })),
        });
    };
    const initialValues = {
        ...defaultParserConfigValues,
        ...pickBy(globalState?.parserConfig ?? {}, Boolean),
    };
    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit,
    });
    useSetValidatedInitialValues({ initialValues, setValues, setTouched });

    const hideUseVirtualHeader = getHideUseVirtualHeaderValue(step, instanceId);

    return (
        <FileInfoFetcher
            formId={formId}
            projectId={accountId}
            typeEntityId={partitionId || instanceId}
            type={partitionId ? "partitions" : "instances"}
            fileId={fileId}
            onFileInfo={setFileInfo}
        >
            {({ loadableFileInfo, changableColumnTypes, setColumnType }) => (
                <PackageDataMappingLayout
                    dataExample={fileInfo ?? {}}
                    nextButton={
                        <Forms.SubmitButton
                            formId={formId}
                            disabled={!fileInfo}
                        >
                            <Button
                                htmlType="submit"
                                type="primary"
                                onClick={handleSubmit}
                                label={t("partition-send-data.button.continue")}
                            />
                        </Forms.SubmitButton>
                    }
                    onBack={onBack}
                    onCancel={onCancel}
                    setColumnType={setColumnType}
                    changableColumnTypes={changableColumnTypes}
                >
                    <T id="package-file-parsing.options.message" />
                    {fileInfoNoValues(fileInfo) && (
                        <>
                            <Gap size="small" />
                            <Alert
                                message={t(
                                    "package-file-parsing.warning.no-values-present",
                                )}
                                type="warning"
                            />
                            <Gap size="small" />
                        </>
                    )}
                    <Forms.Form formId={formId}>
                        <FileParsingFields
                            allowClear={false}
                            hideUseVirtualHeader={hideUseVirtualHeader}
                        />
                    </Forms.Form>
                </PackageDataMappingLayout>
            )}
        </FileInfoFetcher>
    );
};

PackageFileParsing.propTypes = {
    accountId: PropTypes.number.isRequired,
    partitionId: PropTypes.number,
    instanceId: PropTypes.number,
    globalState: PropTypes.object.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    step: PropTypes.object,
};

export default PackageFileParsing;
