import React from "react";

export function useLocalStorageState(key, initialValue) {
    const [storedValue, setStoredValue] = React.useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const setValue = value => {
        try {
            const valueToStore =
                typeof value === "function" ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.log(error);
        }
    };

    const removeItem = () => {
        window.localStorage.removeItem(key);
        setStoredValue(initialValue);
    };

    return [storedValue, setValue, removeItem];
}
