import { useDic } from "@/components/Dic/useDic.hook";
import { hasAccessToLocation, SecurityContext } from "@/security/authorization";
import { userAccountId } from "@/security/UserContext";
import { t } from "@/translations";
import { useContext } from "react";
import { useRecoilValue } from "recoil";

const getBehaviorProps = (locationRouterService, location, params) => {
    const url = `/#${locationRouterService.buildPath(location, params)}`;
    const onClick = (e, path) => {
        const isNew = e.target.classList.contains("ucMenuNewTabIcon");

        if (isNew) {
            window.open(url, "_blank");
            e.preventDefault();
            e.stopPropagation();
        } else locationRouterService.navigate(location, params);
    };

    return {
        showNewTabIcon: true,
        href: url,
        onClick,
    };
};

export const useHeaderMenuItems = () => {
    const {
        locations: {
            marketplaceAppLocation,
            overviewAppLocation,
            administrationAppLocation,
            accountAppLocation,
            productEnhancementAppLocation,
            workflowsAppLocation,
        },
        locationRouterService,
    } = useDic();

    const accountId = useRecoilValue(userAccountId);
    const securityContext = useContext(SecurityContext);

    const locationsParams = {
        accountId,
    };

    return [
        {
            key: "overviewApp",
            anchor: t("location.overviewApp"),
            visible: hasAccessToLocation(securityContext, overviewAppLocation),
            ...getBehaviorProps(locationRouterService, overviewAppLocation),
        },
        {
            key: "accountApp",
            anchor: t("location.accountApp"),
            visible: hasAccessToLocation(securityContext, accountAppLocation),
            ...getBehaviorProps(
                locationRouterService,
                accountAppLocation,
                locationsParams,
            ),
        },
        {
            key: "marketplaceApp",
            anchor: t("location.marketplaceApp"),
            visible: hasAccessToLocation(
                securityContext,
                marketplaceAppLocation,
            ),
            ...getBehaviorProps(
                locationRouterService,
                marketplaceAppLocation,
                locationsParams,
            ),
        },
        {
            key: "administrationApp",
            anchor: t("location.administrationApp"),
            visible: hasAccessToLocation(
                securityContext,
                administrationAppLocation,
            ),
            ...getBehaviorProps(
                locationRouterService,
                administrationAppLocation,
            ),
        },
        {
            key: "productEnhancementApp",
            anchor: t("location.productEnhancementApp"),
            visible: hasAccessToLocation(
                securityContext,
                productEnhancementAppLocation,
            ),
            ...getBehaviorProps(
                locationRouterService,
                productEnhancementAppLocation,
            ),
        },
        {
            key: "workflowsApp",
            anchor: t("location.workflowsApp"),
            visible: hasAccessToLocation(securityContext, workflowsAppLocation),
            ...getBehaviorProps(locationRouterService, workflowsAppLocation),
        },
    ];
};
