import { getIdsByDestination } from "@/components/Marketplace/hooks/useNavigateToPackages.hook";
import { useAlertEffect } from "@/components/PageLayout/useAlertEffect.hook";
import { useCurrentRef } from "@/components/hooks/useCurrentRef.hook";
import { useMemoByEquality } from "@/components/hooks/useMemoByEquality.hook";
import { deployToEnum } from "@/constants/deployment.constants";
import { hasError, useQueryLoadable, waitForValue } from "@/modules/loadable";
import { useRouteParams } from "@/modules/router";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { $values } from "@pricefx/unity-components/dist/es/components/Forms/selectors";
import { isEmpty, noop, pick } from "lodash";
import PropTypes from "prop-types";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValueLoadable } from "recoil";
import { axiosService } from "../../axios";
import { ParsedType } from "../Packages/PackageTableDefinitionPanel/constants";

const useFetchFileInfo = ({ formId, type, typeEntityId, fileId }) => {
    if (!["partitions", "instances"].includes(type))
        throw new Error(`Wrong type '${type}', can be partitions or instances`);
    // TODO: run only for valid values?
    const loadableValues = useRecoilValueLoadable($values(formId));
    const cleanValues = pick(loadableValues.valueMaybe(), [
        "separator",
        "quoteChar",
        "escapeChar",
        "decimalSeparator",
        "dateFormat",
        "useVirtualHeader",
    ]);

    const destinationParams = getIdsByDestination(
        type === "partitions" ? deployToEnum.PARTITION : deployToEnum.INSTANCE,
        typeEntityId,
    );

    const { uploadId } = useRouteParams();
    if (uploadId) {
        cleanValues["uploadId"] = uploadId;
    }

    const memoizedMaybeValues = useMemoByEquality(
        () => cleanValues,
        [cleanValues],
    );
    const fileInfoResource = useQueryLoadable(() => {
        if (!memoizedMaybeValues || isEmpty(memoizedMaybeValues))
            return waitForValue();
        const params = qs.stringify(
            { ...memoizedMaybeValues, ...destinationParams },
            {
                addQueryPrefix: true,
            },
        );
        const url = `/api/data-loads/files/${fileId}/preview${params}`;

        return axiosService
            .get(url)
            .then(getData)
            .catch(({ response: { data } }) => {
                const errorString = t(`package-file-upload.parsing.error`, {
                    error: [].concat(getErrorMessage(data) ?? []).join(". "),
                });
                throw errorString;
            });
    }, [memoizedMaybeValues, fileId]);

    return { loadableValues, fileInfoResource };
};

export const getChangableColumnTypes = (columns = []) =>
    Object.fromEntries(
        columns
            .filter(({ type }) => type !== ParsedType.STRING)
            .map(({ name, type }) => [name, [type, ParsedType.STRING]]),
    );

const applyOverrides = (maybeFileInfo, stringTypeOverrides = []) =>
    !maybeFileInfo
        ? maybeFileInfo
        : {
              ...maybeFileInfo,
              columns: maybeFileInfo.columns.map(c =>
                  !stringTypeOverrides.includes(c?.name)
                      ? c
                      : { ...c, type: ParsedType.STRING },
              ),
          };

// TODO: investigate why const loadableValues = useRecoilValueLoadable($values(formId)) causes infinite rendering loop when called from component using Forms.useForm
export const FileInfoFetcher = ({ children, onFileInfo = noop, ...props }) => {
    const { loadableValues, fileInfoResource } = useFetchFileInfo(props);
    const parsingError = hasError(fileInfoResource)
        ? fileInfoResource.loadable.contents
        : "";
    useAlertEffect(parsingError, () => ({
        type: "error",
        message: parsingError,
    }));

    const [fileInfo, setFileInfo] = useState();
    const [changableColumnTypes, setChangableColumnTypes] = useState(); // need to keep original column type values to allow changing it back, could be removed at the cost of FE modifying fileInfo interface
    const [stringTypeOverrides, setStringTypesOverrides] = useState([]); // need too keep track of column names changed to string to reapply it when parser config changes and fileinfo is overriden

    const onFileInfoFetchStateChange = useCallback(
        maybeFileInfo => {
            const maybeFileInfoWithOverrides = applyOverrides(
                maybeFileInfo,
                stringTypeOverrides,
            );
            setFileInfo(maybeFileInfoWithOverrides);
            setChangableColumnTypes(
                getChangableColumnTypes(maybeFileInfo?.columns),
            );
        },
        [stringTypeOverrides],
    );
    const setColumnType = useCallback(({ name, type }) => {
        // required to keep overrides after parser settings change: https://pricefx.atlassian.net/browse/PFIM-3282
        setStringTypesOverrides(names =>
            type === ParsedType.STRING
                ? [...new Set(names).add(name)]
                : names.filter(
                      stringOverrideName => stringOverrideName !== name,
                  ),
        );
        // need to modify fileinfo to be used by next steps
        setFileInfo(fileInfo =>
            !fileInfo
                ? fileInfo
                : {
                      ...fileInfo,
                      columns: fileInfo.columns.map(c =>
                          c?.name !== name ? c : { ...c, type },
                      ),
                  },
        );
    }, []);

    useEffect(() => {
        onFileInfoFetchStateChange(fileInfoResource.loadable.valueMaybe());
    }, [fileInfoResource.loadable.state, onFileInfoFetchStateChange]);

    const onFileInfoRef = useCurrentRef(onFileInfo);
    useEffect(() => {
        onFileInfoRef.current(fileInfo);
    }, [fileInfo]);

    return children({
        loadableValues,
        loadableFileInfo: fileInfoResource.loadable,
        fileInfo,
        changableColumnTypes,
        setColumnType,
    });
};

FileInfoFetcher.propTypes = {
    formId: PropTypes.string.isRequired,
    projectId: PropTypes.number.isRequired,
    partitionId: PropTypes.number,
    instanceId: PropTypes.number,
    fileId: PropTypes.number.isRequired,
};
