import { Gap } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import React from "react";
import EntityNameSelector from "./EntityNameSelector";
import EntityTypeSelector from "./EntityTypeSelector";

/**
 * @deprecated Use EntitySelectFields instead
 */
export const ObjectTypeSelector = ({
    entityType,
    entityName,
    subFilter,
    handleEntityTypeChange,
    handleEntityNameChange,
    entityNamesResource,
    entityTypeProps,
    entityNameProps,
    entityTypeStyle,
    entityNameStyle,
    errors,
}) => {
    return (
        <div>
            <EntityTypeSelector
                entityType={entityType}
                onChange={handleEntityTypeChange}
                style={entityTypeStyle}
                {...entityTypeProps}
                error={errors.entityType}
            />
            {subFilter && (
                <>
                    <Gap size="small" />
                    <EntityNameSelector
                        entityName={entityName}
                        entityNamesLoadable={entityNamesResource.loadable}
                        onChange={handleEntityNameChange}
                        style={entityNameStyle}
                        error={errors.entityName}
                        {...entityNameProps}
                    />
                </>
            )}
        </div>
    );
};

ObjectTypeSelector.propTypes = {
    entityType: PropTypes.string,
    entityName: PropTypes.string,
    subFilter: PropTypes.bool,
    handleEntityTypeChange: PropTypes.func.isRequired,
    handleEntityNameChange: PropTypes.func.isRequired,
    entityNamesResource: PropTypes.object.isRequired,
    errors: PropTypes.array,
};

export default ObjectTypeSelector;
