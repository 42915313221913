import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    isLoading,
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { get, map, pipe } from "lodash/fp";
import { useMemo } from "react";

export const useApplicationPropertiesQuery = ({ instanceId, canFetch }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            canFetch
                ? axiosService
                      .get(
                          `/api/instances/${instanceId}/application-properties`,
                      )
                      .then(
                          pipe(
                              get("data"),
                              map(name => ({ name })),
                          ),
                      )
                : pendingPromise(),
        [instanceId, canFetch, axiosService],
    );
};

export const useLoadableTableProps = loadable => {
    const loading = isLoading(loadable);
    const dataSource = useMemo(() => loadable.valueMaybe() ?? [], [loadable]);
    return { loading, dataSource };
};

export const useFileContentQuery = ({ name, instanceId, canFetch }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            canFetch
                ? axiosService
                      .get(
                          `/api/instances/${instanceId}/application-properties/${name}`,
                      )
                      .then(get("data"))
                : pendingPromise(),
        [name, instanceId, canFetch, axiosService],
    );
};

export const useFileContentMutation = ({ instanceId, name, afterSuccess }) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async fileContent =>
            axiosService
                .post(
                    `/api/instances/${instanceId}/application-properties/${name}`,
                    fileContent,
                )
                .then(d => {
                    afterSuccessCurrent(d);
                    return d;
                }),
        [instanceId, name, axiosService, afterSuccessCurrent],
    );
};
