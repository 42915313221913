import ClusterList from "@/components/Clusters/ClusterList.component";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import React from "react";

export const ClusterListPage = () => {
    useFullWidthTableLayout();

    return <ClusterList />;
};

export default ClusterListPage;
