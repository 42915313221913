import { $values } from "@pricefx/unity-components/dist/es/components/Forms/selectors";
import pipe from "lodash/fp/pipe";
import { useMemo } from "react";
import { useRecoilValueLoadable } from "recoil";

export const useValues = formId => {
    const values = pipe(
        $values,
        useRecoilValueLoadable,
        loadable => loadable.valueMaybe() || {},
    )(formId);
    const serializedValues = JSON.stringify(values);

    return useMemo(() => JSON.parse(serializedValues), [serializedValues]);
};
