import { nextIndex } from "./utils";

export const createAssetsArray = assetIdsByType =>
    Object.entries(assetIdsByType).reduce(
        (acc, [assetType, assetIds]) =>
            acc.concat(
                assetIds.map(assetId => ({
                    assetId,
                    assetType: assetType.toUpperCase(),
                })),
            ),
        [],
    );

export const hasAnyPermissionChanges = permissionChanges =>
    Object.values(permissionChanges).some(permissions =>
        Object.values(permissions).some(({ stateIndex }) => stateIndex !== 0),
    );

export const nextPermissionChangesState = (permissionChanges, permission) => {
    const stateIndex = nextIndex(
        permission.states,
        permissionChanges?.[permission.type]?.[permission.name]?.stateIndex,
    );
    const nextPermissionChanges = {
        ...permissionChanges,
        [permission.type]: {
            ...permissionChanges[permission.type],
            [permission.name]: {
                state: permission.states[stateIndex],
                stateIndex,
            },
        },
    };

    return nextPermissionChanges;
};

export const hasAnyBusinessRolesChanges = businessRolesChanges =>
    Object.values(businessRolesChanges).some(
        ({ stateIndex }) => stateIndex !== 0,
    );

export const nextBusinessRolesChangesState = (
    businessRolesChanges,
    businessRole,
) => {
    const stateIndex = nextIndex(
        businessRole.states,
        businessRolesChanges?.[businessRole.id]?.stateIndex,
    );
    const nextBusinessRolesChanges = {
        ...businessRolesChanges,
        [businessRole.id]: {
            state: businessRole.states[stateIndex],
            stateIndex,
        },
    };

    return nextBusinessRolesChanges;
};
