import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT } from "@/components/DesignSystem/Table/constants";
import { CreateLocalCopyModal } from "@/components/Mappers/CreateLocalCopyModal";
import { instanceRepoMappersListColumns } from "@/components/Mappers/InstanceRepoMappersList.columns";
import {
    useDeleteInstanceRepoMapperMutation,
    useInstanceRepoMappersListQuery,
    useLocalCopyMutation,
} from "@/components/Mappers/loadables";
import { useMapperNameValidator } from "@/components/Mappers/validators";
import { isLoading } from "@/modules/loadable";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { getInstanceRepoMappersListTrackName as getTrackName } from "../../mixpanel/buttonNames";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { InstanceServerType } from "@/constants/CommonConstats";
import { useInstanceQuery } from "@/components/IntegrationRoutes/loadables";

const preferencesType = preferencesTypes.INSTANCE_REPO_MAPPERS_LIST_TABLE;
const type = "Mapper";
const FIELD_NAME = "name";

export const InstanceRepoMappersList = ({
    instanceId,
    afterLocalCopy,
    afterDelete,
    onShowDefinition,
    reloadToken,
    visible,
}) => {
    const instanceQuery = useInstanceQuery({ instanceId });
    const instanceMaybe = instanceQuery.loadable.valueMaybe();
    const canShowDefinition =
        instanceMaybe?.serverType === InstanceServerType.PROVISIONED;
    const mappersListQuery = useInstanceRepoMappersListQuery({
        instanceId,
        reloadToken,
    });
    const deleteMapperMutation = useDeleteInstanceRepoMapperMutation({
        instanceId,
        afterSuccess: afterDelete,
    });

    const nameValidator = useMapperNameValidator({
        instanceId,
        withOnline: false,
    });

    const actionMenu = useCallback(
        record => (
            <ActionButton
                permission={INTEGRATION_EDIT_PERMISSIONS}
                record={record}
                items={[
                    {
                        visible: record.editable,
                        title: t("mapper-list.create-local-copy.title"),
                        customModal: (
                            <CreateLocalCopyModal
                                instanceId={instanceId}
                                record={record}
                                afterSuccess={afterLocalCopy}
                                fieldName={FIELD_NAME}
                                entityName={type}
                                useLocalCopyMutation={useLocalCopyMutation}
                                nameValidator={nameValidator}
                            />
                        ),
                        track: { name: getTrackName("LocalCopy") },
                    },
                    {
                        visible: canShowDefinition,
                        title: t("mapper-list.show-definition"),
                        onClick: onShowDefinition,
                    },
                    {
                        visible: record.deletable,
                        title: t("general.modal.delete.title", {
                            type: type,
                        }),
                        confirm: {
                            message: t("general.modal.delete.message", {
                                name: record.name,
                                type: type,
                            }),
                            onConfirm: deleteMapperMutation.mutate,
                        },
                        color: "red",
                        track: { name: getTrackName("Delete") },
                    },
                ]}
            />
        ),
        [
            afterLocalCopy,
            canShowDefinition,
            deleteMapperMutation.mutate,
            instanceId,
            nameValidator,
            onShowDefinition,
        ],
    );

    const dataSource = mappersListQuery.loadable.valueMaybe() || [];
    const loading = isLoading(mappersListQuery);

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            columns={instanceRepoMappersListColumns}
            dataSource={dataSource}
            loading={loading}
            fixed
            restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
            rowKey={FIELD_NAME}
            datasetSlicing="local"
            preferencesType={preferencesType}
            visible={visible}
            defaultSort={{
                sortSpecifiers: [
                    { property: "updatedAt", direction: "descending" },
                ],
            }}
        />
    );
};

InstanceRepoMappersList.propTypes = {
    instanceId: PropTypes.number.isRequired,
    afterLocalCopy: PropTypes.func,
    afterDelete: PropTypes.func,
    onShow: PropTypes.func,
    onShowDefinition: PropTypes.func,
    reloadToken: PropTypes.any,
    visible: PropTypes.bool,
};
