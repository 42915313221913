import { Gap, Text } from "@/components/DesignSystem";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { t } from "@/translations";
import moment from "moment-timezone";
import React, { useMemo, useState } from "react";
import DateRangeFilter from "../DateRangeFilter/DateRangeFilter.component";
import defaultFilter from "../DateRangeFilter/DateRangeFilter.filter";
import PageLayout from "../PageLayout";
import { useSetPageTitle } from "../PageLayout/useSetPageTitle.hook";
import ClusterUpgradeOverviewTable from "./ClusterUpgradeOverviewTable";
import { DATETIME } from "./date-formats";

export const ClusterUpgradeOverview = () => {
    useFullWidthTableLayout();
    const [activeFilter, setActiveFilter] = useState(defaultFilter.all);
    const filter = useMemo(
        () => ({
            upgradeDate: {
                value: [
                    moment(activeFilter.from).utc().format(DATETIME),
                    moment(activeFilter.to).utc().format(DATETIME),
                ],
            },
        }),
        [activeFilter.from, activeFilter.to],
    );

    useSetPageTitle(t("cluster.upgrade.overview.header"));

    return (
        <>
            <PageLayout.Perex>
                <Text>{t("cluster.upgrade.overview.perex")}</Text>
                <Gap size="medium" />
                <DateRangeFilter
                    title={t("cluster.upgrade.overview.filter.upgrades")}
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                />
            </PageLayout.Perex>

            <Gap size="small" />
            <ClusterUpgradeOverviewTable filter={filter} />
        </>
    );
};
