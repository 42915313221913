export class RightsApiService {
    httpService = null;

    constructor({ httpService }) {
        this.httpService = httpService;
    }

    fetchAccountUserRights(accountId, userIds) {
        return this.httpService
            .post(`/api/projects/${accountId}/rights/users`, { userIds })
            .then(({ data }) => data);
    }

    fetchAccountUserWorkflowRights(accountId, userIds) {
        return this.httpService
            .post(`/api/projects/${accountId}/rights/user-requests`, {
                userIds,
            })
            .then(({ data }) => data);
    }

    fetchAccountGroupRights(accountId, groupIds) {
        return this.httpService
            .post(`/api/projects/${accountId}/rights/user-groups`, { groupIds })
            .then(({ data }) => data);
    }

    fetchAccountGroupWorkflowRights(accountId, groupIds) {
        return this.httpService
            .post(`/api/projects/${accountId}/rights/user-group-requests`, {
                groupIds,
            })
            .then(({ data }) => data);
    }

    modifyRights(accountId, rightsChanges) {
        return this.httpService.post(
            `/api/projects/${accountId}/rights`,
            rightsChanges,
        );
    }
}
