import { useDic } from "@/components/Dic/useDic.hook";
import React, { useEffect } from "react";
import { FullPage } from "./FullPage";

export function FullPageContainer(props) {
    const {
        authenticationService,
        locationRouterService,
        locations: { authRedirLocation },
    } = useDic();
    useEffect(() => {
        if (!authenticationService.isLoggedIn()) {
            locationRouterService.navigate(authRedirLocation);
        }
    }, []);

    return <FullPage {...props} />;
}
