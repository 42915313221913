import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { Link } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";

export const ProjectPriorities = {
    Low: "Low",
    Medium: "Medium",
    High: "High",
};

export const productEnhancementOverviewColumns = ({
    onShowDetail,
    onAttachmentDownload,
    customers = [],
}) => [
    {
        type: fieldTypes.TEXT,
        label: t("product-enhancement.table.columns.key"),
        name: "key",
        render: (text, record) => (
            <Link onClick={() => onShowDetail(record)}>{text}</Link>
        ),
    },
    {
        type: fieldTypes.OPTION,
        label: t("product-enhancement.table.columns.type"),
        name: "issuetype",
        options: [
            { value: null, label: "All" },
            { value: "Epic", label: "Epic" },
            { value: "Idea", label: "Idea" },
        ],
    },
    {
        type: fieldTypes.OPTION,
        label: t("product-enhancement.table.columns.customers"),
        name: "customers",
        canSort: false,
        options: customers.map(customer => ({
            value: customer,
            label: customer,
        })),
        render: (_, record) => record.customers,
    },
    {
        type: fieldTypes.OPTION,
        label: t("product-enhancement.table.columns.status"),
        name: "status",
        canSort: false,
        options: [
            { value: null, label: "All" },
            { value: "Approved", label: "Approved" },
            { value: "Duplicated", label: "Closed as Duplicate" },
            { value: "Gathering Interest", label: "Gathering Interest" },
            { value: "Development,Awaiting Release", label: "In Progress" },
            { value: "New", label: "New" },
            { value: "Parked", label: "Parked" },
            { value: "Rejected,Closed", label: "Rejected" },
            { value: "Released", label: "Released" },
            {
                value: "Review, Feedback,Refining,Innovation",
                label: "Under Discussion",
            },
        ],

        render: (_, record) => record.status,
    },
    {
        visibleInPanel: true,
        panelOrder: 4,
        type: fieldTypes.TEXT,
        label: t("product-enhancement.table.columns.fixVersions"),
        name: "fixVersions",
        canFilter: false,
        canSort: false,
    },
    {
        type: fieldTypes.TEXT,
        label: t("product-enhancement.table.columns.description"),
        name: "description",
    },
    {
        visible: false,
        visibleInPanel: true,
        onlyIn: ["panel"],
        panelOrder: 0,
        type: fieldTypes.TEXT,
        label: t("product-enhancement.table.columns.description"),
        name: "descriptionDetail",
        render: (text, record) => (
            <div
                dangerouslySetInnerHTML={{
                    __html: record.descriptionDetail,
                }}
            />
        ),
    },
    // TODO: Temporary hidden - PFIM-5725
    // {
    //     visible: false,
    //     visibleInPanel: true,
    //     panelOrder: 1,
    //     type: fieldTypes.TEXT,
    //     label: t("product-enhancement.table.columns.attachments"),
    //     name: "attachments",
    //     render: attachments =>
    //         attachments.map(attachment => (
    //             <Link
    //                 key={`attachment-${attachment.id}`}
    //                 onClick={() =>
    //                     onAttachmentDownload(attachment.filename, attachment.id)
    //                 }
    //             >
    //                 {attachment.filename}
    //             </Link>
    //         ))
    // },
    {
        visibleInPanel: true,
        panelOrder: 2,
        type: fieldTypes.TEXT,
        label: t("product-enhancement.table.columns.summary"),
        name: "summary",
    },
    {
        visibleInPanel: true,
        panelOrder: 3,
        type: fieldTypes.OPTION,
        label: t("product-enhancement.table.columns.roadmapPriority"),
        name: "roadmapPriority",
        options: Object.values(ProjectPriorities),
    },
    {
        visibleInPanel: true,
        panelOrder: 7,
        type: fieldTypes.DATETIME,
        label: t("product-enhancement.table.columns.submited"),
        name: "created",
        render: date => <FormattedDateTime>{date}</FormattedDateTime>,
    },
    {
        visibleInPanel: true,
        panelOrder: 8,
        type: fieldTypes.DATETIME,
        label: t("product-enhancement.table.columns.updated"),
        name: "updated",
        render: date => <FormattedDateTime>{date}</FormattedDateTime>,
    },
    {
        visibleInPanel: true,
        panelOrder: 4,
        type: fieldTypes.TEXT,
        label: t("product-enhancement.table.columns.eta"),
        name: "eta",
        canFilter: false,
        canSort: false,
    },
    {
        visibleInPanel: true,
        panelOrder: 6,
        type: fieldTypes.TEXT,
        label: t("product-enhancement.table.columns.popularity"),
        name: "popularity",
        canFilter: false,
        canSort: false,
    },
];
