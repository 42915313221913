import { Forms } from "@/components/DesignSystem";
import { PATTERN_MAPPER_NAME } from "@/components/Mappers/CopyMapperDialog";
import { mappersService } from "@/services/mapper.service";
import { getErrorMessage } from "@/utils/state/error.utils";
import { useCallback, useMemo } from "react";

export const useMapperExistsValidator = ({
    instanceId,
    skipValidationFor,
    withOnline,
}) => {
    return useCallback(
        value =>
            skipValidationFor && skipValidationFor === value
                ? Forms.success()
                : mappersService
                      .mapperExists({ instanceId, name: value, withOnline })
                      .then(({ data: exists }) =>
                          exists
                              ? Forms.error("Already exists")
                              : Forms.success(),
                      )
                      .catch(e => Forms.error(getErrorMessage(e))),
        [skipValidationFor, instanceId, withOnline],
    );
};

export const useMapperNameValidator = ({
    instanceId,
    currentName,
    withOnline,
}) => {
    const mapperExistsValidator = useMapperExistsValidator({
        instanceId,
        skipValidationFor: currentName,
        withOnline,
    });

    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                Forms.pmValidators.createPatternValidator(
                    PATTERN_MAPPER_NAME,
                    "Name has a wrong format. Valid format is a-z A-Z, 0-9 _ - without spaces",
                ),
                mapperExistsValidator,
            ]),
        [mapperExistsValidator],
    );
};
