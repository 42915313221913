import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { SFTPServerUserCreation } from "@/components/SFTPServers/SFTPServerUserCreation.component";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React, { useCallback } from "react";

export const SFTPDataLoadUserPage = () => {
    const { uploadId, userId, SFTP_ENTITY_TYPE } = useAccountAppParams();
    const {
        locationRouterService,
        accountAppLocations: { partitionDataUploadSFTPManagementLocation },
    } = useDic();
    const navigateToUsersList = useCallback(
        () =>
            locationRouterService.navigate(
                partitionDataUploadSFTPManagementLocation,
            ),
        [locationRouterService, partitionDataUploadSFTPManagementLocation],
    );

    useSetBackButtonAction(navigateToUsersList);

    return (
        <SFTPServerUserCreation
            dataLoadId={uploadId}
            entityType={SFTP_ENTITY_TYPE}
            userId={userId}
            navigateToUsersList={navigateToUsersList}
        />
    );
};

SFTPDataLoadUserPage.propTypes = {};
