import { useSetAlert } from "@/components/PageLayout/useSetAlert.hook";
import { responseErrorMessage } from "@/modules/loadable/utils";
import { useEffect } from "react";

const defaultHasError = e => ({
    type: "error",
    message: responseErrorMessage(e),
});
export const useLoadableAlert = ({
    loadable: loadableOrResource,
    loading,
    hasValue,
    hasError = defaultHasError,
}) => {
    const loadable = loadableOrResource?.loadable || loadableOrResource;
    const setAlert = useSetAlert();
    const maybeGetAlert = { loading, hasValue, hasError }[loadable.state];

    useEffect(() => {
        const maybeAlert = maybeGetAlert?.(loadable.contents);
        if (maybeAlert) setAlert(maybeAlert);

        return () => setAlert(null);
    }, [loadable.contents, maybeGetAlert, setAlert]);

    return loadable;
};
