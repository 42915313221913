import {
    idleLoadableProps,
    useCreateTableMutation,
    useFormatTypesQuery,
    usePfxUserGroupsQuery,
    useStatusesQuery,
    useTableTypesQuery,
    useValueTypesQuery,
} from "@/components/DataUploads/Wizard/loadables";
import { useFooterButtons } from "@/components/DataUploads/Wizard/NewTable/components/FooterButtonsContext";
import { Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { get } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

export const NAME_VALIDATION_PATTERN = new RegExp(/^[a-zA-Z0-9_-]*$/);

const FORBID_FIELD_TYPES_CHANGE_TABLE_TYPES = ["SIMPLE", "RANGE"];

export const useCPTableExistsValidator = ({ partitionId, tableType }) => {
    const { axiosService } = useDic();
    return useCallback(
        async name =>
            axiosService
                .get(
                    `/api/partitions/${partitionId}/create-requests/${tableType}/is-valid/${name}`,
                )
                .then(({ data: isValid }) =>
                    !isValid
                        ? Forms.error(
                              t("validation.alert-rule.exists", { name }),
                          )
                        : Forms.success(),
                )
                .catch(e => Forms.error(getErrorMessage(e))),
        [axiosService, partitionId, tableType],
    );
};

export const CreateCPStep = ({
    stepProps: {
        visible,
        partitionId,
        tableType,
        setTableParams,
        onCancel,
        afterSave,
    },
    stepperProps: { nextStep, prevStep },
}) => {
    const params = { partitionId, tableType, canFetch: !!visible };
    const tableTypesQuery = useTableTypesQuery(params);
    const statusesQuery = useStatusesQuery(params);
    const formatTypesQuery = useFormatTypesQuery(params);
    const pfxUserGroupsQuery = usePfxUserGroupsQuery(params);

    const { formId, handleSubmit } = Forms.useForm({
        onSubmit: ({
            values: { userGroupEdit, userGroupViewDetails, ...values },
        }) =>
            createTableMutation.mutate({
                ...values,
                userGroupEdit: userGroupEdit?.join?.(","),
                userGroupViewDetails: userGroupViewDetails?.join?.(","),
            }),
    });
    const type = Forms.useFieldValue({ formId, name: "type" });

    const createTableMutation = useCreateTableMutation({
        ...params,
        tableType,
        // tableType: type === "JSON" ? "JLTV" : tableType,
        afterSave: ({ uniqueName, id, type }) => {
            setTableParams({
                name: uniqueName,
                lookupTableId: id,
                type,
                _forbidChangeFieldTypes:
                    FORBID_FIELD_TYPES_CHANGE_TABLE_TYPES.includes(type),
            });
            afterSave();
            nextStep();
        },
    });

    useFooterButtons([
        {
            label: t("general.continue"),
            type: "primary",
            formId,
        },
        {
            label: t("general.cancel"),
            type: "text",
            onClick: onCancel,
        },
    ]);
    const valueTypesQuery = useValueTypesQuery({
        ...params,
        type,
        canFetch: !!type,
    });
    const hasFormatType = ["INT", "REAL"].includes(
        Forms.useFieldValue({ formId, name: "valueType" }),
    );
    const existsValidator = useCPTableExistsValidator({
        partitionId,
        tableType,
    });

    return (
        <Forms.Form formId={formId} onSubmit={handleSubmit}>
            <Forms.FieldGroup width="max" inputWidth="max">
                <Forms.Fields.Input
                    name="uniqueName"
                    label={t("new-table.cx.uniqueName.label")}
                    required
                    validator={Forms.validators.failOnFirst([
                        Forms.pmValidators.isRequired,
                        Forms.pmValidators.createPatternValidator(
                            NAME_VALIDATION_PATTERN,
                            "Name has a wrong format. Valid characters are a-z A-Z, 0-9 _ - without spaces", // TODO
                        ),
                        existsValidator,
                    ])}
                />
                <Forms.Fields.Input
                    name="label"
                    label={t("new-table.cx.label.label")}
                />
                <Forms.Fields.DatePicker
                    name="validAfter"
                    label={t("new-table.cx.validAfter.label")}
                    required
                    validator={Forms.pmValidators.isRequired}
                />
                <Forms.Fields.Select
                    name="type"
                    label={t("new-table.cx.type.label")}
                    {...getLoadableSelectProps(tableTypesQuery.loadable)}
                    required
                    validator={Forms.pmValidators.isRequired}
                />
                <Forms.Fields.Select
                    name="valueType"
                    label={t("new-table.cx.valueType.label")}
                    {...getLoadableSelectProps(valueTypesQuery.loadable)}
                    {...idleLoadableProps(
                        valueTypesQuery.loadable,
                        "Please, select table type",
                    )}
                    required
                    validator={Forms.validators.failOnFirst([
                        Forms.pmValidators.isRequired,
                        !isLoading(valueTypesQuery) &&
                            Forms.pmValidators.createAllowedValuesValidator(
                                valueTypesQuery.loadable
                                    .valueMaybe()
                                    ?.map(get("value")) ?? [],
                                "Value not found in options",
                            ),
                    ])}
                />
                <Forms.Fields.Select
                    name="status"
                    label={t("new-table.cx.status.label")}
                    {...getLoadableSelectProps(statusesQuery.loadable)}
                    required
                    validator={Forms.pmValidators.isRequired}
                />
                <Forms.Fields.Input
                    name="simulationSet"
                    label={t("new-table.cx.simulationSet.label")}
                />
                {hasFormatType && (
                    <Forms.Fields.Select
                        name="formatType"
                        label={t("new-table.cx.formatType.label")}
                        required
                        validator={Forms.pmValidators.isRequired}
                        {...getLoadableSelectProps(formatTypesQuery.loadable)}
                    />
                )}

                <Forms.Fields.Select
                    name="userGroupEdit"
                    label={t("new-table.userGroupEdit.label")}
                    mode="tags"
                    {...getLoadableSelectProps(pfxUserGroupsQuery.loadable)}
                />
                <Forms.Fields.Select
                    name="userGroupViewDetails"
                    label={t("new-table.userGroupViewDetails.label")}
                    mode="tags"
                    {...getLoadableSelectProps(pfxUserGroupsQuery.loadable)}
                />
            </Forms.FieldGroup>
        </Forms.Form>
    );
};

CreateCPStep.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    afterFinish: PropTypes.func.isRequired,
    partitionId: PropTypes.number.isRequired,
    tableType: PropTypes.string.isRequired,
};
