import { logger } from "@/modules/logger";
import {
    ACCOUNT_LIST_READ_PERMISSION,
    CLUSTERS_LIST_READ_PERMISSIONS,
    CLUSTER_UPGRADES_LIST_READ_PERMISSIONS,
    CLUSTER_UPGRADES_SLOTS_OVERVIEW_PERMISSIONS,
    FEATURE_FLAGS_PERMISSIONS,
    GLOBAL_ALERTS_PERMISSIONS,
    GROUPS_MANAGEMENT_PERMISSIONS,
    hasManagementPermission,
    hasMarketplaceGlobalPermission,
    hasOverviewPermission,
    hasPermission,
    INTEGRATION_LIST_READ_PERMISSION,
    NODES_LIST_READ_PERMISSION,
    PARTITION_LIST_READ_PERMISSION,
    PERMISSION_POWER_USER_PERMISSION,
    REMOTE_LOGIN_PERMISSIONS,
    SERVERS_PERMISSION,
    UPDATES_READ_PERMISSION,
    USERS_MANAGEMENT_PERMISSIONS,
    VERSIONS_MANAGEMENT_PERMISSION,
} from "@/security/permission.utils";
import isEmpty from "lodash/isEmpty";

export const defaultUserLocation = (
    {
        locationRouterService,
        overviewAppLocation,
        administrationAppLocation,
        accountAppLocation,
        noRightsLocation,
        templatesManagementLocation,
        globalPermissions,
        userPermission,
        defaultPage,
    },
    defaultParams,
) => {
    try {
        let navigateArgs = [];
        if (!isEmpty(defaultPage)) {
            const { page: routeName, ...routeParams } = defaultPage;
            const redirTo =
                locationRouterService.getLocationByRouteName(routeName);
            navigateArgs = [redirTo, routeParams];
        } else if (hasOverviewPermission(globalPermissions)) {
            navigateArgs = [overviewAppLocation];
        } else if (hasManagementPermission(globalPermissions)) {
            navigateArgs = [administrationAppLocation];
        } else if (!isEmpty(userPermission)) {
            navigateArgs = [accountAppLocation, defaultParams];
        } else if (hasMarketplaceGlobalPermission(globalPermissions)) {
            navigateArgs = [templatesManagementLocation, defaultParams];
        } else {
            navigateArgs = [noRightsLocation];
        }
        logger.debug({
            logGroupKey: ["ROUTING", "defaultUserLocation"],
            color: "deeppink",
            msg: `navigating`,
            data: { location: navigateArgs[0], params: navigateArgs[1] },
        });
        locationRouterService.navigate(...navigateArgs);
    } catch (e) {
        console.info("defaultUserLocation Error", e);
    }
};

export const defaultAdministrationLocation = (
    locationRouterService,
    globalPermissions,
    usersLocation,
    groupsLocation,
    globalAlertsRulesLocation,
    activityLogLocation,
    constantsLocation,
    featureFlagsLocation,
    noRightsLocation,
) => {
    if (hasPermission(globalPermissions, USERS_MANAGEMENT_PERMISSIONS)) {
        locationRouterService.navigate(usersLocation);
    } else if (
        hasPermission(globalPermissions, GROUPS_MANAGEMENT_PERMISSIONS)
    ) {
        locationRouterService.navigate(groupsLocation);
    } else if (
        hasPermission(globalPermissions, PERMISSION_POWER_USER_PERMISSION)
    ) {
        locationRouterService.navigate(activityLogLocation);
    } else if (hasPermission(globalPermissions, GLOBAL_ALERTS_PERMISSIONS)) {
        locationRouterService.navigate(globalAlertsRulesLocation);
    } else if (
        hasPermission(globalPermissions, VERSIONS_MANAGEMENT_PERMISSION)
    ) {
        locationRouterService.navigate(constantsLocation);
    } else if (hasPermission(globalPermissions, FEATURE_FLAGS_PERMISSIONS)) {
        locationRouterService.navigate(featureFlagsLocation);
    } else {
        locationRouterService.navigate(noRightsLocation);
    }
};

export const defaultOverviewLocation = (
    locationRouterService,
    globalPermissions,
    accountsLocation,
    clustersLocation,
    nodesLocation,
    partitionsLocation,
    integrationsLocation,
    updatesLocation,
    remoteLoginLocation,
    serversLocation,
    upgradesLocation,
    upgradesSlotsLocation,
    noRightsLocation,
) => {
    if (hasPermission(globalPermissions, ACCOUNT_LIST_READ_PERMISSION)) {
        locationRouterService.navigate(accountsLocation);
    } else if (
        hasPermission(globalPermissions, CLUSTERS_LIST_READ_PERMISSIONS)
    ) {
        locationRouterService.navigate(clustersLocation);
    } else if (hasPermission(globalPermissions, NODES_LIST_READ_PERMISSION)) {
        locationRouterService.navigate(nodesLocation);
    } else if (
        hasPermission(globalPermissions, PARTITION_LIST_READ_PERMISSION)
    ) {
        locationRouterService.navigate(partitionsLocation);
    } else if (
        hasPermission(globalPermissions, INTEGRATION_LIST_READ_PERMISSION)
    ) {
        locationRouterService.navigate(integrationsLocation);
    } else if (hasPermission(globalPermissions, UPDATES_READ_PERMISSION)) {
        locationRouterService.navigate(updatesLocation);
    } else if (hasPermission(globalPermissions, REMOTE_LOGIN_PERMISSIONS)) {
        locationRouterService.navigate(remoteLoginLocation);
    } else if (hasPermission(globalPermissions, SERVERS_PERMISSION)) {
        locationRouterService.navigate(serversLocation);
    } else if (
        hasPermission(globalPermissions, CLUSTER_UPGRADES_LIST_READ_PERMISSIONS)
    ) {
        locationRouterService.navigate(upgradesLocation);
    } else if (
        hasPermission(
            globalPermissions,
            CLUSTER_UPGRADES_SLOTS_OVERVIEW_PERMISSIONS,
        )
    ) {
        locationRouterService.navigate(upgradesSlotsLocation);
    } else {
        locationRouterService.navigate(noRightsLocation);
    }
};
