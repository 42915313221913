import {
    cleanButtonData,
    setButtonData,
} from "@/components/BreadcrumbButton/duck/actions";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { VisibleWhenHasPermission } from "@/security/authorization";
import { useHasGlobalPermission } from "@/security/hooks/useHasGlobalPermission.hook";
import {
    USERS_MANAGEMENT_PERMISSIONS,
    USER_MANAGEMENT_EDIT_PERMISSION,
} from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { AdminUserRightsDrawer } from "../../../apps/accountApp/components/AccountUserAdminSettings/RightsDrawer/AdminUserRightsDrawer";
import { isPricefxUsername } from "../../../apps/accountApp/components/AccountUserAdminSettings/RightsDrawer/loadables";
import { userAdminService } from "../../../apps/administrationApp/services/userAdmin.service";
import { preferencesTypes } from "../../../constants/preferencesTypes.constants";
import { EditUserModal } from "./EditUserModal";
import { UserGlobalPermissionsDrawerContainer } from "./GlobalPermissions/UserGlobalPermissionsDrawerContainer";
import { NewUserModal } from "./NewUserModal";
import { SsoUsernameModal } from "./SsoUsernameModal";
import { createColumns } from "./UsersList.columns";
import { useDeleteUserResource, useResetPasswordResource } from "./loadables";
import { message } from "@/components/DesignSystem";

const VIEWS = {
    DEFAULT: "default",
    GLOBAL_PERMISSIONS: "global_permissions",
    ASSETS_PERMISSIONS: "assets_permissions",
    NEW_USER: "new_user",
    EDIT_USER: "edit_user",
};

const preferencesType = preferencesTypes.ADMIN_USERS_LIST_TABLE;

const UsersListContainer = ({ setButtonData, cleanButtonData }) => {
    useFullWidthTableLayout();
    useHideBackButton();

    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort, filter) =>
            userAdminService.fetchUsers(page, size, sort, filter),
        [],
    );
    const [view, setView] = useState(VIEWS.DEFAULT);

    useEffect(() => {
        setButtonData(
            "user-list.title.create-user",
            () => setView(VIEWS.NEW_USER),
            [USER_MANAGEMENT_EDIT_PERMISSION],
        );
        return cleanButtonData;
    }, [setButtonData, cleanButtonData]);

    const [selectedUsers, setSelectedUsers] = useState([]);
    const multipleIdsPermissions = useCallback(users => {
        setSelectedUsers(users);
        setView(VIEWS.ASSETS_PERMISSIONS);
    }, []);
    const singleRecordPermissions = useCallback(
        user => {
            multipleIdsPermissions([user]);
        },
        [multipleIdsPermissions],
    );
    const showGlobalPermissions = useCallback(user => {
        setSelectedUsers([user]);
        setView(VIEWS.GLOBAL_PERMISSIONS);
    }, []);

    const setSelectedUserByUserId = userIds => {
        const users = userIds.map(
            id =>
                selectedUsers.find(u => u.id === id) ||
                tableProps?.page?.content?.find(u => u.id === id),
        );
        setSelectedUsers(users);
    };

    const selectedUsersIds = useMemo(
        () => selectedUsers.map(user => user.id),
        [selectedUsers],
    );

    const onCancel = useCallback(() => setView(VIEWS.DEFAULT), []);
    const deleteUserResource = useDeleteUserResource({
        afterSuccess: reload,
    });
    const resetPasswordResource = useResetPasswordResource();
    const onEdit = useCallback(user => {
        setSelectedUsers([user]);
        setView(VIEWS.EDIT_USER);
    }, []);
    const isPowerUser = useHasGlobalPermission("permission.power.user");

    const selectionContextMenu = useMemo(
        () => [
            {
                label: t("general.mass-permissions"),
                onClick: () => multipleIdsPermissions(selectedUsers),
            },
        ],
        [selectedUsers, multipleIdsPermissions],
    );
    const rowSelection = useSyncRowSelection(
        selectedUsersIds,
        setSelectedUserByUserId,
    );

    const displaySuperAssets = useMemo(
        () => selectedUsers.every(user => isPricefxUsername(user?.username)),
        [selectedUsers],
    );

    const isUserInvited = record => record.status === "INVITED";

    const resendActivation = record =>
        userAdminService
            .resendActivation(record.id)
            .then(() => {
                message.success(t("user-detail.resend-activation-email-ok"));
            })
            .catch(() => {
                message.error(t("user-detail.resend-activation-email-failed"));
            });

    const actionMenu = useCallback(
        record => (
            <VisibleWhenHasPermission
                permission={USER_MANAGEMENT_EDIT_PERMISSION}
            >
                <ActionButton
                    wrapperProps={{ onClick: e => e.stopPropagation() }}
                    record={record}
                    items={[
                        {
                            title: t("general.tooltip.edit"),
                            onClick: onEdit,
                            visible: !isUserInvited(record),
                        },
                        {
                            title: t("user-detail.modal.sso-username.title"),
                            customModal: <SsoUsernameModal record={record} />,
                            permission: ["user_mgmt.users.edit"],
                        },
                        {
                            title: t("general.permissions"),
                            onClick: singleRecordPermissions,
                            permission: ["permission.power.user"],
                        },
                        {
                            title: t("general.global-permissions"),
                            onClick: showGlobalPermissions,
                            permission: [USER_MANAGEMENT_EDIT_PERMISSION],
                        },
                        {
                            title: t("user-detail.resend-activation-email"),
                            onClick: () => resendActivation(record),
                            visible:
                                isUserInvited(record) &&
                                !isPricefxUsername(record.username),
                            permission: [USER_MANAGEMENT_EDIT_PERMISSION],
                        },
                        {
                            title: t("user-detail.password.button.reset"),
                            confirmMessage: t(
                                "user-detail.password.description",
                            ),
                            confirmButtonTitle: t(
                                "user-detail.password.button.reset",
                            ),
                            // onConfirm: resetPasswordResource.mutate
                            // TODO remove workaround below https://pricefx.atlassian.net/browse/PFIM-4294
                            onConfirm: record => {
                                resetPasswordResource.mutate(record);
                            },
                            visible: !isUserInvited(record),
                        },
                        {
                            title: t("general.modal.delete.title", {
                                type: "User",
                            }),
                            confirmMessage: t("general.modal.delete.message", {
                                name: record.username,
                                type: "User",
                            }),
                            onConfirm: deleteUserResource.mutate,
                            color: "red",
                        },
                    ]}
                />
            </VisibleWhenHasPermission>
        ),
        [
            onEdit,
            singleRecordPermissions,
            showGlobalPermissions,
            reload,
            deleteUserResource.mutate,
            resetPasswordResource,
        ],
    );

    const columns = useMemo(() => createColumns(), []);

    return (
        <VisibleWhenHasPermission permission={USERS_MANAGEMENT_PERMISSIONS}>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                defaultSort={{
                    fieldName: "username",
                    sortDir: "ascending",
                }}
                columns={columns}
                fixed
                rowKey="id"
                rowSelection={!isPowerUser ? undefined : rowSelection}
                selectionContextMenu={
                    !isPowerUser ? undefined : selectionContextMenu
                }
                datasetSlicing="server"
                preferencesType={preferencesType}
                exportUrl={"/api/admin/users/table/export"}
                {...tableProps}
            />
            <UserGlobalPermissionsDrawerContainer
                userId={selectedUsersIds[0]}
                visible={view === VIEWS.GLOBAL_PERMISSIONS}
                onCancel={onCancel}
                onSaved={onCancel}
            />
            <AdminUserRightsDrawer
                userIds={selectedUsersIds}
                onClose={onCancel}
                visible={Boolean(
                    !!selectedUsersIds.length &&
                        view === VIEWS.ASSETS_PERMISSIONS,
                )}
                displaySuperAssets={displaySuperAssets}
            />
            <NewUserModal
                visible={view === VIEWS.NEW_USER}
                onClose={onCancel}
                reloadUsers={reload}
            />
            <EditUserModal
                visible={view === VIEWS.EDIT_USER}
                onClose={onCancel}
                user={selectedUsers?.[0]}
                reloadUsers={reload}
            />
        </VisibleWhenHasPermission>
    );
};

UsersListContainer.propTypes = {
    setButtonData: PropTypes.func.isRequired,
    cleanButtonData: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    setButtonData,
    cleanButtonData,
};

export default connect(undefined, mapDispatchToProps)(UsersListContainer);
