import { number, object, string } from "yup";
import { useRouteParams } from "..";

const schema = object().shape({
    accountId: number(),
    partitionId: number(),
    packageName: string(),
    uniqueName: string(),
    instanceId: number(),
    offerId: number(),
    tag: string(),
    filter: string(),
    page: number(),
});

export function useMarketplaceAppParams() {
    const params = useRouteParams();

    return schema.cast(params);
}
