import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { FIELD_NAME } from "@/components/IntegrationRoutes/InstanceRepoIntegrationsRoutes";
import {
    UserAbortError,
    useWithConflictModal,
} from "@/components/Mappers/ConflictModal";
import {
    crudSuccess,
    pendingPromise,
    responseErrorMessage,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { instancesServices } from "@/services/instance.service";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { noop } from "lodash/fp";

export const useInstanceQuery = ({ instanceId }) => {
    return useQueryLoadable(
        async () => instancesServices.fetchInstance(instanceId).then(getData),
        [instanceId],
    );
};

export const useRoutesWithDetailsQuery = ({ instanceId, reloadToken }) => {
    const { routesService } = useDic();

    return useQueryLoadable(
        async () =>
            isNaN(instanceId)
                ? pendingPromise()
                : routesService.fetchRoutesWithDetail(instanceId).then(getData),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [instanceId, routesService, reloadToken],
    );
};

export const useDeployRouteMutation = ({ instanceId, afterSuccess = noop }) => {
    const { routesService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const withConflictModal = useWithConflictModal();

    return useMutationLoadableWithNotification(
        async record => {
            const res = await withConflictModal(forceCommit =>
                routesService.deployRoute(instanceId, record, { forceCommit }),
            );
            afterSuccessCurrent();
            return res;
        },
        [afterSuccessCurrent, instanceId, routesService, withConflictModal],
        "deployment.route.successful",
        e => (e instanceof UserAbortError ? null : responseErrorMessage(e)),
    );
};

export const useDeleteRouteMutation = ({ afterSuccess = noop }) => {
    const { routesService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record => {
            await routesService.deleteRoute(record.id);
            afterSuccessCurrent();
            return record;
        },
        [afterSuccessCurrent, routesService],
        ({ name }) =>
            t("general.message.deleted", {
                type: "integration",
                name,
            }),
    );
};

export const useStartRouteMutation = ({ instanceId, afterSuccess = noop }) => {
    const { routesService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record =>
            routesService
                .startRoute(instanceId, record[FIELD_NAME])
                .then(() => {
                    afterSuccessCurrent();
                    return record;
                }),
        [afterSuccessCurrent, instanceId, routesService],
        record => `${record[FIELD_NAME]} has been started`,
    );
};

export const useStopRouteMutation = ({ instanceId, afterSuccess = noop }) => {
    const { routesService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record =>
            routesService.stopRoute(instanceId, record[FIELD_NAME]).then(() => {
                afterSuccessCurrent();
                return record;
            }),
        [afterSuccessCurrent, instanceId, routesService],
        record => `${record[FIELD_NAME]} has been stopped`,
    );
};

export const useSaveRouteMutation = ({
    projectId,
    instanceId,
    afterSuccess = noop,
}) => {
    const { routesService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const withConflictModal = useWithConflictModal();

    return useMutationLoadableWithNotification(
        async ({ values, withDeploy }) => {
            const payload = {
                projectId,
                instanceId,
                ...values,
            };
            const res = await withConflictModal(forceCommit =>
                routesService.saveRoute(payload, { forceCommit, withDeploy }),
            );
            afterSuccessCurrent({ withDeploy, id: res.data.id });
            return { data: res.data, withDeploy };
        },
        [
            afterSuccessCurrent,
            instanceId,
            projectId,
            routesService,
            withConflictModal,
        ],
        ({ withDeploy }) =>
            t(withDeploy ? "route.saved-and-deployed" : "route.saved"),
        e => (e instanceof UserAbortError ? null : responseErrorMessage(e)),
    );
};

export const useInstanceRepoIntegrationsRoutesQuery = ({
    instanceId,
    reloadToken,
}) => {
    const { routesService } = useDic();

    return useQueryLoadable(
        async () =>
            isNaN(instanceId)
                ? pendingPromise()
                : routesService
                      .fetchInstanceRepoRoutes(instanceId)
                      .then(getData),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [instanceId, reloadToken, routesService],
    );
};

export const useLocalCopyMutation = ({ instanceId, afterSuccess = noop }) => {
    const { routesService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record => {
            const payload = {
                uniqueName: record.uniqueName,
                parent: record.parent,
                xml: record.xml,
                revisionNumber: record.revisionNumber,
            };
            const res = await routesService.createLocalCopy(
                instanceId,
                payload,
            );
            afterSuccessCurrent();
            return res;
        },
        [afterSuccessCurrent, instanceId, routesService],
        crudSuccess("Route Local Copy", "data.name", "C"),
    );
};

export const useDeleteInstanceRepoRouteMutation = ({
    instanceId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async record =>
            axiosService
                .delete(
                    `/api/instance/${instanceId}/route-online-delete/${record.uniqueName}`,
                    {
                        data: record.prn ? { prn: record.prn } : null,
                    },
                ) // TODO: check
                .then(res => {
                    afterSuccessCurrent();
                    return res;
                }),
        [afterSuccessCurrent, axiosService, instanceId],
        ({ name }) => t("route.delete.message"),
    );
};
