import { tap } from "@/components/Connections/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
    waitForValue,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { noop } from "lodash/fp";

export const useEncryptDataMutation = ({
    instanceId,
    afterSuccess = noop,
    onError = noop,
}) => {
    const { instanceService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const onErrorCurrent = useCurrentHandler(onError);

    return useMutationLoadableWithNotification(
        async value =>
            instanceService
                .encryptData(instanceId, value)
                .then(getData)
                .then(data => {
                    afterSuccessCurrent(data);
                })
                .catch(e => {
                    onErrorCurrent(e);
                    throw e;
                }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [instanceService, afterSuccessCurrent, instanceId, onErrorCurrent],
        null,
    );
};
export const useElkTagsQuery = ({ instanceId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(async () => {
        instanceId, axiosService;
        return axiosService
            .get(`/api/instances/${instanceId}/integration-tags`)
            .then(getData);
    }, [axiosService, instanceId]);
};

export const useSaveElkTagsMutation = ({ instanceId, afterSuccess }) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async values =>
            axiosService
                .post(`/api/instances/${instanceId}/integration-tags`, values)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, axiosService, instanceId],
    );
};

// TODO? API supports deletion, should be used somewhere?
// const useDeleteElkTagsMutation = ({ instanceId }) => {
//     const { axiosService } = useDic();

//     return useMutationLoadableWithNotification(
//         async () =>
//             axiosService.delete(
//                 `/api/instances/${instanceId}/integration-tags`
//             ),
//         [axiosService, instanceId]
//     );
// };

export const useInstanceBackupConfigQuery = ({ instanceId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `/api/instances/${instanceId}/customizations/backup-config`,
                )
                .then(getData),
        [axiosService, instanceId],
    );
};

export const useInstanceBackupConfigMutation = ({ instanceId }) => {
    const { axiosService } = useDic();

    return useMutationLoadableWithNotification(
        async values =>
            axiosService
                .post(
                    `/api/instances/${instanceId}/customizations/backup-config`,
                    values,
                )
                .then(getData),
        [axiosService, instanceId],
    );
};

export const useIntegrationTagsQuery = () => {
    const { instanceService } = useDic();

    return useQueryLoadable(
        async () =>
            instanceService
                .fetchAvailableTags()
                .then(tags => tags.map(tag => ({ name: tag }))),

        [instanceService],
    );
};

export const useIntegrationSupportedTagsQuery = instanceGroupName => {
    const { instanceService } = useDic();

    return useQueryLoadable(
        async () =>
            !instanceGroupName
                ? pendingPromise()
                : instanceService
                      .fetchSupportedTags(instanceGroupName)
                      .then(tags => tags.map(tag => ({ name: tag }))),
        [instanceGroupName, instanceService],
    );
};

export const useCreateNewInstancePossibleQuery = (cloudProvider, region) => {
    const { instanceService } = useDic();

    return useQueryLoadable(
        async () =>
            cloudProvider && region
                ? instanceService.fetchCreateNewPossible(cloudProvider, region)
                : waitForValue(),
        [cloudProvider, instanceService, region],
    );
};

export const useIntegrationProvidersQuery = ({ accountId }) => {
    const { createInstanceService } = useDic();

    return useQueryLoadable(
        async () =>
            createInstanceService.fetchProviders({ accountId }).then(getData),
        [createInstanceService, accountId],
    );
};

export const useInstancesRestartMutation = ({
    projectId,
    provider,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ instanceName, type }) =>
            axiosService
                .post(`/api/instances/restart`, {
                    projectId,
                    provider,
                    instanceName,
                    type,
                })
                .then(getData)
                .then(data => {
                    afterSuccessCurrent();
                    return data;
                }),
        [axiosService, projectId, provider, afterSuccessCurrent],
        t("instance-form.status.missing-in-git.resync.success"),
    );
};

export const useInstancesDeleteMutation = ({ afterSuccess = noop }) => {
    const { instanceService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ instanceId, queryParams }) =>
            instanceService
                .deleteInstance(instanceId, queryParams)
                .then(getData)
                .then(data => {
                    afterSuccessCurrent();
                    return data;
                }),
        [instanceService, afterSuccessCurrent],
        t("instance-list.message.deleted"),
        t("instance-list.message.not-deleted"),
    );
};
