import { useDefaultParams } from "@/components/hooks/useDefaultParams.hook";
import { logger } from "@/modules/logger";
import { useUserBasicInfo } from "@/security/hooks/useUserBasicInfo.hook";
import { clearLoginUrl, getLoginUrl } from "@/security/security";
import React, { useEffect, useRef } from "react";
import { useRoute } from "react-router5";
import { useDic } from "../components/Dic/useDic.hook";
import { defaultUserLocation } from "../utils/location/location.utils";

export const usePathMatch = (path, routeName) => {
    const routeContext = useRoute();
    if (!path) {
        return false;
    }

    if (path?.[0] === "#") {
        console.error("Remove hash from path!");
    }

    if (!routeName) {
        console.error("Missing routeName", routeName);
    }

    const matchedRoute = routeContext.router.matchPath(path);
    if (!matchedRoute) {
        console.error("Unknown path, not matched:", path);
        return false;
    }

    return matchedRoute.name === routeName;
};

export const DefaultRoutePage = () => {
    const {
        locationRouterService,
        authenticationService,
        locations: {
            overviewAppLocation,
            accountAppLocation,
            administrationAppLocation,
            noRightsLocation,
            defaultRouteLocation,
        },
        marketplaceAppLocations: { templatesManagementLocation },
    } = useDic();
    const basicInfo = useUserBasicInfo();
    const isLoggedIn = authenticationService.isLoggedIn();
    const loginUrl = getLoginUrl();
    const path = loginUrl?.replace?.(/^#*/, "");
    const isDefaultLocation = usePathMatch(
        path,
        defaultRouteLocation.routeName,
    );
    const defaultParams = useDefaultParams();

    logger.debug({
        logGroupKey: ["ROUTING", "DefaultRoutePage", "rndr"],
        color: "deeppink",
        msg: ``,
        data: {
            isLoggedIn,
            loginUrl,
            isDefaultLocation,
            defaultParams,
            basicInfo,
        },
    });

    const redirectRunningRef = useRef(false);
    useEffect(() => {
        if (isLoggedIn) {
            if (
                !redirectRunningRef.current &&
                loginUrl &&
                !isDefaultLocation // would stuck on default location, should not happen
            ) {
                redirectRunningRef.current = true;
                logger.debug({
                    logGroupKey: ["ROUTING", "DefaultRoutePage", "effect"],
                    color: "deeppink",
                    msg: `Going to loginUrl`,
                    data: {
                        isLoggedIn,
                        loginUrl,
                        isDefaultLocation,
                        defaultParams,
                        basicInfo,
                    },
                });
                clearLoginUrl();
                window.location.hash = loginUrl;
                return;
            }
            logger.debug({
                logGroupKey: ["ROUTING", "DefaultRoutePage", "effect"],
                color: "deeppink",
                msg: `Going to defaultLocation`,
                data: {
                    isLoggedIn,
                    loginUrl,
                    isDefaultLocation,
                    defaultParams,
                    basicInfo,
                },
            });

            defaultUserLocation(
                {
                    locationRouterService,
                    overviewAppLocation,
                    administrationAppLocation,
                    accountAppLocation,
                    noRightsLocation,
                    templatesManagementLocation,
                    globalPermissions: basicInfo?.permissions.globalPermissions,
                    userPermission: basicInfo?.permissions.permissions,
                    defaultPage: basicInfo?.defaultPage,
                },
                defaultParams,
            );
        }
    }, [
        isLoggedIn,
        basicInfo,
        isDefaultLocation,
        loginUrl,
        locationRouterService,
        overviewAppLocation,
        administrationAppLocation,
        accountAppLocation,
        noRightsLocation,
        templatesManagementLocation,
        defaultParams,
    ]);

    return <></>;
};

DefaultRoutePage.propTypes = {};
