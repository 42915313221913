import { t } from "@/translations";

export const RouteStatus = ({ status }) => {
    switch (status) {
        case "DRAFT":
            return t("routes-list.status.draft");
        case "CHANGED":
            return t("routes-list.status.changed");
        case "DEPLOYED":
            return t("routes-list.status.deployed");
        case "UNDEPLOYED":
            return t("routes-list.status.undeployed");
        case "FAILED":
            return t("routes-list.status.failed");
        default:
            return status;
    }
};
