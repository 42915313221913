import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    pendingPromise,
    useComposeLoadablesMemoized,
    useMapLoadableMemoized,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import get from "lodash/fp/get";
import map from "lodash/fp/map";
import { useCallback } from "react";

const createGroupOption = group => ({ value: group, label: group });

export const useUserGroupOptionsResource = ({
    canFetch,
    projectId,
    atlassianAccountId,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(async () => {
        if (!canFetch) return pendingPromise();
        if (!atlassianAccountId) return []; // TODO: consider

        const { data } = await axiosService.get(
            `/api/projects/${projectId}/atlassian/users/${atlassianAccountId}/groups`,
        );

        return data.map(createGroupOption);
    }, [projectId, canFetch, atlassianAccountId, axiosService]);
};

export const useAtlassianGroupOptionsResource = ({ projectId, canFetch }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(async () => {
        if (!canFetch) return pendingPromise();

        const { data } = await axiosService.get(
            `/api/projects/${projectId}/atlassian/groups`,
        );

        return data.map(createGroupOption);
    }, [projectId, canFetch, axiosService]);
};

export const useAvailableGroupOptionsResource = ({
    projectId,
    atlassianAccountId,
    canFetch,
}) => {
    // https://github.com/facebook/react/issues/14920#issuecomment-466283708
    const { loadable: groupOptionsLoadable, reload: reloadGroups } =
        useAtlassianGroupOptionsResource({
            canFetch,
            projectId,
        });
    const { loadable: userGroupOptionsLoadable, reload: reloadUserGroups } =
        useUserGroupOptionsResource({
            canFetch,
            projectId,
            atlassianAccountId,
        });
    const availableGroupOptionsLoadable = useMapLoadableMemoized(
        useComposeLoadablesMemoized([
            groupOptionsLoadable,
            userGroupOptionsLoadable,
        ]),
        useCurrentHandler(([groupOptions, userGroupOptions]) => {
            const userGroups = map(get("value"), userGroupOptions);
            const availableGroupOptions = map(
                option => ({
                    ...option,
                    disabled: userGroups.includes(option.value),
                }),
                groupOptions,
            );
            // const availableGroupOptions = reject(
            //     ({ value }) => userGroups.includes(value),
            //     groupOptions
            // );
            return availableGroupOptions;
        }),
    );

    const reload = useCallback(() => {
        reloadGroups();
        reloadUserGroups();
    }, [reloadGroups, reloadUserGroups]);

    return { loadable: availableGroupOptionsLoadable, reload };
};

export const useAssignUserToAtlassianGroupResource = ({
    projectId,
    atlassianAccountId,
    afterSuccess,
}) => {
    const { axiosService } = useDic();
    const createUser = useCallback(
        (projectId, username) =>
            axiosService.post(
                `/api/projects/${projectId}/atlassian/users/${username}`,
            ),
        [axiosService],
    );
    const assignToGroup = useCallback(
        (projectId, groupName, userAccountIds) =>
            axiosService.post(
                `/api/projects/${projectId}/atlassian/groups/${groupName}/users`,
                { userAccountIds },
            ),
        [axiosService],
    );

    return useMutationLoadableWithNotification(
        async ({ groupName, username }) => {
            let atlassianAccountIdWithDefault = atlassianAccountId;
            if (!atlassianAccountIdWithDefault) {
                const { data } = await createUser(projectId, username);
                atlassianAccountIdWithDefault = data.atlassianAccountId;
            }

            const { data } = await assignToGroup(projectId, groupName, [
                atlassianAccountIdWithDefault,
            ]);

            afterSuccess({ groupName, username, ...data });
            return { groupName, username, ...data };
        },
        [
            projectId,
            atlassianAccountId,
            afterSuccess,
            assignToGroup,
            createUser,
        ],
        data =>
            `User ${data.username} assigned to Atlassian group ${data.groupName}`,
    );
};

export const useRemoveUserFromAtlassianGroupResource = ({
    projectId,
    atlassianAccountId,
    afterSuccess,
}) => {
    const { axiosService } = useDic();

    return useMutationLoadableWithNotification(
        async ({ groupName }) => {
            const userAccountIds = atlassianAccountId; // TODO: what syntax is used on BE in path variable - list
            const { data } = await axiosService.delete(
                `/api/projects/${projectId}/atlassian/groups/${groupName}/users/${userAccountIds}`,
            );

            afterSuccess(data);
            return { groupName, ...data };
        },
        [axiosService, projectId, atlassianAccountId, afterSuccess],
        data => `User deleted from group ${data.groupName}`,
    );
};
