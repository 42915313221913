import { Tag } from "@/components/DesignSystem/Tag/Tag";
import PropTypes from "prop-types";
import React from "react";

export function InstanceMonitored({ monitored }) {
    return monitored ? (
        <Tag color="success" size="small">
            Enabled
        </Tag>
    ) : (
        <Tag color="error" size="small">
            Disabled
        </Tag>
    );
}

InstanceMonitored.propTypes = {
    monitored: PropTypes.bool,
};
