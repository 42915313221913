import { Col, Progress } from "@/components/DesignSystem";
import * as bytes from "@/utils/formats/bytes";
import PropTypes from "prop-types";
import React from "react";
import styles from "./InstanceMessages.style.less";

export const MessageStat = ({
    label,
    stat,
    color,
    onClick,
    entityName = "Messages",
}) => {
    return (
        <Col sm={6} onClick={onClick}>
            <div className={styles.box}>
                <div>{label}</div>
                <strong>
                    {stat.total} {entityName} (
                    {bytes.getSizeWithLabel(stat?.totalDataSize)})
                </strong>
                <Progress
                    showInfo={false}
                    strokeWidth={5}
                    strokeColor={color}
                    percent={100}
                />
            </div>
        </Col>
    );
};

MessageStat.propTypes = {
    label: PropTypes.node,
    stat: PropTypes.object,
    color: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    entityName: PropTypes.node,
};
