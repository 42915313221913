import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { pendingPromise, useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useIntegrationUpdateAvailableVersions = ({ instanceId }) => {
    const { integrationManagerUpdateService } = useDic();

    return useQueryLoadable(
        async () =>
            isNaN(instanceId)
                ? pendingPromise()
                : integrationManagerUpdateService
                      .getAvailableVersions(instanceId)
                      .then(getData)
                      .then(tags =>
                          tags.map(tag => ({ value: tag, label: tag })),
                      ),
        [instanceId, integrationManagerUpdateService],
    );
};
export const useIntegrationUpdateChangeLog = ({ instanceId, versionTo }) => {
    const { integrationManagerUpdateService } = useDic();

    return useQueryLoadable(
        async () =>
            isNaN(instanceId) || !versionTo
                ? pendingPromise()
                : integrationManagerUpdateService
                      .getChangeLog(instanceId, versionTo)
                      .then(getData),
        [instanceId, integrationManagerUpdateService, versionTo],
    );
};
export const useIntegrationUpdateState = ({ instanceId, onError }) => {
    const { integrationManagerUpdateService } = useDic();
    const onErrorCurrent = useCurrentHandler(onError);

    return useQueryLoadable(
        async () =>
            isNaN(instanceId)
                ? pendingPromise()
                : integrationManagerUpdateService
                      .getState(instanceId)
                      .then(getData)
                      .catch(error => {
                          onErrorCurrent(error);
                          throw error;
                      }),
        [instanceId, onErrorCurrent, integrationManagerUpdateService],
    );
};
export const useIntegrationUpdateStepState = ({
    step,
    canFetch = false,
    instanceId,
    onError,
}) => {
    const { integrationManagerUpdateService } = useDic();

    return useQueryLoadable(
        async () =>
            canFetch
                ? integrationManagerUpdateService
                      .commonGetStepState(step, instanceId)
                      .then(getData)
                      .catch(onError)
                : pendingPromise(),
        [canFetch, integrationManagerUpdateService, step, instanceId, onError],
    );
};
