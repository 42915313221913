import Changed from "@/components/DateTime/Changed";
import { colors } from "@/components/DesignSystem/colors";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { DEPLOYMENT_STATUS } from "@/components/IntegrationRoutes/InstanceRepoIntegrationsRoutes";
import { IconWithTooltip } from "@/components/Integrations/components/IconWithTooltip";
import { NonStandardEntity } from "@/components/Integrations/components/NonStandardEntity";
import { t } from "@/translations";
import React from "react";

export const instanceRepoFiltersColumns = [
    {
        type: fieldTypes.TEXT,
        label: t("mapper-list.header.name"),
        name: "name",
        render: (text, record) => (
            <>
                <NonStandardEntity
                    display={!record.standard}
                    tooltip={t("mapper-list.header.non-standard-filter")}
                />
                {text}
            </>
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("mapper-list.header.type"),
        name: "type",
    },
    {
        type: fieldTypes.TEXT,
        label: t("customer-integration-list.header.deployment-status"),
        name: "deploymentStatus",
        render: (text, record) => (
            <>
                <IconWithTooltip
                    display={
                        record.deploymentStatus === DEPLOYMENT_STATUS.FAILED
                    }
                    tooltip={record.message}
                    icon={"info-circle"}
                    color={colors.danger.primary}
                />
                {text}
            </>
        ),
    },
    {
        type: fieldTypes.TEXT,
        canFilter: false,
        label: t("mapper-list.header.changed"),
        name: "updatedAt",
        render: (text, record) => <Changed key={record.id} data={record} />,
    },
    {
        type: fieldTypes.TEXT,
        label: t("mapper-list.header.revision-number"),
        name: "revisionNumber",
    },
    {
        type: fieldTypes.TEXT,
        label: t("mapper-list.header.path"),
        name: "path",
        visible: false,
    },
];
