import { Text } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { LocationLink } from "@/modules/router";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import eq from "lodash/fp/eq";
import some from "lodash/fp/some";
import PropTypes from "prop-types";
import React from "react";

const ERROR_CODES = {
    EXTERNAL_SERVICE_UNAVAILABLE: "EXTERNAL_SERVICE_UNAVAILABLE",
    PARTITION_CREDENTIALS_ARE_INCORRECT: "PARTITION_CREDENTIALS_ARE_INCORRECT",
    PARTITION_ERROR: "PARTITION_ERROR",
    API_INVALID_PARAMETER: "API_INVALID_PARAMETER",
};

const ERROR_CODE_TO_MESSAGE_KEY = {
    [ERROR_CODES.EXTERNAL_SERVICE_UNAVAILABLE]: () =>
        "version-integrity-check.error.external-service-unavailable",
    [ERROR_CODES.PARTITION_CREDENTIALS_ARE_INCORRECT]: () =>
        "version-integrity-check.error.partition-credentials-are-incorrect",
    // TODO: sub codes from API instead of matching messages
    [ERROR_CODES.API_INVALID_PARAMETER]: ({ messages = [] }) =>
        some(eq("Api supported since 7.0-SNAPSHOT!"), messages)
            ? "version-integrity-check.error.api-invalid-parameter.bijou"
            : undefined,
    // Lots of partitions on QA, related to migration, should not be present in prod
    [ERROR_CODES.PARTITION_ERROR]: () => undefined,
};

export const IntegrityCheckError = ({
    accountId,
    partitionId,
    partitionName,
    error,
}) => {
    const {
        accountAppLocations: { partitionCredentialsLocation },
    } = useDic();

    const messageKey = ERROR_CODE_TO_MESSAGE_KEY[error.code]?.(error);
    const message = messageKey
        ? t(messageKey, {
              partitionName,
          })
        : getErrorMessage(error);
    const link =
        error.code === ERROR_CODES.PARTITION_CREDENTIALS_ARE_INCORRECT ? (
            <LocationLink
                $location={partitionCredentialsLocation}
                $params={{
                    partitionId,
                    accountId,
                }}
            >
                {t(
                    "version-integrity-check.error.partition-credentials-are-incorrect-link",
                )}
            </LocationLink>
        ) : null;

    return (
        <Text type="error">
            {message}&nbsp;
            {link}
        </Text>
    );
};
IntegrityCheckError.propTypes = {
    accountId: PropTypes.number,
    partitionId: PropTypes.number,
    partitionName: PropTypes.string,
    error: PropTypes.shape({ code: PropTypes.string }).isRequired,
};
