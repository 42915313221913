import { Col, Gap, H3, Link, Row, Text } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { getDestination } from "@/components/Marketplace/hooks/useNavigateToPackages.hook";
import { STORAGE_VARIANT } from "@/components/Upload/useUploadStorage.hook";
import { T, t } from "@/translations";
import { downloadFile } from "@/utils/downloadUtils";
import { getErrorMessage } from "@/utils/state/error.utils";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { packageService } from "../../../../../views/Package/package.service";
import Upload from "../../../../Upload/Upload";
import { StepUiHeaders } from "../TableComponents/Skip";
import styles from "./PackageDataUpload.less";

const PackageFileUpload = ({
    accountId,
    partitionId,
    instanceId,
    step = {},
    onNext,
}) => {
    const [uploadedFile, setUploadedFile] = useState(null);

    const { name: tableName, stepLabel } = step;

    const { messageService } = useDic();
    const { destination, destinationId } = getDestination({
        partitionId,
        instanceId,
    });

    useEffect(() => {
        if (uploadedFile) {
            onNext({
                fileId: uploadedFile.id,
                storageVariant: STORAGE_VARIANT.S3,
            });
        }
    }, [onNext, uploadedFile]);

    const getExampleFile = () => {
        if (step.exampleFilePath) {
            packageService
                .getExampleFile(
                    accountId,
                    partitionId,
                    step.exampleFileExactPath,
                )
                .then(response => {
                    downloadFile(
                        response.data,
                        step.exampleFilePath.split("/").pop(),
                    );
                })
                .catch(e =>
                    messageService.error({
                        content: getErrorMessage(e.response.data),
                    }),
                );
        } else {
            downloadFile(
                step.mandatoryFields.map(s => `${s.label}`),
                `${tableName}.csv`,
            );
        }
    };

    return (
        <>
            {!step.ui?.title && (
                <H3>
                    <T id="package-data-upload.title" />
                </H3>
            )}
            <StepUiHeaders ui={step.ui} />
            <Text size="large">
                <FormattedMessage
                    id="package-data-upload.message"
                    values={{
                        tableName: <strong>{tableName}</strong>,
                    }}
                />
                {step?.mandatoryFields?.length > 0 && (
                    <T
                        id="package-data-upload.or-download-example-file"
                        values={{
                            downloadButton: (
                                <Link onClick={getExampleFile}>
                                    {t(
                                        "package-data-upload.button.download-example-file",
                                    )}
                                </Link>
                            ),
                        }}
                    />
                )}
            </Text>
            <Gap size="large" />
            <Row type="flex" align="middle" justify="start">
                <Col>
                    <Upload
                        accountId={accountId}
                        storage={STORAGE_VARIANT.S3}
                        destination={destination}
                        destinationId={destinationId}
                        variant="withIcon"
                        onFileUploaded={setUploadedFile}
                        accept=".csv,.xlsx,.zip"
                        hintMessage={
                            <FormattedMessage
                                id="package-data-upload.upload.hint"
                                className={styles.uploadHint}
                                values={{ tableName }}
                            />
                        }
                    />
                </Col>
            </Row>
        </>
    );
};

export default PackageFileUpload;

PackageFileUpload.propTypes = {
    accountId: PropTypes.number.isRequired,
    partitionId: PropTypes.number,
    instanceId: PropTypes.number,
    step: PropTypes.object.isRequired,
    onNext: PropTypes.func.isRequired,
};
