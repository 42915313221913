import AlertRulesList from "@/components/AlertRules/AlertRulesList";
import { alertRuleScope } from "@/components/AlertRules/services/alertRules.service";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React from "react";

export function AlertRulesAdminPage() {
    useFullWidthTableLayout();
    useHideBackButton();

    const { accountId } = useAccountAppParams();
    const scope = alertRuleScope.GLOBAL;
    const preferencesType = `${preferencesTypes.ALERT_RULES_LIST_TABLE}-${scope}`;

    return (
        <AlertRulesList
            accountId={accountId}
            preferencesType={preferencesType}
            scope={scope}
        />
    );
}
