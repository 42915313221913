import { axiosService } from "../axios";

export const taskService = {
    getTasks() {
        return axiosService.get("api/tasks");
    },
    getTask(taskId) {
        return axiosService.get(`api/tasks/${taskId}`);
    },
    getTaskResult(taskId) {
        return axiosService.get(`api/tasks/${taskId}/result`);
    },
};
