import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React from "react";
import { AccountDeployedPackages } from "./AccountDeployedPackages.component";

export function AccountDeployedPackagesPage() {
    useFullWidthTableLayout();

    const { accountId } = useAccountAppParams();

    return <AccountDeployedPackages accountId={accountId} />;
}
