import { Forms } from "@/components/DesignSystem";
import { t } from "@/translations";
import { converterExpressions } from "../../../Packages/PackageTableDefinitionPanel/stateReducer";

export const getConverterWarning = ({
    withConverterWarning,
    converterExpression,
    parserConfig,
}) => {
    if (!withConverterWarning) return null;

    const maybeAutogeneratedConverterType =
        converterExpressions.match.autoGeneratedType(converterExpression);
    const showWarning =
        maybeAutogeneratedConverterType &&
        (parserConfig
            ? !converterExpressions.validAutogenerated(
                  maybeAutogeneratedConverterType,
                  converterExpression,
                  parserConfig,
              )
            : true);
    if (!showWarning) return null;

    const warning = [
        "advanced-field-editor.converter-warning-1",
        "advanced-field-editor.converter-warning-2",
    ]
        .map(t)
        .join(" ");

    return warning;
};

export const createConverterWarningValidation =
    ({ withConverterWarning, converterExpression, parserConfig }) =>
    async () => {
        const warning = getConverterWarning({
            withConverterWarning,
            converterExpression,
            parserConfig,
        });
        return warning ? Forms.warning(warning) : Forms.success();
    };
