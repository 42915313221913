import { Col, H3, message, P, Row } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import Upload from "@/components/Upload/Upload";
import { t } from "@/translations";
import React from "react";

const TemplatesManagementUpload = () => {
    const {
        locationRouterService,
        marketplaceAppLocations: { templatesManagementLocation },
    } = useDic();

    function handleFileUploaded(response) {
        const info = `${response.label} ${response.version} was deployed successfully`;
        message.success(info);
        locationRouterService.navigate(templatesManagementLocation);
    }

    return (
        <Row type="flex" align="middle" justify="start">
            <Col>
                <H3>{t("marketplace.packages.upload")}</H3>
                <P>{t("marketplace.packages.perex")}</P>
                <Upload
                    variant="withIcon"
                    onFileUploaded={handleFileUploaded}
                    data={{
                        url: "/api/packages/repository/upload",
                    }}
                    accept=".jar"
                />
            </Col>
        </Row>
    );
};

export default TemplatesManagementUpload;
