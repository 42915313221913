import {
    Col,
    Gap,
    H5,
    Input,
    Option,
    Row,
    Select,
} from "@/components/DesignSystem";
import { useConfirmModal } from "@/modules/modal/imperative/useConfirmModal.hook";
import { T, t } from "@/translations";
import { produce } from "immer";
import clone from "lodash/clone";
import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";
import values from "lodash/values";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { conditionalOptionsModal } from "../../../../../apps/marketplaceApp/components/PackageDefinition/MappingOption.modal";
import {
    getAvailableOptions,
    getTypeLabel,
} from "../../../packagesPanel.utils";
import { ParsedType } from "../../constants";
import PackageDataMappingLayout from "../PackageDataMappingLayout/PackageDataMappingLayout";
import { PackageTable } from "../PackageTable/PackageTable";
// assets
import { defaultPreviewCellProps } from "@/components/Packages/PackageTableDefinitionPanel/components/PackageDataMappingLayout/DataPreviewTable.component";
import styles from "../styles.less";
import { getTitleAttribute, handleRowClassName } from "./priceParemeters.utils";

const optionKeys = [1, 2, 3, 4, 5, 6];

const getKeyValues = (count, mandatoryFields, keyValues, step) => {
    let result = [];

    for (let i = 1; i <= count; i++) {
        const field = keyValues[`key${i}`];
        result.push({
            name: `key${i}`,
            label: `Key ${i}`,
            value: field && field.value,
            type: ParsedType.STRING,
        });
    }
    if (mandatoryFields) {
        mandatoryFields.forEach(m => {
            const field = keyValues[m.name];
            result.push({
                name: m.name,
                label: m.label,
                type: m.type,
                value: field && field.value,
            });
        });
    }

    return result;
};

export const PPMatrixKeys = ({
    accountId,
    globalState,
    onNext,
    onBack,
    changeState,
    onCancel,
    step,
}) => {
    const confirmModal = useConfirmModal();
    const { fileInfo: tableExampleData = {}, userInputs = {} } = globalState;
    const { inputs = {}, keyValues = {} } = userInputs;
    const { mandatoryFields } = step;

    const calculatedUserInputs = useMemo(
        () =>
            isEmpty(userInputs) ? { inputs: {}, keyValues: {} } : userInputs,
        [userInputs],
    );

    useEffect(() => {
        if (!inputs || !keyValues) {
            changeState({
                userInputs: {
                    inputs: inputs || calculatedUserInputs.inputs,
                    keyValues: keyValues || calculatedUserInputs.keyValues,
                },
            });
        }
    }, []);

    const setInputs = i => {
        userInputs.inputs = i;
        changeState({ userInputs });
    };

    const setKeyValues = i => {
        userInputs.keyValues = i;
        changeState({ userInputs });
    };

    const createData = () => ({
        inputs: inputs,
        keyValues: keyValues,
    });

    const handleInputs = (name, value) => {
        setInputs(
            produce(inputs, draft => {
                draft[name] = value;
            }),
        );

        if (name === "key") {
            const diff = getKeyValues(value, mandatoryFields, keyValues);
            const byName = clone(keyBy(diff, "name"));
            setKeyValues(byName);
        }
    };

    const handleSelect = (record, value) => {
        setKeyValues(
            produce(keyValues, draft => {
                draft[record.name].value = value;
            }),
        );
    };

    const handleOnNext = () =>
        conditionalOptionsModal({
            condition: step.showMappingOptions && step.skipOptionalFields,
            data: createData(),
            accountId,
            onNext,
            onCancel,
            confirmModal,
        });

    const customColumns = ({ name, type }) => {
        return {
            ...defaultPreviewCellProps,
            label: createTitle(name, type),
            name,
            render: text => {
                return <div>{`${text}`}</div>;
            },
        };
    };

    const createTitle = (name, type) => {
        const value = getTitleAttribute(
            name,
            keyValues,
            calculatedUserInputs.attributes,
        );
        return (
            <div>
                {name}
                <div className={styles.type}>{getTypeLabel(type)}</div>
                <div className={styles.ppField}>{value.label}</div>
            </div>
        );
    };

    const columns = [
        {
            key: "pfxFieldName",
            title: () => t("package-data-upload.label.mandatory-fields"),

            render: (text, record) => (
                <H5 className={styles.headingColor}>{record.label}</H5>
            ),
        },
        {
            key: "valueFromFile",
            width: "30%",
            title: () => (
                <span className={styles.descriptionColor}>
                    <FormattedMessage
                        id="package-data-upload.label.file-fields"
                        values={{
                            fileName: tableExampleData.fileName,
                        }}
                    />
                </span>
            ),
            render: (text, record) => (
                <Select
                    placeholder={t("packages.select.placeholder")}
                    showSearch
                    allowClear
                    onChange={value => {
                        handleSelect(record, value);
                    }}
                    className={styles.itemSelect}
                    defaultValue={record.value}
                >
                    {getAvailableOptions(
                        record,
                        tableExampleData.columns,
                        keyValues,
                        userInputs.attributes,
                    )}
                </Select>
            ),
        },
    ];

    const disableNextButton = () =>
        values(keyValues).filter(v => !v.value).length > 0 ||
        (inputs && !inputs.key) ||
        (inputs && !inputs.label);

    return (
        <PackageDataMappingLayout
            dataExample={tableExampleData}
            disableNextButton={disableNextButton()}
            customColumns={customColumns}
            onNext={handleOnNext}
            onBack={onBack}
            onCancel={onCancel}
        >
            <T id="package-data-upload.price-parameters-mapper-message" />
            <Gap size="small" />
            <Row>
                <Col className={styles.matrixLabel} span={6}>
                    <label>
                        <T id="package-data-upload.pp-simple.input.label" />
                    </label>
                    <Input
                        placeholder={t(
                            "package-data-upload.pp-simple.input.placeholder",
                        )}
                        onChange={e => handleInputs("label", e.target.value)}
                        defaultValue={calculatedUserInputs.inputs.label}
                    />
                </Col>
                <Col span={6}>
                    <div>
                        <label>
                            <T id="package-data-upload.pp-matrix.input.number-of-fields.label" />
                        </label>
                    </div>
                    <Select
                        className={styles.matrixKeySelect}
                        placeholder={t(
                            "package-data-upload.pp-matrix.input.placeholder",
                        )}
                        onChange={value => handleInputs("key", value)}
                        defaultValue={calculatedUserInputs.inputs.key}
                    >
                        {values(optionKeys).map(n => (
                            <Option title={n} key={n}>
                                {n}
                            </Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            <Gap size="medium" />
            {inputs.key && (
                <PackageTable
                    fields={values(keyValues)}
                    handleRowClassName={handleRowClassName}
                    columns={columns}
                />
            )}
        </PackageDataMappingLayout>
    );
};

PPMatrixKeys.propTypes = {
    accountId: PropTypes.number.isRequired,
    changeState: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    globalState: PropTypes.object.isRequired,
    step: PropTypes.object,
};
