import { MarketplaceItemsApiService } from "@/components/Marketplace/api/marketplace/MarketplaceItemsApiService.class";
import { OffersApiService } from "@/components/Marketplace/api/offers/OffersApiService.class";
import { TemplatesApiService } from "@/components/Marketplace/api/templates/TemplatesApiService.class";
import { DeploymentStateApiMapper } from "@/components/Marketplace/mappers/packages/DeploymentStateApiMapper.class";
import { TemplatesApiMapper } from "@/components/Marketplace/mappers/templates/TemplatesApiMapper.class";
import { marketplaceAppLocationsMap } from "@/components/Marketplace/marketplaceApp.locations";
import { OffersService } from "@/components/Marketplace/services/offers/OffersService.class";
import { TemplatesService } from "@/components/Marketplace/services/templates/TemplatesService.class";
import { asClass, asValue } from "awilix";

export function registerMarketplaceApp({
    register,
    cradle: { locationRouterService },
}) {
    register({
        marketplaceAppLocations: asValue(marketplaceAppLocationsMap),
        templatesApiService: asClass(TemplatesApiService).singleton(),
        offersApiService: asClass(OffersApiService).singleton(),
        marketplaceItemsApiService: asClass(
            MarketplaceItemsApiService,
        ).singleton(),
        // mappers
        deploymentStateApiMapper: asClass(DeploymentStateApiMapper).singleton(),
        templatesApiMapper: asClass(TemplatesApiMapper).singleton(),
        templatesService: asClass(TemplatesService).singleton(),
        offersService: asClass(OffersService).singleton(),
    });

    locationRouterService.registerLocations(marketplaceAppLocationsMap);
}
