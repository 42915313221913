import FormattedDate from "@/components/DateTime/FormattedDate";
import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { Link } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";
import Moment from "react-moment";
import ContactEmailsColumnRender from "../ContactEmailsColumnRender/ContactEmailsColumnRender";
import PartitionNameColumnRenderer from "../PartitionNameColumnRenderer/PartitionNameColumnRenderer";
import { Property } from "../Property/Property.component";

export const createColumns = (hasReadRightsFor, hasCreatedStatus) => {
    return [
        {
            type: fieldTypes.TEXT,
            label: t("general.partition"),
            name: "serialNumber",
            className: "pmTable__column--withActionButton",
            render: (text, record) => {
                return (
                    <>
                        <PartitionNameColumnRenderer
                            partitionId={record.id}
                            hasRight={
                                hasCreatedStatus(record) &&
                                hasReadRightsFor(record.accountId, record.id)
                            }
                            projectId={record.accountId}
                            value={record.serialNumber}
                        />
                    </>
                );
            },
        },
        {
            visibleInPanel: true,
            panelOrder: 0,
            type: fieldTypes.TEXT,
            label: t("general.partition-label"),
            name: "name",
        },
        {
            type: fieldTypes.TEXT,
            label: t("general.release-name"),
            name: "releaseName",
        },
        {
            visibleInPanel: true,
            panelOrder: 4,
            type: fieldTypes.TEXT,
            label: t("general.major-version"),
            name: "majorVersion",
            visible: false,
        },
        {
            visibleInPanel: true,
            panelOrder: 6,
            type: fieldTypes.TEXT,
            label: t("partitions-list.header.contact-emails"),
            name: "contactEmails",
            render: (text, record) => (
                <ContactEmailsColumnRender
                    contactEmails={record.contactEmails}
                />
            ),
        },
        {
            type: fieldTypes.TEXT,
            label: t("general.partition-type"),
            name: "partitionType",
            width: "200px",
            render: (text, record) => (
                <React.Fragment>{record.partitionType}</React.Fragment>
            ),
        },
        {
            visible: false,
            type: fieldTypes.TEXT,
            label: t("general.uuid"),
            name: "uuid",
        },
        {
            type: fieldTypes.TEXT,
            label: t("customer-partitions-list.header.status"),
            name: "status",
        },
        {
            type: fieldTypes.DATE,
            label: t("customer-partitions-list.header.last-login"),
            name: "lastLogin",
            render: (text, record) =>
                record.lastLogin && (
                    <FormattedDate>{record.lastLogin}</FormattedDate>
                ),
        },
        {
            visible: false,
            visibleInPanel: true,
            panelOrder: 2,
            type: fieldTypes.DATE,
            label: t("general.install-date"),
            name: "installDate",
            render: (text, record) =>
                record.installDate && (
                    <Moment format="DD/MM/YYYY">{record.installDate}</Moment>
                ),
        },
        {
            visible: false,
            visibleInPanel: true,
            panelOrder: 3,
            type: fieldTypes.TEXT,
            label: t("general.cluster"),
            name: "clusterName",
            render: (text, record) =>
                record.clusterName && (
                    <Link href={record.baseUrl}>{record.clusterName}</Link>
                ),
            panelRenderValue: (text, record) => (
                <Property.Value href={record.baseUrl}>{text}</Property.Value>
            ),
        },
        {
            visible: false,
            visibleInPanel: true,
            panelOrder: 5,
            type: fieldTypes.TEXT,
            label: t("general.modules"),
            name: "modules",
        },
        {
            visible: false,
            visibleInPanel: true,
            panelOrder: 7,
            type: fieldTypes.DATETIME,
            label: t("general.last-changed"),
            name: "updatedAt",
            render: (text, record) => (
                <FormattedDateTime fromNow>
                    {record.updatedAt}
                </FormattedDateTime>
            ),
        },
        {
            visible: false,
            visibleInPanel: true,
            panelOrder: 8,
            panelProps: { hideEmpty: true },
            type: fieldTypes.TEXT,
            label: t("general.host"),
            name: "customSchema.host",
            canFilter: false,
            canSort: false,
            render: (_, record) => record.customSchema?.host,
        },
        {
            visible: false,
            visibleInPanel: true,
            panelOrder: 9,
            panelProps: { hideEmpty: true },
            type: fieldTypes.TEXT,
            label: t("general.database"),
            name: "customSchema.database",
            canFilter: false,
            canSort: false,
            render: (_, record) => record.customSchema?.database,
        },
        {
            visible: false,
            type: fieldTypes.NUMBER,
            label: t("general.admin-licences"),
            name: "maxAdminUsers",
        },
        // Temporary hidden, O.Spican request
        // {
        //     type: fieldTypes.BOOLEAN,
        //     label: t("customer-partitions-list.header.marked-to-delete"),
        //     name: "unused"
        // }
    ];
};
