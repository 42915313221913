export const STEPS_ESTIMATIONS_SECONDS = {
    ANALYSIS: 30,
    MODIFICATION: 30,
    TEST_UPDATE: 300,
    UPDATE: 120,
};
export const IM_UPDATE_RESULT = {
    OK: "OK",
    GENERAL_ERROR: "GENERAL_ERROR",
    MERGE_ERROR: "MERGE_ERROR",
};

export const MINUTE = 60;
