import { useContext, useEffect } from "react";
import { PageLayoutContext } from "./PageLayoutContext";

export const useSetBackButtonAction = fn => {
    const { setBackButtonAction } = useContext(PageLayoutContext);

    useEffect(() => {
        setBackButtonAction(() => fn);
        return () => {
            setBackButtonAction(null);
        };
    }, [setBackButtonAction, fn]);
};
