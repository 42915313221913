import React from "react";
import { Portal } from "react-portal";
export const REMOTE_LAYOUT_ID = "pagelayout-wrapper";

const RemoteLayout = ({ children }) => {
    return (
        <Portal node={document && document.getElementById(REMOTE_LAYOUT_ID)}>
            {children}
        </Portal>
    );
};

export default RemoteLayout;
