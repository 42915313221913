import { DeployedPackagesList } from "@/components/DeployedPackages/DeployedPackagesList.component";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import React from "react";

export function DeployedPackagesPage() {
    useFullWidthTableLayout();
    useHideBackButton();

    return <DeployedPackagesList />;
}
