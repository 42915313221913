import { useDic } from "@/components/Dic/useDic.hook";
import { SidebarAndContentLayout } from "@/components/Layouts";
import { LocationRoutes } from "@/modules/router";
import React from "react";
import { AccountAppSidebar } from "../AccountAppSidebar";

export const AccountApp = () => {
    const { accountAppLocations } = useDic();

    return (
        <SidebarAndContentLayout sidebar={<AccountAppSidebar />}>
            <LocationRoutes locations={accountAppLocations} />
        </SidebarAndContentLayout>
    );
};
