import { message } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useRouteParams } from "@/modules/router";
import { t } from "@/translations";
import join from "lodash/join";
import React from "react";
import { useRecoilState } from "recoil";
import { loginErrorState } from "../../apps/LoginApp/LoginPage";
import { LoginError } from "../../apps/LoginApp/components/LoginError";
import { LOGIN_ERROR_TYPE } from "../../apps/LoginApp/constants";
import pageStyles from "../../views/Pages/styles.less";
import { NewPassword } from "./NewPassword.component";

export const NewPasswordPageContainer = () => {
    const { requestId } = useRouteParams();

    const {
        passwordsService,
        locationRouterService,
        locations: { defaultRouteLocation },
    } = useDic();

    const [, setError] = useRecoilState(loginErrorState);

    const setNewPassword = (password, requestId) => {
        return passwordsService
            .submitPassword(password, requestId)
            .then(res => {
                if (res.error) {
                    const { error, messages } =
                        res?.error?.response?.data || {};
                    const errors = []
                        .concat(error ?? [])
                        .concat(messages ?? []);

                    throw new Error({ password: join(errors, "\n") });
                } else {
                    message.info(t("page.new-password.success"));
                    locationRouterService.navigate(defaultRouteLocation);
                }
            })
            .catch(e => {
                setError({
                    type: LOGIN_ERROR_TYPE.NEW_PASS,
                    message: e.response?.data?.error,
                });
            });
    };

    return (
        <div className={pageStyles.page}>
            <div className="pmFirstPassword">
                <LoginError />
                <NewPassword
                    onSubmit={values =>
                        setNewPassword(values.password, requestId)
                    }
                />
            </div>
        </div>
    );
};
