import capitalize from "lodash/capitalize";
import kebabCase from "lodash/kebabCase";

const ALIAS_PREFIX = "aliasOverview";
const NODE = "overviewApp";

function createAlias(name) {
    return {
        name: `${ALIAS_PREFIX}${capitalize(name)}}`,
        path: `/${kebabCase(name)}`,
        forwardTo: `${NODE}.${name}`,
    };
}

export const overviewAppAliases = [
    "accounts",
    "partitions",
    "integrations",
    "nodes",
    "updates",
    "servers",
    "remoteLogin",
].map(createAlias);
