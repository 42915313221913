import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import { editableCellRenderer } from "@pricefx/unity-components";

const ServerRoles = {
    LIST_PROCESSOR: "ListProcessor",
    CALCULATION_SLAVE: "CalculationSlave",
    CALCULATION_FLOW_PROCESSOR: "CalculationFlowProcessor",
    CALCULATION_FLOW_FAILOVER_PROCESSOR: "CalculationFlowFailoverProcessor",
    PA_DATALOAD: "PADataLoad",
    EVENT_PROCESSOR: "EventProcessor",
    EMAIL_PROCESSOR: "EmailProcessor",
    ACTIVITY_PROCESSOR: "ActivityProcessor",
    JANITOR: "Janitor",
    CALCULATION_SCHEDULER: "CalculationScheduler",
};

export const clusterNodesColumns = ({
    isClusterEditable,
    clusterPartitions,
    onClusterPartitionChange,
}) => {
    const partitionRender = (text, record, ...rest) =>
        editableCellRenderer(onClusterPartitionChange, undefined)(
            text,
            record,
            ...rest,
        );

    return [
        {
            type: fieldTypes.TEXT,
            label: t("cluster-nodes.header.name"),
            name: "uniqueName",
        },
        {
            type: fieldTypes.TEXT,
            label: t("cluster-nodes.header.jetty-host"),
            name: "jettyHost",
        },
        {
            type: fieldTypes.TEXT,
            label: t("cluster-nodes.header.jetty-port"),
            name: "jettyPort",
        },
        {
            type: fieldTypes.DATETIME,
            label: t("cluster-nodes.header.last-join-date"),
            name: "lastJoinDate",
        },
        {
            type: fieldTypes.DATETIME,
            label: t("cluster-nodes.header.last-seen-date"),
            name: "lastSeenDate",
        },
        {
            type: fieldTypes.OPTIONS,
            label: t("cluster-nodes.header.server-roles"),
            name: "serverRoles",
            editable: isClusterEditable,
            options: Object.values(ServerRoles).map(role => ({
                label: role,
                value: role,
            })),
            render: partitionRender,
            //TODO: Workaround should be removed when nested criteria for filtering are supported
            canFilter: false,
        },
        {
            type: fieldTypes.OPTIONS,
            label: t(
                "cluster-nodes.header.restrict-pa-data-to-loads-to-partition",
            ),
            name: "paDataLoadPartitions",
            editable: isClusterEditable,
            options: clusterPartitions.map(({ label, uniqueName }) => ({
                label: label ?? uniqueName,
                value: uniqueName,
            })),
            render: partitionRender,
            //TODO: Workaround should be removed when nested criteria for filtering are supported
            canFilter: false,
        },
        {
            type: fieldTypes.OPTIONS,
            label: t("cluster-nodes.header.restrict-calculation-to-partition"),
            name: "calcPartitions",
            editable: isClusterEditable,
            options: clusterPartitions.map(({ label, uniqueName }) => ({
                label: label ?? uniqueName,
                value: uniqueName,
            })),
            render: partitionRender,
            //TODO: Workaround should be removed when nested criteria for filtering are supported
            canFilter: false,
        },
    ];
};
