import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { withWorkflowFeedback } from "@/components/WorkFlow/workflowNotification";
import {
    pendingPromise,
    useMutationLoadable,
    useQueryLoadable,
} from "@/modules/loadable";
import { useCustomModal } from "@/modules/modal/CustomModal";
import { useReloadUserPermissions } from "@/security/hooks/useReloadUserPermissions.hook";
import { t } from "@/translations";
import map from "lodash/fp/map";
import { useContext } from "react";
import { TrackingContext } from "../../../../../mixpanel/TrackingContextProvider";
import { SUPER_ASSET_TYPE_TO_ASSET_TYPE } from "./PermissionsDrawer/sources.utils";

const fixSuperAssetTypes = map(record => ({
    ...record,
    ...(SUPER_ASSET_TYPE_TO_ASSET_TYPE[record.assetType]
        ? {
              superAssetType: record.assetType,
              assetType: SUPER_ASSET_TYPE_TO_ASSET_TYPE[record.assetType],
          }
        : {}),
}));

export const isPricefxUsername = (username = "") =>
    username?.match?.(/@pricefx\.(eu|com)$/);

export const useGroupedPermissionsResource = () => {
    const { permissionsApiService } = useDic();
    const resource = useQueryLoadable(
        () => permissionsApiService.fetchNonGlobalPermission(),
        [permissionsApiService],
    );
    return resource;
};

export const useAccountBusinessRolesResource = ({ accountId }) => {
    const { businessRoleApiService } = useDic();
    const resource = useQueryLoadable(
        () => businessRoleApiService.fetchBusinessRoles(accountId),
        [accountId, businessRoleApiService],
    );
    return resource;
};

export const useAccountAssetRolesResource = ({ accountId, canFetch }) => {
    const { assetRoleApiService } = useDic();
    const resource = useQueryLoadable(
        () =>
            !canFetch
                ? pendingPromise()
                : assetRoleApiService.fetchAssetRoles(accountId),
        [accountId, canFetch, assetRoleApiService],
    );
    return resource;
};

// ADMIN RIGHTS

export const useAdminUserRightsResource = ({ canFetch, userIds }) => {
    const { adminRightsApiService } = useDic();

    const resource = useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : adminRightsApiService
                      .fetchAdminUserRights(userIds)
                      .then(fixSuperAssetTypes),
        [userIds, canFetch, adminRightsApiService],
    );
    return resource;
};

export const useAdminUserWorkflowRightsResource = ({ canFetch, userIds }) => {
    const { adminRightsApiService } = useDic();

    const resource = useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : adminRightsApiService
                      .fetchAdminUserWorkflowRights(userIds)
                      .then(fixSuperAssetTypes),
        [userIds, canFetch, adminRightsApiService],
    );
    return resource;
};

export const useAdminGroupRightsResource = ({ groupNames, canFetch }) => {
    const { adminRightsApiService } = useDic();
    const resource = useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : adminRightsApiService
                      .fetchAdminGroupRights(groupNames)
                      .then(fixSuperAssetTypes),
        [groupNames, canFetch, adminRightsApiService],
    );
    return resource;
};

export const useAdminGroupWorkflowRightsResource = ({
    groupNames,
    canFetch,
}) => {
    const { adminRightsApiService } = useDic();
    const resource = useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : adminRightsApiService
                      .fetchAdminGroupWorkflowRights(groupNames)
                      .then(fixSuperAssetTypes),
        [groupNames, canFetch, adminRightsApiService],
    );
    return resource;
};

export const useSaveAdminRights = ({ afterSave }) => {
    const { adminRightsApiService, messageService } = useDic();
    const reloadUserPermissions = useReloadUserPermissions();
    const customModal = useCustomModal();
    const currentAfterSave = useCurrentHandler(afterSave);
    const { trackWorkflow } = useContext(TrackingContext);

    const resource = useMutationLoadable(
        async rightsChanges => {
            const { data } = await withWorkflowFeedback(
                { messageService, customModal, trackWorkflow },
                () => adminRightsApiService.modifyRights(rightsChanges),
                t("admin-assets-permissions-panel.command.batchSave.success"),
            );
            await reloadUserPermissions();
            currentAfterSave();
            return data;
        },
        [
            currentAfterSave,
            adminRightsApiService,
            customModal,
            messageService,
            reloadUserPermissions,
            trackWorkflow,
        ],
    );

    return resource;
};

// ACCOUNT RIGHTS

export const useAccountUserRightsResource = ({
    accountId,
    userIds,
    canFetch,
}) => {
    const { rightsApiService } = useDic();
    const resource = useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : rightsApiService
                      .fetchAccountUserRights(accountId, userIds)
                      .then(fixSuperAssetTypes),
        [accountId, userIds, canFetch, rightsApiService],
    );
    return resource;
};

export const useAccountUserWorkflowRightsResource = ({
    accountId,
    userIds,
    canFetch,
}) => {
    const { rightsApiService } = useDic();
    const resource = useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : rightsApiService
                      .fetchAccountUserWorkflowRights(accountId, userIds)
                      .then(fixSuperAssetTypes),
        [accountId, userIds, canFetch, rightsApiService],
    );
    return resource;
};

export const useAccountGroupRightsResource = ({
    accountId,
    groupIds,
    canFetch,
}) => {
    const { rightsApiService } = useDic();
    const resource = useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : rightsApiService
                      .fetchAccountGroupRights(accountId, groupIds)
                      .then(fixSuperAssetTypes),
        [accountId, groupIds, canFetch, rightsApiService],
    );
    return resource;
};

export const useAccountGroupWorkflowRightsResource = ({
    accountId,
    groupIds,
    canFetch,
}) => {
    const { rightsApiService } = useDic();
    const resource = useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : rightsApiService
                      .fetchAccountGroupWorkflowRights(accountId, groupIds)
                      .then(fixSuperAssetTypes),
        [accountId, groupIds, canFetch, rightsApiService],
    );
    return resource;
};

export const useSaveAccountRights = ({
    accountId,
    afterSave,
    success = "user-permissions-panel.command.onSave.success",
}) => {
    const { rightsApiService, messageService } = useDic();
    const reloadUserPermissions = useReloadUserPermissions();
    const customModal = useCustomModal();
    const { trackWorkflow } = useContext(TrackingContext);

    const resource = useMutationLoadable(
        async rightsChanges => {
            const { data } = await withWorkflowFeedback(
                { messageService, customModal, trackWorkflow },
                () => rightsApiService.modifyRights(accountId, rightsChanges),
                t(success),
            );
            await reloadUserPermissions();
            afterSave();
            return data;
        },
        [
            accountId,
            afterSave,
            rightsApiService,
            customModal,
            messageService,
            reloadUserPermissions,
            success,
            trackWorkflow,
        ],
    );

    return resource;
};

export const useDisplaySuperAssetsForGroupResource = ({
    canFetch,
    groupNames,
}) => {
    const { axiosService } = useDic();

    const resource = useQueryLoadable(
        async () =>
            !canFetch || !groupNames?.length
                ? pendingPromise()
                : Promise.all(
                      groupNames.map(groupName =>
                          axiosService
                              .get(`/api/admin/groups/${groupName}/users`)
                              .then(({ data }) => data),
                      ),
                  ).then(groupsUsers => {
                      const allPfx = groupsUsers.every(groupUsers =>
                          groupUsers.every(user =>
                              isPricefxUsername(user.username),
                          ),
                      );

                      return allPfx;
                  }),
        [groupNames, canFetch, axiosService],
    );
    return resource;
};
