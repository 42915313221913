import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { DeleteButtonWithConfirm } from "@/components/Delete";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import React from "react";

export const columns = ({ onDelete }) => [
    {
        label: t("api-token-table.label.comment"),
        name: "comment",
        type: fieldTypes.TEXT,
    },
    {
        label: t("api-token-table.label.username"),
        name: "username",
        type: fieldTypes.TEXT,
    },
    {
        label: t("api-token-table.label.createdBy"),
        name: "createdBy",
        type: fieldTypes.TEXT,
    },
    {
        label: t("api-token-table.label.createdAt"),
        name: "createdAt",
        type: fieldTypes.DATETIME,
        render: date => <FormattedDateTime>{date}</FormattedDateTime>,
    },

    {
        label: t("general.action"),
        name: "delete",
        width: 120,
        render: (_, record) => {
            return (
                <DeleteButtonWithConfirm
                    size="small"
                    data-test="api-token-delete-button"
                    message={t("api-token-table.delete.modal.message", {
                        tokenName: record.comment,
                    })}
                    onConfirm={() => onDelete(record)}
                    onlyLink
                />
            );
        },
    },
];
