import {
    Alert,
    Button,
    Forms,
    Gap,
    Link,
    Text,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import DisableSubmitOnEnter from "@/components/FeatureFlags/components/DisableSubmitOnEnter.component";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { pendingPromise, useQueryLoadable } from "@/modules/loadable";
import { VisibleWhenHasPermission } from "@/security/authorization";
import {
    INTEGRATION_EDIT_PERMISSIONS,
    includesPermissionForIntegration,
} from "@/security/permission.utils";
import { t } from "@/translations";
import { useFormSubmissionLoadable } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import PropTypes from "prop-types";
import React from "react";

const { useForm, useFieldValue, Form, Fields, pmValidators } = Forms;

const isVersion4plus = versionString => {
    if (!versionString) return false;

    const regex = /\d+(\.\d+)+/;
    const extractedVersion = versionString?.match(regex)?.[0];
    if (!extractedVersion) return false;
    return parseInt(extractedVersion.split(".")[0]) >= 4;
};

export const ImUpdate = ({ instanceQuery, onUpdate }) => {
    const { messageService, instanceService } = useDic();

    const maybeInstance = instanceQuery.loadable.valueMaybe();

    const tagsResource = useQueryLoadable(
        () =>
            isNaN(maybeInstance?.id)
                ? pendingPromise()
                : instanceService
                      .fetchTags(maybeInstance?.id)
                      .then(tags =>
                          tags.map(tag => ({ value: tag, label: tag })),
                      ),
        [instanceService, maybeInstance],
    );

    const onSubmit = async ({ values: { gitRef } }) => {
        const result = await instanceService.upgradeInstance({
            instanceName: maybeInstance.instanceName,
            server: maybeInstance.server,
            instanceId: maybeInstance.id,
            gitRef,
        });

        if (result.status === 204) {
            onUpdate?.();
            messageService.success({
                content: t("im-instance.message.upgrade-successfully-started"),
            });
        } else {
            messageService.error({
                content: t("im-instance.message.upgrade-failed"),
            });
        }
    };

    const { formId, handleSubmit } = useForm({
        onSubmit,
    });

    const gitRef = useFieldValue({ formId, name: "gitRef" });

    const submission = useFormSubmissionLoadable(formId);
    const submitting = submission.state === "loading";

    return (
        <VisibleWhenHasPermission
            permissionCheckFunc={c =>
                includesPermissionForIntegration(
                    c,
                    maybeInstance.id,
                    maybeInstance.accountId,
                    INTEGRATION_EDIT_PERMISSIONS,
                )
            }
        >
            <DisableSubmitOnEnter>
                <Text>
                    IM is running on <b>v{maybeInstance.imVersion}</b>,
                    available updates:
                </Text>
                <Gap size="small" />
                <Form formId={formId} onSubmit={handleSubmit}>
                    <Fields.Select
                        label={t("im-instance.version.update")}
                        placeholder={t("general.select")}
                        name="gitRef"
                        required
                        validator={pmValidators.isRequired}
                        showSearch
                        tooltip={t("im-instance.tooltip.versions-to-update")}
                        {...getLoadableSelectProps(tagsResource.loadable)}
                    />
                    <div
                        style={{
                            width: "384px",
                        }}
                    >
                        {gitRef === "v3.8.3" && (
                            <Alert
                                message={
                                    <>
                                        Your current instance uses an old SFTP
                                        solution that will be replaced by a new,
                                        more stable and reliable solution. By
                                        doing this upgrade, you will find both
                                        old and new solutions on your
                                        Integration instance.
                                        <Gap size="small" />
                                        We kindly ask you to migrate to the new
                                        SFTP solution:
                                        <ol>
                                            <li>
                                                Change your SFTP connection
                                                (Integration > Connections) to
                                                default-sftp-connection-new
                                                (automatically created).
                                            </li>
                                            <li>
                                                Replace connections in your
                                                Routes (Integration > Routes).
                                            </li>
                                            <li>
                                                Switch your ERP (or other)
                                                communication to the new SFTP
                                                server
                                            </li>
                                        </ol>
                                        Read more{" "}
                                        <Link
                                            href="https://pricefx.atlassian.net/wiki/display/IM/22-11+-+SFTP+Migration"
                                            targetBlank
                                            displayIcon="always"
                                        >
                                            here.
                                        </Link>
                                    </>
                                }
                                type="warning"
                                showIcon
                                withMargin
                                closable
                            />
                        )}
                        {maybeInstance.imVersion === "3.8.3" &&
                            isVersion4plus(gitRef) && (
                                <Alert
                                    message={
                                        <>
                                            Your current instance uses an old
                                            SFTP solution. If you completed the
                                            migration steps, the old solution is
                                            not needed anymore. <br />
                                            By upgrading to Integration manager
                                            4.0+ you confirm that your
                                            integrations are migrated and old
                                            SFTP solution on:{" "}
                                            <b>{maybeInstance.server}</b> can be
                                            deleted.
                                        </>
                                    }
                                    type="warning"
                                    showIcon
                                    withMargin
                                    closable
                                />
                            )}
                    </div>
                    <Button
                        loading={submitting}
                        type="primary"
                        label={t("general.update")}
                        onClick={e => {
                            e.stopPropagation();
                            handleSubmit(e);
                        }}
                    />
                </Form>
            </DisableSubmitOnEnter>
        </VisibleWhenHasPermission>
    );
};

ImUpdate.propTypes = {
    instanceQuery: PropTypes.object,
    onUpdate: PropTypes.func.isRequired,
};
