// import { ReactComponent as DownloadOutlined } from "@ant-design/icons-svg/inline-svg/outlined/download.svg";
import { Button, ButtonGroup, Option, Select } from "@/components/DesignSystem";
import { t } from "@/translations";
import { downloadFile } from "@/utils/downloadUtils";
import { isSameOrNewer } from "@/utils/versionUtils";
import { DownloadOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { logFileService } from "./logfile.service";
import { sortArchivedLogFiles } from "./logFileHeader.utils";

const fileDownloadSize = 50 * 30 * 1024;
const downloadRange = `bytes=-${fileDownloadSize}`;
const IM_VERSION_WITH_SUPPORT_OF_ARCHIVED_LOG_FILES = "1.1.17";

const LogFileHeader = ({ instanceId, instanceVersion }) => {
    const [archivedLogFiles, setArchivedLogFiles] = useState([]);

    const downloadSupported = useMemo(
        () =>
            isSameOrNewer(
                instanceVersion,
                IM_VERSION_WITH_SUPPORT_OF_ARCHIVED_LOG_FILES,
            ),
        [instanceVersion],
    );

    useEffect(() => {
        getArchivedLogFiles();
    }, [instanceId]);

    const getArchivedLogFilesMenuItems = () => {
        return archivedLogFiles.map(archivedLogFile => (
            <Option title={archivedLogFile.name} key={archivedLogFile.name}>
                {archivedLogFile.name}
            </Option>
        ));
    };

    function downloadArchivedLogFile(archivedLogFile) {
        logFileService
            .downloadArchivedLogFile(instanceId, archivedLogFile)
            .then(fileResponse => {
                downloadFile(
                    fileResponse.data,
                    archivedLogFile,
                    "application/x-gzip",
                );
            });
    }

    function downloadLogFile() {
        logFileService
            .download(instanceId, downloadRange)
            .then(fileResponse => {
                downloadFile(fileResponse.data, "main.log");
            });
    }

    function getArchivedLogFiles() {
        logFileService.getArchivedLogFiles(instanceId).then(({ data }) => {
            setArchivedLogFiles(data ? sortArchivedLogFiles(data) : []);
        });
    }

    return (
        <ButtonGroup spacing="none" buttonsSpacing="small">
            {downloadSupported && (
                <div style={{ width: "200px" }}>
                    <Select
                        showSearch
                        onChange={downloadArchivedLogFile}
                        placeholder={t("log.history-log")}
                    >
                        {getArchivedLogFilesMenuItems()}
                    </Select>
                </div>
            )}
            <Button
                tooltip={t("log.current-log")}
                onClick={() => downloadLogFile()}
                label={<DownloadOutlined />}
            />
        </ButtonGroup>
    );
};

LogFileHeader.propTypes = {
    instanceId: PropTypes.number.isRequired,
    instanceVersion: PropTypes.string,
};

export default LogFileHeader;
