import { Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { allowedDomains } from "@/components/Integrations/InstanceForm.validate";
import { CONNECTION_TYPE_PRICEFX_CLIENT } from "@/services/connections.service";
import { getErrorMessage } from "@/utils/state/error.utils";
import { find } from "lodash/fp";
import { useCallback, useMemo } from "react";

export const NAME_VALIDATION_PATTERN = new RegExp(/^[a-zA-Z0-9_-]*$/);

const isValidPfxUrl = value => {
    try {
        const url = new URL(value);
        if (!find(d => url.hostname.endsWith(d), allowedDomains)) return false;
        if (url.pathname !== "/pricefx/") return false;
        return url.protocol === "https:";
    } catch (_) {
        return false;
    }
};

const useConnectionExistsValidator = ({
    instanceId,
    skipValidationFor,
    withOnline,
}) => {
    const { connectionsService } = useDic();

    return useCallback(
        name =>
            skipValidationFor && skipValidationFor === name
                ? Forms.success()
                : connectionsService
                      .connectionExists({ instanceId, name, withOnline })
                      .then(({ data: exists }) =>
                          exists
                              ? Forms.error("Already exists")
                              : Forms.success(),
                      )
                      .catch(e => Forms.error(getErrorMessage(e))),
        [connectionsService, instanceId, skipValidationFor, withOnline],
    );
};

export const useConnectionNameValidator = ({
    instanceId,
    currentName,
    withOnline,
}) => {
    const connectionExistsValidator = useConnectionExistsValidator({
        instanceId,
        skipValidationFor: currentName,
        withOnline,
    });
    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                Forms.pmValidators.notBlank(),
                Forms.pmValidators.createPatternValidator(
                    NAME_VALIDATION_PATTERN,
                    "Name has a wrong format. Valid format is a-z A-Z, 0-9 _ - without spaces",
                ),
                connectionExistsValidator,
            ]),
        [connectionExistsValidator],
    );
};

export const useTypeValidator = () => Forms.pmValidators.isRequired;

// TODO: why all uris are not validated?
const isValidatedUrl = (type, field) =>
    (field.name === "url" && type === "pricefx") ||
    (field.name === "uri" && type === CONNECTION_TYPE_PRICEFX_CLIENT);

const createRegexpValidator = field =>
    Forms.pmValidators.createPatternValidator(
        field.regexp,
        `Please input a valid value. Expected format is ${field.regexp}`,
    );
const urlValidator = value =>
    isValidPfxUrl(value)
        ? Forms.success()
        : Forms.error(
              "Please input a valid value. Expected format is https://*.pricefx.eu/pricefx/",
          );
export const getFieldValidator = (field, type) => {
    const validators = [
        !field.optional && Forms.pmValidators.isRequired,
        field.regexp && createRegexpValidator(field),
        isValidatedUrl(type, field) && urlValidator,
    ].filter(Boolean);
    return Forms.validators.failOnFirst(validators);
};
