import { Table } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { EntityType } from "../../../../../constants/CommonConstats";
import styles from "../../AccountAssetAdminSettings/accountAdminSettings.style.less";
import { permissionAccountColumns } from "./UserPermissionTable.columns";

const RenderChildrenWhen = ({ cond, children }) => (!cond ? null : children);

export const UserPermissionTable = ({
    grantedPermissions = new Set(),
    groupedPermissions,
    onPermissionGrantedChange,
    entityTypes = [EntityType.PROJECT],
    isGranted,
    isIndeterminate,
    readOnly,
    ...restProps
}) => {
    const isPermissionGranted =
        isGranted || (permission => grantedPermissions.has(permission.name));

    const entityTypesIncludes = entityType =>
        entityTypes
            .map(e => e.toUpperCase())
            .includes(entityType.toUpperCase());

    const columns = useMemo(
        () =>
            permissionAccountColumns(
                onPermissionGrantedChange,
                isPermissionGranted,
                isIndeterminate,
                readOnly,
            ),
        [
            isIndeterminate,
            isPermissionGranted,
            onPermissionGrantedChange,
            readOnly,
        ],
    );

    return (
        <>
            <RenderChildrenWhen cond={entityTypesIncludes(EntityType.PROJECT)}>
                <Table
                    className={styles.permissionTable}
                    columns={columns}
                    dataSource={groupedPermissions[EntityType.PROJECT]}
                    bordered={false}
                    pagination={false}
                    rowKey="name"
                    {...restProps}
                />
            </RenderChildrenWhen>
            <RenderChildrenWhen
                cond={entityTypesIncludes(EntityType.PARTITION)}
            >
                <Table
                    className={styles.permissionTable}
                    columns={columns}
                    dataSource={groupedPermissions[EntityType.PARTITION]}
                    bordered={false}
                    pagination={false}
                    rowKey="name"
                    {...restProps}
                />
            </RenderChildrenWhen>
            <RenderChildrenWhen
                cond={entityTypesIncludes(EntityType.INTEGRATION)}
            >
                <Table
                    className={styles.permissionTable}
                    columns={columns}
                    dataSource={groupedPermissions[EntityType.INTEGRATION]}
                    bordered={false}
                    pagination={false}
                    rowKey="name"
                    {...restProps}
                />
            </RenderChildrenWhen>
        </>
    );
};

UserPermissionTable.propTypes = {
    groupedPermissions: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    grantedPermissions: PropTypes.instanceOf(Set),
    onPermissionGrantedChange: PropTypes.func.isRequired,
    isGranted: PropTypes.func,
    isIndeterminate: PropTypes.func,
    selectedAsset: PropTypes.shape({ assetType: PropTypes.string }),
    entityTypes: PropTypes.arrayOf(
        PropTypes.oneOf([
            ...Object.values(EntityType).map(e => e.toUpperCase()),
            ...Object.values(EntityType).map(e => e.toLowerCase()),
        ]),
    ),
};
