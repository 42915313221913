import { useDic } from "@/components/Dic/useDic.hook";
import { withWorkflowFeedback } from "@/components/WorkFlow/workflowNotification";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { TrackingContext } from "@/mixpanel/TrackingContextProvider";
import { useMutationLoadableWithNotification } from "@/modules/loadable";
import { useCustomModal } from "@/modules/modal";
import { useContext } from "react";

export const useRequestEngSupportUserMutation = ({
    partitionId,
    afterSuccess,
}) => {
    const { axiosService, messageService } = useDic();
    const customModal = useCustomModal();
    const { trackWorkflow } = useContext(TrackingContext);
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async values => {
            withWorkflowFeedback(
                { messageService, customModal, trackWorkflow },
                () => {
                    const payload = {
                        reason: values.reason,
                        expiration:
                            values.expiration?.toISOString?.() ??
                            values.expiration,
                    };

                    return axiosService.post(
                        `/api/partition-assets/${partitionId}/request-eng-support-user`,
                        payload,
                    );
                },
            ).then(currentAfterSuccess);
        },
        [
            axiosService,
            currentAfterSuccess,
            customModal,
            messageService,
            partitionId,
            trackWorkflow,
        ],
        "partition.request-eng-support-user.created",
    );
};
