import FieldGridNotPadded from "@/components/DataUploads/SftpManagement/FieldGridNotPadded.component";
import { Button, Forms } from "@/components/DesignSystem";
import {
    actionToRowValues,
    assetsToSingleSourceRowValues,
    assetToRowValues,
} from "@/components/EventWorkflows/EventWorkflowCopyModal";
import {
    useEOTActionEntitiesOptionsQuery,
    useEOTEntitiesOptionsQuery,
} from "@/components/EventWorkflows/loadables";
import { Hidden } from "@/components/Mappers/ExportMapper/ExportMapperList";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { ReactComponent as ArrowRight } from "@pricefx/unity-components/src/icons/unicons/arrow-right.svg";
import { noop, sortBy } from "lodash/fp";
import React, { useRef } from "react";

const LAYOUT = [{}, {}, {}, { width: 250 }, { width: 32 }, { width: 250 }];

const createCopyOriginal =
    ({ setValues, getRowBag, getFieldName, rowId }) =>
    () =>
        getRowBag().then(({ value }) =>
            setValues({
                [getFieldName(rowId, "copy.id")]: value["original.id"],
            }),
        );

const OriginsMappingList = ({
    name,
    originalName = "original.label",
    originalLabel,
    copyName = "copy.id",
    copyLabel,
    initialList,
    SelectComponent = Forms.Fields.Select,
    selectExtraProps = {},
    setValues = noop,
    hiddenFields = [],
}) => {
    const initialListRef = useRef(initialList);

    return (
        <FieldGridNotPadded>
            <Forms.FieldGrid layout={LAYOUT}>
                <Forms.List name={name} initialValue={initialListRef.current}>
                    {({ rows, getFieldName }) =>
                        rows.map(({ id, fieldProps, getBag }, index) => (
                            <Forms.FieldGrid.Row key={id}>
                                {hiddenFields.map(({ name }, index) => (
                                    <Hidden {...fieldProps(name)} key={index} />
                                ))}
                                <Forms.Fields.Input
                                    disabled
                                    label={originalLabel}
                                    {...fieldProps(originalName)}
                                />
                                <Forms.UIField name="actions">
                                    <Button
                                        icon={ArrowRight}
                                        onClick={createCopyOriginal({
                                            setValues,
                                            getFieldName,
                                            rowId: id,
                                            getRowBag: getBag,
                                        })}
                                    />
                                </Forms.UIField>

                                <SelectComponent
                                    {...fieldProps(copyName)}
                                    required
                                    label={copyLabel}
                                    validator={Forms.pmValidators.isRequired}
                                    rowInitialValues={
                                        initialListRef.current?.[index]
                                    }
                                    {...selectExtraProps}
                                />
                            </Forms.FieldGrid.Row>
                        ))
                    }
                </Forms.List>
            </Forms.FieldGrid>
        </FieldGridNotPadded>
    );
};

const AssetSelect = ({ rowInitialValues, accountId, ...props }) => {
    const eotSourceEntitiesQuery = useEOTEntitiesOptionsQuery({
        accountId,
        eoType: rowInitialValues["copy.type"],
    });
    return (
        <Forms.Fields.Select
            {...getLoadableSelectProps(
                eotSourceEntitiesQuery.loadable,
                sortBy("label"),
            )}
            {...props}
        />
    );
};

export const AssetsMapping = ({
    name,
    originalLabel,
    copyLabel,
    assets,
    selectExtraProps = {},
    setValues = noop,
}) => {
    return (
        <OriginsMappingList
            name={name}
            originalLabel={originalLabel}
            copyLabel={copyLabel}
            initialList={assets.map(assetToRowValues)}
            SelectComponent={AssetSelect}
            selectExtraProps={selectExtraProps}
            setValues={setValues}
            hiddenFields={[
                { name: "original.type" },
                { name: "original.id" },
                { name: "copy.type" },
            ]}
        />
    );
};

export const SingleSourceMapping = ({
    name,
    originalLabel,
    copyLabel,
    assets,
    selectExtraProps = {},
    setValues = noop,
}) => {
    return (
        <OriginsMappingList
            name={name}
            originalLabel={originalLabel}
            copyLabel={copyLabel}
            initialList={assetsToSingleSourceRowValues(assets)}
            SelectComponent={AssetSelect}
            selectExtraProps={selectExtraProps}
            setValues={setValues}
            hiddenFields={[
                { name: "original.type" },
                { name: "original.id" },
                { name: "copy.type" },
            ]}
        />
    );
};

const ActionSelect = ({ rowInitialValues, accountId, ...props }) => {
    const eotTargetActionEntitiesQuery = useEOTActionEntitiesOptionsQuery({
        accountId,
        eoType: rowInitialValues._type, // PARTITION
        entityId: rowInitialValues._id, // {partitionId}
        actionType: rowInitialValues?.["copy.targetType"], // LOGIC
        canFetch: true,
    });
    return (
        <Forms.Fields.Select
            {...getLoadableSelectProps(
                eotTargetActionEntitiesQuery.loadable,
                sortBy("label"),
            )}
            {...props}
        />
    );
};

export const PMActionsMapping = ({
    name,
    originalLabel,
    copyLabel,
    pmActions,
    selectExtraProps = {},
    setValues,
}) => {
    return (
        <OriginsMappingList
            name={name}
            originalLabel={originalLabel}
            copyLabel={copyLabel}
            initialList={pmActions.map(actionToRowValues)}
            SelectComponent={ActionSelect}
            selectExtraProps={selectExtraProps}
            setValues={setValues}
            hiddenFields={[
                { name: "original.targetType" },
                { name: "copy.targetType" },
                { name: "original.id" },
            ]}
        />
    );
};
