import { assetRoleApiSchema } from "./assetRoleApi.schemas";

export class AssetRoleApiService {
    baseUrl = "/api/projects";
    /**
     *
     * @type {HttpService}
     */
    httpService = null;
    /**
     *
     * @type {Mapper}
     */

    constructor({ httpService }) {
        this.httpService = httpService;
    }

    /**
     *  @param {number} accountId
     *  @returns {Promise<AssetRolesAndAssetsBagEntity>}
     */
    fetchAssetRoles(accountId) {
        return this.httpService.get(this.getUrl(accountId), {
            schema: assetRoleApiSchema,
        });
    }

    /**
     *  @param {AssetRolesAndAssetsBagEntity} assetRole
     *  @param {number} accountId
     *
     */
    crateAssetRole(assetRole, accountId) {
        return this.httpService.post(this.getUrl(accountId), assetRole);
    }

    /**
     *  @param {AssetRolesAndAssetsBagEntity} assetRole
     *  @param {number} accountId
     *
     */
    updateAssetRole(assetRole, accountId) {
        return this.httpService.post(
            this.getUrl(accountId, assetRole.id),
            assetRole,
        );
    }

    /**
     *  @param {number} assetRoleId
     *  @param {number} accountId
     *
     */
    deleteAssetRole = (accountId, assetRoleId) => {
        return this.httpService.delete(this.getUrl(accountId, assetRoleId));
    };

    getUrl(accountId, assetRoleId) {
        const assetsUrl = `${this.baseUrl}/${accountId}/asset-roles`;
        return assetRoleId ? `${assetsUrl}/${assetRoleId}` : assetsUrl;
    }
}
