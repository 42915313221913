import { constants, L18NContext } from "@pricefx/unity-components";
import { Input as AntInput } from "antd";
import identity from "lodash/fp/identity";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import React, {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

const { FIELD_TYPES, FORMAT_TYPES } = constants;

const emptyValues = Object.freeze([]);

export const parseStringValue = (text, formatters, formatType, separator) => {
    if (!text) {
        return emptyValues;
    }
    const isPercent = formatType === FORMAT_TYPES.PERCENT;
    return text
        .split(separator)
        .filter(identity)
        .map(value => {
            const parsed = formatters.parseNumber(value);
            if (parsed !== null && isPercent) {
                return parsed / 100;
            }
            return parsed;
        })
        .filter(a => a !== null);
};
export const formatValues = (values, formatters, formatType, separator) => {
    if (!Array.isArray(values)) {
        return "";
    }
    const formatter = formatters.format(formatType, null);
    return values
        ? values
              .filter(a => a || a === 0)
              .map(formatter)
              .join(`${separator} `)
        : "";
};

const dummyColumn = {
    label: "",
    name: "",
    type: FIELD_TYPES.NUMBER,
    formatType: FORMAT_TYPES.NUMERIC,
};

const InputNumberMultiple = forwardRef(
    (
        {
            onChange = noop,
            onBlur = noop,
            value,
            valueSeparator = ";",
            column: { formatType } = dummyColumn,
            ...props
        },
        ref,
    ) => {
        const [localValue, setLocalValue] = useState("");
        const { formatters } = useContext(L18NContext);

        useEffect(() => {
            setLocalValue(
                formatValues(value, formatters, formatType, valueSeparator),
            );
        }, [value, formatType, valueSeparator]);

        const localOnBlur = useCallback(() => {
            const parsed = parseStringValue(
                localValue,
                formatters,
                formatType,
                valueSeparator,
            );
            onChange && onChange(parsed);
            onBlur && onBlur();
        }, [onBlur, onChange, localValue]);

        const localOnChange = useCallback(event => {
            setLocalValue(event?.target?.value);
        }, []);

        return (
            <AntInput
                {...props}
                onBlur={localOnBlur}
                onChange={localOnChange}
                ref={ref}
                size="small"
                value={localValue}
            />
        );
    },
);
InputNumberMultiple.displayName = "pmInputNumberMultiple";

export default InputNumberMultiple;

InputNumberMultiple.propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.any,
    valueSeparator: PropTypes.string,
    column: PropTypes.object,
};
