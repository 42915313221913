import { showMassDelete } from "@/components/DataUploads/utils";
import { Button, Forms, Gap, H3 } from "@/components/DesignSystem";
import { FileInfoFetcher } from "@/components/FileParsing/FileInfoFetcher";
import { FileParsingFields } from "@/components/FileParsing/FileParsingFields";
import { useIncludesRightsForPartition } from "@/components/hooks/useIncludesRightsForPartition.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { DataPreviewTable } from "@/components/Packages/PackageTableDefinitionPanel/components/PackageDataMappingLayout/DataPreviewTable.component";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import { massDeleteOptions } from "@/constants/deployment.constants";
import { useConfirmModal } from "@/modules/modal";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

const WizardParserSettings = ({
    projectId,
    partitionId,
    allValues = {},
    fileId = allValues.fileId, // TODO: rm
    onNext,
    onBack,
    onCancel,
    entityType = allValues.entityType, // rm
    initialValues = allValues, // rm
    onFileInfo,
}) => {
    // useAlertEffect(true, () => ({
    //     type: "warning",
    //     message:
    //         "The uploaded file uses different encoding than UTF-8. There might be errors in the Data Upload."
    // }));

    const showMassDeleteOptions = useMemo(
        () => showMassDelete(entityType),
        [entityType],
    );
    const hasEditRights = useIncludesRightsForPartition([
        "partition.data_management.edit",
    ]);

    const confirmModal = useConfirmModal();
    const onSubmit = async bag => {
        if (bag.values.massDelete === "AFTER")
            return confirmModal.confirm({
                title: t("partition-send-data.delete-after-warning.title"),
                message: t("partition-send-data.delete-after-warning.message"),
                okText: t("partition-send-data.delete-after-warning.ok"),
                cancelText: t("general.cancel"),
                onConfirm: () => onNext(bag.values),
            });
        else return onNext(bag.values);
    };
    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit,
    });
    useSetValidatedInitialValues({ initialValues, setValues, setTouched });

    const allowClear = false;
    const readOnly = !hasEditRights(projectId, partitionId);

    // TODO: investigate why const loadableValues = useRecoilValueLoadable($values(formId)) causes infinite rendering loop here
    return (
        <FileInfoFetcher
            formId={formId}
            projectId={projectId}
            typeEntityId={partitionId}
            type="partitions"
            fileId={fileId}
            onFileInfo={onFileInfo}
        >
            {({ fileInfo }) => (
                <Forms.Form onSubmit={handleSubmit} formId={formId}>
                    <H3>{t("package-file-parsing.options.message")}</H3>
                    <FileParsingFields
                        disabled={readOnly}
                        allowClear={allowClear}
                    />

                    <DataPreviewTable
                        showTypes={false}
                        dataExample={fileInfo ?? {}}
                    />
                    <Gap size="small" />

                    <H3>{t("package-file-upload.options.message")}</H3>
                    {showMassDeleteOptions && (
                        <Forms.Fields.Select
                            name="massDelete"
                            label={t(
                                "partition-send-data.label.mass-delete-strategy",
                            )}
                            placeholder={t(
                                "partition-send-data.label.mass-delete-strategy",
                            )}
                            disabled={readOnly}
                            validator={Forms.pmValidators.isRequired}
                            allowClear={allowClear}
                            options={massDeleteOptions}
                            required
                            tooltip={t(
                                "partition-send-data.label.mass-delete-strategy.tooltip",
                            )}
                        />
                    )}

                    <Forms.Fields.DatePicker
                        name="schedule"
                        label={t("partition-send-data.label.scheduled-time")}
                        showTime
                        format={"YYYY-MM-DD HH:mm"}
                        tooltip={t(
                            "partition-send-data.tooltip.scheduled-time",
                        )}
                    />

                    <Forms.Fields.Checkbox
                        name="sendEmail"
                        label={t("partition-send-data.label.send-email")}
                    />

                    <Forms.SubmitButton
                        type="primary"
                        formId={formId}
                        disabled={!fileInfo}
                        label={t("partition-send-data.button.continue")}
                        data-test="submit"
                    />

                    <BackButton onBack={onBack} />

                    <Button
                        type="text"
                        onClick={onCancel}
                        label={t("partition-send-data.button.cancel")}
                        data-test="cancel"
                    />
                </Forms.Form>
            )}
        </FileInfoFetcher>
    );
};

WizardParserSettings.propTypes = {
    projectId: PropTypes.number.isRequired,
    partitionId: PropTypes.number.isRequired,
    fileId: PropTypes.number.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    onFileInfo: PropTypes.func.isRequired,
    entityType: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
};

export default WizardParserSettings;
