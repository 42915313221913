import { Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { pendingPromise, useQueryLoadable } from "@/modules/loadable";
import isNull from "lodash/fp/isNull";
import map from "lodash/fp/map";
import pipe from "lodash/fp/pipe";
import sum from "lodash/fp/sum";
import values from "lodash/fp/values";
import { MAX_KEY_LENGTH } from "../constants";
import { useValues } from "./useValues.hook";

export const sumValues = pipe(values, map(Number), sum);

export const useBusinessKeys = ({
    entityName,
    entityType,
    partitionId,
    initialBusinessKey,
}) => {
    const showBusinessKeys =
        (entityType === "CX" || entityType === "PX" || entityType === "SX") &&
        entityName;

    const { formId, handleSubmit } = Forms.useForm({
        initialValues: initialBusinessKey,
    });

    const { dataUploadService } = useDic();
    const businessKeySettingsResource = useQueryLoadable(
        async () =>
            !showBusinessKeys
                ? pendingPromise()
                : dataUploadService.getBusinessKeySettings(
                      partitionId,
                      entityType,
                      entityName,
                  ),

        [
            partitionId,
            entityType,
            entityName,
            showBusinessKeys,
            dataUploadService,
        ],
    );
    const formValues = useValues(formId);
    const keysLength = sumValues(formValues);
    const valid =
        !showBusinessKeys ||
        (businessKeySettingsResource.loadable.state === "hasValue" &&
            keysLength <= MAX_KEY_LENGTH &&
            !values(formValues).some(isNull)); // TODO: Form validation result

    return {
        formProps: { formId, handleSubmit },
        loadableBusinessKeys: businessKeySettingsResource.loadable,
        keysLength,

        showBusinessKeys,
        formValues,
        valid,
    };
};
