import {
    useCopyUploadMutation,
    useDataLoadPartitionsQuery,
} from "@/components/DataUploads/loadables";
import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { isLoading, useMapLoadableMemoized } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { get, map, pipe } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

const partitionToOption = ({ serialNumber: label, id: value }) => ({
    value,
    label,
});

export const CopyModal = ({
    projectId,
    afterSuccess,
    onError,
    modalControls: { record, hide },
}) => {
    const optionsLoadable = useMapLoadableMemoized(
        useDataLoadPartitionsQuery({ projectId, canFetch: !!record }).loadable,
        useCurrentHandler(map(partitionToOption)),
    );
    const mutation = useCopyUploadMutation({
        uploadId: record?.id,
        afterSuccess,
        onError,
    });

    const { formId, handleSubmit } = Forms.useForm({
        onSubmit: pipe(get("values"), mutation.mutate),
    });
    const partitionIds = Forms.useFieldValue({ formId, name: "partitionIds" });
    const { dataUploadService } = useDic();
    const dupliciteNameValidator = useCallback(
        async name => {
            if (!partitionIds) return Forms.success(); // Forms init :-/

            const duplicates = await dataUploadService
                .dataLoadDuplicatesForPartitions(name, partitionIds)
                .then(getData)
                .catch(e => console.log("Error getting name duplicites"));

            if (!duplicates?.length) return Forms.success();
            else
                return Forms.error(
                    t("data-upload.copy.name-duplication", {
                        duplicates: duplicates.join(","),
                    }),
                );
        },
        [dataUploadService, partitionIds],
    );

    return (
        <Modal visible={!!record} onClose={hide}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("data-upload.copy.modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Forms.Form formId={formId} onSubmit={handleSubmit}>
                        <Forms.FieldGroup width="max" inputWidth="max">
                            <Forms.Fields.Select
                                required
                                name="partitionIds"
                                label={t("data-upload.copy.label.partitions")}
                                mode="multiple"
                                validator={Forms.pmValidators.isRequired}
                                {...getLoadableSelectProps(optionsLoadable)}
                            />
                            <Forms.Fields.Input
                                required
                                name="name"
                                label={t("data-upload.copy.label.name")}
                                validator={Forms.validators.failOnFirst([
                                    Forms.pmValidators.isRequired,
                                    dupliciteNameValidator,
                                ])}
                                initialValue={record?.name}
                                validateOnMount={false}
                            />
                        </Forms.FieldGroup>
                    </Forms.Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.copy"),
                                    type: "primary",
                                    htmlType: "submit",
                                    formId,
                                    disabled: isLoading(optionsLoadable),
                                },
                                {
                                    label: t("general.cancel"),
                                    type: "text",
                                    onClick: hide,
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

CopyModal.propTypes = {
    projectId: PropTypes.number,
};
