import { Option } from "@/components/DesignSystem";
import cloneDeep from "lodash/cloneDeep";
import difference from "lodash/difference";
import flatten from "lodash/flatten";
import { findIndex, update } from "lodash/fp";
import get from "lodash/get";
import intersection from "lodash/intersection";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import merge from "lodash/merge";
import replace from "lodash/replace";
import sortBy from "lodash/sortBy";
import toUpper from "lodash/toUpper";
import union from "lodash/union";
import values from "lodash/values";
import React from "react";
import { isMultiple } from "../CompositeSelect/CompositeSelect";
import styles from "./PackageTableDefinitionPanel/components/styles.less";
import {
    InputType,
    ParsedType,
    types,
} from "./PackageTableDefinitionPanel/constants";

const typesMap = keyBy(types, "name");

export const getTypeLabel = type => {
    return get(typesMap, `${type}.label`);
};

export const normalize = f => replace(toUpper(f), new RegExp(" ", "g"), "");

export const compareFields = (f1, f2) => {
    return normalize(f1) === normalize(f2);
};

export const resolveBusinessColumns = (arr1, arr2) =>
    union(cloneDeep(arr1), cloneDeep(arr2))
        .filter(field => field.businessKey === true)
        .map(field => getSimpleValue(field.value));

export const getSelectedType = (array, name) => {
    if (isEmpty(array)) {
        return undefined;
    }
    const byLabel = keyBy(values(array), "label");
    return byLabel[name] && byLabel[name].type;
};

export const disableTypeOptions = (columns, record, type) => {
    const byName = keyBy(columns, "name");
    const originalType = byName[record.label];
    const possibleValues = [
        ParsedType.STRING,
        originalType && originalType.type,
    ];
    return !possibleValues.includes(type);
};

export const disableNextButton = map => {
    if (isEmpty(map)) {
        return false;
    }

    let ret = values(map).filter(
        v => (!v.value || isEmpty(v.value)) && !v.skip,
    );
    return ret.length > 0;
};

export const reorderAttributes = (array, fixedAttributes = []) => {
    if (isEmpty(array)) {
        return array;
    }

    let id = 1;
    return array.map(field => {
        while (fixedAttributes.includes(`attribute${id}`)) ++id;

        return { ...field, name: `attribute${id++}` };
    });
};

export const getFixedAttributes = fields =>
    fields && values(fields).map(field => field.name);

export const isCombined = value => isArray(value) && value.length > 1;

export const getSimpleValue = value => (isArray(value) ? value.join() : value);

export const resolveType = (columns, value, inputType) => {
    if (inputType === InputType.FORMULA) {
        return ParsedType.NUMBER;
    }
    if (inputType === InputType.GROOVY) {
        // IMHO this does not make sense
        return ParsedType.STRING;
    }
    if (inputType === InputType.COMPOSED) {
        return ParsedType.COMBINED;
    }
    const byName = keyBy(columns, "name");
    return isCombined(value) ? ParsedType.COMBINED : byName[value]?.type; // TODO: refactor upstream and rm this
};

export const disableBusinessKey = (fields, record) => {
    if (record.businessKeyChangeable === false) {
        return true;
    }

    return (
        record.skip ||
        (fields.filter(field => field.businessKey).length >= 3 &&
            !record.businessKey)
    );
};

export const getAvailableOptions = (record, availableFields, map1, map2) => {
    return isCombined(record.value)
        ? getOptions(getHeaders(availableFields))
        : getOptions(
              getAllAvailableFieldsForRecord(
                  record,
                  map1,
                  isEmpty(map2) ? {} : map2,
                  availableFields,
              ),
          );
};

export const getAllAvailableFields = (map1, map2, fields) => {
    return getAvailableFields(merge(cloneDeep(map1), map2), fields);
};

export const getAllAvailableFieldsForRecord = (record, map1, map2, fields) => {
    return filterUnsupportedTypes(
        record,
        getAvailableFields(merge(cloneDeep(map1), map2), fields),
    );
};

export const filterUnsupportedTypes = (record, allAvailableFields) =>
    allAvailableFields.filter(f =>
        hasConvertibleType(
            getOrDefaultString(f.type),
            getOrDefaultString(record.type),
        ),
    );

export const hasConvertibleType = (fromFileType, fromFieldDefinitionType) =>
    fromFieldDefinitionType === ParsedType.STRING ||
    fromFileType === fromFieldDefinitionType;

export const getAvailableFields = (mandatoryFieldsMap, exampleDataColumns) => {
    // object used for faster lookup: usedColumnNamesMap = { columnName: true }
    const usedColumnNamesMap = Object.values(mandatoryFieldsMap).reduce(
        (acc, { value }) => {
            // value in mandatoryFields corresponds to column name(s)
            if (Array.isArray(value)) {
                // combined value type, but yielding the same result as simple value, cannot be used again:
                if (value.length === 1)
                    return {
                        ...acc,
                        [value[0]]: true,
                    };

                // combined value type (concatenated yields different value) can be used again:
                return acc;
            }

            // already used, cannot be used again:
            return {
                ...acc,
                [value]: true,
            };
        },
        {},
    );
    const result = exampleDataColumns.filter(
        ({ name }) => !usedColumnNamesMap[name],
    );
    return sortBy(result, "name");
};

export const getOptions = availableFields =>
    availableFields.map(h => (
        <Option title={h.name} key={h.name} value={h.name}>
            {h.name}
        </Option>
    ));

export const getHeaders = columns =>
    columns
        ? sortBy(
              columns.map(c => {
                  return {
                      name: c.name,
                  };
              }),
              "name",
          )
        : [];

export const resolveTextColor = (fields, labelName) => {
    return values(fields)
        .filter(field => field.name === labelName)
        .map(field => ({
            labelColor: field.skip ? styles.textDisabled : styles.labelColor,
            descriptionColor: field.skip
                ? styles.textDisabled
                : styles.descriptionColor,
        }))
        .shift();
};

export const resolveNewFieldValue = (record, changes, columns, records) => {
    const path = Array.isArray(records)
        ? findIndex({ name: record.name }, records)
        : record.name;
    const updater = selected => ({
        ...selected,
        ...changes,
        skip: false,
        resolvedType: resolveType(
            columns,
            isEmpty(changes.value) ? record.value : changes.value,
            changes.inputType,
        ),
    });
    return update(path, updater, records);
};

export const showSwitchLabel = fields =>
    values(fields).filter(field => isMultiple(field.type)).length >= 1;

export const addConditionalFields = (
    fields,
    conditionalFields,
    processedFileNames,
    path,
) => {
    return union(
        fields,
        calculateValidConditionalFields(
            conditionalFields,
            processedFileNames,
            path,
        ),
    );
};

const calculateValidConditionalFields = (
    conditionalFields,
    processedFileNames,
    path,
) => {
    return flatten(
        map(conditionalFields, cf => {
            const shouldAdd = cf.condition
                ? isEmpty(difference(cf.steps, processedFileNames))
                : isEmpty(intersection(cf.steps, processedFileNames));

            if (shouldAdd) {
                return cf[path];
            }

            return [];
        }),
    );
};

export const getOrDefaultString = value => value || ParsedType.STRING;

export const prefillOptionalField = (field, column) => ({
    ...column,
    ...field, // TODO: check all usages and remove spreads
    name: field?.name || column?.name,
    parsedValues: field?.parsedValues || column?.parsedValues,
    skip: field?.skip ?? column.skip ?? false,
    label: field?.label || column.name,
    value: column.name,
    originalFieldName: column.name,
    type: field?.type || column.type,
});

export const fieldFromCol = col => ({
    ...col, // TODO: check all usages and remove spread
    parsedValues: col.parsedValues,
    name: col.name,
    skip: col.skip ?? false,
    label: col.name,
    value: col.name,
    originalFieldName: col.name,
    type: col.type,
});

export const getOptionalFields = (
    { mandatoryFieldsMap = {}, significantFields = {}, optionalFields = [] },
    tableExampleData,
) => {
    // all columns from file, which are not mapped to mandatory/significant fields:
    const availableColumns = getAvailableFields(
        { ...mandatoryFieldsMap, ...significantFields },
        tableExampleData.columns,
    );
    const availableColumnsByName = keyBy(availableColumns, "name");

    // optional fields, which can be paired to columns
    const validOptionalFields = optionalFields
        // this removes optional field, which is defined, but used in significant/mandatory, shouldn't be kept and validated instead?
        // not sure if it is requirement or accidental result, original design worked the same way
        .filter(f => fieldExistsInFile(f, availableColumnsByName))
        .filter(f => resolvedTypeCombinationIsValid(f, availableColumnsByName));

    const validOptionalFieldsByColName = keyBy(validOptionalFields, "value");
    // columns, which are not used for any mapping
    const notPairedAvailableColumns = availableColumns.filter(
        col => !validOptionalFieldsByColName[col.name],
    );

    const resultWithOriginalSorting = []
        .concat(
            validOptionalFields.map(field =>
                prefillOptionalField(
                    field,
                    availableColumnsByName[field.value],
                ),
            ),
        )
        .concat(notPairedAvailableColumns.map(fieldFromCol));

    return resultWithOriginalSorting;
};

const fieldExistsInFile = (field, fieldsFromFileByName) => {
    return fieldsFromFileByName[field.value];
};

const resolvedTypeCombinationIsValid = (field, fieldsFromFileByName) => {
    const fieldFromFile = fieldsFromFileByName[field.value];
    return hasConvertibleType(
        getOrDefaultString(fieldFromFile?.type),
        getOrDefaultString(field.type),
    );
};
