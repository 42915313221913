import { defaultQsOptions } from "@/services/constants";
import qs from "qs";
import { axiosService } from "../axios";
import { getData } from "./utils";

export const instancesServices = {
    fetchInstance(instanceId) {
        return axiosService.get(`/api/instances/${instanceId}`);
    },

    fetchInstances(accountId) {
        return axiosService.get(`/api/projects/${accountId}/instances`);
    },

    fetchInstanceHealth(instanceId) {
        return axiosService.get(`/api/instances/${instanceId}/health`);
    },

    convertToCustomImage(instanceId) {
        return axiosService.post(`/api/instances/${instanceId}/custom-image`);
    },

    fetchInstanceHealthHistory(instanceId, days) {
        return axiosService.get(
            `api/instances/${instanceId}/health/history?withHistorySinceInDays=${days}`,
        );
    },

    fetchInstanceWarnings(instanceId) {
        return axiosService.get(`/api/instances/${instanceId}/warnings`);
    },

    createInstance(accountId, values, forceCreateOnly) {
        const queryParam = forceCreateOnly ? "?forceCreateOnly=true" : "";

        return axiosService
            .post(
                `/api/instances${queryParam}`,
                JSON.stringify({
                    projectId: accountId,
                    ...values,
                }),
            )
            .then(getData);
    },

    updateInstance(instanceId, values) {
        return axiosService
            .post(
                `/api/instances/${instanceId}`,
                JSON.stringify({
                    ...values,
                }),
            )
            .then(getData);
    },

    deleteInstance(instanceId, queryParams) {
        const queryStr = qs.stringify(queryParams, defaultQsOptions);

        return axiosService.delete(`/api/instances/${instanceId}${queryStr}`);
    },

    setPassword({ instanceId, body }) {
        return axiosService.post(`api/instances/${instanceId}/password`, body);
    },

    startInstance(instance) {
        return axiosService
            .post(
                `/api/instances/${instance.id}/start`,
                JSON.stringify({
                    instanceName: instance.instanceName,
                    server: instance.server,
                }),
            )
            .then(getData);
    },

    stopInstance(instance) {
        return axiosService
            .post(
                `/api/instances/${instance.id}/stop`,
                JSON.stringify({
                    instanceName: instance.instanceName,
                    server: instance.server,
                }),
            )
            .then(getData);
    },

    restartInstance(instance) {
        return axiosService
            .post(
                `/api/instances/${instance.id}/restart`,
                JSON.stringify({
                    instanceName: instance.instanceName,
                    server: instance.server,
                }),
            )
            .then(getData);
    },

    fetchAvailableTags() {
        return axiosService.get(`/api/instances/tags`).then(getData);
    },

    fetchSupportedTags(instanceGroupName) {
        return axiosService
            .get(`/api/instances/${instanceGroupName}/supported-tags`)
            .then(getData);
    },

    fetchTags(instanceId) {
        return axiosService
            .get(`/api/instances/${instanceId}/tags`)
            .then(getData);
    },

    upgradeInstance({ instanceId, instanceName, server, gitRef }) {
        return axiosService.post(
            `/api/instances/${instanceId}/upgrade`,
            JSON.stringify({
                instanceName,
                server,
                gitRef,
            }),
        );
    },

    getListOfEnvironmentsToTransport(instanceId) {
        return axiosService.get(
            `/api/instances/${instanceId}/transport-allowed-to`,
        );
    },

    getListOfMergeRequests(instanceId, mergeRequestId) {
        return axiosService.get(
            `/api/instances/${instanceId}/merge-requests/${mergeRequestId}`,
        );
    },

    processMergeRequest({ instanceId, mergeRequestId, mergeRequestAction }) {
        return axiosService.post(
            `/api/instances/${instanceId}/merge-requests/${mergeRequestId}/${mergeRequestAction}`,
        );
    },

    validateName: (
        instanceId,
        stepId,
        templateUniqueName,
        integrationUniqueName,
    ) =>
        axiosService
            .post(
                `/api/instances/${instanceId}/templates/${stepId}/validate`,
                JSON.stringify({
                    templateUniqueName: templateUniqueName,
                    integrationUniqueName: integrationUniqueName,
                }),
            )
            .then(getData),
    getFilterConfigurations(instanceId) {
        return axiosService.get(
            `/api/instances/${instanceId}/filter-configurations`,
        );
    },

    fetchMergeRequests(instanceId) {
        return axiosService
            .get(`/api/instances/${instanceId}/merge-requests`)
            .then(({ data }) => data ?? []);
    },

    encryptData(instanceId, secret) {
        return axiosService.post(
            `/api/instances/${instanceId}/encrypt-data`,
            secret,
        );
    },

    fetchFailedEvents(instanceId) {
        return axiosService
            .get(`/api/instances/${instanceId}/failed-events`)
            .then(({ data }) => data ?? []);
    },

    retryEventTypes(instanceId, eventTypes) {
        return axiosService.post(`/api/instances/${instanceId}/failed-events`, {
            eventTypes,
        });
    },

    fetchDataSample(instanceId, params) {
        return axiosService.post(
            `api/instances/${instanceId}/elastic-search`,
            params,
        );
    },

    fetchEntity(instanceId, prn) {
        return axiosService.post(
            `/api/instances/${instanceId}/entities/entity-data`,
            { prn },
        );
    },

    fetchInstanceGroupEnvSizing(instanceGroup) {
        return axiosService.get(`/api/instances/${instanceGroup}/envs-sizing`);
    },

    fetchCreateNewPossible(cloudProvider, region) {
        return axiosService
            .get(
                `/api/instances/${cloudProvider}/${region}/create-new-possible`,
            )
            .then(getData);
    },

    fetchDocumentation(instanceId) {
        return axiosService
            .get(`/api/instances/${instanceId}/documentation`)
            .then(({ data }) => data ?? []);
    },

    getDeployInfo(instanceId, changedItems) {
        return axiosService.post(
            `/api/instances/${instanceId}/deploy-info`,
            changedItems,
        );
    },

    fetchInstanceProperties(instanceId) {
        return axiosService.get(
            `/api/instances/${instanceId}/instance-properties`,
        );
    },
};
