import { Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { debounceAsync } from "@/utils/promises/promise.utils";
import { getErrorMessage } from "@/utils/state/error.utils";
import { useCallback, useMemo } from "react";

export const usePartitionDataUploadExistsValidator = ({
    partitionId,
    skipValidationFor,
}) => {
    const { dataUploadService } = useDic();

    return useCallback(
        value =>
            skipValidationFor && skipValidationFor === value
                ? Forms.success()
                : dataUploadService
                      .isDataUploadExists(partitionId, value)
                      .then(exists => {
                          return exists
                              ? Forms.error("Already exists")
                              : Forms.success();
                      })
                      .catch(e => Forms.error(getErrorMessage(e))),
        [dataUploadService, partitionId, skipValidationFor],
    );
};

export const usePartitionDataUploadNameValidator = ({
    dataUploadName,
    partitionId,
}) => {
    const mapperExistsValidator = usePartitionDataUploadExistsValidator({
        partitionId,
        skipValidationFor: dataUploadName,
    });

    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                Forms.pmValidators.createMinLengthValidation(3),
                debounceAsync(500, mapperExistsValidator),
            ]),
        [mapperExistsValidator],
    );
};
