import { ButtonMenu, Modal, UnityLayout } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_HEIGHT_WITH_BREADCRUMB } from "@/components/DesignSystem/Table/constants";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import {
    useDeleteRouteMutation,
    useDeployRouteMutation,
    useInstanceQuery,
    useRoutesWithDetailsQuery,
} from "@/components/IntegrationRoutes/loadables";
import { useWorkspaceInstanceDeletionAlert } from "@/components/Integrations/InstanceEntityWorkspaceAlert/InstanceEntityWorkspaceAlert";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { isLoading } from "@/modules/loadable";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { getIntegrationRoutesTrackName as getTrackName } from "../../mixpanel/buttonNames";
import { RouteDefinitionDiff } from "../RouteDefinitionDiff/RouteDefinitionDiff";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { CopyRouteDialogContainer } from "./copy/CopyRouteDialogContainer";
import { routesColumns } from "./IntegrationRoutes.columns";

const preferencesType = preferencesTypes.PROJECT_INTEGRATIONS_LIST_TABLE;

export const IntegrationsRoutes = ({
    instanceId,
    accountId,
    visible,
    onEdit,
    reloadToken,
    afterDeploy,
}) => {
    const instanceQuery = useInstanceQuery({ instanceId });
    useWorkspaceInstanceDeletionAlert({
        instanceQuery,
        visible,
    });

    const diffModal = useDetailDrawerState();

    const isProvisioned =
        instanceQuery.loadable.valueMaybe()?.serverType === "PROVISIONED";
    const routesWithDetailsQuery = useRoutesWithDetailsQuery({
        instanceId,
        reloadToken,
    });
    const deleteRouteMutation = useDeleteRouteMutation({
        instanceId,
        afterSuccess: routesWithDetailsQuery.reload,
    });
    const deployRouteMutation = useDeployRouteMutation({
        instanceId,
        afterSuccess: afterDeploy,
    });

    const dataSource = routesWithDetailsQuery.loadable.valueMaybe() || [];
    const loading =
        isLoading(routesWithDetailsQuery) || isLoading(deployRouteMutation);

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.tooltip.edit"),
                        onClick: onEdit,
                        // permission: INTEGRATION_EDIT_PERMISSIONS,
                        visible: isProvisioned && record.id,
                        track: { name: getTrackName("Edit") },
                    },
                    {
                        title: t("general.deploy"),
                        onClick: deployRouteMutation.mutate,
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                        visible: isProvisioned && record.id,
                        track: { name: getTrackName("Deploy") },
                    },
                    {
                        title: t("routes-list.copy.title"),
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                        visible: isProvisioned && record.id,
                        customModal: (
                            <CopyRouteDialogContainer
                                accountId={accountId}
                                record={record}
                                afterSuccess={routesWithDetailsQuery.reload}
                            />
                        ),
                    },
                    {
                        title: t("general.modal.delete.title", {
                            type: "route",
                        }),
                        confirm: {
                            message: `${t("general.modal.delete.message", {
                                name: record.uniqueName,
                                type: "route",
                            })}`,
                            onConfirm: deleteRouteMutation.mutate,
                        },
                        color: "red",
                        permission: INTEGRATION_EDIT_PERMISSIONS,
                        visible: isProvisioned,
                        track: { name: getTrackName("Delete") },
                    },
                ]}
            />
        ),
        [
            onEdit,
            isProvisioned,
            deployRouteMutation.mutate,
            accountId,
            routesWithDetailsQuery.reload,
            deleteRouteMutation.mutate,
        ],
    );

    return (
        <>
            <Modal visible={!!diffModal.record} width="95%">
                <UnityLayout>
                    <UnityLayout.Header
                        size={3}
                        title={t("routes-list.status.diff")}
                    />
                    <UnityLayout.Content padding={[true, true]}>
                        <RouteDefinitionDiff
                            instanceId={instanceId}
                            routeId={diffModal.record}
                        />
                    </UnityLayout.Content>
                    <UnityLayout.Footer
                        actionButtons={
                            <ButtonMenu
                                buttons={[
                                    {
                                        label: t("general.ok"),
                                        onClick: diffModal.hide,
                                        type: "primary",
                                    },
                                ]}
                            />
                        }
                    />
                </UnityLayout>
            </Modal>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                columns={routesColumns({
                    routes: dataSource,
                    onShowDiff: diffModal.show,
                })}
                loading={loading}
                dataSource={dataSource}
                fixed
                restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
                rowKey="uniqueName"
                datasetSlicing="local"
                preferencesType={preferencesType}
                visible={visible}
                defaultSort={{
                    sortSpecifiers: [
                        { property: "updatedAt", direction: "descending" },
                    ],
                }}
            />
        </>
    );
};

IntegrationsRoutes.propTypes = {
    accountId: PropTypes.number.isRequired,
    instanceId: PropTypes.number.isRequired,
    onEdit: PropTypes.func.isRequired,
    reloadToken: PropTypes.any,
    afterDeploy: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};
