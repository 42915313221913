import { axiosService } from "../axios";
import { buildPageableUrl } from "../utils/urlUtils";

export const constantsService = {
    fetchConstantsInfo() {
        return axiosService.get(`/api/constants`);
    },
    fetchEntities(page, size, sort, filter, type) {
        return axiosService.post(
            buildPageableUrl(`/api/constants/${type}/table`, page, size, sort),
            filter,
        );
    },
    upsertEntity(type, entity) {
        return axiosService.post(`/api/constants/${type}`, entity);
    },
    deleteEntity(type, entityId) {
        return axiosService.delete(`/api/constants/${type}/${entityId}`);
    },
};
