import {
    Alert,
    Button,
    ButtonMenu,
    Collapse,
    Forms,
    Gap,
    Panel,
    Text,
} from "@/components/DesignSystem";
import { RowSkeleton } from "@/components/DesignSystem/Skeleton/RowSkeleton.component";
import { H4 } from "@/components/DesignSystem/heading";
import { useDic } from "@/components/Dic/useDic.hook";
import DocumentationLink from "@/components/DocumentationLink";
import { deployDestinationByTemplateType } from "@/components/Marketplace/MarketplaceDetail.page";
import BeforeDeploymentModals from "@/components/Marketplace/components/DeployTemplateCard/BeforeDeploymentModals.component";
import {
    useIntegrationsOptionsForDeploy,
    usePartitionsOptionsForDeploy,
    useTemplateVersionsOptions,
} from "@/components/Marketplace/components/DeployTemplateCard/loadables";
import { DeprecatedWarning } from "@/components/Marketplace/components/Deprecated/DeprecatedWarning.component";
import { isDeprecatedTemplate } from "@/components/Marketplace/components/Deprecated/helpers";
import { FavoriteType } from "@/components/Marketplace/components/FavoriteButton/favorite.types";
import { useFavoriteAction } from "@/components/Marketplace/components/FavoriteButton/useFavoriteAction.hook";
import { useContextTemplateCategoriesQuery } from "@/components/Marketplace/components/TagsMenu/TemplateCategories.context";
import { useCheckDeployability } from "@/components/Marketplace/loadables";
import { useDeleteTemplateActionButton } from "@/components/Marketplace/useDeleteTemplateActionButton.hook";
import { useDeployabilityValidator } from "@/components/Marketplace/validators";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { deployToEnum } from "@/constants/deployment.constants";
import { hasError, isLoading } from "@/modules/loadable";
import { T, t } from "@/translations";
import find from "lodash/fp/find";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { VersionIntegrityCheckModal } from "../../../../apps/marketplaceApp/components/VersionIntegrityCheck/VersionIntegrityCheck.modal";
import { TemplateDeploymentEntity } from "../../entities/templates/TemplateDeploymentEntity.class";
import { useNavigateToPackages } from "../../hooks/useNavigateToPackages.hook";
import "./TemplateDetail.styles.less";

const { useForm, Form, Fields, pmValidators } = Forms;

export const TemplateDetail = ({ accountId, templateResource }) => {
    const {
        locationRouterService,
        marketplaceAppLocations: { marketplaceLocation },
    } = useDic();
    const templateCategoriesResource = useContextTemplateCategoriesQuery();
    const template = templateResource.loadable.valueMaybe();
    const destination = deployDestinationByTemplateType(template.type);
    const beforeDeploymentModal = useVisibility(false);

    const packageName = template.uniqueName;
    const isDestinationInstance = destination === deployToEnum.INSTANCE;
    const instancesOptionsResource = useIntegrationsOptionsForDeploy({
        accountId,
        canFetch: accountId && isDestinationInstance,
    });
    const partitionsOptionsResource = usePartitionsOptionsForDeploy({
        accountId,
        canFetch: accountId && !isDestinationInstance,
    });

    const { navigateToPackages } = useNavigateToPackages();

    const onDeployment = React.useCallback(
        ({ accountId, destinationId, template }) => {
            navigateToPackages({
                destination,
                destinationId,
                accountId,
                template,
            });
        },
        [destination, navigateToPackages],
    );

    const onCancel = React.useCallback(
        ({ accountId }) => {
            locationRouterService.navigate(marketplaceLocation, {
                accountId,
            });
        },
        [marketplaceLocation, locationRouterService],
    );

    const { formId, handleSubmit, setValues, setTouched } = useForm({
        onSubmit: beforeDeploymentModal.show,
    });

    const selectedVersion = Forms.useFieldValue({ formId, name: "version" });
    const destinationId = Forms.useFieldValue({
        formId,
        name: "destinationId",
    });

    const templateDeployment = TemplateDeploymentEntity.create({
        accountId,
        destination,
        destinationId,
        template,
        templateVersion: selectedVersion,
    });

    const templateVersionsResource = useTemplateVersionsOptions({
        packageName,
        accountId,
        canFetch: packageName && !isNaN(accountId),
    });

    const deploymentStateQuery = useCheckDeployability({
        idle: !(packageName && destinationId && selectedVersion),
        destination,
        destinationId,
        packageName,
        templateVersion: selectedVersion,
    });

    const versionIntegrityCheckDisplayed = !isDestinationInstance;
    const versionIntegrityCheckDisabled =
        !destinationId ||
        hasError(deploymentStateQuery) ||
        isLoading(deploymentStateQuery);
    const actionsDisabled =
        hasError(deploymentStateQuery) || isLoading(deploymentStateQuery);

    const deployedVersion = destinationId
        ? deploymentStateQuery.loadable.valueMaybe()
              ?.lastDeployedTemplateVersion ?? "Unknown"
        : null;

    const versionIntegrityCheckModal = useVisibility();

    const { FavoriteIcon, toggleFavorite, title, isActive } = useFavoriteAction(
        {
            favoriteId: template.uniqueName,
            favoriteType: FavoriteType.TEMPLATE,
            isActive: template.userFavorite,
        },
    );

    useBreadcrumbButton(
        {
            icon: FavoriteIcon,
            label: title,
            onClick: () => {
                toggleFavorite();
                templateCategoriesResource.reload();
            },
            size: "small",
            type: "default",
        },
        [isActive, toggleFavorite],
    );

    const deleteButton = useDeleteTemplateActionButton({
        templateDeployment,
        disabled: actionsDisabled,
    });

    const destinationName = useMemo(
        () =>
            find(
                ["value", destinationId],
                [
                    ...(instancesOptionsResource.loadable.valueMaybe() ?? []),
                    ...(partitionsOptionsResource.loadable.valueMaybe() ?? []),
                ],
            )?.label,
        [
            instancesOptionsResource.loadable,
            partitionsOptionsResource.loadable,
            destinationId,
        ],
    );

    useSetValidatedInitialValues(
        {
            initialValues: {
                version:
                    templateVersionsResource.loadable.valueMaybe()?.[0]?.value,
            },
            setValues,
            setTouched,
        },
        [templateVersionsResource.loadable.valueMaybe()?.[0]?.value],
    );

    const deployabilityValidator = useDeployabilityValidator({
        deploymentStateLoadable: deploymentStateQuery.loadable,
        packageName,
        destination,
        destinationId,
    });

    return (
        <div>
            <div className="pmTemplateDetail-description">
                {template?.detailedDescription ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: template?.detailedDescription,
                        }}
                    />
                ) : null}
                {template?.documentationLink && (
                    <>
                        <Gap />
                        <DocumentationLink link={template.documentationLink} />
                    </>
                )}
            </div>

            <Gap />

            {template.canDeploy ? (
                <>
                    <H4>
                        <T id="marketplace.template.detail.deploy" />
                    </H4>
                    <Form formId={formId} onSubmit={handleSubmit}>
                        {isDestinationInstance ? (
                            <Fields.Select
                                label={t(
                                    "marketplace.template.detail.targetInstance",
                                )}
                                name="destinationId"
                                required
                                validator={pmValidators.isRequired}
                                {...getLoadableSelectProps(
                                    instancesOptionsResource.loadable,
                                )}
                            />
                        ) : (
                            <Fields.Select
                                label={t(
                                    "marketplace.template.detail.targetPartition",
                                )}
                                name="destinationId"
                                required
                                validator={pmValidators.isRequired}
                                {...getLoadableSelectProps(
                                    partitionsOptionsResource.loadable,
                                )}
                            />
                        )}
                        {deployedVersion && (
                            <Text size="small">
                                {t(
                                    "marketplace.template.detail.deployedVersion",
                                    {
                                        version: deployedVersion,
                                    },
                                )}
                            </Text>
                        )}

                        {isLoading(templateVersionsResource) ? (
                            <RowSkeleton />
                        ) : (
                            <Fields.Radio
                                label={t(
                                    "marketplace.template.detail.versions.solutions",
                                )}
                                name="version"
                                required
                                validator={deployabilityValidator}
                                options={
                                    templateVersionsResource.loadable.valueMaybe() ??
                                    []
                                }
                            />
                        )}
                    </Form>
                </>
            ) : (
                <>
                    <Alert
                        showIcon
                        type="info"
                        message={t(
                            "marketplace.template.detail.deployment-forbidden",
                        )}
                    />
                    <Gap />
                </>
            )}

            {versionIntegrityCheckDisplayed && (
                <>
                    <Collapse
                        className="cluster-upgrades-overview"
                        bordered={false}
                    >
                        <Panel
                            header={t(
                                "marketplace.template.detail.version-integrity-check",
                            )}
                            key="1"
                        >
                            <Text size="small">
                                {t(
                                    "marketplace.template.detail.version-integrity-check.perex",
                                )}
                            </Text>
                            <Gap />
                            <Button
                                size="small"
                                label={t(
                                    "marketplace.template.detail.version-integrity-check.button",
                                )}
                                type="secondary"
                                disabled={versionIntegrityCheckDisabled}
                                onClick={versionIntegrityCheckModal.show}
                            />
                        </Panel>
                    </Collapse>
                    <Gap />
                </>
            )}

            {isDeprecatedTemplate(template.uniqueName) ? (
                <>
                    <DeprecatedWarning />
                    <Gap />
                </>
            ) : null}

            <ButtonMenu
                buttonSize="middle"
                align="left"
                buttons={[
                    {
                        formId,
                        label: t("general.deploy"),
                        type: "primary",
                        size: "medium",
                        visible: template.canDeploy,
                        disabled: actionsDisabled,
                    },

                    {
                        label: t("general.cancel"),
                        onClick: () => onCancel({ accountId }),
                        type: "text",
                        size: "medium",
                    },
                    deleteButton,
                ]}
            />
            <VersionIntegrityCheckModal
                visible={versionIntegrityCheckModal.visible}
                packageName={template.uniqueName}
                partitionId={destinationId}
                accountId={accountId}
                // templateVersion={selectedVersion}
                partitionName={destinationName}
                onClose={versionIntegrityCheckModal.hide}
            />
            <BeforeDeploymentModals
                templateResource={templateResource}
                templateDeployment={templateDeployment}
                visible={beforeDeploymentModal.visible}
                onCancel={beforeDeploymentModal.hide}
                onDeployment={onDeployment}
            />
        </div>
    );
};

export default TemplateDetail;

TemplateDetail.propTypes = {
    accountId: PropTypes.number.isRequired,
    templateResource: PropTypes.object.isRequired,
};
