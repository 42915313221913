import { useDic } from "@/components/Dic/useDic.hook";
import {
    pendingPromise,
    unwrapAxiosError,
    useQueryLoadable,
} from "@/modules/loadable";
import entries from "lodash/fp/entries";
import flatMap from "lodash/fp/flatMap";
import get from "lodash/fp/get";
import map from "lodash/fp/map";
import pipe from "lodash/fp/pipe";
import set from "lodash/fp/set";
import qs from "qs";

export const CHANGE = {
    DELETED: "DELETED",
    UPDATED: "UPDATED",
    UNCHANGED: "UNCHANGED",
};

const addChange = record => ({
    ...record,
    change: record.deleted
        ? CHANGE.DELETED
        : record.updated
        ? CHANGE.UPDATED
        : CHANGE.UNCHANGED,
});

const mapTemplateChanges = pipe(
    get("data"),
    entries,
    flatMap(([entityType, changes]) =>
        map(pipe(set("entityType", entityType), addChange), changes),
    ),
);

export const useTemplateChanges = ({
    canFetch,
    packageName,
    partitionId,
    templateVersion,
}) => {
    const { axiosService } = useDic();
    const query = qs.stringify({ partitionId, templateVersion });
    const uri = `/api/packages/${packageName}/changes?${query}`;

    return useQueryLoadable(
        async () =>
            canFetch
                ? axiosService
                      .get(uri)
                      .then(mapTemplateChanges)
                      .catch(e => {
                          throw unwrapAxiosError(e);
                      })
                : pendingPromise(),
        [axiosService, canFetch, uri],
    );
};
