import { Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import {
    useRemoteLoginInstancesQuery,
    useRemoteLoginPartitionNamesQuery,
    useRemoteLoginUsersQuery,
} from "@/components/RemoteLogin/loadables";
import { useMapLoadableMemoized } from "@/modules/loadable";
import { t } from "@/translations";
import map from "lodash/fp/map";
import React, { useState } from "react";

const { useForm, useFieldValue, Form, Fields, SubmitButton, pmValidators } =
    Forms;

const TYPE_PARTITION = "PARTITION";
const TYPE_SYSTEM_PARTITION = "SYSTEM_PARTITION";

const remoteLoginTypes = [
    {
        label: t("remote-login.type.partition"),
        value: TYPE_PARTITION,
    },
    {
        label: t("remote-login.type.system-partition"),
        value: TYPE_SYSTEM_PARTITION,
    },
];

const RemoteLoginForm = () => {
    const [remoteLoginType, setRemoteLoginType] = useState(TYPE_PARTITION);
    const { remoteLoginService } = useDic();

    const remoteLoginInstancesResource = useRemoteLoginInstancesQuery();
    const remoteLoginUsersResource = useRemoteLoginUsersQuery({
        hasPermission: true,
    });

    const instancesListLoadable = useMapLoadableMemoized(
        remoteLoginInstancesResource.loadable,
        map(instance => ({
            label: instance.name,
            value: instance.name,
        })),
    );

    const usersListLoadable = useMapLoadableMemoized(
        remoteLoginUsersResource.loadable,
        map(user => ({
            label: user,
            value: user,
        })),
    );

    const onSubmit = async ({ values }) => {
        const url = remoteLoginInstancesResource.loadable
            .valueMaybe()
            .find(i => i.name === values.instance).url;

        await remoteLoginService.doRemoteLogin(
            url,
            values.partition,
            values.user,
        );
    };

    const { formId, handleSubmit, setValues } = useForm({
        onSubmit,
    });

    const clusterName = useFieldValue({ formId, name: "instance" });

    const remoteLoginPartitionNamesResource = useRemoteLoginPartitionNamesQuery(
        { clusterName },
    );

    const partitionNamesLoadable = useMapLoadableMemoized(
        remoteLoginPartitionNamesResource.loadable,
        map(partitionName => ({
            label: partitionName,
            value: partitionName,
        })),
    );

    const onInstanceChange = () => {
        setValues({ user: null, partition: null });
    };

    function handleRemoteLoginTypeChange(value) {
        setRemoteLoginType(value.value);
    }

    return (
        <Form formId={formId} onSubmit={handleSubmit}>
            <Fields.Select
                required
                name="instance"
                label={t("remote-login.label.cluster")}
                placeholder={t("remote-login.placeholder.cluster")}
                onChange={onInstanceChange}
                validator={pmValidators.isRequired}
                {...getLoadableSelectProps(instancesListLoadable)}
            />

            <Fields.Select
                required
                name="user"
                label={t("remote-login.label.user")}
                placeholder={t("remote-login.placeholder.user")}
                validator={pmValidators.isRequired}
                {...getLoadableSelectProps(usersListLoadable)}
            />

            <Fields.RadioButton
                name="remoteLoginType"
                initialValue={TYPE_PARTITION}
                label={t("remote-login.type.select")}
                options={remoteLoginTypes}
                onChange={handleRemoteLoginTypeChange}
                inputWidth="max"
            />

            {remoteLoginType === TYPE_PARTITION && (
                <Fields.Select
                    required
                    name="partition"
                    label={t("remote-login.label.partition.select")}
                    placeholder={t("remote-login.placeholder.partition.select")}
                    validator={pmValidators.isRequired}
                    {...getLoadableSelectProps(partitionNamesLoadable)}
                />
            )}

            {remoteLoginType === TYPE_SYSTEM_PARTITION && (
                <Fields.Input
                    required
                    name="partition"
                    label={t("remote-login.label.partition.input")}
                    validator={pmValidators.isRequired}
                />
            )}

            <SubmitButton
                label={t("remote-login.button.login")}
                type="primary"
            />
        </Form>
    );
};

export default RemoteLoginForm;
