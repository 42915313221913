import { useRollbackParams } from "@/components/CustomerPartitions/loadables";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import {
    includesPermissionInAssetTree,
    MARKETPLACE_ACCELERATOR_PERMISSIONS,
} from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import Moment from "react-moment";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { useRollbackMutation } from "../Marketplace/components/DeployTemplateCard/loadables";
import { useFetchPage } from "../PagableTable/useFetchPage.hook";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { RollbackModal } from "./RollbackModal.component";

const preferencesType = preferencesTypes.PARTITION_DEPLOYED_PACKAGES_TABLE;

export const PartitionDeploymentLog = ({
    accountId,
    partitionId,
    restHeight,
}) => {
    const { packageService } = useDic();
    const rollbackModal = useVisibility();
    const [deploymentId, setDeploymentId] = useState();

    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort, filter) =>
            packageService.getDeployedPackagesForAccountPartition(
                page,
                size,
                sort,
                filter,
                accountId,
                partitionId,
            ),
        [partitionId],
    );

    const rollbackMutation = useRollbackMutation({
        accountId,
        partitionId,
        deploymentId,
        afterSuccess: () => {
            rollbackModal.hide();
            reload();
        },
    });

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    record.canDelete &&
                        (c =>
                            includesPermissionInAssetTree(
                                c,
                                MARKETPLACE_ACCELERATOR_PERMISSIONS,
                            )) && {
                            title: t(
                                "package-deployment.action.delete-deployment",
                            ),
                            onClick: record => {
                                setDeploymentId(record.id);
                                rollbackModal.show();
                            },
                            color: "red",
                        },
                ]}
            />
        ),
        [rollbackModal],
    );

    const rollbackOptionsResource = useRollbackParams({
        canFetch: deploymentId && accountId && partitionId,
        accountId,
        deploymentId,
        partitionId,
    });

    return (
        <>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                fixed
                restHeight={restHeight}
                rowKey="id"
                datasetSlicing="server"
                preferencesType={preferencesType}
                defaultSort={{ fieldName: "updatedAt", sortDir: "descending" }}
                columns={[
                    {
                        type: fieldTypes.TEXT,
                        label: t("account-deployed-templates.header.label"),
                        name: "name",
                        className: "pmTable__column--withActionButton",
                    },
                    {
                        type: fieldTypes.TEXT,
                        label: t(
                            "account-deployed-templates.header.template-name",
                        ),
                        name: "templateName",
                    },
                    {
                        type: fieldTypes.TEXT,
                        label: t(
                            "account-deployed-templates.header.template-version",
                        ),
                        name: "templateVersion",
                    },
                    {
                        type: fieldTypes.TEXT,
                        label: t(
                            "account-deployed-templates.header.deployed-by",
                        ),
                        name: "startedBy",
                    },
                    {
                        type: fieldTypes.TEXT,
                        label: t("account-deployed-templates.header.step"),
                        canFilter: false,
                        canSort: false,
                        name: "currentStepIndex",
                        render: (_, record) =>
                            record.currentStepIndex !== null
                                ? `${record.currentStepIndex}/${record.numberOfSteps}`
                                : "-",
                    },
                    {
                        type: fieldTypes.DATETIME,
                        label: t(
                            "account-deployed-templates.header.deployed-at",
                        ),
                        name: "updatedAt",
                        render: (_, record) =>
                            record.updatedAt && (
                                <Moment format="DD/MM/YYYY HH:mm:ss">
                                    {record.updatedAt}
                                </Moment>
                            ),
                    },
                    {
                        type: fieldTypes.OPTION,
                        label: t("account-deployed-templates.header.status"),
                        name: "status",
                        options: [
                            { value: "FAILED", label: "Failed" },
                            { value: "FINISHED", label: "Finished" },
                            { value: "IN_PROGRESS", label: "In progress" },
                            {
                                value: "ROLL_BACKED",
                                label: "Rollback Finished",
                            },
                            {
                                value: "ROLL_BACK_IN_PROGRESS",
                                label: "Rollback in Progress",
                            },
                        ],
                    },
                ]}
                {...tableProps}
            />
            <RollbackModal
                perex={t("rollback.modal.perex")}
                rollbackMutation={rollbackMutation}
                rollbackOptionsLoadable={rollbackOptionsResource.loadable}
                visible={rollbackModal.visible}
                onClose={rollbackModal.hide}
            />
        </>
    );
};

PartitionDeploymentLog.propTypes = {
    accountId: PropTypes.number.isRequired,
    partitionId: PropTypes.number.isRequired,
    restHeight: PropTypes.number,
};
