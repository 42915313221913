import { useMemoByDeepEquality } from "@/components/hooks/useMemoByDeepEquality.hook";
import { flatMap, get, pipe } from "lodash/fp";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import uuid from "uuid/v4";

export const FooterButtonsContext = React.createContext();

// TODO: naming of context "public hooks" vs direct usage of context value in component

export const useFooterButtons = buttons => {
    const [elementId] = useState(uuid());
    const { setButtons } = useContext(FooterButtonsContext);
    const buttonsMemo = useMemoByDeepEquality(buttons);

    useEffect(() => {
        setButtons({ elementId, buttons: buttonsMemo });
        return () => setButtons({ elementId, buttons: null });
    }, [buttonsMemo, elementId, setButtons]);
};

const updateQueue =
    ({ ...newQueueItem }) =>
    queue => {
        const index = queue.findIndex(
            q => q.elementId === newQueueItem.elementId,
        );

        if (!newQueueItem.buttons?.length)
            return queue.filter(
                queueItem => queueItem.elementId !== newQueueItem.elementId,
            );

        if (index > -1) {
            const newQueue = [...queue];
            newQueue.splice(index, 1, newQueueItem);
            return newQueue;
        }

        return [newQueueItem, ...queue];
    };

export const FooterButtonsProvider = ({ children }) => {
    const [buttonsQueue, setButtonsQueue] = useState([]);
    const buttons = useMemo(
        () => flatMap(get("buttons"), buttonsQueue),
        [buttonsQueue],
    );
    const setButtons = useCallback(pipe(updateQueue, setButtonsQueue), []);
    const contextValue = useMemo(
        () => ({ buttons, setButtons }),
        [buttons, setButtons],
    );

    return (
        <FooterButtonsContext.Provider value={contextValue}>
            {children}
        </FooterButtonsContext.Provider>
    );
};
