import { Menu } from "@/components/DesignSystem/Menu";
import uniq from "lodash/uniq";
import React, { useEffect, useState } from "react";

export const LocationMenu = ({
    routeName,
    routesPath,
    children,
    menuComponent,
    ...props
}) => {
    const Com = menuComponent || Menu;

    const [openKeys, setOpenKeys] = useState(routesPath);

    useEffect(() => {
        // we want to preserve keys, that user has opened
        // but also open new keys according to route change
        setOpenKeys(uniq([...openKeys, ...routesPath]));
    }, [routesPath]);

    return (
        <Com
            selectedKeys={routeName}
            openKeys={openKeys}
            onOpenChange={setOpenKeys}
            {...props}
        >
            {children}
        </Com>
    );
};
