import { ENTITY_FIELDS } from "@/components/DataUploads/Wizard/DataUploadWizard";
import { CREATE_TABLE_SUPPORTED_TYPES } from "@/components/DataUploads/Wizard/NewTable/NewTableModal";
import { Button, ButtonGroup, Forms, H3 } from "@/components/DesignSystem";
import Paragraph from "@/components/DesignSystem/Paragraph/Paragraph";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import {
    EntitySelectFields,
    SOURCE_TYPE,
} from "@/components/Mappers/ExportMapper/EntitySelectFields";
import { usePartitionDataUploadNameValidator } from "@/components/Packages/PackageTableDefinitionPanel/components/NewDataUploadFromDeployment/validators";
import { useAlertEffect } from "@/components/PageLayout/useAlertEffect.hook";
import { useQueryLoadable } from "@/modules/loadable";
import { hasError, maybeError, throwMapped } from "@/modules/loadable/utils";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { get, pick, pipe } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";

const usePartitionAvailableAlert = ({ projectId, partitionId }) => {
    const { projectPartitionApiService } = useDic();
    const availableQuery = useQueryLoadable(
        () =>
            projectPartitionApiService
                .testPartitionAvailability(projectId, partitionId)
                .catch(
                    throwMapped(pipe(get("response.data"), getErrorMessage)),
                ),
        [partitionId, projectId, projectPartitionApiService],
    );

    // TODO: check this is not duplicated in DataUploadsList
    useAlertEffect(hasError(availableQuery), () => ({
        type: "error",
        message: t("partition.connection.unavailable"),
        description: maybeError(availableQuery),
    }));
};

export const DataUploadEntityStep = ({
    projectId,
    partitionId,
    onNext,
    onCancel,
    allValues,
}) => {
    usePartitionAvailableAlert({ projectId, partitionId });
    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit: pipe(get("values"), onNext),
    });
    useSetValidatedInitialValues(
        {
            initialValues: pick(ENTITY_FIELDS, allValues),
            setValues,
            setTouched,
        },
        [allValues],
    );
    const nameValidator = usePartitionDataUploadNameValidator({
        partitionId,
    });

    return (
        <>
            <H3>{t("partition-send-data.title.entity-type")}</H3>
            <Paragraph size={2}>
                {t("partition-send-data.title.description")}
            </Paragraph>
            <Forms.Form formId={formId} onSubmit={handleSubmit}>
                <Forms.Fields.Input
                    required
                    name="name"
                    label={t("partition-send-data.label.name")}
                    placeholder={t("partition-send-data.placeholder.name")}
                    validator={nameValidator}
                    allowClear={false}
                />
                <EntitySelectFields
                    allowCreatePartitionTableFor={CREATE_TABLE_SUPPORTED_TYPES}
                    sourceType={SOURCE_TYPE.PARTITIONS}
                    sourceId={partitionId}
                    projectId={projectId}
                    formId={formId}
                    setTouched={setTouched}
                    setValues={setValues}
                />
                <ButtonGroup spacing="none">
                    <Button
                        formId={formId}
                        htmlType="submit"
                        type="primary"
                        label={t("partition-send-data.button.continue")}
                        data-test="submit"
                    />
                    <Button
                        type="text"
                        size="large"
                        onClick={onCancel}
                        label={t("partition-send-data.button.cancel")}
                        data-test="cancel"
                    />
                </ButtonGroup>
            </Forms.Form>
        </>
    );
};

DataUploadEntityStep.propTypes = {
    projectId: PropTypes.number.isRequired,
    partitionId: PropTypes.number.isRequired,
    onNext: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    allValues: PropTypes.object.isRequired,
};
