import { AlertRulesForm } from "@/components/AlertRules/AlertRulesForm";
import { alertRuleScope } from "@/components/AlertRules/services/alertRules.service";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React, { useCallback } from "react";

export function NewAlertRuleAdminPage() {
    const { alertRuleId } = useAccountAppParams();
    const { locationRouterService, administrationAppLocations } = useDic();
    const handleBack = useCallback(
        () =>
            locationRouterService.navigate(
                administrationAppLocations.globalAlertsRulesLocation,
            ),
        [
            administrationAppLocations.globalAlertsRulesLocation,
            locationRouterService,
        ],
    );
    useSetBackButtonAction(handleBack);

    return (
        <AlertRulesForm
            alertRuleId={alertRuleId}
            scope={alertRuleScope.GLOBAL}
        />
    );
}
