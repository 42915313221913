import { Button, Input, TextAreaWithCopy } from "@/components/DesignSystem";
import { downloadFile } from "@/utils/downloadUtils";
import { ReactComponent as IconDownload } from "@pricefx/unity-components/dist/es/icons/unicons/download-alt.svg";
import PropTypes from "prop-types";
import React from "react";

export const Log = ({
    value,
    downloadFileName,
    downloadLabel = downloadFileName,
    withCopy = true,
    rows = 9,
}) => {
    const TextAreaComponent = withCopy ? TextAreaWithCopy : Input.TextArea;

    return (
        <>
            <TextAreaComponent rows={rows} readOnly={true} value={value} />
            {value && downloadFileName && (
                <Button
                    label={downloadLabel}
                    type="link"
                    icon={IconDownload}
                    onClick={() => downloadFile(value, downloadFileName)}
                />
            )}
        </>
    );
};

Log.propTypes = {
    value: PropTypes.string,
    downloadFileName: PropTypes.string,
    downloadLabel: PropTypes.string,
    withCopy: PropTypes.string,
    rows: PropTypes.string,
};
