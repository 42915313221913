import { Button, Gap } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { PlusOutlined } from "@ant-design/icons";
import reject from "lodash/fp/reject";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { AddPropertyModal } from "./AddPropertyModal";

export const PropertyTable = ({
    value: properties,
    onChange,
    addButtonLabel,
    addModalTitle,
    addModalDescription,
    addButtonProps,
    rowHeight,
}) => {
    const addPropertyModal = useVisibility(false);

    const onAdd = useCallback(
        values => {
            const newValue = [...(properties ?? []), values];
            onChange(newValue);
        },
        [onChange, properties],
    );
    const onRemove = useCallback(
        record => {
            const newValue = reject({ name: record.name }, properties);
            onChange(newValue);
        },
        [onChange, properties],
    );

    const columns = [
        {
            type: fieldTypes.TEXT,
            name: "name",
            label: "Name",
            render: (text, record) => (
                <>
                    {text}
                    <ActionButton
                        record={record}
                        items={[
                            {
                                title: "Remove",
                                onClick: onRemove,
                                color: "red",
                            },
                        ]}
                    />
                </>
            ),
        },
        {
            type: fieldTypes.TEXT,
            name: "value",
            label: "Value",
        },
    ];

    return (
        <div style={{ width: "100%" }}>
            <TableLocalFiltered
                columns={columns}
                dataSource={properties}
                pagination={false}
                rowKey="name"
                rowHeight={rowHeight}
            />
            <Gap size="small" />
            <Button
                icon={PlusOutlined}
                label={addButtonLabel}
                onClick={addPropertyModal.show}
                {...addButtonProps}
            />
            <AddPropertyModal
                visible={addPropertyModal.visible}
                onClose={addPropertyModal.hide}
                onAdd={onAdd}
                addModalTitle={addModalTitle ?? addButtonLabel}
                addModalDescription={addModalDescription}
                properties={properties}
            />
        </div>
    );
};

PropertyTable.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    entities: PropTypes.array.isRequired,
    idKey: PropTypes.string.isRequired,
    title: PropTypes.node,
    addButtonLabel: PropTypes.node.isRequired,
    addModalTitle: PropTypes.node.isRequired,
    addModalDescription: PropTypes.node,
    addButtonProps: PropTypes.object,
    rowHeight: PropTypes.number,
};
