import { compare, compareVersions } from "compare-versions";
import { identity, map, pipe, reverse } from "lodash/fp";

export const compareVersion = (currentVersion, requiredVersion, operator) => {
    try {
        return compare(
            !currentVersion ? "0" : currentVersion,
            requiredVersion,
            operator,
        );
    } catch (_) {
        return false;
    }
};

export const isSameOrNewer = (currentVersion, requiredVersion) => {
    const currentVersionWithoutSnapshot = removeSnapshot(currentVersion);
    const requiredVersionWithoutSnapshot = removeSnapshot(requiredVersion);

    if (
        compareVersion(
            currentVersionWithoutSnapshot,
            requiredVersionWithoutSnapshot,
            "=",
        ) &&
        isSnapshot(currentVersion) &&
        !isSnapshot(requiredVersion)
    ) {
        return false;
    } else {
        return compareVersion(
            currentVersionWithoutSnapshot,
            requiredVersionWithoutSnapshot,
            ">=",
        );
    }
};

export const isNewer = (currentVersion, requiredVersion) => {
    return compareVersion(currentVersion, requiredVersion, ">");
};

export const isOlder = (currentVersion, requiredVersion) => {
    return compareVersion(currentVersion, requiredVersion, "<");
};

export const toVersionNumber = (sort, curredSortField, newSortField) =>
    sort?.replace(curredSortField, newSortField);

export const createSortVersions = ({
    order = "desc",
    getter = identity,
} = {}) =>
    pipe(
        map(identity),
        arr => arr.sort((a, b) => compareVersions(getter(a), getter(b))),
        order === "desc" ? reverse : identity,
    );

export const isMajorChange = (v1, v2) => {
    const [m1] = v1?.match?.(/\d+/) ?? [];
    const [m2] = v2?.match?.(/\d+/) ?? [];

    if (!m1 || !m2) return undefined;
    if (m1 === m2) return false;
    return true;
};

export const isSnapshot = value => {
    return value.endsWith("-SNAPSHOT");
};

export const removeSnapshot = value => {
    if (value) {
        return value.replace("-SNAPSHOT", "");
    } else {
        return value;
    }
};
