import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { Button, Forms, Gap, Text } from "@/components/DesignSystem";
import { TimeWithUnits } from "@/components/Integrations/components/TimeWithUnits.component";
import {
    useInstanceBackupConfigMutation,
    useInstanceBackupConfigQuery,
} from "@/components/Integrations/loadables";
import { DeleteRowButton } from "@/components/Mappers/MapperTableWithCustomization/components/DeleteRowButton";
import { isLoading, LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import { identity } from "lodash";
import PropTypes from "prop-types";
import React from "react";

const LAYOUT = [
    { flex: "0 0 25%" },
    { flex: "0 0 16%" },
    {},
    {},
    { width: 100 },
    { width: 100 },
    { width: 32 },
];

const oneAsteriskValidator = value =>
    value?.indexOf("*") === value?.lastIndexOf("*")
        ? Forms.success()
        : Forms.error("Only one asterisk is allowed");
const folderNameValidator = Forms.validators.failOnFirst([
    Forms.pmValidators.isRequired,
    Forms.pmValidators.notBlank(),
    oneAsteriskValidator,
]);
const limitSecondsValidator = Forms.validators.failOnFirst([
    Forms.pmValidators.isRequired,
    Forms.pmValidators.min(1),
    Forms.pmValidators.max(9.22e18),
]);

const formatDateTime = ({ value }) =>
    value ? <FormattedDateTime>{value}</FormattedDateTime> : "";

const formatNextRunDateTime = ({ value }) => {
    if (value) {
        return new Date() > new Date(value) ? (
            "Pending"
        ) : (
            <FormattedDateTime>{value}</FormattedDateTime>
        );
    } else {
        return "";
    }
};
const bytesToMB = ({ value }) =>
    value ? (value / (1024 * 1024)).toFixed(3) : value;

export const IMArchiveSettings = ({ instanceId }) => {
    const backupConfigQuery = useInstanceBackupConfigQuery({ instanceId });
    const backupConfigMutation = useInstanceBackupConfigMutation({
        instanceId,
    });

    const { formId, handleSubmit } = Forms.useForm({
        onSubmit: ({ values }) => backupConfigMutation.mutate(values),
    });
    const enabledValue = Forms.useFieldValue({ formId, name: "enabled" });

    return (
        <LoadableRenderer
            loadable={backupConfigQuery.loadable}
            hasValue={({ enabled, dirs }) => (
                <Forms.Form formId={formId} onSubmit={handleSubmit}>
                    <Text size="large">{t("im-archive.account.perex")}</Text>
                    <Gap size="small" />
                    <Forms.Fields.Switch
                        name="enabled"
                        label={t("im-archive.account.fields.enabled.label")}
                        textOn={t("im-archive.account.fields.enabled.checked")}
                        textOff={t(
                            "im-archive.account.fields.enabled.unchecked",
                        )}
                        initialValue={enabled}
                        noMaxWidth
                    />
                    <Forms.FieldGrid layout={LAYOUT}>
                        <Forms.List
                            formId={formId}
                            name={"dirs"}
                            initialValue={dirs}
                        >
                            {({ rows, add, remove }) => (
                                <>
                                    {rows.map(({ id, fieldProps }) => (
                                        <Forms.FieldGrid.Row key={id}>
                                            <Forms.Fields.Input
                                                {...fieldProps("folderName")}
                                                label={t(
                                                    "im-archive.account.fields.dirs.folderName.label",
                                                )}
                                                validator={folderNameValidator}
                                                disabled={!enabledValue}
                                            />
                                            <Forms.Field
                                                as={TimeWithUnits}
                                                from={identity}
                                                {...fieldProps("limitSeconds")}
                                                label={t(
                                                    "im-archive.account.fields.dirs.limit.label",
                                                )}
                                                validator={
                                                    limitSecondsValidator
                                                }
                                                disabled={!enabledValue}
                                                condensed
                                            />
                                            <Forms.Fields.ReadOnlyValue
                                                label={t(
                                                    "im-archive.account.fields.dirs.lastRun.label",
                                                )}
                                                getValue={formatDateTime}
                                                {...fieldProps("lastRun")}
                                            />
                                            <Forms.Fields.ReadOnlyValue
                                                label={t(
                                                    "im-archive.account.fields.dirs.nextRun.label",
                                                )}
                                                getValue={formatNextRunDateTime}
                                                {...fieldProps("nextRun")}
                                            />
                                            <Forms.Fields.ReadOnlyValue
                                                label={t(
                                                    "im-archive.account.fields.dirs.totalFilesCount.label",
                                                )}
                                                {...fieldProps(
                                                    "totalFilesCount",
                                                )}
                                            />
                                            <Forms.Fields.ReadOnlyValue
                                                label={t(
                                                    "im-archive.account.fields.dirs.totalFilesSize.label",
                                                )}
                                                getValue={bytesToMB}
                                                {...fieldProps(
                                                    "totalFilesSize",
                                                )}
                                            />
                                            <Forms.UIField name="actions">
                                                <DeleteRowButton
                                                    onClick={() => remove(id)}
                                                    disabled={!enabledValue}
                                                />
                                            </Forms.UIField>
                                        </Forms.FieldGrid.Row>
                                    ))}
                                    <Gap size="small" />
                                    <Button
                                        size="small"
                                        onClick={() => add()}
                                        label={t(
                                            "im-archive.account.add-dir.label",
                                        )}
                                        disabled={!enabledValue}
                                    />
                                </>
                            )}
                        </Forms.List>
                    </Forms.FieldGrid>
                    <Gap />
                    <Forms.SubmitButton disabled={isLoading(backupConfigQuery)}>
                        <Button
                            type="primary"
                            label={t("general.save")}
                            onClick={e => {
                                e.stopPropagation();
                                handleSubmit(e);
                            }}
                        />
                    </Forms.SubmitButton>
                </Forms.Form>
            )}
        />
    );
};

IMArchiveSettings.propTypes = { instanceId: PropTypes.number.isRequired };
