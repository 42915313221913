import { Collapse, Gap, H3, Panel, Text } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { ErrorBoundary } from "@/components/Error";
import { LoadableRenderer, useQueryLoadable } from "@/modules/loadable";
import { VisibleWhenHasPermission } from "@/security/authorization";
import {
    ACCOUNT_ACTIVITY_LOG_PERMISSIONS,
    CLUSTER_UPGRADE_PERMISSIONS,
    PRICEFX_INTERNAL_USER_PERMISSION,
} from "@/security/permission.utils";
import { getData } from "@/services/utils";
import { t, T } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import ActivityLogTimeline from "../ActivityLogTimeline/ActivityLogTimeline";
import { ClusterUpgradeOverviewAccount } from "../UpgradeScheduling/ClusterUpgradeOverviewAccount.component";
import { AccountSalesFxLink } from "./AccountSalesFxLink";
import CustomerDashboardHeader from "./CustomerDashboardHeader";
import { RelatedContacts } from "./RelatedContacts.component";

const CustomerDashboard = ({ accountId }) => {
    const { accountsService } = useDic();
    const accountDetailResource = useQueryLoadable(() => {
        return accountsService.fetchAccountDetail(accountId).then(getData);
    }, [accountId, accountsService]);

    return (
        <LoadableRenderer
            loadable={accountDetailResource.loadable}
            hasValue={accountDetail => (
                <>
                    <H3>
                        {accountDetail.name}
                        <VisibleWhenHasPermission
                            permission={PRICEFX_INTERNAL_USER_PERMISSION}
                        >
                            <AccountSalesFxLink
                                accountUrl={accountDetail.accountUrl}
                            />
                        </VisibleWhenHasPermission>
                    </H3>
                    <CustomerDashboardHeader
                        partitionsCount={accountDetail.partitions.length}
                        integrationsCount={accountDetail.instances.length}
                        accountId={accountId}
                        parentAccountId={accountDetail.parentId}
                        parentAccountName={accountDetail.parentName}
                        ownerName={accountDetail.ownerName}
                        hasRightsForParentAccount={
                            accountDetail.hasRightsForParentAccount
                        }
                    />
                    <Gap size="x-large" />
                    <VisibleWhenHasPermission
                        permission={CLUSTER_UPGRADE_PERMISSIONS}
                    >
                        <Collapse
                            className="cluster-upgrades-overview"
                            defaultActiveKey={["1"]}
                            bordered={false}
                        >
                            <Panel
                                header={t("cluster.upgrade.overview.header")}
                                key="1"
                            >
                                <ClusterUpgradeOverviewAccount
                                    accountId={accountId}
                                    fixed
                                    fixedHeight={320}
                                />
                            </Panel>
                        </Collapse>
                        <Gap size="x-large" />
                    </VisibleWhenHasPermission>
                    <RelatedContacts accountId={accountId} />
                    <VisibleWhenHasPermission
                        permission={ACCOUNT_ACTIVITY_LOG_PERMISSIONS}
                    >
                        <Gap size="x-large" />
                        <Text>
                            <T id="account.dashboard.activity-log" />
                        </Text>
                        <Gap size="small" />
                        <ErrorBoundary>
                            <ActivityLogTimeline accountId={accountId} />
                        </ErrorBoundary>
                    </VisibleWhenHasPermission>
                </>
            )}
        />
    );
};

CustomerDashboard.propTypes = {
    accountId: PropTypes.number.isRequired,
};

export default CustomerDashboard;
