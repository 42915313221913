import { Gap, H4 } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useDic } from "@/components/Dic/useDic.hook";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { useAlertEffect } from "@/components/PageLayout/useAlertEffect.hook";
import { LocationLink } from "@/modules/router";
import UserSecurityContentContext from "@/security/UserSecurityContentContext";
import { T, t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { accountsService } from "../../apps/accountApp/services/accounts.service";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { createColumns } from "./AccountsList.columns";

const preferencesType = preferencesTypes.ACCOUNTS_LIST_TABLE;

const AccountsList = () => {
    const {
        axiosService,
        locationRouterService,
        accountAppLocations: { accountUsersLocation, dashboardLocation },
    } = useDic();

    const [tableProps, { error }] = useFetchPage(
        (page, size, sort, filter) =>
            axiosService.post(
                `/api/dashboard/projects?${qs.stringify({
                    page,
                    size,
                    sort,
                })}`,
                filter,
            ),
        [],
    );

    const [accountTypes, setAccountTypes] = useState([]);

    useEffect(() => {
        accountsService
            .fetchAccountTypes()
            .then(response => {
                setAccountTypes(response.data);
            })
            .catch(e => console.log("Unable to fetch account types."));
    }, []);

    const accountPanel = useDetailDrawerState();
    useAlertEffect(error, () => ({
        type: "error",
        message: getErrorMessage(error),
    }));

    const handleUserRightsClick = useCallback(
        record => {
            const accountId = record.id;
            locationRouterService.navigate(accountUsersLocation, {
                accountId,
            });
        },
        [locationRouterService, accountUsersLocation],
    );

    const tableColumns = useMemo(
        () =>
            createColumns({
                dashboardLocation,
                accountTypes,
            }),
        [dashboardLocation, accountTypes],
    );

    const actionMenu = useCallback(
        record => (
            <UserSecurityContentContext projectId={record.id}>
                <ActionButton
                    record={record}
                    items={[
                        {
                            title: t("general.show-detail"),
                            onClick: accountPanel.show,
                        },
                        {
                            title: t("customer-list.menu.user-rights"),
                            onClick: handleUserRightsClick,
                            permission: ["account.edit"],
                        },
                    ]}
                />
            </UserSecurityContentContext>
        ),
        [accountPanel.show, handleUserRightsClick],
    );

    return (
        <>
            <TableWithPreferencesManagement
                {...tableProps}
                columns={tableColumns}
                defaultSort={{
                    fieldName: "name",
                    sortDir: "ascending",
                }}
                fixed
                rowKey="id"
                datasetSlicing="server"
                preferencesType={preferencesType}
                actionMenu={actionMenu}
                exportUrl="/api/dashboard/projects/export"
                panelControl={accountPanel}
                renderPanelHeader={record => (
                    <>
                        <H4>{record.name}</H4>
                        {record.hasRight && (
                            <LocationLink
                                key={record.id}
                                $location={dashboardLocation}
                                $params={{
                                    accountId: record.id,
                                }}
                            >
                                <T id="general.dashboard" />
                            </LocationLink>
                        )}
                        <Gap />
                    </>
                )}
            />
        </>
    );
};

AccountsList.propTypes = {};

export default AccountsList;
