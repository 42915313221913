import {
    AdvancedFilter as UCAdvancedFilter,
    advancedFilterHooks,
    advancedFilterUtils,
} from "@pricefx/unity-components";
import React from "react";
import "./AdvancedFilter.style.less";
import RuleValueRenderer from "./components/RuleValueRenderer";

const RuleSetRenderer = () => UCAdvancedFilter.RuleSet;

const AdvancedFilter = props => (
    <UCAdvancedFilter
        {...props}
        ruleSetRenderer={RuleSetRenderer}
        ruleValueRenderer={RuleValueRenderer}
    />
);

export const Modal = props => (
    <UCAdvancedFilter.Modal
        {...props}
        ruleSetRenderer={RuleSetRenderer}
        ruleValueRenderer={RuleValueRenderer}
    />
);

export const Filter = props => (
    <UCAdvancedFilter.Filter
        {...props}
        ruleSetRenderer={RuleSetRenderer}
        ruleValueRenderer={RuleValueRenderer}
    />
);

AdvancedFilter.Modal = Modal;
AdvancedFilter.Filter = Filter;
AdvancedFilter.OpenButton = UCAdvancedFilter.OpenButton;
AdvancedFilter.PresetPanel = UCAdvancedFilter.PresetPanel;
AdvancedFilter.RulesPanel = UCAdvancedFilter.RulesPanel;
AdvancedFilter.RuleSet = UCAdvancedFilter.RuleSet;
AdvancedFilter.StatusPanel = UCAdvancedFilter.StatusPanel;
AdvancedFilter.useAdvancedFilter = UCAdvancedFilter.useAdvancedFilter;

export const useAdvancedFilter = UCAdvancedFilter.useAdvancedFilter;
export const OpenButton = UCAdvancedFilter.OpenButton;
export const StatusPanel = UCAdvancedFilter.StatusPanel;

export const representStructure = advancedFilterHooks.representStructure;

export const convertAdvancedFilterToAPIFormat =
    advancedFilterUtils.convertAdvancedFilterToAPIFormat;
export const createAdvancedFilterFromAPI =
    advancedFilterUtils.createAdvancedFilterFromAPI;

export default AdvancedFilter;
