import { AlertsContext } from "@/components/PageLayout/Alerts/AlertsContextProvider";
import React, { useCallback, useContext, useState } from "react";
import uuid from "uuid/v4";
import { Alert } from "@pricefx/unity-components";

export const ALERT_TYPE = {
    ERROR: "error",
    WARNING: "warning",
};

const useAlerts = () => useContext(AlertsContext).alerts;

export const useHeaderAlertProp = () => {
    const alerts = useAlerts();
    return alerts.length
        ? alerts.map(({ id, ...props }) => <Alert key={id} {...props} />)
        : null;
};

export const useSetAlert = () => {
    const [id] = useState(uuid());
    const { setAlert } = useContext(AlertsContext);

    return useCallback(props => setAlert({ id, props }), [id, setAlert]);
};

export const useSetAlertType = (type = ALERT_TYPE.ERROR) => {
    const setAlert = useSetAlert();

    return useCallback(
        message => setAlert({ type, message }),
        [setAlert, type],
    );
};

export const useSetErrorAlert = () => useSetAlertType(ALERT_TYPE.ERROR);
export const useSetWarningAlert = () => useSetAlertType(ALERT_TYPE.WARNING);
