import { useCenteredLayout } from "@/components/hooks/useContentLayout.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import CreateServerForm from "@/components/Servers/CreateServerForm";
import { t } from "@/translations";
import React from "react";

export const NewServerPage = () => {
    useCenteredLayout();
    useSetPageTitle(t("server-create.title.new-server"));

    return <CreateServerForm />;
};
