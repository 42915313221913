import { useDic } from "@/components/Dic/useDic.hook";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { ResourcesList } from "@/components/Resources/ResourcesList.component";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { INTEGRATION_EDIT_PERMISSIONS } from "@/security/permission.utils";
import React from "react";

export const IntegrationResourcesPage = () => {
    const { instanceId, accountId } = useAccountAppParams();
    const {
        locationRouterService,
        accountAppLocations: { resourcesNewLocation },
    } = useDic();
    useFullWidthTableLayout();

    useBreadcrumbButton(
        {
            label: "resources-list.new-resource",
            onClick: () => locationRouterService.navigate(resourcesNewLocation),
            permissions: INTEGRATION_EDIT_PERMISSIONS,
        },
        [],
    );

    return <ResourcesList instanceId={instanceId} accountId={accountId} />;
};
