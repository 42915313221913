import { Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { usePartitionNameValidator } from "@/components/PartitionRequest/validators";
import { getData } from "@/services/utils";
import { debounceAsync } from "@/utils/promises/promise.utils";
import { getErrorMessage } from "@/utils/state/error.utils";
import qs from "qs";
import { useCallback } from "react";

export const useClusterPartitionExistsValidator = ({
    partitionId,
    clusterName,
}) => {
    const { axiosService } = useDic();
    return useCallback(
        partitionName =>
            clusterName && partitionId
                ? axiosService
                      .get(
                          `/api/partition-assets/${partitionId}/validate${qs.stringify(
                              {
                                  partitionId,
                                  partitionName,
                                  clusterName,
                              },
                              {
                                  addQueryPrefix: true,
                              },
                          )}`,
                      )
                      .then(getData)
                      .then(response =>
                          response.valid
                              ? Forms.success()
                              : Forms.error("Partition name already exists"),
                      )
                      .catch(e => Forms.error(getErrorMessage(e)))
                : Forms.success(),
        [axiosService, clusterName, partitionId],
    );
};

export const useClusterPartitionNameValidator = ({
    partitionId,
    clusterName,
}) => {
    const partitionExistsValidator = useClusterPartitionExistsValidator({
        partitionId,
        clusterName,
    });
    return usePartitionNameValidator({
        nextValidator: debounceAsync(500, partitionExistsValidator),
    });
};
