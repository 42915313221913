import { Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { getData } from "@/services/utils";
import qs from "qs";
import { useCallback } from "react";

export const useDownloadNameExistsValidation = ({
    partitionId,
    skipValidation,
}) => {
    const { axiosService } = useDic();
    const skipValidationStatic = useCurrentHandler(skipValidation);

    return useCallback(
        async (name, getBag) => {
            if (skipValidationStatic({ name })) return Forms.success();

            const queryStr = qs.stringify(
                { partitionId, name },
                { addQueryPrefix: true },
            );

            return axiosService
                .get(`/api/data-downloads${queryStr}`)
                .then(getData)
                .then(list => {
                    if (list?.length) return Forms.error("Already exists");
                    else return Forms.success();
                });
        },
        [axiosService, partitionId, skipValidationStatic],
    );
};
