import { useDic } from "@/components/Dic/useDic.hook";
import LayoutWithStepper from "@/components/Packages/LayoutWithStepper";
import { EMPTY_MAPPING_FUNC } from "@/components/Packages/PackageTableDefinitionPanel/constants";
import { stepPlanMappingFunction } from "@/components/Packages/PackageTableDefinitionPanel/stepsPlans";
import { useCurrentRef } from "@/components/hooks/useCurrentRef.hook";
import { isEqual, size } from "lodash";
import isEmpty from "lodash/isEmpty";
import React, { useContext, useEffect, useReducer } from "react";
import { useRoute } from "react-router5";
import { TrackingContext } from "../../../../mixpanel/TrackingContextProvider";
import { getDepGlobalState } from "../../../../views/Package/package.utils";
import { components } from "./PackageDefinition.register";
import {
    CHANGE_STATE,
    FINISH_INIT,
    INIT_GLOBAL_STATE,
    ON_BACK,
    ON_NEXT,
    initialState,
    reducer,
} from "./stateReducer";
import { logger } from "@/modules/logger";

export const PACKAGE_DEFINITION_QUERY = "?stepPlanType&step&state";

const logInvalidPackageQuery = query => {
    const allowedQueryKeys = PACKAGE_DEFINITION_QUERY.replace("?", "")
        .split("&")
        .sort();
    const newQueryKeys = Object.keys(query).sort();
    if (!isEqual(allowedQueryKeys, newQueryKeys)) {
        console.error("Invalid package definition query:", {
            query,
            allowedQueryKeys,
        });
    }
};

export const PackageDefinitionComponent = ({
    accountId,
    partitionId,
    instanceId,
    packageName,
    apiResult,
    onStepFinish,
    onCancel,
    deployPackageState,
    steps,
    currentStepIndex,
}) => {
    const { packageService } = useDic();
    //TODO slavik dan merge with the stateReducer
    const [state, dispatch] = useReducer(reducer, initialState(apiResult));

    const processGlobalState = getDepGlobalState(apiResult);

    const { route, router } = useRoute();
    const routeRef = useCurrentRef(route);
    const routerRef = useCurrentRef(router);

    const { trackStepEngine } = useContext(TrackingContext);

    useEffect(() => {
        // one-directional package state update of query, needed for appcues (to hook into specific step)
        const step = state.componentToShow;
        const stepPlanType = apiResult?.currentStepData?.type;

        if (
            routeRef.current.params?.step !== step ||
            routeRef.current.params?.state !== deployPackageState ||
            routeRef.current.params?.stepPlanType !== stepPlanType
        ) {
            const newPackageQuery = {
                step,
                state: deployPackageState,
                stepPlanType,
            };
            logInvalidPackageQuery(newPackageQuery);

            routerRef.current.navigate(routeRef.current.name, {
                ...routeRef.current.params,
                ...newPackageQuery,
            });
        }
    }, [
        state.componentToShow,
        deployPackageState,
        apiResult?.currentStepData?.type,
    ]);

    const changeState = (globalState = {}, componentState = {}) => {
        dispatch({
            type: CHANGE_STATE,
            componentState,
            globalState,
        });
    };
    const onNext = (globalState = {}, componentState = {}) => {
        dispatch({
            type: ON_NEXT,
            componentState,
            globalState,
            apiResult,
        });
    };

    const skipCacheForIntegrationManagerTemplates = (apiResult, state) => {
        return state?.global?.instanceId || apiResult?.instance;
    };

    useEffect(() => {
        if (
            apiResult?.state?.noCache ||
            skipCacheForIntegrationManagerTemplates(apiResult)
        ) {
            dispatch({ type: FINISH_INIT });
            return;
        }

        packageService.fetchStepProcessCache(apiResult?.id).then(res => {
            logger.debug({
                logGroupKey: [
                    "PACKAGES",
                    "PackageDefinition",
                    "fetchStepProcessCache",
                ],
                color: "lime",
                data: { apiResult, res },
            });
            if (!isEmpty(res?.data)) {
                dispatch({ type: INIT_GLOBAL_STATE, data: res?.data });
            } else {
                dispatch({ type: FINISH_INIT });
            }
        });
    }, []);

    useEffect(() => {
        if (state.processFinished) {
            const mappingFunc =
                stepPlanMappingFunction[apiResult?.currentStepData?.type] ||
                EMPTY_MAPPING_FUNC;

            onStepFinish(mappingFunc(apiResult?.currentStepData, state.global));
        }
    }, [state.processFinished]);

    useEffect(() => {
        if (
            !isEmpty(state.global) &&
            !apiResult?.state?.noCache &&
            !skipCacheForIntegrationManagerTemplates(apiResult, state)
        ) {
            logger.debug({
                logGroupKey: [
                    "PACKAGES",
                    "PackageDefinition",
                    "setStepProcessCache",
                ],
                color: "lime",
                data: { apiResult, state },
            });
            packageService.setStepProcessCache(apiResult?.id, state.global);
        }
    }, [state.planIndex]);

    const uiStep = state.componentToShow;
    const apiStep = apiResult.currentStep;
    useEffect(() => {
        const deps = { accountId, partitionId, instanceId };
        const attributes = {
            packageName,
            apiStep,
            uiStep,
            // result
        };
        if (
            (partitionId || instanceId) &&
            accountId &&
            Object.values(attributes).every(Boolean)
        ) {
            trackStepEngine(deps, attributes);
        } else
            console.log("%cMissing mixpanel attributes", "color:brown;", {
                deps,
                attributes,
            });
    }, [
        packageName,
        partitionId,
        instanceId,
        accountId,
        uiStep,
        apiStep,
        trackStepEngine,
    ]);

    const canGoBack = state.planIndex > 0 && !state.processFinished;
    const onBack = canGoBack
        ? (globalState, componentState) => {
              changeState(globalState, componentState);
              dispatch({ type: ON_BACK });
          }
        : undefined;

    const Component = components[state.componentToShow];
    if (!Component) return null;

    logger.debug({
        logGroupKey: ["PACKAGES", "PackageDefinition", "rndr"],
        color: "lime",
        msg: state.componentToShow,
        data: { apiResult, state, deployPackageState },
    });

    return (
        <>
            {size(steps) > 0 && (
                <LayoutWithStepper
                    steps={steps}
                    currentStepIndex={currentStepIndex}
                />
            )}
            {state.showComponent && (
                <Component
                    accountId={accountId}
                    partitionId={partitionId}
                    instanceId={instanceId}
                    apiResult={apiResult}
                    step={apiResult.currentStepData}
                    globalState={state.global || {}}
                    processGlobalState={processGlobalState || {}}
                    componentState={
                        state.componentsState[state.componentToShow] || {}
                    }
                    changeState={changeState}
                    onNext={onNext}
                    onBack={onBack}
                    onCancel={onCancel}
                />
            )}
        </>
    );
};

PackageDefinitionComponent.propTypes = {};
