import { useFullWidthLayout } from "@/components/hooks/useContentLayout.hook";
import RouteSendPayload from "@/components/IntegrationRoutes/components/RouteSendPayload";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React from "react";

export const RouteSendPayloadPage = () => {
    const { accountId, instanceId, routeId } = useAccountAppParams();

    useFullWidthLayout();

    return (
        <RouteSendPayload
            accountId={accountId}
            instanceId={instanceId}
            routeId={routeId}
        />
    );
};
