export const createAdminApiService = ({ axiosService }) => ({
    fetchProjectUsers: projectId =>
        axiosService.get(`/api/projects/${projectId}/users`),

    inviteUser: (projectId, username) =>
        axiosService.post(
            `/api/projects/${projectId}/invitations`,
            JSON.stringify({ username }),
        ),

    deleteUser: (projectId, userId) =>
        axiosService.delete(`/api/projects/${projectId}/users/${userId}`),

    fetchProjectGroups: projectId =>
        axiosService.get(`/api/projects/${projectId}/groups`),

    fetchProjectGroupDetail: (projectId, groupId) =>
        axiosService.get(`/api/projects/${projectId}/groups/${groupId}`),

    createProjectGroup: (projectId, group) =>
        axiosService.post(`/api/projects/${projectId}/groups`, {
            name: group.name,
            description: group.description,
        }),

    updateProjectGroup: (projectId, group) =>
        axiosService.post(`/api/projects/${projectId}/groups/${group.id}`, {
            name: group.name,
            description: group.description,
        }),

    deleteProjectGroup: (projectId, groupId) =>
        axiosService.delete(`/api/projects/${projectId}/groups/${groupId}`),

    removeUserFromProjectGroup: (projectId, groupId, userId) =>
        axiosService.delete(
            `/api/projects/${projectId}/groups/${groupId}/users/${userId}`,
        ),

    inviteUsersToGroup: (projectId, groupId, userNames) =>
        axiosService.post(
            `/api/projects/${projectId}/groups/${groupId}/users`,
            JSON.stringify({ userNames }),
        ),
});
