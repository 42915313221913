import { useDic } from "@/components/Dic/useDic.hook";
import { EventSchedulerForm } from "@/components/EventSchedulers/EventSchedulerForm";
import { useCreateEventSchedulerMutation } from "@/components/EventSchedulers/loadables";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";

export const NewEventSchedulerPage = () => {
    const { accountId } = useAccountAppParams();
    useSetPageTitle(
        t("location.accountApp.eventOrchestration.eventSchedulers.create"),
    );
    const {
        locationRouterService,
        accountAppLocations: { eventSchedulersLocation },
    } = useDic();
    const onCancel = useCallback(
        () => locationRouterService.navigate(eventSchedulersLocation),
        [eventSchedulersLocation, locationRouterService],
    );
    useSetBackButtonAction(onCancel);
    const createMutation = useCreateEventSchedulerMutation({
        accountId,
        afterSuccess: onCancel,
    });

    return (
        <EventSchedulerForm
            accountId={accountId}
            initialValues={undefined}
            onSubmit={createMutation.mutate}
            onCancel={onCancel}
        />
    );
};
