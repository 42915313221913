import { Path } from "path-parser";

export const parsePath = routePath => {
    return new Path(routePath);
};

export const getUrlParams = routePath => parsePath(routePath).urlParams;

export const replaceRouteId = (route, routeId) => {
    const routePrefix = '<route id="';

    const routeWithoutPrefix = route.substring(
        routePrefix.length,
        route.length,
    );
    const secondPart = routeWithoutPrefix.substring(
        routeWithoutPrefix.indexOf('"'),
        route.length,
    );
    return routePrefix + routeId + secondPart;
};
