import { AdminReportModal } from "@/components/AdminReports/AdminReportModal";
import {
    useDeleteAdminReportMutation,
    usePostAdminReport,
} from "@/components/AdminReports/loadables";
import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import {
    fieldTypes,
    HEADER_HEIGHT_WITH_BREADCRUMB,
} from "@/components/DesignSystem/Table/constants";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { useCustomModal } from "@/modules/modal";
import { t } from "@/translations";
import { buildPageableUrl } from "@/utils/urlUtils";
import React, { useCallback } from "react";
import { axiosService } from "../../axios";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";

const preferencesType = preferencesTypes.REPORTS_ELK_LIST_TABLE;

export const TARGET_OPTIONS = [
    { value: "INTEGRATION", label: "Integration" },
    { value: "PARTITION", label: "Partition" },
];

export const ELK_INDEX_OPTIONS = [
    { value: "logstash-*", label: "logstash-*" },
    { value: "messages-*", label: "messages-*" },
    { value: "stats_api-*", label: "stats_api-*" },
    { value: "routes_state-*", label: "routes_state-*" },
    { value: "invalid_records-*", label: "invalid_records-*" },
    { value: "audit_log-*", label: "audit_log-*" },
    { value: "pm-dataload-*", label: "pm-dataload-*" },
];

const columns = [
    {
        type: fieldTypes.TEXT,
        label: t("adminReports-list.header.name"),
        name: "name",
    },
    {
        type: fieldTypes.BOOLEAN,
        label: t("adminReports-list.header.enabled"),
        name: "enabled",
    },
    {
        type: fieldTypes.OPTION,
        label: t("adminReports-list.header.target"),
        name: "target",
        options: TARGET_OPTIONS,
    },
    {
        type: fieldTypes.OPTION,
        label: t("adminReports-list.header.elkIndex"),
        name: "elkIndex",
        options: ELK_INDEX_OPTIONS,
    },
    {
        type: fieldTypes.TEXT,
        label: t("adminReports-list.header.query"),
        name: "query",
    },
    {
        type: fieldTypes.TEXT,
        label: t("adminReports-list.header.scheduledAt"),
        name: "scheduledAt",
        render: text => <FormattedDateTime>{text}</FormattedDateTime>,
    },
    {
        type: fieldTypes.TEXT,
        label: t("adminReports-list.header.timeZone"),
        name: "timeZone",
    },
    {
        type: fieldTypes.TEXT,
        label: t("adminReports-list.header.lookBack"),
        name: "lookBack",
    },
    {
        type: fieldTypes.TEXT,
        label: t("adminReports-list.header.repeatPeriod"),
        name: "repeatPeriod",
    },
    {
        type: fieldTypes.TEXT,
        label: t("adminReports-list.header.recipients"),
        name: "recipients",
    },
    {
        type: fieldTypes.TEXT,
        label: t("adminReports-list.header.createdAt"),
        name: "createdAt",
        render: text => <FormattedDateTime>{text}</FormattedDateTime>,
        visible: false,
    },
    {
        type: fieldTypes.TEXT,
        label: t("adminReports-list.header.createdBy"),
        name: "createdBy",
        visible: false,
    },
    {
        type: fieldTypes.TEXT,
        label: t("adminReports-list.header.updatedAt"),
        name: "updatedAt",
        render: text => <FormattedDateTime>{text}</FormattedDateTime>,
        visible: false,
    },
    {
        type: fieldTypes.TEXT,
        label: t("adminReports-list.header.updatedBy"),
        name: "updatedBy",
        visible: false,
    },
];

const AdminReportsList = () => {
    useFullWidthTableLayout();

    const [tableProps, { reload }] = useFetchPage(
        async (page, size, sort, filter) => {
            const url = buildPageableUrl(
                "/api/admin-reports/search",
                page,
                size,
                sort,
            );
            return axiosService.post(url, filter);
        },
        [],
    );

    const customModal = useCustomModal();

    const postAdminReportResource = usePostAdminReport(
        {
            afterSave: () => {
                customModal.hide();
                reload();
            },
        },
        [],
    );

    const deleteAdminReportMutation = useDeleteAdminReportMutation({
        afterSuccess: reload,
    });

    const handleDelete = useCallback(
        record => {
            deleteAdminReportMutation.mutate(record);
        },
        [deleteAdminReportMutation],
    );

    const onCreateReport = useCallback(
        () =>
            customModal.show(
                <AdminReportModal onSave={postAdminReportResource.mutate} />,
            ),
        [customModal],
    );

    useBreadcrumbButton(
        {
            label: t("adminReports-list.button.create-report"),
            onClick: onCreateReport,
        },
        [],
    );

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("adminReports-list.rowMenu.edit"),
                        customModal: (
                            <AdminReportModal
                                id={record.id}
                                onSave={postAdminReportResource.mutate}
                            />
                        ),
                    },
                    {
                        title: t("adminReports-list.rowMenu.delete"),
                        confirmMessage: t("general.modal.delete.message", {
                            name: record.name,
                            type: "report",
                        }),
                        onConfirm: handleDelete,
                        color: "red",
                    },
                ]}
            />
        ),
        [handleDelete, postAdminReportResource.mutate],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            defaultSort={{
                fieldName: "name",
                sortDir: "ascending",
            }}
            columns={columns}
            rowKey="id"
            datasetSlicing="server"
            preferencesType={preferencesType}
            fixed
            restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
            {...tableProps}
        />
    );
};

export default AdminReportsList;
