import { Gap, H4, H5 } from "@/components/DesignSystem";
import { LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import { isEmpty } from "lodash";
import React from "react";
import { Property } from "../../Property/Property.component";
import { useAccountGroups } from "../hooks/useAccountGroups.hook";

export const PanelApproversList = ({ record }) => {
    return (
        <>
            <H4>{t("workflow-list.panel.approve-by")}</H4>
            {record?.context.steps &&
                record.context.steps.map((step, index) => (
                    <>
                        {index > 0 && (
                            <>
                                <H5 type="primary">{t("general.and")}</H5>
                                <Gap size="small" />
                            </>
                        )}
                        <Property
                            hideEmpty
                            key={"approvers" + index}
                            label={t(
                                "account.workflows.detail.label.list-of-approvers",
                            )}
                            value={step?.persons}
                        />
                        <Property
                            hideEmpty
                            key={"admin-group-approvers" + index}
                            label={t(
                                "account.workflows.detail.label.list-of-admin-group-approvers",
                            )}
                            value={step.adminGroups}
                        />
                        {record.projectId && !isEmpty(step?.groups) && (
                            <GroupApproversList
                                approverGroups={step.groups}
                                accountId={record.projectId}
                            />
                        )}
                        <Property
                            hideEmpty
                            key={"role-approvers" + index}
                            label={t(
                                "account.workflows.detail.label.list-of-role-approvers",
                            )}
                            value={step.roles}
                        />
                        <Property
                            key={"number" + index}
                            label={t(
                                "account.workflows.detail.label.number-of-approvers",
                            )}
                            value={step.approvedRequires}
                        />
                    </>
                ))}
        </>
    );
};

function GroupApproversList({ approverGroups, accountId }) {
    const groups = useAccountGroups(accountId);
    return (
        <LoadableRenderer
            loadable={groups.loadable}
            loading={() => <LoadableRenderer.Spinner />}
            hasValue={({ data: groupList }) => {
                return (
                    <Property
                        label={t(
                            "account.workflows.detail.label.list-of-group-approvers",
                        )}
                        value={approverGroups.map(
                            el => groupList.find(it => it.id === el).name,
                        )}
                    />
                );
            }}
        />
    );
}
