import filter from "lodash/filter";
import find from "lodash/find";
import isArray from "lodash/isArray";
import keyBy from "lodash/keyBy";
import values from "lodash/values";
import uuid from "uuid/v4";
import {
    compareFields,
    getOrDefaultString,
    hasConvertibleType,
    resolveType,
} from "../../../packagesPanel.utils";
import { InputType, ParsedType } from "../../constants";

export const convertToNumbersForFormula = tableExampleData =>
    filter(
        tableExampleData?.columns ?? [],
        c => c.type === ParsedType.NUMBER,
    ).map((c, index) => ({
        key: toLetters(index),
        sourceField: c.name,
        example: c.parsedValues[0],
    }));

export const getHeadersOfStringType = tableExampleData =>
    filter(
        tableExampleData?.columns ?? [],
        c => c.type === ParsedType.STRING,
    ).map(c => c.name);

const toLetter = num => {
    if (num < 0 || num > 25)
        throw new RangeError(
            `Conversion to letter failed: num=${num}, must be 0 <= num <= 25`,
        );
    const char = String.fromCharCode(65 + num);
    return char;
};

export const toLetters = (num, prev = []) => {
    const mod = num % 26;
    const pow = (num / 26) | 0;
    const letter = toLetter(mod);

    if (!pow) return [letter, ...prev].join("");

    return toLetters(pow - 1, [letter, ...prev]);
};

export const nameMatches = (candidateName, name, aliases = []) => {
    return (
        compareFields(name, candidateName) ||
        aliases.some(alias => compareFields(alias, candidateName))
    );
};

export const includesCaseInsensitive = (str, substr) =>
    str?.toLowerCase()?.includes(substr?.toLowerCase());

const getFieldValue = (fieldDefinition, fieldsInFile) => {
    return find(
        fieldsInFile,
        m =>
            hasConvertibleType(
                getOrDefaultString(m.type),
                getOrDefaultString(fieldDefinition.type),
            ) &&
            nameMatches(
                m.name,
                fieldDefinition.label,
                fieldDefinition.mappingAliases,
            ),
    )?.name;
};

export const calculateExistingFields = (
    fields,
    definedFields,
    fieldsFromFile,
    getId = uuid,
) => {
    const definedFieldsByName = keyBy(definedFields, "name");
    const fieldsFromFileByName = keyBy(fieldsFromFile, "name");
    const filteredFields = values(fields)
        .filter(f => fieldValueExistsInFile(f, fieldsFromFileByName))
        .filter(f =>
            resolvedTypeCombinationIsValid(
                f,
                definedFieldsByName,
                fieldsFromFileByName,
            ),
        );

    const groupedByName = keyBy(filteredFields, "name");
    const result = {};
    (definedFields || []).forEach(stepField => {
        const field = groupedByName[stepField.name]; // global state field
        const value = field?.value ?? getFieldValue(stepField, fieldsFromFile);
        const inputType = field?.inputType ?? InputType.BODY;
        result[stepField.name] = {
            ...stepField,
            ...field,
            id: field?.id || getId(),
            name: stepField.name, // output
            label: stepField.label, // output
            inputType,
            value, // input
            description: stepField.description,
            type: stepField.type,
            skip: field?.skip,
            resolvedType:
                resolveType(fieldsFromFile, value, inputType) ||
                field?.resolvedType ||
                stepField.type,
            settings: field?.settings,
        };
    });

    return result;
};

const fieldValueExistsInFile = (field, fieldsFromFileByName) => {
    if (!field.value) {
        return field;
    }

    return isArray(field.value)
        ? filter(
              field.value,
              combinedValue => fieldsFromFileByName[combinedValue],
          ).length === field.value.length
        : fieldsFromFileByName[field.value];
};

const resolvedTypeCombinationIsValid = (
    field,
    definedFieldsByName,
    fieldsFromFileByName,
) => {
    if (getOrDefaultString(field.resolvedType) === ParsedType.COMBINED) {
        return (
            field.value.filter(
                v =>
                    getOrDefaultString(fieldsFromFileByName[v]?.type) !==
                    ParsedType.STRING,
            ).length === 0
        );
    }

    const fieldDefinition = definedFieldsByName[field.name];
    //Definition has changed and it does not contain this field
    if (!fieldDefinition) {
        return false;
    }

    if (
        getOrDefaultString(field.type) !==
        getOrDefaultString(fieldDefinition.type)
    ) {
        return false;
    }

    const fieldFromFile = fieldsFromFileByName[field.value];
    return hasConvertibleType(
        getOrDefaultString(fieldFromFile?.type),
        getOrDefaultString(field.resolvedType),
    );
};
