import { tap } from "@/components/Connections/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useSftpServerUsersQuery = ({ serverId }) => {
    const { sftpService } = useDic();

    return useQueryLoadable(
        () => sftpService.listSftpUsers({ serverId }).then(getData),
        [sftpService, serverId],
    );
};

export const useDeleteSftpUserMutation = ({ serverId, afterSuccess }) => {
    const { sftpService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ username }) =>
            sftpService
                .deleteUser({ serverId, username })
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [serverId, afterSuccessCurrent, sftpService],
        "sftp-servers.user-management.deleted",
    );
};

export const useSftpServerQuery = ({ serverId }) => {
    const { sftpService } = useDic();

    return useQueryLoadable(
        async () => sftpService.getServer({ serverId }).then(getData),
        [serverId, sftpService],
    );
};

export const useSftpServerUserQuery = ({ serverId, userId, isNew }) => {
    const { sftpService } = useDic();

    return useQueryLoadable(
        () =>
            isNew
                ? Promise.resolve({})
                : sftpService
                      .getUser({
                          serverId,
                          username: userId,
                      })
                      .then(getData),
        [isNew, sftpService, serverId, userId],
    );
};

export const useSftpServerUserMutation = ({
    serverId,
    username,
    afterSuccess,
}) => {
    const { sftpService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async values =>
            (username
                ? sftpService.updateUser({
                      serverId,
                      username,
                      values,
                  })
                : sftpService.createUser({
                      serverId,
                      values,
                  })
            )
                .then(getData)
                .then(res => {
                    afterSuccessCurrent(res);
                    return res;
                }),
        [username, sftpService, serverId, afterSuccessCurrent],
        username
            ? "sftp-servers.user-management.saved"
            : "sftp-servers.user-management.created",
    );
};

export const useParseSftpKeyMutation = () => {
    const { sftpService } = useDic();

    return useMutationLoadableWithNotification(
        async ({ key }) =>
            sftpService
                .parseKey({
                    key,
                })
                .then(getData),
        [sftpService],
        "sftp-servers.user-management.ssh-key.added",
    );
};
