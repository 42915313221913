import { RowSkeleton } from "@/components/DesignSystem/Skeleton/RowSkeleton.component";
import { mapVendorToVendorName } from "@/components/ISVMapping/helpers";
import { SOURCE_TYPE } from "@/components/Mappers/ExportMapper/EntitySelectFields";
import { VENDOR } from "@/components/Mappers/MapperTableWithCustomization/constants";
import { useEntityNamesQuery } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/loadables";
import { ENTITY_TYPE_CODES_ALL } from "@/constants/SupportedTableImportTypes";
import { isLoading } from "@/modules/loadable";
import PropTypes from "prop-types";
import React from "react";

const FakeFieldGridCell = ({
    layout = { flex: "0 0 1O0%" },
    isFirst = false,
    isLast = false,
    children,
}) => (
    <div
        style={{
            ...layout,
            ...(isFirst ? { marginRight: "0.5rem" } : {}),
            ...(isLast ? { marginLeft: "0.5rem" } : {}),
            padding: "0.5rem 0.25rem",
            boxSizing: "content-box",
            minWidth: "unset",
        }}
    >
        {children}
    </div>
);

const getPfxEntityNameAndType = ({
    entityName,
    entityType,
    entityNameLabel,
}) => `${mapVendorToVendorName(VENDOR.PRICEFX)} ${
    ENTITY_TYPE_CODES_ALL[entityType]?.label ?? entityType
}${" "}
${entityName ? entityNameLabel : ""}`;

export const EntityNameHeader = ({
    vendors,
    layout,
    entityName,
    entityType,
    partitionId,
}) => {
    const entityNamesQuery = useEntityNamesQuery({
        entityType,
        sourceType: SOURCE_TYPE.PARTITIONS,
        sourceId: partitionId,
    });

    const entityNameLabel = isLoading(entityNamesQuery.loadable) ? (
        <RowSkeleton />
    ) : (
        (entityNamesQuery.loadable.valueMaybe() ?? []).find(
            entity => entity.value === entityName,
        )?.label
    );

    const entityNameByVendor = vendor => {
        switch (vendor) {
            case VENDOR.PRICEFX:
                return getPfxEntityNameAndType({
                    entityName,
                    entityType,
                    entityNameLabel,
                });
            default:
                return mapVendorToVendorName(vendor);
        }
    };

    return (
        <div style={styles.wrapper}>
            <FakeFieldGridCell isFirst layout={layout[0]}>
                <span style={styles.header}>
                    {entityNameByVendor(vendors[0])}
                </span>
            </FakeFieldGridCell>
            <FakeFieldGridCell layout={layout[1]}></FakeFieldGridCell>
            <FakeFieldGridCell layout={layout[2]}>
                <span style={styles.header}>
                    {entityNameByVendor(vendors[1])}
                </span>
            </FakeFieldGridCell>
            <FakeFieldGridCell isLast layout={layout[3]}></FakeFieldGridCell>
        </div>
    );
};

const styles = {
    wrapper: {
        display: "flex",
    },
    header: { fontWeight: 500, fontSize: "13px", color: "#262626" },
};

EntityNameHeader.propTypes = {
    layout: PropTypes.array.isRequired,
    entityType: PropTypes.string.isRequired,
    entityName: PropTypes.string,
    partitionId: PropTypes.number,
};
