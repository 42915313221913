import moment from "moment";

moment.updateLocale("en", {
    relativeTime: {
        s: "1 second",
        m: "1 minute",
        h: "1 hour",
        d: "1 day", // instead of default an day
        M: "1 month",
        y: "1 year",
    },
});

const formatMaxHours = (value, locale) => {
    const str = [];
    if (Math.floor(value.asDays()) > 0) {
        let hours = Math.floor(value.asDays()) * 24;
        if (value.hours() > 0) {
            hours += value.hours();
        }
        str.push(
            moment
                .localeData(locale)
                .relativeTime(hours, true, hours === 1 ? "h" : "hh", false),
        );
    } else if (Math.floor(value.asHours()) > 0) {
        str.push(
            moment
                .localeData(locale)
                .relativeTime(
                    Math.floor(value.asHours()),
                    true,
                    Math.floor(value.asHours()) === 1 ? "h" : "hh",
                    false,
                ),
        );
        if (value.minutes() > 0) {
            str.push(
                moment
                    .localeData(locale)
                    .relativeTime(
                        value.minutes(),
                        true,
                        value.minutes() === 1 ? "m" : "mm",
                        false,
                    ),
            );
        }
    } else if (Math.floor(value.asMinutes()) > 0) {
        str.push(
            moment
                .localeData(locale)
                .relativeTime(
                    Math.floor(value.asMinutes()),
                    true,
                    Math.floor(value.asMinutes()) === 1 ? "m" : "mm",
                    false,
                ),
        );
    } else if (Math.floor(value.asSeconds()) > 0) {
        str.push(
            moment
                .localeData(locale)
                .relativeTime(
                    Math.floor(value.asSeconds()),
                    true,
                    Math.floor(value.asSeconds()) === 1 ? "s" : "ss",
                    false,
                ),
        );
    }

    if (str.length === 0) {
        str.push("-");
    }
    return str.join(" ");
};

// @see https://github.com/moment/moment/issues/3732 "PT3H30M" leads to "4 hours"
export const durationPrecisely = ({ value, format, locale = "en" }) => {
    if (value === null) {
        return "-";
    }

    value = moment.duration(value);

    switch (format) {
        case "days":
            return moment
                .localeData(locale)
                .relativeTime(Math.floor(value.asDays()), true, "dd", false);
        case "hours":
            return moment
                .localeData(locale)
                .relativeTime(Math.floor(value.asHours()), true, "hh", false);
        case "hh:mm:ss":
            return (
                Math.floor(value.asHours()).toString().padStart(2, "0") +
                ":" +
                Math.floor(value.minutes()).toString().padStart(2, "0") +
                ":" +
                Math.floor(value.seconds()).toString().padStart(2, "0")
            );
        case "hh:mm":
            return (
                Math.floor(value.asHours()).toString().padStart(2, "0") +
                ":" +
                Math.floor(value.minutes()).toString().padStart(2, "0")
            );
        case "minutes":
            return moment
                .localeData(locale)
                .relativeTime(Math.floor(value.asMinutes()), true, "mm", false);
        case "number":
            return value.asMinutes();
        default:
            return formatMaxHours(value, locale);
    }
};
