import { Drawer, Tabs, UnityLayout } from "@/components/DesignSystem";
import {
    LoadableRenderer,
    MutationResourcePropType,
    QueryResourcePropType,
    loadableFromMaybeValue,
} from "@/modules/loadable";
import { useHasGlobalPermission } from "@/security/hooks/useHasGlobalPermission.hook";
import { PERMISSION_POWER_USER_PERMISSION } from "@/security/permission.utils";
import { T } from "@/translations";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { PermissionsTable } from "../../../../apps/accountApp/components/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/PermissionsTable/PermissionsTable.component";
import { applyGlobalPermissionChanges } from "./applyGlobalPermissionChanges";
import { useGlobalPermissionsUI } from "./hooks/useGlobalPermissionsUI.hook";

const TABS = {
    GLOBAL_PERMISSIONS: "global_permissions",
};

export function GlobalPermissionsDrawer({
    permissionsListResource,
    editablePermissionsResource,
    readOnlyPermissionsResource,
    savePermissionsResource,
    visible,
    onCancel,
    title,
}) {
    const isPowerUser = useHasGlobalPermission(
        PERMISSION_POWER_USER_PERMISSION,
    );
    const disabledPermissions = useMemo(
        () =>
            loadableFromMaybeValue(
                isPowerUser ? [] : [PERMISSION_POWER_USER_PERMISSION],
            ),
        [isPowerUser],
    );

    const globalPermissionsUI = useGlobalPermissionsUI(
        permissionsListResource.loadable,
        editablePermissionsResource.loadable,
        disabledPermissions,
        readOnlyPermissionsResource?.loadable,
    );
    const onSaveClick = () => {
        const maybeEditablePermissions =
            editablePermissionsResource.loadable.valueMaybe();
        if (maybeEditablePermissions === undefined) {
            throw new Error("User permissions not loaded, disable saving!");
        }
        const permissions = applyGlobalPermissionChanges(
            maybeEditablePermissions,
            globalPermissionsUI.changes,
        );
        savePermissionsResource.mutate(permissions);
    };

    return (
        <Drawer
            onClose={onCancel}
            okButtonProps={{
                onClick: onSaveClick,
                children: <T id="general.save" />,
                disabled:
                    !globalPermissionsUI.dirty ||
                    savePermissionsResource.loadable.state === "loading" ||
                    globalPermissionsUI.permissionsLoadable.state === "loading",
            }}
            cancelButtonProps={{ onClick: onCancel }}
            visible={visible}
        >
            <Tabs key={TABS.PERMISSIONS}>
                <Tabs.TabPane tab={title} key={TABS.GLOBAL_PERMISSIONS}>
                    <UnityLayout>
                        <UnityLayout.Content>
                            <LoadableRenderer
                                loadable={
                                    globalPermissionsUI.permissionsLoadable
                                }
                                hasValue={permissions => (
                                    <PermissionsTable
                                        title={title}
                                        dataSource={permissions}
                                        changes={globalPermissionsUI.changes}
                                        onChange={globalPermissionsUI.onChange}
                                        disabled={
                                            savePermissionsResource.loadable
                                                .state === "loading"
                                        }
                                    />
                                )}
                            />
                        </UnityLayout.Content>
                    </UnityLayout>
                </Tabs.TabPane>
            </Tabs>
        </Drawer>
    );
}

GlobalPermissionsDrawer.propTypes = {
    permissionsListResource: QueryResourcePropType().isRequired,
    editablePermissionsResource: QueryResourcePropType().isRequired,
    readOnlyPermissionsResource: QueryResourcePropType(),
    savePermissionsResource: MutationResourcePropType().isRequired,
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};
