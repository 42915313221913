import { CustomerPartitionCredentialsForm } from "@/components/CustomerPartitionCredentials/CustomerPartitionCredentialsForm";
import { Modal } from "@/components/DesignSystem";
import { AlertsContextProvider } from "@/components/PageLayout/Alerts/AlertsContextProvider";
import PropTypes from "prop-types";
import React from "react";

export const CustomerPartitionCredentialsModal = ({
    accountId,
    partitionId,
    visible,
    onCancel,
}) => {
    return (
        <AlertsContextProvider>
            <Modal visible={visible} onClose={onCancel}>
                {visible && (
                    <CustomerPartitionCredentialsForm
                        accountId={accountId}
                        partitionId={partitionId}
                        onCancel={onCancel}
                        afterSaveCredentials={onCancel}
                        insideModal
                    />
                )}
            </Modal>
        </AlertsContextProvider>
    );
};
CustomerPartitionCredentialsModal.propTypes = {
    accountId: PropTypes.number,
    partitionId: PropTypes.number,
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
};
